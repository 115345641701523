import { Experience } from "../../components/shared/configurator/helpers/evisibility"
import { DeviceCheckSolutions } from "../../core/store/typings/DeviceCheckSolutions"
import { ServiceTypes } from "../../core/store/typings/ServiceTypes"
import { strings } from "../../localization/strings"

export const getLocalizedChannel = (channel?: number):string => {
    switch (channel) {
        case Experience.Retail: return strings.ORGANIZATION.SERVICES.SERVICE.RETAIL;
        case Experience.ECom: return strings.ORGANIZATION.SERVICES.SERVICE.ECOM;
        case Experience.Mobile: return strings.ORGANIZATION.SERVICES.SERVICE.MOBILE;
        case Experience.MobileSDK: return strings.ORGANIZATION.SERVICES.SERVICE.MOBILE_SDK;
        default: return '';
    }
}

export const getLocalizedDeviceCheckSolution = (deviceCheckSolution?: number):string => {
    switch (deviceCheckSolution) {
        case DeviceCheckSolutions.PiceaOne: return strings.ORGANIZATION.SERVICES.SERVICE.DEVICE_CHECK_SOLUTION_PIECE_ONE;
        case DeviceCheckSolutions.PiceaMobile: return strings.ORGANIZATION.SERVICES.SERVICE.DEVICE_CHECK_SOLUTION_PIECE_MOBILE;
        case DeviceCheckSolutions.WebBased: return strings.ORGANIZATION.SERVICES.SERVICE.DEVICE_CHECK_SOLUTION_WEB_BASED;
        default: return '';
    }
}

export const getLocalizedServiceType = (t:ServiceTypes) :string => {
    switch( t) {
        case ServiceTypes.TradeIn: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.TRADE_IN;
        case ServiceTypes.Insurance: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.INSURANCE;
        case ServiceTypes.Finance: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.FINANCE;
        case ServiceTypes.Repair: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.REPAIR;
        case ServiceTypes.Warranty: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.WARRANTY;
        case ServiceTypes.SelfService: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.SELF_SERVICE;
        case ServiceTypes.CustomerSupport: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.CUSTOMER_SUPPORT;
        case ServiceTypes.Recycling: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.RECYCLING;
        case ServiceTypes.OutOfBoxWarranty: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.OUT_OF_BOX_WARRANTY;
        case ServiceTypes.ContentTransfer: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.CONTENT_TRANSFER;
        default: return "Undefined";
    }
}
