import { FontIcon, Icon, SharedColors, Spinner, Stack, Text } from "@fluentui/react"
import React from "react"
import { SecondaryButton } from "../../../../../../components/shared/buttons/SecondaryButton"
import { PropertyValue } from "../../../../../../components/shared/decorations/PropertyValue"
import { Section } from "../../../../../../components/shared/decorations/Section"
import { portalApi } from "../../../../../../core/api/api"
import { getFormatedPhoneNumber } from "../../../../../../core/helpers/localization"
import { IPerson } from "../../../../../../core/store"
import { IResponseError } from "@piceasoft/core"
import { strings } from "../../../../../../localization/strings"
import { useBoolean } from "@uifabric/react-hooks";
import { IRequestResult } from "../../../../../../core/store/typings/IResponseResult";
import { IPiceaCustomer } from "../../../../../../core/store/typings/IPiceaCustomer";
import { IPiceaCredentials } from "../../../../../../core/store/typings/IPiceaCredentials"
import { IPiceaProduct } from "../../../../../../core/store/typings/IPiceaProduct"
import { CountryCodes, getLocalizedRegion } from "../../../../../../core/helpers/localization";
import { IStore } from "../../../../../../core/store";
import { useSelector } from "react-redux";
import { UserRoles, getLocalizedRole } from "../../../../../../core/store/typings/UserRoles";

export type TPersonInformationPivotProps = {
    item: IPerson
}

export const PersonInformationPivot: React.FC<TPersonInformationPivotProps> = (props) => {

    const [credentialMessageState, setCredentialsMessageState] = React.useState<{ fetched: boolean, successed?: boolean, error?: string }>()
    // const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [customers, setCustomers] = React.useState<IPiceaCustomer[]>();
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [foundCustomerId, setFoundCustomerId] = React.useState<string>("");
    const [piceaCredentials, setPiceaCredentials] = React.useState<IPiceaCredentials>();
    const [foundCustomer, setFoundCustomer] = React.useState<IPiceaCustomer>();
    const [customerProducts, setCustomerProducts] = React.useState<IPiceaProduct[]>();
    const [productExpiryDate, setProductExpiryDate] = React.useState<string>();
    const [productStatus, setProductStatus] = React.useState<string>();
    const roles = useSelector((s: IStore) => s.environment.roles)


    // Get Customers
    React.useEffect(() => {
        const fetchCustomersData = async () => {

            let result: IRequestResult<IPiceaCustomer[]> = { successed: false };
            await fetch('v1/account/customers', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPiceaCustomer[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])


            return result;
        }
        fetchCustomersData()

            .then(data => {
                // Show customer/lic data to only admin or supervisor
                if (roles?.includes(UserRoles.Administrator) || roles?.includes(UserRoles.Supervisor)) {
                    setCustomers(data.data);
                }
            })
            .catch(console.error);


        const fetchPiceaCredentials = async () => {
            let result: IRequestResult<IPiceaCredentials> = { successed: false };
            await fetch('v1/account/getPiceaCredentials?accountId=' + props.item.id, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPiceaCredentials>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])


            return result;
        }
        fetchPiceaCredentials()

            .then(data => {
                if (data.data && data.data.customerId) {
                    // alert('settings customer id...');
                    setPiceaCredentials(data.data);
                }
            })
            .catch(console.error);









    }, [credentialMessageState])


    React.useEffect(() => {
        if (piceaCredentials && customers) {
            let cust = customers.find((c) => c.customer_id === piceaCredentials.customerId);
            if (cust) {
                setFoundCustomer(cust);
                searchCustomerProducts(cust.customer_id);
            }
            // alert("cust id: "+piceaCredentials.customerId);
        }

    }, [piceaCredentials, customers])


    const searchCustomerProducts = async (customer_id: string) => {
        const result = await portalApi.organization.getCustomerProducts(customer_id);
        if (result.data && result.data) {
            let found_prod = result.data.find((p) => p.product_id === piceaCredentials?.productId);
            setProductExpiryDate(found_prod?.expires.substring(0, 10));
            
            setProductStatus(found_prod?.product_type);

        }
    }


    const onSendCredentials = async () => {
        if (props.item.id) {
            setCredentialsMessageState({ fetched: false })

            const result = await portalApi.organization.accounts.sendCredentials(props.item.id);

            setTimeout(() => {
                setCredentialsMessageState({ fetched: true, successed: result.successed, error: (result.errors && result.errors.length > 0) ? result.errors[0].description : undefined })
            }, 500)
        }
    }

    const onRenderCommand = (): React.ReactNode => {
        if (props.item.email && props.item.email.length > 0) {
            return (
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                    {credentialMessageState?.fetched === false && (
                        <Spinner />
                    ) || (
                            <>
                                {credentialMessageState?.successed && (
                                    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center" horizontalAlign="center" grow={1}>
                                        <FontIcon style={{ fontSize: 14, color: SharedColors.greenCyan10 }} iconName={"CompletedSolid"} />
                                        <Text variant='small' style={{ color: SharedColors.gray30 }}>{strings.COMMON.SENT}</Text>
                                    </Stack>
                                )}
                                {credentialMessageState?.error && (
                                    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center" horizontalAlign="center" grow={1}>
                                        <FontIcon style={{ fontSize: 14, color: SharedColors.red10 }} iconName={"AlertSolid"} />
                                        <Text variant='small' style={{ color: SharedColors.red10 }}>{credentialMessageState?.error}</Text>
                                    </Stack>
                                )}
                            </>
                        )}
                    <Stack.Item>
                        <SecondaryButton text={strings.BUTTONS.TEXT.SEND_CREDENTIALS} onClick={onSendCredentials} />
                    </Stack.Item>
                </Stack>
            )
        }
        return null
    }

    
    return (
        <>
            <Section title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PERSON_INFO} onRenderCommand={onRenderCommand}>
                <Stack tokens={{ childrenGap: 12 }}>
                    {foundCustomer && (
                        <Stack tokens={{ childrenGap: 6 }}>
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.NAME_LABEL} value={foundCustomer.name} />
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.VAT_LABEL} value={foundCustomer.vat_number} />
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.STATUS_LABEL} value={foundCustomer.status} />
                            {piceaCredentials && (<PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.DEFAULT_PRODUCT_ID_LABEL} value={piceaCredentials.productId} />)}
                            {productExpiryDate && (<PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_EXPIRY_LABEL} value={productExpiryDate} />)}
                            {productStatus && (<PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_STATUS_LABEL} value={productStatus} />)}

                            {foundCustomer.country && ( <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.COUNTRY_LABEL} value={getLocalizedRegion(CountryCodes[foundCustomer.country as keyof typeof CountryCodes])} />)}
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_EMAIL_LABEL} value={foundCustomer.contact_email} />
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.ADDRESS_LABEL} value={foundCustomer.address} />
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.PHONE_LABEL} value={foundCustomer.contact_phone} />
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_PERSON_LABEL} value={foundCustomer.contact_person} />
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_TITLE_LABEL} value={foundCustomer.contact_title} />
                            <PropertyValue label={strings.ORGANIZATION.NEW_ORGANIZATION.TECHNICAL_CONTACT_EMAIL_LABEL} value={foundCustomer.tech_contact_email} />

                        </Stack>

                    )}
                    {/* <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME} value={props.item.name} />
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ROLES} value={props.item.roles.join(", ")} />
                    </Stack> */}
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.COMPANY} value={props.item.companyName} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.CONTAINER_CODE} value={props.item.container?.code} />
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.CONTAINER_NAME} value={props.item.container?.name} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.POINT_CODE} value={props.item.point?.code} />
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.POINT_NAME} value={props.item.point?.name} />
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.POINT_CITY} value={props.item.point?.city} />
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.POINT_ADDRESS} value={props.item.point?.address} />
                    </Stack>
                </Stack>
            </Section>
            <Section title={strings.ORGANIZATION.NEW_ORGANIZATION.LOGIN_LABEL}>
                <Stack tokens={{ childrenGap: 12 }}>
                 <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME} value={props.item.name} />
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ROLES} value={props.item.roles.map(role => getLocalizedRole(role)).join(", ")} />
                    </Stack> 
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY} value={props.item.display} />
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE} value={getFormatedPhoneNumber(props.item.phone)} />
                        <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.EMAIL} value={props.item.email} />
                    </Stack>
                </Stack>
            </Section>
        </>
    )
}