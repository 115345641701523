import React from "react"
import { MaskedTextField } from "@fluentui/react"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import { getLocalizedPhoneMask } from "../../../../../../../../../../../core/helpers/localization"
import { strings } from "../../../../../../../../../../../localization/strings"

type TProps = {
    value?: string
    config?: IDataCollectionStandardConfig
    errorMessage: string
    phoneCountry?: string
    handleOnBlur: () => void
    handleOnChange: (value: string) => void
}

const PhoneNumber: React.FunctionComponent<TProps> = (props) => {
    let {value, config, phoneCountry, errorMessage, handleOnChange, handleOnBlur} = props
    return (
        <MaskedTextField
            value={value}
            onChange={(e, value) => { handleOnChange(value ?? "") }}
            label={config?.fields?.phone?.label ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PHONE_NUMBER}
            mask={config?.fields?.phone?.mask ?? (phoneCountry && getLocalizedPhoneMask({ country: phoneCountry, number: value ?? '' })) ?? "(999) 999-99-99"}
            errorMessage={errorMessage}
            onBlur={handleOnBlur}
            deferredValidationTime={500}
            required
        />
    )
}

export default PhoneNumber