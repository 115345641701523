import { Stack, Pivot, PivotItem, ScrollablePane, Sticky, StickyPositionType } from '@fluentui/react';
import * as React from 'react';
import { portalApi } from '../../../../../core/api/api';
import { IPerson } from '../../../../../core/store';
import { strings } from '../../../../../localization/strings';
import { PersonHeader } from './PersonHeader';
import { PersonPivotBasedState } from './PersonPivotBasedState';

export type TPersonPivotState = "information" | "cert"

const getTabId = (itemKey: string) => {
    // console.log(itemKey)
    return `PersonPivot_${itemKey}`;
};

export type TProps = {
    person: IPerson
    onEdit: () => void
    onDelete: () => void
    updateState: (item: IPerson) => void
}

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "information": return strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.PERSON_CARD
        // case "access": return strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.
        default: return m;
    }
}

export const StructurePerson: React.FC<TProps> = ({ onDelete, onEdit, person, updateState }) => {

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TPersonPivotState>("information");

    const [item, setItem] = React.useState(person);

    React.useEffect(() => {
        setItem(person)
    }, [person])

    const handlePivotLinkClick = (item?: PivotItem) => {
        console.log()
        setSelectedPivotKey(item?.props.itemKey! as TPersonPivotState);
    };


    const toggleEnabled = async () => {
        if (person.id) {
            const apiResult = await portalApi.organization.accounts.toggleEnabledAccount(person.id)
            if (apiResult.successed && apiResult.data) {
                setItem(apiResult.data)
                updateState(apiResult.data)
            }
        }
    }

    return (
        <Stack verticalFill tokens={{ childrenGap: 8 }}>
            <PersonHeader person={person} onEdit={onEdit} />
            <Stack verticalFill>
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                    <Pivot style={{ padding: "0px 32px 0px 32px" }} aria-label="Point details" selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId}>
                        <PivotItem itemKey='information' headerText={getPivotHeaderText("information")} />
                        <PivotItem itemKey='cert' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.CERT} />
                    </Pivot>
                </Sticky>
                <PersonPivotBasedState
                    state={selectedPivotKey}
                    item={person}
                    onDelete={onDelete}
                    toggleEnabled={toggleEnabled}
                />
            </Stack>
        </Stack>
    )
}
