import React from "react";
import { MaskedTextField } from "@fluentui/react"
import { IDataCollectionStandardConfig } from "@piceasoft/core";
import { strings } from "../../../../../../../../../../../localization/strings";

type TProps = {
    value?: string
    config?: IDataCollectionStandardConfig
    errorMessage: string
    handleOnBlur: () => void
    handleOnChange: (value: string) => void
}

const PassportNumber: React.FunctionComponent<TProps> = (props) => {
    let {value, config, errorMessage, handleOnChange, handleOnBlur} = props
    return (
        <MaskedTextField
            value={value}
            onChange={(e, value) => { handleOnChange(value ?? "") }}
            label={config?.fields?.passport?.number?.label ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PASSPORT_NUMBER}
            mask={config?.fields?.passport?.number?.mask ?? "9999 999999"}
            errorMessage={errorMessage}
            onBlur={handleOnBlur}
            deferredValidationTime={500}
            required
        />
    )
}

export default PassportNumber