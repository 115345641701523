import {
    Stack, Icon, SharedColors, CommandBarButton, ImageFit, DefaultButton,
    Coachmark, DirectionalHint, TeachingBubbleContent, Text, Image, getTheme
} from "@fluentui/react"
import { useBoolean, useId } from "@uifabric/react-hooks"
import React from "react";
import { IDevice, CurrencyCodes, IPromoProviderItemCondition, IPromoProviderItemState } from '@piceasoft/core';
import { CurrencySigns } from "@piceasoft/core";
import { IDiscountsConfig } from "@piceasoft/core";
import { strings } from "../../../../../../localization/strings";
import unknown from '../../../../../../assets/unknown.png';
import { PromotionsPanel } from "./promotions/PromotionsPanel";
import { getPromoPriceTypeDescription } from "../../../../../../localization/helpers/transaction";

type TProps = {
    device?: IDevice
    config?: IDiscountsConfig
    tradeUpDeviceTitle?: string
    currencySign?: string
}

export const OfferPromotions: React.FC<TProps> = (props) => {

    const [promotion, setPromotion] = React.useState<IPromoProviderItemState>();
    const targetOffersSuggestion = useId('target-offers-suggestion');

    const [isShowPanel, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false);
    const [isCoachmarkVisible, { setFalse: hideCoachmark }] = useBoolean(!props.config?.ui?.teaching ? true : false);

    const handleShowDiscounts = () => {
        hideCoachmark()
        showPanel()
    }

    return (
        <>
            <Stack.Item>
                <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                    <Icon styles={{ root: { width: 32, height: 32, fontSize: 32, color: SharedColors.gray10 } }} iconName="Sync" />
                </Stack>
                <PromotionsPanel isOpen={isShowPanel} dismissPanel={hidePanel} promotion={promotion} setPromotion={setPromotion} config={props.config} />
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 16 }} style={{ maxWidth: 320, minWidth: 320, width: 320 }} horizontalAlign='center'>
                    {promotion && (
                        <CommandBarButton styles={{ root: { background: 'transparent', borderRadius: theme.effects.roundedCorner4 }, rootHovered: { background: theme.palette.neutralLight } }} onClick={handleShowDiscounts}>
                            <Stack tokens={{ childrenGap: 16, padding: '0 16px 24px 16px' }} horizontalAlign="center">
                                <Stack>
                                    <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{props.tradeUpDeviceTitle ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PURCHASED_DEVICE}</Text>
                                </Stack>
                                <Stack tokens={{ childrenGap: 16 }}>
                                    <Stack.Item>
                                        <Stack horizontalAlign="center">
                                            <Image width={96} height={96} imageFit={ImageFit.contain} maximizeFrame={true}
                                                src={promotion?.imgSrc ?? unknown}
                                            />
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontalAlign="center">
                                            <Text variant="mediumPlus" style={{ color: SharedColors.cyanBlue10 }}>{promotion?.manufacturer}</Text>
                                            <Text variant="large" style={{ color: SharedColors.cyanBlue10, fontWeight: "bold" }}>{promotion?.device}</Text>
                                            {promotion.conditions?.find(i => i.price) && (
                                                <Text variant="medium" style={{ color: SharedColors.gray30 }}><small>{promotion.conditions?.find(i => i.price) ? getPromoPriceTypeDescription((promotion.conditions?.find(i => i.price) as IPromoProviderItemCondition).priceType) : undefined}</small> <b>{promotion.conditions?.find(i => i.price)?.price.toLocaleString()}</b> <Text variant="smallPlus">{promotion.conditions?.find(i => i.price) ? CurrencySigns[(promotion.conditions?.find(i => i.price) as IPromoProviderItemCondition).currency as CurrencyCodes] : undefined}</Text></Text>
                                            )}
                                            {props.device?.group && (props.device?.attributes.group !== props.device?.name) && (
                                                <Text variant="medium" style={{ fontWeight: "bold", color: SharedColors.gray30 }}>{props.device?.attributes.group}</Text>
                                            )}
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </CommandBarButton>
                    ) || (
                            <>
                                <Stack.Item>
                                    <Stack horizontalAlign="center">
                                        <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{props.tradeUpDeviceTitle ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PURCHASED_DEVICE}</Text>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item style={{ height: 96 }}>
                                    <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                                        <Icon iconName={"MobileSelected"} style={{ fontSize: 64, color: SharedColors.gray10 }} />
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontalAlign="center">
                                        <Stack horizontalAlign="center" style={{ maxWidth: 220 }} tokens={{ childrenGap: 8 }}>
                                            <Stack.Item>
                                                <DefaultButton
                                                    title={props.config?.ui?.button ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.CHOOSE_PROMO}
                                                    className={targetOffersSuggestion}
                                                    styles={{
                                                        label: {
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            maxWidth: '200px',
                                                            lineHeight: '20px'
                                                        }
                                                    }}
                                                    onClick={handleShowDiscounts}
                                                >
                                                        {props.config?.ui?.button ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.CHOOSE_PROMO}
                                                </DefaultButton>
                                                {isCoachmarkVisible && props.config?.ui?.teaching && (
                                                    <Coachmark
                                                        target={`.${targetOffersSuggestion}`}
                                                        positioningContainerProps={{ directionalHint: DirectionalHint.bottomCenter, doNotLayer: false, }}
                                                        ariaAlertText="A coachmark has appeared"
                                                        ariaDescribedBy="coachmark-desc1"
                                                        ariaLabelledBy="coachmark-label1"
                                                        ariaDescribedByText="Press enter or alt + C to open the coachmark notification"
                                                        ariaLabelledByText="Coachmark notification"
                                                    >
                                                        <TeachingBubbleContent headline={props.config?.ui?.header}
                                                            hasCloseButton
                                                            closeButtonAriaLabel="Закрыть"
                                                            onDismiss={hideCoachmark}
                                                            ariaDescribedBy="example-description1"
                                                            ariaLabelledBy="example-label1"
                                                        >
                                                            {props.config?.ui?.teaching ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.THERE_IS_OPTION_TO_INCREASE_BUYOUT_PRICE}
                                                        </TeachingBubbleContent>
                                                    </Coachmark>
                                                )}
                                            </Stack.Item>
                                        </Stack>
                                    </Stack>
                                </Stack.Item>
                            </>
                        )}
                </Stack>
            </Stack.Item>

        </>
    )
}

const theme = getTheme();
