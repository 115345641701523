import { Stack, Pivot, PivotItem, MessageBar, MessageBarType, Sticky } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { portalApi } from '../../../../../core/api/api';
import { IContainer } from '../../../../../core/store';
import { strings } from '../../../../../localization/strings';
import { ContainerPivotBasedState } from './ContainerPivotBasedState';
import { StructureContainerHeader } from './StructureContainerHeader';

export type TContainerPivotState = "settings" | "information" | "services" | "help"

const getTabId = (itemKey: string) => {
    return `ContainerPivot_${itemKey}`;
};

export type TProps = {
    item: IContainer
    onEdit: () => void
    onDelete: () => void
}

export const StructureContainer: React.FC<TProps> = (props) => {

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TContainerPivotState>("information");
    const [item, setItem] = React.useState(props.item);
    const [error, setError] = React.useState<string>()
    const [infoFetching, { setTrue: startFetchInfo, setFalse: stopFetchInfo }] = useBoolean(false)
    const [infoWasSaved, setInfoWasSaved] = React.useState(false)

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TContainerPivotState);
    };

    React.useEffect(() => {
        setItem(props.item)
    }, [props.item])

    const updateContainerInformation = async (information: string) => {
        if (item.id) {
            startFetchInfo()
            setInfoWasSaved(false)
            const result = await portalApi.organization.containers.updateContainerInformation(item.id, information)
            if (result.successed) {
                setItem({ ...item, information: information })
                setInfoWasSaved(true)
            }
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            stopFetchInfo()
        }
    }

    const toggleUseContainerServiceState = async (containerId: string) => {
        const apiResult = await portalApi.organization.services.toggleUseContainerServices(containerId)
        if (apiResult.successed) {
            setItem({ ...item, turnOnServices: !item.turnOnServices })
        }
    }

    return (
        <Stack verticalFill>
            {error && (
                <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
            )}
            <StructureContainerHeader item={props.item} onEdit={props.onEdit} />
            <Stack verticalFill>
                <Sticky>
                    <Pivot style={{ padding: "0px 32px" }} aria-label="Container details" selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId}>
                        <PivotItem itemKey='information' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.CONTAINER_CARD} />
                        <PivotItem itemKey='services' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.SERVICES} />
                        <PivotItem itemKey='settings' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.SETTINGS} />
                        <PivotItem itemKey='help' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.PIVOTS.HELP.TITLE} />
                    </Pivot>
                </Sticky>
                <Stack.Item grow verticalFill>
                    <ContainerPivotBasedState state={selectedPivotKey}
                        item={item}
                        toggleUseServices={() => props.item.id && toggleUseContainerServiceState(props.item.id)}
                        setInformation={updateContainerInformation}
                        isFetching={infoFetching}
                        infoWasSaved={infoWasSaved}
                        resetInfoWasSaved={() => setInfoWasSaved(false)}
                        onDelete={props.onDelete}
                        updateState={(data) => setItem(data)}
                    />
                </Stack.Item>
            </Stack>
        </Stack>
    )
}
