import { Stack, TextField, PrimaryButton, ScrollablePane, Text } from '@fluentui/react';
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IQuestion } from '@piceasoft/core';
import { strings } from '../../../../../../../../../../localization/strings';
import { onRenderLabel } from '../../../../../../../../../renders/onRenderLabel';
import { SecondaryButton } from '../../../../../../../../buttons/SecondaryButton';

type TProps = {
    onConfirm: (question: IQuestion) => void
    question: IQuestion
}

export const ListQuestionForm: React.FC<TProps> = ({ question, onConfirm }) => {
    const { control, handleSubmit, reset, formState: { errors }, getValues, setValue } = useForm<IQuestion>({ defaultValues: { ...question, } });

    const onSubmit: SubmitHandler<IQuestion> = data => {
        console.log(data)
        onConfirm({ ...data, index: question.index })
    };

    React.useEffect(() => {
        reset(question, { keepValues: false })
    }, [question])

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack tokens={{ padding: 20, childrenGap: 16 }}>
                            <Stack.Item>
                                <Stack horizontal grow>
                                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <SecondaryButton iconProps={{ iconName: "Undo" }} onClick={() => reset(question)} />
                                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                            <Stack grow tokens={{ childrenGap: 16 }}>
                                <Controller
                                    control={control}
                                    name="title"
                                    rules={{ 
                                        validate: (value) => value.trim().length > 0 ? true : strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.TITLE.REQUIRED, 
                                        required: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.TITLE.REQUIRED 
                                    }}
                                    render={({ field }) =>
                                        <TextField {...field}
                                            required
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.TITLE.LABEL}
                                            title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.TITLE.TITLE}
                                            placeholder={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.TITLE.PLACEHOLDER}
                                            errorMessage={errors.title?.message}
                                        />
                                    }
                                />
                                <Controller
                                    name="text"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField {...field}
                                            multiline
                                            resizable={false}
                                            style={{ height: 100 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.TEXT.LABEL}
                                            title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.TEXT.TITLE}
                                            placeholder={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.TEXT.PLACEHOLDER}
                                            errorMessage={errors.text?.message}
                                        />
                                    }
                                />
                                {/* <Controller
                                    name="warning"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField {...field}
                                            multiline
                                            resizable={false}
                                            style={{ height: 100 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.WARNING.LABEL}
                                            title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.WARNING.TITLE}
                                            placeholder={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.WARNING.PLACEHOLDER}
                                            errorMessage={errors.warning?.message}
                                        />
                                    }
                                /> */}
                            </Stack>
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
            </Stack>
        </form>
    )
}
