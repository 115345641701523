import { AppThunkAction } from "..";
import { portalApi } from "../api/api";
import { endpoints } from "../api/endpoints";
import { consoleStyles } from "../scripts/console";
import { IOrganizationSettings } from "../store/typings/IOrganizationSettings";
import { IOrganizationSettingsAdditional } from "../store/typings/IOrganizationSettingsAdditional";
import { IOrganizationSettingsLocale } from "../store/typings/IOrganizationSettingsLocale";
import { IOrganizationSettingsOverview } from "../store/typings/IOrganizationSettingsOverview";
import { IPiceaCredentials } from "../store/typings/IPiceaCredentials";
import { IRequestResult } from "../store/typings/IResponseResult";

export interface RequestOrganizationSettingsAction { type: 'SETTINGS_ORGANIZATION_REQUEST_DATA' }
export interface ReceiveOrganizationSettingsStartUpdateAction { type: 'SETTINGS_ORGANIZATION_START_UPDATE' }
export interface ReceiveOrganizationSettingsStopUpdateAction { type: 'SETTINGS_ORGANIZATION_STOP_UPDATE' }
export interface ReceiveOrganizationSettingsErrorsAction { type: 'SETTINGS_ORGANIZATION_RECEIVE_ERRORS', errors: string[] }
export interface ReceiveOrganizationSettingsAction { type: 'SETTINGS_ORGANIZATION_RECEIVE_DATA', data: IOrganizationSettings }
export interface RefreshOrganizationSettingsPrivateKey { type: 'SETTINGS_ORGANIZATION_REFRESH_PRIVATE_KEY', privateKey?: string }
export interface RefreshOrganizationSettingsPiceasoftCredentials { type: 'SETTINGS_ORGANIZATION_REFRESH_PICEASOFT_CREDENTIALS', data?: IPiceaCredentials}

export type KnownAction = RequestOrganizationSettingsAction | ReceiveOrganizationSettingsAction | ReceiveOrganizationSettingsErrorsAction |
    ReceiveOrganizationSettingsStartUpdateAction | ReceiveOrganizationSettingsStopUpdateAction | RefreshOrganizationSettingsPrivateKey | RefreshOrganizationSettingsPiceasoftCredentials

export const actionCreators = {
    /** Запрос на получение данных настроек организации. */
    requestOrganizationSettings: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestOrganizationSettings()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            dispatch({ type: 'SETTINGS_ORGANIZATION_REQUEST_DATA' });
            fetch(endpoints.v1.organization.settings.getSettings)
                .then(response => response.json() as Promise<IRequestResult<IOrganizationSettings>>)
                .then(result => {
                    if (result.successed) {
                        dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_DATA', data: result.data as IOrganizationSettings });
                    } else {
                        console.log(result.errors);
                    }
                })
                .catch(response => {
                    console.log(response);
                });
        }
    },
    /** Обновление данных настроек организации - дополнительно. */
    updateOrganizationSettingsAdditional: (data: IOrganizationSettingsAdditional): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateOrganizationSettingsAdditional(data)", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true && appState.settings.isReady && appState.settings.organization) {
            dispatch({ type: 'SETTINGS_ORGANIZATION_START_UPDATE' });
            const result = await portalApi.settings.organization.updateSettingsAdditional(data);
            if (result.successed) {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_DATA', data: { ...appState.settings.organization, ...data } });
            } else {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_ERRORS', errors: result.errors?.map(e => e.description) ?? ["Unknown error"] });
            }
            setTimeout(() => {
                dispatch({ type: 'SETTINGS_ORGANIZATION_STOP_UPDATE' });
            }, 500)
        }
    },
    /** Обновление данных настроек организации - обзор. */
    updateOrganizationSettingsOverview: (data: IOrganizationSettingsOverview): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateOrganizationSettingsOverview(data)", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true && appState.settings.isReady && appState.settings.organization) {
            dispatch({ type: 'SETTINGS_ORGANIZATION_START_UPDATE' });
            const result = await portalApi.settings.organization.updateSettingsOverview(data);
            if (result.successed) {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_DATA', data: { ...appState.settings.organization, ...data } });
            } else {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_ERRORS', errors: result.errors?.map(e => e.description) ?? ["Unknown error"] });
            }
            setTimeout(() => {
                dispatch({ type: 'SETTINGS_ORGANIZATION_STOP_UPDATE' });
            }, 500)
        }
    },
    /** Обновление логотипа организации. */
    updateOrganizationSettingsLogo: (file: File, setResponse: (response: IRequestResult<any>) => void, setProgress?: (progress: number) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateOrganizationSettingsLogo(file)", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true && appState.settings.isReady && appState.settings.organization) {
            dispatch({ type: 'SETTINGS_ORGANIZATION_START_UPDATE' });
            const uploadResult = await portalApi.settings.organization.uploadFile('company-logo', file, setProgress);
            setResponse(uploadResult);
            if (uploadResult.successed) {
                const result = await portalApi.settings.organization.updateLogo(uploadResult.data as string);
                if (result.successed) {
                    dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_DATA', data: { ...appState.settings.organization, logo: uploadResult.data } });
                } else {
                    dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_ERRORS', errors: result.errors?.map(e => e.description) ?? ["Unknown error"] });
                }
            } else {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_ERRORS', errors: uploadResult.errors?.map(e => e.description) ?? ["Unknown error"] });
            }
            setTimeout(() => {
                dispatch({ type: 'SETTINGS_ORGANIZATION_STOP_UPDATE' });
            }, 500)
        }
    },
    /** Удаление логотипа организации. */
    deleteOrganizationSettingsLogo: (setResponse: (response: IRequestResult<any>) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC deleteOrganizationSettingsLogo()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true && appState.settings.isReady && appState.settings.organization) {
            dispatch({ type: 'SETTINGS_ORGANIZATION_START_UPDATE' });
            const result = await portalApi.settings.organization.deleteLogo();
            setResponse(result);
            if (result.successed) {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_DATA', data: { ...appState.settings.organization, logo: undefined } });
            } else {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_ERRORS', errors: result.errors?.map(e => e.description) ?? ["Unknown error"] });
            }
            setTimeout(() => {
                dispatch({ type: 'SETTINGS_ORGANIZATION_STOP_UPDATE' });
            }, 500)
        }
    },
    /** Обновление данных настроек организации - время и регион. */
    updateOrganizationSettingsLocale: (data: IOrganizationSettingsLocale): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateOrganizationSettingsLocale(data)", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true && appState.settings.isReady && appState.settings.organization) {
            dispatch({ type: 'SETTINGS_ORGANIZATION_START_UPDATE' });
            const result = await portalApi.settings.organization.updateSettingsLocale(data);
            if (result.successed) {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_DATA', data: { ...appState.settings.organization, ...data } });
            } else {
                dispatch({ type: 'SETTINGS_ORGANIZATION_RECEIVE_ERRORS', errors: result.errors?.map(e => e.description) ?? ["Unknown error"] });
            }
            setTimeout(() => {
                dispatch({ type: 'SETTINGS_ORGANIZATION_STOP_UPDATE' });
            }, 500)
        }
    },
    /** Запись обновленного закрытого ключа организации в стейт. */
    refreshOrganizationPrivateKey: (key?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC refreshOrganizationPrivateKey(key)", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true && appState.settings.isReady && appState.settings.organization) {
            dispatch({ type: 'SETTINGS_ORGANIZATION_REFRESH_PRIVATE_KEY', privateKey: key });
        }
    },
    refreshOrganizationPiceasoftCredentials: (data?: IPiceaCredentials): AppThunkAction<KnownAction> => async(dispatch, getState) => {
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true && appState.settings.isReady && appState.settings.organization) {
            dispatch({ type: 'SETTINGS_ORGANIZATION_REFRESH_PICEASOFT_CREDENTIALS', data: data});
        }
    }
};
