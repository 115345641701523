import { css, FontIcon, FontWeights, getTheme, IconButton, IStackStyles, ITooltipHostStyles, mergeStyleSets, PrimaryButton, Separator, Stack, Text, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { strings } from '../../../../../localization/strings';
import { Help } from '../../../help/Help';
import { IDependencyRule, Inspections } from "@piceasoft/core"

const theme = getTheme();

export type InspectionStatePropsType = {
    inspection: Inspections
    icon: string
    // data: IInspectionState
    // grades?: IGrade[]
    allowRestart?: boolean
    autoRun?: boolean
    title?: string
    dependency?: Inspections[]
    skips?: IDependencyRule[]
    executeButtonText?: string;
    repeatButtonText?: string;
}

export type TInspectionSurfaceProps = {
    headerText: string
    isOpen: boolean
    onClose: () => void
}

export type InspectionDispatchPropsType = {
    onStart: any
    onRestart?: any
}

type InspectionPropsType = InspectionStatePropsType & InspectionDispatchPropsType

const Inspection: React.FunctionComponent<InspectionPropsType> = (props) => {

    // const [blocked, setBlocked] = React.useState(false);

    // const assessment = useSelector((s: IStore) => s.process.current?.transaction.assessment);
    // const identity = useSelector((s: IStore) => s.process.current?.transaction.identity);
    // const transaction = useSelector((s: IStore) => s.process.current?.transaction);
    // const config = useSelector((s: IStore) => s.process.current?.workflow.assessment);
    // const offer = useSelector((s: IStore) => s.process.current?.offers);

    const [hideRestartDialog, { setTrue: turn_off_restart_dialog, setFalse: turn_on_restart_dialog }] = useBoolean(true);

    const [dependencyInspections, setDependencyInspections] = React.useState<string[]>([])

    const dispatch = useDispatch()

    // if (!transaction) return null;

    // const start_handler = () => {
    //     dispatch(actionCreators.addEvent(props.title ?? inspectionDescriber(props.inspection), strings.EVENTS.STATUS.START));
    //     dispatch(actionCreators.updateCurrentTransaction([
    //         { key: TransactionKeys.STARTING_INSPECTION, value: props.inspection }
    //     ]));
    //     props.onStart();
    // }

    // const restarting_handler = () => {
    //     if (props.data.status !== InspectionStatuses.Done) {
    //         restart_handler();
    //     } else {
    //         turn_on_restart_dialog();
    //     }
    // }

    // const restart_handler = () => {
    //     turn_off_restart_dialog();
    //     dispatch(actionCreators.addEvent(props.title ?? inspectionDescriber(props.inspection), strings.EVENTS.STATUS.RESTART));
    //     dispatch(actionCreators.receiveInspectionState(props.inspection, {
    //         status: InspectionStatuses.New
    //     }));
    //     props.onRestart();
    // }

    // React.useEffect(() => {
    //     let blocked = false;
    //     let newDependencyInspections: string[] = []
    //     if (props.dependency && (props.data.status !== InspectionStatuses.Skip)) {
    //         for (const i of props.dependency) {
    //             if (transaction.assessment.inspections[i].status === InspectionStatuses.Done) continue
    //             newDependencyInspections.push(config?.inspections[i]?.ui?.title ?? inspectionDescriber(i));
    //             if (assessment?.inspections[i].status !== InspectionStatuses.Done &&
    //                 assessment?.inspections[i].status !== InspectionStatuses.Skip) {
    //                 blocked = true;
    //             }
    //         }
    //         setDependencyInspections(newDependencyInspections)
    //     }

    //     if (props.data.status !== InspectionStatuses.Done) {
    //         // Если данная инспекция должна быть пропущена в связи с настройкой прайс-листа
    //         if (offer?.skip?.includes(props.inspection)) {
    //             dispatch(actionCreators.changeInspectionStatus(props.inspection, InspectionStatuses.Skip));
    //             return;
    //         }
    //         // Если одно из правил пропуска инспекции выполняется, то устанавливаем статус инспекции для пропуска
    //         if (props.skips && props.skips.some((s) => checkoutSkip(transaction, s))) {
    //             dispatch(actionCreators.changeInspectionStatus(props.inspection, InspectionStatuses.Skip));
    //             return;
    //         }
    //         // Если инспекция была ранее пропущена, то восстанавливаем предыдущий статус, либо при его отсутствии огого переводим в статус новой
    //         if (props.data.status === InspectionStatuses.Skip) {
    //             dispatch(actionCreators.changeInspectionStatus(props.inspection, props.data.preSkipStatus ?? InspectionStatuses.New));
    //             // dispatch(actionCreators.clearInspectionWarning(props.inspection));
    //         }   
    //     }
    //     setBlocked(blocked);
    // }, [props.data.status, assessment?.grade, identity?.device.manufacturer, identity?.device.name,
    // assessment?.inspections[Inspections.Interview], assessment?.inspections[Inspections.Photographic],
    // assessment?.inspections[Inspections.Diagnostics], assessment?.inspections[Inspections.Software]
    // ]);

    const statusColor = SharedColors.red10
    const statusIcon = "AlertSolid"

    // switch (props.data.status) {
    //     case InspectionStatuses.New: statusColor = SharedColors.red10; statusIcon = "AlertSolid"; break;
    //     case InspectionStatuses.Run: statusColor = SharedColors.orangeYellow10; statusIcon = "WarningSolid"; break;
    //     case InspectionStatuses.Done: statusColor = SharedColors.green10; statusIcon = "CompletedSolid"; break;
    //     case InspectionStatuses.Fail: statusColor = SharedColors.red10; statusIcon = "AlertSolid"; break;
    //     case InspectionStatuses.Skip: statusColor = SharedColors.gray10; statusIcon = "CompletedSolid"; break;
    //     case InspectionStatuses.Error: statusColor = SharedColors.red10; statusIcon = "AlertSolid"; break;
    //     case InspectionStatuses.Lock: statusColor = SharedColors.orangeYellow10; statusIcon = "WarningSolid"; break;

    //     default: statusColor = SharedColors.red10; break;
    // }

    // let grade = props.grades?.find(i => i.code === props.data.grade)?.name;

    // const commandBarVisibility = props.data.status === InspectionStatuses.Run || props.data.status === InspectionStatuses.Lock ? "collapse" : "visible";
    // const commandBarHeight = props.data.status === InspectionStatuses.Run || props.data.status === InspectionStatuses.Lock ? 0 : 48;
    const commandBarHeight = 48;
    // const opacity = blocked || props.data.status === InspectionStatuses.Skip ? 0.4 : 1;
    // const display = config?.hideDependency === true ? blocked || props.data.status === InspectionStatuses.Skip ? "none" : "flex" : "flex";

    const tooltipRestartId = useId('tooltip-restart');

    const [showHelp, { toggle: toggleShowHelp }] = useBoolean(false)

    // React.useEffect(() => {
    //     if (props.autoRun === true && props.data.status === InspectionStatuses.New) {
    //         setTimeout(() => {
    //             start_handler();
    //         }, 500);
    //     }
    // }, []);

    let urlHelp: string = "";
    // switch (props.inspection) {
    //     case Inspections.Interview: urlHelp = "/help/inspections/interview/index.htm"; break;
    //     case Inspections.Photographic: urlHelp = "/help/inspections/photographic/index.htm"; break;
    //     case Inspections.Diagnostics:
    //         switch (identity?.method) {
    //             case IdentificationMethods.PiceaOne: urlHelp = "/help/otf/diag/index.htm"; break;
    //             case IdentificationMethods.PiceaUsb: urlHelp = "/help/usb/diag/index.htm"; break;
    //         }
    //         break;
    //     case Inspections.Software:
    //         switch (identity?.method) {
    //             case IdentificationMethods.PiceaOne: urlHelp = "/help/otf/verify/index.htm"; break;
    //             case IdentificationMethods.PiceaUsb: urlHelp = "/help/usb/verify/index.htm"; break;
    //         }
    //         break;
    // }

    // if (display === "none") return null;

    return (
        <Stack verticalFill style={{ position: "relative" }} styles={mainStyles}>
            <Stack grow={1} verticalFill styles={containerStyles} style={{ borderTopColor: statusColor, 
                // opacity: opacity 
                }}>
                <Stack.Item>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
                        <FontIcon iconName={statusIcon} style={{ fontSize: 24, height: 24, width: 24, color: statusColor }} 
                        // onClick={(e) => {
                        //     if (e.altKey && e.shiftKey) {
                        //         dispatch(actionCreators.changeInspectionStatus(props.inspection, InspectionStatuses.Done));
                        //     }
                        // }} 
                        />
                        <Stack.Item grow={1}>
                            <TooltipHost 
                                overflowMode={TooltipOverflowMode.Self}
                                styles={tooltipStyles}
                                hostClassName={css(styles.title)}
                                content={props.title}
                            >
                                {props.title}
                            </TooltipHost>                            
                        </Stack.Item>
                        {urlHelp && (
                            <Stack.Item>
                                <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'UnknownSolid' }} ariaLabel="Show help" onClick={toggleShowHelp}>
                                    <Help url={urlHelp} show={showHelp} hide={toggleShowHelp} />
                                </IconButton>
                            </Stack.Item>
                        )}
                    </Stack>
                    <Separator />
                </Stack.Item>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    {/* {props.data.status === InspectionStatuses.Skip && (
                        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                            <Text style={{ color: SharedColors.gray40 }}>{strings.CONSTRUCTOR.INSPECTION.NOT_REQUIRED}</Text>
                        </Stack>
                    ) || (
                        <> */}
                            {props.children}
                        {/* </>
                    )} */}
                </Stack.Item>
                {/* {props.data.warning?.code === 200 && (
                    <Stack.Item>
                        <MessageBar messageBarType={MessageBarType.success}>{props.data.warning?.message}</MessageBar>
                    </Stack.Item>
                )} */}
                <Stack.Item>
                    <Stack horizontal verticalAlign="end" styles={{ root: { height: commandBarHeight, 
                        // visibility: commandBarVisibility 
                        } }}>
                        <Stack.Item grow>
                            {/* {props.data.status === InspectionStatuses.New && ( */}
                                <Text style={{ color: SharedColors.gray10 }}>{strings.CONSTRUCTOR.INSPECTION.NOT_COMPLETED}</Text>
                            {/* )} */}
                            {/* {props.data.status === InspectionStatuses.Fail && (
                                <Text style={{ color: SharedColors.red10 }} variant="large">{strings.CONSTRUCTOR.INSPECTION.NOT_PASSED}</Text>
                            )}
                            {props.data.status === InspectionStatuses.Done && (
                                <Text style={{ color: SharedColors.cyanBlue10 }} variant="large">{grade ?? strings.CONSTRUCTOR.INSPECTION.PASSED}</Text>
                            )}
                            {props.data.status === InspectionStatuses.Skip && (
                                <Text style={{ color: SharedColors.cyanBlue10 }} variant="large">{strings.CONSTRUCTOR.INSPECTION.SKIP}</Text>
                            )}
                            {props.data.status === InspectionStatuses.Error && (
                                <Text style={{ color: SharedColors.gray10 }}>{strings.CONSTRUCTOR.INSPECTION.ERROR}</Text>
                            )} */}
                        </Stack.Item>
                        {/* {props.data.warning?.code !== 200 && ( */}
                            <Stack.Item>
                                {/* {props.data.status === InspectionStatuses.New && ( */}
                                    <TooltipHost content={props.executeButtonText && props.executeButtonText.length > 10 ? props.executeButtonText : ""} id={tooltipRestartId} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                                        <PrimaryButton 
                                        // onClick={start_handler} 
                                        // disabled={props.data.warning && true} 
                                        iconProps={{ iconName: "PlayResume" }} styles={{ root: { paddingLeft: 8 } }}>{( !props.executeButtonText ? undefined : props.executeButtonText.length > 10 ? `${props.executeButtonText.substring(0, 10)}...` : props.executeButtonText  ) ?? strings.BUTTONS.TEXT.EXECUTE}</PrimaryButton>
                                    </TooltipHost>
                                {/* )} */}
                                {/* {(props.data.status === InspectionStatuses.Done || props.data.status === InspectionStatuses.Fail || props.data.status === InspectionStatuses.Error) && props.allowRestart && (
                                    <>
                                        <TooltipHost content={props.data.warning && true && props.data.status === InspectionStatuses.Done ? props.data.warning?.message : strings.CONSTRUCTOR.INSPECTION.CHECK_AGAIN} id={tooltipRestartId} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                                            <DefaultButton 
                                            // onClick={restarting_handler} 
                                            disabled={props.data.warning && (props.data.status === InspectionStatuses.Fail || props.data.status === InspectionStatuses.Done)} iconProps={{ iconName: "Rerun" }} styles={{ root: { paddingLeft: 8 } }}>{strings.BUTTONS.TEXT.REPEAT}</DefaultButton>
                                        </TooltipHost>
                                        <Dialog hidden={hideRestartDialog} minWidth={320} maxWidth={360}
                                            modalProps={{ isBlocking: false }}
                                            onDismiss={turn_off_restart_dialog}
                                            dialogContentProps={{
                                                title: strings.CONSTRUCTOR.INSPECTION.DIALOG_TITLE,
                                                subText: strings.CONSTRUCTOR.INSPECTION.DIALOG_TEXT,
                                                type: DialogType.largeHeader,
                                            }}
                                        >
                                            <DialogFooter>
                                                <PrimaryButton 
                                                // onClick={restart_handler} 
                                                text={strings.BUTTONS.TOGGLE_YES} />
                                                <DefaultButton onClick={turn_off_restart_dialog} text={strings.BUTTONS.TOGGLE_NO} />
                                            </DialogFooter>
                                        </Dialog>
                                    </>
                                )} */}
                            </Stack.Item>
                            {/* )} */}
                    </Stack>
                </Stack.Item>
            </Stack>
            {/* {blocked && props.data.status !== InspectionStatuses.Skip && (
                <Stack verticalAlign="center" horizontalAlign="center" style={disabledStyles} tokens={{ childrenGap: 16 }}>
                    <FontIcon iconName="Lock" style={{ fontSize: 96, height: 96, width: 96, color: SharedColors.gray40 }} />
                    <Text style={{ color: SharedColors.gray40 }}>{strings.CONSTRUCTOR.INSPECTIONS.INSPECTION.REQUIRED}</Text>
                    <Stack>
                        {
                            dependencyInspections.map(i => {
                                return (
                                    <Text style={{ fontWeight: "bolder", color: SharedColors.cyanBlue10 }}>{i}</Text>
                                )
                            })
                        }
                    </Stack>
                </Stack>
            )} */}
        </Stack>
    )
}

export default Inspection

const mainStyles: IStackStyles = { root: { width: 320, maxWidth: 320, minHeight: 410, height: 410 } };

const styles = mergeStyleSets({
    title: [
        theme.fonts.large,
        {
            margin: 0,
            fontWeight: FontWeights.bold,
            color: SharedColors.gray40,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 200,
        }
    ],
    icon: [
        { fontSize: 24, height: 24, width: 24, color: SharedColors.gray10 }
    ]
})

const containerStyles: IStackStyles = {
    root: {
        padding: 16,
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4,
        borderTopWidth: 4,
        borderTopStyle: "solid"
    }
};

const disabledStyles: React.CSSProperties = {
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    textAlign: "center",
    opacity: 0.8
};

const iconButtonStyles = {
    root: {
        color: theme.palette.blue
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const tooltipStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'block' }
}
