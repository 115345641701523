import { Stack, Text, IconButton, Separator, Toggle, Icon, SharedColors, getTheme } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import { Control, Controller } from "react-hook-form"
import { Section } from "../../../../components/shared/decorations/Section"
import { IBranding } from "../../../../core/store/typings/IBranding"
import { strings } from "../../../../localization/strings"
import { TMainColor } from "../BrandingDetails"
import { BrandingSelectColorComponent } from "../BrandingSelectColorComponent"
import { IBrandingConfig } from "@piceasoft/core";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

type Tprops = {
    mainColors: TMainColor[]
    brandingItem?: IBranding
    control: Control<IBranding, object>
    defaultDarkConfig: IBrandingConfig,
    getValues: UseFormGetValues<IBranding>,
    setValue: UseFormSetValue<IBranding>,
}
export const BrandingMobileTopBar: React.FC<Tprops> = (props) => {
    const [isSectionCollapsed, { toggle: toggleSectionCollapse }] = useBoolean(false)
    const isDefault = (): boolean =>{
        var branding = props.getValues();
        return branding?.darkConfig?.mobileTopBar.background == props.defaultDarkConfig.mobileTopBar.background
        && branding?.darkConfig?.mobileTopBar.color == props.defaultDarkConfig.mobileTopBar.color;
    }
    const [isCustomeDark, { toggle: changeCustomeDark }] = useBoolean(!isDefault())
    const toggleCustomeDark = () =>{
        changeCustomeDark();
        if(isCustomeDark){
            props.setValue('darkConfig.mobileTopBar.background', props.defaultDarkConfig.mobileTopBar.background);
            props.setValue('darkConfig.mobileTopBar.color', props.defaultDarkConfig.mobileTopBar.color);
        }
    }
    const onRenderHeader = (): React.ReactNode => {
        return (
            <Stack grow>
                <Stack
                    onClick={toggleSectionCollapse}
                    horizontal grow verticalAlign="center"
                    horizontalAlign="space-between"
                    tokens={{ padding: "10px 20px 0px 20px" }}
                    style={{ paddingBottom: isSectionCollapsed ? "10px" : "0", cursor: 'pointer' }}
                >
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.BRANDING.BRANDING_MOBILE.TITLE}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton iconProps={{ iconName: isSectionCollapsed ? "ChevronDown" : "ChevronUp" }} />
                    </Stack.Item>
                </Stack>
                {!isSectionCollapsed && (
                    <Separator style={{ padding: 0 }} />
                )}
            </Stack>
        )
    }

    return (
        <Stack>
            <Section onRenderHeader={onRenderHeader} headerOnly={isSectionCollapsed}>
                <Stack>
                    {!isSectionCollapsed && (
                        <>
                        <Stack.Item>
                             <Text style={{ fontSize: 16,  fontWeight: 600 }}>{strings.BRANDING.DARK.LIGHT_MODE}</Text>
                             <Separator/>
                         </Stack.Item>                       
                        
                        <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap >
                            <Stack.Item >
                                <Controller
                                    name="config.mobileTopBar.background"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            onChangeField={field.onChange}
                                            mainColors={props.mainColors}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_MOBILE.BACKGROUND_COLOR} />
                                    } />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.mobileTopBar.color"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            onChangeField={field.onChange}
                                            mainColors={props.mainColors}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_MOBILE.CONTENT_COLOR} />
                                    } />
                            </Stack.Item>
                        </Stack>
                        </Stack.Item>
                        <Stack.Item style={{paddingTop: 13}}>
                        <Stack.Item  style={{display: "inline-block"}}      >
                                <Toggle                                  
                                        checked={isCustomeDark}
                                        onChange={toggleCustomeDark }
                                    />
                                </Stack.Item>  
                                <Stack.Item  style={{display: "inline-block"}}      >
                                <Text style={{ fontSize: 16,  fontWeight: 600, paddingLeft: 5 }}>{strings.BRANDING.DARK.CUSTOM_DARK_MODE}</Text>
                                <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20, paddingLeft: 10, paddingRight: 10 }} 
                                title={strings.BRANDING.DARK.INFO} ariaLabel="Info" styles={iconButtonStyles} />
                                </Stack.Item>  
                             <Separator/>
                         </Stack.Item>   
                        <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap >
                            <Stack.Item >
                                <Controller
                                    name="darkConfig.mobileTopBar.background"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            onChangeField={field.onChange}
                                            disabled={!isCustomeDark} 
                                            mainColors={props.mainColors}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_MOBILE.BACKGROUND_COLOR} />
                                    } />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="darkConfig.mobileTopBar.color"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            onChangeField={field.onChange}
                                            disabled={!isCustomeDark} 
                                            mainColors={props.mainColors}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_MOBILE.CONTENT_COLOR} />
                                    } />
                            </Stack.Item>
                        </Stack>
                        </Stack.Item>
                        </>
                    )}
                </Stack>
            </Section>
        </Stack>
    )
}
const theme = getTheme()
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};