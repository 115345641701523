import { getTheme, IconButton, PrimaryButton, Stack, TextField, Text, Link } from "@fluentui/react"
import React from "react";
import { Control, Controller, FieldErrorsImpl, useFieldArray, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { IPromoProviderConfig } from "@piceasoft/core";
import { strings } from "../../../localization/strings";
import { onRenderLabel } from "../../renders/onRenderLabel";

type TProps = {
    control: Control<IPromoProviderConfig, object>
    setValue: UseFormSetValue<IPromoProviderConfig>
    getValues: UseFormGetValues<IPromoProviderConfig>
    errors?: Partial<FieldErrorsImpl<{
        gradesMap: {
            workflowGrade?: string
            providerGrade?: string
        }[];
    }>>
}

export const PromoProviderFormGradesMapArray: React.FC<TProps> = ({ control, getValues, errors }) => {

    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: "gradesMap"
    });

    return (
        <Stack grow tokens={{ childrenGap: 16 }}>
            <Stack tokens={{ childrenGap: 4 }}>
                <Text>{getValues()?.gradesMap?.length > 0 ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.GRADE_MAPPING : strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.GRADE_MAPPING_NOT_CONFIGURED}</Text>
                {(!getValues()?.gradesMap || getValues()?.gradesMap?.length === 0) && (<Link onClick={() => append({ providerGrade: '', workflowGrade: '' })}>{strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.GRADE_MAPPING_ADD_TEXT}</Link>)}
            </Stack>
            <Stack verticalFill>
                <Stack grow style={{ padding: "0px 0px" }} tokens={{ childrenGap: 16 }}>
                    <Stack.Item grow>
                        <Stack grow tokens={{ padding: "0px 0px", childrenGap: 16 }}>
                            {fields.map((field, index) => <Stack.Item key={field.id} grow>
                                <Stack verticalFill grow horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                                    <Stack grow style={{ paddingTop: 2 }} horizontal verticalAlign='start' tokens={{ childrenGap: 24 }}>
                                        <Stack tokens={{ childrenGap: 8 }}>
                                            <Controller
                                                control={control}
                                                name={`gradesMap.${index}.workflowGrade`}
                                                rules={{ required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.WORKFLOW_GRADE_REQUIRED }}
                                                render={({ field }) =>
                                                    <TextField
                                                        required={index === 0 ? true : false}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        onBlur={field.onBlur}
                                                        onRenderLabel={index === 0 ? onRenderLabel : undefined}
                                                        label={index === 0 ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.WORKFLOW_GRADE_LABEL : undefined}
                                                        title={index === 0 ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.WORKFLOW_GRADE_TITLE : undefined}
                                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.WORKFLOW_GRADE_PLACEHOLDER}
                                                        errorMessage={(errors?.gradesMap && errors.gradesMap[index]) ? errors.gradesMap[index]?.workflowGrade?.message : undefined}
                                                    />
                                                }
                                            />
                                        </Stack>
                                        <Controller
                                            control={control}
                                            name={`gradesMap.${index}.providerGrade`}
                                            rules={{
                                                required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROVIDER_GRADE_REQUIRED,
                                            }}
                                            render={({ field }) =>
                                                <TextField
                                                    required={index === 0 ? true : false}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={index === 0 ? onRenderLabel : undefined}
                                                    label={index === 0 ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROVIDER_GRADE_LABEL : undefined}
                                                    title={index === 0 ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROVIDER_GRADE_TITLE : undefined}
                                                    placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROVIDER_GRADE_PLACEHOLDER}
                                                    errorMessage={(errors?.gradesMap && errors.gradesMap[index]) ? errors.gradesMap[index]?.providerGrade?.message : undefined}
                                                />
                                            }
                                        />
                                    </Stack>
                                    <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }} style={{ minWidth: 32 }}>
                                        {index > 0 && <IconButton
                                            iconProps={{ iconName: 'Up' }}
                                            onClick={() => swap(index - 1, index)}
                                        />}
                                    </Stack>
                                    <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }} style={{ minWidth: 32 }}>
                                        <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => remove(index)} />
                                    </Stack>
                                </Stack>
                            </Stack.Item>)}
                            {getValues()?.gradesMap?.length > 0 && (
                                <Stack tokens={{ padding: "12px 0px", childrenGap: 16 }}>
                                    <Stack.Item>
                                        <PrimaryButton onClick={() => append({ providerGrade: '', workflowGrade: '' })}>{strings.BUTTONS.TEXT.ADD}</PrimaryButton>
                                    </Stack.Item>
                                </Stack>
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();