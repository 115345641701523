import { Stack } from "@fluentui/react"
import { IStageConfig, IStageConfigUI } from "@piceasoft/core";
import { strings } from "../../../../../../localization/strings"
import { StageConfirmationPreviewUIKit } from "../../preview/StageConfirmationPreviewUIKit"
import * as UIKit from "@piceasoft/picea-react-ui-kit"

type TProps = {
    config: IStageConfig<IStageConfigUI>
}


export const ConfirmationPreviewUIKit: React.FC<TProps> = ({ config }) => {

    return (
        <StageConfirmationPreviewUIKit config={config}
            defaultConfirmText={strings.BUTTONS.TEXT.CONFIRM}
            defaultCancelText={strings.BUTTONS.TEXT.CANCEL}
            defaultheader={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONFIRMATION}
        >
            <Stack tokens={{ childrenGap: 32 }}>
                <Stack.Item>
                    <UIKit.Text variant="medium" text={config.ui?.confirmation?.information ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONFIRM_STAGE_FINISH }></UIKit.Text>
                </Stack.Item>
            </Stack>
        </StageConfirmationPreviewUIKit>
    )
}