import * as React from 'react';
import { SharedColors } from '@fluentui/theme';
import { FontIcon, ITooltipHostStyles, Stack, TooltipHost, TooltipOverflowMode, css, mergeStyleSets } from '@fluentui/react';
import { strings } from '../../../../../../../localization/strings';
import { IInspectionConfig, IInspectionConfigUI } from '@piceasoft/core';

type TStateProps = {
    config: IInspectionConfig<IInspectionConfigUI>
    icon?: string
}

export const DataCollectionState: React.FC<TStateProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 24 }}>
            <Stack.Item style={{ color: SharedColors.gray40 }}>
                <TooltipHost
                    overflowMode={TooltipOverflowMode.Self}
                    content={props.config.ui?.description ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.FILL_FORM_DATA}
                    styles={tooltipStyles}
                    hostClassName={css(styles.title)}
                >
                    {props.config.ui?.description ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.FILL_FORM_DATA}
                </TooltipHost>   
            </Stack.Item>
            <Stack.Item>
                <Stack>
                    <Stack horizontalAlign="center">
                        <FontIcon iconName={props.icon ?? "PageEdit"} style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray20 }} />
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const styles = mergeStyleSets({
    title: [
        {
            overflow: 'hidden',
            overflowWrap: "break-word",
            maxHeight: 60,
        }
    ]
}) 

const tooltipStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'block' }
}