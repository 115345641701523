import { strings } from "../../../localization/strings"

export enum AccountRoles {
    Manager = "Manager",
    Master = "Master",
    Operator = "Operator",
    Photoreport = "Photoreport",
    Service = "Service",
    Statistic = "Statistic"
}

export const getAccountRoleTitle = (role: AccountRoles): string => {
    switch (role) {
        case AccountRoles.Manager: return strings.ACCOUNT_ROLES.MANAGER
        case AccountRoles.Master: return strings.ACCOUNT_ROLES.MASTER
        case AccountRoles.Operator: return strings.ACCOUNT_ROLES.OPERATOR
        case AccountRoles.Photoreport: return strings.ACCOUNT_ROLES.PHOTOREPORT
        case AccountRoles.Service: return strings.ACCOUNT_ROLES.SERVICE
        case AccountRoles.Statistic: return strings.ACCOUNT_ROLES.STATISTIC
        default: return role
    }
}
