import { getTheme, Stack, Text, Image, ImageFit} from "@fluentui/react";

type TServiceProps = {
    title?: string
    imageSrc?: string
}

export const SelfServicePromotion: React.FC<TServiceProps> = (props) => {
    return (
        <Stack.Item>
            <Stack horizontal>
                <Stack horizontal verticalAlign='center' verticalFill style={{ width: 600 }} tokens={{ childrenGap: 12 }} >
                    <Stack.Item>
                        <Stack verticalAlign="center"
                            horizontalAlign="center"
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: theme.effects.roundedCorner6,
                            }}>
                            {props.imageSrc && (
                                <Stack horizontalAlign='center'>
                                    <Image src={props.imageSrc} width={40} height={40} imageFit={ImageFit.contain} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                </Stack>
                            )}
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow style = {{overflowX:'auto'}}>
                        <Stack horizontal tokens={{ childrenGap: 12 }}>
                            <Stack>
                                <Stack.Item>
                                    <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{props.title}</Text>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack.Item>
    )
}

const theme = getTheme();