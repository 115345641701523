import * as React from 'react';
import usbTight from '../../../../../../assets/usb_tight.gif';
import { DeviceAttributes, PiceaConnectionStatuses } from '@piceasoft/core';
import { IDevice, IIdentificationPiceaUsbConfig } from '@piceasoft/core';
import { strings } from '../../../../../../localization/strings';
import { IPicea } from '../typings/IPicea';
import * as UIKit from '@piceasoft/picea-react-ui-kit';

type TProps = {
    config?: IIdentificationPiceaUsbConfig
    picea: IPicea,
    device?: IDevice
    warning?: string
}

const PiceaUsbUIKit: React.FC<TProps> = ({ picea, device, warning, ...props }) => {

    let image = "";
    let description = "";
    let alertStyle : 'error' | 'info' | 'success' | 'warning' | undefined = undefined;
    let boldDescription = "";
    let showSpinner=false;
    let button=undefined;

    if( picea === undefined) {
        return (
            <UIKit.ConnectDeviceCard 
                imageUrl=""
                icon={props.config?.ui?.icon}
                title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_VIA_CABLE}
                description={strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_NO_ACCESS_TO_SERVICE}
                boldDescription="Picea® Online Services"
                alertStyle="warning"
            />
        );
    }

    if( picea.status === "available") {
        if( picea.connections.usb.status === PiceaConnectionStatuses.Connected )
        {
            if( device ) {
                image = device?.attributes[DeviceAttributes.Image];
            }
            else {
                image = usbTight;
            }
            description = props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAUSB_DEVICE_CONNECT_INFORMATION;

            if( warning && warning.length) {
                alertStyle="warning";
                boldDescription="Picea® Online Services";
                description=warning;
            }
        }
        else if( picea.connections.usb.status === PiceaConnectionStatuses.Connecting ) {
            alertStyle="info";
            boldDescription="Picea® Online Services";
            description=strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CONNECTION_TO_SERVICES;
            showSpinner=true;
        }
        else if( picea.connections.usb.status === PiceaConnectionStatuses.Disconnected) {
            image = usbTight;
            alertStyle="error";
            boldDescription="Picea® Online Services";
            description=strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CANT_CONNECT + " Picea® Online Services";
            button={
                text: strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_REPEAT,
                onClickHandler: () => {}
            };
        }
        else if(picea.connections.usb.status === PiceaConnectionStatuses.Failed) {
            image = usbTight;
            alertStyle="error";
            boldDescription="Picea® Online Services";
            description=picea.connections.usb.error?.message ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.MESSAGE_UNKNOWN_ERROR;
            button={
                text: strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_REPEAT,
                onClickHandler: () => {}
            };
        }
    }
    else if( picea.status === "fail") {
        image = usbTight;
        alertStyle="error";
        boldDescription="Picea® Online Services";
        description=picea.error?.message ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.MESSAGE_UNKNOWN_ERROR;
        button={
            text: strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_REPEAT,
            onClickHandler: () => {}
        };
    }
    else if( picea.status === "loading") {
        showSpinner=true;
        description=strings.SPINNERS.MODULE_LOADING;
    }

    return (
        <UIKit.ConnectDeviceCard 
            imageUrl={image}
            icon={props.config?.ui?.icon}
            title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_VIA_CABLE}
            description={description}
            showSpinner={showSpinner}
            boldDescription={boldDescription}
            button={button}
            alertStyle={alertStyle}
        />
    );
}

export default PiceaUsbUIKit
