import { Stack, Text, getTheme } from "@fluentui/react"
import React from "react"
import { ComingSoon } from "../../../components/shared/comingSoon/ComingSoon";
import { strings } from "../../../localization/strings";

export type TOrganizationSettingsAuthorizationBasicTabProps = {
}

export const SettingsAuthorizationBasicTab: React.FC<TOrganizationSettingsAuthorizationBasicTabProps> = props => {
    return (
        <Stack grow verticalFill tokens={{ padding: 24, childrenGap: 16 }}>
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack style={{ width: 360 }} verticalFill tokens={{ childrenGap: 16 }} >
                        <Stack tokens={{ childrenGap: 16 }} >
                            <Stack.Item>
                                <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.ORGANIZATION.SETTINGS.AUTHORIZATION.BASIC.TITLE}</Text>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack.Item>
            <Stack grow verticalFill horizontalAlign="center" verticalAlign="center" >
                <ComingSoon />
            </Stack>
        </Stack>
    )
}

const theme = getTheme();

