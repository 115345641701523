import { Stack, SharedColors, AnimationStyles, Text } from "@fluentui/react"
import { IDevice, IPreOfferConfig } from '@piceasoft/core';
import { strings } from "../../../../../../localization/strings"
import { StagePreview } from "../../preview/StagePreview"
import { CommonOfferPreview } from "../../commonOffer/preview/CommonOfferPreview"
import { ProcessStages, ICommonOfferConfig } from "@piceasoft/core"

type TProps = {
    device: IDevice
    config: IPreOfferConfig
    commonConfig: ICommonOfferConfig
    selectedPivot?: string
}

export const PreOfferPreview: React.FC<TProps> = ({ config, commonConfig, ...props }) => {
    return (
        <StagePreview config={config}
            stage={ProcessStages.PreOffer}
            defaultNextText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONTINUE}
            defaultCancelText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CANCEL}
            defaultStageTitle={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.PRE_OFFER}
        >
            <Stack tokens={{ childrenGap: 16, padding: "0 0 20px 0" }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }} >
                <Stack.Item>
                    <CommonOfferPreview config={commonConfig} device={props.device} />
                    {(!props.selectedPivot || props.selectedPivot === "hasOffer") && (
                        <Stack tokens={{ childrenGap: 18 }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }}>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Text variant="xLarge" style={{ fontWeight: "bold", color: SharedColors.gray40 }}>{config?.prices?.ui?.header ?? strings.CONSTRUCTOR.STAGES.PRE_OFFER.OFFERS_SUBTITLE}</Text>
                                <Stack tokens={{ maxWidth: 600 }}>
                                    <div style={{ textAlign: "center" }}>
                                        <Text variant="small" style={{ color: SharedColors.gray40 }}>
                                            <div dangerouslySetInnerHTML={{ __html: config?.prices?.ui?.information ?? strings.CONSTRUCTOR.STAGES.PRE_OFFER.OFFERS_INFORMATION }}></div>
                                        </Text>
                                    </div>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                    {props.selectedPivot === 'noOffer' && (
                        <Stack tokens={{ childrenGap: 18 }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }}>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Text variant="xLarge" style={{ fontWeight: "bold", color: SharedColors.gray40 }}>{config?.prices?.ui?.priceNotFoundCaption ?? strings.CONSTRUCTOR.STAGES.PRE_OFFER.NO_OFFERS_SUBTITLE}</Text>
                                <Stack tokens={{ maxWidth: 600 }}>
                                    <div style={{ textAlign: "center" }}>
                                        <Text variant="small" style={{ color: SharedColors.gray40 }}>
                                            <div dangerouslySetInnerHTML={{ __html: config?.prices?.ui?.priceNotFoundInformation ?? strings.CONSTRUCTOR.STAGES.PRE_OFFER.NO_OFFERS_INFORMATION }}></div>
                                        </Text>
                                    </div>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                    {props.selectedPivot === 'notAcceptable' && (
                        <Stack tokens={{ childrenGap: 18 }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }}>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Text variant="xLarge" style={{ fontWeight: "bold", color: SharedColors.gray40 }}>{config?.prices?.ui?.notAcceptedMessage ?? strings.CONSTRUCTOR.STAGES.PRE_OFFER.NOT_ACCEPTED_SUBTITLE}</Text>
                            </Stack>
                        </Stack>
                    )}
                </Stack.Item>
            </Stack>
        </StagePreview>
    )
}
