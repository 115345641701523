const keys = {
    upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    lowerCase: "abcdefghijklmnopqrstuvwxyz",
    number: "0123456789",
    symbol: "\\/=!@#$%^&*_-]"
    // symbol: "@#$%^&*()_+~|}{[]></=-"
}
const getKey = [
    function upperCase() {
        return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
    },
    function lowerCase() {
        return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
    },
    function number() {
        return keys.number[Math.floor(Math.random() * keys.number.length)];
    },
    function symbol() {
        return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
    }
];

export function createPassword(length: number, hasSymbol?: boolean, hasUpper?: boolean, hasNumber?: boolean, hasLower?: boolean) {
    let _hasSymbol = false;
    let password = "";

    while (length > password.length) {
        let keyToAdd = getKey[Math.floor(Math.random() * (getKey.length - 0)) + 0]();
        // console.log('keyToAdd:', keyToAdd)
        
        let indexOf = `${password}${keyToAdd}`.indexOf(`${keyToAdd}${keyToAdd}`);
        while (indexOf !== -1) {
            keyToAdd = getKey[Math.floor(Math.random() * (getKey.length - 0)) + 0]();
            indexOf = `${password}${keyToAdd}`.indexOf(`${keyToAdd}${keyToAdd}`)
            // console.log('whileKeyToAdd:', keyToAdd)
        }

        if (!_hasSymbol && keys.symbol.includes(keyToAdd)) {
            // console.log('_hasSymbol:', _hasSymbol)
            _hasSymbol = true
        }
        if (!_hasSymbol && length - password.length <= 2) {
            // console.log("length - password.length:", length - password.length)
            keyToAdd = getKey[3]();
        }

        password += keyToAdd;
    }

    return password;
}