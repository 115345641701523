import { Separator, Stack, Text, PrimaryButton, TextField } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import IdentificationHeader from './ui/IdentificationHeader';
import { strings } from '../../../../../../localization/strings';
import { IIdentificationTransactionConfig } from '@piceasoft/core';
import { TooltipHost, TooltipOverflowMode, css, mergeStyleSets } from '@fluentui/react';
import { identificationContainerStyle, hasBlockLevelElements, blockOffset } from '../helpers/identificationContainer';

type TProps = {
    config?: IIdentificationTransactionConfig
}

export const Transaction: React.FC<TProps> = (props) => {

    return (
        <Stack verticalFill tokens={{ childrenGap: 32 }}>
            <Stack.Item>
                <IdentificationHeader
                    icon={props.config?.ui?.icon ?? "EntitlementRedemption"}
                    title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.TRANSACTION.TITLE}
                />
                <Separator />
                <Stack tokens={{ childrenGap: 16 }}>
                    <Stack.Item>
                        <TooltipHost
                        overflowMode={TooltipOverflowMode.Self}
                        content={props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION}
                        hostClassName={css(identificationContainerStyle().body)}
                        styles={{ root: {display: 'block'}}}>
                            <Text style={{ color: SharedColors.gray40 }}>
                                <div 
                                    style={hasBlockLevelElements(props.config?.ui?.information) ? {marginTop: `${blockOffset}`} : {marginTop: '0px'}}
                                    dangerouslySetInnerHTML={{ __html: props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.TRANSACTION.INFORMATION }}>
                                </div>
                            </Text>
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        <TextField placeholder={props.config?.ui?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.TRANSACTION.PLACEHOLDER} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack verticalFill styles={{ root: { paddingTop: 8 } }}>
            </Stack>
            <Stack.Item>
                <Stack horizontalAlign="stretch">
                    <PrimaryButton text={strings.BUTTONS.TEXT.CONFIRM}></PrimaryButton>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}
