import { TextField } from "@fluentui/react"
import { useEffect } from "react"
import { IDataCollectionConfig } from "@piceasoft/core"
import { strings } from "../../../../../../../../../../../localization/strings"
import { fetchDaData } from "../../../helpers/previewHelper"

type TProps = {
    value?: string
    config?: IDataCollectionConfig
    errorMessage: string
    subdivisionCode?: string
    handleOnBlur: () => void
    handleOnChange: (value?: string) => void
}

const IssuedBy: React.FunctionComponent<TProps> = (props: TProps) => {
    let {value, config, errorMessage, subdivisionCode, handleOnChange, handleOnBlur} = props

    useEffect(() => {
        if (subdivisionCode?.includes('_')) {
            return
        }

        let fetchData = async () => {
            let data = await fetchDaData(subdivisionCode ?? '', config?.daDataKey ?? '', config?.useDaData ?? false, 'fms_unit')
            if (data === null) return
            if (data?.suggestions[1]?.value) {
                handleOnChange(data?.suggestions[1]?.value)
            }
        }
        fetchData()
    }, [subdivisionCode])

    return (
        <TextField 
            value={value}
            onChange={(e, value) => { handleOnChange(value) }}
            label={config?.config?.fields?.passport?.issuedBy?.label ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PASSPORT_ISSUED_BY}
            placeholder={config?.config?.fields?.passport?.issuedBy?.placeholder ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PLACEHOLDER_PASSPORT_ISSUED_BY}
            errorMessage={errorMessage}
            onBlur={handleOnBlur}
            deferredValidationTime={1000}
            required
        />
    )
}

export default IssuedBy