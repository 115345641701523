export enum TransactionPlaceholders {
    DEVICE_NAME = "{DEVICE.NAME}",
    DEVICE_MODEL = "{DEVICE.MODEL}",
    DEVICE_STORAGE = "{DEVICE.STORAGE}",
    DEVICE_CONFIGURATION = "{DEVICE.CONFIGURATION}",
    DEVICE_VENDOR = "{DEVICE.VENDOR}",
    ARTIKUL = "{ARTIKUL}",  // SKU
    COLOR = "{COLOR}",
    GRADE = "{GRADE}",
    GRADE_CODE = "{GRADE.CODE}",
    IMEI = "{IMEI}",
    IMEI2 = "{IMEI2}",
    SN = "{SN}",
    TAC = "{TAC}",
    DEVICE_MEID = "{DEVICE.MEID}",
    DEVICE_REGION_CODE = "{DEVICE.REGIONCODE}",
    DEVICE_REGION = "{DEVICE.REGION}",
    DEVICE_OS = "{DEVICE.OS}",
    DEVICE_IMSI = "{DEVICE.IMSI}",
    DEVICE_ICCID = "{DEVICE.ICCID}",
    DEVICE_NETWORK_OPERATOR = "{DEVICE.NETWORK_OPERATOR}",
    DEVICE_TABLE = "{DEVICE.TABLE}",    // All available device values in a table.

    TARGET_DEVICE_NAME = "{TARGET.DEVICE.NAME}",
    TARGET_DEVICE_MODEL = "{TARGET.DEVICE.MODEL}",
    TARGET_DEVICE_STORAGE = "{TARGET.DEVICE.STORAGE}",
    TARGET_DEVICE_CONFIGURATION = "{TARGET.DEVICE.CONFIGURATION}",
    TARGET_DEVICE_VENDOR = "{TARGET.DEVICE.VENDOR}",
    TARGET_DEVICE_ARTIKUL = "{TARGET.DEVICE.ARTIKUL}",
    TARGET_DEVICE_COLOR = "{TARGET.DEVICE.COLOR}",
    TARGET_DEVICE_IMEI = "{TARGET.DEVICE.IMEI}",
    TARGET_DEVICE_IMEI2 = "{TARGET.DEVICE.IME2}",
    TARGET_DEVICE_SN = "{TARGET.DEVICE.SN}",
    TARGET_DEVICE_TAC = "{TARGET.DEVICE.TAC}",
    TARGET_DEVICE_MEID = "{TARGET.DEVICE.MEID}",
    TARGET_DEVICE_REGION_CODE = "{TARGET.DEVICE.REGIONCODE}",
    TARGET_DEVICE_REGION = "{TARGET.DEVICE.REGION}",
    TARGET_DEVICE_OS = "{TARGET.DEVICE.OS}",
    TARGET_DEVICE_IMSI = "{TARGET.DEVICE.IMSI}",
    TARGET_DEVICE_ICCID = "{TARGET.DEVICE.ICCID}",
    TARGET_DEVICE_NETWORK_OPERATOR = "{TARGET.DEVICE.NETWORK_OPERATOR}",
    TARGET_DEVICE_TABLE = "{TARGET.DEVICE.TABLE}",

    NUMBER = "{NUMBER}",
    CUSTOMER_PHONE_NUMBER = "{CUSTOMER.PHONE_NUMBER}",
    CUSTOMER_EMAIL = "{CUSTOMER.EMAIL}",
    DATE = "{DATE}",
    TIME = "{TIME}",
    DATE_CLIENT = "{DATE.CLIENT}",
    TIME_CLIENT = "{TIME.CLIENT}",
    PRICE = "{PRICE}",
    MANAGER_FIO = "{MANAGER.FIO}",
    COMPANY_NAME = "{COMPANY.NAME}",
    COMPANY_INN = "{COMPANY.INN}",
    OFFICE_NAME = "{OFFICE.NAME}",
    OFFICE_PN = "{OFFICE.PN}",
    OFFICE_ADDRESS = "{OFFICE.ADDRESS}",
    OFFICE_CITY = "{OFFICE.CITY}",
    BARCODE = "{BARCODE}",
    BARCODE_IMEI = "{BARCODE.IMEI}",
    BARCODE_IMEI2 = "{BARCODE.IMEI2}",
    BARCODE_SN = "{BARCODE.SN}",
    BARCODE_ARTIKUL = "{BARCODE.ARTIKUL}",
    PASSPORT_FIRSTNAME = "{PASSPORT.FIRSTNAME}",
    PASSPORT_SERIES = "{PASSPORT.SERIES}",
    PASSPORT_NUMBER = "{PASSPORT.NUMBER}",
    PASSPORT_ISSUEDBY = "{PASSPORT.ISSUEDBY}",
    PASSPORT_PLACEOFBIRTH = "{PASSPORT.PLACEOFBIRTH}",
    PASSPORT_SUBDIVISIONCODE = "{PASSPORT.SUBDIVISIONCODE}",
    PASSPORT_DATEOFBIRTH = "{PASSPORT.DATEOFBIRTH}",
    PASSPORT_DATEOFISSUE = "{PASSPORT.DATEOFISSUE}",
    PASSPORT_ADDRESS = "{PASSPORT.ADDRESS}",
    INTERVIEW_TABLE_RESULT = "{INTERVIEW.TABLE_RESULT}",
    INTERVIEW_GRADE_CODE = "{INTERVIEW.GRADE.CODE}",
    INTERVIEW_GRADE_NAME = "{INTERVIEW.GRADE.NAME}",
    INTERVIEW_STATUS = "{INTERVIEW.STATUS}",
    PHOTOGRAPHIC_TABLE_RESULT = "{PHOTOGRAPHIC.TABLE_RESULT}",
    PHOTOGRAPHIC_GRADE_CODE = "{PHOTOGRAPHIC.GRADE.CODE}",
    PHOTOGRAPHIC_GRADE_NAME = "{PHOTOGRAPHIC.GRADE.NAME}",
    PHOTOGRAPHIC_STATUS = "{PHOTOGRAPHIC.STATUS}",
    DIAGNOSTICS_TABLE_RESULT = "{DIAGNOSTICS.TABLE_RESULT}",
    DIAGNOSTICS_GRADE_CODE = "{DIAGNOSTICS.GRADE.CODE}",
    DIAGNOSTICS_GRADE_NAME = "{DIAGNOSTICS.GRADE.NAME}",
    DIAGNOSTICS_STATUS = "{DIAGNOSTICS.STATUS}",
    SOFTWARE_TABLE_RESULT = "{SOFTWARE.TABLE_RESULT}",
    SOFTWARE_GRADE_CODE = "{SOFTWARE.GRADE.CODE}",
    SOFTWARE_GRADE_NAME = "{SOFTWARE.GRADE.NAME}",
    SOFTWARE_STATUS = "{SOFTWARE.STATUS}",
    PRICE_WITH_PROMO = "{PRICE.WITH.CAMPAIGN}",
    CAMPAIGN_PROVIDER_CODE = "{CAMPAIGN.PROVIDER.CODE}",
    CAMPAIGN_PROVIDER_NAME = "{CAMPAIGN.PROVIDER.NAME}",
    CAMPAIGN_PROVIDER_DESCRIPTION = "{CAMPAIGN.PROVIDER.DESCRIPTION}",
    CAMPAIGN_NAME = "{CAMPAIGN.NAME}",
    CAMPAIGN_DESCRIPTION = "{CAMPAIGN.DESCRIPTION}",
    CAMPAIGN_DEVICE_MANUFACTURER = "{CAMPAIGN.DEVICE.MANUFACTURER}",
    CAMPAIGN_DEVICE_NAME = "{CAMPAIGN.DEVICE.NAME}",
    CAMPAIGN_PRICE = "{CAMPAIGN.PRICE}",
    CAMPAIGN_PRICE_TYPE = "{CAMPAIGN.PRICE.TYPE}",
    SERVICE_CHANNEL = "{SERVICE.CHANNEL}",
    SERVICE_TYPE = "{SERVICE.TYPE}",

    SELF_SERVICE_TROUBLESHOOT = "{SELF_SERVICE.TROUBLESHOOT}",
    SELF_SERVICE_DIAGNOSTICS = "{SELF_SERVICE.DIAGNOSTICS}",
    SELF_SERVICE_AI_SOLUTION = "{SELF_SERVICE.AI.SOLUTION}",
    SELF_SERVICE_PROMOTION = "{SELF_SERVICE.PROMOTION}",

    SELF_SERVICE_REPAIR_OFFER_ACCEPTED = "{SELF_SERVICE.REPAIROFFER.ACCEPTED}",
    SELF_SERVICE_REAPIR_OFFER_ALL = "{SELF_SERVICE.REAPIROFFER.ALL}",

    SELF_SERVICE_QUESTIONS_PHASE_ONE = "{SELF_SERVICE.QUESTIONS.PHASE.ONE}",
    SELF_SERVICE_QUESTIONS_PHASE_TWO = "{SELF_SERVICE.QUESTIONS.PHASE.TWO}",

    RAN_FORM_NAME = "{RAN_FORM.NAME}",
    RAN_FORM_ID= "{RAN_FORM.ID}",
    RAN_FORM_PHONENUMBER= "{RAN_FORM.PHONENUMBER}",
    RAN_FORM_EMAIL = "{RAN_FORM.EMAIL}",
    RAN_FORM_ADDRESS = "{RAN_FORM.ADDRESS}",
    RAN_FORM_ZIP = "{RAN_FORM.ZIP}",
    RAN_FORM_CITY = "{RAN_FORM.CITY}",
    RAN_FORM_SCHEDULE_FROM = "{RAN_FORM.SCHEDULE.FROM}",
    RAN_FORM_SCHEDULE_TO = "{RAN_FORM.SCHEDULE.TO}",
    RAN_FORM_COMMENTS = "{RAN_FORM.COMMENTS}",
    RAN_FORM_DEVICE_IMEI = "{RAN_FORM.DEVICE.IMEI}",
    RAN_FORM_DEVICE_BRAND = "{RAN_FORM.DEVICE.BRAND}",
    RAN_FORM_DEVICE_MODEL = "{RAN_FORM.DEVICE.MODEL}",
    RAN_FORM_DEVICE_COLOR = "{RAN_FORM.DEVICE.COLOR}",
    RAN_FORM_INVOICE_DATE = "{RAN_FORM.INVOICE.DATE}",

    OPERATOR_SIGNATURE = "{OPERATOR.SIGNATURE}",
    SUPERVISOR_SIGNATURE = "{SUPERVISOR.SIGNATURE}",

    CONTENTTRANSFER_DURATION = "{CONTENTTRANSFER.DURATION}",
    CONTENTTRANSFER_TABLE_RESULT = "{CONTENTTRANSFER.TABLE.RESULT}",
}