import { IDropdownOption } from "@fluentui/react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../../../core/store"
import { IDiagnosticsWebBasedConfig } from "@piceasoft/core"
import { strings } from "../../../../../../../../localization/strings"
import { Section } from "../../../../../../decorations/Section"
import { ParameterItem } from "../../../../../components/ParameterItem"

export type TWebBasedSettingsConfiguratorModePivotProps = {
    config: IDiagnosticsWebBasedConfig
    gradesCategory?: string
    onModeConfirm: (config: IDiagnosticsWebBasedConfig) => void
}

export const WebBasedSettingsConfiguratorModePivot: React.FC<TWebBasedSettingsConfiguratorModePivotProps> = (props) => {

    const hasTests = props.config.tests && props.config.tests.length > 0
    const workflow = useSelector((s:IStore) => s.configurator)
    const grades = props.gradesCategory !== undefined ? (workflow.gradesCategories?.find(gc => gc.code === props.gradesCategory)?.grades ?? []) : workflow.grades

    const gradeOptions = [{text: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.CHOOSE_SUCCESS_GRADE_BEST_GRADE, key: ''}, ...(grades ?? []).map(g => ({text: g.name, key: g.code}) as IDropdownOption)] as IDropdownOption[]
    const successGradeErrorMessage = workflow.assessment?.modules.find((module)=>module.config.errors?.find((error)=>error.code === 18))? strings.CONSTRUCTOR.INSPECTIONS.COMMON.WARNING_OPTION_TURNED_OFF : undefined
    return (
        <Section flat max>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.DEFAULT_TEST_TITLE}
                useToggle={true}
                toggleEnabled
                noSeparator
                toggleChecked={props.config.defaultTestIndex !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        defaultTestIndex: props.config.defaultTestIndex !== undefined ? undefined : -1
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.DEFAULT_TEST_DESCRIPTION}
                useDropdown={props.config.defaultTestIndex !== undefined ? true : false}
                dropdownEnabled={props.config.tests && props.config.tests.length > 0}
                dropdownSelectedKey={props.config.defaultTestIndex}
                dropdownOnChange={(opt) => props.onModeConfirm({
                    ...props.config,
                    defaultTestIndex: opt?.key as number
                })}
                dropdownPlaceholder={(props.config.tests && props.config.tests.length > 0) ? strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.CHOOSE_TEST_PLACEHOLDER : strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.CHOOSE_TEST_PLACEHOLDER_NO_TESTS}
                dropdownOptions={(props.config.tests ?? []).map((i, index) => ({ key: index, text: `${index + 1} ${i.name}` }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.ANDROID_TEST_TITLE}
                useToggle={true}
                toggleEnabled
                toggleChecked={props.config.androidTestIndex !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        androidTestIndex: props.config.androidTestIndex !== undefined ? undefined : -1
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.ANDROID_TEST_DESCRIPTION}
                useDropdown={props.config.androidTestIndex !== undefined ? true : false}
                dropdownEnabled={props.config.tests && props.config.tests.length > 0}
                dropdownSelectedKey={props.config.androidTestIndex}
                dropdownOnChange={(opt) => props.onModeConfirm({
                    ...props.config,
                    androidTestIndex: opt?.key as number
                })}
                dropdownPlaceholder={(props.config.tests && props.config.tests.length > 0) ? strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.CHOOSE_TEST_PLACEHOLDER : strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.CHOOSE_TEST_PLACEHOLDER_NO_TESTS}
                dropdownOptions={(props.config.tests ?? []).map((i, index) => ({ key: index, text: `${index + 1} ${i.name}` }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.IOS_TEST_TITLE}
                useToggle={true}
                toggleEnabled
                toggleChecked={props.config.iosTestIndex !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        iosTestIndex: props.config.iosTestIndex !== undefined ? undefined : -1
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.IOS_TEST_DESCRIPTION}
                useDropdown={props.config.iosTestIndex !== undefined ? true : false}
                dropdownEnabled={props.config.tests && props.config.tests.length > 0}
                dropdownSelectedKey={props.config.iosTestIndex}
                dropdownOnChange={(opt) => props.onModeConfirm({
                    ...props.config,
                    iosTestIndex: opt?.key as number
                })}
                dropdownPlaceholder={hasTests ? strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.CHOOSE_TEST_PLACEHOLDER : strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.CHOOSE_TEST_PLACEHOLDER_NO_TESTS}
                dropdownOptions={(props.config.tests ?? []).map((i, index) => ({ key: index, text: `${index + 1} ${i.name}` }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.ALLOW_CHOOSE_TEST_TITLE}
                useToggle={true}
                toggleEnabled
                toggleChecked={props.config.allowChooseTest !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        allowChooseTest: props.config.allowChooseTest !== undefined ? undefined : true,
                        allowComplexTest: undefined
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.ALLOW_CHOOSE_TEST_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.ALLOW_COMPLEX_TEST_TITLE}
                useToggle={true}
                toggleEnabled={props.config.allowChooseTest}
                toggleChecked={props.config.allowComplexTest !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        allowComplexTest: props.config.allowComplexTest !== undefined ? undefined : true
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.ALLOW_COMPLEX_TEST_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.SUCCESS_GRADE_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.config.setSuccessGrade !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        setSuccessGrade: props.config.setSuccessGrade !== undefined ? undefined : ""
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.SUCCESS_GRADE_DESCRIPTION}
                useDropdown={props.config.setSuccessGrade !== undefined}
                dropdownEnabled={props.config.setSuccessGrade !== undefined}
                dropdownSelectedKey={props.config.setSuccessGrade}
                dropdownOnChange={(opt) => props.onModeConfirm({
                    ...props.config,
                    setSuccessGrade: opt?.key as string
                })}
                dropdownOptions={gradeOptions}
                errorMessage={successGradeErrorMessage}
            />
        </Section>
    )
}
