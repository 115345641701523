import {
    DirectionalHint, FontIcon, getFocusStyle, getTheme,
    ISearchBoxStyles, ITheme, mergeStyleSets, Modal,
    SharedColors, Spinner, Stack, Text
} from "@fluentui/react"
import { useBoolean, useId } from "@uifabric/react-hooks"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actionCreators } from "../../../core/actions/teaching-actions"
import { portalApi } from "../../../core/api/api"
import { IStore } from "../../../core/store"
import { ILogEntry, TransactionLogFlags } from "@piceasoft/core";
import { strings } from "../../../localization/strings"
import TransactionResult from "../../../screens/main/transactions/transactionResult/TransactionResult"
import SuggestedSearch, { handleTextToListKeyPress } from "../../shared/search/SuggestedSearch"
import { StartTeachingItem } from "../../shared/startTeaching/StartTeachingItem"

export const TransactionSearch: React.FC<{}> = (props) => {
    const [searchRequest, setSearchRequest] = useState<string>()
    const [searchResults, setSearchResults] = useState<{data?: ILogEntry[], request?: string}>()
    const [isFetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const [error, setError] = React.useState<string>()

    const [selectedTransactionUid, setSelectedTransactionUid] = useState<string>()

    const teachingState = useSelector((s: IStore) => s.teaching)
    const teachingItemName = "transaction_search"
    const dispatch = useDispatch();

    React.useEffect(() => {
        setTimeout(() => {
            dispatch(actionCreators.addItemId(teachingItemName, searchBoxId))
        }, 500)
    }, [])

    const calloutListId = useId('search-list-id')
    const searchBoxId = useId('searchbox-id')
    const searchId = useId('search-id')
    const searchMaxWidth = 320

    const searchStyles: Partial<ISearchBoxStyles> = {
        root: {
            width: searchRequest ? searchMaxWidth : 250,
            transition: '0.75s',
            selectors: {
                '&:focus-within': { width: searchMaxWidth },
            }
        }
    }

    useEffect(() => {
        if (!isFetching && searchResults?.request !== searchRequest) {
            if (searchRequest && searchRequest.length < 3) {
                setSearchResults({data:[], request: searchRequest})
            } else {
                handleSearch()
            }
        }
    }, [searchRequest, isFetching])

    // useEffect(() => {
    //     console.log(searchResults)
    // }, [searchResults])

    const handleSearch = async () => {
        if (searchRequest) {
            setError(undefined)
            if (searchRequest.length > 0) {
                startFetch()
                setSearchResults(undefined)
                const result = await portalApi.log.search(searchRequest)
                console.log(result)
                if (result.successed) {
                    setSearchResults({data: result.data, request: searchRequest})
                }
                if (result.errors && result.errors.length > 0) {
                    setError(result.errors[0].description)
                }
                stopFetch()
            }
            if (searchRequest.length === 0) {
                setSearchResults({data: []})
            }
        } else {
            setSearchResults(undefined)
        }
    }

    const handleClick = (item?: ILogEntry) => {
        if (item) {
            setSelectedTransactionUid(item.uid)
        }
    }

    const onHideTransaction = () => {
        setSelectedTransactionUid(undefined)
    }

    const searchRequestHandler = async (value?: string) => {
        if (value && value.trim().length > 0)
            setSearchRequest(value.trim())
        if (!value || value.length === 0)
            setSearchRequest('')
    }

    const getTransactionColor = (flag: string): string => {
        switch (flag) {
            case TransactionLogFlags.Unfinished:
                return SharedColors.orangeYellow10
            case TransactionLogFlags.Created:
                return SharedColors.green10
            case TransactionLogFlags.Canceled:
                return SharedColors.red10
            default: return theme.palette.black
        }
    }

    const onRenderCell = (item?: ILogEntry, index?: number, isScrolling?: boolean): JSX.Element => {
        if (!item) return <></>
        return (
            <div className={classNames.itemCell}
                data-is-focusable={true}
                id={`${calloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, searchResults?.data?.length ?? 0, searchBoxId, calloutListId, handleClick, index, item)}
                tabIndex={-1}
                onClick={() => handleClick(item)}>
                <Stack horizontal verticalAlign='center' grow tokens={{ padding: 4, childrenGap: 4 }}>
                    <Stack grow horizontal verticalAlign='center' tokens={{ childrenGap: 8 }}>
                        <FontIcon iconName='ToggleThumb' style={{ fontSize: 10, marginTop: 1, color: getTransactionColor(item.flag) }} />
                        <Stack tokens={{ childrenGap: 2 }}>
                            <Text style={{ color: SharedColors.gray40 }}>{item.number}</Text>
                            <Text variant="smallPlus" style={{ color: SharedColors.gray20 }}>{item.device}</Text>
                        </Stack>
                    </Stack>
                    {item.creationDate && <Text variant="small">{new Date(item.creationDate).toLocaleString()}</Text>}
                </Stack>
            </div >
        );
    };

    const notFoundBlockRender = (): React.ReactNode => {
        return (
            <Stack tokens={{ padding: 4 }} style={{ width: '23em' }}>
                {!isFetching && (searchRequest && searchRequest?.length < 6) && (
                    <Stack tokens={{ padding: 8 }}>
                        <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.TRANSACTIONS.SEARCH_MIN_LENGTH}</Text>
                    </Stack>
                ) || (
                        <Stack tokens={{ padding: 8 }}>
                            <Text variant="small" style={{ color: SharedColors.gray40, fontWeight: 600 }}>{strings.TRANSACTIONS.SEARCH_IS_EMPTY}</Text>
                        </Stack>
                    )}
                {isFetching && (
                    <Stack verticalAlign="center" horizontalAlign="center" verticalFill grow>
                        <Spinner label={strings.SPINNERS.DATA_IS_LOADING} />
                    </Stack>
                )}
            </Stack>
        )
    }

    return (
        <div id={searchId}>
            <SuggestedSearch
                inputBoxPlaceholder={strings.HEADER.SEARCH.PLACEHOLDER}
                onClickSuggestionCell={handleClick}
                inputBoxId={searchBoxId}
                onRenderSuggestionCell={onRenderCell}
                suggestionsListId={calloutListId}
                searchRequest={searchRequest}
                suggestions={searchResults?.data}
                notFoundBlockRender={notFoundBlockRender}
                setSearchRequest={searchRequestHandler}
                isSearchEqualTheOneSuggestion={false}
                searchBoxStyles={searchStyles}
                suggestionsListWidth={searchMaxWidth}
                textDeferredValidationTime={5000}
                isFetching={isFetching}
            />
            {teachingState.currentItem === teachingItemName && (
                <StartTeachingItem
                    name={teachingItemName}
                    header={strings.START_TEACHING.SEARCH.HEADER}
                    text={strings.START_TEACHING.SEARCH.TEXT}
                    target={searchId}
                    direction={DirectionalHint.bottomLeftEdge}
                />
            )}
            <Modal styles={{
                main: {
                    width: "60%", height: "80%"
                },
                scrollableContent: {
                    width: "100%", height: "100%"
                }
            }} isOpen={selectedTransactionUid ? true : false} onDismiss={onHideTransaction} isBlocking={true}>
                {selectedTransactionUid && <TransactionResult hideModal={onHideTransaction} transactionId={selectedTransactionUid} />}
            </Modal>
        </div>
    )
}

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1, outlineColor: palette.neutralLight, borderColor: palette.neutralLight }),
        {
            padding: 5,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: palette.neutralLight },
                '&:focus': { background: palette.neutralLight },
            },
        },
    ],
    itemContent: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
    },
    itemName: [
        fonts.medium,
        {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    ],
});