import { Stack, SharedColors, Text } from "@fluentui/react"
import { PropertyValue } from "../../../../../components/shared/decorations/PropertyValue"
import { ICompany } from "../../../../../core/store"
import { strings } from "../../../../../localization/strings"

export type TCompanyInformationPivotProps = {
    data: ICompany
}

export const CompanyInformationPivot: React.FC<TCompanyInformationPivotProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 12 }}>
            <Stack tokens={{ childrenGap: 6 }}>
                <PropertyValue label={strings.ORGANIZATION.COMPANIES.COMPANY.NAME} value={props.data.name}/>
                <PropertyValue label={strings.ORGANIZATION.COMPANIES.COMPANY.MANAGER} value={props.data.manager}/>
            </Stack>
            <Stack tokens={{ childrenGap: 6 }}>
                <PropertyValue label={strings.ORGANIZATION.COMPANIES.COMPANY.ITN} value={props.data.itn}/>
                <PropertyValue label={strings.ORGANIZATION.COMPANIES.COMPANY.PSRN} value={props.data.psrn}/>
                {/* <PropertyValue label={strings.ORGANIZATION.COMPANIES.COMPANY.IEC} value={props.data.iec}/> */}
            </Stack>
            <Stack tokens={{ childrenGap: 6 }}>
                <PropertyValue label={strings.ORGANIZATION.COMPANIES.COMPANY.ADDRESS} value={props.data.address}/>
            </Stack>
        </Stack>
    )
}