import {
    Stack, Icon, getTheme, Text
} from "@fluentui/react"
import { IContentTransferConfig } from "@piceasoft/core"
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";
import { getLocalizedServiceType } from "../../../../localization/helpers/channel";
import { ServiceTypes } from "../../../../core/store/typings/ServiceTypes";
type TProps = {
    config: IContentTransferConfig
    showSettings: () => void
}
export const ContentTransfer: React.FC<TProps> = (props) => {
    return (
        <StageBox title={strings.ORGANIZATION.SERVICES.SERVICE_TYPE.CONTENT_TRANSFER} settingsButtonProps={{ onClick: props.showSettings }} >
            <Stack tokens={{ childrenGap: 16 }}>

                <Stack styles={{
                    root: {
                        padding: "16px 32px",
                        color: theme.palette.black,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: theme.palette.neutralQuaternaryAlt,
                        borderTopWidth: 8,
                        backgroundColor: theme.palette.white,
                        position: "relative",
                        ":hover": {
                            cursor: "arrow",
                            borderColor: theme.palette.themePrimary,
                            backgroundColor: theme.palette.themeLighterAlt
                        }
                    }
                }}>
                    <Stack.Item>
                        <Stack horizontal grow>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Icon iconName={ "Sync"} styles={{ root: { color: theme.palette.black } }} />
                                <Text styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{getLocalizedServiceType( ServiceTypes.ContentTransfer)}</Text>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>{getLocalizedServiceType( ServiceTypes.ContentTransfer)}</Text>
                    </Stack.Item>
                </Stack>

            </Stack>
        </StageBox>
    )
}

const theme = getTheme();
