import { Slider } from '@fluentui/react'
import * as React from 'react'
import { IPhotographicRequestConfig } from '@piceasoft/core'
import { strings } from '../../../../../../../../localization/strings'
import { Section } from '../../../../../../decorations/Section'
import { ParameterItem } from '../../../../../components/ParameterItem'

export type TModeConfiguratorCommonPivotProps = {
    config?: IPhotographicRequestConfig
    onConfirm: (config?: IPhotographicRequestConfig) => void
}

export const RequestConfiguratorPivot: React.FC<TModeConfiguratorCommonPivotProps> = ({ config, onConfirm }) => {
    return (
        <Section flat max>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COMMENT_TITLE}
                noSeparator
                useToggle
                toggleEnabled
                toggleChecked={config?.comment ? true : false}
                toggleOnChange={() => {
                    onConfirm({
                        ...config,
                        comment: config?.comment ? undefined : { minLength: 3 },
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COMMENT_DESCRIPTION}
            />
            {config?.comment && (
                <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.NO_REQUIRE_COMMENT_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config.comment.required === false ? true : false}
                    toggleOnChange={() => {
                        onConfirm({
                            ...config,
                            comment: {
                                ...config.comment,
                                required: config.comment?.required === false ? undefined : false
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.NO_REQUIRE_COMMENT_DESCRIPTION}
                />

            )}
            {config?.comment && (
                <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COMMENT_MIN_LENGTH_TITLE}
                    useToggle={false}
                    description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COMMENT_MIN_LENGTH_DESCRIPTION}
                >
                    <Slider showValue={true}
                        value={config.comment.minLength} onChange={(val) => {
                            onConfirm({
                                ...config,
                                comment: {
                                    ...config.comment,
                                    minLength: val && val !== 0 && val || undefined
                                }
                            }
                            )
                        }} min={0} />
                </ParameterItem>
            )}
        </Section>
    )
}
