import {
    Text, getTheme, Stack, IStackStyles, Icon, Nav, Image, INavLinkGroup, IRenderFunction,
    IRenderGroupHeaderProps, INavLink, IComponentAs, INavButtonProps, INavStyles
} from '@fluentui/react';
import * as React from 'react';
import { TTabState } from './Settings';



export type TProps = {
    handleTabLinkClick: (item?: INavLink) => void
    groups: INavLinkGroup[]
    selectedTabKey: TTabState
}

export const SettingsTabs: React.FC<TProps> = (props) => {

    const onRenderGroupHeader: IRenderFunction<IRenderGroupHeaderProps> = (props) => {
        return (
            <Stack tokens={{ padding: '8px 0px 8px 32px' }}>
                <Text style={{ color: theme.palette.black, fontWeight: 600, fontSize: ".9375rem" }}>{props?.name}</Text>
            </Stack>
        )
    }

    const linkAs: IComponentAs<INavButtonProps> = (lProps) => {
        const keyParts = props.selectedTabKey?.split('.');
        const isGroup = (lProps?.link?.links && lProps?.link?.links?.length > 0) ?? false
        let isSelectedKey = false;
        let isSelectedGroupChild = false
        let isSelectedGroupKey = false
        if (keyParts && keyParts?.length > 1) {
            isSelectedGroupChild = lProps?.link?.key?.split('.')[1] === keyParts[1]
            isSelectedGroupKey = lProps?.link?.key === [keyParts[0], keyParts[1]].join('.');
        }
        if (window.location.pathname === lProps?.link?.url) {
            isSelectedKey = true;
        }

        return (
            <Stack key={lProps.link?.key} tokens={{ childrenGap: !isGroup ? 14 : 8 }} styles={getNavItemStyles(lProps.link?.key === props.selectedTabKey, isSelectedGroupChild, isGroup, isSelectedGroupKey)} horizontal verticalAlign='center' grow onClick={lProps?.onClick}>
                {lProps?.link?.icon &&
                    <Stack verticalFill verticalAlign='center' styles={{ root: { paddingLeft: !isGroup ? 10 : 0 } }}>
                        {isGroup && (
                            <Image src={lProps?.link?.icon} width={24} height={24} />
                        ) || (
                                <Icon iconName={lProps?.link?.icon?.substr(1)} style={{ fontSize: 16 }} />
                            )}
                    </Stack>}
                <Text style={{ fontWeight: isSelectedKey ? 600 : 400 }} variant={"medium"}>{lProps?.link?.name}</Text>
            </Stack>
        )
    }

    return (
        <>
            <Stack tokens={{ padding: '0px 0px 0px 0px' }} verticalFill>
                <Nav
                    styles={navStyles}
                    groups={props.groups}
                    onLinkExpandClick={() => { }}
                    onRenderGroupHeader={onRenderGroupHeader}
                    selectedKey={props.selectedTabKey}
                    onLinkClick={(ev, item) => props.handleTabLinkClick(item)}
                    linkAs={linkAs}
                />
            </Stack>
        </>
    )
}

const getNavItemStyles = (isSelectedItem: boolean, isSelectedGroupChild: boolean, isGroup: boolean, isSelectedGroupKey: boolean): Partial<IStackStyles> => {
    return {
        root: {

            padding: '6px 0px 6px 20px',
            borderLeft: isSelectedItem ? `2px solid ${theme.palette.themePrimary}` : (isSelectedGroupChild ? `2px solid rgba(0,0,0,.3)` : `2px solid transparent`),
            background: isSelectedItem ? 'rgba(0,0,0,.09)' : (isSelectedGroupChild ? 'rgba(0,0,0,.07)' : 'transparent'),
            selectors: {
                // '&:hover': { background: (!isSelectedGroupKey && isGroup) ? 'rgba(0,0,0,.09)' : (isSelectedGroupChild ? `rgba(0,0,0,.01)` : 'transparent')},
            },
            cursor: 'pointer'
        }
    }
}

const theme = getTheme();

const containerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 } };

const navStyles: Partial<INavStyles> = {
    link: {
        height: 32
    },
    groupContent: {
        padding: 0,
        margin: 0
    }
}