import { Stack, SharedColors, AnimationStyles, getTheme, Text } from "@fluentui/react"
import { IDevice } from '@piceasoft/core';
import { strings } from "../../../../../../localization/strings"
import { StagePreview } from "../../preview/StagePreview"
import { CommonOfferPreview } from "../../commonOffer/preview/CommonOfferPreview"
import { ProcessStages, ICommonOfferConfig, IPostOfferConfig } from "@piceasoft/core"

type TProps = {
    device: IDevice
    config: IPostOfferConfig
    commonConfig: ICommonOfferConfig
}

export const PostOfferPreview: React.FC<TProps> = ({ config, commonConfig, ...props }) => {
    return (
        <StagePreview config={config}
            stage={ProcessStages.PostOffer}
            defaultNextText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONTINUE}
            defaultCancelText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CANCEL}
            defaultStageTitle={"№CONF-0000-0000007"}
        >
            <Stack tokens={{ childrenGap: 16, padding: "0 0 20px 0" }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }} >
                <CommonOfferPreview config={commonConfig} device={props.device} />
                <Stack tokens={{ childrenGap: 18 }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }}>
                    <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                        <Text variant="xLarge" style={{ fontWeight: "bold", color: SharedColors.gray40 }}>{config?.ui?.header ?? strings.CONSTRUCTOR.STAGES.POST_OFFER.SUBTITLE}</Text>
                        <Stack tokens={{ maxWidth: 600 }}>
                            <div style={{ textAlign: "center" }}>
                                <Text variant="small" style={{ color: SharedColors.gray40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: config?.ui?.information ?? strings.CONSTRUCTOR.STAGES.POST_OFFER.INFORMATION }}></div>
                                </Text>
                            </div>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </StagePreview>
    )
}

const theme = getTheme();