import { Stack, Icon } from '@fluentui/react';
import React from 'react'
import { IDiagnosticsPiceaCaseConfig } from '@piceasoft/core';
import { strings } from '../../../../../../../../localization/strings';
import { Tooltip } from '../../../../../../help/Tooltip';

type TProps = {
    item: IDiagnosticsPiceaCaseConfig
}

const securityDescriber = (value: number | undefined): string => {
    switch (value) {
        case 0: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY_OPEN;
        case 1: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY_WEP;
        case 2: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY_WPA;
        default: return String(value);
    }
}

const hiddenDescriber = (value: boolean | undefined): string => {
    switch (value) {
        case true: return strings.BUTTONS.TOGGLE_ENABLE;
        case false: return strings.BUTTONS.TOGGLE_DISABLE;
        default: return String(value);
    }
}

const touchPatternTypeDescriber = (value: number | undefined): string => {
    switch (value) {
        case 1: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_ONE;
        case 2: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_TWO;
        case 3: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_THREE;
        case 4: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_FOUR;
        case 5: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_FIVE;
        case 6: return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE_SIX;
        default: return String(value);
    }
}

export const TestCaseOptionViewer: React.FC<TProps> = (props) => {
    return (
        <Stack horizontal verticalFill verticalAlign="center" tokens={{ childrenGap: 12, padding: "0 0 0 8px" }}>
            {/* Common options */}
            {props.item?.options?.stop_on_failure && (
                <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.COMMON.STOP_ON_FAILURE_INFO}>
                    <Icon iconName={"CircleStop"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.limit && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_TOOL_TIP} - ${props.item?.options?.limit}%`}>
                    <Icon iconName={"SpeedHigh"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.uptime_warning_limit && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT} - ${props.item?.options?.uptime_warning_limit}`}>
                    <Icon iconName={"SpeedHigh"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.sleep_time_percentage_limit && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEP_TIME_PERCENTAGE_LIMIT} - ${props.item?.options?.sleep_time_percentage_limit}%`}>
                    <Icon iconName={"SpeedHigh"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {/* Wi-Fi options */}
            {props.item?.options?.ssid && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SSID}: ${props.item.options.ssid}`}>
                    <Icon iconName={"InternetSharing"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.password && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.PASSWORD}: ${props.item.options.password}`}>
                    <Icon iconName={"PasswordField"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.security && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.SECURITY}: ${securityDescriber(props.item.options.security)}`}>
                    <Icon iconName={"ReportHacked"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.hidden && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_SOFTWARE_UPDATES.HIDDEN}: ${hiddenDescriber(props.item.options.hidden)}`}>
                    <Icon iconName={"Hide"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {/* Touch options */}
            {props.item?.options?.touch_pattern_type && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_DISPLAY_TOUCH.TOUCH_PATTERN_TYPE}: ${touchPatternTypeDescriber(props.item.options.touch_pattern_type)}`}>
                    <Icon iconName={"WhiteBoardApp16"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {/* Call options */}
            {props.item?.options?.phone_number && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_CALL.PHONE_NUMBER}: ${props.item.options.phone_number}`}>
                    <Icon iconName={"Phone"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.is_manual && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_CALL.IS_MANUAL}: ${hiddenDescriber(props.item.options.is_manual)}`}>
                    <Icon iconName={"Dialpad"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {/* Battery options */}
            {props.item?.options?.failure_level && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.FAILURE_LEVEL}: ${props.item.options.failure_level}%`}>
                    <Icon iconName={"SpeedHigh"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.level_drop && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.LEVEL_DROP}: ${props.item.options.level_drop}%`}>
                    <Icon iconName={"Health"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
            {props.item?.options?.min_battery_level && (
                <Tooltip content={`${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TC_BATTERY_STRESS.MIN_BATTERY_LEVEL}: ${props.item.options.min_battery_level}%`}>
                    <Icon iconName={"LightningBolt"} style={{ fontSize: 16 }} />
                </Tooltip>
            )}
        </Stack>
    );
}
