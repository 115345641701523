import { IColumn, IRenderFunction, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, TooltipHost, Sticky, StickyPositionType, Stack, Text, DetailsList, SelectionMode, DetailsListLayoutMode, Spinner, mergeStyleSets, IDetailsRowProps, DetailsRow, IDetailsListProps, IDetailsRowStyles, getTheme, SharedColors, FontIcon, TextField, ISearchBoxStyleProps, ISearchBoxStyles, IStyleFunctionOrObject } from '@fluentui/react';
import * as React from 'react';
import { onRenderColumnHeaderCommon } from '../../../../../components/shared/detailsList/commonRenders';
import { SimpleCustomFilter } from '../../../../../components/shared/filter/SimpleCustomFilter';
import { ItemsNotFound } from '../../../../../components/shared/notFound/ItemsNotFound';
import { ITransactionEvent } from '@piceasoft/core';
import { strings } from '../../../../../localization/strings';

type TTransactionEvent = ITransactionEvent & { fail: boolean }

type TProps = {
    events: ITransactionEvent[]
}


export const EventsPivot: React.FC<TProps> = (props) => {

    const prepareItems = (): TTransactionEvent[] => {
        return props.events
            .map((i) => {
                const newEvent: TTransactionEvent = i as TTransactionEvent
                const originValues = [i.action, i.status]
                originValues.map(ov => {
                    if (ov && newEvent.fail !== true) {
                        newEvent.fail = ov.toString().toLowerCase().match(new RegExp([`\w*сбой\w*`, `\w*failure\w*`].join("|"))) != null
                    }
                })
                console.log(newEvent)
                return newEvent
            })
    }

    const [items, setItems] = React.useState<TTransactionEvent[]>(prepareItems())

    const [searchString, setSearchString] = React.useState<string>()

    React.useEffect(() => {
        setItems(prepareItems())
    }, [props.events])

    React.useEffect(() => {
        setItems(() => {
            var newItems = prepareItems()
            if (searchString) {
                newItems = [
                    ...((newItems as TTransactionEvent[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.action,
                            i.status,
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as TTransactionEvent[]
        })
    }, [searchString])

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.TRANSACTION.RESULT.EVENTS.COLUMNS.TIME,
            fieldName: 'creationDate',
            minWidth: 70,
            maxWidth: 70,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: TTransactionEvent) => {
                return (
                    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 4 }}>
                        <FontIcon iconName="Clock" />
                        <Text variant='small' style={{ color: SharedColors.gray40 }}>{new Date(item.creationDate).toLocaleTimeString()}</Text>
                    </Stack>
                )
            },
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.TRANSACTION.RESULT.EVENTS.COLUMNS.OPERATION,
            fieldName: 'action',
            minWidth: 110,
            maxWidth: 110,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: TTransactionEvent) => {
                return <Text variant='small' style={{ color: SharedColors.cyanBlue10 }}>{item.action}</Text>
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.TRANSACTION.RESULT.EVENTS.COLUMNS.OPERATION_STATUS,
            fieldName: 'status',
            minWidth: 200,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: TTransactionEvent) => {
                return <Text variant='small'>{item.status}</Text>
            },
            isPadded: true,
        }
    ]

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        props.styles = { root: { padding: 0 } }
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };


    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};

        const backgroundColor = (rProps?.item as { fail: boolean }).fail ? 'rgb(253,231,233, 0.6)' : undefined
        const hoverBackgroundColor = (rProps?.item as { fail: boolean }).fail ? 'rgb(253,231,233)' : undefined

        if (rProps) {
            customStyles.root = {
                backgroundColor: backgroundColor,
                ':hover': {
                    backgroundColor: hoverBackgroundColor
                }
            }

            return <DetailsRow {...rProps} styles={customStyles} />;
        }
        return null;
    }

    return (props.events && (
        <Stack>
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                <Stack horizontalAlign='end' tokens={{ padding: "16px 16px 0px 0px" }}>
                    <SimpleCustomFilter styles={filterStyles} onChange={(ev, value) => setSearchString(value)} />
                </Stack>
            </Sticky>
            <DetailsList
                items={items}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderRow={onRenderRow}
                onRenderDetailsHeader={onRenderDetailsHeader}
                getKey={(item: ITransactionEvent, index) => item.creationDate.toString() + index}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                compact
                styles={{ focusZone: { paddingTop: 0, }, contentWrapper: { padding: 0 }, headerWrapper: { padding: 0 } }}
            />
        </Stack>
    ) || (
            <ItemsNotFound doNotUseButton
                info={strings.TRANSACTION.RESULT.EVENTS.NOT_FOUND_CASE.INFO}
                suggestion={strings.TRANSACTION.RESULT.EVENTS.NOT_FOUND_CASE.SUGGESTION}
                imgSrc={"images/navigation/images/not_found.png"} />
        )
    )
}

const theme = getTheme();

const filterStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = {
    icon: { color: theme.palette.neutralTertiaryAlt },
    iconContainer: {},
    clearButton: {},
    root: {
        backgroundColor: theme.palette.white,
        borderColor: theme.palette.neutralTertiaryAlt,
        width: 250
    }
}