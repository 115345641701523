import React, { useEffect } from "react"
import { DaDataAddressSuggestion } from 'react-dadata'
import { getFocusStyle, getTheme, mergeStyleSets, SharedColors, Stack, Text } from "@fluentui/react"
import { useId } from "@uifabric/react-hooks";
import { fetchDaData, handleTextToListKeyPressLocal } from "../../../helpers/previewHelper"
import type { TDaDataSuggestions } from "../../../../../dataCollectionTypes"
import { strings } from "../../../../../../../../../../../localization/strings"
import { escapeRegExp } from "../../../../../../../../../../../assets/ts/utils/regex"
import SuggestedSearch from "../../../../../../../../../search/SuggestedSearch"
import { IDataCollectionConfig } from "@piceasoft/core"

type TProps = {
    config?: IDataCollectionConfig
    errorMessage: string
    searchRequest?: string
    setSearchRequest: (item?: any) => void
    onBlur: () => void
}

const Address: React.FunctionComponent<TProps> = (props) => {
    const {
        config,
        errorMessage,
        searchRequest,
        onBlur,
        setSearchRequest,
    } = props

    const passportAddressTextId = useId("passport-address-text-id")
    const passportAddressCalloutListId = useId("passport-address-callout-id")
    const [daDataPassportAddress, setDaDataPassportAddress] = React.useState<TDaDataSuggestions | null>()

    useEffect(() => {
        if (searchRequest === "") {
            setDaDataPassportAddress(null)
            return
        }

        if (searchRequest === undefined) {
            return
        }

        const fetchData = async () => {
            const data = await fetchDaData(searchRequest, config?.daDataKey ?? "", config?.useDaData ?? false, 'address')
            if (data === null) return
            setDaDataPassportAddress(data)
        }
        fetchData()
    }, [searchRequest])

    const onRenderSuggestionsHeader = (): JSX.Element => {
        return (
            <Stack tokens={{ padding: 8, childrenGap: 4 }}>
                <Text style={{ color: SharedColors.gray10 }}>{strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.CHOOSE_AN_OPTION}</Text>
            </Stack>
        );
    };

    const onRenderAddressSuggestionsCell = (item?: DaDataAddressSuggestion, index?: number, isScrolling?: boolean): JSX.Element => {
        let typesValues: string[] = []
        let values: string[] = []
        if (item) {
            item.data.region && values.push(item.data.region)
            item.data.area && values.push(item.data.area)
            item.data.city && values.push(item.data.city)
            item.data.settlement && values.push(item.data.settlement)
            item.data.city_district && values.push(item.data.city_district)
            item.data.street && values.push(item.data.street)
            item.data.house && values.push(item.data.house)
            item.data.block && values.push(item.data.block)
            item.data.flat && values.push(item.data.flat)

            item.data.region_type && typesValues.push(item.data.region_type)
            item.data.region_type_full && typesValues.push(item.data.region_type_full)
            item.data.area_type && typesValues.push(item.data.area_type)
            item.data.area_type_full && typesValues.push(item.data.area_type_full)
            item.data.city_type && typesValues.push(item.data.city_type)
            item.data.city_type_full && typesValues.push(item.data.city_type_full)
            item.data.city_district_type && typesValues.push(item.data.city_district_type)
            item.data.city_district_type_full && typesValues.push(item.data.city_district_type_full)
            item.data.settlement_type && typesValues.push(item.data.settlement_type)
            item.data.settlement_type_full && typesValues.push(item.data.settlement_type_full)
            item.data.street_type && typesValues.push(item.data.street_type)
            item.data.street_type_full && typesValues.push(item.data.street_type_full)
            item.data.house_type && typesValues.push(item.data.house_type)
            item.data.house_type_full && typesValues.push(item.data.house_type_full)
            item.data.block_type && typesValues.push(item.data.block_type)
            item.data.block_type_full && typesValues.push(item.data.block_type_full)
            item.data.flat_type && typesValues.push(item.data.flat_type)
            item.data.flat_type_full && typesValues.push(item.data.flat_type_full)
        }

        const filteredSeparatedParts = (searchRequest?.trim() ?? "")
            .split(" ")
            .map(fsp => {
                if (fsp.substring(-1) === ",") {
                    return fsp.substring(0, fsp.length - 1)
                }
                return fsp
            })
            .filter(v => v !== "")
        let finalParts: string[] = []
        filteredSeparatedParts && filteredSeparatedParts.map((p, i) => {
            if (finalParts.length > 0) {
                const thisRoundFinalParts = [...finalParts]
                finalParts = []
                thisRoundFinalParts.map(fp => {
                    const newFP = fp.split(" ").filter(i => i !== "")
                    newFP.map(nfp => {
                        const thisRoundParts = nfp.split(new RegExp(`(^${p})`, "i")).filter(i => i !== "")
                        if (thisRoundParts) {
                            finalParts.push(...thisRoundParts)
                            finalParts.push(" ")
                        } else {
                            finalParts.push(nfp)
                            finalParts.push(" ")
                        }
                    })
                })
            } else {
                if (item?.value) {
                    finalParts = item?.value.split(new RegExp(`\w*(${p})\w*`, "i")).filter(i => i !== "")
                }
            }
        })
        const tmpFinalParts = [...finalParts]
        finalParts = []
        tmpFinalParts.map((tfp, i) => {
            if (tfp === " ") {
                if (finalParts[i + 1] === ",") {
                    return
                }
                const regex = new RegExp(`^${escapeRegExp(tmpFinalParts[i - 1] + tmpFinalParts[i + 1])}`, "i")
                if (values.some(e => regex.test(e))) {
                    return
                }
            }
            finalParts.push(tfp)
        })
        return (
            <div className={classNames.itemCell}
                data-is-focusable={true}
                id={`${passportAddressCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => (
                    handleTextToListKeyPressLocal(
                        ev,
                        passportAddressTextId,
                        passportAddressCalloutListId,
                        daDataPassportAddress?.suggestions.length ?? 0,
                        setSearchRequest,
                        index,
                        item?.value
                ))}
                tabIndex={-1}
                onClick={() => {
                    const suggestion = daDataPassportAddress?.suggestions[index ?? 0]
                    setDaDataPassportAddress(null)
                    setSearchRequest(suggestion?.value)
                }}
            >
                <Stack tokens={{ padding: 4, childrenGap: 4 }}>
                    {(finalParts && finalParts.length > 0) && (
                        <Text>
                            {finalParts.map(p => {
                                var regex = new RegExp(`^${escapeRegExp(p)}`, "i")
                                if (filteredSeparatedParts.some(e => regex.test(e))) {
                                    if (!typesValues.some(e => regex.test(e))) {
                                        return <Text style={{ color: SharedColors.cyanBlue10 }} variant="medium">{p}</Text>
                                    }
                                }
                                return <Text variant="medium">{p}</Text>
                            }
                            )}
                        </Text>
                    ) || (
                            <Text variant="medium">{item?.value}</Text>
                        )
                    }
                </Stack>
            </div>
        );
    }

    return (
        <SuggestedSearch
            inputBoxId={passportAddressTextId}
            suggestionsListId={passportAddressCalloutListId}
            type="text"
            required
            label={config?.config?.fields?.passport?.address?.label ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.ADDRESS}
            textValidateOnFocusOut={true}
            textDeferredValidationTime={2000}
            textOnGetErrorMessage={() => errorMessage}
            searchRequest={searchRequest}
            setSearchRequest={setSearchRequest}
            suggestions={daDataPassportAddress?.suggestions}
            inputBoxPlaceholder={config?.config?.fields?.passport?.address?.placeholder ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PLACEHOLDER_ADDRESS}
            onClickSuggestionCell={setSearchRequest}
            isSearchEqualTheOneSuggestion={(daDataPassportAddress?.suggestions[0] && searchRequest === daDataPassportAddress?.suggestions[0].value) ?? false}
            onRenderSuggestionCell={onRenderAddressSuggestionsCell}
            onRenderSuggestionsHeader={onRenderSuggestionsHeader}
            suggestionsListWidth={590}
            textOnBlur={onBlur}
            errorMessage={errorMessage}
        />
    )
}

let theme = getTheme()
const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.neutralLight, borderColor: theme.palette.neutralLight }),
        {
            padding: 5,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: theme.palette.neutralLight },
                '&:focus': { background: theme.palette.neutralLight },
            },
        },
    ],
});

export default Address
