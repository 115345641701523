import { DefaultButton, getFocusStyle, getTheme, IComboBoxOption, Icon, IDropdownOption, mergeStyleSets, PrimaryButton, ScrollablePane, SharedColors, Stack, TextField, Text, ITextFieldProps } from '@fluentui/react'
import { useId } from '@uifabric/react-hooks'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { escapeRegExp } from '../../../assets/ts/utils/regex'
import { portalApi } from '../../../core/api/api'
import { IContainer } from '../../../core/store'
import { IStructureNode } from '../../../core/store/typings/IStructureNode'
import { strings } from '../../../localization/strings'
import { onRenderLabel } from '../../renders/onRenderLabel'
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from '../search/SuggestedSearch'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { CSHHelpLink } from '../help/CSHHelp'

type TProps = {
    onSubmit: (container: IContainer) => void
    onCancel: () => void
    data?: IContainer
}

export const ContainerForm: React.FC<TProps> = (props) => {

    const { control, reset, watch, handleSubmit, formState: { errors } } = useForm<IContainer>({ defaultValues: { ...props.data, parentId: undefined } });
    const onSubmit: SubmitHandler<IContainer> = data => {
        props.onSubmit({ ...data, parentId: selectedContainer ? (selectedContainer?.key.toString() !== 'null' ? selectedContainer?.key.toString() : undefined) : props.data?.parentId })
    };

    const formState = watch();

    const [containersState, setContainersState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IStructureNode> }>({ fetched: false })

    const [containers, setContainers] = React.useState<IComboBoxOption[]>()

    const [selectedContainer, setSelectedContainer] = React.useState<IComboBoxOption>()
    const containerHostId = useId("container-host-id")
    const containerTextId = useId("container-text-id")
    const containerCalloutListId = "container-callout-id"

    React.useEffect(() => {
        // console.log(props)
        getContainers()
    }, [])

    const getContainers = async () => {
        setContainersState({ fetched: false })
        const result = await portalApi.organization.containers.getContainers()
        // console.log(result)
        setContainersState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: props.data?.id ? result.data?.filter(i => i.id !== props.data?.id) : result.data })
        const parent = result.data?.find(i => i.id === props.data?.parentId)
        if (parent) {
            setSelectedContainer({ key: parent.id, text: parent.itemName })
        }
    }

    React.useEffect(() => {
        if (containers && !selectedContainer) {
            setSelectedContainer(containers[0])
        }
    }, [containers])

    React.useEffect(() => {
        // console.log(props)
        if (containersState.fetched && containersState.data) {
            const parentId = containersState.data.find(i => i.id === props.data?.parentId)?.id
            setContainers(getContainersOptions(containersState.data))
            if (parentId) {
                reset({ ...formState, parentId: parentId })
            }
        }
    }, [containersState])

    React.useEffect(() => {
        // console.log(formState)
        reset({ ...formState, parentId: selectedContainer?.key.toString() })
        if (containersState.data) {
            setContainers(getContainersOptions(containersState.data))
        }
    }, [selectedContainer])

    const getContainersOptions = (containerNodes: IStructureNode[]): IDropdownOption[] => {
        return [{ key: 'null', text: strings.COMMON.NOT_CHOSED }, ...containerNodes.map(cn => ({ key: cn.itemId, text: cn.itemName } as IDropdownOption))]
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderContainerSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${containerCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, containers?.length ?? 0, containerTextId, containerCalloutListId, () => setSelectedContainer(item), index, item?.text, containerHostId)}
                tabIndex={-1}
                onClick={() => {
                    console.log(index)
                    setSelectedContainer(item)
                    focusElement(`${containerCalloutListId}-${index as number}`, containerHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.NAME_REQUIRED, minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` } }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.NAME}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.NAME_TOOLTIP}
                                        description={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.NAME_DESCRIPTION}
                                        placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="code"
                                // rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.CODE_REQUIRED }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.CODE}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.CODE_TOOLTIP}
                                        description={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.CODE_DESCRIPTION}
                                        placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.CODE_PLACEHOLDER}
                                        errorMessage={errors.code?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="description"
                                // rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.DESCRIPTION_REQUIRED }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        multiline resizable={false}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.DESCRIPTION}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.DESCRIPTION_TOOLTIP}
                                        description={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.DESCRIPTION_DESCRIPTION}
                                        placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.DESCRIPTION_PLACEHOLDER}
                                        errorMessage={errors.description?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="parentId"
                                render={({ field }) =>
                                    <SuggestedSearch
                                        type="dropdown-search"
                                        inputBoxId={containerTextId}
                                        hostId={containerHostId}
                                        // onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT_TOOLTIP}
                                        description={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT_DESCRIPTION}
                                        inputBoxPlaceholder={!containers ? strings.SPINNERS.DATA_IS_GETTING : containers.length > 0 ? (strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT_PLACEHOLDER) : strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT_NO_CONTAINERS}
                                        onClickSuggestionCell={setSelectedContainer}
                                        // setSearchRequest={(opt: IComboBoxOption) => field.onChange(opt?.text)}
                                        searchRequest={containers?.find(i => i.key === field.value)?.text}
                                        suggestionsListWidth={440}
                                        disabled={!containers || containers.length === 0}
                                        suggestions={containers ?? []}
                                        suggestionsListId={containerCalloutListId}
                                        isSearchEqualTheOneSuggestion={false}
                                        onRenderSuggestionCell={onRenderContainerSuggestionsCell}
                                        prepareFilteredItems={prepareFilteredOptions}
                                        selectedKey={selectedContainer?.key.toString()}
                                        errorMessage={errors.parentId?.message}
                                    />
                                }
                            />
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink alignment='left' articleid='1102' />
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();
