import { DefaultButton, FontWeights, getTheme, IconButton, mergeStyleSets, MessageBar, MessageBarType, Modal, ScrollablePane, Spinner, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { portalApi } from '../../core/api/api';
import { strings } from '../../localization/strings';
import { useHistory, useParams } from 'react-router';
import { IPerson, IStore } from '../../core/store';
import { StructurePerson } from './OrganizationStructure/Elements/StructurePerson/StructurePerson';
import { PersonForm } from '../../components/shared/forms/PersonForm';
import { useSelector } from 'react-redux';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';

export const OrganizationAccount: React.FC = () => {

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const [error, setError] = React.useState<string>()
    const [formError, setFormError] = React.useState<string>()

    const { accountId } = useParams<{ accountId: string }>();
    const [accountState, setAccountState] = React.useState<{ fetched: boolean, data?: IPerson }>({ fetched: false })

    const [confirmation, { setTrue: showConfirmation, setFalse: hideConfirmation }] = useBoolean(false)

    const organization = useSelector((s: IStore) => s.workplace.organization)
    const history = useHistory();

    React.useEffect(() => {
        getAccount(accountId)
    }, [])

    const getAccount = async (id: string) => {
        setError(undefined)
        const result = await portalApi.organization.accounts.getAccount(id)
        console.log(result)
        if (result.successed && result.data) {
            setAccountState({ fetched: true, data: result.data })
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation()
            setError(result.errors[0].description)
        }
    }

    const updateAccount = async (item: IPerson) => {
        setFormError(undefined)
        startFetch()
        const result = await portalApi.organization.accounts.updateAccount(item)
        if (result.successed && result.data) {
            setAccountState({ fetched: true, data: result.data })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setError(result.errors[0].description)
        }
    }

    const deleteAccount = async (id: string) => {
        startFetch()
        const result = await portalApi.organization.accounts.deleteAccount(id)
        if (result.successed) {
            history.push("/organization/access");
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation();
            setError(result.errors[0].description);
            return;
        }
        if (!result.successed) {
            setError("There is a problem! Account wasn't deleted! Server response false without an error")
        }
        stopFetch()
        hideConfirmation()
    }

    const onFormSubmit = (data: IPerson) => {
        accountState.data?.id && updateAccount(data)
    }

    return (
        <>
            <Stack verticalFill>
                {!error && (accountState.fetched && accountState.data && (
                    <Stack grow verticalFill style={{position: 'relative'}}>
                        <ScrollablePane>
                            <StructurePerson onEdit={showPanel} person={accountState.data} onDelete={showConfirmation} updateState={(data) => setAccountState({ ...accountState, data: data })} />
                        </ScrollablePane>
                    </Stack>
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    ))}
                {error && (
                    <Stack verticalFill>
                        {error.length > 0 && (
                            <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                        )}
                        <Stack.Item verticalFill>
                            <SomethingWentWrong action={() => getAccount(accountId)} />
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={hidePanel}
                title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.TITLE}
            >
                {formError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {formError}
                    </MessageBar>
                )}
                {!formError && !fetching && (
                    <PersonForm
                        onSubmit={(data: IPerson) => onFormSubmit(data)}
                        onCancel={hidePanel}
                        organizationDomain={organization?.domain}
                        organizationCountry={organization?.region}
                        data={accountState.data as IPerson} />
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
            </CustomPanel >
            {accountState.data?.id && (
                <Modal isOpen={confirmation} onDismiss={hideConfirmation} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideConfirmation}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={() => accountState.data?.id && deleteAccount(accountState.data?.id)}
                            onCancel={hideConfirmation}
                            requiredMessageString={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_AREA.USERNAMES_DO_NOT_MATCH}
                            confirmationCheckString={accountState.data?.name ?? ''}
                            placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_PLACEHOLDER}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TEXT1} <b>"{accountState.data?.name}"</b></Text>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TEXT2}</Text>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{accountState.data?.name}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>)}
        </>
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};