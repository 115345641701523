import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/workplace-actions";
import { IWorkplace } from "../store/typings/IWorkplace";

export const reducer: Reducer<IWorkplace> = (state: IWorkplace | undefined, incomingAction: Action): IWorkplace => {
    if (state === undefined) {
        return {
            isReady: false
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'WORKPLACE_REQUEST_DATA':
            return state;
        case 'WORKPLACE_RECEIVE_DATA':
            return { ...state, ...action.data, isReady: true };
        default: return state;
    }
};
