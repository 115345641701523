import React from "react";
import { MaskedTextField } from "@fluentui/react"
import { IDataCollectionStandardConfig } from "@piceasoft/core";
import { strings } from "../../../../../../../../../../../localization/strings";

type TProps = {
    value?: string
    config?: IDataCollectionStandardConfig
    errorMessage: string
    handleOnBlur: () => void
    handleOnChange: (value: string) => void
}

const SubdivisonCode: React.FunctionComponent<TProps> = (props) => {
    let {value, config, errorMessage, handleOnChange, handleOnBlur} = props
    return (
        <MaskedTextField
            value={value}
            onChange={(e, value) => { handleOnChange(value ?? "") }}
            label={config?.fields?.passport?.subdivisionCode?.label ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PASSPORT_SUBDIVISION_CODE}
            mask={config?.fields?.passport?.subdivisionCode?.mask ?? "999-999"}
            errorMessage={errorMessage}
            required
            onBlur={handleOnBlur}
            deferredValidationTime={500}
        />
    )
}

export default SubdivisonCode