import {
    Stack, getTheme, Text, IStackStyles, Dialog,
    DefaultButton, DialogFooter, DialogType,
    PrimaryButton, IDropdownOption, SpinButton, ScrollablePane, SharedColors, Link, LinkBase
} from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../core/actions/configurator-actions";
import { IWorkflow } from "../../../../core/store";
import { DeviceTypes } from "@piceasoft/core";
import { strings } from "../../../../localization/strings";
import { ParameterItem } from "../components/ParameterItem";
import { CheckWorkflowSettingsAccess } from "../helpers/evisibility";
import { IImeiLockoutConfig } from "../../../../core/store/typings/IWorkflow";
import { Experience } from "../helpers/evisibility";

type TProps = {
    config: IWorkflow
    onChangeDispatch: Dispatch<any>
    channel: number
    okButtonDisabled(arg: boolean): void
}

export const WorkflowSettings: React.FC<TProps> = ({ config, onChangeDispatch, channel, okButtonDisabled }) => {

    const [isConfimationShow, { setTrue: showConfimation, setFalse: hideConfimation }] = useBoolean(false)
    const [isConfirmDiscountCatalogs, {setTrue: showConfirmDiscount, setFalse: hideConfirmDiscount}] = useBoolean(false)

    const onUseGradesCategoriesToggle = () => {
        onChangeDispatch(actionCreators.toggleUseGradesCategories())
        hideConfimation()
    }

    const onToggleDiscountCatalogs = () => {
        onChangeDispatch(actionCreators.toggleUseDiscountCatalogs())
        hideConfirmDiscount()
    }

    React.useEffect(() => {
        if(Array.isArray(config.deviceTypes)) {
            config.deviceTypes.length === 0 ? okButtonDisabled(true) : okButtonDisabled(false);
        } else {
            okButtonDisabled(false)
        }
    }, [config.deviceTypes])

    const gradesDelimiterOptions: IDropdownOption[] = [
        { key: "-", text: "\"-\"" },
        { key: "_", text: "\"_\"" },
        { key: "/", text: "\"/\"" },
        { key: "#", text: "\"#\"" },
        { key: ":", text: "\":\"" },
        { key: ";", text: "\";\"" },
        { key: ",", text: "\",\"" },
        { key: ".", text: "\".\"" },
    ]
    const deviceTypesOptions: IDropdownOption[] = [
        {
            key: DeviceTypes.DEVICE_TYPE_MOBILE,
            text: strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES.MOBILE
        },        
        {
            key: DeviceTypes.DEVICE_TYPE_WATCH,
            text: strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES.WATCH
        },
    ]
    return (
        <Stack verticalFill grow style={{ padding: "0px 0px", position: 'relative' }}>
            <ScrollablePane>
                <Stack verticalFill grow>
                    <ParameterItem title={strings.CONSTRUCTOR.PARAMETERS.CONTROL_TRANSACTION_TITLE}
                        useToggle
                        toggleEnabled={CheckWorkflowSettingsAccess(channel, 'This is control workflow')}
                        toggleChecked={config.isControlTransaction}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.toggleIsControlTransaction())
                        }}
                        description={strings.CONSTRUCTOR.PARAMETERS.CONTROL_TRANSACTION_DESCRIPTION}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.PARAMETERS.USE_GRADES_CATEGORIES_TITLE}
                        useToggle
                        toggleEnabled={CheckWorkflowSettingsAccess(channel, 'Use grade categories')}
                        toggleChecked={config.useGradesCategories}
                        toggleOnChange={showConfimation}
                        description={strings.CONSTRUCTOR.PARAMETERS.USE_GRADES_CATEGORIES_DESCRIPTION}
                    />
                    {config.useGradesCategories && ( 
                        <React.Fragment>
                        <ParameterItem title={strings.CONSTRUCTOR.PARAMETERS.USE_DISCOUNT_CATALOGS_TITLE}
                            useToggle
                            noSeparator
                            toggleEnabled={CheckWorkflowSettingsAccess(channel, 'Use discount catalogs')}
                            toggleChecked={!!config.useDiscountCatalogs}
                            toggleOnChange={showConfirmDiscount}
                            description={strings.CONSTRUCTOR.PARAMETERS.USE_DISCOUNT_CATALOGS_DESCRIPTION}
                        />

                        <ParameterItem title={strings.CONSTRUCTOR.PARAMETERS.GRADES_DELIMITER_TITLE}
                            useToggle
                            noSeparator
                            toggleEnabled={true}
                            toggleChecked={typeof config.gradesDelimiter === 'string'}
                            toggleOnChange={() => {
                                const delimiter = typeof config.gradesDelimiter === 'string' ? undefined : ""
                                onChangeDispatch(actionCreators.setGradesDelimiter(delimiter))
                            }}
                            description={strings.CONSTRUCTOR.PARAMETERS.GRADES_DELIMITER_DESCRIPTION}
                            useDropdown
                            dropdownEnabled={typeof config.gradesDelimiter === 'string'}
                            dropdownSelectedKey={config.gradesDelimiter}
                            dropdownOnChange={(opt) => opt && onChangeDispatch(actionCreators.setGradesDelimiter(opt?.key as string))}
                            dropdownPlaceholder={strings.CONSTRUCTOR.PARAMETERS.GRADES_DELIMITER_PLACEHOLDER}
                            dropdownOptions={gradesDelimiterOptions}
                        />
                        </React.Fragment>
                    )}
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES_TITLE}
                        useToggle
                        toggleEnabled={CheckWorkflowSettingsAccess(channel, 'Filter devices by category')}
                        toggleChecked={Array.isArray(config.deviceTypes)}
                        toggleOnChange={() => {
                            const deviceTypes = Array.isArray(config.deviceTypes) ? undefined : []
                            onChangeDispatch(actionCreators.deviceTypes.setDeviceTypes(deviceTypes))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES_DESCRIPTION}
                        useDropdown
                        dropdownMultiselect
                        dropdownEnabled={Array.isArray(config.deviceTypes)}
                        dropdownSelectedKeys={config.deviceTypes}
                        dropdownOnChange={(opt) => {
                            const diviceTypes = opt?.selected ? (config.deviceTypes ? [...config.deviceTypes, opt.key as number] : [opt.key as number]) : config.deviceTypes?.filter((el) => el !== opt?.key)
                            onChangeDispatch(actionCreators.deviceTypes.setDeviceTypes(diviceTypes))
                        }}
                        dropdownPlaceholder={strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES_PLACEHOLDER}
                        dropdownOptions={deviceTypesOptions}
                        dropdownErrorMessage={config.deviceTypes?.length === 0 ? strings.CONSTRUCTOR.WARNINGS.SELECT_DEVICE_CATEGORY : undefined}
                    />
                    <ParameterItem title={strings.PROCESS.DEVICE.IMEI_LOCKOUT.TITLE}
                        useToggle
                        toggleEnabled={channel == Experience.Retail }
                        toggleChecked={config.imeiLockout ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.parameters.imeiLockoutConfiguration(!config.imeiLockout ? { period: 24 } : undefined))
                        }}
                        description={strings.PROCESS.DEVICE.IMEI_LOCKOUT.DESCRIPTION}
                    >
                        {config.imeiLockout && (
                            <Stack.Item>
                            <SpinButton
                                value={config.imeiLockout.period.toString()}
                                min={1}
                                max={336}
                                step={1}
                                style={{ width: 100 }}
                                onChange={(ev, opt) => opt && onChangeDispatch(actionCreators.parameters.imeiLockoutConfiguration({ ...config.imeiLockout, period: Number(opt) }))}
                            />
                            <Text variant="xSmall" style={{color: SharedColors.gray20}}>{strings.PROCESS.DEVICE.IMEI_LOCKOUT.HOURS_INFORMATION}</Text>
                            </Stack.Item>
                        )}
                    </ParameterItem>
                    {config.imeiLockout && (
                        <>
                            <ParameterItem title={strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_TITLE_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={typeof config.imeiLockout?.title === 'string' ? true : false}
                                toggleOnChange={() => {
                                    onChangeDispatch(actionCreators.parameters.imeiLockoutConfiguration({
                                        ...config.imeiLockout,
                                        title: typeof config.imeiLockout?.title === 'string' ? undefined : ""
                                    } as IImeiLockoutConfig))
                                }}
                                description={strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_TITLE_DESCRIPTION}
                                useTextField
                                textFieldEnabled={typeof config.imeiLockout?.title === 'string' ? true : false}
                                textFieldPlaceholder={typeof config.imeiLockout?.title === 'string' ? undefined : strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_TITLE_DEFAULT}
                                textFieldValue={config.imeiLockout?.title}
                                textFieldOnChange={(val) => {
                                    onChangeDispatch(actionCreators.parameters.imeiLockoutConfiguration({
                                        ...config.imeiLockout,
                                        title: val
                                    } as IImeiLockoutConfig))
                                }}
                            />
                            <ParameterItem title={strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_MESSAGE_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={typeof config.imeiLockout?.message === 'string' ? true : false}
                                toggleOnChange={() => {
                                    onChangeDispatch(actionCreators.parameters.imeiLockoutConfiguration({
                                        ...config.imeiLockout,
                                        message: typeof config.imeiLockout?.message === 'string' ? undefined : ""
                                    } as IImeiLockoutConfig))
                                }}
                                description={strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_MESSAGE_DESCRIPTION}
                                useTextField
                                textFieldPlaceholder={typeof config.imeiLockout?.message === 'string' ? undefined : strings.PROCESS.DEVICE.IMEI_LOCKOUT.DIALOG_MESSAGE_DEFAULT}
                                textFieldEnabled={typeof config.imeiLockout?.message === 'string' ? true : false}
                                textFieldValue={config.imeiLockout?.message}
                                textFieldMultiline
                                textFieldOnChange={(val) => {
                                    onChangeDispatch(actionCreators.parameters.imeiLockoutConfiguration({
                                        ...config.imeiLockout,
                                        message: val
                                    } as IImeiLockoutConfig))
                                }}
                            />
                        </>
                    )}
                    
                </Stack>
            </ScrollablePane>
            
            <Dialog hidden={!isConfimationShow} minWidth={320} maxWidth={360}
                modalProps={{ isBlocking: true }}
                onDismiss={hideConfimation}
                dialogContentProps={{
                    title: strings.CONSTRUCTOR.PARAMETERS.RESET_GRADE_DEPENDENCY_CONFIGURATION_TITLE,
                    // subText: strings.CONSTRUCTOR.PARAMETERS.RESET_GRADE_DEPENDENCY_CONFIGURATION_TEXT,
                    type: DialogType.largeHeader,
                    
                }}
            >
                <Stack tokens={{ childrenGap: 8 }}>
                    {/* <Text variant="mediumPlus" style={{ fontWeight: 600 }}>{strings.CONSTRUCTOR.PARAMETERS.RESET_GRADE_DEPENDENCY_CONFIGURATION_TEXT}</Text>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Text>{strings.CONSTRUCTOR.PARAMETERS.RESET_GRADE_DEPENDENCY_CONFIGURATION_TEXT2}</Text>
                        <Text>{strings.CONSTRUCTOR.PARAMETERS.RESET_GRADE_DEPENDENCY_CONFIGURATION_TEXT3}</Text>
                        <Text>{strings.CONSTRUCTOR.PARAMETERS.RESET_GRADE_DEPENDENCY_CONFIGURATION_TEXT4}</Text>
                    </Stack> */}
                </Stack>
                <DialogFooter>
                    <PrimaryButton
                        onClick={onUseGradesCategoriesToggle}
                        text={strings.BUTTONS.TEXT.CONFIRM} 
                        />
                    <DefaultButton onClick={hideConfimation} text={strings.BUTTONS.TEXT.CANCEL} />
                </DialogFooter>
            </Dialog>

            <Dialog hidden={!isConfirmDiscountCatalogs} minWidth={320} maxWidth={360}
                modalProps={{ isBlocking: true }}
                onDismiss={hideConfirmDiscount}
                dialogContentProps={{
                    title: strings.CONSTRUCTOR.PARAMETERS.RESET_DISCOUNT_CATALOG_MODE_TITLE,
                    type: DialogType.largeHeader,
                    
                }}
            >
                <Stack tokens={{ childrenGap: 8 }}>
                    {strings.CONSTRUCTOR.PARAMETERS.RESET_DISCOUNT_CATALOG_MODE_EXPLANATION}
                </Stack>
                <DialogFooter>
                    <PrimaryButton
                        onClick={onToggleDiscountCatalogs}
                        text={strings.BUTTONS.TEXT.CONFIRM} 
                        />
                    <DefaultButton onClick={hideConfirmDiscount} text={strings.BUTTONS.TEXT.CANCEL} />
                </DialogFooter>
            </Dialog>
        </Stack>
        
    )
}

const theme = getTheme();

const contentStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0", zIndex: 10, minHeight: 480 } };
