import { IDropdownOption, Stack } from "@fluentui/react"
import React, { Dispatch, useEffect } from "react";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";
import { IResultConfig } from "@piceasoft/core";

type TProps = {
    config: IResultConfig
    onChangeDispatch: Dispatch<any>
    channel: number
}

export const ResultUserSatisfactionSurveyChildren: React.FC<TProps> = ({ onChangeDispatch, config, channel }) => {
    const customCsatRatingScaleOptions: IDropdownOption[] = [
        { key: 5, text: "5" },
        { key: 4, text: "4" },
        { key: 3, text: "3" },
    ]
    const [csatToggleEnabled, setCsatToggleEnabled] = React.useState(true);
    const [npsToggleEnabled, setNpsToggleEnabled] = React.useState(true);
    const [customToggleEnabled, setCustomToggleEnabled] = React.useState(false);
    useEffect(()=>{
        let ussObject = config.userSatisfactionSurvey;
        if(ussObject?.csatSurvey === undefined && ussObject?.npsSurvey === undefined && ussObject?.customSurvey === undefined){
            setCsatToggleEnabled(true);
            setNpsToggleEnabled(true);
        }
        if(ussObject?.csatSurvey !== undefined && ussObject.csatSurvey ===true){
            setNpsToggleEnabled(true);
            setCustomToggleEnabled(true);
        }
        if(ussObject?.npsSurvey !== undefined && ussObject.npsSurvey ===true){
            setCsatToggleEnabled(true);
            setCustomToggleEnabled(true);
        }
    })

    /**
     * Does some config preprosessing before saving
     * @param config 
     */
    const onCommitConfig = (config: IResultConfig) => {
        if (config.userSatisfactionSurvey) {

            if (config.userSatisfactionSurvey.csatSurvey === true) {
                // survey enabled, make sure scale and title array match

                if (config.userSatisfactionSurvey.customCsatResponses) {

                    if (!config.userSatisfactionSurvey.customCsatRating) {
                        config.userSatisfactionSurvey.customCsatRatingScale = undefined;
                    }
                    else if (typeof config.userSatisfactionSurvey.customCsatRatingScale !== 'number' ) {
                        config.userSatisfactionSurvey.customCsatRatingScale = 5;
                    }
                    // make sure customCsatResponseTitles array size is correct
                    resizeCustomCsatResponseTitlesArray(config);
                }
                else {
                    config.userSatisfactionSurvey.customCsatResponseTitles = undefined;
                }
            }
            else {
                config.userSatisfactionSurvey.customCsatRating = false;
                config.userSatisfactionSurvey.customCsatRatingScale = undefined;
                config.userSatisfactionSurvey.customCsatResponses = false;
                config.userSatisfactionSurvey.customCsatResponseTitles = undefined;
            }
        }

        onChangeDispatch(config);
    }

    const onToggleChange =(type: string)=>{
        switch(type) {
            case 'CSAT':
                let csatSurvey = config.userSatisfactionSurvey?.csatSurvey === undefined  ||  config.userSatisfactionSurvey?.csatSurvey === false ? true : false;
                onCommitConfig({
                    ...config,
                    userSatisfactionSurvey: {
                        ...config?.userSatisfactionSurvey,
                        csatSurvey: csatSurvey,
                        npsSurvey:false,
                        customSurvey: false,
                        customSurveyQuestion:'',
                        customCsatRating:csatSurvey === true ? true : false,
                        customCsatRatingScale:csatSurvey === true ? 5: undefined,
                        customCsatResponses:csatSurvey === true ? true : false,
                        customCsatResponseTitles:[]
                    }
                });
                setNpsToggleEnabled(!csatSurvey);
                setCustomToggleEnabled(csatSurvey);
              break;
            case 'NPS':
                let npsSurvey = config.userSatisfactionSurvey?.npsSurvey === undefined  ||  config.userSatisfactionSurvey?.npsSurvey === false ? true : false
                onCommitConfig({
                    ...config,
                    userSatisfactionSurvey: {
                        ...config?.userSatisfactionSurvey,
                        csatSurvey: false,
                        npsSurvey: npsSurvey,
                        customSurvey: false,
                        customSurveyQuestion:''
                    }
                });
                setCustomToggleEnabled(npsSurvey);
                setCsatToggleEnabled(!npsSurvey);
              break;
            case 'Custom':
                let customSurvey =config.userSatisfactionSurvey?.customSurvey === undefined  ||  config.userSatisfactionSurvey?.customSurvey === false ? true : false
                onCommitConfig({
                    ...config,
                    userSatisfactionSurvey: {
                        ...config?.userSatisfactionSurvey,
                        customSurvey: customSurvey,
                        customSurveyQuestion:'' 
                    }
                });
              break;
            default:
              // code block
          }
    }

    /**
     * Modifyes customCsatResponseTitles array in config to expected size
     * @param config 
     */
    const resizeCustomCsatResponseTitlesArray = (config: IResultConfig) => {
        if (!config.userSatisfactionSurvey)
            return;

        const count = config?.userSatisfactionSurvey?.customCsatRatingScale ?? 5;

        if (config.userSatisfactionSurvey.customCsatResponseTitles === undefined) {
            config.userSatisfactionSurvey.customCsatResponseTitles = [];
        }

        if (config.userSatisfactionSurvey.customCsatResponseTitles.length !== count) {

          for (let i = 1; i < count+1; i++) {
            const indexExists = config.userSatisfactionSurvey.customCsatResponseTitles.some(obj => obj.code === i);
            if (!indexExists) {
                config.userSatisfactionSurvey.customCsatResponseTitles.push({ code: i, title: "" });
            }
          }
          // Sort the array by code
          config.userSatisfactionSurvey.customCsatResponseTitles.sort((a, b) => (a.code ?? 0) - (b.code ?? 0));

          // Remove extra elements
          config.userSatisfactionSurvey.customCsatResponseTitles = config.userSatisfactionSurvey.customCsatResponseTitles.filter(obj => (obj.code ?? 0) < count+1);
        }
    }

    const onResponseChange=(code: number, newTitle: string)=>{
        let oldTitles = config?.userSatisfactionSurvey?.customCsatResponseTitles;

        if (!oldTitles)
            return;
        
        const foundIndex = oldTitles.findIndex((obj) => obj.code === code);
    
        if (foundIndex !== -1) {
            oldTitles[foundIndex] = { ...oldTitles[foundIndex], title: newTitle };
        } else {
            // not found? Should not happen as array is already created to correct size
            return;
        }
        
        onCommitConfig({
            ...config,
            userSatisfactionSurvey: {
                ...config?.userSatisfactionSurvey,
                customCsatResponseTitles: oldTitles               
            }
        });
    }

    const getResponseTitle =(index:number)=>{
        switch (index){
            case 1:
                return strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RESPONSES_TITLE_RESPONSE_1;
            case 2:
                return strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RESPONSES_TITLE_RESPONSE_2;
            case 3:
                return strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RESPONSES_TITLE_RESPONSE_3;
            case 4:
                return strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RESPONSES_TITLE_RESPONSE_4;
            case 5:
                return strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RESPONSES_TITLE_RESPONSE_5;        
            default:
                return '';    
        }
    }

    const renderResponses=()=>{
        const components = [];
        const textFieldEnable = config?.userSatisfactionSurvey?.customCsatResponses === undefined  ||  config?.userSatisfactionSurvey?.customCsatResponses === false ? false : true;
        const scale = config.userSatisfactionSurvey?.customCsatRatingScale ?? 5;
        for (let i = 1; i <= scale; i++) {
            components.push(<ParameterItem title={getResponseTitle(i)}
                useToggle ={false}
                useTextField
                textFieldMaxLength={40}
                textFieldEnabled={textFieldEnable}
                textFieldValue={config.userSatisfactionSurvey?.customCsatResponseTitles?.find(x=>x.code == i)?.title}
                textFieldOnChange={(val) => onResponseChange(i,val)
                }
            />);
        }
        return components;
      
    }
    return (
    <Stack style={{ padding: "0px 0px" }}>
       <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CSAT_SURVEY_TITLE}
                useToggle
                toggleEnabled={csatToggleEnabled}
                textFieldMaxLength={50}
                toggleChecked={config?.userSatisfactionSurvey?.csatSurvey === undefined  ||  config?.userSatisfactionSurvey?.csatSurvey === false ? false : true}
                toggleOnChange={
                ()=>onToggleChange("CSAT")
            }
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CSAT_SURVEY_DESCRIPTION}
        />
        <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.NPS_SURVEY_TITLE}
                useToggle
                toggleEnabled={npsToggleEnabled}
                toggleChecked={config?.userSatisfactionSurvey?.npsSurvey === undefined  ||  config?.userSatisfactionSurvey?.npsSurvey === false ? false : true}
                toggleOnChange={
                    ()=>onToggleChange('NPS')
            }
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.NPS_SURVEY_DESCRIPTION}
        />
         <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_SURVEY_QUESTION_TITLE}
                useToggle
                toggleEnabled={customToggleEnabled}
                toggleChecked={config?.userSatisfactionSurvey?.customSurvey === undefined  ||  config?.userSatisfactionSurvey?.customSurvey === false ? false : true}
                toggleOnChange={
                    ()=> onToggleChange('Custom')
            }
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_SURVEY_QUESTION_DESCRIPTION}
                useTextField
                    textFieldEnabled={config.userSatisfactionSurvey?.customSurvey !== undefined && config.userSatisfactionSurvey?.customSurvey !==false ? true : false}
                    textFieldValue={config.userSatisfactionSurvey?.customSurveyQuestion}
                    textFieldMaxLength={200}
                    textFieldMultiline={true}
                    textFieldOnChange={(val) => onCommitConfig({
                        ...config,
                        userSatisfactionSurvey: {
                            ...config?.userSatisfactionSurvey,
                            customSurveyQuestion: val
                        }
                    })
                    }
        />
        {config.userSatisfactionSurvey?.csatSurvey !== undefined  &&  config.userSatisfactionSurvey?.csatSurvey === true &&
            <>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RATING_SCALE_TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={config?.userSatisfactionSurvey?.customCsatRating === undefined  ||  config?.userSatisfactionSurvey?.customCsatRating === false ? false : true}
                toggleOnChange={() => {
                    onCommitConfig({
                        ...config,
                        userSatisfactionSurvey: {
                            ...config?.userSatisfactionSurvey,
                            customCsatRating: config.userSatisfactionSurvey?.customCsatRating === undefined  ||  config.userSatisfactionSurvey?.customCsatRating === false ? true : false
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RATING_SCALE_DESCRIPTION}
                useDropdown
                dropdownEnabled={config.userSatisfactionSurvey?.customCsatRating ===undefined ||  config.userSatisfactionSurvey?.customCsatRating === false?false:true}
                dropdownSelectedKey={config.userSatisfactionSurvey?.customCsatRatingScale !== undefined ?config.userSatisfactionSurvey?.customCsatRatingScale: customCsatRatingScaleOptions.reduce((max, current) => (current.key > max.key ? current : max), customCsatRatingScaleOptions[0]).key }
                dropdownOnChange={(opt) => {
                    console.log("dropdownOnChange: ");
                    console.log(opt);
                    if (opt) {

                        onCommitConfig(
                        {
                            ...config,
                            userSatisfactionSurvey: {
                                ...config?.userSatisfactionSurvey,
                                customCsatRatingScale: convertToNumber(opt.key)
                            }
                        });
                    }
                }}
                
                dropdownOptions={customCsatRatingScaleOptions}
            />

            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RESPONSES_TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={config?.userSatisfactionSurvey?.customCsatResponses === undefined  ||  config?.userSatisfactionSurvey?.customCsatResponses === false ? false : true}
                toggleOnChange={() => {
                    onCommitConfig({
                        ...config,
                        userSatisfactionSurvey: {
                            ...config?.userSatisfactionSurvey,
                            customCsatResponses: config.userSatisfactionSurvey?.customCsatResponses === undefined  ||  config.userSatisfactionSurvey?.customCsatResponses === false ? true : false,
                            customCsatResponseTitles: []
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.CUSTOM_RESPONSES_DESCRIPTION}
            />
            {renderResponses()}
            </>
        }
        <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.OPEN_ENDED_QUESTION_TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={config?.userSatisfactionSurvey?.openEndedQuestion === undefined  ||  config?.userSatisfactionSurvey?.openEndedQuestion === false ? false : true}
                toggleOnChange={() => {
                    onCommitConfig({
                        ...config,
                        userSatisfactionSurvey: {
                            ...config?.userSatisfactionSurvey,
                            openEndedQuestion: config.userSatisfactionSurvey?.openEndedQuestion === undefined  ||  config.userSatisfactionSurvey?.openEndedQuestion === false ? true : false,
                            openEndedQuestionTitle: ''
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.USER_SATISFACTION_SURVEY.OPEN_ENDED_QUESTION_DESCRIPTION}
                useTextField
                    textFieldEnabled={config.userSatisfactionSurvey?.openEndedQuestion !== undefined && config.userSatisfactionSurvey?.openEndedQuestion !==false ? true : false}
                    textFieldValue={config.userSatisfactionSurvey?.openEndedQuestionTitle}
                    textFieldMaxLength={200}
                    textFieldMultiline={true}
                    textFieldOnChange={(val) => onCommitConfig({
                        ...config,
                        userSatisfactionSurvey: {
                            ...config?.userSatisfactionSurvey,
                            openEndedQuestionTitle: val
                        }
                    })
                    }
        />
                </Stack>
    )
}

function convertToNumber(value: string | number): number | undefined {
    if (typeof value === 'number') {
      return value;
    } else if (typeof value === 'string') {
      const parsedNumber = parseInt(value);
      if (!isNaN(parsedNumber)) {
        return parsedNumber;
      }
    }
    return undefined;
  }