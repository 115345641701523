import { merge } from "lodash"
import LocalizedStrings from "react-localization";
import { strings as localization } from "./ng-loc";

type TNewStringsLang = {
  
}

type TNewStrings = {
    EN: TNewStringsLang
    RU: TNewStringsLang
    FR: TNewStringsLang
    IT: TNewStringsLang
    ES: TNewStringsLang
    DE: TNewStringsLang
    PT_BR: TNewStringsLang
    PT: TNewStringsLang
    TR: TNewStringsLang
    EL: TNewStringsLang
    NL: TNewStringsLang
    BG: TNewStringsLang
}

const newStrings: TNewStrings = {
    EN: {
        
    },
    RU: {
        
    },
    FR: {
        
    },
    IT: {
        
    },
    ES: {
       
    },
    DE: {
     
    },
    PT: {
     
    },
    PT_BR: {
     
    },
    TR: {
     
    },
    EL: {

    },
    NL: {

    },
    BG: {

    },
}
export const strings = new LocalizedStrings({
    en: merge(newStrings.EN, localization.EN),
    ru: merge(newStrings.RU, localization.RU),
    fr: merge(newStrings.FR, localization.FR),
    es: merge(newStrings.ES, localization.ES),
    it: merge(newStrings.IT, localization.IT),
    de: merge(newStrings.DE, localization.DE),
    pt: merge(newStrings.PT, localization.PT),
    pt_br: merge(newStrings.PT_BR, localization.PT_BR),
    tr: merge(newStrings.TR, localization.TR),
    el: merge(newStrings.EL, localization.EL),
    nl: merge(newStrings.NL, localization.NL),
    bg: merge(newStrings.BG, localization.BG),
}, 
{
    logsEnabled: false
});
