import { Action, Reducer } from "redux";
import { IValidationTokenResponse } from "../store/typings/IValidationTokenResponse"; 
import { KnownAction } from "../actions/validation-actions";
import { IValidationToken } from "../store/typings/IValidationToken";

export const reducer: Reducer<IValidationTokenResponse> = (state: IValidationTokenResponse | undefined, incomingAction: Action): IValidationTokenResponse => {
    if (state === undefined) {
        return {
            structure: {
                data_tokens: {
                    data: {
                        status: 0,
                        tokens: []
                    }
                },
                data_apis: {
                    data: {
                        status: 0,
                        apis: [],
                    }
                },
                isReady: false
            }
        };
    }

    const action = incomingAction as KnownAction;
    switch(action.type){
        case 'VALIDATION_API_TOKEN_REQUEST':
            return state;
        case 'VALIDATION_API_TOKEN_RECEIVE':
                return { 
                    ...state,
                    structure: {  
                        ...state.structure,
                        isReady: true,
                        data_tokens: {
                            ...state.structure.data_tokens,
                            ...action.data
                        }
                }}
        case 'VALIDATION_API_APIS_REQUEST':
            return state;
        case 'VALIDATION_API_APIS_RECEIVE':
            return { 
                ...state, 
                structure: {
                    ...state.structure,
                    isReady: true, 
                    data_apis: {
                        ...state.structure.data_apis,
                        ...action.data
                    }
                }}
        case "VALIDATION_API_TOKEN_CREATE":
            return {
                ...state,
                structure: {
                    ...state.structure,
                    isReady: true,
                    data_tokens: {
                        data: {
                            ...state.structure.data_tokens,
                            tokens: [...state.structure.data_tokens.data.tokens, action.data],
                            status: 0
                        }
                    }
                }
            }
        case 'VALIDATION_API_TOKEN_EDIT':
            return {
                ...state,
                structure: {
                    ...state.structure,
                    isReady: true,
                    data_tokens: {
                        data: {
                            ...state.structure.data_tokens,
                            tokens: state.structure.data_tokens.data.tokens.map((e: IValidationToken) => {
                                    if (e.token === action.data.token) {
                                        return action.data;
                                    }
                                    return e;
                            }),
                            status: 0
                        }
                    }
                }
            }
        case "VALIDATION_API_TOKEN_ENABLE":
            return {
                ...state,
                structure: {
                    ...state.structure,
                    isReady: true,
                    data_tokens: {
                        data: {
                            ...state.structure.data_tokens,
                            tokens: state.structure.data_tokens.data.tokens.map((e: IValidationToken) => {
                                if (e.token === action.data.token) {
                                    return {
                                        ...e,
                                        is_valid: !e.is_valid,
                                    }
                                }
                                return e;
                            }),
                            status: 0
                        }
                    }
                }
            }
        default: return state;
    }
}