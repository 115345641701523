import { Stack, ScrollablePane } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { Section } from "../../../../../decorations/Section";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationScannerConfig } from "@piceasoft/core";
import { ScannerIdentificationTypes, IIdentificationScannerConfigUI } from '@piceasoft/core';

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const ScannerComponentUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationScannerConfig)

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.COMPONENT_UI.HEADER} max flat>
                    {config.type === ScannerIdentificationTypes.Imei && (
                        <>
                            <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.HEADER_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={config.ui?.componentText?.imei?.header !== undefined ? true : false}
                                toggleOnChange={() => {
                                    props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        ui: {
                                            ...config.ui,
                                            componentText: {
                                                ...config.ui?.componentText,
                                                imei: {
                                                    ...(config.ui?.componentText?.imei ?? {}),
                                                    header: config.ui?.componentText?.imei?.header !== undefined ? undefined : ""
                                                }
                                            }
                                        } as IIdentificationScannerConfigUI
                                    }))
                                }}
                                description={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.HEADER_DESCRIPTION}
                                useTextField
                                textFieldEnabled={config.ui?.componentText?.imei?.header !== undefined ? true : false}
                                textFieldValue={config.ui?.componentText?.imei?.header}
                                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    ui: {
                                        ...config.ui,
                                        componentText: {
                                            ...config.ui?.componentText,
                                            imei: {
                                                ...config.ui?.componentText?.imei,
                                                header: val
                                            }
                                        }
                                    } as IIdentificationScannerConfigUI
                                }))}
                            />
                            <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.DESCRIPTION_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={config.ui?.componentText?.imei?.description !== undefined ? true : false}
                                toggleOnChange={() => {
                                    props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        ui: {
                                            ...config.ui,
                                            componentText: {
                                                ...config.ui?.componentText,
                                                imei: {
                                                    ...(config.ui?.componentText?.imei ?? {}),
                                                    description: config.ui?.componentText?.imei?.description !== undefined ? undefined : ""
                                                }
                                            }
                                        } as IIdentificationScannerConfigUI
                                    }))
                                }}
                                description={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.DESCRIPTION_DESCRIPTION}
                                useTextField
                                textFieldEnabled={config.ui?.componentText?.imei?.description !== undefined ? true : false}
                                textFieldValue={config.ui?.componentText?.imei?.description}
                                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    ui: {
                                        ...config.ui,
                                        componentText: {
                                            ...config.ui?.componentText,
                                            imei: {
                                                ...config.ui?.componentText?.imei,
                                                description: val
                                            }
                                        }
                                    } as IIdentificationScannerConfigUI
                                }))}
                            />
                            <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.INFORMATION_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={config.ui?.componentText?.imei?.information !== undefined ? true : false}
                                toggleOnChange={() => {
                                    props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        ui: {
                                            ...config.ui,
                                            componentText: {
                                                ...config.ui?.componentText,
                                                imei: {
                                                    ...(config.ui?.componentText?.imei ?? {}),
                                                    information: config.ui?.componentText?.imei?.information !== undefined ? undefined : ""
                                                }
                                            }
                                        } as IIdentificationScannerConfigUI
                                    }))
                                }}
                                description={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.INFORMATION_DESCRIPTION}
                                useTextField
                                textFieldMultiline
                                textFieldEnabled={config.ui?.componentText?.imei?.information !== undefined ? true : false}
                                textFieldValue={config.ui?.componentText?.imei?.information}
                                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    ui: {
                                        ...config.ui,
                                        componentText: {
                                            ...config.ui?.componentText,
                                            imei: {
                                                ...config.ui?.componentText?.imei,
                                                information: val
                                            }
                                        }
                                    } as IIdentificationScannerConfigUI
                                }))}
                            />
                        </>
                    )}
                    {config.type === ScannerIdentificationTypes.Label && (
                        <>
                            <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.HEADER_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={config.ui?.componentText?.label?.header !== undefined ? true : false}
                                toggleOnChange={() => {
                                    props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        ui: {
                                            ...config.ui,
                                            componentText: {
                                                ...config.ui?.componentText,
                                                label: {
                                                    ...(config.ui?.componentText?.label ?? {}),
                                                    header: config.ui?.componentText?.label?.header !== undefined ? undefined : ""
                                                }
                                            }
                                        } as IIdentificationScannerConfigUI
                                    }))
                                }}
                                description={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.HEADER_DESCRIPTION}
                                useTextField
                                textFieldEnabled={config.ui?.componentText?.label?.header !== undefined ? true : false}
                                textFieldValue={config.ui?.componentText?.label?.header}
                                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    ui: {
                                        ...config.ui,
                                        componentText: {
                                            ...config.ui?.componentText,
                                            label: {
                                                ...config.ui?.componentText?.label,
                                                header: val
                                            }
                                        }
                                    } as IIdentificationScannerConfigUI
                                }))}
                            />
                            <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.DESCRIPTION_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={config.ui?.componentText?.label?.description !== undefined ? true : false}
                                toggleOnChange={() => {
                                    props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        ui: {
                                            ...config.ui,
                                            componentText: {
                                                ...config.ui?.componentText,
                                                label: {
                                                    ...(config.ui?.componentText?.label ?? {}),
                                                    description: config.ui?.componentText?.label?.description !== undefined ? undefined : ""
                                                }
                                            }
                                        } as IIdentificationScannerConfigUI
                                    }))
                                }}
                                description={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.DESCRIPTION_DESCRIPTION}
                                useTextField
                                textFieldEnabled={config.ui?.componentText?.label?.description !== undefined ? true : false}
                                textFieldValue={config.ui?.componentText?.label?.description}
                                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    ui: {
                                        ...config.ui,
                                        componentText: {
                                            ...config.ui?.componentText,
                                            label: {
                                                ...config.ui?.componentText?.label,
                                                description: val
                                            }
                                        }
                                    } as IIdentificationScannerConfigUI
                                }))}
                            />
                            <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.INFORMATION_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={config.ui?.componentText?.label?.information !== undefined ? true : false}
                                toggleOnChange={() => {
                                    props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        ui: {
                                            ...config.ui,
                                            componentText: {
                                                ...config.ui?.componentText,
                                                label: {
                                                    ...(config.ui?.componentText?.label ?? {}),
                                                    information: config.ui?.componentText?.label?.information !== undefined ? undefined : ""
                                                }
                                            }
                                        } as IIdentificationScannerConfigUI
                                    }))
                                }}
                                description={strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.INFORMATION_DESCRIPTION}
                                useTextField
                                textFieldMultiline
                                textFieldEnabled={config.ui?.componentText?.label?.information !== undefined ? true : false}
                                textFieldValue={config.ui?.componentText?.label?.information}
                                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    ui: {
                                        ...config.ui,
                                        componentText: {
                                            ...config.ui?.componentText,
                                            label: {
                                                ...config.ui?.componentText?.label,
                                                information: val
                                            }
                                        }
                                    } as IIdentificationScannerConfigUI
                                }))}
                            />
                            {/* <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.LABEL.CAMERA_HINT_TITLE}
                                useToggle
                                toggleEnabled
                                toggleChecked={config.ui?.componentText?.label?.cameraHint !== undefined ? true : false}
                                toggleOnChange={() => {
                                    props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        ui: {
                                            ...config.ui,
                                            componentText: {
                                                ...config.ui?.componentText,
                                                label: {
                                                    ...(config.ui?.componentText?.label ?? {}),
                                                    cameraHint: config.ui?.componentText?.label?.cameraHint !== undefined ? undefined : ""
                                                }
                                            }
                                        } as IIdentificationScannerConfigUI
                                    }))
                                }}
                                description={strings.IDENTIFICATION.SCANNER.COMPONENT.LABEL.CAMERA_HINT_DESCRIPTION}
                                useTextField
                                textFieldEnabled={config.ui?.componentText?.label?.cameraHint !== undefined ? true : false}
                                textFieldValue={config.ui?.componentText?.label?.cameraHint}
                                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    ui: {
                                        ...config.ui,
                                        componentText: {
                                            ...config.ui?.componentText,
                                            label: {
                                                ...config.ui?.componentText?.label,
                                                cameraHint: val
                                            }
                                        }
                                    } as IIdentificationScannerConfigUI
                                }))}
                            /> */}
                        </>
                        
                    )}
                </Section>
            </ScrollablePane>
        </Stack.Item>
    )
}