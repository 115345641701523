import {
    Stack, TextField, Text, IDropdownProps, ITextFieldProps, SharedColors, getTheme
} from "@fluentui/react";
import React, { CSSProperties} from "react";
import { strings } from "../../../../localization/strings";

type TProps = {
    required?: boolean
    style?: CSSProperties | undefined;
    onRenderLabel?: (props: ITextFieldProps | IDropdownProps | undefined) => JSX.Element
    onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
    maxLength?: number
    value?: string
    disabled?:boolean
    placeholder?: string
    label?: string
    multiline?: boolean
    textFieldMultiLimitSymbolsCount?: number
    title?:string
    errorMessage?: string | JSX.Element
    draggable?: boolean | undefined;
    pattern?: string | undefined;
    onBlur?: () => void;

}
const theme = getTheme();
const placeholderColor = `${theme.palette.neutralSecondary} !important`;
export const inputStyles = {
    field: {
        '::placeholder': {
            color: placeholderColor,
        },
        ':-ms-input-placeholder': {
            color: placeholderColor,
        },
        '::-ms-input-placeholder': {
            color: placeholderColor,
        },
    },
};

export const ParameterText: React.FC<TProps> = (props) => {
    return (
        <Stack>
            <TextField
                required={props.required}
                disabled={props.disabled}
                placeholder={props.placeholder}
                onRenderLabel={props.onRenderLabel}
                maxLength={props.maxLength}
                label={props.label}
                multiline={props.multiline ?? ((props.textFieldMultiLimitSymbolsCount && props.value) ? props.value.length > props.textFieldMultiLimitSymbolsCount : false)}
                value={props.value}
                onChange={(ev, value) => props.onChange && props.onChange(ev,value)}
                title={props.title}
                errorMessage={props.errorMessage}
                pattern={props.pattern}
                draggable = {props.draggable}
                onBlur= {props.onBlur}
                styles={{field: inputStyles.field}}
                style={props.style}
            />
            {props.maxLength && (
                <Text variant="small" style={{ color: SharedColors.gray10 }}>
                    {strings.TRANSACTION.RESULT.INPUT_LIMIT.replace('{0}', (props.maxLength ?? 0).toString())
                        .replace('{0}', (props.maxLength ?? 0).toString()).replace('{1}', (props.value ?? '').length.toString())}
                </Text>)}
        </Stack>
    )
}
