import * as React from 'react';
import { DefaultButton, getTheme, MessageBar, MessageBarType, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { useEffect, useState } from 'react';
import s from '../../../../../../../assets/css/scrollHide.module.css'
import { IPhotographic } from '../../../../../../../core/store';
import { IPhotographicConfig } from '@piceasoft/core'
import { strings } from '../../../../../../../localization/strings';
import Dropzone from '../../../../../dropzone/Dropzone';
import { uploadFileType } from '../PhotographicStatePreview';
import { FileCard } from './FileCard';

type TProps = {
    onClose?: () => void
    onResult?: (result: IPhotographic) => void
    onPending: (files: Array<uploadFileType>, isAdditional?: boolean, comment?: string) => void
    buttonsAlignCenter?: boolean
    moderatorComment?: string
    qrCode?: string
    config?: IPhotographicConfig
}

export const RequestPreview: React.FC<TProps> = (props) => {

    const AllowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif"]
    const [files, setFiles] = useState<Array<uploadFileType>>([])
    const [commentWarning, setCommentWarning] = useState<string>("")
    const [warnings, setWarnings] = useState<string[]>([])
    const [comment, setComment] = useState<string>("")
    const [requireComment, setRequireComment] = useState(props.config?.request?.comment?.required === false ? false : (props.config?.request?.comment && true))

    const updateCommentWarning = () => {
        let uWarning = ""
        if (requireComment && (!comment || comment.length < (props.config?.request?.comment?.minLength ?? 3))) {
            uWarning = strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COMMENT_MIN_LENGTH
        }
        setCommentWarning(uWarning)
    }

    useEffect(() => {
        setRequireComment(props.config?.request?.comment?.required === false ? false : (props.config?.request?.comment && true))
    }, [props.config?.request?.comment, props.config?.request?.comment?.required])

    useEffect(() => {
        updateCommentWarning()
    }, [comment, files, requireComment, props.config?.request?.comment?.minLength, props.config?.request?.comment?.required, props.config?.request?.comment])

    const addFiles = (upFiles: FileList) => {
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => AllowedFileTypes.includes(upFile.type))]
        if (checkedUpFiles) {
            setFiles([...files, ...(checkedUpFiles.map(upFile => upFile &&
            {
                imgUrl: URL.createObjectURL(upFile),
                data: upFile
            }) as Array<uploadFileType>)])
        }

        const skippedFilesLength = upFiles.length - checkedUpFiles.length
        const newWarnings = warnings?.filter(v => ![strings.INSPECTIONS.PHOTOGRAPHIC.FILE_FORMAT_NOT_SUPPORT, strings.INSPECTIONS.PHOTOGRAPHIC.FILES_FORMAT_NOT_SUPPORT].includes(v))
        if (skippedFilesLength > 0) {
            if (upFiles.length > 1) {
                setWarnings([...newWarnings, strings.INSPECTIONS.PHOTOGRAPHIC.FILES_FORMAT_NOT_SUPPORT])
            } else {
                setWarnings([...newWarnings, strings.INSPECTIONS.PHOTOGRAPHIC.FILE_FORMAT_NOT_SUPPORT])
            }
        } else {
            setWarnings(newWarnings)
        }
    }

    const deleleFile = (imgUrl: string) => {
        URL.revokeObjectURL(files.find(f => f.imgUrl === imgUrl)?.imgUrl as string)
        setFiles([...files.filter(f => f.imgUrl !== imgUrl)])
    }

    const confirmHandler = () => {
        if (props.onClose !== undefined) {
            props.onClose()
        }
        setTimeout(() => {
            props.onPending(files, true, comment)
        }, 1000)
    }

    const cancelHandler = () => {
        if (props.onClose !== undefined) {
            props.onClose()
        }
    }

    return (
        <Stack className={s.scrollHide} grow tokens={{ childrenGap: 16, padding: '64px 32px 32px 32px' }} style={{ backgroundColor: theme.palette.white }}>
            {props.moderatorComment && (
                <Stack tokens={{ childrenGap: 4 }}>
                    <Text variant="mediumPlus"><b>{strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.MODERATOR_MESSAGE}</b></Text>
                    <Text variant="medium" >{props.moderatorComment}</Text>
                </Stack>
            )}
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <Dropzone minHeight={320} filesLenght={files.length} handleDrop={addFiles} qrCode={props.qrCode}>
                        <Stack horizontal wrap tokens={{ padding: 16, childrenGap: 8 }}>
                            {files.map((f, i) =>
                                <FileCard
                                    key={f.imgUrl}
                                    status={strings.SPINNERS.LOADING}
                                    deleteFile={() => deleleFile(f.imgUrl)}
                                    iconName={'FileImage'}
                                    statusIcon=''
                                    title={f.data.name}
                                    imgUrl={f.imgUrl}
                                />
                            )}
                        </Stack>
                    </Dropzone>
                </Stack.Item>
                <Stack tokens={{ childrenGap: 12 }}>
                    {props.config?.request?.comment && (
                        <TextField value={comment} onChange={(e, v) => setComment(v ?? "")} label={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.OPERATOR_MESSAGE} placeholder={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.OPERATOR_MESSAGE_PLACEHOLDER} />
                    )}
                    <Stack.Item>
                        {commentWarning.length > 0 && (
                            <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                {commentWarning}
                            </MessageBar>
                        )}
                        {warnings.map(i => (
                            <MessageBar messageBarType={MessageBarType.error} isMultiline>{i}</MessageBar>
                        ))}
                    </Stack.Item>
                </Stack>
                <Stack horizontal horizontalAlign={props.buttonsAlignCenter === true && "center" || "end"} tokens={{ childrenGap: 8 }}>
                    <PrimaryButton onClick={confirmHandler} disabled={(requireComment && commentWarning.length !== 0) || files.length === 0}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                    {props.onClose !== undefined && <DefaultButton disabled onClick={cancelHandler}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>}
                </Stack>
            </Stack>
        </Stack>
    )
}
const theme = getTheme();