import { TextField } from "@fluentui/react"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import { strings } from "../../../../../../../../../../../localization/strings"

type TProps = {
    value?: string
    config?: IDataCollectionStandardConfig
    errorMessage: string
    handleOnBlur: () => void
    handleOnChange: (value?: string) => void
}

const PlaceOfBirth: React.FunctionComponent<TProps> = (props: TProps) => {
    let {value, config, errorMessage, handleOnChange, handleOnBlur} = props
    return (
        <TextField 
            value={value}
            onChange={(e, value) => { handleOnChange(value) }}
            label={config?.fields?.passport?.placeOfBirth?.label ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.BIRTH_PLACE}
            placeholder={config?.fields?.passport?.placeOfBirth?.placeholder ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PLACEHOLDER_BIRTH_PLACE}
            minLength={config?.fields?.passport?.placeOfBirth?.minLength}
            maxLength={config?.fields?.passport?.placeOfBirth?.maxLength}
            errorMessage={errorMessage}
            onBlur={handleOnBlur}
            deferredValidationTime={1000}
            required
        />
    )
}

export default PlaceOfBirth