import { IPoint } from '../../../core/store';
import React, { useRef, SetStateAction, Dispatch } from 'react'
import './map.css'
import { getTheme } from "@fluentui/react";
import { faLocationDot as ficon } from "@fortawesome/free-solid-svg-icons";


interface IAutocomplete {
    value: IPlaceID
}

interface IPlaceID {
    place_id: string;
}
interface ICoordinates {
    lat: number,
    lng: number,
    address: string
}

interface IMap {
    mapType: google.maps.MapTypeId,
    mapTypeControl?: boolean,
    autocomplete?: IAutocomplete
    // setAutocomplete?: Dispatch<SetStateAction<IAutocomplete | undefined>>,
    SetCoordinates: Dispatch<SetStateAction<ICoordinates>>
    formdata?: IPoint
}

interface IMarker {
    address: string,
    latitude: number,
    longitude: number
}


type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;
type GoogleMarker = google.maps.Marker;
type GoogleGeocoderResult = google.maps.places.AutocompletePrediction;
// type GoogleAutocomplete = google.maps.places.Autocomplete;

interface IStoresResponse {
    stores?: Array<IPoint>
}


const GoogleMaps: React.FC<IMap> = ({ mapType, mapTypeControl = false, autocomplete, SetCoordinates, formdata }) => {

    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<GoogleMap>();
    const [marker, setMarker] = React.useState<IMarker>();
    const [markers, setMarkers] = React.useState<Array<GoogleMarker>>([]);

    const startMap = (): void => {
        if (!map) {
            defaultMapStart();
        }
    };

    React.useEffect(startMap, [map]);

    const defaultMapStart = (): void => {
        // Piceasoft OY default coords
        let lat = 61.50211117917764;
        let lng = 23.757585883140564;
        if (formdata && formdata.lat && formdata.lng) {
            lat = parseFloat(formdata.lat);
            lng = parseFloat(formdata.lng);
        }
        const defaultAddress = new google.maps.LatLng(lat, lng);
        initMap(0, defaultAddress);
    };

    const addSingleMarker = (): void => {
        if (marker) {
            addMarker(new google.maps.LatLng(marker.latitude, marker.longitude), marker.address);
        }
    };

    React.useEffect(addSingleMarker, [marker]);

    const getAddressCoordinates = (): void => {
        if (autocomplete && autocomplete.value) {
            console.log(autocomplete);
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ placeId: autocomplete.value.place_id }, function (results, status) {
                if (status === 'OK' && results) {
                    // console.log(results);
                    // console.log(results[0]);
                    setMarker({
                        address: results[0].formatted_address,
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng()
                    });
                    // setCoordinates
                    SetCoordinates({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng(), address: results[0].formatted_address });
                }
            });
        }
    };
    React.useEffect(getAddressCoordinates, [autocomplete]);


    const addMarker = (location: GoogleLatLng, address?: string): void => {

        if (markers) {
            markers.map((marker) => {
                marker.setMap(null);
            });
        }
        const marker: GoogleMarker = new google.maps.Marker({
            position: location,
            map: map,
            icon: {
                path: ficon.icon[4] as string,
                fillColor: "#0000ff",
                fillOpacity: 1,
                anchor: new google.maps.Point(
                    ficon.icon[0] / 2, // width
                    ficon.icon[1] // height
                ),
                strokeWeight: 2,
                strokeColor: "#ffffff",
                scale: 0.075,
              },
            // icon: getIconAttributes('black','black')
        });
        if (map) {
            map.panTo(location);
            map.setZoom(12);
        }
        marker.addListener('click', () => {
            if (map) {
                map.panTo(location);
                map.setZoom(15);
                const geocoder = new google.maps.Geocoder();
                var coordinate: GoogleLatLng = new google.maps.LatLng(location.lat(), location.lng());
                geocoder.geocode({ location: coordinate }, function (results, status) {
                    if (status === 'OK' && results) {
                                 // Info window content
                                 var contentString =
                                 '<div id="content">' +
                                 '<div id="bodyContent">' +
                                 '<div>' +
                                 results[0].formatted_address +
                                 '</div>' +
                                 '</div>' +
                                 '</div>';

                             // Add info window
                             const infowindow = new google.maps.InfoWindow({
                                 content: contentString
                             });

                             infowindow.open(map, marker);
                    }
                });
               
            }
        });
        setMarkers([...markers, marker]);
    };


    const getIconAttributes = (iconColor: string, strokeColor: string) => {
        return {
            path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
            fillColor: iconColor,
            fillOpacity: 1.0,
            strokeColor: strokeColor,
            strokeWeight: 0,
            // anchor: new google.maps.Point(0, 0),
            // labelOrigin:
            scale: 2,
            anchor: new google.maps.Point(0, 20)
        };
    }

    const initMap = (zoomLevel: number, address: GoogleLatLng): void => {
        if (ref.current) {
            setMap(
                new google.maps.Map(ref.current, {
                    zoom: zoomLevel,
                    center: address,
                    mapTypeControl: mapTypeControl,
                    streetViewControl: false, // maybe not false -> allow stret view
                    zoomControl: true,
                    mapTypeId: mapType
                })
            );
            // Set store location marker if it already exists
            if (address && formdata && formdata.address) {
                setMarker({
                    address: address.toString(),
                    latitude: address.lat(),
                    longitude: address.lng()
                });
            }
        }
    };

    let default_address = '';

    if (formdata && formdata.address) {
        default_address = formdata.address;
    }

    return (
        <div style={{ width: '100%' }}>
            {/* <span className="ms-TextField-description">Address location on map</span> */}
            <div className="map-container">
                <div ref={ref} className="map-container__map"></div>
            </div>

        </div>
    );
};

const theme = getTheme();


export default GoogleMaps