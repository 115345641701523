import { Image, ImageFit, Stack, Separator, Text, Icon, SharedColors, TooltipHost, DirectionalHint } from '@fluentui/react';
import * as React from 'react';
import { DeviceAttributes, CurrencyCodes, IIdentificationModuleConfigUI, IResultGrade, CommissionType } from '@piceasoft/core';
import { ITransaction, ITransactionDataItem } from '../../../../../core/store/typings/ITransaction';
import unknown from '../../../../../assets/unknown.png';
import { IIdentificationModuleConfig } from '@piceasoft/core';
import { getLocalizedIdentificationMethod, getPromoPriceTypeDescription } from '../../../../../localization/helpers/transaction';
import { TransactionTabEntry } from './TransactionTabEntry';
import { strings } from '../../../../../localization/strings';
import { PropertyGrid } from '../../../../../components/shared/decorations/PropertyGrid';
import { ITransactionDevice, CurrencySigns, TransactionKeys, buildOsString } from '@piceasoft/core';

type TProps = {
    transaction: ITransaction
    identity?: ITransactionDevice
    data: ITransactionDataItem[]
    showAssesments: boolean
    deviceTitle: string | undefined
}

export const OverviewPivot: React.FC<TProps> = (props) => {
    const deviceImage = props.identity?.device.attributes[DeviceAttributes.Image] ?? unknown;

    const priceProviderCode = props.data?.find(i => i.key === TransactionKeys.OFFER_PROVIDER)?.value;

    const transactionPriceProviderName = priceProviderCode
        ? (
            props.transaction.workflow?.commonOffer?.providers?.find(i => i.code === priceProviderCode)?.name
            ?? props.transaction.workflow?.offer?.providers?.find(i => i.code === priceProviderCode)?.name
        )
        : undefined;

    const configIdentificationMethodTitle = props.identity?.method && (
        props.transaction.workflow?.identification?.modules[props.identity?.method] as unknown as IIdentificationModuleConfig<IIdentificationModuleConfigUI>
    )?.ui?.title

    const promotion = props.transaction.promotion

    const condition = promotion?.conditions?.find(i => i.grade === props.transaction.assessment.grade || i.grade === "*")
    const osString = buildOsString( props.identity?.device.attributes);


    // price is price without any possible commission reductions
    const price = typeof props.transaction.priceWithoutCommission === 'number' ? props.transaction.priceWithoutCommission : props.transaction.price ?? 0;
    // finalPrice is shown only if commissions are enabled, finalprice is price with commission reducted
    const finalPrice = typeof props.transaction.priceWithoutCommission === 'number' ? props.transaction.price : undefined;
    // commission is calculated from finalPrice and price, available only if finalprice is defined (commissions enabled)
    const commission = typeof finalPrice === 'number' ? price - finalPrice : undefined;

    const currency = props.transaction.currency !== null ? CurrencySigns[props.transaction.currency as CurrencyCodes] : '';

    return (
        <Stack verticalFill style={{ padding: 24 }}>
            <Stack horizontal verticalFill tokens={{ childrenGap: 24 }}>
                <Stack horizontalAlign="center" tokens={{ childrenGap: 16 }} style={{ paddingTop: 2 }}>
                    <Image height={160} width={160} imageFit={ImageFit.contain} maximizeFrame={true} src={deviceImage} />
                    {props.identity?.method && (
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 0 }}>
                            <Text variant="xSmall" style={{ color: SharedColors.gray10 }}>{strings.TRANSACTION.OVERVIEW.IDENTIFICATION_METHOD}</Text>
                            <Text variant="small" style={{ color: SharedColors.cyanBlue10 }}>{configIdentificationMethodTitle ?? getLocalizedIdentificationMethod(props.identity.initialIdentificationMethod ?? props.identity.method)}</Text>
                        </Stack>
                    )}
                    {props.identity?.isAuthenticated && (
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 4 }}>
                            <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={strings.TRANSACTION.OVERVIEW.TRUSTED_DATA}>
                                <Icon iconName="VerifiedBrandSolid" style={{ fontSize: 21, color: SharedColors.yellowGreen10, cursor: "default" }} />
                            </TooltipHost>
                        </Stack>
                    ) || (
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 4 }}>
                                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={strings.TRANSACTION.OVERVIEW.FREE_DATA}>
                                    <Icon iconName="InfoSolid" style={{ fontSize: 21, color: SharedColors.gray10 }} />
                                </TooltipHost>
                            </Stack>
                        )}
                </Stack>
                <Separator vertical />
                <Stack grow horizontal tokens={{ childrenGap: 32 }}>
                    <Stack tokens={{ childrenGap: 16 }} grow>
                        <PropertyGrid name={props.deviceTitle ?? strings.TRANSACTION.OVERVIEW.DEVICE_TITLE} icon="Package">
                            <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.MANUFACTURER} value={props.identity?.device.manufacturer ?? ""} />
                            <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.NAME} value={props.identity?.device.name ?? ""} />
                            {props.identity?.device.attributes[DeviceAttributes.Capacity] && (
                                <TransactionTabEntry
                                    name={strings.TRANSACTION.OVERVIEW.CONFIGURATION}
                                    value={props.identity?.device.attributes[DeviceAttributes.Capacity]}
                                    onValueRender={(value: string) => <>{value}<small>GB</small></>}
                                />
                            )}
                            {props.identity?.device.attributes[DeviceAttributes.Model] && (
                                <TransactionTabEntry
                                    name={strings.TRANSACTION.OVERVIEW.MODEL}
                                    value={props.identity?.device.attributes[DeviceAttributes.Model]}
                                />
                            )}
                            {props.identity?.device.attributes[DeviceAttributes.Color] && (
                                <TransactionTabEntry
                                    name={strings.TRANSACTION.OVERVIEW.COLOR}
                                    value={props.identity?.device.attributes[DeviceAttributes.Color]}
                                />
                            )}
                        </PropertyGrid>

                        {(props.identity?.device.attributes[DeviceAttributes.SN] || props.identity?.device.attributes[DeviceAttributes.IMEI]) && (
                            <PropertyGrid name={strings.TRANSACTION.OVERVIEW.IDENTIFIERS_TITLE} icon="GenericScan">
                                {props.identity?.device.attributes[DeviceAttributes.SN] && (
                                    <TransactionTabEntry
                                        name={strings.TRANSACTION.OVERVIEW.SN}
                                        value={props.identity?.device.attributes[DeviceAttributes.SN]}
                                    />
                                )}
                                {props.identity?.device.attributes[DeviceAttributes.IMEI] && (
                                    <TransactionTabEntry
                                        name={strings.TRANSACTION.OVERVIEW.IMEI}
                                        value={props.identity?.device.attributes[DeviceAttributes.IMEI]}
                                    />
                                )}
                                {props.identity?.device.attributes[DeviceAttributes.IMEI2] && (
                                    <TransactionTabEntry
                                        name={strings.TRANSACTION.OVERVIEW.IMEI2}
                                        value={props.identity?.device.attributes[DeviceAttributes.IMEI2]}
                                    />
                                )}
                                {props.identity?.device.attributes[DeviceAttributes.IMEI2] && props.identity?.device.attributes[DeviceAttributes.IMEI2] === '' && (
                                    <TransactionTabEntry
                                        name={strings.TRANSACTION.OVERVIEW.IMEI2}
                                        value={strings.TRANSACTION.OVERVIEW.IMEI2_NOT_SPECIFIED}
                                    />
                                )}
                            </PropertyGrid>
                        )}
                        {osString && (
                            <PropertyGrid name={strings.TRANSACTION.OVERVIEW.SOFTWARE_TITLE} icon="SaveToMobile">
                                <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.OS} value={osString} />
                            </PropertyGrid>
                        )}
                    </Stack>
                </Stack>
                { props.showAssesments && <Separator vertical />}
                { props.showAssesments && (<Stack style={{ width: 250 }} tokens={{ childrenGap: 16 }}>
                    <PropertyGrid name={strings.TRANSACTION.OVERVIEW.ASSESSMENT_TITLE} icon="Diamond">
                        {(props.transaction.grade || props.transaction.assessment.grade) && (
                            <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.GRADE} value={props.transaction.workflow?.grades.find(i => i.code === props.transaction.assessment.grade)?.name ?? props.transaction.assessment.grade ?? props.transaction.grade ?? strings.TRANSACTION.RESULT.GRADE_NOT_DEFINED_UPPERCASE} />
                        )}
                        {price >= 0 && (
                            <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.PRICE} value={`${price?.toLocaleString()} ${currency}`} />
                        )}
                        {/** Show commission if price is available and commissions are defined (even if commission is 0) */}
                        {price > 0 && typeof commission === 'number' && (
                            <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.COMMISSION} value={`${commission.toLocaleString()} ${currency}`}/>
                        )}
                        {/** Show final price if available and only if commissions are defined*/}
                        {typeof finalPrice === 'number' && typeof commission === 'number' && (
                            <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.FINAL_PRICE} value={`${finalPrice.toLocaleString()} ${currency}`}/>
                        )}
                        {promotion && condition && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.OVERVIEW.PROMOTION}
                                value={getPromoPriceTypeDescription(condition.priceType)}
                                onValueRender={(value: string) => <><small>{value}</small> {condition.price.toLocaleString()} <small>{condition.currency ? CurrencySigns[condition.currency as CurrencyCodes] : undefined}</small></>}
                            />
                        )}
                        {transactionPriceProviderName && (
                            <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.PRICE_PROVIDER} value={transactionPriceProviderName} />
                        )}
                        {!(props.transaction.grade || props.transaction.assessment.grade) && !(((props.transaction.assessment.price ?? 0) > 0) || ((props.transaction.price ?? 0) > 0)) && (
                            <TransactionTabEntry name={strings.TRANSACTION.OVERVIEW.GRADE} value={strings.TRANSACTION.RESULT.GRADE_NOT_DEFINED_UPPERCASE}/>
                        )}
                        {props.transaction.data?.find(i => i.key === TransactionKeys.ASSESSMENT_GRADES) && (
                            <Stack.Item>
                                <Stack>
                                    {props.transaction.workflow.gradesCategories?.map((gc, index) => {
                                        const gradesDataString = props.transaction.data?.find(i => i.key === TransactionKeys.ASSESSMENT_GRADES)?.value
                                        if (gradesDataString) {
                                            const gradesData: { [key: string]: string } = JSON.parse(gradesDataString)
                                            let grades: IResultGrade[] = []
                                            Object.keys(gradesData).forEach(gc => {
                                                const grade = props.transaction.workflow.gradesCategories?.find(i => i.code === gc)?.grades.find(g => g.code === gradesData[gc])
                                                if (grade) {
                                                    grades.push({ ...grade, gradeCategoryCode: gc, gradeCategoryName: "" })
                                                }
                                            })

                                            if (grades?.find(i => i.gradeCategoryCode === gc.code)) {
                                                return (
                                                    <Stack.Item key={index}>
                                                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                                                            <Stack.Item grow>
                                                                <Text variant='smallPlus' nowrap style={{ color: SharedColors.gray20 }}>{gc.name ? gc.name : strings.TRANSACTION.COMMON.NO_NAME}</Text>
                                                            </Stack.Item>
                                                            <Stack.Item>
                                                                <Text variant='smallPlus' nowrap style={{ color: SharedColors.cyanBlue10 }}>{grades?.find(i => i.gradeCategoryCode === gc.code)?.name ?? strings.TRANSACTION.COMMON.GRADE_NOT_DEFINED}</Text>
                                                            </Stack.Item>
                                                        </Stack>
                                                    </Stack.Item>
                                                )
                                            }
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Stack>
                            </Stack.Item>
                        )}
                    </PropertyGrid>
                </Stack>)}
            </Stack >
        </Stack >
    )
}
