import { CommandBarButton, getTheme, ICommandBarStyles, IStackStyles, Stack, Text } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../../core/actions/teaching-actions';
import { PortalBreadcrumb } from './PortalBreadcrumb';
import { TransactionSearch } from './TransactionSearch';
import { UserBox } from './UserBox';
import { CSHHelpLink } from '../../shared/help/CSHHelp';

type HeaderProps = {
    logo?: string
    organization?: string
    point?: string
    showChangeOrganizatoinModal: () => void
}

const Header: React.FC<HeaderProps> = (props) => {

    const historyButtonId = useId('header-history-button-id')
    const teachingItemName = "history"

    React.useEffect(() => {
        setTimeout(() => {
            dispatch(actionCreators.addItemId(teachingItemName, historyButtonId))
        }, 500)
    }, [])

    const dispatch = useDispatch();

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }} styles={headerStyles}>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign="center">
                    <CommandBarButton href="/" title="Picea® Online" styles={logoButtonStyles}>
                        <Stack style={{ overflow: 'hidden', flexFlow: 'wrap', height: 48 }} tokens={{ childrenGap: 12, padding: '0 8px' }} grow horizontal verticalAlign="center">
                            <img height={24} src="/logo-h.svg" alt='Logo' />
                            {/* <Stack verticalAlign='center' style={{ height: 48 }}>
                                <Stack verticalAlign="center"
                                    horizontalAlign="center"
                                    style={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: theme.effects.roundedCorner4,
                                        backgroundColor: !props.logo ? (props.organization ? getPersonaColorsPallete[props.organization[0].toLowerCase()] : undefined) : undefined,
                                        cursor: 'pointer'
                                    }}>
                                    {(props.logo) && (
                                        <Stack horizontalAlign='center'>
                                            <Image src={props.logo} width={24} height={24} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                        </Stack>
                                    ) || (
                                        <Text variant="small" style={{ color: "#fff" }}>
                                            {getIconSymbolsFromString(props.organization)}
                                        </Text>
                                    )}
                                </Stack>
                            </Stack>
                            <Text variant="mediumPlus" style={{ fontWeight: "bold", color: theme.palette.black }}>{props.organization ?? "ONE2PHONE"}</Text> */}
                        </Stack>
                    </CommandBarButton>
                    <PortalBreadcrumb />
                </Stack>
            </Stack.Item>
            <Stack.Item grow shrink>
                <Text style={{ marginLeft: 8 }}>{props.point}</Text>
            </Stack.Item>
            <CSHHelpLink />
            <TransactionSearch />
            {/* <CommandBarButton onClick={() => dispatch(actionCreators.clearTeachingState())} iconProps={{ iconName: "Unknown" }} styles={commandBarButtonStyles} /> */}
            <Stack.Item>
                <Stack horizontal>
                    <CommandBarButton styles={commandBarButtonStyles} style={{ marginLeft: 12 }}>
                        <UserBox showChangeOrganizatoinModal={props.showChangeOrganizatoinModal} />
                    </CommandBarButton>
                </Stack>

            </Stack.Item>
        </Stack>
    )
}

export default Header

const theme = getTheme();
const headerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 1px 0" } };
const commandBarButtonStyles: ICommandBarStyles = { root: { height: 48, width: 48 } };
const logoButtonStyles: ICommandBarStyles = { root: { height: 48, width: 260 } };