import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationImeiConfig, IIdentificationImeiConfigUI } from "@piceasoft/core";
import { IdentificationModuleUISettingsPivot } from "../IdentificationModuleUISettingsPivot";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const ImeiUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationImeiConfig)

    return (
        <IdentificationModuleUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch}>
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.IMEI.PIVOTS.UI.IMEI_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={config.ui?.imei?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            imei: {
                                ...config.ui?.imei,
                                placeholder: config.ui?.imei?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationImeiConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.SERIAL_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.imei?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.imei?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        imei: {
                            ...config.ui?.imei,
                            placeholder: val
                        }
                    } as IIdentificationImeiConfigUI
                }))}
            />
        </IdentificationModuleUISettingsPivot>

    )
}