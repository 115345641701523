import { Stack, getTheme, Pivot, PivotItem, DirectionalHint, Icon, IPivotItemProps, IRenderFunction, TooltipHost, IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject, SharedColors, IStackStyles } from "@fluentui/react"
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { IDiagnosticsConfig, ISoftwareConfig, IStore } from "../../../../../core/store";
import { strings } from "../../../../../localization/strings";
import { InterviewStatePreview } from "./interview/InterviewStatePreview";
import { PhotographicStatePreview } from "./photographic/PhotographicStatePreview";
import { InterviewSettingsPivotBasedState } from "./interview/common/InterviewSettingsPivotBasedState";
import { PhotographicSettingsPivotBasedState } from "./photographic/common/PhotographicSettingsPivotBasedState";
import { SoftwareStatePreview } from "./software/SoftwareStatePreview";
import { SoftwareSettingsPivotBasedState } from "./software/common/SoftwareSettingsPivotBasedState";
import { DiagnosticsStatePreview } from "./diagnostics/DiagnosticsStatePreview";
import { DiagnosticsSettingsPivotBasedState } from "./diagnostics/common/DiagnosticsSettingsPivotBasedState";
import { DataCollectionSettingsPivotBasedState } from "./dataCollection/common/DataCollectionSettingsPivotBasedState";
import { DataCollectionStatePreview } from "./dataCollection/DataCollectionStatePreview";
import { EraseSettingsPivotBasedState } from "./erase/common/EraseSettingsPivotBasedState";
import { EraseStatePreview } from "./erase/EraseStatePreview";
import { getStageInspectionModules } from "../../../../../core/actions/configurator-actions";
import { AIGradingStatePreview } from "./aigrading/AIGradingStatePreview";
import { AIGradingSettingsPivotBasedState } from "./aigrading/common/AIGradingSettingsPivotBasedState";
import { Experience } from "../../helpers/evisibility";
import { ManualGradesStatePreview } from "./manualGrades/ManualGradesStatePreview";
import { ManualGradesSettingsPivotBasedState } from "./manualGrades/common/ManualGradesSettingsPivotBasedState";
import {
    IInterviewConfig, IDataCollectionConfig, IEraseConfig, IAIGradingConfig, IManualGradingConfig, IPhotographicConfig,
    Inspections, ProcessStages
} from "@piceasoft/core";

type TProps = {
    index: number
    stage: ProcessStages
    pivot: TInspectionSettingsPivotStates
    onChangeDispatch: Dispatch<any>
    onChangePivotState: (state: TInspectionSettingsPivotStates) => void
    onClose: () => void
    withoutGrade?: boolean
    commonSettingsPivotChildren?: React.ReactNode
}

export type TInspectionSettingsPivotStates = "common" | "ui" | "config" | "dependency_inspections" | "skip" | "push"

const getTabId = (itemKey: string) => {
    return `InspectionModulePivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "common": return strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.TITLE
        case "ui": return strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.TITLE
        case "config": return strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.CONFIG.TITLE
        case "dependency_inspections": return strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.DEPENDENCY_INSPECTIONS.TITLE
        case "skip": return strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.TITLE
        case "push": return strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.PUSH_NOTIFICATION.TITLE
        default: return m;
    }
}

const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
    return (
        <span style={{ ...iProps?.style }}>
            <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
            </TooltipHost>
        </span>
    )
}

export const InspectionModule: React.FC<TProps> = (props) => {

    const module = useSelector((s: IStore) => getStageInspectionModules(props.stage, s)?.find(i => i.index === props.index))
    if (!module) return null
    const channel = useSelector((s: IStore) => s.configurator.channel)

    const handlePivotLinkClick = (item?: PivotItem) => {
        props.onChangePivotState(item?.props.itemKey! as TInspectionSettingsPivotStates);
    };

    return (
        <>
            <Stack horizontalAlign="end" styles={headerStylesMain}>
                <Stack.Item>
                    <Pivot styles={pivotStyles} selectedKey={props.pivot} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                        <PivotItem itemIcon={'View'} headerText={getPivotHeaderText("ui")} onRenderItemLink={onRenderItemLink} itemKey="ui" />
                        {/* {![IdentificationMethods.PiceaUsb, IdentificationMethods.PiceaOne].includes(props.method) && ( */}
                            <PivotItem itemIcon={'Repair'} headerText={getPivotHeaderText("common")} onRenderItemLink={onRenderItemLink} itemKey="common" />
                            {channel === Experience.Mobile &&  (module.type === Inspections.Diagnostics || module.type === Inspections.Software) && (
                                <PivotItem itemIcon={'Message'} headerText={getPivotHeaderText("push")} onRenderItemLink={onRenderItemLink} itemKey="push"/>
                            )}
                            <PivotItem itemIcon={'Link'} headerText={getPivotHeaderText("dependency_inspections")} onRenderItemLink={onRenderItemLink} itemKey="dependency_inspections" />
                            <PivotItem itemIcon={'Unpin'} headerText={getPivotHeaderText("skip")} onRenderItemLink={onRenderItemLink} itemKey="skip" />
                        {/* )} */}
                        <PivotItem itemIcon={'Code'} headerText={getPivotHeaderText("config")} onRenderItemLink={onRenderItemLink} itemKey="config" />
                    </Pivot>
                </Stack.Item>
            </Stack>
            <Stack styles={contentStyles} horizontal verticalFill>
                <Stack verticalAlign="center" horizontalAlign="center" style={{ background: theme.palette.neutralLighter }}>
                    {module.type === Inspections.Interview && props.pivot === "ui" && (
                        <InterviewStatePreview config={module.config as IInterviewConfig} />
                    )}
                    {module.type === Inspections.Photographic && props.pivot === "ui" && (
                        <PhotographicStatePreview config={module.config as IPhotographicConfig} />
                    )}
                    {module.type === Inspections.Diagnostics && props.pivot === "ui" && (
                        <DiagnosticsStatePreview config={module.config as IDiagnosticsConfig} />
                    )}
                    {module.type === Inspections.Software && props.pivot === "ui" && (
                        <SoftwareStatePreview config={module.config as ISoftwareConfig} />
                    )}
                    {module.type === Inspections.DataCollection && props.pivot === "ui" && (
                        <DataCollectionStatePreview config={module.config as IDataCollectionConfig} />
                    )}
                    {module.type === Inspections.Erase && props.pivot === "ui" && (
                        <EraseStatePreview config={module.config as IEraseConfig} />
                    )}
                     {module.type === Inspections.AIGrading && props.pivot === "ui" && (
                        <AIGradingStatePreview config={module.config as IAIGradingConfig} />
                    )}
                    {module.type === Inspections.ManualGrading && props.pivot == "ui" && (
                        <ManualGradesStatePreview config={module.config as IManualGradingConfig} />
                    )}
                </Stack>
                <Stack.Item grow style={{ zIndex: 10 }}>
                    {module.type === Inspections.Interview && (
                        <InterviewSettingsPivotBasedState
                            state={props.pivot}
                            index={props.index}
                            stage={props.stage}
                            onChangeDispatch={props.onChangeDispatch}
                            onClose={props.onClose}
                            withoutGrade={props.withoutGrade}
                        />
                    )}
                    {module.type === Inspections.Photographic && (
                        <PhotographicSettingsPivotBasedState
                            state={props.pivot}
                            index={props.index}
                            stage={props.stage}
                            onChangeDispatch={props.onChangeDispatch}
                            onClose={props.onClose}
                            withoutGrade={props.withoutGrade}
                        />
                    )}
                    {module.type === Inspections.Diagnostics && (
                        <DiagnosticsSettingsPivotBasedState
                            state={props.pivot}
                            index={props.index}
                            stage={props.stage}
                            onChangeDispatch={props.onChangeDispatch}
                            onClose={props.onClose}
                            withoutGrade={props.withoutGrade}
                        />
                    )}
                    {module.type === Inspections.Software && (
                        <SoftwareSettingsPivotBasedState
                            state={props.pivot}
                            index={props.index}
                            stage={props.stage}
                            onChangeDispatch={props.onChangeDispatch}
                            onClose={props.onClose}
                            withoutGrade={props.withoutGrade}
                            commonSettingsPivotChildren={props.commonSettingsPivotChildren}
                        />
                    )}
                    {module.type === Inspections.DataCollection && (
                        <DataCollectionSettingsPivotBasedState
                            state={props.pivot}
                            index={props.index}
                            stage={props.stage}
                            onChangeDispatch={props.onChangeDispatch}
                            onClose={props.onClose}
                            commonSettingsPivotChildren={props.commonSettingsPivotChildren}
                        />
                    )}
                    {module.type === Inspections.Erase && (
                        <EraseSettingsPivotBasedState
                            state={props.pivot}
                            index={props.index}
                            stage={props.stage}
                            onChangeDispatch={props.onChangeDispatch}
                            onClose={props.onClose}
                            commonSettingsPivotChildren={props.commonSettingsPivotChildren}
                        />
                    )}
                    {module.type === Inspections.AIGrading && (
                        <AIGradingSettingsPivotBasedState
                            state={props.pivot}
                            index={props.index}
                            stage={props.stage}
                            onChangeDispatch={props.onChangeDispatch}
                            onClose={props.onClose}
                            withoutGrade={props.withoutGrade}
                        />
                    )}
                    {module.type === Inspections.ManualGrading && (
                        <ManualGradesSettingsPivotBasedState
                            state={props.pivot}
                            index={props.index}
                            stage={props.stage}
                            onChangeDispatch={props.onChangeDispatch}
                            onClose={props.onClose}
                            withoutGrade={props.withoutGrade}
                        />
                    )}
                </Stack.Item>
            </Stack>
        </>
    )
}

const theme = getTheme();

const headerStylesMain: IStackStyles = { root: { position: "relative", zIndex: 14 } };
const contentStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0", zIndex: 10, minHeight: 480 } };

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}