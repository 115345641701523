import { getFocusStyle, getTheme, mergeStyleSets, SharedColors, Stack, Text } from "@fluentui/react"
import { useId } from "@uifabric/react-hooks"
import React, { useEffect } from "react"
import { DaDataFioSuggestion } from "react-dadata"
import { IDataCollectionConfig } from "@piceasoft/core"
import { escapeRegExp } from "../../../../../../../../../../../assets/ts/utils/regex"
import { strings } from "../../../../../../../../../../../localization/strings"
import SuggestedSearch from "../../../../../../../../../search/SuggestedSearch"
import { TDaDataSuggestions } from "../../../../../dataCollectionTypes"
import { fetchDaData, handleTextToListKeyPressLocal } from "../../../helpers/previewHelper"

type TProps = {
    config?: IDataCollectionConfig
    errorMessage: string
    searchRequest?: string
    setSearchRequest: (item?: any) => void
    onRenderSuggestionsHeader: () => JSX.Element
    onBlur: () => void
}

const ClientNameSearch: React.FunctionComponent<TProps> = (props) => {
    const {
        config,
        errorMessage,
        searchRequest,
        onBlur,
        setSearchRequest,
        onRenderSuggestionsHeader,
    } = props

    const passportNameTextId = useId("passport-name-text-id")
    const passportNameCalloutListId = useId("passport-name-callout-id")

    const [daDataPassportName, setDaDataPassportName] = React.useState<TDaDataSuggestions | null>(null);

    const onRenderNameSuggestionsCell = (item?: DaDataFioSuggestion, index?: number, isScrolling?: boolean): JSX.Element => {
        if (searchRequest === undefined) {
            return <></>
        }
        const parts = item?.value.split(new RegExp(`\w*(${escapeRegExp(searchRequest.trimEnd())})\w*`, "i"))
        return (
            <div className={classNames.itemCell}
                data-is-focusable={true}
                id={`${passportNameCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => (
                    handleTextToListKeyPressLocal(
                        ev,
                        passportNameTextId,
                        passportNameCalloutListId,
                        daDataPassportName?.suggestions?.length ?? 0,
                        setSearchRequest,
                        index,
                        item?.value
                ))}
                tabIndex={-1}
                onClick={(e) => {
                    const suggestion = daDataPassportName?.suggestions[index ?? 0]
                    setDaDataPassportName(null)
                    setSearchRequest(suggestion?.value)
                }}
            >
                <Stack tokens={{ padding: 4, childrenGap: 4 }}>
                    {parts && (
                        <Text>
                            {parts.map((p, i) => {
                                if (p !== "") {
                                    if (i % 2 === 1) {
                                        return <Text key={p} style={{ color: SharedColors.cyanBlue10 }} variant="medium">{p}</Text>
                                    }
                                    return <Text variant="medium">{p}</Text>
                                }
                            }
                            )}
                        </Text>
                    ) || (
                            <Text variant="medium">{item?.value}</Text>
                        )
                    }
                </Stack>
            </div>
        );
    };

     useEffect(() => {
        if (searchRequest === "") {
            setDaDataPassportName(null)
            return
        }

        if (searchRequest === undefined) {
            return
        }

        const fetchData = async () => {
            const data = await fetchDaData(searchRequest, config?.daDataKey ?? "", config?.useDaData ?? false, 'fio')
            if (data === null) return
            setDaDataPassportName(data)
        }
        fetchData()
    }, [searchRequest])

    return (
        <SuggestedSearch
            inputBoxId={passportNameTextId}
            suggestionsListId={passportNameCalloutListId}
            suggestions={daDataPassportName?.suggestions}
            onClickSuggestionCell={setSearchRequest}
            onRenderSuggestionCell={onRenderNameSuggestionsCell}
            setSearchRequest={setSearchRequest}
            label={config?.config?.fields?.name?.label ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.FULL_NAME}
            inputBoxPlaceholder={config?.config?.fields?.name?.placeholder ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PLACEHOLDER_FULL_NAME}
            searchRequest={searchRequest}
            required
            textMinLength={config?.config?.fields?.name?.minLength}
            textMaxLength={config?.config?.fields?.name?.maxLength}
            textValidateOnFocusOut={true}
            textDeferredValidationTime={2000}
            type="text"
            isSearchEqualTheOneSuggestion={(daDataPassportName?.suggestions !== null && daDataPassportName?.suggestions[0] && searchRequest === daDataPassportName?.suggestions[0].value) ?? false}
            onRenderSuggestionsHeader={onRenderSuggestionsHeader}
            suggestionsListWidth={590}
            textOnBlur={onBlur}
            errorMessage={errorMessage}
        />
    )
}

const theme = getTheme();

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.neutralLight, borderColor: theme.palette.neutralLight }),
        {
            padding: 5,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: theme.palette.neutralLight },
                '&:focus': { background: theme.palette.neutralLight },
            },
        },
    ],
});

export default ClientNameSearch