import * as React from 'react'
import { IInterviewModeConfig, IInterviewListConfig, IInterviewModeConfigUI } from "@piceasoft/core";
import { strings } from '../../../../../../../../../localization/strings';
import { ParameterItem } from '../../../../../../components/ParameterItem';
import { InterviewModeSettings } from '../../../common/InterviewModeSettings';

export type TListQuestionsConfiguratorSettingsPivotProps = {
    config: IInterviewListConfig
    onConfigConfirm: (config: IInterviewListConfig) => void
}

export const ListQuestionsConfiguratorSettingsPivot: React.FC<TListQuestionsConfiguratorSettingsPivotProps> = (props) => {

    const onModeConfirm = (config: IInterviewModeConfig<IInterviewModeConfigUI>) => {
        props.onConfigConfirm({
            ...props.config,
            ...config
        })
    }

    return (
        <InterviewModeSettings {...{ config: props.config as IInterviewModeConfig<IInterviewModeConfigUI>, onConfirm: onModeConfirm }} >
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.ALLOW_DESCRIPTION_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.config.allowDescription ? true : false}
                toggleOnChange={() => {
                    props.onConfigConfirm({
                        ...props.config,
                        allowDescription: !props.config.allowDescription ? true : false
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.ALLOW_DESCRIPTION_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.INFORMATION_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.config.information !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigConfirm({
                        ...props.config,
                        information: props.config.information !== undefined ? undefined : ""
                    }
                    )
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.INFORMATION_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={props.config.information !== undefined ? true : false}
                textFieldValue={props.config.information}
                textFieldOnChange={(val) => props.onConfigConfirm({
                    ...props.config,
                    information: val
                })
                }
            />
        </InterviewModeSettings>
    )
}
