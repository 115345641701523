import { Stack, getTheme, Text, PrimaryButton, DefaultButton, FontWeights, mergeStyleSets } from "@fluentui/react"
import React from "react";
import { IStageConfig, IStageConfigUI } from "@piceasoft/core";

type TProps = {
    config: IStageConfig<IStageConfigUI>
    defaultConfirmText: string
    defaultCancelText: string
    defaultheader: string
}

export const StageConfirmationPreview: React.FC<TProps> = ({ config, ...props }) => {
    return (
        <Stack horizontalAlign="center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} >
            <Stack.Item tokens={{ padding: 24 }}>
                <Stack style={{ minWidth: 500, maxWidth: 600, boxShadow: theme.effects.elevation64, backgroundColor: theme.palette.white }}>
                    <div className={contentStyles.header}>
                        <Text style={{ color: theme.palette.themePrimary, fontWeight: 600 }} variant="large">{config.ui?.confirmation?.header ?? props.defaultheader}</Text>
                    </div>
                    <div className={contentStyles.body}>
                        {props.children}
                    </div>
                    <Stack horizontal horizontalAlign="end" tokens={{ padding: 24, childrenGap: 8 }}>
                        <PrimaryButton
                            text={(config.ui?.confirmation?.buttons?.confirm?.text && config.ui?.confirmation?.buttons?.confirm?.text.trim().length > 0)
                                ? config.ui?.confirmation?.buttons?.confirm?.text :
                                props.defaultConfirmText}
                        />
                        <DefaultButton text={(config.ui?.confirmation?.buttons?.cancel?.text && config.ui?.confirmation?.buttons?.cancel?.text.trim().length > 0)
                            ? config.ui?.confirmation?.buttons?.cancel?.text
                            : props.defaultCancelText}
                        />
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        maxWidth: 640
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});