import { Coachmark, Dialog, DirectionalHint, IButtonProps, Layer, TeachingBubble, TeachingBubbleContent } from "@fluentui/react"
import * as React from 'react';
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../core/actions/teaching-actions";
import { strings } from "../../../localization/strings";

type TTeachingItemProps = {
    name: string
    header: string
    text: string
    target: string
    direction: DirectionalHint
}

export const StartTeachingItem: React.FC<TTeachingItemProps> = (props) => {

    const dispatch = useDispatch();

    const primaryButtonProps: IButtonProps = {
        children: strings.START_TEACHING.BUTTONS.REMEMBERED,
        onClick: () => dispatch(actionCreators.setPassedItem(props.name)),
    };

    const secondaryButtonProps: IButtonProps = {
        children: strings.START_TEACHING.BUTTONS.REMIND,
        onClick: () => dispatch(actionCreators.setHiddenItem(props.name)),
    };

    return (
        null
        // <TeachingBubble
        //     headline={props.header}
        //     target={`#${props.target}`}
        //     primaryButtonProps={primaryButtonProps}
        //     secondaryButtonProps={secondaryButtonProps}
        //     calloutProps={{ directionalHint: props.direction }}
        // >
        //     {props.text}
        // </TeachingBubble>
    )
}