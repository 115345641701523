import {
    DefaultButton, FontWeights, getTheme, IconButton,
    IContextualMenuItem, IContextualMenuProps, IStackStyles,
    mergeStyleSets, Modal, PrimaryButton, ScrollablePane,
    Spinner, Stack, Text, TextField, Image, ImageFit
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { DangerButton } from '../buttons/DangerButton';
import { portalApi } from '../../../core/api/api';
import { IModerationResult } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { strings as coreStrings, getOnlineLanguage } from '@piceasoft/core'
import { ModerationStatuses, Inspections } from "@piceasoft/core";
import { MyMessage } from '../messages/MyMessage';
import { ItemsNotFound } from '../notFound/ItemsNotFound';
import { getLocalizedModerationStatus } from '../../../localization/helpers/transaction';
import { getModerationColor } from '../../helpers/transaction';
import { useBoolean } from '@uifabric/react-hooks';
import { IGradesCategory } from '@piceasoft/core';

type TProps = {
    transactionUid: string
    moderatorHash: string
    isRequest?: boolean
    isDecline?: boolean
    lang?: string
}

enum ModeratorActions {
    request = "request",
    decline = "decline",
    accept = "accept"
}

export const ExternalModeration: React.FC<TProps> = ({ transactionUid, moderatorHash, isRequest, isDecline, lang }) => {
    const [moderationState, setModerationState] = React.useState<{ fetched: boolean, data?: IModerationResult }>({ fetched: false })
    const [transactionNumber, setTransactionNumber] = React.useState<string>()
    const [selectedGrade, setSelectedGrade] = React.useState<string>()
    const [error, setError] = React.useState<string>()
    const [moderatorMessage, setModeratorMessage] = React.useState<string>()
    const [multiline, setMultiline] = React.useState(false);

    const [moderatorAction, setModeratorAction] = React.useState<ModeratorActions>()

    const [langPrepared, { setTrue: prepareLang }] = useBoolean(false)

    const onChangeTextField = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void => {
        setMultiline(newText ? newText.length > 44 : false)
        setModeratorMessage(newText)
    };

    const [menuProps, setMenuProps] = React.useState<IContextualMenuProps>()
    const isModerationResponsible = true

    React.useEffect(() => {
        selectedGrade && setModeratorAction(ModeratorActions.accept)
    }, [selectedGrade])

    React.useEffect(() => {
        const onlineLang = getOnlineLanguage(lang)
        strings.setLanguage(onlineLang);
        coreStrings.setLanguage(onlineLang);
        prepareLang()
        getModeration()
    }, [])

    React.useEffect(() => {
        if (!moderationState.data?.workflow?.useGradesCategories && moderationState.data?.workflow?.grades && moderationState.data?.workflow?.grades.length > 0) {
            setMenuProps({
                items: moderationState.data.workflow.grades.map(g => ({
                    onClick: () => setSelectedGrade(g.code),
                    key: g.code,
                    text: g.name
                } as IContextualMenuItem)), useTargetWidth: true
            })
        }
        if (moderationState.data?.workflow?.useGradesCategories
            && moderationState.data?.workflow?.gradesCategories
            && moderationState.data?.workflow?.gradesCategories.length > 0
            && moderationState.data.workflow.assessment?.modules.find(i => i.type === Inspections.Photographic)) {
            setMenuProps({
                items: (moderationState.data.workflow.gradesCategories.find(i => moderationState.data?.workflow?.assessment?.modules.find(m => m.type === Inspections.Photographic)?.config.gradesCategory === i.code) as IGradesCategory).grades.map(g => ({
                    onClick: () => setSelectedGrade(g.code),
                    key: g.code,
                    text: g.name
                } as IContextualMenuItem)), useTargetWidth: true
            })
        }
    }, [moderationState])

    const getModeration = async () => {
        setModerationState({ fetched: false })
        const result = await portalApi.overview.getExternalModeration(transactionUid, moderatorHash)
        console.log(result)
        result.data?.messages?.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime())
        setTimeout(() => {
            setModerationState({ fetched: true, data: result.data })
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            if (result.successed && result.data?.moderationStatus === ModerationStatuses.moderation) {
                if (isRequest) {
                    setModeratorAction(ModeratorActions.request);
                    if (!transactionNumber && result.data?.number) {
                        setTransactionNumber(result.data?.number)
                    }
                }
                if (isDecline) {
                    setModeratorAction(ModeratorActions.decline);
                    if (!transactionNumber && result.data?.number) {
                        setTransactionNumber(result.data?.number)
                    }
                }
            }
        }, 500)
    }

    const onActionSuccess = async (callback: () => void) => {
        const sendNotifyResult = await portalApi.overview.moderation.notifyExternal(moderationState.data?.uid as string, moderatorHash)
        console.log(sendNotifyResult)
        if (sendNotifyResult.errors && sendNotifyResult.errors.length > 0) {
            setError(sendNotifyResult.errors[0].description);
        }
        if (sendNotifyResult.successed) {
            callback()
        }
    }

    const onSuccess = () => {
        getModeration()
        setModeratorAction(undefined)
    }

    const onConfirmationCancel = () => {
        setSelectedGrade(undefined)
        setModeratorAction(undefined)
    }

    const onAcceptAction = async () => {
        setError(undefined)
        setModerationState({ ...moderationState, fetched: false })
        setModeratorAction(undefined)
        if (selectedGrade) {
            const result = await portalApi.overview.setExternalModeration(moderationState.data?.uid as string, moderatorHash, moderatorMessage, selectedGrade)
            console.log(result)
            if (result.successed) {
                onActionSuccess(onSuccess)
            } else {
                if (result.errors && result.errors.length > 0) {
                    setError(result.errors[0].description)
                }
                setModerationState({ ...moderationState, fetched: true })
            }
        }
    }

    const onRequestAction = async () => {
        setError(undefined)
        setModerationState({ ...moderationState, fetched: false })
        setModeratorAction(undefined)
        const result = await portalApi.overview.setExternalModerationRequest(moderationState.data?.uid as string, moderatorHash, moderatorMessage)
        console.log(result)
        if (result.successed) {
            onActionSuccess(onSuccess)
        } else {
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            setModerationState({ ...moderationState, fetched: true })
        }
    }

    const onDeclineAction = async () => {
        setError(undefined)
        setModerationState({ ...moderationState, fetched: false })
        setModeratorAction(undefined)
        const result = await portalApi.overview.setExternalModeration(moderationState.data?.uid as string, moderatorHash, moderatorMessage)
        console.log(result)
        if (result.successed) {
            onActionSuccess(onSuccess)
        } else {
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            setModerationState({ ...moderationState, fetched: true })
        }
    }

    const getConfirmationHeader = () => {
        switch (moderatorAction) {
            case ModeratorActions.accept: return strings.TRANSACTIONS.MODERATIONS.MODERATION.ACCEPT_HEADER
            case ModeratorActions.decline: return strings.TRANSACTIONS.MODERATIONS.MODERATION.DECLINE_HEADER
            case ModeratorActions.request: return strings.TRANSACTIONS.MODERATIONS.MODERATION.REQUEST_HEADER
        }
    }

    const onAcceptToProcessing = async () => {
        setError(undefined)
        setModerationState({ ...moderationState, fetched: false })
        setModeratorAction(undefined)
        const result = await portalApi.overview.moderation.start(moderationState.data?.uid as string)
        if (result.successed) {
            onActionSuccess(onSuccess)
        } else {
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            setModerationState({ ...moderationState, fetched: true })
        }
    }

    const moderationColor = moderationState.data?.moderationStatus && getModerationColor(moderationState.data?.moderationStatus)

    return (
        <Stack verticalFill grow style={{ position: 'relative' }}>
            <ScrollablePane>
                <Stack verticalFill grow>
                    {!error && moderationState.data && (
                        <Stack styles={headerStylesMain}>
                            <Stack.Item styles={headerStyles}>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }} styles={{ root: { borderLeftStyle: "solid", borderLeftWidth: 12, borderLeftColor: moderationColor, padding: "4px 16px 4px 12px", backgroundColor: theme.palette.white } }}>
                                    <Stack.Item grow>
                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }} styles={{ root: { height: 72 } }}>
                                            <Stack grow>
                                                {moderationState.data?.number && (
                                                    <Text style={{ color: SharedColors.gray20 }}>{moderationState.data.number}</Text>
                                                )}
                                                <Stack horizontal verticalAlign="end">
                                                    <Text block nowrap variant="xLarge" style={{ fontWeight: "bold" }}>{moderationState.data?.device}</Text>
                                                    {moderationState.data?.grade && (
                                                        <Text style={{ color: SharedColors.cyanBlue10, marginLeft: 16 }}>
                                                            {moderationState.data?.grade ?? strings.TRANSACTION.RESULT.GRADE_NOT_DEFINED_UPPERCASE}
                                                        </Text>
                                                    )}
                                                    {((moderationState.data?.price ?? 0) > 0) && (
                                                        <Text variant="large" style={{ color: SharedColors.orangeYellow20, marginLeft: 16 }}>
                                                            {moderationState.data?.price?.toLocaleString()}
                                                        </Text>
                                                    )}
                                                </Stack>
                                                {/* <Text variant="small" style={{ color: SharedColors.gray20 }}>{moderationState.data?.service}</Text> */}
                                            </Stack>
                                            <Stack verticalFill horizontal tokens={{ childrenGap: 12 }}>
                                                <Stack verticalFill verticalAlign='center'>
                                                    <Text variant="small" style={{ fontWeight: 400, color: SharedColors.gray30 }}>{getLocalizedModerationStatus(moderationState.data?.moderationStatus as ModerationStatuses)}</Text>
                                                    <Text variant="medium" style={{ fontWeight: 600, color: SharedColors.cyanBlue10 }}>{moderationState.data?.moderator}</Text>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    )}
                    <Stack grow verticalFill>
                        <Stack grow verticalFill>
                            {moderationState.fetched && ((moderationState.data?.files && moderationState.data?.files.length > 0) && (
                                <Stack grow horizontalAlign='center' tokens={{ childrenGap: 16, padding: 16 }}>
                                    {moderationState.data.files.map((f, index) => <Stack.Item style={{ maxWidth: 800 }} key={index}>
                                        <Image key={f.link} src={f.link} imageFit={ImageFit.cover} />
                                    </Stack.Item>)}
                                </Stack>
                            ))}
                            {!error && moderationState.fetched && (moderationState.data?.messages && moderationState.data?.messages.length > 0 && (
                                <Stack grow style={{ background: theme.palette.neutralLighter }}>
                                    <Stack grow verticalFill style={{ position: "relative", borderLeft: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                                        <Stack tokens={{ padding: 16, childrenGap: 16 }}>
                                            {moderationState.data.messages.map((m, index) => (
                                                <Stack.Item key={index} >
                                                    <MyMessage dateTime={m.datetime} offset={moderationState.data?.timezone} sender={m.sender} text={m.message} myMessage={["модератор", "moderator"].includes(m.sender.toLowerCase())} />
                                                </Stack.Item>
                                            ))}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ) || (
                                    <Stack verticalFill verticalAlign='center' grow horizontalAlign='center'>
                                        <ItemsNotFound doNotUseButton imgSrc={"images/navigation/images/not_found.png"} info={strings.TRANSACTIONS.MODERATIONS.MODERATION.INFO} suggestion={strings.TRANSACTIONS.MODERATIONS.MODERATION.SUGGESTION} />
                                    </Stack>
                                ))}
                            {!moderationState.fetched && (
                                <Stack verticalAlign="center" horizontalAlign="center" verticalFill grow>
                                    <Spinner label={langPrepared ? strings.SPINNERS.PLEASE_WAIT : undefined} />
                                </Stack>
                            )}
                            {error && (
                                <Stack grow horizontalAlign='center'>
                                    <ItemsNotFound info={error} imgSrc={"images/navigation/images/security_crop.png"} doNotUseButton />
                                </Stack>
                            )}
                            {!error && (
                                <Stack grow tokens={{ padding: 16 }} styles={{ root: { backgroundColor: theme.semanticColors.defaultStateBackground, boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0", zIndex: 20 } }}>
                                    <Stack grow tokens={{ childrenGap: 8 }}>
                                        {moderationState.fetched && moderationState.data?.moderationStatus && (
                                            ![ModerationStatuses.completed, ModerationStatuses.moderation, ModerationStatuses.queue, ModerationStatuses.denied].includes(moderationState.data?.moderationStatus as ModerationStatuses) ||
                                            [ModerationStatuses.moderation].includes(moderationState.data?.moderationStatus as ModerationStatuses) && isModerationResponsible
                                        ) && (moderationState.data?.workflow?.grades && moderationState.data?.workflow?.grades.length > 0 && (
                                            <DangerButton onClick={() => setModeratorAction(ModeratorActions.decline)}>{strings.BUTTONS.TEXT.DECLINE}</DangerButton>
                                        ))}
                                        {moderationState.fetched && moderationState.data?.moderationStatus && (
                                            ![ModerationStatuses.completed, ModerationStatuses.moderation, ModerationStatuses.queue, ModerationStatuses.denied].includes(moderationState.data?.moderationStatus as ModerationStatuses) ||
                                            [ModerationStatuses.moderation].includes(moderationState.data?.moderationStatus as ModerationStatuses) && isModerationResponsible
                                        ) && ((moderationState.data?.workflow?.useGradesCategories
                                            ? (moderationState.data?.workflow?.gradesCategories && moderationState.data?.workflow?.gradesCategories.length > 0)
                                            : (moderationState.data?.workflow?.grades && moderationState.data?.workflow?.grades.length > 0)) && (
                                                <PrimaryButton menuProps={menuProps} onClick={() => setModeratorAction(ModeratorActions.accept)}>{strings.BUTTONS.TEXT.SET_GRADE}</PrimaryButton>
                                            ) || (
                                                <PrimaryButton disabled={!selectedGrade}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                                            ))}
                                        {moderationState.fetched && moderationState.data?.moderationStatus && [ModerationStatuses.queue].includes(moderationState.data?.moderationStatus as ModerationStatuses) && (
                                            <PrimaryButton onClick={onAcceptToProcessing}>{strings.BUTTONS.TEXT.ACCEPT_TO_PROCESSING}</PrimaryButton>
                                        )}
                                        {moderationState.fetched && moderationState.data?.moderationStatus && (
                                            ![ModerationStatuses.completed, ModerationStatuses.moderation, ModerationStatuses.queue, ModerationStatuses.denied, ModerationStatuses.request].includes(moderationState.data?.moderationStatus as ModerationStatuses) ||
                                            [ModerationStatuses.moderation].includes(moderationState.data?.moderationStatus as ModerationStatuses) && isModerationResponsible
                                        ) && (moderationState.data?.workflow?.grades && moderationState.data?.workflow?.grades.length > 0 && (
                                            <DefaultButton onClick={() => setModeratorAction(ModeratorActions.request)}>{strings.BUTTONS.TEXT.REQUEST}</DefaultButton>
                                        ))}
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
                {moderatorAction && (
                    <Modal allowTouchBodyScroll isOpen={moderatorAction ? true : false} onDismiss={() => setModeratorAction(undefined)} isBlocking={true} containerClassName={contentStyles.container}>
                        <div className={contentStyles.header}>
                            <Stack>
                                <Text variant='xLarge'>{getConfirmationHeader()}</Text>
                                {moderatorAction === ModeratorActions.accept && <Text variant='medium' style={{ color: theme.palette.themePrimary }} >{`${moderationState.data?.workflow?.grades.find(i => i.code === selectedGrade)?.name} - ${selectedGrade}`}</Text>}
                            </Stack>
                            <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }} ariaLabel="Close" onClick={() => setModeratorAction(undefined)} />
                        </div>
                        <div className={contentStyles.body}>
                            <Stack verticalAlign='space-between' tokens={{ childrenGap: 16 }}>
                                <TextField placeholder={strings.TRANSACTIONS.MODERATIONS.MODERATION.MESSAGE_PLACEHOLDER} multiline={multiline} onChange={onChangeTextField} />
                                <Stack verticalAlign="center">
                                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 4 }}>
                                        {moderatorAction === ModeratorActions.request && <PrimaryButton disabled={!moderatorMessage || moderatorMessage.length === 0} style={{ width: 144 }} text={strings.BUTTONS.TEXT.CONFIRM} onClick={onRequestAction} />}
                                        {moderatorAction === ModeratorActions.decline && <PrimaryButton disabled={!moderatorMessage || moderatorMessage.length === 0} style={{ width: 144 }} text={strings.BUTTONS.TEXT.CONFIRM} onClick={onDeclineAction} />}
                                        {moderatorAction === ModeratorActions.accept && <PrimaryButton style={{ width: 144 }} text={strings.BUTTONS.TEXT.CONFIRM} onClick={onAcceptAction} />}
                                        <DefaultButton style={{ width: 144 }} text={strings.BUTTONS.TEXT.CANCEL} onClick={onConfirmationCancel} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </div>
                    </Modal>
                )
                }
            </ScrollablePane>
        </Stack>
    )
}

const theme = getTheme();
const headerStylesMain: IStackStyles = { root: { zIndex: 4 } };
const headerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 1px 0", zIndex: 10 } };
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const contentStyles = mergeStyleSets({
    container: {
        width: '400px',
        maxWidth: '400px',
        minHeight: 150,
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 12px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

