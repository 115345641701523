import {
    Text, Stack, IconButton, Icon, IGroup, DirectionalHint,
    IContextualMenuProps, PrimaryButton, DetailsList, IColumn,
    IRenderFunction, IGroupHeaderProps, SharedColors, mergeStyleSets,
    getTheme, IButtonStyles, SelectionMode, IDetailsListProps, IDetailsRowStyles,
    DetailsRow, IContextualMenuItem, ContextualMenuItemType, IStyle, ScrollablePane,
    IDetailsHeaderProps, Sticky, TooltipHost, TooltipDelay, FontWeights, Link,
    IContextualMenuItemProps, Check, IDetailsColumnProps} from '@fluentui/react'
import * as React from 'react'
import { getPiceaSetIdByCaseId, setsDescriber, testDescriber } from '../../../../../../../../core/scripts/picea.describer'
import { DiagnosticCases, DiagnosticSets, IGrade } from "@piceasoft/core"
import { IGradesCategory } from '@piceasoft/core'
import { strings } from '../../../../../../../../localization/strings'
import { CustomPanel } from '../../../../../../panel/CustomPanel'
import { DiagnosticCaseOptionsForm } from './diagnosticCase/DiagnosticCaseOptionsForm'
import { SecondaryButton } from '../../../../../../buttons/SecondaryButton'
import { onRenderColumnHeaderCommon } from '../../../../../../detailsList/commonRenders'
import { Badge } from '../../../../../../decorations/Badge'
import { TestCaseOptionViewer } from './TestCaseOptionViewer'
import { ErrorsRenderDiagnostics } from '../../../../../../../../screens/services/Service/ServiceConfigurationDetails/ValidationInspectionsModules'
import { ConnectionTypes, definitions, IDiagnosticsPiceaCaseConfig, IDiagnosticCase, IDiagnosticSet, SupportedDiagnosticCases, PiceaMobileSets, IDiagnosticsPiceaMobileTestConfig } from '@piceasoft/core'
import { DangerButton } from '../../../../../../buttons/DangerButton'
import { IDiagnosticsPiceaMobileCaseConfig, IDiagnosticsPiceaMobileSetConfig } from '@piceasoft/core'
import { CSHHelpLink } from '../../../../../../help/CSHHelp'
import { DeviceCheckSolutions } from '../../../../../../../../core/store/typings/DeviceCheckSolutions'

export type TProps = {
    item: IDiagnosticsPiceaMobileTestConfig
    grades: IGrade[]
    gradesCategories?: IGradesCategory[]
    useGradesCategories?: boolean
    onUpdateTest: (test: IDiagnosticsPiceaMobileTestConfig) => void
    onEditTest: (index: number) => void
}

enum GradeAssignmentType { Set, Case }

const UNDEFINED_ICON = "ConstructionCone"
const DOWNGRADE_ICON = "MarketDown"
const DECLINE_ICON = "HandsFree"

type TCheckSets = {
    allChecked?: boolean
    sets?: {
        id: number,
        cases?: number[]
        check?: boolean
    }[]
}

export const PiceaMobileDiagnosticsTestConfigurator: React.FC<TProps> = (props) => {
    
    const [checksState, setChecksState] = React.useState<TCheckSets>({})

    const checkCaseIsPiceaMobileSupported = (c?: IDiagnosticCase):boolean => {
        return c ?  c.platforms.some(p => p.connectionTypes.some(ct => [ConnectionTypes.NG_WIRELESS].includes(ct))) : false
    }

    const prepareCasesState = (): { groups: IGroup[], cases: IDiagnosticsPiceaMobileCaseConfig[] } => {
        let cases: IDiagnosticsPiceaMobileCaseConfig[] = []
        let groups: IGroup[] = []

        props.item.sets.forEach(set => {
            set.cases.forEach(c => {
                // Поддержка устаревшей версии конфигурации!
                // Раньше был простой массив с идентификаторами тест-кейсов
                if (typeof c === "number") {
                    cases.push({ id: c as number, options: {} })
                } else {
                    cases.push(c)
                }
            })
        })

        props.item.sets.forEach((s) => {
            let index: number = -1
            let count = 0
            let oldCount = casesState?.groups?.find(i => i.key === s.id.toString())?.count ?? count

            cases.forEach((c, i) => {
                if (s.id === getPiceaSetIdByCaseId(c.id)) {
                    if (index === -1) index = i
                    count++
                }
            })

            groups.push({
                key: s.id.toString(),
                name: setsDescriber(s.id),
                data: s,
                startIndex: index, count: count,
                isCollapsed: casesState?.groups?.find(i => i.key === s.id.toString())?.isCollapsed ? oldCount === count : false
            })
        })
        return { cases, groups }
    }

    const [casesState, setCasesState] = React.useState<{ groups: IGroup[], cases: IDiagnosticsPiceaMobileCaseConfig[] }>(prepareCasesState())
    const [currentCaseIndex, setCurrenCaseIndex] = React.useState<number>()

    React.useEffect(() => {
        setCasesState(prepareCasesState())
        let newCheckState = { ...checksState }
        props.item.sets.forEach((el) => {
            if (!newCheckState.sets?.find((set) => set.id === el.id)) {
                newCheckState.sets ? newCheckState.sets?.push({ id: el.id }) : [{ id: el.id }]

            }
        })
        setChecksState({
            sets: props.item.sets.map((set) => ({
                id: set.id
            }))
        })
    }, [props.item.sets])

    const onMoveUpSet = (setIndex: number) => {
        props.onUpdateTest({
            ...props.item,
            sets: props.item.sets.map((s, sIndex) => {
                if (sIndex === setIndex) {
                    return props.item.sets[sIndex - 1];
                }
                if (sIndex === setIndex - 1) {
                    return props.item.sets[sIndex + 1];
                }
                return s;
            })
        });
    }

    const onMoveUpCase = (setIndex: number, caseIndex: number) => {
        props.onUpdateTest({
            ...props.item,
            sets: props.item.sets.map((s, sIndex) => {
                if (sIndex === setIndex) {
                    return ({
                        ...s,
                        cases: s.cases.map((c, cIndex) => {
                            if (cIndex === caseIndex) {
                                return s.cases[cIndex - 1];
                            }
                            if (cIndex === caseIndex - 1) {
                                return s.cases[cIndex + 1];
                            }
                            return c;
                        })
                    })
                }
                return s;
            })
        });
    }

    const onDeleteTestSet = (setIndex: number) => {
        props.onUpdateTest({
            ...props.item,
            sets: props.item.sets.filter(s => s.id !== setIndex)
        })
    }

    const onCheckSet = (setId: number) => {
        if (checksState.sets?.find((set) => set.id === setId)?.check) {
            setChecksState({
                sets: checksState.sets.map((set) => {
                    if (set.id === setId) {
                        return { id: set.id }
                    }
                    return set
                })
            })
        } else {
            const cases = props.item.sets.find((set) => set.id === setId)?.cases
            const isAllMustBeChecked = checksState.sets?.filter((setElem) => setElem.id !== setId)?.find((set) => set.check !== true) ? false : true
            setChecksState({
                sets: [...(checksState.sets?.filter(i => i.id !== setId) ?? []),
                {
                    id: setId, check: true,
                    cases: cases ? cases.map((caseElem) => caseElem.id) : []
                }],
                allChecked: isAllMustBeChecked
            })
        }
    }

    const onCheckCase = (caseId: number) => {
        const setId = getPiceaSetIdByCaseId(caseId);
        const setConfig = props.item.sets.find((set) => set.id === setId)
        const previousSetState = checksState.sets?.find((set) => set.id === setId)
        const isUnCheck = previousSetState?.cases?.find((caseEl) => caseEl === caseId) ? true : false
        const isLastCase = setConfig?.cases.find((el) => ![...(previousSetState ?? { id: setId }).cases?.map((el) => el) ?? [], caseId].includes(el.id)) ? false : true
        const isSetMustBeChecked = isUnCheck ? false : isLastCase
        const isOtherSetsChecked = checksState.sets?.filter((setElem) => setElem.id !== setId)?.find((set) => set.check !== true) ? false : true
        const isAllMustBeChecked = isSetMustBeChecked && isOtherSetsChecked

        setChecksState({
            sets: [
                ...(checksState.sets?.filter((set) => set.id !== setId) ?? []),
                {
                    id: setId as number,
                    check: isSetMustBeChecked,
                    cases: isUnCheck
                        ? previousSetState?.cases?.filter((el) => el !== caseId)
                        : (
                            previousSetState?.cases ? [...previousSetState.cases, caseId] : [caseId]
                        )
                }],
            allChecked: isAllMustBeChecked
        })
    }

    const onCheckAll = () => {
        if (checksState.allChecked) {
            setChecksState({})
        } else {
            if (props.item.sets.length > 0) {
                setChecksState({
                    sets: props.item.sets.map((set) => ({
                        id: set.id,
                        check: true,
                        cases: set.cases.map((caseElem) => (caseElem.id))
                    })),
                    allChecked: true
                })
            }
        }
    }

    const onDeleteCheckItems = () => {
        let newTest: IDiagnosticsPiceaMobileTestConfig = { ...props.item, sets: [] }
        props.item.sets.forEach((set) => {
            if (checksState.sets?.find((i) => i.id === set.id)?.check) {
                return
            }
            let newCases: IDiagnosticsPiceaMobileCaseConfig[] = []
            set.cases.forEach((caseElem) => {
                if (checksState.sets?.find((setElem) => setElem.id === set.id)?.cases?.includes(caseElem.id)) {
                    return
                }
                newCases.push(caseElem)
            })
            newTest.sets.push({ ...set, cases: newCases })
        })
        props.onUpdateTest(newTest)
        setChecksState({})
    }
    const getOptions = (set: IDiagnosticSet , diaCase : IDiagnosticCase) => {
        if (set.id === DiagnosticSets.TS_BATTERY) {
            return diaCase.id === DiagnosticCases.TC_BATTERY_STRESS ? { failure_level: 16, min_battery_level: 16, level_drop: 2 } : { failure_level: 16 }
        } 
        else if(set.id === DiagnosticSets.TS_SOFTWARE && diaCase.id === DiagnosticCases.TC_UPTIME){
            return { uptime_warning_limit : 336 }
        }
        else if(set.id === DiagnosticSets.TS_SOFTWARE && diaCase.id === DiagnosticCases.TC_SLEEPTIME){
            return { sleep_time_percentage_limit : 20, sleep_time_warning_uptime_limit:24 }
        }
        else if ([DiagnosticCases.TC_RAM, DiagnosticCases.TC_STORAGE].includes(diaCase.id)) {
            return { limit: 80 }
        }
        else {
            return {}
        }
    }
    
    const getPreparedSetsAllCases = (sets: IDiagnosticSet[]): IDiagnosticsPiceaMobileSetConfig[] => {
        let newSets: IDiagnosticsPiceaMobileSetConfig[] = []

        sets.forEach((set) => {
            let cases = props.item.sets.find(s => s.id === Number(set.id))?.cases ?? []
            const availableCases = set.cases.filter(i => checkCaseIsPiceaMobileSupported(i) && !cases.find(c => c.id === i.id)) ?? []
            newSets.push({
                id: Number(set.id),
                grade: props.item.sets.find(i => i.id === Number(set.id))?.grade ?? '',
                cases: [
                    ...cases,
                    ...availableCases.map((i) => ({
                        id: i.id,
                        options: getOptions(set, i)
                    }))
                ]
            })
        })
        return newSets
    }

    const onAddTestAllSetsAllCases = () => {
        const existsSetsDefinitions = props.item.sets.map(i => definitions.diagnostic.db.find(ds => Number(ds.id) === i.id) as IDiagnosticSet)
        const newSetsDefinitions = definitions.diagnostic.db.filter(s => !props.item.sets.find(i => i.id === Number(s.id)) && definitions.diagnostic.db.find(ds => ds.id === s.id)?.cases?.find(i => checkCaseIsPiceaMobileSupported(i)))

        const sets: IDiagnosticsPiceaMobileSetConfig[] = [
            ...getPreparedSetsAllCases(existsSetsDefinitions),
            ...getPreparedSetsAllCases(newSetsDefinitions)
        ]

        props.onUpdateTest({
            ...props.item,
            sets: sets
        })
    }

    const onAddAllSets = (allSets: number[]) => {
        let sets = [...props.item.sets]
        allSets.forEach((set) => {
            if (props.item.sets.find(i => i.id === set)) {
                return
            }
            sets.push({
                id: set,
                grade: '',
                cases: []
            })
        })
        props.onUpdateTest({
            ...props.item,
            sets: sets
        })
    }

    const onAddTestSet = (setId: number) => {
        props.onUpdateTest({
            ...props.item,
            sets: props.item.sets ? [...props.item.sets, { id: setId, grade: "", cases: [] }] : [{ id: setId, grade: "", cases: [] }]
        })
    }

    const onAddTestSetAllCases = (setId: number, allCases: number[]) => {
        let cases = props.item.sets.find((set) => set.id === setId)?.cases
        allCases.forEach((caseElem) => {
            if (Number(caseElem) === DiagnosticCases.TC_BATTERY_STRESS) {
                cases?.push(
                    { id: caseElem as SupportedDiagnosticCases, options: { failure_level: 16, min_battery_level: 16, level_drop: 2 } }
                )
            } 
            else if (Number(caseElem) === DiagnosticCases.TC_BATTERY) {
                cases?.push({ id: caseElem as SupportedDiagnosticCases, options: { failure_level: 16 } })
            }
            else if ([DiagnosticCases.TC_RAM, DiagnosticCases.TC_STORAGE].includes(caseElem)) {
                cases?.push({ id: caseElem as SupportedDiagnosticCases, options: { limit: 80 } })
            }
            else if (Number(caseElem) === DiagnosticCases.TC_UPTIME) {
                cases?.push({ id: caseElem as SupportedDiagnosticCases, options: { uptime_warning_limit : 336 } })
            }
            else if (Number(caseElem) === DiagnosticCases.TC_SLEEPTIME) {
                cases?.push({ id: caseElem as SupportedDiagnosticCases, options: { sleep_time_percentage_limit : 20, sleep_time_warning_uptime_limit:24 } })
            }
            else {
                cases?.push({ id: caseElem as SupportedDiagnosticCases, options: {} })
            }
        })

        props.onUpdateTest({
            ...props.item,
            sets: props.item?.sets?.map(s => {
                if (s.id === setId) {
                    return ({
                        ...s,
                        cases: cases ?? []
                    })
                }
                return s
            })
        })
    }

    const onAddTestCase = (setId: PiceaMobileSets, caseId: number) => {
        props.onUpdateTest({
            ...props.item,
            sets: props.item?.sets?.map(s => {
                if (s.id === setId) {
                    if (caseId === DiagnosticCases.TC_BATTERY_STRESS) {
                        return ({
                            ...s,
                            cases: s.cases ? [...s.cases, { id: caseId, options: { failure_level: 16, min_battery_level: 16, level_drop: 2 } }] : [{ id: caseId, options: { failure_level: 16, min_battery_level: 16, level_drop: 2 } }]
                        })
                    }
                    if (caseId === DiagnosticCases.TC_BATTERY) {
                        return ({
                            ...s,
                            cases: s.cases ? [...s.cases, { id: caseId, options: { failure_level: 16 } }] : [{ id: caseId, options: { failure_level: 16 } }]
                        })
                    }
                    if (caseId === DiagnosticCases.TC_STORAGE || caseId === DiagnosticCases.TC_RAM ) {
                        return ({
                            ...s,
                            cases: s.cases ? [...s.cases, { id: caseId, options: { limit: 80 } }] : [{ id: caseId, options: { limit: 80 } }]
                        })
                    }
                    if (caseId === DiagnosticCases.TC_UPTIME ) {
                        return ({
                            ...s,
                            cases: s.cases ? [...s.cases, { id: caseId, options: { uptime_warning_limit: 336 } }] : [{ id: caseId, options: { uptime_warning_limit: 336 } }]
                        })
                    }
                    if (caseId === DiagnosticCases.TC_SLEEPTIME ) {
                        return ({
                            ...s,
                            cases: s.cases ? [...s.cases, { id: caseId, options: { sleep_time_percentage_limit: 20, sleep_time_warning_uptime_limit:24 } }] : [{ id: caseId, options: { sleep_time_percentage_limit: 20, sleep_time_warning_uptime_limit:24 } }]
                        })
                    }
                    return ({
                        ...s,
                        cases: s.cases ? [...s.cases, { id: caseId, }] : [{ id: caseId }]
                    })
                }
                return s
            })
        })
    }

    const onConfirmSet = (set: IDiagnosticsPiceaMobileSetConfig) => {
        props.onUpdateTest({
            ...props.item,
            sets: props.item.sets.map((s) => {
                if (s.id === set.id) {
                    return set;
                }
                return s;
            })
        })
    }

    const onConfirmCase = (caseItem: IDiagnosticsPiceaMobileCaseConfig) => {
        const setId = getPiceaSetIdByCaseId(caseItem.id);
        const set = props.item.sets.find(i => i.id === setId);
        if (!set) return
        onConfirmSet({
            ...set,
            cases: set.cases.map(c => {
                if (c.id === caseItem.id) {
                    return caseItem
                }
                return c
            })
        })
    }

    const getSetsMenuProps = (item: IDiagnosticsPiceaMobileTestConfig): IContextualMenuProps => {
        var sets = definitions.diagnostic.db.filter(i => i.cases.find(c => checkCaseIsPiceaMobileSupported(c)) && !casesState.groups.find(g => Number(g.key) === i.id)).map(s => s.id)
        let items = sets.map(i => ({
            key: `add-set-${i}`,
            onClick: () => onAddTestSet(i),
            text: definitions.diagnostic.setDescriber(i)
        }) as IContextualMenuItem)
        items = sets && sets.length > 0 ? [
            {
                itemType: ContextualMenuItemType.Section,
                key: 'items-section',
                sectionProps: {
                    items: items,
                }

            } as IContextualMenuItem,
            {
                key: 'all-section',
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                    items: [
                        {
                            key: 'add-all-sets',
                            onClick: () => { onAddAllSets(sets) },
                            text: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.ADD_ALL_SETS
                        },
                        {
                            key: 'add-all-sets-all-cases',
                            onClick: () => { onAddTestAllSetsAllCases() },
                            text: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.ADD_ALL_SETS_ALL_CASES
                        }
                    ],
                    topDivider: true
                }

            } as IContextualMenuItem,


        ] : [...items]
        return {
            items,
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const getCasesMenuProps = (setId: number): IContextualMenuProps | undefined => {
        var cases = definitions.diagnostic.getSet(setId)?.cases.filter(i => checkCaseIsPiceaMobileSupported(i) && !casesState.cases.find(cs => cs.id === i.id)) ?? []
        let items = cases?.map(c => ({
            key: `add-case-${c.id}`,
            onClick: () => onAddTestCase(setId, c.id),
            text: c.getName(),
            onRenderContent: (props: IContextualMenuItemProps) => {
                var setInfo = definitions.diagnostic?.db?.find(s => s.id === setId);
                var caseInfo = setInfo?.cases?.find(ci => ci.id == c.id);
                return (
                    <Stack grow>
                        <TooltipHost calloutProps={{ gapSpace: 10 }} content={onRenderCaseInfo(caseInfo)} directionalHint={DirectionalHint.rightCenter}>
                            <Text>{props.item.text}</Text>
                        </TooltipHost>
                    </Stack>
                )
            }
        }) as IContextualMenuItem)
        if (!cases?.length || cases?.length === 0) return undefined
        items = cases && cases.length > 0 ? [
            {
                key: 'items-section',
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                    items: items,
                }

            } as IContextualMenuItem,
            {
                itemType: ContextualMenuItemType.Section,
                key: 'all-section',
                sectionProps: {
                    items: [
                        {
                            key: 'addAllCases',
                            onClick: () => onAddTestSetAllCases(setId, cases.map(i => i.id)),
                            text: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.ADD_ALL_SETS_ALL_CASES,
                        }
                    ],
                    topDivider: true
                }
            } as IContextualMenuItem,
        ] : [...items ?? []]

        return {
            items: items,
            directionalHint: DirectionalHint.bottomRightEdge,
        }
    }

    const getGradeText = (gradeCode?: string, categoryCode?: string, isCategoryHidden?: boolean): string => {
        let gradeText = strings.CONSTRUCTOR.GRADES.GRADE_NOT_FOUND
        if (categoryCode) {
            const category = props.gradesCategories?.find(i => i.code === categoryCode)
            const grade = category?.grades.find(i => i.code === gradeCode)
            if (grade) {
                gradeText = isCategoryHidden ? grade.name : `${category?.name}: ${grade.name}`
            }
        } else {
            const grade = props.grades.find(i => i.code === gradeCode)
            if (grade) {
                gradeText = grade.name
            }
        }
        return gradeText;
    }

    const getGradeAssignmentIcon = (type: GradeAssignmentType, grade?: string) => {
        switch (grade) {
            case undefined: return type === GradeAssignmentType.Case ? SharedColors.gray10 : UNDEFINED_ICON
            case "": return DECLINE_ICON
            // case "!WASRESET": return ERROR_ICON
            default: return DOWNGRADE_ICON
        }
    }

    const getGradeAssignmentTextColor = (type: GradeAssignmentType, grade?: string) => {
        switch (grade) {
            case undefined: return type === GradeAssignmentType.Case ? SharedColors.gray10 : SharedColors.gray20
            case "": return SharedColors.red10
            case "!WASRESET": return SharedColors.red10
            default: return SharedColors.cyanBlue10
        }
    }

    const getGradeAssignmentText = (type: GradeAssignmentType, grade?: string, category?: string, isCategoryHidden?: boolean) => {
        switch (grade) {
            case undefined:
                if (type === GradeAssignmentType.Case) return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.CASE_USE_SET_GRADE;
                if (type === GradeAssignmentType.Set) return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.NO_INFLUENCE;
                break;
            case "": return strings.CONSTRUCTOR.GRADES.DECLINE;
            case '!WASRESET':
                if (type === GradeAssignmentType.Case) return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_WAS_RESET;
                if (type === GradeAssignmentType.Set) return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_WAS_RESET;
                break;
            default: return getGradeText(grade, category, isCategoryHidden)
        }
    }

    const getItemMenuProps = (type: GradeAssignmentType, itemId: number, grade?: string): IContextualMenuProps => {
        let result: IContextualMenuProps = {
            items: []
        }

        result.items.push(getGradeAssignmentMenuItem(type, itemId, grade))

        let actionsItem: IContextualMenuItem = {
            key: `${type === GradeAssignmentType.Case ? "case" : "set"}-${itemId}-actions`,
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                items: type === GradeAssignmentType.Case ? getCaseActionsMenuProps(itemId).items : getSetActionsMenuProps(itemId).items,
                topDivider: true,
                title: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.CONTEXTUAL_MENU.ACTIONS,
            }
        }

        result.items.push(actionsItem)

        return result;
    }

    const getSetActionsMenuProps = (setId: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `delete-set-${setId}`,
                    onClick: () => onDeleteTestSet(setId),
                    text: strings.BUTTONS.TEXT.DELETE,
                    iconProps: { iconName: "Delete", style: { color: theme.palette.black } }
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const getCaseActionsMenuProps = (caseId: number): IContextualMenuProps => {
        const setId = getPiceaSetIdByCaseId(caseId);
        const setConfig = props.item.sets.find(i => i.id === setId)
        if (!setConfig) return { items: [] }
        return {
            items: [
                {
                    key: `case-${caseId}-settings`,
                    onClick: () => setCurrenCaseIndex(caseId),
                    text: strings.BUTTONS.TEXT.OPTIONS,
                    iconProps: { iconName: "Repair", style: { color: theme.palette.black } }
                },
                {
                    key: `delete-case-${caseId}`,
                    onClick: () => onConfirmSet({
                        ...setConfig,
                        cases: setConfig?.cases.filter(i => i.id !== caseId) ?? []

                    }),
                    text: strings.BUTTONS.TEXT.DELETE,
                    iconProps: { iconName: "Delete", style: { color: theme.palette.black } }
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const getGradeMenuItem = (type: GradeAssignmentType, key: string, itemId: number, icon?: string, itemGrade?: string, code?: string, category?: string): IContextualMenuItem => {
        const itemCategory = itemGrade?.indexOf("-") === -1 ? undefined : itemGrade?.split("-")[0]
        return {
            iconProps: icon ? { iconName: icon, style: { color: theme.palette.black } } : undefined,
            key: key,
            onClick: () => type === GradeAssignmentType.Case
                ? onConfirmCase({ ...casesState.cases.find(i => i.id === itemId) as IDiagnosticsPiceaMobileCaseConfig, grade: category ? `${category}-${code}` : code })
                : onConfirmSet({ ...props.item.sets.find(i => i.id === itemId) as IDiagnosticsPiceaMobileSetConfig, grade: category ? `${category}-${code}` : code }),
            text: getGradeAssignmentText(type, code, category, true),
            disabled: itemCategory ? `${category}-${code}` === itemGrade : code === itemGrade
        }
    }

    const getGradeAssignmentMenuItem = (type: GradeAssignmentType, itemId: number, grade?: string): IContextualMenuItem => {
        let result: IContextualMenuItem = {
            key: `${type === GradeAssignmentType.Case ? "case" : "set"}-${itemId}-assingment`,
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                items: [],
                topDivider: true,
                bottomDivider: true,
                title: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.CONTEXTUAL_MENU.ASSIGNMENT,
            }
        }

        result.sectionProps?.items.push(getGradeMenuItem(type, `${type === GradeAssignmentType.Case ? "case" : "set"}-${itemId}-no-grade`, itemId, UNDEFINED_ICON, grade, undefined, undefined))

        let gradeMenu: IContextualMenuItem = {
            iconProps: { iconName: DOWNGRADE_ICON, style: { color: theme.palette.black } },
            key: `${type === GradeAssignmentType.Case ? "case" : "set"}-${itemId}-grades`,
            subMenuProps: {
                items: []
            },
            text: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.USE_DOWN_GRADE
        }
        if (props.useGradesCategories && type === GradeAssignmentType.Case) {
            props.gradesCategories?.forEach(gc => {
                let gradeCategoryMenuItem: IContextualMenuItem = {
                    key: `${type === GradeAssignmentType.Case ? "case" : "set"}-${itemId}-gradeCategory-${gc.code}}`,
                    subMenuProps: {
                        items: []
                    },
                    text: gc.name
                }

                gc.grades.forEach(g => {
                    gradeCategoryMenuItem.subMenuProps?.items.push(getGradeMenuItem(type, `${type === GradeAssignmentType.Case ? "case" : "set"}-${itemId}-grade-${gc.code}-${g.code}`, itemId, undefined, grade, g.code, gc.code))
                })

                gradeMenu.subMenuProps?.items.push(gradeCategoryMenuItem)
            })

        } else {

            props.grades?.forEach(g => {
                gradeMenu.subMenuProps?.items.push(getGradeMenuItem(type, `${type === GradeAssignmentType.Case ? "case" : "set"}-${itemId}-grade-${g.code}`, itemId, undefined, grade, g.code))
            })


        }
        result.sectionProps?.items.push(gradeMenu)

        result.sectionProps?.items.push(getGradeMenuItem(type, `${type === GradeAssignmentType.Case ? "case" : "set"}-${itemId}-decline`, itemId, DECLINE_ICON, grade, "", undefined))

        return result;
    }

    const styles = mergeStyleSets({
        button: {
            width: 130,
        },
        callout: {
            width: 320
        },
        title: {
            marginBottom: 12,
            fontWeight: FontWeights.semilight,
        },
        description: {
            marginBottom: 12,
        },
        runType: {
            color: SharedColors.gray10,
        },
        supported: {
            marginBottom: 6,
            fontWeight: FontWeights.semibold,
        },
        platform: {
            color: SharedColors.cyanBlue10,
            fontWeight: FontWeights.semibold,
        },
        link: {
            display: 'block',
            marginTop: 20,
        },
    });

    const onRenderCaseInfo = (item?: IDiagnosticCase): JSX.Element | undefined => {
        if (item) {
            const imageString = definitions.diagnostic.getImage(item.id)
            let base64icon = ''
            if (imageString) {
                base64icon = btoa(imageString)
            }
            return (
                <Stack key={item.id} tokens={{ padding: "10px 14px" }} className={styles.callout}>
                    <Stack.Item>
                        <Stack horizontal grow tokens={{ childrenGap: 16 }}>
                            <Stack.Item grow>
                                <Text block variant="xLarge" className={styles.title}>
                                    {item.getName()}
                                </Text>
                            </Stack.Item>
                            <Stack.Item>
                                {imageString && (
                                    <img alt='image' style={{ height: 24, width: 24 }} src={`data:image/svg+xml;base64,${base64icon}`} />
                                ) || (
                                        <Icon style={{ fontSize: 16 }} iconName='TestBeakerSolid' />
                                    )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Text block variant="small" className={styles.description}>
                            {item.getDescription()}
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text block variant="small" className={styles.supported}>
                            {strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.CONTEXTUAL_MENU.TOOLTIP_MENU.SUPPORTING}
                        </Text>
                        <Stack tokens={{ childrenGap: 4 }}>
                            {item.platforms.map((p, index) => (
                                <Stack.Item key={index}>
                                    <Stack horizontal grow>
                                        <Stack horizontal grow tokens={{ childrenGap: 8 }}>
                                            <Text variant="small" className={styles.platform}>{p.name}</Text>
                                            {/* <Text variant="xSmall" className={styles.runType}>{definitions.diagnostic.runTypeDescriber(p.runType as DiagnosticRunTypes)}</Text> */}
                                        </Stack>
                                        <Stack.Item>
                                            <Stack horizontal tokens={{ childrenGap: 4 }}>
                                                {p.connectionTypes.map((ct, index) => (
                                                    <Badge key={index} title={ct} />
                                                ))}
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            ))}
                        </Stack>
                    </Stack.Item>
                    <Stack.Item className={styles.link}>
                        <CSHHelpLink  articleid="1135" alignment='left' label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.CONTEXTUAL_MENU.TOOLTIP_MENU.LINK}/>
                    </Stack.Item>

                </Stack>
            )
        }
    };

    const onRenderColumnHeaderCommonCheck: IRenderFunction<IDetailsColumnProps> = (chProps) => {
        if (chProps) {
            return (
                <Stack tokens={{ padding: "0px 32px 0px 0px" }} horizontal style={{ height: 42 }} verticalAlign="center">
                    <div onClick={(ev) => { ev.preventDefault(); onCheckAll(); }} >
                        <Check checked={checksState.allChecked} />
                    </div>
                </Stack>
            );
        }

        return null;
    }

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: '',
            fieldName: 'up',
            minWidth: 24,
            maxWidth: 24,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IDiagnosticsPiceaCaseConfig) => {
                let caseIndex = 0
                let setIndex = 0
                props.item.sets.forEach((s, sIndex) => {
                    const set = s.cases.find(i => i.id === item.id)
                    if (set) {
                        s.cases.forEach((c, cIndex) => {
                            if (c.id === item.id) {
                                caseIndex = cIndex
                                setIndex = sIndex
                            }

                        })

                    }
                })
                return (
                    <Stack horizontal verticalFill verticalAlign="center">
                        <IconButton disabled={!caseIndex} iconProps={{ iconName: "Up", className: 'up-icon' }} styles={onHoverIconStyles} style={{ height: 24, width: 24, visibility: caseIndex ? undefined : 'hidden' }} onClick={(ev) => { ev.stopPropagation(); onMoveUpCase(setIndex, caseIndex) }} />
                    </Stack>
                )
            },
        },
        {
            key: 'column0.5',
            name: '',
            fieldName: 'check',
            minWidth: 24,
            maxWidth: 24,
            isResizable: false,
            data: 'string',
            isPadded: true,
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommonCheck,
            onRender: (item: IDiagnosticsPiceaCaseConfig) => {
                var setId = getPiceaSetIdByCaseId(item.id);
                return (
                    <Stack verticalFill verticalAlign='center'>
                        <div onClick={() => { onCheckCase(item.id) }}>
                            <Check checked={checksState.sets?.find((set) => set.id === setId)?.cases?.find((elem) => elem === item.id) ? true : false} />
                        </div>
                    </Stack>
                )
            },
        },
        {
            key: 'column1',
            name: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IDiagnosticsPiceaCaseConfig) => {
                var setId = getPiceaSetIdByCaseId(item.id);
                var setInfo = definitions.diagnostic.db.find(s => s.id === setId);
                var caseInfo = setInfo?.cases.find(c => c.id == item.id);
                if (!caseInfo) return null;
                const imageString = definitions.diagnostic.getImage(caseInfo.id)
                let base64icon = ''
                if (imageString) {
                    base64icon = btoa(imageString)
                }
                return (
                    <Stack horizontal verticalFill verticalAlign="center" tokens={{ childrenGap: 12 }}>

                        {imageString && (
                            <img alt='image' style={{ height: 24, width: 24 }} src={`data:image/svg+xml;base64,${base64icon}`} />
                        ) || (
                                <Icon style={{ fontSize: 16 }} iconName='TestBeakerSolid' />
                            )}
                        <Text style={{ color: SharedColors.gray40 }}>{caseInfo.getName()}</Text>
                        <TooltipHost content={checkCaseIsPiceaMobileSupported(caseInfo) ? onRenderCaseInfo(caseInfo) : strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.CONTEXTUAL_MENU.TOOLTIP_MENU.UNSUPPORTED_TEST} delay={TooltipDelay.zero}
                            directionalHint={DirectionalHint.bottomCenter}
                            styles={{ root: { display: 'flex' } }}
                        >
                            <Icon iconName="Info" style={{ color: !checkCaseIsPiceaMobileSupported(caseInfo) ? SharedColors.red10 : undefined }} />
                        </TooltipHost>
                    </Stack>
                )
            },
        },
        {
            key: 'column1.5',
            name: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.COLUMNS.OPTIONS,
            fieldName: 'options',
            minWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IDiagnosticsPiceaCaseConfig) => <TestCaseOptionViewer item={item} />
        },
        {
            key: 'column2',
            name: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.COLUMNS.GRADE_ASSINGNMENT,
            fieldName: 'person',
            minWidth: 250,
            maxWidth: 250,
            isResizable: false,
            data: 'string',
            styles: { cellTitle: { justifyContent: 'flex-end', paddingRight: 22 }, root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IDiagnosticsPiceaCaseConfig) => {
                const caseCategory = item.grade?.indexOf("-") === -1 ? undefined : item.grade?.split("-")[0]
                const caseGrade = item.grade?.indexOf("-") === -1 ? item.grade : item.grade?.split("-")[1]
                return (
                    <Stack grow horizontalAlign='end' horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                        {item.errors && item.errors.length > 0 && (
                            <ErrorsRenderDiagnostics caseItem={item} />
                        )}
                        {(!item.errors || item.errors.length === 0) && (
                            <Stack grow horizontalAlign='end' horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                                <Icon style={{ fontSize: 14, color: caseGrade === "!WASRESET" ? SharedColors.red10 : undefined }} iconName={getGradeAssignmentIcon(GradeAssignmentType.Case, caseGrade)} />
                                <Text variant='small' style={{ color: getGradeAssignmentTextColor(GradeAssignmentType.Case, caseGrade) }}>{getGradeAssignmentText(GradeAssignmentType.Case, caseGrade, caseCategory)}</Text>
                            </Stack>
                        )}
                        <Stack horizontal verticalFill verticalAlign='center'>
                            <Stack.Item>
                                <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={onHoverIconStyles} menuProps={getItemMenuProps(GradeAssignmentType.Case, item.id, caseGrade)} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                )
            }
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            var setId = getPiceaSetIdByCaseId(rProps.item.id);
            var setInfo = definitions.diagnostic?.db?.find(s => s.id == setId);
            var caseInfo = setInfo?.cases?.find(c => c.id == rProps.item.id);
            if (!checkCaseIsPiceaMobileSupported(caseInfo)) {
                customStyles.root = {
                    backgroundColor: "rgba(253, 231, 233,0.6)",
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: "rgb(253, 231, 233)",
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.hidden-button': {
                            color: theme.palette.black
                        },
                        '.up-icon': {
                            fontSize: 16,
                            color: theme.palette.themePrimary
                        }
                    }
                };
                customStyles.cellUnpadded = { paddingLeft: 6 }
            } else {
                customStyles.root = customRowRootStyle;
            }

            customStyles.cellUnpadded = { paddingLeft: 6 }
            return <DetailsRow {...rProps} styles={customStyles} />
        }
        return null;
    }

    const onRenderGroupTitle: IRenderFunction<IGroupHeaderProps> = (gProps) => {
        let customStyles = {};
        if (!gProps?.group) return null
        const setConfig = props.item.sets.find((i, index) => i.id === Number.parseInt(gProps.group?.key as string))
        if (!setConfig) return null
        const casesCount = definitions.diagnostic.getSet(Number(gProps.group?.key))?.cases?.filter(i => !casesState.cases.find(c => c.id === i.id)).length
        const setCategory = setConfig.grade?.indexOf("-") === -1 ? undefined : setConfig.grade?.split("-")[0]
        const setGrade = setConfig.grade?.indexOf("-") === -1 ? setConfig.grade : setConfig.grade?.split("-")[1]
        const unsupportedSet = definitions.diagnostic.db.filter(ds => !ds.cases.find(i => checkCaseIsPiceaMobileSupported(i)))
        if (unsupportedSet.find((set) => set?.id === Number.parseInt(gProps.group?.key as string))) {
            customStyles = {
                root: {
                    backgroundColor: "rgba(253, 231, 233,0.6)",
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: "rgb(253, 231, 233)",
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.hidden-button': {
                            color: theme.palette.black
                        },
                        '.up-icon': {
                            fontSize: 16,
                            color: theme.palette.themePrimary
                        }
                    }
                }
            }
        }
        else {
            customStyles = {
                root: {
                    backgroundColor: "rgb(250, 249, 248)",
                    borderBottom: "1px solid rgb(237, 235, 233)"
                }
            }
        }

        const menuProps = getCasesMenuProps(Number.parseInt(gProps?.group?.key as string))

        return (
            <Stack style={{ cursor: 'default' }} styles={customStyles} grow verticalFill horizontal verticalAlign='center' tokens={{ padding: "12px 24px 12px 0px", childrenGap: 8 }} className={classNames.listItem} >
                <Stack.Item style={{ minWidth: 24, minHeight: 24, paddingLeft: '8px' }}>
                    <IconButton disabled={!gProps.groupIndex} iconProps={{ iconName: "Up", className: 'up-icon' }} styles={onHoverIconStyles} style={{ height: 24, width: 24, visibility: gProps.groupIndex ? undefined : 'hidden' }} onClick={(ev) => { ev.stopPropagation(); gProps?.groupIndex && onMoveUpSet(gProps?.groupIndex) }} />
                </Stack.Item>
                <Stack.Item tokens={{ padding: '0 18px 0 10px' }}>
                    <div onClick={() => onCheckSet(Number(gProps.group?.key))}>
                        <Check checked={checksState.sets?.find((set) => set.id === Number(gProps.group?.key))?.check} />
                    </div>
                </Stack.Item>
                <Stack.Item grow>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                        <Text variant="medium" style={{ color: SharedColors.gray40, fontWeight: 600 }}>{gProps?.group?.name}</Text>
                        <Badge title={`${gProps?.group?.count}`} />
                        {unsupportedSet.find((set) => set?.id === Number.parseInt(gProps.group?.key as string)) ? (
                            <TooltipHost content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.CONTEXTUAL_MENU.TOOLTIP_MENU.UNSUPPORTED_SET} delay={TooltipDelay.zero}
                                directionalHint={DirectionalHint.bottomCenter}
                                styles={{ root: { display: 'flex' } }}
                            >
                                <Icon iconName="Info" style={{ color: SharedColors.red10 }} />
                            </TooltipHost>
                        ) : (<Stack.Item onClick={(ev) => ev.stopPropagation()}>
                            <IconButton disabled={!menuProps?.items } iconProps={{ iconName: "Add" }} menuIconProps={{ style: { display: 'none' } }} style={{ visibility: !casesCount ? 'hidden' : undefined }} menuProps={casesCount ? menuProps : undefined} />
                        </Stack.Item>)}
                        <Stack grow horizontalAlign='end' horizontal verticalAlign='center' tokens={{ childrenGap: 8 }}>
                            {setConfig.errors && setConfig.errors.find((error) => error.code === 1) && (
                                <ErrorsRenderDiagnostics set={setConfig} />
                            )}
                            {!setConfig.errors?.find((error) => error.code === 1) && (
                                <Stack grow horizontalAlign='end' horizontal verticalAlign='center' tokens={{ childrenGap: 8 }}>
                                    <Icon style={{ fontSize: 14 }} iconName={getGradeAssignmentIcon(GradeAssignmentType.Set, setGrade)} />
                                    <Text style={{ color: getGradeAssignmentTextColor(GradeAssignmentType.Set, setGrade) }}>{getGradeAssignmentText(GradeAssignmentType.Set, setGrade, setCategory)}</Text>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Stack.Item>
                <Stack.Item style={{ paddingRight: 4 }}>
                    <IconButton styles={onHoverIconStyles} menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} menuProps={getItemMenuProps(GradeAssignmentType.Set, Number.parseInt(gProps.group.key as string), setGrade)} />
                </Stack.Item>
            </Stack>
        )
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }
        return (
            <Sticky>
                {defaultRender!({ ...hProps })}
            </Sticky>
        );
    }

    // convert IDiagnosticsPiceaMobileCaseConfig to IDiagnosticsPiceaCaseConfig
    const toPiceaCaseConfig = (mobileConfig?: IDiagnosticsPiceaMobileCaseConfig): IDiagnosticsPiceaCaseConfig|undefined => {
        return mobileConfig ? {
            id: mobileConfig.id,
            grade: mobileConfig.grade,
            options: mobileConfig.options ?? {},
            errors: mobileConfig.errors
        } : undefined;
    }

    return (
        <>
            <Stack tokens={{ padding: "0px 16px" }} horizontal>
                <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton disabled={getSetsMenuProps(props.item).items.length === 0} menuProps={getSetsMenuProps(props.item)} >{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.ADD_SET}</PrimaryButton>
                    {checksState.sets?.find((set) => set.check || checksState.sets?.find((set) => set.cases && set.cases.length > 0)) && <DangerButton onClick={onDeleteCheckItems}>{strings.BUTTONS.TEXT.DELETE_SELECTED}</DangerButton>}
                </Stack>
                <Stack.Item>
                    <SecondaryButton text={strings.BUTTONS.TEXT.EDIT_DATA} onClick={() => props.onEditTest(props.item.index)} />
                </Stack.Item>
            </Stack>
            <Stack verticalFill style={{ position: 'relative' }}>
                <ScrollablePane>
                    <DetailsList
                        groups={casesState?.groups}
                        items={casesState?.cases ?? []}
                        columns={columns}
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        ariaLabelForSelectionColumn="Toggle selection"
                        checkButtonAriaLabel="select row"
                        checkButtonGroupAriaLabel="select section"
                        onRenderDetailsHeader={onRenderDetailsHeader}
                        groupProps={{
                            showEmptyGroups: true,
                            headerProps: {
                                onRenderTitle: onRenderGroupTitle,
                                styles: {
                                    root: {
                                        backgroundColor: "rgb(250, 249, 248)",
                                        borderBottom: "1px solid rgb(237, 235, 233)"
                                    }
                                }
                            }
                        }}
                        onRenderRow={onRenderRow}
                        selectionMode={SelectionMode.none}
                    />
                </ScrollablePane>
                <CustomPanel title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TEST_RUN_OPTIONS_TITLE}
                    isOpen={currentCaseIndex !== undefined}
                    description={testDescriber(currentCaseIndex)}
                    onCancel={() => setCurrenCaseIndex(undefined)}
                >
                    <DiagnosticCaseOptionsForm onConfirmCase={onConfirmCase} caseElement={toPiceaCaseConfig(casesState?.cases.find((el) => el.id === currentCaseIndex))} options={ casesState?.cases.find((el) => el.id === currentCaseIndex)?.options} handleCancelClick={() => setCurrenCaseIndex(undefined)} diagnosticType={DeviceCheckSolutions.PiceaMobile} />
                </CustomPanel>
            </Stack>
        </>
    )
}

const theme = getTheme();


const onHoverIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const classNames = mergeStyleSets({
    listItem: [
        {
            padding: 4,
            borderLeft: '2px solid transparent',
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                },
                '.up-icon': {
                    fontSize: 16,
                    color: theme.palette.themePrimary
                }
            }
        },
    ]
});

const customRowRootStyle: IStyle = {
    cursor: 'default',
    ':hover': {
        '.disabled-icon': {
            color: theme.palette.black
        },
        '.menu-icon': {
            color: theme.palette.black
        },
        '.up-icon': {
            fontSize: 16,
            color: theme.palette.themePrimary
        }
    }
};
