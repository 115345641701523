import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/settings-actions";
import { ISettings } from "../store";

export const reducer: Reducer<ISettings> = (state: ISettings | undefined, incomingAction: Action): ISettings => {
    if (state === undefined) {
        return {
            isReady: false,
            isUpdating: false
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SETTINGS_ORGANIZATION_REQUEST_DATA':
            return { ...state, isReady: false, errors: undefined };
        case 'SETTINGS_ORGANIZATION_START_UPDATE':
            return { ...state, isUpdating: true };
        case 'SETTINGS_ORGANIZATION_STOP_UPDATE':
            return { ...state, isUpdating: false };
        case 'SETTINGS_ORGANIZATION_RECEIVE_DATA':
            return { ...state, organization: action.data, isReady: true, errors: undefined };
        case 'SETTINGS_ORGANIZATION_REFRESH_PRIVATE_KEY':
            if (!state.organization) return state;
            return {
                ...state,
                organization: {
                    ...state.organization,
                    privateKey: action.privateKey
                }
            };
        case 'SETTINGS_ORGANIZATION_REFRESH_PICEASOFT_CREDENTIALS':
            console.log(action.data)
            if (!state.organization) return state;
            return {
                ...state,
                organization:{
                    ...state.organization,
                    piceaCredentials: action.data
                }
            }

        case 'SETTINGS_ORGANIZATION_RECEIVE_ERRORS':
            return { ...state, isReady: true, errors: action.errors };
        default: return state;
    }
};
