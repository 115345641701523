import { FontIcon, ITooltipHostStyles, Stack, Text, TooltipHost, TooltipOverflowMode, css, mergeStyleSets } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { IPhotographicModeConfig, IPhotographicModeConfigUI } from '@piceasoft/core';
import { strings } from '../../../../../../../localization/strings';

type TStateProps = {
    config: IPhotographicModeConfig<IPhotographicModeConfigUI>
    icon?: string
}

export const PhotographicState: React.FC<TStateProps> = (props) => {
    return <None description={props.config.ui?.description} icon={props.icon} />
}

type TNoneStateProps = {
    description?: string
    icon?: string
}

const None: React.FC<TNoneStateProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 24 }}>
            <Stack.Item style={{ color: SharedColors.gray40 }}>
                <TooltipHost
                    overflowMode={TooltipOverflowMode.Self}
                    content={props.description ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.UPLOAD_DEVICE_PHOTO}
                    styles={tooltipStyles}
                    hostClassName={css(styles.title)}
                >
                    {props.description ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.UPLOAD_DEVICE_PHOTO}
                </TooltipHost>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontalAlign="center">
                    <FontIcon iconName={props.icon ?? "Camera"} style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray20 }} />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const styles = mergeStyleSets({
    title: [
        {
            overflow: 'hidden',
            overflowWrap: "break-word",
            maxHeight: 60,
        }
    ]
}) 

const tooltipStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'block' }
}