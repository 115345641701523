import { TextField } from "@fluentui/react"
import { strings } from "../../../../../../../../../../../localization/strings"
import { TCustomFieldData, IContractCustomField } from "@piceasoft/core"

type TProps = {
    value?: TCustomFieldData
    errorMessage: string
    customField: IContractCustomField
    handleOnBlur: () => void
    handleOnChange: (value?: TCustomFieldData) => void
}

const CustomField: React.FunctionComponent<TProps> = (props: TProps) => {
    let {value, errorMessage, customField, handleOnChange, handleOnBlur} = props
    return (
        <TextField
            required={customField.required}
            value={value?.value}
            onChange={(event, newValue) => handleOnChange({
                key: customField.key,
                value: newValue ?? ""
            })}
            label={customField.label ?? `${strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.ADDITIONAL_FIELD} ${customField.key}`}
            placeholder={customField.placeholder ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PLACEHOLDER_ADDITIONAL_FIELD}
            errorMessage={errorMessage}
            onBlur={() => handleOnBlur()}
        />
    )
}

export default CustomField