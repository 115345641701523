/** The file was automatically generated. Please do not modify manually */
import { strings_de } from "./de/strings";
import { strings_el } from "./el/strings";
import { strings_en } from "./en/strings";
import { strings_es } from "./es/strings";
import { strings_fr } from "./fr/strings";
import { strings_it } from "./it/strings";
import { strings_nl } from "./nl/strings";
import { strings_pt_br } from "./pt-br/strings";
import { strings_pt } from "./pt/strings";
import { strings_ru } from "./ru/strings";
import { strings_tr } from "./tr/strings";
import { strings_bg } from "./bg/strings";
import { merge } from "lodash";
export var strings = {
    EN: strings_en,
    DE: merge(JSON.parse(JSON.stringify(strings_en)), strings_de),
    EL: merge(JSON.parse(JSON.stringify(strings_en)), strings_el),
    ES: merge(JSON.parse(JSON.stringify(strings_en)), strings_es),
    FR: merge(JSON.parse(JSON.stringify(strings_en)), strings_fr),
    IT: merge(JSON.parse(JSON.stringify(strings_en)), strings_it),
    NL: merge(JSON.parse(JSON.stringify(strings_en)), strings_nl),
    PT_BR: merge(JSON.parse(JSON.stringify(strings_en)), strings_pt_br),
    PT: merge(JSON.parse(JSON.stringify(strings_en)), strings_pt),
    RU: merge(JSON.parse(JSON.stringify(strings_en)), strings_ru),
    TR: merge(JSON.parse(JSON.stringify(strings_en)), strings_tr),
    BG: merge(JSON.parse(JSON.stringify(strings_en)), strings_bg)
};
