import { Stack, Text } from "@fluentui/react"
import { SharedColors } from "@fluentui/theme"
import { strings } from "../../localization/strings"

export const PasswordPolicy: React.FC<{ isManager?: boolean }> = props => {
    return (
        <Stack.Item>
            <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_POLICY_REQUIREMENTS}</Text>
            <ul style={{ paddingLeft: 24, color: SharedColors.gray30 }}>
                <li>
                    <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{props.isManager ? strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_MIN_15 : strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_MIN_8}</Text>
                </li>
                <li>
                    <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_SPECIAL_SYMBOLS_1}</Text>
                </li>
                <li>
                    <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_CONSECUTIVE_MAX_3}</Text>
                </li>
            </ul>
        </Stack.Item>
    )
}