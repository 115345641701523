import { getTheme, IconButton, MessageBar, MessageBarType, SharedColors, Stack, Text } from "@fluentui/react"
import { Dispatch } from "react";
import { ICommonOfferConfig, IConfigErrors, IPreOfferConfig } from "@piceasoft/core";
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";

type TProps = {
    config: IPreOfferConfig
    commonConfig: ICommonOfferConfig
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
    showCustomSettings: (offerProviderCode?: string) => void
    showDiscountsSettings: () => void
}

export const PreOffer: React.FC<TProps> = (props) => {
    return (
        <StageBox title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.TITLE} settingsButtonProps={{ onClick: props.showSettings }}>
            <Stack tokens={{ childrenGap: 16 }}>
                {props.commonConfig.allowDiscount && (
                    <OfferItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.TITLE}
                        onConfig={props.showDiscountsSettings}
                        config={props.commonConfig}
                    />
                )}
                {props.commonConfig.providers?.map((co, index) => (
                    <OfferItem key={index} title={co.name}
                        config={props.commonConfig}
                        errors={co.errors}
                        onConfig={() => props.showCustomSettings(co.code)} description={co.description}
                    />
                ))}
            </Stack>
        </StageBox>
    )
}

type TOfferItemProps = {
    title: string
    description?: string
    config?: ICommonOfferConfig
    errors?: IConfigErrors[]
    onConfig: () => void
}


export const OfferItem: React.FC<TOfferItemProps> = (props) => {
    const errors = props.errors ?? [] 
    let errorListRender: JSX.Element = (
        <div style={{ display: "inline" }}>
            <ul style={{ paddingLeft: 16 }}>
                {errors?.map((error, index) => (
                    <li key={index}>{error.text}</li>
                ))}
            </ul>
        </div>
    )
    const renderErrors = (
        <>
            {errors && errors.length > 0 && (
                <MessageBar isMultiline={true} overflowButtonAriaLabel="See more" messageBarType={MessageBarType.severeWarning}>
                    <b style={{ width: "100%" }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.NOTES_ON_SETTINGS_MODULE}</b> {errorListRender}
                </MessageBar>
            )}
        </>
    )
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item grow styles={{ root: { paddingRight: 18 } }}>
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <Stack onClick={props.onConfig} styles={{
                        root: {
                            padding: 16,
                            color: theme.palette.black,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: errors && errors.length > 0 ? '#ee7676' : theme.palette.neutralQuaternaryAlt,
                            backgroundColor: theme.palette.white,
                            position: "relative",
                            ":hover": {
                                cursor: "pointer",
                                borderColor: errors && errors.length > 0 ? SharedColors.redOrange10 : theme.palette.themePrimary,
                                backgroundColor: errors && errors.length > 0 ? theme.palette.neutralLighter : theme.palette.themeLighterAlt
                            }
                        }
                    }}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Text styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{props.title}</Text>
                            </Stack>
                        </Stack.Item>
                        {props.description && (
                            <Stack.Item>
                                <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>{props.description}</Text>
                            </Stack.Item>
                        )}
                    </Stack>
                    <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0, zIndex: 100 } }}>
                        <IconButton onClick={props.onConfig} iconProps={{ iconName: "Repair" }} styles={{
                            root: {
                                width: 36,
                                height: 36,
                                backgroundColor: theme.palette.white,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: errors && errors.length > 0 ? '#ee7676' : theme.palette.neutralQuaternaryAlt,
                                borderRadius: "50%",
                                color: errors && errors.length > 0 ? SharedColors.redOrange10 : theme.palette.black,
                                boxShadow: theme.effects.elevation8,
                                ":hover": {
                                    borderColor: errors && errors.length > 0 ? SharedColors.redOrange10 : theme.palette.themePrimary,
                                    backgroundColor: errors && errors.length > 0 ? theme.palette.neutralLighter : theme.palette.themeLighterAlt
                                }
                            },
                            flexContainer: {
                                borderRadius: "50%",
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            },
                            iconHovered: {
                                color: errors && errors.length > 0 ? SharedColors.redOrange10 : theme.palette.black
                            }
                        }} />
                    </Stack>
                    <Stack.Item>
                        {renderErrors}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
