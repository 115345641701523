import { DefaultButton, FontIcon, FontWeights, getTheme, IconButton, mergeStyleSets, Modal, PrimaryButton, ScrollablePane, SharedColors, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from "markdown-it";
import React from "react";
import { strings } from "../../../localization/strings";
import { SecondaryButton } from "../buttons/SecondaryButton";
import { Section } from "../decorations/Section";
import MdEditor from 'react-markdown-editor-lite';
import { useBoolean } from "@uifabric/react-hooks";

type TProps = {
    getHelp: () => void
    setHelp: () => void
    value?: string
    setValue: (text?: string) => void
    headerText: string
    disabled?: boolean
    resetTitle?: string
    resetInfo?: string
    isFetching: boolean
    noChanges?: boolean
    wasSaved?: boolean
    resetWasSaved: () => void
}

export const MdHelp: React.FC<TProps> = (props) => {

    const [confirmation, { setTrue: showConfirmation, setFalse: hideConfirmation }] = useBoolean(false)

    React.useEffect(() => {
        if (!props.noChanges) {
            props.resetWasSaved()
        }
    }, [props.noChanges])

    const mdParser = new MarkdownIt(/* Markdown-it options */);

    const onRenderSectionHeader = () => {
        return (
            <Stack horizontal horizontalAlign='space-between' verticalAlign='center' tokens={{ padding: "16px 20px 0px 20px" }}>
                <Stack.Item>
                    <Text style={{ fontSize: "1.0625rem", fontWeight: 500, color: theme.palette.black }}>{props.headerText}</Text>
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    {props.isFetching && (
                        <Stack verticalAlign="center" horizontalAlign="center" grow={1}>
                            <Spinner size={SpinnerSize.small} />
                        </Stack>
                    )}
                    {props.noChanges && props.wasSaved && (
                        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center" horizontalAlign="center" grow={1}>
                            <FontIcon style={{ fontSize: 14, color: SharedColors.greenCyan10 }} iconName={"CompletedSolid"} />
                            <Text variant='small' style={{ color: SharedColors.gray30 }}>{strings.COMMON.SAVED}</Text>
                        </Stack>
                    )}
                    <PrimaryButton disabled={props.disabled || props.isFetching || props.noChanges} text={strings.BUTTONS.TEXT.SAVE} onClick={props.setHelp} />
                    <SecondaryButton disabled={props.disabled || props.isFetching || props.noChanges} text={strings.BUTTONS.TEXT.CANCEL} onClick={showConfirmation} />
                </Stack>
            </Stack>
        )
    }

    React.useEffect(() => {
        props.getHelp()
    }, [])

    const resetHandler = () => {
        props.getHelp()
        hideConfirmation()
    }

    return (
        <Stack verticalFill>
            <Section flat verticalFill onRenderHeader={onRenderSectionHeader}>
                <Stack verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <MdEditor style={{ position: 'relative', height: '100%' }} value={props.value} renderHTML={text => mdParser.render(text)} onChange={(data: { text: string; html: string; }) => !props.disabled && props.setValue(data.text ?? '')} />
                    </ScrollablePane>
                </Stack>
            </Section>
            {confirmation && (
                <Modal isOpen={confirmation} onDismiss={hideConfirmation} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{props.resetTitle ?? strings.HELP.RESET_HELP_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={resetHandler}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <Stack tokens={{ childrenGap: 16 }} >
                            <Stack.Item>
                                <Text>{props.resetInfo ?? strings.HELP.RESET_HELP_INFORMATION}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <PrimaryButton onClick={resetHandler}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                                        <DefaultButton style={cancelButtonStyle} onClick={resetHandler}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>
                                    </Stack>
                                </Stack.Item>
                            </Stack.Item>
                        </Stack>
                    </div>
                </Modal>)}
        </Stack>
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const cancelButtonStyle: React.CSSProperties = {
    border: 'none',
    background: theme.palette.neutralLight
}