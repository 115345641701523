import { Stack, ScrollablePane, Link, Text } from "@fluentui/react"
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../core/store";
import { IIdentificationModuleConfig } from "@piceasoft/core";
import { IIdentificationModuleConfigUI } from "@piceasoft/core";
import { strings } from "../../../../../../localization/strings";
import { Section } from "../../../../decorations/Section";
import { ParameterItem } from "../../../components/ParameterItem";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const IdentificationModuleUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationModuleConfig<IIdentificationModuleConfigUI>)

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.HEADER} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.TITLE_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.ui?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    title: config.ui?.title !== undefined ? undefined : ""
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.title !== undefined ? true : false}
                        textFieldValue={config.ui?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                title: val
                            }
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.ICON_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.ui?.icon !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    icon: config.ui?.icon !== undefined ? undefined : ""
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.ICON_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.icon !== undefined ? true : false}
                        textFieldValue={config.ui?.icon}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                icon: val
                            }
                        }))}
                    >
                        <Stack grow>
                            <Text variant="small" >{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION_LINK_TEXT1}
                                <Link target="_blank" href={'https://developer.microsoft.com/en-us/fluentui#/styles/web/icons#available-icons'} style={{ fontSize: 12, marginLeft: 6, marginRight: 4, }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION_LINK}</Link>
                                <Text variant="small" style={{ flexWrap: 'wrap' }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION_LINK_TEXT2}</Text>
                            </Text>
                            <Text variant="small">{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION_LINK_TEXT3}</Text>
                        </Stack>
                    </ParameterItem>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.INFORMATION_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.ui?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    information: config.ui?.information !== undefined ? undefined : ""
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldMultiLimitSymbolsCount={40}
                        textFieldEnabled={config.ui?.information !== undefined ? true : false}
                        textFieldValue={config.ui?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                information: val
                            }
                        }))}
                    />
                    {props.children}
                </Section>
            </ScrollablePane>
        </Stack.Item>
    )
}