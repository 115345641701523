import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { IStageConfig, ProcessStages, IStageConfigUI } from "@piceasoft/core";

type TProps = {
    config: IStageConfig<IStageConfigUI>
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    channel: number
}

export const StageConfirmationSettingsPivot: React.FC<TProps> = ({ config, onChangeDispatch, stage, children, channel }) => {
    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CONFIRMATION.CONFIRMATION_TITLE}
                noSeparator
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Confirmation', 'Use step confirmation')}
                toggleChecked={config.requireConfirmation ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        requireConfirmation: config.requireConfirmation ? undefined : true
                    } as IStageConfig<IStageConfigUI>
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CONFIRMATION.CONFIRMATION_DESCRIPTION}
            />
            {config.requireConfirmation && (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.CONFIRMATION_HEADER_TITLE}
                        useToggle
                        toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Confirmation', 'Use custom confirmation header')}
                        toggleChecked={config.ui?.confirmation?.header !== undefined ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.editStage(stage, {
                                ...config,
                                ui: {
                                    ...config.ui,
                                    confirmation: {
                                        ...config.ui?.confirmation,
                                        header: config.ui?.confirmation?.header !== undefined ? undefined : ""
                                    }
                                }
                            } as IStageConfig<IStageConfigUI>
                            ))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.CONFIRMATION_HEADER_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.confirmation?.header !== undefined ? true : false}
                        textFieldValue={config.ui?.confirmation?.header}
                        textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                            ...config,
                            ui: {
                                ...config.ui,
                                confirmation: {
                                    ...config.ui?.confirmation,
                                    header: val
                                }
                            }
                        } as IStageConfig<IStageConfigUI>
                        ))
                        }
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.CONFIRMATION_INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Confirmation', 'Use custom confirmation information')}
                        toggleChecked={config.ui?.confirmation?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.editStage(stage, {
                                ...config,
                                ui: {
                                    ...config.ui,
                                    confirmation: {
                                        ...config.ui?.confirmation,
                                        information: config.ui?.confirmation?.information !== undefined ? undefined : ""
                                    }
                                }
                            } as IStageConfig<IStageConfigUI>
                            ))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.CONFIRMATION_INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.confirmation?.information !== undefined ? true : false}
                        textFieldValue={config.ui?.confirmation?.information}
                        textFieldMultiline
                        textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                            ...config,
                            ui: {
                                ...config.ui,
                                confirmation: {
                                    ...config.ui?.confirmation,
                                    information: val
                                }
                            }
                        } as IStageConfig<IStageConfigUI>
                        ))
                        }
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.CONFIRM.TEXT_TITLE}
                        useToggle
                        toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Confirmation', 'Custom Confirm button caption')}
                        toggleChecked={config.ui?.confirmation?.buttons?.confirm?.text !== undefined ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.editStage(stage, {
                                ...config,
                                ui: {
                                    ...config.ui,
                                    confirmation: {
                                        ...config.ui?.confirmation,
                                        buttons: {
                                            ...config.ui?.confirmation?.buttons,
                                            confirm: {
                                                ...config.ui?.confirmation?.buttons?.confirm,
                                                text: config.ui?.confirmation?.buttons?.confirm?.text !== undefined ? undefined : ""
                                            }
                                        }
                                    }
                                }
                            } as IStageConfig<IStageConfigUI>
                            ))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.CONFIRM.TEXT_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.confirmation?.buttons?.confirm?.text !== undefined ? true : false}
                        textFieldValue={config.ui?.confirmation?.buttons?.confirm?.text}
                        textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                            ...config,
                            ui: {
                                ...config.ui,
                                confirmation: {
                                    ...config.ui?.confirmation,
                                    buttons: {
                                        ...config.ui?.confirmation?.buttons,
                                        confirm: {
                                            ...config.ui?.confirmation?.buttons?.confirm,
                                            text: val
                                        }
                                    }
                                }
                            }
                        } as IStageConfig<IStageConfigUI>
                        ))
                        }
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.CANCEL.TEXT_TITLE}
                        useToggle
                        toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Confirmation', 'Custom Cancel button caption')}
                        toggleChecked={config.ui?.confirmation?.buttons?.cancel?.text !== undefined ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.editStage(stage, {
                                ...config,
                                ui: {
                                    ...config.ui,
                                    confirmation: {
                                        ...config.ui?.confirmation,
                                        buttons: {
                                            ...config.ui?.confirmation?.buttons,
                                            cancel: {
                                                ...config.ui?.confirmation?.buttons?.cancel,
                                                text: config.ui?.confirmation?.buttons?.cancel?.text !== undefined ? undefined : ""
                                            }
                                        }
                                    }
                                }
                            } as IStageConfig<IStageConfigUI>
                            ))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.CANCEL.TEXT_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.confirmation?.buttons?.cancel?.text !== undefined ? true : false}
                        textFieldValue={config.ui?.confirmation?.buttons?.cancel?.text}
                        textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                            ...config,
                            ui: {
                                ...config.ui,
                                confirmation: {
                                    ...config.ui?.confirmation,
                                    buttons: {
                                        ...config.ui?.confirmation?.buttons,
                                        cancel: {
                                            ...config.ui?.confirmation?.buttons?.cancel,
                                            text: val
                                        }
                                    }
                                }
                            }
                        } as IStageConfig<IStageConfigUI>
                        ))
                        }
                    />
                    {children}
                </>
            )}
        </Stack>
    )
}