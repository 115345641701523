import { BusinessErrors } from "../../core/store/typings/BusinessErrors"
import { strings } from "../strings"

export const  getLozalizedBusinessError = (code?: BusinessErrors) => {
    let result = ""
    switch (code) {
        case BusinessErrors.Configurator_Organization_DuplicateDomain: result = strings.ERRORS.ORGANIZATION.DUPLICATE_DOMAIN
            break;
        case BusinessErrors.Configurator_Organization_CantAddSupervisorRoleToAccount: result = strings.ERRORS.ORGANIZATION.CANT_ADD_SUPERVISOR_TO_ACCOUNT
            break;
        case BusinessErrors.Configurator_Organization_CantCreate: result = strings.ERRORS.ORGANIZATION.CANT_CREATE
            break;
        case BusinessErrors.Configurator_Organization_CantCreateOrganizationAccount: result = strings.ERRORS.ORGANIZATION.CANT_CREATE_ORGANIZATION_ACCOUNT
            break;
        case BusinessErrors.Configurator_License_CantAddCustomer: result = strings.ERRORS.LICENSE.CANT_ADD_CUSTOMER
            break;
        case BusinessErrors.Configurator_License_CantSetParentCustomer: result = strings.ERRORS.LICENSE.CANT_SET_PARENT_CUSTOMER
            break;
        case BusinessErrors.Configurator_License_CantCloneProduct: result = strings.ERRORS.LICENSE.CANT_CLONE_PRODUCT
            break;
        case BusinessErrors.Configurator_License_CustomerAlreadyExists: result = strings.ERRORS.LICENSE.CUSTOMER_ALREADY_EXISTS
            break;
        case BusinessErrors.Configurator_Identity_DefaultError: result = strings.ERRORS.IDENTITY.DEFAULT_ERROR
            break;
        case BusinessErrors.Configurator_Identity_ConcurrencyFailure: result = strings.ERRORS.IDENTITY.CONCURRENCY_FAILURE
            break;
        case BusinessErrors.Configurator_Identity_PasswordMismatch: result = strings.ERRORS.IDENTITY.PASSWORD_MISMATCH
            break;
        case BusinessErrors.Configurator_Identity_InvalidToken: result = strings.ERRORS.IDENTITY.INVALID_TOKEN
            break;
        case BusinessErrors.Configurator_Identity_LoginAlreadyAssociated: result = strings.ERRORS.IDENTITY.LOGIN_ALREADY_ASSOCIATED
            break;
        case BusinessErrors.Configurator_Identity_InvalidUserName: result = strings.ERRORS.IDENTITY.INVALID_USER_NAME
            break;
        case BusinessErrors.Configurator_Identity_InvalidEmail: result = strings.ERRORS.IDENTITY.INVALID_EMAIL
            break;
        case BusinessErrors.Configurator_Identity_DuplicateUserName: result = strings.ERRORS.IDENTITY.DUPLICATE_USERNAME
            break;
        case BusinessErrors.Configurator_Identity_DuplicateEmail: result = strings.ERRORS.IDENTITY.DUPLICATE_EMAIL
            break;
        case BusinessErrors.Configurator_Identity_InvalidRoleName: result = strings.ERRORS.IDENTITY.INVALID_ROLE_NAME
            break;
        case BusinessErrors.Configurator_Identity_DuplicateRoleName: result = strings.ERRORS.IDENTITY.DUPLICATE_ROLE_NAME
            break;
        case BusinessErrors.Configurator_Identity_UserAlreadyHasPassword: result = strings.ERRORS.IDENTITY.USER_ALREADY_HAS_PASSWORD
            break;
        case BusinessErrors.Configurator_Identity_UserLockoutNotEnabled: result = strings.ERRORS.IDENTITY.USER_LOCKOUT_NOT_ENABLED
            break;
        case BusinessErrors.Configurator_Identity_UserAlreadyInRole: result = strings.ERRORS.IDENTITY.USER_ALREADY_IN_ROLE
            break;
        case BusinessErrors.Configurator_Identity_PasswordTooShort: result = strings.ERRORS.IDENTITY.PASSWORD_TOO_SHORT
            break;
        case BusinessErrors.Configurator_Identity_PasswordRequiresNonAlphanumeric: result = strings.ERRORS.IDENTITY.PASSWORD_REQUIRES_NON_ALPHANUMERIC
            break;
        case BusinessErrors.Configurator_Identity_PasswordRequiresDigit: result = strings.ERRORS.IDENTITY.PASSWORD_REQUIRES_DIGIT
            break;
        case BusinessErrors.Configurator_Identity_PasswordRequiresLower: result = strings.ERRORS.IDENTITY.PASSWORD_REQUIRES_LOWER
            break;
        case BusinessErrors.Configurator_Identity_PasswordRequiresUpper: result = strings.ERRORS.IDENTITY.PASSWORD_REQUIRES_UPPER
            break;
        case BusinessErrors.Configurator_Identity_UserNotInRole: result = strings.ERRORS.IDENTITY.USER_NOT_IN_ROLE
            break;
        case BusinessErrors.Configurator_Identity_RecoveryCodeRedemptionFailed: result = strings.ERRORS.IDENTITY.RECOVERY_CODE_REDEMPTION_FAILED
            break;
        case BusinessErrors.Configurator_Identity_PasswordRequiresUniqueChars: result = strings.ERRORS.IDENTITY.PASSWORD_REQUIRES_UNIQUE_CHARS
            break;
        case BusinessErrors.Configurator_Identity_PasswordIncludesNotMoreThenThreeConsecutiveCharacters: result = strings.ERRORS.IDENTITY.PASSWORD_MAX_THREE_CONSECUTIVE_CHARS
            break;
        case BusinessErrors.Configurator_Identity_PasswordLengthAtLeastFifteenCharacters: result = strings.ERRORS.IDENTITY.PASSWORD_MIN_LENGHT_FIFTEEN_CHARS
            break;
    }

    return result
}