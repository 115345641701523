import { getTheme, Icon, MessageBar, MessageBarType, Stack, Text} from "@fluentui/react"
import React, { Dispatch } from "react";
import { getEnumName } from "../../../../core/actions/teaching-actions";
import { IIdentificationConfig, IdentificationMethods } from '@piceasoft/core';
import { IIdentificationModuleConfig } from "@piceasoft/core";
import { IIdentificationModuleConfigUI } from "@piceasoft/core";
import { getLocalizedIdentificationMethod, getLocalizedIdentificationSubtitle } from "../../../../localization/helpers/transaction";
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";


type TProps = {
    config: IIdentificationConfig,
    setModuleParams: (index: number, pivot: string) => void
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
}

export const IdentificationContentTransfer: React.FC<TProps> = (props) => {

    return (
        <StageBox title={strings.CONSTRUCTOR.IDENTIFICATION.TITLE} settingsButtonProps={{ onClick: props.showSettings }}>
            <Stack tokens={{ childrenGap: 16 }}>
                {props.config.items?.sort((a, b) => a.index - b.index).map((m, order) => {
                    if (m) {
                        return (
                            <IdentificationModuleItem key={`${order}-${m.index}-${m.method}`} onChangeDispatch={props.onChangeDispatch}
                                module={m} icon={m.ui?.icon ?? "Plug"} setModuleParams={props.setModuleParams}
                            />
                        )
                    }
                })}
            </Stack>
            {props.config.items && props.config.items.length === 0 && (
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <MessageBar isMultiline messageBarType={MessageBarType.severeWarning}>
                        <b>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.WARNING_EMPTY_ASSESSMENT}</b>
                    </MessageBar>
                </Stack>
            )}
        </StageBox>
    )
}

type TItemProps = {
    module: IIdentificationModuleConfig<IIdentificationModuleConfigUI>
    setModuleParams: (index: number, pivot: string) => void
    icon?: string
    onChangeDispatch: Dispatch<any>
}

export const IdentificationModuleItem: React.FC<TItemProps> = (iProps) => {
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item grow styles={{ root: { paddingRight: 18 } }}>
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                        borderRadius: 10
                    }
                }}>
                    <Stack onClick={() => iProps.setModuleParams(iProps.module.index, "ui")} styles={{
                        root: {
                            padding: 16,
                            color: theme.palette.black,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: theme.palette.neutralQuaternaryAlt,
                            backgroundColor: theme.palette.white,
                            position: "relative",
                            borderRadius: 10,
                            ":hover": {
                                cursor: "pointer",
                                borderColor: theme.palette.themePrimary,
                                backgroundColor: theme.palette.themeLighterAlt
                            }
                        }
                    }}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Icon iconName={iProps.icon ?? "InternetSharing"} styles={{ root: { color: theme.palette.black } }} />
                                <Text styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{iProps.module.ui?.title ?? getLocalizedIdentificationMethod(iProps.module.method)}</Text>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>{getLocalizedIdentificationSubtitle(iProps.module.method)}</Text>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();