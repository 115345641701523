import { Dispatch } from "react"
import { useSelector } from "react-redux"
import { actionCreators, getStageInspectionModules } from "../../../../../core/actions/configurator-actions"
import { IStore } from "../../../../../core/store"
import { IControlInspectionConfig } from "@piceasoft/core"
import { strings } from "../../../../../localization/strings"
import { ParameterItem } from "../../components/ParameterItem"
import { ProcessStages } from "@piceasoft/core"

type TProps = {
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
}

export const StageInspectionItemCommonSettingsChildren: React.FC<TProps> = ({stage, index, onChangeDispatch}) => {

    const module = useSelector((s: IStore) => getStageInspectionModules(stage, s)?.find(i => i.index === index))
    
    if (!module) return null
    
    switch (stage) {
        case ProcessStages.Control:
            const config = module.config as IControlInspectionConfig
            
            return (
                <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.REQUIRED_TITLE}
        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.REQUIRED_DESCRIPTION}
        useToggle
        toggleEnabled
        toggleChecked={config.required}
        toggleOnChange={() => {
            onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                ...config,
                required: config.required ? undefined : true
            } as IControlInspectionConfig))
        }}
    />
        )
        default: return <></>
    }

}