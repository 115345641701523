import { Stack, AnimationStyles, SharedColors, ImageFit, Text, Image } from "@fluentui/react"
import { DeviceAttributes, IDevice, ICommonOfferConfig } from "@piceasoft/core"
import { strings } from "../../../../../../localization/strings"
import unknown from '../../../../../../assets/unknown.png';
import { OfferPromotions } from "./OfferPromotions"

type TProps = {
    config: ICommonOfferConfig
    device: IDevice
}

export const CommonOfferPreview: React.FC<TProps> = ({ config, device }) => {

    return (
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 16 }} styles={{ root: { ...AnimationStyles.slideUpIn10, paddingTop: 24, paddingBottom: 24 } }}>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 16 }} style={{ maxWidth: 320, minWidth: 320, width: 320 }}>
                    <Stack.Item>
                        <Stack horizontalAlign="center">
                            <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{config?.ui?.tradeInDevice ?? strings.CONSTRUCTOR.STAGES.PRE_OFFER.TRADEIN_DEVICE}</Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontalAlign="center">
                            <Image width={96} height={96} imageFit={ImageFit.contain} maximizeFrame={true}
                                src={device.attributes[DeviceAttributes.Image] ?? unknown}
                            />
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontalAlign="center">
                            <Text variant="mediumPlus" style={{ color: SharedColors.cyanBlue10 }}>{device.manufacturer}</Text>
                            <Text variant="large" style={{ color: SharedColors.cyanBlue10, fontWeight: "bold", textAlign: "center" }}>{device.name} {device.attributes["color"]}</Text>
                            {device.configuration && device.type === "0" && (
                                <Text variant="medium" style={{ fontWeight: "bold", color: SharedColors.gray30 }}>{device.configuration} <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>GB</Text></Text>
                            ) || (
                                    <Text variant="medium" style={{ fontWeight: "bold", color: SharedColors.gray30 }}>{device.configuration}</Text>
                                )}
                            {device.group && (device.attributes.group !== device.name) && (
                                <Text variant="medium" style={{ fontWeight: "bold", color: SharedColors.gray30 }}>{device.attributes.group}</Text>
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            {config?.allowDiscount && (
                <OfferPromotions device={device} config={config.discount} tradeUpDeviceTitle={config.discount?.ui?.tradeUpDevice} />
            )}
        </Stack>
    )
}