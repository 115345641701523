import {
    Stack, getTheme, Text, PivotItem, Pivot, IconButton, IIconProps,
    DirectionalHint, Icon, IPivotItemProps, IRenderFunction, TooltipHost,
    SharedColors, IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject,
    FontWeights, mergeStyleSets, DefaultButton, MessageBar, Separator, ScrollablePane, AnimationStyles
} from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import * as React from 'react';
import { Dispatch } from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { actionCreators, getStageInspectionModules } from "../../../../../../../core/actions/configurator-actions";
import { noop } from "../../../../../../../core/helpers/common";
import { IStore } from "../../../../../../../core/store";
import { IPhotographicConfig, IPhotographicModeConfig, PhotographicModes, Inspections, IPhotographicModeConfigUI,
    IPhotographicCollectionConfig, IPhotographicCollectionItem, IPhotographicFreeConfig, IPhotographicRequestConfig
 } from '@piceasoft/core';
//import { IPhotographicCollectionConfig, IPhotographicCollectionItem, IPhotographicFreeConfig, IPhotographicRequestConfig } from "../../../../../../../core/store/typings/IPhotographicConfig";
import { getLocalizedInspectionName, getLocalizedInspectionModeName } from "../../../../../../../localization/helpers/transaction";
import { strings } from "../../../../../../../localization/strings";
import { ItemsNotFound } from "../../../../../notFound/ItemsNotFound";
import { CustomPanel } from "../../../../../panel/CustomPanel";
import { StagePreviewWrapper } from "../../../preview/StagePreviewWrapper";
import { ModulePreviewWrapper } from "../../preview/ModulePreviewWrapper";
import { CollectionPreview } from "../preview/CollectionPreview";
import { FreePreview } from "../preview/FreePreview";
import { RequestPreview } from "../preview/RequestPreview";
import { CollectionForm } from "./CollectionForm";
import { CollectionModeConfiguratorModePivot } from "./pivots/CollectionModeConfiguratorModePivot";
import { FreeModeConfiguratorModePivot } from "./pivots/FreeModeConfiguratorModePivot";
import { ModeConfiguratorCommonPivot } from "./pivots/ModeConfiguratorCommonPivot";
import { RequestConfiguratorPivot } from "./pivots/RequestConfiguratorPivot";
import { ProcessStages } from "@piceasoft/core";
import { CSHHelpLink } from "../../../../../help/CSHHelp";

type TProps = {
    index: number
    stage: ProcessStages
    onDissmiss: () => void
    onChangeDispatch: Dispatch<any>
}

type TPhotographicModeConfiguratorPivotState = "mode" | "common" | "request"

export const PhotographicModeConfigurator: React.FC<TProps> = ({ stage, index, onDissmiss, onChangeDispatch }) => {

    const photographic = useSelector((s: IStore) => getStageInspectionModules(stage, s)?.find(i => i.index === index)?.config as IPhotographicConfig)
    const [pivotState, setPivotState] = React.useState<TPhotographicModeConfiguratorPivotState>("mode")
    const [selectedPreviewPivotKey, setSelectedPreviewPivotKey] = React.useState<TPreviewPivotState>(photographic.defaultMethod ?? (photographic.useMethods && photographic.useMethods[0]) ?? "remote");
    const [previewState, { toggle: togglePreviewState }] = useBoolean(false)

    const methods: Array<"local" | "remote"> = photographic.useMethods ?? ["remote", "local"]

    const [collectionPanelState, setCollectionPanelState] = React.useState<{ index: number, item: IPhotographicCollectionItem }>()
    const [isCollectionPanelOpen, { setTrue: showCollectionPanel, setFalse: hideCollectionPanel }] = useBoolean(false)
    const collectioError = photographic.errors?.find((error) => error.code === 12)
    React.useEffect(() => {
        if (collectionPanelState && !isCollectionPanelOpen) {
            showCollectionPanel()
        }
    }, [collectionPanelState])

    React.useEffect(() => {
        setSelectedPreviewPivotKey(photographic.defaultMethod ?? (photographic.useMethods && photographic.useMethods[0]) ?? "remote")
    }, [photographic.defaultMethod, photographic.useMethods])

    const onCollectionCancel = () => {
        hideCollectionPanel();
        setTimeout(() => setCollectionPanelState(undefined), 1000);
    }

    const onMoveUpCollectionHandler = (lIndex: number) => {
        const collection = (photographic.config as IPhotographicCollectionConfig).collection
        onConfigConfirmHandler({
            ...photographic.config,
            collection: collection.map((c, cIndex) => {
                if (cIndex === lIndex) {
                    return collection[cIndex - 1];
                }
                if (cIndex === lIndex - 1) {
                    return collection[cIndex + 1];
                }
                return c;
            })
        });
    }

    const onDeleteCollectionHandler = (lIndex: number) => {
        const collection = (photographic.config as IPhotographicCollectionConfig).collection
        onConfigConfirmHandler({
            ...photographic.config,
            collection: collection.filter((c, cIndex) => cIndex !== lIndex)
        });
    }

    const onCollectionEdit = (item: IPhotographicCollectionItem, index: number) => {
        setCollectionPanelState({ index, item });
    }

    const onCollectionCommit = (item: IPhotographicCollectionItem) => {
        const collection = (photographic.config as IPhotographicCollectionConfig).collection
        if (collectionPanelState?.index !== undefined) {
            onConfigConfirmHandler({
                ...photographic.config,
                collection: collection.map((c, cIndex) => {
                    if (collectionPanelState.index === cIndex) {
                        return item;
                    }
                    return c;
                })
            });
        } else {
            onConfigConfirmHandler({
                ...photographic.config,
                collection: collection ? [...collection, item] : [item]
            });
        }
        onCollectionCancel();
    };

    const handlePreviewPivotLinkClick = (item?: PivotItem) => {
        setSelectedPreviewPivotKey(item?.props.itemKey! as TPreviewPivotState);
    };

    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(item?.props.itemKey! as TPhotographicModeConfiguratorPivotState);
    };

    const getTabId = (itemKey: string) => {
        return `PhotographicModeConfiguratorPivot_${itemKey}`;
    };

    const getPivotHeaderText = (m: string): string => {
        const commonText = photographic.errors?.find((error) => error.code === 9) ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_WAS_RESET : strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.MODE_CONFIGURATOR.PIVOTS.COMMON
        switch (m) {
            case "mode": return strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.MODE_CONFIGURATOR.PIVOTS.MODE
            case "common": return commonText
            case "request": return strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.MODE_CONFIGURATOR.PIVOTS.REQUEST
            default: return m;
        }
    }

    const getPreviewPivotHeaderText = (m: string): string => {
        if (m === "local") {
            return photographic.ui?.localMethodTitle ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.LOCAL
        }
        return photographic.ui?.remoteMethodTitle ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE
    }

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
                </TooltipHost>
            </span>
        )

    }
    const onRenderItemLinkWarning: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText} tooltipProps={{ calloutProps: { styles: { calloutMain: { "p": {} } } } }}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px', color: SharedColors.red10 }} />
                </TooltipHost>
            </span>
        )

    }

    const onConfigConfirmHandler = (config: IPhotographicCollectionConfig | IPhotographicFreeConfig | IPhotographicModeConfig<IPhotographicModeConfigUI>) => {
        onChangeDispatch(actionCreators.inspections.photographic.editModeConfig(stage, index, config))
    }
    const onRequestConfirmHandler = (config?: IPhotographicRequestConfig) => onChangeDispatch(actionCreators.inspections.photographic.editPhotographicRequest(stage, index, config))

    return (
        <Stack grow verticalFill horizontal>
            <ModulePreviewWrapper color={"rgba(0, 0, 0, 0.4)"}>
                <Stack verticalFill>
                    {/* <Stack.Item style={{ position: "relative", padding: 16 }} >
                        <Text variant="xLarge" nowrap style={{transition:'0s'}} >{strings.CONSTRUCTOR.COMMON.PREVIEW}</Text>
                    </Stack.Item> */}
                    <Stack verticalFill verticalAlign="center">
                        <Stack.Item tokens={{ padding: "64px 12px 32px 48px" }} style={{ width: pivotState !== "request" ? 640 : undefined }}>
                            {pivotState !== "request" && (
                                <Stack styles={contentStyles2}>
                                    <div className={contentStyles.header}>
                                        <span>{photographic?.ui?.title ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.TITLE}</span>
                                        <IconButton disabled={true} styles={iconButtonStyles} iconProps={cancelIcon} />
                                    </div>
                                    {methods.length > 1 && (
                                        <>
                                            <Stack grow horizontalAlign="center" >
                                                <Pivot aria-label="Photographic" selectedKey={selectedPreviewPivotKey} onLinkClick={handlePreviewPivotLinkClick} headersOnly={true} getTabId={getTabId}>
                                                    {methods?.map(m => <PivotItem key={m} itemKey={m} headerText={getPreviewPivotHeaderText(m)} />)}
                                                </Pivot>
                                            </Stack>
                                            <Separator />
                                        </>
                                    )}
                                    <Stack verticalFill grow tokens={{ childrenGap: 16 }} style={{ position: 'relative', height: '100%' }}>
                                        <PreviewPivotBasedState
                                            state={selectedPreviewPivotKey}
                                            config={photographic}
                                            mode={photographic.mode}
                                            onAddCollection={showCollectionPanel}
                                        />
                                    </Stack>
                                </Stack>
                            )}
                            {pivotState === "request" && (
                                <Stack.Item>
                                    <Stack styles={{ root: { width: 640 } }}>
                                        <RequestPreview config={photographic} onPending={noop} onClose={noop} onResult={noop} />
                                    </Stack>
                                </Stack.Item>
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack>
            </ModulePreviewWrapper>
            <Stack verticalFill grow>
                <Stack horizontal grow style={{ padding: 8 }}>
                    <Stack style={{ padding: 8 }} grow tokens={{ childrenGap: 4 }}>
                        <Text variant="xLarge">{photographic?.ui?.title ?? getLocalizedInspectionName(Inspections.Photographic)}</Text>
                        <Text variant="small">{getLocalizedInspectionModeName(Inspections.Photographic, photographic as IPhotographicConfig)}</Text>
                    </Stack>
                    <Stack horizontal style={{ alignItems: "center" }}>
                        <CSHHelpLink articleid="1139"/>
                        <IconButton onClick={onDissmiss} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                    </Stack>
                </Stack>
                <Stack.Item tokens={{ padding: "0px 16px 0px 16px" }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                    <Pivot styles={pivotStyles} selectedKey={pivotState} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                        <PivotItem itemIcon={'List'} headerText={getPivotHeaderText("mode")} onRenderItemLink={collectioError ? onRenderItemLinkWarning : onRenderItemLink} itemKey="mode" />
                        <PivotItem itemIcon={'Settings'}
                            headerText={getPivotHeaderText("common")}
                            onRenderItemLink={photographic.errors?.find((error) => error.code === 9) ? onRenderItemLinkWarning : onRenderItemLink}
                            itemKey="common" />
                        <PivotItem itemIcon={'Up'} headerText={getPivotHeaderText("request")} onRenderItemLink={onRenderItemLink} itemKey="request" />
                    </Pivot>
                </Stack.Item>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <PivotBasedState
                            state={pivotState}
                            photographic={photographic}
                            onConfigConfirm={onConfigConfirmHandler}
                            onRequestConfirm={onRequestConfirmHandler}
                            onAdd={showCollectionPanel}
                            onEditCollection={onCollectionEdit}
                            onDelete={onDeleteCollectionHandler}
                            onMoveUp={onMoveUpCollectionHandler}
                            onDissmiss={onDissmiss}
                            onConfirm={(newConfig) => onChangeDispatch(actionCreators.inspections.photographic.editPhotographic(stage, index, newConfig))}
                        />
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <CustomPanel
                        isOpen={isCollectionPanelOpen}
                        onCancel={onCollectionCancel}
                        noCancelOnDissmiss={true}
                        title={collectionPanelState ?
                            strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.EDIT :
                            strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.NEW
                        }
                    >
                        <CollectionForm
                            onConfirm={onCollectionCommit}
                            // onCancel={onCollectionCancel}
                            collection={collectionPanelState?.item}
                        />
                    </CustomPanel>
                </Stack.Item>
            </Stack>
        </Stack >
    )
}

type TPreviewPivotState = "local" | "remote"

type TPreviewPivotBasedStateProps = {
    state: TPreviewPivotState
    mode: PhotographicModes
    config: IPhotographicConfig
    onAddCollection: () => void
}

const PreviewPivotBasedState: React.FC<TPreviewPivotBasedStateProps> = (props) => {
    switch (props.state) {
        case "local": return (
            <div className={contentStyles.body}>
                {/* {props.data.moderationStatus === ModerationStatuses.request && <Request moderatorComment={props.data.warning?.message ?? ""} onClose={props.onClose} onPending={props.onPending} onResult={props.onResult} qrCode={props.qrCode} config={props.config} />} */}
                {props.config?.mode === PhotographicModes.Collection && ((props.config.config as IPhotographicCollectionConfig).collection && (props.config.config as IPhotographicCollectionConfig).collection.length > 0 && (
                    <CollectionPreview config={props.config.config as IPhotographicCollectionConfig} onClose={noop} onPending={noop} onResult={noop} />
                ) || (
                        <ItemsNotFound
                            onClick={() => props.onAddCollection()}
                            info={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.NOT_FOUND_CASE.INFO}
                            suggestion={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.NOT_FOUND_CASE.SUGGESTION}
                            buttonText={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.NOT_FOUND_CASE.ADD_BUTTON}
                            imgSrc={"images/navigation/images/not_found.png"} />
                    ))}
                {props.config?.mode === PhotographicModes.Free && <FreePreview config={props.config.config as IPhotographicFreeConfig}
                    onClose={noop} onPending={noop} onResult={noop} />}
            </div>
        )
        case "remote": return <RemoteUploadPivot description={props.config?.config?.ui?.remoteText} information={props.config?.config?.ui?.remoteMessageBarText} qrCode={"configurator"} getModeration={noop} onClose={noop} />
    }
}

type TRemoteUploadPivotProps = {
    qrCode: string
    description?: string
    information?: string
    getModeration: () => void
    onClose: () => void
}

const RemoteUploadPivot: React.FC<TRemoteUploadPivotProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 24 }} styles={{ root: { paddingBottom: 24, paddingTop: 16 } }} >
            <Stack horizontalAlign="center" style={{ color: SharedColors.gray40 }} tokens={{padding: '0 24px 0 24px'}}>
                <Text variant="medium">
                    <div dangerouslySetInnerHTML={{ __html: props.description ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE_DESCRIPTION }}></div>
                </Text>
            </Stack>
            <Stack verticalAlign="center" horizontalAlign="center">
                <div style={{ boxShadow: theme.effects.elevation8, padding: 12, overflow: "hidden" }}>
                    <a href={props.qrCode} target="_blank" onClick={props.getModeration}>
                        <QRCode value={props.qrCode} size={112} fgColor={SharedColors.gray20} />
                    </a>
                </div>
            </Stack>
            <Stack horizontalAlign="center">
                <Stack.Item tokens={{padding: '0 24px 0 24px'}}>
                    <MessageBar>
                        <div dangerouslySetInnerHTML={{ __html: props.information ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE_INFORMATION }}></div>
                    </MessageBar>
                </Stack.Item>
            </Stack>
            <Stack grow horizontalAlign="end" tokens={{ childrenGap: 8 }} style={{ paddingRight: 24 }}>
                {props.onClose !== undefined && <DefaultButton disabled onClick={props.onClose}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>}
            </Stack>
        </Stack>
    )
}

type TStateProps = {
    state: TPhotographicModeConfiguratorPivotState
    photographic: IPhotographicConfig
    onConfigConfirm: (config: IPhotographicCollectionConfig | IPhotographicFreeConfig | IPhotographicModeConfig<IPhotographicModeConfigUI>) => void
    onConfirm: (config: IPhotographicConfig) => void
    onRequestConfirm: (config?: IPhotographicRequestConfig) => void
    onAdd: () => void
    onDelete: (lIndex: number) => void
    onMoveUp: (lIndex: number) => void
    onEditCollection: (collection: IPhotographicCollectionItem, index: number) => void
    onDissmiss: () => void
}

const PivotBasedState: React.FC<TStateProps> = (props) => {
    switch (props.state) {
        case "common": return <ModeConfiguratorCommonPivot onConfigConfirm={props.onConfirm} onModeConfirm={props.onConfigConfirm} photographic={props.photographic} />
        case "mode":
            switch (props.photographic.mode) {
                case PhotographicModes.Collection:
                    return <CollectionModeConfiguratorModePivot
                        config={props.photographic.config as IPhotographicCollectionConfig}
                        onModeConfirm={props.onConfigConfirm}
                        onDeleteCollection={props.onDelete}
                        onEditCollection={props.onEditCollection}
                        onUpCollection={props.onMoveUp}
                        onAddCollection={props.onAdd}
                    />
                case PhotographicModes.Free:
                    return <FreeModeConfiguratorModePivot config={props.photographic.config as IPhotographicFreeConfig} onModeConfirm={props.onConfigConfirm} />
            }
        case "request": return <RequestConfiguratorPivot onConfirm={props.onRequestConfirm} config={props.photographic.request} />
    }
}

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}

const contentStyles = mergeStyleSets({
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        heigth: '100%',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const contentStyles2 = mergeStyleSets({
    root: {
        overflow: 'hidden',
        width: 640,
        backgroundColor: theme.palette.white,
    },
    container: {
        width: 640,
        padding: '0 32px',
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '6px 24px 24px 24px',
        overflow: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    }
});
