import { DirectionalHint, getTheme, IButtonStyles, Icon, IconButton, PrimaryButton, SharedColors, Stack, Text } from "@fluentui/react"
import { IPromotion } from "../../../core/store/typings/IPromotion";
import { strings } from "../../../localization/strings";

export type TProps = {
    item: IPromotion
    onEdit: (item: IPromotion) => void
    onDelete: (item: IPromotion) => void
}

export const FinancePromotionHeader: React.FC<TProps> = (props) => {

    const menuItems = {
        items: [
            {
                key: `edit-${props.item.id}`,
                iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => props.item.id ? props.onEdit(props.item) : undefined,
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
            },
            {
                key: `delete-${props.item.id}`,
                iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => props.item.id ? props.onDelete(props.item) : undefined,
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
            },
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    }

    return (
        <Stack horizontal grow style={{ padding: "24px 32px 12px 32px" }}>
            <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                <Stack.Item>
                    <Icon iconName={"Shop"} style={{ fontSize: 42, color: SharedColors.orangeYellow20 }} />
                </Stack.Item>
                <Stack.Item grow>
                    <Stack>
                        <Stack.Item>
                            <Text style={{ fontSize: "1.3125rem", letterSpacing: "-.02em", fontWeight: 600, color: theme.palette.black }}>{props.item.name}</Text>
                        </Stack.Item>
                        {props.item.summary && (
                            <Stack.Item>
                                <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{props.item.summary}</Text>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
            </Stack>
            {!props.item.removed && (
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <IconButton menuIconProps={{ iconName: "MoreVertical" }} styles={menuIconStyles} menuProps={menuItems} />
                </Stack>
            )}
        </Stack>
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    root: {
        padding: 4
    },
    menuIcon: {
        fontSize: 16,
        color: theme.palette.black,
        minWidth: 16,
        padding: 4
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}