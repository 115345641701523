import * as React from 'react';
import { IColumn, Stack, Text, DetailsList, SelectionMode, DetailsListLayoutMode, SharedColors} from '@fluentui/react';
import { strings } from '../../../../../localization/strings';
import { onRenderColumnHeaderCommon } from '../../../../../components/shared/detailsList/commonRenders';
import { ItemsNotFound } from '../../../../../components/shared/notFound/ItemsNotFound';
import { OperationStatus, SwitchTransferOperationCompletedData, SwitchContentTypeReport, ContentTransferTypes, ContentType } from '../../../../../components/shared/configurator/stages/identification/scripts/picea.typings';
import { sizeToText } from '../../../../../core/helpers/common';

type IContentsData = {
    contentName: string
    succeeded: string
    duplicates: string
    notTransferred: string
    notSupported: string
}

type ITransferResultValues = {
    name: string
    value: string
    style?: object
}

type TProps = {
    summary: SwitchTransferOperationCompletedData | undefined
}

export const ContentsPivot: React.FC<TProps> = (props) => { 

    const transferResultColumns : IColumn[] = [
        {
            key: 'column1',
            name: strings.TRANSACTION.CONTENTS.COLUMN.TRANSFER_DETAILS,
            fieldName: 'title',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ITransferResultValues) => {
                return <Text variant='small'>{item.name}</Text>
            },
            isPadded: true,
        },
        {
            key: 'column',
            name: "",
            fieldName: 'value',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: undefined,
            onRender: (item: ITransferResultValues) => {
                return <Text variant='small' style={item.style} >{item.value}</Text>
            },
            isPadded: true,
        }
    ]

    const contentTypeColumns: IColumn[] = [
        {
            key: 'column1',
            name: strings.TRANSACTION.CONTENTS.COLUMN.CONTENT_TYPE,
            fieldName: 'contentType',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IContentsData) => {
                return <Text variant='small'>{item.contentName}</Text>
            },
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.TRANSACTION.CONTENTS.COLUMN.SUCCEEDED,
            fieldName: 'succeeded',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IContentsData) => {
                return <Text variant='small'>{item.succeeded}</Text>
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.TRANSACTION.CONTENTS.COLUMN.DUPLICATES,
            fieldName: 'duplicates',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IContentsData) => {
                return <Text variant='small'>{item.duplicates}</Text>
            },
            isPadded: true,
        },
        {
            key: 'column4',
            name: strings.TRANSACTION.CONTENTS.COLUMN.NOT_TRANSFERRED,
            fieldName: 'notTransferred',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IContentsData) => {
                return <Text variant='small'>{item.notTransferred}</Text>
            },
            isPadded: true,
        },
        {
            key: 'column5',
            name: strings.TRANSACTION.CONTENTS.COLUMN.NOT_SUPPORTED,
            fieldName: 'notSupported',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IContentsData) => {
                return <Text variant='small'>{item.notSupported}</Text>
            },
            isPadded: true,
        }
    ]

    let size = 0;
    let contentTypeValues : IContentsData[] = [];
    const content = props.summary?.operation_status.switch_details.content_types;
    const isCloning = props.summary?.operation_status.switch_details.switch_type === ContentTransferTypes.Cloning;
    let remaining: SwitchContentTypeReport[] = [];
    if( content) {
        if( isCloning) {
            //  Get clone size.
            const restore = content.find( ( value: SwitchContentTypeReport) => value.content_type === ContentType.ContentType_iOSRestore);
            if( restore && restore.item_size) {
                size = restore.item_size;
            }
        }
        else {
            //  For Switch, exclude iOS restore from content items.
            remaining = content.filter( ( value: SwitchContentTypeReport) => value.content_type !== ContentType.ContentType_iOSRestore);
        }
    }
    if( !isCloning && remaining.length > 0) {
        contentTypeValues = remaining.map( (value: SwitchContentTypeReport) => {

            let v: IContentsData = {
                contentName: value.content_type.toString(),
                succeeded: value.succeeded.toString(),
                duplicates: value.duplicate.toString(),
                notTransferred: value.failed.toString(),
                notSupported: value.not_supported.toString(),
            }

            //  No total size in result. Sum each content type size.
            if( value.item_size) {
                size += value.item_size;
                v.succeeded = value.succeeded.toString() + " (" + sizeToText( value.item_size) + ")";
            }
            if( value.item_size_failed) {
                v.notTransferred = value.failed.toString() + " (" + sizeToText( value.item_size_failed) + ")";
            }

            switch ( value.content_type) {
                case ContentType.ContentType_Contacts:
                    v.contentName = strings.TRANSACTION.CONTENTS.CONTACTS;
                    break;
                case ContentType.ContentType_Calendar:
                    v.contentName = strings.TRANSACTION.CONTENTS.CALENDAR;
                    break;
                case ContentType.ContentType_Messages:
                    v.contentName = strings.TRANSACTION.CONTENTS.MESSAGES;
                    break;
                case ContentType.ContentType_Bookmarks:
                    v.contentName = strings.TRANSACTION.CONTENTS.BOOKMARKS;
                    break;
                case ContentType.ContentType_Music:
                    v.contentName = strings.TRANSACTION.CONTENTS.MUSIC;
                    break;
                case ContentType.ContentType_Photos:
                    v.contentName = strings.TRANSACTION.CONTENTS.PHOTOS;
                    break;
                case ContentType.ContentType_Videos:
                    v.contentName = strings.TRANSACTION.CONTENTS.VIDEOS;
                    break;
                case ContentType.ContentType_Documents:
                    v.contentName = strings.TRANSACTION.CONTENTS.DOCUMENTS;
                    break;
                case ContentType.ContentType_Apps:
                    v.contentName = strings.TRANSACTION.CONTENTS.APPS;
                    break;
                // Not included in supported types in CB-626
                // case ContentType.ContentType_CallLog:
                //     break;
                // case ContentType.ContentType_iOSRestore:
                //     break;
                // case ContentType.ContentType_OptionalContentApps:
                //     break;
                // case ContentType.ContentType_PreloadContentApps:
                //     break;
                // case ContentType.ContentType_WhatsApp:
                //     break;
            }
            return v;
        } )
    }


    type SummaryData = {
        result: string;
        style: object;
        method: string;
        duration: string;
    }

    const parseSummary = () => {
        let s:SummaryData = {
            result: strings.TRANSACTION.CONTENTS.RESULT.SUCCESSFUL,
            style: { color: SharedColors.green20, fontWeight: 600 },
            method: isCloning ? strings.TRANSACTION.CONTENTS.METHOD.CLONE : strings.TRANSACTION.CONTENTS.METHOD.SWITCH,
            duration: "00:00:00"
        };
        const duration = props.summary?.operation_status.duration;
        if( duration) {
            const sec = duration%60;
            const min = Math.floor( duration/60)%60;
            const h = Math.floor( (duration/3600))%24;
            s.duration = (h < 10 ? "0"+h:h) + ':';
            s.duration += (min < 10 ? "0"+min:min) + ':';
            s.duration += (sec < 10 ? "0"+sec:sec);
        }
        
        if( props.summary?.operation_status.status === OperationStatus.OperationStatus_Succeeded) {
            s.result = strings.TRANSACTION.CONTENTS.RESULT.SUCCESSFUL;
            s.style = { color: SharedColors.green20, fontWeight: 600 };
        }
        if( props.summary?.operation_status.status === OperationStatus.OperationStatus_Failed) {
            s.result = strings.TRANSACTION.CONTENTS.RESULT.FAILED;
            s.style = { color: SharedColors.red20, fontWeight: 600 };
        }
        // POS History indicates anyting not failed as succeeded.
        if( props.summary?.operation_status.status === OperationStatus.OperationStatus_EmptyRun) {
            s.result = strings.TRANSACTION.CONTENTS.RESULT.SUCCESSFUL;
            s.style = { color: SharedColors.green20, fontWeight: 600 };
        }
        if( props.summary?.operation_status.status === OperationStatus.OperationStatus_Skipped){
            s.result = strings.TRANSACTION.CONTENTS.RESULT.SUCCESSFUL;
            s.style = { color: SharedColors.green20, fontWeight: 600 };
        }
        if( props.summary?.operation_status.status === OperationStatus.OperationStatus_Cancelled){
            s.result = strings.TRANSACTION.CONTENTS.RESULT.SUCCESSFUL;
            s.style = { color: SharedColors.green20, fontWeight: 600 };
        }

        return s;
    }

    const summary : SummaryData = parseSummary();

    let transferResultValues : ITransferResultValues[] = [
        {
            name: strings.TRANSACTION.CONTENTS.ROW.RESULT,
            value: summary.result,
            style: summary.style
        },
        {
            name: strings.TRANSACTION.CONTENTS.ROW.DURATION,
            value: summary.duration
        }
    ]

    //  Total size in summary only for Clone. Transfer has sizes individually for each content type.
    if( isCloning) {
        transferResultValues.push(
            {
                name: strings.TRANSACTION.CONTENTS.ROW.CLONE_SIZE,
                value: sizeToText( size)
            }
        );
    }
    transferResultValues.push( 
        {
            name: strings.TRANSACTION.CONTENTS.ROW.METHOD,
            value: summary.method
        }
    );

    return ( <> 
         <Stack>
            <DetailsList
                items={transferResultValues}
                columns={transferResultColumns}
                selectionMode={SelectionMode.none}
                getKey={(item: ITransferResultValues, index) => item.name + index}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                compact
                styles={{ focusZone: { paddingTop: 0, }, contentWrapper: { padding: 0 }, headerWrapper: { padding: 0 } }}
            />
        </Stack>

    { !isCloning && ( contentTypeValues.length > 0 && (
        <Stack>
            <DetailsList
                items={contentTypeValues}
                columns={contentTypeColumns}
                selectionMode={SelectionMode.none}
                getKey={(item: IContentsData, index) => item.contentName + index}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                compact
                styles={{ focusZone: { paddingTop: 0, }, contentWrapper: { padding: 0 }, headerWrapper: { padding: 0 } }}
            />
        </Stack>
    ) || (
            <ItemsNotFound doNotUseButton
                info={strings.TRANSACTION.CONTENTS.NOT_FOUND_CASE.INFO}
                suggestion={strings.TRANSACTION.CONTENTS.NOT_FOUND_CASE.SUGGESTION}
                imgSrc={"images/navigation/images/not_found.png"} />
        ))}
    </>)
}
