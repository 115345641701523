import React from "react";
import { getCommonFieldRules } from "../../../helpers/validationRules"
import { Control, Controller } from "react-hook-form"
import Address from "./Address"
import { IDataCollectionConfig } from "@piceasoft/core"
import { TContractData } from "../../../../../dataCollectionTypes"

type TProps = {
    config?: IDataCollectionConfig
    suggestions?: {value: string}[]
    errorMessage: string
    onRenderSuggestionsHeader: () => JSX.Element
    control: Control<TContractData, object>,
}

const AddressController: React.FunctionComponent<TProps> = (props) => {
    const {
        config,
        control,
        errorMessage,
    } = props

    return (
        <Controller
            name="passport.address"
            control={control}
            rules={getCommonFieldRules(config?.config?.fields?.name)}
            render={({ field }) => 
                <Address
                    config={config}
                    setSearchRequest={(v: string) => field.onChange(v)}
                    errorMessage={errorMessage}
                    onBlur={field.onBlur}
                    searchRequest={field.value}
                />
            }
        />
    )
}

export default AddressController