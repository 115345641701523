import { getFocusStyle, getTheme, ITheme, mergeStyleSets, Separator, Stack, Text, getId } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { strings } from '../../../../../../localization/strings';
import SuggestedSearch from '../../../../search/SuggestedSearch';
import { IIdentificationSearchFormConfig } from '@piceasoft/core';
import IdentificationHeader from './ui/IdentificationHeader';
import { TooltipHost, TooltipOverflowMode, css } from '@fluentui/react';
import { identificationContainerStyle, hasBlockLevelElements, blockOffset } from '../helpers/identificationContainer';
type TProps = {
    config?: IIdentificationSearchFormConfig
}

export type DeviceSearchItem = {
    id: number
    manufacturer?: string
    name: string
    configuration?: string
    type?: number
}

const Search: React.FC<TProps> = (props) => {

    const [searchRequest, setSearchRequest] = React.useState<string>()
    const searchListId = getId('search-list-id')
    const searchBoxId = 'search-box-id'

    const searchHandler = async (value?: string) => {
        if (value && value.length > 0)
            setSearchRequest(value)
        if (!value || value.length === 0)
            setSearchRequest('')
    }

    const onRenderCell = (item?: DeviceSearchItem, index?: number, isScrolling?: boolean): JSX.Element => {
        return (
            <div className={classNames.itemCell}
                data-is-focusable={true}
                id={`${searchListId}-${index as number}`}
                onKeyDown={() => {}}
                tabIndex={-1}
                onClick={() => {}}>
                <Stack grow tokens={{ padding: 4, childrenGap: 4 }}>
                    <Stack horizontal horizontalAlign="space-between" grow tokens={{ childrenGap: 4 }}>
                        <Text style={{ color: SharedColors.cyanBlue10 }}>{item?.manufacturer}</Text>
                        {item?.configuration && item?.configuration !== "0" && (
                            <Text variant="small" style={{ color: SharedColors.gray30 }}>
                                {strings.ATTRIBUTES.DEVICE.CONFIGURATION}: <Text variant="medium">{item?.configuration}</Text>
                            </Text>
                        )}
                    </Stack>
                    <Stack.Item style={{ maxWidth: 300 }}>
                        <Text block nowrap variant="mediumPlus" style={{ color: SharedColors.gray40 }}>{item?.name}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    };

    return (
        <Stack horizontalAlign="stretch">
            <Stack.Item>
                <IdentificationHeader
                    icon={props.config?.ui?.icon ?? "Search"}
                    title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_SEARCH}
                />
            </Stack.Item>
            <Separator />
            <Stack.Item>
                <TooltipHost
                overflowMode={TooltipOverflowMode.Self}
                content={props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION}
                hostClassName={css(identificationContainerStyle().body)}
                styles={{ root: {display: 'block'}}}>
                    <Text style={{ color: SharedColors.gray40 }}>
                        <div 
                            style={hasBlockLevelElements(props.config?.ui?.information) ? {marginTop: `${blockOffset}`} : {marginTop: '0px'}}
                            dangerouslySetInnerHTML={{ __html: props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_SEARCH }}>
                        </div>
                    </Text>
                </TooltipHost>
            </Stack.Item>
            <Stack styles={{ root: { paddingTop: 16 } }}>
                <SuggestedSearch
                    onClickSuggestionCell={() => {}}
                    inputBoxId={searchBoxId}
                    onRenderSuggestionCell={onRenderCell}
                    suggestionsListId={searchListId}
                    searchRequest={searchRequest}
                    suggestions={[]}
                    setSearchRequest={searchHandler}
                    isSearchEqualTheOneSuggestion={false}
                    inputBoxPlaceholder={props.config?.ui?.placeholder}
                />
            </Stack>
        </Stack>
    );
}

export default Search

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;
const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1, outlineColor: palette.neutralLight, borderColor: palette.neutralLight }),
        {
            padding: 5,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: palette.neutralLight },
                '&:focus': { background: palette.neutralLight },
            },
        },
    ]
});