import * as React from 'react';
import { ConstrainMode, DetailsList, DetailsListLayoutMode, DetailsRow, getTheme, IColumn, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles, IRenderFunction, SelectionMode, Stack, Sticky, StickyPositionType, TooltipHost } from '@fluentui/react';
import { ILogEntry } from "@piceasoft/core";

export type TProps = {
    entries: ILogEntry[]
    columns: IColumn[]
    getDetails: (uid: string) => void
}

export const LogDetailsList: React.FC<TProps> = props => {

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => props.getDetails(rProps.item['uid'])
            }><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    }

    return (
        <DetailsList
            items={props.entries}
            columns={props.columns}
            selectionMode={SelectionMode.none}
            getKey={(item: ILogEntry, index) => item.creationDate.toString() + index}
            setKey="none"
            constrainMode={ConstrainMode.unconstrained}
            onRenderDetailsHeader={onRenderDetailsHeader}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderRow={onRenderRow}
        />
    );
}

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
            })}
        </Sticky>
    );
};

const theme = getTheme();
