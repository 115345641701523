import { Stack, Text, MessageBar, MessageBarType, mergeStyleSets, TextField, DirectionalHint, PrimaryButton, getTheme, MaskedTextField, IconButton, Modal, FontWeights } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import React from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { CopyToClipBoardButton } from "../../../components/shared/buttons/CopyToClipboardButton"
import { DangerButton } from "../../../components/shared/buttons/DangerButton"
import { CustomSeparator } from "../../../components/shared/decorations/CustomSeparator"
import { ConfirmationForm } from "../../../components/shared/forms/ConfirmationForm"
import { actionCreators } from "../../../core/actions/settings-actions"
import { settingsApi } from "../../../core/api/settings.api"
import { IPiceaCredentials } from "../../../core/store/typings/IPiceaCredentials"
import { IResponseError } from "@piceasoft/core"
import { strings } from "../../../localization/strings"

type TProps = {
    piceaCredentials?: IPiceaCredentials
    organizationId: string
}

export const SettingsSecurityPiceasoftTab: React.FC<TProps> = props => {
    const dispatch = useDispatch();
    const { control, handleSubmit, getValues, setValue, reset, formState: { errors, isDirty } } = useForm<IPiceaCredentials>({
        defaultValues: props.piceaCredentials
    })
    const [formErrors, setErrors] = React.useState<IResponseError[]>()
    const [isFetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false);
    const [isShowWarningDelete, { setTrue: showWarningDelete, setFalse: hideWarningDelete }] = useBoolean(false)
    const [isShowWarningGenerate, { setTrue: showWarningGenerate, setFalse: hideWarningGenerate }] = useBoolean(false)
    const validationOfRequiredGuidField = (value: string) => {
        const guid = value.split('_').join('')
        if (guid.split('-').join('').length === 0) {
            return strings.SETTINGS.SECURITY.PICEASOFT.WARNINGS.EMPTY_FIELD_WARNING
        }
        else if (guid.split('-').join('').length < 32) {
            return strings.SETTINGS.SECURITY.PICEASOFT.WARNINGS.NOT_FILLED_FIELD_WARNING
        } else {
            const pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
            const regex = new RegExp(pattern)
            const result = regex.test(value)
            return result ? result : strings.SETTINGS.SECURITY.PICEASOFT.WARNINGS.INVALID_FIELD_WARNING
        }

    }
    const validationOfOptionalGuidField = (value?: string) => {
        if (value) {
            const guid = value.split('_').join('')
            if (guid.split('-').join('').length < 32 && guid.split('-').join('').length !== 0) {
                return strings.SETTINGS.SECURITY.PICEASOFT.WARNINGS.NOT_FILLED_FIELD_WARNING
            }
            else if (guid.split('-').join('').length === 32) {
                const pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
                const regex = new RegExp(pattern)
                const result = regex.test(value)
                return result ? result : strings.SETTINGS.SECURITY.PICEASOFT.WARNINGS.INVALID_FIELD_WARNING
            }
        }
    }

    const onSubmit: SubmitHandler<IPiceaCredentials> = async data => {
        if (data) {
            setErrors(undefined);
            startFetch();
            const result = await settingsApi.organization.updatePiceasoftCredentials(data);
            if (result.successed && result.data) {
                dispatch(actionCreators.refreshOrganizationPiceasoftCredentials(result.data));
            }
            if (result.errors && result.errors.length > 0) {
                setErrors(result.errors)
            }
            stopFetch();

        }
    }
    const onGeneratePiceaCredentials = async () => {
        setErrors(undefined);
        startFetch();
        const result = await settingsApi.organization.generateNewPiceasoftCredentials()
        if (result.successed && result.data) {
            dispatch(actionCreators.refreshOrganizationPiceasoftCredentials(result.data));
        }
        if (result.errors && result.errors.length > 0) {
            setErrors(result.errors)
        }
        hideWarningGenerate()
        stopFetch();
    }
    React.useEffect(() => {
        reset(props.piceaCredentials, { keepValues: false })
    }, [props.piceaCredentials])

    const onDeletePiceaCredentials = async () => {
        setErrors(undefined);
        startFetch();
        const result = await settingsApi.organization.deletePiceasoftCredentials();
        if (result.successed) {
            dispatch(actionCreators.refreshOrganizationPiceasoftCredentials());
        }
        if (result.errors && result.errors.length > 0) {
            setErrors(result.errors)
        }
        reset({...props.piceaCredentials, customerId: '',productId: '', reportingClientId: '', reportingClientKey: '', validationClientId: '', validationClientKey: '' })
        hideWarningDelete()
        stopFetch();
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%" }}>
            <Stack verticalFill>
                <Stack.Item>
                    {formErrors && formErrors.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error} isMultiline>
                            <Stack tokens={{ childrenGap: 2 }}>
                                {formErrors.map(i => <Text key={i.code}>{i.description}</Text>)}
                            </Stack>
                        </MessageBar>
                    )}
                </Stack.Item>
                <Stack.Item>
                    <Modal isOpen={isShowWarningDelete} onDismiss={hideWarningDelete} containerClassName={contentStyles.container}>
                        <div className={contentStyles.header}>
                            <span style={{ paddingRight: 32 }}>{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_DELETE_WARNINGS.WARNING_TITLE}</span>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel="Close"
                                onClick={hideWarningDelete}
                            />
                        </div>
                        <div className={contentStyles.body}>
                            <ConfirmationForm
                                onConfirm={onDeletePiceaCredentials}
                                onCancel={hideWarningDelete}
                                requiredMessageString={strings.SETTINGS.SECURITY.PICEASOFT.MODAL_DELETE_WARNINGS.VALID_REQUIRED_MESSAGE}
                                confirmationCheckString={strings.SETTINGS.SECURITY.PICEASOFT.MODAL_DELETE_WARNINGS.CHECK_STRING}
                                placeholder={strings.SETTINGS.SECURITY.PICEASOFT.MODAL_DELETE_WARNINGS.PLACEHOLDER}
                            >
                                <Stack tokens={{ childrenGap: 12 }}>
                                    <Text>{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_DELETE_WARNINGS.WARNING_TEXT1}</Text>
                                    <Text>{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_DELETE_WARNINGS.WARNING_TEXT2}</Text>
                                    <Text unselectable="on">{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_DELETE_WARNINGS.CONFIRM_DELETE_TEXT} <b>"{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_DELETE_WARNINGS.CONFIRM_TEXT}"</b></Text>
                                </Stack>
                            </ConfirmationForm>
                        </div>
                    </Modal>
                </Stack.Item>
                <Stack.Item>
                    <Modal isOpen={isShowWarningGenerate} onDismiss={hideWarningGenerate} containerClassName={contentStyles.container}>
                        <div className={contentStyles.header}>
                            <span style={{ paddingRight: 32 }}>{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_GENERATE_WARNINGS.WARNING_TITLE}</span>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel="Close"
                                onClick={hideWarningGenerate}
                            />
                        </div>
                        <div className={contentStyles.body}>
                            <ConfirmationForm
                                onConfirm={onGeneratePiceaCredentials}
                                onCancel={hideWarningGenerate}
                                requiredMessageString={strings.SETTINGS.SECURITY.PICEASOFT.MODAL_GENERATE_WARNINGS.VALID_REQUIRED_MESSAGE}
                                confirmationCheckString={strings.SETTINGS.SECURITY.PICEASOFT.MODAL_GENERATE_WARNINGS.CHECK_STRING}
                                placeholder={strings.SETTINGS.SECURITY.PICEASOFT.MODAL_GENERATE_WARNINGS.PLACEHOLDER}
                            >
                                <Stack tokens={{ childrenGap: 12 }}>
                                    <Text>{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_GENERATE_WARNINGS.WARNING_TEXT1}</Text>
                                    <Text>{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_GENERATE_WARNINGS.WARNING_TEXT2}</Text>
                                    <Text unselectable="on">{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_GENERATE_WARNINGS.CONFIRM_GENERATE_TEXT} <b>"{strings.SETTINGS.SECURITY.PICEASOFT.MODAL_GENERATE_WARNINGS.CONFIRM_TEXT}"</b></Text>
                                </Stack>
                            </ConfirmationForm>
                        </div>
                    </Modal>
                </Stack.Item>
                <Stack.Item>
                    <Stack verticalFill tokens={{ padding: 24, childrenGap: 16 }}>
                        <Stack.Item>
                            <Stack horizontal horizontalAlign="space-between">
                                <Stack style={{ width: 360 }} verticalFill tokens={{ childrenGap: 16 }} >
                                    <Stack tokens={{ childrenGap: 16 }} >
                                        <Stack.Item>
                                            <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.NAVIGATION.SECURITY.PICEASOFT_LABEL}</Text>
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Controller control={control}
                                name={"customerId"}
                                rules={{
                                    required: strings.SETTINGS.SECURITY.PICEASOFT.WARNINGS.NOT_FILLED_FIELD_WARNING,
                                    validate: (value) => {
                                        return validationOfRequiredGuidField(value)
                                    }
                                }}
                                render={({ field }) =>
                                    <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <MaskedTextField
                                            label={strings.SETTINGS.SECURITY.PICEASOFT.CUSTOMER_ID}
                                            style={{ width: 520 }}
                                            value={field.value ?? ''}
                                            mask={"********-****-****-****-************"}
                                            required
                                            onChange={(ev, value) => field.onChange(
                                                value === "________-____-____-____-____________" ? undefined : value
                                            )}
                                            errorMessage={errors.customerId?.message}
                                        />
                                        <Stack>
                                            {field.value && (
                                                <CopyToClipBoardButton content={field.value}
                                                    disabled={isFetching}
                                                    copyText={strings.SETTINGS.SECURITY.PICEASOFT.COPY_CUSTOMER_ID}
                                                    copiedText={strings.SETTINGS.SECURITY.PICEASOFT.CUSTOMER_ID_COPIED}
                                                    tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                                />
                                            )}
                                            {errors.customerId && (
                                                <Stack style={{ paddingTop: 22 }}></Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                } />
                            <Controller control={control}
                                name={"productId"}
                                rules={{
                                    required: strings.SETTINGS.SECURITY.PICEASOFT.WARNINGS.NOT_FILLED_FIELD_WARNING,
                                    validate: (value) => {
                                        return validationOfRequiredGuidField(value)

                                    }
                                }}
                                render={({ field }) =>
                                    <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <MaskedTextField
                                            label={strings.SETTINGS.SECURITY.PICEASOFT.PRODUCT_ID}
                                            style={{ width: 520 }}
                                            value={field.value ?? ''}
                                            mask={"********-****-****-****-************"}
                                            required
                                            onChange={(ev, value) => field.onChange(
                                                value === "________-____-____-____-____________" ? undefined : value
                                            )}
                                            errorMessage={errors.productId?.message}
                                        />
                                        <Stack>
                                            {field.value && (
                                                <CopyToClipBoardButton content={field.value}
                                                    disabled={isFetching}
                                                    copyText={strings.SETTINGS.SECURITY.PICEASOFT.COPY_PRODUCT_ID}
                                                    copiedText={strings.SETTINGS.SECURITY.PICEASOFT.PRODUCT_ID_COPIED}
                                                    tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                                />
                                            )}
                                            {errors.productId && (
                                                <Stack style={{ paddingTop: 22 }}></Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                }
                            />
                            <Controller control={control}
                                name={"validationClientId"}
                                rules={{
                                    validate: (value) => {
                                        return validationOfOptionalGuidField(value)
                                    }
                                }}
                                render={({ field }) =>
                                    <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <MaskedTextField
                                            label={strings.SETTINGS.SECURITY.PICEASOFT.CLIENT_VALIDATION_ID}
                                            style={{ width: 520 }}
                                            value={field.value ?? ''}
                                            mask={"********-****-****-****-************"}
                                            onChange={(ev, value) => field.onChange(
                                                value === "________-____-____-____-____________" ? undefined : value
                                            )}
                                            errorMessage={errors.validationClientId?.message}
                                        />
                                        <Stack>
                                            {field.value && (
                                                <CopyToClipBoardButton content={field.value}
                                                    disabled={isFetching}
                                                    copyText={strings.SETTINGS.SECURITY.PICEASOFT.CLIENT_VALIDATION_ID_COPY}
                                                    copiedText={strings.SETTINGS.SECURITY.PICEASOFT.COPIED_CLIENT_VALIDATION_ID}
                                                    tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                                />
                                            )}
                                            {errors.validationClientId && (
                                                <Stack style={{ paddingTop: 22 }}></Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                }
                            />
                            <Controller control={control}
                                name={"validationClientKey"}
                                render={({ field }) =>
                                    <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <TextField
                                            label={strings.SETTINGS.SECURITY.PICEASOFT.CLIENT_VALIDATION_KEY}
                                            style={{ width: 520 }}
                                            value={field.value ?? ''}
                                            onChange={field.onChange}
                                        />
                                        {field.value && (
                                            <CopyToClipBoardButton content={field.value ?? ""}
                                                disabled={isFetching}
                                                copyText={strings.SETTINGS.SECURITY.PICEASOFT.CLIENT_VALIDATION_KEY_COPY}
                                                copiedText={strings.SETTINGS.SECURITY.PICEASOFT.COPIED_CLIENT_VALIDATION_KEY}
                                                tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                            />
                                        )}
                                    </Stack>
                                }
                            />
                            <Controller control={control}
                                name={"reportingClientId"}
                                rules={{
                                    validate: (value) => {
                                        return validationOfOptionalGuidField(value)
                                    }
                                }}
                                render={({ field }) =>
                                    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="end">
                                        <MaskedTextField
                                            label={strings.SETTINGS.SECURITY.PICEASOFT.CLIENT_REPORTING_ID}
                                            style={{ width: 520 }}
                                            value={field.value ?? ''}
                                            mask={"********-****-****-****-************"}
                                            onChange={(ev, value) => field.onChange(
                                                value === "________-____-____-____-____________" ? undefined : value
                                            )}
                                            errorMessage={errors.reportingClientId?.message}
                                        />
                                        <Stack>
                                            {field.value && (
                                                <CopyToClipBoardButton content={field.value}
                                                    disabled={isFetching}
                                                    copyText={strings.SETTINGS.SECURITY.PICEASOFT.CLIENT_REPORTING_ID_COPY}
                                                    copiedText={strings.SETTINGS.SECURITY.PICEASOFT.COPIED_CLIENT_REPORTING_ID}
                                                    tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                                />
                                            )}
                                            {errors.reportingClientId && (
                                                <Stack style={{ paddingTop: 22 }}></Stack>
                                            )}
                                        </Stack>
                                    </Stack>
                                }
                            /><Controller control={control}
                                name={"reportingClientKey"}
                                render={({ field }) =>
                                    <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <TextField
                                            label={strings.SETTINGS.SECURITY.PICEASOFT.CLIENT_REPORTING_KEY}
                                            style={{ width: 520 }}
                                            value={field.value ?? ''}
                                            onChange={field.onChange}
                                        />
                                        {field.value && (
                                            <CopyToClipBoardButton content={field.value ?? ""}
                                                disabled={isFetching}
                                                copyText={strings.SETTINGS.SECURITY.PICEASOFT.CLIENT_REPORTING_KEY_COPY}
                                                copiedText={strings.SETTINGS.SECURITY.PICEASOFT.COPIED_CLIENT_REPORTING_KEY}
                                                tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                            />
                                        )}
                                    </Stack>
                                }
                            />
                        </Stack>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow tokens={{ childrenGap: 8, padding: '0 0 0 24px' }}>
                        <PrimaryButton disabled={(isFetching || (props.piceaCredentials?.customerId && props.piceaCredentials?.productId)) ? true : false} onClick={showWarningGenerate}>
                            {strings.BUTTONS.TEXT.GENERATE_NEW_KEY}
                        </PrimaryButton>
                        <DangerButton onClick={showWarningDelete} disabled={props.piceaCredentials ? false : true}>
                            {strings.BUTTONS.TEXT.DELETE}
                        </DangerButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)} disabled={isDirty ? false : true}>
                            {strings.BUTTONS.TEXT.SAVE}
                        </PrimaryButton>
                    </Stack>
                </Stack.Item>
                <CustomSeparator />
            </Stack>
        </form>
    )
}
const theme = getTheme();
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});