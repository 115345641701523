import {
     ISoftwareConfig
} from "../../core/store"
import { IDiagnosticsConfig } from "../../core/store/typings/IDiagnosticsConfig"
import { strings } from "../strings"
import {
    IInspectionConfig, IInterviewConfig, IPhotographicConfig,
    SoftwareModes, Inspections, ManualGradingModes,  PhotographicModes, InterviewModes, IdentificationMethods,
    DiagnosticsModes, IInspectionConfigUI, DataCollectionModes,
    IAIGradingConfig, AIGradingResultTypes, IManualGradingConfig, IDataCollectionConfig, IEraseConfig, PromotionPriceTypes,
    ModerationStatuses, AIGradingModes, InspectionStatuses, EraseModes, ProcessStages, TransactionLogFlags
} from "@piceasoft/core"
import { getEnumName } from "../../core/actions/teaching-actions";

export const getLocalizedInspectionStatus = (status: InspectionStatuses): string => {
    switch (status) {
        case InspectionStatuses.Done:
            return strings.TRANSACTION.INSPECTION.STATUS.DONE.TITLE
        case InspectionStatuses.Error:
            return strings.TRANSACTION.INSPECTION.STATUS.ERROR.TITLE
        case InspectionStatuses.Fail:
            return strings.TRANSACTION.INSPECTION.STATUS.FAIL.TITLE
        case InspectionStatuses.Lock:
            return strings.TRANSACTION.INSPECTION.STATUS.LOCK.TITLE
        case InspectionStatuses.New:
            return strings.TRANSACTION.INSPECTION.STATUS.NEW.TITLE
        case InspectionStatuses.Run:
            return strings.TRANSACTION.INSPECTION.STATUS.RUN.TITLE
        case InspectionStatuses.SkippedByUser:
        case InspectionStatuses.SkippedByCondition:
            return strings.TRANSACTION.INSPECTION.STATUS.SKIP.TITLE
    }
}

export const getLocalizedInspectionName = (inspection: Inspections, stage?: ProcessStages): string => {
    switch (inspection) {
        case Inspections.DataCollection:
            return strings.TRANSACTION.INSPECTIONS.DATACOLLECTION.TITLE
        case Inspections.Diagnostics:
            return strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.TITLE
        case Inspections.Interview:
            return strings.TRANSACTION.INSPECTIONS.INTERVIEW.TITLE
        case Inspections.Photographic:
            return strings.TRANSACTION.INSPECTIONS.PHOTOGRAPHIC.TITLE
        case Inspections.Software:
            if (stage && stage === ProcessStages.PostOffer)
                return `${strings.TRANSACTION.INSPECTIONS.SOFTWARE.TITLE} (${strings.CONSTRUCTOR.STAGES.POST_OFFER.SUBTITLE})`
            return strings.TRANSACTION.INSPECTIONS.SOFTWARE.TITLE
        case Inspections.Erase:
            return strings.TRANSACTION.INSPECTIONS.TOOLS.ERASE
        case Inspections.AIGrading:
            return strings.TRANSACTION.INSPECTIONS.AIGRADING.TITLE
        case Inspections.Contract:
            return ""
        case Inspections.ManualGrading:
            return strings.TRANSACTION.GRADING.MAIN_TITLE
    }
}

export const getLocalizedInspectionModeName = (type: Inspections, config: IInspectionConfig<IInspectionConfigUI>): string => {
    switch (type) {
        case Inspections.DataCollection:
            return getLocalizedDataCollectionModeName((config as IDataCollectionConfig).mode)
        case Inspections.Diagnostics:
            return getLocalizedDiagnosticsModeName((config as IDiagnosticsConfig).mode)
        case Inspections.Interview:
            return getLocalizedInterviewModeName((config as IInterviewConfig).mode)
        case Inspections.Photographic:
            return getLocalizedPhotographicModeName((config as IPhotographicConfig).mode)
        case Inspections.Software:
            return getLocalizedSoftwareModeName((config as ISoftwareConfig).mode)
        case Inspections.Erase:
            return getLocalizedEraseModeName((config as IEraseConfig).mode)
        case Inspections.AIGrading:
            return getLocalizedAIGradingModeName((config as IAIGradingConfig).mode)
        case Inspections.Contract:
            return ""
        case Inspections.ManualGrading:
            return getLocalizedGradeModeName((config as IManualGradingConfig).mode)
    }
}

export const getLocalizedInterviewModeName = (mode: InterviewModes): string => {
    switch (mode) {
        case InterviewModes.Sequence:
            return strings.TRANSACTION.INSPECTIONS.INTERVIEW.SEQUENCE
        case InterviewModes.List:
            return strings.TRANSACTION.INSPECTIONS.INTERVIEW.LIST
        case InterviewModes.Manual:
            return strings.TRANSACTION.INSPECTIONS.INTERVIEW.MANUAL
    }
}

export const getLocalizedGradeModeName = (mode: ManualGradingModes): string => {
    switch (mode) {
        case ManualGradingModes.Default:
            return strings.TRANSACTION.GRADING.MAIN_TITLE
    }
}

export const getLocalizedPhotographicModeName = (mode: PhotographicModes): string => {
    switch (mode) {
        case PhotographicModes.Collection:
            return strings.TRANSACTION.INSPECTIONS.PHOTOGRAPHIC.COLLECTION
        case PhotographicModes.Free:
            return strings.TRANSACTION.INSPECTIONS.PHOTOGRAPHIC.FREE
    }
}

export const getLocalizedDiagnosticsModeName = (mode: DiagnosticsModes): string => {
    switch (mode) {
        case DiagnosticsModes.Manual:
            return strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.MANUAL
        case DiagnosticsModes.Piceasoft:
            return strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.PICEAONE
        case DiagnosticsModes.PiceaMobile:
            return strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.MOBILE
        case DiagnosticsModes.WebBased:
            return strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.WEB_BASED
    }
}

export const getLocalizedSoftwareModeName = (mode: SoftwareModes): string => {
    switch (mode) {
        case SoftwareModes.Piceasoft:
            return strings.TRANSACTION.INSPECTIONS.SOFTWARE.PICEASOFT
        case SoftwareModes.PiceaMobile:
            return strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.MOBILE
        default:
            return ""
    }
}

export const getLocalizedDataCollectionModeName = (mode: DataCollectionModes): string => {
    switch (mode) {
        case DataCollectionModes.Standard:
            return strings.TRANSACTION.INSPECTIONS.DATACOLLECTION.STANDARD
    }
}

export const getLocalizedEraseModeName = (mode: EraseModes): string => {
    switch (mode) {
        case EraseModes.Default:
            return strings.TRANSACTION.INSPECTIONS.TOOLS.ERASE
    }
}

export const getLocalizedAIGradingModeName = (mode: AIGradingModes): string => {
    switch (mode) {
        case AIGradingModes.Default:
            return strings.TRANSACTION.INSPECTIONS.AIGRADING.TITLE
    }
}

export const getLocalizedAIGradingResultTypeName = (type: AIGradingResultTypes): string => {
    switch (type) {
        case AIGradingResultTypes.ScreenCover:
            return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_COVER_TITLE
        case AIGradingResultTypes.ScreenCrack:
            return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_CRACK_TITLE
        case AIGradingResultTypes.ScreenScratch:
            return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_SCRATCH_TITLE
    }
}

export const getLocalizedAIGradingResultTypeDescription = (type: AIGradingResultTypes): string => {
    switch (type) {
        case AIGradingResultTypes.ScreenCover:
            return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_COVER
        case AIGradingResultTypes.ScreenCrack:
            return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_CRACK_DESCRIPTION
        case AIGradingResultTypes.ScreenScratch:
            return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_SCRATCH_DESCRIPTION
    }
}

export const getLocalizedIdentificationMethod = (method: IdentificationMethods): string => {
    switch (method) {
        case IdentificationMethods.FreeForm:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.FREEFORM.TITLE
        case IdentificationMethods.Global:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.GLOBAL.TITLE
        case IdentificationMethods.PiceaOne:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.PICEA_ONE.TITLE
        case IdentificationMethods.PiceaUsb:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.PICEA_USB.TITLE
        case IdentificationMethods.SearchForm:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.SEARCH.TITLE
        case IdentificationMethods.Serial:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.SERIAL.TITLE
        case IdentificationMethods.Universal:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.UNIVERSAL.TITLE
        case IdentificationMethods.Transaction:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.TRANSACTION.TITLE
        case IdentificationMethods.Scanner:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.SCANNER.TITLE
        case IdentificationMethods.Imei:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.IMEI.TITLE
        case IdentificationMethods.SDK:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.SDK.TITLE
        default: return String(method)
    }
}

export const getLocalizedIdentificationSubtitle = (method: IdentificationMethods): string => {
    switch (method) {
        case IdentificationMethods.Global:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.GLOBAL.SUBTITLE
        case IdentificationMethods.SearchForm:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.SEARCH.SUBTITLE
        case IdentificationMethods.Universal:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.UNIVERSAL.SUBTITLE
        case IdentificationMethods.Scanner:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.SCANNER.SUBTITLE
        case IdentificationMethods.Transaction:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.TRANSACTION.SUBTITLE
        case IdentificationMethods.SDK:
            return strings.TRANSACTION.IDENTIFICATION.METHODS.SDK.SUBTITLE
        default: return getEnumName(IdentificationMethods, String(method))
    }
}

export const getLocalizedTransactionFlag = (flag: string): string => {
    switch (flag) {
        case TransactionLogFlags.Unfinished:
            return strings.TRANSACTION.FLAGS.STARTED
        case TransactionLogFlags.Created:
            return strings.TRANSACTION.FLAGS.FINISHED
        case TransactionLogFlags.Canceled:
            return strings.TRANSACTION.FLAGS.CANCELED
        default: 
            return strings.TRANSACTION.FLAGS.STARTED
    }
}

export const getLocalizedTransactionFlagTooltip = (flag: string): string => {
    switch (flag) {
        case TransactionLogFlags.Unfinished:
            return strings.TRANSACTION.RESULT.TRANSACTION_STATUS_TOOLTIP.STARTED
        case TransactionLogFlags.Created:
            return strings.TRANSACTION.RESULT.TRANSACTION_STATUS_TOOLTIP.FINISHED
        case TransactionLogFlags.Canceled:
            return strings.TRANSACTION.RESULT.TRANSACTION_STATUS_TOOLTIP.CANCELLED
        default:
            return strings.TRANSACTION.RESULT.TRANSACTION_STATUS_TOOLTIP.STARTED
    }
}

export const getLocalizedModerationStatus = (status: string): string => {
    switch (status) {
        case ModerationStatuses.request:
            return strings.TRANSACTIONS.MODERATIONS.STATUSES.REQUEST
        case ModerationStatuses.moderation:
            return strings.TRANSACTIONS.MODERATIONS.STATUSES.MODERATION
        case ModerationStatuses.queue:
            return strings.TRANSACTIONS.MODERATIONS.STATUSES.QUEUE
        case ModerationStatuses.completed:
            return strings.TRANSACTIONS.MODERATIONS.STATUSES.COMPLETED
        case ModerationStatuses.denied:
            return strings.TRANSACTIONS.MODERATIONS.STATUSES.DENIED
        case ModerationStatuses.none:
            return strings.TRANSACTIONS.MODERATIONS.STATUSES.NONE
        default: return status
    }
}

export const getPromoPriceTypeDescription = (type: PromotionPriceTypes): string => {
    switch (type) {
        case PromotionPriceTypes.Benefit: return strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS.BENEFIT
        case PromotionPriceTypes.FixPrice: return strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS.FIX_PRICE
    }
}
