import { DetailsList, Text, DetailsListLayoutMode, DetailsRow, getTheme, IColumn, IDetailsListProps, IDetailsRowStyles, PrimaryButton, ScrollablePane, SelectionMode, Stack, Image, IconButton, IButtonStyles, IContextualMenuProps, DirectionalHint, ImageFit, FontWeights, mergeStyleSets, Modal, Spinner, IDetailsColumnProps, IRenderFunction, SharedColors, IDetailsHeaderProps, Sticky, IDetailsColumnRenderTooltipProps, TooltipHost } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import * as React from 'react';
import { useHistory } from "react-router";
import { ScreenHeader } from "../../../components/shared/decorations/ScreenHeader"
import { Section } from "../../../components/shared/decorations/Section"
import { onRenderColumnHeaderCommon } from "../../../components/shared/detailsList/commonRenders";
import { ConfirmationForm } from "../../../components/shared/forms/ConfirmationForm";
import { ItemsNotFound } from "../../../components/shared/notFound/ItemsNotFound";
import { portalApi } from "../../../core/api/api";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../core/scripts/style";
import { IBranding } from "../../../core/store/typings/IBranding";
import { strings } from "../../../localization/strings";
import { BrandingDetails } from "./BrandingDetails";
import defaultServiceIcon from "../../../assets/service.svg"

export const OrganizationBranding: React.FC = () => {
    const [brandingsState, setBrandingsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IBranding> }>({ fetched: false })
    const [deleteFetching, { setTrue: startDeleteFetch, setFalse: stopDeleteFetch }] = useBoolean(false)
    const [deleteBrandingState, setDeleteBrandingState] = React.useState<IBranding>()
    const [brandingState, setBrandingState] = React.useState<{ fetched: boolean, error?: string, data?: IBranding }>()

    React.useEffect(() => {
        getBrandingItems()
    }, [])

    const getBrandingItems = async () => {
        setBrandingsState({ fetched: false })
        const result = await portalApi.organization.branding.getBrandings()
        setBrandingsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(c => !c.removed) })
    }
    const onAddBranding = async (data: IBranding) => {
        setBrandingsState({ data: brandingsState.data, fetched: false })
        const result = await portalApi.organization.branding.addBranding(data)
        console.log(result.data)
        setBrandingsState({
            data: result.data ? (
                brandingsState.data ? [...brandingsState.data, result.data] : [result.data]
            ) : brandingsState?.data,
            fetched: true,
            error: (result.errors && result.errors.length > 0) ? result.errors[0].description : undefined
        })
        if (result.successed) {
            setBrandingState(undefined)
        }
    }
    const onUpdateBranding = async (branding: IBranding) => {
        setBrandingsState({ data: brandingsState.data, fetched: false })
        const result = await portalApi.organization.branding.updateBranding(branding)
        const updatedData = brandingsState.data?.map(i => {
            if (i.id === branding.id && result.successed) {
                return branding
            }
            return i
        })
        setBrandingsState({ ...brandingsState, data: updatedData, fetched: true, error: (result.errors && result.errors.length > 0) ? result.errors[0].description : undefined })
        if (result.successed) {
            setBrandingState(undefined)
        }
    }
    const onBrandingDelete = () => {
        if (deleteBrandingState?.id) {
            deleteBranding(deleteBrandingState?.id)
            setDeleteBrandingState(undefined)
        }
    }
    const deleteBranding = async (id: number) => {
        startDeleteFetch()
        setBrandingsState({ ...brandingsState, error: undefined })
        const result = await portalApi.organization.branding.deleteBranding(id)
        if (result.successed) {
            if (brandingsState.data) {
                setBrandingsState({
                    ...brandingsState,
                    data: brandingsState.data.filter(c => c.id !== id)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setBrandingsState({ ...brandingsState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setBrandingsState({ ...brandingsState, error: strings.ORGANIZATION.BRANDING.DELETED_BRANDING_ERROR });
        }
        stopDeleteFetch()
    }
    const onCreateBranding = () => {
        setBrandingState({ data: { name: '' }, fetched: true })
    }
    const onEditBranding = async (id: number) => {
        setBrandingState({ fetched: false })
        const result = await portalApi.organization.branding.getBranding(id)
        setBrandingState({ data: result.data, fetched: true, error: (result.errors && result.errors.length > 0) ? result.errors[0].description : undefined })
    }
    const columns: IColumn[] = [
        {
            key: 'column2',
            name: strings.ORGANIZATION.BRANDING.NAME,
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 700,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IBranding) => {
                return (
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <Stack verticalFill>
                            <Image src={(item.imageSrc && item.imageSrc !== '') ? item.imageSrc : '/noLogoInList.svg'} width={40} height={40} imageFit={ImageFit.cover} />
                        </Stack>
                        <Stack verticalFill verticalAlign='center'>
                            <Stack.Item style={{ fontWeight: 600 }}>{item.name}</Stack.Item>
                            <Stack.Item>{item.description}</Stack.Item>
                        </Stack>
                    </Stack>
                )
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.BRANDING.DATE,
            fieldName: 'Creation date',
            minWidth: 200,
            maxWidth: 350,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IBranding) => {
                let fulldate = undefined
                let fulltime = undefined
                if (item.creationDate) {
                    fulldate = new Date(item.creationDate).toLocaleDateString().split(".").join('/')
                    fulltime = [new Date(item.creationDate).getHours(), new Date(item.creationDate).getMinutes()].join(':')
                }

                return (
                    <Stack horizontal verticalFill verticalAlign='center' tokens={{ childrenGap: 6 }}>
                        <Stack.Item>{fulldate}</Stack.Item>
                        <Stack.Item>{fulltime}</Stack.Item>
                    </Stack>
                )
            },
            isPadded: true,
        },
        {
            key: 'column5',
            name: '',
            fieldName: 'name',
            minWidth: 40,
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IBranding) => {
                return (
                    <Stack horizontal verticalFill verticalAlign='center' horizontalAlign="end">
                        <Stack.Item>
                            {!item.removed && <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles}
                                menuProps={getItemMenuProps(item, setDeleteBrandingState)}
                            />}
                        </Stack.Item>
                    </Stack>
                )
            },
            isPadded: true,
        },
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => onEditBranding(props.item.id)}><DetailsRow {...props} styles={customStyles} /></Stack>;
        }
        return null;
    };
    const getItemMenuProps = (item: IBranding, onDelete: (item: IBranding) => void): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? onEditBranding(item.id) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? onDelete(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }
    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }

        return (
            <Stack>
                <Stack.Item>
                </Stack.Item>
                {defaultRender!({ ...hProps })}
            </Stack>
        );
    }
    return (
        <Stack verticalFill>
            {!brandingState && (
                brandingsState?.fetched && (
                    brandingsState?.data && brandingsState.data.length > 0 && (
                        <>
                            <Stack.Item>
                                <ScreenHeader title={strings.ORGANIZATION.BRANDING.BRANDING_TITLE} description={strings.ORGANIZATION.BRANDING.BRANDING_DESCRIPTION}>
                                    <PrimaryButton text={strings.ORGANIZATION.BRANDING.BRANDING_SET.CREATE_BUTTON} onClick={() => onCreateBranding()} />
                                </ScreenHeader>
                            </Stack.Item>
                            <Stack verticalFill style={{ position: 'relative' }}>
                                <ScrollablePane>
                                    <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                        <Section max>
                                            <DetailsList
                                                styles={{ root: { width: '100%' } }}
                                                onRenderDetailsHeader={onRenderDetailsHeader}
                                                items={brandingsState.data}
                                                columns={columns}
                                                // setKey="none"
                                                selectionMode={SelectionMode.none}
                                                layoutMode={DetailsListLayoutMode.justified}
                                                // onRenderDetailsHeader={onRenderDetailsHeader}
                                                // isHeaderVisible={true}
                                                onRenderRow={onRenderRow}
                                            />
                                        </Section>
                                    </Stack.Item>
                                </ScrollablePane>
                            </Stack>
                        </>
                    ) || (
                        <ItemsNotFound
                            info={strings.ORGANIZATION.BRANDING.NOT_FOUND_CASE_INFO}
                            suggestion={strings.ORGANIZATION.BRANDING.NOT_FOUND_CASE_SUGGESTION}
                            buttonText={strings.ORGANIZATION.BRANDING.BRANDING_SET.CREATE_BUTTON}
                            onClick={onCreateBranding}
                            imgSrc="images/navigation/images/organization.png" />
                    )
                ) || (
                    <Stack verticalFill grow verticalAlign="center" horizontalAlign="center">
                        <Spinner />
                    </Stack>
                )
            ) || (
                    brandingState?.fetched && (
                        <BrandingDetails onClickSubmit={brandingState?.data?.id ? onUpdateBranding : onAddBranding} hidePanel={() => setBrandingState(undefined)} branding={brandingState?.data} defaultLogo={brandingState.data?.imageSrc ? undefined : defaultServiceIcon} />
                    ) || (
                        <Stack verticalFill grow verticalAlign="center" horizontalAlign="center">
                            <Spinner />
                        </Stack>
                    )
                )
            }
            {deleteBrandingState && (
                <Modal isOpen={deleteBrandingState ? true : false} onDismiss={() => setDeleteBrandingState(undefined)} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.BRANDING.DELETE_BRANDING_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={() => setDeleteBrandingState(undefined)}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={onBrandingDelete}
                            onCancel={() => setDeleteBrandingState(undefined)}
                            requiredMessageString={strings.ORGANIZATION.BRANDING.DELETE_BRANDING_REQUIRED}
                            confirmationCheckString={deleteBrandingState?.name}
                            placeholder={strings.ORGANIZATION.BRANDING.DELETE_BRANDING_PLACEHOLDER}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.BRANDING.DELETE_BRANDING_DESCRIPTION}</Text>
                                <Text>{strings.ORGANIZATION.BRANDING.DELETE_BRANDING_CONFIRMATION_TEXT} <b>"{deleteBrandingState.name}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>
            )}
        </Stack>
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};