import { AppThunkAction } from "..";
import { IStartTeachingStateItem } from "@piceasoft/core";

export interface UpdateItemAction { type: 'TEACHING_UPDATE_ITEM', item: IStartTeachingStateItem }
export interface ClearStateAction { type: 'TEACHING_CLEAR_STATE' }
export interface RebuildItemsAction { type: 'TEACHING_REBUILD_ITEMS', items: string[] }

export type KnownAction = UpdateItemAction | ClearStateAction | RebuildItemsAction

export const actionCreators = {
    setPassedItem: (itemName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
        }
        const localState = appState.teaching.teachingState?.filter(i => i.name !== itemName).map(i => {
            return { name: i.name, passed: i.passed }
        }) as Array<{ name: string, passed?: boolean }>
        localStorage.setItem('START_TEACHING', JSON.stringify([...localState, { name: itemName, passed: true }]))
        dispatch({
            type: 'TEACHING_UPDATE_ITEM',
            item: {
                ...appState.teaching.teachingState?.find(i => i.name === itemName) as IStartTeachingStateItem, passed: true
            }
        })
    },
    setHiddenItem: (itemName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({
                type: 'TEACHING_UPDATE_ITEM',
                item: {
                    ...appState.teaching.teachingState?.find(i => i.name === itemName) as IStartTeachingStateItem, hidden: true
                }
            })
        }
    },
    addItemId: (itemName: string, id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({
                type: 'TEACHING_UPDATE_ITEM',
                item: {
                    ...appState.teaching.teachingState?.find(i => i.name === itemName) as IStartTeachingStateItem, id: id
                }
            })
        }
    },
    clearTeachingState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            localStorage.setItem('START_TEACHING', "[]")
            dispatch({ type: 'TEACHING_CLEAR_STATE' })
        }
    },
    rebuildItems: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let workflowItems = [] as string[]
        if (appState) {
            workflowItems.push("add-item-here")
        }
        if (workflowItems.length > 0) {
            dispatch({ type: 'TEACHING_REBUILD_ITEMS', items: workflowItems })
        }
    },
};

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

export function getEnumName<E extends Enum<E>>(enumeration: E, text: string): string {
    if (Number.parseInt(text)) {
        return enumeration[Number.parseInt(text)]
    }
    if (Object.keys(enumeration).includes(text)) {
        return text
    }
    return ""
}

export function getEnumNumber<E extends Enum<E>>(enumeration: E, text: string): number {
    if(text === "0"){
        return 0
    }
    if (Number.parseInt(text)) {
        return Number.parseInt(text)
    }
    if (Object.keys(enumeration).includes(text)) {
        return Number.parseInt(Object.entries(enumeration).find((k, v) => k[0].toString() === text)?.[1] as string)
    }
    return 0
}
