import { Spinner, Stack } from '@fluentui/react';
import * as React from 'react';
import { connect } from 'react-redux';
import * as Environment from './core/actions/environment-actions';
import * as Workplace from './core/actions/workplace-actions';
import { IStore } from './core/store';
import MainRouter from './routers';

interface MapStateProps {
    isReady: boolean
    isAuthenticated: boolean
}

interface MapDispatchProps {
    requestInitialization: (lang?: string) => void
    requestWorkplace: () => void
}

interface OwnProps {
    lang?: string
    spinnerLabel: string
}

type StartupProps = MapStateProps & MapDispatchProps & OwnProps

const Startup: React.FC<StartupProps> = (props) => {

    React.useEffect(() => {
        props.requestInitialization(props.lang);
    }, [props.isReady]);

    React.useEffect(() => {
        if (props.isAuthenticated) {
            props.requestWorkplace();
        }
    }, [props.isAuthenticated]);

    if (props.isReady) return <MainRouter isAuthenticated={props.isAuthenticated} />

    return (
        <Stack verticalAlign="center" horizontalAlign="center" grow={1}>
            <Spinner
            // label={props.spinnerLabel} 
            />
        </Stack>
    )
}

export default connect<MapStateProps, MapDispatchProps, OwnProps, IStore>(
    (state: IStore): MapStateProps => ({
        isAuthenticated: state.environment.isAuthenticated,
        isReady: state.environment.isReady
    }),
    {
        requestInitialization: Environment.actionCreators.initializationRequest,
        requestWorkplace: Workplace.actionCreators.requestWorkplace
    }
)(Startup);
