import * as React from 'react'
import { FontWeights, getTheme, IconButton, mergeStyleSets, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, Stack, Text } from "@fluentui/react"
import { useBoolean } from '@uifabric/react-hooks'
import { portalApi } from '../../../core/api/api'
import { ScreenHeader } from '../../../components/shared/decorations/ScreenHeader'
import { AdministrationSupervisorsList } from './AdministrationSupervisorsList'
import { CustomPanel } from '../../../components/shared/panel/CustomPanel'
import { ConfirmationForm } from '../../../components/shared/forms/ConfirmationForm'
import { strings } from '../../../localization/strings'
import { ISupervisor } from '../../../core/store/typings/ISupervisor'
import { SupervisorForm } from '../../../components/shared/forms/SupervisorForm'

export type TSupervisorsState = { fetched: boolean, error?: string, data?: Array<ISupervisor> }

export const AdministrationSupervisors: React.FC<{}> = (props) => {

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const [searchString, setSearchString] = React.useState<string>()

    const [supervisorsState, setSupervisorsState] = React.useState<TSupervisorsState>({ fetched: false })
    const [items, setItems] = React.useState<ISupervisor[]>([])

    const [supervisor, setSupervisor] = React.useState<ISupervisor>()
    const [deleteSupervisorState, setDeleteSupervisorState] = React.useState<ISupervisor>()

    React.useEffect(() => {
        requestSupervisors()
    }, [])

    React.useEffect(() => {
        if (supervisorsState.data && supervisorsState.data !== items) {
            // console.log(supervisorsState.data)
            setItems(supervisorsState.data)
        }
    }, [supervisorsState])

    React.useEffect(() => {
        setItems(() => {
            var newItems = supervisorsState.data ? [...supervisorsState.data] : []
            if (searchString) {
                newItems = [
                    ...((newItems as ISupervisor[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.email,
                            i.name,
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as ISupervisor[]
        })
    }, [searchString])

    const requestSupervisors = async () => {
        setSupervisorsState({ fetched: false })
        const result = await portalApi.organization.supervisors.getSupervisors()
        setSupervisorsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
    }

    const onCancel = () => {
        hidePanel()
        setTimeout(() => setSupervisor(undefined), 1000)
    }

    const onAddSupervisor = () => {
        stopFetch()
        setFormError(undefined)
        showPanel()
    }

    const addSupervisor = async (item: ISupervisor) => {
        startFetch()
        const result = await portalApi.organization.supervisors.addSupervisor(item)
        if (result.successed && result.data) {
            console.log(result)
            setSupervisorsState({ ...supervisorsState, data: supervisorsState.data ? [...supervisorsState.data, result.data] : [result.data] })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onEditSupervisor = (item: ISupervisor) => {
        stopFetch()
        setFormError(undefined)
        setSupervisor(item)
        showPanel()
    }

    const updateSupervisor = async (item: ISupervisor) => {
        startFetch()
        const result = await portalApi.organization.supervisors.updateSupervisor(item)
        if (result.successed && result.data) {
            console.log(result)
            setSupervisorsState({
                ...supervisorsState, data: supervisorsState.data && [...supervisorsState.data.map(p => {
                    if (result.data && p.id === result.data?.id) {
                        return result.data
                    }
                    return p
                })]
            })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onDeleteSupervisor = () => {
        if (deleteSupervisorState?.id) {
            deleteSupervisor(deleteSupervisorState.id)
            setDeleteSupervisorState(undefined)
        }
    }

    const deleteSupervisor = async (supervisorId: string) => {
        console.log('deletePerson ' + supervisorId)
        setSupervisorsState({ ...supervisorsState, error: undefined })
        const result = await portalApi.organization.supervisors.deleteSupervisor(supervisorId)
        if (result.successed) {
            if (supervisorsState.data) {
                setSupervisorsState({
                    ...supervisorsState,
                    data: supervisorsState.data.filter(c => c.id !== supervisorId)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setSupervisorsState({ ...supervisorsState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setSupervisorsState({ ...supervisorsState, error: "There is a problem! Supervisor wasn't deleted! Server response false without an error" });
        }
    }

    const onSubmitHandler = (data: ISupervisor) => {
        setFormError(undefined)
        supervisor?.id ? updateSupervisor(data) : addSupervisor(data)
    }

    return (
        <>
            <Stack verticalFill>
                <ScreenHeader title={strings.ORGANIZATION.SUPERVISORS.TITLE}>
                    {supervisorsState.data && supervisorsState.data.length > 0 && <PrimaryButton onClick={onAddSupervisor} text={strings.ORGANIZATION.SUPERVISORS.NOT_FOUND_CASE.ADD_BUTTON} />}
                </ScreenHeader>
                <Stack.Item verticalFill>
                    <Stack verticalFill tokens={{ childrenGap: 16 }} style={{ position: 'relative' }}>
                        <AdministrationSupervisorsList
                            items={items}
                            onAdd={onAddSupervisor}
                            onEdit={onEditSupervisor}
                            supervisorsState={supervisorsState}
                            requestSupervisors={requestSupervisors}
                            setDeleteSupervisorState={setDeleteSupervisorState}
                            setSearchString={setSearchString}
                        />
                    </Stack>
                </Stack.Item>
            </Stack>
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                noCancelOnDissmiss={true}
                title={supervisor?.id ? strings.ORGANIZATION.SUPERVISORS.EDIT_SUPERVISOR : strings.ORGANIZATION.SUPERVISORS.ADD_SUPERVISOR}
            >
                <Stack verticalFill>
                    {formError && (
                        <MessageBar messageBarType={MessageBarType.error}>
                            {formError}
                        </MessageBar>
                    )}
                    {(isPanelOpen && !fetching) && (
                        <SupervisorForm
                            onSubmit={onSubmitHandler}
                            onCancel={onCancel}
                            data={supervisor}
                        />
                    ) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        )}
                </Stack>
            </CustomPanel>
            {deleteSupervisorState && (
                <Modal isOpen={deleteSupervisorState ? true : false} onDismiss={() => setDeleteSupervisorState(undefined)} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SUPERVISORS.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={() => setDeleteSupervisorState(undefined)}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={onDeleteSupervisor}
                            onCancel={() => setDeleteSupervisorState(undefined)}
                            requiredMessageString={strings.ORGANIZATION.RESPONSIBLE_PERSONS.EMAIL_VALID_REQUIRED_MESSAGE}
                            confirmationCheckString={deleteSupervisorState?.email as string}
                            placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.EMAIL_PLACEHOLDER}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.SUPERVISORS.REMOVE_CONFIRMATION_TEXT1} <b>"{deleteSupervisorState.name}"</b>.</Text>
                                <Text>{strings.ORGANIZATION.SUPERVISORS.REMOVE_CONFIRMATION_TEXT2}</Text>
                                <Text unselectable="on">{strings.ORGANIZATION.SUPERVISORS.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{deleteSupervisorState.email}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>
            )}
        </>
    )
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
