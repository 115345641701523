import {
    ConstrainMode,
    DefaultButton, DetailsList, DetailsListLayoutMode,
    DirectionalHint,
    Dropdown, FontIcon, getFocusStyle, getTheme, IColumn,
    IComboBoxOption, Icon, IDetailsHeaderProps,
    IDropdownOption, IRenderFunction, ISelectableOption, Link, mergeStyleSets,
    MessageBar,
    MessageBarType,
    ScrollablePane, SelectionMode, SharedColors,
    Spinner, Stack, Sticky, Text, TooltipHost
} from "@fluentui/react"
import { useId } from "@uifabric/react-hooks"
import React from "react"
import { CatalogVersionStates, ICatalog, ICatalogItem } from "../../../../core/store"
import { strings } from "../../../../localization/strings"
import { Section } from "../../../../components/shared/decorations/Section"
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from "../../../../components/shared/search/SuggestedSearch"
import { escapeRegExp } from "../../../../assets/ts/utils/regex"
import { ICatalogVersionsState } from "../FinanceCatalog"
import { SimpleCustomFilter } from "../../../../components/shared/filter/SimpleCustomFilter"
import { portalApi } from "../../../../core/api/api"
import { ItemsNotFound } from "../../../../components/shared/notFound/ItemsNotFound"
import { onRenderColumnHeaderCommon } from "../../../../components/shared/detailsList/commonRenders"
import { SomethingWentWrong } from "../../../../components/shared/somethingWentWrong/SomethingWentWrong"
import { CSHHelpLink } from "../../../../components/shared/help/CSHHelp"
import { CatalogTypes } from "@piceasoft/core"

type TFinanceCatalogPricesPivotProps = {
    data: ICatalog
    versionsState: ICatalogVersionsState
    onImport: () => void
    setSelectedVersionId: (id?: string) => void
}

export const FinanceCatalogPricesPivot: React.FC<TFinanceCatalogPricesPivotProps> = (props) => {

    const [versionsOptions, setVersionsOptions] = React.useState<IDropdownOption[]>()

    const versionsHostId = useId("versions-host-id")
    const versionsTextId = useId("versions-text-id")
    const versionsCalloutListId = useId("versions-callout-id")

    const [filterValue, setFilterValue] = React.useState<string>()

    const [searchString, setSearchString] = React.useState<string>()

    const [selectedVersionOption, setSelectedVersionOption] = React.useState<IComboBoxOption>()

    const [itemsState, setItemsState] = React.useState<{ fetched: boolean, data?: ICatalogItem[], error?: string }>({ fetched: false });

    const [items, setItems] = React.useState<ICatalogItem[]>()

    const [columns, setColumns] = React.useState<IColumn[]>([])

    React.useEffect(() => {
        prepareVersions()
    }, [])

    React.useEffect(() => {
        if (versionsOptions && versionsOptions.length > 0) {
            let newSelectedVersionId: string | undefined

            if (props.data.currentVersionDate) {
                newSelectedVersionId = props.versionsState.data?.find(i => i.fromDate?.toString() === props.data.currentVersionDate?.toString())?.id
            }
            if (!newSelectedVersionId) {
                newSelectedVersionId = versionsOptions[0].key as string
            }
            props.setSelectedVersionId(newSelectedVersionId)
            setSelectedVersionOption(versionsOptions.find(i => i.key === newSelectedVersionId))
        }
    }, [versionsOptions])

    React.useEffect(() => {
        setItemsState({ fetched: false })
        getItems()
    }, [selectedVersionOption])

    React.useEffect(() => {
        prepareVersions()
    }, [props.versionsState.data])

    React.useEffect(() => {
        setItems(() => {
            var newItems = itemsState.data ? [...itemsState.data] : []
            if (searchString) {
                newItems = [
                    ...((newItems as ICatalogItem[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.group,
                            i.manufacturer,
                            i.name,
                            i.configuration,
                            i.summary,
                            i.sku
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                let escapedSearchString = searchString ? searchString.replace(/[\[\]()]/g, '\\$&') : '';
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${escapedSearchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as ICatalogItem[]
        })
    }, [searchString])

    React.useEffect(() => {
        setItems(undefined)
        if (itemsState.data) {
            let newColumns: IColumn[] = []
            if (itemsState.data.find(i => i.group)) {
                newColumns.push(groupColumn)
            }
            newColumns.push(nameColumn)
            newColumns.push(...getGradeColumns())
            if (itemsState.data?.find(i => i.sku && i.sku.length > 0)) {
                newColumns.push(skuColumn)
            }
            setItems([...itemsState.data])
            setColumns(newColumns)
        }
    }, [itemsState.data])

    const getCatalogVersionState = (date?: Date): CatalogVersionStates | undefined => {
        const dateTime = date && new Date(date).getTime()
        const catalogDate = props.data.currentVersionDate && new Date(props.data.currentVersionDate).getTime()
        if (catalogDate && dateTime) {
            console.log(catalogDate && dateTime)
            if (dateTime === catalogDate) return CatalogVersionStates.Actual
            if (dateTime > catalogDate) return CatalogVersionStates.Planned
            if (dateTime < catalogDate) return CatalogVersionStates.Outdated
        }
        return undefined
    }

    const prepareVersions = () => {
        setVersionsOptions(undefined)
        if (props.versionsState.data) {
            setVersionsOptions(props.versionsState.data.map(i => {
                return { key: i.id, text: i.fromDate ? new Date((i.fromDate)).toLocaleString() : "", title: i.summary ?? "" } as IComboBoxOption
            }))
        }
    }

    const getItems = async () => {
        if (props.data.id && selectedVersionOption?.key) {
            const result = await portalApi.organization.catalogs.getCatalogVersionItems(props.data.id, selectedVersionOption.key.toString())
            console.log(result)
            setItemsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
        }
    }
    const getPrice =(item:ICatalogItem, col:IColumn)=>{
        let itemDetails = item.prices.find(i => {
            if (!i.grade || !col.fieldName) return false;
            return (i.grade === col.fieldName?.substring(col.fieldName?.length - i.grade?.length))
        });

        if(itemDetails === undefined || itemDetails?.price === null && itemDetails?.minPrice === null){
            return "";
        }

        if(itemDetails?.minPrice !== null)
        return `${itemDetails?.minPrice} - ${itemDetails?.price}`;

        return itemDetails?.price ?? "";
    }

    const getMaxWidth=(columnName:string)=>{
          if(columnName.length>6){
            return 40+columnName.length*6;
          }
          else{
            return 44;
          }
    }

    const getGradeColumns = (): IColumn[] => {
        var gradesSet = new Set();
        if (itemsState.data) {
            itemsState.data.forEach(i => {
                i.prices.forEach(p => gradesSet.add(p.grade))
            });
        }
        let grades: IColumn[] = []

        gradesSet.forEach(i => {
            if (i) {
                let maxWidth = getMaxWidth(i as string);
                grades.push({
                    key: `column-grade-${i}`,
                    name: i as string,
                    fieldName: `column-grade-${i}`,
                    minWidth: 40,
                    maxWidth: maxWidth,
                    isResizable: false,
                    data: 'string',
                    onRenderHeader: (props) => <Stack style={{ minWidth: 40, maxWidth: maxWidth }} horizontal grow horizontalAlign='center'><Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{props?.column.name}</Text></Stack>,
                    onRender: (item: ICatalogItem, i, col) => col && <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                        <Text style={{ color: SharedColors.orangeYellow20 }}>
                            {getPrice(item, col)}
                        </Text>
                    </Stack>,
                    isPadded: true,
                })
            }
        })
        return grades
    }

    const groupColumn: IColumn = {
        key: 'group',
        name: strings.ORGANIZATION.CATALOGS.CATALOG.TABLE_COLUMNS.GROUP,
        fieldName: 'group',
        minWidth: 40,
        maxWidth: 60,
        isCollapsible: true,
        isResizable: true,
        data: 'string',
        styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
        onRenderHeader: onRenderColumnHeaderCommon,
        onRender: (item: ICatalogItem) => <Stack verticalFill verticalAlign="center"><Text variant="small" style={{ color: SharedColors.gray30 }} >{item.group}</Text></Stack>,
        isPadded: true,
    }

    const skuColumn: IColumn = {
        key: 'sku',
        name: strings.ORGANIZATION.CATALOGS.CATALOG.TABLE_COLUMNS.SKU,
        fieldName: 'sku',
        minWidth: 120,
        isResizable: false,
        isCollapsible: true,
        data: 'string',
        styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
        onRenderHeader: onRenderColumnHeaderCommon,
        onRender: (item: ICatalogItem) => <Stack verticalFill verticalAlign="center"><Text style={{ color: SharedColors.magentaPink20 }}>{item.sku}</Text></Stack>,
        isPadded: true,
    }

    const nameColumn: IColumn = {
        key: 'name',
        name: strings.ORGANIZATION.CATALOGS.CATALOG.TABLE_COLUMNS.NAME,
        fieldName: 'name',
        minWidth: 180,
        isResizable: true,
        data: 'string',
        styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
        onRenderHeader: onRenderColumnHeaderCommon,
        onRender: (item: ICatalogItem) => (
            <Stack horizontal tokens={{ childrenGap: 16 }} verticalFill verticalAlign="center" style={{ overflow: "hidden" }}>
                {item.summary && (
                    <TooltipHost calloutProps={{ directionalHint: DirectionalHint.leftCenter, gapSpace: 4 }} content={item.summary}>
                        <FontIcon iconName={"Info"} />
                    </TooltipHost>
                )}
                <Text block nowrap style={{ maxWidth: "100%", color: SharedColors.cyanBlue20 }}>{item.manufacturer} {item.name} {item.configuration === "*" ? <Text style={{ color: SharedColors.gray20 }}>{item.configuration}</Text> : <Text variant = 'small' style={{ color: SharedColors.gray20 }}>{item.configuration}</Text>}</Text>
            </Stack>
        ),
        isPadded: true,
    }


    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: "0px 6px",
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderVersionSuggestionsCell = (item?: IComboBoxOption, index?: number) => {

        const state = getCatalogVersionState(props.versionsState.data?.find(i => i.id === item?.key)?.fromDate ?? undefined)

        return (
            <div style={{ cursor: 'pointer' }} className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${versionsCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, versionsOptions?.length ?? 0, versionsTextId, versionsCalloutListId, () => versionOptionSelectHandler(item), index, item?.text, versionsHostId)}
                tabIndex={-1}
                onClick={() => {
                    versionOptionSelectHandler(item)
                    focusElement(`${versionsCalloutListId}-${index as number}`, versionsHostId)
                }}
            >
                <Stack horizontal verticalAlign="center" grow tokens={{ padding: 2, childrenGap: 18 }} style={{ height: 40 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack grow tokens={{ padding: 0, childrenGap: 2 }}>
                        <Text block nowrap variant="medium" style={{
                            color: state === CatalogVersionStates.Outdated ? SharedColors.gray10 : SharedColors.gray40, lineHeight: 1.2
                        }}>{item?.text}</Text>
                        <Text block nowrap variant="tiny" style={{ color: SharedColors.gray10, lineHeight: 1.2 }}>{item?.title}</Text>
                    </Stack>
                    {state === CatalogVersionStates.Actual && (
                        <Icon style={{ fontSize: 18, color: SharedColors.green10 }} iconName={"ReminderTime"} />
                    )}
                </Stack>
            </div >
        );
    }

    const onRenderVersionDropdownItem: IRenderFunction<ISelectableOption<IComboBoxOption>> = (item) => {

        const state = getCatalogVersionState(props.versionsState.data?.find(i => i.id === item?.key)?.fromDate ?? undefined)

        return (
            <Stack horizontal verticalAlign="center" grow tokens={{ padding: "2px", childrenGap: 18 }} style={{ height: 38 }}>
                <Stack grow tokens={{ padding: 0, childrenGap: 2 }}>
                    <Text block nowrap variant="medium" style={{
                        color: state === CatalogVersionStates.Outdated ? SharedColors.gray10 : SharedColors.gray40, lineHeight: 1.2
                    }}>{item?.text}</Text>
                    <Text block nowrap variant="tiny" style={{ color: SharedColors.gray10, lineHeight: 1.2 }}>{item?.title}</Text>
                </Stack>
                {state === CatalogVersionStates.Actual && (
                    <Icon style={{ fontSize: 18, color: SharedColors.green10 }} iconName={"ReminderTime"} />
                )}
            </Stack>
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, title: i.title, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    const versionOptionSelectHandler = (item?: IComboBoxOption) => {
        props.setSelectedVersionId(item?.key.toString())
        setSelectedVersionOption(item)
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }

        return (
            <Sticky>
                <Stack>
                    <Stack.Item>
                        <Stack tokens={{ padding: "16px 32px 4px 20px", childrenGap: 12 }} horizontal>
                            <Stack horizontal tokens={{ childrenGap: 4 }}>
                                <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                                <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                                    <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{itemsState.data?.length ?? 0}</Text>
                                </Stack>
                            </Stack>
                            {searchString && searchString.length > 0 && (
                                <Stack horizontal tokens={{ childrenGap: 4 }}>
                                    <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.FILTERED}</Text>
                                    <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                                        <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{items?.length ?? 0}</Text>
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </Stack.Item>
                    {defaultRender!({ ...hProps })}
                </Stack>
            </Sticky>
        );
    }

    return (
        <Stack verticalFill tokens={{ padding: "24px 0px 0px 0px" }}>
            {!itemsState.error && itemsState.fetched && (
                <Stack.Item>
                    <Stack horizontal horizontalAlign='space-between' verticalAlign="end" tokens={{ padding: "0px 32px 24px 32px" }}>
                        {(versionsOptions?.length ?? 0) <= 10 && (
                            <Dropdown
                                options={versionsOptions ?? []}
                                onChange={(ev, opt) => versionOptionSelectHandler(opt)}
                                onRenderOption={onRenderVersionDropdownItem}
                                disabled={!versionsOptions || versionsOptions.length === 0}
                                styles={{
                                    dropdown: { width: 250 },
                                    dropdownItem: { height: 40, padding: "0px 6px" },
                                    dropdownItemSelected: { height: 40, padding: "0px 6px" },
                                    dropdownItemSelectedAndDisabled: { height: 40, padding: "0px 6px" }
                                }}
                                selectedKey={selectedVersionOption?.key.toString()}
                                placeholder={(!props.versionsState.fetched) ?
                                    strings.SPINNERS.DATA_IS_GETTING :
                                    versionsOptions?.find(i => i.key === selectedVersionOption?.key)?.text ?? (versionsOptions && versionsOptions.length > 0 ?
                                        strings.ORGANIZATION.CATALOGS.VERSIONS.VERSIONS_PLACEHOLDER :
                                        strings.ORGANIZATION.CATALOGS.VERSIONS.NO_VERSIONS)}
                            />
                        ) || (
                                <SuggestedSearch
                                    type="dropdown-search"
                                    inputBoxId={versionsTextId}
                                    hostId={versionsHostId}
                                    label={strings.ORGANIZATION.CATALOGS.VERSIONS.TITLE}
                                    inputBoxPlaceholder={(!props.versionsState.fetched) ?
                                        strings.SPINNERS.DATA_IS_GETTING :
                                        versionsOptions?.find(i => i.key === selectedVersionOption?.key)?.text ?? (versionsOptions && versionsOptions.length > 0 ?
                                            strings.ORGANIZATION.CATALOGS.VERSIONS.VERSIONS_PLACEHOLDER :
                                            strings.ORGANIZATION.CATALOGS.VERSIONS.NO_VERSIONS)}
                                    onClickSuggestionCell={versionOptionSelectHandler}
                                    // setSearchRequest={(opt: IComboBoxOption) => setFilterValue(opt?.text)}
                                    searchRequest={filterValue}
                                    suggestionsListWidth={250}
                                    inputBoxWidth={250}
                                    disabled={!versionsOptions || versionsOptions.length === 0}
                                    suggestions={versionsOptions ?? []}
                                    suggestionsListId={versionsCalloutListId}
                                    isSearchEqualTheOneSuggestion={false}
                                    onRenderSuggestionCell={onRenderVersionSuggestionsCell}
                                    prepareFilteredItems={prepareFilteredOptions}
                                    selectedKey={selectedVersionOption?.key.toString()}
                                />
                            )}
                        <SimpleCustomFilter onChange={(ev, value) => setSearchString(value)} />
                    </Stack>
                </Stack.Item>
            )}
            <Stack.Item verticalFill>
                <Section verticalFill flat max tokens={{ padding: "0px" }} grow>
                    {!itemsState.error && props.versionsState.fetched && ((props.versionsState.data && props.versionsState?.data?.length > 0 && (
                        <Stack verticalFill tokens={{ childrenGap: 16 }}>
                            {items && (
                                <Stack.Item verticalFill style={{ position: 'relative' }}>
                                    <ScrollablePane>
                                        <DetailsList
                                            styles={{ root: { display: "flex" }, contentWrapper: { display: "flex" } }}
                                            items={items ?? []}
                                            columns={columns}
                                            setKey="none"
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                            onRenderDetailsHeader={onRenderDetailsHeader}
                                            isHeaderVisible={true}
                                            constrainMode={ConstrainMode.unconstrained}
                                        />
                                    </ScrollablePane>
                                </Stack.Item>
                            ) || (
                                    <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                        <Spinner label={strings.SPINNERS.DATA_PROCESSING} />
                                    </Stack>
                                )}
                        </Stack>
                    ) || (
                            <Section flat>
                                <Section backgroundBlue>
                               <Stack tokens={{ childrenGap: 8 }}>
                               <Stack.Item>
                                <br></br>
                                <Stack horizontal grow horizontalAlign={"start"} style={{
                                    flexDirection: "row",
                                    alignItems: "end",
                                    marginLeft: 'auto',
                                    marginRight: '10px',
                                    fontSize: 14,
                                }}>
                                    <Icon iconName="Info" styles={{ root: { marginRight: 5 } }}/>
                                    <Text variant="large" style={{ color: theme.palette.black, fontWeight: 500,}}>{strings.ORGANIZATION.CATALOGS.INSTRUCTION.HEADER}</Text>
                                </Stack>
                                <Text>
                                   <ol>
                                     <li dangerouslySetInnerHTML={{ __html: instructionTextLine1(props.data.catalogType) }}></li>
                                     <li>{ instructionTextLine2(props.data.catalogType) }</li>
                                     <li>{ instructionTextLine3(props.data.catalogType) }</li>
                                     <br />
                                     <CSHHelpLink alignment='left' label={strings.ORGANIZATION.CATALOGS.INSTRUCTION.LEARN.MORE} />
                                     
                                   </ol>
                                </Text>
                               </Stack.Item>
                            </Stack>
                            </Section>
                           </Section> 
                        )
                    ) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        ))}
                    {itemsState.error && (
                        <Stack verticalFill>
                            {itemsState.error.length > 0 && (
                                <MessageBar messageBarType={MessageBarType.error}>{itemsState.error}</MessageBar>
                            )}
                            <SomethingWentWrong action={getItems} />
                        </Stack>
                    )}
                </Section>
            </Stack.Item>
        </Stack>
    )
}

const instructionTextLine1 = (catalogType: number) => {
    switch (catalogType) {
        case CatalogTypes.Tradein: return strings.ORGANIZATION.CATALOGS.INSTRUCTION.TRADE_IN.ONE;
        case CatalogTypes.GradeDiscount: return strings.ORGANIZATION.CATALOGS.INSTRUCTION.GRADE_DISCOUNT.ONE;
        default: return strings.ORGANIZATION.CATALOGS.INSTRUCTION.ONE;
    }
}
const instructionTextLine2 = (catalogType: number) => {
    switch (catalogType) {
        case CatalogTypes.GradeDiscount: return strings.ORGANIZATION.CATALOGS.INSTRUCTION.GRADE_DISCOUNT.TWO;
        default: return strings.ORGANIZATION.CATALOGS.INSTRUCTION.TWO;
    }
}
const instructionTextLine3 = (catalogType: number) => {
    switch (catalogType) {
        case CatalogTypes.GradeDiscount: return strings.ORGANIZATION.CATALOGS.INSTRUCTION.GRADE_DISCOUNT.THREE;
        default: return strings.ORGANIZATION.CATALOGS.INSTRUCTION.THREE;
    }
}

const theme = getTheme();
