import {definitions, IDiagnosticCase} from "@piceasoft/core";
import {Icon, Stack, Text} from "@fluentui/react";
import {styles} from "./styles";
import {strings} from "../../../../../../localization/strings";
import {Badge} from "../../../../decorations/Badge";
import {CSHHelpLink} from "../../../../help/CSHHelp";
import {connectionTypes} from "../../../../../../core/helpers/self-service";

export const onRenderCaseInfo = (item?: IDiagnosticCase): JSX.Element | undefined => {
    if (item) {
        const imageString = definitions.diagnostic.getImage(item.id)
        let base64icon = ''
        if (imageString) {
            base64icon = btoa(imageString)
        }
        return (
            <Stack key={item.id} tokens={{padding: "10px 14px"}} className={styles.callout}>
                <Stack.Item>
                    <Stack horizontal grow tokens={{childrenGap: 16}}>
                        <Stack.Item grow>
                            <Text block variant="xLarge" className={styles.title}>
                                {item.getName()}
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            {imageString && (
                                <img alt='image' style={{height: 24, width: 24}}
                                     src={`data:image/svg+xml;base64,${base64icon}`}/>
                            ) || (
                                <Icon style={{fontSize: 16}} iconName='TestBeakerSolid'/>
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text block variant="small" className={styles.description}>
                        {item.getDescription()}
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text block variant="small" className={styles.supported}>
                        {strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.CONTEXTUAL_MENU.TOOLTIP_MENU.SUPPORTING}
                    </Text>
                    <Stack tokens={{childrenGap: 4}}>
                        {item.platforms.map((p, index) => (
                            <Stack.Item key={index}>
                                <Stack horizontal grow>
                                    <Stack horizontal grow tokens={{childrenGap: 8}}>
                                        <Text variant="small" className={styles.platform}>{p.name}</Text>
                                    </Stack>
                                    <Stack.Item>
                                        <Stack horizontal tokens={{childrenGap: 4}}>
                                            {p.connectionTypes
                                                .filter((ct) => connectionTypes.includes(ct)).map((ct, index) => (
                                                    <Badge key={index} title={ct}/>
                                                ))}
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        ))}
                    </Stack>
                </Stack.Item>
                <Stack.Item className={styles.link}>
                    <CSHHelpLink articleid="1135" alignment='left'
                                 label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.CONTEXTUAL_MENU.TOOLTIP_MENU.LINK}/>
                </Stack.Item>

            </Stack>
        )
    }
}
