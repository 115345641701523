import { Stack, Text, Image, SharedColors, IconButton } from '@fluentui/react';
import * as React from 'react';
import { IOrganization } from "@piceasoft/core";

type TProps = {
    item: IOrganization
    onDelete: (id: string) => void
}

export const SupervisorOrganizationItem: React.FC<TProps> = (props) => {
    return (
        <Stack styles={{
            root: {
                padding: "4px 16px", "&:hover": {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(0, 0, 0, 0.06)'
                }
            }
        }} verticalAlign='center' horizontal grow tokens={{ padding: 4, childrenGap: 18 }}>
            <Stack verticalAlign='center' style={{ height: 48, minWidth: 150 }}>
                <Image height={24} src={(props.item as IOrganization)?.logo ?? "/favicon.png"} />
            </Stack>
            <Stack grow tokens={{ padding: 2 }}>
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray20 }}>{props.item?.name}</Text>
                <Text block nowrap variant="medium" style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{props.item?.domain}</Text>
            </Stack>
            <IconButton iconProps={{ iconName: "Delete" }} onClick={() => props.onDelete(props.item.id)} />
        </Stack>
    )
}
