import { getTheme, Stack } from "@fluentui/react"
import { IStageHeaderProps, StageHeader } from "./StageHeader";

export type TStageSettingsButtonProps = {
    tooltipContent?: string
    iconName?: string
    onClick?: () => void
    hideButton?: boolean
}

export const StageBox: React.FC<IStageHeaderProps> = (props) => {
    return (
        <Stack grow tokens={{ childrenGap: 20 }} styles={{
            root: {
                width: props.width ?? 400,
                minWidth: props.width ?? 400,
                maxWidth: props.width ?? 400,
                border: "1px dashed",
                borderColor: theme.palette.neutralTertiaryAlt,
                padding: "20px 20px 30px 20px"
            }
        }}>
            <Stack.Item>
                <StageHeader {...props} />
            </Stack.Item>
            <Stack.Item>
                {props.children}
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
