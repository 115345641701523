import { getTheme, Stack } from "@fluentui/react"
import React from "react";
import { strings } from "../../../../../localization/strings";
import { MdHelp } from "../../../../../components/shared/mdHelp/MdHelp";

export type TCompanyHelpPivotProps = {
    information?: string
    setInformation: (information: string) => void
    disabled?: boolean
    isFetching: boolean
    resetInfoWasSaved: () => void
    infoWasSaved: boolean
}

export const ServiceConfigurationHelpPivot: React.FC<TCompanyHelpPivotProps> = (props) => {

    const [mdState, setMdState] = React.useState(props.information ?? "");

    const getHelp = async () => {
        setMdState(props.information ?? "")
    }

    const setHelp = async () => {
        props.setInformation(mdState)
    }

    return (
        <Stack verticalFill tokens={{ padding: "24px 0px 0px 0px", childrenGap: 8 }}>
            <Stack verticalFill tokens={{ childrenGap: 12 }}>
                <Stack.Item verticalFill>
                    <MdHelp
                        disabled={props.disabled}
                        value={mdState} 
                        getHelp={getHelp}
                        setHelp={setHelp} setValue={(value) => setMdState(value ?? "")}
                        headerText={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.HELP.HEADER}
                        isFetching={props.isFetching}
                        noChanges={(props.information === mdState)}
                        wasSaved={props.infoWasSaved}
                        resetWasSaved={props.resetInfoWasSaved}
                    />
                </Stack.Item>
            </Stack>
            {/* <Stack.Item>
                <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        <Text style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.HELP.INFO}</Text>
                        <br />
                        <Link>{strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.HELP.INFO_LINK_TEXT}</Link>
                    </Stack.Item>
                </Stack>
            </Stack.Item> */}
        </Stack>
    )
}

const theme = getTheme();
