import { DefaultButton, DirectionalHint, FontWeights, getTheme, IconButton, IContextualMenuItem, IContextualMenuProps, IStackStyles, mergeStyleSets, MessageBar, MessageBarType, Modal, PrimaryButton, ScrollablePane, Spinner, Stack, Sticky, StickyPositionType, Text, TextField, TooltipHost } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getModerationColor } from '../../../components/helpers/transaction';
import { CopyToClipBoardButton } from '../../../components/shared/buttons/CopyToClipboardButton';
import { DangerButton } from '../../../components/shared/buttons/DangerButton';
import { MyMessage } from '../../../components/shared/messages/MyMessage';
import { ItemsNotFound } from '../../../components/shared/notFound/ItemsNotFound';
import { SliderImage } from '../../../components/shared/SliderImage/SilderImage';
import { SomethingWentWrong } from '../../../components/shared/somethingWentWrong/SomethingWentWrong';
import { portalApi } from '../../../core/api/api';
import { IModerationResult, IModerationResultLog, IStore } from '../../../core/store';
import { IGradesCategory } from '@piceasoft/core';
import { getLocalizedModerationStatus } from '../../../localization/helpers/transaction';
import { strings } from '../../../localization/strings';
import { ModerationStatuses, Inspections } from "@piceasoft/core";
type TProps = {
    moderationLog: IModerationResultLog
    onHideModal: () => void
    updateModerationItem: (item: IModerationResultLog) => void
}

enum ModeratorActions {
    request = "request",
    decline = "decline",
    accept = "accept"
}

export const ModerationResult: React.FC<TProps> = ({ moderationLog, onHideModal, updateModerationItem }) => {
    const [moderationState, setModerationState] = React.useState<{ fetched: boolean, data?: IModerationResult }>({ fetched: false })
    const [transactionNumber, setTransactionNumber] = React.useState<string>()
    const [selectedGrade, setSelectedGrade] = React.useState<string>()
    const [error, setError] = React.useState<string>()
    const [warning, setWarning] = React.useState<string>()
    const [moderatorMessage, setModeratorMessage] = React.useState<string>()
    const [multiline, setMultiline] = React.useState(false);
    const offset = useSelector((s: IStore) => s.workplace.organization?.timezone)
    const userEmail = useSelector((s: IStore) => s.workplace.user?.email)

    const [moderatorAction, setModeratorAction] = React.useState<ModeratorActions>()

    const onChangeTextField = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void => {
        setMultiline(newText ? newText.length > 44 : false)
        setModeratorMessage(newText)
    };
    const [menuProps, setMenuProps] = React.useState<IContextualMenuProps>()

    const isModerationResponsible = true

    React.useEffect(() => {
        selectedGrade && setModeratorAction(ModeratorActions.accept)
    }, [selectedGrade])

    React.useEffect(() => {
        getModeration()
    }, [moderationLog])

    React.useEffect(() => {
        if (!moderationState.data?.workflow?.useGradesCategories && moderationState.data?.workflow?.grades && moderationState.data?.workflow?.grades.length > 0) {
            setMenuProps({
                items: moderationState.data.workflow.grades.map(g => ({
                    onClick: () => setSelectedGrade(g.code),
                    key: g.code,
                    text: g.name
                } as IContextualMenuItem)), useTargetWidth: true
            })
        }
        if (moderationState.data?.workflow?.useGradesCategories
            && moderationState.data?.workflow?.gradesCategories
            && moderationState.data?.workflow?.gradesCategories.length > 0
            && moderationState.data.workflow.assessment?.modules.find(i => i.type === Inspections.Photographic)) {
            setMenuProps({
                items: (moderationState.data.workflow.gradesCategories.find(i => moderationState.data?.workflow?.assessment?.modules.find(m => m.type === Inspections.Photographic)?.config.gradesCategory === i.code) as IGradesCategory).grades.map(g => ({
                    onClick: () => setSelectedGrade(g.code),
                    key: g.code,
                    text: g.name
                } as IContextualMenuItem)), useTargetWidth: true
            })
        }
    }, [moderationState])

    const getModeration = async () => {
        setModerationState({ fetched: false })
        const result = await portalApi.overview.getModeration(moderationLog.uid)
        console.log(result)
        result.data?.messages?.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime())
        if (result.errors && result.errors.length > 0) {
            setError(result.errors[0].description);
        }
        if (!transactionNumber && result.data?.number) {
            setTransactionNumber(result.data?.number)
        }
        setModerationState({ fetched: true, data: result.data })
    }

    const onActionSuccess = async (callback: () => void) => {
        const sendNotifyResult = await portalApi.overview.moderation.notify(moderationState.data?.uid as string)
        if (sendNotifyResult.errors && sendNotifyResult.errors.length > 0) {
            setError(sendNotifyResult.errors[0].description);
        }
        if (sendNotifyResult.successed) {
            callback()
        }
    }

    const onConfirmationSuccess = () => {
        setError(undefined);
        setWarning(undefined);
        setModeratorAction(undefined)
        refreshModerationLog()
    }

    const refreshModerationLog = async () => {
        const result = await portalApi.overview.getModeration(moderationLog.uid)
        console.log(result)
        if (result.successed) {
            updateModerationItem(result.data as IModerationResultLog)
        } else {
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
        }
    }

    const onConfirmationCancel = () => {
        setSelectedGrade(undefined)
        setModeratorAction(undefined)
        setModeratorMessage(undefined)
    }

    const onAcceptAction = async () => {
        if (selectedGrade) {
            setError(undefined);
            setWarning(undefined);
            setModerationState({ ...moderationState, fetched: false })
            setModeratorAction(undefined)
            const result = await portalApi.overview.moderation.result(moderationState.data?.uid as string, moderatorMessage, selectedGrade)
            if (result.successed) {
                onActionSuccess(onConfirmationSuccess)
            } else {
                if (result.errors && result.errors.length > 0) {
                    setError(result.errors[0].description)
                }
                setModerationState({ ...moderationState, fetched: true })
            }
            setModeratorMessage(undefined)
        }
    }

    const onRequestAction = async () => {
        setError(undefined);
        setWarning(undefined);
        setModerationState({ ...moderationState, fetched: false })
        setModeratorAction(undefined)
        const result = await portalApi.overview.moderation.request(moderationState.data?.uid as string, moderatorMessage)
        if (result.successed) {
            onActionSuccess(onConfirmationSuccess)
        } else {
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            setModerationState({ ...moderationState, fetched: true })
        }
        setModeratorMessage(undefined)
    }

    const onDeclineAction = async () => {
        setError(undefined);
        setWarning(undefined);
        setModerationState({ ...moderationState, fetched: false })
        setModeratorAction(undefined)
        const result = await portalApi.overview.moderation.result(moderationState.data?.uid as string, moderatorMessage)
        if (result.successed) {
            onActionSuccess(onConfirmationSuccess)
        } else {
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            setModerationState({ ...moderationState, fetched: true })
        }
        setModeratorMessage(undefined)
    }

    const getConfirmationHeader = () => {
        switch (moderatorAction) {
            case ModeratorActions.accept: return strings.TRANSACTIONS.MODERATIONS.MODERATION.ACCEPT_HEADER
            case ModeratorActions.decline: return strings.TRANSACTIONS.MODERATIONS.MODERATION.DECLINE_HEADER
            case ModeratorActions.request: return strings.TRANSACTIONS.MODERATIONS.MODERATION.REQUEST_HEADER
        }
    }

    const onAcceptToProcessing = async () => {
        if (userEmail) {
            setError(undefined);
            setWarning(undefined);
            setModerationState({ ...moderationState, fetched: false })
            setModeratorAction(undefined)
            const result = await portalApi.overview.moderation.start(moderationState.data?.uid as string)
            console.log(result)
            if (result.successed) {
                onActionSuccess(() => {
                    refreshModerationLog();
                })
            } else {
                if (result.errors && result.errors.length > 0) {
                    setError(result.errors[0].description)
                }
                setModerationState({ ...moderationState, fetched: true })
            }
        } else {
            setWarning(strings.TRANSACTIONS.MODERATIONS.MODERATION.ACCEPT_NOT_ALLOW_NO_EMAIL)
        }
    }

    const moderationColor = moderationLog.moderationStatus && getModerationColor(moderationLog.moderationStatus)

    return (
        <Stack verticalFill>
            <Stack verticalFill grow>
                <Stack styles={headerStylesMain}>
                    <Stack.Item styles={headerStyles}>
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }} styles={{ root: { borderLeftStyle: "solid", borderLeftWidth: 12, borderLeftColor: moderationColor, padding: "4px 16px 4px 12px", backgroundColor: theme.palette.white } }}>
                            <Stack.Item grow>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }} styles={{ root: { height: 72 } }}>
                                    <Stack grow>
                                        <Text variant="small" style={{ color: SharedColors.gray20 }}>{moderationLog.service}</Text>
                                        <Stack horizontal verticalAlign="end">
                                            <Text block nowrap variant="xLarge" style={{ fontWeight: "bold" }}>{moderationLog.device}</Text>
                                            {moderationLog.grade && (
                                                <Text style={{ color: SharedColors.cyanBlue10, marginLeft: 16 }}>
                                                    {moderationLog.grade ?? strings.TRANSACTION.RESULT.GRADE_NOT_DEFINED_UPPERCASE}
                                                </Text>
                                            )}
                                            {((moderationLog.price ?? 0) > 0) && (
                                                <Text variant="large" style={{ color: SharedColors.orangeYellow20, marginLeft: 16 }}>
                                                    {moderationLog.price?.toLocaleString()}
                                                </Text>
                                            )}
                                        </Stack>
                                    </Stack>
                                    <Stack verticalFill horizontal tokens={{ childrenGap: 12 }}>
                                        <Stack verticalFill verticalAlign='center'>
                                            <Text variant="small" style={{ fontWeight: 400, color: SharedColors.gray30 }}>{getLocalizedModerationStatus(moderationLog.moderationStatus as ModerationStatuses)}</Text>
                                            <Text variant="medium" style={{ fontWeight: 600, color: SharedColors.cyanBlue10 }}>{moderationLog.moderator}</Text>
                                        </Stack>
                                        <IconButton
                                            styles={iconButtonStyles}
                                            style={{ marginTop: 12, height: 38, width: 38 }}
                                            iconProps={{ iconName: 'Cancel' }}
                                            ariaLabel="Close window"
                                            onClick={onHideModal}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
                {error && (
                    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                    </Sticky>
                )}
                <Stack grow verticalFill verticalAlign='center'>
                    <Stack horizontal grow verticalFill>
                        {!error && moderationState.fetched && ((moderationState.data?.files && moderationState.data?.files.length > 0) && (
                            <Stack tokens={{ padding: "20px 0 0 20px" }}>
                                <SliderImage
                                    data={moderationState.data.files.map(i => ({ image: i.link, text: i.name, preview: i.link + '-preview' }))}
                                    showDescription={true}
                                    direction="right"
                                />
                            </Stack>
                        ))}
                        {(!error && moderationState.fetched) && ((moderationState.data?.messages && moderationState.data?.messages.length > 0 && (
                            <Stack grow style={{ background: theme.palette.neutralLighter }}>
                                <Stack grow verticalFill style={{ position: "relative", borderLeft: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                                    <ScrollablePane>
                                        <Stack tokens={{ padding: 16, childrenGap: 16 }}>
                                            {moderationState.data.messages.map((m, index) => (
                                                <Stack.Item key={index} >
                                                    <MyMessage dateTime={m.datetime} offset={offset} sender={m.sender} text={m.message} myMessage={["модератор", "moderator"].includes(m.sender.toLowerCase())} />
                                                </Stack.Item>
                                            ))}
                                        </Stack>
                                    </ScrollablePane>
                                </Stack>
                            </Stack>
                        ) || (
                                <Stack verticalFill verticalAlign='center' grow horizontalAlign='center'>
                                    <ItemsNotFound doNotUseButton imgSrc={"images/navigation/images/not_found.png"} info={strings.TRANSACTIONS.MODERATIONS.MODERATION.INFO} suggestion={strings.TRANSACTIONS.MODERATIONS.MODERATION.SUGGESTION} />
                                </Stack>
                            )))}
                        {!moderationState.fetched && (
                            <Stack verticalAlign="center" horizontalAlign="center" verticalFill grow>
                                <Spinner label={strings.SPINNERS.PLEASE_WAIT} />
                            </Stack>
                        )}
                        {error && (
                            <Stack grow horizontalAlign='center'>
                                <SomethingWentWrong />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Stack>
            {warning && (
                <MessageBar messageBarType={MessageBarType.error}>{warning}</MessageBar>
            )}
            <Stack horizontal horizontalAlign="end" verticalAlign="center" tokens={{ padding: 16 }} styles={{ root: { backgroundColor: theme.semanticColors.defaultStateBackground, boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0", zIndex: 20 } }}>
                {transactionNumber && (
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Stack>
                                <Text style={{ color: SharedColors.gray20 }}>{transactionNumber}</Text>
                                <Text variant="xSmall" style={{ color: SharedColors.gray10 }}>{strings.TRANSACTION.RESULT.TRANSACTION_NUMBER}</Text>
                            </Stack>
                            <Stack>
                                <CopyToClipBoardButton content={transactionNumber}
                                    copyText={strings.TRANSACTION.RESULT.COPY_TRANSACTION_NUMBER}
                                    copiedText={strings.TRANSACTION.RESULT.TRANSACTION_NUMBER_COPIED}
                                    tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                />
                            </Stack>
                        </Stack>
                    </Stack.Item>
                )}
                <Stack grow horizontal horizontalAlign='end' tokens={{ childrenGap: 8 }}>
                    {moderationState.fetched && moderationState.data?.moderationStatus && (
                        ![ModerationStatuses.completed, ModerationStatuses.moderation, ModerationStatuses.queue, ModerationStatuses.request, ModerationStatuses.denied].includes(moderationState.data?.moderationStatus as ModerationStatuses) ||
                        [ModerationStatuses.moderation].includes(moderationState.data?.moderationStatus as ModerationStatuses) && isModerationResponsible
                    ) && (moderationState.data?.workflow?.grades && moderationState.data?.workflow?.grades.length > 0 && (
                        <>
                            <TooltipHost
                                content={strings.BUTTONS.TEXT.REQUEST.length > 19 ? strings.BUTTONS.TEXT.REQUEST : ""}
                                styles={{root: {display: 'inline-block'}}}
                            >
                                <DefaultButton onClick={() => setModeratorAction(ModeratorActions.request)}>{strings.BUTTONS.TEXT.REQUEST.length > 19 ? `${strings.BUTTONS.TEXT.REQUEST.substring(0, 19)}...` : strings.BUTTONS.TEXT.REQUEST}</DefaultButton>
                            </TooltipHost>
                        </>
                    ))}
                    {moderationState.fetched && moderationState.data?.moderationStatus && (
                        ![ModerationStatuses.completed, ModerationStatuses.moderation, ModerationStatuses.queue, ModerationStatuses.denied].includes(moderationState.data?.moderationStatus as ModerationStatuses) ||
                        [ModerationStatuses.moderation].includes(moderationState.data?.moderationStatus as ModerationStatuses) && isModerationResponsible
                    ) && (moderationState.data?.workflow?.grades && moderationState.data?.workflow?.grades.length > 0 && (
                        <>
                            <DangerButton onClick={() => setModeratorAction(ModeratorActions.decline)}>{strings.BUTTONS.TEXT.DECLINE}</DangerButton>
                        </>
                    ))}
                    {moderationState.fetched && moderationState.data?.moderationStatus && (
                        ![ModerationStatuses.completed, ModerationStatuses.moderation, ModerationStatuses.queue, ModerationStatuses.denied].includes(moderationState.data?.moderationStatus as ModerationStatuses) ||
                        [ModerationStatuses.moderation].includes(moderationState.data?.moderationStatus as ModerationStatuses) && isModerationResponsible
                    ) && ((moderationState.data?.workflow?.useGradesCategories
                        ? (moderationState.data?.workflow?.gradesCategories && moderationState.data?.workflow?.gradesCategories.length > 0)
                        : (moderationState.data?.workflow?.grades && moderationState.data?.workflow?.grades.length > 0)) && (
                            <>
                                <PrimaryButton menuProps={menuProps} onClick={() => setModeratorAction(ModeratorActions.accept)}>{strings.BUTTONS.TEXT.SET_GRADE}</PrimaryButton>
                            </>
                        ) || (
                            <>
                                <PrimaryButton disabled={!selectedGrade}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                            </>
                        ))}
                    {moderationState.fetched && moderationState.data?.moderationStatus && [ModerationStatuses.queue].includes(moderationState.data?.moderationStatus as ModerationStatuses) && (
                        <PrimaryButton onClick={onAcceptToProcessing}>{strings.BUTTONS.TEXT.ACCEPT_TO_PROCESSING}</PrimaryButton>
                    )}
                    <DefaultButton onClick={onHideModal}>{strings.BUTTONS.TEXT.CLOSE}</DefaultButton>
                    <PrimaryButton iconProps={{ iconName: "Refresh" }} onClick={onConfirmationSuccess} />
                </Stack>
            </Stack>
            {moderatorAction && (
                <Modal isOpen={moderatorAction ? true : false} onDismiss={() => setModeratorAction(undefined)} isBlocking={true} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <Stack>
                            <Text variant='xLarge'>{getConfirmationHeader()}</Text>
                            {moderatorAction === ModeratorActions.accept && <Text variant='medium' style={{ color: theme.palette.themePrimary }} >{`${moderationState.data?.workflow?.grades.find(i => i.code === selectedGrade)?.name} - ${selectedGrade}`}</Text>}
                        </Stack>
                        <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }} ariaLabel="Close" onClick={() => setModeratorAction(undefined)} />
                    </div>
                    <div className={contentStyles.body}>
                        <Stack verticalAlign='space-between' tokens={{ childrenGap: 16 }}>
                            <TextField placeholder={strings.TRANSACTIONS.MODERATIONS.MODERATION.MESSAGE_PLACEHOLDER} multiline={multiline} onChange={onChangeTextField} />
                            <Stack verticalAlign="center">
                                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 4 }}>
                                    {moderatorAction === ModeratorActions.request && <PrimaryButton disabled={!moderatorMessage || moderatorMessage.trim().length === 0} style={{ width: 144 }} text={strings.BUTTONS.TEXT.CONFIRM} onClick={onRequestAction} />}
                                    {moderatorAction === ModeratorActions.decline && <PrimaryButton disabled={!moderatorMessage || moderatorMessage.trim().length === 0} style={{ width: 144 }} text={strings.BUTTONS.TEXT.CONFIRM} onClick={onDeclineAction} />}
                                    {moderatorAction === ModeratorActions.accept && <PrimaryButton style={{ width: 144 }} text={strings.BUTTONS.TEXT.CONFIRM} onClick={onAcceptAction} />}
                                    <DefaultButton style={{ width: 144 }} text={strings.BUTTONS.TEXT.CANCEL} onClick={onConfirmationCancel} />
                                </Stack>
                            </Stack>
                        </Stack>
                    </div>
                </Modal>
            )
            }
        </Stack>
    )
}

const theme = getTheme();
const headerStylesMain: IStackStyles = { root: { zIndex: 4 } };
const headerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 1px 0", zIndex: 10 } };
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const contentStyles = mergeStyleSets({
    container: {
        width: '400px',
        maxWidth: '400px',
        minHeight: 150,
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 12px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

