import React, { Dispatch } from "react"
import { TStageSettingsPivotStates } from "../../Configurator"
import { StageCommonButtonsSettingsPivot } from "../settings/StageCommonButtonsSettingsPivot"
import { StageCommonUISettingsPivot } from "../settings/StageCommonUISettingsPivot"
import { IStageConfig, ProcessStages, IStageConfigUI, ServiceTypes } from "@piceasoft/core"

type TProps = {
    serviceType?: ServiceTypes
    state: TStageSettingsPivotStates
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    additionalPivot?: React.ReactNode
    config?: IStageConfig<IStageConfigUI>
    withoutTitle?: boolean
    withoutNextTeaching?: boolean
    commonUIChildren?: React.ReactNode
    commonButtonsChildren?: React.ReactNode
    pivots?: { name: string, component: React.ReactNode }[]
    channel:number
}

export const ResultSettingsPivotBasedState: React.FC<TProps> = ({ config, ...props }) => {

    if (!config) return null

    switch (props.state) {
        case "common_buttons": return (
            <StageCommonButtonsSettingsPivot serviceType={props.serviceType} channel={ props.channel} config={config} onChangeDispatch={props.onChangeDispatch} stage={props.stage} withoutNextTeaching={props.withoutNextTeaching}>
                {props.commonButtonsChildren}
            </StageCommonButtonsSettingsPivot>
        )
        case "common_ui": return (
            <StageCommonUISettingsPivot config={config} onChangeDispatch={props.onChangeDispatch} stage={props.stage} withoutTitle={props.withoutTitle} channel={ props.channel}>
                {props.commonUIChildren}
            </StageCommonUISettingsPivot>
        )
        default:
            return <>{props.pivots?.find(i => i.name === props.state)?.component}</>
    }
}
