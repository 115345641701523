import { IContextualMenuProps, IDropdownOption, PrimaryButton, Stack, Text } from "@fluentui/react"
import { IContractCustomField, ISelfServiceWorkflowConfig } from "@piceasoft/core"
import React, { Dispatch } from "react"
import { strings } from "../../../../../../localization/strings"
import { InputField } from "../../Contract"
import ContactFormFieldEditor, { EditableContactField, ContactFieldNames, getContactFieldTitle } from "./ContactFormFieldEditor"
import DocumentTemplateSelect from "./DocumentTemplateSelect"


interface TProps {
    config?: ISelfServiceWorkflowConfig
    pivots?: { name: string, component: React.ReactNode }[]
    isFetchingTemplates?: boolean
    documentTemplates?: IDropdownOption[]
    onChangeDispatch: Dispatch<any>
}

const ContactFormSettings: React.FC<TProps> = (props: TProps) => {

    const[isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const[fieldToEdit, setFieldtoEdit] = React.useState<EditableContactField>('')
    const[dataToEdit, setDataToEdit] = React.useState<object|undefined>(undefined)
    const [customDataToEdit, setCustomDataToEdit] = React.useState<IContractCustomField|undefined>(undefined)
    const fields = props.config?.contactForms?.contactForm
    const customFieldIds = fields?.custom?.map( ( f:IContractCustomField) => f.key) ?? []

    const updateFieldConfiguration = ( field: EditableContactField, value?: object) => {
        const contactForm = props.config?.contactForms?.contactForm ? {...props.config?.contactForms?.contactForm} : {enabled: true}

        if( "name" === field) {
            contactForm.name = value;
        }
        else if( "phone" === field) {
            contactForm.phone = value;
        }
        else if( "email" === field) {
            contactForm.email = value;
        }
        else if( "address" === field) {
            contactForm.address = value;
        }
        else if( 'passportNumber' === field) {
            contactForm.passportNumber = value;
        }
        else if( 'passportDateOfIssue' === field) {
            contactForm.passportDateOfIssue = value;
        }
        else if( 'passportIssuedBy' === field) {
            contactForm.passportIssuedBy = value;
        }
        else if( 'passportSubdivisionCode' === field) {
            contactForm.passportSubdivisionCode = value;
        }
        else if( 'dateOfBirth' === field) {
            contactForm.dateOfBirth = value;
        }
        else if( 'placeOfBirth' === field) {
            contactForm.placeOfBirth = value;
        }
        else if( 'operator' === field) {
            contactForm.operator = value;
        }
        else {
            //Must be custom field...
            const f = value as IContractCustomField
            const newCustom = contactForm.custom ? ('' === field ? [...contactForm.custom, f] : contactForm.custom.map((cf) => { return (cf.key === field ? f : cf) } )
                                                    ) : [f]
            contactForm.custom = newCustom;
        }
        const settings = {...props.config}
        if( !settings.contactForms) {
            settings.contactForms = {}
        }
        settings.contactForms.contactForm = contactForm;
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: settings })
    }

    const setField = ( field: ContactFieldNames, enable: boolean) => {
        const value = enable ? {} : undefined
        updateFieldConfiguration( field, value)
    }

    const editField = ( field: ContactFieldNames) => {
        const data = props.config?.contactForms?.contactForm ? props.config?.contactForms?.contactForm[field] : undefined
        setDataToEdit( data)
        setFieldtoEdit( field)
        setIsModalOpen( true)
    }

    const newCustomField = () => {
        setCustomDataToEdit( undefined)
        setFieldtoEdit( '')
        setIsModalOpen( true)
    }

    const deleteCustomField = ( field: string) => {
        const contactForm = props.config?.contactForms?.contactForm ? {...props.config?.contactForms?.contactForm} : {enabled: true}
        contactForm.custom = contactForm.custom?.filter( (f) => f.key !== field)
        const settings = {...props.config}
        if( !settings.contactForms) {
            settings.contactForms = {}
        }
        settings.contactForms.contactForm = contactForm;
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: settings })
    }

    const editCustomField = ( field: string) => {
        const contactForm = props.config?.contactForms?.contactForm ? {...props.config?.contactForms?.contactForm} : {enabled: true}
        const item = contactForm.custom?.find( (f) => f.key === field)
        
        if( item) {
            setCustomDataToEdit( item)
            setFieldtoEdit( item.key)
            setIsModalOpen( true)
        }
    }

    const onTemplateSelected = (item?: IDropdownOption) => {
        const settings = {...props.config}
        if( !settings.contactForms) {
            settings.contactForms = { contactForm: {}}
        }
        if( !settings.contactForms.contactForm) {
            settings.contactForms.contactForm = {}
        }
        settings.contactForms.contactForm.documentId = item?.key.toString() !== 'null' ? item?.key as number : undefined;
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: settings })
    }

    const addFieldMenuProps: IContextualMenuProps = {
        items: [
            {
                key: "name",
                onClick: () => setField( 'name', true),
                disabled: fields?.name ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.NAME.TITLE
            },
            {
                key: "phone",
                onClick: () => setField( 'phone', true),
                disabled: fields?.phone ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PHONE.TITLE
            },
            {
                key: "email",
                onClick: () => setField( 'email', true),
                disabled: fields?.email ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.EMAIL.TITLE
            },
            {
                key: "address",
                onClick: () => setField( 'address', true),
                disabled: fields?.address ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ADDRESS.TITLE
            },
            {
                key:'passportNumber',
                onClick: () => setField( 'passportNumber', true),
                disabled: fields?.passportNumber ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.NUMBER.TITLE
            },
            {
                key:'passportDateOfIssue',
                onClick: () => setField( 'passportDateOfIssue', true),
                disabled: fields?.passportDateOfIssue ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_ISSUE.TITLE
            },
            {
                key:'passportIssuedBy',
                onClick: () => setField( 'passportIssuedBy', true),
                disabled: fields?.passportIssuedBy ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ISSUED_BY.TITLE
            },
            {
                key:'passportSubdivisionCode',
                onClick: () => setField( 'passportSubdivisionCode', true),
                disabled: fields?.passportSubdivisionCode ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.SUBDIVISION_CODE.TITLE
            },
            {
                key:'dateOfBirth',
                onClick: () => setField( 'dateOfBirth', true),
                disabled: fields?.dateOfBirth ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_BIRTH.TITLE
            },
            {
                key:'placeOfBirth',
                onClick: () => setField( 'placeOfBirth', true),
                disabled: fields?.placeOfBirth ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.PLACE_OF_BIRTH.TITLE
            },
            {
                key:'operator',
                onClick: () => setField( 'operator', true),
                disabled: fields?.operator ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.OPERATOR.TITLE
            },
            {
                key:'custom',
                onClick: () => newCustomField(),
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.CUSTOM.TITLE
            },
        ]
    }

    return (<Stack tokens={{ childrenGap: 24 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <DocumentTemplateSelect
                label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.SELECT_TEMPLATE}
                placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.CONTACT_TEMPLATE_PLACEHOLDER}
                isFetching={props.isFetchingTemplates}
                selectionOptions={props.documentTemplates}
                selectedId={fields?.documentId}
                onSelected={onTemplateSelected}
             />
        </Stack>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Text><b>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.CONTACT_FORM}</b></Text>
            <PrimaryButton iconProps={{iconName: 'add'}} menuProps={addFieldMenuProps} text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.ADD_FIELDS}/>
        </Stack>
        <Stack tokens={{ childrenGap: 16 }}>
            { fields?.name && <InputField name={ getContactFieldTitle( 'name')} 
                onConfig={ () => editField( "name")} 
                onDelete={ () => setField( 'name', false)}/>}
            { fields?.phone && <InputField name={getContactFieldTitle( 'phone')} 
                onConfig={ () => editField( "phone")} 
                onDelete={ () => setField( 'phone', false)}/>}
            { fields?.email && <InputField name={getContactFieldTitle( 'email')} 
                onConfig={ () => editField( "email")} 
                onDelete={ () => setField( 'email', false)}/>}
            { fields?.address && <InputField name={getContactFieldTitle( 'address')} 
                onConfig={ () => editField( "address")} 
                onDelete={ () => setField( 'address', false)}/>}
            { fields?.passportNumber && <InputField name={getContactFieldTitle( 'passportNumber')} 
                onConfig={ () => editField( "passportNumber")} 
                onDelete={ () => setField( 'passportNumber', false)}/>}
            { fields?.passportDateOfIssue && <InputField name={getContactFieldTitle( 'passportDateOfIssue')} 
                onConfig={ () => editField( "passportDateOfIssue")} 
                onDelete={ () => setField( 'passportDateOfIssue', false)}/>}
            { fields?.passportIssuedBy && <InputField name={getContactFieldTitle( 'passportIssuedBy')} 
                onConfig={ () => editField( "passportIssuedBy")} 
                onDelete={ () => setField( 'passportIssuedBy', false)}/>}
            { fields?.passportSubdivisionCode && <InputField name={getContactFieldTitle( 'passportSubdivisionCode')} 
                onConfig={ () => editField( "passportSubdivisionCode")} 
                onDelete={ () => setField( 'passportSubdivisionCode', false)}/>}
            { fields?.dateOfBirth && <InputField name={getContactFieldTitle( 'dateOfBirth')} 
                onConfig={ () => editField( "dateOfBirth")} 
                onDelete={ () => setField( 'dateOfBirth', false)}/>}
            { fields?.placeOfBirth && <InputField name={getContactFieldTitle( 'placeOfBirth')} 
                onConfig={ () => editField( "placeOfBirth")} 
                onDelete={ () => setField( 'placeOfBirth', false)}/>}
            { fields?.operator && <InputField name={getContactFieldTitle( 'operator')} 
                onConfig={ () => editField( "operator")} 
                onDelete={ () => setField( 'operator', false)}/>}
            { fields?.custom && fields.custom.length > 0 && (
                fields.custom.map( (f:IContractCustomField) => {
                    return (<InputField name={f.key} 
                    onConfig={ () => editCustomField(f.key)} 
                    onDelete={ () => deleteCustomField(f.key)} />)})
            )}
        </Stack>
        <ContactFormFieldEditor 
            isModalOpen={isModalOpen} 
            fieldId={fieldToEdit}
            fieldData={dataToEdit}
            customFields={customFieldIds}
            customFieldData={customDataToEdit}
            onCloseModal={ () => setIsModalOpen(false)} 
            onUpdateField={updateFieldConfiguration} 
        />
    </Stack> )
}
export default ContactFormSettings;