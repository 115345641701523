import { Control, Controller } from "react-hook-form"
import { getCommonFieldRules } from "../../../helpers/validationRules"
import IssuedBy from "./IssuedBy"
import { IDataCollectionConfig } from "@piceasoft/core"
import { TContractData } from "../../../../../dataCollectionTypes"

type TProps = {
    config?: IDataCollectionConfig
    errorMessage: string
    subdivisionCode?: string
    control: Control<TContractData, object>
}

const IssuedByController: React.FunctionComponent<TProps> = (props: TProps) => {
    let {config, control, subdivisionCode, errorMessage} = props

    return (
        <Controller
            name="passport.issuedBy"
            control={control}
            rules={getCommonFieldRules(config?.config?.fields?.passport?.issuedBy)}
            render={({ field }) =>
                <IssuedBy
                    config={config}
                    value={field.value}
                    subdivisionCode={subdivisionCode}
                    errorMessage={errorMessage}
                    handleOnBlur={() => {
                        field.onBlur()
                    }}
                    handleOnChange={(value?: string) => {
                        field.onChange(value)
                    }}
                />
            }
        />
    )
}

export default IssuedByController