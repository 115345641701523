import { DefaultButton, MessageBar, PrimaryButton, Stack, Text, ImageFit, Image, MessageBarType, TextField } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { noop } from '../../../../../../../core/helpers/common';
import { IPhotographic } from '../../../../../../../core/store';
import { IPhotographicCollectionConfig } from '@piceasoft/core';
import { strings } from '../../../../../../../localization/strings';
import Dropzone from '../../../../../dropzone/Dropzone';
import { uploadFileType } from '../PhotographicStatePreview';

type TProps = {
    config?: IPhotographicCollectionConfig
    data?: IPhotographic
    onClose?: () => void
    onResult?: (result: IPhotographic) => void
    onPending: (files: Array<uploadFileType>, isAdditional?: boolean, comment?: string) => void
    buttonsAlignCenter?: boolean
}

export const CollectionPreview: React.FC<TProps> = (props) => {
    const AllowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif"]
    const [files, setFiles] = useState<Array<uploadFileType>>([])
    const [warnings, setFilesWarning] = useState<string[]>([])
    const [comment, setComment] = useState<string>()

    const updateFilesWarning = () => {
        let uWarnings = []
        if (props.config?.collection?.length && files.length < props.config?.collection?.length) {
            uWarnings.push(`${strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FILES_ADDED} ${files.length} ${strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FILES_ADDED_FROM} ${props.config?.collection?.length}`)
        }
        if (props.config?.comment?.required && (!comment || comment.length < (props.config.comment.minLength ?? 5))) {
            uWarnings.push(strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COMMENT_MIN_LENGTH)
        }
        setFilesWarning(uWarnings)
    }

    useEffect(() => {
        updateFilesWarning()
    }, [files, comment, props.config?.collection])

    const addFiles = (upFiles: FileList, label?: string) => {
        var upFile = upFiles[0]
        if (AllowedFileTypes.includes(upFile.type)) {
            setFiles([
                ...files.filter(f => f.label !== label),
                {
                    label: label,
                    imgUrl: URL.createObjectURL(upFile),
                    data: upFile
                } as uploadFileType
            ])
        } else {
            const allowFilesWarning = strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FILE_NOT_SUPPORT
            warnings.filter(v => v === allowFilesWarning).length === 0 && setFilesWarning([...warnings, allowFilesWarning])
        }
    }

    return (
        <Stack grow tokens={{ childrenGap: 16 }}>
            <Stack horizontalAlign="center">
                {props.config?.ui?.localText && <span dangerouslySetInnerHTML={{ __html: props.config?.ui?.localText }} />}
            </Stack>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack horizontal wrap horizontalAlign="center" tokens={{ childrenGap: 16 }} style={{ overflow: "hidden" }}>
                    {props.config?.collection?.map(c => {
                        const file = files.find(f => f.label === c.label)
                        return (
                            <Stack.Item key={c.label} styles={{ root: { width: 180 } }}>
                                <Dropzone minHeight={140}
                                    filesLenght={file && 1}
                                    isMultiple={false}
                                    handleDrop={addFiles}
                                    label={c.label}
                                    description={c.description}
                                >
                                    <Stack style={{ width: 160, height: 120, textAlign: "center", overflow: "hidden", justifyContent: "center", border: 1, background: "black" }}>
                                        <Image src={file?.imgUrl} width={160} height={120} imageFit={ImageFit.contain} />
                                    </Stack>
                                </Dropzone>
                            </Stack.Item>)
                    })}
                </Stack>
                <Stack.Item>
                    {props.config?.comment && <TextField value={comment} onChange={(e, v) => setComment(v ?? "")} label={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.OPERATOR_MESSAGE} placeholder={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.OPERATOR_MESSAGE_PLACEHOLDER} />}
                    <Stack style={{ paddingTop: 12 }}>
                        {warnings.length > 0 ?
                            warnings.map(warning => <MessageBar key={warning} messageBarType={MessageBarType.error} isMultiline={false}>
                                {warning}.
                            </MessageBar>)
                            : <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
                                {strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.ALL_OK}
                            </MessageBar>}
                    </Stack>
                </Stack.Item>
            </Stack>
            <Stack horizontal horizontalAlign={props.buttonsAlignCenter === true && "center" || "end"} tokens={{ childrenGap: 8 }}>
                <PrimaryButton onClick={noop} disabled={(files.length !== props.config?.collection?.length || files.length === 0)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                {props.onClose !== undefined && <DefaultButton disabled onClick={noop}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>}
            </Stack>
        </Stack>
    )
}
