import { Control, Controller } from "react-hook-form"
import { getCustomFieldRules } from "../../../helpers/validationRules"
import CustomField from "./CustomField"

import type { TContractData } from "../../../../../dataCollectionTypes"
import type { TCustomFieldData, IContractCustomField } from "@piceasoft/core"
import { strings } from "../../../../../../../../../../../localization/strings"

type TProps = {
    field?: TCustomFieldData
    number: number
    errorMessage: string
    customFieldData: IContractCustomField
    control: Control<TContractData, object>,
}

const CustomFieldController: React.FunctionComponent<TProps> = (props: TProps) => {
    let {number, field, control, customFieldData, errorMessage} = props
    return (
        <Controller
            name={`customFields.${number}` as const}
            control={control}
            rules={getCustomFieldRules(field?.value, customFieldData)}
            render={({ field }) =>
                <CustomField
                    value={field.value}
                    customField={customFieldData}
                    errorMessage={errorMessage}
                    handleOnBlur={() => {
                         field.onBlur()
                    }}
                    handleOnChange={(value?: TCustomFieldData) => {
                        field.onChange(value)
                    }}
                />
            }
        />
    )
}

export default CustomFieldController