import { Stack, ScrollablePane, Image } from "@fluentui/react"
import * as React from 'react';
import { useSelector } from "react-redux";
import { IStore } from "../../../../../../core/store";
import { Dispatch } from "react";
import { strings } from "../../../../../../localization/strings";
import { ILandingConfig } from "@piceasoft/core";
import { Section } from "../../../../decorations/Section";
import { ParameterItem } from "../../../components/ParameterItem";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { useBoolean } from '@uifabric/react-hooks';
import { portalApi } from '../../../../../../core/api/api';
import { IRequestResult } from '../../../../../../core/store/typings/IResponseResult';

const maxFileSize = 1000;
type TProps = {
    config: ILandingConfig,
    onChangeDispatch: Dispatch<any>
}

type TPropsImage = {   
    imageFile: File | null,
    errorMessage?: string
}

export const LandingSetting: React.FC<TProps> = (props) => {

    const module = useSelector((s: IStore) => s.configurator.landing as ILandingConfig)
    const [isImageUploading, { setTrue: startUploading, setFalse: stopUploading }] = useBoolean(false)
    const [imageName, setImageName] = React.useState<string>()
    
    if (!module) return null    
    const [image, setImage] = React.useState<TPropsImage>()
    
    const allowedFileTypes = ["image/jpeg", "image/png"]
    const azureContainer = "workflow-landing-page"

    const addFile = (upFiles: FileList) => {
        var checkUpFilesSize = [...Array.from(upFiles).filter(upFile => (upFile.size / 1024) < maxFileSize )]
       
        if(!checkUpFilesSize.length) {           
           setImage({imageFile: null, errorMessage: strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE_TO_BIG})            
        } else {
            var checkUpFiles = [...Array.from(checkUpFilesSize).filter(upFile => allowedFileTypes.includes(upFile.type))]

            if(checkUpFiles.length) {
                setImage({ imageFile: checkUpFiles[0], errorMessage: ""});    
                uploadImageToStorage(checkUpFiles[0]);           
            } else {
                setImage({imageFile: null, errorMessage: strings.CONSTRUCTOR.INSPECTIONS.LANDING.INVALID_IMAGE_TYPE})     
            }
        }
    }

    const onDeleteImage = () => {
        startUploading() 
        deleteImageFromStorage();
        props.onChangeDispatch(actionCreators.landing.onConfigCommit({   
            ...props.config,                                                          
             imageUrl: undefined                             
        }));
        setImageName(undefined)        
        stopUploading()   
    }

    const deleteImageFromStorage = async () => { 
        if(props.config.imageUrl){
            const splitUrl = props.config.imageUrl.split("/")
            const imageKey = splitUrl[splitUrl.length - 1]
            const result = await portalApi.settings.organization.deleteFile(azureContainer, imageKey); 
            if(result.errors && result.errors.length > 0) {
                console.log(result.errors)
            }
        }
    }

    const uploadImageToStorage = async (file: File) => { 
        if(file) {          
            startUploading()
            let uploadResult: IRequestResult<string> = { successed: false }
            if(file){     
                uploadResult = await portalApi.settings.organization.uploadFile(azureContainer, file, undefined, true)
                stopUploading();
                setImageName(file.name);
                if(uploadResult.errors && uploadResult.errors.length > 0) {
                    if(uploadResult.errors.length > 0) {
                        console.log(uploadResult.errors)
                    }
                }
                else{
                    console.log("Image saved with URL: " + uploadResult.data);
                    props.onChangeDispatch(actionCreators.landing.onConfigCommit({   
                        ...props.config,                                                          
                         imageUrl: uploadResult.data                              
                    }));
                }
            }           
        }
    }

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
             <ScrollablePane>                
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.HEADER} max flat>
                <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.TITLE_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={(props.config.title !== undefined && props.config.title !== null) ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.landing.onConfigCommit({   
                                ...props.config,                                                          
                                 title: (props.config?.title !== undefined && props.config?.title !== null) ? undefined : ""                                
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={props.config?.title !== undefined ? true : false}
                        textFieldValue={props.config?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.landing.onConfigCommit({                                               
                            ...props.config, 
                            title: val                            
                        }))}
                    />     
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.LANDING.BODY_TEXT}
                        useToggle
                        toggleEnabled
                        toggleChecked={(props.config?.bodyText !== undefined && props.config?.bodyText !== null) ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.landing.onConfigCommit({    
                                ...props.config,                                                          
                                bodyText: (props.config?.bodyText !== undefined && props.config?.bodyText !== null) ? undefined : ""                                
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.LANDING.BODY_TEXT_HINT}
                        useTextField
                        textFieldMultiline
                        textFieldEnabled={props.config?.bodyText !== undefined ? true : false}
                        textFieldValue={props.config?.bodyText}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.landing.onConfigCommit({                                               
                            ...props.config, 
                            bodyText: val                            
                        }))}
                    />     
                     <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE}
                        useToggle
                        toggleEnabled
                        toggleChecked={props.config?.useImage ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.landing.onConfigCommit({    
                                ...props.config,                                                          
                                useImage: !props.config?.useImage,
                                imageUrl: props.config?.useImage ? undefined : props.config.imageUrl, 
                                imageLink: props.config?.useImage ? undefined : props.config.imageLink, 
                                imageManualUrl: props.config?.useImage ? undefined : props.config.imageUrl
                            }))
                            setImageName(undefined)
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE_HINT}                     
                    />       
                     <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE}
                        useToggle
                        toggleEnabled={props.config?.useImage ? true : false}                      
                        toggleChecked={(props.config.imageUrl !== undefined && props.config.imageUrl !== null) ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.landing.onConfigCommit({    
                                ...props.config,                                                          
                                imageUrl: (props.config?.imageUrl !== undefined && props.config?.imageUrl !== null) ? undefined : "",
                                imageManualUrl: (props.config?.imageUrl == undefined && props.config?.imageUrl == null) ? undefined : props.config?.imageManualUrl                                
                            }))
                        }}
                        imageEnabled={(props.config?.imageUrl !== undefined && props.config?.imageUrl !== null) ? true : false}
                        description={strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE_SUPPORTED}   
                        useImage
                        inputFileButtonId={'btnLandingUploadImg'}
                        image_url={props.config?.imageUrl}   
                        addFile={addFile}   
                        onDeleteImage={onDeleteImage}
                        isImageUploading={isImageUploading} 
                        imageName={imageName}                           
                    />  
                     <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE_URL}
                        useToggle
                        toggleEnabled={props.config?.useImage ? true : false}   
                        toggleChecked={(props.config.imageManualUrl !== undefined && props.config.imageManualUrl !== null) ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.landing.onConfigCommit({    
                                ...props.config,                                                          
                                imageManualUrl: (props.config?.imageManualUrl !== undefined && props.config?.imageManualUrl !== null) ? undefined : "",
                                imageUrl: (props.config?.imageManualUrl == undefined && props.config?.imageManualUrl == null) ? undefined : props.config?.imageManualUrl                                 
                            }))
                            setImageName(undefined)
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE_URL_HINT}
                        descriptionBotom = {strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE_SUPPORTED}
                        useTextField                        
                        textFieldEnabled={(props.config?.imageManualUrl !== undefined && props.config?.imageManualUrl !== null) ? true : false}
                        textFieldValue={props.config?.imageManualUrl}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.landing.onConfigCommit({                                               
                            ...props.config, 
                            imageManualUrl: val                            
                        }))}                             
                    />      
                     <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE_LINK}
                        useToggle
                        toggleEnabled={props.config?.useImage ? true : false}   
                        toggleChecked={(props.config?.imageLink !== undefined && props.config?.imageLink !== null) ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.landing.onConfigCommit({    
                                ...props.config,                                                          
                                imageLink: (props.config?.imageLink !== undefined && props.config?.imageLink !== null) ? undefined : ""                                
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.LANDING.IMAGE_LINK_HINT}
                        useTextField                       
                        textFieldEnabled={(props.config?.imageLink !== undefined && props.config?.imageLink !== null) ? true : false}
                        textFieldValue={props.config?.imageLink}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.landing.onConfigCommit({                                               
                            ...props.config, 
                            imageLink: val                            
                        }))}
                    />      
                     <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.LANDING.BUTTON_TEXT}
                        useToggle
                        toggleEnabled
                        toggleChecked={(props.config?.buttonText !== undefined && props.config?.buttonText !== null) ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.landing.onConfigCommit({    
                                ...props.config,                                                          
                                buttonText: (props.config?.buttonText !== undefined && props.config?.buttonText !== null) ? undefined : ""                                
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.LANDING.BUTTON_TEXT_HINT}
                        useTextField                       
                        textFieldEnabled={(props.config?.buttonText !== undefined && props.config?.buttonText !== null) ? true : false}
                        textFieldValue={props.config?.buttonText}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.landing.onConfigCommit({                                               
                            ...props.config, 
                            buttonText: val                            
                        }))}
                    />      
                </Section>               
            </ScrollablePane>
        </Stack.Item>
    )
}

