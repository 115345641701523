import { Stack, Text, IconButton, Separator, TextField } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import { Control, Controller, FieldError } from "react-hook-form"
import { onRenderLabel } from "../../../../components/renders/onRenderLabel"
import { Section } from "../../../../components/shared/decorations/Section"
import { IBranding } from "../../../../core/store/typings/IBranding"
import { strings } from "../../../../localization/strings"

type Error = {
    name?: FieldError | undefined;
    description?: FieldError | undefined;
    id?: FieldError | undefined;
    imageSrc?: FieldError | undefined;
    removed?: FieldError | undefined;
    creationDate?: FieldError | undefined;
    config?: {
    } | undefined;
}
type TProps = {
    errors?: Error
    control: Control<IBranding, object>
}
export const BrandingSetup: React.FC<TProps> = (props) => {
    const [isSectionCollapsed, { toggle: toggleSectionCollapse }] = useBoolean(false)
    const onRenderHeader = (): React.ReactNode => {
        return (
            <Stack grow>
                <Stack
                    onClick={toggleSectionCollapse} horizontal verticalAlign="center"
                    grow horizontalAlign="space-between"
                    tokens={{ padding: "10px 20px 0px 20px" }}
                    style={{ paddingBottom: isSectionCollapsed ? "10px" : "0", cursor: 'pointer' }}
                >
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_SETUP.BRANDING_SETUP_TITLE}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton iconProps={{ iconName: isSectionCollapsed ? "ChevronDown" : "ChevronUp" }} />
                    </Stack.Item>
                </Stack>
                {!isSectionCollapsed && (
                    <Separator />
                )}
            </Stack>
        )
    }

    return (
        <Stack>
            <Section onRenderHeader={onRenderHeader} headerOnly={isSectionCollapsed}>
                <Stack>
                    {!isSectionCollapsed && (
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap>
                            <Stack.Item>
                                <Controller
                                    name="name"
                                    control={props.control}
                                    rules={ {
                                      required: {
                                        value: true,
                                        message: strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_SETUP.NAME_REQUIRED_ERROR
                                      },
                                      validate: (value) => value && value.trim().length > 0 ? true : strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_SETUP.NAME_REQUIRED_ERROR
                                    }
                                  }
                                    render={({ field }) =>
                                        <TextField value={field.value}
                                            required
                                            placeholder={strings.ORGANIZATION.BRANDING.BRANDING_PLACEHOLDER.NAME_PLACEHOLDER}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_SETUP.NAME_TITLE}
                                            description={strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_SETUP.NAME_INFO}
                                            onChange={field.onChange}
                                            style={{ minWidth: 300 }}
                                            errorMessage={props.errors?.name?.message} />} />
                                            
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="description"
                                    control={props.control}
                                    render={({ field }) =>
                                        <TextField value={field.value}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_SETUP.DESCRIPTION_TITLE}
                                            description={strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_SETUP.DESCRIPTION_INFO}
                                            placeholder={strings.ORGANIZATION.BRANDING.BRANDING_PLACEHOLDER.DESCRIPTION_PLACEHOLDER}
                                            onRenderLabel={onRenderLabel}
                                            onChange={field.onChange}
                                            style={{ minWidth: 300 }} />} />
                            </Stack.Item>
                        </Stack>
                    )}
                </Stack>
            </Section>
        </Stack>
    )
}
