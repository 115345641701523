import { config } from "process"
import React, { Dispatch } from "react"
import { TStageSettingsPivotStates } from "../../../Configurator"
import { StageCancelReasonsPivot } from "../../settings/StageCancelReasonsPivot"
import { StageCommonButtonsSettingsPivot } from "../../settings/StageCommonButtonsSettingsPivot"
import { StageConfirmationSettingsPivot } from "../../settings/StageConfirmationSettingsPivot"
import { PostOfferStageAdditionalSettingsPivot } from "./PostOfferStageAdditionalSettingsPivot"
import { PostOfferStageCommonUIPivot } from "./PostOfferStageCommonUIPivot"
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { IStageConfig, ProcessStages, IStageConfigUI, IPostOfferConfig } from "@piceasoft/core"

type TProps = {
    state: TStageSettingsPivotStates
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
    config?: IPostOfferConfig
    confirmationChildren?: React.ReactNode
    pivots?: { name: string, component: React.ReactNode }[]
    channel: number
}

export const PostOfferStageSettingsPivotBasedState: React.FC<TProps> = (props) => {
    switch (props.state) {
        case "common_buttons": return (
            <StageCommonButtonsSettingsPivot channel={ props.channel} config={props.config as IStageConfig<IStageConfigUI>} onChangeDispatch={props.onChangeDispatch} stage={props.stage} />
        )
        case "confirmation": return (
            <StageConfirmationSettingsPivot channel={ props.channel} config={props.config as IStageConfig<IStageConfigUI>} onChangeDispatch={props.onChangeDispatch} stage={props.stage}>{props.confirmationChildren}</StageConfirmationSettingsPivot>
        )
        case "cancel_reasons": return (
            <StageCancelReasonsPivot channel={ props.channel} config={props.config as IStageConfig<IStageConfigUI>} onChangeDispatch={props.onChangeDispatch} stage={props.stage} />
        )
        case "additional": return <PostOfferStageAdditionalSettingsPivot channel={ props.channel} onChangeDispatch={props.onChangeDispatch} config={props.config} />
        case "common_ui": return <PostOfferStageCommonUIPivot channel={ props.channel} onChangeDispatch={props.onChangeDispatch} config={props.config} />
        default:
            return <>{props.pivots?.find(i => i.name === props.state)?.component}</>
    }
}
