import { IContextualMenuProps, DirectionalHint, IColumn, Stack, Text, IconButton, IDetailsListProps, IDetailsRowStyles, DetailsRow, SelectionMode, DetailsListLayoutMode, DetailsList, getTheme, IButtonStyles, PrimaryButton } from '@fluentui/react'
import * as React from 'react'
import { IPhotographicCollectionConfig, IPhotographicCollectionItem } from '@piceasoft/core'
import { strings } from '../../../../../../../../localization/strings'
import { Section } from '../../../../../../decorations/Section'
import { onRenderColumnHeaderCommon } from '../../../../../../detailsList/commonRenders'
import { ParameterItem } from '../../../../../components/ParameterItem'

export type TCollectionModeConfiguratorModePivotProps = {
    config: IPhotographicCollectionConfig
    onModeConfirm: (config: IPhotographicCollectionConfig) => void
    onEditCollection: (collection: IPhotographicCollectionItem, index: number) => void
    onDeleteCollection: (index: number) => void
    onUpCollection: (index: number) => void
    onAddCollection: () => void
}

export const CollectionModeConfiguratorModePivot: React.FC<TCollectionModeConfiguratorModePivotProps> = (props) => {

    const getItemMenuProps = (item: IPhotographicCollectionItem, order: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${order}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => props.onEditCollection(item, order),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${order}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => props.onDeleteCollection(order),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.LABEL,
            fieldName: 'label',
            minWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPhotographicCollectionItem) => <Stack verticalFill verticalAlign="center">
                <Text style={{ color: theme.palette.black, fontWeight: 600 }}>{item.label}</Text>
            </Stack>,
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.DESCRIPTION,
            fieldName: 'description',
            minWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPhotographicCollectionItem) => <Stack verticalFill verticalAlign="center">
                <Text>{item.description}</Text>
            </Stack>,
            isPadded: true,
        },
        {
            key: 'column3',
            name: '',
            fieldName: 'commandBar',
            minWidth: 70,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPhotographicCollectionItem, order?: number) => {
                return (
                    <Stack horizontal verticalFill horizontalAlign='end' verticalAlign='center' onClick={(ev) => ev.stopPropagation()}>
                        {(order !== undefined && order > 0) && (
                            <Stack.Item style={{ minWidth: 36 }}>
                                <IconButton iconProps={{ iconName: "Up", className: 'up-icon' }} styles={menuIconStyles} onClick={() => props.onUpCollection(order)} />
                            </Stack.Item>
                        )}
                        <Stack.Item>
                            <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={order !== undefined ? getItemMenuProps(item, order) : undefined} />
                        </Stack.Item>
                    </Stack>
                )
            }
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                    '.up-icon': {
                        fontSize: 16,
                        color: theme.palette.black
                    }
                }
            };
            return <Stack onClick={() => { props.onEditCollection(rProps.item, rProps.itemIndex) }
            }><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    }

    return (
        <Section flat max>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.COLLECTION_TITLE}
                noSeparator
                useToggle={false}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.COLLECTION_DESCRIPTION}
            >
                {props.config.collection && props.config.collection.length > 0 && (
                    <DetailsList
                        styles={{ root: { width: '100%' } }}
                        items={props.config.collection}
                        columns={columns}
                        setKey="none"
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onRenderRow={onRenderRow}
                    />
                )}
                <Stack.Item>
                    <PrimaryButton onClick={props.onAddCollection} text={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.NOT_FOUND_CASE.ADD_BUTTON} />
                </Stack.Item>
            </ParameterItem>
        </Section>
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}