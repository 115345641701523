import * as React from 'react';
import { LogDetailsList } from './transactions/transactionResult/TransactionsDetailsList';
import {
    Calendar, Callout, CommandBarButton, FontIcon, FontWeights,
    getTheme, IColumn, IconButton, IContextualMenuProps, mergeStyleSets,
    MessageBar, MessageBarType, Modal, PrimaryButton, ScrollablePane,
    ScrollbarVisibility, Spinner, Stack, Text
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { strings } from '../../localization/strings';
import { Filter, TFilterOptions } from '../../components/shared/filter/Filter';
import { TFilterOptionValue } from '../../components/shared/filter/FilterOption';
import { TransactionLogFlags, ILogEntry, CurrencyCodes } from '@piceasoft/core';
import TransactionResult from './transactions/transactionResult/TransactionResult';
import { portalApi } from '../../core/api/api';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { onRenderColumnHeaderCommon } from '../../components/shared/detailsList/commonRenders';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { ComingSoon } from '../../components/shared/comingSoon/ComingSoon';
import { getDaysArray } from '../../components/helpers/common';
import { endpoints } from '../../core/api/endpoints';
import { CurrencySigns } from '@piceasoft/core';
import { useParams } from 'react-router';
import { getLocalizedChannel, getLocalizedServiceType } from '../../localization/helpers/channel';

type TProps = {
}

enum ExportPeriodTypes {
    Choosed,
    LastMonth,
    PreviousMonth,
    Current
}

export const OverviewTransactions: React.FC<TProps> = (props) => {

    const [searchString, setSearchString] = useState<string>()
    const [filterOptions, setFilterOptions] = useState<TFilterOptions>()
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<TFilterOptions>()

    const [logsState, setLogsState] = useState<{ fetched: boolean, data?: ILogEntry[] }>({ fetched: false })
    const [error, setError] = useState<string>()
    const [items, setItems] = useState<ILogEntry[]>()

    const [transactionId, setTransactionId] = useState<string>()
    const contract_url_base = "/v1/contract?uid=";

    const [isShowSettingsModal, { setTrue: showSettingsModal, setFalse: hideSettingsModal }] = useBoolean(false)

    const [isShowRangeSelectCallout, { setTrue: showRangeSelectCallout, setFalse: hideRangeSelectCallout }] = useBoolean(false)
    const dateRangeButtonClassname = useId(`date-range-button-classname`)
    const [preSelectedFrom, setPreSelectedFrom] = useState<Date>()
    const [preSelectedTo, setPreSelectedTo] = useState<Date>()
    const [selectedFrom, setSelectedFrom] = useState<Date>()
    const [selectedTo, setSelectedTo] = useState<Date>()

    const [isShowExportRangeSelectModal, { setTrue: showExportRangeSelectModal, setFalse: hideExportRangeSelectModal }] = useBoolean(false)
    const [exportPreSelectedFrom, setExportPreSelectedFrom] = useState<Date>()
    const [exportPreSelectedTo, setExportPreSelectedTo] = useState<Date>()
    const [exportSelectedFrom, setExportSelectedFrom] = useState<Date>()
    const [exportSelectedTo, setExportSelectedTo] = useState<Date>()
    const { transactionUid } = useParams<{ transactionUid: string }>();

    const handleDateRangeSelect = (date: Date) => {
        if (!preSelectedFrom || (preSelectedFrom && preSelectedTo)) {
            setPreSelectedFrom(date)
            setPreSelectedTo(undefined)
        } else {
            setPreSelectedTo(date)
        }
    }

    const handleDateExportRangeSelect = (date: Date) => {
        if (!exportPreSelectedFrom || (exportPreSelectedFrom && exportPreSelectedTo)) {
            setExportPreSelectedFrom(date)
            setExportPreSelectedTo(undefined)
        } else {
            setExportPreSelectedTo(date)
        }
    }

    const handleShowRangeSelectCalloutVisibility = () => {
        if (!isShowRangeSelectCallout) {
            showRangeSelectCallout()
        } else {
            hideRangeSelectCallout()
        }
    }

    useEffect(() => {
        const toDate = new Date()
        let fromDate = new Date()
        fromDate.setDate(fromDate.getDate() - 1)
        setSelectedFrom(fromDate)
        setSelectedTo(toDate)
        if (transactionUid) {
            setTransactionId(transactionUid)
        }
    }, [])

    useEffect(() => {
        if (exportPreSelectedFrom && exportPreSelectedTo) {
            // console.log(`preSelectedFrom: ${preSelectedFrom}`)
            // console.log(`preSelectedTo: ${preSelectedTo}`)
            if (exportPreSelectedFrom > exportPreSelectedTo) {
                // console.log("Exchanging range bounds")
                setExportSelectedFrom(exportPreSelectedTo)
                setExportSelectedTo(exportPreSelectedFrom)
                setExportPreSelectedFrom(exportPreSelectedTo)
                setExportPreSelectedTo(exportPreSelectedFrom)
            } else {
                setExportSelectedTo(exportPreSelectedTo)
                setExportSelectedFrom(exportPreSelectedFrom)
            }
        }
    }, [exportPreSelectedTo, exportPreSelectedFrom])

    useEffect(() => {
        if (preSelectedFrom && preSelectedTo) {
            // console.log(`preSelectedFrom: ${preSelectedFrom}`)
            // console.log(`preSelectedTo: ${preSelectedTo}`)
            if (preSelectedFrom > preSelectedTo) {
                // console.log("Exchanging range bounds")
                setSelectedFrom(preSelectedTo)
                setSelectedTo(preSelectedFrom)
                setPreSelectedFrom(preSelectedTo)
                setPreSelectedTo(preSelectedFrom)
            } else {
                setSelectedTo(preSelectedTo)
                setSelectedFrom(preSelectedFrom)
            }
        }
    }, [preSelectedTo, preSelectedFrom])

    useEffect(() => {
        if (selectedFrom && selectedTo) {
            // console.log("Requesting entries")
            hideRangeSelectCallout()
            requestEntries(selectedFrom.toDateString(), selectedTo.toDateString())
        }
    }, [selectedTo, selectedFrom])

    // useEffect(() => {
    //     console.log(filterOptions)
    //     if (filterOptions && logsState.data && logsState.data.length > 0) {
    //         if (filterOptions["service"].values.find(v => v.key === TransactionLogFlags.Created) !== null) {
    //             setSelectedFilterOption('flag', [{ key: TransactionLogFlags.Created, title: strings.FILTER.FLAGS.CREATED }])
    //         }
    //     }
    // }, [filterOptions])

    useEffect(() => {
        if (logsState.data !== items) {
            setItems(logsState.data)
            if (logsState.data) {
                setFilterOptions(getFilterOptions(logsState.data))
            }
        }
    }, [logsState])

    useEffect(() => {
        // console.log(selectedFilterOptions)
        setItems(() => {
            var newItems = [...(logsState.data ?? [])]
            if (searchString) {
                newItems = [
                    ...((newItems as ILogEntry[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i["number"],
                            i["device"],
                            i["imei"],
                            i["sn"]
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            if (selectedFilterOptions) {
                Object.keys(selectedFilterOptions).map(k => {
                    selectedFilterOptions[k].values.map((v, i) => {
                        // console.log(k," ", v)
                        newItems = [
                            ...(newItems as ILogEntry[]).filter(f => {
                                return selectedFilterOptions[k].values.map(v => v.key).includes(f[k])
                            })
                        ]
                    })
                })
            }
            return newItems as ILogEntry[]
        })
    }, [selectedFilterOptions, searchString])

    const onExportTransaction = (type: ExportPeriodTypes) => {
        switch (type) {
            case ExportPeriodTypes.Current:
                if (selectedFrom && selectedTo) {
                    location.href = endpoints.v1.exportlogs(selectedFrom.toDateString(), selectedTo.toDateString())
                }
                break
            case ExportPeriodTypes.Choosed:
                if (exportSelectedFrom && exportSelectedTo) {
                    location.href = endpoints.v1.exportlogs(exportSelectedFrom.toDateString(), exportSelectedTo.toDateString())
                }
                break
            case ExportPeriodTypes.LastMonth:
                const nowLastMonth = new Date();
                const prevMonthLastDateLastMonth = new Date(nowLastMonth.getFullYear(), nowLastMonth.getMonth(), nowLastMonth.getDate());
                const prevMonthFirstDateLastMonth = new Date(nowLastMonth.getFullYear(), nowLastMonth.getMonth(), 1);
                location.href = endpoints.v1.exportlogs(prevMonthFirstDateLastMonth.toDateString(), prevMonthLastDateLastMonth.toDateString())
                break
            case ExportPeriodTypes.PreviousMonth:
                hideExportRangeSelectModal()
                const nowPreviousMonth = new Date();
                const prevMonthLastDatePreviousMonth = new Date(nowPreviousMonth.getFullYear(), nowPreviousMonth.getMonth(), 0);
                const prevMonthFirstDatePreviousMonth = new Date(nowPreviousMonth.getFullYear() - (nowPreviousMonth.getMonth() > 0 ? 0 : 1), (nowPreviousMonth.getMonth() - 1 + 12) % 12, 1);
                location.href = endpoints.v1.exportlogs(prevMonthFirstDatePreviousMonth.toDateString(), prevMonthLastDatePreviousMonth.toDateString())
                break
        }
    }

    const exportMenuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        isBeakVisible: true,
        items: [
            {
                text: strings.TRANSACTIONS.EXPORT.MENU.CURRENT_PERIOD,
                key: 'currentPeriod',
                onClick: () => onExportTransaction(ExportPeriodTypes.Current)
            },
            {
                text: strings.TRANSACTIONS.EXPORT.MENU.LAST_MONTH,
                key: 'lastMonth',
                onClick: () => onExportTransaction(ExportPeriodTypes.LastMonth)
            },
            {
                text: strings.TRANSACTIONS.EXPORT.MENU.PREVIOUS_MONTH,
                key: 'previousMonth',
                onClick: () => onExportTransaction(ExportPeriodTypes.PreviousMonth)
            },
            {
                text: strings.TRANSACTIONS.EXPORT.MENU.CHOOSE_PERIOD,
                key: 'choosePeriod',
                onClick: showExportRangeSelectModal
            },
        ]
    }

    const propsColumns: IColumn[] = [
        {
            key: 'column1',
            name: strings.TRANSACTIONS.COLUMNS.TRANSACTION_NUMBER,
            fieldName: 'number',
            minWidth: 150,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: false,
            isSorted: true,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return (
                    <Stack horizontal grow verticalAlign="center" tokens={{ childrenGap: 2 }} >
                        <Stack.Item grow>
                            <Text style={{ paddingRight: 2 }}>{item["number"]}</Text>
                        </Stack.Item>
                        {/* {item["flag"] === TransactionLogFlags.Created && (
                            <Stack.Item tokens={{ padding: '0px 8px 0px 0px' }}>
                                <IconButton className={classNames.itemCell} iconProps={{ iconName: "Print" }} target="_blank" href={(item["service"] === "TradeIn *" || item["service"] === "Recycle *") ? `https://one2phone.ru/Services/Request/${item["uid"]}?handler=Contract` : contract_url_base + item["uid"]} />
                            </Stack.Item>
                        )} */}
                    </Stack>
                )
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.TRANSACTIONS.COLUMNS.CREATION_DATE,
            fieldName: 'creationDate',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{new Date(item["creationDate"]).toLocaleString()}</span>;
            },
            isPadded: true,
        },
        {
            key: 'column12',
            name: strings.TRANSACTIONS.COLUMNS.SERVICE_CHANNEL,
            fieldName: 'channel',
            minWidth: 110,
            maxWidth: 140,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{ item["channel"] }</span>;
            },
            isPadded: true,
        },
        {
            key: 'column11',
            name: strings.TRANSACTIONS.COLUMNS.SERVICE_TYPE,
            fieldName: 'serviceType',
            minWidth: 110,
            maxWidth: 140,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{ item["serviceType"] }</span>;
            },
            isPadded: true,
        },
        {
            key: 'column4',
            name: strings.TRANSACTIONS.COLUMNS.SERVICE,
            fieldName: 'service',
            minWidth: 180,
            maxWidth: 180,
            isResizable: false,
            isCollapsible: false,
            data: 'number',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                let textColor
                switch (item["flag"]) {
                    case TransactionLogFlags.Created:
                        textColor = theme.palette.green
                        break;
                    case TransactionLogFlags.Canceled:
                        textColor = theme.palette.red
                        break;
                    case TransactionLogFlags.Unfinished:
                        textColor = theme.palette.neutralDark
                        break;
                }
                return <span style={{ color: textColor }}>{item["service"]}</span>;
            }
        },
        {
            key: 'column5',
            name: strings.TRANSACTIONS.COLUMNS.DEVICE,
            fieldName: 'device',
            minWidth: 150,
            maxWidth: 250,
            isRowHeader: false,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{item["device"]}</span>;
            },
            isPadded: true,
        },
        {
            key: 'column6',
            name: strings.TRANSACTIONS.COLUMNS.IMEI,
            fieldName: 'imei',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            isCollapsible: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{item["imei"]}</span>;
            },
        },
        {
            key: 'column7',
            name: strings.TRANSACTIONS.COLUMNS.SN,
            fieldName: 'sn',
            minWidth: 140,
            maxWidth: 140,
            isResizable: false,
            isCollapsible: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{item["sn"]}</span>;
            },
        },
        {
            key: 'column8',
            name: strings.TRANSACTIONS.COLUMNS.GRADE,
            fieldName: 'grade',
            minWidth: 80,
            maxWidth: 80,
            isResizable: false,
            isCollapsible: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{item["grade"]}</span>;
            },
        },
        {
            key: 'column9',
            name: strings.TRANSACTIONS.COLUMNS.PRICE,
            fieldName: 'price',
            minWidth: 90,
            maxWidth: 90,
            isResizable: false,
            isCollapsible: false,
            data: 'number',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{`${item["price"]} ${(item["currency"] !== null || undefined) && (CurrencySigns[item["currency"] as CurrencyCodes] !== undefined) ? CurrencySigns[item["currency"] as CurrencyCodes] : ''}`}</span>;
            },
        },
        {
            key: 'column10',
            name: strings.TRANSACTIONS.COLUMNS.STATUS,
            fieldName: 'status',
            minWidth: 180,
            maxWidth: 180,
            isResizable: false,
            isCollapsible: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ILogEntry) => {
                return <span>{item["status"]}</span>;
            },
        }
    ];

    const requestEntries = async (from?: string, to?: string, search?: string) => {
        setSearchString(undefined)
        setSelectedFilterOptions(undefined)
        setLogsState({ fetched: false })
        setError(undefined)
        setItems(undefined)
        const result = await portalApi.log.getLogs(from, to, search);
        if (result.successed) {
            if( result.data) {
                const tmp: ILogEntry[] = result.data.map( (d) => { 
                    var v: ILogEntry = {...d}
                    //v["serviceType"] = getLocalizedServiceType( Math.floor( Math.random()*11));
                    //v["channel"] = getLocalizedChannel( Math.floor( Math.random()*4));
                    // if( d["serviceType"]) {
                    //     v[ "serviceType"] = getLocalizedServiceType( d["serviceType"]);
                    // }
                    //  Above leaves "Service type" column values empty.
                    //  Below sets column value to "Undefined" for transactions that don't have the value (== all)
                    //  So once transactions start to have the value, it will be possible to filter also "Undefined".
                    v[ "serviceType"] = getLocalizedServiceType( d["serviceType"]);
                    v[ "channel"] = getLocalizedChannel( d["channel"]);
                    return v;
                });
                setLogsState({ fetched: true, data: tmp })
            }
            else 
                setLogsState({ fetched: true, data: result.data })
        }
        if (result.error) {
            setLogsState({ fetched: true })
            setError(result.error)
        }
    }

    const setSelectedFilterOption = (name?: string, values?: TFilterOptionValue[]) => {
        if (!name) {
            setSelectedFilterOptions(undefined)
        } else {
            let result: TFilterOptions = { ...selectedFilterOptions }
            if (values) {
                result[name] = { values: values }
            }
            setSelectedFilterOptions(result)
        }
    }

    const cancelHandler = () => {
        setTransactionId(undefined)
    }

    const showTransactionHandler = async (uid: string) => {
        setTransactionId(uid)
    }

    return (
        <>
            <Stack style={{ backgroundColor: theme.palette.white }} verticalFill>
                {(error) && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {error}
                    </MessageBar>
                )}
                <ScreenHeader title={strings.TRANSACTIONS.TITLE}>
                    <Stack horizontal styles={{ root: { height: 32 } }}>
                        <CommandBarButton iconProps={{ iconName: "Calendar" }} onClick={handleShowRangeSelectCalloutVisibility} className={dateRangeButtonClassname} styles={{ root: { paddingLeft: 12, paddingRight: 12 } }}>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8, padding: "0 0 0 4px" }}>
                                <Text>{(selectedFrom && selectedTo) && (`${selectedFrom.toLocaleDateString()} - ${selectedTo.toLocaleDateString()}`) || (strings.TRANSACTIONS.PERIOD_LABEL)}</Text>
                                <FontIcon iconName={isShowRangeSelectCallout ? "ChevronUp" : "ChevronDown"} />
                            </Stack>
                            {isShowRangeSelectCallout &&
                                <Callout
                                    role="dialog"
                                    isBeakVisible={false}
                                    gapSpace={8}
                                    target={`.${dateRangeButtonClassname}`}
                                    onDismiss={hideRangeSelectCallout}
                                    setInitialFocus
                                    alignTargetEdge
                                >
                                    <Stack>
                                        <Stack.Item>
                                            <Stack horizontal verticalAlign="center" style={{ paddingTop: 16, paddingLeft: 16, paddingRight: 16 }} horizontalAlign="space-between">
                                                <Stack.Item>
                                                    <Text>{strings.TRANSACTIONS.PERIOD_TITLE}</Text>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Text>{preSelectedFrom?.toLocaleDateString() ?? strings.TRANSACTIONS.NOT_CHOOSEN} - {preSelectedTo?.toLocaleDateString() ?? strings.TRANSACTIONS.NOT_CHOOSEN}</Text>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Calendar
                                                minDate={preSelectedFrom ? new Date(preSelectedFrom.getFullYear(), preSelectedFrom.getMonth() - 1, preSelectedFrom.getDate()) : undefined}
                                                maxDate={preSelectedFrom ? new Date(preSelectedFrom.getFullYear(), preSelectedFrom.getMonth() + 1, preSelectedFrom.getDate()) : undefined}
                                                highlightSelectedMonth highlightCurrentMonth showWeekNumbers
                                                calendarDayProps={{ styles: { dayMarker: { background: theme.palette.themePrimary } }, markedDays: (selectedFrom && selectedTo && preSelectedFrom && preSelectedTo) ? getDaysArray(selectedFrom, selectedTo) : undefined }}
                                                onSelectDate={(date) => handleDateRangeSelect(date)} strings={strings.DAY_PICKER} />
                                        </Stack.Item>
                                    </Stack>
                                </Callout>}
                        </CommandBarButton>
                        <CommandBarButton iconProps={{ iconName: "ExcelDocument" }} menuProps={exportMenuProps} onRenderMenuIcon={() => null} />
                        {/* <CommandBarButton iconProps={{ iconName: "ColumnOptions" }} /> */}
                        <CommandBarButton iconProps={{ iconName: "Settings" }} onClick={showSettingsModal} />
                    </Stack>
                </ScreenHeader>
                {!logsState.fetched && (
                    <Stack verticalAlign="center" verticalFill grow={1}>
                        <Spinner label={strings.TRANSACTIONS.SPINNER_LABEL_WAIT} />
                    </Stack>
                ) || (
                        <Stack verticalFill>
                            {!error && (
                                <Stack.Item style={{ padding: "0 32px" }}>
                                    <Filter searchString={searchString}
                                        options={filterOptions}
                                        selectedFilterOptions={selectedFilterOptions ?? {}}
                                        setSelectedFilterOption={setSelectedFilterOption}
                                        setSearchString={setSearchString} />
                                </Stack.Item>
                            )}
                            <Stack.Item verticalFill style={{ position: "relative" }}>
                                {error && (
                                    <SomethingWentWrong action={() => requestEntries(selectedFrom?.toDateString(), selectedTo?.toDateString())} />
                                )}
                                {((logsState.data?.length === 0) || !logsState.data) && (
                                    <ItemsNotFound onClick={() => { }} info={strings.TRANSACTIONS.PERIOD_IS_EMPTY} suggestion={strings.TRANSACTIONS.PERIOD_IS_EMPTY_SUGGESTION} doNotUseButton imgSrc={"images/navigation/images/no_results.png"} />
                                )}
                                {logsState.data && logsState.data.length > 0 && ((items && items.length > 0) && (
                                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                        <LogDetailsList columns={propsColumns} getDetails={showTransactionHandler} entries={items} />
                                    </ScrollablePane>
                                ) || (
                                        <ItemsNotFound onClick={() => requestEntries(selectedFrom?.toDateString(), selectedTo?.toDateString(), searchString)} info={strings.TRANSACTIONS.FILTER_IS_EMPTY} suggestion={strings.TRANSACTIONS.FILTER_IS_EMPTY_SUGGESTION} buttonText={strings.TRANSACTIONS.FILTER_IS_EMPTY_BUTTON_TEXT} imgSrc={"images/navigation/images/not_found.png"} />
                                    ))}
                            </Stack.Item>
                        </Stack>
                    )}
            </Stack>
            <Modal styles={{
                main: {
                    width: "60%", height: "80%", minHeight: 600
                },
                scrollableContent: {
                    width: "100%", height: "100%"
                }
            }} isOpen={transactionId ? true : false} onDismiss={cancelHandler} isBlocking={true}>
                {transactionId && <TransactionResult hideModal={cancelHandler} transactionId={transactionId} />}
            </Modal>
            <Modal isOpen={isShowSettingsModal} onDismiss={hideSettingsModal}>
                <div className={contentStyles.header}>
                    <span style={{ paddingRight: 32 }}>{strings.TRANSACTIONS.SETTINGS_MODAL.HEADER}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close"
                        onClick={hideSettingsModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <ComingSoon />
                </div>

            </Modal>
            <Modal isOpen={isShowExportRangeSelectModal} onDismiss={hideExportRangeSelectModal}>
                <div className={contentStyles.header}>
                    <span style={{ paddingRight: 32 }}>{strings.TRANSACTIONS.EXPORT.MODAL.HEADER}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close"
                        onClick={hideExportRangeSelectModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign="center" style={{ paddingTop: 16, paddingLeft: 16, paddingRight: 16 }} horizontalAlign="space-between">
                                <Text>{strings.TRANSACTIONS.PERIOD_TITLE}</Text>
                                <Text>{exportPreSelectedFrom?.toLocaleDateString() ?? strings.TRANSACTIONS.NOT_CHOOSEN} - {exportPreSelectedTo?.toLocaleDateString() ?? strings.TRANSACTIONS.NOT_CHOOSEN}</Text>
                            </Stack>
                            <Calendar
                                minDate={exportPreSelectedFrom ? new Date(exportPreSelectedFrom.getFullYear(), exportPreSelectedFrom.getMonth() - 1, exportPreSelectedFrom.getDate()) : undefined}
                                maxDate={exportPreSelectedFrom ? new Date(exportPreSelectedFrom.getFullYear(), exportPreSelectedFrom.getMonth() + 1, exportPreSelectedFrom.getDate()) : undefined}
                                highlightSelectedMonth highlightCurrentMonth calendarDayProps={{ styles: { dayMarker: { background: theme.palette.themePrimary } }, markedDays: (exportSelectedFrom && exportSelectedTo && exportSelectedFrom && exportPreSelectedTo) ? getDaysArray(exportSelectedFrom, exportSelectedTo) : undefined }} onSelectDate={(date) => handleDateExportRangeSelect(date)} strings={strings.DAY_PICKER} />
                        </Stack.Item>
                        <Stack horizontal horizontalAlign='end'>
                            <Stack.Item>
                                <PrimaryButton text={strings.BUTTONS.TEXT.CONFIRM} disabled={!(exportSelectedFrom && exportSelectedTo)} onClick={() => onExportTransaction(ExportPeriodTypes.Choosed)} />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </div>
            </Modal>
        </>
    )
}

const getTransactionFlagTitleByKey = (flag: string): string => {
    switch (flag) {
        case TransactionLogFlags.Created: return strings.FILTER.FLAGS.FINISHED
        case TransactionLogFlags.Canceled: return strings.FILTER.FLAGS.CANCELED
        case TransactionLogFlags.Unfinished: return strings.FILTER.FLAGS.STARTED
        default: return flag
    }
}

function getFilterOptions(entries: ILogEntry[]): TFilterOptions {
    var service = new Set<string>();
    var flag = new Set<string>();
    var status = new Set<string>();
    var point = new Map();
    var company = new Set<string>();
    var serviceType = new Set<string>();
    var channel = new Set<string>();
    entries.map((i: ILogEntry) => {
        service.add(i["service"])
        flag.add(i["flag"])
        point.set(i["pointName"], i["pointCode"])
        company.add(i["companyName"])
        status.add(i["status"])
        serviceType.add( i["serviceType"]);
        channel.add( i[ "channel"]);
    });
    var servicesOptions = Array<TFilterOptionValue>()
    var flagsOptions = Array<TFilterOptionValue>()
    var statusesOptions = Array<TFilterOptionValue>()
    var pointsOptions = Array<TFilterOptionValue>()
    var companiesOptions = Array<TFilterOptionValue>()
    var serviceTypesOptions = Array<TFilterOptionValue>()
    var channelsOptions = Array<TFilterOptionValue>()
    Array.from(service).map((ft: string) => {
        servicesOptions.push({ key: ft });
    })
    Array.from(flag).map((ft: string) => {
        flagsOptions.push({ key: ft, title: getTransactionFlagTitleByKey(ft) });
    })
    Array.from(status).map((ft: string) => {
        statusesOptions.push({ key: ft });
    })
    Array.from(point).map((v: [string, string]) => {
        pointsOptions.push({ key: v[0], title: `${v[1]} ${v[0]}` });
    })
    Array.from(company).map((ft: string) => {
        companiesOptions.push({ key: ft });
    })
    Array.from(serviceType).map((ft: string) => {
        serviceTypesOptions.push({ key: ft });
    })
    Array.from(channel).map((ft: string) => {
        channelsOptions.push({ key: ft });
    })
    return ({
        //
        // All keys must be indetical with item keys !!!
        //
        service: {
            values: servicesOptions,
            title: strings.TRANSACTIONS.COLUMNS.SERVICE
        },
        flag: {
            values: flagsOptions,
            title: strings.TRANSACTIONS.COLUMNS.FLAG
        },
        pointName: {
            values: pointsOptions,
            title: strings.TRANSACTIONS.COLUMNS.POINT,
            hasValuesFilter: true
        },
        companyName: {
            values: companiesOptions,
            title: strings.TRANSACTIONS.COLUMNS.COMPANY,
            hasValuesFilter: true
        },
        status: {
            values: statusesOptions,
            title: strings.TRANSACTIONS.COLUMNS.STATUS
        },
        channel: {
            values: channelsOptions,
            title: strings.TRANSACTIONS.COLUMNS.SERVICE_CHANNEL
        },
        serviceType: {
            values: serviceTypesOptions,
            title: strings.TRANSACTIONS.COLUMNS.SERVICE_TYPE
        },
    })
}

const theme = getTheme();

const classNames = mergeStyleSets({
    itemCell: [
        {
            padding: 0,
            paddingLeft: 0,
            paddingRight: 0,
            height: 14,
            color: theme.palette.neutralDark,
            selectors: {
                '&:hover': {
                    color: theme.palette.blue
                },
                '&:focus': {
                    color: theme.palette.blue
                },
            },
        },
    ],
    container: {
        heigth: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            // flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '6px 24px 24px 24px',
        overflow: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});


const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
