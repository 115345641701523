import { Link, SharedColors, Stack, Text, Toggle } from "@fluentui/react"
import { CustomSeparator } from "../../../../../components/shared/decorations/CustomSeparator"
import { RemoveArea } from "../../../../../components/shared/decorations/RemoveArea"
import { IPerson } from "../../../../../core/store"
import { strings } from "../../../../../localization/strings"
import { PersonInformationPivot } from "./pivots/PersonInformationPivot"
import { TPersonPivotState } from "./StructurePerson"

export type TStructurePersonPivotBasedStateProps = {
    state: TPersonPivotState
    item: IPerson
    onDelete: () => void
    toggleEnabled: () => void
}

export const PersonPivotBasedState: React.FC<TStructurePersonPivotBasedStateProps> = (props) => {
    switch (props.state) {
        case "cert": return (<></>)
        case "information": return (
            <Stack tokens={{ padding: "24px 32px", childrenGap: 16 }}>
                <PersonInformationPivot item={props.item} />
                <CustomSeparator />
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.LOCK_AREA.TITLE}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.LOCK_AREA.TEXT}</Text>
                        <br />
                        <Link href={"#"}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.LOCK_AREA.HREF_TEXT}</Link>
                    </Stack.Item>
                    <Stack.Item style={{ paddingTop: 8 }}>
                        <Toggle onClick={props.toggleEnabled} checked={!props.item.enabled} onText={strings.BUTTONS.TOGGLE_ENABLE} offText={strings.BUTTONS.TOGGLE_DISABLE} />
                    </Stack.Item>
                </Stack>
                <CustomSeparator />
                <RemoveArea
                    href="1109"
                    hrefText={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_AREA.HREF_TEXT}
                    onDelete={props.onDelete}
                    text={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_AREA.TEXT}
                    title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_AREA.TITLE} />
            </Stack>
        )
    }
}
