export enum AllowedCurrencies {
    CNY = "CNY",
    EUR = "EUR",
    GBP = "GBP",
    MXN = "MXN",
    RUR = "RUR",
    USD = "USD",
    ZAR = "ZAR",
    COP = "COP",
    PEN = "PEN",
    BRL = "BRL",
    ARS = "ARS",
    CLP = "CLP",
    SAR = "SAR",
    JOD = "JOD",
    KWD = "KWD",
    YER = "YER",
    AED = "AED",
    OMR = "OMR",
    QAR = "QAR",
    INR = "INR",
    EGP = "EGP",
    SEK = "SEK",
    CHF = "CHF",
    PLN = "PLN",
    CAD = "CAD",
    KES = "KES",
    XOF = "XOF",
    NGN = "NGN",
    MYR = "MYR",
    IDR = "IDR",
    THB = "THB",
    VND = "VND",
    RON = "RON",
    DOP = "DOP",
    PKR = "PKR",
}