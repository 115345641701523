import { Stack, IStackStyles, getTheme, ScrollablePane } from "@fluentui/react"
import { CustomSeparator } from "../../../../components/shared/decorations/CustomSeparator"
import { RemoveArea } from "../../../../components/shared/decorations/RemoveArea"
import { Section } from "../../../../components/shared/decorations/Section"
import { ICompany } from "../../../../core/store"
import { strings } from "../../../../localization/strings"
import { TCompanyPivotState } from "../CompanyDetails"
import { CompanyCertificatesPivot } from "./pivots/CompanyCertificatesPivot"
import { CompanyHelpPivot } from "./pivots/CompanyHelpPivot"
import { CompanyInformationPivot } from "./pivots/CompanyInformationPivot"
import { CompanyIntegrationPivot } from "./pivots/CompanyIntegrationsPivot"
import { CompanyPointsPivot } from "./pivots/CompanyPointsPivot"
import { CompanyServicesPivot } from "./pivots/CompanyServicesPivot"
import { CompanySettingsPivot } from "./pivots/CompanySettingsPivot"



export type TCompanyPivotBasedStateProps = {
    state: TCompanyPivotState
    data: ICompany
    onDelete: () => void
    retryInfo: () => void
    setInformation: (information: string) => void
    isFetching: boolean
    resetInfoWasSaved: () => void
    infoWasSaved: boolean
    updateState: (item: ICompany) => void
}

export const CompanyPivotBasedState: React.FC<TCompanyPivotBasedStateProps> = (props) => {
        switch (props.state) {
        case "information": return (
            <Stack tokens={{ padding: "24px 32px 0px 32px", childrenGap: 16 }}>
                <Section title={strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INFORMATION.LEGAL_DATA}>
                    <CompanyInformationPivot data={props.data} />
                </Section>
                <CustomSeparator />
                <RemoveArea
                    hrefText={strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_INFO_MORE_HELP}
                    href='1100'
                    onDelete={props.onDelete}
                    text={strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_INFO_TEXT}
                    title={strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_TITLE} />
            </Stack>
        )
        case "services": return props.data.id ? (
            <Stack.Item verticalFill style={{ position: 'relative' }}>
                <ScrollablePane>
                    <CompanyServicesPivot id={props.data.id} />
                </ScrollablePane>
            </Stack.Item>
        ) : null
        case "points": return (
            <Stack grow verticalFill>
                <Stack grow verticalFill verticalAlign='space-between'>
                    <CompanyPointsPivot data={props.data} />
                </Stack>
            </Stack>
        )
        case "settings": return <CompanySettingsPivot item={props.data} updateState={props.updateState} />
        case "certificates": return <CompanyCertificatesPivot />
        case "help": return <CompanyHelpPivot
            disabled={props.data.removed}
            information={props.data.information}
            setInformation={props.setInformation}
            isFetching={props.isFetching}
            infoWasSaved={props.infoWasSaved}
            resetInfoWasSaved={props.resetInfoWasSaved}
        />
        case "integration": return <CompanyIntegrationPivot />
    }
}
