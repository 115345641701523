import { Stack, ScrollablePane, Icon, Text, getTheme, Link } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators, getStageInspectionModules } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";
import { SecondaryButton } from "../../../../buttons/SecondaryButton";
import { Section } from "../../../../decorations/Section";
import { ParameterItem } from "../../../components/ParameterItem";
import { IStore } from "../../../../../../core/store";
import { IInspectionConfig, IInspectionConfigUI, Inspections, ProcessStages, IInspectionModuleConfig } from "@piceasoft/core";

type TProps = {
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
}

export const InspectionUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => getStageInspectionModules(props.stage, s)?.find(i => i.index === props.index) as IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>)
    const [isMoreOptionsShow, { setTrue: showMoreOptions, setFalse: hideMoreOptions }] = useBoolean(false)

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.HEADER} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.TITLE_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.config.ui?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                ...config.config,
                                ui: {
                                    ...config.config.ui,
                                    title: config.config.ui?.title !== undefined ? undefined : ""
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.title !== undefined ? true : false}
                        textFieldValue={config.config.ui?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                            ...config.config,
                            ui: {
                                ...config.config.ui,
                                title: val
                            }
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.config.ui?.icon !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                ...config.config,
                                ui: {
                                    ...config.config.ui,
                                    icon: config.config.ui?.icon !== undefined ? undefined : ""
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.icon !== undefined ? true : false}
                        textFieldValue={config.config.ui?.icon}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                            ...config.config,
                            ui: {
                                ...config.config.ui,
                                icon: val
                            }
                        }))}
                    >
                        <Stack grow>
                            <Text variant="small" >{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION_LINK_TEXT1}
                                <Link target="_blank" href={'https://developer.microsoft.com/en-us/fluentui#/styles/web/icons#available-icons'} style={{ fontSize: 12, marginLeft: 6, marginRight: 4, }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION_LINK}</Link>
                                <Text variant="small" style={{ flexWrap: 'wrap' }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION_LINK_TEXT2}</Text>
                            </Text>
                            <Text variant="small">{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.ICON_DESCRIPTION_LINK_TEXT3}</Text>
                        </Stack>
                    </ParameterItem>
                    <Stack style={{ borderTop: `1px solid ${theme.palette.neutralLight}` }} tokens={{ padding: "16px 0 16px 160px" }}>
                        <Stack.Item>
                            <SecondaryButton onClick={isMoreOptionsShow ? hideMoreOptions : showMoreOptions}>
                                <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                    <Icon iconName={isMoreOptionsShow ? "ChevronUp" : "ChevronDown"} style={{ fontWeight: 500 }} />
                                    <Text style={{ fontWeight: 500 }}>{strings.BUTTONS.TEXT.ADVANCED}</Text>
                                </Stack>
                            </SecondaryButton>
                        </Stack.Item>
                    </Stack>
                    {isMoreOptionsShow && props.children}
                    {isMoreOptionsShow && (
                        <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.BUTTONS.EXECUTE_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={typeof config.config.ui?.executeButtonText === 'string' ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                    ...config.config,
                                    ui: {
                                        ...config.config.ui,
                                        executeButtonText: typeof config.config.ui?.executeButtonText === 'string' ? undefined : ""
                                    }
                                }))
                            }}
                            description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.BUTTONS.EXECUTE_DESCRIPTION}
                            useTextField
                            textFieldEnabled={typeof config.config.ui?.executeButtonText === 'string' ? true : false}
                            textFieldValue={config.config.ui?.executeButtonText}
                            textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                ...config.config,
                                ui: {
                                    ...config.config.ui,
                                    executeButtonText: (val && val.trim().length > 0) ? val : undefined
                                }
                            }))}
                        />
                    )}
                    {isMoreOptionsShow && config.config.allowRestart && (
                        <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.BUTTONS.RESTART_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={typeof config.config.ui?.restartButtonText === 'string' ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                    ...config.config,
                                    ui: {
                                        ...config.config.ui,
                                        restartButtonText: typeof config.config.ui?.restartButtonText === 'string' ? undefined : ""
                                    }
                                }))
                            }}
                            description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.BUTTONS.RESTART_DESCRIPTION}
                            useTextField
                            textFieldEnabled={typeof config.config.ui?.restartButtonText === 'string' ? true : false}
                            textFieldValue={config.config.ui?.restartButtonText}
                            textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                ...config.config,
                                ui: {
                                    ...config.config.ui,
                                    restartButtonText: (val && val.trim().length > 0) ? val : undefined
                                }
                            }))}
                        />
                    )}
                    {isMoreOptionsShow && config.config.allowSkip && (
                        <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.BUTTONS.SKIP_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={typeof config.config.ui?.skipButtonText === 'string' ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                    ...config.config,
                                    ui: {
                                        ...config.config.ui,
                                        skipButtonText: typeof config.config.ui?.skipButtonText === 'string' ? undefined : ""
                                    }
                                }))
                            }}
                            description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.BUTTONS.SKIP_DESCRIPTION}
                            useTextField
                            textFieldEnabled={typeof config.config.ui?.skipButtonText === 'string' ? true : false}
                            textFieldValue={config.config.ui?.skipButtonText}
                            textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                ...config.config,
                                ui: {
                                    ...config.config.ui,
                                    skipButtonText: (val && val.trim().length > 0) ? val : undefined
                                }
                            }))}
                        />
                    )}
                    {isMoreOptionsShow && [Inspections.Interview, Inspections.Photographic].includes(config.type) && (
                        <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.PROGRESS.LABEL_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.config.ui?.progress?.label !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                    ...config.config,
                                    ui: {
                                        ...config.config.ui,
                                        progress: {
                                            ...config.config.ui?.progress,
                                            label: config.config.ui?.progress?.label !== undefined ? undefined : ""
                                        }
                                    }
                                }))
                            }}
                            description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.PROGRESS.LABEL_DESCRIPTION}
                            useTextField
                            textFieldEnabled={config.config.ui?.progress?.label !== undefined ? true : false}
                            textFieldValue={config.config.ui?.progress?.label}
                            textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                ...config.config,
                                ui: {
                                    ...config.config.ui,
                                    progress: {
                                        ...config.config.ui?.progress,
                                        label: (val && val.trim().length > 0) ? val : undefined
                                    }
                                }
                            }))}
                        />
                    )}
                    {isMoreOptionsShow && [Inspections.Interview, Inspections.Photographic].includes(config.type) && (
                        <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.PROGRESS.DESCRIPTION_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.config.ui?.progress?.description !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                    ...config.config,
                                    ui: {
                                        ...config.config.ui,
                                        progress: {
                                            ...config.config.ui?.progress,
                                            description: config.config.ui?.progress?.description !== undefined ? undefined : ""
                                        }
                                    }
                                }))
                            }}
                            description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.PROGRESS.DESCRIPTION_DESCRIPTION}
                            useTextField
                            textFieldEnabled={config.config.ui?.progress?.description !== undefined ? true : false}
                            textFieldValue={config.config.ui?.progress?.description}
                            textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, config.index, {
                                ...config.config,
                                ui: {
                                    ...config.config.ui,
                                    progress: {
                                        ...config.config.ui?.progress,
                                        description: (val && val.trim().length > 0) ? val : undefined
                                    }
                                }
                            }))}
                        />
                    )}
                </Section>
            </ScrollablePane>
        </Stack.Item>
    )
}

const theme = getTheme();