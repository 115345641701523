import { css, FontIcon, FontWeights, getTheme, IconButton, ITooltipHostStyles, mergeStyleSets, Stack, Text, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import React from 'react';

type TProps = {
    icon: string
    title: string
    noHelp?: boolean
}

const IdentificationHeader: React.FC<TProps> = (props) => {
    return (
        <Stack horizontal verticalAlign="center" style={{minHeight: 32}} tokens={{ childrenGap: 12 }}>
            {props.icon && props.icon.length > 0 && (
                <FontIcon iconName={props.icon} style={{ fontSize: 24, height: 24, width: 24, color: SharedColors.gray10 }} />
            )}
            <Stack.Item grow={1}>
                <TooltipHost
                    overflowMode={TooltipOverflowMode.Self}
                    styles={tooltipStyles}
                    hostClassName={css(styles.title)}
                    content={props.title}
                >
                    {props.title}
                </TooltipHost>
                {/* <Text variant="mediumPlus" nowrap block style={{ maxWidth: 200, fontWeight: "bold", color: SharedColors.gray40 }}>{props.title}</Text> */}
            </Stack.Item>
            {props.noHelp !== true && (
                <IconButton styles={iconButtonStyles} iconProps={{ iconName: "UnknownSolid" }} ariaLabel="Show help" />
            )}
        </Stack>
    )
}

export default IdentificationHeader

const theme = getTheme()
const iconButtonStyles = {
    root: {
        color: theme.palette.blue,
        marginLeft: 'auto'
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    }
}

const styles = mergeStyleSets({
    title: [
        theme.fonts.mediumPlus,
        {
            fontWeight: FontWeights.bold,
            color: SharedColors.gray40,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 200,
        }
    ]
}) 

const tooltipStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'block' }
}
