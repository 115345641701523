import { Dropdown, IDropdownOption, SelectableOptionMenuItemType, Stack } from "@fluentui/react"
import React from "react"
import { Dispatch } from "react"
import { useSelector } from "react-redux"
import { actionCreators } from "../../../../../../../../core/actions/configurator-actions"
import { IStore } from "../../../../../../../../core/store"
import { 
    AIGradingImageTypes, ProcessStages, AIGradingUploadMethods, IGrade,
    IAIGradingConfig, IAIGradingDefaultConfig, IGradesCategory
} from "@piceasoft/core"
import { aiGradingImageTypesDescriber, aiGradingImageTypesDescriberShort, aiGradingUploadMethodsDescriber } from "../../../../../../../../localization/helpers/inspections"
import { strings } from "../../../../../../../../localization/strings"
import { ParameterItem } from "../../../../../components/ParameterItem"
import s from '../../../../../../../../assets/css/aigrading.module.css'
import { Experience } from "../../../../../helpers/evisibility"

export type TModeConfiguratorCommonPivotProps = {
    index: number
    stage: ProcessStages
    aigrading: IAIGradingConfig
    onChangeDispatch: Dispatch<any>
}

export const DefaultModeCommonPivot: React.FC<TModeConfiguratorCommonPivotProps> = ({ aigrading, stage, index, onChangeDispatch }) => {
    const grades = useSelector((s: IStore) => s.configurator.useGradesCategories ? s.configurator.gradesCategories?.find(i => i.code === aigrading.gradesCategory)?.grades : s.configurator.grades)
    const gradesCategories = useSelector((s: IStore) => s.configurator.gradesCategories)
    const channel = useSelector((s: IStore) => s.configurator.channel) 
    const prepareGradesOptions = (grades: IGrade[]): IDropdownOption[] => {
        return (grades ?? []).map(i => ({ text: `${i.name}`, key: `${i.code}` }))
    }
    
    const prepareGradesCategoriesOptions = (gradesCategories: IGradesCategory[]): IDropdownOption[] => {
        let options: IDropdownOption[] = []
        gradesCategories.forEach(gc => {
            if (gc.name) {
                options.push({ key: 'gradesCategory-' + gc.code, text: gc.name, itemType: SelectableOptionMenuItemType.Header })
                gc.grades.forEach(g => { options.push({ key: `${gc.code}-${g.code}`, text: `${g.name} (${g.code})` }) })
                if (gc.index < gradesCategories.length) {
                    options.push({ key: 'gradesCategoryDivider-' + gc.code, text: gc.name, itemType: SelectableOptionMenuItemType.Divider })
                }
            }
        })
        return options;
    }

    const uploadMethodsOptions: IDropdownOption[] = [
        {
            key: AIGradingUploadMethods.Remote,
            text: aiGradingUploadMethodsDescriber(AIGradingUploadMethods.Remote)
        },
        {
            key: AIGradingUploadMethods.Camera,
            text: aiGradingUploadMethodsDescriber(AIGradingUploadMethods.Camera),
            disabled: channel === Experience.Mobile || channel === Experience.MobileSDK
        },
        {
            key: AIGradingUploadMethods.Upload,
            text: aiGradingUploadMethodsDescriber(AIGradingUploadMethods.Upload)
        }
    ]

    const imageTypesOptions: IDropdownOption[] = Object.keys(AIGradingImageTypes)
        .filter(k => {
            const imageType = Number(k);

            return !isNaN(imageType) && (imageType == AIGradingImageTypes.ScreenOff || imageType == AIGradingImageTypes.Back);
        }).map(t => ({
            key: Number.parseInt(t),
            text: aiGradingImageTypesDescriber(Number.parseInt(t))
        }))

    // Filter not available image types.
    const imageTypes = aigrading.config.imageTypes.filter(i => imageTypesOptions.find(o => i === o.key));
    const gradesOptions: IDropdownOption[] = [
        ...(gradesCategories ? prepareGradesCategoriesOptions(gradesCategories) : prepareGradesOptions(grades ?? [])),
        {
            key: '!DECLINE',
            text: strings.CONSTRUCTOR.GRADES.DECLINE
        }
    ]

    const getGradeCodeOption = (grade?: string) => {
        if (grade === "") {
            return "!DECLINE"
        }
        return grade
    }

    const getGradeOptionCode = (grade?: string) => {
        if (grade === "!DECLINE") {
            return ""
        }
        return grade
    }

    const getGradingTableEditComponent = (gradeKey: string, defaultGrade: string) => {
        const selectedKey = getGradeCodeOption(aigrading?.config?.resultGradesNew?.find((g: { type: string }) => g.type === gradeKey)?.grade ?? defaultGrade);
        return (
            <Dropdown
                options={gradesOptions}
                selectedKey={selectedKey}
                placeholder={strings.CONSTRUCTOR.GRADES.GRADE_PLACEHOLDER}
                onChange={(ev, opt) => {
                    onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(stage, index, {
                        ...aigrading,
                        config: {
                            ...aigrading?.config,
                            resultGradesNew: [
                                ...(aigrading?.config?.resultGradesNew?.filter((g: { type: string }) => g.type !== gradeKey) ?? []),
                                { type: gradeKey, grade: getGradeOptionCode(opt?.key?.toString() ?? defaultGrade) }
                            ]
                        }
                    }
                    ));
                }}
            />
        );
    }

    const getGradingTables = () => {
        let tables = [];

        if(imageTypes.length === 1){
                const table = getSingularGradingTable(imageTypes[0].toString());
                tables.push(table);
        }
        else{
            for (let i = 0; i < imageTypes.length - 1; i++) {
                for (let j = i + 1; j < imageTypes.length; j++) {
                    const table = getGradingTable(imageTypes[i].toString(), imageTypes[j].toString());
    
                    tables.push(table);
                }
            }
        }

        return (
            <>
                {tables.map(t => t)}
            </>
        );
    }

    const getSingularGradingTable = (imageType: string) => {
        const imageTypeName = imageTypesOptions.find(o => imageType == o.key);

        if (!imageTypeName) {
            return <div>{`${strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.IMAGE_TYPES_WARNING} '${imageType}'.`}</div>;
        }

        const imageTypeShortDescription = aiGradingImageTypesDescriberShort(imageTypeName.key as AIGradingImageTypes);

        return (
            <table key={`grading_table_${imageType}`}>
            <thead>
                <tr>
                    <th className={s.gradingMatrixColSplit}>
                        <div className={s.gradingMatrixColSplitCont}>
                            <div className={s.gradingMatrixColSplitFirst}>
                               
                            </div>
                        </div>
                    </th>
                    <th className={s.gradingMatrixTblColHead}>A</th>
                    <th className={s.gradingMatrixTblColHead}>B</th>
                    <th className={s.gradingMatrixTblColHead}>C</th>
                    <th className={s.gradingMatrixTblColHead}>D</th>
                </tr>
            </thead>
            <tbody>
                    <tr>
                        <td className={s.gradingMatrixHeaderColHorizontal}> {imageTypeShortDescription} </td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType}_:A_A`, 'A')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType}_:B_A`, 'B')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType}_:C_A`, 'C')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType}_:D_A`, 'D')}</td>
                    </tr>
            </tbody>
            </table>
        )
    }

    const getGradingTable = (imageType1: string, imageType2: string) => {
        const imageTypeName1 = imageTypesOptions.find(o => imageType1 == o.key);
        const imageTypeName2 = imageTypesOptions.find(o => imageType2 == o.key);

        if (!imageTypeName1) {
            return <div>{`${strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.IMAGE_TYPES_WARNING} '${imageType1}'.`}</div>;
        }

        if (!imageTypeName2) {
            return <div>{`${strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.IMAGE_TYPES_WARNING} '${imageType2}'.`}</div>;
        }

        const imageTypeShortDescription1 = aiGradingImageTypesDescriberShort(imageTypeName1.key as AIGradingImageTypes);
        const imageTypeShortDescription2 = aiGradingImageTypesDescriberShort(imageTypeName2.key as AIGradingImageTypes);

        let rowGrades: IGrade[] | undefined = [];

        if (gradesCategories) {
            gradesCategories.forEach(gc => {
                if (!gc.name) {
                    return;
                }

                gc.grades.forEach(g => {
                    rowGrades?.push({
                        code: g.code,
                        name: `${gc.name}:${g.name}`,
                        index: g.index,
                        explanation: "",
                        description: ""
                    });
                });
            })
        } else {
            rowGrades = grades;
        }

        return (
            <table key={`grading_table_${imageType1}_${imageType2}`}>
                <thead>
                    <tr>
                        <th className={s.gradingMatrixColSplit}>
                            <div className={s.gradingMatrixColSplitCont}>
                                <div className={s.gradingMatrixColSplitFirst}>
                                    {imageTypeShortDescription2}
                                </div>
                                <div className={s.gradingMatrixColSplitSecond}>
                                    {imageTypeShortDescription1}
                                </div>
                            </div>
                        </th>
                        <th className={s.gradingMatrixTblColHead}>A</th>
                        <th className={s.gradingMatrixTblColHead}>B</th>
                        <th className={s.gradingMatrixTblColHead}>C</th>
                        <th className={s.gradingMatrixTblColHead}>D</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={s.gradingMatrixHeaderColHorizontal}>A</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:A_A`, 'A')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:B_A`, 'B')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:C_A`, 'C')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:D_A`, 'D')}</td>
                    </tr>
                    <tr>
                        <td className={s.gradingMatrixHeaderColHorizontal}>B</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:A_B`, 'A')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:B_B`, 'B')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:C_B`, 'C')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:D_B`, 'D')}</td>
                    </tr>
                    <tr>
                        <td className={s.gradingMatrixHeaderColHorizontal}>C</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:A_C`, 'A')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:B_C`, 'B')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:C_C`, 'C')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:D_C`, 'D')}</td>
                    </tr>
                    <tr>
                        <td className={s.gradingMatrixHeaderColHorizontal}>D</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:A_D`, 'A')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:B_D`, 'B')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:C_D`, 'C')}</td>
                        <td className={s.gradingMatrixColValue}>{getGradingTableEditComponent(`${imageType1}_${imageType2}:D_D`, 'D')}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    return (
        <Stack>
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.METHODS_TITLE}
                noSeparator
                useToggle={false}
                useDropdown
                dropdownEnabled
                dropdownMultiselect
                dropdownSelectedKeys={aigrading.config.uploadMethods.filter((key) => key !== AIGradingUploadMethods.Camera || (channel !== Experience.Mobile))}
                dropdownOptions={uploadMethodsOptions}
                dropdownOnChange={(opt) => opt && onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(stage, index, {
                    ...aigrading,
                    config: {
                        ...aigrading.config,
                        uploadMethods: opt?.selected && !opt.disabled ? (
                            (aigrading.config.uploadMethods ? [...aigrading.config.uploadMethods, opt.key] : [opt.key])
                        ) : (
                            aigrading.config.uploadMethods?.length > 1 ? aigrading.config.uploadMethods.filter(i => i !== opt?.key) : aigrading?.config?.uploadMethods
                        ),
                        ui: {
                            ...aigrading.ui,
                            uploadMethods: {
                                remote: (opt.key === AIGradingUploadMethods.Remote && !opt.selected) ? undefined : aigrading.config?.ui?.uploadMethods?.remote,
                                camera: (opt.key === AIGradingUploadMethods.Camera && !opt.selected) ? undefined : aigrading.config?.ui?.uploadMethods?.camera,
                                upload: (opt.key === AIGradingUploadMethods.Upload && !opt.selected) ? undefined : aigrading.config?.ui?.uploadMethods?.upload
                            }
                        },
                    } as IAIGradingDefaultConfig
                } as IAIGradingConfig
                ))}
                description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.METHODS_DESCRIPTION}
            />
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.DEFAULT_METHOD_TITLE}
                useToggle
                toggleEnabled={aigrading.config.uploadMethods?.length > 0}
                toggleChecked={aigrading.config.defaultUploadMethod !== undefined}
                toggleOnChange={() => onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(stage, index, {
                    ...aigrading,
                    config: {
                        ...aigrading.config,
                        defaultUploadMethod: aigrading.config.defaultUploadMethod !== undefined ? undefined : aigrading.config.uploadMethods[0]
                    } as IAIGradingDefaultConfig
                } as IAIGradingConfig
                ))}
                useDropdown
                dropdownEnabled={aigrading.config.defaultUploadMethod !== undefined}
                dropdownSelectedKey={aigrading.config.defaultUploadMethod ?? ""}
                dropdownOptions={aigrading.config.uploadMethods.map(i => ({ key: i, text: aiGradingUploadMethodsDescriber(i) })) ?? []}
                dropdownOnChange={(opt) => opt && onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(stage, index, {
                    ...aigrading,
                    config: {
                        ...aigrading.config,
                        defaultUploadMethod: opt?.key,
                    } as IAIGradingDefaultConfig
                } as IAIGradingConfig
                ))}
                description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.DEFAULT_METHOD_DESCRIPTION}
            />
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.IMAGE_TYPES_TITLE}
                useToggle={false}
                useDropdown
                dropdownEnabled
                dropdownMultiselect
                dropdownSelectedKeys={imageTypes}
                dropdownOptions={imageTypesOptions}
                dropdownOnChange={(opt) => {
                    if (!opt) {
                        return;
                    }

                    let newImageTypes = opt.selected
                        ? (imageTypes ? [...imageTypes, opt.key] : [opt.key])
                        : (imageTypes.length > 1 ? imageTypes.filter(i => i !== opt?.key) : imageTypes);

                    onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(
                        stage,
                        index,
                        {
                            ...aigrading,
                            config: {
                                ...aigrading.config,
                                imageTypes: newImageTypes
                            } as IAIGradingDefaultConfig
                        } as IAIGradingConfig)
                    );
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.IMAGE_TYPES_DESCRIPTION}
            />
            {imageTypes && <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.GRADING_MATRIX_TITLE}
                useToggle={false}
                description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.GRADING_MATRIX_DESCRIPTION}
            >
                <Stack tokens={{ childrenGap: 8 }}>
                    {getGradingTables()}
                </Stack>
            </ParameterItem>
            }
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.FORCE_ROTATE_IMAGE_TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={aigrading.config.forceRotateImage}
                toggleOnChange={() => onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(stage, index, {
                    ...aigrading,
                    config: {
                        ...aigrading.config,
                        forceRotateImage: !aigrading.config.forceRotateImage
                    } as IAIGradingDefaultConfig
                } as IAIGradingConfig
                ))}
                description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.FORCE_ROTATE_IMAGE_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.GRADING_SHOW_HEATMAP_TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={aigrading.config.showHeatmap}
                toggleOnChange={() => onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(stage, index, {
                    ...aigrading,
                    config: {
                        ...aigrading.config,
                        showHeatmap: !aigrading.config.showHeatmap
                    } as IAIGradingDefaultConfig
                } as IAIGradingConfig
                ))}
                description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.GRADING_SHOW_HEATMAP_DESCRIPTION}
            />
        </Stack>
    )
}