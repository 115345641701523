import { Stack, Text, getTheme, IDropdownOption, Dropdown, IDropdownStyles, Spinner, SharedColors } from "@fluentui/react"
import React from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { actionCreators } from "../../../core/actions/settings-actions";
import { prepareCurrencyOptions } from "../../../core/helpers/currency";
import { prepareRegionOptions, preparedLanguageOptions } from "../../../core/helpers/localization";
import { prepareTimezonesOptions } from "../../../core/helpers/timezone";
import { IStore } from "../../../core/store";
import { IOrganizationSettingsLocale } from "../../../core/store/typings/IOrganizationSettingsLocale";
import { strings } from "../../../localization/strings";

export type TSettingsLocaleTabProps = {
    data?: IOrganizationSettingsLocale
    isUpdating: boolean
}

export const SettingsLocaleTab: React.FC<TSettingsLocaleTabProps> = props => {

    const dispatch = useDispatch();

    const { control, handleSubmit, formState: { errors } } = useForm<IOrganizationSettingsLocale>({ defaultValues: { ...props.data, currency: props.data?.currency ?? 'undefined' } });
    const onSubmit: SubmitHandler<IOrganizationSettingsLocale> = data => {
        dispatch(actionCreators.updateOrganizationSettingsLocale({ ...data, currency: data.currency === 'undefined' ? undefined : data.currency }));
    };

    const timezones = prepareTimezonesOptions();
    const region = prepareRegionOptions();
    const currency = prepareCurrencyOptions();

    return (
        <Stack style={{ width: 640 }} tokens={{ padding: 24, childrenGap: 16 }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack style={{ width: 360 }} verticalFill tokens={{ childrenGap: 16 }} >
                        <Stack tokens={{ childrenGap: 16 }} >
                            <Stack.Item>
                                <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.GENERAL.LOCALE.TITLE}</Text>
                            </Stack.Item>
                            <Controller
                                name="timezone"
                                control={control}
                                rules={{ required: strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.TIMEZONE_REQUIRED }}
                                render={({ field }) =>
                                    <Dropdown
                                        selectedKey={field.value}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.TIMEZONE}
                                        placeholder={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.TIMEZONE_PLACEHOLDER}
                                        options={timezones}
                                        styles={timezonesDropdownStyles}
                                        errorMessage={errors.timezone?.message}
                                    />}
                            />
                            <Controller
                                name="lang"
                                control={control}
                                rules={{ required: strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.LANGUAGE_REQUIRED }}
                                render={({ field }) =>
                                    <Stack>
                                        <Dropdown
                                            selectedKey={field.value}
                                            onChange={(ev, opt) => field.onChange(opt?.key)}
                                            onBlur={field.onBlur}
                                            label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.LANGUAGE}
                                            placeholder={preparedLanguageOptions ? strings.SPINNERS.DATA_IS_GETTING : strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.LANGUAGE_PLACEHOLDER}
                                            options={preparedLanguageOptions}
                                            errorMessage={errors.lang?.message}
                                        />
                                        <Text style={{ color: SharedColors.gray30 }}>{strings.ORGANIZATION.SETTINGS.NEED_RESTART}</Text>
                                    </Stack>
                                }
                            />
                            <Controller
                                name="region"
                                control={control}
                                rules={{ required: strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.REGION_REQUIRED }}
                                render={({ field }) =>
                                    <Dropdown
                                        selectedKey={field.value}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.REGION}
                                        placeholder={region ? strings.SPINNERS.DATA_IS_GETTING : strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.REGION_PLACEHOLDER}
                                        options={region}
                                        errorMessage={errors.region?.message}
                                    />}
                            />
                            <Controller
                                name="currency"
                                control={control}
                                render={({ field }) =>
                                    <Dropdown
                                        selectedKey={field.value}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.CURRENCY}
                                        placeholder={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.CURRENCY_PLACEHOLDER}
                                        options={currency}
                                        errorMessage={errors.currency?.message}
                                    />}
                            />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <SecondaryButton disabled={props.isUpdating} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</SecondaryButton>
                            {props.isUpdating && (
                                <Spinner />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    )
}

const theme = getTheme();

const timezonesDropdownStyles: Partial<IDropdownStyles> = {
    dropdownItemsWrapper: { height: 300 },
};