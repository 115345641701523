import { DirectionalHint, getTheme, IButtonStyles, ICalloutContentStyles, ICalloutProps, IconButton, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useId } from "@uifabric/react-hooks";
import copy from "copy-to-clipboard";
import React from "react";

type TProps = {
    content: string
    copyText?: string
    copiedText?: string
    tooltipDelay?: TooltipDelay
    tooltipCalloutProps?: ICalloutProps
    buttonStyles?: IButtonStyles
    disabled?: boolean
    iconName?: string
}

export const CopyToClipBoardButton: React.FC<TProps> = (props) => {
    const tooltipId = useId('tooltip');
    const [copyButtonText, setCopyButtonText] = React.useState(props.copyText);

    React.useEffect(() => {
        if (copyButtonText === props.copiedText) {
            setTimeout(() => {
                setCopyButtonText(props.copyText)
            }, 3000)
        }
    }, [copyButtonText])

    const copyCodeToClipboard = () => {
        copy(props.content);
        setCopyButtonText(props.copiedText)
    }

    return (
        <TooltipHost
            calloutProps={{ styles: { ...tooltipCalloutStyles }, ...props.tooltipCalloutProps }}
            content={copyButtonText}
            delay={props.tooltipDelay ?? TooltipDelay.medium}
            id={tooltipId}
            directionalHint={DirectionalHint.bottomCenter}
        >
            <div style={{display: 'inline'}} onClick={copyCodeToClipboard}>
                {props.children || (
                    <IconButton disabled={props.disabled} iconProps={{ iconName: props.iconName ?? 'Copy' }} styles={props.buttonStyles} ariaLabel="Copy" />
                )}
            </div>
        </TooltipHost>
    )
}

const theme = getTheme();

const tooltipCalloutStyles: Partial<ICalloutContentStyles> = {
    beakCurtain: { background: theme.palette.neutralLight },
    beak: { background: theme.palette.neutralLight },
    calloutMain: { background: theme.palette.neutralLight },
    root: { color: theme.palette.white },
    container: { color: 'white' }
}