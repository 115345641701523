import { AnimationStyles, DefaultButton, DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint, FontWeights, getTheme, IButtonStyles, IColumn, IconButton, IContextualMenuProps, IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles, IRenderFunction, IStackStyles, mergeStyleSets, MessageBar, MessageBarType, Modal, PrimaryButton, SelectionMode, SharedColors, Spinner, Stack, Sticky, StickyPositionType, Text } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"
import { Badge } from "../../../../../components/shared/decorations/Badge"
import { ScreenHeader } from "../../../../../components/shared/decorations/ScreenHeader"
import { onRenderColumnHeaderCommon } from "../../../../../components/shared/detailsList/commonRenders"
import { TFilterOptions } from "../../../../../components/shared/filter/Filter"
import { SimpleCustomFilter } from "../../../../../components/shared/filter/SimpleCustomFilter"
import { ConfirmationForm } from "../../../../../components/shared/forms/ConfirmationForm"
import { PointForm } from "../../../../../components/shared/forms/PointForm"
import { CustomPanel } from "../../../../../components/shared/panel/CustomPanel"
import { SomethingWentWrong } from "../../../../../components/shared/somethingWentWrong/SomethingWentWrong"
import { portalApi } from "../../../../../core/api/api"
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../../../core/scripts/style"
import { ICompany, IPoint } from "../../../../../core/store"
import { strings } from "../../../../../localization/strings"
import { useDispatch } from "react-redux"
import { actionCreators } from "../../../../../core/actions/workflow-actions"

export type TCompanyPointsPivotProps = {
    data: ICompany
}

export const CompanyPointsPivot: React.FC<TCompanyPointsPivotProps> = (props) => {

    const [isPointPanelOpen, { setTrue: showPointPanel, setFalse: hidePointPanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const [searchString, setSearchString] = useState<string>()
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<TFilterOptions>()

    const [pointsState, setPointsState] = useState<{ fetched: boolean, error?: string, data?: Array<IPoint> }>({ fetched: false })
    const [items, setItems] = useState<IPoint[]>([])
    const [error, setError] = React.useState<string>()

    const [point, setPoint] = useState<IPoint>()
    const [deletePointState, setDeletePointState] = useState<IPoint>()

    const history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
        requestPoints()
    }, [])

    useEffect(() => {
        console.log(point)
    }, [point])

    useEffect(() => {
        if (pointsState.data && pointsState.data !== items) {
            setItems(pointsState.data)
        }
    }, [pointsState])

    useEffect(() => {
        setItems(() => {
            var newItems = pointsState.data ? [...pointsState.data] : []
            if (searchString) {
                newItems = [
                    ...((newItems as IPoint[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.address,
                            i.city,
                            i.code,
                            i.name
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as IPoint[]
        })
    }, [selectedFilterOptions, searchString])

    const requestPoints = async () => {
        if (props.data.id) {
            setPointsState({ fetched: false })
            const result = await portalApi.organization.points.getPoints(props.data.id)
            console.log(result)
            setPointsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(i => !i.removed) })
        }
    }

    const getItemMenuProps = (item: IPoint): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? onEditPoint(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? setDeletePointState(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.STRUCTURE.ITEM.POINT.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPoint) => item.name.length > 0 && onRenderNamePointCell(item),
            isPadded: true,
        },
        // {
        //     key: 'column2',
        //     name: strings.ORGANIZATION.COMPANIES.TABLE_COLUMNS.ITN,
        //     fieldName: 'itn',
        //     minWidth: 150,
        //     maxWidth: 150,
        //     isResizable: false,
        //     data: 'string',
        //     onRender: (item: IPoint) => {
        //         return <Stack verticalFill verticalAlign="center"><Text style={{ color: theme.palette.black }}>{item.code}</Text></Stack>;
        //     },
        //     isPadded: true,
        // },
        // {
        //     key: 'column3',
        //     name: strings.ORGANIZATION.COMPANIES.TABLE_COLUMNS.MANAGER,
        //     fieldName: 'manager',
        //     minWidth: 300,
        //     maxWidth: 500,
        //     isResizable: false,
        //     data: 'string',
        //     onRender: (item: IPoint) => {
        //         return <Stack verticalFill verticalAlign="center"><Text style={{ color: theme.palette.black }}>{item.timezone}</Text></Stack>;
        //     },
        //     isPadded: true,
        // },
        {
            key: 'column4',
            name: '',
            fieldName: 'enabled',
            minWidth: 60,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPoint) => (
                <Stack horizontal verticalFill verticalAlign='center'>
                    <Stack.Item>
                        {/* <TooltipHost directionalHint={DirectionalHint.leftBottomEdge} key={item.id} content={item.enabled ? strings.ORGANIZATION.COMPANIES.COMPANY.DISABLE_TOOLTIP : strings.ORGANIZATION.COMPANIES.COMPANY.ENABLE_TOOLTIP}>
                            <IconButton iconProps={item.enabled ? activeEnabledIconProps : enabledIconProps} onClick={(e) => {
                                e.stopPropagation();
                                item.id && companyIsActivatedToggle(item.id)
                            }} />
                        </TooltipHost> */}
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item)} />
                    </Stack.Item>
                </Stack>
            )
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                background: rProps.item['enabled'] ? undefined : 'rgb(253,231,233, 0.6)',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => history.push(`/organization/companies/${props.data.id}/points/${rProps.item['id']}`)}><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    };

    const onCancel = () => {
        hidePointPanel()
        setTimeout(() => setPoint(undefined), 1000)

    }

    const onAddPoint = () => {
        setFormError(undefined)
        stopFetch()
        showPointPanel()
    }

    const addPoint = async (item: IPoint) => {
        startFetch()
        const result = await portalApi.organization.points.addPoint(item)
        if (result.successed && result.data) {
            console.log(result)
            setPointsState({ ...pointsState, data: pointsState.data ? [...pointsState.data, result.data] : [result.data] })
            hidePointPanel()
            dispatch(actionCreators.requestStructure())
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onEditPoint = (item: IPoint) => {
        stopFetch()
        setFormError(undefined)
        setPoint(item)
        showPointPanel()
    }

    const updatePoint = async (item: IPoint) => {
        startFetch()
        const result = await portalApi.organization.points.updatePoint(item)
        if (result.successed && result.data) {
            console.log(result)
            if (pointsState.data?.find(p => p.id === item.id)?.companyId !== item.companyId) {
                setPointsState({ ...pointsState, data: pointsState.data?.filter(p => p.id !== item.id) })
            } else {
                setPointsState({
                    ...pointsState, data: pointsState.data && [...pointsState.data.map(p => {
                        if (p.id === result.data?.id) {
                            return result.data
                        }
                        return p
                    })] as IPoint[]
                })
            }
            hidePointPanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onDeletePoint = () => {
        if (deletePointState?.id) {
            deletePoint(deletePointState.id)
            setDeletePointState(undefined)
        }
    }

    const deletePoint = async (pointId: string) => {
        setPointsState({ ...pointsState, error: undefined })
        const result = await portalApi.organization.points.deletePoint(pointId)
        console.log(result)
        if (result.successed) {
            dispatch(actionCreators.requestStructure())
            if (pointsState.data) {
                setPointsState({
                    ...pointsState,
                    data: pointsState.data.filter(c => c.id !== pointId)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setPointsState({ ...pointsState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setPointsState({ ...pointsState, error: "There is a problem! Point wasn't deleted! Server response false without an error" });
        }
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }

        return (
            <Stack>
                <Stack.Item>
                    <Stack tokens={{ padding: "16px 20px 4px 20px", childrenGap: 4 }} horizontal horizontalAlign='space-between'>
                        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 4 }}>
                            <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                            <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                                <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{items.length}</Text>
                            </Stack>
                        </Stack>
                        <PrimaryButton onClick={onAddPoint} text={strings.ORGANIZATION.COMPANIES.BUTTONS.TEXT.ADD_POINT} />
                    </Stack>
                </Stack.Item>
                {defaultRender!({ ...props })}
            </Stack>
        );
    };

    return (
        <Stack verticalFill>
            <Stack.Item verticalFill>
                <Stack verticalFill>
                    {!pointsState.error && (pointsState.fetched && pointsState.data && (
                        <>
                            <ScreenHeader title={strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.POINTS.TITLE}>
                                <SimpleCustomFilter onChange={(ev, value) => setSearchString(value)} />
                            </ScreenHeader>
                            <Stack.Item tokens={{ padding: "0px 32px 24px 32px" }} >
                                <Stack styles={containerStyles}>
                                    <Stack.Item>
                                        <DetailsList
                                            styles={{ root: { width: '100%' } }}
                                            items={items}
                                            columns={columns}
                                            setKey="none"
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            onRenderDetailsHeader={onRenderDetailsHeader}
                                            isHeaderVisible={true}
                                            onRenderRow={onRenderRow}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </>
                    ) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        ))}
                    {pointsState.error && (
                        <Stack verticalFill>
                            {pointsState.error.length > 0 && (
                                <MessageBar messageBarType={MessageBarType.error}>{pointsState.error}</MessageBar>
                            )}
                            <Stack.Item verticalFill>
                                <SomethingWentWrong action={requestPoints} />
                            </Stack.Item>
                        </Stack>
                    )}
                </Stack>
            </Stack.Item>
            <CustomPanel
                isOpen={isPointPanelOpen}
                onCancel={onCancel}
                title={point?.id ? strings.ORGANIZATION.STRUCTURE.ITEM.POINT.EDIT_POINT : strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADD_POINT}
            >
                <Stack verticalFill>
                    {formError && (
                        <MessageBar messageBarType={MessageBarType.error}>
                            {formError}
                        </MessageBar>
                    )}
                    {(isPointPanelOpen && !fetching) && (
                        <PointForm
                            onSubmit={(data: IPoint) => point?.id ? updatePoint(data) : addPoint(data)}
                            onCancel={onCancel}
                            data={{ ...point, companyId: props.data.id } as IPoint}
                        />
                    ) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        )}
                </Stack>
            </CustomPanel>
            {deletePointState && (
                <Modal isOpen={deletePointState ? true : false} onDismiss={() => setDeletePointState(undefined)} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={() => setDeletePointState(undefined)}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={onDeletePoint}
                            onCancel={() => setDeletePointState(undefined)}
                            requiredMessageString={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_REQUIRED}
                            confirmationCheckString={deletePointState?.name}
                            placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_PLACEHOLDER}
                            confirmText={strings.BUTTONS.TEXT.DELETE}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TEXT1}</Text>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TEXT2}</Text>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{deletePointState.name}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>
            )}
        </Stack>
    )
}

const onRenderNamePointCell = (item: IPoint) => {
    return (
        <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
            <Stack.Item>
                <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: getPersonaColorsPallete[item.name[0].toLowerCase()] }}>
                    <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                        {getIconSymbolsFromString(item.name)}
                    </Text>
                </Stack>
            </Stack.Item>
            <Stack.Item grow>
                <Stack>
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
                        {item.code && <Badge title={item.code} />}
                    </Stack>
                    <Stack.Item>
                        <Text variant="small" style={{ color: SharedColors.gray30 }}>{item.address}</Text>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4,
        ...AnimationStyles.slideUpIn10,
        overflow: 'hidden',
        position: "relative"
    }
};

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
