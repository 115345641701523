import {ISelfServiceConfigAnswer, ISelfServiceConfigItem, SelfServiceConfigType} from "@piceasoft/core";

import {IRequestResult} from "../store/typings/IResponseResult";
import {endpoints} from "./endpoints";
import {DiagnosticCasesMap} from "../store/typings/DiagnosticCasesMap";
import {RepairComponentsMap} from "../store/typings/RepairComponentsMap";

interface ISelfServiceConfigItemResponse {
    id: string;
    level: number;
    type: string;
    label: string;
    parent?: string;
    answers?: ISelfServiceConfigAnswer[];
    properties?: {
        value?: { label: string, value: string }[]
    }
}

interface IAiConfigurationsResponse<T> {
    error_details: any | null
    message: T
    status: number
}

const selfServiceConfigTypeMap: { [key: string]: SelfServiceConfigType } = {
    question: SelfServiceConfigType.Question,
    catalog: SelfServiceConfigType.Catalog,
    diagnostic: SelfServiceConfigType.Diagnostic,
    freeText: SelfServiceConfigType.FreeText,
    solution: SelfServiceConfigType.Solution,
};

const convertConfigItemResponse = (response: IRequestResult<IAiConfigurationsResponse<ISelfServiceConfigItemResponse[]>>): IRequestResult<ISelfServiceConfigItem[]> => {
    return {
        ...response,
        data: response.data?.message.map((m) => {
            const type = selfServiceConfigTypeMap[m.type];
            const getProperties = ()=> {
                switch (type) {
                    case SelfServiceConfigType.Question:
                        return {
                            answers: m.answers?.map((a) => ({
                                id: a.id,
                                text: a.text,
                                disable: false
                            })),
                        }
                    case SelfServiceConfigType.Diagnostic:
                        return {
                            cases: m.properties?.value?.map((v) => Number(v.value)),
                        }
                    case SelfServiceConfigType.Catalog:
                        return {
                            components: m.properties?.value?.map((v) => v.value),
                        }
                    default:
                        return undefined
                }
            }
            const res: ISelfServiceConfigItem = {
                type,
                id: m.id,
                level: m.level,
                label: m.label,
                parent: m.parent,
                properties: getProperties()
            }
            return res;
        })
    }
}
export const aiApi = {
    getConfigurations: async (lang?: string): Promise<IRequestResult<ISelfServiceConfigItem[]>> => {
        const currentLang = lang || 'en'
        let result: IRequestResult<ISelfServiceConfigItem[]> = {successed: false};
        await fetch(endpoints.v1.picea.ai.configurations(), {
            method: "POST",
            body: JSON.stringify({lang: currentLang}),
            headers: {"Content-Type": "application/json"}
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IAiConfigurationsResponse<ISelfServiceConfigItemResponse[]>>>)
                    .then(response => {
                        result = convertConfigItemResponse(response)
                    })
                    .catch(error => result.errors = [error])
            })
            .catch(error => result.errors = [error])
        return result;
    },
    getDiagnosticCases: async (lang?: string): Promise<IRequestResult<IAiConfigurationsResponse<DiagnosticCasesMap>>> => {
        const currentLang = lang || 'en'
        let result: IRequestResult<IAiConfigurationsResponse<DiagnosticCasesMap>> = {successed: false};
        await fetch(endpoints.v1.picea.ai.diagnostic(), {
            method: "POST",
            body: JSON.stringify({lang: currentLang}),
            headers: {"Content-Type": "application/json"}
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IAiConfigurationsResponse<DiagnosticCasesMap>>>)
                    .then(response => {
                        result = response
                    })
                    .catch(error => result.errors = [error])
            })
            .catch(error => result.errors = [error])
        return result;
    },
    getComponents: async (lang?: string): Promise<IRequestResult<IAiConfigurationsResponse<RepairComponentsMap>>> => {
        const currentLang = lang || 'en'
        let result: IRequestResult<IAiConfigurationsResponse<RepairComponentsMap>> = {successed: false};
        await fetch(endpoints.v1.picea.ai.components(), {
            method: "POST",
            body: JSON.stringify({lang: currentLang}),
            headers: {"Content-Type": "application/json"}
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IAiConfigurationsResponse<RepairComponentsMap>>>)
                    .then(response => {
                        result = response
                    })
                    .catch(error => result.errors = [error])
            })
            .catch(error => result.errors = [error])
        return result;
    },
}
