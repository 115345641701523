import { Stack, Pivot, PivotItem, ScrollablePane } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationScannerConfig } from "@piceasoft/core";
import { IIdentificationScannerConfigUI } from "@piceasoft/core";
import { scannerUploadMethodsDescriber } from "./ScannerCommonSettingsPivot";
import { ScannerUploadMethods } from "@piceasoft/core";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const ScannerModalUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationScannerConfig)

    const uploadMethods = config.uploadMethods.filter(i => i !== ScannerUploadMethods.Camera)

    const [pivotState, setPivotState] = React.useState((uploadMethods && uploadMethods.length > 0) ? uploadMethods[0] : undefined)

    const getTabId = (itemKey: string) => {
        return `IdentificationScannerUISettingsPivot_${itemKey}`;
    };

    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(Number.parseInt(item?.props.itemKey!));
    };

    return (
        <Stack verticalFill>
            {config.uploadMethods && config.uploadMethods.length > 0 && (
                <>
                    {uploadMethods.length > 1 && (
                        <Stack>
                            <Pivot style={{ padding: 16 }} getTabId={getTabId} onLinkClick={handlePivotLinkClick}>
                                {uploadMethods.map(i => (
                                    <PivotItem key={i} itemKey={i.toString()} headerText={scannerUploadMethodsDescriber(i)} />
                                ))}
                            </Pivot>
                        </Stack>
                    )}
                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                        <ScrollablePane>
                            <PivotBasedState state={pivotState} index={props.index} config={config} onChangeDispatch={props.onChangeDispatch} />
                        </ScrollablePane>
                    </Stack.Item>
                </>
            )}
        </Stack>
    )
}

type TStateProps = {
    index: number
    onChangeDispatch: Dispatch<any>
    state?: ScannerUploadMethods
    config: IIdentificationScannerConfig
}

const PivotBasedState: React.FC<TStateProps> = (props) => {

    const { config } = props

    switch (props.state) {
        case ScannerUploadMethods.Camera:
            return (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.CAMERA_TITLE_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Camera)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.camera?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        camera: {
                                            ...config.ui?.uploadMethods.camera,
                                            title: config.ui?.uploadMethods?.camera?.title !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.CAMERA_TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.camera?.title !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.camera?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    camera: {
                                        ...config.ui?.uploadMethods.camera,
                                        title: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.CAMERA_INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Camera)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.camera?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        camera: {
                                            ...config.ui?.uploadMethods.camera,
                                            information: config.ui?.uploadMethods?.camera?.information !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.CAMERA_INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.camera?.information !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.camera?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    camera: {
                                        ...config.ui?.uploadMethods.camera,
                                        information: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.CAMERA_WARNING_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Camera)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.camera?.warning !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        camera: {
                                            ...config.ui?.uploadMethods.camera,
                                            warning: config.ui?.uploadMethods?.camera?.warning !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.CAMERA_WARNING_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.camera?.warning !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.camera?.warning}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    camera: {
                                        ...config.ui?.uploadMethods.camera,
                                        warning: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                </>
            )
        case ScannerUploadMethods.Remote:
            return (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.REMOTE_TITLE_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.remote?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        remote: {
                                            ...config.ui?.uploadMethods.remote,
                                            title: config.ui?.uploadMethods?.remote?.title !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.REMOTE_TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.remote?.title !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.remote?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    remote: {
                                        ...config.ui?.uploadMethods.remote,
                                        title: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.REMOTE_INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.remote?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        remote: {
                                            ...config.ui?.uploadMethods.remote,
                                            information: config.ui?.uploadMethods?.remote?.information !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.REMOTE_INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.remote?.information !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.remote?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    remote: {
                                        ...config.ui?.uploadMethods.remote,
                                        information: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.REMOTE_WARNING_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.remote?.warning !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        remote: {
                                            ...config.ui?.uploadMethods.remote,
                                            warning: config.ui?.uploadMethods?.remote?.warning !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.REMOTE_WARNING_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.remote?.warning !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.remote?.warning}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    remote: {
                                        ...config.ui?.uploadMethods.remote,
                                        warning: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    {/* <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_REMOTE_TITLE_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.remote?.external?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        remote: {
                                            ...config.ui?.uploadMethods.remote,
                                            external: {
                                                ...config.ui?.uploadMethods.remote?.external,
                                                title: config.ui?.uploadMethods?.remote?.external?.title !== undefined ? undefined : ""
                                            }
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_REMOTE_TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.remote?.external?.title !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.remote?.external?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    remote: {
                                        ...config.ui?.uploadMethods.remote,
                                        external: {
                                            ...config.ui?.uploadMethods.remote?.external,
                                            title: val
                                        }
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_REMOTE_INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.remote?.external?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        remote: {
                                            ...config.ui?.uploadMethods.remote,
                                            external: {
                                                ...config.ui?.uploadMethods.remote?.external,
                                                information: config.ui?.uploadMethods?.remote?.external?.information !== undefined ? undefined : ""
                                            }
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_REMOTE_INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.remote?.external?.information !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.remote?.external?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    remote: {
                                        ...config.ui?.uploadMethods.remote,
                                        external: {
                                            ...config.ui?.uploadMethods.remote?.external,
                                            information: val
                                        }
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_REMOTE_WARNING_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.remote?.external?.warning !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        remote: {
                                            ...config.ui?.uploadMethods.remote,
                                            external: {
                                                ...config.ui?.uploadMethods.remote?.external,
                                                warning: config.ui?.uploadMethods?.remote?.external?.warning !== undefined ? undefined : ""
                                            }
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_REMOTE_WARNING_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.remote?.external?.warning !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.remote?.external?.warning}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    remote: {
                                        ...config.ui?.uploadMethods.remote,
                                        external: {
                                            ...config.ui?.uploadMethods.remote?.external,
                                            warning: val
                                        }
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    /> */}
                </>
            )
        case ScannerUploadMethods.Upload:
            return (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.UPLOAD_TITLE_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Upload)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.upload?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        upload: {
                                            ...config.ui?.uploadMethods.upload,
                                            title: config.ui?.uploadMethods?.upload?.title !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.UPLOAD_TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.upload?.title !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.upload?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    upload: {
                                        ...config.ui?.uploadMethods.upload,
                                        title: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.UPLOAD_INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Upload)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.upload?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        upload: {
                                            ...config.ui?.uploadMethods.upload,
                                            information: config.ui?.uploadMethods?.upload?.information !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.UPLOAD_INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.upload?.information !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.upload?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    upload: {
                                        ...config.ui?.uploadMethods.upload,
                                        information: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.UPLOAD_WARNING_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.includes(ScannerUploadMethods.Upload)! ? true : false}
                        toggleChecked={config.ui?.uploadMethods?.upload?.warning !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.ui?.uploadMethods,
                                        upload: {
                                            ...config.ui?.uploadMethods.upload,
                                            warning: config.ui?.uploadMethods?.upload?.warning !== undefined ? undefined : ""
                                        }
                                    }
                                } as IIdentificationScannerConfigUI
                            }))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.UI.UPLOAD_METHODS.UPLOAD_WARNING_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.ui?.uploadMethods?.upload?.warning !== undefined ? true : false}
                        textFieldValue={config.ui?.uploadMethods?.upload?.warning}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    ...config.ui?.uploadMethods,
                                    upload: {
                                        ...config.ui?.uploadMethods.upload,
                                        warning: val
                                    }
                                }
                            } as IIdentificationScannerConfigUI
                        }))}
                    />
                </>
            )
        default: return null
    }
}