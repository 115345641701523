import { AppThunkAction } from "..";

export interface RequestGoogleMaps { type: 'GOOGLEMAPS_IS_READY' }
export interface RequestGoogleMapsApiKey { type: 'GOOGLEMAPS_API_KEY', apiKey: string }

export type KnownAction = RequestGoogleMaps | RequestGoogleMapsApiKey

export const actionCreators = {

    requestGoogleMaps: (key: string): AppThunkAction<KnownAction> => (dispatch) => {
        const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,places&language=en&v=quarterly`;
        const googleMapsScript = document.createElement('script');
        googleMapsScript.src = mapsUrl;
        googleMapsScript.async = true;
        googleMapsScript.defer = true;  
        googleMapsScript.onload = () => {
            dispatch({ type: 'GOOGLEMAPS_IS_READY' });
        }
        document.body.appendChild(googleMapsScript);
    }
};
