import { Stack, getTheme, Text, PrimaryButton, DefaultButton, FontWeights, mergeStyleSets, MaskedTextField, SharedColors, TextField } from "@fluentui/react"
import React from "react";
import { strings } from "../../../../../localization/strings";
import { IAssessmentConfig, IIdentificationConfig } from "@piceasoft/core";
type TProps = {
    config: IAssessmentConfig | IIdentificationConfig
}

export const StageRequirementsPreview: React.FC<TProps> = (props) => {

    const [imeiManualEntry, setManualImei] = React.useState<string>();
    const [snManualEntry, setManualSn] = React.useState<string>();
    const [isRequirements, setIsRequirements] = React.useState(false);

    return (
        <Stack horizontalAlign="center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} >
            <Stack.Item tokens={{ padding: 24 }}>
                <Stack style={{ minWidth: 500, maxWidth: 600, boxShadow: theme.effects.elevation64, backgroundColor: theme.palette.white }}>
                    <div className={contentStyles.header}>
                        <Text style={{ color: theme.palette.themePrimary, fontWeight: 600 }} variant="large">{props.config?.ui?.requirements?.title ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.REQUIRED_DATA}</Text>
                    </div>
                    <div className={contentStyles.body}>
                    <Stack tokens={{ childrenGap: 8 }}>
                        <Stack.Item>
                            <Text style={{ color: SharedColors.gray40 }}>
                                <div dangerouslySetInnerHTML={{ __html: props.config?.ui?.requirements?.information ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.REQUIRED_DATA_INFORMATION }}></div>
                            </Text>
                        </Stack.Item>
                        {props.config?.requirements?.imei && (
                            <Stack.Item>
                                {props.config.requirements.imei.mask && (
                                    <MaskedTextField value={imeiManualEntry}
                                        label={props.config.requirements.imei.label ?? strings.ATTRIBUTES.DEVICE.IMEI + ":"}
                                        mask={props.config.requirements.imei.mask}
                                        placeholder={props.config.requirements.imei.placeholder ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.PLACEHOLDER_IMEI}
                                        onChange={(ev, value) => setManualImei(value)}
                                        onGetErrorMessage={(value) => {
                                            if (value.indexOf("_") > -1) {
                                                setIsRequirements(false);
                                                return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.REQUIRE_TO_FILL;
                                            }
                                            setIsRequirements(true);
                                            return undefined;
                                        }} />
                                ) || (
                                        <TextField value={imeiManualEntry}
                                            label={props.config.requirements.imei.label ?? strings.ATTRIBUTES.DEVICE.IMEI + ":"}
                                            placeholder={props.config.requirements.imei.placeholder ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.PLACEHOLDER_IMEI}
                                            onChange={(ev, value) => setManualImei(value)}
                                            minLength={props.config.requirements.imei.minLength}
                                            maxLength={props.config.requirements.imei.maxLength}
                                            onGetErrorMessage={(value) => {
                                                if (value.indexOf("_") > -1) {
                                                    setIsRequirements(false);
                                                    return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.REQUIRE_TO_FILL;
                                                }
                                                if (props.config?.requirements?.imei?.minLength && value.length < props.config.requirements.imei.minLength) {
                                                    setIsRequirements(false);
                                                    return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.MIN_LENGTH_WARNING + props.config.requirements.imei.minLength;
                                                }
                                                setIsRequirements(true);
                                                return undefined;
                                            }} />
                                    )
                                }
                            </Stack.Item>
                        )}
                        {props.config?.requirements?.imei2 && (
                            <Stack.Item>
                                {props.config.requirements.imei2.mask && (
                                    <MaskedTextField value={imeiManualEntry}
                                        label={props.config.requirements.imei2.label ?? strings.ATTRIBUTES.DEVICE.IMEI2 + ":"}
                                        mask={props.config.requirements.imei2.mask}
                                        placeholder={props.config.requirements.imei2.placeholder ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.PLACEHOLDER_IMEI2}
                                        onChange={(ev, value) => setManualImei(value)}
                                        onGetErrorMessage={(value) => {
                                            if (value.indexOf("_") > -1) {
                                                setIsRequirements(false);
                                                return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.REQUIRE_TO_FILL;
                                            }
                                            setIsRequirements(true);
                                            return undefined;
                                        }} />
                                ) || (
                                        <TextField value={imeiManualEntry}
                                            label={props.config.requirements.imei2.label ?? strings.ATTRIBUTES.DEVICE.IMEI2 + ":"}
                                            placeholder={props.config.requirements.imei2.placeholder ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.PLACEHOLDER_IMEI2}
                                            onChange={(ev, value) => setManualImei(value)}
                                            minLength={props.config.requirements.imei2.minLength}
                                            maxLength={props.config.requirements.imei2.maxLength}
                                            onGetErrorMessage={(value) => {
                                                if (value.indexOf("_") > -1) {
                                                    setIsRequirements(false);
                                                    return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.REQUIRE_TO_FILL;
                                                }
                                                if (props.config?.requirements?.imei2?.minLength && value.length < props.config.requirements.imei2.minLength) {
                                                    setIsRequirements(false);
                                                    return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.MIN_LENGTH_WARNING + props.config.requirements.imei2.minLength;
                                                }
                                                setIsRequirements(true);
                                                return undefined;
                                            }} />
                                    )
                                }
                            </Stack.Item>
                        )}
                        {props.config?.requirements?.sn && (
                            <Stack.Item>
                                {props.config.requirements.sn.mask && (
                                    <MaskedTextField value={snManualEntry}
                                        label={props.config.requirements.sn.label ?? strings.ATTRIBUTES.DEVICE.SERIAL_NUMBER + ":"}
                                        mask={props.config.requirements.sn.mask}
                                        placeholder={props.config.requirements.sn.placeholder ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.PLACEHOLDER_SERIAL_NUMBER}
                                        onChange={(ev, value) => setManualSn(value)}
                                        onGetErrorMessage={(value) => {
                                            if (!value) {
                                                setIsRequirements(false);
                                                return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.REQUIRE_TO_FILL;
                                            }
                                            setIsRequirements(true);
                                            return undefined;
                                        }} />
                                ) || (
                                        <TextField value={snManualEntry}
                                            label={props.config.requirements.sn.label ?? strings.ATTRIBUTES.DEVICE.SERIAL_NUMBER + ":"}
                                            placeholder={props.config.requirements.sn.placeholder ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.PLACEHOLDER_SERIAL_NUMBER}
                                            onChange={(ev, value) => setManualSn(value)}
                                            minLength={props.config.requirements.sn.minLength}
                                            maxLength={props.config.requirements.sn.maxLength}
                                            onGetErrorMessage={(value) => {
                                                if (!value) {
                                                    setIsRequirements(false);
                                                    return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.REQUIRE_TO_FILL;
                                                }
                                                if (props.config?.requirements?.sn?.minLength && value.length < props.config.requirements.sn.minLength) {
                                                    setIsRequirements(false);
                                                    return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.MIN_LENGTH_WARNING + props.config.requirements.sn.minLength;
                                                }
                                                setIsRequirements(true);
                                                return undefined;
                                            }} />
                                    )
                                }
                            </Stack.Item>
                        )}
                    </Stack>
                    </div>
                    <Stack horizontal horizontalAlign="end" tokens={{ padding: 24, childrenGap: 8 }}>
                        <PrimaryButton text={strings.BUTTONS.TEXT.CONFIRM} />
                        <DefaultButton text={strings.BUTTONS.TEXT.CANCEL} />
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        maxWidth: 640
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});