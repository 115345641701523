import { strings } from "../../../../../../localization/strings"

export const getSliderLabel = (min?: number, max?: number, single?: string, multi?: string, noLimit?: string): string => {
    if (max && !min && max === 1) {
        return (
            `${strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.SLIDER.UP_TO} ${max} ${single ?? strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.SLIDER.SYMBOL_LOWER}`
        )
    }
    if (max && !min) {
        return (
            `${strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.SLIDER.UP_TO} ${max} ${multi ?? strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.SLIDER.SYMBOLS_LOWER}`
        )
    }
    if (max && min) {
        return (
            `${strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.SLIDER.FROM} ${min} ${strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.SLIDER.UP_TO_LOWER} ${max} ${multi ?? strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.SLIDER.SYMBOLS_LOWER}`
        )
    }
    return noLimit ?? strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.SLIDER.NO_LIMITS
}