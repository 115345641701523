import { Stack } from '@fluentui/react';
import * as React from 'react';
import Inspection from '../Inspection';
import { DiagnosticsModes, Inspections, IInspectionModeConfig } from "@piceasoft/core";
import { noop } from '../../../../../../core/helpers/common';
import { strings } from '../../../../../../localization/strings';
import { IInspectionModeConfigUI } from '@piceasoft/core';
import { DiagnosticsState } from './preview/DiagnosticsState';
import { IDiagnosticsConfig } from '../../../../../../core/store/typings/IDiagnosticsConfig';
import { getLocalizedDiagnosticsModeName } from '../../../../../../localization/helpers/transaction';

export type uploadFileType = { imgUrl: string, loadProgress: number, data: File, label?: string }

type TProps = {
    config?: IDiagnosticsConfig
}

export const DiagnosticsStatePreview: React.FC<TProps> = (props) => {
    if (props.config === undefined) return null;

    return (
        <Stack grow tokens={{ padding: "32px 32px 32px 32px" }}>
            <Inspection inspection={Inspections.Diagnostics}
                icon={props.config.ui?.icon ?? "Camera"}
                title={props.config.ui?.title ?? getLocalizedDiagnosticsModeName(props.config.mode)}
                allowRestart={props.config.allowRestart}
                onStart={noop}
                onRestart={noop}
                dependency={props.config.dependency}
                skips={props.config.skip}
                autoRun={props.config.autoRun}
                executeButtonText={props.config.ui?.executeButtonText}
                repeatButtonText={props.config.ui?.restartButtonText}
            >
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    {props.config.mode === DiagnosticsModes.Piceasoft && (
                        <DiagnosticsState config={props.config.config as IInspectionModeConfig<IInspectionModeConfigUI>} 
                        description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.PICEA_RUN_TESTS} 
                        icon={props.config.ui?.icon} />
                    )}
                    {props.config.mode === DiagnosticsModes.PiceaMobile && (
                        <DiagnosticsState config={props.config.config as IInspectionModeConfig<IInspectionModeConfigUI>} 
                        description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.PICEA_MOBILE_RUN_TESTS}
                        icon={props.config.ui?.icon} />
                    )}
                    {props.config.mode === DiagnosticsModes.WebBased && (
                        <DiagnosticsState config={props.config.config as IInspectionModeConfig<IInspectionModeConfigUI>} 
                        description={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.WEB_BASED_RUN_TESTS}
                        icon={props.config.ui?.icon} />
                    )}
                </Stack>
            </Inspection>
        </Stack>
    );
}