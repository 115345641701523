import { DefaultButton, Modal, PrimaryButton, Stack, Text } from '@fluentui/react'
import React from 'react'
import { IValidationToken } from '../../core/store/typings/IValidationToken'
import { useDispatch } from 'react-redux'
import { actionCreators } from '../../core/actions/validation-actions'
import { strings } from '../../localization/strings';

type TProps = {
    token: IValidationToken,
    isOpenEnableModal: boolean
    closeEnableModal: () => void
}

export const EnableValidationToken: React.FC<TProps> = (props) => {
    
    const { token, isOpenEnableModal, closeEnableModal} = props
    const dispatch = useDispatch();
  
    const handletSubmit = (e: any) => {
      e.preventDefault();

      const enabledUpdatedToken ={
        ...token,
         is_valid: !token.is_valid
      }
      
      dispatch(actionCreators.enableValidationToken(enabledUpdatedToken));
      closeEnableModal();
    }

  return (
    <Modal isOpen={ isOpenEnableModal } onDismiss={closeEnableModal} styles={modalStyles}>
        <Stack style={{display: 'flex', alignItems: 'center'}}>
            <Text variant='xLarge' style={{ marginBottom: '10px'}}>{ token.is_valid ? strings.SETTINGS.VALIDATION_API.DISABLE : strings.SETTINGS.VALIDATION_API.ENABLE }</Text>
            <Stack.Item>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton onClick={handletSubmit}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                <DefaultButton onClick={closeEnableModal}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>
              </Stack>
            </Stack.Item>
        </Stack>
    </Modal>
  )
}

const modalStyles = {
    main: {
      width: '80%',
      maxWidth: '500px',
      display: 'flex',
      alignItems: 'center'
    }
  };