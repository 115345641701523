import React from "react";
import { Control, Controller } from "react-hook-form"
import ClientNameSearch from "./ClientNameSearch"
import {getCommonFieldRules} from "../../../helpers/validationRules"
import { IDataCollectionConfig } from "@piceasoft/core"
import type { TContractData } from "../../../../../dataCollectionTypes"

type TProps = {
    config: IDataCollectionConfig
    errorMessage: string
    onRenderSuggestionsHeader: () => JSX.Element
    control: Control<TContractData, object>,
}

const ClientNameController: React.FunctionComponent<TProps> = (props) => {
    const {
        config,
        control,
        errorMessage,
        onRenderSuggestionsHeader,
    } = props

    return (
        <Controller
            name="name"
            control={control}
            rules={getCommonFieldRules(config?.config?.fields?.name)}
            render={({ field }) => 
                <ClientNameSearch
                    config={config}
                    setSearchRequest={(v: string) => field.onChange(v)}
                    onRenderSuggestionsHeader={onRenderSuggestionsHeader}
                    errorMessage={errorMessage}
                    onBlur={() => { field.onBlur() }}
                    searchRequest={field.value}
                />
            }
        />
    )
}

export default ClientNameController