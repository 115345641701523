import { AIGradingImageTypes } from "@piceasoft/core"
import { AIGradingResponseStatuses } from "@piceasoft/core"
import { AIGradingResultTypes } from "@piceasoft/core"
import { AIGradingResultValues } from "@piceasoft/core"
import { AIGradingUploadMethods } from '@piceasoft/core'
import { strings } from "../strings"

export const aiGradingUploadMethodsDescriber = (method: AIGradingUploadMethods): string => {
    switch (method) {
        case AIGradingUploadMethods.Remote: return strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.METHODS.REMOTE
        case AIGradingUploadMethods.Camera: return strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.METHODS.CAMERA
        case AIGradingUploadMethods.Upload: return strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.COMMON.METHODS.UPLOAD
    }
}

export const aiGradingImageTypesDescriber = (type: AIGradingImageTypes): string => {
    switch (type) {
        case AIGradingImageTypes.ScreenOnPiceaApp: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.SCREEN_ON_PICEA_APP
        case AIGradingImageTypes.ScreenOff: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.SCREEN_OFF
        case AIGradingImageTypes.Back: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.BACK
        case AIGradingImageTypes.Left: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.LEFT
        case AIGradingImageTypes.Right: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.RIGHT
        case AIGradingImageTypes.Top: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.TOP
        case AIGradingImageTypes.Bottom: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.BOTTOM
    }
}

export const aiGradingImageTypesDescriberShort = (type: AIGradingImageTypes): string => {
    switch (type) {
        case AIGradingImageTypes.ScreenOnPiceaApp: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.SCREEN_ON_PICEA_APP_SHORT
        case AIGradingImageTypes.ScreenOff: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.SCREEN_OFF_SHORT
        case AIGradingImageTypes.Back: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.BACK_SHORT
        case AIGradingImageTypes.Left: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.LEFT_SHORT
        case AIGradingImageTypes.Right: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.RIGHT_SHORT
        case AIGradingImageTypes.Top: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.TOP_SHORT
        case AIGradingImageTypes.Bottom: return strings.INSPECTIONS.AIGRADING.IMAGE_TYPES.BOTTOM_SHORT
    }
}

export const aiGradingResultTypeDescriber = (type: AIGradingResultTypes): string => {
    switch (type) {
        case AIGradingResultTypes.ScreenCover: return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_COVER
        case AIGradingResultTypes.ScreenCrack: return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_CRACK
        case AIGradingResultTypes.ScreenScratch: return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_SCRATCH
    }
}

export const aiGradingResultTypeDropdownLabelDescriber = (type: AIGradingResultTypes): string => {
    switch (type) {
        case AIGradingResultTypes.ScreenCover: return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_COVER_TITLE
        case AIGradingResultTypes.ScreenCrack: return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_CRACK_TITLE
        case AIGradingResultTypes.ScreenScratch: return strings.INSPECTIONS.AIGRADING.RESULT_TYPES.SCREEN_SCRATCH_TITLE
    }
}

export const aiGradingResultValueDescriber = (value: AIGradingResultValues): string => {
    switch (value) {
        case AIGradingResultValues.NoResultIssues: return strings.INSPECTIONS.AIGRADING.RESULT_VALUES.NO_RESULTS_ISSUES
        case AIGradingResultValues.ResultFailed: return strings.INSPECTIONS.AIGRADING.RESULT_VALUES.RESILT_FAILED
        case AIGradingResultValues.ResultIssues: return strings.INSPECTIONS.AIGRADING.RESULT_VALUES.RESULT_ISSUES
    }
}

export const aiGradingResponseStatusesDescriber = (status: number): string => {
    switch (status) {
        case AIGradingResponseStatuses.InternalError: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.INTERNAL_ERROR
        case AIGradingResponseStatuses.Success: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.SUCCESS
        case AIGradingResponseStatuses.SignatureError: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.SIGNATURE_ERROR
        case AIGradingResponseStatuses.AuthenticationError: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.AUTHENTUCATION_ERROR
        case AIGradingResponseStatuses.InvalidRequestParameters: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.INVALID_REQUEST_PAREMETERS
        case AIGradingResponseStatuses.DatabaseError: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.DATABASE_ERROR
        case AIGradingResponseStatuses.UnknownCommand: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.UNKNOWN_COMMAND
        case AIGradingResponseStatuses.InvalidRequestContent: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.INVALID_REQUEST_CONTENT
        case AIGradingResponseStatuses.ServerNotReadyYet: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.SERVER_NOT_READY_YET
        case AIGradingResponseStatuses.GlareIsDetected: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.GLARE_IS_DETECTED
        case AIGradingResponseStatuses.ImageIsOutOfFocus: return strings.INSPECTIONS.AIGRADING.RESPONSE_STATUSES.IMAGE_IS_OUT_OF_Focus
        default: return status.toString()
    }
}