import { Stack, Text, Link } from "@fluentui/react";
import { SharedColors } from "@fluentui/theme";
import React from "react";
import { strings } from "../../../localization/strings";
import { DangerButton } from "../buttons/DangerButton";
import { CSHHelpLink } from "../help/CSHHelp";

type TProps = {
    text: string
    hrefText: string
    title: string
    href?: string
    onDelete?: () => void
}

export const RemoveArea: React.FC<TProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 8 }}>
            <Stack.Item>
                <Text style={{ fontWeight: 600 }}>{props.title}</Text>
            </Stack.Item>
            <Stack.Item>
                <Text style={{ color: SharedColors.gray20 }}>{props.text}</Text>
                <br />
                <CSHHelpLink  articleid={props.href} alignment='left' label={props.hrefText}/>
            </Stack.Item>
            {props.onDelete && (
                <Stack.Item style={{ paddingTop: 8 }}>
                    <DangerButton onClick={props.onDelete} text={strings.BUTTONS.TEXT.DELETE} />
                </Stack.Item>
            )}
        </Stack>
    )
}