import { Stack, Separator, Text } from '@fluentui/react';
import * as React from 'react';
import { ITransaction } from '../../../../../core/store/typings/ITransaction';
import { TransactionTabEntry } from './TransactionTabEntry';
import { strings } from '../../../../../localization/strings';
import { PropertyGrid } from '../../../../../components/shared/decorations/PropertyGrid';
import { getFormatedPhoneNumber } from '../../../../../core/helpers/localization';

type TProps = {
    transaction: ITransaction
}

export const PointPivot: React.FC<TProps> = (props) => {
    console.log(props.transaction.workplace?.user)
    return (
        <Stack verticalFill style={{ padding: "24px 24px" }}>
            <Stack horizontal verticalFill tokens={{ childrenGap: 24 }}>
                <Stack tokens={{ childrenGap: 16 }} style={{ width: "50%" }}>
                    <PropertyGrid name={strings.TRANSACTION.OVERVIEW.OPERATOR.TITLE} icon="Contact">
                        {props.transaction.workplace?.user?.display && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.OVERVIEW.OPERATOR.NAME}
                                value={props.transaction.workplace?.user?.display}
                            />
                        )}
                        {props.transaction.workplace?.user?.name && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.OVERVIEW.OPERATOR.LOGIN}
                                value={props.transaction.workplace?.user?.name}
                            />
                        )}
                        {props.transaction.workplace?.user?.email && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.OVERVIEW.OPERATOR.EMAIL}
                                value={props.transaction.workplace?.user?.email}
                            />
                        )}
                        {props.transaction.workplace?.user?.phone?.number && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.OVERVIEW.OPERATOR.PHONE}
                                value={getFormatedPhoneNumber(props.transaction.workplace?.user?.phone)}
                            />
                        )}
                    </PropertyGrid>
                </Stack>
                <Separator vertical />
                <Stack.Item style={{ width: "50%" }}>
                    <PropertyGrid name={strings.TRANSACTION.OVERVIEW.POINT} icon="ContactCard">
                        {props.transaction.workplace?.company?.name && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.OVERVIEW.COMPANY}
                                value={props.transaction.workplace?.company?.name}
                            />
                        )}
                        {props.transaction.workplace?.point?.name && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.OVERVIEW.POINT_NAME}
                                value={props.transaction.workplace?.point?.name}
                            />
                        )}
                        {props.transaction.workplace?.point?.address && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.OVERVIEW.ADDRESS}
                                value={props.transaction.workplace?.point?.address}
                            />
                        )}
                    </PropertyGrid>
                </Stack.Item>
            </Stack >
        </Stack >
    )
}
