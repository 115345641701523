import { strings } from "../../../localization/strings"

export enum ValidationErrors {
    GradeWasReset = 1,
    GradeIndexChanged = 2,
    CategoryWasReset = 3,
    AnswerError = 4,
    QuestionError = 5,
    CaseError = 6,
    SetError = 7,
    TestError = 8,
    PhotograficError = 9,
    VerifyError = 10,
    SkipError = 11,
    CollectionEmpty = 12,
    AnswersEmpty = 13,
    QuestionsEmpty = 14,
    TestsEmpty = 15,
    SetsEmpty = 16,
    CasesEmpty = 17,
    SuccessGradeReset = 18,
    ChecksEmpty = 19,
    CategoryNotSelected = 20,
    AssessmentEmpty = 21,
    IdentificationEmpty = 22,
    IdentificationMethodReset = 23,
    QuestionAnswersEmpty = 24,
    NextQuestionReset = 25,
    TestSetsEmpty = 26,
    TestSetCasesEmpty = 27,
    ModuleError = 28,
    ControlEmpty = 29,
    PreOfferDiscounts = 30,
    GradesEmpty = 31,
    GradeCategoriesEmpty = 32,
    CheckError = 33,
    EmailAddress = 34,
    DataCollectionEmail = 35,
    ConfigDeletedPromotions = 36,
    DeletedPromotion = 37,
    ConfigDeletedCatalog = 38,
    DeletedCatalog = 39,
    NotSelectedPromo = 40, 
    NotSelectedProvider = 41,
    NotSetCatalog = 42,
    QuestionsTitleEmpty = 43,
    NoUISettingsEnabled = 44,
}
export const getValidtionErrorTitle = (error: ValidationErrors): string => {
    switch(error){
        case ValidationErrors.GradeWasReset: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_WAS_RESET
        case ValidationErrors.GradeIndexChanged: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_INDEX_WAS_CHANGED
        case ValidationErrors.CategoryWasReset: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_CATEGORY_WAS_RESET
        case ValidationErrors.AnswerError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.ANSWER_ERROR
        case ValidationErrors.QuestionError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.QUESTION_ERROR
        case ValidationErrors.CaseError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.CASE_ERROR
        case ValidationErrors.SetError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.SET_ERROR
        case ValidationErrors.TestError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.TEST_ERROR
        case ValidationErrors.PhotograficError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.PHOTOGRAFIC_ERROR
        case ValidationErrors.VerifyError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.VERIFY_ERROR
        case ValidationErrors.SkipError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.SKIP_ERROR
        case ValidationErrors.CollectionEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.PHOTOGRAFIC_COLLECTION_EMPTY_ERROR
        case ValidationErrors.AnswersEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.INTERVIEW_ANSWERS_EMPTY_ERROR
        case ValidationErrors.QuestionsEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.INTERVIEW_QUESTIONS_EMPTY_ERROR
        case ValidationErrors.TestsEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DIAGNOSTICS_TESTS_EMPTY_ERROR
        case ValidationErrors.SetsEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DIAGNOSTICS_SETS_EMPTY_ERROR
        case ValidationErrors.CasesEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DIAGNOSTICS_SET_CASES_EMPTY_ERROR
        case ValidationErrors.SuccessGradeReset: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DIAGNOSTICS_SET_SUCCESS_GRADE_ERROR
        case ValidationErrors.ChecksEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.VERIFY_CHECKS_EMPTY_ERROR
        case ValidationErrors.CategoryNotSelected: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.CATEGORY_GRADE_NOT_SELECTED
        case ValidationErrors.AssessmentEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.EMPTY_ASSESSMENT
        case ValidationErrors.IdentificationEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.EMPTY_IDENTIFICATION
        case ValidationErrors.IdentificationMethodReset: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.SKIP_IDENTIFICATION_ERROR
        case ValidationErrors.QuestionAnswersEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.QUESTION_EMPTY_ANSWERS
        case ValidationErrors.QuestionsTitleEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS_QUESTION_EMPTY_TITLE
        case ValidationErrors.NextQuestionReset: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.NEXT_QUESTION_ERROR
        case ValidationErrors.TestSetsEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DIAGNOSTICS_TEST_SETS_EMPTY_ERROR
        case ValidationErrors.TestSetCasesEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DIAGNOSTICS_TEST_SETS_CASES_EMPTY_ERROR
        case ValidationErrors.ModuleError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.MODULE_ERROR
        case ValidationErrors.ControlEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.EMPTY_CONTROL
        case ValidationErrors.PreOfferDiscounts: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.PRE_OFFER_DISCOUNTS_ERROR
        case ValidationErrors.GradesEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.EMPTY_GRADES
        case ValidationErrors.GradeCategoriesEmpty: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.EMPTY_CATEGORY_GRADES
        case ValidationErrors.CheckError: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.CHECK_ERROR
        case ValidationErrors.EmailAddress: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.EMAIL_ADDRESS_ERROR
        case ValidationErrors.DataCollectionEmail: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DATA_COLLECTION_EMAIL_ERROR
        case ValidationErrors.ConfigDeletedPromotions: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DELETED_PROMOTIONS
        case ValidationErrors.DeletedPromotion: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DELETED_PROMOTION
        case ValidationErrors.ConfigDeletedCatalog: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DELETED_CATALOGS
        case ValidationErrors.DeletedCatalog: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DELETED_CATALOG
        case ValidationErrors.NotSelectedPromo: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.NO_SELECTED_CATALOG_PROMO
        case ValidationErrors.NotSelectedProvider: return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.NO_SELECTED_CATALOG_PRICE
        case ValidationErrors.NotSetCatalog: return strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.NO_SELECTED_CATALOG
        case ValidationErrors.NoUISettingsEnabled: return strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.NO_UI_SETTINGS_ENABLED
        default: return ''
    }
}