import * as React from 'react';
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { FileCard } from './FileCard';
import { useEffect, useState } from 'react';
import s from '../../../../../../../assets/css/scrollHide.module.css'
import { uploadFileType } from '../PhotographicStatePreview';
import { IPhotographic } from '../../../../../../../core/store';
import { IPhotographicFreeConfig } from '@piceasoft/core';
import { strings } from '../../../../../../../localization/strings';
import Dropzone from '../../../../../dropzone/Dropzone';

type TProps = {
    config?: IPhotographicFreeConfig
    data?: IPhotographic
    onClose?: () => void
    onResult?: (result: IPhotographic) => void
    onPending: (files: Array<uploadFileType>, isAdditional?: boolean, comment?: string) => void
    buttonsAlignCenter?: boolean
}

export const FreePreview: React.FC<TProps> = (props) => {

    const AllowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif"]
    const [files, setFiles] = useState<Array<uploadFileType>>([])
    const [warnings, setWarnings] = useState<string[]>([])
    const [comment, setComment] = useState<string>("")
    const confirmIsDisabled =
        warnings?.filter(v => ![
            strings.INSPECTIONS.PHOTOGRAPHIC.FILE_FORMAT_NOT_SUPPORT,
            strings.INSPECTIONS.PHOTOGRAPHIC.FILES_FORMAT_NOT_SUPPORT
        ]?.includes(v)).length > 0
        || files.length === 0

    const updateWarnings = () => {
        let uWarnings = []
        if (props.config?.minCount && files.length < props.config.minCount) {
            uWarnings.push(strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FILES_MIN_COUNT + " " + props.config.minCount)
        }
        if (props.config?.maxCount && files.length > props.config.maxCount) {
            uWarnings.push(`${strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FILES_ADDED_MAX} ${props.config.maxCount}. ${strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FILES_ADDED} ${files.length}.`)
        }
        if (props.config?.comment?.required && (!comment || comment.length < (props.config.comment?.minLength ?? 5))) {
            uWarnings.push(strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COMMENT_MIN_LENGTH)
        }
        setWarnings(uWarnings)
    }

    useEffect(() => {
        updateWarnings()
    }, [files, comment, props.config?.maxCount, props.config?.minCount])

    const addFiles = (upFiles: FileList) => {
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => AllowedFileTypes.includes(upFile.type))]
        const skippedFilesLength = upFiles.length - checkedUpFiles.length
        const newWarnings = warnings?.filter(v => ![strings.INSPECTIONS.PHOTOGRAPHIC.FILE_FORMAT_NOT_SUPPORT, strings.INSPECTIONS.PHOTOGRAPHIC.FILES_FORMAT_NOT_SUPPORT].includes(v))
        if (skippedFilesLength > 0) {
            if (upFiles.length > 1) {
                setWarnings([...newWarnings, strings.INSPECTIONS.PHOTOGRAPHIC.FILES_FORMAT_NOT_SUPPORT])
            } else {
                setWarnings([...newWarnings, strings.INSPECTIONS.PHOTOGRAPHIC.FILE_FORMAT_NOT_SUPPORT])
            }
        } else {
            setWarnings(newWarnings)
        }

        if (checkedUpFiles) {
            setFiles([...files, ...(checkedUpFiles.map(upFile => upFile &&
            {
                imgUrl: URL.createObjectURL(upFile),
                data: upFile
            }) as Array<uploadFileType>)])
        }
    }

    const deleleFile = (imgUrl: string) => {
        URL.revokeObjectURL(files.find(f => f.imgUrl === imgUrl)?.imgUrl as string)
        setFiles([...files.filter(f => f.imgUrl !== imgUrl)])
    }

    const confirmHandler = () => {
        if (props.onClose !== undefined) {
            props.onClose()
        }
        setTimeout(() => {
            props.onPending(files, false, comment)
        }, 1000)
    }

    const cancelHandler = () => {
        if (props.onClose !== undefined) {
            props.onClose()
        }
    }

    return (
        <Stack className={s.scrollHide} grow tokens={{ childrenGap: 16 }}>
            <Stack horizontalAlign="center">
                {props.config?.ui?.localText && <span dangerouslySetInnerHTML={{ __html: props.config?.ui?.localText }} />}
            </Stack>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <Dropzone minHeight={320} filesLenght={files.length} handleDrop={addFiles}>
                        <Stack horizontal wrap tokens={{ padding: 16, childrenGap: 8 }}>
                            {files.map(f =>
                                <FileCard
                                    key={f.imgUrl}
                                    status={strings.SPINNERS.LOADING}
                                    deleteFile={() => deleleFile(f.imgUrl)}
                                    iconName={'FileImage'}
                                    statusIcon=''
                                    title={f.data.name}
                                    imgUrl={f.imgUrl}
                                />
                            )}
                        </Stack>
                    </Dropzone>
                </Stack.Item>
                <Stack.Item>
                    {props.config?.comment && <TextField value={comment} onChange={(e, v) => setComment(v ?? "")} label={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.OPERATOR_MESSAGE} placeholder={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.OPERATOR_MESSAGE_PLACEHOLDER} />}
                    <Stack style={{ paddingTop: 12 }}>
                        {warnings.length > 0 ?
                            warnings.map(warning => <MessageBar key={warning} messageBarType={MessageBarType.error} isMultiline={false}>
                                {warning}.
                            </MessageBar>)
                            : <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
                                {strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.ALL_OK}
                            </MessageBar>}
                    </Stack>
                </Stack.Item>
            </Stack>
            <Stack horizontal horizontalAlign={props.buttonsAlignCenter === true && "center" || "end"} tokens={{ childrenGap: 8 }}>
                <PrimaryButton onClick={confirmHandler} disabled={confirmIsDisabled}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                {props.onClose !== undefined && <DefaultButton disabled onClick={cancelHandler}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>}
            </Stack>
        </Stack>
    )
}
