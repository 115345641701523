import { DirectionalHint, getTheme, TooltipHost } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { strings } from '../../../localization/strings'

type TProps = {
    content: string
}

export const Tooltip: React.FC<TProps> = (props) => {
    return (
        <TooltipHost content={props.content}
            directionalHint={DirectionalHint.bottomCenter}
            styles={{ root: { display: "flex", cursor: "default" } }}
            tooltipProps={{
                calloutProps: {
                    styles: {
                        beak: { background: theme.palette.black },
                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                    },
                },
            }}
        >
            {props.children}
        </TooltipHost>
    )
}

const theme = getTheme()
