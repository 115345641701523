import * as React from 'react';
import { SharedColors } from '@fluentui/theme';
import { FontIcon, Stack, Text } from '@fluentui/react';
import { strings } from '../../../../../../../localization/strings';
import { IInspectionConfig, IInspectionConfigUI } from '@piceasoft/core';

type TStateProps = {
    config: IInspectionConfig<IInspectionConfigUI>
    icon?: string
}

export const ErasesState: React.FC<TStateProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 24 }}>
            <Stack.Item style={{ color: SharedColors.gray40 }}>
                <Text>{props.config.ui?.description ?? strings.CONSTRUCTOR.INSPECTIONS.ERASE.DESCRIPTION}</Text>
            </Stack.Item>
            <Stack.Item>
                <Stack>
                    <Stack horizontalAlign="center">
                        <FontIcon iconName={props.icon ?? "EraseTool"} style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray20 }} />
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

