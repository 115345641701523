import { Stack, TextField, Text, Image, getTheme, ImageFit, Spinner, Icon, SharedColors, Separator } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { AllRoles, IPerson, IUser } from "../../../core/store";
import { getLocalizedRole } from "../../../core/store/typings/UserRoles"
import { strings } from "../../../localization/strings";
// import { Updatelogo } from "./Updatelogo";

export type TSettingsRolesTabProps = {
    data?: IPerson
    isUpdating: boolean
}

export const SettingsRolesTab: React.FC<TSettingsRolesTabProps> = props => {



    const roleIcon = (role: AllRoles) => {
        switch (role) {
            case 'Organization': return 'Work'
            case 'Administrator': return 'Admin'
            case 'Trader': return 'Commitments'
            case 'Supervisor': return 'PartyLeader'
            case 'Manager': return 'AccountManagement'
            case 'Master': return 'PlayerSettings'
            case 'Operator': return 'Telemarketer'
            case 'Photoreport': return 'Camera'
            case 'Service': return 'CRMServices'
            case 'Statistic': return 'Financial'
        }
    }


    return (
        <Stack style={{ width: 640 }} tokens={{ padding: 24, childrenGap: 16 }}>
            <Stack horizontal horizontalAlign="space-between">
                <Stack style={{ width: 360 }} verticalFill  >
                    <Stack tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.USER_SETTINGS.ROLES_TITLE}</Text>
                        </Stack.Item>
                        <Stack>
                            {props.data?.roles.map((role) => {
                                return (
                                    <Stack.Item key={role} style={{ border: '1px soid red' }}>
                                        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                                            <Icon iconName={roleIcon(role)} style={{ fontSize: 20 }} />
                                            <Stack verticalFill verticalAlign="center">
                                                <Text variant="mediumPlus" style={{ color: theme.palette.black, fontWeight: "normal", letterSpacing: "-.04em" }}>
                                                    {getLocalizedRole(role)}
                                                </Text>
                                                <Text variant="medium" style={{ color: SharedColors.gray40, fontWeight: "normal", letterSpacing: "-.04em" }}></Text>
                                            </Stack>
                                        </Stack>
                                        <Separator
                                            styles={{ root: { '::before': { backgroundColor: theme.palette.neutralTertiaryAlt } } }}
                                        />
                                    </Stack.Item>
                                )
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();

