import { Stack, SharedColors, FontIcon, Text, TooltipHost, TooltipOverflowMode, mergeStyleSets, ITooltipHostStyles, css } from "@fluentui/react"
import { IAIGradingConfig } from "@piceasoft/core"
import { strings } from "../../../../../../../localization/strings"

type TStateProps = {
    config: IAIGradingConfig
}

export const AIGradingState: React.FC<TStateProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 24 }}>
            <Stack.Item style={{ color: SharedColors.gray40 }}>
                <TooltipHost 
                    overflowMode={TooltipOverflowMode.Self}
                    content={props.config.config.ui?.description ?? strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.DESCRIPTION}
                    styles={tooltipStyles}
                    hostClassName={css(styles.title)}
                >
                    {props.config.config.ui?.description ?? strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.DESCRIPTION}
                </TooltipHost>  
            </Stack.Item>
            <Stack.Item>
                <Stack>
                    <Stack horizontalAlign="center">
                        <FontIcon iconName={props.config.ui?.icon ?? "Robot"} style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray20 }} />
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const styles = mergeStyleSets({
    title: [
        {
            overflow: 'hidden',
            overflowWrap: "break-word",
            maxHeight: 60,
        }
    ]
}) 

const tooltipStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'block' }
}