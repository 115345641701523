import { Stack, TextField, Text, getTheme, MessageBar, MessageBarType, Spinner, SharedColors, FontIcon, PrimaryButton } from "@fluentui/react"
import React from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { validatePassword } from "../../../components/shared/configurator/stages/assessment/dataCollection/modeConfigurator/pivots/helpers/validationRules";
import { PasswordPolicy } from "../../../components/shared/PasswordPolicy";
import { IPerson } from "../../../core/store";
import { IResponseError } from "@piceasoft/core";
import { strings } from "../../../localization/strings";

export type TSettingsPasswordTabProps = {
    data?: IPerson
    isUpdating: boolean
    onSubmit: (oldPassword: string, newPassword: string, setSuccessed: () => void) => void
    errors?: IResponseError[]
}

type TProps = {
    personData: IPerson
    newPassword?: string
    repeatNewPassword?: string
}
export const SettingsPasswordTab: React.FC<TSettingsPasswordTabProps> = props => {

    const [successed, setSuccessed] = React.useState<boolean>()
    const { control, handleSubmit, watch, trigger, reset, formState: { errors, isDirty, isValid } } = useForm<TProps>({ defaultValues: {}, mode: "all" });

    const onCancel = () => {
        reset({ newPassword: '', repeatNewPassword: '', personData: { ...props.data, password: '' } })
    }
    const onSubmit: SubmitHandler<TProps> = data => {
        setSuccessed(undefined)
        if (data.newPassword && data.newPassword === data.repeatNewPassword) {
            props.onSubmit(data.personData.password ?? '', data.newPassword, () => setSuccessed(true))
        }
    };

    return (
        <Stack>
            <Stack>
                {props.errors && props.errors.length > 0 && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {props.errors[0].description}
                    </MessageBar>
                )}
            </Stack>
            <Stack style={{ width: 640 }} tokens={{ padding: 24, childrenGap: 16 }}>
                <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack style={{ width: 360 }} verticalFill tokens={{ childrenGap: 16 }}>
                            <Stack tokens={{ childrenGap: 16 }} >
                                <Stack.Item>
                                    <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.USER_SETTINGS.CHANGE_PASSWORD_TITLE}</Text>
                                </Stack.Item>
                                <Controller
                                    control={control}
                                    name="personData.password"
                                    rules={{ required: strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.REQUIRE_FIELD }}
                                    render={({ field }) =>
                                        <TextField
                                            value={field.value}
                                            type="password"
                                            canRevealPassword
                                            onChange={field.onChange}
                                            required
                                            // onBlur={field.onBlur}
                                            label={strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.OLD_PASSWORD}
                                            placeholder={strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.OLD_PASSWORD_PLACEHOLDER}
                                            errorMessage={errors.personData?.password?.message}
                                        />
                                    }
                                />
                                <Stack.Item>
                                    <PasswordPolicy isManager />
                                    <Controller
                                        control={control}
                                        name="newPassword"
                                        rules={{ ...validatePassword(watch().repeatNewPassword, true) }}
                                        render={({ field }) =>
                                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                                <Stack.Item grow>
                                                    <TextField
                                                        value={field.value}
                                                        styles={{
                                                            fieldGroup: {
                                                                borderColor: field.value && !errors.newPassword?.message ? "#5DCA42" : undefined, ':after': {
                                                                    borderColor: field.value && !errors.newPassword?.message ? "#5DCA42" : undefined
                                                                }
                                                            }
                                                        }}
                                                        type="password"
                                                        required
                                                        canRevealPassword
                                                        onChange={(ev, val) => { field.onChange(val); trigger("repeatNewPassword") }}
                                                        // onBlur={field.onBlur}
                                                        label={strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.NEW_PASSWORD}
                                                        placeholder={strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.NEW_PASSWORD_PLACEHOLDER}
                                                        errorMessage={errors.newPassword?.message}
                                                    />
                                                </Stack.Item>
                                                <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>
                                                    <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().newPassword && !errors.newPassword) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                                </Stack.Item>
                                            </Stack>
                                        }
                                    />
                                </Stack.Item>
                                <Controller
                                    control={control}
                                    name="repeatNewPassword"
                                    rules={{
                                        // required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED,
                                        validate: (value) => {
                                            return (watch().newPassword === value) ? true : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED
                                        }
                                    }}
                                    render={({ field }) =>
                                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                                            <Stack.Item grow>
                                                <TextField
                                                    value={field.value}
                                                    styles={{
                                                        fieldGroup: {
                                                            borderColor: field.value && !errors.repeatNewPassword?.message ? "#5DCA42" : undefined, ':after': {
                                                                borderColor: field.value && !errors.repeatNewPassword?.message ? "#5DCA42" : undefined
                                                            }
                                                        }
                                                    }}
                                                    type="password"
                                                    required
                                                    canRevealPassword
                                                    onChange={field.onChange}
                                                    // onBlur={field.onBlur}
                                                    label={strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.REPEAT_NEW_PASSWORD}
                                                    placeholder={strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.REPEAT_NEW_PASSWORD_PLACEHOLDER}
                                                    errorMessage={errors.repeatNewPassword?.message}
                                                />
                                            </Stack.Item>
                                            <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>
                                                <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().repeatNewPassword && !errors.repeatNewPassword) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                            </Stack.Item>
                                        </Stack>
                                    }
                                />
                            </Stack>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
                                <Stack horizontal tokens={{ childrenGap: 8 }}>
                                    <SecondaryButton disabled={props.isUpdating || !isDirty} onClick={onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                                    <PrimaryButton disabled={props.isUpdating || !isValid} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                                </Stack>
                                {props.isUpdating && (
                                    <Spinner />
                                )}
                                {successed && (
                                    <FontIcon iconName={"CompletedSolid"} style={{ color: SharedColors.green10, fontSize: 20 }} />
                                )}
                            </Stack>
                        </Stack>

                    </Stack>
                </form>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();

