import * as React from 'react';
import {
    DocumentCard,
    DocumentCardActions,
    DocumentCardTitle,
    IDocumentCardStyles,
    mergeStyles,
    mergeStyleSets,
    Stack,
    FontIcon,
    FontSizes,
    Image,
    ImageFit,
    ProgressIndicator,
    getTheme,
    IButtonProps,
    Icon,
    IconButton
} from '@fluentui/react';

type StatePropsType = {
    title?: string
    status: string
    statusIcon?: string
    iconName: string
    isSending?: true
    persentSended?: number
    imgUrl?: string
    loadProgress?: number
}

type DispatchPropsType = {
    deleteFile: () => void
    moveUpFile?: () => void
    retryFile?: () => void
}

type PropsType = StatePropsType & DispatchPropsType

export const UploadFileCard: React.FunctionComponent<PropsType> = (props) => {

    const cardStyles: IDocumentCardStyles = {
        root: {
            display: "flex",
            flexFlow: "column",
            minWidth: 160,
            width: 160,
        },
    };

    const iconClass = mergeStyles({
        fontSize: "4em",
        margin: '0.4em 0'
    });
    const classNames = mergeStyleSets({
        deepSkyBlue: [{ color: 'gray' }, iconClass],
        greenYellow: [{ color: 'greenyellow' }, iconClass],
        salmon: [{ color: 'salmon' }, iconClass],
    });

    return (
        <DocumentCard styles={cardStyles}>
            <Stack style={{ width: 160, height: 120, textAlign: "center", justifyContent: "center", borderBottom: `1px solid ${theme.palette.neutralLight}`, background: props.imgUrl ? "black" : undefined }}>
                {!props.imgUrl
                    ? <FontIcon iconName={props.iconName} className={classNames.deepSkyBlue} />
                    : <Image src={props.imgUrl} width={160} height={120} imageFit={ImageFit.contain} />}
            </Stack>
            <ProgressIndicator percentComplete={!props.imgUrl ? (props.loadProgress ?? 0) : 100} />
            {props.title && <DocumentCardTitle styles={{ root: { fontSize: FontSizes.small, height: "32" } }} title={props.title} shouldTruncate />}
            <Stack horizontal grow tokens={{childrenGap: 8, padding: 4}}>
                {props.moveUpFile && (
                        <IconButton iconProps={{ iconName: 'Back' }} onClick={props.moveUpFile} />
                )}
                {props.retryFile && (
                        <IconButton iconProps={{ iconName: 'RepeatAll' }} onClick={props.retryFile} />
                )}
                <Stack horizontal horizontalAlign='end' grow>
                    <IconButton iconProps={{ iconName: 'Delete' }} onClick={props.deleteFile} />
                </Stack>
            </Stack>
        </DocumentCard>
    );
};

const theme = getTheme();
