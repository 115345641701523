import { IOrganizationSettings } from "../../core/store/typings/IOrganizationSettings"
import { TTabState } from "./Settings"
import { SettingsAdditionalTab } from "./tabs/SettingsAdditionalTab"
import { SettingsAuthorizationBasicTab } from "./tabs/SettingsAuthorizationBasicTab"
import { SettingsAuthorizationCertificatesTab } from "./tabs/SettingsAuthorizationCertificatesTab"
import { SettingsBillingTab } from "./tabs/SettingsBillingTab"
import { SettingsLocaleTab } from "./tabs/SettingsLocaleTab"
import { SettingsNotificationsTab } from "./tabs/SettingsNotificationsTab"
import { SettingsOverviewTab } from "./tabs/SettingsOverviewTab"
import { SettingsSecurityPiceasoftTab } from "./tabs/SettingsSecurityPiceasoftTab"
import { SettingsSecurityPrivateKeyTab } from "./tabs/SettingsSecurityPrivateKeyTab"

type TTabStateProps = {
    state: TTabState
    settings: IOrganizationSettings
    isUpdating: boolean
}

export const TabBasedState: React.FC<TTabStateProps> = props => {
    switch (props.state) {
        case "GENERAL.OVERVIEW": return <SettingsOverviewTab data={props.settings} isUpdating={props.isUpdating} />
        case "GENERAL.LOCALE": return <SettingsLocaleTab data={props.settings} isUpdating={props.isUpdating} />
        case "GENERAL.BILLING": return <SettingsBillingTab />
        case "GENERAL.NOTIFICATIONS": return <SettingsNotificationsTab />
        case "GENERAL.ADDITIONAL": return <SettingsAdditionalTab data={props.settings} isUpdating={props.isUpdating} />
        case "AUTHORIZATION.BASIC": return <SettingsAuthorizationBasicTab />
        case "AUTHORIZATION.CERTIFICATES": return <SettingsAuthorizationCertificatesTab />
        case "SECURITY.PRIVATE_KEY": return <SettingsSecurityPrivateKeyTab privateKey={props.settings.privateKey} organiationId={props.settings.id} />
        case "SECURITY.PICEASOFT": return <SettingsSecurityPiceasoftTab piceaCredentials={props.settings.piceaCredentials} organizationId={props.settings.id}/>
        case "INTEGRATION.HTTP": return <></>
        case "INTEGRATION.FTP": return <></>
    }
}