import React, { FC } from "react";
import { getTheme, Stack } from "@fluentui/react";
import { StageBox } from "../components/StageBox";
import SelfServiceConfigItem from "./selfService/SelfServiceConfigItem";
import SelfServiceRepairOfferItems from "./selfService/SelfServiceRepairOfferItems";
import { ISelfServiceWorkflowConfig } from "@piceasoft/core";
import { strings } from "../../../../localization/strings";

// TODO: update this props
type TProps = {
    config: ISelfServiceWorkflowConfig
    showSettings: () => void
    showConfigSettings: () => void
    showOfferSettings: (offerProviderCode?: string) => void
}
const SelfService: FC<TProps> = ({ config, showSettings, showConfigSettings, showOfferSettings }) => {
    return (
        <StageBox title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.HEADER} settingsButtonProps={{ onClick: showSettings }}>
            <Stack tokens={{ childrenGap: 16 }}>
                <SelfServiceConfigItem showConfigSettings={showConfigSettings} />
                <SelfServiceRepairOfferItems config={config} showOfferSettings={showOfferSettings} />
            </Stack>
        </StageBox>
    );
};

export default SelfService;
const theme = getTheme();
