import { NotificationMethods, INotificationMessage } from '@piceasoft/core';
import * as signalR from "@microsoft/signalr";
import { consoleStyles } from './console';

export const initializeNotifications = () => {

    const connection = new signalR.HubConnectionBuilder()
        .withUrl("/notificationHub")
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
                if (retryContext.elapsedMilliseconds < 30000) {
                    return Math.random() * 10000;
                } else {
                    return null;
                }
            }
        })
        .build();

    const start = async () => {
        try {
            console.debug("%c" + "SIGNALR: connection", consoleStyles.logic);
            console.info({ connection });
            await connection.start().then(() => {
                window.NOTIFICATIONS.onConnected();
            });
        } catch (error) {
            console.debug("%c" + "SIGNALR: connection.start()", consoleStyles.logic);
            console.info({ error });
            setTimeout(start, 5000);
        }
    };

    start();

    connection.on(NotificationMethods.NOTIFICATION, (message: INotificationMessage) => window.NOTIFICATIONS.onNotification(message));
    connection.onclose(window.NOTIFICATIONS.onDisconnected);
    connection.onreconnecting(window.NOTIFICATIONS.onReconnecting)
    connection.onreconnected(window.NOTIFICATIONS.onConnected);
}

window.NOTIFICATIONS_HANDLERS = {
    onNotification: (handler: (message: INotificationMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onNotification", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onNotification", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<INotificationMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onNotification', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onNotification', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onNotification", consoleStyles.addEventListener);
        };
    },

    onConnected: (handler: () => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onConnected", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onConnected", consoleStyles.eventHandler);
            const _event = event as Event;
            if (_event) {
                handler();
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onConnected', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onConnected', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onConnected", consoleStyles.addEventListener);
        };
    },

    onDisconnected: (handler: () => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onDisconnected", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onDisconnected", consoleStyles.eventHandler);
            const _event = event as Event;
            if (_event) {
                handler();
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onDisconnected', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onDisconnected', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onDisconnected", consoleStyles.addEventListener);
        };
    },

    onReconnecting: (handler: () => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onReconnecting", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onReconnecting", consoleStyles.eventHandler);
            const _event = event as Event;
            if (_event) {
                handler();
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onReconnecting', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onReconnecting', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onReconnecting", consoleStyles.addEventListener);
        };
    }
}

window.NOTIFICATIONS = {
    onNotification: (message: INotificationMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onNotification", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onNotification', {
            detail: message
        } as CustomEventInit<INotificationMessage>));
    },
    onConnected: () => {
        console.debug("%c" + "NOTIFICATIONS: onConnected")
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onConnected'));
    },
    onReconnecting: () => {
        console.debug("%c" + "NOTIFICATIONS: onReconnecting")
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onReconnecting'));
    },
    onDisconnected: () => {
        console.debug("%c" + "NOTIFICATIONS: onDisconnected")
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onDisconnected'));
        initializeNotifications()
    }
}