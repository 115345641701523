import { Stack } from "@fluentui/react"
import { Section } from "../../../../../components/shared/decorations/Section"
import { strings } from "../../../../../localization/strings"

type TCompanySettingsPivotProps = {
}

export const CompanyCertificatesPivot: React.FC<TCompanySettingsPivotProps> = (props) => {
    return (
        <Stack tokens={{ padding: "24px 32px 0px 32px", childrenGap: 16 }}>
            <Section title={strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.CERTIFICATES.HEADER}>
                {strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.CERTIFICATES.INFORMATION}
            </Section>
        </Stack>
    )
}