import {
    Stack, ImageFit, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps,
    TooltipHost, Sticky, StickyPositionType, IColumn, FontIcon,
    DetailsList, Image, getTheme, Text
} from "@fluentui/react"
import { SharedColors } from "@fluentui/theme"
import { IRenderFunction, SelectionMode } from "@fluentui/utilities"
import { onRenderColumnHeaderCommon } from "../../../../../components/shared/detailsList/commonRenders"
import { endpoints } from "../../../../../core/api/endpoints"
import { AIGradingResponseStatuses } from "@piceasoft/core"
import { AIGradingResultTypes } from "@piceasoft/core"
import { AIGradingResultValues } from "@piceasoft/core"
import { IAIGrading } from '@piceasoft/core';
import { IAIGradingImage, IAIGradingGradeImageResponseResult } from "@piceasoft/core"
import { strings } from "../../../../../localization/strings"

type TStateProps = {
    data: IAIGrading
}

export const AIGradingDefaultModeResult: React.FC<TStateProps> = (props) => {

    const getGradeText = (grade?: string): string => {
        switch (grade) {
            case undefined: return strings.CONSTRUCTOR.COMMON.NO_GRADE
            case "": return strings.CONSTRUCTOR.GRADES.DECLINE
            default: return grade
        }
    }

    type TIconState = {
        name: string
        color: string
    }

    const getAIGradingResultContent = (data: IAIGradingImage) => {      

        return (
            <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 16 }}>
                <Stack tokens={{ childrenGap: 16 }}>
                    {data.response.image_data?.filter(i => i.value.file_id).map((i, index) => {
                        const url = endpoints.v1.apiFile(i.value.file_id as string)
                        return (
                            <Image
                                key={index}
                                style={{ backgroundColor: theme.palette.black }}
                                imageFit={ImageFit.contain}
                                src={url}
                                width={240} height={180}
                            />
                        )
                    })}
                </Stack>
                <Stack.Item>
                    <pre style={{ overflow: "hidden", fontSize: 10 }}>{JSON.stringify(data.response, null, 2)}</pre>
                </Stack.Item>
            </Stack>
        )
    }

    const getAIGradingResultValueIconState = (result?: IAIGradingGradeImageResponseResult): TIconState => {
        switch (result?.result) {
            case AIGradingResultValues.NoResultIssues: return ({ name: 'StatusCircleCheckmark', color: SharedColors.green10 })
            case AIGradingResultValues.ResultIssues: return ({ name: 'StatusCircleBlock2', color: SharedColors.red10 })
            case AIGradingResultValues.ResultFailed: return ({ name: 'StatusCircleQuestionMark', color: SharedColors.gray20 })
            default: return ({ name: 'StatusCircleCheckmark', color: SharedColors.green10 })
        }
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        props.styles = { root: { padding: 0 } }
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.INSPECTIONS.AIGRADING.RESULT_TABLE.COLUMNS.IMAGE.TITLE,
            fieldName: 'image',
            minWidth: 180,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IAIGradingImage) => {
                return (
                    <Stack horizontalAlign="start">
                        <TooltipHost tooltipProps={{ maxWidth: '1024px' }} content={getAIGradingResultContent(item)}>
                            <Image style={{ backgroundColor: theme.palette.black }} imageFit={ImageFit.contain} src={item.path} width={160} height={120} />
                        </TooltipHost>
                    </Stack>
                )
            },
        },
        {
            key: 'column2',
            name: strings.INSPECTIONS.AIGRADING.RESULT_TABLE.COLUMNS.SCREEN_CRACK.TITLE,
            fieldName: 'screenCrack',
            minWidth: 60,
            maxWidth: 60,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IAIGradingImage) => {
                const response = item.response
                const result = response?.results?.find(i => i.result_type === AIGradingResultTypes.ScreenCrack)
                const iconState = getAIGradingResultValueIconState(result)
                return (
                    <Stack grow verticalFill horizontalAlign="center" verticalAlign="center">
                        {(response.status !== AIGradingResponseStatuses.Success) && (
                            <FontIcon iconName={"StatusCircleQuestionMark"} style={{ color: SharedColors.gray20, fontSize: 28 }} />
                        ) || (
                                <FontIcon iconName={iconState.name} style={{ color: iconState.color, fontSize: 28 }} />
                            )}
                    </Stack>
                )
            }
        },
        {
            key: 'column3',
            name: strings.INSPECTIONS.AIGRADING.RESULT_TABLE.COLUMNS.SCREEN_SCRATCH.TITLE,
            fieldName: 'screenScratch',
            minWidth: 60,
            maxWidth: 60,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IAIGradingImage) => {
                const response = item.response
                const result = response?.results?.find(i => i.result_type === AIGradingResultTypes.ScreenScratch)
                const iconState = getAIGradingResultValueIconState(result)
                return (
                    <Stack grow verticalFill horizontalAlign="center" verticalAlign="center">
                        {(response.status !== AIGradingResponseStatuses.Success) && (
                            <FontIcon iconName={"StatusCircleQuestionMark"} style={{ color: SharedColors.gray20, fontSize: 28 }} />
                        ) || (
                                <FontIcon iconName={iconState.name} style={{ color: iconState.color, fontSize: 28 }} />
                            )}
                    </Stack>
                )
            }
        },
        {
            key: 'column4',
            name: strings.INSPECTIONS.AIGRADING.RESULT_TABLE.COLUMNS.SCREEN_COVER.TITLE,
            fieldName: 'screenCover',
            minWidth: 60,
            maxWidth: 60,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IAIGradingImage) => {
                const response = item.response
                const result = response?.results?.find(i => i.result_type === AIGradingResultTypes.ScreenCover)
                const iconState = getAIGradingResultValueIconState(result)
                return (
                    <Stack grow verticalFill horizontalAlign="center" verticalAlign="center">
                        {(response.status !== AIGradingResponseStatuses.Success) && (
                            <FontIcon iconName={"StatusCircleQuestionMark"} style={{ color: SharedColors.gray20, fontSize: 28 }} />
                        ) || (
                                <FontIcon iconName={iconState.name} style={{ color: iconState.color, fontSize: 28 }} />
                            )}
                    </Stack>
                )
            }
        },
        {
            key: 'column5',
            name: strings.INSPECTIONS.AIGRADING.RESULT_TABLE.COLUMNS.GRADE.TITLE,
            fieldName: 'grade',
            minWidth: 200,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IAIGradingImage) => {
                const textContent = item.response.status !== AIGradingResponseStatuses.Success ? item.response.error_details : getGradeText(item.grade)
                return (
                    <Stack grow verticalFill verticalAlign="center" style={{ maxWidth: 200 }}>
                        <TooltipHost content={textContent}>
                            <Text block nowrap style={{ fontWeight: 500, color: SharedColors.gray30, maxWidth: 200 }}>{textContent}</Text>
                        </TooltipHost>
                    </Stack>
                )
            }
        }
    ]

    return (
        <>
            {props.data?.images && <DetailsList
                items={props.data.images}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={onRenderDetailsHeader}
            />}
        </>
    )
}

const theme = getTheme()