import { getTheme, IconButton, SharedColors, Stack, Text, Image, ImageFit, Spinner, SpinnerSize, Slider } from '@fluentui/react'
import { useId } from '@uifabric/react-hooks'
import * as React from 'react'
import { endpoints } from '../../../core/api/endpoints'
import { strings } from '../../../localization/strings'
import './styles.css'

type TSliderDirection = 'left' | 'right'

interface ISliderImage {
    image: string;
    preview: string;
    text?: string;
}

type TProps = {
    /** Array to display on the slider image */
    data: ISliderImage[];
    /** Left, Center, or Right */
    direction?: TSliderDirection;
    /** Whether to display a description image */
    showDescription?: boolean;
    /** Set size for slider image */
    width?: number | string;
}

export const SliderImage: React.FC<TProps> = ({ data, width, showDescription, direction }) => {

    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [imageIsLoaded, setImageIsLoaded] = React.useState(false)
    const [zoomValue, setZoomValue] = React.useState<{ zoom: number }>({ zoom: 1 })
    const [cursorPosition, setCursorPosition] = React.useState<{ x: number, y: number }>()
    const imgId = useId('img')

    const zoomMax = 3
    const zoomMin = 1

    let refImage = React.useRef<HTMLImageElement | null>(null)
    let refLens = React.useRef<HTMLDivElement | null>(null)
    let refResult = React.useRef<HTMLDivElement | null>(null)

    React.useEffect(() => {
        setImageIsLoaded(false)
    }, [selectedIndex])

    React.useEffect(() => {
        if (cursorPosition) {
            moveLens(cursorPosition)
        }
    }, [zoomValue])

    React.useEffect(() => {
        setZoomValue({ zoom: 1 })
    }, [refImage.current?.height, refImage.current?.width])

    const getCursorPos = (e: MouseEvent | { x: number, y: number }) => {
        let x = 0
        let y = 0
        const getBoundingImage = refImage.current?.getBoundingClientRect()

        e = e || window.event
        // console.log(getBoundingImage)
        if (getBoundingImage) {
            x = (((e as MouseEvent).pageX ?? e.x) - getBoundingImage.left - window.pageXOffset)
            y = (((e as MouseEvent).pageY ?? e.y) - getBoundingImage.top - y - window.pageYOffset)
        }
        return { x, y }
    }

    function moveLens(e: MouseEvent | { x: number, y: number }) {
        if (refImage.current && refResult.current && refLens.current) {
            if (typeof e === MouseEvent.toString()) {
                (e as MouseEvent).preventDefault()
            }
            let x = 0
            let y = 0
            const pos = getCursorPos(e)
            x = pos.x - refLens.current.offsetWidth / 2
            y = pos.y - refLens.current.offsetHeight / 2

            if (x > refImage.current.width - refLens.current.offsetWidth) {
                x = refImage.current.width - refLens.current.offsetWidth
            }
            if (x < 0) {
                x = 0
            }
            if (y > refImage.current.height - refLens.current.offsetHeight) {
                y = refImage.current.height - refLens.current.offsetHeight
            }
            if (y < 0) {
                y = 0
            }

            refLens.current.style.left = `${x}px`
            refLens.current.style.top = `${y}px`
            refResult.current.style.backgroundPosition = `-${x * refResult.current.offsetWidth / refLens.current.offsetWidth}px -${y * refResult.current.offsetHeight / refLens.current.offsetHeight}px`
        }
    }

    React.useEffect(() => {
        if (refImage.current && refResult.current && refLens.current) {
            refResult.current.style.backgroundSize = `${refImage.current.width * refResult.current.offsetWidth / refLens.current.offsetWidth}px ${refImage.current.height * refResult.current.offsetHeight / refLens.current.offsetHeight}px`
        }
    }, [refImage.current, zoomValue])

    const onPrevSlider = React.useCallback(() => {
        setSelectedIndex(prev => (prev - 1 + data.length) % data.length)
    }, [])

    const onNextSlider = React.useCallback(() => {
        setSelectedIndex(prev => (prev + 1) % data.length)
    }, [])

    const onScroll = () => {
        if (zoomValue.zoom < zoomMax) {
            setZoomValue({ zoom: zoomValue.zoom + 1 })
        } else if (zoomValue.zoom === zoomMax) {
            setZoomValue({ zoom: zoomMin })
        }
    }

    const onImageDownload = () => {
          location.href = endpoints.v1.downloadPhoto(data[selectedIndex].image, data[selectedIndex].text);
    }

    return (
        <Stack>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        {showDescription && (
                            <Text style={{ color: theme.palette.black, fontWeight: 600 }}>
                                {data[selectedIndex].text}
                            </Text>
                        )}
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal horizontalAlign='center' tokens={{ childrenGap: 6 }} style={{ position: 'relative' }}>
                            <Stack verticalAlign='center' horizontalAlign='center' className='react-slider__areaZoom' style={{ height: 280, width: 210, backgroundColor: theme.palette.black, border: `1px solid ${SharedColors.gray40}` }}>
                                {!imageIsLoaded && (
                                    <Spinner size={SpinnerSize.small} />
                                )}
                                <Stack.Item style={{ position: 'relative', overflow: 'hidden', cursor: 'crosshair' }}>
                                    <div onMouseMove={(event: React.MouseEvent) => {
                                        setCursorPosition({ x: event.pageX, y: event.pageY });
                                        moveLens(event.nativeEvent)
                                    }}
                                        onClick={() => onScroll()}
                                        ref={refLens}
                                        style={{
                                            display: !imageIsLoaded ? 'none' : undefined,
                                            opacity: 0.4, position: 'absolute', zIndex: 3,
                                            width: (100 / zoomValue.zoom % 2) !== 0 ? 100 / zoomValue.zoom : 100 / zoomValue.zoom + 1,
                                            height: (100 / zoomValue.zoom % 2) !== 0 ? 100 / zoomValue.zoom : 100 / zoomValue.zoom + 1,
                                            backgroundColor: '#fff', transition: 'opacity .3s ease-in-out',
                                        }}
                                    />
                                    <img onMouseMove={(event: React.MouseEvent) => {
                                        setCursorPosition({ x: event.pageX, y: event.pageY });
                                        moveLens(event.nativeEvent)
                                    }}
                                        onClick={() => onScroll()}
                                        onLoad={() =>
                                            setTimeout(() => {
                                                setImageIsLoaded(true)
                                            }, 250)
                                        }
                                        style={{ display: !imageIsLoaded ? 'none' : 'block', background: theme.palette.black, maxWidth: '100%', maxHeight: '100%', textIndent: -9999 }}
                                        src={data[selectedIndex].image} alt={data[selectedIndex].preview} ref={refImage}
                                        id={imgId}
                                    />
                                </Stack.Item>
                                <div ref={refResult} className={"react-slider__imgZoom"} style={{
                                    backgroundColor: theme.palette.black,
                                    backgroundImage: `url(${data[selectedIndex].image})`,
                                    left: `${direction === 'left' && '-107%'}`,
                                    boxShadow: theme.effects.elevation64
                                }} />
                            </Stack>
                            <Stack>
                                <IconButton onClick={onImageDownload} styles={{ icon: { padding: '8px 7px 8px 9px' } }} iconProps={{ iconName: 'Download' }} />
                                <Slider styles={{ root: { height: 100, marginLeft: 2 } }} vertical showValue={false} min={zoomMin} max={zoomMax} step={1} value={zoomValue.zoom} onChange={val => setZoomValue({ zoom: val })} />
                                <Stack verticalFill verticalAlign="end">
                                    <IconButton iconProps={{ iconName: 'ChevronRight' }} onClick={onNextSlider} />
                                    <IconButton iconProps={{ iconName: 'ChevronLeft' }} onClick={onPrevSlider} />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                    <Stack grow horizontal tokens={{ childrenGap: 4 }} style={{ width: 210 }}>
                        {data.map((item, idx) => (
                            <Image styles={{
                                root: {
                                    border: '1px solid #fff',
                                    outline: idx === selectedIndex ? `1px solid ${SharedColors.gray40}` : '1px solid #fff'
                                },
                                image: {
                                    opacity: idx !== selectedIndex ? .7 : undefined,
                                    cursor: 'pointer',
                                    selectors: {
                                        ':hover': {
                                            opacity: 1
                                        }
                                    }
                                }
                            }} key={idx} height={40} width={30} imageFit={ImageFit.contain} onClick={() => setSelectedIndex(idx)} style={sliderImgStyle} src={item.preview} alt="" />
                        ))}
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

// const imgZoomClassname = mergeStyleSets([{
//   position: 'absolute',
//   top: 0,
//   left: '100%',
//   transform: 'translateX(20px)',
//   width: 400,
//   height: 400,
//   backgroundRepeat: 'no-repeat',
//   border: 'thin solid #ccc',
//   opacity: 0,
//   zIndex: -9999,
//   transition: 'opacity .3s ease-in-out'
// }]);

// const buttonStyle: React.CSSProperties = {
//   width: 31,
//   height: 70,
//   background: '#999',
//   color: '#fff',
//   // position: 'absolute',
//   top: '50%',
//   zIndex: 4,
//   transform: 'translateY(-55%)',
//   opacity: 0.75,
//   outline: 0,
//   border: 0,
//   cursor: 'pointer'
// };

const sliderImgStyle: React.CSSProperties = {
    // border: 'thin solid #ccc',
    background: theme.palette.black,
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block',
    textIndent: -9999
}