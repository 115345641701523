import React from "react";
import { getTheme, Icon, ITooltipProps, Link, SharedColors, Stack, Text, TooltipHost } from "@fluentui/react";
import { Tooltip } from "../help/Tooltip";
import { useId } from "@uifabric/react-hooks";
import QRCode from "react-qr-code";
import { strings } from "../../../localization/strings";

type TProps =
    {
        qrCodeLink: string
    }

export const WorkflowQrCodeItem : React.FC<TProps> = (props) => {
    const tooltipId = useId('tooltip')
    
    const qrCodeTooltipProps: ITooltipProps = {
        onRenderContent: () => (
            <div style={{ boxShadow: theme.effects.elevation8, padding: 12, overflow: "hidden" }}>
                <QRCode value={ props.qrCodeLink  }
                        size={130}
                        fgColor={SharedColors.gray40} />
            </div>
        ),
    };
    return (
        <Stack horizontal verticalFill tokens={{ childrenGap: 20 }} >
            <Stack.Item styles={{ root: { minWidth: 200, maxWidth: 200 } }}>
                <Stack horizontal verticalAlign={"center"}>
                    <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 500 }}>
                        {strings.ORGANIZATION.SERVICES.SERVICE.WORKFLOW_QR_CODE}                        
                    </Text>
                    <Tooltip content={strings.ORGANIZATION.SERVICES.SERVICE.QR_CODE_INFO}>
                        <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.blue10, marginLeft:'1rem' }} />
                    </Tooltip>
                </Stack>
            </Stack.Item>
            <Stack.Item styles={{ root: { maxWidth: 480 } }}>
                <TooltipHost tooltipProps={qrCodeTooltipProps} id={tooltipId}>
                    <Link aria-describedby={tooltipId} href={props.qrCodeLink} target="_blank">
                        { (props.qrCodeLink.length > 100) && (props.qrCodeLink.slice(0, 100) + '...') || props.qrCodeLink }
                    </Link>
                </TooltipHost>
            </Stack.Item>
        </Stack>
    )
}
const theme = getTheme();