import {
    Stack, getTheme, Text, IconButton, IIconProps,
    SharedColors, IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject,
    FontWeights, mergeStyleSets, ScrollablePane, DirectionalHint, Icon, IPivotItemProps,
    IRenderFunction, Pivot, PivotItem, TooltipHost
} from "@fluentui/react"
import * as React from 'react';
import { Dispatch } from "react";
import { useSelector } from "react-redux";
import { ISoftwareConfig, ISoftwarePiceaConfig, IStore } from "../../../../../../../core/store";
import { IInspectionConfig, IInspectionConfigUI, SoftwareModes, Inspections, IGrade } from '@piceasoft/core';
import { IGradesCategory } from "@piceasoft/core";
import { actionCreators, getStageInspectionModules } from "../../../../../../../core/actions/configurator-actions";
import { getLocalizedInspectionName, getLocalizedInspectionModeName } from "../../../../../../../localization/helpers/transaction";
import { strings } from "../../../../../../../localization/strings";
import { PiceaChecksConfiguratorModePivot } from "./pivots/PiceaChecksConfiguratorModePivot";
import { PiceaSettingsConfiguratorModePivot } from "./pivots/PiceaSettingsConfiguratorModePivot";
import { ProcessStages } from "@piceasoft/core";
import { CSHHelpLink } from "../../../../../help/CSHHelp";

type TProps = {
    index: number
    stage: ProcessStages
    onDissmiss: () => void
    onChangeDispatch: Dispatch<any>
    withoutGrades?: boolean
}

type TSoftwareModeConfiguratorPivotState = "common" | "checks"

export const SoftwareModeConfigurator: React.FC<TProps> = ({ stage, index, onDissmiss, onChangeDispatch, withoutGrades }) => {

    const grades = useSelector((s: IStore )=> s.configurator.grades)
    const categoryGrades = useSelector((s: IStore )=> s.configurator.gradesCategories)
    const useGradesCategories = useSelector((s: IStore)=> s.configurator.useGradesCategories)
    const software = useSelector((s: IStore) => getStageInspectionModules(stage, s)?.find(i => i.index === index)?.config as ISoftwareConfig)
    const [pivotState, setPivotState] = React.useState<TSoftwareModeConfiguratorPivotState>("checks")

    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(item?.props.itemKey! as TSoftwareModeConfiguratorPivotState);
    };

    const getTabId = (itemKey: string) => {
        return `SoftwareModeConfiguratorPivot_${itemKey}`;
    };

    const getPivotHeaderText = (m: string): string => {
        const commonText = software.errors?.find((error) => error.code === 10)? strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_WAS_RESET : strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.MODE_CONFIGURATOR.PIVOTS.COMMON
        switch (m) {
            case "checks": return strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.CHECK_LIST_TITLE
            case "common": return commonText
            default: return m;
        }
    }

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
                </TooltipHost>
            </span>
        )
    }
    const onRenderItemLinkWarning: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText} tooltipProps={{ calloutProps: { styles: { calloutMain: { "p": {} } } } }}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px', color: SharedColors.red10 }} />
                </TooltipHost>
            </span>
        )

    }

    const onConfigConfirmHandler = (config: ISoftwarePiceaConfig | IInspectionConfig<IInspectionConfigUI>) => onChangeDispatch(actionCreators.inspections.software.editModeConfig(stage, index, config))

    return (
        <Stack grow verticalFill horizontal>
            <Stack verticalFill grow>
                <Stack horizontal grow style={{ padding: 8 }}>
                    <Stack style={{ padding: 8 }} grow tokens={{ childrenGap: 4 }}>
                        <Text variant="xLarge">{software?.ui?.title ?? getLocalizedInspectionName(Inspections.Software)}</Text>
                        <Text variant="small">{getLocalizedInspectionModeName(Inspections.Software, software as ISoftwareConfig)}</Text>
                    </Stack>
                    <Stack horizontal style={{ alignItems: "center" }}>
                        <CSHHelpLink articleid="1142"/>
                        <IconButton onClick={onDissmiss} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                    </Stack>
                </Stack>
                <Stack.Item tokens={{ padding: "0px 16px 0px 16px" }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                    <Pivot styles={pivotStyles} selectedKey={pivotState} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                        <PivotItem itemIcon={'List'} headerText={getPivotHeaderText("checks")} onRenderItemLink={software.errors?.find((error) => error.code === 19)?onRenderItemLinkWarning:onRenderItemLink} itemKey="checks" />
                        <PivotItem itemIcon={'Settings'} headerText={getPivotHeaderText("common")} onRenderItemLink={software.errors?.find((error) => error.code === 10)?onRenderItemLinkWarning:onRenderItemLink} itemKey="common" />
                    </Pivot>
                </Stack.Item>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <PivotBasedState
                            state={pivotState}
                            software={software}
                            grades={grades}
                            categoryGrades={categoryGrades}
                            useGradesCategories={useGradesCategories}
                            onConfigConfirm={onConfigConfirmHandler}
                            onDissmiss={onDissmiss}
                            onConfirm={(newConfig) => onChangeDispatch(actionCreators.inspections.software.editSoftware(stage,index, newConfig))}
                            withoutGrades={withoutGrades}
                        />
                    </ScrollablePane>
                </Stack.Item>
            </Stack>
        </Stack >
    )
}

type TStateProps = {
    state: TSoftwareModeConfiguratorPivotState
    software: ISoftwareConfig
    grades: IGrade[]
    categoryGrades?: IGradesCategory[]
    useGradesCategories?: boolean
    onConfigConfirm: (config: ISoftwarePiceaConfig | IInspectionConfig<IInspectionConfigUI>) => void
    onConfirm: (config: ISoftwareConfig) => void
    onDissmiss: () => void
    withoutGrades?: boolean
}

const PivotBasedState: React.FC<TStateProps> = (props) => {
    switch (props.state) {
        case "common":
            switch (props.software.mode) {
                case SoftwareModes.Piceasoft:
                    return <PiceaSettingsConfiguratorModePivot
                        onModeConfirm={props.onConfigConfirm}
                        mode={props.software.mode}
                        gradesCategory={props.software.gradesCategory}
                        config={props.software.config as ISoftwarePiceaConfig}
                        errorSoftwareMessage={props.software.errors?.find((error) => error.code === 10) ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.WARNING_OPTION_TURNED_OFF : undefined}
                        withoutGrades={props.withoutGrades}
                    />
                case SoftwareModes.PiceaMobile:
                    return <PiceaSettingsConfiguratorModePivot
                        onModeConfirm={props.onConfigConfirm}
                        mode={props.software.mode}
                        gradesCategory={props.software.gradesCategory}
                        config={props.software.config as ISoftwarePiceaConfig}
                        errorSoftwareMessage={props.software.errors?.find((error) => error.code === 10) ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.WARNING_OPTION_TURNED_OFF : undefined}
                        withoutGrades={props.withoutGrades}
                     />
                default: 
                    return <></>
            }
        case "checks":
            switch (props.software.mode) {
                case SoftwareModes.Piceasoft:
                    return <PiceaChecksConfiguratorModePivot
                        config={props.software.config as ISoftwarePiceaConfig}
                        mode={props.software.mode}
                        grades={props.grades}
                        gradesCategories={props.categoryGrades}
                        useGradesCategories={props.useGradesCategories}
                        onModeConfirm={props.onConfigConfirm}
                        withoutGrades={props.withoutGrades}
                    />
                case SoftwareModes.PiceaMobile:
                    return <PiceaChecksConfiguratorModePivot
                        config={props.software.config as ISoftwarePiceaConfig}
                        mode={props.software.mode}
                        grades={props.grades}
                        gradesCategories={props.categoryGrades}
                        useGradesCategories={props.useGradesCategories}
                        onModeConfirm={props.onConfigConfirm}
                        withoutGrades={props.withoutGrades}
                    />
                default: 
                    return <></>
            }
        default: return <></>
    }
}

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}

const contentStyles = mergeStyleSets({
    panel: {
        width: '640px',
        maxWidth: '640px',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        boxShadow: theme.effects.elevation64,
        backgroundColor: theme.palette.white
    },
    container: {
        width: '640px',
        maxWidth: '640px',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        boxShadow: theme.effects.elevation64,
        backgroundColor: theme.palette.white
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        heigth: '100%',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
