import { Stack, ScrollablePane } from "@fluentui/react"
import { CustomSeparator } from "../../../../../components/shared/decorations/CustomSeparator"
import { RemoveArea } from "../../../../../components/shared/decorations/RemoveArea"
import { IContainer } from "../../../../../core/store"
import { strings } from "../../../../../localization/strings"
import { ContainerHelpPivot } from "./pivots/ContainerHelpPivot"
import { ContainerInfoPivot } from "./pivots/ContainerInfoPivot"
import { ContainerServicesPivot } from "./pivots/ContainerServicesPivot"
import { ContainerSettingsPivot } from "./pivots/ContainerSettingsPivot"
import { TContainerPivotState } from "./StructureContainer"

type TContainerPivotBasedStateProps = {
    state: TContainerPivotState
    item: IContainer
    toggleUseServices: () => void
    setInformation: (information: string) => void
    isFetching: boolean
    infoWasSaved: boolean
    resetInfoWasSaved: () => void
    onDelete: () => void
    updateState: (item: IContainer) => void
}

export const ContainerPivotBasedState: React.FC<TContainerPivotBasedStateProps> = (props) => {
    switch (props.state) {
        case "settings": return (
            <ContainerSettingsPivot item={props.item} updateState={props.updateState} />
        )
        case "information": return (
            <Stack verticalFill tokens={{ padding: "24px 32px", childrenGap: 16 }}>
                <ContainerInfoPivot item={props.item} />
                <CustomSeparator />
                <RemoveArea
                    href="1104"
                    hrefText={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.REMOVE_AREA.HREF_TEXT}
                    onDelete={props.onDelete}
                    text={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.REMOVE_AREA.TEXT}
                    title={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.REMOVE_AREA.TITLE} />
            </Stack>
        )
        case "services": return (
            <Stack verticalFill tokens={{ padding: "24px 0px 0px 0px", childrenGap: 16 }}>
                <ContainerServicesPivot item={props.item} toggleUseServices={props.toggleUseServices} />
            </Stack>
        )
        case "help": return (
                <ContainerHelpPivot
                    disabled={props.item.removed}
                    information={props.item.information}
                    setInformation={props.setInformation}
                    isFetching={props.isFetching}
                    infoWasSaved={props.infoWasSaved}
                    resetInfoWasSaved={props.resetInfoWasSaved}
                />
        )
    }
}