import {
    getTheme, Stack, Text, PrimaryButton,
    ScrollablePane, Sticky, MessageBar,
    MessageBarType, Toggle
} from '@fluentui/react'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { strings } from '../../../localization/strings'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { IDisclaimer } from '@piceasoft/core'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { IResponseError } from '@piceasoft/core'
import { CSHHelpLink } from '../help/CSHHelp'
import { ParameterText } from '../configurator/components/ParameterText'
type TProps = {
    onFormSubmit: (item: IDisclaimer) => void
    onCancel: () => void
    data?: IDisclaimer
}

export const DisclaimerFrom: React.FC<TProps> = ({ data, onCancel, onFormSubmit }) => {
    const [iconErrors, setIconErrors] = React.useState<IResponseError[]>([])
    const [checkedPrimary, setCheckedPrimary] = React.useState(data?.buttons?.primary !== null && data?.buttons?.primary !== undefined && data?.buttons?.primary!=='' ? true : false);
    const [checkedSecondary, setCheckedSecondary] = React.useState(data?.buttons?.secondary !== null && data?.buttons?.secondary !== undefined && data?.buttons?.secondary !==''? true : false);
    const { control, handleSubmit, setValue, formState: { errors }} = useForm<IDisclaimer>({ defaultValues: { ...data } });
    
    const onSubmit: SubmitHandler<IDisclaimer> = async data => {
        let errors: IResponseError[] = []
        if (errors.length === 0) {
             if(!checkedPrimary)
                 data.buttons.primary = undefined
             if(!checkedSecondary)
                 data.buttons.secondary = undefined
            onFormSubmit(data)
        } else {
            setIconErrors(errors)
        }
    };

    const onCheckedPrimaryChange = (checkedPrimary: boolean | undefined,  text?: string) => {
        setCheckedPrimary(checkedPrimary ?? false);
        setValue('buttons.primary', checkedPrimary ? text != undefined ? text:strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_PRIMARY : strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_PRIMARY);
    };

    const onCheckedSecondaryChange = (checkedSecondary: boolean | undefined, text?: string) => {
        setCheckedSecondary(checkedSecondary ?? false);
        setValue('buttons.secondary', checkedSecondary ? text != undefined ? text:strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_SECONDARY : strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_SECONDARY);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        {iconErrors.length > 0 && (
                            <Sticky>
                                <MessageBar messageBarType={MessageBarType.error}>
                                    <Stack tokens={{ childrenGap: 2 }}>
                                        {iconErrors.map(i => <Text>{i.description}</Text>)}
                                    </Stack>
                                </MessageBar>
                            </Sticky>
                        )}
                        <Stack verticalFill tokens={{ padding: "0px 20px 20px 20px", childrenGap: 16 }}>
                            <Controller
                                control={control}
                                name={"name"}
                                rules={{
                                    validate: (value) => value && value.trim().length > 0 ? true : strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_NAME_REQUIRED,
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_NAME_REQUIRED,
                                }}
                                render={({ field }) =>
                                    <ParameterText
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        maxLength={50}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_NAME_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"title"}
                                rules={{
                                    validate: (value) => value && value.trim().length > 0 ? true : strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_TITLE_REQUIRED,
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_TITLE_REQUIRED,
                                }}
                                render={({ field }) =>
                                    <ParameterText
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        maxLength={100}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_TITLE_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_TITLE_PLACEHOLDER}
                                        errorMessage={errors.title?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"body"}
                                rules={{
                                    validate: (value) => value && value.trim().length > 0 ? true : strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_BODY_REQUIRED,
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_BODY_REQUIRED,
                                }}
                                render={({ field }) =>
                                    <ParameterText
                                        multiline
                                        required
                                        value={field.value ?? ""}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ height: 200 }}
                                        maxLength={5000}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_BODY_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_BODY_PLACEHOLDER}
                                        errorMessage={errors.body?.message}
                                    />
                                }
                            />
                            <Controller
                                name="buttons.primary"
                                control={control}
                                render={({ field }) =>
                                    <Stack>
                                        <Toggle
                                            checked={checkedPrimary}
                                            onChange={(ev, checkedPrimary) => onCheckedPrimaryChange(checkedPrimary, field.value)}
                                            onBlur={field.onBlur}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_PRIMARY_LABEL}
                                            offText={strings.BUTTONS.TOGGLE_OFF}
                                            onText={strings.BUTTONS.TOGGLE_ON}
                                        />
                                        <ParameterText
                                            disabled={!checkedPrimary}
                                            value={field.value ?? strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_PRIMARY}
                                            onChange={field.onChange}
                                            onRenderLabel={onRenderLabel}
                                            maxLength={20}
                                        />
                                    </Stack>
                                }
                            />
                            <Controller
                                name="buttons.secondary"
                                control={control}
                                render={({ field }) =>
                                    <Stack>
                                        <Toggle
                                            checked={checkedSecondary}
                                            onChange={(ev, checkedSecondary) => onCheckedSecondaryChange(checkedSecondary, field.value)}
                                            onBlur={field.onBlur}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_SECONDARY_LABEL}
                                            offText={strings.BUTTONS.TOGGLE_OFF}
                                            onText={strings.BUTTONS.TOGGLE_ON}
                                        />
                                        <ParameterText
                                            disabled={!checkedSecondary}
                                            value={field.value ?? strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.DISCLAIMER_SECONDARY}
                                            onChange={field.onChange}
                                            onRenderLabel={onRenderLabel}
                                            maxLength={20}
                                        />
                                    </Stack>
                                }
                            />
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink alignment='left' articleid='1017'></CSHHelpLink>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        <SecondaryButton onClick={onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}

const theme = getTheme();
