import { Stack, Spinner, TextField, Text, DirectionalHint, getTheme, DefaultButton, SharedColors, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React from "react"
import { useDispatch } from "react-redux";
import { CopyToClipBoardButton } from "../../../components/shared/buttons/CopyToClipboardButton";
import { DangerButton } from "../../../components/shared/buttons/DangerButton";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { CustomSeparator } from "../../../components/shared/decorations/CustomSeparator";
import { RemoveArea } from "../../../components/shared/decorations/RemoveArea";
import { actionCreators } from "../../../core/actions/settings-actions";
import { settingsApi } from "../../../core/api/settings.api";
import { IResponseError } from "@piceasoft/core";
import { strings } from "../../../localization/strings";

export type TSettingsLocaleTabProps = {
    privateKey?: string
    organiationId: string
}

export const SettingsSecurityPrivateKeyTab: React.FC<TSettingsLocaleTabProps> = props => {

    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState<IResponseError[]>()

    const [isFetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false);

    const onUpdateKey = async () => {
        setErrors(undefined);
        startFetch();
        const result = await settingsApi.organization.updatePrivateKey();
        if (result.successed && result.data) {
            dispatch(actionCreators.refreshOrganizationPrivateKey(result.data));
        }
        if (result.errors && result.errors.length > 0) {
            setErrors(result.errors)
        }
        stopFetch();
    }

    const onDeleteKey = async () => {
        setErrors(undefined);
        startFetch();
        const result = await settingsApi.organization.deletePrivateKey();
        if (result.successed) {
            dispatch(actionCreators.refreshOrganizationPrivateKey());
        }
        if (result.errors && result.errors.length > 0) {
            setErrors(result.errors)
        }
        stopFetch();
    }

    return (
        <Stack verticalFill>
            {errors && errors.length > 0 && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline>
                    <Stack tokens={{ childrenGap: 2 }}>
                        {errors.map(i => <Text>{i.description}</Text>)}
                    </Stack>
                </MessageBar>
            )}
            <Stack grow verticalFill tokens={{ padding: 24, childrenGap: 16 }}>
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack style={{ width: 360 }} verticalFill tokens={{ childrenGap: 16 }} >
                            <Stack tokens={{ childrenGap: 16 }} >
                                <Stack.Item>
                                    <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.NAVIGATION.SECURITY.PRIVATE_KEY.TITLE}</Text>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack.Item>
                <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                    <TextField label={strings.SETTINGS.SECURITY.PRIVATE_KEY.ORGANIZATION_ID_LABEL} style={{ width: 520 }} disabled={isFetching} readOnly value={props.organiationId} />
                    <CopyToClipBoardButton content={props.organiationId}
                        disabled={isFetching}
                        copyText={strings.SETTINGS.SECURITY.PRIVATE_KEY.COPY_ORGANIZATION_ID}
                        copiedText={strings.SETTINGS.SECURITY.PRIVATE_KEY.ORGANIZATION_ID_COPIED}
                        tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                    />
                </Stack>
                <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                    <TextField label={strings.SETTINGS.SECURITY.PRIVATE_KEY.PRIVATE_KEY_LABEL} style={{ width: 520 }} disabled={!props.privateKey || isFetching} readOnly value={props.privateKey} />
                    {props.privateKey && (
                        <CopyToClipBoardButton content={props.privateKey}
                            disabled={isFetching}
                            copyText={strings.SETTINGS.SECURITY.PRIVATE_KEY.COPY_PRIVATE_KEY}
                            copiedText={strings.SETTINGS.SECURITY.PRIVATE_KEY.PRIVATE_KEY_COPIED}
                            tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                        />
                    )}
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <PrimaryButton disabled={isFetching} onClick={onUpdateKey}>{strings.BUTTONS.TEXT.GENERATE_NEW_KEY}</PrimaryButton>
                    {props.privateKey && (
                        <DangerButton disabled={isFetching} onClick={onDeleteKey}>{strings.BUTTONS.TEXT.DELETE}</DangerButton>
                    )}
                    {isFetching && (
                        <Spinner />
                    )}
                </Stack>
                <CustomSeparator />
                <RemoveArea
                    href="1105"
                    hrefText={strings.SETTINGS.SECURITY.PRIVATE_KEY.INFO_AREA.HREF_TEXT}
                    text={strings.SETTINGS.SECURITY.PRIVATE_KEY.INFO_AREA.TEXT}
                    title={strings.SETTINGS.SECURITY.PRIVATE_KEY.INFO_AREA.TITLE} />
            </Stack>
        </Stack>
    )
}

const theme = getTheme();
