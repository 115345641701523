import { ComboBox, IComboBoxOption, IStyle } from "@fluentui/react"
import React from "react"
import { UseFormTrigger } from "react-hook-form"
import { IKnownDevice } from "@piceasoft/core"
import { IPromotionItem } from "../../../../core/store/typings/IPromotionItem"

type TProps = {
    value: string
    onChange: (...event: any[]) => void
    onBlur: () => void
    styles: Partial<{ errorMessage: IStyle }>
    placeholder: string
    errorMessage?: string
    devices: IKnownDevice[]
    manufacturer?: string
    trigger: UseFormTrigger<IPromotionItem>
}

export const PromotionItemConditionModel: React.FC<TProps> = props => {

    const [modelsOptions, setModelOptions] = React.useState<IComboBoxOption[]>([])

    React.useEffect(() => {
        prepareModelOptions(props.manufacturer)
    }, [])

    React.useEffect(() => {
        prepareModelOptions(props.manufacturer)
    }, [props.manufacturer])

    const prepareModelOptions = async (manufacturer?: string) => {
        const modelsArray = props.devices.filter(i => i.manufacturer === manufacturer).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(i => i.name)
        const modelsSet = new Set(modelsArray)
        setModelOptions(Array.from(modelsSet).map(i => ({ key: i, text: i })))
    }

    return (
        <ComboBox
            allowFreeform
            autoComplete="on"
            text={props.value}
            options={modelsOptions}
            placeholder={props.placeholder}
            onChange={(ev, opt, index, val) => {
                console.log(opt, val)
                props.trigger("conditions")
                props.onChange(opt?.text ?? val)
            }}
            onInputValueChange={(val) => {
                props.trigger("conditions")
                props.onChange(val)
            }}
            onItemClick={(ev, opt) => {
                if (opt) {
                    props.trigger("conditions")
                    props.onChange(opt.text)
                }
            }}
            styles={{ ...props.styles, optionsContainer: { maxHeight: 300 } }}
            useComboBoxAsMenuWidth
            errorMessage={props.errorMessage}
        />
    )
}
