import { AnimationStyles, DetailsList, DetailsListLayoutMode, DetailsRow, FontWeights, Image, getTheme, IButtonStyles, IColumn, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles, IIconProps, IRenderFunction, IStackStyles, mergeStyleSets, MessageBar, MessageBarType, Modal, ScrollablePane, SelectionMode, SharedColors, Spinner, Stack, Sticky, StickyPositionType, Text, TooltipHost, ImageFit, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { getModerationStatusBadgeTheme } from '../../components/helpers/transaction';
import { SecondaryButton } from '../../components/shared/buttons/SecondaryButton';
import { Badge2 } from '../../components/shared/decorations/Badge2';
import { MyPersona } from '../../components/shared/decorations/MyPersona';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { Section } from '../../components/shared/decorations/Section';
import { onRenderColumnHeaderCommon } from '../../components/shared/detailsList/commonRenders';
import { SimpleCustomFilter } from '../../components/shared/filter/SimpleCustomFilter';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { portalApi } from '../../core/api/api';
import { ModerationStatuses, DeviceAttributes, IResponseError } from '@piceasoft/core';
import { IModerationResult, IModerationResultLog } from '../../core/store';
import { getLocalizedModerationStatus } from '../../localization/helpers/transaction';
import { strings } from '../../localization/strings';
import { ModerationResult } from './moderationResult/ModerationResult';

export const OverviewModerations: React.FC = () => {

    const [moderationsState, setModerationsState] = React.useState<{ fetched: boolean, errors?: IResponseError[], data?: IModerationResultLog[] }>({ fetched: false })

    const [searchString, setSearchString] = React.useState<string>()

    const [items, setItems] = React.useState<IModerationResultLog[]>([])

    const [currentModeration, setCurrentModeration] = React.useState<IModerationResultLog>()
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

    React.useEffect(() => {
        getModerations()
    }, [])

    React.useEffect(() => {
        if (moderationsState.data && moderationsState.data !== items) {
            setItems(moderationsState.data)
        }
        const newItem = moderationsState.data?.find(i => i.uid === currentModeration?.uid)
        newItem && setCurrentModeration(newItem)
    }, [moderationsState])

    React.useEffect(() => {
        setItems(() => {
            var newItems = moderationsState.data ? [...moderationsState.data] : []
            if (searchString) {
                newItems = [
                    ...((newItems as IModerationResultLog[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.device,
                            i.flag,
                            i.grade,
                            i.imei,
                            i.moderator,
                            i.moderationStatus,
                            i.service,
                            i.sn
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as IModerationResultLog[]
        })
    }, [searchString])

    const getModerations = async () => {
        setModerationsState({ fetched: false })
        const result = await portalApi.overview.getModerations()
        console.log(result)
        setModerationsState({ errors: result.errors, fetched: true, data: result.data })
    }

    const onHideModal = () => {
        hideModal()
        setTimeout(() => {
            setCurrentModeration(undefined)
        }, 500)
    }

    const updateModerationItem = (item: IModerationResultLog) => {
        setModerationsState({
            ...moderationsState, data: moderationsState.data?.map(i => {
                if (i.uid === currentModeration?.uid) {
                    return {
                        ...i,
                        ...item as IModerationResultLog}
                }
                return i
            })
        })
    }

    const onShowModeration = (moderation: IModerationResultLog) => {
        setCurrentModeration(moderation)
        showModal()
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.TRANSACTIONS.COLUMNS.DEVICE,
            fieldName: 'device',
            minWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IModerationResult) => onRenderDeviceCell(item),
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.TRANSACTIONS.MODERATIONS.MODERATOR,
            fieldName: 'moderator',
            minWidth: 220,
            maxWidth: 220,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IModerationResult) => {
                return item.moderator && <MyPersona text={item.moderator} />;
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.TRANSACTIONS.MODERATIONS.TIME,
            fieldName: 'time',
            minWidth: 150,
            maxWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IModerationResult) => {
                return <Stack verticalFill verticalAlign="center"><Text variant="medium" style={{ color: SharedColors.gray30 }}>{new Date(item.creationDate).toLocaleTimeString()}</Text></Stack>;
            },
            isPadded: true,
        },
        {
            key: 'column4',
            name: strings.TRANSACTIONS.MODERATIONS.MODERATIONS_STATUS,
            fieldName: 'status',
            minWidth: 150,
            maxWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IModerationResult) => {
                return item.moderationStatus && <Stack verticalFill verticalAlign='center'><Badge2 title={getLocalizedModerationStatus(item.moderationStatus)} {...getModerationStatusBadgeTheme(item.moderationStatus as ModerationStatuses)} /></Stack>;
            },
            isPadded: true,
        },
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => onShowModeration(props.item)}><DetailsRow {...props} styles={customStyles} /></Stack>;
        }
        return null;
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }

        return (
            <Stack>
                <Stack horizontal horizontalAlign='space-between' tokens={{ padding: "16px 32px 4px 20px" }}>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: 4 }} horizontal>
                            <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                            <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                                <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{items.length}</Text>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <SecondaryButton iconProps={{ iconName: 'Refresh' }} onClick={getModerations} />
                    </Stack.Item>
                </Stack>
                {defaultRender!({ ...hProps })}
            </Stack>
        );
    }

    return (
        <Stack verticalFill>

            {moderationsState.errors && moderationsState.errors.length > 0 && (
                <>
                    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                        <MessageBar messageBarType={MessageBarType.error}>
                            <Stack tokens={{ childrenGap: 4 }}>
                                {moderationsState.errors.map(e => <Text>{e.description}</Text>)}
                            </Stack>
                        </MessageBar>
                    </Sticky>
                    <SomethingWentWrong action={getModerations} />
                </>
            )}
            {moderationsState.fetched && !moderationsState.errors && (
                <Stack.Item verticalFill>
                    <Stack.Item>
                        <ScreenHeader title={strings.NAVIGATION.OVERVIEW.PHOTO_MODERATION}>
                            <SimpleCustomFilter onChange={(ev, value) => setSearchString(value)} />
                        </ScreenHeader>
                    </Stack.Item>
                    {moderationsState.data && moderationsState.data.length > 0 && (
                        <Stack.Item verticalFill style={{ position: 'relative' }}>
                            <ScrollablePane>
                                <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                    <Section max>
                                        <DetailsList
                                            styles={{ root: { width: '100%' } }}
                                            items={items}
                                            columns={columns}
                                            setKey="none"
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            onRenderDetailsHeader={onRenderDetailsHeader}
                                            isHeaderVisible={true}
                                            onRenderRow={onRenderRow}
                                        />
                                    </Section>
                                </Stack.Item>
                            </ScrollablePane>
                        </Stack.Item>
                    ) || (
                            <ItemsNotFound doNotUseButton
                                info={strings.TRANSACTIONS.MODERATIONS.NOT_FOUND_CASE.INFO}
                                suggestion={strings.TRANSACTIONS.MODERATIONS.NOT_FOUND_CASE.SUGGESTION}
                                imgSrc={"images/navigation/images/not_found.png"}
                            />)}
                </Stack.Item>
            )}
            {!moderationsState.fetched && (
                <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                    <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                </Stack>
            )}
            <Modal isOpen={(isModalOpen && currentModeration) ? true : false} onDismiss={onHideModal} containerClassName={contentStyles.container}>
                {currentModeration && (
                    <ModerationResult onHideModal={onHideModal} moderationLog={currentModeration} updateModerationItem={updateModerationItem} />
                )}
            </Modal>
        </Stack >
    )
}

const onRenderDeviceCell = (item: IModerationResultLog) => {
    return (
        <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
            <Stack.Item>
                <Stack verticalAlign="center" horizontalAlign="center">
                    <Image width={48} height={48} imageFit={ImageFit.contain} src={item.identity?.device.attributes[DeviceAttributes.Image]} />
                </Stack>
            </Stack.Item>
            <Stack.Item grow>
                <Stack>
                    <Stack.Item>
                        <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{`${item.identity?.device.manufacturer} ${item.identity?.device.name} `}<Text variant='small'>{item.identity?.device.configuration}</Text></Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="small" style={{ color: SharedColors.gray30 }}>{item.number}</Text>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

// const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
//     if (!props) {
//         return null;
//     }
//     const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
//         <TooltipHost {...tooltipHostProps} />
//     );

//     return (
//         // <Sticky stickyClassName={classNames.header} stickyPosition={StickyPositionType.Header} isScrollSynced>
//         defaultRender!({
//             ...props,
//             onRenderColumnHeaderTooltip,
//         })
//         // </Sticky>
//     );
// };

const theme = getTheme();

const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4,
        ...AnimationStyles.slideUpIn10,
        overflow: 'hidden',
        position: "relative"
    }
};
const activeEnabledIconProps: IIconProps = { iconName: 'FavoriteStarFill', styles: { root: { color: SharedColors.yellow10 } }, className: 'enabled-icon' }
const enabledIconProps: IIconProps = { iconName: 'FavoriteStar', styles: { root: { color: 'transparent' } }, className: 'disabled-icon' }
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 800,
        height: 600
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};