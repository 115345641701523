import { FontWeights, getTheme, IconButton, mergeStyleSets, Modal, SharedColors, Stack, Text } from '@fluentui/react'
import s from '../../../assets/css/scrollHide.module.css';
import React, { useEffect, useState } from 'react'
import { IHelpConfig } from '@piceasoft/core';
import { HelpManualMode } from './modes/HelpManualMode';
import { HelpYoutubeMode } from './modes/HelpYoutubeMode';
import { HelpUrlMode } from './modes/HelpUrlMode';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { strings } from '../../../localization/strings';

type TProps = {
    show: boolean
    hide: () => void
}

export const Help: React.FC<IHelpConfig & TProps> = (props) => {

    const contentId = useId('iframe-help-element')
    const [currentIFrameLocation, setCurrentIFrameLocation] = React.useState<string | undefined>(props.url)
    const [isBackButtonMustBeDisabled, { setTrue: setIsBackButtonMustBeDisabled, setFalse: setIsBackButtonMustBeEnabled }] = useBoolean(true)

    const handleCurrentIFrameLocation = () => {
        console.log('onLoad')
        const nextIFrameLocation = (document.getElementById(contentId) as HTMLIFrameElement).contentWindow?.location.pathname
        console.log(`isBackButtonMustBeDisabled: ${props.url === nextIFrameLocation}`)
        if (props.url === nextIFrameLocation) {
            if (!isBackButtonMustBeDisabled) {
                setIsBackButtonMustBeDisabled()
            }
        } else {
            if (isBackButtonMustBeDisabled) {
                setIsBackButtonMustBeEnabled()
            }
        }
        if (nextIFrameLocation !== props.url && nextIFrameLocation !== currentIFrameLocation) {
            setCurrentIFrameLocation((document.getElementById(contentId) as HTMLIFrameElement).contentWindow?.location.pathname)
        }
    }

    const handleOnBackClick = () => {
        (document.getElementById(contentId) as HTMLIFrameElement).contentWindow?.history.back();
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: props.width ?? 800,
            height: props.height ?? 600,
            maxWidth: props.width ?? 800,
            maxHeight: props.height ?? 600
        },
        header: [
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const dismissHandler = () => {
        props.hide()
    }

    return (
        <Modal scrollableContentClassName={s.scrollHide} isOpen={props.show} onDismiss={dismissHandler} containerClassName={contentStyles.container}>
            <Stack verticalFill>
                <Stack.Item className={contentStyles.header}>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }} style={{ paddingRight: 32 }}>
                        {/* {props.url && (
                            <Stack.Item>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: 'Back' }}
                                    ariaLabel="Back"
                                    disabled={isBackButtonMustBeDisabled}
                                    onClick={handleOnBackClick}
                                />
                            </Stack.Item>
                        )} */}
                        <Stack.Item>
                            <Text variant="xLargePlus">{props.title ?? strings.FOOTER.TUTORIAL.TITLE}</Text>
                            {props.description && <Text variant="medium">{props.description}</Text>}
                        </Stack.Item>
                    </Stack>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close window"
                        onClick={dismissHandler}
                    />
                </Stack.Item>
                <Stack.Item verticalFill>
                    {props.manual && props.manual.length > 0 && <HelpManualMode src={props.manual} spinnerLabel={props.spinnerLabel} />}
                    {currentIFrameLocation && <HelpUrlMode startLocation={props.url ?? ""} setCurentIFrameLocation={handleCurrentIFrameLocation} contentId={contentId} src={currentIFrameLocation} />}
                    {props.youtube && props.youtube.length > 0 && <HelpYoutubeMode src={props.youtube} />}
                </Stack.Item>
            </Stack>
        </Modal>
    )
}

const theme = getTheme()
const iconButtonStyles = {
    root: {
        color: theme.palette.blue,
        marginLeft: 'auto'
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
}