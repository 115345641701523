import {
    Stack, getTheme, Pivot, PivotItem, DirectionalHint, Icon,
    IPivotItemProps, IRenderFunction, TooltipHost, IPivotStyleProps,
    IPivotStyles, IStyleFunctionOrObject, SharedColors, IStackStyles
} from "@fluentui/react"
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { IStore } from "../../../../../core/store";
import { IdentificationMethods, ScannerUploadMethods } from '@piceasoft/core';
import { strings } from "../../../../../localization/strings";
import { GlobalPreview } from "./preview/GlobalPreview";
import { ImeiPreview } from "./preview/ImeiPreview";
import { PiceaOnePreview } from "./preview/PiceaOnePreview";
import { PiceaUsbPreview } from "./preview/PiceaUsbPreview";
import { ScannerComponentPreview } from "./preview/ScannerComponentPreview";
import { ScannerPreview } from "./preview/ScannerPreview";
import { SearchPreview } from "./preview/SearchPreview";
import { SerialPreview } from "./preview/SerialPreview";
import { TransactionPreview } from "./preview/TransactionPreview";
import { UniversalPreview } from "./preview/UniversalPreview";
import { GlobalSettingsPivotBasedState } from "./settings/global/GlobalSettingsPivotBasedState";
import { ImeiSettingsPivotBasedState } from "./settings/imei/ImeiSettingsPivotBasedState";
import { PiceaOneSettingsPivotBasedState } from "./settings/piceaOne/PiceaOneSettingsPivotBasedState";
import { PiceaUsbSettingsPivotBasedState } from "./settings/piceaUsb/PiceaUsbSettingsPivotBasedState";
import { ScannerSettingsPivotBasedState } from "./settings/scanner/ScannerSettingsPivotBasedState";
import { SearchSettingsPivotBasedState } from "./settings/search/SearchSettingsPivotBasedState";
import { SerialSettingsPivotBasedState } from "./settings/serial/SerialSettingsPivotBasedState";
import { TransactionSettingsPivotBasedState } from "./settings/transaction/TransactionSettingsPivotBasedState";
import { UniversalSettingsPivotBasedState } from "./settings/universal/UniversalSettingsPivotBasedState";
import { IIdentificationScannerConfig } from "@piceasoft/core";

type TProps = {
    index: number
    method: IdentificationMethods
    pivot?: TIdentificationModuleSettingsPivotStates
    onChangeDispatch: Dispatch<any>
    okButtonDisabled(arg: boolean): void
}

export type TIdentificationModuleSettingsPivotStates = "common" | "ui" | "config" | "component_ui" | "modal_ui"

const getTabId = (itemKey: string) => {
    return `IdentificationModulePivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "common": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.COMMON.TITLE
        case "ui": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.TITLE
        case "component_ui": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.COMPONENT_UI.TITLE
        case "modal_ui": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.MODAL_UI.TITLE
        case "config": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.CONFIG.TITLE
        default: return m;
    }
}

const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
    return (
        <span style={{ ...iProps?.style }}>
            <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
            </TooltipHost>
        </span>
    )
}

export const IdentificationModule: React.FC<TProps> = (props) => {

    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index))
    const isContentTransfer = useSelector((s: IStore) => s.configurator.contentTransfer ? true: false)

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TIdentificationModuleSettingsPivotStates>(props.pivot ?? "ui");

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TIdentificationModuleSettingsPivotStates);
    };

    return (
        <>
            <Stack horizontalAlign="end" styles={headerStylesMain}>
                <Stack.Item>
                    <Pivot styles={pivotStyles} selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                        <PivotItem itemIcon={'View'} headerText={getPivotHeaderText("ui")} onRenderItemLink={onRenderItemLink} itemKey="ui" />
                        {props.method === IdentificationMethods.Scanner && (config as IIdentificationScannerConfig).uploadMethods.filter(i => i !== ScannerUploadMethods.Camera).length > 0 && (
                            <PivotItem itemIcon={'WindowEdit'} headerText={getPivotHeaderText("modal_ui")} onRenderItemLink={onRenderItemLink} itemKey="modal_ui" />
                        )}
                        {![IdentificationMethods.PiceaUsb, IdentificationMethods.Transaction, IdentificationMethods.Imei].includes(props.method) && (
                            <PivotItem itemIcon={'Repair'} headerText={getPivotHeaderText("common")} onRenderItemLink={onRenderItemLink} itemKey="common" />
                        )}
                        {props.method === IdentificationMethods.Scanner && (
                            <PivotItem itemIcon={'BrowserScreenShot'} headerText={getPivotHeaderText("component_ui")} onRenderItemLink={onRenderItemLink} itemKey="component_ui" />
                        )}
                        <PivotItem itemIcon={'Code'} headerText={getPivotHeaderText("config")} onRenderItemLink={onRenderItemLink} itemKey="config" />
                    </Pivot>
                </Stack.Item>
            </Stack>
            <Stack styles={contentStyles} horizontal verticalFill>
                <Stack verticalAlign="center" horizontalAlign="center" style={{ background: theme.palette.neutralLighter }}>
                    {props.method === IdentificationMethods.PiceaOne && (
                        <PiceaOnePreview index={props.index} />
                    )}
                    {props.method === IdentificationMethods.PiceaUsb && (
                        <PiceaUsbPreview index={props.index} uiKit={isContentTransfer}/>
                    )}
                    {props.method === IdentificationMethods.Global && (
                        <GlobalPreview index={props.index} />
                    )}
                    {props.method === IdentificationMethods.Serial && (
                        <SerialPreview index={props.index} />
                    )}
                    {props.method === IdentificationMethods.SearchForm && (
                        <SearchPreview index={props.index} />
                    )}
                    {props.method === IdentificationMethods.Universal && (
                        <UniversalPreview index={props.index} />
                    )}
                    {props.method === IdentificationMethods.Transaction && (
                        <TransactionPreview index={props.index} />
                    )}
                    {props.method === IdentificationMethods.Scanner && (
                        selectedPivotKey === "component_ui" && (
                            <ScannerComponentPreview index={props.index} />
                        ) || (selectedPivotKey !== "modal_ui" && (
                            <ScannerPreview index={props.index} />
                        ))
                    )}
                    {props.method === IdentificationMethods.Imei && (
                        <ImeiPreview index={props.index} />
                    )}
                </Stack>
                <Stack.Item grow style={{ zIndex: 10, minHeight: 476 }}>
                    {props.method === IdentificationMethods.PiceaOne && (
                        <PiceaOneSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                        />
                    )}
                    {props.method === IdentificationMethods.PiceaUsb && (
                        <PiceaUsbSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                        />
                    )}
                    {props.method === IdentificationMethods.Global && (
                        <GlobalSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                            okButtonDisabled={props.okButtonDisabled}
                        />
                    )}
                    {props.method === IdentificationMethods.Serial && (
                        <SerialSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                        />
                    )}
                    {props.method === IdentificationMethods.SearchForm && (
                        <SearchSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                        />
                    )}
                    {props.method === IdentificationMethods.Universal && (
                        <UniversalSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                        />
                    )}
                    {props.method === IdentificationMethods.Transaction && (
                        <TransactionSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                        />
                    )}
                    {props.method === IdentificationMethods.Scanner && (
                        <ScannerSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                        />
                    )}{props.method === IdentificationMethods.Imei && (
                        <ImeiSettingsPivotBasedState
                            state={selectedPivotKey}
                            index={props.index}
                            onChangeDispatch={props.onChangeDispatch}
                        />
                    )}
                </Stack.Item>
            </Stack>
        </>
    )
}

const theme = getTheme();

const headerStylesMain: IStackStyles = { root: { position: "relative", zIndex: 14 } };
const contentStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0", zIndex: 10 } };

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}