import { FontWeights, getTheme, mergeStyleSets, Separator, Stack, Text, CommandBarButton, Icon, ICalloutContentStyles } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { DeviceAttributes } from '@piceasoft/core';
import { IStore } from '../../../../../core/store';
import { strings } from '../../../../../localization/strings';
import unknown from '../../../../../assets/unknown.png'
import { sampleOffer, sampleIdentity, sampleGrade, sampleDiscount } from '../../mock/samples';

type TProps = {
}

const Device: React.FC<TProps> = (props) => {

    const workflow = useSelector((s: IStore) => s.configurator);
    const grade = sampleGrade;
    const discount = sampleDiscount;
    const offer = sampleOffer;
    const bestOffer = sampleOffer;

    const deviceDisplay = `${sampleIdentity.device.manufacturer} ${sampleIdentity.device.name} ${sampleIdentity.device.attributes[DeviceAttributes.Color] ?? ""}`;
    const deviceImage = sampleIdentity.device.attributes[DeviceAttributes.Image] ?? unknown;

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
            <Stack>
                <div className={styles.deviceButtonArea}>
                    <CommandBarButton style={{ padding: 8 }}>
                        <img height={36} src={deviceImage} />
                        <Stack horizontalAlign="start" style={{ marginLeft: 24 }}>
                            <Text variant="medium" nowrap style={{ color: SharedColors.cyanBlue10, maxWidth: 220 }}>{deviceDisplay}</Text>
                            {(grade && <Text style={{ color: SharedColors.cyanBlue10 }} variant="xSmall">{grade.name}</Text>) || <Text nowrap style={{ color: SharedColors.gray20 }} variant="xSmall">{strings.CONSTRUCTOR.STAGES.DEVICE.STATE_NOT_DEFINED}</Text>}
                        </Stack>
                    </CommandBarButton>
                </div>
            </Stack>
            {workflow.offer?.showPricesBeforeAssessment && (
                <>
                    <Separator vertical />
                    <Stack.Item>
                        <div className={styles.offersButtonArea}>
                            <CommandBarButton style={{ padding: 8 }} >
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 16 }}>
                                    <Stack.Item>
                                        {(offer && (
                                            <>
                                                {discount && (
                                                    <Stack>
                                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                            {offer.source && (
                                                                <Icon iconName={"Calories"} style={{ color: SharedColors.redOrange10 }} />
                                                            )}
                                                            <Text style={{ color: SharedColors.orangeYellow20 }} variant="large">{(offer.price).toLocaleString()} <small>{strings.CONSTRUCTOR.STAGES.CURRENCY}</small></Text>
                                                        </Stack>
                                                        <Text style={{ color: SharedColors.green10 }}>+{((discount.amount)).toLocaleString()} <small>{strings.CONSTRUCTOR.STAGES.CURRENCY}</small></Text>
                                                    </Stack>
                                                ) || (
                                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                            {offer.source && (
                                                                <Icon iconName={"Calories"} style={{ color: SharedColors.redOrange10 }} />
                                                            )}
                                                            <Text style={{ color: SharedColors.orangeYellow20 }} variant="large">{offer.price.toLocaleString()} <small>{strings.CONSTRUCTOR.STAGES.CURRENCY}</small></Text>
                                                        </Stack>
                                                    )}
                                            </>
                                        )) || (
                                                <>
                                                    {discount && (
                                                        <Stack>
                                                            <Text style={{ color: SharedColors.orangeYellow20 }} variant="large"><small>{strings.CONSTRUCTOR.STAGES.DEVICE.UP_TO}</small> {((bestOffer?.price ?? 0) + discount.amount).toLocaleString()} <small>{strings.CONSTRUCTOR.STAGES.CURRENCY}</small></Text>
                                                        </Stack>
                                                    ) || (
                                                            <Text style={{ color: SharedColors.gray10 }} variant="large"><small>{strings.CONSTRUCTOR.STAGES.DEVICE.UP_TO}</small> {bestOffer?.price?.toLocaleString()} <small>{strings.CONSTRUCTOR.STAGES.CURRENCY}</small></Text>
                                                        )}
                                                </>
                                            )}
                                    </Stack.Item>
                                </Stack>
                            </CommandBarButton>
                        </div>
                    </Stack.Item>
                </>
            )}
        </Stack>
    )
}

export default Device

const theme = getTheme();
const styles = mergeStyleSets({
    deviceButtonArea: {
    },
    offersButtonArea: {
    },
    connectionButtonArea: {
    },
    callout: {
        maxWidth: 420,
        minWidth: 420,
    },
    header: {
        padding: '18px 24px 0px',
    },
    title: [
        theme.fonts.xLarge,
        {
            margin: 0,
            color: SharedColors.gray30,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0px 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    gradeBoxOff: {
        backgroundColor: theme.palette.whiteTranslucent40,
    },
    gradeBoxOn: {
        backgroundColor: theme.palette.themeLighter,
    },
    gradeOff: [
        theme.fonts.medium,
        {
            margin: 0,
            fontWeight: FontWeights.regular,
            color: SharedColors.gray40
        },
    ],
    gradeOn: [
        theme.fonts.medium,
        {
            margin: 0,
            fontWeight: FontWeights.bold,
            color: SharedColors.gray40
        },
    ],
    value: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
            color: SharedColors.cyanBlue10
        },
    ],
    price: [
        theme.fonts.large,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
            color: SharedColors.orangeYellow20
        },
    ],
    priceOn: [
        theme.fonts.large,
        {
            margin: 0,
            fontWeight: FontWeights.bold,
            color: SharedColors.orangeYellow20
        },
    ],
    discountAmount: [
        theme.fonts.medium,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
            color: SharedColors.redOrange10
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
});

const tooltipCalloutStyles: Partial<ICalloutContentStyles> = {
    beakCurtain: { background: theme.palette.neutralLight },
    beak: { background: theme.palette.neutralLight },
    calloutMain: { background: theme.palette.neutralLight },
    root: { color: theme.palette.white },
    container: { color: 'white' }
}
