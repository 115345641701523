import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import * as React from 'react';

const Main: React.FC = (props) => {
    return (
        <>
        {/* <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> */}
            {props.children}
        {/* </ScrollablePane> */}
        </>
    )
}

export default Main
