import { getTheme, INavLink, INavLinkGroup, ScrollablePane, SharedColors, Spinner, Stack, Text } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../core/actions/settings-actions";
import { actionCreators as workplaceActionCreators } from "../../core/actions/workplace-actions";
import { portalApi } from "../../core/api/api";
import { IPerson, IStore } from "../../core/store";
import { IOrganizationSettings } from "../../core/store/typings/IOrganizationSettings";
import { IResponseError } from "@piceasoft/core";
import { strings } from "../../localization/strings";
import { TabBasedState } from "./TabBasedState";
import { UserProfileSettingsTabs } from "./UserProfileSettingsTabs";

export type TUserSettingsTabState = 'ACCOUNT.PROFILE' | 'ACCOUNT.LOCALE' | 'SECURITY.ROLES' | 'SECURITY.PASSWORD'

export type TNewAccountPassword = {
    old_password: string
    new_password: string
}

export const UserSettings: React.FC = () => {
    const [selectedTabKey, setSelectedTabKey] = React.useState<TUserSettingsTabState>('ACCOUNT.PROFILE');
    const [accountState, setAccountState] = React.useState<{ fetched: boolean, data?: IPerson }>({ fetched: false })
    const [isUpdating, { setTrue: startUpdating, setFalse: stopUpdating }] = useBoolean(false)
    const [isFetchingGetAccount, { setTrue: startFetchingGetAccount, setFalse: stopFetchingGetAccount }] = useBoolean(false)
    const [passwordUpdatingErrors, setPasswordUpdatingErrors] = React.useState<IResponseError[]>([])

    const handleTabLinkClick = (item?: INavLink) => {
        setSelectedTabKey(item?.key as TUserSettingsTabState);
    };
    const settings = useSelector((s: IStore) => s.settings)
    const user = useSelector((s: IStore) => s.workplace.user)
    const dispatch = useDispatch();

    React.useEffect(() => {
        getAccount(user?.id ?? '')
    }, [])

    React.useEffect(() => {
        if (!settings.isReady) {
            dispatch(actionCreators.requestOrganizationSettings())
        }
    }, [])

    const getAccount = async (id: string) => {
        startFetchingGetAccount()
        const result = await portalApi.organization.accounts.getAccount(id)
        if (result.successed && result.data) {
            console.log(result)
            setAccountState({ fetched: true, data: result.data })
        }
        stopFetchingGetAccount()
    }

    const updateAccount = async (item: IPerson) => {
        startUpdating()
        const result = await portalApi.organization.profile.updateProfile(item)
        if (result.successed && result.data) {
            setAccountState({ fetched: true, data: result.data })
            dispatch(workplaceActionCreators.refreshUserData({
                display: result.data.display,
                email: result.data.email,
                imgSrc: result.data.imgSrc,
                phone: result.data.phone
            }))
        }
        stopUpdating()
    }

    const updatePassword = async (oldPassword: string, newPassword: string, setSuccessed: () => void) => {
        setPasswordUpdatingErrors([])
        startUpdating()
        const result = await portalApi.organization.profile.updatePassword({ old_password: oldPassword, new_password: newPassword })
        if (result.successed) {
            setSuccessed()
        } else if (result.errors) {
            setPasswordUpdatingErrors(result.errors)
        }
        stopUpdating()
    }

    const navGroups: INavLinkGroup[] = [
        {
            name: strings.SETTINGS.USER_SETTINGS.ACCOUNT_TITLE,
            links: [
                {
                    name: strings.SETTINGS.USER_SETTINGS.PROFILE_TITLE,
                    url: '',
                    icon: '/OEM',
                    iconProps: {},
                    key: 'ACCOUNT.PROFILE'
                },
                // {
                //     name: strings.SETTINGS.USER_SETTINGS.LOCALE_TITLE,
                //     url: '',
                //     icon: '/WorldClock',
                //     iconProps: {},
                //     key: 'ACCOUNT.LOCALE'
                // },
            ]
        },
        {
            name: strings.SETTINGS.USER_SETTINGS.SECURITY_TITLE,
            links: [
                {
                    name: strings.SETTINGS.USER_SETTINGS.ROLES_TITLE,
                    url: '',
                    icon: '/People',
                    iconProps: {},
                    key: 'SECURITY.ROLES'
                },
                {
                    name: strings.SETTINGS.USER_SETTINGS.PASSWORD_TITLE,
                    url: '',
                    icon: '/PasswordField',
                    iconProps: {},
                    key: 'SECURITY.PASSWORD'
                },
            ]
        },
    ]

    return (
        <Stack horizontal grow verticalFill>
            <Stack.Item verticalFill style={{ width: 250, borderRight: `1px solid ${theme.palette.neutralTertiaryAlt}` }}>
                <Stack verticalFill>
                    <Stack.Item>
                        <Stack tokens={{ padding: 20 }}>
                            <Text style={{ color: theme.palette.black, fontSize: "1.3125rem", fontWeight: 600, letterSpacing: "-.02em" }}>{strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.PROFILE_SETTINGS_TITLE}</Text>
                            <Text style={{ color: SharedColors.gray30, fontWeight: 600 }}>{user?.display}</Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item verticalFill styles={{ root: { position: 'relative' } }}>
                        {/* <ScrollablePane> */}
                        <UserProfileSettingsTabs groups={navGroups} handleTabLinkClick={handleTabLinkClick} selectedTabKey={selectedTabKey} />
                        {/* </ScrollablePane> */}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item verticalFill grow>
                {
                    isFetchingGetAccount && (
                        <Stack grow verticalFill verticalAlign="center" horizontalAlign="center">
                            <Spinner />
                        </Stack>
                    ) ||
                    (
                        <Stack verticalFill grow>
                            <Stack.Item grow verticalFill style={{ position: 'relative' }}>
                                <ScrollablePane>
                                    <TabBasedState
                                        state={selectedTabKey}
                                        account={accountState.data}
                                        isUpdating={isUpdating}
                                        isUpdatingSettings={settings.isUpdating}
                                        settings={settings.organization as IOrganizationSettings}
                                        onSubmit={updateAccount}
                                        onUpdatePassword={updatePassword}
                                        passwordUpdatingErrors={passwordUpdatingErrors} />
                                </ScrollablePane>
                            </Stack.Item>
                        </Stack >
                    )}
            </Stack.Item >
        </Stack>
    )
}
const theme = getTheme();