import { IDropdownOption } from "@fluentui/react"
import { DeviceCheckSolutions } from "../../core/store/typings/DeviceCheckSolutions"
import { getLocalizedChannel, getLocalizedDeviceCheckSolution, getLocalizedServiceType } from "./channel"
import { Experience } from "../../components/shared/configurator/helpers/evisibility"
import { ServiceTypes } from "@piceasoft/core"


const deviceCheckSolution: IDropdownOption[] = [
    {
        key: DeviceCheckSolutions.PiceaOne,
        text: getLocalizedDeviceCheckSolution(DeviceCheckSolutions.PiceaOne)
    },
    {
        key: DeviceCheckSolutions.PiceaMobile,
        text: getLocalizedDeviceCheckSolution(DeviceCheckSolutions.PiceaMobile)
    }
]

const ecomDeviceCheckSolutions: IDropdownOption[] = [
    ...deviceCheckSolution,
    {
        key: DeviceCheckSolutions.WebBased,
        text: getLocalizedDeviceCheckSolution(DeviceCheckSolutions.WebBased)
    }
]

const workflowTypeOptions: IDropdownOption[] = [
    {
        key: Experience.Retail,
        text: getLocalizedChannel(Experience.Retail)
    },
    {
        key: Experience.ECom,
        text: getLocalizedChannel(Experience.ECom,)
    },
    {
        key: Experience.Mobile,
        text: getLocalizedChannel(Experience.Mobile)
    },
    {
        key: Experience.MobileSDK,
        text: getLocalizedChannel(Experience.MobileSDK)
    }
]
const CommonServiceTypeOptions: IDropdownOption[] = [
    {
        key: ServiceTypes.TradeIn,
        text: getLocalizedServiceType( ServiceTypes.TradeIn)
    },
    {
        key: ServiceTypes.Insurance,
        text: getLocalizedServiceType( ServiceTypes.Insurance)
    },
    {
        key: ServiceTypes.Finance,
        text: getLocalizedServiceType( ServiceTypes.Finance)
    },
    {
        key: ServiceTypes.Repair,
        text: getLocalizedServiceType( ServiceTypes.Repair)
    },
    {
        key: ServiceTypes.Warranty,
        text: getLocalizedServiceType( ServiceTypes.Warranty)
    },
    {
        key: ServiceTypes.SelfService,
        text: getLocalizedServiceType( ServiceTypes.SelfService)
    },
    {
        key: ServiceTypes.CustomerSupport,
        text: getLocalizedServiceType( ServiceTypes.CustomerSupport)
    },
    {
        key: ServiceTypes.Recycling,
        text: getLocalizedServiceType( ServiceTypes.Recycling)
    },
    {
        key: ServiceTypes.OutOfBoxWarranty,
        text: getLocalizedServiceType( ServiceTypes.OutOfBoxWarranty)
    }
]
const RetailServiceTypeOptions: IDropdownOption[] = [
    ...CommonServiceTypeOptions,
    {
        key: ServiceTypes.ContentTransfer,
        text: getLocalizedServiceType( ServiceTypes.ContentTransfer)
    }
]

export const useServiceFormOptions = () => {
    return {
        deviceCheckSolution,
        ecomDeviceCheckSolutions,
        workflowTypeOptions,
        CommonServiceTypeOptions,
        RetailServiceTypeOptions
    }
}
