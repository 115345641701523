import React from "react"
import { getFocusStyle, getTheme, IComboBoxOption, Icon, mergeStyleSets, SharedColors, Stack, Text } from "@fluentui/react"
import { useId } from "@uifabric/react-hooks"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import { preparePhonePrefixOptions } from "../../../../../../../../../../../core/helpers/localization"
import SuggestedSearch, { focusElement, handleTextToListKeyPress, preparePhoneCountryFilteredOptions } from "../../../../../../../../../search/SuggestedSearch"
import { strings } from "../../../../../../../../../../../localization/strings"

type TProps = {
    config?: IDataCollectionStandardConfig
    errorMessage: string
    searchRequest?: string
    organizationCountry?: string
    setSearchRequest: (value: string) => void
}

const PhoneSearch: React.FunctionComponent<TProps> = (props) => {
    const {
        errorMessage,
        searchRequest,
        setSearchRequest,
    } = props

    const phoneCountryHostId = useId("phone-country-host-id")
    const phoneCountryTextId = useId("phone-country-text-id")
    const phoneCountryCalloutListId = "phone-country-callout-id"
    
    const phoneCountryOptions = preparePhonePrefixOptions();

    const theme = getTheme()
    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderPhoneCountrySuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${phoneCountryCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => 
                    handleTextToListKeyPress(
                        ev,
                        phoneCountryOptions?.length ?? 0,
                        phoneCountryTextId,
                        phoneCountryCalloutListId,
                        () => setSearchRequest(item?.key.toString() ?? ""),
                        index, item?.text, phoneCountryHostId
                    )}
                tabIndex={-1}
                onClick={() => {
                    setSearchRequest(item?.key as string)
                    focusElement(`${phoneCountryCalloutListId}-${index as number}`, phoneCountryHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                            {item?.title && <Text>{`(+${item?.title})`}</Text>}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const onRenderPlaceholder = (value?: string) => {
        const item = phoneCountryOptions.find(i => i.key === value)
        return item && (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                {item?.title && <Text>{`(+${item?.title})`}</Text>}
            </Stack>
        ) || (
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PHONE_COUNTRY_PLACEHOLDER}</Text>
            )
    }

    return (
        <SuggestedSearch
            type="dropdown-search"
            inputBoxId={phoneCountryTextId}
            hostId={phoneCountryHostId}
            label={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PHONE_COUNTRY_LABLE}
            onClickSuggestionCell={(item) => {
                setSearchRequest((item as IComboBoxOption).key as string)
            }}
            setSearchRequest={(opt: IComboBoxOption) => {
                setSearchRequest(opt.key as string)
            }}
            searchRequest={phoneCountryOptions.find(i => i.key === searchRequest)?.text.toString()}
            suggestionsListWidth={440}
            required
            onRenderPlaceholder={() => onRenderPlaceholder(searchRequest)}
            suggestions={phoneCountryOptions}
            suggestionsListId={phoneCountryCalloutListId}
            isSearchEqualTheOneSuggestion={false}
            onRenderSuggestionCell={onRenderPhoneCountrySuggestionsCell}
            prepareFilteredItems={preparePhoneCountryFilteredOptions}
            selectedKey={searchRequest}
            errorMessage={errorMessage}
        />
    )
}

export default PhoneSearch 