import React, { Dispatch } from "react"
import { TStageSettingsPivotStates } from "../../../Configurator"
import { StageCommonButtonsSettingsPivot } from "../../settings/StageCommonButtonsSettingsPivot"
import { IContentTransferConfig, IStageConfig, ProcessStages, IStageConfigUI } from "@piceasoft/core"

type TProps = {
    state: TStageSettingsPivotStates
    onChangeDispatch: Dispatch<any>
    config?: IContentTransferConfig
    stage: ProcessStages
    pivots?: { name: string, component: React.ReactNode }[]
    channel: number
}

export const ContentTransferStateSettingsPivotBasedState: React.FC<TProps> = (props) => {
    switch (props.state) {
        case "common_buttons": return (
            <StageCommonButtonsSettingsPivot channel={props.channel } config={props.config as IStageConfig<IStageConfigUI>} onChangeDispatch={props.onChangeDispatch} stage={props.stage} withoutNext={true} />
        )
        // case "common_ui": return (
        //     <StageCommonUISettingsPivot config={props.config as IStageConfig<IStageConfigUI>} onChangeDispatch={props.onChangeDispatch} stage={props.stage} withoutTitle={true} channel={ props.channel}>
        //     </StageCommonUISettingsPivot>
        // )
        default:
            return <>{props.pivots?.find(i => i.name === props.state)?.component}</>
    }
}