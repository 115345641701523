import { Stack, ScrollablePane } from "@fluentui/react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../../core/store"
import { actionCreators, getStageInspectionModules } from "../../../../../../../core/actions/configurator-actions"
import { ProcessStages } from "@piceasoft/core";
import { Dispatch } from "react";
import { strings } from "../../../../../../../localization/strings"
import { Section } from "../../../../../decorations/Section";
import { ParameterItem } from "../../../../components/ParameterItem";
import { ISoftwareConfig } from "../../../../../../../core/store"

type TProps = {
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
}

export const SoftwarePushSettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => getStageInspectionModules(props.stage, s)?.find(i => i.index === props.index)?.config as ISoftwareConfig)

    return(
            <Stack.Item verticalFill style={{ position: 'relative' }}>
                <ScrollablePane>
                    <Section title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.PUSH_NOTIFICATION.TITLE} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.PUSH_NOTIFICATION.TITLE_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.pushNotification?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.software.editSoftware(props.stage, props.index, {
                                ...config,
                                pushNotification:{
                                        ...config.pushNotification,
                                        title: config.pushNotification?.title !== undefined ? undefined : ""
                                }
                            }))
                        }}
                        textFieldValue={config.pushNotification?.title}
                        useTextField
                        textFieldEnabled={config.pushNotification?.title !== undefined ? true : false}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.software.editSoftware(props.stage, props.index, {
                            ...config,
                            pushNotification:{
                                ...config.pushNotification,
                                title: val
                            }
                        }))}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.PUSH_NOTIFICATION.TITLE_DESCRIPTION}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.PUSH_NOTIFICATION.BODY_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.pushNotification?.description !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.software.editSoftware(props.stage, props.index, {
                                ...config,
                                pushNotification:{
                                    ...config.pushNotification,
                                    description: config.pushNotification?.description !== undefined ? undefined : ""
                                }
                            }))
                        }}
                        useTextField
                        textFieldEnabled={config.pushNotification?.description !== undefined ? true : false}
                        textFieldMultiline
                        textFieldValue={config.pushNotification?.description}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.software.editSoftware(props.stage, props.index , {
                            ...config,
                            pushNotification:{
                                ...config.pushNotification,
                                description: val
                            }
                        }))}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.PUSH_NOTIFICATION.BODY_DESCRIPTION}
                    />
                    </Section>
                </ScrollablePane>
            </Stack.Item>
        )
    } 