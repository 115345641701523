import { AppThunkAction } from "..";
import { strings } from "../../localization/strings";
import { ICatalog, IContractConfig, ISoftwareConfig, ISoftwarePiceaConfig, IStore, IWorkflow } from "../store";
import {
    IAssessmentConfig,
    IControlConfig,
    IDependencyRule,
    IInterviewConfig,
    IInterviewWithQuestionsConfig,
    IPhotographicConfig,
    IPhotographicModeConfig,
    SoftwareModes,
    IInspectionModuleConfig,
    IResourcesConfig,
    IPreOfferConfig,
    IPostOfferConfig,
    IIdentificationModuleConfigUI,
    IInspectionModeConfig,
    IStageConfigUI,
    IDiagnosticsPiceaMobileConfig,
    PhotographicModes,
    InterviewModes,
    DiagnosticsModes,
    IResultConfig,
    IGrade,
    IInterviewManualConfig,
    IdentificationMethods,
    DataCollectionModes,
    EraseModes,
    DeviceTypes,
    ScannerIdentificationTypes,
    IPhotographicModeConfigUI,
    AIGradingModes,
    AIGradingUploadMethods,
    AIGradingImageTypes,
    AIGradingResultTypes,
    IDiagnosticsPiceaConfig,
    IDiagnosticsManualConfig,
    IQuestion,
    IStageConfig,
    ManualGradingModes,
    IManualGradingDefaultConfig,
    IIdentificationConfig,
    ICommonOfferConfig,
    ScannerUploadMethods,
    ProcessStages,
    Inspections,
    IInspectionConfig,
    IInspectionConfigUI,
    IDataCollectionStandardConfig,
    IIdentificationModuleConfig,
    IGradesCategory,
    IInspectionModeConfigUI,
    IEraseDefaultConfig,
    IIdentificationScannerConfig,
    IAIGradingConfig,
    IContentTransferConfig,
    IDataCollectionConfig,
    IEraseConfig,
    IInterviewSequenceConfig,
    IInterviewListConfig,
    IManualGradingConfig,
    IPhotographicCollectionConfig,
    IPhotographicFreeConfig,
    IPhotographicRequestConfig,
    IDiagnosticsWebBasedConfig,
    ILandingConfig,
    ISelfServiceWorkflowConfig, ISelfServiceConfigItem, ISelfServiceConfigAnswer, SelfServiceConfigType,
    IRepairOfferProviderConfig, IRepairOfferConfig, DiagnosticCases, ConnectionTypes,
    IDisclaimer,
    ISelfServiceDiagnosticCaseOptions
} from '@piceasoft/core';
import { IDiagnosticsConfig } from "../store/typings/IDiagnosticsConfig";
import { IPromotion } from "../store/typings/IPromotion";
import { Experience } from "../../components/shared/configurator/helpers/evisibility";
import { IImeiLockoutConfig } from "../store/typings/IWorkflow";
import {ISelfServiceDiagnosticCase} from "@piceasoft/core/dist/interfaces/ISelfServiceDiagnosticCase";

export interface ReceiveWorkflowAction { type: 'CONFIGURATOR_RECEIVE', data?: IWorkflow, channel?: number }
export interface VersionUpdateAction { type: 'CONFIGURATOR_VERSION_UPDATE', version: number }
export interface ContentTRansferUpdateAction { type: 'CONFIGURATOR_CONTENT_TRANSFER_UPDATE', contentTransfer: IContentTransferConfig }
export interface LockoutConfigUpdateAction { type: 'CONFIGURATOR_PARAMETERS_IMEI_LOCKOUT', config?: IImeiLockoutConfig }
export interface IdentificationUpdateAction { type: 'CONFIGURATOR_IDENTIFICATION_UPDATE', identification: IIdentificationConfig }
export interface CommonOfferUpdateAction { type: 'CONFIGURATOR_COMMON_OFFER_UPDATE', commonOffer: ICommonOfferConfig }
export interface PreOfferUpdateAction { type: 'CONFIGURATOR_PRE_OFFER_UPDATE', preOffer: IPreOfferConfig }
export interface PostOfferUpdateAction { type: 'CONFIGURATOR_POST_OFFER_UPDATE', postOffer: IPostOfferConfig }
export interface ResultUpdateAction { type: 'CONFIGURATOR_RESULT_UPDATE', result: IResultConfig }
export interface ContractUpdateAction { type: 'CONFIGURATOR_CONTRACT_UPDATE', contract: IContractConfig }
export interface ControlUpdateAction { type: 'CONFIGURATOR_CONTROL_UPDATE', control: IControlConfig }
export interface ResourcesUpdateAction { type: 'CONFIGURATOR_RESOURCES_UPDATE', resources: IResourcesConfig }
export interface AssessmentUpdateAction { type: 'CONFIGURATOR_ASSESSMENT_UPDATE', assessment: IAssessmentConfig }
export interface AssessmentInspectionsUpdateAction { type: 'CONFIGURATOR_ASSESSMENT_INSPECTION_MODULES_UPDATE', modules: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>[] }
export interface IdentificationModulesUpdateAction { type: 'CONFIGURATOR_IDENTIFICATION_ITEMS_UPDATE', items: IIdentificationModuleConfig<IIdentificationModuleConfigUI>[] }
export interface StageInspectionsUpdateAction { type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: ProcessStages, modules: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>[] }
export interface ToggleResourcesAction { type: 'CONFIGURATOR_TOGGLE_RESOURCES', data?: IResourcesConfig }
export interface TogglePreOfferAction { type: 'CONFIGURATOR_TOGGLE_PRE_OFFER', channel?: Experience }
export interface TogglePostOfferAction { type: 'CONFIGURATOR_TOGGLE_POST_OFFER', data?: IPostOfferConfig }
export interface ToggleAssessmentAction { type: 'CONFIGURATOR_TOGGLE_ASSESSMENT', data?: IAssessmentConfig }
export interface ToggleIsControlTransactionAction { type: 'CONFIGURATOR_TOGGLE_IS_CONTROL_TRANSACTION' }
export interface ToggleUseGradesCategoriesAction { type: 'CONFIGURATOR_TOGGLE_USE_GRADES_CATEGORIES' }
export interface ToggleUseDiscountCatalogsAction { type: 'CONFIGURATOR_TOGGLE_USE_DISCOUNT_CATALOGS'}
export interface SetGradesDelimiterAction { type: 'CONFIGURATOR_SET_GRADES_DELIMITER', data?: string }
export interface ToggleContractAction { type: 'CONFIGURATOR_TOGGLE_CONTRACT', data?: IContractConfig }
export interface ToggleControlAction { type: 'CONFIGURATOR_TOGGLE_CONTROL', data?: IControlConfig }
export interface ToggleResultAction { type: 'CONFIGURATOR_TOGGLE_RESULT', data?: IResultConfig }
export interface UpdateGrades { type: 'CONFIGURATOR_GRADES_UPDATE', data: IGrade[] }
export interface UpdatePreviousGrades { type: 'CONFIGURATOR_PREVIOUS_GRADES_UPDATE', data: IGrade[] }
export interface UpdateGradesCategories { type: 'CONFIGURATOR_GRADES_CATEGORIES_UPDATE', data?: IGradesCategory[] }
export interface UpdatePreviousGradesCategories { type: 'CONFIGURATOR_PREVIOUS_GRADES_CATEGORIES_UPDATE', data?: IGradesCategory[] }
export interface UpdatePreviousCommonOfferPromoProviders { type: 'CONFIGURATOR_COMMON_OFFER_PROMO_PROVIDER_UPDATE', data?: IPromotion[] }
export interface UpdatePreviousCommonOfferProviders { type: 'CONFIGURATOR_COMMON_OFFER_PROVIDER_UPDATE', data?: ICatalog[] }
export interface ReceiveIdentificationMethodAction { type: 'CONFIGURATOR_IDENTIFICATION_RECEIVE_METHOD', method: IdentificationMethods, data: IIdentificationModuleConfig<IIdentificationModuleConfigUI> }
export interface RemoveIdentificationMethodAction { type: 'CONFIGURATOR_IDENTIFICATION_REMOVE_METHOD', method: IdentificationMethods }
export interface DeviceTypesUpdateAction { type: 'CONFIGURATOR_DEVICE_TYPES_UPDATE', data?: Array<DeviceTypes> }
export interface ConfigPromoDataUpdateAction { type: 'CONFIGURATOR_PROMO_DATA_UPDATE', promotions?: IPromotion[], catalogs?: ICatalog[] }
export interface ToggleContentTransferAction { type: 'CONFIGURATOR_TOGGLE_CONTENT_TRANSFER', data?: IContentTransferConfig }
export interface LandingUpdateAction { type: 'CONFIGURATOR_LANDING_UPDATE', landing: ILandingConfig }
export interface ToggleLandingAction { type: 'CONFIGURATOR_TOGGLE_LANDING', data?: ILandingConfig }
export interface UpdateSelfServiceAction { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService?: ISelfServiceWorkflowConfig }

export type KnownAction = ReceiveWorkflowAction | VersionUpdateAction | LockoutConfigUpdateAction |
    ResourcesUpdateAction | ContractUpdateAction | ResultUpdateAction | CommonOfferUpdateAction | PreOfferUpdateAction | PostOfferUpdateAction |
    ControlUpdateAction | StageInspectionsUpdateAction | ContentTRansferUpdateAction |
    IdentificationUpdateAction | AssessmentUpdateAction | AssessmentInspectionsUpdateAction |
    IdentificationModulesUpdateAction | ToggleResourcesAction | TogglePreOfferAction | TogglePostOfferAction |
    ToggleAssessmentAction | ToggleIsControlTransactionAction | ToggleUseGradesCategoriesAction | ToggleUseDiscountCatalogsAction |
    SetGradesDelimiterAction | ToggleContractAction | UpdatePreviousCommonOfferPromoProviders | UpdatePreviousCommonOfferProviders |
    ToggleControlAction | ToggleResultAction | UpdateGrades | UpdatePreviousGrades | UpdateGradesCategories | UpdatePreviousGradesCategories |
    ReceiveIdentificationMethodAction | RemoveIdentificationMethodAction | DeviceTypesUpdateAction | ConfigPromoDataUpdateAction | ToggleContentTransferAction
    | LandingUpdateAction | ToggleLandingAction | UpdateSelfServiceAction

export const actionCreators = {
    initializeWorkflow: (data?: IWorkflow, channel?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIGURATOR_RECEIVE', data: data, channel: channel });
    },
    toggleStage: (stage: ProcessStages, channel?: Experience): AppThunkAction<KnownAction> => (dispatch, getState) => {
        switch (stage) {
            case ProcessStages.Landing:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_LANDING' });
                break;
            case ProcessStages.Resources:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_RESOURCES' });
                break;
            case ProcessStages.PreOffer:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_PRE_OFFER', channel: channel });
                break;
            case ProcessStages.PostOffer:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_POST_OFFER' });
                break;
            case ProcessStages.Assessment:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_ASSESSMENT' });
                break;
            case ProcessStages.Contract:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_CONTRACT' });
                break;
            case ProcessStages.Control:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_CONTROL' });
                break;
            case ProcessStages.Result:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_RESULT' });
                break;
            case ProcessStages.ContentTransfer:
                dispatch({ type: 'CONFIGURATOR_TOGGLE_CONTENT_TRANSFER' });
                break;
        }
    },
    toggleIsControlTransaction: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIGURATOR_TOGGLE_IS_CONTROL_TRANSACTION' });
    },
    toggleUseGradesCategories: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIGURATOR_TOGGLE_USE_GRADES_CATEGORIES' });
    },
    toggleUseDiscountCatalogs: (): AppThunkAction<KnownAction> => (dispatch, getState)=> {
        dispatch({ type: 'CONFIGURATOR_TOGGLE_USE_DISCOUNT_CATALOGS'});
    },
    setGradesDelimiter: (delimiter?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIGURATOR_SET_GRADES_DELIMITER', data: delimiter });
    },
    createOrUpdateIdentificationMethod: (method: IdentificationMethods, data: IIdentificationModuleConfig<IIdentificationModuleConfigUI>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIGURATOR_IDENTIFICATION_RECEIVE_METHOD', method: method, data: data });
    },
    removeIdentificationMethod: (method: IdentificationMethods): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIGURATOR_IDENTIFICATION_REMOVE_METHOD', method: method });
    },
    grades: {
        save: (data: IGrade[]): AppThunkAction<KnownAction> => (dispatch) => {
            dispatch({ type: 'CONFIGURATOR_GRADES_UPDATE', data: data })
        }
    },
    previousGrades: {
        save: (data: IGrade[]): AppThunkAction<KnownAction> => (dispatch) => {
            dispatch({ type: 'CONFIGURATOR_PREVIOUS_GRADES_UPDATE', data: data })
        },
    },
    gradesCategories: {
        save: (data: IGradesCategory[]): AppThunkAction<KnownAction> => (dispatch) => {
            dispatch({ type: 'CONFIGURATOR_GRADES_CATEGORIES_UPDATE', data: data })
        }
    },
    previousGradesCategories: {
        save: (data: IGradesCategory[]): AppThunkAction<KnownAction> => (dispatch) => {
            dispatch({ type: 'CONFIGURATOR_PREVIOUS_GRADES_CATEGORIES_UPDATE', data: data })
        }
    },
    previousCommonOfferProviders: {
        save: (data: ICatalog[]): AppThunkAction<KnownAction> => (dispatch) => {
            if(data){
                dispatch({ type: 'CONFIGURATOR_COMMON_OFFER_PROVIDER_UPDATE', data: data})
            }
        }
    },
    previousCommonOfferPromoProviders: {
        save: (data: IPromotion[]): AppThunkAction<KnownAction> => (dispatch) => {
            if(data) {
                console.log("DATA");
                dispatch({ type: 'CONFIGURATOR_COMMON_OFFER_PROMO_PROVIDER_UPDATE', data: data})
            }
        }
    },
    parameters: {
        setVersion: (version: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_VERSION_UPDATE', version: version });
        },
        imeiLockoutConfiguration: (config?: IImeiLockoutConfig): AppThunkAction<KnownAction> => (dispatch) => {
            dispatch({ type: 'CONFIGURATOR_PARAMETERS_IMEI_LOCKOUT', config: config });
        },
    },
    landing: {
        onConfigCommit: (config: ILandingConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_LANDING_UPDATE', landing: config });
        },
    },
    resources: {
        removeResource: (resourceIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const resources = getState().configurator.resources
            const newConfig = {
                ...resources,
                items: (resources?.items ?? []).filter((i, index) => index !== resourceIndex)
            }
            dispatch({ type: 'CONFIGURATOR_RESOURCES_UPDATE', resources: newConfig });
        },
        onConfigCommit: (config: IResourcesConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_RESOURCES_UPDATE', resources: config });
        },
    },
    commonOffer: {
        onConfigCommit: (config: ICommonOfferConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_COMMON_OFFER_UPDATE', commonOffer: config });
        },
    },
    preOffer: {
        onConfigCommit: (config: IPreOfferConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_PRE_OFFER_UPDATE', preOffer: config });
        },
    },
    postOffer: {
        onConfigCommit: (config: IPostOfferConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_POST_OFFER_UPDATE', postOffer: config });
        },
    },
    promo: {
        onConfigCommit: (promotions?: IPromotion[], catalogs?: ICatalog[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_PROMO_DATA_UPDATE', promotions: promotions, catalogs: catalogs })
        }
    },
    result: {
        onConfigCommit: (config: IResultConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_RESULT_UPDATE', result: config });
        },
    },
    contract: {
        onConfigCommit: (config: IContractConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_CONTRACT_UPDATE', contract: config });
        },
    },
    inspections: {
        updateInspectionSkip: (stage: ProcessStages, index: number, skip?: IDependencyRule[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let modules = getStageInspectionModules(stage, getState())
            if (modules) {
                modules = modules.map(m => {
                    if (m.index === index) {
                        return {
                            ...m,
                            config: {
                                ...m.config,
                                skip: skip
                            }
                        }
                    }
                    return m
                })
                dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
            }
        },
        interview: {
            addInterview: (stage: ProcessStages, mode: InterviewModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState()) ?? []
                const nextIndex = (modules?.length ?? 0) + 1
                dispatch({
                    type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE',
                    stage: stage,
                    modules: [
                        ...modules,
                        {
                            index: nextIndex,
                            type: Inspections.Interview,
                            config: {
                                mode: mode,
                                config: {
                                    allowPrimary: false,
                                    allowRollback: false,
                                    allowSecondaryText: false,
                                    requireComment: false,
                                    questions: []
                                }
                            } as IInterviewConfig
                        }]
                }
                );
            },
            editInterview: (stage: ProcessStages, index: number, config: IInterviewConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    modules = modules.map(m => {
                        if (m.index === index) {
                            return {
                                ...m,
                                config: config
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
                }
            },
            addQuestion: (stage: ProcessStages, moduleIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const interviewConfig = (m.config as IInterviewConfig)
                            const config = interviewConfig.config as IInterviewWithQuestionsConfig
                            const nextIndex = (config.questions && config.questions.length > 0) ? Math.max(...config.questions.map(i => i.index)) + 1 : 1
                            switch (interviewConfig.mode) {
                                case InterviewModes.Sequence:
                                    const newSequenceQuestion = {
                                        // order: config.questions.length,
                                        index: nextIndex,
                                        text: "",
                                        title: '',
                                        answers: []
                                    } as IQuestion;
                                    return {
                                        ...m,
                                        config: {
                                            ...interviewConfig,
                                            config: {
                                                ...interviewConfig.config,
                                                questions: config.questions ? [
                                                    ...config.questions,
                                                    newSequenceQuestion
                                                ] : [newSequenceQuestion]
                                            }
                                        } as IInterviewConfig
                                    }
                                case InterviewModes.List:
                                    const newListQuestion = {
                                        // order: config.questions.length,
                                        index: nextIndex,
                                        text: "",
                                        title: '',
                                        answers: [
                                            {
                                                index: 0,
                                                text: strings.BUTTONS.TOGGLE_NO
                                            },
                                            {
                                                index: 1,
                                                text: strings.BUTTONS.TOGGLE_YES
                                            }
                                        ]
                                    } as IQuestion;
                                    return {
                                        ...m,
                                        config: {
                                            ...interviewConfig,
                                            config: {
                                                ...interviewConfig.config,
                                                questions: config.questions ? [
                                                    ...config.questions,
                                                    newListQuestion
                                                ] : [newListQuestion]
                                            }
                                        } as IInterviewConfig
                                    }
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            },
            removeQuestion: (stage: ProcessStages, moduleIndex: number, questionIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const interviewConfig = (m.config as IInterviewConfig)
                            const config = interviewConfig.config as IInterviewWithQuestionsConfig
                            const newQuestions = config.questions?.filter(i => i.index !== questionIndex)
                            // .sort((a, b) => a.order - b.order)
                            // .map((i, localOrder) => { return ({ ...i, order: localOrder }) })
                            return {
                                ...m,
                                config: {
                                    ...interviewConfig,
                                    config: {
                                        ...interviewConfig.config,
                                        questions: newQuestions ?? []
                                    }
                                } as IInterviewConfig
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            },
            editQuestion: (stage: ProcessStages, moduleIndex: number, data: IQuestion): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const interviewConfig = (m.config as IInterviewConfig)
                            const config = interviewConfig.config as IInterviewWithQuestionsConfig
                            const newQuestions = config.questions?.map(q => {
                                if (q.index === data.index) {
                                    return data
                                }
                                return q
                            })
                            return {
                                ...m,
                                config: {
                                    ...interviewConfig,
                                    config: {
                                        ...interviewConfig.config,
                                        questions: newQuestions ?? []
                                    }
                                } as IInterviewConfig
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            },
            moveUpQuestion: (stage: ProcessStages, moduleIndex: number, questionIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let isOk = false;
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const interviewConfig = (m.config as IInterviewConfig);
                            const config = interviewConfig.config as IInterviewWithQuestionsConfig;
                            if (config.questions[0].index !== questionIndex) {
                                let questionOrder = -1;
                                config.questions.forEach((q, order) => {
                                    if (q.index === questionIndex) {
                                        questionOrder = order;
                                        isOk = true;
                                    }
                                })
                                const newQuestions = config.questions?.map((q, order) => {
                                    if (order === questionOrder - 1) {
                                        return config.questions[questionOrder];
                                    }
                                    if (order === questionOrder) {
                                        return config.questions[questionOrder - 1];
                                    }
                                    return q;
                                });
                                return {
                                    ...m,
                                    config: {
                                        ...interviewConfig,
                                        config: {
                                            ...interviewConfig.config,
                                            questions: newQuestions ?? []
                                        }
                                    } as IInterviewConfig
                                };
                            }
                        }
                        return m;
                    });
                    if (isOk) {
                        dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                    }
                }
            },
            editModeConfig: (stage: ProcessStages, moduleIndex: number, config: IInterviewSequenceConfig | IInterviewListConfig | IInterviewManualConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState()) ?? []
                let newModules = modules.map(m => {
                    if (moduleIndex === m.index) {
                        const interviewConfig = (m.config as IInterviewConfig)
                        switch (interviewConfig.mode) {
                            case InterviewModes.Sequence:
                                return {
                                    ...m,
                                    config: {
                                        ...interviewConfig,
                                        config: {
                                            ...interviewConfig.config,
                                            ...config
                                        } as IInterviewSequenceConfig
                                    }
                                }
                            case InterviewModes.List:
                                return {
                                    ...m,
                                    config: {
                                        ...interviewConfig,
                                        config: {
                                            ...interviewConfig.config,
                                            ...config
                                        } as IInterviewListConfig
                                    }
                                }
                            case InterviewModes.Manual:
                                return {
                                    ...m,
                                    config: {
                                        ...interviewConfig,
                                        config: {
                                            ...interviewConfig.config,
                                            ...config
                                        } as IInterviewManualConfig
                                    }
                                }
                        }
                    }
                    return m
                })
                dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
            },
        },
        photographic: {
            addPhotographic: (stage: ProcessStages, mode: PhotographicModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    const nextIndex = (modules?.length ?? 0) + 1
                    dispatch({
                        type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE',
                        stage: stage,
                        modules: [
                            ...modules,
                            {
                                index: nextIndex,
                                type: Inspections.Photographic,
                                config: {
                                    mode: mode,
                                    config: {
                                    }
                                } as IPhotographicConfig
                            }]
                    }
                    );
                }
            },
            editPhotographic: (stage: ProcessStages, index: number, config: IPhotographicConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    modules = modules.map(m => {
                        if (m.index === index) {
                            return {
                                ...m,
                                config: config
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
                }
            },
            editPhotographicRequest: (stage: ProcessStages, index: number, config?: IPhotographicRequestConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    modules = modules.map(m => {
                        if (m.index === index) {
                            return {
                                ...m,
                                config: {
                                    ...m.config,
                                    request: config
                                } as IPhotographicConfig
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
                }
            },
            editModeConfig: (stage: ProcessStages, moduleIndex: number, config: IPhotographicCollectionConfig | IPhotographicFreeConfig | IPhotographicModeConfig<IPhotographicModeConfigUI>): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const photographicConfig = (m.config as IPhotographicConfig)
                            switch (photographicConfig.mode) {
                                case PhotographicModes.Collection:
                                    return {
                                        ...m,
                                        config: {
                                            ...photographicConfig,
                                            config: {
                                                ...photographicConfig.config,
                                                ...config
                                            } as IPhotographicCollectionConfig
                                        }
                                    }
                                case PhotographicModes.Free:
                                    return {
                                        ...m,
                                        config: {
                                            ...photographicConfig,
                                            config: {
                                                ...photographicConfig.config,
                                                ...config
                                            } as IPhotographicFreeConfig
                                        }
                                    }
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            },
        },
        software: {
            addSoftware: (stage: ProcessStages, mode: SoftwareModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState()) ?? []
                const nextIndex = (modules?.length ?? 0) + 1
                dispatch({
                    type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE',
                    stage: stage,
                    modules: [
                        ...modules,
                        {
                            index: nextIndex,
                            type: Inspections.Software,
                            config: {
                                mode: mode,
                                config: {
                                }
                            } as ISoftwareConfig
                        }]
                }
                );
            },
            editSoftware: (stage: ProcessStages, index: number, config: ISoftwareConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    modules = modules.map(m => {
                        if (m.index === index) {
                            return {
                                ...m,
                                config: config
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
                }
            },
            editModeConfig: (stage: ProcessStages, moduleIndex: number, config: ISoftwarePiceaConfig | IInspectionModeConfig<IInspectionModeConfigUI>): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const softwareConfig = (m.config as ISoftwareConfig)
                            switch (softwareConfig.mode) {
                                case SoftwareModes.Piceasoft:
                                    return {
                                        ...m,
                                        config: {
                                            ...softwareConfig,
                                            config: {
                                                ...softwareConfig.config,
                                                ...config
                                            } as ISoftwarePiceaConfig
                                        }
                                    }
                                case SoftwareModes.PiceaMobile:
                                    return {
                                        ...m,
                                        config: {
                                            ...softwareConfig,
                                            config: {
                                                ...softwareConfig.config,
                                                ...config
                                            } as ISoftwarePiceaConfig
                                        }
                                    }
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            },
        },
        dataCollection: {
            addDataCollection: (stage: ProcessStages, mode: DataCollectionModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState()) ?? []
                const nextIndex = (modules?.length ?? 0) + 1
                dispatch({
                    type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE',
                    stage: stage,
                    modules: [
                        ...modules,
                        {
                            index: nextIndex,
                            type: Inspections.DataCollection,
                            config: {
                                mode: mode,
                                config: {
                                }
                            } as IDataCollectionConfig
                        }]
                }
                );
            },
            editDataCollection: (stage: ProcessStages, index: number, config: IDataCollectionConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    modules = modules.map(m => {
                        if (m.index === index) {
                            return {
                                ...m,
                                config: config
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
                }
            },
            editModeConfig: (stage: ProcessStages, moduleIndex: number, config: IDataCollectionConfig | IDataCollectionStandardConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const dataCollectionConfig = (m.config as IDataCollectionConfig)
                            switch (dataCollectionConfig.mode) {
                                case DataCollectionModes.Standard:
                                    return {
                                        ...m,
                                        config: {
                                            ...dataCollectionConfig,
                                            config: {
                                                ...dataCollectionConfig.config,
                                                ...config
                                            } as IDataCollectionStandardConfig
                                        }
                                    }
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            }
        },
        diagnostics: {
            addDiagnostics: (stage: ProcessStages, mode: DiagnosticsModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
                console.log(mode)
                let modules = getStageInspectionModules(stage, getState()) ?? []
                const nextIndex = (modules?.length ?? 0) + 1
                dispatch({
                    type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE',
                    stage: stage,
                    modules: [
                        ...modules,
                        {
                            index: nextIndex,
                            type: Inspections.Diagnostics,
                            config: {
                                mode: mode,
                                config: {
                                    tests: []
                                }
                            } as IDiagnosticsConfig
                        }]
                }
                );
            },
            editDiagnostics: (stage: ProcessStages, index: number, config: IDiagnosticsConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    modules = modules.map(m => {
                        if (m.index === index) {
                            return {
                                ...m,
                                config: config
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
                }
            },
            editModeConfig: (stage: ProcessStages, moduleIndex: number, config: IDiagnosticsPiceaConfig | IDiagnosticsManualConfig | IDiagnosticsPiceaMobileConfig | IInspectionModeConfig<IInspectionModeConfigUI>): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const diagnosticsConfig = (m.config as IDiagnosticsConfig)
                            switch (diagnosticsConfig.mode) {
                                case DiagnosticsModes.Piceasoft:
                                    return {
                                        ...m,
                                        config: {
                                            ...diagnosticsConfig,
                                            config: {
                                                ...diagnosticsConfig.config,
                                                ...config
                                            } as IDiagnosticsPiceaConfig
                                        }
                                    }
                                case DiagnosticsModes.PiceaMobile:
                                    console.log(true)
                                    return {
                                        ...m,
                                        config: {
                                            ...diagnosticsConfig,
                                            config: {
                                                ...diagnosticsConfig.config,
                                                ...config
                                            } as IDiagnosticsPiceaMobileConfig
                                        }
                                    }
                                case DiagnosticsModes.WebBased:
                                    return {
                                        ...m,
                                        config: {
                                            ...diagnosticsConfig,
                                            config: {
                                                ...diagnosticsConfig.config,
                                                ...config
                                            } as IDiagnosticsWebBasedConfig
                                        }
                                    }
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            },
        },
        erase: {
            addErase: (stage: ProcessStages, mode: EraseModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState()) ?? []
                const nextIndex = (modules?.length ?? 0) + 1
                dispatch({
                    type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE',
                    stage: stage,
                    modules: [
                        ...modules,
                        {
                            index: nextIndex,
                            type: Inspections.Erase,
                            config: {
                                mode: mode,
                                config: {
                                }
                            } as IEraseConfig
                        }]
                })
            },
            editErase: (stage: ProcessStages, index: number, config: IEraseConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    modules = modules.map(m => {
                        if (m.index === index) {
                            return {
                                ...m,
                                config: config
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
                }
            },
            editModeConfig: (stage: ProcessStages, moduleIndex: number, config: IEraseConfig | IEraseDefaultConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const eraseConfig = (m.config as IEraseConfig)
                            switch (eraseConfig.mode) {
                                case EraseModes.Default:
                                    return {
                                        ...m,
                                        config: {
                                            ...eraseConfig,
                                            config: {
                                                ...eraseConfig.config,
                                                ...config
                                            } as IEraseDefaultConfig
                                        }
                                    }
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            }
        },
        addInspection: (stage: ProcessStages, type: Inspections, config: IInspectionConfig<IInspectionConfigUI>): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let modules = getStageInspectionModules(stage, getState())
            if (modules) {
                const nextIndex = (modules.length ?? 0) + 1
                dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: [...modules, { index: nextIndex, type: type, config: config }] });
            }
        },
        editInspection: (stage: ProcessStages, index: number, config: IInspectionConfig<IInspectionConfigUI>): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let modules = getStageInspectionModules(stage, getState())
            if (modules) {
                modules = modules.map(m => {
                    if (m.index === index) {
                        return {
                            ...m,
                            config: config
                        }
                    }
                    return m
                })
                dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
            }
        },
        moveUpInspection: (stage: ProcessStages, index: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let modules = getStageInspectionModules(stage, getState())
            if (modules) {
                if (index > 1) {
                    let newModules = modules.map(m => {
                        if (m.index === index) {
                            return { ...m, index: m.index - 1 }
                        }
                        if (m.index === index - 1) {
                            return { ...m, index: m.index + 1 }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            }
        },
        aigrading: {
            addAigrading: (stage: ProcessStages, mode: AIGradingModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState()) ?? []
                const nextIndex = (modules?.length ?? 0) + 1
                dispatch({
                    type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE',
                    stage: stage,
                    modules: [
                        ...modules,
                        {
                            index: nextIndex,
                            type: Inspections.AIGrading,
                            config: {
                                mode: mode,
                                config: {
                                    uploadMethods: [AIGradingUploadMethods.Remote],
                                    imageTypes: [AIGradingImageTypes.ScreenOff],
                                    resultGrades: [
                                        { type: AIGradingResultTypes.ScreenCrack, grade: undefined },
                                        { type: AIGradingResultTypes.ScreenScratch, grade: undefined },
                                        { type: AIGradingResultTypes.ScreenCover, grade: undefined }
                                    ]
                                }
                            } as IAIGradingConfig
                        }]
                })
            },
            editAIGrading: (stage: ProcessStages, index: number, config: IAIGradingConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getState().configurator.assessment?.modules.map(m => {
                    if (m.index === index) {
                        return {
                            ...m,
                            config: {
                                ...config,
                                config: {
                                    ...config.config,
                                    uploadMethods: config.config.uploadMethods.sort(),
                                    defaultUploadMethod: config.config.defaultUploadMethod !== undefined ? (
                                        config.config.uploadMethods.find(i => i === config.config.defaultUploadMethod) ?? config.config.uploadMethods?.[0]
                                    ) : undefined,
                                    imageTypes: config.config.imageTypes?.sort()
                                }
                            }
                        }
                    }
                    return m
                })
                dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
            },
        },
        manualGrades: {
            addManualGrades: (stage: ProcessStages, mode: ManualGradingModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState()) ?? []
                const nextIndex = (modules?.length ?? 0) + 1
                dispatch({
                    type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE',
                    stage: stage,
                    modules: [
                        ...modules,
                        {
                            index: nextIndex,
                            type: Inspections.ManualGrading,
                            config: {
                                mode: mode,
                                config: {
                                }
                            } as IManualGradingConfig
                        }
                    ]
                }
                );
            },
            editManualGrades: (stage: ProcessStages, index: number, config: IManualGradingConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    modules = modules.map(m => {
                        if (m.index === index) {
                            return {
                                ...m,
                                config: config
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? [] });
                }
            },
            editModeConfig: (stage: ProcessStages, moduleIndex: number, config: IManualGradingConfig | IManualGradingDefaultConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
                let modules = getStageInspectionModules(stage, getState())
                if (modules) {
                    let newModules = modules.map(m => {
                        if (moduleIndex === m.index) {
                            const manualGradesConfig = (m.config as IManualGradingConfig)
                            switch (manualGradesConfig.mode) {
                                case ManualGradingModes.Default:
                                    return {
                                        ...m,
                                        config: {
                                            ...manualGradesConfig,
                                            config: {
                                                ...manualGradesConfig.config,
                                                ...config
                                            } as IDataCollectionStandardConfig
                                        }
                                    }
                            }
                        }
                        return m
                    })
                    dispatch({ type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: newModules ?? [] });
                }
            }
        },
        removeInspection: (stage: ProcessStages, index: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let modules = getStageInspectionModules(stage, getState())
            if (modules) {
                modules = modules.filter(i => i.index !== index)
                    .sort((a, b) => a.index - b.index)
                    .map((i, localIndex) => { return ({ ...i, index: localIndex + 1 }) })
                dispatch({
                    type: 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE', stage: stage, modules: modules ?? []

                });
            }
        },
    },
    identification: {
        addModule: (method: IdentificationMethods): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let items = getState().configurator.identification.items
            const nextIndex = (items?.length ?? 0) + 1
            let newItem: IIdentificationModuleConfig<IIdentificationModuleConfigUI> = { index: nextIndex, method: method }
            if (newItem.method === IdentificationMethods.Scanner) {
                newItem = { ...newItem, type: ScannerIdentificationTypes.Imei, uploadMethods: [ScannerUploadMethods.Remote] } as IIdentificationScannerConfig
            }
            dispatch({ type: 'CONFIGURATOR_IDENTIFICATION_ITEMS_UPDATE', items: items ? [...items, newItem] : [newItem] });
        },
        editModule: (module: IIdentificationModuleConfig<IIdentificationModuleConfigUI>): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let items = getState().configurator.identification.items.map(m => {
                if (m.index === module.index) {
                    if (module.method === IdentificationMethods.Scanner) {
                        return ({
                            ...module, uploadMethods: (module as IIdentificationScannerConfig).uploadMethods.sort(),
                            defaultUploadMethod: (module as IIdentificationScannerConfig).defaultUploadMethod !== undefined ? (
                                (module as IIdentificationScannerConfig).uploadMethods.find(i => i === (module as IIdentificationScannerConfig).defaultUploadMethod) ??
                                (module as IIdentificationScannerConfig).uploadMethods?.[0]
                            ) : undefined
                        } as IIdentificationScannerConfig)

                    }
                    return module
                }
                return m
            })
            dispatch({ type: 'CONFIGURATOR_IDENTIFICATION_ITEMS_UPDATE', items: items });
        },
        moveUpModule: (index: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
            if (index > 0) {
                let newItems = getState().configurator.identification.items.map(m => {
                    if (m.index === index) {
                        return { ...m, index: m.index - 1 }
                    }
                    if (m.index === index - 1) {
                        return { ...m, index: m.index + 1 }
                    }
                    return m
                })
                dispatch({ type: 'CONFIGURATOR_IDENTIFICATION_ITEMS_UPDATE', items: newItems });
            }
        },
        removeModule: (index: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let items = getState().configurator.identification.items
                .filter(i => i.index !== index)
                .sort((a, b) => a.index - b.index)
                .map((i, localIndex) => { return ({ ...i, index: localIndex + 1 }) })
            dispatch({ type: 'CONFIGURATOR_IDENTIFICATION_ITEMS_UPDATE', items: items });
        },
    },
    deviceTypes: {
        setDeviceTypes: (deviceTypes?: Array<DeviceTypes>): AppThunkAction<KnownAction> => (dispatch, getState) => {
            dispatch({ type: 'CONFIGURATOR_DEVICE_TYPES_UPDATE', data: deviceTypes });
        },
    },
    editStage: (stage: ProcessStages, data: IStageConfig<IStageConfigUI>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState().configurator
        switch (stage) {
            case ProcessStages.Resources: return
            case ProcessStages.ContentTransfer: return dispatch({ type: 'CONFIGURATOR_CONTENT_TRANSFER_UPDATE', contentTransfer: { ...state.contentTransfer, ...data } });
            case ProcessStages.Identification: return dispatch({ type: 'CONFIGURATOR_IDENTIFICATION_UPDATE', identification: { ...state.identification, ...data } })
            case ProcessStages.PreOffer: return dispatch({ type: 'CONFIGURATOR_PRE_OFFER_UPDATE', preOffer: state.preOffer ? { ...state.preOffer, ...data } : { ...data as IPreOfferConfig } })
            case ProcessStages.Assessment: return dispatch({ type: 'CONFIGURATOR_ASSESSMENT_UPDATE', assessment: state.assessment ? { ...state.assessment, ...data } : { ...data as IAssessmentConfig } })
            case ProcessStages.PostOffer: return dispatch({ type: 'CONFIGURATOR_POST_OFFER_UPDATE', postOffer: state.postOffer ? { ...state.postOffer, ...data } : { ...data as IPostOfferConfig } })
            case ProcessStages.Contract: return dispatch({ type: 'CONFIGURATOR_CONTRACT_UPDATE', contract: state.contract ? { ...state.contract, ...data } : { ...data as IContractConfig } })
            case ProcessStages.Control: return dispatch({ type: 'CONFIGURATOR_CONTROL_UPDATE', control: state.control ? { ...state.control, ...data } : { ...data as IControlConfig } })
            case ProcessStages.Result: return dispatch({ type: 'CONFIGURATOR_RESULT_UPDATE', result: state.result ? { ...state.result, ...data } : { ...data as IResultConfig } })
            case ProcessStages.Landing: return dispatch({ type: 'CONFIGURATOR_LANDING_UPDATE', landing: state.result ? { ...state.result, ...data } : { ...data as ILandingConfig } })
        }
    },
    selfService: {
        toggleSelfServiceConfigItem: (answerId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const selfService = getState().configurator.selfService
            const questions: ISelfServiceConfigItem[] = selfService?.config.items.filter((i: ISelfServiceConfigItem) => i.type === SelfServiceConfigType.Question) || []
            if (selfService) {
                const itemIndex = questions.findIndex((i) => i.properties?.answers?.some(a => a.id === answerId))
                if (itemIndex >= 0) {
                    // empty array check is needed purely for types check
                    //const toggleIndex = (questions[itemIndex].properties?.answers ?? []).find((a: ISelfServiceConfigAnswer) => a.id === answerId)
                    //selfService.config.items[itemIndex].properties.answers[toggleIndex].disable = !selfService.config.items[itemIndex].properties?.answers?[toggleIndex].disable
                    const answer = (questions[itemIndex].properties?.answers ?? []).find((a: ISelfServiceConfigAnswer) => a.id === answerId)
                    if (answer !== undefined)
                        answer.disable = !answer.disable
                }
                dispatch({ type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService })
            }
        },
        renameSelfServiceConfigItem: (answerId: string, text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const selfService = getState().configurator.selfService
            const questions: ISelfServiceConfigItem[] = selfService?.config.items.filter((i: ISelfServiceConfigItem) => i.type === SelfServiceConfigType.Question) || []
            if (selfService) {
                const itemIndex = questions.findIndex((i) => i.properties?.answers?.some(a => a.id === answerId))
                if (itemIndex >= 0) {
                    const answer = (questions[itemIndex].properties?.answers ?? []).find((a: ISelfServiceConfigAnswer) => a.id === answerId)
                    if (answer !== undefined)
                        answer.text = text
                }
                dispatch({type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService})
            }
        },
        updateDiagnosticCase: (caseId: number, mode: DiagnosticsModes): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const selfService = getState().configurator.selfService
            if (selfService) {
                const cases: ISelfServiceDiagnosticCase[] = selfService.config.diagnostics.cases
                const rest: ISelfServiceDiagnosticCase[]  = cases.filter((c) => c.caseId !== caseId)
                selfService.config.diagnostics.cases = [...rest, {caseId, mode}]
                dispatch({type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService})
            }
        },
        updateDiagnosticCaseOptions: (caseId: number, options?: ISelfServiceDiagnosticCaseOptions): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const selfService = getState().configurator.selfService
            if (selfService) {
                const cases: ISelfServiceDiagnosticCase[] = selfService.config.diagnostics.cases
                const rest: ISelfServiceDiagnosticCase[]  = cases.filter((c) => c.caseId !== caseId)
                let newCase: ISelfServiceDiagnosticCase = {
                    caseId: caseId,
                    mode: cases.find(x => x.caseId == caseId)?.mode ?? DiagnosticsModes.PiceaMobile,
                    options: options
                }
                selfService.config.diagnostics.cases = [...rest, newCase]
                dispatch({type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService})
            }
        },
        setPromotions: (data: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let state = getState().configurator.selfService;

            // Check if state is defined
            if (state) {
                // Check for duplicate IDs
                const uniqueData = data.filter(id => state && !state.promotions.includes(id));

                // Dispatch an action to update the state
                dispatch({
                    type: 'CONFIGURATOR_SELF_SERVICE_UPDATE',
                    selfService: {
                        ...state,
                        promotions: state.promotions ? [...state.promotions, ...uniqueData] : [...uniqueData]
                    }
                });
            }
        },
        editStepButtons: (data: ISelfServiceWorkflowConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const selfService = getState().configurator.selfService
            dispatch({ type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService: { ...selfService, ...data } })
        },
        setRepairOfferProviders: (data: IRepairOfferProviderConfig[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
            let state = getState().configurator.selfService;
            if (state) {
                // Dispatch an action to update the state
                dispatch({
                    type: 'CONFIGURATOR_SELF_SERVICE_UPDATE',
                    selfService: {
                        ...state,
                        repairOffers: {
                            ...state.repairOffers,
                            providers: data
                        }
                    }
                });
            }

        },
        editRepairOfferConfigs: (data: IRepairOfferConfig): AppThunkAction<KnownAction> => (dispatch, getState) => {
            data.disclaimerId = data.disclaimerId === -1 ? undefined : data.disclaimerId;
            const selfService = getState().configurator.selfService
            if (selfService) {
                dispatch({
                    type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService: {
                        ...selfService,
                        repairOffers: data,
                    }
                })
            }
        },
        upsertDisclaimersConfigs: (data: IDisclaimer[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const selfService = getState().configurator.selfService
            if (selfService) {
                dispatch({
                    type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService: {
                        ...selfService,
                        disclaimers: data
                    }
                })
            }
        },
        updateSelfServiceConfigDiagnosticsCases: (diagnosticsCases: (ISelfServiceDiagnosticCase | undefined)[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const selfService = getState().configurator.selfService;
            if(selfService) {
                let updatedDiagnosticsCases = [...selfService.config.diagnostics.cases];
                diagnosticsCases.map(diagnosticCase => {
                    if(diagnosticCase)
                        updatedDiagnosticsCases.push(diagnosticCase);
                })
                selfService.config.diagnostics.cases = updatedDiagnosticsCases;
                dispatch({type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService});
            }
        },
        updateSelfServiceConfigItems: (items: ISelfServiceConfigItem[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const selfService = getState().configurator.selfService;
            if(selfService) {
                selfService.config.items = items;
                dispatch({type: "CONFIGURATOR_SELF_SERVICE_UPDATE", selfService});
            }
        },
    }
};

export const getStageInspectionModules = (stage: ProcessStages, state: IStore): IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>[] | undefined => {
    switch (stage) {
        case ProcessStages.Assessment: return state.configurator.assessment?.modules;
        case ProcessStages.Control: return state.configurator.control?.modules;
        case ProcessStages.PostOffer: return state.configurator.postOffer?.modules;
    }
}
