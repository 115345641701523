import { IDropdownOption } from '@fluentui/react'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../../../../../../../../core/store'
import { IPhotographicConfig, IPhotographicModeConfig, IGrade, IPhotographicModeConfigUI } from "@piceasoft/core"
import { strings } from '../../../../../../../../localization/strings'
import { Section } from '../../../../../../decorations/Section'
import { ParameterItem } from '../../../../../components/ParameterItem'

export type TModeConfiguratorCommonPivotProps = {
    photographic: IPhotographicConfig
    onModeConfirm: (config: IPhotographicModeConfig<IPhotographicModeConfigUI>) => void
    onConfigConfirm: (config: IPhotographicConfig) => void
}

export const ModeConfiguratorCommonPivot: React.FC<TModeConfiguratorCommonPivotProps> = (props) => {

    const tabsOptions: IDropdownOption[] = [
        { key: "local", text: strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.LOCAL },
        { key: "remote", text: strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE }
    ]

    const grades = (props.photographic.gradesCategory
        ? useSelector((s: IStore) => s.configurator.gradesCategories?.find(i => i.code === props.photographic.gradesCategory))?.grades
        : useSelector((s: IStore) => s.configurator.grades)) as IGrade[]

    const getMethodText = (m: string): string => {
        if (m === "local") {
            return props.photographic.ui?.localMethodTitle ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.LOCAL
        }
        return props.photographic.ui?.remoteMethodTitle ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE
    }

    return (
        <Section flat max>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.USE_METHODS_TITLE}
                useToggle
                noSeparator
                toggleEnabled
                toggleChecked={props.photographic.useMethods !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigConfirm({
                        ...props.photographic,
                        useMethods: props.photographic.useMethods !== undefined ? undefined : []
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.USE_METHODS_DESCRIPTION}
                useDropdown={props.photographic.useMethods ? true : false}
                dropdownMultiselect
                dropdownEnabled={props.photographic.useMethods !== undefined ? true : false}
                dropdownSelectedKeys={props.photographic.useMethods ?? []}
                dropdownOnChange={(opt) => props.onConfigConfirm({
                    ...props.photographic,
                    useMethods: opt?.selected ? (props.photographic.useMethods ? [...props.photographic.useMethods, opt.key as "remote" | "local"] : [opt.key as "remote" | "local"]) : props.photographic.useMethods?.filter(i => i !== opt?.key as "remote" | "local")
                })}
                dropdownPlaceholder={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.USE_METHODS_PLACEHOLDER}
                dropdownOptions={tabsOptions}
            >
            </ParameterItem>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.DEFAULT_METHOD_TITLE}
                useToggle={false}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.DEFAULT_METHOD_DESCRIPTION}
                useDropdown
                dropdownEnabled
                dropdownSelectedKey={props.photographic.defaultMethod}
                dropdownOnChange={(opt) => props.onConfigConfirm({
                    ...props.photographic,
                    defaultMethod: opt?.key as "remote" | "local"
                })}
                dropdownPlaceholder={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.DEFAULT_METHOD_PLACEHOLDER}
                dropdownOptions={((props.photographic.useMethods && props.photographic.useMethods.length > 0) ? props.photographic.useMethods : ["remote","local"]).map(i => ({key: i, text: getMethodText(i)}))}
            >
            </ParameterItem>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.AUTO_APPROVE_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.photographic.autoApprove !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigConfirm({
                        ...props.photographic,
                        autoApprove: props.photographic.autoApprove !== undefined ? undefined : []
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.AUTO_APPROVE_DESCRIPTION}
                useDropdown={props.photographic.autoApprove ? true : false}
                dropdownMultiselect
                dropdownEnabled={grades && grades.length > 0 ? true : false}
                dropdownSelectedKeys={props.photographic.autoApprove ?? []}
                dropdownOnChange={(opt) => props.onConfigConfirm({
                    ...props.photographic,
                    autoApprove: opt?.selected ? (props.photographic.autoApprove ? [...props.photographic.autoApprove, opt.key as string] : [opt.key as string]) : props.photographic.autoApprove?.filter(i => i !== opt?.key as string)
                })}
                // dropdownErrorMessage={props.photographic.errors? strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_WAS_RESET: undefined }
                dropdownPlaceholder={grades && grades.length > 0 ? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.AUTO_APPROVE_PLACEHOLDER : strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.AUTO_APPROVE_PLACEHOLDER_NO_GRADES}
                dropdownOptions={grades.map(g => ({ key: g.code, text: `${g.name} - ${g.code}` }))}
                errorMessage={props.photographic.errors?.find((error)=>error.code === 9)?strings.CONSTRUCTOR.INSPECTIONS.COMMON.WARNING_OPTION_TURNED_OFF: undefined}
            >
            </ParameterItem>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.LOCAL_TEXT_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.photographic.config.ui?.localText !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.photographic.config,
                        ui: {
                            ...props.photographic.config.ui,
                            localText: props.photographic.config.ui?.localText !== undefined ? undefined : ""
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.LOCAL_TEXT_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={props.photographic.config.ui?.localText !== undefined ? true : false}
                textFieldValue={props.photographic.config.ui?.localText}
                textFieldOnChange={(val) => props.onModeConfirm({
                    ...props.photographic.config,
                    ui: {
                        ...props.photographic.config.ui,
                        localText: val
                    }
                })
                }
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE_TEXT_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.photographic.config.ui?.remoteText !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.photographic.config,
                        ui: {
                            ...props.photographic.config.ui,
                            remoteText: props.photographic.config.ui?.remoteText !== undefined ? undefined : ""
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE_TEXT_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={props.photographic.config.ui?.remoteText !== undefined ? true : false}
                textFieldValue={props.photographic.config.ui?.remoteText}
                textFieldOnChange={(val) => props.onModeConfirm({
                    ...props.photographic.config,
                    ui: {
                        ...props.photographic.config.ui,
                        remoteText: val
                    }
                })
                }
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE_MESSAGE_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.photographic.config.ui?.remoteMessageBarText !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.photographic.config,
                        ui: {
                            ...props.photographic.config.ui,
                            remoteMessageBarText: props.photographic.config.ui?.remoteMessageBarText !== undefined ? undefined : ""
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.REMOTE_MESSAGE_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={props.photographic.config.ui?.remoteMessageBarText !== undefined ? true : false}
                textFieldValue={props.photographic.config.ui?.remoteMessageBarText}
                textFieldOnChange={(val) => props.onModeConfirm({
                    ...props.photographic.config,
                    ui: {
                        ...props.photographic.config.ui,
                        remoteMessageBarText: val
                    }
                })
                }
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.ALLOW_COMMENT_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.photographic.config?.comment ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.photographic.config,
                        comment: props.photographic.config?.comment ? undefined : {}
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.ALLOW_COMMENT_DESCRIPTION}
            />
        </Section>
    )
}
