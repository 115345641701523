import {
    Modal, IconButton, Dropdown, IComboBoxOption, IDropdownOption,
    SharedColors, Stack, Text, Image, PrimaryButton, IRenderFunction, ISelectableOption
} from "@fluentui/react";
import { getTheme, mergeStyleSets, FontWeights, getFocusStyle } from "@fluentui/style-utilities";
import { useId } from "@uifabric/react-hooks";
import React from "react";
import { escapeRegExp } from "../../../../assets/ts/utils/regex";
import { SecondaryButton } from "../../../../components/shared/buttons/SecondaryButton";
import SuggestedSearch, { handleTextToListKeyPress, focusElement } from "../../../../components/shared/search/SuggestedSearch";
import { IOrganization } from "@piceasoft/core";
import { strings } from "../../../../localization/strings";

type TProps = {
    items: IOrganization[]
    isOpen: boolean
    onDismiss: () => void
    onAddSupervisorOrganization: (organizationId: string) => void
}

export const ChooseOrganization: React.FC<TProps> = (props) => {

    const [organizationsOptions, setOrganizationsOptions] = React.useState<IDropdownOption[]>()

    const organizationsHostId = useId("organizations-host-id")
    const organizationsTextId = useId("organizations-text-id")
    const organizationsCalloutListId = useId("organizations-callout-id")

    const [filterValue, setFilterValue] = React.useState<string>()

    const [selectedOrganizationOption, setSelectedOrganizationOption] = React.useState<IComboBoxOption>()

    React.useEffect(() => {
        prepareOrganizationsOptions()
    }, [])

    React.useEffect(() => {
        prepareOrganizationsOptions()
    }, [props.items])

    const prepareOrganizationsOptions = () => {
        setOrganizationsOptions(props.items.map(i => {
            return { key: i.id, text: i.name, data: i } as IDropdownOption
        }) ?? [])
    }

    const onaddOrganization = async () => {
        if (selectedOrganizationOption?.key) {
            props.onAddSupervisorOrganization(selectedOrganizationOption.key as string)
        }
    }

    const organizationOptionSelectHandler = (item?: IComboBoxOption) => {
        setSelectedOrganizationOption(item)
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.neutralQuaternaryAlt : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    // padding: 2,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.neutralQuaternaryAlt,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    cursor: 'pointer',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderDropDownOption: IRenderFunction<ISelectableOption<IOrganization>> = (item) => {
        return (
            <Stack 
            // onClick={() => organizationOptionSelectHandler(item)} 
            verticalAlign='center'
                horizontal horizontalAlign="space-between" grow tokens={{ padding: 4, childrenGap: 18 }}>
                <Stack tokens={{ padding: 2 }}>
                    <Text block nowrap variant="medium" style={{ color: SharedColors.gray20 }}>{item?.text}</Text>
                    <Text block nowrap variant="medium" style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{item?.data?.domain}</Text>
                </Stack>
                <Stack verticalAlign='center' style={{ height: 48 }}>
                    <Image height={24} src={item?.data?.logo ?? "/favicon.png"} />
                </Stack>
            </Stack>
        );
    }

    const onRenderSuggestionsCell = (item?: IDropdownOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${organizationsCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, organizationsOptions?.length ?? 0, organizationsTextId, organizationsCalloutListId, () => organizationOptionSelectHandler(item), index, item?.text, organizationsHostId)}
                tabIndex={-1}
                onClick={() => {
                    organizationOptionSelectHandler(item)
                    focusElement(`${organizationsCalloutListId}-${index as number}`, organizationsHostId)
                }}
            >
                <Stack verticalAlign='center' styles={{
                    root: {
                        cursor: 'pointer',
                    }
                }} horizontal horizontalAlign="space-between" grow tokens={{ padding: 4, childrenGap: 18 }}>
                    <Stack tokens={{ padding: 2 }}>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray20 }}>{item?.text}</Text>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{(item?.data as IOrganization)?.domain}</Text>
                    </Stack>
                    <Stack verticalAlign='center' style={{ height: 48 }}>
                        <Image height={24} src={(item?.data as IOrganization)?.logo ?? "/favicon.png"} />
                    </Stack>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey, data: i.data } as IDropdownOption)
            })
    }

    return (
        <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.ORGANIZATIONS.LINK_TITLE}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close"
                    onClick={props.onDismiss}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack tokens={{ childrenGap: 16 }}>
                    {(organizationsOptions?.length ?? 0) <= 5 && (
                        <Dropdown
                            // label={strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.CHOOSE_ORGANIZATION_LABEL}
                            options={organizationsOptions ?? []}
                            onChange={(ev, opt) => organizationOptionSelectHandler(opt)}
                            disabled={!organizationsOptions || organizationsOptions.length === 0}
                            styles={{
                                dropdown: { width: '100%' },
                                dropdownItem: { height: 64, borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }, dropdownItemSelected: { height: 64, borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }, dropdownItemsWrapper: { height: 300 }
                            }}
                            selectedKey={selectedOrganizationOption?.key.toString()}
                            onRenderOption={onRenderDropDownOption}
                            placeholder={organizationsOptions && organizationsOptions.length > 0 ?
                                strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.CHOOSE_ORGANIZATION_PLACEHOLDER :
                                strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.NO_ORGANIZATIONS} />
                    ) || (
                            <SuggestedSearch
                                type="dropdown-search"
                                inputBoxId={organizationsTextId}
                                hostId={organizationsHostId}
                                // label={strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.CHOOSE_ORGANIZATION_LABEL}
                                inputBoxPlaceholder={!organizationsOptions ?
                                    strings.SPINNERS.DATA_IS_GETTING :
                                    // organizationsOptions?.find(i => i.key === selectedOrganizationOption?.key)?.text ?? (organizationsOptions &&
                                    organizationsOptions.length > 0 ?
                                        strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.CHOOSE_ORGANIZATION_PLACEHOLDER :
                                        strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.NO_ORGANIZATIONS
                                    // )
                                }
                                onClickSuggestionCell={organizationOptionSelectHandler}
                                // setSearchRequest={(opt: IComboBoxOption) => setFilterValue(opt?.text)}
                                searchRequest={organizationsOptions?.find(i => i.key === selectedOrganizationOption?.key)?.text}
                                suggestionsListWidth={430}
                                inputBoxWidth={'100%'}
                                maxHeigth={320}
                                disabled={!organizationsOptions || organizationsOptions.length === 0}
                                suggestions={organizationsOptions ?? []}
                                suggestionsListId={organizationsCalloutListId}
                                isSearchEqualTheOneSuggestion={false}
                                onRenderSuggestionCell={onRenderSuggestionsCell}
                                prepareFilteredItems={prepareFilteredOptions}
                                selectedKey={selectedOrganizationOption?.key.toString()}
                            />
                        )}
                    <Stack verticalAlign='center' tokens={{ childrenGap: 8 }} horizontal horizontalAlign='end'>
                        <PrimaryButton disabled={!selectedOrganizationOption?.key} onClick={onaddOrganization} text={strings.BUTTONS.TEXT.CONFIRM} />
                        <SecondaryButton onClick={props.onDismiss} text={strings.BUTTONS.TEXT.CANCEL} />
                    </Stack>
                </Stack>
            </div>
        </Modal>
    )
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

