import { FontIcon, Image, ImageFit, MessageBar, MessageBarButton, MessageBarType, ProgressIndicator, Separator, Spinner, Stack, Text } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import unknown from '../../../../../../assets/unknown.png';
import usb from '../../../../../../assets/usb.gif';
import IdentificationHeader from './ui/IdentificationHeader';
import { DeviceAttributes, PiceaConnectionStatuses  } from '@piceasoft/core';
import { IDevice, IIdentificationPiceaUsbConfig } from '@piceasoft/core';
import { strings } from '../../../../../../localization/strings';
import { IPicea } from '../typings/IPicea';
import { TooltipHost, css, mergeStyleSets, TooltipOverflowMode } from '@fluentui/react';
import { defaultPiceaUsbRowSize, identificationContainerStyle, hasBlockLevelElements, blockOffset } from '../helpers/identificationContainer';

type TProps = {
    config?: IIdentificationPiceaUsbConfig
    picea: IPicea,
    device?: IDevice
    warning?: string
}

const PiceaUsb: React.FC<TProps> = ({ picea, device, warning, ...props }) => {
    return (
        <Stack style={{ maxHeight: 380 }} verticalFill horizontalAlign="stretch">
            <Stack.Item>
                <IdentificationHeader
                    icon={props.config?.ui?.icon ?? "Plug"}
                    title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_VIA_CABLE}
                />
            </Stack.Item>
            <Separator />
            <Stack.Item verticalFill>
                {picea === undefined && <Unavailable />}
                {picea && picea.status === "available" && picea.connections.usb.status === PiceaConnectionStatuses.Connected && <Information device={device} warning={warning} config={props.config} />}
                {picea && picea.status === "available" && picea.connections.usb.status === PiceaConnectionStatuses.Connecting && <Connecting />}
                {picea && picea.status === "available" && picea.connections.usb.status === PiceaConnectionStatuses.Disconnected && <Disconnected />}
                {picea && picea.status === "available" && picea.connections.usb.status === PiceaConnectionStatuses.Failed && <Fail message={picea.connections.usb.error?.message} />}
                {picea && picea.status === "fail" && <Fail message={picea.error?.message} />}
                {picea && picea.status === "loading" && <Loading />}
            </Stack.Item>
        </Stack>
    );
}

export default PiceaUsb

const childrenGap = 24;

const Unavailable: React.FC = () => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <FontIcon iconName="AlertSettings" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray10 }} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <MessageBar messageBarType={MessageBarType.warning}>
                    {strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_NO_ACCESS_TO_SERVICE} <b>Picea® Online Services</b>.
                </MessageBar>
            </Stack.Item>
        </Stack>
    )
}

const Information: React.FC<{ device?: IDevice, warning?: string, warningCode?: number, config?: IIdentificationPiceaUsbConfig }> = (props) => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item>
            <TooltipHost
                overflowMode={TooltipOverflowMode.Self}
                content={props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION}
                hostClassName={css(identificationContainerStyle(defaultPiceaUsbRowSize).body)}
                styles={{ root: {display: 'block'}}}>
                <Text style={{ color: SharedColors.gray40 }}>
                    <div 
                        style={hasBlockLevelElements(props.config?.ui?.information) ? {marginTop: `${blockOffset}`} : {marginTop: '0px'}}
                        dangerouslySetInnerHTML={{ __html: props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAUSB_DEVICE_CONNECT_INFORMATION }}>
                    </div>
                </Text>
            </TooltipHost>
            </Stack.Item>
            <Stack.Item verticalFill>
                {props.device && (
                    <Stack verticalFill>
                        <Stack.Item>
                            <Stack verticalFill horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 12 }}>
                                <Image width={96} height={96} imageFit={ImageFit.contain} maximizeFrame={true}
                                    src={props.device.attributes[DeviceAttributes.Image] ?? unknown} alt="Device Image" />
                                <Text variant="mediumPlus" style={{ color: SharedColors.cyanBlue10 }}>{props.device.manufacturer} {props.device.name} {props.device.configuration}</Text>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <ProgressIndicator />
                        </Stack.Item>
                    </Stack>
                ) || (
                        <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                            <Image src={usb} style={{ width: "100%", marginLeft: 20 }} />
                        </Stack>
                    )}
            </Stack.Item>
            {props.warning && (
                <Stack.Item>
                    <MessageBar messageBarType={MessageBarType.warning}>
                        {props.warning}
                    </MessageBar>
                </Stack.Item>
            ) || (
                <Stack.Item>
                    <MessageBar>
                        {strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_MESSAGE_BAR}
                    </MessageBar>
                </Stack.Item>
            )}
        </Stack>
    )
}

const Connecting: React.FC<{ warning?: string }> = (props) => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <FontIcon iconName="Processing" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray10 }} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <ProgressIndicator label="Picea® Online Services" description={strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CONNECTION_TO_SERVICES} />
            </Stack.Item>
        </Stack>
    )
}

const Disconnected: React.FC = () => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <FontIcon iconName="AlertSettings" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray10 }} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <MessageBar messageBarType={MessageBarType.warning} actions={<MessageBarButton>{strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_REPEAT}</MessageBarButton>}>
                    {strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CANT_CONNECT} <b>Picea® Online Services</b>.
                </MessageBar>
            </Stack.Item>
        </Stack>
    )
}

const Fail: React.FC<{ message?: string }> = (props) => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <FontIcon iconName="AlertSettings" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.red10 }} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <MessageBar messageBarType={MessageBarType.error} actions={<MessageBarButton >{strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_REPEAT}</MessageBarButton>}>
                    {props.message ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.MESSAGE_UNKNOWN_ERROR}
                </MessageBar>
            </Stack.Item>
        </Stack>
    )
}

const Loading: React.FC = () => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <Spinner label={strings.SPINNERS.MODULE_LOADING} />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}
