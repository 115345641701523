import {
    PivotItem, IRenderFunction, IPivotItemProps, Text,
    TooltipHost, DirectionalHint, Icon, Stack, IconButton,
    Pivot, IIconProps, getTheme, IStyleFunctionOrObject,
    IPivotStyleProps, IPivotStyles, SharedColors, ScrollablePane
} from "@fluentui/react"
import { Dispatch, useState } from "react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../../core/store"
import { AIGradingUploadMethods } from '@piceasoft/core'
import { getLocalizedInspectionName, getLocalizedInspectionModeName } from "../../../../../../../localization/helpers/transaction"
import { strings } from "../../../../../../../localization/strings"
import { DefaultModeCommonPivot } from "./pivots/DefaultModeCommonPivot"
import { DefaultModeComponentUIPivot } from "./pivots/DefaultModeComponentUIPivot"
import { DefaultModeModalUIPivot } from "./pivots/DefaultModeModalUIPivot"
import { IAIGradingConfig, Inspections, ProcessStages } from "@piceasoft/core"
import { CSHHelpLink } from "../../../../../help/CSHHelp"
type TProps = {
    index: number
    stage: ProcessStages
    onDissmiss: () => void
    onChangeDispatch: Dispatch<any>
}

type TAIGradingModeConfiguratorPivotState = "common" | "ui" | "component_ui"

export const AIGradingModeConfigurator: React.FC<TProps> = ({ stage, index, onDissmiss, onChangeDispatch }) => {
    const aigrading = useSelector((s: IStore) => s.configurator.assessment?.modules.find(i => i.index === index)?.config as IAIGradingConfig)
    const [pivotState, setPivotState] = useState<TAIGradingModeConfiguratorPivotState>("common")

    const getTabId = (itemKey: string) => {
        return `AIGradingModeConfiguratorPivot_${itemKey}`;
    };

    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(item?.props.itemKey! as TAIGradingModeConfiguratorPivotState);
    };

    const getPivotHeaderText = (m: string): string => {
        switch (m) {
            case "common": return strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.MODE_CONFIGURATOR.PIVOTS.COMMON.TITLE
            case "ui": return strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.MODE_CONFIGURATOR.PIVOTS.UI.TITLE
            case "component_ui": return strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.MODE_CONFIGURATOR.PIVOTS.COMPONENT_UI.TITLE
            default: return m;
        }
    };

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Icon
                        iconName={iProps?.itemIcon}
                        style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
                </TooltipHost>
            </span>
        )
    };

    return (
        <Stack verticalFill grow>
            <Stack horizontal grow style={{ padding: 8 }}>
                <Stack style={{ padding: 8 }} grow tokens={{ childrenGap: 4 }}>
                    <Text variant="xLarge">
                        {aigrading?.ui?.title ?? getLocalizedInspectionName(Inspections.AIGrading)}
                    </Text>
                    <Text variant="small">
                        {getLocalizedInspectionModeName(Inspections.AIGrading, aigrading as IAIGradingConfig)}
                    </Text>
                </Stack>
                <Stack horizontal style={{ alignItems: "center" }}>
                        <CSHHelpLink articleid="1130"/>
                        <IconButton onClick={onDissmiss} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                    </Stack>
            </Stack>
            <Stack.Item tokens={{ padding: "0px 16px 0px 16px" }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                <Pivot styles={pivotStyles} selectedKey={pivotState} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                    <PivotItem
                        itemIcon={'Settings'}
                        headerText={getPivotHeaderText("common")}
                        onRenderItemLink={onRenderItemLink}
                        itemKey="common"
                    />
                    <PivotItem
                        itemIcon={'WindowEdit'}
                        headerText={getPivotHeaderText("ui")}
                        onRenderItemLink={onRenderItemLink}
                        itemKey="ui"
                    />
                    {(aigrading.config.uploadMethods.find(i => [AIGradingUploadMethods.Camera, AIGradingUploadMethods.Remote].includes(i)) !== undefined) && (
                        < PivotItem
                            itemIcon={'BrowserScreenShot'}
                            headerText={getPivotHeaderText("component_ui")}
                            onRenderItemLink={onRenderItemLink}
                            itemKey="component_ui"
                        />
                    )}
                </Pivot>
            </Stack.Item>
            <Stack.Item verticalFill style={{ position: 'relative' }}>
                <ScrollablePane>
                    <PivotStateBase
                        index={index}
                        stage={stage}
                        pivotState={pivotState}
                        aigrading={aigrading}
                        onChangeDispatch={onChangeDispatch}
                    />
                </ScrollablePane>
            </Stack.Item>
        </Stack>
    )
}

type TPivotStateBaseProps = {
    index: number,
    stage: ProcessStages
    aigrading: IAIGradingConfig,
    pivotState: TAIGradingModeConfiguratorPivotState,
    onChangeDispatch: Dispatch<any>
}

const PivotStateBase = (props: TPivotStateBaseProps) => {
    let { index, aigrading, pivotState, onChangeDispatch } = props
    switch (pivotState) {
        case "common":
            return (
                <DefaultModeCommonPivot
                    index={index}
                    stage={props.stage}
                    aigrading={aigrading}
                    onChangeDispatch={onChangeDispatch}
                />
            )
        case "ui":
            return (
                <DefaultModeModalUIPivot
                    index={index}
                    stage={props.stage}
                    aigrading={aigrading}
                    onChangeDispatch={onChangeDispatch}
                />
            )
        case "component_ui":
            return (
                <DefaultModeComponentUIPivot
                    index={index}
                    stage={props.stage}
                    aigrading={aigrading}
                    onChangeDispatch={onChangeDispatch}
                />
            )
        default: return <></>
    }
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

let theme = getTheme()

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}
