import { Stack, ScrollablePane, AnimationStyles } from '@fluentui/react';
import * as React from 'react';
import { IPerson, IContainer, IPoint } from '../../../core/store';
import { StructureContainer } from './Elements/StructureContainer/StructureContainer';
import { StructureNodeTypes } from './Elements/StructureNodeTypes';
import { StructurePerson } from './Elements/StructurePerson/StructurePerson';
import { StructurePoint } from './Elements/StructurePoint/StructurePoint';
import { TStructureItemData } from './OrganizationStructure';

type TProps = {
    item: TStructureItemData
    onEdit: () => void
    onDelete: () => void
    updateTreeItemData: (data: IPoint | IContainer | IPerson) => void
    updateState: (data: IPoint | IContainer | IPerson) => void
}

export const StructureElementDetails: React.FC<TProps> = (props) => {

    return (
        <Stack grow verticalFill styles={{ root: { position: "relative", ...AnimationStyles.scaleUpIn100 } }}>
            <ScrollablePane>
                {props.item.type === StructureNodeTypes.Container && (
                    <StructureContainer item={props.item.data as IContainer} onEdit={props.onEdit} onDelete={props.onDelete} />
                )}
                {props.item.type === StructureNodeTypes.Point && (
                    <StructurePoint point={props.item.data as IPoint} onEdit={props.onEdit} onDelete={props.onDelete} updateState={props.updateState} />
                )}
                {props.item.type === StructureNodeTypes.Person && (
                    <StructurePerson person={props.item.data as IPerson} onEdit={props.onEdit} onDelete={props.onDelete} updateState={props.updateState} />
                )}
            </ScrollablePane>
        </Stack>
    );
}
