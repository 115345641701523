import { FC } from "react";
import { getTheme, IconButton, Stack, Text, MessageBar, MessageBarType, SharedColors } from "@fluentui/react";
import { strings } from "../../../../../localization/strings";
import { IConfigErrors, ISelfServiceWorkflowConfig } from "@piceasoft/core";


interface IProps {
    config: ISelfServiceWorkflowConfig
    showOfferSettings: (offerProviderCode?: string) => void
}

const SelfServiceRepairOfferItems: FC<IProps> = ({ config, showOfferSettings }) => {
    return (
        <Stack.Item styles={{
            root: {
                color: theme.palette.black,
                position: "relative",
                width: "100%",
            }
        }}>
            <Stack tokens={{ childrenGap: 16 }}>
                {
                    config.repairOffers.providers?.map((co, index) => (
                        <OfferItem key={index} title={co.name}
                            errors={co.errors}
                            onConfig={() => showOfferSettings(co.code)}
                        />
                    ))
                }
            </Stack>
        </Stack.Item>
    );
};
type TOfferItemProps = {
    title: string
    description?: string
    config?: ISelfServiceWorkflowConfig
    errors?: IConfigErrors[]
    onConfig: () => void
}

export const OfferItem: React.FC<TOfferItemProps> = (props) => {
    const errors = props.errors ?? [] 

    let errorListRender: JSX.Element = (
        <div style={{ display: "inline" }}>
            <ul style={{ paddingLeft: 16 }}>
                {errors?.map((error, index) => (
                    <li key={index}>{error.text}</li>
                ))}
            </ul>
        </div>
    )
    const renderErrors = (
        <>
            {errors && errors.length > 0 && (
                <MessageBar isMultiline={true} messageBarType={MessageBarType.severeWarning}>
                    <b style={{ width: "100%" }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.NOTES_ON_SETTINGS_MODULE}</b> {errorListRender}
                </MessageBar>
            )}
        </>
    )

    return (
        <Stack styles={{
            root: {
                color: theme.palette.black,
                boxShadow: theme.effects.elevation16,
                position: "relative",
            }
        }}>
            <Stack onClick={props.onConfig}
                styles={{
                    root: {
                        padding: "16px",
                        color: theme.palette.black,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor:  errors && errors.length > 0 ? '#ee7676' : theme.palette.neutralQuaternaryAlt,
                        backgroundColor: theme.palette.white,
                        position: "relative",
                        ":hover": {
                            cursor: "pointer",
                            borderColor: errors && errors.length > 0 ? SharedColors.redOrange10 : theme.palette.themePrimary,
                            backgroundColor: errors && errors.length > 0 ? theme.palette.neutralLighter : theme.palette.themeLighterAlt
                    }
                    }
                }}>
                <Stack.Item>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                        <Text styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{props.title}</Text>
                    </Stack>
                </Stack.Item>
            </Stack>
            <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0, zIndex: 100 } }}>
                <IconButton onClick={props.onConfig} iconProps={{ iconName: "Repair" }} styles={{
                    root: {
                        width: 36,
                        height: 36,
                        backgroundColor: theme.palette.white,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: errors && errors.length > 0 ? '#ee7676' : theme.palette.neutralQuaternaryAlt,
                        borderRadius: "50%",
                        color: errors && errors.length > 0 ? SharedColors.redOrange10 : theme.palette.black,
                        boxShadow: theme.effects.elevation8,
                        ":hover": {
                            borderColor: errors && errors.length > 0 ? SharedColors.redOrange10 : theme.palette.themePrimary,
                            backgroundColor: errors && errors.length > 0 ? theme.palette.neutralLighter : theme.palette.themeLighterAlt
                        }
                    },
                    flexContainer: {
                        borderRadius: "50%",
                        ":hover": {
                            borderColor: theme.palette.themePrimary,
                            backgroundColor: theme.palette.themeLighterAlt
                        }
                    },
                    iconHovered: {
                        color: errors && errors.length > 0 ? SharedColors.redOrange10 : theme.palette.black
                    }
                }} />
            </Stack>
            <Stack.Item>
                {renderErrors}
            </Stack.Item>            
        </Stack>
    )
}
export default SelfServiceRepairOfferItems;

const theme = getTheme();
