import { getTheme, IStackStyles, PrimaryButton, Separator, Stack, Text } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { strings } from '../../../../../../localization/strings';
import { IIdentificationScannerConfig } from '@piceasoft/core';
import { ScannerIdentificationTypes } from '@piceasoft/core';
import IdentificationHeader from './ui/IdentificationHeader';

type TProps = {
    config?: IIdentificationScannerConfig
}

export const ScannerComponent: React.FC<TProps> = (props) => {
    switch (props.config?.type) {
        case ScannerIdentificationTypes.Imei:
            return (
                <Stack horizontalAlign="stretch" verticalFill>
                    <Stack.Item>
                        <IdentificationHeader
                            noHelp
                            icon={""}
                            title={props.config?.ui?.componentText?.imei?.header ?? strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.HEADER}
                        />
                    </Stack.Item>
                    <Separator />
                    <Stack verticalFill tokens={{ childrenGap: 16 }}>
                        <Stack verticalFill tokens={{ childrenGap: 16 }}>
                            <Stack.Item>
                                <Text variant='mediumPlus' style={{ color: SharedColors.gray30 }}>{props.config?.ui?.componentText?.imei?.description ?? strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.DESCRIPTION}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text style={{ color: SharedColors.gray40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: props.config?.ui?.componentText?.imei?.information ?? strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.INFORMATION }}></div>
                                </Text>
                            </Stack.Item>
                        </Stack>
                        <Stack.Item grow>
                            <Stack horizontalAlign="stretch">
                                <PrimaryButton text={strings.IDENTIFICATION.SCANNER.COMPONENT.BUTTONS.SCAN} />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack>
            )
        case ScannerIdentificationTypes.Label:
            return (
                <Stack horizontalAlign="stretch" verticalFill>
                <Stack.Item>
                    <IdentificationHeader
                        noHelp
                        icon={""}
                        title={props.config?.ui?.componentText?.label?.header ?? strings.IDENTIFICATION.SCANNER.COMPONENT.IMEI.HEADER}
                    />
                </Stack.Item>
                <Separator />
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    <Stack verticalFill tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <Text variant='mediumPlus' style={{ color: SharedColors.gray30 }}>{props.config?.ui?.componentText?.label?.description ?? strings.IDENTIFICATION.SCANNER.COMPONENT.LABEL.DESCRIPTION }</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text style={{ color: SharedColors.gray40 }}>
                                <div dangerouslySetInnerHTML={{ __html: props.config?.ui?.componentText?.label?.information ?? strings.IDENTIFICATION.SCANNER.COMPONENT.LABEL.INFORMATION }}></div>
                            </Text>
                        </Stack.Item>
                    </Stack>
                    <Stack.Item grow>
                        <Stack horizontalAlign="stretch">
                            <PrimaryButton text={strings.IDENTIFICATION.SCANNER.COMPONENT.BUTTONS.SCAN} />
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
/*                 <Stack horizontalAlign="stretch" verticalFill>
                    <Stack verticalFill verticalAlign='center' tokens={{padding: 16}}>
                        <Stack horizontalAlign='center' styles={containerStyles}>
                            <Text style={{ color: theme.palette.themePrimary }}>{props.config.ui?.componentText?.label?.cameraHint ?? strings.IDENTIFICATION.SCANNER.COMPONENT.LABEL.CAMERA_HINT}</Text>
                        </Stack>
                    </Stack>
                </Stack> */
            )
        default: return <></>
    }
}

const theme = getTheme();
const containerStyles: IStackStyles = { root: { padding: "16px 32px", backgroundColor: theme.palette.neutralLight, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 } };