import { PrimaryButton, ScrollablePane, Stack, Text } from '@fluentui/react';
import * as React from 'react'
import { portalApi } from '../../../../../../../../../../core/api/api';
import { strings } from '../../../../../../../../../../localization/strings';
import { SecondaryButton } from '../../../../../../../../buttons/SecondaryButton';
import Dropzone from '../../../../../../../../dropzone/Dropzone';
import { UploadFileCard } from '../../../../../../../../dropzone/UploadFileCard';

type TProps = {
    images?: string[]
    onConfirm: (images?: string[]) => void
}

let fileIndex = 0;

export type TUploadFileType = { index: number, imgUrl?: string, loadProgress?: number, data?: File, fail?: boolean }

export const SequenceQuestionImagesForm: React.FC<TProps> = ({ images, onConfirm }) => {

    const prepareImages = (): TUploadFileType[] => {
        return images?.map(i => {
            const file: TUploadFileType = {
                index: fileIndex,
                imgUrl: i
            }
            fileIndex++;
            return file;

        }) ?? []
    }

    const AllowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif"]
    const [files, setFiles] = React.useState<TUploadFileType[]>(prepareImages())
    const [fileState, setFileState] = React.useState<TUploadFileType>()

    React.useEffect(() => {
        setFiles(prepareImages())
    }, [images])

    React.useEffect(() => {
        setFiles(files.map(f => {
            if (f.index === fileState?.index) {
                return {
                    ...f,
                    ...fileState
                }
            }
            return f
        }))
    }, [fileState])

    const onUploadFile = (file: TUploadFileType) => {
        if (file.data) {
            portalApi.organization.configurator.uploadFile("question-answers-images", file.data, (result) => setFileState({ ...file, imgUrl: result.data, fail: !result.successed }), (progress) => setFileState({ ...file, loadProgress: progress }))
        }
    }

    const addFiles = (upFiles: FileList) => {
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => AllowedFileTypes.includes(upFile.type))]
        if (checkedUpFiles) {
            setFiles([
                ...files,
                ...(checkedUpFiles.map(upFile => {
                    const file: TUploadFileType = {
                        index: fileIndex,
                        data: upFile
                    }
                    fileIndex++;
                    onUploadFile(file)
                    return file;
                }))
            ])
        }
    }

    const moveUpFile = (index: number) => index > 0 && setFiles([...files.map((f, fIndex) => {
        if (fIndex === index - 1) {
            return files[index];
        }
        if (fIndex === index) {
            return files[index - 1];
        }
        return f
    })])


    const deleleFile = (index: number) => setFiles([...files.filter((f) => f.index !== index)])

    return (
        <Stack verticalFill>
            <Stack.Item>
                <Stack tokens={{ padding: 20 }} horizontal grow>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                        <SecondaryButton iconProps={{ iconName: "Undo" }} onClick={() => setFiles(prepareImages())} />
                        <PrimaryButton disabled={files.find(i => !i.imgUrl) ? true : false} onClick={() => onConfirm(files.length > 0 ? files.map(i => i.imgUrl) as string[] : undefined)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                    </Stack>
                </Stack>
            </Stack.Item>
            <Stack verticalFill style={{ position: 'relative' }}>
                <ScrollablePane>
                    <Stack.Item tokens={{ padding: "0px 20px 20px 20px" }}>
                        <Dropzone minHeight={320} filesLenght={files.length} handleDrop={addFiles}>
                            <Stack horizontal wrap tokens={{ padding: 16, childrenGap: 8 }}>
                                {files?.map((f, index) =>
                                    <UploadFileCard
                                        key={f.index}
                                        status={strings.SPINNERS.LOADING}
                                        moveUpFile={index > 0 ? () => moveUpFile(index) : undefined}
                                        retryFile={f.fail ? () => onUploadFile({ ...f, fail: undefined }) : undefined}
                                        deleteFile={() => deleleFile(f.index)}
                                        iconName={'FileImage'}
                                        imgUrl={f.imgUrl ? f.imgUrl + "-preview" : undefined}
                                        loadProgress={f.loadProgress}
                                    />
                                )}
                            </Stack>
                        </Dropzone>
                    </Stack.Item>
                </ScrollablePane>
            </Stack>
        </Stack>
    )
}