import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";
import { ParameterItem } from "../../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { ProcessStages, IPostOfferConfig } from "@piceasoft/core";

type TProps = {
    config?: IPostOfferConfig
    onChangeDispatch: Dispatch<any>
    channel: number
}

export const PostOfferStageCommonUIPivot: React.FC<TProps> = ({ onChangeDispatch, config, channel }) => {
    if (!config) return null;

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.POST_OFFER.COMMON_UI.SUBTITLE_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PostOffer, 'Step UI', 'Custom post-offer stage subtitle')}
                toggleChecked={config.ui?.header !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                        ...config,
                        ui: {
                            ...config.ui,
                            header: config.ui?.header !== undefined ? undefined : ""
                        }
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.POST_OFFER.COMMON_UI.SUBTITLE_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.header !== undefined ? true : false}
                textFieldValue={config.ui?.header}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                    ...config,
                    ui: {
                        ...config.ui,
                        header: val
                    }
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.POST_OFFER.COMMON_UI.INFORMATION_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PostOffer, 'Step UI', 'Custom information for the post-offer stage')}
                toggleChecked={config.ui?.information !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                        ...config,
                        ui: {
                            ...config.ui,
                            information: config.ui?.information !== undefined ? undefined : ""
                        }
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.POST_OFFER.COMMON_UI.INFORMATION_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.information !== undefined ? true : false}
                textFieldValue={config.ui?.information}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.postOffer.onConfigCommit({
                    ...config,
                    ui: {
                        ...config.ui,
                        information: val
                    }
                }))}
            />
        </Stack>
    )
}