import { getTheme, IIconProps, mergeStyleSets, FontWeights, IconButton, Separator, Stack, Text, DefaultButton, PrimaryButton, SharedColors, Toggle } from '@fluentui/react';
import * as React from 'react';
import { IInterviewListConfig, IQuestion } from "@piceasoft/core"
import { strings } from '../../../../../../../localization/strings';

type TProps = {
    questions: IQuestion[]
    config: IInterviewListConfig
    headerText: string
}

export const ListQuestionsPreview: React.FC<TProps> = (props) => {

    const confirmHandler = () => {
    }

    const cancelHandler = () => {
    }

    const items = props.config.questions.map((i) => {
        return (
            <Stack key={i.index}>
                <Stack horizontal verticalAlign="center">
                    <Stack.Item grow>
                        <Stack>
                            <Text variant="mediumPlus"><strong>{i.title}</strong></Text>
                            {props.config.allowDescription && <Text variant="small" style={{ color: SharedColors.gray30 }}>{i.text}</Text>}
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle offText={i.answers[0]?.text} onText={i.answers[1]?.text}></Toggle>
                    </Stack.Item>
                </Stack>
                <Separator />
            </Stack>
        )
    })

    const onRenderBodyContent = () => {
        return (
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <Stack tokens={{ childrenGap: 8 }} style={{ paddingBottom: 24 }}>
                        <Text style={{ color: SharedColors.gray40 }}>{props.config.information ?? strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.LIST_ANSWER_QUESTIONS}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack>
                        {items}
                    </Stack>
                </Stack.Item>
            </Stack>
        )
    }

    const onRenderFooterContent = () => {
        return (
            <Stack verticalAlign="center">
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 4 }}>
                    <PrimaryButton style={{ width: 144 }} text={strings.BUTTONS.TEXT.CONFIRM} onClick={confirmHandler} />
                    <DefaultButton style={{ width: 144 }} text={strings.BUTTONS.TEXT.CANCEL} onClick={cancelHandler} />
                </Stack>
            </Stack>
        )
    }

    return (
        <>
            {props.config.useFullscreen && (
                <Stack verticalFill grow className={contentStyles.panel} tokens={{ padding: "64px 12px 32px 48px" }} >
                    <Stack tokens={{ padding: "16px 0px 0px 0px" }} >
                        <Stack horizontal grow tokens={{ childrenGap: 16 }}>
                            <Stack.Item grow tokens={{ padding: "0px 16px" }}>
                                <Text variant="xLarge">{props.headerText}</Text>
                            </Stack.Item>
                            <Stack.Item tokens={{ padding: "0px 16px" }}>
                                <IconButton disabled={true} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                            </Stack.Item>
                        </Stack>
                        <Separator />
                    </Stack>
                    <Stack verticalFill tokens={{ childrenGap: 16, padding: "32px" }}>
                        {onRenderBodyContent()}
                    </Stack>
                    <Stack.Item>
                        <Stack horizontalAlign='end' tokens={{ childrenGap: 16, padding: "32px" }}>
                            {onRenderFooterContent()}
                        </Stack>
                    </Stack.Item>
                </Stack>) || (
                    <Stack verticalFill verticalAlign='center'>
                        <Stack className={contentStyles.container}>
                            <Stack horizontal grow tokens={{ padding: '12px 12px 14px 24px', childrenGap: 24 }}
                                style={{
                                    borderTop: `4px solid ${theme.palette.themePrimary}`,
                                    color: theme.palette.neutralPrimary,
                                }}
                            >
                                <Stack.Item grow>
                                    <Text variant='xLargePlus' style={{ fontWeight: FontWeights.semibold }}>{props.headerText}</Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <IconButton disabled={true} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                                </Stack.Item>
                            </Stack>
                            <div className={contentStyles.body}>
                                <Stack tokens={{ childrenGap: 16 }}>
                                    {onRenderBodyContent()}
                                    {onRenderFooterContent()}
                                </Stack>
                            </div>
                        </Stack>
                    </Stack>
                )}
        </>
    )

}

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const contentStyles = mergeStyleSets({
    panel: {
        width: '640px',
        maxWidth: '640px',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        // boxShadow: theme.effects.elevation64,
        backgroundColor: theme.palette.white
    },
    container: {
        width: '640px',
        maxWidth: '640px',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        // boxShadow: theme.effects.elevation64,
        backgroundColor: theme.palette.white
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        heigth: '100%',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};