import { DirectionalHint, getFocusStyle, getTheme, IButtonStyles, Icon, IconButton, IContextualMenuProps, List, mergeStyleSets, SharedColors, Stack, Text } from "@fluentui/react"
import React from "react"
import { ICatalog } from "../../../core/store"
import { strings } from "../../../localization/strings"

type TProps = {
    items: {isSelected: boolean, data: any}[]
    setSelectedKey: (key: string) => void
    selectedKey: string
    getItemId?: (item?: any) => string
    onDelete: (item: any) => void
    onEdit: (item: any) => void
}

export const ItemsList: React.FC<TProps> = (props) => {

    const getItemMenuProps = (item: ICatalog, onDelete: (item: ICatalog) => void, onEdit: (item: ICatalog) => void): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => (props.getItemId ? props.getItemId(item) : item.id) ? onEdit(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => (props.getItemId ? props.getItemId(item) : item.id) ? onDelete(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const prepareCellStyle = (item?: any) => {
        const currentColor = (props.getItemId ? props.getItemId(item) : item?.id) === props.selectedKey ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 0,
                    boxSizing: 'border-box',
                    background: (props.getItemId ? props.getItemId(item) : item?.id) === props.selectedKey && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderCell = React.useCallback((item?: {isSelected: boolean, data: any}, index?: number) => {
        const itemId = props.getItemId ? props.getItemId(item?.data) : item?.data.id
        return (
            <div key={`${item?.data.id}-${index}` ?? ""} onClick={() => props.setSelectedKey(itemId)} className={prepareCellStyle(item?.data).itemCell}>
                <Stack horizontal verticalAlign='center' grow tokens={{ childrenGap: 8 }} className={item?.data.id === props.selectedKey ? classNames.activeListItem : classNames.listItem}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {itemId === props.selectedKey && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item grow>
                        <Text block nowrap variant="medium" style={{ width: 236, color: SharedColors.gray40 }}>{item?.data.name}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        {item && <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} style={{ height: 24, width: 24 }} styles={menuIconStyles} menuProps={getItemMenuProps(item.data, props.onDelete, props.onEdit)} />}
                    </Stack.Item>
                </Stack>
            </div >
        );
    },[props.selectedKey])

    return (
        <List
            items={props.items}
            onRenderCell={onRenderCell}
            getKey={(item) => props.getItemId ? props.getItemId(item) : item?.data.id}
        />
    )
}

const theme = getTheme();

const classNames = mergeStyleSets({
    listItem: [
        {
            padding: 4,
            borderLeft: '2px solid transparent',
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        },
    ],
    activeListItem: [
        {
            padding: 4,
            background: 'rgba(222,236,249,1)',
            borderLeft: `2px solid ${theme.palette.themePrimary}`,
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        }
    ]
});

const menuIconStyles: IButtonStyles = {
    root: {
        padding: 0
    },
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}