import { getTheme, SharedColors, Stack, Text, Toggle, Image, ImageFit } from "@fluentui/react";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../core/scripts/style";
import { strings } from "../../../localization/strings";
import defaultServiceIcon from '../../../assets/service.svg'

type TCompanyServiceProps = {
    enabled?: boolean
    name: string
    description?: string
    onToggleServiceState: () => void
    isToggleDisabled?: boolean
    withoutToggle?: boolean
    generateIcon?: boolean
    imageSrc?: string
}

export const CompanyService: React.FC<TCompanyServiceProps> = (props) => {
    return (
        <Stack.Item>
            <Stack horizontal>
                <Stack horizontal verticalAlign='center' verticalFill style={{ width: 600 }} tokens={{ childrenGap: 12 }} >
                    <Stack.Item>
                        <Stack verticalAlign="center"
                            horizontalAlign="center"
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: theme.effects.roundedCorner6,
                                backgroundColor: !props.imageSrc && props.generateIcon ?
                                    (props.name ?
                                        getPersonaColorsPallete[props.name[0].toLowerCase()] :
                                        undefined) :
                                    undefined
                            }}>
                            {props.imageSrc && (
                                <Stack horizontalAlign='center'>
                                    <Image src={props.imageSrc} width={40} height={40} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                </Stack>
                            ) || (props.generateIcon && (
                                <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                                    {getIconSymbolsFromString(props.name)}
                                </Text>
                            ) || (
                                    <Stack horizontalAlign='center'>
                                        <Image src={defaultServiceIcon} width={40} height={40} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                    </Stack>
                                )
                                )}
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack>
                            <Stack.Item>
                                <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{props.name}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant="small" style={{ color: SharedColors.gray30 }}>{props.description}</Text>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
                {!props.withoutToggle && <Stack>
                    <Toggle disabled={props.isToggleDisabled} checked={props.enabled} onText={strings.BUTTONS.TOGGLE_ENABLE} offText={strings.BUTTONS.TOGGLE_DISABLE} onChange={props.onToggleServiceState} />
                </Stack>}
            </Stack>
        </Stack.Item>
    )
}

const theme = getTheme();