import { SharedColors } from "@fluentui/react"
import  { ModerationStatuses, InspectionStatuses } from "@piceasoft/core"
export const getModerationColor = (status: string): string => {
    switch (status) {
        case ModerationStatuses.request:
            return SharedColors.orangeYellow10
        case ModerationStatuses.moderation:
            return SharedColors.yellow10
        case ModerationStatuses.queue:
            return SharedColors.cyanBlue10
        case ModerationStatuses.completed:
            return SharedColors.green10
        case ModerationStatuses.denied:
            return SharedColors.red10
        case ModerationStatuses.none:
            return SharedColors.gray20
        default: return ""
    }
}

export const getInspectionStatusBadgeTheme = (status: InspectionStatuses): { iconName: string, iconBackgroundColor: string, isFilled?: boolean } => {
    switch (status) {
        case InspectionStatuses.Done:
            return { iconName: "CompletedSolid", isFilled: true, iconBackgroundColor: SharedColors.green10 }
        case InspectionStatuses.Error:
            return { iconName: "AlertSolid", isFilled: true, iconBackgroundColor: SharedColors.red20 }
        case InspectionStatuses.Fail:
            return { iconName: "Blocked2Solid", isFilled: true, iconBackgroundColor: SharedColors.red20 }
        case InspectionStatuses.Lock:
            return { iconName: "BlockedSolid", isFilled: true, iconBackgroundColor: SharedColors.gray10 }
        case InspectionStatuses.New:
            return { iconName: "CircleAdditionSolid", isFilled: true, iconBackgroundColor: SharedColors.gray10 }
        case InspectionStatuses.Run:
            return { iconName: "MSNVideosSolid", isFilled: true, iconBackgroundColor: SharedColors.gray10 }
        case InspectionStatuses.SkippedByUser:
        case InspectionStatuses.SkippedByCondition:
            return { iconName: "CompletedSolid", isFilled: true, iconBackgroundColor: SharedColors.gray10 }
    }
}

export const getModerationStatusBadgeTheme = (status: ModerationStatuses): { iconName: string, iconBackgroundColor: string, isFilled?: boolean } => {
    switch (status) {
        case ModerationStatuses.request:
            return { iconName: "CompletedSolid", isFilled: true, iconBackgroundColor: SharedColors.orangeYellow10 }
        case ModerationStatuses.moderation:
            return { iconName: "AlertSolid", isFilled: true, iconBackgroundColor: SharedColors.yellow10 }
        case ModerationStatuses.queue:
            return { iconName: "Blocked2Solid", isFilled: true, iconBackgroundColor: SharedColors.cyanBlue10 }
        case ModerationStatuses.completed:
            return { iconName: "BlockedSolid", isFilled: true, iconBackgroundColor: SharedColors.green10 }
        case ModerationStatuses.denied:
            return { iconName: "CircleAdditionSolid", isFilled: true, iconBackgroundColor: SharedColors.red10 }
        case ModerationStatuses.none:
            return { iconName: "MSNVideosSolid", isFilled: true, iconBackgroundColor: SharedColors.gray10 }
    }
}