import { Dropdown, getFocusStyle, getTheme, IComboBoxOption, Icon, MaskedTextField, mergeStyleSets, PrimaryButton, ProgressIndicator, Separator, Stack, Text, TextField } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import React, { useEffect, useState } from 'react';
import { useId } from '@uifabric/react-hooks';
import { useForm, Controller } from 'react-hook-form'
import IdentificationHeader from './ui/IdentificationHeader';
import { strings } from '../../../../../../localization/strings';
import { escapeRegExp } from '../../../../../../assets/ts/utils/regex';
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from '../../../../search/SuggestedSearch';
import { IIdentificationUniversalConfig } from '@piceasoft/core';
import { IDeviceGroup } from '@piceasoft/core';
import { portalApi } from '../../../../../../core/api/api';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../../core/store';
import { processIdSample } from '../../../mock/samples';
import { TooltipHost, TooltipOverflowMode, css } from '@fluentui/react';
import { defaultCustomSearchRowSize, identificationContainerStyle, hasBlockLevelElements, blockOffset } from '../helpers/identificationContainer';
type TProps = {
    config: IIdentificationUniversalConfig
}

type TUniversalFormData = {
    group: string
    manufacturer: string
    configuration: string
    model: string
    serial: string
    imei: string
}

const Universal: React.FC<TProps> = (props) => {

    const { control, reset, watch, formState: { isValid } } = useForm<TUniversalFormData>({
        defaultValues: {
            group: props.config?.useGroup?.items?.find(i => i.code === props.config?.useGroup?.default)?.value,
            manufacturer: props.config?.useManufacturer?.default,
            model: props.config?.useModel?.default,
            serial: props.config?.useSerial?.autoGenerate ? generate(processIdSample, props.config?.useSerial?.mask) : "",
            imei: props.config?.useImei?.autoGenerate ? generate(processIdSample, props.config?.useImei?.mask) : ""
        },
        mode: 'all'
    });

    React.useEffect(() => {
        reset({
            group: props.config?.useGroup?.items?.find(i => i.code === props.config?.useGroup?.default)?.value,
            manufacturer: props.config?.useManufacturer?.default,
            model: props.config?.useModel?.default,
            serial: props.config?.useSerial?.autoGenerate ? generate(processIdSample, props.config?.useSerial?.mask) : "",
            imei: props.config?.useImei?.autoGenerate ? generate(processIdSample    , props.config?.useImei?.mask) : ""
        })
    }, [props.config])

    const formState = watch();

    const [inputGroup, setInputGroup] = React.useState({ key: "", value: "" });

    const [groupOptions, setGroupOptions] = useState<IComboBoxOption[]>()
    const groupHostId = useId("group-host-id")
    const groupTextId = useId("group-text-id")
    const groupCalloutListId = "group-callout-id"

    const [manufacturerOptions, setManufacturerOptions] = useState<IComboBoxOption[]>()
    const [filteredmanufacturerOptionsLenght, setFilteredmanufacturerOptionsLenght] = useState<number>()
    const manufacturerHostId = useId("manufacturer-host-id")
    const manufacturerTextId = useId("manufacturer-text-id")
    const manufacturerCalloutListId = "manufacturer-callout-id"

    useEffect(() => {
        prepareGroupOptions()
        prepareManufacturerOptions()
    }, [])

    useEffect(() => {
        prepareGroupOptions()
    }, [props.config.useGroup?.items])

    useEffect(() => {
        prepareManufacturerOptions()
    }, [props.config.useManufacturer?.items, props.config.useManufacturer?.usePriceSource])

    useEffect(() => {
        if (inputGroup.value !== formState.group) {
            reset({ ...formState, group: inputGroup.value ?? "" })
        }
    }, [inputGroup])

    const prepareGroupOptions = () => {
        console.log(props.config.useGroup?.items)
        if (!props.config?.useGroup?.items || props.config?.useGroup?.items.length === 0) {
            setGroupOptions(undefined)
            return
        }
        let filteredItems: IDeviceGroup[] = [...props.config.useGroup.items]
        if (filteredItems.length > 0) {
            let groupArray: IComboBoxOption[] = [];
            filteredItems.map((i: IDeviceGroup) => {
                let comboOption: IComboBoxOption = { key: i.code, text: i.value };
                groupArray.push(comboOption);
            });
            setGroupOptions(groupArray)
        }
    }

    const groupSelectHandler = (key?: string, value?: string) => {
        if (inputGroup.value !== value) {
            setInputGroup({ key: groupOptions?.find(go => go.text === value)?.key.toString() ?? value ?? "", value: value ?? "" })
        }
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderGroupSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${groupCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(
                    ev,
                    filteredmanufacturerOptionsLenght ??
                    prepareFilteredManufacturerOptions(manufacturerOptions ?? [], formState.manufacturer)?.length ?? 0,
                    manufacturerTextId,
                    manufacturerCalloutListId,
                    manufacturerSelectHandler,
                    index,
                    item?.text,
                    manufacturerHostId)}
                tabIndex={-1}
                onClick={() => {
                    setInputGroup({ key: item?.key.toString() ?? "", value: item?.text ?? "" })
                    focusElement(`${groupCalloutListId}-${index as number}`, groupHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredGroupOptions = (items: IComboBoxOption[], filterValue?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map(i => {
                return ({ key: i.key, text: i.text, selected: i.text === inputGroup.value } as IComboBoxOption)
            })
    }

    const onRenderManufacturerSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${manufacturerCalloutListId}-${index as number}`}
                onKeyDown={() => <></>}
                tabIndex={-1}
                onClick={() => {
                    manufacturerSelectHandler(item?.text)
                    focusElement(`${manufacturerCalloutListId}-${index as number}`, manufacturerHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredManufacturerOptions = (items: IComboBoxOption[], filterValue?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
    }

    const manufacturerSelectHandler = (value?: string) => {
        if (formState.manufacturer !== value) {
            reset({ ...formState, manufacturer: value ?? "" })
        }
    }

    const prepareManufacturerOptions = async () => {
        setManufacturerOptions(undefined)
        if (props.config?.useManufacturer?.usePriceSource) {
            const result = await portalApi.organization.devices.getManufacturers()
            if (result.successed) {
                setTimeout(() => {
                    setManufacturerOptions(result.data?.map(mo => { return { text: mo, key: mo } }))
                }, 100)
            }
        } else if (props.config?.useManufacturer?.items) {
            setManufacturerOptions(props.config?.useManufacturer?.items.map(i => { return { text: i, key: i } }))
        }
    }

    return (
        <form style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack horizontalAlign="stretch" verticalFill>
                <Stack.Item>
                    <IdentificationHeader
                        icon={props.config?.ui?.icon ?? "Edit"}
                        title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_MANUAL_IDENTIFICATION}
                    />
                </Stack.Item>
                <Separator />
                <Stack.Item>
                    <TooltipHost
                        overflowMode={TooltipOverflowMode.Self}
                        content={props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION}
                        hostClassName={css(identificationContainerStyle(defaultCustomSearchRowSize).body)}
                        styles={{ root: {display: 'block'}}}>
                        <Text style={{ color: SharedColors.gray40 }}>
                            <div 
                                style={hasBlockLevelElements(props.config?.ui?.information) ? {marginTop: `${blockOffset}`} : {marginTop: '0px'}}
                                dangerouslySetInnerHTML={{ __html: props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.FREEFORM_INFORMATION }}>
                            </div>
                        </Text>
                    </TooltipHost>
                </Stack.Item>
                <Stack.Item grow={1} verticalFill>
                    <Stack horizontalAlign="stretch">
                        {props.config.useGroup && (
                            <Stack.Item>
                                {groupOptions && !props.config.useGroup.useManualInput && (
                                    <Controller
                                        control={control}
                                        name="group"
                                        rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                        render={({ field }) => {
                                            return <SuggestedSearch
                                                type="dropdown-search"
                                                hostId={groupHostId}
                                                inputBoxId={groupTextId}
                                                required={true}
                                                label={props.config?.ui?.group?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_DEVICE_GROUP}
                                                inputBoxPlaceholder={props.config?.ui?.group?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CHOOSE_DEVICE_GROUP}
                                                onClickSuggestionCell={groupSelectHandler}
                                                // setSearchRequest={groupSelectHandler}
                                                searchRequest={field.value}
                                                suggestions={groupOptions}
                                                suggestionsListId={groupCalloutListId}
                                                suggestionsListWidth={288}
                                                isSearchEqualTheOneSuggestion={false}
                                                onRenderSuggestionCell={onRenderGroupSuggestionsCell}
                                                prepareFilteredItems={prepareFilteredGroupOptions} />;
                                        }
                                        }
                                    />
                                )}
                                {props.config.useGroup.useManualInput && (
                                    <Controller
                                        control={control}
                                        name="group"
                                        rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                        render={({ field }) =>
                                            <TextField label={props.config?.ui?.group?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_DEVICE_GROUP}
                                                placeholder={props.config?.ui?.group?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_DEVICE_GROUP}
                                                value={field.value} onChange={(_, val) => groupSelectHandler(val, val)}
                                                required />
                                        }
                                    />
                                )}
                            </Stack.Item>
                        )}
                        {props.config.useManufacturer && (
                            <Stack.Item>
                                {!props.config.useManufacturer.useManualInput && (
                                    manufacturerOptions && (
                                        <Controller
                                            control={control}
                                            name="manufacturer"
                                            rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                            render={({ field }) =>
                                                <SuggestedSearch
                                                    type="combobox-search"
                                                    hostId={manufacturerHostId}
                                                    inputBoxId={manufacturerTextId}
                                                    required={true}
                                                    label={props.config?.ui?.manufacturer?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_MANUFACTURER}
                                                    inputBoxPlaceholder={props.config?.ui?.manufacturer?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CHOOSE_MANUFACTURER}
                                                    onClickSuggestionCell={manufacturerSelectHandler}
                                                    setSearchRequest={manufacturerSelectHandler}
                                                    searchRequest={field.value}
                                                    suggestions={manufacturerOptions}
                                                    suggestionsListId={manufacturerCalloutListId}
                                                    isSearchEqualTheOneSuggestion={true}
                                                    suggestionsListWidth={288}
                                                    onRenderSuggestionCell={onRenderManufacturerSuggestionsCell}
                                                    prepareFilteredItems={prepareFilteredManufacturerOptions}
                                                    setFilteredItemsLenght={setFilteredmanufacturerOptionsLenght}
                                                    listWithTextfieldItem
                                                />
                                            }
                                        />
                                    ) || (
                                        <ProgressIndicator label={strings.SPINNERS.DATA_IS_LOADING} />
                                    )
                                )}
                                {props.config.useManufacturer.useManualInput && (
                                    <Controller
                                        control={control}
                                        name="manufacturer"
                                        rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                        render={({ field }) =>
                                            <TextField label={props.config?.ui?.manufacturer?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_MANUFACTURER}
                                                placeholder={props.config?.ui?.manufacturer?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_MANUFACTURER}
                                                value={field.value} onChange={(_, val) => manufacturerSelectHandler(val ?? "")}
                                                required />
                                        }
                                    />
                                )}
                            </Stack.Item>
                        )}
                        {props.config.useModel && (
                            <Stack.Item>
                                {props.config.useModel && (
                                    <Controller
                                        control={control}
                                        name="model"
                                        rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                        render={({ field }) =>
                                            <TextField label={props.config?.ui?.name?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_MODEL}
                                                placeholder={props.config?.ui?.name?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_MODEL}
                                                value={field.value} onChange={(_, val) => field.onChange(val)}
                                                required />
                                        }
                                    />
                                )}
                            </Stack.Item>
                        )}
                        {props.config.useConfiguration && (
                            <Stack.Item>
                                {props.config.useConfiguration.items && (
                                    <Controller
                                        control={control}
                                        name="configuration"
                                        rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                        render={({ field }) =>
                                            <Dropdown
                                                label={props.config?.ui?.configuration?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CONFIGURATION}
                                                placeholder={props.config?.ui?.configuration?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CHOOSE_CONFIGURATION}
                                                onChange={(_, val) => field.onChange(props.config?.useConfiguration?.items?.find(i => i.code === val?.key.toString())?.code ?? "")}
                                                defaultValue={undefined}
                                                selectedKey={field.value}
                                                options={props.config?.useConfiguration?.items?.map(i => { return { key: i.code, text: i.name } }) ?? []}
                                                required />
                                        }
                                    />
                                )}
                            </Stack.Item>
                        )}
                        {props.config.useSerial && !props.config.useSerial.hide && (
                            <Stack.Item>
                                {props.config.useSerial.mask && (
                                    <Controller
                                        control={control}
                                        name="serial"
                                        rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                        render={({ field }) =>
                                            <MaskedTextField label={props.config?.ui?.serial?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_SERIAL_NUMBER}
                                                placeholder={props.config?.ui?.serial?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_SERIAL}
                                                mask={props.config?.useSerial?.mask} disabled={props.config?.useSerial?.autoGenerate}
                                                value={field.value} onChange={(_, val) => field.onChange(val)}
                                                onBlur={field.onBlur}
                                                required />
                                        }
                                    />
                                ) || (
                                        <Controller
                                            control={control}
                                            name="serial"
                                            rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                            render={({ field }) =>
                                                <TextField label={props.config?.ui?.serial?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_SERIAL_NUMBER}
                                                    placeholder={props.config?.ui?.serial?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_SERIAL}
                                                    value={field.value} onChange={(_, val) => field.onChange(val)}
                                                    onBlur={field.onBlur}
                                                    disabled={props.config?.useSerial?.autoGenerate} required />
                                            }
                                        />
                                    )}
                            </Stack.Item>
                        )}
                        {props.config.useImei && !props.config.useImei.hide && (
                            <Stack.Item>
                                {props.config.useImei.mask && (
                                    <Controller
                                        control={control}
                                        name="imei"
                                        rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                        render={({ field }) =>
                                            <MaskedTextField label={props.config?.ui?.imei?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_IMEI}
                                                placeholder={props.config?.ui?.imei?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_IMEI}
                                                mask={props.config?.useImei?.mask} disabled={props.config?.useImei?.autoGenerate}
                                                value={field.value} onChange={(_, val) => field.onChange(val)}
                                                required />
                                        }
                                    />
                                ) || (
                                        <Controller
                                            control={control}
                                            name="imei"
                                            rules={{ required: true, validate: (value) => value.trim().length > 0 }}
                                            render={({ field }) =>
                                                <TextField label={props.config?.ui?.imei?.label ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_IMEI}
                                                    placeholder={props.config?.ui?.imei?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_IMEI}
                                                    value={field.value} onChange={(_, val) => field.onChange(val)}
                                                    disabled={props.config?.useImei?.autoGenerate} required />
                                            }
                                        />
                                    )}
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
                <Stack.Item grow>
                    <Stack horizontalAlign="stretch">
                        <PrimaryButton text={strings.BUTTONS.TEXT.CONFIRM} disabled={!isValid}></PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    );
}

const generate = (value: number, mask?: string): string => {
    value *= 1111;
    if (!mask) return value.toString();
    let requiredLength = 0;
    let mixed = false;
    for (let index = 0; index < mask.length; index++) {
        if (mask[index] === "9") {
            requiredLength++; continue;
        }
        if (mask[index] === "*") {
            mixed = true; requiredLength++; continue;
        }
    }

    let result = mask.split("");
    let insert = mixed ? value.toString(16).toUpperCase() : value.toString();
    if (insert.length < requiredLength) {
        insert = insert.padStart(requiredLength, "0")
    }
    let j = requiredLength - 1;
    for (let i = mask.length - 1; i > -1; i--) {
        if (mask[i] === "9" || mask[i] === "*") {
            result[i] = insert[j--];
        }
    }
    console.log(result.join(""))
    return result.join("");
}


export default Universal

const theme = getTheme()