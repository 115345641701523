import * as React from 'react';
import { useSelector } from "react-redux";
import { IStore } from "../../../../../../core/store";
import { ILandingConfig } from "@piceasoft/core";
import { strings } from '../../../../../../localization/strings';
import * as UIKit from "@piceasoft/picea-react-ui-kit"
import piceasoftLogo from '../../../../../../assets/piceasoft_solid_logo.png'
import { getTheme } from "@fluentui/react"

type TProps = {
    config: ILandingConfig,
    serviceName?: string
}

export const LandingPreview: React.FC<TProps> = (props) => {
    const module = useSelector((s: IStore) => s.configurator.landing as ILandingConfig)
    if (!module) return null
    const theme = getTheme();
    return (
        <div className='flex-grow-1 d-flex flex-column' style={{ padding: "32px 32px 32px 32px" }}>
            <div style={{ minWidth: 320, maxWidth: 320, minHeight: 500, backgroundColor: theme.palette.white, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 }}>
                <div>
                    <UIKit.AppHeader imageSrc={piceasoftLogo} logoHeight={30} />
                </div>
                <div className='text-start text-sm-center' style={{ marginTop: '28px', padding: "0px 16px 0px 16px" }}>
                    <UIKit.Text variant='h2'>{props.config?.title ?? props.serviceName}</UIKit.Text>
                    <UIKit.Text>
                        <div dangerouslySetInnerHTML={{ __html: props.config?.bodyText ?? (strings.CONSTRUCTOR.INSPECTIONS.LANDING.BODY_DEFAULT.replace('{0}', props.serviceName ?? '')) }}></div>
                    </UIKit.Text>
                </div>
                <div style={{ marginTop: '32px', padding: "0px 16px 0px 16px" }} onClick={() => { if (props.config?.imageLink) { window.open(props.config?.imageLink, '_blank'); } }}>
                    {props.config?.useImage && (<UIKit.ImageCard src={props.config?.imageUrl ?? props.config?.imageManualUrl} width="100%" padding="15px" />)}
                </div>
                <div className='flex-grow-1 mt-5 d-flex justify-content-center align-items-end' style={{ display: 'inline-block', alignContent: "center", padding: "0px 10px 0px 10px" }}>
                    <UIKit.OnlineButton fixedSize="large" color="primary" style={{ marginTop: '32px' }} onClickHandler={() => { }}>
                        <React.Fragment key=".0">
                            {props.config?.buttonText ?? strings.CONSTRUCTOR.INSPECTIONS.LANDING.BUTTON_TEXT_DEFAULT}
                        </React.Fragment>
                    </UIKit.OnlineButton>
                </div>
            </div>
        </div>
    )
}


