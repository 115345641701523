import { ComboBox, IComboBoxOption, IStyle } from "@fluentui/react"
import React from "react"
import { UseFormTrigger } from "react-hook-form"
import { IKnownDevice } from "@piceasoft/core"
import { IPromotionItem } from "../../../../core/store/typings/IPromotionItem"

type TProps = {
    value: string
    onChange: (...event: any[]) => void
    onBlur: () => void
    styles: Partial<{ errorMessage: IStyle }>
    placeholder: string
    errorMessage?: string
    devices: IKnownDevice[]
    manufacturer?: string
    model?: string
    trigger: UseFormTrigger<IPromotionItem>
}

export const PromotionItemConditionConfiguration: React.FC<TProps> = props => {

    const [configurationOptions, setConfigurationOptions] = React.useState<IComboBoxOption[]>([])

    React.useEffect(() => {
        prepareConfigurationOptions(props.manufacturer, props.model)
    }, [])

    React.useEffect(() => {
        prepareConfigurationOptions(props.manufacturer, props.model)
    }, [props.manufacturer, props.model])

    const prepareConfigurationOptions = async (manufacturer?: string, model?: string) => {
        const devicesWithManufacturer = props.devices.filter(i => i.manufacturer === manufacturer)
        const devicesWithModel = devicesWithManufacturer.filter(i => i.name === model)
        const configuraitonsArray = devicesWithModel.sort((a, b) => parseInt(a.configuration) - parseInt(b.configuration)).map(i => i.configuration)
        const configuraitonsSet = new Set(configuraitonsArray)
        setConfigurationOptions(Array.from(configuraitonsSet).map(i => ({ key: i, text: i })))
    }

    return (
        <ComboBox
            allowFreeform
            autoComplete="on"
            text={props.value}
            options={configurationOptions}
            placeholder={props.placeholder}
            onChange={(ev, opt, index, val) => {
                console.log(opt, val)
                props.trigger("conditions")
                props.onChange(opt?.text ?? val)
            }}
            onInputValueChange={(val) => {
                props.trigger("conditions")
                props.onChange(val)
            }}
            onItemClick={(ev, opt) => {
                if (opt) {
                    props.trigger("conditions")
                    props.onChange(opt.text)
                }
            }}
            styles={{ ...props.styles, optionsContainer: { maxHeight: 300 } }}
            useComboBoxAsMenuWidth
            errorMessage={props.errorMessage}
        />
    )
}
