import {
    Stack, getTheme, Text, IconButton, IIconProps, SharedColors,
    IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject,
    ScrollablePane, DirectionalHint, Icon, IPivotItemProps,
    IRenderFunction, Pivot, PivotItem, TooltipHost
} from "@fluentui/react"
import * as React from 'react';
import { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { 
    IInspectionConfig, IInspectionConfigUI, DiagnosticsModes, Inspections, ProcessStages,
    IDiagnosticsPiceaConfig, IDiagnosticsManualConfig, IDiagnosticsPiceaMobileConfig, IDiagnosticsWebBasedConfig
} from "@piceasoft/core";
import { IDiagnosticsConfig } from "../../../../../../../core/store/typings/IDiagnosticsConfig";
import { getLocalizedInspectionName, getLocalizedInspectionModeName } from "../../../../../../../localization/helpers/transaction";
import { strings } from "../../../../../../../localization/strings";
import { PiceaMobileSettingsConfiguratorModePivot } from "./pivots/PiceaMobileSettingsConfiguratorModePivot";
import { PiceaMobileTestsConfiguratorModePivot } from "./pivots/PiceaMobileTestsConfiguratorModePivot";
import { PiceaSettingsConfiguratorModePivot } from "./pivots/PiceaSettingsConfiguratorModePivot";
import { PiceaTestsConfiguratorModePivot } from "./pivots/PiceaTestsConfiguratorModePivot";
import { CSHHelpLink } from "../../../../../help/CSHHelp";
import { WebBasedSettingsConfiguratorModePivot } from "./pivots/WebBasedSettingsConfiguratorModePivot";

type TProps = {
    index: number
    stage: ProcessStages
    onDissmiss: () => void
    onChangeDispatch: Dispatch<any>
}

type TDiagnosticsModeConfiguratorPivotState = "common" | "tests"

export const DiagnosticsModeConfigurator: React.FC<TProps> = ({ stage, index, onDissmiss, onChangeDispatch }) => {

    const diagnostics = useSelector((s: IStore) => s.configurator.assessment?.modules.find(i => i.index === index)?.config as IDiagnosticsConfig)
    const diagnosticsConfig = diagnostics.config as IDiagnosticsPiceaConfig
    const diagnosticsErrors = (diagnosticsConfig.tests.find((test) => test.errors && test.errors.length > 0) || diagnostics.errors?.find((error) => error.code === 15)) ? true : false
    const [pivotState, setPivotState] = React.useState<TDiagnosticsModeConfiguratorPivotState>("tests")

    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(item?.props.itemKey! as TDiagnosticsModeConfiguratorPivotState);
    };

    const getTabId = (itemKey: string) => {
        return `DiagnosticsModeConfiguratorPivot_${itemKey}`;
    };

    const getPivotHeaderText = (m: string): string => {
        switch (m) {
            case "tests": return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST_PIVOT
            case "common": return strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.COMMON_PIVOT
            default: return m;
        }
    }

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
                </TooltipHost>
            </span>
        )
    }
    const onRenderItemLinkWarning: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText} tooltipProps={{ calloutProps: { styles: { calloutMain: { "p": {} } } } }}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px', color: SharedColors.red10 }} />
                </TooltipHost>
            </span>
        )

    }

    const onConfigConfirmHandler = (config: IDiagnosticsPiceaConfig | IDiagnosticsManualConfig | IDiagnosticsWebBasedConfig | IInspectionConfig<IInspectionConfigUI>) => onChangeDispatch(actionCreators.inspections.diagnostics.editModeConfig(stage, index, config))

    return (
        <Stack grow verticalFill horizontal>
            <Stack verticalFill grow>
                <Stack horizontal grow style={{ padding: 8 }}>
                    <Stack style={{ padding: 8 }} grow tokens={{ childrenGap: 4 }}>
                        <Text variant="xLarge">{diagnostics?.ui?.title ?? getLocalizedInspectionName(Inspections.Diagnostics)}</Text>
                        <Text variant="small">{getLocalizedInspectionModeName(Inspections.Diagnostics, diagnostics as IDiagnosticsConfig)}</Text>
                    </Stack>
                    <Stack horizontal style={{ alignItems: "center" }}>
                        <CSHHelpLink articleid="1137"/>
                        <IconButton onClick={onDissmiss} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                    </Stack>
                </Stack>
                <Stack.Item tokens={{ padding: "0px 16px 0px 16px" }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                    <Pivot styles={pivotStyles} selectedKey={pivotState} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                        <PivotItem itemIcon={'List'} headerText={getPivotHeaderText("tests")} onRenderItemLink={diagnosticsErrors ? onRenderItemLinkWarning : onRenderItemLink} itemKey="tests" />
                        <PivotItem itemIcon={'Settings'} headerText={getPivotHeaderText("common")} onRenderItemLink={diagnostics.errors?.find((error) => error.code === 18) ? onRenderItemLinkWarning : onRenderItemLink} itemKey="common" />
                    </Pivot>
                </Stack.Item>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <PivotBasedState
                            state={pivotState}
                            diagnostics={diagnostics}
                            onConfigConfirm={onConfigConfirmHandler}
                            onDissmiss={onDissmiss}
                            onConfirm={(newConfig) => onChangeDispatch(actionCreators.inspections.diagnostics.editDiagnostics(stage, index, newConfig))}
                        />
                    </ScrollablePane>
                </Stack.Item>
            </Stack>
        </Stack >
    )
}

type TStateProps = {
    state: TDiagnosticsModeConfiguratorPivotState
    diagnostics: IDiagnosticsConfig
    onConfigConfirm: (config: IDiagnosticsPiceaConfig | IDiagnosticsManualConfig | IDiagnosticsWebBasedConfig | IInspectionConfig<IInspectionConfigUI>) => void
    onConfirm: (config: IDiagnosticsConfig) => void
    onDissmiss: () => void
}

const PivotBasedState: React.FC<TStateProps> = (props) => {
    switch (props.state) {
        case "common":
            switch (props.diagnostics.mode) {
                case DiagnosticsModes.Piceasoft:
                    return <PiceaSettingsConfiguratorModePivot
                        onModeConfirm={props.onConfigConfirm}
                        gradesCategory={props.diagnostics.gradesCategory}
                        config={props.diagnostics.config as IDiagnosticsPiceaConfig}
                    />
                case DiagnosticsModes.PiceaMobile:
                    return <PiceaMobileSettingsConfiguratorModePivot
                        onModeConfirm={props.onConfigConfirm}
                        gradesCategory={props.diagnostics.gradesCategory}
                        config={props.diagnostics.config as IDiagnosticsPiceaMobileConfig} />
                case DiagnosticsModes.WebBased:
                    return <WebBasedSettingsConfiguratorModePivot 
                        onModeConfirm={props.onConfigConfirm}
                        gradesCategory={props.diagnostics.gradesCategory}
                        config={props.diagnostics.config as IDiagnosticsWebBasedConfig} />
                default: return <></>
            }
        case "tests":
            switch (props.diagnostics.mode) {
                case DiagnosticsModes.Piceasoft:
                    return <PiceaTestsConfiguratorModePivot
                        config={props.diagnostics.config as IDiagnosticsPiceaConfig}
                        mode={props.diagnostics.mode}
                        gradesCategory={props.diagnostics.gradesCategory}
                        onModeConfirm={props.onConfigConfirm}
                    />
                case DiagnosticsModes.PiceaMobile:
                    return <PiceaMobileTestsConfiguratorModePivot
                        config={props.diagnostics.config as IDiagnosticsPiceaMobileConfig}
                        gradesCategory={props.diagnostics.gradesCategory}
                        onModeConfirm={props.onConfigConfirm}
                    />
                case DiagnosticsModes.WebBased:
                    return <PiceaTestsConfiguratorModePivot
                        config={props.diagnostics.config as IDiagnosticsWebBasedConfig}
                        gradesCategory={props.diagnostics.gradesCategory}
                        mode={props.diagnostics.mode}
                        onModeConfirm={props.onConfigConfirm}
                    />
                default: return <></>
            }
        default: return <></>
    }
}

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}
