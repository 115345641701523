import { Stack, SharedColors, DefaultButton, Text } from "@fluentui/react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"
import { StagePreview } from "../../preview/StagePreview"
import { ProcessStages } from "@piceasoft/core"

export const ContractPreview: React.FC = () => {

    const config = useSelector((s: IStore) => s.configurator.contract)

    if (!config) return null

    return (
        <StagePreview config={config}
        stage={ProcessStages.Contract}
        defaultNextText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONTINUE}
        defaultCancelText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CANCEL}
        defaultStageTitle={"№CONF-0000-0000007"}
    >
        <Stack tokens={{ childrenGap: 24, padding: 32 }}>
            <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                <Text variant="xLarge" style={{ fontWeight: "bold" }}>{config?.ui?.header ?? strings.CONSTRUCTOR.STAGES.CONTRACT.TITLE}</Text>
                <Stack horizontalAlign="center" tokens={{ maxWidth: 800, childrenGap: 24 }}>
                    <Text variant="small" style={{ color: SharedColors.gray40 }}>
                        <div dangerouslySetInnerHTML={{ __html: config?.ui?.information ?? "" }}></div>
                    </Text>
                    <Stack.Item>
                        <DefaultButton>{config?.ui?.edit ?? strings.BUTTONS.TEXT.EDIT_DATA}</DefaultButton>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
    </StagePreview>
    )
}
