import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators, getStageInspectionModules } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { IDiagnosticsConfig } from "../../../../../../../core/store/typings/IDiagnosticsConfig";
import { strings } from "../../../../../../../localization/strings";
import { ParameterItem } from "../../../../components/ParameterItem";
import { InspectionUISettingsPivot } from "../../settings/InspectionUISettingsPivot";
import { ProcessStages } from "@piceasoft/core";

type TProps = {
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
}

export const DiagnosticsUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => getStageInspectionModules(props.stage, s)?.find(i => i.index === props.index)?.config as IDiagnosticsConfig)

    return (
        <InspectionUISettingsPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch}>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.DESCRIPTION_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={config.config.ui?.description !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.inspections.diagnostics.editDiagnostics(props.stage, props.index, {
                        ...config,
                        config: {
                            ...config.config,
                            ui: {
                                ...config.config.ui,
                                description: config.config.ui?.description !== undefined ? undefined : ""
                            }
                        }
                    }))
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.DESCRIPTION_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={config.config.ui?.description !== undefined ? true : false}
                textFieldValue={config.config.ui?.description}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.diagnostics.editDiagnostics(props.stage, props.index, {
                    ...config,
                    config: {
                        ...config.config,
                        ui: {
                            ...config.config.ui,
                            description: val
                        }
                    }
                })
                )}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.REJECT_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={config.config.ui?.reject !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.inspections.diagnostics.editDiagnostics(props.stage, props.index, {
                        ...config,
                        config: {
                            ...config.config,
                            ui: {
                                ...config.config.ui,
                                reject: config.config.ui?.reject !== undefined ? undefined : ""
                            }
                        }
                    }))
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.UI.REJECT_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={config.config.ui?.reject !== undefined ? true : false}
                textFieldValue={config.config.ui?.reject}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.diagnostics.editDiagnostics(props.stage, props.index, {
                    ...config,
                    config: {
                        ...config.config,
                        ui: {
                            ...config.config.ui,
                            reject: val
                        }
                    }
                })
                )}
            />
        </InspectionUISettingsPivot>
    )
}