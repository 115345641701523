import { Stack } from "@fluentui/react"
import React from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { IKnownDevice } from "@piceasoft/core"
import { IPromotionItemCondition } from "../../../core/store/typings/IPromotionItemCondition"
import { PromotionItemConditionsFormArray } from "./PromotionItemConditionsFormArray"

type TProps = {
    data: IPromotionItemCondition[]
    onSubmit: (data: IPromotionItemCondition[]) => void
    onDismiss: () => void
    devices: IKnownDevice[] 
    isFetching: boolean
}

type TPromotionItemCondition = IPromotionItemCondition & {itemId?: string}

type TConditions = {
    conditions: TPromotionItemCondition[]
}

export const PromotionItemConditionsForm: React.FC<TProps> = props => {

    const prepareConditions = (conditions: IPromotionItemCondition[]): TPromotionItemCondition[] => conditions.map(i => ({...i, itemId: i.id}))

    const methods = useForm<TConditions>({
        defaultValues: { conditions: prepareConditions(props.data) }
    });
    const { control, handleSubmit, reset, trigger, watch } = methods;

    const formData = watch()

    React.useEffect(() => {
        trigger("conditions")
    }, [formData.conditions.length])

    const onSubmit: SubmitHandler<TConditions> = data => props.onSubmit(data.conditions.map(i => ({...i, id: i.itemId, itemId: undefined}) as IPromotionItemCondition));
    const onHandleSubmit = handleSubmit(onSubmit);
    const onReset = () => reset({ conditions: prepareConditions(props.data) }, { keepDirty: false })

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
                <Stack.Item verticalFill grow>
                    <PromotionItemConditionsFormArray control={control} onSubmit={onHandleSubmit} onReset={onReset} onDismiss={props.onDismiss} devices={props.devices} isFetching={props.isFetching} />
                </Stack.Item>
            </form>
        </FormProvider>
    )
}
