import { DatePicker, Stack } from "@fluentui/react"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import { strings } from "../../../../../../../../../../../localization/strings"
import { formatDate, parseDateFromString } from "../../../helpers/previewHelper"

type TProps = {
    value?: string
    config?: IDataCollectionStandardConfig
    errorMessage: string
    phoneCountry?: string
    handleOnBlur: () => void
    handleOnChange: (value: string) => void
}

const DateOfIssue: React.FunctionComponent<TProps> = (props) => {
    let {value, config, handleOnChange, handleOnBlur} = props

    return (
        <Stack.Item>
            {config?.fields?.passport?.dateOfIssue && (
                <DatePicker
                    value={parseDateFromString(value ?? "") ?? undefined}
                    allowTextInput
                    formatDate={(date?: Date) => formatDate(date)}
                    parseDateFromString={parseDateFromString}
                    label={config?.fields?.passport?.dateOfIssue?.placeholder ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PASSPORT_ISSUE_DATE}
                    placeholder={config?.fields?.passport?.dateOfIssue?.placeholder ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PLACEHOLDER_PASSPORT_ISSUE_DATE}
                    onSelectDate={(date) => { handleOnChange(formatDate(date)) }}
                    onBlur={handleOnBlur}
                    strings={strings.DAY_PICKER}
                    isRequired
                />
            )}
        </Stack.Item>
    )
}

export default DateOfIssue