import { Stack } from "@fluentui/react";
import React from "react";
import { strings } from "../../../localization/strings";
import { ItemsNotFound } from "../notFound/ItemsNotFound";

export const ComingSoon: React.FC<{}> = () => {
    return (
        <Stack verticalAlign='center' verticalFill>
            <ItemsNotFound doNotUseButton
                info={strings.COMMON.SOON_INFO}
                suggestion={strings.COMMON.SOON_SUGGESTION}
                imgSrc={"images/navigation/images/coming_soon.svg"}
            />
        </Stack>
    )
}