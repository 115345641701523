import {
    getTheme, Stack, TextField, Text, Separator, PrimaryButton, ChoiceGroup,
    IChoiceGroupOption, ScrollablePane, Sticky, MessageBar, MessageBarType,
    getFocusStyle, IComboBoxOption, Icon, IDropdownOption, mergeStyleSets,
    SharedColors, Dropdown, Toggle, Spinner
} from '@fluentui/react'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { strings } from '../../../localization/strings'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { ChooseIcon } from '../chooseIcon/ChooseIcon'
import { portalApi } from '../../../core/api/api'
import { IRequestResult } from '../../../core/store/typings/IResponseResult'
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from '../search/SuggestedSearch'
import { useBoolean, useId } from '@uifabric/react-hooks'
import { escapeRegExp } from '../../../assets/ts/utils/regex'
import { IStore } from '../../../core/store'
import { prepareCurrencyOptions } from '../../../core/helpers/currency'
import { useDispatch, useSelector } from 'react-redux'
import { PromoProviderTypes, IPromoProviderConfig, IResponseError } from '@piceasoft/core';
import { PromoProviderFormGradesMapArray } from './PromoProviderFormGradesMapArray'
import { IPromotion } from '../../../core/store/typings/IPromotion'
import { CSHHelpLink } from '../help/CSHHelp'
import { actionCreators } from '../../../core/actions/configurator-actions'

type TProps = {
    onSubmit: (item: IPromoProviderConfig) => void
    onCancel: () => void
    codes: string[]
    data?: IPromoProviderConfig
}

export const PromoProviderForm: React.FC<TProps> = (props) => {

    console.log(props.data)

    const organizationCurrency = useSelector((s: IStore) => s.workplace.organization?.currency);
    const currency = prepareCurrencyOptions();
    const dispatch = useDispatch();
    const [providerType, setProviderType] = React.useState<PromoProviderTypes>(props.data?.type ?? PromoProviderTypes.Promotion);
    const [showNotifyField, { toggle: toggleNotifyField }] = useBoolean(props.data?.notifyEndpoint ? true : false);

    const { control, handleSubmit, reset, watch, getValues, setValue, formState: { errors } } = useForm<IPromoProviderConfig>({ defaultValues: { ...props.data, currency: props.data?.currency ?? organizationCurrency } });
    const onSubmit: SubmitHandler<IPromoProviderConfig> = async data => {
        if(promotionsState.data){
            dispatch(actionCreators.previousCommonOfferPromoProviders.save(promotionsState.data));
        };

        let icon = props.data?.imageSrc
        let errors: IResponseError[] = []

        if (icon !== fileSrc) {
            setIsFetchingIcon(true)
            setIconErrors([])
            let uploadResult: IRequestResult<string> = { successed: false }
            if (file) {
                uploadResult = await portalApi.settings.organization.uploadFile('offer-provider-icon', file, undefined, true);
                if (uploadResult.errors && uploadResult.errors.length > 0) {
                    errors = uploadResult.errors;
                }
            } else {
                uploadResult.successed = true;
            }

            if (uploadResult.successed) {
                icon = uploadResult.data
                setIsIconChanged(false)
            }
            setIsFetchingIcon(false)
        }

        if (errors.length === 0) {
            props.onSubmit({ ...data, type: providerType, imageSrc: icon ?? "" })
        } else {
            setIconErrors(errors)
        }

    };

    const state = watch();

    React.useEffect(() => {
        console.log(state)
    }, [state])


    const providerTypes: IChoiceGroupOption[] = [
        { key: PromoProviderTypes.Promotion.toString(), text: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.TYPES.PROMOTION.LABEL, iconProps: { iconName: "Shop" }, styles: { root: { display: 'flex' } } },
        // { key: PromoProviderTypes.Provider.toString(), text: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.TYPES.PROVIDER.LABEL, iconProps: { iconName: "Money" }, styles: { root: { display: 'flex' } } },
    ]

    const [file, setFile] = React.useState<File>()
    const [isFetchingIcon, setIsFetchingIcon] = React.useState(false)
    const [isIconChanged, setIsIconChanged] = React.useState(false)
    const [iconErrors, setIconErrors] = React.useState<IResponseError[]>([])
    const [fileSrc, setFileSrc] = React.useState(props.data?.imageSrc)

    const [promotionsState, setPromotionsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IPromotion> }>({ fetched: false })

    const [promotions, setPromotions] = React.useState<IComboBoxOption[]>()

    const promotionHostId = useId("promotion-host-id")
    const promotionTextId = useId("promotion-text-id")
    const promotionCalloutListId = "promotion-callout-id"

    React.useEffect(() => {
        getPromotions()
    }, [])

    React.useEffect(() => {
        if (promotionsState.fetched && promotionsState.data) {
            setPromotions(getPromotionsOptions(promotionsState.data))
        }
    }, [promotionsState])

    React.useEffect(() => {
        if (providerType === PromoProviderTypes.Provider) {
            if (!getValues().currency) {
                setValue("currency", props.data?.currency ?? organizationCurrency)
            }
        } else {
            setValue("currency", undefined)
        }
    }, [providerType])

    const getPromotions = async () => {
        setPromotionsState({ fetched: false })
        const result = await portalApi.organization.promotions.getPromotions()
        console.log(result)
        setPromotionsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
    }

    React.useEffect(() => {
        setFileSrc(props.data?.imageSrc)
        setFile(undefined)
    }, [props.data?.imageSrc])

    React.useEffect(() => {
        if (iconErrors.length > 0) {
            setIconErrors([])
        }
    }, [fileSrc])

    const allowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif", "image/svg+xml"]

    const addFile = (upFiles: FileList) => {
        console.log(upFiles)
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => allowedFileTypes.includes(upFile.type))]
        console.log(checkedUpFiles)
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            setIsIconChanged(true)
            setFileSrc(URL.createObjectURL(checkedUpFiles[0]))
            setFile(checkedUpFiles[0])
        }
    }

    const resetImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFileSrc(props.data?.imageSrc)
            setFile(undefined)
            setIsIconChanged(false)
        }
    }

    const deleteImageSrc = () => {
        if (fileSrc) {
            setFileSrc(undefined)
            setIsIconChanged(true)
        }
    }

    const onToggleNotify = () => {
        if (showNotifyField) {
            setValue("notifyEndpoint", "");
        }
        toggleNotifyField();
    }

    const onSelectPromotion = (promotionId: string) => {
        const promotion = promotionsState.data?.find(i => i.id === promotionId)
        if (promotion) {
            setValue("currency", promotion.currency);
            reset({
                ...getValues(),
                promotionId: promotion.id,
                name: promotion.name,
                description: promotion.summary                
            })
        }
        toggleNotifyField();
    }

    const getPromotionsOptions = (promotions: IPromotion[]): IDropdownOption[] => promotions.map(c => ({ key: c.id, text: c.name } as IDropdownOption))


    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderPromotionSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${promotionCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, promotions?.length ?? 0, promotionTextId, promotionCalloutListId, () => setValue("promotionId", item?.key as string), index, item?.text, promotionHostId)}
                tabIndex={-1}
                onClick={() => {
                    console.log(index)
                    onSelectPromotion(item?.key as string)
                    focusElement(`${promotionCalloutListId}-${index as number}`, promotionHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        {iconErrors.length > 0 && (
                            <Sticky>
                                <MessageBar messageBarType={MessageBarType.error}>
                                    <Stack tokens={{ childrenGap: 2 }}>
                                        {iconErrors.map(i => <Text>{i.description}</Text>)}
                                    </Stack>
                                </MessageBar>
                            </Sticky>
                        )}
                        <Stack verticalFill tokens={{ padding: "0px 20px 20px 20px", childrenGap: 16 }}>
                            <Controller
                                control={control}
                                name={"code"}
                                rules={{
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.CODE_REQUIRED,
                                    validate: (value) => {
                                        if (props.data && props.data.code === value) {
                                            return true;
                                        }
                                        return props.codes.find(k => k === value) ?
                                            strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROVIDER_CODE_VALIDATION :
                                            true
                                    }
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.CODE_LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.CODE_TITLE}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.CODE_PLACEHOLDER}
                                        errorMessage={errors.code?.message}
                                    />
                                }
                            />
                            <Stack.Item>
                                {onRenderLabel({
                                    required: true,
                                    label: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.TYPE.LABEL,
                                    title: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.TYPE.TITLE
                                })}
                                <ChoiceGroup
                                    required
                                    selectedKey={providerType.toString()}
                                    onChange={(ev, opt) => opt && setProviderType(Number.parseInt(opt.key) as PromoProviderTypes)}
                                    options={providerTypes}
                                    key={`resultType-${providerType}`}
                                />
                            </Stack.Item>
                            {providerType === PromoProviderTypes.Promotion && (
                                <Controller
                                    control={control}
                                    name={"promotionId"}
                                    rules={{
                                        required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROMOTION_REQUIRED,
                                    }}
                                    render={({ field }) =>
                                        <SuggestedSearch
                                            type="dropdown-search"
                                            inputBoxId={promotionTextId}
                                            hostId={promotionHostId}
                                            required
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROMOTION_LABEL}
                                            title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROMOTION_TITLE}
                                            inputBoxPlaceholder={!promotions ? strings.SPINNERS.DATA_IS_GETTING : promotions.length > 0 ? (strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PROMOTION_PLACEHOLDER) : strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NO_PROMOTIONS_PLACEHOLDER}
                                            onClickSuggestionCell={(item) => field.onChange(item ? item.key as string : undefined)}
                                            searchRequest={promotions ? promotions.find(i => i.key === field.value)?.text : strings.SPINNERS.DATA_IS_GETTING}
                                            suggestionsListWidth={440}
                                            disabled={!promotions || promotions.length === 0}
                                            suggestions={promotions ?? []}
                                            suggestionsListId={promotionCalloutListId}
                                            isSearchEqualTheOneSuggestion={false}
                                            onRenderSuggestionCell={onRenderPromotionSuggestionsCell}
                                            prepareFilteredItems={prepareFilteredOptions}
                                            selectedKey={getValues().promotionId}
                                            errorMessage={errors.promotionId?.message}
                                        />
                                    }
                                />
                            )}
                            <Controller
                                control={control}
                                name={"name"}
                                rules={{
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NAME_REQUIRED,
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NAME_LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NAME_TITLE}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            {providerType === PromoProviderTypes.Provider && (
                                <Controller
                                    name="currency"
                                    control={control}
                                    rules={{
                                        required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.CURRENCY_REQUIRED,
                                    }}
                                    render={({ field }) =>
                                        <Dropdown
                                            selectedKey={field.value}
                                            required
                                            onChange={(ev, opt) => field.onChange(opt?.key)}
                                            onBlur={field.onBlur}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.CURRENCY_LABEL}
                                            title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.CURRENCY_TITLE}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.CURRENCY_PLACEHOLDER}
                                            options={currency}
                                            errorMessage={errors.currency?.message}
                                        />}
                                />
                            )}
                            <Controller
                                control={control}
                                name={"description"}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.DESCRIPTION_LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.DESCRIPTION_TITLE}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.DESCRIPTION_PLACEHOLDER}
                                        errorMessage={errors.description?.message}
                                    />
                                }
                            />
                            {providerType === PromoProviderTypes.Provider && (
                                <Controller
                                    control={control}
                                    name={"endpoint"}
                                    rules={{
                                        required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.ENDPOINT_REQUIRED,
                                    }}
                                    render={({ field }) =>
                                        <TextField
                                            required
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.ENDPOINT_LABEL}
                                            title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.ENDPOINT_TITLE}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.ENDPOINT_PLACEHOLDER}
                                            errorMessage={errors.endpoint?.message}
                                        />
                                    }
                                />
                            )}
                            {providerType === PromoProviderTypes.Provider && (
                                <Controller
                                    control={control}
                                    name={"user"}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.USER_LABEL}
                                            title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.USER_TITLE}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.USER_PLACEHOLDER}
                                            errorMessage={errors.user?.message}
                                        />
                                    }
                                />
                            )}
                            {providerType === PromoProviderTypes.Provider && (
                                <Controller
                                    control={control}
                                    name={"password"}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PASSWORD_LABEL}
                                            title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PASSWORD_TITLE}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.PASSWORD_PLACEHOLDER}
                                            errorMessage={errors.password?.message}
                                        />
                                    }
                                />
                            )}
                            {providerType === PromoProviderTypes.Provider && (
                                <Toggle checked={showNotifyField} onChange={onToggleNotify} label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NOTIFY_TOOGLE_LABLE} />
                            )}
                            {providerType === PromoProviderTypes.Provider && showNotifyField && (
                                <Controller
                                    control={control}
                                    name={"notifyEndpoint"}
                                    // rules={{
                                    //     required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.ENDPOINT_REQUIRED,
                                    // }}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NOTIFY_ENDPOINT_LABEL}
                                            title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NOTIFY_ENDPOINT_TITLE}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NOTIFY_ENDPOINT_PLACEHOLDER}
                                            errorMessage={errors.endpoint?.message}
                                        />
                                    }
                                />
                            )}
                            <Stack>
                                <Separator />
                                {onRenderLabel({
                                    title: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.ICON_TITLE,
                                    label: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.ICON_LABEL
                                })}
                                <ChooseIcon
                                    acceptTypeMask={"image/*"}
                                    allowedFileTypes={allowedFileTypes}
                                    type="image"
                                    changed={isIconChanged}
                                    resetChanged={() => setIsIconChanged(true)}
                                    imgSrc={getValues().imageSrc}
                                    fileSrc={fileSrc}
                                    itemName={getValues().name}
                                    lable={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.ICON_LOAD_LABEL}
                                    description={true}
                                    generateIcon
                                    addFile={addFile}
                                    deleteImageSrc={deleteImageSrc}
                                    resetImageSrc={resetImageSrc}
                                />
                                <Separator />
                            </Stack>
                            {providerType === PromoProviderTypes.Provider && (
                                <PromoProviderFormGradesMapArray {...{ control, defaultValues: props.data, getValues, setValue, errors }} />
                            )}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        {isFetchingIcon && (
                            <Spinner />
                        )}
                        <CSHHelpLink alignment='left' articleid='1157'></CSHHelpLink>
                        <PrimaryButton disabled={isFetchingIcon} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}

const theme = getTheme();
