import { IDropdownStyles, SearchBox, FontIcon, getTheme, PrimaryButton, ScrollablePane, Stack, TextField, Toggle, Dropdown, IDropdownOption, DatePicker, Icon, ComboBox, TooltipHost, DirectionalHint } from '@fluentui/react';
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { strings } from '../../../localization/strings'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { INewOrganization } from '../../../core/store/typings/INewOrganization'
import { validatePassword } from '../configurator/stages/assessment/dataCollection/modeConfigurator/pivots/helpers/validationRules'
import { PasswordPolicy } from '../PasswordPolicy'
import { IPiceaCustomer } from '../../../core/store/typings/IPiceaCustomer';
import { IPiceaCountry } from '../../../core/store/typings/IPiceaCountry';
import { IPiceaProduct } from '../../../core/store/typings/IPiceaProduct';
import { Tooltip } from '../help/Tooltip';
import { portalApi } from "../../../core/api/api";
import moment from "moment";
import { Solutions } from '../../../core/store/typings/Solutions';


type TProps = {
    onSubmit: (data: INewOrganization) => void
    onCancel: () => void
    data?: INewOrganization
    customers: IPiceaCustomer[]
    countries: IPiceaCountry[]
    searchCustomerProducts: (customer_id: string) => void
    searchPiceaCustomerId: (customer_id: string) => void
    searchPiceaCustomerVat: (vat_number: string) => void
    resetOrganizationId: (vat: boolean) => void
    customerProducts?: IPiceaProduct[]
    existingOrganizationId: string
    existingVatOrganizationId: string
    changeToExistingOrganization: (organization_id: string) => void
}

export const OrganizationForm: React.FC<TProps> = (props) => {

    const { control, watch, trigger, handleSubmit, getValues, setValue, formState: { errors } } = useForm<INewOrganization & { repeatPassword?: string }>({ defaultValues: props.data ? props.data : { login: 'root' }, mode: "all" });
    const [foundCustomerWithName, setFoundCustomerWithName] = React.useState<IPiceaCustomer>();
    const [foundCustomerWithVat, setFoundCustomerWithVat] = React.useState<IPiceaCustomer>();
    const [foundCustomer, setFoundCustomer] = React.useState<IPiceaCustomer>();
    const [searchCustomerNameString, setSearchCustomerNameString] = React.useState<string>('');
    const [searchCustomerVatNumberString, setSearchCustomerVatNumberString] = React.useState<string>('');

    const [customerWithNameDisabled, setCustomerWithNameDisabled] = React.useState<boolean>(false);
    const [customerWithVatDisabled, setCustomerWithVatDisabled] = React.useState<boolean>(false);
    const [customerDisabled, setCustomerDisabled] = React.useState<boolean>(false);

    const [vatCustomerProductsDisabled, setVatCustomerProductsDisabled] = React.useState<boolean>(false);
    const [nameCustomerProductsDisabled, setNameCustomerProductsDisabled] = React.useState<boolean>(false);


    const onSubmit: SubmitHandler<INewOrganization & { repeatPassword?: string }> = data => {

        if (data.password === data.repeatPassword) {
            delete (data.repeatPassword)
            props.onSubmit(data)
        }
    }


    const formData = watch();

    if (formData && formData.picea_customer_id && foundCustomer === undefined) {
        let customer = props.customers.find(custo => custo.customer_id.toLowerCase() === formData.picea_customer_id.toLowerCase());
        if (customer) {
            setFoundCustomer(customer);
        }
    }


    function parseDateFromString(value: string): Date | null {
        let data = value.split(".");
        if (data.length === 3) {
            return new Date(Number(data[2]), Number(data[1]) - 1, Number(data[0]));
        } else {
            return null;
        }
    }

    function formatDate(date?: Date): string {
        return date?.toLocaleDateString() ?? "";
    }

    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    const aMonthFromNow = new Date();
    let month = aMonthFromNow.getMonth() + 1;
    if (aMonthFromNow.getMonth() === 12) {
        month = 1;
    }
    aMonthFromNow.setMonth(aMonthFromNow.getMonth() + 1);

    function addWeeks(date: Date, weeks: number) {
        date.setDate(date.getDate() + 7 * weeks);
        return date;
    }

    const twoWeeksFromNow = addWeeks(new Date(), 2);


    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdownItemsWrapper: { maxHeight: 300 }
    };

    const productOptions: IDropdownOption[] = props.customerProducts ? props.customerProducts.map((product) => {
        return { key: product.product_id, text: product.name };
    }) : [];



    const statusOptions: IDropdownOption[] = [
        { key: 'Official', text: 'Official' },
        { key: 'Trial', text: 'Trial' },
        { key: 'Internal', text: 'Internal' },

    ];


    const productStatusOptions: IDropdownOption[] = [
        { key: 'Official', text: 'Official' },
        { key: 'BackOffice', text: 'BackOffice' },
        { key: 'Trial', text: 'Trial' },
        { key: 'Pilot', text: 'Pilot' },
        { key: 'Testing', text: 'Testing' }
    ];


    const countryOptions: IDropdownOption[] = props.countries.map((country) => {
        return { key: country.cc, text: country.name };
    });

    const buttonDivStyle = { color: "orange", paddingTop: "10px" };
    const buttonStyle = { color: "blue", background: "none", textDecoration: "underline", border: "none", cursor: "pointer" };
    const alertDivStyle = { color: "red", paddingTop: "10px" };


    const [showAdvanced, setShowAdvanced] = React.useState(false)

    const handleCustomerNameSearch = (value: string) => {
        setValue("name", value);
        setSearchCustomerNameString(value);
    }

    const handleCustomerVatSearch = (value: string) => {
        setValue("picea_vat_number", value);
        setSearchCustomerVatNumberString(value);
    }

    const handleProductStatusChange = (value: any) => {
        if (value === "Official") {
            setValue("picea_product_expiry_date", aYearFromNow.toDateString());
        }
        else if (value === "Trial") {
            setValue("picea_product_expiry_date", twoWeeksFromNow.toDateString());
        }
        else {
            setValue("picea_product_expiry_date", aMonthFromNow.toDateString());
        }
    }

    const handleProductSelection = (value: string) => {
        if (props.customerProducts) {
            let product = props.customerProducts.find(p => p.product_id === value);
            if (product && product.expires) {
                setValue("picea_product_expiry_date", product.expires);
            }

            if (product && product.product_type) {
                setValue("picea_product_status", product.product_type);
            }
        }
    }

    const handleCustomerWithNameSelection = () => {
        if (foundCustomerWithName) {
            setFoundCustomer(foundCustomerWithName);
            setCustomerWithVatDisabled(false);
            setVatCustomerProductsDisabled(false);
            props.searchCustomerProducts(foundCustomerWithName?.customer_id);
        }
    }


    const handleCustomerWithVatSelection = () => {
        if (foundCustomerWithVat) {
            setFoundCustomer(foundCustomerWithVat);
            setCustomerWithNameDisabled(false);
            setNameCustomerProductsDisabled(false);
            props.searchCustomerProducts(foundCustomerWithVat?.customer_id);
        }
    }

    const checkCustomerProductValidy = async (customer_id: string, searchByName: boolean = true) => {
        let has_valid_products = false;
        const result = await portalApi.organization.getCustomerProducts(customer_id);
        if (result.successed && result.data) {
            const has_online_products = result.data.find((product) => {
                return product.solution.solution_id === Solutions.Online
            });
            let filtered = result.data.filter((product) => {
                return moment(product.expires) > moment() && product.solution.solution_id === Solutions.Online
            });
            if (filtered.length === 0) {
                if (searchByName && has_online_products) {
                    setCustomerWithNameDisabled(true);
                    setNameCustomerProductsDisabled(true);
                }
                else if(has_online_products) {
                    setCustomerWithVatDisabled(true);
                    setVatCustomerProductsDisabled(true);
                }
            }
            else {
                if (searchByName) {
                    setNameCustomerProductsDisabled(false);
                }
                else {
                    setVatCustomerProductsDisabled(false);
                }
            }
        }
        else {
            if (searchByName) {
                setCustomerWithNameDisabled(false);
                setNameCustomerProductsDisabled(false);
            }
            else {
                setCustomerWithVatDisabled(false);
                setVatCustomerProductsDisabled(false);
            }
        }

        return has_valid_products;
    }


    React.useEffect(() => {
        if (searchCustomerNameString && searchCustomerNameString.length > 3 && props.customers) {
            let customer = props.customers.find(custo => custo.name.toLowerCase() === searchCustomerNameString.toLowerCase());
            if (customer) {
                if (customer.disabled) {
                    setNameCustomerProductsDisabled(false);
                    setCustomerWithNameDisabled(true);
                }
                else {
                    setCustomerWithNameDisabled(false);
                    checkCustomerProductValidy(customer.customer_id, true);
                }
                props.searchPiceaCustomerId(customer.customer_id);
                setFoundCustomerWithName(customer);
            }
            else {
                setFoundCustomerWithName(undefined);
                setFoundCustomer(undefined);
                props.resetOrganizationId(false);
                setCustomerWithNameDisabled(false);

            }
        }
        else{
            setFoundCustomerWithName(undefined);
            setFoundCustomer(undefined);
            props.resetOrganizationId(false);
            setCustomerWithNameDisabled(false);
        }

    }, [searchCustomerNameString]);


    React.useEffect(() => {


        if (searchCustomerVatNumberString && searchCustomerVatNumberString.length > 3 && props.customers) {
            let customers = props.customers.filter(custo => custo.vat_number === searchCustomerVatNumberString);
            if (customers.length > 1) {
                console.log('Too many customers with same vat');
            }
            else {
                let customer = props.customers.find(custo => custo.vat_number === searchCustomerVatNumberString);
                if (customer) {
                    if (customer.disabled) {
                        setVatCustomerProductsDisabled(false);
                        setCustomerWithVatDisabled(true);
                    }
                    else {
                        setCustomerWithVatDisabled(false);
                        checkCustomerProductValidy(customer.customer_id, false);
                        props.searchPiceaCustomerVat(customer.vat_number);
                        setFoundCustomerWithVat(customer);
                    }
                }
                else {
                    setFoundCustomerWithVat(undefined);
                    setFoundCustomer(undefined);
                    props.resetOrganizationId(true);
                    setCustomerWithVatDisabled(false);

                }
            }
        }
        else {
            setFoundCustomerWithVat(undefined);
            setFoundCustomer(undefined);
            props.resetOrganizationId(true);
            setCustomerWithVatDisabled(false);

        }

    }, [searchCustomerVatNumberString]);



    React.useEffect(() => {

        if (foundCustomer) {
            setFoundCustomerWithName(undefined);
            setFoundCustomerWithVat(undefined);
            setValue("name", foundCustomer.name);
            setValue("picea_vat_number", foundCustomer.vat_number);
            setValue("picea_country", foundCustomer.country);
            setValue("picea_status", foundCustomer.status);
            setValue("picea_address", foundCustomer.address);


            if (!foundCustomer.contact_email) {
                setValue("picea_contact_email", foundCustomer.name.toLowerCase() + "@email.com");
            }
            else {
                setValue("picea_contact_email", foundCustomer.contact_email.toLowerCase());
            }
            setValue("picea_phone_number", foundCustomer.contact_phone);
            setValue("picea_contact_person", foundCustomer.contact_person);
            setValue("picea_contact_title", foundCustomer.contact_title);
            setValue("picea_technical_contact_email", foundCustomer.tech_contact_email?.toLowerCase());
            setValue("picea_customer_id", foundCustomer.customer_id);
            setValue("picea_product_status", foundCustomer.status);
            setValue("login", "");


        }
    }, [foundCustomer]);


    React.useEffect(() => {
        if (props.customerProducts && productOptions.length === 1) {
            setValue("picea_default_product_id", productOptions[0].key.toString());
            setValue("picea_product_expiry_date", props.customerProducts[0].expires);
            setValue("picea_product_status", props.customerProducts[0].product_type);
            if (props.customerProducts[0].product_type === "Official") {
                setValue("picea_product_expiry_date", aYearFromNow.toDateString());
            }
            else if (props.customerProducts[0].product_type === "Trial") {
                setValue("picea_product_expiry_date", twoWeeksFromNow.toDateString());
            }
            else {
                setValue("picea_product_expiry_date", aMonthFromNow.toDateString());
            }
        }
        else {
            if (foundCustomer && foundCustomer.status === "Official") {
                setValue("picea_product_expiry_date", aYearFromNow.toDateString());
            }
            else if (foundCustomer && foundCustomer.status === "Trial") {
                setValue("picea_product_expiry_date", twoWeeksFromNow.toDateString());
            }
            else {
                if(formData.picea_product_expiry_date === undefined)
                setValue("picea_product_expiry_date", aMonthFromNow.toDateString());
            }
        }
    }, [props.customerProducts]);



    React.useEffect(() => {
        if (customerWithNameDisabled || customerWithVatDisabled) {
            setCustomerDisabled(true);
        }
        else {
            setCustomerDisabled(false);
        }

    }, [customerWithNameDisabled, customerWithVatDisabled]);



    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <div>
                                <Controller
                                    control={control}
                                    name="name"
                                    rules={{ required: strings.ORGANIZATION.NEW_ORGANIZATION.NAME_REQUIRED, minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` } }}
                                    render={({ field, fieldState, formState }) =>
                                        <TextField
                                            required
                                            value={field.value}
                                            onChange={(event, newString: any) => {
                                                handleCustomerNameSearch(newString);
                                                field.onChange;
                                            }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.ORGANIZATION.NEW_ORGANIZATION.NAME_LABEL}
                                            title={strings.ORGANIZATION.NEW_ORGANIZATION.NAME_TITLE}
                                            placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.NAME_PLACEHOLDER}
                                            disabled={((foundCustomer && !errors.name?.message) || customerWithVatDisabled) ? true : false}
                                            errorMessage={errors.name?.message}
                                        />
                                    }
                                />
                                {(!customerWithNameDisabled && !foundCustomer && foundCustomerWithName && props.existingOrganizationId === "")
                                    && (<div style={buttonDivStyle}>{strings.ORGANIZATION.NEW_ORGANIZATION.CUSTOMER_ID_EXISTS}
                                        <button style={buttonStyle} type="button" onClick={() => handleCustomerWithNameSelection()}>
                                            {strings.ORGANIZATION.NEW_ORGANIZATION.HERE}
                                        </button>
                                    </div>)}
                                {(!foundCustomer && props.existingOrganizationId)
                                    && (<div style={buttonDivStyle}>{strings.ORGANIZATION.NEW_ORGANIZATION.CONFIGURE_EXISTING_CUSTOMER}
                                        <button style={buttonStyle} type="button" onClick={() => props.changeToExistingOrganization(props.existingOrganizationId)}>
                                            {strings.ORGANIZATION.NEW_ORGANIZATION.HERE}
                                        </button>
                                    </div>)}
                                {(!nameCustomerProductsDisabled && customerWithNameDisabled && foundCustomerWithName) && (
                                    <div style={alertDivStyle}>
                                        {strings.ORGANIZATION.NEW_ORGANIZATION.CUSTOMER_DISABLED_NAME}
                                    </div>
                                )}

                                {(!foundCustomer && nameCustomerProductsDisabled && foundCustomerWithName) && (
                                    <div style={alertDivStyle}>
                                        {strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCTS_DISABLED_NAME}
                                    </div>
                                )}

                            </div>
                            <div>
                                <div>
                                    <Controller
                                        control={control}
                                        name="picea_vat_number"
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={(event, newString: any) => {
                                                    handleCustomerVatSearch(newString);
                                                }}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.NEW_ORGANIZATION.VAT_LABEL}
                                                title={strings.ORGANIZATION.NEW_ORGANIZATION.VAT_LABEL}
                                                placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.VAT_PLACEHOLDER}
                                                disabled={(foundCustomer || customerWithNameDisabled) ? true : false}
                                                errorMessage={errors.picea_vat_number?.message}
                                            />
                                        }

                                    />
                                    {(!customerWithVatDisabled && !foundCustomer && foundCustomerWithVat && props.existingVatOrganizationId === "") && (
                                        <div style={buttonDivStyle}>{strings.ORGANIZATION.NEW_ORGANIZATION.VAT_EXISTS}
                                            <button style={buttonStyle} type="button" onClick={() => handleCustomerWithVatSelection()}>
                                                {strings.ORGANIZATION.NEW_ORGANIZATION.HERE}
                                            </button>
                                        </div>)}
                                    {(!foundCustomer && props.existingVatOrganizationId) && (
                                        <div style={buttonDivStyle}>{strings.ORGANIZATION.NEW_ORGANIZATION.CONFIGURE_EXISTING_CUSTOMER}
                                            <button type="button" style={buttonStyle} onClick={() => props.changeToExistingOrganization(props.existingVatOrganizationId)}>
                                                {strings.ORGANIZATION.NEW_ORGANIZATION.HERE}
                                            </button>
                                        </div>)}
                                    {(!vatCustomerProductsDisabled && customerWithVatDisabled) && (
                                        <div style={alertDivStyle}>
                                            {strings.ORGANIZATION.NEW_ORGANIZATION.CUSTOMER_DISABLED_VAT}
                                        </div>
                                    )}
                                    {(vatCustomerProductsDisabled && customerWithVatDisabled) && (
                                        <div style={alertDivStyle}>
                                            {strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCTS_DISABLED_VAT}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Controller
                                control={control}
                                name="picea_status"
                                rules={{ required: strings.ORGANIZATION.NEW_ORGANIZATION.STATUS_IS_REQUIRED }}
                                render={({ field }) =>
                                    <Dropdown {...field}
                                        selectedKey={field.value}
                                        required
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        options={statusOptions}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.NEW_ORGANIZATION.STATUS_LABEL}
                                        title={strings.ORGANIZATION.NEW_ORGANIZATION.STATUS_LABEL}
                                        placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.STATUS_PLACEHOLDER}
                                        disabled={(customerDisabled || foundCustomer) ? true : false}
                                        errorMessage={errors.picea_status?.message}

                                    />
                                }
                            />

                            <div>
                                {(foundCustomer && props.customerProducts && productOptions && productOptions.length > 0) && (
                                    <Controller
                                        control={control}
                                        name="picea_default_product_id"
                                        rules={{ required: strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_IS_REQUIRED }}
                                        render={({ field }) =>
                                            <Dropdown {...field}
                                                selectedKey={field.value}
                                                defaultSelectedKey={0}
                                                onChange={(ev, opt) => {
                                                    handleProductSelection(field.value);
                                                    field.onChange(opt?.key)
                                                }}
                                                options={productOptions}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.SETTINGS.SECURITY.PICEASOFT.PRODUCT_ID}
                                                title={strings.SETTINGS.SECURITY.PICEASOFT.PRODUCT_ID}
                                                placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_PLACEHOLDER}
                                                disabled={productOptions.length === 1 ? true : false}
                                                errorMessage={errors.picea_default_product_id?.message}
                                            />
                                        }
                                    />)
                                }
                                {(!foundCustomer ||
                                    (foundCustomer && (!props.customerProducts || props.customerProducts.length === 0))) && (
                                    <Controller
                                        control={control}
                                        name="picea_default_product_id"
                                        render={({ field }) =>
                                            <TextField
                                                disabled
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.SETTINGS.SECURITY.PICEASOFT.PRODUCT_ID}
                                                title={strings.SETTINGS.SECURITY.PICEASOFT.PRODUCT_ID}
                                                placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.DEFAULT_PRODUCT_ID_PLACEHOLDER}
                                                errorMessage={errors.picea_default_product_id?.message}
                                            />
                                        }
                                    />
                                )}
                            </div>


                            <Controller
                                control={control}
                                name="picea_product_status"
                                rules={{ required: strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_STATUS_IS_REQUIRED }}
                                render={({ field }) =>
                                    <Dropdown {...field}
                                        selectedKey={field.value}
                                        required
                                        onChange={(ev, opt) => {
                                            handleProductStatusChange(opt?.text);
                                            field.onChange(opt?.key)
                                        }}
                                        options={productStatusOptions}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_STATUS_LABEL}
                                        title={strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_STATUS_LABEL}
                                        placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_STATUS_LABEL}
                                        disabled={(customerDisabled || foundCustomer) ? true : false}
                                        errorMessage={errors.picea_product_status?.message}

                                    />
                                }
                            />

                            <Controller
                                control={control}
                                name="picea_product_expiry_date"
                                rules={{ required: strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_EXPIRY_IS_REQUIRED }}
                                render={({ field }) =>
                                    <Stack.Item>
                                        {onRenderLabel({
                                            title: strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_EXPIRY_LABEL,
                                            label: strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_EXPIRY_LABEL,
                                            required: true
                                        })}
                                        <DatePicker
                                            value={field.value ? new Date(field.value) : aYearFromNow}
                                            onSelectDate={(date) => {
                                                field.onChange(date);
                                            }}
                                            defaultValue={aYearFromNow.toString()}
                                            formatDate={(date?: Date) => formatDate(date)}
                                            parseDateFromString={parseDateFromString}
                                            style={{ width: 294 }}
                                            onBlur={field.onBlur}
                                            placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.PRODUCT_EXPIRY_PLACEHOLDER}
                                            strings={strings.DAY_PICKER}
                                            disabled={(customerDisabled || foundCustomer) ? true : false}
                                        />
                                    </Stack.Item>

                                }
                            />

                            <Controller
                                control={control}
                                name="picea_country"
                                rules={{ required: strings.ORGANIZATION.NEW_ORGANIZATION.COUNTRY_IS_REQUIRED }}
                                render={({ field }) =>
                                    <Stack.Item>
                                        {onRenderLabel({
                                            title: strings.ORGANIZATION.NEW_ORGANIZATION.COUNTRY_LABEL,
                                            label: strings.ORGANIZATION.NEW_ORGANIZATION.COUNTRY_LABEL,
                                            required: true
                                        })}
                                        <ComboBox {...field}
                                            className="single-select"
                                            selectedKey={field.value}
                                            disabled={(customerDisabled || foundCustomer) ? true : false}
                                            onChange={(ev, opt) => field.onChange(opt?.key)}
                                            options={countryOptions}
                                            placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.COUNTRY_PLACEHOLDER}
                                            required
                                            styles={dropdownStyles}
                                            errorMessage={errors.picea_country?.message}

                                        />
                                    </Stack.Item>

                                }
                            />


                            <Controller
                                control={control}
                                name="picea_contact_email"
                                rules={{
                                    required: strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_EMAIL_IS_REQUIRED, pattern: { value: /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/, message: strings.ORGANIZATION.NEW_ORGANIZATION.EMAIL_NOT_VALID }
                                }}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        required
                                        disabled={(customerDisabled || foundCustomer) ? true : false}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_EMAIL_LABEL}
                                        title={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_EMAIL_LABEL}
                                        placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_EMAIL_PLACEHOLDER}
                                        errorMessage={errors.picea_contact_email?.message}

                                    />
                                }
                            />



                            <Controller
                                control={control}
                                name="domain"
                                rules={{ required: strings.ORGANIZATION.NEW_ORGANIZATION.DOMAIN_REQUIRED }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        value={field.value}
                                        required
                                        onChange={field.onChange}
                                        disabled={(customerDisabled) ? true : false}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.NEW_ORGANIZATION.DOMAIN_LABEL}
                                        title={strings.ORGANIZATION.NEW_ORGANIZATION.DOMAIN_TITLE}
                                        placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.DOMAIN_PLACEHOLDER}
                                        errorMessage={errors.domain?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="login"
                                rules={{ required: strings.ORGANIZATION.NEW_ORGANIZATION.LOGIN_REQUIRED }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        value={field.value}
                                        required
                                        onChange={field.onChange}
                                        disabled={(customerDisabled) ? true : false}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        suffix={formData.domain && '@' + formData.domain}
                                        label={strings.ORGANIZATION.NEW_ORGANIZATION.LOGIN_LABEL}
                                        title={strings.ORGANIZATION.NEW_ORGANIZATION.LOGIN_TITLE}
                                        placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.LOGIN_PLACEHOLDER}
                                        errorMessage={errors.domain?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="password"
                                rules={validatePassword(undefined, true)}
                                render={({ field, fieldState, formState }) =>
                                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                                        <Stack.Item grow>
                                            <TextField
                                                required
                                                disabled={(customerDisabled) ? true : false}
                                                value={field.value}
                                                styles={{
                                                    fieldGroup: {
                                                        borderColor: field.value && !errors.password?.message ? "#5DCA42" : undefined, ':after': {
                                                            borderColor: field.value && !errors.password?.message ? "#5DCA42" : undefined
                                                        }
                                                    }
                                                }}
                                                onChange={(ev, val) => { field.onChange(val); trigger("repeatPassword") }}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                type="password"
                                                canRevealPassword
                                                label={strings.ORGANIZATION.NEW_ORGANIZATION.PASSWORD_LABEL}
                                                title={strings.ORGANIZATION.NEW_ORGANIZATION.PASSWORD_TITLE}
                                                placeholder={strings.COMMON.CREATE_PASSWORD}
                                                errorMessage={errors.password?.message}
                                            />
                                        </Stack.Item>
                                        <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>
                                            <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().password && !errors.password) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                        </Stack.Item>
                                    </Stack>
                                }
                            />
                            <Stack.Item>
                                <PasswordPolicy isManager />
                                <Controller
                                    control={control}
                                    name="repeatPassword"
                                    rules={{
                                        // required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED,
                                        validate: (value) => {
                                            return (watch().password === value) ? true : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED
                                        }
                                    }}
                                    render={({ field }) =>
                                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                                            <Stack.Item grow>
                                                <TextField
                                                    value={field.value}
                                                    disabled={(customerDisabled) ? true : false}
                                                    styles={{
                                                        fieldGroup: {
                                                            borderColor: field.value && !errors.repeatPassword?.message ? "#5DCA42" : undefined, ':after': {
                                                                borderColor: field.value && !errors.repeatPassword?.message ? "#5DCA42" : undefined
                                                            }
                                                        }
                                                    }}
                                                    type="password"
                                                    required
                                                    canRevealPassword
                                                    onChange={field.onChange}
                                                    label={strings.COMMON.CONFIRM_PASSWORD}
                                                    placeholder={strings.COMMON.CONFIRM_PASSWORD}
                                                    errorMessage={errors.repeatPassword?.message}
                                                />
                                            </Stack.Item>
                                            <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>
                                                <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().repeatPassword && !errors.repeatPassword) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                            </Stack.Item>
                                        </Stack>
                                    }
                                />
                            </Stack.Item>
                            <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>

                                <SecondaryButton
                                    type="button"
                                    iconProps={{
                                        iconName: showAdvanced ? "ChevronUpSmall" : 'ChevronDownSmall',
                                        styles: {
                                            root: { color: 'black', flexDirection: 'row-reverse' }
                                        }
                                    }}
                                    onClick={(e) => setShowAdvanced(!showAdvanced)}
                                    className="btn btn-primary mb-5 float-right">

                                    Advanced
                                </SecondaryButton>
                            </Stack.Item>
                            {showAdvanced && (
                                <Stack tokens={{ childrenGap: 16, padding: 0 }}>
                                    <Controller
                                        control={control}
                                        name="picea_address"
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                disabled={(customerDisabled || foundCustomer) ? true : false}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.NEW_ORGANIZATION.ADDRESS_LABEL}
                                                title={strings.ORGANIZATION.NEW_ORGANIZATION.ADDRESS_LABEL}
                                                placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.ADDRESS_PLACEHOLDER}
                                                errorMessage={errors.picea_address?.message}
                                            />
                                        }
                                    />
                                    <Controller
                                        control={control}
                                        name="picea_phone_number"
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                disabled={(customerDisabled || foundCustomer) ? true : false}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.NEW_ORGANIZATION.PHONE_LABEL}
                                                title={strings.ORGANIZATION.NEW_ORGANIZATION.PHONE_LABEL}
                                                placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.PHONE_PLACEHOLDER}
                                                errorMessage={errors.picea_phone_number?.message}
                                            />
                                        }
                                    />
                                    <Controller
                                        control={control}
                                        name="picea_contact_person"
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                disabled={(customerDisabled || foundCustomer) ? true : false}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_PERSON_LABEL}
                                                title={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_PERSON_LABEL}
                                                placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_PERSON_PLACEHOLDER}
                                                errorMessage={errors.picea_contact_person?.message}
                                            />
                                        }
                                    />

                                    <Controller
                                        control={control}
                                        name="picea_contact_title"
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                disabled={(customerDisabled || foundCustomer) ? true : false}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_TITLE_LABEL}
                                                title={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_TITLE_LABEL}
                                                placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_TITLE_PLACEHOLDER}
                                                errorMessage={errors.picea_contact_title?.message}
                                            />
                                        }
                                    />

                                    <Controller
                                        control={control}
                                        name="picea_technical_contact_email"
                                        rules={{ pattern: { value: /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/, message: strings.ORGANIZATION.NEW_ORGANIZATION.EMAIL_NOT_VALID } }}
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                disabled={(customerDisabled || foundCustomer) ? true : false}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.NEW_ORGANIZATION.TECHNICAL_CONTACT_EMAIL_LABEL}
                                                title={strings.ORGANIZATION.NEW_ORGANIZATION.TECHNICAL_CONTACT_EMAIL_LABEL}
                                                placeholder={strings.ORGANIZATION.NEW_ORGANIZATION.TECHNICAL_CONTACT_EMAIL_PLACEHOLDER}
                                                errorMessage={errors.picea_technical_contact_email?.message}
                                            />
                                        }
                                    />
                                    <div style={{ display: "none" }}>
                                        <Controller
                                            control={control}
                                            name="picea_customer_id"
                                            render={({ field }) =>
                                                <TextField
                                                    value={field.value}
                                                    hidden={true}
                                                    onChange={(event, newString: any) => {
                                                        field.onChange;
                                                    }}
                                                    onRenderLabel={onRenderLabel}
                                                />
                                            }
                                        />
                                    </div>
                                </Stack>
                            )}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>

                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        {!foundCustomer || (foundCustomer && (!nameCustomerProductsDisabled || !vatCustomerProductsDisabled)) ? (
                            <PrimaryButton disabled={customerDisabled} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>) : (
                            <Tooltip content={strings.ORGANIZATION.NEW_ORGANIZATION.NO_VALID_PRODUCTS}>
                                <PrimaryButton disabled={true}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                            </Tooltip>
                        )

                        }
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();
