import { DefaultButton, FontWeights, getTheme, IconButton, mergeStyleSets, MessageBar, MessageBarType, Modal, ScrollablePane, Spinner, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { ConfirmationForm } from '../../../../../components/shared/forms/ConfirmationForm';
import { CustomPanel } from '../../../../../components/shared/panel/CustomPanel';
import { portalApi } from '../../../../../core/api/api';
import { IPoint } from '../../../../../core/store';
import { strings } from '../../../../../localization/strings';
import { StructurePoint } from '../../../OrganizationStructure/Elements/StructurePoint/StructurePoint';
import { PointForm } from '../../../../../components/shared/forms/PointForm';
import { SomethingWentWrong } from '../../../../../components/shared/somethingWentWrong/SomethingWentWrong';

export const CompanyPoint: React.FC = () => {

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const [error, setError] = React.useState<string>()
    const [formError, setFormError] = React.useState<string>()

    const { companyId, pointId } = useParams<{ companyId: string, pointId: string }>();
    const [pointState, setPointState] = React.useState<{ fetched: boolean, data?: IPoint }>({ fetched: false })

    const [confirmation, { setTrue: showConfirmation, setFalse: hideConfirmation }] = useBoolean(false)

    const history = useHistory();

    React.useEffect(() => {
        getPoint(pointId)
    }, [])

    const getPoint = async (id: string) => {
        setError(undefined)
        const result = await portalApi.organization.points.getPoint(id)
        console.log(result)
        if (result.successed && result.data) {
            setPointState({ fetched: true, data: result.data })
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation()
            setError(result.errors[0].description)
        }
    }

    const updatePoint = async (item: IPoint) => {
        setFormError(undefined)
        startFetch()
        const result = await portalApi.organization.points.updatePoint(item)
        if (result.successed && result.data) {
            setPointState({ fetched: true, data: result.data })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
        }
    }

    const deletePoint = async (id: string) => {
        setError(undefined)
        startFetch()
        const result = await portalApi.organization.points.deletePoint(id)
        if (result.successed) {
            history.push(`/organization/companies/${companyId}`);
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation();
            setError(result.errors[0].description);
            return;
        }
        if (!result.successed) {
            setError("There is a problem! Point wasn't deleted! Server response false without an error")
        }
        stopFetch()
        hideConfirmation()
    }

    const onFormSubmit = (data: IPoint) => {
        pointState.data?.id && updatePoint(data)
    }

    return (
        <Stack verticalFill>
            {!error && (pointState.data && (
                <Stack grow verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <StructurePoint onEdit={showPanel} point={pointState.data} onDelete={showConfirmation} updateState={(data) => setPointState({ ...pointState, data: data })} />
                    </ScrollablePane>
                </Stack>
            ) || (
                    <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                        <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                    </Stack>
                ))}
            {error && (
                <Stack verticalFill>
                    {error.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                    )}
                    <Stack.Item verticalFill>
                        <SomethingWentWrong action={() => getPoint(pointId)} />
                    </Stack.Item>
                </Stack>
            )}

            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={hidePanel}
                title={strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.ADD_POINT}
            >
                {formError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {formError}
                    </MessageBar>
                )}
                {!formError && !fetching && (
                    <PointForm
                        onSubmit={(data: IPoint) => onFormSubmit(data)}
                        onCancel={hidePanel}
                        data={{ ...pointState.data, companyId: companyId } as IPoint} />
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
            </CustomPanel >
            {pointState.data?.id && (
                <Modal isOpen={confirmation} onDismiss={hideConfirmation} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideConfirmation}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={() => pointState.data?.id && deletePoint(pointState.data?.id)}
                            onCancel={hideConfirmation}
                            requiredMessageString={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_REQUIRED}
                            confirmationCheckString={pointState.data?.name ?? ''}
                            placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_PLACEHOLDER}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TEXT1} <b>"{pointState.data?.name}"</b></Text>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TEXT2}</Text>
                                <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{pointState.data?.name}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>)}
        </Stack>
    )
}


const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};