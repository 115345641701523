import {
    Checkbox,
    CommandBar,
    FontSizes,
    FontWeights,
    getTheme,
    ICommandBarItemProps,
    IconButton,
    IContextualMenuItem,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    Panel,
    PanelType,
    PrimaryButton,
    ScrollablePane,
    Separator,
    SharedColors,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TooltipHost
} from "@fluentui/react"
import {useBoolean} from "@uifabric/react-hooks"
import React, {Dispatch} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Configurator} from "../../../../../components/shared/configurator/Configurator"
import {WorkflowSettings} from "../../../../../components/shared/configurator/parameters/WorkflowSettings"
import {
    StandardModeConfigurator
} from "../../../../../components/shared/configurator/stages/assessment/dataCollection/modeConfigurator/StandardModeConfigurator"
import {
    DiagnosticsModeConfigurator
} from "../../../../../components/shared/configurator/stages/assessment/diagnostics/modeConfigurator/DiagnosticsModeConfigurator"
import {
    InspectionModule,
    TInspectionSettingsPivotStates
} from "../../../../../components/shared/configurator/stages/assessment/InspectionModule"
import {
    QuestionsConfigurator
} from "../../../../../components/shared/configurator/stages/assessment/interview/questionsConfigurator/QuestionsConfigurator"
import {
    PhotographicModeConfigurator
} from "../../../../../components/shared/configurator/stages/assessment/photographic/modeConfigurator/PhotographicModeConfigurator"
import {
    SoftwareModeConfigurator
} from "../../../../../components/shared/configurator/stages/assessment/software/modeConfigurator/SoftwareModeConfigurator"
import {
    EraseConfigurator
} from "../../../../../components/shared/configurator/stages/assessment/erase/modeConfigurator/EraseConfigurator"
import {
    IdentificationModule,
    TIdentificationModuleSettingsPivotStates
} from "../../../../../components/shared/configurator/stages/identification/IdentificationModule"
import {
    CatalogTypes,
    IAssessmentConfig,
    IdentificationMethods,
    IIdentificationGlobalConfig,
    IIdentificationSearchFormConfig,
    IInspectionConfig,
    IInspectionConfigUI,
    IInspectionModuleConfig,
    IInterviewConfig,
    IInterviewWithQuestionsConfig,
    Inspections,
    InterviewModes,
    IResponseError,
    ProcessStages
} from "@piceasoft/core"
import {Section} from "../../../../../components/shared/decorations/Section"
import {actionCreators, getStageInspectionModules} from "../../../../../core/actions/configurator-actions"
import {portalApi} from "../../../../../core/api/api"
import {getWorkflowStages} from "../../../../../core/reducers/configurator-reducer"
import {IStore, IWorkflow} from "../../../../../core/store"
import {
    getLocalizedIdentificationMethod,
    getLocalizedInspectionModeName,
    getLocalizedInspectionName
} from "../../../../../localization/helpers/transaction"
import {strings} from "../../../../../localization/strings"
import {GradesCategoriesForm} from "../../../../../components/shared/forms/GradesCategoriesForm"
import {SecondaryButton} from "../../../../../components/shared/buttons/SecondaryButton"
import {DangerButton} from "../../../../../components/shared/buttons/DangerButton"
import {WorkflowErrorsRender} from "../ValidationInspectionsModules"
import {
    AIGradingModeConfigurator
} from "../../../../../components/shared/configurator/stages/assessment/aigrading/modeConfigurtor/AIGradingModeConfigurator"
import {
    StageInspectionItemCommonSettingsChildren
} from "../../../../../components/shared/configurator/stages/control/StageInspectionItemCommonSettingsChildren"
import {GradesForm} from "../../../../../components/shared/forms/GradesForm"
import {Experience} from "../../../../../components/shared/configurator/helpers/evisibility";
import {ServiceTypes} from "../../../../../core/store/typings/ServiceTypes"
import {
    CSHHelpLink,
    getHelpArticleIdForIdentificationModule,
    getHelpArticleIdForInspectionModule
} from "../../../../../components/shared/help/CSHHelp"
import {LandingPivot} from "../../../../../components/shared/configurator/stages/landing/preview/LandingPivot";
import SelfServiceConfiguratorPivot
    from "../../../../../components/shared/configurator/stages/selfService/pivots/SelfServiceConfiguratorPivot";
import SelfServiceConfigurator
    from "../../../../../components/shared/configurator/stages/selfService/SelfServiceConfigurator";
import SelfService from "../../../../../components/shared/configurator/stages/SelfService"

type TServiceConfigurationPivotProps = {
    serviceId: number
    workflow: IWorkflow
    isRemoved: boolean
    onImportWorkflow: () => void
    refreshService: () => void
    channel: number
    serviceType: number
    deviceCheckSolution: number,
    catalogId?: string,
    serviceName?: string
}

export const ServiceConfigurationConfigurationPivot: React.FC<TServiceConfigurationPivotProps> = ({ isRemoved, onImportWorkflow, ...props }) => {

    const [isShowConfiguration, { setTrue: showConfiguration, setFalse: hideConfiguration }] = useBoolean(false)

    const [isFetching, setIsFetching] = React.useState(false)
    const [noChanges, setNoChanges] = React.useState(true)

    const [isShowGrades, { setTrue: showGrades, setFalse: hideGrades }] = useBoolean(false)
    const [isShowParameters, { setTrue: showParameters, setFalse: hideParameters }] = useBoolean(false)
    const [isShowWarning, { setTrue: showWarning, setFalse: hideWarning }] = useBoolean(false)

    const [isShowIdentificationModule, { setTrue: showIdentificationModule, setFalse: hideIdentificationModule }] = useBoolean(false)
    const [identificationModuleIndex, setIdentificationModuleIndex] = React.useState<number>()

    const [isShowStageModule, { setTrue: showStageModule, setFalse: hideStageModule }] = useBoolean(false)
    const [stageModuleState, setStageModuleState] = React.useState<{ stage: ProcessStages, index: number }>()

    const [settingsPivotState, setSettingsPivotState] = React.useState<string>()
    const [isStagePanelOpened, { setTrue: openStagePanel, setFalse: closeStagePanel }] = useBoolean(false)
    const [errors, setErrors] = React.useState<IResponseError[]>([])

    let [disableOkButton, setDisableOkButton] = React.useState(false)
    const globalCatalogConfig = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === identificationModuleIndex) as IIdentificationGlobalConfig)

    const [isShowLandingModule, { setTrue: showLandingModule, setFalse: hideLandingModule }] = useBoolean(false)

    const store = useSelector((s: IStore) => s)
    const workflow = store.configurator

    const dispatch = useDispatch();

    const okButtonDisabled = (value: boolean) => {
        setDisableOkButton(value);
    }

    React.useEffect(() => {
        if (Array.isArray(workflow.deviceTypes)) {
            if (workflow.deviceTypes.length === 0) {
                workflow.deviceTypes = undefined;
            }
        }

        if (globalCatalogConfig !== undefined) {
            if (globalCatalogConfig.types !== undefined) {
                if (globalCatalogConfig.types.length === 0) {
                    globalCatalogConfig.types = undefined;
                }
            }
        }

        const timerID = setTimeout(() => {
            setDisableOkButton(false)
        }, 500);

        return () => {
            clearTimeout(timerID);
        };

    }, [isShowParameters, isShowIdentificationModule])

    const getPromoData = async () => {
        const resultPromotions = await portalApi.organization.promotions.getPromotions()
        const isSelfService = props.serviceType === ServiceTypes.SelfService && props.channel == Experience.Mobile;
        const catalogType = isSelfService ? CatalogTypes.Repair : props.workflow?.useDiscountCatalogs ? CatalogTypes.GradeDiscount : CatalogTypes.Tradein;
        const resultCatalogs = await portalApi.organization.catalogs.getCatalogsByCatalogType(catalogType)
        if (resultPromotions.successed && resultCatalogs.successed) {
            const providers = resultCatalogs.data?.filter((elem) => !elem.removed)
            dispatch(actionCreators.promo.onConfigCommit(resultPromotions.data, providers))
        }
    }
    React.useEffect(() => {
        props.workflow.catalogId = props.catalogId;
        dispatch(actionCreators.initializeWorkflow(props.workflow, props.channel))
        getPromoData()
    }, [])

    React.useEffect(() => {
        props.workflow.catalogId = props.catalogId;
        dispatch(actionCreators.initializeWorkflow(props.workflow, props.channel))
    }, [props.workflow])

    React.useEffect(() => {
        if (identificationModuleIndex && settingsPivotState) {
            showIdentificationModule()
        }
        if (!(identificationModuleIndex && settingsPivotState)) {
            hideIdentificationModule()
        }
    }, [identificationModuleIndex])

    React.useEffect(() => {
        if (stageModuleState && settingsPivotState) {
            showStageModule()
        }
        if (!(stageModuleState && settingsPivotState)) {
            hideStageModule()
        }
    }, [stageModuleState, settingsPivotState])

    const downloadWorkflow = () => {
        var a = document.createElement("a");
        let workflow_temp = workflow;
        workflow_temp.channel = props.channel;
        workflow_temp.deviceCheckSolution = props.deviceCheckSolution;
        var file = new Blob([JSON.stringify(workflow_temp)], { type: 'application/json' });
        a.href = URL.createObjectURL(file);
        a.download = 'workflow.json';
        a.click();
        console.log(workflow_temp)
    }
    const onChangeDispatch: Dispatch<any> = (value: any) => {
        if (noChanges) {
            setNoChanges(false)
        }
        dispatch(value)
    }

    React.useEffect(() => {
        if (!isStagePanelOpened) {
            setStageModuleState(undefined)
            setSettingsPivotState(undefined)
        }
    }, [isStagePanelOpened])

    const onShowIdentificationModule = (index: number, pivot: string) => {
        console.log(index, " ", pivot)
        setIdentificationModuleIndex(index)
        setSettingsPivotState(pivot)
    }

    const onShowLandingModule = () => {
        showLandingModule()
    }

    const onSelfServiceModule = () => {
        setStageModuleState({stage: ProcessStages.SelfService, index: 0})
        openStagePanel()
    }

    const onShowStageModule = (stage: ProcessStages, index: number, pivot: string) => {
        // console.log(index, " ", pivot)
        setStageModuleState({ stage, index })
        if (pivot !== "questions") {
            setSettingsPivotState(pivot)
        }
        if (pivot === "questions") {
            openStagePanel()
        }
    }

    React.useEffect(() => {
        console.log(settingsPivotState)
    }, [settingsPivotState])

    const onHideIdentificationModule = () => {
        setIdentificationModuleIndex(undefined)
        setSettingsPivotState(undefined)
    }

    const onHideStageModule = () => {
        setStageModuleState(undefined)
        setSettingsPivotState(undefined)
    }

    const onWorkflowUndo = () => {
        props.workflow.catalogId = props.catalogId;
        dispatch(actionCreators.initializeWorkflow(props.workflow))
        setNoChanges(true)
    }
    const onWorkflowSave = async () => {
        console.log(workflow)
        setErrors([])
        setIsFetching(true)
        console.log(workflow?.assessment?.modules?.map(k => k.index))
        let currentWorkflow: IWorkflow = {
            ...workflow,
            identification: {
                ...workflow.identification,
                items: workflow.identification.items?.map(m => {
                    if (m.method === IdentificationMethods.SearchForm) {
                        const newMethod = m as IIdentificationSearchFormConfig
                        return {
                            ...newMethod,
                            manufacturers: {
                                exclude: !newMethod?.includeManufacturers ? newMethod?.manufacturers?.exclude : undefined,
                                include: newMethod?.includeManufacturers ? newMethod?.manufacturers?.include : undefined
                            }
                        } as IIdentificationSearchFormConfig
                    }
                    return m
                })
            },
            assessment: workflow.assessment ? ({
                ...workflow.assessment,
                modules: workflow.assessment?.modules?.map(m =>
                ({
                    ...m,
                    config: {
                        ...m.config,
                        dependencyInspections:
                            m.config.dependencyInspections?.filter(i => workflow.assessment?.modules?.map(k => k.index)
                                .includes(i))
                                .filter(i => i !== m.index)
                    }
                })) as IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>[]
            } as IAssessmentConfig) : undefined
        }
        console.log(currentWorkflow)

        if (workflow.preOffer?.disabled) {
            currentWorkflow = { ...currentWorkflow, preOffer: undefined }
        }

        if (workflow.assessment?.disabled || !workflow.assessment) {
            currentWorkflow = { ...currentWorkflow, assessment: undefined }
        }
        else {
            currentWorkflow = {
                ...currentWorkflow, assessment: {
                    ...currentWorkflow.assessment,
                    modules: currentWorkflow.assessment?.modules?.map(m => {
                        if (m.type === Inspections.Interview) {

                            m.config.errors = m.config.errors?.filter((error) => error.code !== 5)
                            const interviewConfig = m.config as IInterviewConfig
                            if ([InterviewModes.List, InterviewModes.Sequence].includes(interviewConfig.mode)) {
                                let config = interviewConfig.config as IInterviewWithQuestionsConfig
                                const questions = config.questions.map(q => {
                                    let newAnswers = q.answers;
                                    newAnswers.forEach((a, index) => {
                                        if (a.errors?.find((error) => error.code === 1)) {
                                            a.grade = "!WASRESET"
                                            a.errors = a.errors.filter((error) => error.code !== 1)
                                        }
                                        newAnswers[index] = { ...a, index: a.index ?? Math.max(-1, ...newAnswers.map(i => i.index ?? -1)) + 1 }
                                    })
                                    return ({
                                        ...q,
                                        answers: newAnswers
                                    })
                                })
                                return {
                                    ...m,
                                    config: {
                                        ...interviewConfig,
                                        config: {
                                            ...config,
                                            questions: questions
                                        } as IInterviewWithQuestionsConfig
                                    } as IInterviewConfig,
                                } as IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>
                            }
                        }
                        return m
                    })
                },
                temporaryData: { previousGrades: undefined, previousGradesCategories: undefined },
            } as IWorkflow
        }

        if (workflow.postOffer?.disabled) {
            currentWorkflow = { ...currentWorkflow, postOffer: undefined }
        }
        if (workflow.contract?.disabled) {
            currentWorkflow = { ...currentWorkflow, contract: undefined }
        }

        if (workflow.control?.disabled) {
            currentWorkflow = { ...currentWorkflow, control: undefined }
        }

        if (workflow.offer?.disabled) {
            currentWorkflow = { ...currentWorkflow, offer: undefined }
        }

        if (workflow.resources?.disabled) {
            currentWorkflow = { ...currentWorkflow, resources: undefined }
        }

        if (workflow.landing?.disabled) {
            currentWorkflow = { ...currentWorkflow, landing: undefined }
        }

        if (workflow.result?.disabled) {
            currentWorkflow = { ...currentWorkflow, result: undefined }
        }

        if (workflow.contentTransfer?.disabled) {
            currentWorkflow = { ...currentWorkflow, contentTransfer: undefined }
        }

        currentWorkflow.stages = getWorkflowStages(currentWorkflow)
        const result = await portalApi.organization.services.updateServiceConfig(currentWorkflow, props.serviceId, isValidWorkflow());
        console.log(result)
        if (result.successed) {
            setNoChanges(true)
            props.refreshService()
        }
        setTimeout(() => {
            if (result.errors) {
                setErrors(result.errors)
            }
            setIsFetching(false)
        }, 500)
        getPromoData()

    }

    const isValidWorkflowCheck = () => {
        let existingErrors = false
        workflow.stages?.forEach((stage) => {
            switch (stage.type) {
                case ProcessStages.Identification:
                    if (workflow.identification.errors && workflow.identification.errors.length > 0) {
                        existingErrors = true
                    }
                    return
                case ProcessStages.Assessment:
                    if (!workflow.assessment?.disabled) {
                        if ((workflow.assessment?.modules.find((module) => module.config.errors
                            && module.config.errors.length > 0))
                            || workflow.assessment?.errors && workflow.assessment.errors.length > 0) {
                            existingErrors = true
                        }
                    }
                    return
                case ProcessStages.Contract:
                    if (!workflow.contract?.disabled) {
                        if ((workflow.contract?.errors && workflow.contract.errors.length > 0)) {
                            existingErrors = true
                        }
                    }

                    return
                case ProcessStages.Control:
                    if (!workflow.control?.disabled) {
                        if ((workflow.control?.modules.find((module) => module.config.errors
                            && module.config.errors.length > 0))
                            || workflow.control?.errors && workflow.control.errors.length > 0) {
                            existingErrors = true
                        }
                    }
                    return
                case ProcessStages.PreOffer:
                    if (!workflow.preOffer?.disabled) {
                        if ((workflow.preOffer?.errors && workflow.preOffer.errors.length > 0)) {
                            existingErrors = true
                        }
                        if (workflow.commonOffer?.errors && workflow.commonOffer.errors.length > 0) {
                            existingErrors = true
                        }
                        if (workflow.commonOffer?.providers?.some(p => p.errors && p.errors.length > 0)){
                            existingErrors = true
                        }
                    }
                    return
                case ProcessStages.PostOffer:
                    if (!workflow.preOffer?.disabled) {
                        if ((workflow.preOffer?.errors && workflow.preOffer.errors.length > 0)) {
                            existingErrors = true
                        }
                        if (workflow.commonOffer?.errors && workflow.commonOffer.errors.length > 0) {
                            existingErrors = true
                        }
                        if (workflow.postOffer?.modules && (workflow.postOffer?.modules.find((module) => module.config.errors
                            && module.config.errors.length > 0))) {
                            existingErrors = true
                        }
                        if (workflow.commonOffer?.providers?.some(p => p.errors && p.errors.length > 0)){
                            existingErrors = true
                        }
                    }
                    return
                case ProcessStages.SelfService:
                    if (workflow.selfService?.repairOffers?.providers?.some(p => p.errors && p.errors.length > 0)) {
                        existingErrors = true; 
                    }
                    return
            }
        })
        if (workflow.errors && workflow.errors.length > 0) {
            existingErrors = true
        }
        console.log("isValidWorkflowCheck, existingErrors: " + existingErrors)
        return existingErrors
    }
    const onWorkflowSaveConfiguration = () => {
        isValidWorkflowCheck() ? showWarning() : onWorkflowSave()
    }
    const onWorkflowSaveWithWarnings = () => {
        onWorkflowSave()
        hideWarning()
    }
    const isValidWorkflow = () => {
        let valid = false
        const errorModule = workflow.assessment?.modules.find((module) => module.errors && module.errors.length > 0)
        if ((!workflow.errors || workflow.errors.length === 0) && !errorModule && !isValidWorkflowCheck()) {
            valid = true
        }
        console.log("isValidWorkflow: " + valid);
        return valid
    }

    const stagesConfig: TStageMenuItemProps[] = [
        {
            key: 'landing',
            text: strings.CONSTRUCTOR.INSPECTIONS.LANDING.LANDING_PAGE,
            checked: workflow?.landing ? (workflow?.landing?.disabled ? false : true) : false,
            disabled: props.channel != Experience.Mobile,
            iconName: 'Globe',
            stage: ProcessStages.Landing,
        },
        {
            key: 'resources',
            text: strings.CONSTRUCTOR.COMMAND_BAR.STAGES.RESOURCES.TITLE,
            checked: workflow?.resources ? (workflow?.resources?.disabled ? false : true) : false,
            disabled: props.channel != Experience.Retail || (props.channel == Experience.Retail && ServiceTypes.ContentTransfer == props.serviceType),
            iconName: 'Globe',
            stage: ProcessStages.Resources,
        },
        {
            key: 'preOffer',
            text: strings.CONSTRUCTOR.COMMAND_BAR.STAGES.PRE_OFFER.TITLE,
            checked: workflow?.preOffer ? (workflow?.preOffer?.disabled ? false : true) : false,
            disabled: (props.channel == Experience.Retail && ServiceTypes.ContentTransfer == props.serviceType) || (props.channel == Experience.Mobile && ServiceTypes.SelfService == props.serviceType) ,
            iconName: 'Processing',
            stage: ProcessStages.PreOffer,
            channel: props.channel
        },
        {
            key: 'assessment',
            text: strings.CONSTRUCTOR.COMMAND_BAR.STAGES.ASSESSMENT.TITLE,
            checked: workflow?.assessment ? (workflow?.assessment?.disabled ? false : true) : false,
            disabled: (props.channel == Experience.Retail && ServiceTypes.ContentTransfer == props.serviceType) || (props.channel == Experience.Mobile && ServiceTypes.SelfService == props.serviceType),
            iconName: 'Processing',
            stage: ProcessStages.Assessment,
        },
        {
            key: 'postOffer',
            text: strings.CONSTRUCTOR.COMMAND_BAR.STAGES.POST_OFFER.TITLE,
            checked: workflow?.postOffer ? (workflow?.postOffer?.disabled ? false : true) : false,
            disabled: (props.channel == Experience.Retail && ServiceTypes.ContentTransfer == props.serviceType) || (props.channel == Experience.Mobile && ServiceTypes.SelfService == props.serviceType),
            iconName: 'Processing',
            stage: ProcessStages.PostOffer,
        },
        {
            key: 'contract',
            text: strings.CONSTRUCTOR.COMMAND_BAR.STAGES.CONTRACT.TITLE,
            checked: workflow?.contract ? (workflow?.contract?.disabled ? false : true) : false,
            disabled: (props.channel == Experience.Mobile && ServiceTypes.SelfService == props.serviceType),
            iconName: 'InsertSignatureLine',
            stage: ProcessStages.Contract,
        },
        // {
        //     key: 'control',
        //     text: strings.CONSTRUCTOR.COMMAND_BAR.STAGES.CONTROL.TITLE,
        //     checked: workflow?.control ? (workflow?.control?.disabled ? false : true) : false,
        //     iconName: 'InboxCheck',
        //     stage: ProcessStages.Control,
        // },
        {
            key: 'result',
            text: strings.CONSTRUCTOR.COMMAND_BAR.STAGES.RESULT.TITLE,
            checked: workflow?.result ? (workflow?.result?.disabled ? false : true) : false,
            disabled: (props.channel == Experience.Mobile && ServiceTypes.SelfService == props.serviceType),
            iconName: 'InboxCheck',
            stage: ProcessStages.Result,
        },
    ]

    type TStageMenuItemProps = { key: string, text: string, checked: boolean, stage: ProcessStages, iconName: string, disabled?: boolean, channel?: Experience }

    const getStageMenuItem = (props: TStageMenuItemProps): IContextualMenuItem => {
        return {
            key: props.key,
            text: props.text,
            checked: props.checked,
            disabled: props.disabled,
            onClick: () => {
                onChangeDispatch(actionCreators.toggleStage(props.stage, props.channel))
            },
            onRender: (item: IContextualMenuItem) => <Checkbox styles={{ root: { padding: 4 } }} label={item.text} checked={item.checked} disabled={item.disabled} onChange={() => item.onClick && item.onClick()} />,
            iconProps: { iconName: props.iconName },
        }
    }

    const _items: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: strings.CONSTRUCTOR.COMMAND_BAR.STAGES.TITLE,
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Step' },
            subMenuProps: {
                styles: { list: { padding: 12 } },
                items: stagesConfig.map(i => getStageMenuItem(i))
            },

        },
        {
            key: 'grades',
            text: workflow.useGradesCategories ? strings.CONSTRUCTOR.COMMAND_BAR.GRADES_CATEGORIES.TITLE : strings.CONSTRUCTOR.COMMAND_BAR.GRADES.TITLE,
            iconProps: { iconName: 'AssessmentGroup' },
            onClick: showGrades,
        },
        {
            key: 'common',
            text: strings.CONSTRUCTOR.COMMAND_BAR.PARAMETERS.TITLE,
            iconProps: { iconName: 'Repair' },
            onClick: showParameters,
        },
        {
            key: 'discard',
            text: strings.CONSTRUCTOR.COMMAND_BAR.UNDO,
            disabled: noChanges,
            iconProps: { iconName: 'Undo' },
            onClick: onWorkflowUndo,
        },
        {
            key: 'save',
            text: strings.CONSTRUCTOR.COMMAND_BAR.SAVE,
            disabled: noChanges,
            iconProps: { iconName: 'Save' },
            onClick: onWorkflowSaveConfiguration
        },
        // {
        //     key: 'check',
        //     text: strings.CONSTRUCTOR.COMMAND_BAR.CHECK,
        //     iconProps: { iconName: 'CheckMark' },
        //     onClick: checkConfiguration
        // },
    ];

    const _overflowItems: ICommandBarItemProps[] = [
        { key: 'upload', text: strings.CONSTRUCTOR.COMMAND_BAR.UPLOAD_CONFIG, onClick: onImportWorkflow, iconProps: { iconName: 'Upload' } },
        { key: 'download', disabled: !workflow, text: strings.CONSTRUCTOR.COMMAND_BAR.DOWNLOAD_CONFIG, onClick: downloadWorkflow, iconProps: { iconName: 'Download' } },
    ];

    const _farItems: ICommandBarItemProps[] = [
        {
            key: 'tile',
            text: strings.CONSTRUCTOR.COMMAND_BAR.SHOW_CONFIG,
            disabled: !workflow,
            // This needs an ariaLabel since it's icon-only
            ariaLabel: 'Grid view',
            iconOnly: true,
            iconProps: { iconName: 'View' },
            onClick: () => showConfiguration(),
        },
        // {
        //     key: 'info',
        //     text: strings.CONSTRUCTOR.COMMAND_BAR.CONFIG_DOCUMENTATION,
        //     // This needs an ariaLabel since it's icon-only
        //     ariaLabel: 'Info',
        //     iconOnly: true,
        //     iconProps: { iconName: 'Unknown' },
        //     onClick: () => console.log('Info'),
        // },
    ];

    const onRenderSectionHeader = () => {
        let filteredItems;
        if ((props.channel === Experience.Retail && props.serviceType == ServiceTypes.ContentTransfer)
            || (props.channel === Experience.Mobile && props.serviceType == ServiceTypes.SelfService) ) {
            // ContentTransfer, 'grades' removed
            filteredItems = _items.filter(el => el.key !== "grades");
        }
        else {
            filteredItems = _items;
        }

        return (
            <Stack>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16, padding: "10px 20px 0px 20px" }}>
                    <Stack.Item>
                        <Text style={{ fontSize: "1.0625rem", fontWeight: 500, color: theme.palette.black }}>{strings.CONSTRUCTOR.TITLE}</Text>
                    </Stack.Item>
                    <Separator vertical />
                    <Stack.Item grow>
                        <CommandBar
                            items={filteredItems}
                            styles={{ root: { margin: 0, padding: 0 } }}
                            overflowItems={_overflowItems}
                            farItems={_farItems}
                        />
                    </Stack.Item>
                </Stack>
                {workflow.errors && workflow.errors.length > 0 && (
                    <WorkflowErrorsRender config={workflow} />
                )}
            </Stack>
        )
    }

    return (
        <Stack verticalFill tokens={{ padding: "24px 0px 0px 0px" }} style={{ margin: 0 }}>
            {errors.map(e => (
                <MessageBar messageBarType={MessageBarType.error}>{e.description}</MessageBar>
            ))}
            <Section verticalFill flat max onRenderHeader={onRenderSectionHeader}>
                <Stack.Item verticalFill>
                    <Configurator
                        config={workflow}
                        onChangeDispatch={onChangeDispatch}
                        channel={props.channel}
                        deviceCheckSolution={props.deviceCheckSolution}
                        setIdentificationModuleParams={onShowIdentificationModule}
                        setStageModuleParams={onShowStageModule}
                        setLandingModuleParams={onShowLandingModule}
                        setSelfServiceModule={onSelfServiceModule}
                    />
                </Stack.Item>
            </Section>
            {isFetching && (
                <Stack style={{ position: 'absolute', bottom: 0, right: 0, left: 0, top: 1, backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 100 }}>
                    <Stack verticalAlign="center" horizontalAlign="center" grow={1}>
                        <Spinner size={SpinnerSize.large} label={strings.SPINNERS.PLEASE_WAIT} />
                    </Stack>
                </Stack>
            )}
            <Modal isOpen={isShowGrades} onDismiss={hideGrades} containerClassName={modalStyles.container} styles={{ main: { display: "flex", height: "100%", width: "100%", maxHeight: "600px", maxWidth: "1000px" } }}>
                <Stack verticalFill>
                    <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                        <span style={{ paddingRight: 32 }}>{workflow.useGradesCategories ? strings.CONSTRUCTOR.COMMAND_BAR.GRADES_CATEGORIES.TITLE : strings.CONSTRUCTOR.COMMAND_BAR.GRADES.TITLE}</span>
                        {workflow.useGradesCategories ? <CSHHelpLink articleid='1121'></CSHHelpLink> : <CSHHelpLink articleid='1122'></CSHHelpLink>}
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideGrades}
                        />
                    </Stack>
                    <Stack.Item verticalFill>
                        {workflow.useGradesCategories && (
                            <GradesCategoriesForm onChangeDispatch={onChangeDispatch} categories={workflow.gradesCategories} handleCancelClick={hideGrades} />
                        ) || (
                                <GradesForm onChangeDispatch={onChangeDispatch} grades={workflow.grades} handleCancelClick={hideGrades} channel={props.channel} />
                            )}
                    </Stack.Item>
                </Stack>
            </Modal>
            <Modal isOpen={isShowParameters} onDismiss={hideParameters} containerClassName={modalStyles.container} styles={{ main: { display: "flex", height: "100%", width: "100%", maxHeight: "600px", maxWidth: "800px" } }}>
                <Stack verticalFill>
                    <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.CONSTRUCTOR.COMMAND_BAR.PARAMETERS.TITLE}</span>
                        <CSHHelpLink articleid='1125' />
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideParameters}
                        />
                    </Stack>
                    <Stack.Item verticalFill>
                        <Stack verticalFill grow tokens={{ childrenGap: 16 }}>
                            <Section verticalFill flat max title={strings.CONSTRUCTOR.PARAMETERS.TITLE} description={strings.CONSTRUCTOR.PARAMETERS.DESCRIPTION}>
                                <WorkflowSettings channel={props.channel} config={workflow} onChangeDispatch={onChangeDispatch} okButtonDisabled={okButtonDisabled} />
                            </Section>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 16, padding: 16 }}>
                            <Stack horizontal horizontalAlign="end" grow >
                                <PrimaryButton onClick={hideParameters} disabled={disableOkButton}>{strings.BUTTONS.TEXT.OK}</PrimaryButton>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Modal>
            <Modal isOpen={isShowWarning} onDismiss={hideWarning} containerClassName={modalStyles.container} styles={{ main: { display: "flex", height: "100%", width: "100%", maxHeight: "300px", maxWidth: "500px" } }}>
                <Stack verticalFill>
                    <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.CONSTRUCTOR.WARNINGS.WARNING_TITLE}</span>

                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideWarning}
                        />
                    </Stack>
                    <Stack.Item verticalFill>
                        <Stack verticalFill grow tokens={{ childrenGap: 16 }}>
                            <Section flat >
                                <Text className={warningStyles.container}>{strings.CONSTRUCTOR.WARNINGS.CONFIGURATION_INCORRECT}</Text>
                                <br />
                                <br />
                                <Text className={warningStyles.description}>{strings.CONSTRUCTOR.WARNINGS.SAVING_CONFIGURATION}</Text>
                            </Section>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <MessageBar messageBarType={MessageBarType.error}>{strings.CONSTRUCTOR.WARNINGS.DESCRIPTION}</MessageBar>
                        <Stack horizontal tokens={{ childrenGap: 16, padding: 24 }}>
                            <Stack horizontal horizontalAlign="end" grow tokens={{ childrenGap: 8 }}>
                                <DangerButton onClick={onWorkflowSaveWithWarnings} >{strings.BUTTONS.TEXT.SAVE}</DangerButton>
                                <SecondaryButton onClick={hideWarning}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Modal>
            <Modal isOpen={isShowLandingModule} onDismiss={hideLandingModule} containerClassName={modalStyles.containerModule} styles={{ main: { display: "flex", width: "100%", maxHeight: '100%', maxWidth: "1000px" } }}>
                <Stack verticalFill>
                    <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                        <Stack style={{ paddingRight: 32 }} tokens={{ childrenGap: 4 }}>
                            <Text variant="xLarge" style={{ fontWeight: 600 }}>{strings.CONSTRUCTOR.INSPECTIONS.LANDING.LANDING_PAGE}</Text>
                            <Text>
                               {strings.CONSTRUCTOR.INSPECTIONS.LANDING.ONBOARDING}
                            </Text>
                        </Stack>
                        <Stack horizontal style={{ alignItems: "center" }}>
                            <CSHHelpLink articleid="0"></CSHHelpLink>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel="Close"
                                onClick={hideLandingModule}
                            />
                        </Stack>
                    </Stack>
                    <Stack grow>
                        <LandingPivot
                            config={workflow.landing}
                            onChangeDispatch={onChangeDispatch}
                            serviceName={props.serviceName}
                        />
                    </Stack>
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 16, padding: 16 }}>
                            <Stack horizontal horizontalAlign="end" grow>
                                <PrimaryButton onClick={hideLandingModule} disabled={disableOkButton}>{strings.BUTTONS.TEXT.OK}</PrimaryButton>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Modal>

            {identificationModuleIndex && (
                <Modal isOpen={isShowIdentificationModule} onDismiss={onHideIdentificationModule} containerClassName={modalStyles.containerModule} styles={{ main: { display: "flex", width: "100%", maxHeight: '100%', maxWidth: "1000px" } }}>
                    <Stack verticalFill>
                        <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                            <Stack style={{ paddingRight: 32 }} tokens={{ childrenGap: 4 }}>
                                <Text variant="xLarge" style={{ fontWeight: 600 }}>{strings.CONSTRUCTOR.IDENTIFICATION.TITLE}</Text>
                                <Text>{getLocalizedIdentificationMethod(workflow.identification.items.find(i =>
                                    i.index === identificationModuleIndex)?.method as IdentificationMethods)}
                                </Text>
                            </Stack>
                            <Stack horizontal style={{ alignItems: "center" }}>
                                <CSHHelpLink articleid={getHelpArticleIdForIdentificationModule(workflow.identification.items.find(i =>
                                    i.index === identificationModuleIndex)?.method as IdentificationMethods)}></CSHHelpLink>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Close"
                                    onClick={onHideIdentificationModule}
                                />
                            </Stack>
                        </Stack>
                        <Stack grow>
                            {identificationModuleIndex && (
                                <IdentificationModule onChangeDispatch={onChangeDispatch} okButtonDisabled={okButtonDisabled} index={identificationModuleIndex} method={workflow.identification.items.find(i => i.index === identificationModuleIndex)?.method as IdentificationMethods} pivot={settingsPivotState ? settingsPivotState as TIdentificationModuleSettingsPivotStates : undefined} />
                            )}
                        </Stack>
                        <Stack.Item>
                            <Stack horizontal tokens={{ childrenGap: 16, padding: 16 }}>
                                <Stack horizontal horizontalAlign="end" grow>
                                    <PrimaryButton onClick={onHideIdentificationModule} disabled={disableOkButton}>{strings.BUTTONS.TEXT.OK}</PrimaryButton>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Modal>
            )}
            {stageModuleState && (
                <Modal isOpen={isShowStageModule} onDismiss={onHideStageModule} containerClassName={modalStyles.containerModule} styles={{ main: { display: "flex", width: "100%", maxHeight: '100%', maxWidth: "1000px" } }}>
                    <Stack verticalFill>
                        <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                            <Stack style={{ paddingRight: 32 }} tokens={{ childrenGap: 4 }}>
                                <TooltipHost content={(stageModuleState.stage === ProcessStages.Assessment ? workflow.assessment?.modules : workflow.control?.modules)?.find(i =>
                                    i.index === stageModuleState.index)?.config.ui?.title ?? getLocalizedInspectionName((stageModuleState.stage === ProcessStages.Assessment ? workflow.assessment?.modules : workflow.control?.modules)?.find(i =>
                                        i.index === stageModuleState.index)?.type as Inspections)}
                                >
                                    <Text variant="xLarge" nowrap block style={{ fontWeight: 600, maxWidth: 300 }}>{(stageModuleState.stage === ProcessStages.Assessment ? workflow.assessment?.modules : workflow.control?.modules)?.find(i =>
                                        i.index === stageModuleState.index)?.config.ui?.title ?? getLocalizedInspectionName((stageModuleState.stage === ProcessStages.Assessment ? workflow.assessment?.modules : workflow.control?.modules)?.find(i =>
                                            i.index === stageModuleState.index)?.type as Inspections)}
                                    </Text>
                                </TooltipHost>
                                <Text>{getLocalizedInspectionModeName((stageModuleState.stage === ProcessStages.Assessment ? workflow.assessment?.modules : workflow.control?.modules)?.find(i =>
                                    i.index === stageModuleState.index)?.type as Inspections, (stageModuleState.stage === ProcessStages.Assessment ? workflow.assessment?.modules : workflow.control?.modules)?.find(i =>
                                        i.index === stageModuleState.index)?.config as IInspectionConfig<IInspectionConfigUI>)}
                                </Text>
                            </Stack>
                            <Stack horizontal style={{ alignItems: "center" }}>
                                <CSHHelpLink articleid={getHelpArticleIdForInspectionModule((stageModuleState.stage === ProcessStages.Assessment ? workflow.assessment?.modules : workflow.control?.modules)?.find(i =>
                                    i.index === stageModuleState.index)?.type as Inspections)}></CSHHelpLink>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Close"
                                    onClick={onHideStageModule}
                                />
                            </Stack>
                        </Stack>
                        <Stack grow>
                            {stageModuleState && (
                                <InspectionModule onChangeDispatch={onChangeDispatch}
                                    index={stageModuleState.index}
                                    stage={stageModuleState.stage}
                                    pivot={settingsPivotState ? settingsPivotState as TInspectionSettingsPivotStates : 'ui'}
                                    onClose={onHideStageModule}
                                    onChangePivotState={setSettingsPivotState}
                                    withoutGrade={stageModuleState.stage === ProcessStages.Control}
                                    commonSettingsPivotChildren={
                                        <StageInspectionItemCommonSettingsChildren
                                            index={stageModuleState.index}
                                            stage={stageModuleState.stage}
                                            onChangeDispatch={onChangeDispatch}
                                        />}
                                />
                            )}
                        </Stack>
                        {settingsPivotState && settingsPivotState !== 'skip' && (
                            <Stack.Item>
                                <Stack horizontal tokens={{ childrenGap: 16, padding: 16 }}>
                                    <Stack horizontal horizontalAlign="end" grow>
                                        <PrimaryButton onClick={onHideStageModule} >{strings.BUTTONS.TEXT.OK}</PrimaryButton>
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                        )}
                    </Stack>
                </Modal>
            )}
            {workflow && (
                <Modal isOpen={isShowConfiguration} onDismiss={hideConfiguration} containerClassName={modalStyles.container} styles={{ main: { display: "flex", height: "100%" } }}>
                    <Stack verticalFill>
                        <Stack.Item>
                            <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                                <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SERVICES.SERVICE.CONFIGURATION_FILE}</span>

                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Close"
                                    onClick={hideConfiguration}
                                />
                            </Stack>
                        </Stack.Item>

                        <Stack verticalFill className={modalStyles.body}>
                            <ScrollablePane>
                                <pre style={{ overflow: "hidden", fontSize: 10 }}>{JSON.stringify(workflow, null, 2)}</pre>
                            </ScrollablePane>
                        </Stack>
                    </Stack>
                </Modal>
            )}
            <Panel type={PanelType.custom} isOpen={isStagePanelOpened} onDismiss={closeStagePanel}
                onRenderNavigation={() => <></>}
                onRenderHeader={() => <></>}
                styles={{ scrollableContent: { overflowY: 'hidden', display: "flex", height: "100%", width: "100%" }, content: { padding: 0, display: 'flex', flexGrow: 1 } }}>
                {stageModuleState && getStageInspectionModules(stageModuleState.stage, store)?.find(m => m.index === stageModuleState.index)?.type === Inspections.Interview && (
                    <QuestionsConfigurator stage={stageModuleState.stage} index={stageModuleState.index} onDissmiss={closeStagePanel} onChangeDispatch={onChangeDispatch} />
                )}
                {stageModuleState && getStageInspectionModules(stageModuleState.stage, store)?.find(m => m.index === stageModuleState.index)?.type === Inspections.Photographic && (
                    <PhotographicModeConfigurator stage={stageModuleState.stage} index={stageModuleState.index} onDissmiss={closeStagePanel} onChangeDispatch={onChangeDispatch} />
                )}
                {stageModuleState && getStageInspectionModules(stageModuleState.stage, store)?.find(m => m.index === stageModuleState.index)?.type === Inspections.Diagnostics && (
                    <DiagnosticsModeConfigurator stage={stageModuleState.stage} index={stageModuleState.index} onDissmiss={closeStagePanel} onChangeDispatch={onChangeDispatch} />
                )}
                {stageModuleState && getStageInspectionModules(stageModuleState.stage, store)?.find(m => m.index === stageModuleState.index)?.type === Inspections.Software && (
                    <SoftwareModeConfigurator stage={stageModuleState.stage} index={stageModuleState.index} onDissmiss={closeStagePanel} onChangeDispatch={onChangeDispatch} withoutGrades={stageModuleState.stage === ProcessStages.Control} />
                )}
                {stageModuleState && getStageInspectionModules(stageModuleState.stage, store)?.find(m => m.index === stageModuleState.index)?.type === Inspections.DataCollection && (
                    <StandardModeConfigurator stage={stageModuleState.stage} index={stageModuleState.index} onDissmiss={closeStagePanel} onChangeDispatch={onChangeDispatch} />
                )}
                {stageModuleState && getStageInspectionModules(stageModuleState.stage, store)?.find(m => m.index === stageModuleState.index)?.type === Inspections.Erase && (
                    <EraseConfigurator index={stageModuleState.index} onDissmiss={closeStagePanel} stage={stageModuleState.stage} onChangeDispatch={onChangeDispatch} />
                )}
                {stageModuleState && getStageInspectionModules(stageModuleState.stage, store)?.find(m => m.index === stageModuleState.index)?.type === Inspections.AIGrading && (
                    <AIGradingModeConfigurator index={stageModuleState.index} onDissmiss={closeStagePanel} stage={stageModuleState.stage} onChangeDispatch={onChangeDispatch} />
                )}
                {stageModuleState && stageModuleState.stage === ProcessStages.SelfService && (
                    <SelfServiceConfigurator config={workflow.selfService}
                                             onDismiss={closeStagePanel}
                                             onChangeDispatch={onChangeDispatch}/>
                )}
            </Panel>
        </Stack>
    )
}

const theme = getTheme();

const modalStyles = mergeStyleSets({
    containerModule: {
        borderRadius: 4
    },
    container: {
        flex: '1 1 auto',
        alignItems: 'stretch',
        borderRadius: 6
    },
    header: [
        theme.fonts.xLarge,
        {
            color: theme.palette.neutralPrimary,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },

    ],
    body: {
        fontSize: 10,
        position: "relative",
        overflowY: 'hidden'
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const warningStyles = mergeStyleSets({
    container: {
        color: SharedColors.gray20,
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
    },
    description: {
        color: SharedColors.gray40,
        fontSize: FontSizes.size16,
    }
})

