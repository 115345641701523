export enum BusinessErrors {
    Configurator_Organization_DuplicateDomain = 55101,
    Configurator_Organization_CantAddSupervisorRoleToAccount = 55102,
    Configurator_Organization_CantCreate = 55103,
    Configurator_Organization_CantCreateOrganizationAccount = 55104,
    Configurator_License_CantAddCustomer = 55501,
    Configurator_License_CantSetParentCustomer = 55502,
    Configurator_License_CantCloneProduct = 55503,
    Configurator_License_CustomerAlreadyExists = 55504,
    Configurator_Identity_DefaultError = 55701,
    Configurator_Identity_ConcurrencyFailure = 55702,
    Configurator_Identity_PasswordMismatch = 55703,
    Configurator_Identity_InvalidToken = 55704,
    Configurator_Identity_LoginAlreadyAssociated = 55705,
    Configurator_Identity_InvalidUserName = 55706,
    Configurator_Identity_InvalidEmail = 55707,
    Configurator_Identity_DuplicateUserName = 55708,
    Configurator_Identity_DuplicateEmail = 55709,
    Configurator_Identity_InvalidRoleName = 55710,
    Configurator_Identity_DuplicateRoleName = 55711,
    Configurator_Identity_UserAlreadyHasPassword = 55712,
    Configurator_Identity_UserLockoutNotEnabled = 55713,
    Configurator_Identity_UserAlreadyInRole = 55714,
    Configurator_Identity_PasswordTooShort = 55715,
    Configurator_Identity_PasswordRequiresNonAlphanumeric = 55716,
    Configurator_Identity_PasswordRequiresDigit = 55717,
    Configurator_Identity_PasswordRequiresLower = 55718,
    Configurator_Identity_PasswordRequiresUpper = 55719,
    Configurator_Identity_UserNotInRole = 55720,
    Configurator_Identity_RecoveryCodeRedemptionFailed = 55721,
    Configurator_Identity_PasswordRequiresUniqueChars = 55722,
    Configurator_Identity_PasswordIncludesNotMoreThenThreeConsecutiveCharacters = 55723,
    Configurator_Identity_PasswordLengthAtLeastFifteenCharacters = 55724,
}