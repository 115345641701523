import { Stack, TextField, PrimaryButton, ScrollablePane, Text } from '@fluentui/react';
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IPhotographicCollectionItem } from '@piceasoft/core';
import { strings } from '../../../../../../../localization/strings';
import { onRenderLabel } from '../../../../../../renders/onRenderLabel';
import { SecondaryButton } from '../../../../../buttons/SecondaryButton';

type TProps = {
    onConfirm: (collection: IPhotographicCollectionItem) => void
    collection?: IPhotographicCollectionItem
}

export const CollectionForm: React.FC<TProps> = ({ collection, onConfirm }) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<IPhotographicCollectionItem>({ defaultValues: { ...collection, } });

    const onSubmit: SubmitHandler<IPhotographicCollectionItem> = data => {
        console.log(data)
        onConfirm({ ...data })
    };

    React.useEffect(() => {
        reset(collection, { keepValues: false })
    }, [collection])

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack tokens={{ padding: 20, childrenGap: 16 }}>
                            <Stack.Item>
                                <Stack horizontal grow>
                                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <SecondaryButton iconProps={{ iconName: "Undo" }} onClick={() => reset(collection)} />
                                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                            <Stack grow tokens={{ childrenGap: 16 }}>
                                <Controller
                                    control={control}
                                    name="label"
                                    rules={{ required: strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.LABEL_REQUIRED }}
                                    render={({ field }) =>
                                        <TextField {...field}
                                            required
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.LABEL}
                                            title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.LABEL_TITLE}
                                            placeholder={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.LABEL_PLACEHOLDER}
                                            errorMessage={errors.label?.message}
                                        />
                                    }
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField {...field}
                                            multiline
                                            resizable={false}
                                            style={{ height: 100 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.DESCRIPTION}
                                            title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.DESCRIPTION_TITLE}
                                            placeholder={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.COLLECTION.DESCRIPTION_PLACEHOLDER}
                                            errorMessage={errors.description?.message}
                                        />
                                    }
                                />
                            </Stack>
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
            </Stack>
        </form>
    )
}
