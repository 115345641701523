import { Stack, Dropdown, TextField, PrimaryButton, ScrollablePane, IDropdownOption, ISelectableOption, IComboBoxOption, IRenderFunction, Text, SharedColors, Icon } from '@fluentui/react';
import { getTheme } from '@fluentui/style-utilities';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IService, IWorkflow, IWorkflowType } from '../../../core/store';
import { ISelfServiceConfigItem, IdentificationMethods } from '@piceasoft/core';
import { strings } from '../../../localization/strings';
import { onRenderLabel } from '../../renders/onRenderLabel'
import { SecondaryButton } from '../buttons/SecondaryButton';
import { Experience } from "../configurator/helpers/evisibility";
import { ServiceTypes } from '../../../core/store/typings/ServiceTypes';
import { DeviceCheckSolutions } from '../../../core/store/typings/DeviceCheckSolutions';
import { CSHHelpLink } from '../help/CSHHelp';
import { useServiceFormOptions } from '../../../localization/helpers/useServiceFormOptions';
import { getDefaultSelfServiceConfig } from "../../../core/helpers/self-service";
import { aiApi } from "../../../core/api/ai.api";
import { selector } from '../../../core/store/selector';
import { useMemo } from 'react';
import { portalApi } from '../../../core/api/api';
import { compareCurrentUTCDateTime } from '../../../core/helpers/date-time';


interface IFormService {
    /** Уникальный идентификатор сервисной процедуры. */
    id?: number
    /** Уникальный код сервисной процедуры в рамках организации. */
    code: string
    /** Наименование сервисной процедуры. */
    name: string
    /** Описание сервисной процедуры. */
    description: string
    /** Описание сервисной процедуры. */
    summary?: string
    /** Справка сервисной процедуры. */
    information?: string
    /** Иконка сервисной процедуры. */
    icon?: string
    /** Сервис включён для юридического лица.. */
    enabled: boolean
    /** Сервис удален для юридического лица.. */
    removed: boolean
    /** Конфигурация рабочего процесса.. */
    workflow: object
    /** Workflow type */
    channel: Experience
    /**Service type */
    serviceType: ServiceTypes
    /**Device check solution*/
    deviceCheckSolution: DeviceCheckSolutions;
}

type TProps = {
    onSubmit: (company: IService) => void
    onCancel: () => void
    data?: IService
}

const noDeviceCheckSolutionConditions = [
    { channel: Experience.Retail, serviceType: ServiceTypes.ContentTransfer },
    { channel: Experience.Mobile, serviceType: ServiceTypes.SelfService }
]

export const ServiceForm: React.FC<TProps> = (props) => {
    const { control, watch, resetField, handleSubmit, formState: { errors } } = useForm<IFormService>({ defaultValues: { ...props.data } });
    const selectedChannel = watch('channel');
    const selectedService = watch("serviceType");
    const selectedDeviceCheck = watch("deviceCheckSolution");
    const currentlang = useSelector(selector.workplace.getCurrentLanguage);

    const showDeviceCheckSolution = useMemo(() =>
        noDeviceCheckSolutionConditions.findIndex(
            (item) => item.channel === selectedChannel && item.serviceType === selectedService) === -1,
        [selectedChannel, selectedService])

    const {
        deviceCheckSolution,
        ecomDeviceCheckSolutions,
        workflowTypeOptions,
        CommonServiceTypeOptions,
        RetailServiceTypeOptions
    } = useServiceFormOptions();

    //  Available Service types depend on selected channel.
    const effectiveOptions: IDropdownOption[] = (selectedChannel == Experience.Retail) ? RetailServiceTypeOptions : CommonServiceTypeOptions;
    const effeciveDeviceCheckSolutions: IDropdownOption[] = ([Experience.ECom, Experience.Mobile].includes(selectedChannel)) ? ecomDeviceCheckSolutions : deviceCheckSolution;

    //  Reset Service type selection, if new channel does not support current service type.
    const updateServiceTypes = (channel: number | string | undefined) => {
        if (undefined == channel)
            resetField("serviceType", { defaultValue: null });
        else if (Experience.Retail == channel) {
            if (undefined == RetailServiceTypeOptions.find((t) => t.key == selectedService))
                resetField("serviceType", { defaultValue: null });
        }
        else if (undefined == CommonServiceTypeOptions.find((t) => t.key == selectedService))
            resetField("serviceType", { defaultValue: null });

        if (![Experience.ECom, Experience.Mobile].includes(channel as Experience)) {
            if (DeviceCheckSolutions.WebBased === selectedDeviceCheck)
                resetField("deviceCheckSolution", { defaultValue: null });
        }
        if (channel == Experience.MobileSDK) {
            resetField("deviceCheckSolution", { defaultValue: DeviceCheckSolutions.PiceaMobile });
        }
    }

    // const [selectedWorkflowTypeOption, setSelectedWorkflowTypeOption] = React.useState<IComboBoxOption>()
    const onSubmit: SubmitHandler<IFormService> = data => {
        let asyncData = false;
        if (!data.workflow) {
            console.log("Form data", data)
            const isSelfService = selectedService == ServiceTypes.SelfService && selectedChannel == Experience.Mobile;
            //  Content transfer comes with predefined steps.
            if (Experience.Retail == data.channel && ServiceTypes.ContentTransfer == data.serviceType) {
                data.workflow = {
                    channel: data.channel,
                    grades: [],
                    identification: {
                        items: [{ "index": 1, "method": IdentificationMethods.PiceaUsb }], // USB connection.
                        modules: []
                    },
                    contentTransfer: {},
                    serviceType: data.serviceType,
                    deviceCheckSolution: DeviceCheckSolutions.Undefined,
                    version: 2
                } as IWorkflow
                data.deviceCheckSolution = DeviceCheckSolutions.Undefined
            } else if (Experience.MobileSDK == data.channel) {
                data.workflow = {
                    channel: data.channel,
                    grades: [],
                    identification: {
                        items: [{ "index": 1, "method": IdentificationMethods.SDK }], // fixed SDK identification method
                        modules: []
                    },
                    serviceType: data.serviceType,
                    deviceCheckSolution: isSelfService ? DeviceCheckSolutions.Undefined : data.deviceCheckSolution,
                    version: 2
                } as IWorkflow
            } else if (Experience.Mobile == data.channel && ServiceTypes.SelfService == data.serviceType) {
                asyncData = true;

                const configurationRequest = aiApi.getConfigurations(currentlang)
                let configResult: ISelfServiceConfigItem[] = [];
                configurationRequest
                .then(configurationRes => {
                    if (configurationRes.data)
                        configResult = configurationRes.data
            
                    data.workflow = {
                        channel: data.channel,
                        grades: [],
                        identification: {
                            items: [],
                            modules: []
                        },
                        result: {},
                        selfService: getDefaultSelfServiceConfig(configResult),
                        serviceType: data.serviceType,
                        deviceCheckSolution: DeviceCheckSolutions.Undefined,
                        version: 2
                    } as IWorkflow
                    data.deviceCheckSolution = DeviceCheckSolutions.Undefined
                    props.onSubmit(data as IService)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            } else {
                data.workflow = {
                    channel: data.channel,
                    grades: [],
                    identification: {
                        items: [],
                        modules: []
                    },
                    serviceType: data.serviceType,
                    deviceCheckSolution: isSelfService ? DeviceCheckSolutions.Undefined : data.deviceCheckSolution,
                    version: 2
                } as IWorkflow
            }
        }
        if (!asyncData) {
            props.onSubmit(data as IService)
        }
    };

    // const [organizationsOptions, setOrganizationsOptions] = React.useState<IDropdownOption[]>()

    const onRenderDropDownOption: IRenderFunction<ISelectableOption<IWorkflowType>> = (item) => {
        return (
            <Stack
                // onClick={() => setSelectedOrganizationOption(item)}
                verticalAlign='center'
                horizontal horizontalAlign="space-between" grow tokens={{ padding: 4, childrenGap: 18 }}>
                <Stack tokens={{ padding: 2 }}>
                    <Text block nowrap variant="medium" style={{ color: SharedColors.gray20 }}>{item?.text}</Text>
                    <Text block nowrap variant="medium" style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{item?.data?.name}</Text>
                </Stack>
                <Stack verticalAlign='center' style={{ height: 48 }}>
                </Stack>
            </Stack>
        );
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{
                                    validate: (value) => value.trim().length > 0 ? true : strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_REQUIRED,
                                    required: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_REQUIRED
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME}
                                        title={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME}
                                        placeholder={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                name="code"
                                control={control}
                                rules={{
                                    validate: (value) => value.trim().length > 0 ? true : strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.CODE_REQUIRED,
                                    required: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.CODE_REQUIRED
                                }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.CODE}
                                        title={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.CODE}
                                        placeholder={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.CODE_PLACEHOLDER}
                                        errorMessage={errors.code?.message}
                                    />
                                }
                            />

                            <Controller
                                control={control}
                                name="channel"
                                rules={{
                                    required: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL_IS_REQUIRED
                                }}
                                render={({ field }) =>
                                    <Dropdown
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL}
                                        required
                                        options={workflowTypeOptions ?? []}
                                        onChange={(ev, opt) => {
                                            field.onChange(opt?.key)
                                            updateServiceTypes(opt?.key);
                                        }}
                                        placeholder={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SELECT_SERVICE_CHANNEL}
                                        disabled={props.data && props.data.channel ? true : false}
                                        styles={{
                                            dropdown: { width: '100%' },
                                            dropdownItem: { borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }, dropdownItemSelected: { borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }
                                        }}
                                        selectedKey={field.value}
                                        onRenderOption={onRenderDropDownOption}
                                        errorMessage={errors.channel?.message}
                                    />
                                }
                            />

                            <Controller
                                control={control}
                                name="serviceType"
                                rules={{ required: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_TYPE_IS_REQUIRED }}
                                render={({ field }) =>
                                    <Dropdown
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_TYPE}
                                        required
                                        options={effectiveOptions}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        placeholder={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SELECT_SERVICE_TYPE}
                                        disabled={!selectedChannel || (props.data && props.data.channel ? true : false)}
                                        styles={{
                                            dropdown: { width: '100%' },
                                            dropdownItem: { borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }, dropdownItemSelected: { borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }
                                        }}
                                        selectedKey={field.value}
                                        onRenderOption={onRenderDropDownOption}
                                        errorMessage={errors.serviceType?.message}
                                    />
                                }
                            />

                            {showDeviceCheckSolution && <Controller
                                control={control}
                                name="deviceCheckSolution"
                                rules={{
                                    required: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.DEVICE_CHECK_SOLUTION_IS_REQUIRED
                                }}
                                render={({ field }) =>
                                    <Dropdown
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.DEVICE_CHECK_SOLUTION}
                                        required
                                        options={effeciveDeviceCheckSolutions}
                                        onChange={(ev, opt) => {
                                            field.onChange(opt?.key)
                                            updateServiceTypes(opt?.key);
                                        }}
                                        placeholder={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SELECT_DEVICE_CHECK_SOLUTION}
                                        disabled={props.data && !!props.data.deviceCheckSolution || selectedChannel === Experience.MobileSDK}
                                        styles={{
                                            dropdown: { width: '100%' },
                                            dropdownItem: { borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }, dropdownItemSelected: { borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }
                                        }}
                                        selectedKey={field.value}
                                        onRenderOption={onRenderDropDownOption}
                                        errorMessage={errors.deviceCheckSolution?.message}
                                    />
                                }
                            />}
                            <Controller
                                name="summary"
                                control={control}
                                // rules={{ required: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SUMMARY_REQUIRED }}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        multiline
                                        resizable={false}
                                        style={{ height: 100 }}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SUMMARY}
                                        title={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SUMMARY}
                                        placeholder={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SUMMARY_PLACEHOLDER}
                                        errorMessage={errors.description?.message}
                                    />
                                }
                            />
                            <Text style={{ display: 'flex' }}>
                                <Icon iconName='Info' style={{ color: SharedColors.blue10, paddingTop: '3px', paddingRight: '8px' }} />
                                <span style={{ color: SharedColors.gray30 }}>
                                    {strings.ORGANIZATION.SERVICES.SERVICE.CREATE_DESCRIPTION_TEXT}
                                </span>
                            </Text>
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink alignment='left' articleid='1120' />
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();
