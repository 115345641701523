import { AppThunkAction } from "..";
import { portalApi } from "../api/api";
import { consoleStyles } from "../scripts/console";
import { ICompany } from "../store";
import { ITransaction } from "../store/typings/ITransaction";
export interface RequestCompaniesAction { type: 'REQUEST_COMPANIES' }
export interface ReceiveCompaniesAction { type: 'RECEIVE_COMPANIES', companies: ICompany[] }

export type KnownAction = RequestCompaniesAction | ReceiveCompaniesAction

export const actionCreators = {
    requestCompanies: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestCompanies()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isReady === false) {
            dispatch({ type: 'REQUEST_COMPANIES' });
            const apiResult = await portalApi.organization.companies.getCompanies();
            if (apiResult.successed && apiResult.data) {
                dispatch({ type: 'RECEIVE_COMPANIES', companies: apiResult.data })
            } else {
                console.log(apiResult.errors ? apiResult.errors[0] : 'Unknown error');
            }
        }
    },
    overview: {
        printSticker: (tx: ITransaction ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            console.debug("%c" + "LOGIC printSticker(tx)", consoleStyles.logic);
            console.debug(tx);
    
            await portalApi.overview.transactions.postSticker({
                tx: tx?.number,
                device: (tx?.identity?.device.manufacturer ? (tx?.identity?.device.manufacturer + " ") : "") + tx?.identity?.device.name,
                imei: tx?.identity?.device.attributes.imei,
                grade: tx?.assessment.grade,
                date: new Date(tx?.creationDate as unknown as string).toLocaleString(),
                batch: "1"
            });
        },
    }
}