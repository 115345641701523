import { getTheme, ISearchBoxProps, ISearchBoxStyleProps, ISearchBoxStyles, IStackStyles, IStyleFunctionOrObject, mergeStyleSets, SearchBox, SharedColors } from '@fluentui/react';
import * as React from 'react';
import { TFilterOptionValue } from './FilterOption';

export type TFilterOptions = { [key: string]: { values: Array<TFilterOptionValue>, title?: string } }

export const SimpleCustomFilter: React.FC<ISearchBoxProps> = (props) => <SearchBox styles={simpleCustomFilterStyles} iconProps={{iconName: "Filter", style: {color: SharedColors.gray30}}} {...props} />

const theme = getTheme();
const simpleCustomFilterStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = {
icon: {},
iconContainer: {},
clearButton: {},
root: { 
    borderRadius: theme.effects.roundedCorner4,
    backgroundColor: theme.palette.white,
    boxShadow: theme.effects.elevation8,
    border: 'none',
    width: 250
},
field: {}
}
const filterRootStyle = {
    backgroundColor: theme.palette.neutralLight,
    borderRadius: 6,
    marginLeft: 24,
    marginRight: 24,
    marginTop: 12
}
const classNames = mergeStyleSets({
    iconButton: [
        // getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.neutralLight, borderColor: theme.palette.neutralLight }),
        {
            height: 30,
            color: theme.palette.neutralDark,
            selectors: {
                '&:hover': {
                    color: theme.palette.red,
                    backgroundColor: theme.palette.neutralQuaternary
                }
            },
        },
    ]
});

const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4,
        // ...AnimationStyles.slideUpIn10,
        // overflow: 'hidden',
        // position: "relative"
    }
};