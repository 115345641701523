import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { IIdentificationConfig, ProcessStages } from "@piceasoft/core";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config: IIdentificationConfig
    channel: number
}

export const IdentificationStageAdditionalSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, channel }) => {

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.USE_GROUP_AS_SKU_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Identification, 'Additional', 'Use device group as SKU code')}
                toggleChecked={config.useGroupAsSku ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Identification, {
                        ...config,
                        useGroupAsSku: config.useGroupAsSku ? undefined : true
                    } as IIdentificationConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.USE_GROUP_AS_SKU_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.MOVE_NEXT_STAGE_ON_COMPLETE_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Identification, 'Additional', 'Skip device identification summary page')}
                toggleChecked={config.moveNextStageOnComplete ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Identification, {
                        ...config,
                        moveNextStageOnComplete: config.moveNextStageOnComplete ? undefined : true
                    } as IIdentificationConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.MOVE_NEXT_STAGE_ON_COMPLETE_DESCRIPTION}
            />
        </Stack>
    )
}