import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../../core/actions/validation-actions'
import { Stack, Text, ScrollablePane, IconButton, SearchBox, PrimaryButton, IColumn ,DetailsList, SelectionMode, IButtonStyles, IContextualMenuProps, getTheme, MessageBar, MessageBarType } from '@fluentui/react';
import { IValidationToken } from '../../core/store/typings/IValidationToken';
import { IStore } from '../../core/store';
import { IValidationApiDataTokens, IValidationApiDataApis } from '../../core/store/typings/IValidationTokenResponse';
import { CreateValidationToken } from './CreateValidationToken';
import { strings } from '../../localization/strings';
import { EnableValidationToken } from './EnableValidationToken';
import { EditValidationToken } from './EditValidationToken';
import { onRenderColumnHeaderCommon } from '../../components/shared/detailsList/commonRenders';
import { IValidationTokenApis } from '../../core/store/typings/IValidationTokenApis';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';

export const ValidationApi: React.FC = () => {

  const validationApiData: IValidationApiDataTokens = useSelector((s: IStore) => s.validationapi.structure.data_tokens);   
  const validationApiApis: IValidationApiDataApis = useSelector((s: IStore) => s.validationapi.structure.data_apis);    
  let searchResults: IValidationToken[] = [];
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenEnableModal, setIsOpenEnableModal] = useState(false);
  const [searchString, setSearchString] = useState<string>('');
  const [tokenData, setTokenData] = useState<IValidationToken>();
  const [expandedRowKey, setExpandedRowKey] = useState<string>();

  const openModal = (setOpenModal: (option: boolean) => void) => { setOpenModal(true) };
  const closeModal = (setCloseModal: (option: boolean) => void) => { setCloseModal(false) };

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
       await dispatch(actionCreators.requestValidation());
       await dispatch(actionCreators.requestValidationApis());
    };
  
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (validationApiData && validationApiData.data && validationApiData.data.tokens.length > 0) {
      searchResults = validationApiData.data.tokens.filter((token: IValidationToken) =>
        token.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
  }, [validationApiData, searchString]);

  useEffect(() => {
  if (validationApiData && validationApiData.data && validationApiData.data.tokens.length > 0){
      validationApiData.data.tokens.forEach((token: IValidationToken) => setTokenData(token))
    }
  }, [validationApiData]);

  const handleSearch = (value: string) => {
    setSearchString(value);
  }

  const handleRowClick = (token: IValidationToken) => {
    setExpandedRowKey(prevExpandedRowKey => {
      if (prevExpandedRowKey === token.token) {
        return '';
      } else {
        return token.token;
      }
    });
  };

  const formatExpireDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000); 
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  const columns: IColumn[] = [
    {
        key: 'column1',
        name: strings.SETTINGS.VALIDATION_API.TOKEN_NAME,
        fieldName: 'name',
        minWidth: 300,
        maxWidth: 700,
        styles: { root: { ':hover': { backgroundColor: 'transparent', padding: '10px' }}},
        onRenderHeader: onRenderColumnHeaderCommon,
        onRender: (token: IValidationToken) => {
          let tokenId = '';
          if(token.token){
            tokenId = token.token;
          }
          const isExpanded = token.token === expandedRowKey;
          return (
            <Stack onClick={() => handleRowClick(token)} style={{cursor: 'pointer'}}>
            { token &&
              <Stack>
                <Stack.Item style={{display: 'flex', flexDirection: 'column'}}>
                  <Text>{token.name}</Text>
                  { isExpanded &&
                    <Stack.Item style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <Text>{token.token}</Text>
                      <IconButton 
                        menuIconProps={{iconName:'Copy'}}
                        title="Copy"
                        ariaLabel="Copy"
                        onClick={() => navigator.clipboard.writeText(tokenId)}
                      />
                    </Stack.Item>
                  }
                  { token.is_valid ? "" : 
                    <MessageBar messageBarType={MessageBarType.warning}>{strings.SETTINGS.VALIDATION_API.TOKEN_DISABLED}</MessageBar>
                  }
                </Stack.Item>
            </Stack>
            }
            </Stack>
          )
        },
        isPadded: true,
    },
    {
      key: 'column2',
      name: strings.SETTINGS.VALIDATION_API.TABLE_COLUMN.NAME,
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 700,
      styles: { root: { ':hover': { backgroundColor: 'transparent', padding: '10px' }}},
      onRenderHeader: onRenderColumnHeaderCommon,
      onRender: (token: IValidationToken) => {
        return (
          <Stack>
            {token && 
              <Stack.Item>
                { token.apis &&
                  token.apis.map((apis: IValidationTokenApis) => {
                    return (
                      <Stack key={`apis_name-${apis.api_id}`}>
                        <Text>{apis.name}</Text>
                      </Stack>
                    )
                  })
                }
              </Stack.Item>
            }
            </Stack>
        )
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: strings.SETTINGS.VALIDATION_API.TABLE_COLUMN.CREATED_BY,
      fieldName: 'name',
      minWidth: 300,
      maxWidth: 700,
      styles: { root: { ':hover': { backgroundColor: 'transparent', padding: '10px' }}},
      onRenderHeader: onRenderColumnHeaderCommon,
      onRender: (token: IValidationToken) => {
        let creator = '';
        if(token.apis[0]){
          creator = token.apis[0].creator;
        }
        return (
          <Stack>
            {token && 
              <Stack.Item>
                <Text>{creator}</Text>
              </Stack.Item>
            }
            </Stack>
        )
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: strings.SETTINGS.VALIDATION_API.TABLE_COLUMN.CREATED,
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 500,
      styles: { root: { ':hover': { backgroundColor: 'transparent', padding: '10px' }}},
      onRenderHeader: onRenderColumnHeaderCommon,
      onRender: (token: IValidationToken) => {
        let created = 0;
        if(token.apis[0]){
          created = token.apis[0].created;
        }
        return (
          <Stack>
            {token && 
                <Text>{formatExpireDate(created)}</Text>
            }
            </Stack>
        )
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: strings.SETTINGS.VALIDATION_API.TABLE_COLUMN.EXPIRY_DATE,
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 700,
      styles: { root: { ':hover': { backgroundColor: 'transparent', padding: '10px' }}},
      onRenderHeader: onRenderColumnHeaderCommon,
      onRender: (token: IValidationToken) => {
        let max_expiry_time = 0;
        if(token.apis[0]){
          max_expiry_time = token.apis[0].max_expiry_time;
        }
        return (
          <Stack>
            {token &&
                <Text>{formatExpireDate(max_expiry_time)}</Text>
            }
            </Stack>
        )
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: '',
      fieldName: 'name',
      minWidth: 40,
      maxWidth: 70,
      styles: { root: { ':hover': { backgroundColor: 'transparent' }, } },
      onRenderHeader: onRenderColumnHeaderCommon,
      onRender: (token: IValidationToken) => {
          return (
              <Stack horizontal verticalFill verticalAlign='center' horizontalAlign="end">
                  <Stack.Item>
                      { token && 
                      <IconButton 
                        menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon', style: {color: theme.palette.black} }} 
                        styles={menuIconStyles}
                        menuProps={getTokenMenuProps(token)}
                      />}
                  </Stack.Item>
              </Stack>
          )
      },
      isPadded: true,
    },
  ]

  const getTokenMenuProps = (token: IValidationToken): IContextualMenuProps => {{
    return {
      items: [
        {
          key: `edit-${token.token}`,
          text: strings.BUTTONS.TEXT.EDIT_DATA,
          iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
          onClick: () =>  {
            setTokenData(token);
            setIsOpenEditModal(true);
          }
        },
        {
          key: `enable-${token.token}`,
          text: token.is_valid ? strings.BUTTONS.TEXT.DISABLE : strings.BUTTONS.TEXT.ENABLE,
          iconProps: { iconName: token.is_valid ? 'Cancel' : 'CheckMark', style: { color: theme.palette.black, fontSize: 14 } },
          onClick: () => {
            setTokenData(token);
            setIsOpenEnableModal(true);
          }
        }
      ]
    }
  }}

  return (
          <Stack>
            <ScreenHeader title={strings.SETTINGS.VALIDATION_API.VALIDATION_API_TITTLE}>
              <Stack.Item style={{ display: "flex", justifyContent: "space-between",alignItems:'baseline', margin: '20px', paddingBottom: '10px'}}>
                <Stack style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <PrimaryButton onClick={() => openModal(setIsOpenCreateModal)} style={{marginRight: '10px'}}>{strings.BUTTONS.TEXT.CREATE_VALIDATION_TOKEN}</PrimaryButton>  
                  <SearchBox  
                      placeholder='Search...'
                      value={searchString}
                      onChange={(event, newString: any) => handleSearch(newString)}
                    />
                </Stack>
              </Stack.Item>
            </ScreenHeader>
            {(tokenData && validationApiApis) &&
                  <CreateValidationToken
                    apisData={validationApiApis}
                    isOpenCreateModal={isOpenCreateModal}
                    closeCreateModal={() => closeModal(setIsOpenCreateModal)}
                    token={tokenData}
                  />
                }

            <ScrollablePane  style={{margin: '100px 20px 0 20px'}}>
              { validationApiData ? 
                (validationApiData.data && searchString === '' ? (
                  <DetailsList 
                    items={validationApiData.data.tokens}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                  />
                ): (
                  validationApiData.data ? (
                  <DetailsList
                    items={searchResults}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    />
                ): (
                  <Text>{strings.SETTINGS.VALIDATION_API.NOT_FOUND}</Text>
                ))) : (
                  <Text>{strings.SETTINGS.VALIDATION_API.NOT_FOUND}</Text>
                )
                }

                {(tokenData && validationApiApis) &&
                  <EditValidationToken 
                    token={tokenData}
                    tokenApis={tokenData.apis}
                    apisData={validationApiApis}
                    isOpenEditModal={isOpenEditModal} 
                    closeEditModal={() => {closeModal(setIsOpenEditModal)}}/> 
                } 

                {tokenData && 
                  <EnableValidationToken 
                    token={tokenData} 
                    isOpenEnableModal={isOpenEnableModal} 
                    closeEnableModal={() => {closeModal(setIsOpenEnableModal)}}/> 
                }
            </ScrollablePane>
        </Stack>
  )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
  menuIcon: {
      fontSize: 16,
      color: 'transparent',
      minWidth: 16
  },
  menuIconExpanded: {
      color: theme.palette.black
  }
}