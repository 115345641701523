import {
    getTheme, Stack,
    Text, Icon, Image, ImageFit, Toggle
} from "@fluentui/react"
import React, { Dispatch, useEffect, useState } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { ISelfServicePromotion, ISelfServiceWorkflowConfig, CurrencyCodes, CurrencySigns } from "@piceasoft/core"
import { strings } from "../../../../../../localization/strings";
import { Tooltip } from "../../../../help/Tooltip";
import { TStageSettingsPivotStates } from "../../../Configurator";
import { portalApi } from "../../../../../../core/api/api";
import { AllowedCurrencies } from "../../../../../../core/store/typings/AllowedCurrencies";
import { compareCurrentUTCDateTime } from '../../../../../../core/helpers/date-time'

import unknown from '../../../../../../assets/emptyimage.svg'
import { ItemsNotFound } from "../../../../notFound/ItemsNotFound";


interface IProps {
    state: TStageSettingsPivotStates
    config?: ISelfServiceWorkflowConfig
    pivots?: { name: string, component: React.ReactNode }[]
    onChangeDispatch: Dispatch<any>
}

const PromotionList: React.FC<IProps> = ({ state, config, pivots, onChangeDispatch }) => {
    const [promotionsState, setPromotionsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<ISelfServicePromotion> }>({ fetched: false })
    useEffect(() => {

        let isSubscribed = true;

        const getPromotions = async () => {
            setPromotionsState({ fetched: false })
            const result = await portalApi.organization.selfServicePromotion.getPromotions()
            setPromotionsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0].description : undefined, fetched: true, data: result.data })
        }

        getPromotions()
            .catch(error => {
                if (isSubscribed) {
                    console.log(error)
                }
            });

        return () => {
            isSubscribed = false;
        }

    }, [])

    return (<>{promotionsState.fetched && (promotionsState?.data && promotionsState?.data.length > 0 && promotionsState?.data?.map(item => <PromotionRow key={item.id} item={item} config={config} onChangeDispatch={onChangeDispatch} />) ||
        (<ItemsNotFound
            doNotUseButton
            info={strings.CONSTRUCTOR.STAGES.PROMOTION_LIST.NO_PROMOTIONS}
            suggestion={strings.CONSTRUCTOR.STAGES.PROMOTION_LIST.NO_PROMOTIONS_SUGGESTION}
            imgSrc={"images/navigation/images/not_found.png"}
            linkText={strings.CONSTRUCTOR.STAGES.PROMOTION_LIST.LEARN_MORE}
        />))}</>);
}


export default PromotionList;

type TPromotionRowProps = {
    item: ISelfServicePromotion;
    config?: ISelfServiceWorkflowConfig
    onChangeDispatch: Dispatch<any>
}
const PromotionRow: React.FC<TPromotionRowProps> = ({ item, config, onChangeDispatch }) => {
    const getLocalizedCurrencyName = (code: CurrencyCodes): string => strings.CURRENCY[code as unknown as AllowedCurrencies] as string
    const [isHovered, setIsHovered] = useState(false);
    const getToggleDisabled = () => {
        return (item.expirationDate == null || (item?.expirationDate && compareCurrentUTCDateTime(item?.expirationDate, false))) ? false : true
    }

    const getToggleChecked = () => {
        let result;
        if ((item?.expirationDate && compareCurrentUTCDateTime(item?.expirationDate, false)) || item?.expirationDate === null) {
            if (config?.promotions && config?.promotions.length > 0) {
                if (config?.promotions.findIndex(x => x == item.id) !== -1) {
                    result = true
                } else {
                    result = false;
                }
            }
        } else {
            result = false;
        }
        return result;
    }
    const [toggleChecked, setToggleChecked] = useState(getToggleChecked());

    const getCustomStyles = (item: ISelfServicePromotion, isHovered: boolean) => {
        console.log(item.id, item.expirationDate);
        const baseStyles: React.CSSProperties = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
            boxShadow: '0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13), 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.10)',
            backgroundColor: item?.expirationDate && compareCurrentUTCDateTime(item?.expirationDate, true) ? "rgb(253, 231, 233)" : "",
        };

        return {
            ...baseStyles,
        };
    }

    const changePromotions = (id: number) => {
        let promotions = config?.promotions;
        if (promotions?.indexOf(id) == -1) {
            promotions.push(id);
            setToggleChecked(true);
        } else {
            let index = promotions?.findIndex(x => x == id) ?? -1;
            if (index !== -1) {
                promotions?.splice(index, 1);
                setToggleChecked(false);
            }
        }
        if (promotions)
            onChangeDispatch(actionCreators.selfService.setPromotions(promotions))
    }
    return <div key={`row-div-${item.id}`} style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ ...cellStyle }}>
            <Stack horizontal style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }}>
                <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                    disabled={getToggleDisabled()}
                    checked={toggleChecked}
                    onChange={() => changePromotions(item.id)}
                />
            </Stack>
        </div>
        <div key={`row-${item.id}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
            style={getCustomStyles(item, isHovered)}
        >
            <div style={{ ...cellStyle, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 100 }}>
                <Stack horizontalAlign='center'>
                    <Image src={item.imageSrc || unknown} width={50} height={40} imageFit={ImageFit.contain} />
                </Stack>
            </div>

            {/** title /*/}
            <div style={{ ...cellStyle, minWidth: 150, maxWidth: 400, flex: 3, flexDirection: 'column' }}>
                <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600 }}>{item.title}</Text>
            </div>

            {/** Creation date /*/}
            <div style={{ ...cellStyle, minWidth: 150, maxWidth: 400, flex: 3, flexDirection: 'column' }}>
                <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 400 }}>{item?.creationDate && new Date(item?.creationDate).toLocaleString()}</Text>
            </div>

            {/** currency /*/}
            <div style={{ ...cellStyle, maxWidth: 270, flex: 1 }}>
                <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600 }}>{`${getLocalizedCurrencyName(item.currency as CurrencyCodes)} - ${CurrencySigns[item.currency as CurrencyCodes]}` ?? ''}</Text>
            </div>

            {/** Warning Image /*/}
            {item.expirationDate && compareCurrentUTCDateTime(item?.expirationDate, true) ?
                <div style={{ ...cellStyle, minWidth: 150, maxWidth: 400, flex: 3, flexDirection: 'column' }}>
                    <Stack horizontalAlign='center'>
                        <Tooltip content={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.PROMOTIONS.WARNING_MESSAGE}>
                            <Icon iconName={'warning'} style={{ color: theme.palette.red, fontSize: 20 }} />
                        </Tooltip>
                    </Stack>
                </div> :
                <div style={{ ...cellStyle, minWidth: 150, maxWidth: 400, flex: 3, flexDirection: 'column' }}>
                </div>}
        </div>
    </div>
}

const theme = getTheme();

const cellStyle: React.CSSProperties = {
    display: 'flex',
    padding: '5px'
}
