import { Callout, Checkbox, getTheme, Stack, mergeStyleSets, FontWeights, Text, StackItem, Icon, IconButton, IStackStyles, FontIcon, CommandBar, CommandButton, List, TooltipHost, DirectionalHint, ICalloutContentStyles, ICalloutProps, SearchBox, IListOnRenderRootProps, IRenderFunction, Sticky, ScrollablePane, IListOnRenderSurfaceProps, IButtonStyles } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { strings } from '../../../localization/strings';
import { SecondaryButton } from '../buttons/SecondaryButton';
import SuggestedSearch from '../search/SuggestedSearch';

type TProps = {
    name: string,
    title?: string,
    values?: Array<TFilterOptionValue>,
    selectedOptions: TFilterOptionValue[],
    hasValuesFilter?: boolean
    setSelectedFilterOption: (name: string, values: Array<TFilterOptionValue>) => void,
}

export type TFilterOptionValue = { key: string, title?: string, selected?: boolean }

export const FilterOption: React.FC<TProps> = (props) => {

    const calloutLabel: string = useId(`callout-filter-option-label-${props.name}`);
    const descriptionId: string = useId(`callout-filter-option-description-${props.name}`);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const optionClassName = useId(`callout-filter-option-classname-${props.name}`)

    const [filterString, setFilterString] = React.useState<string>()

    const prepareFilteredItems = (): TFilterOptionValue[] => {
        var newItems = props.values
        if (filterString) {
            newItems = [
                ...((newItems as TFilterOptionValue[]).filter(i => {
                    let isFounded = props.selectedOptions.find(so => (i.key === so.key && i.title === i.title)) ? true : false
                    const originValues = [
                        i.key,
                        i.title
                    ]
                    originValues.map(ov => {
                        if (ov && isFounded !== true) {
                            isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${filterString?.toLowerCase()}\w*`)) != null
                        }
                    })
                    return isFounded
                }))
            ]
        }
        return newItems as TFilterOptionValue[]
    }

    const onDismiss = () => {
        setFilterString(undefined)
        toggleIsCalloutVisible()
    }

    // const onRenderRoot: IRenderFunction<IListOnRenderRootProps<TFilterOptionValue>> = (localProps) => {
    //     if (!localProps) return null
    //     const { rootRef, surfaceElement, divProps } = localProps;

    //     return (
    //         <>
    //             {props.hasValuesFilter && (
    //                 <Sticky>
    //                     <Stack tokens={{ padding: 8 }}>
    //                         <SearchBox onChange={(ev, value) => setFilterString(value)} />
    //                     </Stack>
    //                 </Sticky>
    //             )}
    //             <div ref={rootRef} {...divProps}>
    //                 {surfaceElement}
    //             </div>
    //         </>
    //     );
    // };

    const onRenderSurface: IRenderFunction<IListOnRenderSurfaceProps<TFilterOptionValue>> = (localProps) => {
        if (!localProps) return null
        const { surfaceRef, pageElements, divProps } = localProps;

        return (
            <Stack tokens={{childrenGap: 8}}>
                {props.hasValuesFilter && (
                    <Sticky>
                        <Stack tokens={{ padding: 8 }}>
                            <SearchBox onChange={(ev, value) => setFilterString(value)} />
                        </Stack>
                    </Sticky>
                )}
                <div ref={surfaceRef} {...divProps}>
                    {pageElements}
                </div>
                <Stack tokens={{ padding: "8px 4px 0px 4px" }} grow horizontalAlign='end' verticalAlign='center' style={{ borderTop: `1px solid rgba(0,0,0,0.06)` }}>
                    <SecondaryButton disabled={(props.selectedOptions && props.selectedOptions.length > 0) ? false : true} 
                    styles={cancelButtonStyles} iconProps={{ iconName: 'Clear' }} 
                    text={strings.BUTTONS.TEXT.CLEAR}
                    onClick={() => props.setSelectedFilterOption(props.name, [])} 
                    />
                </Stack>
            </Stack>
        );
    };

    const onRenderCell = (item?: TFilterOptionValue): React.ReactNode => {

        const checked = props.selectedOptions.find(o => o.key === item?.key) ? true : false

        return (
            <Stack verticalAlign='center' horizontal style={{ padding: '4px 16px 4px 16px' }}>
                <TooltipHost calloutProps={tooltipCalloutProps} content={item?.title ?? item?.key}>
                    <Checkbox key={item?.key} label={item?.title ?? item?.key}
                        checked={checked}
                        styles={{ checkbox: { borderRadius: 4, height: 16, width: 16, borderColor: theme.palette.neutralSecondaryAlt }, checkmark: { fontSize: 12 } }}
                        onRenderLabel={(props) => <Text style={{ minWidth: 200 }} block nowrap>{props?.label}</Text>}
                        onChange={(ev, opt) =>
                            !opt
                                ? props.setSelectedFilterOption(props.name, [...props.selectedOptions.filter(o => o.key !== item?.key)])
                                : props.setSelectedFilterOption(props.name, [...props.selectedOptions, item as TFilterOptionValue])
                        }
                    />
                </TooltipHost>
            </Stack>
        )
    }

    return (
        <Stack style={{ cursor: 'pointer' }} horizontal verticalAlign="center">
            <Stack onClick={onDismiss} styles={optionButtonStyle} className={optionClassName}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                    <Text variant="medium">{(props.selectedOptions.length > 0) && (<b> {(props.selectedOptions[0].title ?? props.selectedOptions[0].key) + (props.selectedOptions.length > 1 ? ` +${props.selectedOptions.length - 1}` : "")} </b>) || (props.title ?? props.name)}</Text>
                    <FontIcon iconName={isCalloutVisible ? "ChevronUp" : "ChevronDown"} />
                </Stack>
            </Stack>
            {isCalloutVisible &&
                <Callout
                    ariaLabelledBy={calloutLabel}
                    ariaDescribedBy={descriptionId}
                    role="dialog"
                    isBeakVisible={false}
                    gapSpace={8}
                    target={`.${optionClassName}`}
                    onDismiss={onDismiss}
                    setInitialFocus
                    alignTargetEdge
                >
                    {/* {props.hasValuesFilter && (
                        <Sticky>
                            <Stack tokens={{ padding: 8 }}>
                                <SearchBox onChange={(ev, value) => setFilterString(value)} />
                            </Stack>
                        </Sticky>
                    )} */}
                    {/* {props.hasValuesFilter ? filteredItems : props.values && ( */}
                    <List
                        style={{ padding: "8px 0px" }}
                        onRenderCell={onRenderCell}
                        onRenderSurface={onRenderSurface}
                        // onRenderRoot={onRenderRoot}
                        items={props.hasValuesFilter ? prepareFilteredItems() : props.values}
                    />
                    {/* )} */}
                    {/* {filteredItems?.length === 0 && (
                                <Text>"По запросу совпадений не найдено"</Text>
                            )} */}
                </Callout>}
        </Stack>
    )
}

const theme = getTheme();

const optionButtonStyle: IStackStyles = {
    root: {
        borderRadius: 2,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 6,
        paddingTop: 6,
        transition: '0.5s',
        selectors: {
            '&:hover': {
                backgroundColor: theme.palette.neutralQuaternary
            }
        }
    }
};

const tooltipCalloutStyles: Partial<ICalloutContentStyles> = {
    beakCurtain: { background: theme.palette.neutralLight },
    beak: { background: theme.palette.neutralLight },
    calloutMain: { background: theme.palette.neutralLight },
    root: { color: theme.palette.neutralLight },
    container: { color: theme.palette.neutralLight }
}

const tooltipCalloutProps: ICalloutProps = {
    directionalHint: DirectionalHint.topAutoEdge,
    // isBeakVisible: false, 
    beakWidth: 0,
    gapSpace: 8,
    styles: tooltipCalloutStyles
}

const cancelButtonStyles: Partial<IButtonStyles> = {
    root: {
        padding: "0px 4px", border: 'none'
    },
    rootDisabled: {
        backgroundColor: 'transparent'
    },
    rootHovered: {
        backgroundColor: 'rgba(0,0,0,0.05)'
    }
}

