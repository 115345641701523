import { Stack } from '@fluentui/react';
import * as React from 'react';
import Inspection from '../Inspection';
import { noop } from '../../../../../../core/helpers/common';
import { strings } from '../../../../../../localization/strings';
import { PhotographicState } from './preview/PhotographicState';
import { Inspections, IPhotographicModeConfigUI, IPhotographicConfig, IPhotographicModeConfig } from "@piceasoft/core";
export type uploadFileType = { imgUrl: string, loadProgress: number, data: File, label?: string }

type TProps = {
    config?: IPhotographicConfig
}

export const PhotographicStatePreview: React.FC<TProps> = (props) => {
    if (props.config === undefined) return null;

    return (
        <Stack grow tokens={{ padding: "32px 32px 32px 32px" }}>
            <Inspection inspection={Inspections.Photographic}
                icon={props.config.ui?.icon ?? "Camera"}
                title={props.config.ui?.title ?? strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.TITLE}
                allowRestart={props.config.allowRestart}
                onStart={noop}
                onRestart={noop}
                dependency={props.config.dependency}
                skips={props.config.skip}
                autoRun={props.config.autoRun}
                executeButtonText={props.config.ui?.executeButtonText}
                repeatButtonText={props.config.ui?.restartButtonText}
            >
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    <PhotographicState
                        config={props.config.config as IPhotographicModeConfig<IPhotographicModeConfigUI>}
                        icon= {props.config.ui?.icon}
                    />
                </Stack>
            </Inspection>
        </Stack>
    );
}