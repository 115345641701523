import {
    AnimationStyles, DefaultButton, DetailsList, DetailsListLayoutMode, DetailsRow,
    DirectionalHint, FontWeights, getTheme, IButtonStyles, IColumn, IconButton, IContextualMenuProps,
    IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles,
    IIconProps, IRenderFunction, IStackStyles, mergeStyleSets, MessageBar, MessageBarType, Modal,
    PrimaryButton, ScrollablePane, SelectionMode, Spinner, Stack, Sticky, StickyPositionType, Text, TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useHistory } from 'react-router';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { Section } from '../../components/shared/decorations/Section';
import { onRenderColumnHeaderCommon } from '../../components/shared/detailsList/commonRenders';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { PrintTemplateForm } from '../../components/shared/forms/PrintTemplateForm';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { portalApi } from '../../core/api/api';
import { IDocument } from '../../core/store';
import { strings } from '../../localization/strings';
import { PrintTemplate } from './PrintTemplate';

export const ServicesPrintTemplates: React.FC = () => {

    const [printTemplatesState, setPrintTemplatesState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IDocument> }>({ fetched: false })

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const [deleteTemplateState, setDeleteTemplateState] = React.useState<IDocument>()

    const history = useHistory();

    React.useEffect(() => {
        getPrintTemplates()
    }, [])

    React.useEffect(() => {
        console.log(deleteTemplateState)
    }, [deleteTemplateState])

    const getPrintTemplates = async () => {
        setPrintTemplatesState({ fetched: false })
        const result = await portalApi.organization.documents.getDocuments()
        console.log(result)
        setPrintTemplatesState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(i => !i.removed) })
    }

    const onTemplateDelete = () => {
        if (deleteTemplateState?.id) {
            deleteTemplate(deleteTemplateState?.id)
            setDeleteTemplateState(undefined)
        }
    }

    const deleteTemplate = async (id: number) => {
        setPrintTemplatesState({ ...printTemplatesState, error: undefined })
        const result = await portalApi.organization.documents.deleteDocument(id)
        if (result.successed) {
            if (printTemplatesState.data) {
                setPrintTemplatesState({
                    ...printTemplatesState,
                    data: printTemplatesState.data.filter(c => c.id !== id)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setPrintTemplatesState({ ...printTemplatesState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setPrintTemplatesState({ ...printTemplatesState, error: "There is a problem! Document Template wasn't deleted! Server response false without an error" });
        }
    }

    const onAddPrintTemplate = () => {
        setFormError(undefined)
        stopFetch()
        showPanel()
    }

    const addPrintTemplate = async (item: IDocument) => {
        startFetch()
        const result = await portalApi.organization.documents.addDocument(item)
        if (result.successed && result.data) {
            console.log(result)
            history.push('/services/print/' + result.data.id?.toString())
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onCancel = () => {
        hidePanel()
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IDocument) => <PrintTemplate name={item.name} description={item.summary} />,
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.SERVICES.TABLE_COLUMNS.CREATION_DATE,
            fieldName: 'code',
            minWidth: 300,
            maxWidth: 500,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IDocument) => {
                return <Stack verticalFill verticalAlign="center"><Text style={{ color: theme.palette.black }}>{new Date(item.creationDate).toLocaleString()}</Text></Stack>;
            },
            isPadded: true,
        },
        {
            key: 'column4',
            name: '',
            fieldName: 'enabled',
            minWidth: 40,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IDocument) => (
                <Stack horizontal verticalFill verticalAlign='center'>
                    {/* <Stack.Item>
                        <TooltipHost directionalHint={DirectionalHint.leftBottomEdge} key={item.id} content={item.enabled ? strings.ORGANIZATION.COMPANIES.COMPANY.DISABLE_TOOLTIP : strings.ORGANIZATION.COMPANIES.COMPANY.ENABLE_TOOLTIP}>
                            <IconButton iconProps={item.enabled ? activeEnabledIconProps : enabledIconProps} onClick={(e) => {
                                e.stopPropagation();
                                item.id && serviceIsActivatedToggle(item.id)
                            }} />
                        </TooltipHost>
                    </Stack.Item> */}
                    <Stack.Item>
                        {/* {!item.removed &&  */}
                        <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item, setDeleteTemplateState)} />
                        {/* } */}
                    </Stack.Item>
                </Stack>
            )
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => history.push(`/services/print/${props.item['id']}`)}><DetailsRow {...props} styles={customStyles} /></Stack>;
        }
        return null;
    };

    return (
        // <Stack verticalFill verticalAlign="center" horizontalAlign="center">
        //     <Stack horizontalAlign="center" tokens={{ childrenGap: 16 }} style={{ maxWidth: 520 }}>
        //         <Stack.Item>
        //             <Image height={160} src="images/navigation/images/workflow.png" />
        //         </Stack.Item>
        //         <Stack.Item style={{ fontSize: "1.75rem", fontWeight: "bold", letterSpacing: "-.04em", color: "black", textAlign: "center" }}>
        //             Создайте свой первый процесс
        //         </Stack.Item>
        //         <Stack.Item style={{ color: "black", textAlign: "center" }}>
        //             Автоматизируйте рабочие процессы с помощью нашего мастера и контролируйте качество своего бизнеса.
        //         </Stack.Item>
        //         <Stack.Item style={{ paddingTop: 16 }}>
        //             <PrimaryButton text="Создать рабочий процесс" />
        //         </Stack.Item>
        //     </Stack>
        // </Stack>
        <Stack verticalFill>
            {!printTemplatesState.fetched && (
                <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                    <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                </Stack>
            )}
            {printTemplatesState.fetched && !printTemplatesState.error && (
                printTemplatesState.data && printTemplatesState.data.length > 0 && (
                    <Stack verticalFill>
                        <ScreenHeader title={strings.NAVIGATION.SERVICES.PRINT}>
                            <PrimaryButton onClick={onAddPrintTemplate} text={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.ADD_TEMPLATE_BUTTON} />
                            {/* <SimpleCustomFilter onChange={(ev, value) => props.setSearchString(value)} /> */}
                        </ScreenHeader>
                        <Stack verticalFill style={{ position: 'relative' }}>
                            <ScrollablePane>
                                <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                    <Section max>
                                        <DetailsList
                                            styles={{ root: { width: '100%' } }}
                                            items={printTemplatesState.data}
                                            columns={columns}
                                            setKey="none"
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            onRenderDetailsHeader={onRenderDetailsHeader}
                                            isHeaderVisible={true}
                                            onRenderRow={onRenderRow}
                                        />
                                    </Section>
                                </Stack.Item>
                            </ScrollablePane>
                        </Stack>
                    </Stack>
                ) || (
                    <ItemsNotFound
                        onClick={onAddPrintTemplate}
                        info={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NOT_FOUND_CASE.INFO}
                        suggestion={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NOT_FOUND_CASE.SUGGESTION}
                        buttonText={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NOT_FOUND_CASE.ADD_BUTTON}
                        imgSrc={"images/navigation/images/pic.svg"} />
                ))}
            {printTemplatesState.error && (
                <Stack verticalFill>
                    {printTemplatesState.error.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error}>{printTemplatesState.error}</MessageBar>
                    )}
                    <Stack.Item verticalFill>
                        <SomethingWentWrong action={getPrintTemplates} />
                    </Stack.Item>
                </Stack>
            )}
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                title={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.ADD_TEMPLATE}
            >
                {formError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {formError}
                    </MessageBar>
                )}
                {(isPanelOpen && !fetching) && (
                    <PrintTemplateForm
                        onSubmit={(data: IDocument) => addPrintTemplate(data)}
                        onCancel={onCancel}
                    />
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
            </CustomPanel>
            {deleteTemplateState && (
                <Modal isOpen={deleteTemplateState ? true : false} onDismiss={() => setDeleteTemplateState(undefined)} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close"
                            onClick={() => setDeleteTemplateState(undefined)}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={onTemplateDelete}
                            onCancel={() => setDeleteTemplateState(undefined)}
                            requiredMessageString={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.NAME_REQUIRED}
                            confirmationCheckString={deleteTemplateState?.name}
                            placeholder={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.NAME_PLACEHOLDER}
                            confirmText={strings.BUTTONS.TEXT.DELETE}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <ConfirmationText document={deleteTemplateState}></ConfirmationText>
                                <Text>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{deleteTemplateState.name}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>
            )}
        </Stack>
    )
}

type TConfirmationTextProps = {
    document?: IDocument
}
const ConfirmationText: React.FC<TConfirmationTextProps> = (props) => {
    if (props.document?.services?.length) {
        return <>
            <Text>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_CONFIRMATION_TEXT_NEW + " " +
            strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_CONFIRMATION_TEXT_WORKFLOWS_FOUND}</Text>
            <ul>
                { props.document?.services?.map( s => <li key={s.id}>{s.name}</li> ) }
            </ul>
        </>
    }
    else {
        return <Text>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_CONFIRMATION_TEXT_NEW}</Text>
    }
}

const getItemMenuProps = (item: IDocument, onDelete: (item: IDocument) => void): IContextualMenuProps => {
    return {
        items: [
            {
                key: `delete-${item.id}`,
                iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => item.id ? onDelete(item) : undefined,
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
            }
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    }
}

// const onRenderNameServiceCell = (item: IService) => {
//     return (
//         <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
//             <Stack.Item>
//                 <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: getPersonaColorsPallete[item.name[0].toLowerCase()] }}>
//                     <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
//                         {getIconSymbolsFromString(item.name)}
//                     </Text>
//                 </Stack>
//             </Stack.Item>
//             <Stack.Item grow>
//                 <Stack>
//                     <Stack.Item>
//                         <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
//                     </Stack.Item>
//                     <Stack.Item>
//                         <Text variant="small" style={{ color: SharedColors.gray30 }}>{item.description}</Text>
//                     </Stack.Item>
//                 </Stack>
//             </Stack.Item>
//         </Stack>
//     )
// }

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );

    return (
        // <Sticky stickyClassName={classNames.header} stickyPosition={StickyPositionType.Header} isScrollSynced>
        defaultRender!({
            ...props,
            onRenderColumnHeaderTooltip,
        })
        // </Sticky>
    );
};

const theme = getTheme();

const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4,
        ...AnimationStyles.slideUpIn10,
        overflow: 'hidden',
        position: "relative"
    }
};
// const activeEnabledIconProps: IIconProps = { iconName: 'FavoriteStarFill', styles: { root: { color: SharedColors.yellow10 } }, className: 'enabled-icon' }
// const enabledIconProps: IIconProps = { iconName: 'FavoriteStar', styles: { root: { color: 'transparent' } }, className: 'disabled-icon' }
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};