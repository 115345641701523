import React, {FC, useMemo} from "react";
import {DiagnosticCases, diagnosticDb, IDiagnosticCase, IPlatform, Platforms} from "@piceasoft/core";
import {Image, Stack} from "@fluentui/react";
import androidSvg from "../../../../../../assets/android_icon.svg";
import appleSvg from "../../../../../../assets/apple_icon.svg";

const casesFlat: IDiagnosticCase[] = diagnosticDb.reduce((acc: IDiagnosticCase[], val) => {
    acc.push(...val.cases)
    return acc;
}, [])

const getPlatformImage = (platform: IPlatform) => {
    switch (platform.id) {
        case Platforms.ANDROID:
            return <Image height={16} width={16} src={androidSvg}/>
        case Platforms.IPHONE:
        case Platforms.IPAD:
            return <Image height={16} width={16} src={appleSvg}/>
        default:
            return <></>
    }
}
const mergePlatforms = [Platforms.IPAD, Platforms.IPHONE]
const AvailablePlatforms: FC<{ caseId: DiagnosticCases }> = ({caseId}) => {
    const platforms = useMemo(() => {
        return casesFlat
            .filter(c => c.id === caseId)
            .reduce((acc: IPlatform[], val: IDiagnosticCase) => {
                // this functions filters platforms to merge get proper amount of elements
                // right now it merges IPhone and IPad
                const platformsAvailable = [...val.platforms].reduce((pAcc: IPlatform[], pVal: IPlatform) => {
                    if (!mergePlatforms.includes(pVal.id) || (mergePlatforms.includes(pVal.id) && !pAcc.some(p => mergePlatforms.includes(p.id)))) {
                        pAcc.push(pVal);
                    }
                    return pAcc;
                }, [])
                acc.push(...platformsAvailable);
                return acc
            }, [])
    }, [caseId])

    return (
        <Stack horizontal verticalAlign="center" tokens={{childrenGap: 4}}>
            {platforms.map((p) => (<Stack.Item key={`${caseId}-platform-${p.id}`}>
                {getPlatformImage(p)}
            </Stack.Item>))}
        </Stack>
    );
}

export default AvailablePlatforms
