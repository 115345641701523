import * as React from 'react';
import { TagPicker, ITag, IInputProps, IBasePickerSuggestionsProps, IPickerItemProps, TagItem } from '@fluentui/react/lib/Pickers';
import { Stack } from '@fluentui/react/lib/Stack';

type TProps = {
    items: ITag[]
    selectedItems: ITag[]
    onItemSelected: (selectedItem?: ITag | undefined) => ITag | PromiseLike<ITag> | null
    onRemoveSelectedItem: (item: ITag) => void
    inputProps?: IInputProps
    pickerSuggestionsProps: IBasePickerSuggestionsProps
    selectionAriaLabel?: string
}

const listContainsDocument = (tag: ITag, tagList: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
        return false;
    }
    return tagList.filter(compareTag => compareTag.key === tag.key).length > 0;
};

export const SearchResultsWithTagPicker: React.FC<TProps> = (props) => {
    const onFilterChanged = React.useCallback((filterText: string, tagList?: ITag[]): ITag[] => {
        const filteredItems = filterText.trim() ? props.items
            .filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0)
            .filter(tag => !listContainsDocument(tag, tagList ?? []))
            : [];
        console.log(props.items)
        console.log(filteredItems)
        return filteredItems;
    }, []);

    const onRenderItem = (lProps: IPickerItemProps<ITag>) => {
        let newProps = {
            ...lProps,
            onRemoveItem: () => props.onRemoveSelectedItem(lProps.item)
        }
        return <TagItem {...newProps}>{lProps.item.name}</TagItem>
    }

    return (
        <Stack tokens={{ childrenGap: 10 }} style={{ minWidth: 320 }}>
            <TagPicker
                removeButtonAriaLabel="Remove"
                onRenderItem={onRenderItem}
                selectionAriaLabel={props.selectionAriaLabel}
                onResolveSuggestions={onFilterChanged}
                onItemSelected={props.onItemSelected}
                selectedItems={props.selectedItems}
                getTextFromItem={(item) => { return item.name }}
                pickerSuggestionsProps={props.pickerSuggestionsProps}
                inputProps={props.inputProps}
            />
        </Stack>
    );
};
