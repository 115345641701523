import { getTheme, Image, ImageFit, PrimaryButton, SharedColors, Stack, Text } from "@fluentui/react"
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../core/scripts/style";
import { IService } from "../../../core/store";
import { strings } from "../../../localization/strings";
import defaultServiceIcon from '../../../assets/service.svg';
import { getLocalizedChannel, getLocalizedServiceType } from "../../../localization/helpers/channel";
import { Experience } from "../../../components/shared/configurator/helpers/evisibility";

export type TProps = {
    item: IService
    onEdit: () => void
    generateIcon?: boolean
    onShareWorkflow: () => void
}

export const ServiceConfigurationHeader: React.FC<TProps> = ({ item, onEdit, generateIcon, onShareWorkflow }) => {
    return (
        <Stack horizontal grow style={{ padding: "24px 32px 12px 32px" }}>
            <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                <Stack.Item>
                    <Stack verticalAlign="center" horizontalAlign="center" style={{
                        width: 72, height: 72, borderRadius: 6,
                        backgroundColor: !item.imageSrc && generateIcon ? getPersonaColorsPallete[item.name[0].toLowerCase()] : undefined
                    }}>
                        {item.imageSrc && (
                            <Stack horizontalAlign='center'>
                                <Image src={item.imageSrc} width={72} height={72} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                            </Stack>
                        ) || (generateIcon && (
                            <Text style={{ color: "#fff", fontSize: 28, fontWeight: 400, paddingBottom: 2 }}>
                                {getIconSymbolsFromString(item.name)}
                            </Text>
                        ) || (
                                <Stack horizontalAlign='center'>
                                    <Image src={defaultServiceIcon} width={72} height={72} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                </Stack>
                            )
                            )}
                    </Stack>
                </Stack.Item>
                <Stack.Item grow>
                    <Stack tokens={{ childrenGap: 2 }}>
                        <Stack.Item>
                            <Text style={{ fontSize: "1.3125rem", letterSpacing: "-.02em", fontWeight: 600, color: theme.palette.black }}>{item.name}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text style={{ color: SharedColors.gray30 }}>{item.description}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Text variant="small" style={{ color: SharedColors.cyanBlue10 }}>{getLocalizedChannel(item.channel)} - {getLocalizedServiceType(item.serviceType)}</Text>
                                <Text variant="small" style={{ color: SharedColors.gray20 }}>|</Text>

                                {item.catalogName && (
                                    <Text variant="small" style={{ color: SharedColors.cyanBlue10 }}>{item.catalogName}</Text>
                                ) || (
                                        <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.SERVICES.SERVICE.CATALOG_NOT_SPECIFIED}</Text>
                                    )}
                                <Text variant="small" style={{ color: SharedColors.gray20 }}>|</Text>
                                {item.documentName && (
                                    <Text variant="small" style={{ color: SharedColors.cyanBlue10 }}>{item.documentName}</Text>
                                ) || (
                                        <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.SERVICES.SERVICE.DOCUMENT_NOT_SPECIFIED}</Text>
                                    )}
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{childrenGap: 10}}>
            {item.channel === Experience.Mobile && (
                <Stack.Item>
                    <PrimaryButton text={strings.BUTTONS.TEXT.SHARE_WORKFLOW} onClick={onShareWorkflow} />
                </Stack.Item>
            )}
            {!item.removed && (
                <Stack.Item>
                    <SecondaryButton text={strings.BUTTONS.TEXT.EDIT_DATA} onClick={onEdit} />
                </Stack.Item>
            )}
            </Stack>
        </Stack>
    )
}

const theme = getTheme();