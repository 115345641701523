import * as React from 'react'
import { Pivot, PivotItem, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { ICompany } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { CompanyPivotBasedState } from './CompanyDetails/CompanyPivotBasedState';

type TProps = {
    data: ICompany
    onDelete: () => void
    retryInfo: () => void
    setInformation: (information: string) => void
    isFetching: boolean
    resetInfoWasSaved: () => void
    infoWasSaved: boolean
    updateState: (item: ICompany) => void
}

export type TCompanyPivotState = "information" | "services" | "points" | "settings" | "help" | "integration" | "certificates"

const getTabId = (itemKey: string) => {
    return `CompanyPivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "information": return strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INFORMATION.TITLE
        case "services": return strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.SERVICES.TITLE
        case "points": return strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.POINTS.TITLE
        case "help": return strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.HELP.TITLE
        case "settings": return strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.SETTINGS
        case "certificates": return strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.CERTIFICATES.TITLE
        case "integration": return strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INTEGRATION
        default: return m;
    }
}

export const CompanyDetails: React.FC<TProps> = (props) => {

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TCompanyPivotState>("information");

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TCompanyPivotState);
    };

    return (
        <Stack verticalFill>
            <Stack.Item >
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                    <Stack tokens={{ padding: "0px 32px 0px 32px" }}>
                        <Stack.Item>
                            <Pivot selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="menu">
                                <PivotItem itemKey={"information"} headerText={getPivotHeaderText("information")} />
                                <PivotItem itemKey={"services"} headerText={getPivotHeaderText("services")} />
                                <PivotItem itemKey={"points"} headerText={getPivotHeaderText("points")} />
                                <PivotItem itemKey={"settings"} headerText={getPivotHeaderText("settings")} />
                                <PivotItem itemKey={"help"} headerText={getPivotHeaderText("help")} />
                                {/* <PivotItem itemKey={"certificates"} headerText={getPivotHeaderText("certificates")} /> */}
                                {/* <PivotItem itemKey={"integration"} headerText={getPivotHeaderText("integration")} /> */}
                            </Pivot>
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </Stack.Item>
            <Stack.Item verticalFill>
                <CompanyPivotBasedState
                    state={selectedPivotKey}
                    data={props.data}
                    onDelete={props.onDelete}
                    retryInfo={props.retryInfo}
                    setInformation={props.setInformation}
                    isFetching={props.isFetching}
                    infoWasSaved={props.infoWasSaved}
                    resetInfoWasSaved={props.resetInfoWasSaved}
                    updateState={props.updateState}
                />
            </Stack.Item>
        </Stack>
    )
}
