import { DirectionalHint, getFocusStyle, getTheme, IButtonStyles, Icon, IconButton, IContextualMenuProps, List, mergeStyleSets, SharedColors, Stack, Text } from "@fluentui/react"
import React from "react"
import { IDiagnosticsPiceaTestConfig } from "@piceasoft/core"
import { strings } from "../../../../../../../../localization/strings"

type TProps = {
    tests: { isSelected: boolean, data: IDiagnosticsPiceaTestConfig }[]
    setOrder: (order: number) => void
    index: number
    onDelete: (index: number) => void
    onUp: (index: number) => void
    onEdit: (item: IDiagnosticsPiceaTestConfig) => void
}

export const TestsList: React.FC<TProps> = (props) => {

    const getItemMenuProps = (item: IDiagnosticsPiceaTestConfig): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.index}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => props.onEdit(item),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.index}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => props.onDelete(item.index),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const prepareCellStyle = (item?: IDiagnosticsPiceaTestConfig) => {
        const currentColor = item?.index === props.index ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 0,
                    boxSizing: 'border-box',
                    background: item?.index === props.index && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderCell = (item?: { isSelected: boolean, data: IDiagnosticsPiceaTestConfig }, localIndex?: number) => {
        return (
            <div key={`${localIndex}-${item?.data.index}` ?? ""} onClick={() => localIndex !== undefined && props.setOrder(localIndex)} className={prepareCellStyle(item?.data).itemCell}>
                <Stack horizontal verticalAlign='center' grow tokens={{ childrenGap: 8 }} className={item?.data.errors && item.data.errors.length > 0 ? (item.data.index === props.index ? classNames.activeErrorListItem : classNames.errorListItem) : (item?.data.index === props.index ? classNames.activeListItem : classNames.listItem)}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.data.index === props.index && <Icon style={{ fontSize: 12, fontWeight: 400 }} iconName="Accept" />}
                        {(item?.data.index !== props.index) && (item?.data.errors && item.data.errors.length > 0) && <Icon style={{ fontSize: 14, fontWeight: 400, paddingLeft: 5,color: SharedColors.red10 }} iconName="Error" />}
                    </Stack>
                    <Stack.Item grow>
                        <Text block nowrap variant="medium" style={{ width: 236, color: (item?.data.errors && item.data.errors.length > 0 && item.data.index !== props.index) ? SharedColors.red10 : SharedColors.gray40 }}>{(item?.data.name && item?.data.name.length > 0) ? item?.data.name : `${strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TITLE} ${item?.data.name}`}</Text>
                    </Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 4 }}>
                        {item && localIndex !== undefined && localIndex > 0 && <IconButton iconProps={{ iconName: "Up", className: 'up-icon' }} style={{ height: 24, width: 24 }} styles={menuIconStyles} onClick={(ev) => { ev.stopPropagation(); props.onUp(item?.data.index) }} />}
                        {item && <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} style={{ height: 24, width: 24 }} styles={menuIconStyles} menuProps={getItemMenuProps(item.data)} />}
                    </Stack>
                </Stack>
            </div >
        );
    }

    return (
        <List
            items={props.tests}
            onRenderCell={onRenderCell}
            getKey={item => item.data.index.toString()}
        />
    )
}

const theme = getTheme();

const classNames = mergeStyleSets({
    listItem: [
        {
            padding: 4,
            borderLeft: '2px solid transparent',
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                },
                '.up-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        },
    ],
    activeListItem: [
        {
            padding: 4,
            background: 'rgba(222,236,249,1)',
            borderLeft: `2px solid ${theme.palette.themePrimary}`,
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                },
                '.up-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        }
    ],
    errorListItem: [
        {
            padding: 4,
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                },
                '.up-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        },
    ],
    activeErrorListItem: [
        {
            padding: 4,
            background: 'rgb(253,231,233)',
            borderLeft: `2px solid ${theme.palette.red}`,
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                },
                '.up-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        }

    ]
});

const menuIconStyles: IButtonStyles = {
    root: {
        padding: 0
    },
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}