import { getTheme, Separator } from "@fluentui/react";
import React from "react";

type TProps = {
    color?: string
    vertical?: boolean
}

export const CustomSeparator: React.FC<TProps> = (props) => {
    return <Separator vertical={props.vertical ?? false} styles={{
        root: {
            selectors: {
                '&::before': {
                    backgroundColor: props.color ?? theme.palette.neutralQuaternary,
                    '&::after': {
                        backgroundColor: props.color ?? theme.palette.neutralQuaternary
                    }
                }
            }
        }
    }} />
}

const theme = getTheme();