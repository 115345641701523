import { Stack, IconButton, SharedColors, getTheme, IIconProps } from "@fluentui/react"

export type TPreviewState = {
    title: string
    index: number
    iconProps: IIconProps
}

type TProps = {
    states: TPreviewState[]
    currentState?: number
    onSelectState: (index: number) => void
}

export const PreviewStatesToggler: React.FC<TProps> = (props) => {
    return (
        <Stack horizontal style={{ background: theme.palette.white, boxShadow: theme.effects.elevation64, borderRadius: 8 }}>
            {props.states.map(s => {
                let styles = {
                    ...stateButtonStyles, root: {
                        ...stateButtonStyles.root,
                        borderTopLeftRadius: s.index === 0 ? 8 : undefined, borderBottomLeftRadius: s.index === 0 ? 8 : undefined,
                        borderTopRightRadius: s.index === props.states.length - 1 ? 8 : undefined, borderBottomRightRadius: s.index === props.states.length - 1 ? 8 : undefined
                    }
                }
                return (
                    <IconButton
                        key={s.index}
                        checked={s.index === props.currentState}
                        onClick={() => props.onSelectState(s.index)}
                        styles={styles}
                        iconProps={{ iconName: 'StatusCircleInner', ...s.iconProps, style: { color: SharedColors.cyanBlue10, fontSize: 10, ...s.iconProps.style } }}
                        title={s.title}
                    />
                )
            }
            )}
        </Stack>

    )
}

const theme = getTheme();

const stateButtonStyles = {
    root: {
        borderRadius: 0,
        width: 24,
        height: 24
    },
    rootChecked: {
        background: theme.palette.neutralLighter
    }
}