import {
    Stack, getTheme, Text, Spinner, PivotItem, Pivot, IconButton,
    IIconProps, DirectionalHint, Icon, IPivotItemProps, IRenderFunction,
    TooltipHost, SharedColors, IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject,
    ScrollablePane
} from "@fluentui/react"
import * as React from 'react';
import { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators, getStageInspectionModules } from "../../../../../../../core/actions/configurator-actions";
import { noop } from "../../../../../../../core/helpers/common";
import { IStore } from "../../../../../../../core/store";
import { 
    IInterviewConfig, IInterviewWithQuestionsConfig, IInterviewSequenceConfig, IInterviewListConfig,
    InterviewModes, Inspections, ProcessStages, IGrade, IQuestion
} from "@piceasoft/core";
import { getLocalizedInspectionName, getLocalizedInspectionModeName } from "../../../../../../../localization/helpers/transaction";
import { strings } from "../../../../../../../localization/strings";
import { ItemsNotFound } from "../../../../../notFound/ItemsNotFound";
import { ModulePreviewWrapper } from "../../preview/ModulePreviewWrapper";
import { ListQuestionsPreview } from "./ListQuestionsPreview";
import { ListQuestionsConfiguratorSettingsPivot } from "./pivots/questions/ListQuestionsConfiguratorSettingsPivot";
import { QuestionsConfiguratorQuestionsPivot } from "./pivots/questions/QuestionsConfiguratorQuestionsPivot";
import { SequenceQuestionsConfiguratorSettingsPivot } from "./pivots/questions/SequenceQuestionsConfiguratorSettingsPivot";
import { SequenceQuestionPreview } from "./SequenceQuestionPreview";
import { CSHHelpLink } from "../../../../../help/CSHHelp";

type TProps = {
    index: number
    stage: ProcessStages
    onDissmiss: () => void
    onChangeDispatch: Dispatch<any>
}

type TQuestionsConfiguratorPivotState = "questions" | "settings"

export const QuestionsConfigurator: React.FC<TProps> = ({ stage, index, onDissmiss, onChangeDispatch }) => {

    const interview = useSelector((s: IStore) => getStageInspectionModules(stage, s)?.find(i => i.index === index)?.config as IInterviewConfig)
    const config = interview.config as IInterviewWithQuestionsConfig
    const questionsError = (config.questions.find((question) => question.errors && question.errors.length > 0) || interview.errors?.find((error) => error.code === 14)) ? true : false
    
    const grades = (interview.gradesCategory
        ? useSelector((s: IStore) => s.configurator.gradesCategories?.find(i => i.code === interview.gradesCategory))?.grades ?? []
        : useSelector((s: IStore) => s.configurator.grades))

    const [selectedQuestionOrder, setSelectedQuestionOrder] = React.useState(-1)

    const [pivotState, setPivotState] = React.useState<TQuestionsConfiguratorPivotState>("questions")

    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(item?.props.itemKey! as TQuestionsConfiguratorPivotState);
    };

    const getTabId = (itemKey: string) => {
        return `QuestionsConfiguratorPivot_${itemKey}`;
    };

    const getPivotHeaderText = (m: string): string => {
        switch (m) {
            case "questions": return strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.PIVOTS.QUESTIONS
            case "settings": return strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.PIVOTS.SETTINGS
            default: return m;
        }
    }

    React.useEffect(() => {
        if (selectedQuestionOrder < 0 && config.questions && config.questions.length > 0) {
            setSelectedQuestionOrder(0)
        }
        if (!config.questions || config.questions.length === 0) {
            setSelectedQuestionOrder(-1)
        }
        if (config.questions && selectedQuestionOrder >= 0 && !config.questions[selectedQuestionOrder]) {
            setSelectedQuestionOrder(config.questions.length - 1)
        }
    }, [config.questions])

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
                </TooltipHost>
            </span>
        )
    }
    const onRenderItemLinkWarning: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText} tooltipProps={{ calloutProps: { styles: { calloutMain: { "p": {} } } } }}>
                    <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px', color: SharedColors.red10 }} />
                </TooltipHost>
            </span>
        )

    }

    const onAddHandler = () => onChangeDispatch(actionCreators.inspections.interview.addQuestion(stage, index))

    const onConfirmHandler = (question: IQuestion) => onChangeDispatch(actionCreators.inspections.interview.editQuestion(stage, index, question))

    const onConfigConfirmHandler = (config: IInterviewSequenceConfig | IInterviewListConfig) => onChangeDispatch(actionCreators.inspections.interview.editModeConfig(stage, index, config))

    const onDeleteHandler = (lIndex: number) => onChangeDispatch(actionCreators.inspections.interview.removeQuestion(stage, index, lIndex))

    const onMoveUpHandler = (lIndex: number) => onChangeDispatch(actionCreators.inspections.interview.moveUpQuestion(stage, index, lIndex))

    const checkTitle = () => {
        config.questions.map((i) => {
            if(i.title === "") {
                i.title = strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.QUESTION + ' ' + i.index
            }
        });

        onDissmiss();
    }

    return (
        <Stack grow verticalFill horizontal>
            <ModulePreviewWrapper color={selectedQuestionOrder >= 0 ? "rgba(0, 0, 0, 0.4)" : undefined}>
                <Stack verticalFill verticalAlign="center">
                    <Stack style={{ paddingTop: 64, paddingBottom: 32, width: 640 }}>
                        {interview.mode === InterviewModes.Sequence && config.questions && config.questions.length > 0 && config.questions[selectedQuestionOrder] && (
                            <Stack.Item style={{ paddingLeft: 6}} verticalFill>
                                <SequenceQuestionPreview config={config as IInterviewSequenceConfig} question={config.questions[selectedQuestionOrder]}
                                    onReset={noop}
                                    onFinish={noop} onRollback={noop} onAction={noop}
                                    headerText={interview.ui?.title ?? strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.TITLE}
                                />
                            </Stack.Item>
                        )}
                        {interview.mode === InterviewModes.Sequence && config.questions && config.questions.length > 0 && !config.questions[selectedQuestionOrder] && (
                            <Stack.Item tokens={{ padding: "64px 32px" }}>
                                <Stack verticalFill verticalAlign="center" style={{ minWidth: 640 }}>
                                    <Spinner label={strings.SPINNERS.PLEASE_WAIT} />
                                </Stack>
                            </Stack.Item>
                        )}
                        {[InterviewModes.Sequence, InterviewModes.List].includes(interview.mode) && (!config.questions || config.questions.length === 0) && (
                            <Stack.Item tokens={{ padding: "64px 32px" }}>
                                <Stack verticalFill verticalAlign="center" style={{ minWidth: 640 }}>
                                    <ItemsNotFound
                                        onClick={() => onChangeDispatch(actionCreators.inspections.interview.addQuestion(stage, index))}
                                        info={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.NOT_FOUND_CASE.INFO}
                                        suggestion={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.NOT_FOUND_CASE.SUGGESTION}
                                        buttonText={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.NOT_FOUND_CASE.ADD_BUTTON}
                                        imgSrc={"images/navigation/images/not_found.png"} />
                                </Stack>
                            </Stack.Item>
                        )
                        }
                        {interview.mode === InterviewModes.List && config && config.questions.length > 0 && (
                            <Stack.Item style={{ paddingLeft: 38}} verticalFill>
                                <ListQuestionsPreview questions={config.questions} config={config as IInterviewListConfig}
                                    headerText={interview.ui?.title ?? strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.TITLE} />
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack>
            </ModulePreviewWrapper>
            <Stack verticalFill grow>
                <Stack horizontal grow style={{ padding: 8 }}>
                    <Stack style={{ padding: 8 }} grow tokens={{ childrenGap: 4 }}>
                        <Text variant="xLarge">{interview?.ui?.title ?? getLocalizedInspectionName(Inspections.Interview)}</Text>
                        <Text variant="small">{getLocalizedInspectionModeName(Inspections.Interview, interview as IInterviewConfig)}</Text>
                    </Stack>
                    <Stack horizontal style={{ alignItems: "center" }}>
                        <CSHHelpLink articleid="1132"/>
                        <IconButton onClick={onDissmiss} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                    </Stack>
                </Stack>
                <Stack.Item tokens={{ padding: "0px 16px 0px 16px" }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                    <Pivot styles={pivotStyles} selectedKey={pivotState} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                        <PivotItem itemIcon={'List'} headerText={getPivotHeaderText("questions")} onRenderItemLink={questionsError ? onRenderItemLinkWarning : onRenderItemLink} itemKey="questions" />
                        <PivotItem itemIcon={'Settings'} headerText={getPivotHeaderText("settings")} onRenderItemLink={onRenderItemLink} itemKey="settings" />
                    </Pivot>
                </Stack.Item>
                <Stack.Item verticalFill>
                    <PivotBasedState
                        questions={config.questions}
                        grades={grades}
                        interview={interview}
                        onConfirm={onConfirmHandler}
                        onConfigConfirm={onConfigConfirmHandler}
                        onDelete={onDeleteHandler}
                        onMoveUp={onMoveUpHandler}
                        onAdd={onAddHandler}
                        state={pivotState}
                        onDissmiss={checkTitle}
                        selectedQuestionOrder={selectedQuestionOrder}
                        setSelectedQuestionOrder={setSelectedQuestionOrder}
                    />
                </Stack.Item>
            </Stack>
        </Stack >
    )
}

type TStateProps = {
    state: TQuestionsConfiguratorPivotState
    interview: IInterviewConfig
    questions: IQuestion[]
    grades: IGrade[]
    onConfirm: (question: IQuestion) => void
    onConfigConfirm: (config: IInterviewSequenceConfig | IInterviewListConfig) => void
    onDelete: (lIndex: number) => void
    onMoveUp: (lIndex: number) => void
    onAdd: () => void
    onDissmiss: () => void
    selectedQuestionOrder: number
    setSelectedQuestionOrder: (order: number) => void
}

const PivotBasedState: React.FC<TStateProps> = (props) => {
    switch (props.state) {
        case "questions": return <QuestionsConfiguratorQuestionsPivot  {...props} />
        case "settings":
            switch (props.interview.mode) {
                case InterviewModes.Sequence: return (
                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                        <ScrollablePane>
                            <SequenceQuestionsConfiguratorSettingsPivot config={props.interview.config as IInterviewSequenceConfig} {...props} />
                        </ScrollablePane>
                    </Stack.Item>
                )
                case InterviewModes.List: return (
                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                        <ScrollablePane>
                            <ListQuestionsConfiguratorSettingsPivot config={props.interview.config as IInterviewListConfig} {...props} />
                        </ScrollablePane>
                    </Stack.Item>
                )
                default: return <></>
            }
    }
}

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}