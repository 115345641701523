import { AppThunkAction } from "..";
import { consoleStyles } from "../scripts/console";


export interface SetArticleIdHelpAction { type: 'HELP_SET_ARTICLE_ID', helpArticleId?: string }


export type KnownAction =  SetArticleIdHelpAction

export const actionCreators = {
    /** Set content sensitive help article id. */
    setHelpArticleId: (helpArticleId?: string, ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC SetArticleIdHelp()", consoleStyles.logic);
        dispatch({ type: 'HELP_SET_ARTICLE_ID', helpArticleId: helpArticleId });
    }
};
