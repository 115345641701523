import { Stack, ScrollablePane, TextField, PrimaryButton, IDropdownOption, Dropdown, IconButton, getTheme, Text, SelectableOptionMenuItemType, Separator } from "@fluentui/react"
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators, getStageInspectionModules } from "../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../core/store";
import { IDependencyRule, Dependencies, ProcessStages, IdentificationMethods, IGrade } from "@piceasoft/core";
import { IGradesCategory } from "@piceasoft/core";
import { getLocalizedIdentificationMethod } from "../../../../../../localization/helpers/transaction";
import { strings } from "../../../../../../localization/strings";
import { onRenderLabel } from "../../../../../renders/onRenderLabel";
import { SecondaryButton } from "../../../../buttons/SecondaryButton";
import { Section } from "../../../../decorations/Section";
import { ItemsNotFound } from "../../../../notFound/ItemsNotFound";
import { isEqual } from "lodash";

type TProps = {
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
    onClose: () => void
}

enum AndOr {
    And = 'and',
    Or = 'or'
}

export const SkipPivot: React.FC<TProps> = (props) => {

    const config = useSelector((s: IStore) => getStageInspectionModules(props.stage, s)?.find(i => i.index === props.index)?.config)
    const [errors, setErrors] = React.useState<{ index: number, hasError: boolean }[]>([])
    const [localState, setLocalState] = React.useState<IDependencyRule[]>(config?.skip ?? []);
    const [buttonState, setButtonState] = React.useState<IDependencyRule[]>(config?.skip ?? []);
    const [deleteAction, setDeleteAction] = React.useState<boolean>();

    const onSubmit = (close?: boolean) => {
        props.onChangeDispatch(actionCreators.inspections.updateInspectionSkip(props.stage, props.index, localState))
        if (close) {
            props.onClose()
        }
        setButtonState([]);
    }

    const updateErrorCount = (index: number, hasError: boolean) => {
        setErrors((errors) => {
          const updatedErrors = [...errors];
      
          if (hasError) {
            const filteredErrors = updatedErrors.filter((error) => error.index !== index);
      
            const insertIndex = Math.min(index, updatedErrors.length);
            return [...filteredErrors.slice(0, insertIndex), { index, hasError: true }, ...filteredErrors.slice(insertIndex)];
          }
      
          const filteredErrors = updatedErrors.filter((error) => error.index !== index);
          return filteredErrors.map((item, i) => ({ ...item, index: i + 1 }));
        });
      };

    const updateChild = (data: IDependencyRule, childData: IDependencyRule, roadMap: AndOr[]): IDependencyRule => {
        let newData = { ...data };
        const currentRoad = roadMap.shift()
        if (newData.and && currentRoad === AndOr.And) {
            newData.and = updateChild(newData.and, childData, roadMap)
            return newData;
        }
        if (newData.or && currentRoad === AndOr.Or) {
            newData.or = updateChild(newData.or, childData, roadMap)
            return newData;
        }
        if (newData.dependency !== childData.dependency) {
            childData.value = undefined
            childData.condition = undefined
        }
        return childData;
    }

    const updateSkipItem = (index: number, data: IDependencyRule, roadMap: AndOr[]) => {
        const newState = localState.map((el, i) => {
            if (index === i) {
                return updateChild(el, data, roadMap)
            }
            return el;
        }) as IDependencyRule[];       

        setLocalState(newState);
        setButtonState(localState);

    }

    const addSkipItem = () => {
        setLocalState([...localState, {dependency: Dependencies.ASSESSMENT_GRADE, name: "" }])
    }

    const deleteSkipItem = (index: number) => {
        setDeleteAction(true);
        updateErrorCount(index, false);
        setLocalState(localState.filter((si, i) => i !== index))
    }
    
    const isUndoStateValid = (localState: IDependencyRule[]) => {
        let stateChanged = isEqual(localState, config?.skip);
        if(stateChanged || localState.length <= 0){ 
            return true;
        }
        return false;
    }

    const isSkipStateValid = (buttonState: IDependencyRule[]): boolean => {
        if(errors.some(e => e.hasError === true)) {
            return true;
        }
        return false;
    }

    const onRenderCommand = (): JSX.Element => <Stack.Item>
        <Stack horizontal>

            <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 4, padding: "16px 20px 16px 20px" }}>
                {localState.length > 0 && (
                    <PrimaryButton onClick={addSkipItem}>{strings.BUTTONS.TEXT.ADD}</PrimaryButton>
                )}
                <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 4 }}>
                    <SecondaryButton iconProps={{ iconName: "Undo" }} disabled={isUndoStateValid(localState)} onClick={() => setLocalState(config?.skip ?? [])  } />
                    <PrimaryButton disabled={isSkipStateValid(buttonState)} onClick={() => onSubmit(false)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                    <PrimaryButton disabled={isSkipStateValid(buttonState)} onClick={() => onSubmit(true)}>{strings.BUTTONS.TEXT.CONFIRM_AND_CLOSE}</PrimaryButton>
                </Stack>

            </Stack>
        </Stack>
    </Stack.Item>

    return (
        <Section verticalFill title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.HEADER} max flat grow onRenderFooter={onRenderCommand} >
            <Stack verticalFill grow tokens={{ childrenGap: 16, padding: "16px 16px 16px 16px" }}>
                {localState.length > 0 && (
                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                        <ScrollablePane>
                            <Stack>
                                {localState.map((skip, index) => (
                                    <Stack key={`skip-${index}`}>
                                        <Stack tokens={{ childrenGap: 16 }} horizontal >
                                            <Stack.Item tokens={{ padding: '24px 0px 0px 0px' }}>
                                                <IconButton
                                                    iconProps={{ iconName: 'Delete' }}
                                                    onClick={() => deleteSkipItem(index)}
                                                />
                                            </Stack.Item>
                                            <SkipItem data={skip} update={updateSkipItem} index={index} parent={skip} roadMap={[]} updateErrorCount={updateErrorCount} errors={errors} deleteAction={deleteAction} setDeleteAction={setDeleteAction} />
                                        </Stack>
                                        <Separator styles={{ content: { fontWeight: 600, fontSize: 16, color: theme.palette.themePrimary } }} alignContent="start" >{localState.length > index + 1 ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.OR : undefined}</Separator>
                                    </Stack>
                                )
                                )}
                            </Stack>
                        </ScrollablePane>
                    </Stack.Item>
                ) || (
                        <ItemsNotFound
                            onClick={addSkipItem}
                            buttonText={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.NOT_FOUND_CASE.BUTTON_TEXT}
                            info={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.NOT_FOUND_CASE.INFO}
                            suggestion={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.NOT_FOUND_CASE.SUGGESTION}
                            imgSrc={"images/navigation/images/not_found.png"} />
                    )}
            </Stack>
        </Section>
    )
}

type TErrorProps = {
    index: number,
    hasError: boolean
}

type TSkipProps = {
    index: number
    data: IDependencyRule
    parent: IDependencyRule
    errors: TErrorProps[]
    update: (index: number, data: IDependencyRule, roadMap: AndOr[]) => void
    childType?: AndOr
    roadMap: AndOr[]
    updateErrorCount?: (index: number, hasError: boolean) => void
    deleteAction: boolean | undefined;
    setDeleteAction: (value: React.SetStateAction<boolean | undefined>) => void
}

const SkipItem: React.FC<TSkipProps> = ({ errors, index, data, update, childType, parent, roadMap, updateErrorCount, deleteAction, setDeleteAction}) => {

    const useGradesCategories = useSelector((s: IStore) => s.configurator.useGradesCategories)
    const grades = useSelector((s: IStore) => s.configurator.grades)
    const identificationsItems = useSelector((s: IStore) => s.configurator.identification.items)
    const identifications = Array.from(new Set(identificationsItems.map(i => i.method)))
    const gradesCategories = useSelector((s: IStore) => s.configurator.gradesCategories) ?? []

    type Conditions = "EQUAL" | "NOT_EQUAL" | "LESS_THAN" | "GREATER_THAN" | "EMPTY" | "NOT_EMPTY" | "INCLUDE" | "NOT_INCLUDE"
    
    const [nameError, setNameError] = React.useState<string>( !data.name ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.NAME_VALIDATION_ERROR : "")
    
    const [conditionError, setConditionError] = React.useState<string>( !data.condition ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.CONDITION_VALIDATION_ERROR : "")
    
    const [dependencyError, setDependencyError] = React.useState<string>( !data.dependency ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.DEPENDENCY_VALIDATION_ERROR : "")
    
    const [gradeError, setGradeError] = React.useState<string>( !data.value ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.GRADE_VALIDATION_ERROR : "")

    const errorValues = React.useMemo(() => [nameError, conditionError, dependencyError, gradeError], [
        nameError,
        conditionError,
        dependencyError,
        gradeError
    ]);

    
    const hasErrors =  React.useMemo(() =>
        errorValues.some(error => error !== null && error !== undefined && error.trim().length > 0
    ), [errorValues])

    React.useEffect(() => {
        if(deleteAction){
            setDeleteAction(false);
        }
        if(hasErrors && updateErrorCount) {
            updateErrorCount(index, true);
        } 
        if(!hasErrors && updateErrorCount) {
            const result = validateAll(parent);
            if(!result){
                updateErrorCount(index, validateAll(parent));
            }
        }
    }, [hasErrors]);

    React.useEffect(() => {
        if(deleteAction && errors.length === 0){
            setGradeError("");
            setNameError("");
            setDependencyError("");
            setConditionError("");
            setDeleteAction(false);
        }
        if(deleteAction && !hasErrors && updateErrorCount){
            updateErrorCount(index, validateAll(parent));
            setDeleteAction(false);
        }
    }, [deleteAction, data])

    React.useEffect(() => {
        if(hasErrors) {
            if(parent.and && !parent.and.name && !parent.and.condition&& !parent.and.value){
                validateAll(parent.and);
            }
            if(parent.or && !parent.or.name && !parent.or.condition&& !parent.or.value){
                validateAll(parent.or);
            }
            if(parent.and && parent.or){
                validateAll(parent);
            }
        }
    }, [parent]);


    const isDataClear = (data: IDependencyRule) => {
        const valuesToCheck = [data.name, data.condition, data.dependency, data.value];
        return valuesToCheck.every((value) => value);
      };
    const validateAll = (data: IDependencyRule): boolean => {
        let hasErrors = false;
      
        if (!isDataClear(data)) {
            hasErrors = true;
        }
      
        if(data.and && data.or){
            const orHasErrors = validateAll(data.or);
            const andHasErrors = validateAll(data.and);
            hasErrors = orHasErrors || andHasErrors
        }

        else {
            if (data.or) {
                const orHasErrors = validateAll(data.or);
                hasErrors = hasErrors || orHasErrors;
              }
            
              if (data.and) {
                const andHasErrors = validateAll(data.and);
                hasErrors = hasErrors || andHasErrors;
              }
        }
    
        return hasErrors;
      };      
    
    const validateName = (value?: string) => {
        if (!value || value.trim().length <= 1 || value === "") {
            setNameError(strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.NAME_VALIDATION_ERROR)
        } else {
            setNameError("")
        }
    }

    const validateCondition = (value?: any) => {
        if(!value || !value.key || value.text === undefined) {
            setConditionError(strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.CONDITION_VALIDATION_ERROR);
        } else {
            setConditionError("");
        }
    };

    const validateGrade = (value?: any ) => {
        if(!value || !value.key || value.text === undefined) {
            setGradeError(strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.GRADE_VALIDATION_ERROR);
        } else {
            setGradeError("");
        }
    }

    const validateDependencies = (value?: any) => {
        if(!value || !value.key || value.text === undefined) {
            setDependencyError(strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.DEPENDENCY_VALIDATION_ERROR);
        } else {
            setDependencyError("");
        }
    }

    const validateGradeTextField = (value?: string) => {
        if(!value || typeof(value !== "string") && value.trim().length <= 0){
            setGradeError(strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.GRADE_VALIDATION_ERROR);
        } else {
            setGradeError("");
        }
    }

    const dependencyOptions: IDropdownOption[] = [
        {
            text: Dependencies.ASSESSMENT_GRADE,
            key: Dependencies.ASSESSMENT_GRADE
        },
        {
            text: Dependencies.ASSESSMENT_GRADE_INDEX,
            key: Dependencies.ASSESSMENT_GRADE_INDEX
        },
        {
            text: Dependencies.ASSESSMENT_PRICE,
            key: Dependencies.ASSESSMENT_PRICE
        },
        {
            text: Dependencies.IDENTIFICATION_METHOD,
            key: Dependencies.IDENTIFICATION_METHOD
        },
        {
            text: Dependencies.DEVICE_MODEL,
            key: Dependencies.DEVICE_MODEL
        },
        {
            text: Dependencies.DEVICE_MANUFACTURER,
            key: Dependencies.DEVICE_MANUFACTURER
        },
        {
            text: Dependencies.DEVICE_OS,
            key: Dependencies.DEVICE_OS
        },
        {
            text: Dependencies.DEVICE_OS_VERSION,
            key: Dependencies.DEVICE_OS_VERSION
        },
        {
            text: Dependencies.POST_OFFER_GRADE,
            key: Dependencies.POST_OFFER_GRADE
        },
        {
            text: Dependencies.POST_OFFER_GRADE_INDEX,
            key: Dependencies.POST_OFFER_GRADE_INDEX
        },
        {
            text: Dependencies.UNSUPPORTED,
            key: Dependencies.UNSUPPORTED
        },
    ]

    const conditionOptions: IDropdownOption[] = [
        {
            text: "EQUAL",
            key: "EQUAL"
        },
        {
            text: "NOT_EQUAL",
            key: "NOT_EQUAL"
        },
        {
            text: "LESS_THAN",
            key: "LESS_THAN"
        },
        {
            text: "GREATER_THAN",
            key: "GREATER_THAN"
        },
        {
            text: "EMPTY",
            key: "EMPTY"
        },
        {
            text: "NOT_EMPTY",
            key: "NOT_EMPTY"
        },
        {
            text: "INCLUDE",
            key: "INCLUDE"
        },
        {
            text: "NOT_INCLUDE",
            key: "NOT_INCLUDE"
        },
    ]
    const prepareGradesOptions = (type: Dependencies, grades: IGrade[]): IDropdownOption[] => {
        if (data.dependency === Dependencies.ASSESSMENT_GRADE || data.dependency === Dependencies.POST_OFFER_GRADE) {
            return (grades ?? []).map(i => ({ text: `${i.name}`, key: `${i.code}` }))
        }
        return (grades ?? []).map(i => ({ text: `${i.index}: ${i.name}`, key: `${i.index}` }))
    }

    const prepareIdentificationMethodsOptions = (type: Dependencies, methods: IdentificationMethods[]): IDropdownOption[] => {
        return (methods ?? []).map(i => ({ text: `${getLocalizedIdentificationMethod(i)}`, key: `${i}` }))
    }

    const prepareGradesCategoriesOptions = (type: Dependencies, gradesCategories: IGradesCategory[]): IDropdownOption[] => {
        let options: IDropdownOption[] = []
        if (data.dependency === Dependencies.ASSESSMENT_GRADE || data.dependency === Dependencies.POST_OFFER_GRADE) {
            gradesCategories.forEach(gc => {
                if (gc.name) {
                    options.push({ key: 'gradesCategory-' + gc.code, text: gc.name, itemType: SelectableOptionMenuItemType.Header })
                    gc.grades.forEach(g => { options.push({ key: `${gc.code}-${g.code}`, text: `${g.name} (${g.code})` }) })
                    if (gc.index < gradesCategories.length) {
                        options.push({ key: 'gradesCategoryDivider-' + gc.code, text: gc.name, itemType: SelectableOptionMenuItemType.Divider })
                    }
                }
            })
            return options;
        }
        gradesCategories.forEach(gc => {
            if (gc.name) {
                options.push({ key: 'gradesCategory-' + gc.code, text: gc.name, itemType: SelectableOptionMenuItemType.Header })
                gc.grades.forEach(g => { options.push({ key: `${gc.code}-${g.index}`, text: `${g.code}: ${g.name}` }) })
                if (gc.index < gradesCategories.length) {
                    options.push({ key: 'gradesCategoryDivider-' + gc.code, text: gc.name, itemType: SelectableOptionMenuItemType.Divider })
                }
            }
        })
        return options;

    }

    return (
        <Stack horizontal>
            {childType && (
                <Stack style={{ minWidth: 32 }} tokens={{ childrenGap: 4 }} >
                    {childType === AndOr.And && <Text style={{ fontWeight: 600 }} variant="mediumPlus">{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.AND}</Text>}
                    {childType === AndOr.Or && <Text style={{ fontWeight: 600 }} variant="mediumPlus">{strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.OR}</Text>}
                    {childType && updateErrorCount && (
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => {update(index, {
                                ...parent,
                                and: childType === AndOr.And ? undefined : parent.and,
                                or: childType === AndOr.Or ? undefined : parent.or
                            }, roadMap.filter((r, i) => i < roadMap.length - 1))
                            setDeleteAction(true);
                            updateErrorCount(index,true);
                        }}
                        />
                    )}
                </Stack>
            )}
            <Stack tokens={{ childrenGap: 8, padding: `0px 0px 0px ${(roadMap.length) * 8}px` }} grow>
                <Stack.Item>
                    <TextField
                        label={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.NAME_LABEL}
                        title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.NAME_TITLE}
                        placeholder={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.NAME_PLACEHOLDER}
                        onRenderLabel={onRenderLabel}
                        styles={{ root: { width: 350 } }}
                        value={data.name}
                        onChange={(ev, val) => {
                            validateName(val)
                            update(index, {
                                ...data,
                                name: val ?? ""
                            }, roadMap)
                        }}
                        errorMessage={nameError}
                    />
                </Stack.Item>
                <Stack grow tokens={{ childrenGap: 16 }} horizontal verticalAlign="end">
                    <Stack.Item>
                        <Dropdown
                            required
                            selectedKey={data.dependency}
                            onChange={(ev, opt) => { 
                                validateDependencies(opt)
                                update(index, {
                                ...data,
                                dependency: opt?.key.toString() as Dependencies,
                            }, roadMap)
                        }}
                            errorMessage={dependencyError}
                            style={{ width: 200 }}
                            onRenderLabel={onRenderLabel}
                            label={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.DEPENDENCY_LABEL}
                            title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.DEPENDENCY_TITLE}
                            placeholder={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.DEPENDENCY_PLACEHOLDER}
                            options={dependencyOptions}
                        />
                    </Stack.Item>
                    {!(data.dependency === Dependencies.UNSUPPORTED) && (
                        <>
                            <Stack.Item>
                                <Dropdown
                                    required
                                    selectedKey={data.condition ?? strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.CONDITION_PLACEHOLDER}
                                    onChange={(ev, opt) => { 
                                        validateCondition(opt);
                                        update(index, {
                                        ...data,
                                        condition: opt?.key.toString() as Conditions
                                    }, roadMap)
                                }}
                                    errorMessage={conditionError}
                                    style={{ width: 200 }}
                                    onRenderLabel={onRenderLabel}
                                    label={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.CONDITION_LABEL}
                                    title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.CONDITION_TITLE}
                                    placeholder={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.CONDITION_PLACEHOLDER}
                                    options={conditionOptions}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                {([Dependencies.ASSESSMENT_GRADE, Dependencies.ASSESSMENT_GRADE_INDEX, Dependencies.POST_OFFER_GRADE, Dependencies.POST_OFFER_GRADE_INDEX].includes(data.dependency)) && (
                                    <Dropdown
                                        required
                                        selectedKey={data.value ?? strings.CONSTRUCTOR.GRADES_CATEGORIES.PLACEHOLDER_GRADES_CATEGORIES}
                                        onChange={(ev, opt) => {
                                            validateGrade(opt);
                                            update(index, {
                                            ...data,
                                            value: opt?.key.toString()
                                        }, roadMap)
                                    }}
                                        onRenderLabel={onRenderLabel}
                                        style={{ width: 250 }}
                                        label={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.VALUE_LABEL}
                                        title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.VALUE_TITLE}
                                        placeholder={useGradesCategories
                                            ? (!gradesCategories || gradesCategories.length === 0
                                                ? strings.CONSTRUCTOR.GRADES_CATEGORIES.PLACEHOLDER_NO_GRADES_CATEGORIES
                                                : strings.CONSTRUCTOR.GRADES_CATEGORIES.PLACEHOLDER_GRADES_CATEGORIES)
                                            : (!grades || grades.length === 0
                                                ? strings.CONSTRUCTOR.GRADES.MODAL_NO_GRADES
                                                : strings.CONSTRUCTOR.GRADES.GRADE_PLACEHOLDER)
                                        }
                                        disabled={useGradesCategories
                                            ? (!gradesCategories || gradesCategories.length === 0)
                                            : (!grades || grades.length === 0)
                                        }
                                        options={useGradesCategories
                                            ? prepareGradesCategoriesOptions(data.dependency, gradesCategories)
                                            : prepareGradesOptions(data.dependency, grades)
                                        }
                                        errorMessage={(data.errors?.find((error) => error.code === 1)) ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_WAS_RESET : gradeError}
                                    />
                                )}
                                {([Dependencies.IDENTIFICATION_METHOD].includes(data.dependency)) && (
                                    <Dropdown
                                        required
                                        selectedKey={data.value}
                                        onChange={(ev, opt) => { 
                                            validateGrade(opt);
                                            update(index, {
                                            ...data,
                                            value: opt?.key.toString()
                                        }, roadMap)
                                    }}
                                        onRenderLabel={onRenderLabel}
                                        style={{ width: 250 }}
                                        label={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.VALUE_LABEL}
                                        title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.VALUE_TITLE}
                                        placeholder={(!identifications || identifications.length === 0)
                                            ? strings.CONSTRUCTOR.IDENTIFICATION.NO_METHODS
                                            : strings.CONSTRUCTOR.IDENTIFICATION.METHOD_PLACEHOLDER}
                                        disabled={!identifications || identifications.length === 0}
                                        options={prepareIdentificationMethodsOptions(data.dependency, identifications)}
                                        errorMessage={(data.errors?.find((error) => error.code === 23)) ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.SKIP_IDENTIFICATION_ERROR : gradeError}
                                    />
                                )}
                                {![Dependencies.ASSESSMENT_GRADE, Dependencies.POST_OFFER_GRADE, Dependencies.ASSESSMENT_GRADE_INDEX, Dependencies.POST_OFFER_GRADE_INDEX, Dependencies.IDENTIFICATION_METHOD].includes(data.dependency) && (
                                    <TextField
                                        label={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.VALUE_LABEL}
                                        title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.VALUE_TITLE}
                                        placeholder={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.VALUE_PLACEHOLDER}
                                        onRenderLabel={onRenderLabel}
                                        styles={{ root: { width: 200 } }}
                                        value={data.value}
                                        onChange={(ev, val) => {
                                            validateGradeTextField(val);
                                            update(index, {
                                            ...data,
                                            value: val
                                        }, roadMap)
                                    }}
                                        errorMessage={gradeError}
                                    />
                                )}
                            </Stack.Item>
                        </>
                    )}
                    <Stack.Item>
                        {!data.and
                            // && roadMap.length < 2 
                            && (
                                <PrimaryButton width={70} 
                                    text={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.AND}
                                    disabled={hasErrors} 
                                    onClick={ev => update(index, {
                                        ...data,
                                        and: {
                                            dependency: Dependencies.ASSESSMENT_GRADE,
                                            name: ""
                                        }
                                    }, roadMap)} />
                            )}
                    </Stack.Item>
                    <Stack.Item>
                        {!data.or
                            // && roadMap.length < 2 
                            && (
                                <PrimaryButton width={70} 
                                    text={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.SKIP.FORM.OR}
                                    disabled={hasErrors} 
                                    onClick={ev => update(index, {
                                        ...data,
                                        or: {
                                            dependency: Dependencies.ASSESSMENT_GRADE,
                                            name: ""
                                        }
                                    }, roadMap)} />
                            )}
                    </Stack.Item>
                </Stack>
                {data.and && (
                    <SkipItem data={data.and} update={update} index={index} childType={AndOr.And} parent={data} roadMap={[...roadMap, AndOr.And]} updateErrorCount={updateErrorCount} errors={errors} deleteAction={deleteAction} setDeleteAction={setDeleteAction}/>
                )}
                {data.or && (
                    <SkipItem data={data.or} update={update} index={index} childType={AndOr.Or} parent={data} roadMap={[...roadMap, AndOr.Or]} updateErrorCount={updateErrorCount} errors={errors} deleteAction={deleteAction} setDeleteAction={setDeleteAction}/>
                )}
            </Stack>
        </Stack>
    )
}

const theme = getTheme();