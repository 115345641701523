import { endpoints } from "./endpoints";
export interface IGoogleMapsApiKey {
    apiKey: string,
    errors?: Array<Object>
}

export const GoogleMapsApi = {
    getApiKey: async (): Promise<IGoogleMapsApiKey> => {
        let result: IGoogleMapsApiKey = { apiKey: "" };
        await fetch(endpoints.v1.googleMaps.getApiKey)
            .then(async response => {
                await (response.json() as Promise<IGoogleMapsApiKey>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;

    }
}