import {
    Stack, TextField, Text, getTheme, ImageFit, Spinner,
    IComboBoxOption, Icon, SharedColors, mergeStyleSets,
    getFocusStyle, MaskedTextField, PrimaryButton
} from "@fluentui/react"
import { useId } from "@uifabric/react-hooks";
import React from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { onRenderLabel } from "../../../components/renders/onRenderLabel";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { ChooseIcon } from "../../../components/shared/chooseIcon/ChooseIcon";
import SuggestedSearch, { focusElement, handleTextToListKeyPress, preparePhoneCountryFilteredOptions } from "../../../components/shared/search/SuggestedSearch";
import { portalApi } from "../../../core/api/api";
import { CountryPhonePrefixCodes, getLocalizedPhoneMask, preparePhonePrefixOptions } from "../../../core/helpers/localization";
import { IPerson } from "../../../core/store";
import { IResponseError } from "@piceasoft/core";
import { IRequestResult } from "../../../core/store/typings/IResponseResult";
import { strings } from "../../../localization/strings";

export type TSettingsProfileTabProps = {
    data?: IPerson
    isUpdating: boolean
    onSubmit: (data: IPerson) => void
}

export const SettingsProfileTab: React.FC<TSettingsProfileTabProps> = props => {
    const { control, handleSubmit, setValue, getValues, reset, formState: { errors, isDirty } } = useForm<IPerson>({ defaultValues: { ...props.data } });
    React.useEffect(() => {
        reset({ ...props.data })
    }, [props.data])

    const onCancel = () => {
        reset({ ...props.data })
    }
    const onSubmit: SubmitHandler<IPerson> = async data => {
        console.log(data)
        let image = props.data?.imgSrc
        let errors: IResponseError[] = []

        if (image !== fileSrc) {
            if (file) {
                let uploadResult: IRequestResult<string> = { successed: false }
                if (file) {
                    uploadResult = await portalApi.settings.organization.uploadFile('profile-image', file, undefined, true);
                    if (uploadResult.errors && uploadResult.errors.length > 0) {
                        errors = uploadResult.errors;
                    }
                } else {
                    uploadResult.successed = true;
                }

                if (uploadResult.successed) {
                    image = uploadResult.data
                    setIsImageChanged(false)
                }
            }
            else {
                image = fileSrc
                console.log(image)
            }
        }

        if (errors.length > 0) {
        } else {
            data.imgSrc = image ?? ''
            console.log(data.imgSrc)
        }
        let preparedPhoneNumber = data.phone?.number?.replace(/\D/g, '')
        if (preparedPhoneNumber && preparedPhoneNumber.length > 10) {
            preparedPhoneNumber = preparedPhoneNumber.substring(0, 10)
        }
        data = { ...data, phone: undefined, phoneNumber: !preparedPhoneNumber ? undefined : ((data.phone?.country ?? '') + (data.phone?.country ? CountryPhonePrefixCodes[data.phone?.country as unknown as CountryPhonePrefixCodes].toString() : "") + (preparedPhoneNumber ?? "")) }
        props.onSubmit(data)
        console.log(data)
    };
    const allowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif"]

    const [file, setFile] = React.useState<File>()
    const [isImageChanged, setIsImageChanged] = React.useState(false)
    const [fileSrc, setFileSrc] = React.useState(props.data?.imgSrc)

    const addFile = (upFiles: FileList) => {
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => allowedFileTypes.includes(upFile.type))]
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            setIsImageChanged(true)
            setFileSrc(URL.createObjectURL(checkedUpFiles[0]))
            setFile(checkedUpFiles[0])
        }
    }

    const resetImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFileSrc(props.data?.imgSrc)
            setFile(undefined)
            setIsImageChanged(false)
        }
    }

    const deleteImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFile(undefined)
            setFileSrc(undefined)
            setIsImageChanged(true)
        }
    }

    const phoneCountryHostId = useId("phone-country-host-id")
    const phoneCountryTextId = useId("phone-country-text-id")
    const phoneCountryCalloutListId = "phone-country-callout-id"

    const phoneCountryOptions = preparePhonePrefixOptions();

    const onRenderPhoneCountrySuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${phoneCountryCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, phoneCountryOptions?.length ?? 0, phoneCountryTextId, phoneCountryCalloutListId, () => setValue("phone.country", item?.key.toString()), index, item?.text, phoneCountryHostId)}
                tabIndex={-1}
                onClick={() => {
                    setValue("phone.country", item?.key.toString())
                    focusElement(`${phoneCountryCalloutListId}-${index as number}`, phoneCountryHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                            {item?.title && <Text>{`(+${item?.title})`}</Text>}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const onRenderPlaceholder = (value?: string) => {
        const item = phoneCountryOptions.find(i => i.key === value)
        return item && (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                {item?.title && <Text>{`(+${item?.title})`}</Text>}
            </Stack>
        ) || (
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_PLACEHOLDER}</Text>
            )
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    return (
        <Stack style={{ width: 640 }} tokens={{ padding: 24, childrenGap: 16 }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack style={{ minWidth: 360 }} verticalFill tokens={{ childrenGap: 16 }} >
                        <Stack tokens={{ childrenGap: 16 }} >
                            <Stack.Item>
                                <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.PROFILE_SETTINGS_TITLE}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack tokens={{ childrenGap: 4 }}>
                                    <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.PROFILE_PICTURE}</Text>
                                    <ChooseIcon
                                        acceptTypeMask={"image/*"}
                                        allowedFileTypes={allowedFileTypes}
                                        type="icon"
                                        changed={isImageChanged}
                                        resetChanged={() => setIsImageChanged(true)}
                                        imgSrc={getValues().imgSrc}
                                        fileSrc={fileSrc}
                                        itemName={getValues().display ? getValues().display : getValues().name}
                                        generateIcon={true}
                                        lable={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.IMAGE_LOAD_LABEL}
                                        addFile={addFile}
                                        deleteImageSrc={deleteImageSrc}
                                        resetImageSrc={resetImageSrc}
                                        borderRadius={36}
                                        imageFit={ImageFit.cover}
                                    />
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <TextField
                                    value={props.data?.name}
                                    disabled
                                    label={strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.LOGIN_TITLE}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    control={control}
                                    name="display"
                                    render={({ field }) =>
                                        <TextField
                                            value={field.value}
                                            onChange={field.onChange}
                                            label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY}
                                            placeholder={strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.USER_NAME_PLACEHOLDER}
                                        />
                                    }
                                />
                            </Stack.Item>
                            {/* <Stack.Item>
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) =>
                                        <TextField
                                            value={field.value}
                                            onChange={field.onChange}
                                            type="email"
                                            label={strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.USER_EMAIL}
                                            placeholder={strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.USER_EMAIL_PLACEHOLDER}
                                        />
                                    }
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Stack tokens={{ childrenGap: 10 }}>
                                    <Controller
                                        control={control}
                                        name="phone.country"
                                        rules={{
                                            validate: (value) => {
                                                let number = getValues().phone?.number
                                                if (!number) return

                                                number = number.replace(/\D/g, '');
                                                if (number.length > 10) {
                                                    number = number.substring(0, 10)
                                                }

                                                if (number.length === 0) return;
                                                if (!value || value.length === 0) return strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.PHONE_COUNTRY;
                                            }
                                        }}
                                        render={({ field }) =>
                                            <SuggestedSearch
                                                type="dropdown-search"
                                                inputBoxId={phoneCountryTextId}
                                                hostId={phoneCountryHostId}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.PHONE_COUNTRY}
                                                title={strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.USER_PHONE_TITLE}
                                                onClickSuggestionCell={(item) => field.onChange((item as IComboBoxOption).key)}
                                                searchRequest={phoneCountryOptions.find(i => i.key === field.value)?.text.toString()}
                                                suggestionsListWidth={440}
                                                onRenderPlaceholder={() => onRenderPlaceholder(field.value)}
                                                suggestions={phoneCountryOptions}
                                                suggestionsListId={phoneCountryCalloutListId}
                                                isSearchEqualTheOneSuggestion={false}
                                                onRenderSuggestionCell={onRenderPhoneCountrySuggestionsCell}
                                                prepareFilteredItems={preparePhoneCountryFilteredOptions}
                                                selectedKey={field.value}
                                                errorMessage={errors.phone?.country?.message}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="phone.number"
                                        control={control}
                                        rules={{
                                            validate: (value) => {
                                                if (!value) return;

                                                value = value.replace(/\D/g, '');
                                                if (value.length > 10) {
                                                    value = value.substring(0, 10)
                                                }

                                                if (value.length === 10) return;
                                                if (value.length === 0) return;
                                                return strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_FILL;
                                            }
                                        }}
                                        render={({ field }) =>
                                            <MaskedTextField
                                                value={field.value ?? undefined}
                                                onChange={(ev, value) => field.onChange(value ? value.substring(0, getLocalizedPhoneMask(getValues().phone).length) : undefined)}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                mask={getLocalizedPhoneMask(getValues().phone)}
                                                label={strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.USER_PHONE}
                                                title={strings.SETTINGS.USER_SETTINGS.PROFILE_SETTINGS.USER_PHONE}
                                                // placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.PHONE_NUMBER_PLACEHOLDER}
                                                errorMessage={errors.phone?.number?.message}
                                            />
                                        }
                                    />
                                </Stack>
                            </Stack.Item> */}
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 8 }} horizontalAlign="start" grow>
                            <SecondaryButton onClick={onCancel} disabled={props.isUpdating || (isDirty ? false : true)}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                            <PrimaryButton disabled={props.isUpdating || (isDirty || isImageChanged ? false : true)} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                            {props.isUpdating && (
                                <Spinner />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    )
}

const theme = getTheme();

