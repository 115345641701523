import {
    Stack,
    SharedColors,
    Text,
    Link,
    Separator,
    DetailsList,
    IColumn,
    SelectionMode,
    DetailsListLayoutMode,
    getTheme,
    IDetailsHeaderProps,
    IRenderFunction,
    TooltipHost,
    IDetailsColumnRenderTooltipProps,
    StickyPositionType,
    Sticky,
    IGroup,
    IGroupHeaderProps,
    Icon,
    DirectionalHint,
    ProgressIndicator,
    IDetailsListProps,
    DetailsRow,
    IDetailsRowStyles
} from "@fluentui/react"
import React from "react"
import { DangerButton } from "../../../../../components/shared/buttons/DangerButton"
import { Badge } from "../../../../../components/shared/decorations/Badge"
import { PropertyValue } from "../../../../../components/shared/decorations/PropertyValue"
import { Section } from "../../../../../components/shared/decorations/Section"
import { onRenderColumnHeaderCommon } from "../../../../../components/shared/detailsList/commonRenders"
import { IService, IStore } from "../../../../../core/store"
import { IGrade, ServiceTypes } from "@piceasoft/core"
import { strings } from "../../../../../localization/strings"
import { TGrade } from "../ServiceConfigurationPivotBasedState"
import { getLocalizedChannel, getLocalizedServiceType, getLocalizedDeviceCheckSolution } from "../../../../../localization/helpers/channel";
import { useSelector } from "react-redux";
import { endpoints } from "../../../../../core/api/endpoints";
import { Experience } from "../../../../../components/shared/configurator/helpers/evisibility";
import { WorkflowQrCodeItem } from "../../../../../components/shared/qrCode/WorkflowQrCodeItem";
import { CSHHelpLink } from "../../../../../components/shared/help/CSHHelp"

export type TServiceInformationPivotProps = {
    data: IService
    grades?: TGrade[]
    onDelete: () => void
}

export const ServiceConfigurationInformationPivot: React.FC<TServiceInformationPivotProps> = ({ data, onDelete, grades }) => {

    const gradesCategories = data.workflow?.gradesCategories

    const [gradesGroups, setGradeGroups] = React.useState<IGroup[]>([])
    const [allGrades, setAllGrades] = React.useState<IGrade[]>([])
    const applicationUrl = useSelector((s: IStore) => s.environment.applicationUrl)
    const qrCodeLink = React.useMemo(() => endpoints.external.applicationWidget(applicationUrl, data.id as number), [data.id]) 
        
    React.useEffect(() => {
        prepareGroups()
    }, [])

    const prepareGroups = () => {
        let newGroups: IGroup[] = []
        let newGrades: IGrade[] = []

        if (gradesCategories) {
            gradesCategories.forEach(gc => {
                newGroups.push({
                    key: gc.index.toString(),
                    name: gc.name,
                    data: gc,
                    startIndex: newGrades.length, count: gc.grades.length,
                    isCollapsed: false
                })
                newGrades.push(...gc.grades)
            })
        }
        if (newGroups.length > 0) {
            setGradeGroups(newGroups)
        }
        if (newGrades.length > 0) {
            setAllGrades(newGrades)
        }
    }

    const columns: IColumn[] = [
        {
            key: 'index',
            name: strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.TABLE_COLUMNS.INDEX,
            fieldName: 'index',
            minWidth: 26,
            maxWidth: 26,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IGrade) => <Text style={{ color: SharedColors.gray20 }}>{item.index}</Text>,
            isPadded: true,
        },
        {
            key: 'code',
            name: strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.TABLE_COLUMNS.CODE,
            fieldName: 'code',
            minWidth: 40,
            maxWidth: 120,
            isResizable: true,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: TGrade) => data.workflow.useGradesCategories
                ? (
                    <Text style={{ fontWeight: 600, color: SharedColors.cyanBlue10 }}>{item.code}</Text>
                )
                : (
                    <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 8 }}>
                        {!item.isInCatalog && (
                            <TooltipHost directionalHint={DirectionalHint.bottomCenter}
                                content={strings.CONSTRUCTOR.GRADES.NOT_CATALOG_GRADE}
                                styles={{ root: { display: "flex", cursor: "default" } }}
                                tooltipProps={{
                                    calloutProps: {
                                        styles: {
                                            beak: { background: theme.palette.black },
                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                        },
                                    },
                                }}>
                                <Icon iconName="Info" styles={{ root: { display: "flex", color: SharedColors.red10 } }} />
                            </TooltipHost>
                        )}
                        <Text style={{ fontWeight: 600, color: SharedColors.cyanBlue10 }}>{item.code}</Text>
                    </Stack>
                ),
            isPadded: true,
        },
        {
            key: 'name',
            name: strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 40,
            maxWidth: 180,
            isResizable: true,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IGrade) => <Text style={{ fontWeight: 600, color: SharedColors.gray40 }}>{item.name}</Text>,
            isPadded: true,
        },
        {
            key: 'description',
            name: strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.TABLE_COLUMNS.DESCRIPTION,
            fieldName: 'description',
            minWidth: 80,
            maxWidth: 180,
            isResizable: true,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IGrade) => <Text style={{ color: SharedColors.gray40 }}>{item.description}</Text>,
            isPadded: true,
        },
        {
            key: 'explanation',
            name: strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.TABLE_COLUMNS.EXPLANATION,
            fieldName: 'explanation',
            minWidth: 40,
            maxWidth: 180,
            isResizable: true,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IGrade) => <Text style={{ color: SharedColors.gray40 }}>{item.explanation}</Text>,
            isPadded: true,
        }
    ]

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        props.styles = { root: { padding: 0 } }
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                background: rProps.item?.isInCatalog ? undefined : 'rgb(253,231,233, 0.6)',
                '&:hover': {
                    background: rProps.item?.isInCatalog ? undefined : 'rgb(253,231,233)'
                }
            };
            customStyles.cellUnpadded = { paddingLeft: 6 }
            return <DetailsRow {...rProps} styles={customStyles} />
        }
        return null;
    }

    const onRenderGroupTitle: IRenderFunction<IGroupHeaderProps> = (gProps) => {

        return (
            <Stack style={{ cursor: 'pointer' }} grow horizontal verticalAlign='center' tokens={{ padding: "12px 24px 12px 0px", childrenGap: 8 }}>
                <Stack.Item grow>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                        <Text variant="medium" style={{ color: SharedColors.gray40, fontWeight: 600 }}>{gProps?.group?.name}</Text>
                        <Badge title={`${gProps?.group?.count}`} />
                    </Stack>
                </Stack.Item>
            </Stack>
        )
    }

    const onGroupHeaderClick = (group: IGroup) => {
        setGradeGroups(gradesGroups.map(g => {
            if (g.key === group.key) {
                return { ...g, isCollapsed: !g.isCollapsed }
            }
            return g
        })
        )
    }

    return (
        <Stack tokens={{ padding: "24px 32px", childrenGap: 20 }}>
            <Section title={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.SERVICE_DATA}>
                <Stack tokens={{ childrenGap: 12 }}>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME} value={data.name} />
                        <PropertyValue label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL} value={getLocalizedChannel(data.channel)} />
                        <PropertyValue label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_TYPE} value={getLocalizedServiceType(data.serviceType)} />
                        
                        {data.serviceType != ServiceTypes.ContentTransfer && // device check solution is disabled for content transfer
                            <PropertyValue label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.DEVICE_CHECK_SOLUTION} value={getLocalizedDeviceCheckSolution(data.deviceCheckSolution)} />
                        }
                        <PropertyValue label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.CODE} value={data.code} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SUMMARY} value={data.summary} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.CATALOG} value={data.catalogName} />
                        <PropertyValue label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.DOCUMENT} value={data.documentName} />
                        {data.channel === Experience.Mobile && (<WorkflowQrCodeItem qrCodeLink={qrCodeLink} />)}
                    </Stack>
                </Stack>
            </Section>
            {data.workflow && (data.workflow.useGradesCategories && (data.workflow.gradesCategories && data.workflow.gradesCategories.length > 0 && (
                <Section title={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.CATEGORIES_TITLE}>
                    <Stack tokens={{ childrenGap: 12 }}>
                        <Stack.Item>
                            <DetailsList
                                items={allGrades}
                                groups={gradesGroups}
                                groupProps={{
                                    headerProps: {
                                        onRenderTitle: onRenderGroupTitle,
                                        onGroupHeaderClick: onGroupHeaderClick
                                    }

                                }}
                                columns={columns}
                                setKey="none"
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.justified}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                isHeaderVisible={true}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="small" style={{ color: SharedColors.gray20 }}>
                                {strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.NOTE}
                            </Text>
                        </Stack.Item>
                    </Stack>
                </Section>
            )) || (
                    grades === undefined && (
                        <ProgressIndicator />
                    ) || (
                        grades && grades.length > 0 && (
                            <Section title={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.TITLE}>
                                <Stack tokens={{ childrenGap: 12 }}>
                                    <Stack.Item>
                                        <DetailsList
                                            items={grades}
                                            columns={columns}
                                            setKey="none"
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            onRenderDetailsHeader={onRenderDetailsHeader}
                                            isHeaderVisible={true}
                                            onRenderRow={onRenderRow}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Text variant="small" style={{ color: SharedColors.gray20 }}>
                                            {strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.GRADES.NOTE}
                                        </Text>
                                    </Stack.Item>
                                </Stack>
                            </Section>
                        )
                    )
                )
            )}
            {!data.removed && (
                <>
                    <Stack.Item>
                        <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Stack.Item>
                                <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_TITLE}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_INFO_TEXT}</Text>
                                <br />
                                <CSHHelpLink articleid="1192" alignment="left" label={strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_INFO_MORE_HELP}></CSHHelpLink>
                            </Stack.Item>
                            <Stack.Item style={{ paddingTop: 8 }}>
                                <DangerButton onClick={onDelete} text={strings.BUTTONS.TEXT.DELETE} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </>
            )}
        </Stack>
    )
}

const theme = getTheme();