import { Stack, Spinner, Text, getTheme, MessageBar, MessageBarType, Modal, IconButton, FontWeights, mergeStyleSets, DefaultButton, Sticky, ScrollablePane } from '@fluentui/react'
import { useBoolean } from '@uifabric/react-hooks'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ConfirmationForm } from '../../../components/shared/forms/ConfirmationForm'
import { PrintTemplateForm } from '../../../components/shared/forms/PrintTemplateForm'
import { ServiceForm } from '../../../components/shared/forms/ServiceForm'
import { CustomPanel } from '../../../components/shared/panel/CustomPanel'
import { SomethingWentWrong } from '../../../components/shared/somethingWentWrong/SomethingWentWrong'
import { portalApi } from '../../../core/api/api'
import { IDocument, IService } from '../../../core/store'
import { strings } from '../../../localization/strings'
import { ServicesPrintTemplateDetails } from './ServicesPrintTemplateDetails'
import { ServicesPrintTemplateHeader } from './ServicesPrintTemplateHeader'

type TProps = {
}

export const ServicesPrintTemplate: React.FC<TProps> = (props) => {

    const [printTemplateState, setPrintTemplateState] = React.useState<{ fetched: boolean, error?: string, data?: IDocument }>({ fetched: false })
    const [error, setError] = React.useState<string>()
    const [confirmation, { setTrue: showConfirmation, setFalse: hideConfirmation }] = useBoolean(false)
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const { templateId } = useParams<{ templateId: string }>();
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const history = useHistory();

    React.useEffect(() => {
        getPrintTemplate(parseInt(templateId))
    }, [])

    const getPrintTemplate = async (id: number) => {
        const result = await portalApi.organization.documents.getDocument(id)
        console.log(result)
        if (result.successed && result.data) {
            setPrintTemplateState({ fetched: true, data: result.data })
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation()
            setError(result.errors[0].description)
        }
    }

    const updatePrintTemplate = async (item: IDocument) => {
        setFormError(undefined)
        startFetch()
        const result = await portalApi.organization.documents.updateDocument(item)
        if (result.successed && result.data) {
            setPrintTemplateState({ fetched: true, data: result.data })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setError(result.errors[0].description)
        }
    }

    const deletePrintTemplate = async (id: number) => {
        startFetch()
        const result = await portalApi.organization.documents.deleteDocument(id)
        if (result.successed) {
            history.push("/services/print");
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation();
            setError(result.errors[0].description);
            return;
        }
        if (!result.successed) {
            setError("There is a problem! Document template wasn't deleted! Server response false without an error")
        }
        stopFetch()
        hideConfirmation()
    }

    const onFormSubmit = (data: IDocument) => {
        printTemplateState.data?.id && updatePrintTemplate(data)
    }

    return (
        <>
            <Stack verticalFill style={{ position: 'relative' }}>
                {!error && (printTemplateState.data && (
                    <ScrollablePane>
                        <Stack verticalFill>
                            <ServicesPrintTemplateHeader onEdit={showPanel} item={printTemplateState.data} />
                            <Stack.Item verticalFill>
                                <ServicesPrintTemplateDetails onRetry={() => getPrintTemplate(parseInt(templateId))} data={printTemplateState.data} onDelete={showConfirmation} />
                            </Stack.Item>
                        </Stack>
                    </ScrollablePane>
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    ))}
                {error && (
                    <Stack verticalFill>
                        {error.length > 0 && (
                            <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                        )}
                        <Stack.Item verticalFill>
                            <SomethingWentWrong action={() => getPrintTemplate(parseInt(templateId))} />
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={hidePanel}
                title={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.EDIT_SERVICE}
            >
                {formError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {formError}
                    </MessageBar>
                )}
                {!formError && !fetching && (
                    <PrintTemplateForm
                        onSubmit={(data: IDocument) => onFormSubmit(data)}
                        onCancel={hidePanel}
                        data={printTemplateState.data as IDocument} />
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
            </CustomPanel >
            {printTemplateState.data?.id && (
                <Modal isOpen={confirmation} onDismiss={hideConfirmation} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideConfirmation}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={() => printTemplateState.data?.id && deletePrintTemplate(printTemplateState.data?.id)}
                            onCancel={hideConfirmation}
                            requiredMessageString={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.NAME_REQUIRED}
                            confirmationCheckString={printTemplateState.data?.name}
                            placeholder={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.NAME_PLACEHOLDER}
                            confirmText={strings.BUTTONS.TEXT.DELETE}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_CONFIRMATION_TEXT_NEW}</Text>
                                <Text>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{printTemplateState.data?.name}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>)}
        </>
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};