import React from "react"
import { ComboBox, IComboBoxOption, Stack } from "@fluentui/react"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import { strings } from "../../../../../../../../../../../localization/strings"

type TProps = {
    value?: string
    config?: IDataCollectionStandardConfig
    errorMessage: string
    phoneCountry?: string
    handleOnBlur: () => void
    handleOnChange: (value: string) => void
}

const Operator: React.FunctionComponent<TProps> = (props: TProps) => {
    let {value, config, errorMessage, handleOnChange, handleOnBlur} = props

    const [operatorOptions, setOperatorOptions] = React.useState<IComboBoxOption[]>()
    const testOperatorPrefix = strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.TEST_OPERATOR_NAME
    const operatorNames = [`${testOperatorPrefix} 1`, `${testOperatorPrefix} 2`, `${testOperatorPrefix} 3`]

    const prepareoperatorOptions = () => {
            let operatorArray: IComboBoxOption[] = [];
            operatorNames.map((on) => {
                let comboOption: IComboBoxOption = { key: on, text: on };
                operatorArray.push(comboOption);
            });
            setOperatorOptions(operatorArray)
    }

    React.useEffect(() => {
        prepareoperatorOptions()
    }, [])
    
    return (
        <Stack.Item grow>
            <ComboBox
                autoComplete="on"
                allowFreeform
                label={config?.fields?.operator?.label ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.OPERATOR_FULLNAME}
                placeholder={config?.fields?.operator?.placeholder ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.PLACEHOLDER_OPERATOR_FULLNAME}
                onChange={(ev, opt?: IComboBoxOption, i?: number, value?: string) => handleOnChange(value ?? opt?.text.toString() ?? "")}
                onInputValueChange={(value?: string) => handleOnChange(value ?? "")}
                onItemClick={(ev, opt?: IComboBoxOption) => opt && handleOnChange(opt?.text.toString())}
                useComboBoxAsMenuWidth
                options={operatorOptions ?? []}
                errorMessage={errorMessage}
                onBlur={handleOnBlur}
                key={"operator_combobox"}
                text={value}
                required
            />
        </Stack.Item>
    )
}

export default Operator