import {
    Dropdown, getTheme, IDropdownOption, PrimaryButton,
    ScrollablePane, Stack, TextField, Text, Icon, MaskedTextField,
    IComboBoxOption, getFocusStyle, mergeStyleSets, SharedColors,
    ChoiceGroup, IChoiceGroupOption, IChoiceGroupOptionProps, IRenderFunction, FontIcon
} from '@fluentui/react'
import { useBoolean, useId } from '@uifabric/react-hooks'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { escapeRegExp } from '../../../assets/ts/utils/regex'
import { portalApi } from '../../../core/api/api'
import { ICompany, IPerson, IPoint } from '../../../core/store'
import { AccountRoles, getAccountRoleTitle } from '../../../core/store/typings/AccountRoles'
import { IStructureNode } from '../../../core/store/typings/IStructureNode'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { strings } from '../../../localization/strings'
import SuggestedSearch, { focusElement, handleTextToListKeyPress, preparePhoneCountryFilteredOptions } from '../search/SuggestedSearch'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { Badge } from '../decorations/Badge'
import { CountryPhonePrefixCodes, getLocalizedPhoneMask, preparePhonePrefixOptions } from '../../../core/helpers/localization'
import { validatePassword } from '../configurator/stages/assessment/dataCollection/modeConfigurator/pivots/helpers/validationRules'
import { PasswordPolicy } from '../PasswordPolicy'
import { AllRoles, checkIsManagerUser } from '../../../core/store/typings/UserRoles'
import { CSHHelpLink } from '../help/CSHHelp'

type TProps = {
    onSubmit: (person: IPerson) => void
    onCancel: () => void
    data?: IPerson
    organizationDomain?: string
    organizationCountry?: string
}

export const PersonForm: React.FC<TProps> = (props) => {

    const { control, handleSubmit, watch, getValues, setValue, trigger, formState: { errors } } = useForm<IPerson & { repeatPassword?: string }>({
        defaultValues: {
            ...props.data,
            password: undefined,
            repeatPassword: undefined,
            phone: { ...props.data?.phone, country: !props.data?.phone?.country ? props.organizationCountry : props.data.phone.country },
            name: props.data?.name?.split("@")[0],
            roles: props.data?.pointId && (!props.data?.id && [AccountRoles.Operator]) || props.data?.roles,
            containerId: props.data?.containerId
        },
        mode: "all"
    });

    const onSubmit: SubmitHandler<IPerson & { repeatPassword?: string }> = data => {
        console.log(data)

        const availableRoles = rolesOptions.map(ro => ro.key);

        const filteredRoles = data.roles.filter(r => {
            return availableRoles.find(ar => ar === r);
        });

        if (filteredRoles.length > 0) {
            setShowRolesError(false)
            let preparedPhoneNumber = data.phone?.number?.replace(/\D/g, '')
            if (preparedPhoneNumber && preparedPhoneNumber.length > 10) {
                preparedPhoneNumber = preparedPhoneNumber.substring(0, 10)
            }

            delete (data.repeatPassword)

            if (isStructurePerson) {
                data.point = undefined;
                data.pointId = undefined;
            } else {
                data.containerId = undefined;
            }

            props.onSubmit(
                {
                    ...data,
                    roles: filteredRoles,
                    phone: undefined,
                    phoneNumber: !preparedPhoneNumber ? undefined : ((data.phone?.country ?? '') + (data.phone?.country ? CountryPhonePrefixCodes[data.phone?.country as unknown as CountryPhonePrefixCodes].toString() : "") + (preparedPhoneNumber ?? ""))
                }
            );
        } else {
            setShowRolesError(true)
        }
    };

    const isManager = checkIsManagerUser(watch().roles)

    const [isStructurePerson, setIsStructurePerson] = React.useState(props.data?.pointId ? false : (props.data?.pointId ? false : true))
    const [storeUserRoles, setStoreUserRoles] = React.useState(isStructurePerson ? [] : props.data?.roles)
    const [folderUserRoles, setFolderUserRoles] = React.useState(isStructurePerson ? props.data?.roles : [])

    const [companiesState, setCompaniesState] = React.useState<{ fetched: boolean, error?: string, data?: Array<ICompany> }>({ fetched: false })
    const [pointsState, setPointsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IPoint> }>({ fetched: false })

    const [companies, setCompanies] = React.useState<IComboBoxOption[]>()

    const [selectedCompany, setSelectedCompany] = React.useState<IComboBoxOption>()
    const companyHostId = useId("company-host-id")
    const companyTextId = useId("company-text-id")
    const companyCalloutListId = "company-callout-id"

    const [points, setPoints] = React.useState<IComboBoxOption[]>()

    const [selectedPoint, setSelectedPoint] = React.useState<IComboBoxOption>()
    const pointHostId = useId("point-host-id")
    const pointTextId = useId("point-text-id")
    const pointCalloutListId = "point-callout-id"

    const [containersState, setContainersState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IStructureNode> }>({ fetched: false })

    const [containers, setContainers] = React.useState<IComboBoxOption[]>()

    const [selectedContainer, setSelectedContainer] = React.useState<IComboBoxOption>()
    const containerHostId = useId("container-host-id")
    const containerTextId = useId("container-text-id")
    const containerCalloutListId = "container-callout-id"

    const phoneCountryHostId = useId("phone-country-host-id")
    const phoneCountryTextId = useId("phone-country-text-id")
    const phoneCountryCalloutListId = "phone-country-callout-id"

    const phoneCountryOptions = preparePhonePrefixOptions();

    const rolesOptions: IDropdownOption[] = getRolesOptions(isStructurePerson)

    const [showRolesError, setShowRolesError] = React.useState(false)
    const [isContactsShow, { setTrue: showContacts, setFalse: hideContacts }] = useBoolean(false)

    React.useEffect(() => {
        if (isStructurePerson) {
            getContainers()
        } else {
            if (selectedCompany) {
                getPoints(selectedCompany.key.toString())
            }
        }
    }, [isStructurePerson])

    React.useEffect(() => {
        getCompanies()
    }, [])

    // React.useEffect(() => {
    //     reset({
    //         ...props.data,
    //         phone: { ...props.data?.phone, country: !props.data?.phone?.country ? props.organizationCountry : props.data.phone.country },
    //         name: props.data?.name?.split("@")[0],
    //         roles: props.data?.pointId && (!props.data?.id && [AccountRoles.Operator]) || props.data?.roles,
    //         containerId: props.data?.containerId
    //     })
    //     getCompanies()
    // }, [props.data])

    React.useEffect(() => {
        if (companiesState.fetched && companiesState.data) {
            setCompanies(getCompaniesOptions(companiesState.data))
            setValue("companyId", companiesState.data.find(i => i.id === props.data?.companyId)?.name)
        }
    }, [companiesState])

    React.useEffect(() => {
        setSelectedPoint(undefined)
        if (selectedCompany?.key) {
            setValue("companyId", selectedCompany?.key.toString())
            if (!isStructurePerson) {
                getPoints(selectedCompany.key.toString())
            }
        }
    }, [selectedCompany])

    React.useEffect(() => {
        if (pointsState.fetched && pointsState.data) {
            setPoints(getPointsOptions(pointsState.data))
            setValue("pointId", pointsState.data.find(i => i.id === props.data?.pointId)?.name)
        }
    }, [pointsState])

    React.useEffect(() => {
        if (selectedPoint?.key) {
            setValue("pointId", selectedPoint?.key.toString())
        }
    }, [selectedPoint])

    React.useEffect(() => {
        trigger("password");
    }, [watch().roles?.length])

    const onRenderChoiceField: IRenderFunction<IChoiceGroupOption & IChoiceGroupOptionProps> = (props, render) => {
        const params = props?.key ? getOptionParams(props?.key) : undefined
        return (
            <Stack tokens={{ childrenGap: 8 }} grow horizontal verticalAlign="center" style={{
                borderBottom: `1px solid ${theme.palette.neutralLight}`,
                padding: "2px 0 10px 0",
                width: "100%"
            }}>
                <Stack.Item>
                    {render!(props)}
                </Stack.Item>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                    <Icon iconName={params?.icon} style={{ fontSize: 24, display: "flex" }} />
                    <Stack grow>
                        <Text variant="medium" style={{ fontWeight: 600, color: theme.palette.black }}>{params?.title}</Text>
                        <Text variant="small" style={{ color: SharedColors.gray20 }}>{params?.text}</Text>
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    const getOptionParams = (type: string): { icon: string, title: string, text: string } => {
        switch (type) {
            case 'Point': return ({
                icon: 'UserEvent',
                title: strings.ORGANIZATION.COMMON.PERSON_TYPES.POINT_TITLE,
                text: strings.ORGANIZATION.COMMON.PERSON_TYPES.POINT_TEXT
            })
            case 'Structure': return ({
                icon: 'AccountManagement',
                title: strings.ORGANIZATION.COMMON.PERSON_TYPES.STRUCTURE_TITLE,
                text: strings.ORGANIZATION.COMMON.PERSON_TYPES.STRUCTURE_TEXT
            })
            default: return { icon: '1', title: '2', text: '3' }
        }

    }

    const personTypeOptions: IChoiceGroupOption[] = [
        {
            key: 'Point',
            text: '',
            onRenderField: onRenderChoiceField
        },
        {
            key: 'Structure',
            text: '',
            onRenderField: onRenderChoiceField
        }
    ]

    const getCompanies = async () => {
        setCompaniesState({ fetched: false })
        const result = await portalApi.organization.companies.getCompanies()
        setCompaniesState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(c => !c.removed) })
        const dCompany = result.data?.find(i => i.id === props.data?.companyId)
        if (dCompany?.id) {
            setSelectedCompany({ key: dCompany.id, text: dCompany.name })
        }
    }

    const getPoints = async (companyId: string) => {
        setPointsState({ fetched: false })
        const result = await portalApi.organization.points.getPoints(companyId)
        setPointsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(c => !c.removed) })
        const dPoint = result.data?.find(i => i.id === props.data?.pointId)
        if (dPoint?.id) {
            setSelectedPoint({ key: dPoint.id, text: dPoint.name, title: dPoint.code })
        }
    }

    const getCompaniesOptions = (items: ICompany[]): IDropdownOption[] => {
        return items.map(c => ({ key: c.id, text: c.name } as IDropdownOption))
    }

    const getPointsOptions = (items: IPoint[]): IDropdownOption[] => {
        return items.map(p => ({ key: p.id, text: p.name, title: p.code } as IDropdownOption))
    }

    React.useEffect(() => {
        if (containers && !selectedContainer) {
            setSelectedContainer(containers.find(c => c.key === props.data?.containerId) ?? containers[0])
        }
    }, [containers])

    React.useEffect(() => {
        if (containersState.fetched && containersState.data) {
            const parentId = containersState.data.find(i => i.id === props.data?.containerId)?.id
            setContainers(getContainersOptions(containersState.data))
            if (parentId) {
                setValue("containerId", parentId)
            }
        }
    }, [containersState])

    React.useEffect(() => {
        setValue("containerId", selectedContainer?.key.toString() !== 'null' ? selectedContainer?.key.toString() : undefined)
        if (containersState.data) {
            setContainers(getContainersOptions(containersState.data))
        }
    }, [selectedContainer])

    const getContainersOptions = (containerNodes: IStructureNode[]): IDropdownOption[] => {
        return [{ key: 'null', text: strings.COMMON.NOT_CHOSED }, ...containerNodes.map(cn => ({ key: cn.itemId, text: cn.itemName } as IDropdownOption))]
    }

    const getContainers = async () => {
        setContainersState({ fetched: false })
        const result = await portalApi.organization.containers.getContainers()
        setContainersState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: props.data?.id ? result.data?.filter(i => i.id !== props.data?.id) : result.data })
        const parent = result.data?.find(i => i.id === props.data?.containerId)
        if (parent) {
            setSelectedContainer({ key: parent.id, text: parent.itemName })
        }
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderCompanySuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${companyCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, companies?.length ?? 0, companyTextId, companyCalloutListId, () => companySelectHandler(item), index, item?.text, companyHostId)}
                tabIndex={-1}
                onClick={() => {
                    companySelectHandler(item)
                    focusElement(`${companyCalloutListId}-${index as number}`, companyHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const companySelectHandler = (item?: IComboBoxOption) => {
        setSelectedCompany(item)
    }

    const onRenderPointSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${pointCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, points?.length ?? 0, pointTextId, pointCalloutListId, () => pointSelectHandler(item), index, item?.text, pointHostId)}
                tabIndex={-1}
                onClick={() => {
                    pointSelectHandler(item)
                    focusElement(`${pointCalloutListId}-${index as number}`, pointHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            {item?.title && <Badge title={item?.title} />}
                            <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const onRenderPhoneCountrySuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${phoneCountryCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, phoneCountryOptions?.length ?? 0, phoneCountryTextId, phoneCountryCalloutListId, () => setValue("phone.country", item?.key.toString()), index, item?.text, phoneCountryHostId)}
                tabIndex={-1}
                onClick={() => {
                    setValue("phone.country", item?.key.toString())
                    focusElement(`${phoneCountryCalloutListId}-${index as number}`, phoneCountryHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                            {item?.title && <Text>{`(+${item?.title})`}</Text>}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const onRenderPlaceholder = (value?: string) => {
        const item = phoneCountryOptions.find(i => i.key === value)
        return item && (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                {item?.title && <Text>{`(+${item?.title})`}</Text>}
            </Stack>
        ) || (
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_PLACEHOLDER}</Text>
            )
    }

    const pointSelectHandler = (item?: IComboBoxOption) => {
        setSelectedPoint(item)
    }

    const onRenderContainerSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${containerCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, containers?.length ?? 0, containerTextId, containerCalloutListId, () => containerSelectHandler(item), index, item?.text, containerHostId)}
                tabIndex={-1}
                onClick={() => {
                    containerSelectHandler(item)
                    focusElement(`${containerCalloutListId}-${index as number}`, containerHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(`${(i.title ?? "").toLowerCase()}${i.text.toLowerCase()}`)
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ ...i, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    const containerSelectHandler = (item?: IComboBoxOption) => {
        setSelectedContainer(item)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{
                                    required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_REQUIRED,
                                    minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` },
                                    pattern: {
                                        value: /[A-z0-9-_.]/,
                                        message: strings.ORGANIZATION.STRUCTURE.ITEM.USERNAME_VALIDATE
                                    },
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        // autoComplete="username"
                                        suffix={`@${props.organizationDomain ?? "noDomain"}`}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME}
                                        description={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME}
                                        placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            {!props.data?.id && (
                                <Controller
                                    control={control}
                                    name="password"
                                    rules={validatePassword(undefined, isManager)}
                                    render={({ field }) =>
                                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                                            <Stack.Item grow>
                                                <TextField
                                                    required
                                                    value={field.value}
                                                    styles={{
                                                        fieldGroup: {
                                                            borderColor: field.value && !errors.password?.message ? "#5DCA42" : undefined, ':after': {
                                                                borderColor: field.value && !errors.password?.message ? "#5DCA42" : undefined
                                                            }
                                                        }
                                                    }}
                                                    onChange={(ev, val) => { field.onChange(val); trigger("repeatPassword") }}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    type='password'
                                                    canRevealPassword
                                                    label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD}
                                                    title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD}
                                                    description={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD}
                                                    placeholder={strings.COMMON.CREATE_PASSWORD}
                                                    errorMessage={errors.password?.message}
                                                />
                                            </Stack.Item>
                                            <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 24px 0" }}>
                                                <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().password && !errors.password) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                            </Stack.Item>
                                        </Stack>
                                    }
                                />
                            )}
                            {!props.data?.id && (
                                <Stack.Item>
                                    <PasswordPolicy isManager={isManager} />
                                    <Controller
                                        control={control}
                                        name="repeatPassword"
                                        rules={{
                                            // required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED,
                                            validate: (value) => {
                                                return (watch().password === value) ? true : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED
                                            }
                                        }}
                                        render={({ field }) =>
                                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                                <Stack.Item grow>
                                                    <TextField
                                                        value={field.value}
                                                        type="password"
                                                        required
                                                        styles={{
                                                            fieldGroup: {
                                                                borderColor: field.value && !errors.repeatPassword?.message ? "#5DCA42" : undefined, ':after': {
                                                                    borderColor: field.value && !errors.repeatPassword?.message ? "#5DCA42" : undefined
                                                                }
                                                            }
                                                        }}
                                                        canRevealPassword
                                                        onChange={field.onChange}
                                                        label={strings.COMMON.CONFIRM_PASSWORD}
                                                        placeholder={strings.COMMON.CONFIRM_PASSWORD}
                                                        errorMessage={errors.repeatPassword?.message}
                                                    />
                                                </Stack.Item>
                                                <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>
                                                    <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().repeatPassword && !errors.repeatPassword) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                                </Stack.Item>
                                            </Stack>
                                        }
                                    />
                                </Stack.Item>
                            )}
                            <Controller
                                name="companyId"
                                control={control}
                                rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY_REQUIRED }}
                                render={({ field }) =>
                                    <SuggestedSearch
                                        type="dropdown-search"
                                        inputBoxId={companyTextId}
                                        hostId={companyHostId}
                                        required
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY}
                                        description={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY}
                                        inputBoxPlaceholder={!companies ? strings.SPINNERS.DATA_IS_GETTING : strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY_PLACEHOLDER}
                                        onClickSuggestionCell={companySelectHandler}
                                        // setSearchRequest={(opt: IComboBoxOption) => field.onChange(opt?.text)}
                                        searchRequest={companies?.find(i => i.key === field.value)?.text.toString()}
                                        suggestionsListWidth={440}
                                        disabled={!companies || companies.length === 0}
                                        suggestions={companies ?? []}
                                        suggestionsListId={companyCalloutListId}
                                        isSearchEqualTheOneSuggestion={false}
                                        onRenderSuggestionCell={onRenderCompanySuggestionsCell}
                                        prepareFilteredItems={prepareFilteredOptions}
                                        selectedKey={selectedCompany?.key.toString()}
                                        errorMessage={errors.companyId?.message}
                                    />}
                            />
                            <ChoiceGroup
                                styles={{
                                    flexContainer: {
                                        display: 'flex',
                                        flexDirection: 'column'
                                    },
                                    root: {
                                        display: 'flex',
                                        flexGrow: 1,
                                        paddingBottom: 8
                                    },
                                    label: {
                                        display: 'flex',
                                        fontSize: 14,
                                        color: theme.palette.black,
                                        fontWeight: 500
                                    }
                                }}
                                label={strings.ORGANIZATION.COMMON.PERSON_TYPES.TITLE}
                                options={personTypeOptions}
                                selectedKey={isStructurePerson ? personTypeOptions.find(i => i.key === "Structure")?.key : personTypeOptions.find(i => i.key === "Point")?.key}
                                onChange={(ev, option) => {
                                    const isStructurePerson = option?.key === "Structure";

                                    if (props.data) {
                                        let userRoles = (isStructurePerson ? folderUserRoles : storeUserRoles) ?? [];

                                        if (!props.data?.id && !isStructurePerson && userRoles.length === 0) {
                                            userRoles = [AccountRoles.Operator];
                                        }

                                        setValue("roles", userRoles);
                                    }

                                    setIsStructurePerson(isStructurePerson)
                                }}
                            />
                            {!isStructurePerson && (
                                <Controller
                                    control={control}
                                    name="pointId"
                                    rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.POINT_REQUIRED }}
                                    render={({ field }) =>
                                        <SuggestedSearch
                                            type="dropdown-search"
                                            inputBoxId={pointTextId}
                                            hostId={pointHostId}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.POINT}
                                            title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.POINT}
                                            inputBoxPlaceholder={!selectedCompany ? strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.CHOOSE_COMPANY : (!points ? strings.SPINNERS.DATA_IS_GETTING : points.length > 0 ? (strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.POINT_PLACEHOLDER) : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.NO_POINTS)}
                                            onClickSuggestionCell={pointSelectHandler}
                                            // setSearchRequest={(opt: IComboBoxOption) => field.onChange(opt?.text)}
                                            searchRequest={points?.find(i => i.key === field.value)?.text.toString()}
                                            suggestionsListWidth={440}
                                            disabled={(!selectedCompany || (!points || points.length === 0))}
                                            suggestions={points ?? []}
                                            suggestionsListId={pointCalloutListId}
                                            isSearchEqualTheOneSuggestion={false}
                                            onRenderSuggestionCell={onRenderPointSuggestionsCell}
                                            prepareFilteredItems={prepareFilteredOptions}
                                            selectedKey={selectedPoint?.key.toString()}
                                            errorMessage={errors.pointId?.message}
                                        />
                                    }
                                />
                            ) || (
                                    <Controller
                                        control={control}
                                        name="containerId"
                                        render={({ field }) =>
                                            <SuggestedSearch
                                                type="dropdown-search"
                                                inputBoxId={containerTextId}
                                                hostId={containerHostId}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT}
                                                inputBoxPlaceholder={!containers ? strings.SPINNERS.DATA_IS_GETTING : containers.length > 0 ? (strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT_PLACEHOLDER) : strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT_NO_CONTAINERS}
                                                onClickSuggestionCell={containerSelectHandler}
                                                // setSearchRequest={(opt: IComboBoxOption) => field.onChange(opt?.text)}
                                                searchRequest={containers?.find(i => i.key === field.value)?.text}
                                                suggestionsListWidth={440}
                                                disabled={!containers || containers.length === 0}
                                                suggestions={containers ?? []}
                                                suggestionsListId={containerCalloutListId}
                                                isSearchEqualTheOneSuggestion={false}
                                                onRenderSuggestionCell={onRenderContainerSuggestionsCell}
                                                prepareFilteredItems={prepareFilteredOptions}
                                                selectedKey={selectedContainer?.key.toString()}
                                                errorMessage={errors.containerId?.message}
                                            />
                                        }
                                    />
                                )}
                            <Controller
                                name="roles"
                                control={control}
                                rules={{ minLength: { value: 1, message: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ROLES_REQUIRED } }}
                                render={({ field }) =>
                                    <Dropdown
                                        onChange={(ev, opt) => {
                                            let newRoles = opt?.selected ?
                                                (opt?.key && (field.value ? [...field.value, opt.key] : [opt.key as string])) :
                                                (field.value?.filter(v => v !== opt?.key) ?? []);

                                            let userRoles = (isStructurePerson ? folderUserRoles : storeUserRoles) ?? [];

                                            userRoles = opt?.selected
                                                ? [...userRoles, opt?.key as AllRoles]
                                                : userRoles.filter(r => r !== opt?.key);

                                            if (isStructurePerson) {
                                                setFolderUserRoles(userRoles);
                                            } else {
                                                setStoreUserRoles(userRoles);
                                            }

                                            field.onChange(newRoles);
                                        }}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ROLES}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ROLES}
                                        placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ROLES_PLACEHOLDER}
                                        options={rolesOptions}
                                        required
                                        defaultChecked={true}
                                        multiSelect
                                        selectedKeys={field.value}
                                        errorMessage={(showRolesError && field.value.length === 0) ? strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ROLES_REQUIRED : undefined}
                                    />
                                }
                            />
                            <Stack horizontalAlign="end">
                                <SecondaryButton onClick={isContactsShow ? hideContacts : showContacts}>
                                    <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                        <Icon iconName={isContactsShow ? "ChevronUp" : "ChevronDown"} style={{ fontWeight: 500 }} />
                                        <Text style={{ fontWeight: 500 }}>{strings.BUTTONS.TEXT.ADVANCED}</Text>
                                    </Stack>
                                </SecondaryButton>
                            </Stack>
                            {isContactsShow && (
                                <>
                                    <Controller
                                        name="display"
                                        control={control}
                                        // rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY_REQUIRED }}
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY}
                                                placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY_PLACEHOLDER}
                                                errorMessage={errors.display?.message}
                                            />
                                        }
                                    />
                                    <Controller
                                        control={control}
                                        name="email"
                                        // rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_REQUIRED, minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` } }}
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.EMAIL}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.EMAIL}
                                                placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.EMAIL_PLACEHOLDER}
                                                errorMessage={errors.email?.message}
                                            />
                                        }
                                    />
                                    <Stack>
                                        <Controller
                                            control={control}
                                            name="phone.country"
                                            // rules={{ required: errors.phone?.number?.message ? strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_REQUIRED : undefined }}
                                            rules={{
                                                validate: (value) => {
                                                    let number = getValues().phone?.number
                                                    if (!number) return

                                                    number = number.replace(/\D/g, '');
                                                    if (number.length > 10) {
                                                        number = number.substring(0, 10)
                                                    }

                                                    if (number.length === 0) return;
                                                    if (!value || value.length === 0) return strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_REQUIRED;
                                                }
                                            }}
                                            render={({ field }) =>
                                                <SuggestedSearch
                                                    type="dropdown-search"
                                                    inputBoxId={phoneCountryTextId}
                                                    hostId={phoneCountryHostId}
                                                    onRenderLabel={onRenderLabel}
                                                    label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_LABLE}
                                                    title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_TITLE}
                                                    onClickSuggestionCell={(item) => field.onChange((item as IComboBoxOption).key)}
                                                    // setSearchRequest={(opt: IComboBoxOption) => field.onChange(opt?.text)}
                                                    searchRequest={phoneCountryOptions.find(i => i.key === field.value)?.text.toString()}
                                                    suggestionsListWidth={440}
                                                    onRenderPlaceholder={() => onRenderPlaceholder(field.value)}
                                                    suggestions={phoneCountryOptions}
                                                    suggestionsListId={phoneCountryCalloutListId}
                                                    isSearchEqualTheOneSuggestion={false}
                                                    onRenderSuggestionCell={onRenderPhoneCountrySuggestionsCell}
                                                    prepareFilteredItems={preparePhoneCountryFilteredOptions}
                                                    selectedKey={field.value}
                                                    errorMessage={errors.phone?.country?.message}
                                                />
                                            }
                                        />
                                    </Stack>
                                    <Controller
                                        name="phone.number"
                                        control={control}
                                        rules={{
                                            validate: (value) => {
                                                if (!value) return;

                                                value = value.replace(/\D/g, '');
                                                if (value.length > 10) {
                                                    value = value.substring(0, 10)
                                                }

                                                if (value.length === 10) return;
                                                if (value.length === 0) return;
                                                return strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_FILL;
                                            }
                                        }}
                                        render={({ field }) =>
                                            <MaskedTextField
                                                value={field.value ?? undefined}
                                                onChange={(ev, value) => field.onChange(value ? value.substring(0, getLocalizedPhoneMask(getValues().phone).length) : undefined)}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                mask={getLocalizedPhoneMask(getValues().phone)}
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE}
                                                errorMessage={errors.phone?.number?.message}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink alignment='left' articleid='1109' />
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}

const getRolesOptions = (isStructurePerson?: boolean): IDropdownOption[] => {
    return Object.values(AccountRoles).filter(i => isStructurePerson ? i !== AccountRoles.Operator : i === AccountRoles.Operator).map(r => ({ key: r, text: getAccountRoleTitle(r) } as IDropdownOption))
}

const theme = getTheme();
