import {
    ColorPicker, Dropdown, FontIcon, getTheme, Text,
    IDropdownProps, IRenderFunction, ISelectableOption,
    Stack,
    IconButton,
    TooltipHost,
    DirectionalHint,
    Separator
} from "@fluentui/react"
import { SharedColors } from "@fluentui/theme"
import React from "react"
import { onRenderLabel } from "../../../components/renders/onRenderLabel"
import { IBranding } from "../../../core/store/typings/IBranding"
import { strings } from "../../../localization/strings"
import { getNameOfColorByKey, TMainColor } from "./BrandingDetails"

type TProps = {
    mainColors: TMainColor[]
    fieldValue: string
    label?: string
    description?: string
    placeholder?: string
    colorKey?: string
    onChangeField: (value?: string) => void
    onChangePalleteColors?: (key: string, value: string, prevValue: string) => void
    disabled?: boolean
}
export const BrandingSelectColorComponent: React.FC<TProps> = (props) => {

    const [currentColorState, setCurrentColorState] = React.useState(props.fieldValue ?? '#fff')
    const getValueOfField = (color: string) => {
        if (!props.colorKey) {
            switch (color) {
                case props.mainColors[0].value: return getNameOfColorByKey(props.mainColors[0].key)
                case props.mainColors[1].value: return getNameOfColorByKey(props.mainColors[1].key)
                case props.mainColors[2].value: return getNameOfColorByKey(props.mainColors[2].key)
            }
        }
    }

    const onRenderItem: IRenderFunction<ISelectableOption> = (dropdownprops) => {
        return (
            <Stack style={{ borderRadius: 3 }} tokens={{ padding: 6 }} >
                <ColorPicker key={props.label} color={props.fieldValue ?? '#fff'}
                    alphaType="none"
                    showPreview
                    styles={{ root: { borderRadius: 3 } }}
                    onChange={(ev, value) => {
                        props.onChangeField(`#${value.hex}`)
                    }}
                />
                <Separator />
                <Stack horizontal tokens={{ childrenGap: 6, padding: '0 16px 0 16px' }}>
                    <Stack.Item grow>
                        <TooltipHost content={strings.ORGANIZATION.BRANDING.COLORS.CURRENT}
                            directionalHint={DirectionalHint.bottomCenter}>
                            <IconButton style={{ width: getColorOfButton(currentColorState) === theme.palette.white ? 21 : 20, height: getColorOfButton(currentColorState) === theme.palette.white ? 21 : 20, borderRadius: 3, background: currentColorState, border: currentColorState === 'transparent' ? "1px solid black" : `1px solid ${getColorOfButton(currentColorState)}` }} onClick={() => props.onChangeField(currentColorState)} />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        <TooltipHost content={strings.ORGANIZATION.BRANDING.COLORS.TRANSPARENT}
                            directionalHint={DirectionalHint.bottomCenter}>
                            <IconButton style={{ width: 20, height: 20, borderRadius: 3, background: "transparent", border: `1px solid black` }} onClick={() => props.onChangeField("transparent")} />
                        </TooltipHost>
                    </Stack.Item>
                    {props.mainColors.map((color) =>
                        <Stack.Item key={color.key}>
                            <TooltipHost content={getNameOfColorByKey(color.key, true)}
                                directionalHint={DirectionalHint.bottomCenter}>
                                <IconButton style={{ width: 20, height: 20, borderRadius: 3, backgroundColor: color.value }} onClick={() => props.onChangeField(color.value)} />
                            </TooltipHost>
                        </Stack.Item>
                    )}
                </Stack>
            </Stack>
        )
    }

    const onRenderCaretDown: IRenderFunction<IDropdownProps> = () => {
        return (
            <FontIcon
                iconName="Add"
                style={{
                    marginTop: '3px',
                    fontSize: 12,
                    fontWeight: 600,
                    color: getColorOfButton(props.fieldValue),
                }} />
        )
    }

    const getRGB = (color: string) => {
        const r = color.substring(1, 3),
            g = color.substring(3, 5),
            b = color.substring(5, 7);
        return {
            R: parseInt(r, 16),
            G: parseInt(g, 16),
            B: parseInt(b, 16)
        };
    }

    const addAlpha = (color: string, opacity: number): string => {
        // coerce values so ti is between 0 and 1.
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }

    const getColorOfButton = (grbColor?: string) => {
        if (grbColor) {
            if (grbColor.length === 4) {
                grbColor = '#' + grbColor[1] + grbColor[1] + grbColor[2] + grbColor[2] + grbColor[3] + grbColor;
            }
            var nThreshold = 105,
                components = getRGB(grbColor),
                bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114);
            return ((255 - bgDelta) < nThreshold) ? SharedColors.gray30 : theme.palette.white;
        }
        return SharedColors.gray30
    }
    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <Dropdown disabled={props.disabled} options={[{ key: 1, text: '' }]} onRenderItem={onRenderItem} onRenderLabel={onRenderLabel}
                onRenderCaretDown={onRenderCaretDown}
                styles={{
                    caretDownWrapper: {
                        right: '4px',
                        top: '3px',
                        padding: getColorOfButton(props.fieldValue) === SharedColors.gray30 ? '0 6px' : '1px 7px',
                        backgroundColor: props.disabled ? addAlpha(props.fieldValue, 0.5): props.fieldValue,
                        borderRadius: 3,
                        border: getColorOfButton(props.fieldValue) === SharedColors.gray30 ? `${getColorOfButton(props.fieldValue)} 1px solid` : undefined,
                        lineHeight: 18,
                        height: 24
                    }, title: {
                        color: props.fieldValue && theme.palette.black
                    }                    
                }}
                onDismiss={() => {
                    let prevValue = currentColorState
                    setCurrentColorState(props.fieldValue)
                    if (props.onChangePalleteColors && props.colorKey) {
                        props.onChangePalleteColors(props.colorKey, props.fieldValue, prevValue)
                    }
                }}               
                selectedKey={getValueOfField(props.fieldValue) ? getValueOfField(props.fieldValue) : props.fieldValue}
                label={props.label}
                placeholder={getValueOfField(props.fieldValue) ? getValueOfField(props.fieldValue) : props.fieldValue && props.fieldValue !== '' ? props.fieldValue : (props.placeholder ? props.placeholder : strings.ORGANIZATION.BRANDING.BRANDING_PLACEHOLDER.SELECT_COLOR)}
                style={{ width: 300 }}
            />
            <Text style={{ color: SharedColors.gray30, fontSize: 10, width: 300 }}>{props.description}</Text>
        </Stack>
    )
}
const theme = getTheme()
