import { getTheme, Stack } from "@fluentui/react";
import React from "react";
import { strings } from "../../../localization/strings";
import { ItemsNotFound } from "../notFound/ItemsNotFound";

type TProps = {
    action?: () => void
    buttonText?: string
}

export const SomethingWentWrong: React.FC<TProps> = (props) => {

    const defaultAction = () => {}

    return (
        <Stack verticalAlign='center' verticalFill style={{backgroundColor: theme.palette.white}}>
            <ItemsNotFound onClick={props.action ?? defaultAction} 
                doNotUseButton={props.action ? false : true}
                buttonText={props.buttonText ?? strings.BUTTONS.TEXT.TRY_AGAIN}
                info={strings.TRANSACTIONS.ERROR.INFO}
                suggestion={strings.TRANSACTIONS.ERROR.SUGGESTION}
                imgSrc={"images/navigation/images/unknown_crop.png"}
            />
        </Stack>
    )
}

const theme = getTheme();