import { useSelector } from "react-redux"
import { IStore } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"
import { StagePreview } from "../../preview/StagePreview"
import { ResultStagePreview } from "./ResultStagePreview"
import { ProcessStages } from "@piceasoft/core"

export const ResultPreview: React.FC = () => {

    const config = useSelector((s: IStore) => s.configurator.result)

    if (!config) return null

    return (
        <ResultStagePreview config={config}
            defaultNextText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CLOSE_TRANSACTION}
            transactionNumber={"№CONF-0000-0000007"} 
            transaction={"CONF-0000-0000007"} 
            device={"Apple Iphone 11 64Gb Black"} 
            sku={"APPLE IPHONE 11 64 BLACK"} 
            sn={"11AO15RNQ"} 
            imei={"114252453425342532"} />
    )
}