import { Image, Separator, Stack, Text, TextField, PrimaryButton, ProgressIndicator, ComboBox, IComboBoxOption } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import IdentificationHeader from './ui/IdentificationHeader';
import { strings } from '../../../../../../localization/strings';
import { IIdentificationSerialConfig } from '@piceasoft/core';
import { TooltipHost, TooltipOverflowMode, css, mergeStyleSets } from '@fluentui/react';
import { identificationContainerStyle, hasBlockLevelElements, blockOffset } from '../helpers/identificationContainer';

type TProps = {
    config?: IIdentificationSerialConfig
}

interface IAppleDevice {
    group: string
    name: string
    model: string
    configuration: string
    generation: string
    configurations: string[]
}

const Serial: React.FC<TProps> = (props) => {

    const [model, setModel] = React.useState<IAppleDevice>({
        configuration: "black",
        generation: "11",
        group: "PHONE",
        model: "11",
        name: "IPhone",
        configurations: ["64Gb, 128Gb, 256Gb"]
    });

    const configurations: IComboBoxOption[] = props.config?.configurations?.map(i => {
        return {
            key: i.value,
            text: i.text,
            data: i.value
        }
    }) ?? [];


    return (
        <Stack horizontalAlign="stretch" verticalFill>
            <Stack.Item>
                <IdentificationHeader
                    icon={props.config?.ui?.icon ?? "GenericScan"}
                    title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_SERIAL_NUMBER}
                />
            </Stack.Item>
            <Separator />
            <Stack verticalFill tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <TooltipHost
                    overflowMode={TooltipOverflowMode.Self}
                    content={props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION}
                    hostClassName={css(identificationContainerStyle().body)}
                    styles={{ root: {display: 'block'}}}>
                        <Text style={{ color: SharedColors.gray40 }}>
                            <div 
                                style={hasBlockLevelElements(props.config?.ui?.information) ? {marginTop: `${blockOffset}`} : {marginTop: '0px'}}
                                dangerouslySetInnerHTML={{ __html: props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.SERIAL_INFORMATION }}>
                            </div>
                        </Text>
                    </TooltipHost>
                </Stack.Item>
                <Stack.Item verticalFill>
                    <Stack verticalFill horizontalAlign="stretch" tokens={{ childrenGap: 12 }}>
                        <Stack.Item>
                            <TextField
                                placeholder={props.config?.ui?.serial?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_SERIAL}
                                onChange={(_, val) => { }}
                            />
                        </Stack.Item>
                        {props.config?.useConfiguration && model && (
                            <Stack.Item>
                                <ComboBox
                                    autoComplete="on"
                                    disabled={(!model)}
                                    placeholder={props.config?.ui?.configuration?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CHOOSE_CONFIGURATION}
                                    onChange={(_, val) => {
                                        setModel({
                                            ...model, configuration: val?.data
                                        })
                                    }}
                                    useComboBoxAsMenuWidth
                                    options={configurations}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
                <Stack.Item grow>
                    <Stack horizontalAlign="stretch">
                        <PrimaryButton text={strings.BUTTONS.TEXT.CONFIRM} disabled={(!model || props.config?.useConfiguration && !model.configuration)} onClick={() => { confirm(); }}></PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Stack>
    );
}

export default Serial