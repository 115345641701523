import { IStyleFunctionOrObject } from '@fluentui/merge-styles';
import { Modal, Stack, IconButton, IIconProps, IButtonStyles, IModalStyleProps, IModalStyles, Text } from '@fluentui/react';
import { getTheme } from '@fluentui/style-utilities';
import * as React from 'react'

export type TCustomPanelProps = {
    title: string
    description?: string
    isOpen: boolean
    noCancelOnDissmiss?: boolean
    onCancel: () => void
}

export const CustomPanel: React.FC<TCustomPanelProps> = (props) => {
    return (
        <Modal
            styles={modalStyles}
            isOpen={props.isOpen}
            onDismiss={props.noCancelOnDissmiss ? undefined : props.onCancel}
        >
            <Stack verticalFill grow>
                <Stack.Item style={panelHeaderStyle} grow>
                    <Stack horizontal grow verticalFill verticalAlign='center' >
                        <Stack.Item grow>
                            <Stack grow verticalFill verticalAlign='center'>
                                <Stack.Item grow>
                                    <Text variant='xLarge'>{props.title}</Text>
                                </Stack.Item>
                                {props.description && (
                                    <Stack.Item grow>
                                        <Text style={{ color: theme.palette.neutralSecondaryAlt }} variant='medium'>{props.description}</Text>
                                    </Stack.Item>
                                )}
                            </Stack>
                        </Stack.Item>
                        <Stack.Item verticalFill style={{ marginLeft: 16 }}>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel', style: { fontSize: 20 } }}
                                onClick={props.onCancel}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item verticalFill grow style={{ position: 'relative' }}>
                    {props.children}
                </Stack.Item>
            </Stack>
        </Modal>
    )
}

const theme = getTheme();

const iconButtonStyles: IButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    }
};

const modalStyles: IStyleFunctionOrObject<IModalStyleProps, IModalStyles> = {
    main: {
        display: 'flex',
        borderRadius: theme.effects.roundedCorner6,
        boxShadow: theme.effects.elevation64,
        flexFlow: 'column nowrap',
        position: 'absolute',
        overflow: 'hidden',
        width: 480,
        top: 16,
        bottom: 16,
        right: 16
    },
   // root: {
        //background: 'rgba(0, 0, 0, .01)'
    //}
}

const panelHeaderStyle: React.CSSProperties = {
    paddingTop: 16,
    paddingLeft: 20,
    paddingRight: 16,
    paddingBottom: 16
}
