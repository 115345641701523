import { IPerson } from "../../core/store"
import { IOrganizationSettings } from "../../core/store/typings/IOrganizationSettings"
import { IResponseError } from "@piceasoft/core";
import { SettingsPasswordTab } from "./tabs/SettingsPasswordTab"
import { SettingsProfileTab } from "./tabs/SettingsProfileTab"
import { SettingsRolesTab } from "./tabs/SettingsRolesTab"
import { SettingsUserLocaleTab } from "./tabs/SettingsUserLocaleTab"
import { TUserSettingsTabState } from "./UserSettings"

type TTabStateProps = {
    state: TUserSettingsTabState
    settings: IOrganizationSettings
    account?: IPerson
    isUpdating: boolean
    isUpdatingSettings: boolean
    passwordUpdatingErrors?: IResponseError[]
    onSubmit: (data: IPerson) => void
    onUpdatePassword: (oldPassword: string, newPassword: string, setSuccessed: () => void) => void
}

export const TabBasedState: React.FC<TTabStateProps> = props => {
    switch (props.state) {
        case "ACCOUNT.PROFILE": return <SettingsProfileTab data={props.account} isUpdating={props.isUpdating} onSubmit={props.onSubmit} />
        case "ACCOUNT.LOCALE": return <SettingsUserLocaleTab />
        case "SECURITY.PASSWORD": return <SettingsPasswordTab data={props.account} isUpdating={props.isUpdating} onSubmit={props.onUpdatePassword} errors={props.passwordUpdatingErrors} />
        case "SECURITY.ROLES": return <SettingsRolesTab data={props.account} isUpdating={props.isUpdating} />
    }
}