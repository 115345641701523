import { ChoiceGroup, Dropdown, getTheme, IChoiceGroupOption, Icon, IDropdownOption, PrimaryButton, ScrollablePane, Stack, Text, TextField } from '@fluentui/react'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useBoolean } from '@uifabric/react-hooks'
import { IGrade, IQuestion } from "@piceasoft/core"
import { strings } from '../../../../../../../../../../localization/strings'
import { onRenderLabel } from '../../../../../../../../../renders/onRenderLabel'
import { SecondaryButton } from '../../../../../../../../buttons/SecondaryButton'
import { IAnswerOption } from '@piceasoft/core'
type TProps = {
    onSubmit: (answer: IAnswerOption) => void
    onCancel: () => void
    data?: IAnswerOption
    questions: IQuestion[]
    grades: IGrade[]
}

export enum AnswerOptionResultTypes {
    // NextQuestion = "next",
    Grade = "grade",
    // Fail = "fail",
    None = "grade"
}

export const ListQuestionAnswerForm: React.FC<TProps> = (props) => {

    const { control, handleSubmit, formState: { errors } } = useForm<IAnswerOption>({ defaultValues: { ...props.data, grade: props.grades.find(i => i.code === props.data?.grade)?.code } });
    // const [resultType, setResultType] = React.useState<AnswerOptionResultTypes>(AnswerOptionResultTypes.NextQuestion);
    // const [isButtonStyleSettingsShow, { setTrue: showButtonStyleSettings, setFalse: hideButtonStyleSettings }] = useBoolean(false)

    const onSubmit: SubmitHandler<IAnswerOption> = data => props.onSubmit(data);

    // const questionOptions = props.questions.map(q => ({ key: q.index, text: `${q.title}` } as IDropdownOption))
    const gradeOptions = [{ key: "", text: strings.COMMON.NOT_CHOSED }, ...props.grades.map(g => ({ key: g.code, text: `${g.code} ${g.name}` } as IDropdownOption))]

    // const resultTypes: IChoiceGroupOption[] = [
    //     { key: AnswerOptionResultTypes.NextQuestion, text: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.NEXT_QUESTION.LABEL, iconProps: { iconName: "Up" }, styles: { root: { display: 'flex' } } },
    //     { key: AnswerOptionResultTypes.Grade, text: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.GRADE.LABEL, iconProps: { iconName: "AssessmentGroup" }, styles: { root: { display: 'flex' } } },
    //     { key: AnswerOptionResultTypes.Fail, text: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.FAIL, iconProps: { iconName: "Cancel" }, styles: { root: { display: 'flex' } } }
    // ]

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="text"
                                rules={{
                                    required: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.TEXT.REQUIRED,
                                    // minLength: { value: 3, message: `${strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.NAME_MIN_LENGHT_MESSAGE} ${3}` } 
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.TEXT.LABEL}
                                        title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.TEXT.TITLE}
                                        placeholder={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.TEXT.PLACEHOLDER}
                                        errorMessage={errors.text?.message}
                                    />
                                }
                            />
                            {/* <Controller
                                name="secondaryText"
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.SECONDARY_TEXT.LABEL}
                                        title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.SECONDARY_TEXT.TITLE}
                                        placeholder={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.SECONDARY_TEXT.PLACEHOLDER}
                                        errorMessage={errors.secondaryText?.message}
                                    />
                                }
                            /> */}
                            {/* <Stack.Item>
                                {onRenderLabel({
                                    required: true,
                                    label: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT_TYPE.LABEL,
                                    title: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT_TYPE.TITLE
                                    })}
                                <ChoiceGroup
                                    required
                                    selectedKey={resultType}
                                    onChange={(ev, opt) => opt && setResultType(opt.key as AnswerOptionResultTypes)}
                                    options={resultTypes}
                                    key={`resultType-${resultType}`}
                                />
                            </Stack.Item> */}
                            {/* {resultType === AnswerOptionResultTypes.NextQuestion && (
                                <Controller
                                    name="next"
                                    control={control}
                                    // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA_REQUIRED }}
                                    render={({ field, fieldState, formState }) =>
                                        <Dropdown
                                            required
                                            selectedKey={field.value}
                                            onChange={(ev, opt) => field.onChange(opt?.key)}
                                            onBlur={field.onBlur}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.NEXT_QUESTION.LABEL}
                                            title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.NEXT_QUESTION.TITLE}
                                            placeholder={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.NEXT_QUESTION.PLACEHOLDER}
                                            options={questionOptions}
                                            // key={field.value}
                                            errorMessage={errors.next?.message}
                                        />}
                                />
                            )} */}
                            {/* {resultType === AnswerOptionResultTypes.Grade && ( */}
                            <Controller
                                name="grade"
                                control={control}
                                // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA_REQUIRED }}
                                render={({ field, fieldState, formState }) =>
                                    <Dropdown
                                        required
                                        selectedKey={field.value}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.GRADE.LABEL}
                                        title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.GRADE.TITLE}
                                        placeholder={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.GRADE.PLACEHOLDER}
                                        options={gradeOptions}
                                        // key={field.value}
                                        errorMessage={errors.next?.message}
                                    />}
                            />
                            {/* )} */}
                            {/* <Stack horizontalAlign="end">
                                <SecondaryButton onClick={isButtonStyleSettingsShow ? hideButtonStyleSettings : showButtonStyleSettings}>
                                    <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                        <Icon iconName={isButtonStyleSettingsShow ? "ChevronUp" : "ChevronDown"} style={{ fontWeight: 500 }} />
                                        <Text style={{ fontWeight: 500 }}>{strings.BUTTONS.TEXT.ADVANCED}</Text>
                                    </Stack>
                                </SecondaryButton>
                            </Stack>
                            {isButtonStyleSettingsShow && (
                                <>
                                    <Controller
                                        name="button.textColor"
                                        control={control}
                                        // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA_REQUIRED }}
                                        render={({ field, fieldState, formState }) =>
                                            <Stack.Item style={{ width: 100 }}>
                                                <TextField
                                                    id={"button.textColor"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.BUTTON.TEXT_COLOR.LABEL}
                                                    title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.BUTTON.TEXT_COLOR.TITLE}
                                                    placeholder={"#ffffff"}
                                                // errorMessage={errors.secondaryText?.message}
                                                />
                                            </Stack.Item>
                                        }
                                    />
                                    <Controller
                                        name="button.color"
                                        control={control}
                                        // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA_REQUIRED }}
                                        render={({ field, fieldState, formState }) =>
                                            <Stack.Item style={{ width: 100 }}>
                                                <TextField
                                                    id={"button.color"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.BUTTON.COLOR.LABEL}
                                                    title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.BUTTON.COLOR.TITLE}
                                                    placeholder={"#000000"}
                                                // errorMessage={errors.secondaryText?.message}
                                                />
                                            </Stack.Item>
                                        }
                                    />
                                    <Controller
                                        name="button.colorActive"
                                        control={control}
                                        // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA_REQUIRED }}
                                        render={({ field, fieldState, formState }) =>
                                            <Stack.Item style={{ width: 100 }}>
                                                <TextField
                                                    id={"button.colorActive"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.BUTTON.COLOR_ACTIVE.LABEL}
                                                    title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.BUTTON.COLOR_ACTIVE.TITLE}
                                                    placeholder={"#000000"}
                                                // errorMessage={errors.secondaryText?.message}
                                                />
                                            </Stack.Item>
                                        }
                                    />
                                    <Controller
                                        name="button.colorHover"
                                        control={control}
                                        // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA_REQUIRED }}
                                        render={({ field, fieldState, formState }) =>
                                            <Stack.Item style={{ width: 100 }}>
                                                <TextField
                                                    id={"button.colorHover"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    label={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.BUTTON.COLOR_HOVER.LABEL}
                                                    title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.BUTTON.COLOR_HOVER.TITLE}
                                                    placeholder={"#000000"}
                                                // errorMessage={errors.secondaryText?.message}
                                                />
                                            </Stack.Item>
                                        }
                                    />
                                </>
                            )} */}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

// const theme = getTheme();s
