import { Pivot, PivotItem, Stack } from "@fluentui/react"
import React from "react"
import { Dispatch } from "react"
import { actionCreators } from "../../../../../../../../core/actions/configurator-actions"
import { ProcessStages, AIGradingUploadMethods, IAIGradingConfig, IAIGradingDefaultConfigUI } from "@piceasoft/core"
import { aiGradingUploadMethodsDescriber } from "../../../../../../../../localization/helpers/inspections"
import { strings } from "../../../../../../../../localization/strings"
import { ParameterItem } from "../../../../../components/ParameterItem"

export type TModeConfiguratorCommonPivotProps = {
    index: number
    stage: ProcessStages
    aigrading: IAIGradingConfig
    onChangeDispatch: Dispatch<any>
}

export const DefaultModeModalUIPivot: React.FC<TModeConfiguratorCommonPivotProps> = ({ aigrading, stage, index, onChangeDispatch }) => {

    const [pivotState, setPivotState] = React.useState((aigrading.config.uploadMethods && aigrading.config.uploadMethods.length > 0) ? aigrading.config.uploadMethods[0] : undefined)

    const getTabId = (itemKey: string) => {
        return `AIGradingUISettingsPivot_${itemKey}`;
    };

    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(Number.parseInt(item?.props.itemKey!));
    };

    return (
        <Stack>
            {aigrading.config.uploadMethods && aigrading.config.uploadMethods.length > 0 && (
                <>
                    <Stack>
                        <Pivot style={{ padding: 16 }} getTabId={getTabId} onLinkClick={handlePivotLinkClick}>
                            {aigrading.config.uploadMethods.map(i => {
                                if (i !== AIGradingUploadMethods.Camera) {
                                    return (
                                        <PivotItem key={i} itemKey={i.toString()} headerText={aiGradingUploadMethodsDescriber(i)} />
                                    )
                                }
                            })}
                        </Pivot>
                    </Stack>
                    <PivotBasedState stage={stage} state={pivotState} index={index} config={aigrading} onChangeDispatch={onChangeDispatch} />
                </>
            )}
        </Stack>
    )
}


type TStateProps = {
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
    state?: AIGradingUploadMethods
    config: IAIGradingConfig
}

const PivotBasedState: React.FC<TStateProps> = (props) => {

    const { config } = props

    switch (props.state) {
        case AIGradingUploadMethods.Camera:
            return (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.TITLE_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Camera)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.camera?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            camera: {
                                                ...config.config.ui?.uploadMethods.camera,
                                                title: config.config.ui?.uploadMethods?.camera?.title !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.camera?.title !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.camera?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        camera: {
                                            ...config.config.ui?.uploadMethods.camera,
                                            title: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Camera)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.camera?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            camera: {
                                                ...config.config.ui?.uploadMethods.camera,
                                                information: config.config.ui?.uploadMethods?.camera?.information !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.camera?.information !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.camera?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        camera: {
                                            ...config.config.ui?.uploadMethods.camera,
                                            information: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.WARNING_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Camera)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.camera?.warning !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            camera: {
                                                ...config.config.ui?.uploadMethods.camera,
                                                warning: config.config.ui?.uploadMethods?.camera?.warning !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.WARNING_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.camera?.warning !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.camera?.warning}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        camera: {
                                            ...config.config.ui?.uploadMethods.camera,
                                            warning: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                </>
            )
        case AIGradingUploadMethods.Remote:
            return (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.TITLE_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.remote?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            remote: {
                                                ...config.config.ui?.uploadMethods.remote,
                                                title: config.config.ui?.uploadMethods?.remote?.title !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI,
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.remote?.title !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.remote?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        remote: {
                                            ...config.config.ui?.uploadMethods.remote,
                                            title: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.remote?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            remote: {
                                                ...config.config.ui?.uploadMethods.remote,
                                                information: config.config.ui?.uploadMethods?.remote?.information !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI,
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.remote?.information !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.remote?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        remote: {
                                            ...config.config.ui?.uploadMethods.remote,
                                            information: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.WARNING_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.remote?.warning !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            remote: {
                                                ...config.config.ui?.uploadMethods.remote,
                                                warning: config.config.ui?.uploadMethods?.remote?.warning !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI,
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.WARNING_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.remote?.warning !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.remote?.warning}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        remote: {
                                            ...config.config.ui?.uploadMethods.remote,
                                            warning: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    {/* <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_TITLE_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.remote?.external?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            remote: {
                                                ...config.config.ui?.uploadMethods.remote,
                                                external: {
                                                    ...config.config.ui?.uploadMethods.remote?.external,
                                                    title: config.config.ui?.uploadMethods?.remote?.external?.title !== undefined ? undefined : ""
                                                }
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI,
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.remote?.external?.title !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.remote?.external?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        remote: {
                                            ...config.config.ui?.uploadMethods.remote,
                                            external: {
                                                ...config.config.ui?.uploadMethods.remote?.external,
                                                title: val
                                            }
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.remote?.external?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            remote: {
                                                ...config.config.ui?.uploadMethods.remote,
                                                external: {
                                                    ...config.config.ui?.uploadMethods.remote?.external,
                                                    information: config.config.ui?.uploadMethods?.remote?.external?.information !== undefined ? undefined : ""
                                                }
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI,
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.remote?.external?.information !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.remote?.external?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        remote: {
                                            ...config.config.ui?.uploadMethods.remote,
                                            external: {
                                                ...config.config.ui?.uploadMethods.remote?.external,
                                                information: val
                                            }
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_WARNING_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Remote)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.remote?.external?.warning !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            remote: {
                                                ...config.config.ui?.uploadMethods.remote,
                                                external: {
                                                    ...config.config.ui?.uploadMethods.remote?.external,
                                                    warning: config.config.ui?.uploadMethods?.remote?.external?.warning !== undefined ? undefined : ""
                                                }
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI,
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.EXTERNAL_WARNING_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.remote?.external?.warning !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.remote?.external?.warning}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        remote: {
                                            ...config.config.ui?.uploadMethods.remote,
                                            external: {
                                                ...config.config.ui?.uploadMethods.remote?.external,
                                                warning: val
                                            }
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    /> */}
                </>
            )
        case AIGradingUploadMethods.Upload:
            return (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.TITLE_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Upload)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.upload?.title !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            upload: {
                                                ...config.config.ui?.uploadMethods.upload,
                                                title: config.config.ui?.uploadMethods?.upload?.title !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.TITLE_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.upload?.title !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.upload?.title}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        upload: {
                                            ...config.config.ui?.uploadMethods.upload,
                                            title: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Upload)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.upload?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            upload: {
                                                ...config.config.ui?.uploadMethods.upload,
                                                information: config.config.ui?.uploadMethods?.upload?.information !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.upload?.information !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.upload?.information}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        upload: {
                                            ...config.config.ui?.uploadMethods.upload,
                                            information: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.WARNING_TITLE}
                        useToggle
                        toggleEnabled={config.config.uploadMethods.includes(AIGradingUploadMethods.Upload)! ? true : false}
                        toggleChecked={config.config.ui?.uploadMethods?.upload?.warning !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                                ...config,
                                config: {
                                    ...config.config,
                                    ui: {
                                        ...config.ui,
                                        uploadMethods: {
                                            ...config.config.ui?.uploadMethods,
                                            upload: {
                                                ...config.config.ui?.uploadMethods.upload,
                                                warning: config.config.ui?.uploadMethods?.upload?.warning !== undefined ? undefined : ""
                                            }
                                        }
                                    } as IAIGradingDefaultConfigUI
                                }
                            } as IAIGradingConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.PIVOTS.UI.UPLOAD_METHODS.WARNING_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.config.ui?.uploadMethods?.upload?.warning !== undefined ? true : false}
                        textFieldValue={config.config.ui?.uploadMethods?.upload?.warning}
                        textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(props.stage, props.index, {
                            ...config,
                            config: {
                                ...config.config,
                                ui: {
                                    ...config.ui,
                                    uploadMethods: {
                                        ...config.config.ui?.uploadMethods,
                                        upload: {
                                            ...config.config.ui?.uploadMethods.upload,
                                            warning: val
                                        }
                                    }
                                } as IAIGradingDefaultConfigUI
                            }
                        } as IAIGradingConfig
                        ))}
                    />
                </>
            )
        default: return null
    }
}