import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { IStageConfig, ProcessStages, IStageConfigUI } from "@piceasoft/core";

type TProps = {
    config: IStageConfig<IStageConfigUI>
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    withoutTitle?: boolean
    withoutInformation?: boolean
    channel:number
}

export const StageCommonUISettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, stage, withoutTitle, withoutInformation, children, channel }) => {

    return (
        <Stack style={{ padding: "0px 0px" }}>
            {!withoutTitle && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.TITLE_TITLE}
                    useToggle
                    noSeparator
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel,stage,'Step UI', 'Use custom step title')}
                    toggleChecked={config.ui?.stageTitle !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.editStage(stage, {
                            ...config,
                            ui: {
                                ...config.ui,
                                stageTitle: config.ui?.stageTitle !== undefined ? undefined : ""
                            }
                        } as IStageConfig<IStageConfigUI>
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.ui?.stageTitle !== undefined ? true : false}
                    textFieldValue={config.ui?.stageTitle}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            stageTitle: val
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                    }
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.HEADER_TITLE}
                noSeparator={withoutTitle}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel,stage,'Step UI', 'Use custom step header')}
                toggleChecked={config.ui?.header !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            header: config.ui?.header !== undefined ? undefined : ""
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.HEADER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.header !== undefined ? true : false}
                textFieldValue={config.ui?.header}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                    ...config,
                    ui: {
                        ...config.ui,
                        header: val
                    }
                } as IStageConfig<IStageConfigUI>
                ))
                }
            />
            { !withoutInformation && (<ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.INFORMATION_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel,stage,'Step UI', 'Use custom step information')}
                toggleChecked={config.ui?.information !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            information: config.ui?.information !== undefined ? undefined : ""
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.INFORMATION_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.information !== undefined ? true : false}
                textFieldValue={config.ui?.information}
                textFieldMultiline
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                    ...config,
                    ui: {
                        ...config.ui,
                        information: val
                    }
                } as IStageConfig<IStageConfigUI>
                ))
                }
            />)}
            {children}
        </Stack>
    )
}