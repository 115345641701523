import { Checkbox, ComboBox, CommandBar, DatePicker, DefaultButton, Dropdown, FontWeights, getFocusStyle, getTheme, IComboBoxOption, ICommandBarItemProps, Icon, IconButton, IDropdownOption, IRenderFunction, ISelectableOption, mergeStyleSets, Modal, PrimaryButton, Separator, SharedColors, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react"
import { useBoolean, useId } from "@uifabric/react-hooks"
import React, { useRef } from "react"
import { portalApi } from "../../../../core/api/api"
import { CatalogVersionStates, IDocument, IDocumentVersion, IService, IStore, TransactionPlaceholders } from "../../../../core/store"
import { strings } from "../../../../localization/strings"
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from "tinymce"
import { Section } from "../../../../components/shared/decorations/Section"
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from "../../../../components/shared/search/SuggestedSearch"
import { escapeRegExp } from "../../../../assets/ts/utils/regex"
import { useSelector } from "react-redux"
import { IRequestResult } from "../../../../core/store/typings/IResponseResult"
import { getPlaceholdersSubMenuItems } from "./placeholdersSubMenuItems"
import { selector } from "../../../../core/store/selector"


type TServicesPrintTemplateConfigurationPivotProps = {
    data: IDocument
    onRetry: () => void
}

export const ServicesPrintTemplateConfigurationPivot: React.FC<TServicesPrintTemplateConfigurationPivotProps> = (props) => {

    const prepareHourOptions = (): IComboBoxOption[] => {
        return [...Array(24).keys()].map(i => {
            return {
                key: i,
                text: i < 10 ? "0" + i.toString() : i.toString(),
            }
        })
    }
    const prepareMinOptions = (): IComboBoxOption[] => {
        return [...Array(60).keys()].map(i => {
            return {
                key: i,
                text: i < 10 ? "0" + i.toString() : i.toString(),
            }
        })
    }

    const [documentVersions, setDocumentVersions] = React.useState<{ fetched: boolean, data?: IDocumentVersion[], error?: string }>({ fetched: false })

    const [versionsOptions, setVersionsOptions] = React.useState<IDropdownOption[]>()

    const versionsHostId = useId("versions-host-id")
    const versionsTextId = useId("versions-text-id")
    const versionsCalloutListId = useId("versions-callout-id")

    const [selectedVerionsOption, setSelectedVersionsOption] = React.useState<IComboBoxOption>()

    const [currentDocumentVersion, setCurrentDocumentVersion] = React.useState<{ fetched: boolean, data?: IDocumentVersion, error?: string }>({ fetched: false });

    const [fromDate, setFromDate] = React.useState<Date>()
    const [fromTimeHour, setFromTimeHour] = React.useState(0);
    const [fromTimeMin, setFromTimeMin] = React.useState(0);
    const [fromDateTime, setFromDateTime] = React.useState<Date>();

    const hourOptions = prepareHourOptions()
    const minOptions = prepareMinOptions()

    const [resetConfirmation, { setTrue: showResetConfirmation, setFalse: hideResetConfirmation }] = useBoolean(false)

    const tinyMCElang = useSelector(selector.workplace.getTinyMCELanguage)
    const [sendDocumentVersionConfirmation, {
        setTrue: showSendDocumentVersionConfirmation,
        setFalse: hideSendDocumentVersionConfirmation
    }] = useBoolean(false)

    const [override, setOverride] = React.useState<boolean>(false)

    const editorRef = useRef<TinyMCEEditor | null>(null);

    const [noChanges, setNoChanges] = React.useState(false)

    React.useEffect(() => {
        if (!documentVersions.fetched) {
            getDocumentVersions()
        }
    })

    React.useEffect(() => {
        if (!sendDocumentVersionConfirmation) {
            setFromDate(undefined)
            setFromTimeHour(0)
            setFromTimeMin(0)
            setFromDateTime(undefined)
        }
    }, [sendDocumentVersionConfirmation])

    React.useEffect(() => {
        if (fromDate) {
            setFromDateTime(
                new Date(
                    Date.UTC(
                        (fromDate)?.getFullYear(),
                        (fromDate)?.getMonth(),
                        (fromDate)?.getDate(),
                        fromTimeHour + new Date().getTimezoneOffset() / 60,
                        fromTimeMin
                    )
                )
            )
        }
    }, [fromDate, fromTimeHour, fromTimeMin])

    React.useEffect(() => {
        editorRef.current?.setContent(currentDocumentVersion.data?.content ?? "")
        setNoChanges(true)
    }, [currentDocumentVersion.data?.content]);

    React.useEffect(() => {
        // console.log(noChanges)
    }, [noChanges]);

    React.useEffect(() => {
        if (selectedVerionsOption?.key !== currentDocumentVersion.data?.id) {
            setNoChanges(false)
        }
        getDocumentVersion()
    }, [selectedVerionsOption])

    React.useEffect(() => {
        if (versionsOptions && versionsOptions.length > 0) {
            let newSelectedVersionId = currentDocumentVersion.data?.id

            if (!newSelectedVersionId && props.data.currentVersionDate) {
                newSelectedVersionId = documentVersions.data?.find(i => i.fromDate === props.data.currentVersionDate)?.id
            }
            if (!newSelectedVersionId) {
                newSelectedVersionId = currentDocumentVersion.data?.id ?? versionsOptions[0].key as number
            }
            setSelectedVersionsOption(versionsOptions.find(i => i.key === newSelectedVersionId))
        }
    }, [versionsOptions])

    React.useEffect(() => {
        if (documentVersions.data) {
            setVersionsOptions(documentVersions.data.map(i => {
                return { key: i.id, text: new Date(i.fromDate).toLocaleString() } as IComboBoxOption
            }))
        }
    }, [documentVersions.data])

    const getDocumentVersions = async () => {
        if (props.data.id) {
            const result = await portalApi.organization.documents.getDocumentVersions(props.data.id)
            setDocumentVersions({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
        }
    }

    const getDocumentVersion = async () => {
        setCurrentDocumentVersion({ fetched: false })
        if (selectedVerionsOption) {
            const documentVersionId = documentVersions.data?.find(i => i.id === selectedVerionsOption?.key as number)?.id
            const result = await portalApi.organization.documents.getDocumentVersion(props.data.id as number, documentVersionId as number)
            // console.log(result)
            setCurrentDocumentVersion({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
        } else {
            setCurrentDocumentVersion({ fetched: true })
        }
    }

    const onSendDocumentVersion = () => {
        showSendDocumentVersionConfirmation()
    }

    const sendDocumentVersion = async () => {
        setCurrentDocumentVersion({ data: currentDocumentVersion.data, fetched: false })
        hideSendDocumentVersionConfirmation()
        let result: IRequestResult<IDocumentVersion>
        const content = editorRef.current?.getContent()
        if (content) {

            if (override && currentDocumentVersion.data) {
                result = await portalApi.organization.documents.updateDocumentVersion({ ...currentDocumentVersion.data, content: content })
            } else {
                result = await portalApi.organization.documents.addDocumentVersion({ documentId: props.data.id as number, content: content, fromDate: fromDateTime as Date })
            }
            // console.log(result)
            if (result.successed) {
                setNoChanges(true)
                getDocumentVersions()
                setCurrentDocumentVersion({ data: result.data ?? currentDocumentVersion.data, fetched: true })
            }
            if (result.errors) {
                setCurrentDocumentVersion({ data: currentDocumentVersion.data, fetched: true, error: result.errors[0].description })
            }
            props.onRetry()
        }
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const handleDateSelect = (date: Date) => {
        const fromDate = new Date(Date.UTC((date)?.getFullYear(), (date)?.getMonth(), (date)?.getDate()))
        setFromDate(fromDate)
    }

    function formatDate(date?: Date): string {
        return date?.toLocaleDateString() ?? "";
    }

    function parseDateFromString(value: string): Date | null {
        let data = value.split(".");
        if (data.length === 3) {
            return new Date(Number(data[2]), Number(data[1]) - 1, Number(data[0]));
        } else {
            return null;
        }
    }

    const getDocumentVersionState = (date?: Date): CatalogVersionStates | undefined => {
        const dateTime = date && new Date(date).getTime()
        const catalogDate = props.data.currentVersionDate && new Date(props.data.currentVersionDate).getTime()
        if (catalogDate && dateTime) {
            if (dateTime === catalogDate) return CatalogVersionStates.Actual
            if (dateTime > catalogDate) return CatalogVersionStates.Planned
            if (dateTime < catalogDate) return CatalogVersionStates.Outdated
        }
        return undefined
    }

    const onRenderLabel: IRenderFunction<ISelectableOption<IComboBoxOption>> = (item) => {
        const state = getDocumentVersionState(documentVersions.data?.find(i => i.id === item?.key as number)?.fromDate ?? undefined)
        return (
            <Stack horizontal horizontalAlign='space-between' grow tokens={{ padding: 2, childrenGap: 18 }}>
                <Text block nowrap variant="medium" style={{
                    color: state === CatalogVersionStates.Outdated ? SharedColors.gray10 : SharedColors.gray40
                }}>{item?.text}</Text>
                {state === CatalogVersionStates.Actual && (
                    <Icon style={{ fontSize: 18, color: SharedColors.green10 }} iconName={"ReminderTime"} />
                )}
            </Stack>
        );
    }

    const onRenderVersionSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        const state = getDocumentVersionState(documentVersions.data?.find(i => i.id === item?.key as number)?.fromDate ?? undefined)
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${versionsCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, versionsOptions?.length ?? 0, versionsTextId, versionsCalloutListId, () => versionOptionSelectHandler(item), index, item?.text, versionsHostId)}
                tabIndex={-1}
                onClick={() => {
                    versionOptionSelectHandler(item)
                    focusElement(`${versionsCalloutListId}-${index as number}`, versionsHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Text block nowrap variant="medium" style={{
                        color: state === CatalogVersionStates.Outdated ? SharedColors.gray10 : SharedColors.gray40
                    }}>{item?.text}</Text>
                    {state === CatalogVersionStates.Actual && (
                        <Icon style={{ fontSize: 18, color: SharedColors.green10 }} iconName={"ReminderTime"} />
                    )}
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key as number, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    const versionOptionSelectHandler = (item?: IComboBoxOption) => {
        setSelectedVersionsOption(item)
    }

    const resetHandler = () => {
        getDocumentVersion()
        editorRef.current?.setContent(currentDocumentVersion.data?.content ?? "")
        // setNoChanges(false)
        hideResetConfirmation()
        setNoChanges(true)
    }

    const pastePlaceholder = (placeholder: TransactionPlaceholders | string) => {
        editorRef.current?.insertContent(placeholder)
        setNoChanges(false)
    }

    const _items: ICommandBarItemProps[] = [
        {
            key: 'placeholders',
            text: strings.BUTTONS.TEXT.PLACEHOLDERS,
            iconProps: { iconName: 'Variable2' },
            subMenuProps: {
                items: getPlaceholdersSubMenuItems(pastePlaceholder, props.data.services as IService[])
            }
        },
        {
            key: 'save',
            text: strings.BUTTONS.TEXT.SAVE,
            iconProps: { iconName: 'Save' },
            disabled: noChanges,
            onClick: onSendDocumentVersion,
        },
        {
            key: 'discard',
            text: strings.BUTTONS.TEXT.DISCARD,
            iconProps: { iconName: 'Undo' },
            disabled: noChanges,
            onClick: showResetConfirmation
        },
    ];

    const _overflowItems: ICommandBarItemProps[] = [
        { key: 'upload', text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.UPLOAD, onClick: () => { }, iconProps: { iconName: 'Upload' } },
        { key: 'download', text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.DOWNLOAD, onClick: () => { }, iconProps: { iconName: 'Download' } },
    ];

    const _farItems: ICommandBarItemProps[] = [
        {
            key: 'tile',
            text: strings.BUTTONS.TEXT.SHOW,
            //   disabled: !workflow,
            // This needs an ariaLabel since it's icon-only
            ariaLabel: 'Show',
            iconOnly: true,
            iconProps: { iconName: 'View' },
            //   onClick: () => showConfiguration(),
        },
        {
            key: 'info',
            text: strings.BUTTONS.TEXT.HELP,
            // This needs an ariaLabel since it's icon-only
            ariaLabel: 'Info',
            iconOnly: true,
            iconProps: { iconName: 'Unknown' },
            //   onClick: () => console.log('Info'),
        },
    ];

    const onRenderSectionHeader = () => {
        return (
            <Stack verticalAlign='center' horizontal horizontalAlign='space-between' tokens={{ childrenGap: 4, padding: "16px 20px 0px 20px" }}>
                <Stack verticalAlign='center' horizontal tokens={{ childrenGap: 8 }}>
                    <Text variant='medium' style={{ fontWeight: 600 }}>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.TITLE}</Text>
                    {(versionsOptions?.length ?? 0) <= 10 && (
                        <Dropdown
                            // label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.TITLE}
                            options={versionsOptions ?? []}
                            onChange={(ev, opt) => versionOptionSelectHandler(opt)}
                            disabled={!versionsOptions || versionsOptions.length === 0}
                            onRenderOption={onRenderLabel}
                            styles={{ dropdown: { width: 250 } }}
                            selectedKey={selectedVerionsOption?.key}
                            errorMessage={documentVersions.error}
                            placeholder={(!documentVersions.fetched) ?
                                strings.SPINNERS.DATA_IS_GETTING :
                                versionsOptions?.find(i => i.key === selectedVerionsOption?.key)?.text ?? (versionsOptions && versionsOptions.length > 0 ?
                                    strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.VERSIONS_PLACEHOLDER :
                                    strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.NO_VERSION)} />
                    ) || (
                            <SuggestedSearch
                                type="dropdown-search"
                                inputBoxId={versionsTextId}
                                hostId={versionsHostId}
                                // label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.TITLE}
                                inputBoxPlaceholder={(!documentVersions.fetched) ?
                                    strings.SPINNERS.DATA_IS_GETTING :
                                    versionsOptions?.find(i => i.key === selectedVerionsOption?.key)?.text ?? (versionsOptions && versionsOptions.length > 0 ?
                                        strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.VERSIONS_PLACEHOLDER :
                                        strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.NO_VERSION)}
                                onClickSuggestionCell={versionOptionSelectHandler}
                                // setSearchRequest={(opt: IComboBoxOption) => setFilterValue(opt?.text)}
                                searchRequest={selectedVerionsOption?.text}
                                suggestionsListWidth={250}
                                inputBoxWidth={250}
                                errorMessage={documentVersions.error}
                                disabled={!versionsOptions || versionsOptions.length === 0}
                                suggestions={versionsOptions ?? []}
                                suggestionsListId={versionsCalloutListId}
                                isSearchEqualTheOneSuggestion={false}
                                onRenderSuggestionCell={onRenderVersionSuggestionsCell}
                                prepareFilteredItems={prepareFilteredOptions}
                                selectedKey={selectedVerionsOption?.key.toString()}
                            />
                        )}
                    <Separator vertical />
                </Stack>
                <Stack.Item grow>
                    <CommandBar
                        items={_items}
                        styles={{ root: { margin: 0, padding: 0 } }}
                        // overflowItems={_overflowItems}
                        overflowButtonProps={{ ariaLabel: 'More commands' }}
                        // farItems={_farItems}
                        ariaLabel="Inbox actions"
                        primaryGroupAriaLabel="Main actions"
                        farItemsGroupAriaLabel="More actions"
                    />
                </Stack.Item>
                {/* <Stack.Item>
                    <Text style={{ fontSize: "1.0625rem", fontWeight: 500, color: theme.palette.black }}>{ }</Text>
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    {currentDocumentVersion.fetched && noChanges && wasSaved && (
                        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center" horizontalAlign="center" grow={1}>
                            <FontIcon style={{ fontSize: 14, color: SharedColors.greenCyan10 }} iconName={"CompletedSolid"} />
                            <Text variant='small' style={{ color: SharedColors.gray30 }}>{strings.COMMON.SAVED}</Text>
                        </Stack>
                    )}
                    {currentDocumentVersion.error && (
                        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center" horizontalAlign="center" grow={1}>
                            <FontIcon style={{ fontSize: 14, color: SharedColors.red10 }} iconName={"AlertSolid"} />
                            <Text variant='small' style={{ color: SharedColors.red10 }}>{currentDocumentVersion.error}</Text>
                        </Stack>
                    )}
                    <PrimaryButton text={strings.BUTTONS.TEXT.SAVE} disabled={noChanges} onClick={onSendDocumentVersion} />
                    <SecondaryButton text={strings.BUTTONS.TEXT.CANCEL} disabled={noChanges} onClick={showResetConfirmation} />
                </Stack> */}
            </Stack>
        )
    }

    return (
        <Stack verticalFill tokens={{ padding: "24px 0px 0px 0px" }} style={{ position: 'relative' }}>
            <Stack.Item verticalFill>
                <Section verticalFill flat onRenderHeader={onRenderSectionHeader} >
                    <Stack verticalFill style={{ position: 'relative', zIndex: 0 }}>
                        <Editor
                            // onFocus={() => {
                            //     editorRef.current?.execCommand('mceFocus', false, editorId);
                            //     editorRef.current?.getBody().focus();
                            // }}
                            onKeyUp={() => noChanges && setNoChanges(false)}
                            apiKey="j7wmtih0bo104czb4288y5v6j1fb260otde8k4e2i6kmrb1q"
                            onInit={(ev, editor: TinyMCEEditor) => editorRef.current = editor}
                            initialValue={currentDocumentVersion.data?.content}
                            init={{
                                // id: editorId,
                                height: '100%',
                                menubar: true,
                                language: tinyMCElang,
                                resize: false,
                                // inline: true,
                                // auto_focus: editorId,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code pagebreak help',
                                ],
                                toolbar:
                                    'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | image | pagebreak | code',
                                toolbar_mode: 'floating',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                pagebreak_separator: '<div style="page-break-before: always; clear:both"/></div>',
                                convert_urls: false
                            }}
                            onEditorChange={() => noChanges && setNoChanges(false)}
                        />
                    </Stack>
                </Section>
            </Stack.Item>
            {!currentDocumentVersion.fetched && (
                <Stack style={{ position: 'absolute', bottom: 0, right: 0, left: 0, top: 24, backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                    <Stack verticalAlign="center" horizontalAlign="center" grow={1}>
                        <Spinner size={SpinnerSize.large} />
                    </Stack>
                </Stack>
            )}
            {resetConfirmation && (
                <Modal isOpen={resetConfirmation} onDismiss={hideResetConfirmation} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.RESET.TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideResetConfirmation}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <Stack tokens={{ childrenGap: 16 }} >
                            <Stack.Item>
                                <Text>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.RESET.INFORMATION}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <PrimaryButton onClick={resetHandler}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                                        <DefaultButton style={cancelButtonStyle} onClick={hideResetConfirmation}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>
                                    </Stack>
                                </Stack.Item>
                            </Stack.Item>
                        </Stack>
                    </div>
                </Modal>
            )}
            {sendDocumentVersionConfirmation && (
                <Modal isOpen={sendDocumentVersionConfirmation} onDismiss={hideSendDocumentVersionConfirmation} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.CONFIRM.TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideSendDocumentVersionConfirmation}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <Stack tokens={{ childrenGap: 16 }} >
                            <Stack tokens={{ childrenGap: 8 }}>
                                <Text>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.CONFIRM.INFORMATION}</Text>
                                <Stack horizontal verticalAlign='end' horizontalAlign='space-between'>
                                    <DatePicker value={fromDate ? new Date(fromDate) : undefined} allowTextInput
                                        formatDate={(date?: Date) => formatDate(date)} parseDateFromString={parseDateFromString}
                                        label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.CONFIRM.FROM_DATE}
                                        placeholder={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.CONFIRM.CHOOSE_FROM_DATE}
                                        onSelectDate={(date) => date && handleDateSelect(date)}
                                        strings={strings.DAY_PICKER}
                                        disabled={override} />
                                    <ComboBox label={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.HOURS}
                                        style={{ width: 90, paddingBottom: 5 }} options={hourOptions}
                                        selectedKey={fromTimeHour}
                                        autoComplete="on"
                                        dropdownWidth={52}
                                        onChange={(ev, opt, val) => opt && setFromTimeHour(opt.key as number)}
                                        disabled={override}
                                    />
                                    <ComboBox label={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.MINUTES}
                                        style={{ width: 90, paddingBottom: 5 }} options={minOptions}
                                        selectedKey={fromTimeMin}
                                        autoComplete="on"
                                        dropdownWidth={52}
                                        onChange={(ev, opt, val) => opt && setFromTimeMin(opt.key as number)}
                                        disabled={override}
                                    />
                                </Stack>
                                {currentDocumentVersion.data && (
                                    <Checkbox label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.CONFIRM.OVERRIDE}
                                        checked={override}
                                        onRenderLabel={(props) => <Text style={{ maxWidth: 300 }} block nowrap>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.CONFIRM.OVERRIDE}</Text>}
                                        onChange={(ev, opt) => setOverride(opt ?? false)
                                        }
                                    />
                                )}
                                {override && (
                                    <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.VERSIONS.CONFIRM.OVERRIDE_INFO}</Text>
                                )}
                            </Stack>
                            <Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                        <PrimaryButton disabled={!override && !fromDateTime} onClick={sendDocumentVersion}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                                        <DefaultButton style={cancelButtonStyle} onClick={hideSendDocumentVersionConfirmation}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>
                                    </Stack>
                                </Stack.Item>
                            </Stack.Item>
                        </Stack>
                    </div>
                </Modal>)}
        </Stack >
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const cancelButtonStyle: React.CSSProperties = {
    border: 'none',
    background: theme.palette.neutralLight
}