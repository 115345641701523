import { Stack, TextField, DefaultButton, PrimaryButton, ScrollablePane } from '@fluentui/react';
import { getTheme } from '@fluentui/style-utilities';
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DocumentTypes, IDocument, IService, IStore } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { onRenderLabel } from '../../renders/onRenderLabel'
import { SecondaryButton } from '../buttons/SecondaryButton';

type TProps = {
    onSubmit: (document: IDocument) => void
    onCancel: () => void
    data?: IDocument
}

export const PrintTemplateForm: React.FC<TProps> = (props) => {

    const organization = useSelector((s: IStore) => s.workplace.organization)
    const { control, handleSubmit, formState: { errors } } = useForm<IDocument>({ defaultValues: { ...props.data, type: DocumentTypes.Contract, organizationId: organization?.id } });
    const onSubmit: SubmitHandler<IDocument> = data => {
        // console.log(data)
        props.onSubmit(data)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.NAME_REQUIRED }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME}
                                        title={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME}
                                        placeholder={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                name="summary"
                                control={control}
                                // rules={{ required: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.SUMMARY_REQUIRED }}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        multiline
                                        resizable={false}
                                        style={{height: 100}}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.SUMMARY}
                                        title={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.SUMMARY}
                                        placeholder={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.SUMMARY_PLACEHOLDER}
                                        errorMessage={errors.summary?.message}
                                    />
                                }
                            />
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();
