import * as React from 'react'
import { IInterviewSequenceConfig } from '@piceasoft/core';
import { IInterviewModeConfig, IInterviewModeConfigUI } from "@piceasoft/core";
import { strings } from '../../../../../../../../../localization/strings';
import { ParameterItem } from '../../../../../../components/ParameterItem';
import { InterviewModeSettings } from '../../../common/InterviewModeSettings';

export type TSequenceQuestionsConfiguratorSettingsPivotProps = {
    config: IInterviewSequenceConfig
    onConfigConfirm: (config: IInterviewSequenceConfig) => void
}

export const SequenceQuestionsConfiguratorSettingsPivot: React.FC<TSequenceQuestionsConfiguratorSettingsPivotProps> = (props) => {

    const onModeConfirm = (config: IInterviewModeConfig<IInterviewModeConfigUI>) => {
        props.onConfigConfirm({
            ...props.config,
            ...config
        })
    }

    return (
        <InterviewModeSettings {...{config: props.config as IInterviewModeConfig<IInterviewModeConfigUI>, onConfirm: onModeConfirm}} >
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.ALLOW_SECONDARY_TEXT_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.config.allowSecondaryText ? true : false}
                toggleOnChange={() => {
                    props.onConfigConfirm({
                        ...props.config,
                        allowSecondaryText: !props.config.allowSecondaryText ? true : false
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.ALLOW_SECONDARY_TEXT_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.ALLOW_PRIMARY_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.config.allowPrimary ? true : false}
                toggleOnChange={() => {
                    props.onConfigConfirm({
                        ...props.config,
                        allowPrimary: !props.config.allowPrimary ? true : false
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.ALLOW_PRIMARY_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.ALLOW_ROLLBACK_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.config.allowRollback ? true : false}
                toggleOnChange={() => {
                    props.onConfigConfirm({
                        ...props.config,
                        allowRollback: !props.config.allowRollback ? true : false
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.ALLOW_ROLLBACK_DESCRIPTION}
            />
        </InterviewModeSettings>
    )
}
