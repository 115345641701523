import { Stack } from "@fluentui/react"
import { CustomSeparator } from "../../../components/shared/decorations/CustomSeparator"
import { RemoveArea } from "../../../components/shared/decorations/RemoveArea"
import { ISupervisor } from "../../../core/store/typings/ISupervisor"
import { strings } from "../../../localization/strings"
import { SupervisorInfoPivot } from "./pivots/SupervisorInfoPivot"
import { SupervisorOrganizationsPivot } from "./pivots/SupervisorOrganizationsPivot"
import { TSupervisorPivotState } from "./Supervisor"

type TSupervisorPivotBasedState = {
    state: TSupervisorPivotState
    item: ISupervisor
    isFetching: boolean
    onDelete: () => void
    updateState: (item: ISupervisor) => void
}

export const SupervisorPivotBasedState: React.FC<TSupervisorPivotBasedState> = (props) => {
    switch (props.state) {
        case "information": return (
            <Stack verticalFill tokens={{ padding: "24px 32px", childrenGap: 16 }}>
                <SupervisorInfoPivot item={props.item} />
                <CustomSeparator />
                <RemoveArea
                    hrefText={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.REMOVE_AREA.HREF_TEXT}
                    onDelete={props.onDelete}
                    text={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.REMOVE_AREA.TEXT}
                    title={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.REMOVE_AREA.TITLE} />
            </Stack>
        )
        case "organizations": return (
            <Stack verticalFill tokens={{ padding: "24px 0px 0px 0px", childrenGap: 16 }}>
                <SupervisorOrganizationsPivot item={props.item} />
            </Stack>
        )
    }
}