import { Spinner, Stack, MessageBar, MessageBarType, Text } from '@fluentui/react'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { ContainerForm } from '../../../../components/shared/forms/ContainerForm'
import { PersonForm } from '../../../../components/shared/forms/PersonForm'
import { PointForm } from '../../../../components/shared/forms/PointForm'
import { ItemsNotFound } from '../../../../components/shared/notFound/ItemsNotFound'
import { CustomPanel } from '../../../../components/shared/panel/CustomPanel'
import { IContainer, IPerson, IPoint, IStore } from '../../../../core/store'
import { strings } from '../../../../localization/strings'
import { TStructureItemData } from '../OrganizationStructure'
import { StructureNodeTypes } from './StructureNodeTypes'

type TProps = {
    isOpen: boolean,
    onCancel: () => void
    onConfirm: (item: TStructureItemData) => void
    item: TStructureItemData
    denied?: boolean
}

export const StructureItemPanel: React.FC<TProps> = (props) => {

    const organizationDomain = useSelector((s: IStore) => s.workplace.organization?.domain)

    let title
    let description
    let form: React.ReactNode | undefined
    switch (props.item?.type) {
        case StructureNodeTypes.Container:
            title = strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.TITLE
            description = strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.DESCRIPTION
            form = props.item.fetched && <ContainerForm onSubmit={(data: IContainer) => { props.onConfirm({ ...props.item, data: data }) }} onCancel={props.onCancel} data={props.item.data as IContainer} />
            break;
        case StructureNodeTypes.Point:
            title = strings.ORGANIZATION.STRUCTURE.ITEM.POINT.TITLE
            description = strings.ORGANIZATION.STRUCTURE.ITEM.POINT.DESCRIPTION
            form = props.item.fetched && <PointForm onSubmit={(data: IPoint) => props.onConfirm({ ...props.item, data: data })} onCancel={props.onCancel} data={props.item.data as IPoint} />
            break;
        case StructureNodeTypes.Person:
            title = strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.TITLE
            description = props.item.id ? props.item.data?.name : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DESCRIPTION
            form = props.item.fetched && <PersonForm onSubmit={(data: IPerson) => props.onConfirm({ ...props.item, data: data })} onCancel={props.onCancel} data={props.item.data as IPerson} organizationDomain={organizationDomain} />
            break;
    }

    return (
        <CustomPanel
            isOpen={props.isOpen}
            onCancel={props.onCancel}
            noCancelOnDissmiss={true}
            title={title ?? strings.ORGANIZATION.STRUCTURE.ITEM.PANEL_UNDEFINED_ITEM}
            description={description}
        >
            {props.denied && (
                <ItemsNotFound doNotUseButton
                    imgSrc={"images/navigation/images/security_crop.png"}
                    info={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DENIED_INFO}
                    suggestion={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DENIED_EDIT_SUGGESTION} />
            ) || (
                    <Stack verticalFill>
                        {props.item.error && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                {props.item.error}
                            </MessageBar>
                        )}
                        {props.item.fetched && form}
                        {!props.item.fetched && (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        )}
                    </Stack>
                )}
        </CustomPanel >
    )
}
