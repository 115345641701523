import { Stack, Text, IconButton, Separator, SharedColors, Toggle, getTheme, Icon } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import React from "react"
import { Control, UseFormGetValues } from "react-hook-form"
import { ChooseIcon } from "../../../../components/shared/chooseIcon/ChooseIcon"
import { Section } from "../../../../components/shared/decorations/Section"
import { IBranding } from "../../../../core/store/typings/IBranding"
import { strings } from "../../../../localization/strings"

type Tprops = {
    allowedFileTypes: string[]
    isImageChanged: boolean
    fileSrc?: string
    fileDarkSrc?: string
    control: Control<IBranding, object>
    defaultLogo?: string
    setIsImageChanged: React.Dispatch<React.SetStateAction<boolean>>
    addFile: (files: FileList) => void
    deleteImageSrc: () => void
    resetImageSrc: () => void
    getValues: UseFormGetValues<IBranding>


    isImageDarkChanged: boolean
    setIsImageDarkChanged: React.Dispatch<React.SetStateAction<boolean>>
    addFileDark: (files: FileList) => void
    deleteImageDarkSrc: () => void
    resetImageDarkSrc: () => void
    setShowLogoInMobile: (showLogoInMobile: boolean) => void
    isShowLogoInMobile: boolean
}
export const BrandingLogo: React.FC<Tprops> = (props) => {
    const [isSectionCollapsed, { toggle: toggleSectionCollapse }] = useBoolean(false)
    const allowTypes = props.allowedFileTypes.map((type) => ' ' + type.split('/')[1].toUpperCase())
    const maximumFileSize = " 256 KB.";

    const isDefault = (): boolean => {
        return !props.fileDarkSrc;
    }
    const [isCustomeDark, { toggle: changeCustomeDark }] = useBoolean(!isDefault())
    const toggleCustomeDark = () => {
        changeCustomeDark();
        if (isCustomeDark) {
            props.deleteImageDarkSrc();
        }
    }
    const [isShowInMobile, { toggle: changeShowInMobile }] = useBoolean(props.isShowLogoInMobile)
    const toggleShowInMobile = () => {
        changeShowInMobile();   
        props.setShowLogoInMobile(!isShowInMobile);    
    }

    const onRenderHeader = (): React.ReactNode => {
        return (
            <Stack grow>
                <Stack
                    onClick={toggleSectionCollapse} horizontal
                    verticalFill grow verticalAlign="center" horizontalAlign="space-between"
                    tokens={{ padding: "10px 20px 0px 20px" }}
                    style={{ paddingBottom: isSectionCollapsed ? "10px" : "0", cursor: 'pointer' }}
                >
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_LOGO} - <Text style={{ color: SharedColors.gray30, fontSize: 16, width: 400 }}>{strings.ORGANIZATION.BRANDING.LOGO_INFO}</Text></Text>
                        <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20, paddingLeft: 10, paddingRight: 10 }} title={strings.BRANDING.DARK.INFO} ariaLabel="Info" styles={iconButtonStyles} />
                        <Text style={{ color: SharedColors.gray30, fontSize: 16, width: 400 }}>{strings.ORGANIZATION.BRANDING.LOGO_IMAGE_FORMATS + allowTypes + ". " + strings.ORGANIZATION.BRANDING.LOGO_IMAGE_SIZE + maximumFileSize}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton iconProps={{ iconName: isSectionCollapsed ? "ChevronDown" : "ChevronUp" }} />
                    </Stack.Item>
                </Stack>
                <Stack>
                    {!isSectionCollapsed && (
                        <Separator />
                    )}
                </Stack>
                <Stack>
                    <Stack.Item style={{ paddingLeft: 15 }}>
                        <Stack.Item style={{ display: "inline-block" }}>
                            <Toggle
                                checked={isShowInMobile}
                                onChange={toggleShowInMobile}
                            />
                        </Stack.Item>
                        <Stack.Item style={{ display: "inline-block" }}>
                            <Text style={{ fontSize: 16, fontWeight: 600, paddingLeft: 5 }}>{strings.BRANDING.SOW_LOGO_IN_MOBILE}</Text>
                            <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20, paddingLeft: 10, paddingRight: 10 }} title={strings.BRANDING.SOW_LOGO_IN_MOBILE_TOOLTIP} styles={iconButtonStyles} />
                        </Stack.Item>
                    </Stack.Item>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack>
            <Section onRenderHeader={onRenderHeader} headerOnly={isSectionCollapsed}>
                <Stack>
                    {!isSectionCollapsed && (
                        <Stack horizontalAlign="space-between">
                            <Stack>
                                <Stack.Item>
                                    <Text style={{ fontSize: 16, fontWeight: 600 }}>{strings.BRANDING.DARK.LIGHT_MODE}</Text>
                                </Stack.Item>
                                <Stack.Item style={{ paddingTop: 7 }}>
                                    <ChooseIcon
                                        acceptTypeMask={"image/*"}
                                        allowedFileTypes={props.allowedFileTypes}
                                        type="icon"
                                        brandingProps={true}
                                        changed={props.isImageChanged}
                                        resetChanged={() => props.setIsImageChanged(true)}
                                        imgSrc={props.getValues().config?.logo}
                                        fileSrc={props.fileSrc}
                                        borderRadius={1}
                                        defaultIcon={props.defaultLogo ?? undefined}
                                        lable={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ICON_LOAD_LABEL}
                                        addFile={props.addFile}
                                        removeImageText={strings.ORGANIZATION.BRANDING.REMOVE_LOGO}
                                        uploadImageText={strings.ORGANIZATION.BRANDING.UPLOAD_LOGO}
                                        withoutButtonLabel
                                        deleteImageSrc={props.deleteImageSrc}
                                        resetImageSrc={props.resetImageSrc}
                                        noHideRemoveButton
                                    />
                                </Stack.Item>

                            </Stack>
                            <Stack>
                                <Stack.Item>
                                    <Stack.Item style={{ display: "inline-block" }}      >
                                        <Toggle
                                            checked={isCustomeDark}
                                            onChange={toggleCustomeDark}
                                        />
                                    </Stack.Item>
                                    <Stack.Item style={{ display: "inline-block", paddingTop:10 }}      >
                                        <Text style={{ fontSize: 16, fontWeight: 600, paddingLeft: 5 }}>{strings.BRANDING.DARK.CUSTOM_DARK_MODE}</Text>
                                        <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20, paddingLeft: 10, paddingRight: 10 }} title="Info" ariaLabel="Info" styles={iconButtonStyles} />
                                    </Stack.Item>
                                </Stack.Item>
                                <Stack.Item>
                                    <ChooseIcon
                                        acceptTypeMask={"image/*"}
                                        allowedFileTypes={props.allowedFileTypes}
                                        type="icon"
                                        brandingProps={true}
                                        changed={props.isImageDarkChanged}
                                        resetChanged={() => props.setIsImageDarkChanged(true)}
                                        imgSrc={props.getValues().darkConfig?.logo}
                                        fileSrc={props.fileDarkSrc}
                                        borderRadius={1}
                                        defaultIcon={props.defaultLogo ?? undefined}
                                        lable={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ICON_LOAD_LABEL}
                                        addFile={props.addFileDark}
                                        removeImageText={strings.ORGANIZATION.BRANDING.REMOVE_LOGO}
                                        uploadImageText={strings.ORGANIZATION.BRANDING.UPLOAD_LOGO}
                                        withoutButtonLabel
                                        deleteImageSrc={props.deleteImageDarkSrc}
                                        resetImageSrc={props.resetImageDarkSrc}
                                        noHideRemoveButton
                                        buttonDisabled={!isCustomeDark}
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center" style={{ paddingTop: 13 }}>
                    <Stack.Item>
                        <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20 }} title="Info" ariaLabel="Info" styles={iconButtonStyles} />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Text style={{ fontWeight: 500, color: SharedColors.gray20 }}>{strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.IMAGE_LOAD_DESCRIPTION}</Text>
                    </Stack.Item>
                </Stack>
            </Section>
        </Stack>
    )
}
const theme = getTheme()
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};