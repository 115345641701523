import { Stack, AnimationStyles, Text } from "@fluentui/react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"
import { StagePreview } from "../../preview/StagePreview"
import { ProcessStages } from "@piceasoft/core"

export const ControlPreview: React.FC = () => {

    const config = useSelector((s: IStore) => s.configurator.control)

    if (!config) return null

    return (
        <StagePreview config={config}
            stage={ProcessStages.Control}
            defaultNextText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONTINUE}
            defaultCancelText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CANCEL}
            defaultStageTitle={"№CONF-0000-0000007"}
        >
            <Stack tokens={{ childrenGap: 32, padding: 32 }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }} >
                <Stack.Item>
                    <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                        <Text variant="xLarge" style={{ fontWeight: "bold" }}>{config.ui?.header ?? strings.CONSTRUCTOR.STAGES.CONTROL.HEADER}</Text>
                        <Stack tokens={{ maxWidth: 800 }}>
                            <div style={{ textAlign: "center" }}>
                                <Text variant="small">
                                    <div dangerouslySetInnerHTML={{ __html: config.ui?.information ?? strings.CONSTRUCTOR.STAGES.CONTROL.INFORMATION }}></div>
                                </Text>
                            </div>
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack>
        </StagePreview>
    )
}