import { CommandBarButton, FontWeights, getTheme, IconButton, IContextualMenuProps, mergeStyleSets, MessageBar, MessageBarType, Modal, PrimaryButton, Separator, SharedColors, Stack, Text } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React from "react";
import { Dispatch } from "react";
import { actionCreators } from "../../../../core/actions/configurator-actions";
import { IContractConfig } from "../../../../core/store";
import { IContractCustomField, IContractAttachmentField, ProcessStages } from "@piceasoft/core"
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";
import { CustomFieldForm } from "./contract/CustomFieldForm";
import { FieldForm } from "./contract/FieldForm";
import { FieldFormCommon } from "./contract/FieldFormCommon";
import { FieldFormWithMask } from "./contract/FieldFormWithMask";
import { AttachmentFieldForm } from "./contract/AttachmentFieldForm";
import { CheckProcessStageSectionOptionAccess } from "../helpers/evisibility";

type TProps = {
    config: IContractConfig
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
    channel: number
    stage: ProcessStages
}

export const Contract: React.FC<TProps> = (props) => {

    const [fieldModalState, setFieldModalState] = React.useState<{ key: string, index?: number }>()

    const [isModalOpen, { setTrue: showModal, setFalse: closeModal }] = useBoolean(false)

    const [parentKey, setParentKey] = React.useState('');

    React.useEffect(() => {
        if (fieldModalState) {
            showModal();
        }
    }, [fieldModalState])

    const onCloseModal = () => {
        closeModal();
        setTimeout(() => {
            setFieldModalState(undefined);
            setParentKey('');
        }, 500)
    }

    const onNewCustomField = (parentKey: string) => {
        setFieldModalState({ index: props.config.fields?.custom?.length ?? 0, key: "" })
        setParentKey(parentKey);
    }
    const onAttachmentField = (parentKey: string) => {
        setFieldModalState({ index: props.config.fields?.attachment?.length ?? 0, key: "" })
        setParentKey(parentKey);
    }

    const addFieldMenuProps: IContextualMenuProps = {
        items: [
            {
                key: "name",
                onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                    ...props.config,
                    fields: {
                        ...props.config.fields,
                        name: !props.config.fields?.name ? {} : undefined
                    }
                })),
                disabled: props.config.fields?.name ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.NAME.TITLE,
            },
            {
                key: "phone",
                onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                    ...props.config,
                    fields: {
                        ...props.config.fields,
                        phone: !props.config.fields?.phone ? {} : undefined
                    }
                })),
                disabled: props.config.fields?.phone ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PHONE.TITLE,
            },
            {
                key: "email",
                onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                    ...props.config,
                    fields: {
                        ...props.config.fields,
                        email: !props.config.fields?.email ? {} : undefined
                    }
                })),
                disabled: props.config.fields?.email ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.EMAIL.TITLE
            },
            {
                key: "passport",
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.TITLE,
                subMenuProps: {
                    items: [
                        {
                            key: "number",
                            onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                ...props.config,
                                fields: {
                                    ...props.config.fields,
                                    passport: {
                                        ...props.config.fields?.passport,
                                        number: !props.config.fields?.passport?.number ? {} : undefined
                                    }
                                }
                            })),
                            disabled: props.config.fields?.passport?.number ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.NUMBER.TITLE,
                        },
                        {
                            key: "dateOfIssue",
                            onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                ...props.config,
                                fields: {
                                    ...props.config.fields,
                                    passport: {
                                        ...props.config.fields?.passport,
                                        dateOfIssue: !props.config.fields?.passport?.dateOfIssue ? {} : undefined
                                    }
                                }
                            })),
                            disabled: props.config.fields?.passport?.dateOfIssue ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_ISSUE.TITLE,
                        },
                        {
                            key: "issuedBy",
                            onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                ...props.config,
                                fields: {
                                    ...props.config.fields,
                                    passport: {
                                        ...props.config.fields?.passport,
                                        issuedBy: !props.config.fields?.passport?.issuedBy ? {} : undefined
                                    }
                                }
                            })),
                            disabled: props.config.fields?.passport?.issuedBy ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ISSUED_BY.TITLE,
                        },
                        {
                            key: "subdivisionCode",
                            onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                ...props.config,
                                fields: {
                                    ...props.config.fields,
                                    passport: {
                                        ...props.config.fields?.passport,
                                        subdivisionCode: !props.config.fields?.passport?.subdivisionCode ? {} : undefined
                                    }
                                }
                            })),
                            disabled: props.config.fields?.passport?.subdivisionCode ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.SUBDIVISION_CODE.TITLE,
                        },
                        {
                            key: "dateOfBirth",
                            onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                ...props.config,
                                fields: {
                                    ...props.config.fields,
                                    passport: {
                                        ...props.config.fields?.passport,
                                        dateOfBirth: !props.config.fields?.passport?.dateOfBirth ? {} : undefined
                                    }
                                }
                            })),
                            disabled: props.config.fields?.passport?.dateOfBirth ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_BIRTH.TITLE,
                        },
                        {
                            key: "placeOfBirth",
                            onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                ...props.config,
                                fields: {
                                    ...props.config.fields,
                                    passport: {
                                        ...props.config.fields?.passport,
                                        placeOfBirth: !props.config.fields?.passport?.placeOfBirth ? {} : undefined
                                    }
                                }
                            })),
                            disabled: props.config.fields?.passport?.placeOfBirth ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.PLACE_OF_BIRTH.TITLE,
                        },
                        {
                            key: "address",
                            onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                ...props.config,
                                fields: {
                                    ...props.config.fields,
                                    passport: {
                                        ...props.config.fields?.passport,
                                        address: !props.config.fields?.passport?.address ? {} : undefined
                                    }
                                }
                            })),
                            disabled: props.config.fields?.passport?.address ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ADDRESS.TITLE,
                        },
                    ]
                }
            },
            {
                key: "operator",
                onClick: () => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                    ...props.config,
                    fields: {
                        ...props.config.fields,
                        operator: !props.config.fields?.operator ? {} : undefined
                    }
                })),
                disabled: props.config.fields?.operator ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.OPERATOR.TITLE,
            },
            {
                key: "custom",
                onClick: () => onNewCustomField('custom'),
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.CUSTOM.TITLE,
            },
            {
                key: "attachment",
                onClick: () => onAttachmentField('attachment'),
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ATTACHMENT.TITLE,
                disabled: !CheckProcessStageSectionOptionAccess(props.channel, props.stage, "Data fields", "Attachment")
            }

        ]
    }

    const commands = () => (
        <>
            <CommandBarButton iconProps={{ iconName: "Add" }} menuProps={addFieldMenuProps} text={strings.BUTTONS.TEXT.ADD} disabled={false} />
        </>
    )

    const onCustomFieldUp = (lIndex: number) => {
        if (lIndex > 0) {
            const newCustom = props.config.fields?.custom?.map((cf, index) => {
                if (index === lIndex) {
                    return props.config.fields?.custom ? props.config.fields?.custom[index - 1] : cf
                }
                if (index === lIndex - 1) {
                    return props.config.fields?.custom ? props.config.fields?.custom[index + 1] : cf
                }
                return cf;
            })
            props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                ...props.config,
                fields: {
                    ...props.config.fields,
                    custom: newCustom
                }
            }))
        }
    }

    const onAttachmentFieldUp = (lIndex: number) => {
        if (lIndex > 0) {
            const newAttachment = props.config.fields?.attachment?.map((cf, index) => {
                if (index === lIndex) {
                    return props.config.fields?.attachment ? props.config.fields?.attachment[index - 1] : cf
                }
                if (index === lIndex - 1) {
                    return props.config.fields?.attachment ? props.config.fields?.attachment[index + 1] : cf
                }
                return cf;
            })
            props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                ...props.config,
                fields: {
                    ...props.config.fields,
                    attachment: newAttachment
                }
            }))
        }
    }

    const onFieldConfig = (key: string) => {
        setFieldModalState({ key: key })
    }

    const onCustomFieldConfig = (key: string, index: number, parentKey: string) => {
        setFieldModalState({ index: index, key: key })
        setParentKey(parentKey);
    }

    const onCustomFieldCommit = (data: IContractCustomField) => {
        const newCustom = props.config.fields?.custom ?
            (fieldModalState?.index === props.config.fields?.custom.length ?
                [...props.config.fields.custom, data] :
                props.config.fields.custom.map((cf, index) => {
                    if (index === fieldModalState?.index) {
                        return data;
                    }
                    return cf;
                })) : [data]
        props.onChangeDispatch(actionCreators.contract.onConfigCommit({
            ...props.config,
            fields: {
                ...props.config.fields,
                custom: newCustom
            }
        }))
        onCloseModal();
    }

    const onAttachmentFieldCommit = (data: IContractAttachmentField) => {
        const newAttachment = props.config.fields?.attachment ?
            (fieldModalState?.index === props.config.fields?.attachment.length ?
                [...props.config.fields.attachment, data] :
                props.config.fields.attachment.map((cf, index) => {
                    if (index === fieldModalState?.index) {
                        return data;
                    }
                    return cf;
                })) : [data]
        props.onChangeDispatch(actionCreators.contract.onConfigCommit({
            ...props.config,
            fields: {
                ...props.config.fields,
                attachment: newAttachment
            }
        }))
        onCloseModal();
    }

    const getFieldName = (key?: string, parentKey?: string): string | undefined => {
        switch (key) {
            case "name": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.NAME.TITLE
            case "phone": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PHONE.TITLE
            case "operator": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.OPERATOR.TITLE
            case "number": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.NUMBER.TITLE
            case "address": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ADDRESS.TITLE
            case "dateOfBirth": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_BIRTH.TITLE
            case "dateOfIssue": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_ISSUE.TITLE
            case "issuedBy": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ISSUED_BY.TITLE
            case "placeOfBirth": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.PLACE_OF_BIRTH.TITLE
            case "subdivisionCode": return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.SUBDIVISION_CODE.TITLE
            case 'email': return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.EMAIL.TITLE
        }

        switch (parentKey) {
            case 'attachment': return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ATTACHMENT.TITLE_DESCRIPTION
        }
    }

    const getFieldTitle = (parentKey?: string): string => {
        switch (parentKey) {
            case 'attachment': return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ATTACHMENT.ADD_ATTACHMENT
            default: return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.FIELD_CONFIGURATION.TITLE
        }
    }

    const renderErrors = (errorText: string) => (
        <>
            <MessageBar isMultiline messageBarType={MessageBarType.severeWarning}>
                <b>{errorText}</b>
            </MessageBar>
        </>
    )

    return (
        <StageBox title={strings.CONSTRUCTOR.STAGES.CONTRACT.TITLE} onCommandRender={commands} settingsButtonProps={{ onClick: props.showSettings }}>
            {props.config.errors && props.config.errors.length > 0 && (
                <Stack tokens={{ padding: "0 0 28px 0" }}>
                    {props.config.errors?.map((error) => <Stack.Item styles={{
                        root: {
                            color: theme.palette.black,
                            boxShadow: theme.effects.elevation16,
                            position: "relative",
                        }
                    }}>{renderErrors(error.text)}</Stack.Item>)}
                </Stack>
            )}
            <Stack tokens={{ childrenGap: 16 }}>
                {props.config.fields?.name && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.NAME.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            name: undefined
                        }
                    }))}
                    onConfig={() => onFieldConfig("name")} />}
                {props.config.fields?.phone && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PHONE.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            phone: undefined
                        }
                    }))}
                    onConfig={() => onFieldConfig("phone")} />}
                {props.config.fields?.passport?.number && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.NUMBER.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            passport: {
                                ...props.config.fields?.passport,
                                number: undefined
                            }
                        }
                    }))}
                    onConfig={() => onFieldConfig("number")} />}
                {props.config.fields?.email && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.EMAIL.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            email: undefined
                        }
                    }))}
                    onConfig={() => onFieldConfig("email")} />}
                {props.config.fields?.passport?.dateOfIssue && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_ISSUE.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            passport: {
                                ...props.config.fields?.passport,
                                dateOfIssue: undefined
                            }
                        }
                    }))}
                    onConfig={() => onFieldConfig("dateOfIssue")} />}
                {props.config.fields?.passport?.issuedBy && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ISSUED_BY.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            passport: {
                                ...props.config.fields?.passport,
                                issuedBy: undefined
                            }
                        }
                    }))}
                    onConfig={() => onFieldConfig("issuedBy")} />}
                {props.config.fields?.passport?.subdivisionCode && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.SUBDIVISION_CODE.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            passport: {
                                ...props.config.fields?.passport,
                                subdivisionCode: undefined
                            }
                        }
                    }))}
                    onConfig={() => onFieldConfig("subdivisionCode")} />}
                {props.config.fields?.passport?.dateOfBirth && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_BIRTH.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            passport: {
                                ...props.config.fields?.passport,
                                dateOfBirth: undefined
                            }
                        }
                    }))}
                    onConfig={() => onFieldConfig("dateOfBirth")} />}
                {props.config.fields?.passport?.placeOfBirth && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.PLACE_OF_BIRTH.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            passport: {
                                ...props.config.fields?.passport,
                                placeOfBirth: undefined
                            }
                        }
                    }))}
                    onConfig={() => onFieldConfig("placeOfBirth")} />}
                {props.config.fields?.passport?.address && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ADDRESS.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            passport: {
                                ...props.config.fields?.passport,
                                address: undefined
                            }
                        }
                    }))}
                    onConfig={() => onFieldConfig("address")} />}
                {props.config.fields?.operator && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.OPERATOR.TITLE}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            operator: undefined
                        }
                    }))}
                    onConfig={() => onFieldConfig("operator")} />}
                {props.config.fields?.custom && props.config.fields.custom.length > 0 && <Separator />}
                {props.config.fields?.custom?.map((i, lIndex) => <InputField key={i.key} name={i.key} label={i.label}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            custom: props.config.fields?.custom?.filter((cf, cfIndex) => cfIndex !== lIndex),
                        }
                    }))}
                    onUp={lIndex > 0 ? () => onCustomFieldUp(lIndex) : undefined}
                    onConfig={() => onCustomFieldConfig(i.key, lIndex, 'custom')} />)}

                {props.config.fields?.attachment && props.config.fields.attachment.length > 0 && <Separator />}
                {props.config.fields?.attachment?.map((i, lIndex) => <InputField key={i.key} name={i.key} label={i.label}
                    onDelete={() => props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...props.config,
                        fields: {
                            ...props.config.fields,
                            attachment: props.config.fields?.attachment?.filter((cf, cfIndex) => cfIndex !== lIndex),
                        }
                    }))}
                    onUp={lIndex > 0 ? () => onAttachmentFieldUp(lIndex) : undefined}
                    onConfig={() => onCustomFieldConfig(i.key, lIndex, 'attachment')} />)}
            </Stack>
            <Modal isOpen={isModalOpen} onDismiss={onCloseModal} containerClassName={modalStyles.container} styles={{ main: { display: "flex", maxHeight: "640px", maxWidth: "500px" } }}>
                <Stack verticalFill>
                    <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                        <Stack verticalAlign="center" tokens={{ childrenGap: 2 }}>
                            <Text variant="xLarge" style={{ paddingRight: 32, fontWeight: 600 }}>{getFieldTitle(parentKey)}</Text>
                            <Text variant="smallPlus" style={{ paddingRight: 32, color: SharedColors.gray40, fontWeight: 600 }}>{getFieldName(fieldModalState?.key, parentKey)}</Text>
                        </Stack>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={onCloseModal}
                        />
                    </Stack>
                    <Stack.Item verticalFill>
                        {fieldModalState?.key === "name" && !fieldModalState.index && (
                            <FieldForm data={props.config.fields?.name} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        name: data
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "email" && !fieldModalState.index && (
                            <FieldFormCommon data={props.config.fields?.email} key={'email'} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        email: data
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "phone" && !fieldModalState.index && (
                            <FieldFormWithMask data={props.config.fields?.phone} sliderDisabled={true} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        phone: data
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "operator" && !fieldModalState.index && (
                            <FieldFormWithMask data={props.config.fields?.operator} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        operator: data
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "number" && !fieldModalState.index && (
                            <FieldFormWithMask data={props.config.fields?.passport?.number} sliderDisabled={true}
                                onCommit={(data) => {
                                    props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                        ...props.config,
                                        fields: {
                                            ...props.config.fields,
                                            passport: {
                                                ...props.config.fields?.passport,
                                                number: data
                                            }
                                        }
                                    }))
                                    onCloseModal();
                                }
                                } />
                        )}
                        {fieldModalState?.key === "issuedBy" && !fieldModalState.index && (
                            <FieldForm data={props.config.fields?.passport?.issuedBy} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        passport: {
                                            ...props.config.fields?.passport,
                                            issuedBy: data
                                        }
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "dateOfIssue" && !fieldModalState.index && (
                            <FieldFormCommon data={props.config.fields?.passport?.dateOfIssue} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        passport: {
                                            ...props.config.fields?.passport,
                                            dateOfIssue: data
                                        }
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "subdivisionCode" && !fieldModalState.index && (
                            <FieldFormWithMask data={props.config.fields?.passport?.subdivisionCode} sliderDisabled={true} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        passport: {
                                            ...props.config.fields?.passport,
                                            subdivisionCode: data
                                        }
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "dateOfBirth" && !fieldModalState.index && (
                            <FieldFormCommon data={props.config.fields?.passport?.dateOfBirth} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        passport: {
                                            ...props.config.fields?.passport,
                                            dateOfBirth: data
                                        }
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "placeOfBirth" && !fieldModalState.index && (
                            <FieldForm data={props.config.fields?.passport?.placeOfBirth} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        passport: {
                                            ...props.config.fields?.passport,
                                            placeOfBirth: data
                                        }
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {fieldModalState?.key === "address" && !fieldModalState.index && (
                            <FieldForm data={props.config.fields?.passport?.address} onCommit={(data) => {
                                props.onChangeDispatch(actionCreators.contract.onConfigCommit({
                                    ...props.config,
                                    fields: {
                                        ...props.config.fields,
                                        passport: {
                                            ...props.config.fields?.passport,
                                            address: data
                                        }
                                    }
                                }))
                                onCloseModal();
                            }
                            } />
                        )}
                        {parentKey === 'custom' && fieldModalState?.index !== undefined && (
                            <CustomFieldForm keys={props.config.fields?.custom?.filter(i => i.key !== fieldModalState.key).map(i => i.key) ?? []}
                                data={props.config.fields?.custom ? props.config.fields?.custom[fieldModalState.index] : undefined}
                                onCommit={onCustomFieldCommit} />
                        )}
                        {parentKey === 'attachment' && fieldModalState?.index !== undefined && (
                            <AttachmentFieldForm keys={props.config.fields?.attachment?.filter(i => i.key !== fieldModalState.key).map(i => i.key) ?? []}
                                data={props.config.fields?.attachment ? props.config.fields?.attachment[fieldModalState.index] : undefined}
                                onCommit={onAttachmentFieldCommit}
                                onCloseModal={onCloseModal}  
                                />
                        )}
                    </Stack.Item>
                </Stack>
            </Modal>
        </StageBox>
    )
}

type TFieldProps = {
    name: string
    label?: string
    onConfig: () => void
    onDelete: () => void
    onUp?: () => void
}

export const InputField: React.FC<TFieldProps> = (props) => {
    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Stack.Item>
                <Stack verticalFill verticalAlign="center">
                    {props.onUp && <IconButton iconProps={{ iconName: "Up" }} onClick={props.onUp} styles={{ icon: { fontSize: 12 } }} />}
                    <IconButton iconProps={{ iconName: "Delete" }} onClick={props.onDelete} styles={{ icon: { fontSize: 12 } }} />
                </Stack>
            </Stack.Item>
            <Stack.Item grow styles={{ root: { paddingRight: 18 } }}>
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <Stack styles={{
                        root: {
                            padding: "12px 16px",
                            color: theme.palette.black,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: theme.palette.neutralQuaternaryAlt,
                            backgroundColor: theme.palette.white,
                            position: "relative",
                            ":hover": {
                                cursor: "pointer",
                                borderColor: theme.palette.themePrimary,
                                backgroundColor: theme.palette.themeLighterAlt
                            }
                        }
                    }}>
                        <Stack.Item>
                            <Stack verticalAlign="center" tokens={{ childrenGap: 4 }}>
                                <Text variant={"small"} styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{props.name}</Text>
                                {/* {props.label && <Text variant={"small"} styles={{ root: { color: theme.palette.themePrimary, fontWeight: 400 } }}>{props.label}</Text>} */}
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0, zIndex: 100 } }}>
                        <IconButton iconProps={{ iconName: "Repair" }} onClick={props.onConfig} styles={{
                            root: {
                                width: 36,
                                height: 36,
                                backgroundColor: theme.palette.white,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: theme.palette.neutralQuaternaryAlt,
                                borderRadius: "50%",
                                color: theme.palette.black,
                                boxShadow: theme.effects.elevation8,
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            },
                            flexContainer: {
                                borderRadius: "50%",
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            }
                        }} />
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const modalStyles = mergeStyleSets({
    containerModule: {
        borderRadius: 4
    },
    container: {
        flex: '1 1 auto',
        alignItems: 'stretch',
        borderRadius: 6
    },
    header: [
        theme.fonts.xLarge,
        {
            color: theme.palette.neutralPrimary,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        fontSize: 10,
        position: "relative",
        overflowY: 'hidden'
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
