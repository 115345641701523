import {
    getTheme, DetailsList, SelectionMode, DetailsListLayoutMode,
    DetailsRow, IDetailsListProps, IDetailsRowStyles, Stack,
    IButtonStyles, IconButton, DirectionalHint, IContextualMenuProps,
    IColumn, Text, SharedColors, Icon, PrimaryButton, Image, ImageFit
} from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { portalApi } from "../../../../../../core/api/api";
import { getPromoProviderIcon } from "../../../../../../core/helpers/providers";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../../../../core/scripts/style";
import { IGrade, IPromoProviderConfig } from "@piceasoft/core"
import { IPromotion } from "../../../../../../core/store/typings/IPromotion";
import { strings } from "../../../../../../localization/strings";
import { onRenderColumnHeaderCommon } from "../../../../detailsList/commonRenders";
import { PromoProviderForm } from "../../../../forms/PromoProviderForm";
import { Tooltip } from "../../../../help/Tooltip";
import { ItemsNotFound } from "../../../../notFound/ItemsNotFound";
import { CustomPanel } from "../../../../panel/CustomPanel";
import { ICommonOfferConfig } from "@piceasoft/core";
type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: ICommonOfferConfig
    grades?: IGrade[]
    offerProviderCode?: string
}

export const CommonOfferStagePromotionsPivotProviders: React.FC<TProps> = (props) => {

    if (!props.config) return null

    const [panelState, setPanelState] = React.useState<{ index: number, item: IPromoProviderConfig }>()
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [promotions, setPromotions] = React.useState<IPromotion[]>([])
    React.useEffect(() => {
        if (props.offerProviderCode) {
            let index = 0
            const providerItem = props.config?.promoProviders?.find((i, localIndex) => {
                index = localIndex;
                return i.code === props.offerProviderCode ? true : false;
            })
            if (providerItem) {
                setPanelState({ index: index, item: providerItem })
            }
        }
    }, [])

    React.useEffect(() => {
        if (panelState && !isPanelOpen) {
            showPanel()
        }
    }, [panelState])

    const onCancel = () => {
        hidePanel();
        setTimeout(() => setPanelState(undefined), 100);
    }

    const onEdit = (item: IPromoProviderConfig, index: number) => {
        setPanelState({ index, item });
    }

    const onAdd = () => {
        showPanel()
    }

    const onDelete = async (index: number) => {
        props.onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
            ...props.config,
            promoProviders: props.config?.promoProviders?.filter((op, opIndex) => opIndex !== index)
        }));
        onCancel();
    };

    const onCommit = async (item: IPromoProviderConfig) => {
        console.log(item)
        if (panelState?.index !== undefined) {
            props.onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                ...props.config,
                promoProviders: props.config?.promoProviders?.map((op, aIndex) => {
                    if (panelState.index === aIndex) {
                        return item;
                    }
                    return op;
                })
            }));
        } else {
            props.onChangeDispatch(actionCreators.commonOffer.onConfigCommit({ ...props.config, promoProviders: [...(props.config?.promoProviders ?? []), item] }));
        }
        onCancel();
    };


    const getItemMenuProps = (item: IPromoProviderConfig, order: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.code}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => onEdit(item, order),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.code}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => onDelete(order),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            fieldName: 'type',
            minWidth: 112,
            maxWidth: 112,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromoProviderConfig) => <Stack verticalFill horizontal verticalAlign="center" tokens={{ childrenGap: 32 }}>
                {item.imageSrc && (
                    <Stack horizontalAlign='center'>
                        <Image src={item.imageSrc} width={40} height={40} imageFit={ImageFit.cover} style={{ borderRadius: 20 }} />
                    </Stack>
                ) || (
                        <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: getPersonaColorsPallete[item.name[0].toLowerCase()] }}>
                            <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                                {getIconSymbolsFromString(item.name)}
                            </Text>
                        </Stack>
                    )}
                <Icon style={{ fontSize: 32 }} iconName={getPromoProviderIcon(item.type)}
                />
            </Stack>,
            isPadded: false,
        },
        {
            key: 'column2',
            name: '',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromoProviderConfig) => {
                return (
                    <Stack horizontal verticalFill grow tokens={{ childrenGap: 20 }}>
                        <Stack verticalFill verticalAlign="center">
                            <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
                            <Text variant="small" style={{ color: SharedColors.gray30 }}>{item.description}</Text>
                        </Stack>
                    </Stack>
                )
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: '',
            fieldName: 'code',
            minWidth: 70,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromoProviderConfig) => <Stack verticalFill verticalAlign="center">
                <Text variant="medium" style={{ color: SharedColors.gray30, fontWeight: 500 }}>{item.code}</Text>
                <Text variant="medium" style={{ color: SharedColors.gray30 }}>{item.endpoint}</Text>
            </Stack>,
            isPadded: true,
        },
        {
            key: 'column4',
            name: '',
            fieldName: 'commandBar',
            minWidth: 70,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromoProviderConfig, order?: number) => {
                return (
                    <Stack horizontal verticalFill horizontalAlign='end' verticalAlign='center' onClick={(ev) => ev.stopPropagation()} tokens={{ childrenGap: 10 }}>
                        {item.errors && item.errors.length > 0 && (
                            <Stack.Item>
                                <Stack verticalAlign="center" verticalFill>
                                    <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DELETED_PROMOTION}>
                                        <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                                    </Tooltip>
                                </Stack>
                            </Stack.Item>
                        )}
                        <Stack.Item>
                            <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={order !== undefined ? getItemMenuProps(item, order) : undefined} />
                        </Stack.Item>
                    </Stack>
                )
            }
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            if (rProps.item.errors) {
                customStyles.root = {
                    backgroundColor: "rgba(253, 231, 233,0.6)",
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: "rgb(253, 231, 233)",
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.menu-icon': {
                            color: theme.palette.black
                        },
                    }
                };
            } else {
                customStyles.root = {
                    cursor: 'pointer',
                    ':hover': {
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.menu-icon': {
                            color: theme.palette.black
                        },
                        '.up-icon': {
                            fontSize: 16,
                            color: theme.palette.black
                        }
                    }
                };
            }
            return <Stack onClick={() => { onEdit(rProps.item, rProps.itemIndex) }
            }><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    }

    return (
        <>
            {(props.config.promoProviders && props.config.promoProviders.length > 0) && (
                <>
                    <DetailsList
                        items={props.config.promoProviders}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={false}
                        columns={columns}
                        onRenderRow={onRenderRow}
                    />
                    <Stack tokens={{ padding: "12px 0px", childrenGap: 16 }}>
                        <Stack.Item>
                            <PrimaryButton onClick={onAdd}>{strings.BUTTONS.TEXT.ADD}</PrimaryButton>
                        </Stack.Item>
                    </Stack>
                </>
            ) || (
                    <ItemsNotFound
                        info={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NOT_FOUND.INFO}
                        suggestion={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NOT_FOUND.SUGGESTION}
                        buttonText={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER_FORM.NOT_FOUND.BUTTON_TEXT}
                        imgSrc={"images/navigation/images/not_found.png"}
                        onClick={showPanel}
                    />
                )}
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                noCancelOnDissmiss={true}
                title={panelState ?
                    strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER.EDIT :
                    strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROMO_PROVIDER.NEW
                }
            >
                <PromoProviderForm
                    onSubmit={onCommit}
                    onCancel={onCancel}
                    data={panelState?.item}
                    codes={props.config.promoProviders?.filter(i => i.code !== panelState?.item.code).map(i => i.code) ?? []}
                />
            </CustomPanel>
        </>
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

