import { Stack, TextField } from '@fluentui/react'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { strings } from '../../../localization/strings'
import { DangerButton } from '../buttons/DangerButton'
import { SecondaryButton } from '../buttons/SecondaryButton'

type TProps = {
    onConfirm: () => void
    onCancel: () => void
    confirmationCheckString: string
    requiredMessageString: string
    placeholder: string
    confirmText?: string
}

export const ConfirmationForm: React.FC<TProps> = (props) => {

    const { control, handleSubmit, formState: { isValid, errors } } = useForm<{ confirmationString: string }>({ mode: 'onChange', reValidateMode: 'onChange' });
    const onSubmit: SubmitHandler<{ confirmationString: string }> = data => props.onConfirm();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item style={{ paddingBottom: 4 }}>
                    {props.children}
                </Stack.Item>
                <Stack tokens={{ childrenGap: 16 }} >
                    <Stack.Item>
                        <Controller
                            control={control}
                            name="confirmationString"
                            rules={{
                                validate: (value) => value === props.confirmationCheckString?.replace(/ +(?= )/g,'')
                            }}
                            render={({ field }) =>
                                <TextField
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    placeholder={props.placeholder}
                                    errorMessage={errors.confirmationString?.type === 'validate' ? props.requiredMessageString : undefined}
                                />
                            }
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack.Item>
                            <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                <SecondaryButton onClick={props.onCancel} text={strings.BUTTONS.TEXT.CANCEL} />
                                <DangerButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>{props.confirmText ?? strings.BUTTONS.TEXT.CONFIRM}</DangerButton>
                            </Stack>
                        </Stack.Item>
                    </Stack.Item>
                </Stack>
            </Stack>
        </form>
    )
}
