import {
    Stack, Pivot, PivotItem, MessageBar, MessageBarType,
    Sticky, ScrollablePane, IconButton, Modal, Spinner,
    Text, FontWeights, getTheme, mergeStyleSets
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { ConfirmationForm } from '../../../components/shared/forms/ConfirmationForm';
import { SupervisorForm } from '../../../components/shared/forms/SupervisorForm';
import { CustomPanel } from '../../../components/shared/panel/CustomPanel';
import { SomethingWentWrong } from '../../../components/shared/somethingWentWrong/SomethingWentWrong';
import { portalApi } from '../../../core/api/api';
import { ISupervisor } from '../../../core/store/typings/ISupervisor';
import { strings } from '../../../localization/strings';
import { SupervisorHeader } from './SupervisorHeader';
import { SupervisorPivotBasedState } from './SupervisorPivotBasedState';

export type TSupervisorPivotState = "organizations" | "information"

const getTabId = (itemKey: string) => {
    return `SupervisorPivot_${itemKey}`;
};

type TProps = {
}

export const Supervisor: React.FC<TProps> = (props) => {

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TSupervisorPivotState>("information");
    const [supervisorState, setSupervisorState] = React.useState<{ fetched: boolean, error?: string, data?: ISupervisor }>({ fetched: false })

    const { supervisorId } = useParams<{ supervisorId: string }>();

    const [error, setError] = React.useState<string>()
    const [confirmation, { setTrue: showConfirmation, setFalse: hideConfirmation }] = useBoolean(false)
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const history = useHistory();


    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TSupervisorPivotState);
    };

    React.useEffect(() => {
        getSupervisor(supervisorId)
    }, [])

    const getSupervisor = async (id: string) => {
        const result = await portalApi.organization.supervisors.getSupervisor(id)
        console.log(result)
        if (result.successed && result.data) {
            setSupervisorState({ fetched: true, data: result.data })
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation()
            setError(result.errors[0].description)
        }
    }

    const updateSupervisor = async (item: ISupervisor) => {
        setFormError(undefined)
        startFetch()
        const result = await portalApi.organization.supervisors.updateSupervisor
            (item)
        if (result.successed && result.data) {
            setSupervisorState({ fetched: true, data: result.data })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setError(result.errors[0].description)
        }
    }

    const deleteSupervisor = async (id: string) => {
        startFetch()
        const result = await portalApi.organization.supervisors.deleteSupervisor(id)
        if (result.successed) {
            history.push("/administration/supervisors");
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation();
            setError(result.errors[0].description);
            return;
        }
        if (!result.successed) {
            setError("There is a problem! Document template wasn't deleted! Server response false without an error")
        }
        stopFetch()
        hideConfirmation()
    }

    const onFormSubmit = (data: ISupervisor) => {
        supervisorState.data?.id && updateSupervisor(data)
    }

    return (
        <>
            <Stack verticalFill style={{ position: 'relative' }}>
                {!error && (supervisorState.data && (
                    <ScrollablePane>
                        <Stack verticalFill>
                            <SupervisorHeader onEdit={showPanel} item={supervisorState.data} />
                            <Stack.Item verticalFill>
                                <Stack verticalFill>
                                    <Sticky>
                                        <Pivot style={{ padding: "0px 32px" }} aria-label="Container details" selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId}>
                                            <PivotItem itemKey='information' headerText={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.PIVOTS.INFORMATION} />
                                            <PivotItem itemKey='organizations' headerText={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.PIVOTS.ORGANIZATIONS} />
                                        </Pivot>
                                    </Sticky>
                                    <Stack.Item grow verticalFill>
                                        <SupervisorPivotBasedState state={selectedPivotKey}
                                            item={supervisorState.data}
                                            isFetching={fetching}
                                            onDelete={showConfirmation}
                                            updateState={(data) => updateSupervisor(data)}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </ScrollablePane>
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    ))}
                {error && (
                    <Stack verticalFill>
                        {error.length > 0 && (
                            <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                        )}
                        <Stack.Item verticalFill>
                            <SomethingWentWrong action={() => getSupervisor(supervisorId)} />
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={hidePanel}
                title={strings.ORGANIZATION.SUPERVISORS.EDIT_SUPERVISOR}
            >
                {formError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {formError}
                    </MessageBar>
                )}
                {!formError && !fetching && (
                    <SupervisorForm
                        onSubmit={(data: ISupervisor) => onFormSubmit(data)}
                        onCancel={hidePanel}
                        data={supervisorState.data as ISupervisor} />
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
            </CustomPanel >
            {supervisorState.data?.id && (
                <Modal isOpen={confirmation} onDismiss={hideConfirmation} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SUPERVISORS.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideConfirmation}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={() => supervisorState.data?.id && deleteSupervisor(supervisorState.data?.id)}
                            onCancel={hideConfirmation}
                            requiredMessageString={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.FIELDS.NAME_REQUIRED}
                            confirmationCheckString={supervisorState.data?.name as string}
                            placeholder={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.FIELDS.NAME_PLACEHOLDER}
                            confirmText={strings.BUTTONS.TEXT.DELETE}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.SUPERVISORS.REMOVE_CONFIRMATION_TEXT1} <b>"{supervisorState.data?.name}"</b>.</Text>
                                <Text>{strings.ORGANIZATION.SUPERVISORS.REMOVE_CONFIRMATION_TEXT2}</Text>
                                <Text>{strings.ORGANIZATION.SUPERVISORS.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{supervisorState.data?.email}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>)}
        </>
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};