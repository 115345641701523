import { PrimaryButton, ScrollablePane, Stack, TextField, Text, Icon, FontIcon } from '@fluentui/react'
import { useBoolean } from '@uifabric/react-hooks'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { strings } from '../../../localization/strings'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { ISupervisor } from '../../../core/store/typings/ISupervisor'
import { validatePassword } from '../configurator/stages/assessment/dataCollection/modeConfigurator/pivots/helpers/validationRules'
import { PasswordPolicy } from '../PasswordPolicy'

type TProps = {
    onSubmit: (supervisor: ISupervisor) => void
    onCancel: () => void
    data?: ISupervisor
    organizationDomain?: string
}

export const SupervisorForm: React.FC<TProps> = (props) => {

    const { control, handleSubmit, watch, trigger, formState: { errors } } = useForm<ISupervisor & { repeatPassword?: string }>({
        defaultValues: {
            ...props.data
        },
        mode: "all"
    });

    const onSubmit: SubmitHandler<ISupervisor & { repeatPassword?: string }> = data => {
        if (data.password === data.repeatPassword) {
            delete (data.repeatPassword)
            props.onSubmit(data)
        }
    };

    const [isContactsShow, { setTrue: showContacts, setFalse: hideContacts }] = useBoolean(false)

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_REQUIRED, minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` } }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME}
                                        description={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME}
                                        placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="password"
                                rules={validatePassword(undefined, true)}
                                render={({ field }) =>
                                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                                        <Stack.Item grow>
                                            <TextField
                                                required
                                                value={field.value}
                                                styles={{
                                                    fieldGroup: {
                                                        borderColor: field.value && !errors.password?.message ? "#5DCA42" : undefined, ':after': {
                                                            borderColor: field.value && !errors.password?.message ? "#5DCA42" : undefined
                                                        }
                                                    }
                                                }}
                                                onChange={(ev, val) => { field.onChange(val); trigger("repeatPassword") }}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                type='password'
                                                canRevealPassword
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD}
                                                description={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD}
                                                placeholder={strings.COMMON.CREATE_PASSWORD}
                                                errorMessage={errors.password?.message}
                                            />
                                        </Stack.Item>
                                        <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 24px 0" }}>
                                                <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().password && !errors.password) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                            </Stack.Item>
                                    </Stack>
                                }
                            />
                            <Stack.Item>
                                <PasswordPolicy isManager />
                                <Controller
                                    control={control}
                                    name="repeatPassword"
                                    rules={{
                                        // required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED,
                                        validate: (value) => {
                                            return (watch().password === value) ? true : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED
                                        }
                                    }}
                                    render={({ field }) =>
                                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                                            <Stack.Item grow>
                                                <TextField
                                                    value={field.value}
                                                    styles={{
                                                        fieldGroup: {
                                                            borderColor: field.value && !errors.repeatPassword?.message ? "#5DCA42" : undefined, ':after': {
                                                                borderColor: field.value && !errors.repeatPassword?.message ? "#5DCA42" : undefined
                                                            }
                                                        }
                                                    }}
                                                    type="password"
                                                    required
                                                    canRevealPassword
                                                    onChange={field.onChange}
                                                    label={strings.COMMON.CONFIRM_PASSWORD}
                                                    placeholder={strings.COMMON.CONFIRM_PASSWORD}
                                                    errorMessage={errors.repeatPassword?.message}
                                                />
                                            </Stack.Item>
                                            <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>
                                                    <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().repeatPassword && !errors.repeatPassword) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                                </Stack.Item>
                                        </Stack>
                                    }
                                />
                            </Stack.Item>
                            <Stack horizontalAlign="end">
                                <SecondaryButton onClick={isContactsShow ? hideContacts : showContacts}>
                                    <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                        <Icon iconName={isContactsShow ? "ChevronUp" : "ChevronDown"} style={{ fontWeight: 500 }} />
                                        <Text style={{ fontWeight: 500 }}>{strings.BUTTONS.TEXT.ADVANCED}</Text>
                                    </Stack>
                                </SecondaryButton>
                            </Stack>
                            {isContactsShow && (
                                <>
                                    <Controller
                                        name="display"
                                        control={control}
                                        // rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY_REQUIRED }}
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY}
                                                placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DISPLAY_PLACEHOLDER}
                                                errorMessage={errors.display?.message}
                                            />
                                        }
                                    />
                                    <Controller
                                        control={control}
                                        name="email"
                                        // rules={{ required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_REQUIRED, minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` } }}
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.EMAIL}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.EMAIL}
                                                placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.EMAIL_PLACEHOLDER}
                                                errorMessage={errors.email?.message}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}
