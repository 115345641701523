import { IComboBoxOption, IDatePickerStrings, IDropdownOption } from "@fluentui/react";
import { strings } from "../../localization/strings";
import { IPhoneNumber } from "@piceasoft/core"

export const dayPickerStrings: IDatePickerStrings = {
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    shortMonths: ['Янв', 'Фев', 'Май', 'Апр', 'Март', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    goToToday: 'Сегодня',
    prevMonthAriaLabel: 'Предыдущий месяц',
    nextMonthAriaLabel: 'Следующий месяц',
    prevYearAriaLabel: 'Предыдущий год',
    nextYearAriaLabel: 'Следующий год'
};

export const getLocalizedRegion = (code: CountryCodes): string => {
    switch (code) {
        case CountryCodes.AF: return strings.SHARED.CONTRIES.AF;
        case CountryCodes.AL: return strings.SHARED.CONTRIES.AL;
        case CountryCodes.DZ: return strings.SHARED.CONTRIES.DZ;
        case CountryCodes.AS: return strings.SHARED.CONTRIES.AS;
        case CountryCodes.AD: return strings.SHARED.CONTRIES.AD;
        case CountryCodes.AO: return strings.SHARED.CONTRIES.AO;
        case CountryCodes.AI: return strings.SHARED.CONTRIES.AI;
        case CountryCodes.AQ: return strings.SHARED.CONTRIES.AQ;
        case CountryCodes.AG: return strings.SHARED.CONTRIES.AG;
        case CountryCodes.AR: return strings.SHARED.CONTRIES.AR;
        case CountryCodes.AM: return strings.SHARED.CONTRIES.AM;
        case CountryCodes.AW: return strings.SHARED.CONTRIES.AW;
        case CountryCodes.AU: return strings.SHARED.CONTRIES.AU;
        case CountryCodes.AT: return strings.SHARED.CONTRIES.AT;
        case CountryCodes.AZ: return strings.SHARED.CONTRIES.AZ;
        case CountryCodes.BS: return strings.SHARED.CONTRIES.BS;
        case CountryCodes.BH: return strings.SHARED.CONTRIES.BH;
        case CountryCodes.BD: return strings.SHARED.CONTRIES.BD;
        case CountryCodes.BB: return strings.SHARED.CONTRIES.BB;
        case CountryCodes.BY: return strings.SHARED.CONTRIES.BY;
        case CountryCodes.BE: return strings.SHARED.CONTRIES.BE;
        case CountryCodes.BZ: return strings.SHARED.CONTRIES.BZ;
        case CountryCodes.BJ: return strings.SHARED.CONTRIES.BJ;
        case CountryCodes.BM: return strings.SHARED.CONTRIES.BM;
        case CountryCodes.BT: return strings.SHARED.CONTRIES.BT;
        case CountryCodes.BO: return strings.SHARED.CONTRIES.BO;
        case CountryCodes.BQ: return strings.SHARED.CONTRIES.BQ;
        case CountryCodes.BA: return strings.SHARED.CONTRIES.BA;
        case CountryCodes.BW: return strings.SHARED.CONTRIES.BW;
        case CountryCodes.BV: return strings.SHARED.CONTRIES.BV;
        case CountryCodes.BR: return strings.SHARED.CONTRIES.BR;
        case CountryCodes.IO: return strings.SHARED.CONTRIES.IO;
        case CountryCodes.BN: return strings.SHARED.CONTRIES.BN;
        case CountryCodes.BG: return strings.SHARED.CONTRIES.BG;
        case CountryCodes.BF: return strings.SHARED.CONTRIES.BF;
        case CountryCodes.BI: return strings.SHARED.CONTRIES.BI;
        case CountryCodes.CV: return strings.SHARED.CONTRIES.CV;
        case CountryCodes.KH: return strings.SHARED.CONTRIES.KH;
        case CountryCodes.CM: return strings.SHARED.CONTRIES.CM;
        case CountryCodes.CA: return strings.SHARED.CONTRIES.CA;
        case CountryCodes.KY: return strings.SHARED.CONTRIES.KY;
        case CountryCodes.CF: return strings.SHARED.CONTRIES.CF;
        case CountryCodes.TD: return strings.SHARED.CONTRIES.TD;
        case CountryCodes.CL: return strings.SHARED.CONTRIES.CL;
        case CountryCodes.CN: return strings.SHARED.CONTRIES.CN;
        case CountryCodes.CX: return strings.SHARED.CONTRIES.CX;
        case CountryCodes.CC: return strings.SHARED.CONTRIES.CC;
        case CountryCodes.CO: return strings.SHARED.CONTRIES.CO;
        case CountryCodes.KM: return strings.SHARED.CONTRIES.KM;
        case CountryCodes.CD: return strings.SHARED.CONTRIES.CD;
        case CountryCodes.CG: return strings.SHARED.CONTRIES.CG;
        case CountryCodes.CK: return strings.SHARED.CONTRIES.CK;
        case CountryCodes.CR: return strings.SHARED.CONTRIES.CR;
        case CountryCodes.HR: return strings.SHARED.CONTRIES.HR;
        case CountryCodes.CU: return strings.SHARED.CONTRIES.CU;
        case CountryCodes.CW: return strings.SHARED.CONTRIES.CW;
        case CountryCodes.CY: return strings.SHARED.CONTRIES.CY;
        case CountryCodes.CZ: return strings.SHARED.CONTRIES.CZ;
        case CountryCodes.CI: return strings.SHARED.CONTRIES.CI;
        case CountryCodes.DK: return strings.SHARED.CONTRIES.DK;
        case CountryCodes.DJ: return strings.SHARED.CONTRIES.DJ;
        case CountryCodes.DM: return strings.SHARED.CONTRIES.DM;
        case CountryCodes.DO: return strings.SHARED.CONTRIES.DO;
        case CountryCodes.EC: return strings.SHARED.CONTRIES.EC;
        case CountryCodes.EG: return strings.SHARED.CONTRIES.EG;
        case CountryCodes.SV: return strings.SHARED.CONTRIES.SV;
        case CountryCodes.GQ: return strings.SHARED.CONTRIES.GQ;
        case CountryCodes.ER: return strings.SHARED.CONTRIES.ER;
        case CountryCodes.EE: return strings.SHARED.CONTRIES.EE;
        case CountryCodes.SZ: return strings.SHARED.CONTRIES.SZ;
        case CountryCodes.ET: return strings.SHARED.CONTRIES.ET;
        case CountryCodes.FK: return strings.SHARED.CONTRIES.FK;
        case CountryCodes.FO: return strings.SHARED.CONTRIES.FO;
        case CountryCodes.FJ: return strings.SHARED.CONTRIES.FJ;
        case CountryCodes.FI: return strings.SHARED.CONTRIES.FI;
        case CountryCodes.FR: return strings.SHARED.CONTRIES.FR;
        case CountryCodes.GF: return strings.SHARED.CONTRIES.GF;
        case CountryCodes.PF: return strings.SHARED.CONTRIES.PF;
        case CountryCodes.TF: return strings.SHARED.CONTRIES.TF;
        case CountryCodes.GA: return strings.SHARED.CONTRIES.GA;
        case CountryCodes.GM: return strings.SHARED.CONTRIES.GM;
        case CountryCodes.GE: return strings.SHARED.CONTRIES.GE;
        case CountryCodes.DE: return strings.SHARED.CONTRIES.DE;
        case CountryCodes.GH: return strings.SHARED.CONTRIES.GH;
        case CountryCodes.GI: return strings.SHARED.CONTRIES.GI;
        case CountryCodes.GR: return strings.SHARED.CONTRIES.GR;
        case CountryCodes.GL: return strings.SHARED.CONTRIES.GL;
        case CountryCodes.GD: return strings.SHARED.CONTRIES.GD;
        case CountryCodes.GP: return strings.SHARED.CONTRIES.GP;
        case CountryCodes.GU: return strings.SHARED.CONTRIES.GU;
        case CountryCodes.GT: return strings.SHARED.CONTRIES.GT;
        case CountryCodes.GG: return strings.SHARED.CONTRIES.GG;
        case CountryCodes.GN: return strings.SHARED.CONTRIES.GN;
        case CountryCodes.GW: return strings.SHARED.CONTRIES.GW;
        case CountryCodes.GY: return strings.SHARED.CONTRIES.GY;
        case CountryCodes.HT: return strings.SHARED.CONTRIES.HT;
        case CountryCodes.HM: return strings.SHARED.CONTRIES.HM;
        case CountryCodes.VA: return strings.SHARED.CONTRIES.VA;
        case CountryCodes.HN: return strings.SHARED.CONTRIES.HN;
        case CountryCodes.HK: return strings.SHARED.CONTRIES.HK;
        case CountryCodes.HU: return strings.SHARED.CONTRIES.HU;
        case CountryCodes.IS: return strings.SHARED.CONTRIES.IS;
        case CountryCodes.IN: return strings.SHARED.CONTRIES.IN;
        case CountryCodes.ID: return strings.SHARED.CONTRIES.ID;
        case CountryCodes.IR: return strings.SHARED.CONTRIES.IR;
        case CountryCodes.IQ: return strings.SHARED.CONTRIES.IQ;
        case CountryCodes.IE: return strings.SHARED.CONTRIES.IE;
        case CountryCodes.IM: return strings.SHARED.CONTRIES.IM;
        case CountryCodes.IL: return strings.SHARED.CONTRIES.IL;
        case CountryCodes.IT: return strings.SHARED.CONTRIES.IT;
        case CountryCodes.JM: return strings.SHARED.CONTRIES.JM;
        case CountryCodes.JP: return strings.SHARED.CONTRIES.JP;
        case CountryCodes.JE: return strings.SHARED.CONTRIES.JE;
        case CountryCodes.JO: return strings.SHARED.CONTRIES.JO;
        case CountryCodes.KZ: return strings.SHARED.CONTRIES.KZ;
        case CountryCodes.KE: return strings.SHARED.CONTRIES.KE;
        case CountryCodes.KI: return strings.SHARED.CONTRIES.KI;
        case CountryCodes.KP: return strings.SHARED.CONTRIES.KP;
        case CountryCodes.KR: return strings.SHARED.CONTRIES.KR;
        case CountryCodes.KW: return strings.SHARED.CONTRIES.KW;
        case CountryCodes.KG: return strings.SHARED.CONTRIES.KG;
        case CountryCodes.LA: return strings.SHARED.CONTRIES.LA;
        case CountryCodes.LV: return strings.SHARED.CONTRIES.LV;
        case CountryCodes.LB: return strings.SHARED.CONTRIES.LB;
        case CountryCodes.LS: return strings.SHARED.CONTRIES.LS;
        case CountryCodes.LR: return strings.SHARED.CONTRIES.LR;
        case CountryCodes.LY: return strings.SHARED.CONTRIES.LY;
        case CountryCodes.LI: return strings.SHARED.CONTRIES.LI;
        case CountryCodes.LT: return strings.SHARED.CONTRIES.LT;
        case CountryCodes.LU: return strings.SHARED.CONTRIES.LU;
        case CountryCodes.MO: return strings.SHARED.CONTRIES.MO;
        case CountryCodes.MG: return strings.SHARED.CONTRIES.MG;
        case CountryCodes.MW: return strings.SHARED.CONTRIES.MW;
        case CountryCodes.MY: return strings.SHARED.CONTRIES.MY;
        case CountryCodes.MV: return strings.SHARED.CONTRIES.MV;
        case CountryCodes.ML: return strings.SHARED.CONTRIES.ML;
        case CountryCodes.MT: return strings.SHARED.CONTRIES.MT;
        case CountryCodes.MH: return strings.SHARED.CONTRIES.MH;
        case CountryCodes.MQ: return strings.SHARED.CONTRIES.MQ;
        case CountryCodes.MR: return strings.SHARED.CONTRIES.MR;
        case CountryCodes.MU: return strings.SHARED.CONTRIES.MU;
        case CountryCodes.YT: return strings.SHARED.CONTRIES.YT;
        case CountryCodes.MX: return strings.SHARED.CONTRIES.MX;
        case CountryCodes.FM: return strings.SHARED.CONTRIES.FM;
        case CountryCodes.MD: return strings.SHARED.CONTRIES.MD;
        case CountryCodes.MC: return strings.SHARED.CONTRIES.MC;
        case CountryCodes.MN: return strings.SHARED.CONTRIES.MN;
        case CountryCodes.ME: return strings.SHARED.CONTRIES.ME;
        case CountryCodes.MS: return strings.SHARED.CONTRIES.MS;
        case CountryCodes.MA: return strings.SHARED.CONTRIES.MA;
        case CountryCodes.MZ: return strings.SHARED.CONTRIES.MZ;
        case CountryCodes.MM: return strings.SHARED.CONTRIES.MM;
        case CountryCodes.NA: return strings.SHARED.CONTRIES.NA;
        case CountryCodes.NR: return strings.SHARED.CONTRIES.NR;
        case CountryCodes.NP: return strings.SHARED.CONTRIES.NP;
        case CountryCodes.NL: return strings.SHARED.CONTRIES.NL;
        case CountryCodes.NC: return strings.SHARED.CONTRIES.NC;
        case CountryCodes.NZ: return strings.SHARED.CONTRIES.NZ;
        case CountryCodes.NI: return strings.SHARED.CONTRIES.NI;
        case CountryCodes.NE: return strings.SHARED.CONTRIES.NE;
        case CountryCodes.NG: return strings.SHARED.CONTRIES.NG;
        case CountryCodes.NU: return strings.SHARED.CONTRIES.NU;
        case CountryCodes.NF: return strings.SHARED.CONTRIES.NF;
        case CountryCodes.MP: return strings.SHARED.CONTRIES.MP;
        case CountryCodes.NO: return strings.SHARED.CONTRIES.NO;
        case CountryCodes.OM: return strings.SHARED.CONTRIES.OM;
        case CountryCodes.PK: return strings.SHARED.CONTRIES.PK;
        case CountryCodes.PW: return strings.SHARED.CONTRIES.PW;
        case CountryCodes.PS: return strings.SHARED.CONTRIES.PS;
        case CountryCodes.PA: return strings.SHARED.CONTRIES.PA;
        case CountryCodes.PG: return strings.SHARED.CONTRIES.PG;
        case CountryCodes.PY: return strings.SHARED.CONTRIES.PY;
        case CountryCodes.PE: return strings.SHARED.CONTRIES.PE;
        case CountryCodes.PH: return strings.SHARED.CONTRIES.PH;
        case CountryCodes.PN: return strings.SHARED.CONTRIES.PN;
        case CountryCodes.PL: return strings.SHARED.CONTRIES.PL;
        case CountryCodes.PT: return strings.SHARED.CONTRIES.PT;
        case CountryCodes.PR: return strings.SHARED.CONTRIES.PR;
        case CountryCodes.QA: return strings.SHARED.CONTRIES.QA;
        case CountryCodes.MK: return strings.SHARED.CONTRIES.MK;
        case CountryCodes.RO: return strings.SHARED.CONTRIES.RO;
        case CountryCodes.RU: return strings.SHARED.CONTRIES.RU;
        case CountryCodes.RW: return strings.SHARED.CONTRIES.RW;
        case CountryCodes.RE: return strings.SHARED.CONTRIES.RE;
        case CountryCodes.BL: return strings.SHARED.CONTRIES.BL;
        case CountryCodes.SH: return strings.SHARED.CONTRIES.SH;
        case CountryCodes.KN: return strings.SHARED.CONTRIES.KN;
        case CountryCodes.LC: return strings.SHARED.CONTRIES.LC;
        case CountryCodes.MF: return strings.SHARED.CONTRIES.MF;
        case CountryCodes.PM: return strings.SHARED.CONTRIES.PM;
        case CountryCodes.VC: return strings.SHARED.CONTRIES.VC;
        case CountryCodes.WS: return strings.SHARED.CONTRIES.WS;
        case CountryCodes.SM: return strings.SHARED.CONTRIES.SM;
        case CountryCodes.ST: return strings.SHARED.CONTRIES.ST;
        case CountryCodes.SA: return strings.SHARED.CONTRIES.SA;
        case CountryCodes.SN: return strings.SHARED.CONTRIES.SN;
        case CountryCodes.RS: return strings.SHARED.CONTRIES.RS;
        case CountryCodes.SC: return strings.SHARED.CONTRIES.SC;
        case CountryCodes.SL: return strings.SHARED.CONTRIES.SL;
        case CountryCodes.SG: return strings.SHARED.CONTRIES.SG;
        case CountryCodes.SX: return strings.SHARED.CONTRIES.SX;
        case CountryCodes.SK: return strings.SHARED.CONTRIES.SK;
        case CountryCodes.SI: return strings.SHARED.CONTRIES.SI;
        case CountryCodes.SB: return strings.SHARED.CONTRIES.SB;
        case CountryCodes.SO: return strings.SHARED.CONTRIES.SO;
        case CountryCodes.ZA: return strings.SHARED.CONTRIES.ZA;
        case CountryCodes.GS: return strings.SHARED.CONTRIES.GS;
        case CountryCodes.SS: return strings.SHARED.CONTRIES.SS;
        case CountryCodes.ES: return strings.SHARED.CONTRIES.ES;
        case CountryCodes.LK: return strings.SHARED.CONTRIES.LK;
        case CountryCodes.SD: return strings.SHARED.CONTRIES.SD;
        case CountryCodes.SR: return strings.SHARED.CONTRIES.SR;
        case CountryCodes.SJ: return strings.SHARED.CONTRIES.SJ;
        case CountryCodes.SE: return strings.SHARED.CONTRIES.SE;
        case CountryCodes.CH: return strings.SHARED.CONTRIES.CH;
        case CountryCodes.SY: return strings.SHARED.CONTRIES.SY;
        case CountryCodes.TW: return strings.SHARED.CONTRIES.TW;
        case CountryCodes.TJ: return strings.SHARED.CONTRIES.TJ;
        case CountryCodes.TZ: return strings.SHARED.CONTRIES.TZ;
        case CountryCodes.TH: return strings.SHARED.CONTRIES.TH;
        case CountryCodes.TL: return strings.SHARED.CONTRIES.TL;
        case CountryCodes.TG: return strings.SHARED.CONTRIES.TG;
        case CountryCodes.TK: return strings.SHARED.CONTRIES.TK;
        case CountryCodes.TO: return strings.SHARED.CONTRIES.TO;
        case CountryCodes.TT: return strings.SHARED.CONTRIES.TT;
        case CountryCodes.TN: return strings.SHARED.CONTRIES.TN;
        case CountryCodes.TR: return strings.SHARED.CONTRIES.TR;
        case CountryCodes.TM: return strings.SHARED.CONTRIES.TM;
        case CountryCodes.TC: return strings.SHARED.CONTRIES.TC;
        case CountryCodes.TV: return strings.SHARED.CONTRIES.TV;
        case CountryCodes.UG: return strings.SHARED.CONTRIES.UG;
        case CountryCodes.UA: return strings.SHARED.CONTRIES.UA;
        case CountryCodes.AE: return strings.SHARED.CONTRIES.AE;
        case CountryCodes.GB: return strings.SHARED.CONTRIES.GB;
        case CountryCodes.UM: return strings.SHARED.CONTRIES.UM;
        case CountryCodes.US: return strings.SHARED.CONTRIES.US;
        case CountryCodes.UY: return strings.SHARED.CONTRIES.UY;
        case CountryCodes.UZ: return strings.SHARED.CONTRIES.UZ;
        case CountryCodes.VU: return strings.SHARED.CONTRIES.VU;
        case CountryCodes.VE: return strings.SHARED.CONTRIES.VE;
        case CountryCodes.VN: return strings.SHARED.CONTRIES.VN;
        case CountryCodes.VG: return strings.SHARED.CONTRIES.VG;
        case CountryCodes.VI: return strings.SHARED.CONTRIES.VI;
        case CountryCodes.WF: return strings.SHARED.CONTRIES.WF;
        case CountryCodes.EH: return strings.SHARED.CONTRIES.EH;
        case CountryCodes.YE: return strings.SHARED.CONTRIES.YE;
        case CountryCodes.ZM: return strings.SHARED.CONTRIES.ZM;
        case CountryCodes.ZW: return strings.SHARED.CONTRIES.ZW;
        case CountryCodes.AX: return strings.SHARED.CONTRIES.AX;
        default: return code
    }
}

export enum CountryCodes {
    AF = "AF",
    AL = "AL",
    DZ = "DZ",
    AS = "AS",
    AD = "AD",
    AO = "AO",
    AI = "AI",
    AQ = "AQ",
    AG = "AG",
    AR = "AR",
    AM = "AM",
    AW = "AW",
    AU = "AU",
    AT = "AT",
    AZ = "AZ",
    BS = "BS",
    BH = "BH",
    BD = "BD",
    BB = "BB",
    BY = "BY",
    BE = "BE",
    BZ = "BZ",
    BJ = "BJ",
    BM = "BM",
    BT = "BT",
    BO = "BO",
    BQ = "BQ",
    BA = "BA",
    BW = "BW",
    BV = "BV",
    BR = "BR",
    IO = "IO",
    BN = "BN",
    BG = "BG",
    BF = "BF",
    BI = "BI",
    CV = "CV",
    KH = "KH",
    CM = "CM",
    CA = "CA",
    KY = "KY",
    CF = "CF",
    TD = "TD",
    CL = "CL",
    CN = "CN",
    CX = "CX",
    CC = "CC",
    CO = "CO",
    KM = "KM",
    CD = "CD",
    CG = "CG",
    CK = "CK",
    CR = "CR",
    HR = "HR",
    CU = "CU",
    CW = "CW",
    CY = "CY",
    CZ = "CZ",
    CI = "CI",
    DK = "DK",
    DJ = "DJ",
    DM = "DM",
    DO = "DO",
    EC = "EC",
    EG = "EG",
    SV = "SV",
    GQ = "GQ",
    ER = "ER",
    EE = "EE",
    SZ = "SZ",
    ET = "ET",
    FK = "FK",
    FO = "FO",
    FJ = "FJ",
    FI = "FI",
    FR = "FR",
    GF = "GF",
    PF = "PF",
    TF = "TF",
    GA = "GA",
    GM = "GM",
    GE = "GE",
    DE = "DE",
    GH = "GH",
    GI = "GI",
    GR = "GR",
    GL = "GL",
    GD = "GD",
    GP = "GP",
    GU = "GU",
    GT = "GT",
    GG = "GG",
    GN = "GN",
    GW = "GW",
    GY = "GY",
    HT = "HT",
    HM = "HM",
    VA = "VA",
    HN = "HN",
    HK = "HK",
    HU = "HU",
    IS = "IS",
    IN = "IN",
    ID = "ID",
    IR = "IR",
    IQ = "IQ",
    IE = "IE",
    IM = "IM",
    IL = "IL",
    IT = "IT",
    JM = "JM",
    JP = "JP",
    JE = "JE",
    JO = "JO",
    KZ = "KZ",
    KE = "KE",
    KI = "KI",
    KP = "KP",
    KR = "KR",
    KW = "KW",
    KG = "KG",
    LA = "LA",
    LV = "LV",
    LB = "LB",
    LS = "LS",
    LR = "LR",
    LY = "LY",
    LI = "LI",
    LT = "LT",
    LU = "LU",
    MO = "MO",
    MG = "MG",
    MW = "MW",
    MY = "MY",
    MV = "MV",
    ML = "ML",
    MT = "MT",
    MH = "MH",
    MQ = "MQ",
    MR = "MR",
    MU = "MU",
    YT = "YT",
    MX = "MX",
    FM = "FM",
    MD = "MD",
    MC = "MC",
    MN = "MN",
    ME = "ME",
    MS = "MS",
    MA = "MA",
    MZ = "MZ",
    MM = "MM",
    NA = "NA",
    NR = "NR",
    NP = "NP",
    NL = "NL",
    NC = "NC",
    NZ = "NZ",
    NI = "NI",
    NE = "NE",
    NG = "NG",
    NU = "NU",
    NF = "NF",
    MP = "MP",
    NO = "NO",
    OM = "OM",
    PK = "PK",
    PW = "PW",
    PS = "PS",
    PA = "PA",
    PG = "PG",
    PY = "PY",
    PE = "PE",
    PH = "PH",
    PN = "PN",
    PL = "PL",
    PT = "PT",
    PR = "PR",
    QA = "QA",
    MK = "MK",
    RO = "RO",
    RU = "RU",
    RW = "RW",
    RE = "RE",
    BL = "BL",
    SH = "SH",
    KN = "KN",
    LC = "LC",
    MF = "MF",
    PM = "PM",
    VC = "VC",
    WS = "WS",
    SM = "SM",
    ST = "ST",
    SA = "SA",
    SN = "SN",
    RS = "RS",
    SC = "SC",
    SL = "SL",
    SG = "SG",
    SX = "SX",
    SK = "SK",
    SI = "SI",
    SB = "SB",
    SO = "SO",
    ZA = "ZA",
    GS = "GS",
    SS = "SS",
    ES = "ES",
    LK = "LK",
    SD = "SD",
    SR = "SR",
    SJ = "SJ",
    SE = "SE",
    CH = "CH",
    SY = "SY",
    TW = "TW",
    TJ = "TJ",
    TZ = "TZ",
    TH = "TH",
    TL = "TL",
    TG = "TG",
    TK = "TK",
    TO = "TO",
    TT = "TT",
    TN = "TN",
    TR = "TR",
    TM = "TM",
    TC = "TC",
    TV = "TV",
    UG = "UG",
    UA = "UA",
    AE = "AE",
    GB = "GB",
    UM = "UM",
    US = "US",
    UY = "UY",
    UZ = "UZ",
    VU = "VU",
    VE = "VE",
    VN = "VN",
    VG = "VG",
    VI = "VI",
    WF = "WF",
    EH = "EH",
    YE = "YE",
    ZM = "ZM",
    ZW = "ZW",
    AX = "AX"
}

export enum CountryPhonePrefixCodes {
    AF = 93,
    AL = 355,
    DZ = 213,
    AS = 1,
    AD = 376,
    AO = 244,
    AI = 1,
    AQ = 672,
    AG = 1,
    AR = 54,
    AM = 374,
    AW = 297,
    AU = 61,
    AT = 43,
    AZ = 994,
    BS = 1,
    BH = 973,
    BD = 880,
    BB = 1,
    BY = 375,
    BE = 32,
    BZ = 501,
    BJ = 229,
    BM = 1,
    BT = 975,
    BO = 591,
    BQ = 599,
    BA = 387,
    BW = 267,
    BV = 74,
    BR = 55,
    IO = 246,
    BN = 673,
    BG = 359,
    BF = 226,
    BI = 257,
    KH = 855,
    CM = 237,
    CA = 1,
    CV = 238,
    KY = 1,
    CF = 236,
    TD = 235,
    CL = 56,
    CN = 86,
    CX = 61,
    CC = 61,
    CO = 57,
    KM = 269,
    CD = 243,
    CG = 242,
    CK = 682,
    CR = 506,
    HR = 385,
    CU = 53,
    CW = 599,
    CY = 357,
    CZ = 420,
    CI = 225,
    DK = 45,
    DJ = 253,
    DM = 1,
    DO = 1,
    EC = 593,
    EG = 20,
    SV = 503,
    GQ = 240,
    ER = 291,
    EE = 372,
    SZ = 268,
    ET = 251,
    FK = 500,
    FO = 298,
    FJ = 679,
    FI = 358,
    FR = 33,
    GF = 594,
    PF = 689,
    TF = 262,
    GA = 241,
    GM = 220,
    GE = 995,
    DE = 49,
    GH = 233,
    GI = 350,
    GR = 30,
    GL = 299,
    GD = 1,
    GP = 590,
    GU = 1,
    GT = 502,
    GG = 44,
    GN = 224,
    GW = 245,
    GY = 592,
    HT = 509,
    HM = 672,
    VA = 379,
    HN = 504,
    HK = 852,
    HU = 36,
    IS = 354,
    IN = 91,
    ID = 62,
    IR = 98,
    IQ = 964,
    IE = 353,
    IM = 44,
    IL = 972,
    IT = 39,
    JM = 1,
    JP = 81,
    JE = 44,
    JO = 962,
    KZ = 7,
    KE = 254,
    KI = 686,
    KP = 850,
    KR = 82,
    KW = 965,
    KG = 996,
    LA = 856,
    LV = 371,
    LB = 961,
    LS = 266,
    LR = 231,
    LY = 218,
    LI = 423,
    LT = 370,
    LU = 352,
    MO = 853,
    MG = 389,
    MW = 261,
    MY = 265,
    MV = 60,
    ML = 960,
    MT = 223,
    MH = 356,
    MQ = 692,
    MR = 222,
    MU = 230,
    YT = 262,
    MX = 52,
    FM = 691,
    MD = 373,
    MC = 377,
    MN = 976,
    ME = 382,
    MS = 1,
    MA = 212,
    MZ = 258,
    MM = 95,
    NA = 264,
    NR = 674,
    NP = 977,
    NL = 31,
    NC = 687,
    NZ = 64,
    NI = 505,
    NE = 227,
    NG = 234,
    NU = 683,
    NF = 672,
    MP = 1,
    NO = 47,
    OM = 968,
    PK = 92,
    PW = 680,
    PS = 970,
    PA = 507,
    PG = 675,
    PY = 595,
    PE = 51,
    PH = 63,
    PN = 64,
    PL = 48,
    PT = 351,
    PR = 1,
    QA = 974,
    MK = 389,
    RO = 40,
    RU = 7,
    RW = 250,
    RE = 262,
    BL = 590,
    SH = 290,
    KN = 1,
    LC = 1,
    MF = 590,
    PM = 508,
    VC = 1,
    WS = 685,
    SM = 378,
    ST = 239,
    SA = 966,
    SN = 221,
    RS = 381,
    SC = 248,
    SL = 232,
    SG = 65,
    SX = 1,
    SK = 421,
    SI = 386,
    SB = 677,
    SO = 252,
    ZA = 27,
    GS = 500,
    SS = 211,
    ES = 34,
    LK = 94,
    SD = 249,
    SR = 597,
    SJ = 47,
    SE = 46,
    CH = 41,
    SY = 963,
    TW = 886,
    TJ = 992,
    TZ = 255,
    TH = 66,
    TL = 670,
    TG = 228,
    TK = 690,
    TO = 676,
    TT = 1,
    TN = 216,
    TR = 90,
    TM = 993,
    TC = 1,
    TV = 688,
    UG = 256,
    UA = 380,
    AE = 971,
    GB = 44,
    UM = 1,
    US = 1,
    UY = 598,
    UZ = 998,
    VU = 678,
    VE = 58,
    VN = 84,
    VG = 1,
    VI = 1,
    WF = 681,
    EH = 212,
    YE = 967,
    ZM = 260,
    ZW = 263,
    AX = 358
}

export const prepareRegionOptions = (): IComboBoxOption[] => {
    return Object.entries(CountryCodes).map(([countryKey, countryCode]) => {
        return {
            key: countryCode,
            text: getLocalizedRegion(countryKey as CountryCodes),
        }
    })
}

export const preparePhonePrefixOptions = (): IComboBoxOption[] => {
    return Object.entries(CountryCodes).map(([countryKey, countryCode]) => {
        return {
            key: countryKey,
            title: CountryPhonePrefixCodes[countryKey as unknown as CountryPhonePrefixCodes],
            text: getLocalizedRegion(countryKey as CountryCodes),
        }
    })
}

export const getFormatedPhoneNumber = (phone?: IPhoneNumber) => {
    if (!phone?.number) return ''
    switch (phone.country) {
        case "RU": return `${phone.prefix ? (`+${phone.prefix}`) : ''} (${phone.number?.substring(0,3)}) ${phone.number?.substring(3,6)} ${phone.number?.substring(6,8)}-${phone.number?.substring(8)}`
        default: return `${phone.prefix ? (`+${phone.prefix}`) : ''} ${phone.number}`
    }
}

export const getLocalizedPhoneMask = (phone?: IPhoneNumber) => {
    if (phone?.number === undefined) return "9999999999"
    switch (phone.country) {
        case "RU": return "(999) 999-9999"
        default: return "9999999999"
    }
}

export const getMaskedPhoneValue = (mask: string, value: string) => {
    let result = ""
    const maskArray = Array.from(mask)
    const digits = value.replace(/[^0-9]+/g, "")
    let position = 0
    maskArray.forEach(symbol => {
        if (Number.parseInt(symbol)) {
            if (position >= digits.length) {
                result += "_"
                return;
            }
            result += digits[position];
            position += 1;
            return;
        }
        result += symbol;
    });
    return result;
}

export const getTinyMCELang = (lang?: string):string => {
    lang = lang || 'en-US'

    if (lang.substring(0, 5).toLowerCase() === "pt-br")
        return "pt_BR"
    if (lang.substring(0, 5).toLowerCase() === "pt-pt")
        return "pt_PT"

    if (lang && lang.length > 2) {
        lang = lang.substring(0, 2).toLowerCase();
    }

    switch (lang) {
        case 'fr': return 'fr_FR'
        default: return lang
    }   
}

export const preparedLanguageOptions: IDropdownOption[] = [
    { key: "de-DE", text: "German" }, 
    { key: 'en-US', text: "English" }, 
    { key: "es-ES", text: "Spanish" }, 
    { key: 'fr-FR', text: "French" }, 
    { key: "it-IT", text: "Italian" }, 
    { key: 'ru-RU', text: "Russian" },
    { key: 'pt-BR', text: "Brazilian portuguese" },
    { key: 'pt-PT', text: "Portuguese" },
    { key: 'tr-TR', text: "Turkish" },
    { key: 'el-EL', text: "Greek" },
    { key: 'nl-NL', text: "Dutch" },
    { key: 'bg-BG', text: "Bulgarian" }
]
