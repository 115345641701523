import {
    getTheme, INavLinkGroup, INavStyles, Nav, Stack,
    INavLink, Text, Icon, IComponentAs, Image,
    INavButtonProps, IStackStyles, IButtonStyles,
    TooltipHost, DirectionalHint, ImageFit
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getIconSymbolsFromString, getPersonaColorsPallete } from '../../../core/scripts/style';
import { IStore } from '../../../core/store';
import { AccountRoles } from '../../../core/store/typings/AccountRoles';
import { AllRoles, UserRoles } from '../../../core/store/typings/UserRoles';
import { strings } from '../../../localization/strings';
import { AddOrganization } from '../../AddOrganization';

export const getFullNav = () => [
    {
        links: [
            {
                name: strings.NAVIGATION.OVERVIEW.TITLE,
                url: '',
                key: 'NAVIGATION.OVERVIEW',
                icon: 'images/navigation/icons/overview.svg',
                links: [
                    {
                        name: strings.NAVIGATION.OVERVIEW.TRANSACTIONS_LOG,
                        url: '/overview/transactions',
                        key: 'NAVIGATION.OVERVIEW.TRANSACTIONS',
                        icon: '/Sprint',
                        roles: [UserRoles.All]
                    },
                    {
                        name: strings.NAVIGATION.OVERVIEW.PHOTO_MODERATION,
                        url: '/overview/moderations',
                        key: 'NAVIGATION.OVERVIEW.MODERATIONS',
                        icon: '/Photo2',
                        roles: [UserRoles.All]
                    },
                    // {
                    //     name: strings.NAVIGATION.OVERVIEW.STORE,
                    //     url: '/overview/store',
                    //     key: 'NAVIGATION.OVERVIEW.STORE',
                    //     icon: '/Package',
                    //     roles: [UserRoles.All]
                    // },
                ],
                isExpanded: false
            },
            {
                name: strings.NAVIGATION.ORGANIZATION.TITLE,
                url: '',
                key: 'NAVIGATION.ORGANIZATION',
                icon: 'images/navigation/icons/work.svg',
                links: [
                    {
                        name: strings.NAVIGATION.ORGANIZATION.STRUCTURE,
                        url: '/organization/structure',
                        key: 'NAVIGATION.ORGANIZATION.STRUCTURE',
                        icon: '/Org',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor]
                    },
                    {
                        name: strings.NAVIGATION.ORGANIZATION.COMPANIES,
                        url: '/organization/companies',
                        key: 'NAVIGATION.ORGANIZATION.COMPANIES',
                        icon: '/Suitcase',
                        roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor, AccountRoles.Master, AccountRoles.Manager]
                    },
                    {
                        name: strings.NAVIGATION.ORGANIZATION.RESPONSIBLE,
                        url: '/organization/responsible',
                        key: 'NAVIGATION.ORGANIZATION.RESPONSIBLE',
                        icon: '/Telemarketer',
                        roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor, AccountRoles.Master, AccountRoles.Manager]
                    },
                    {
                        name: strings.NAVIGATION.ORGANIZATION.ACCESS,
                        url: '/organization/access',
                        key: 'NAVIGATION.ORGANIZATION.ACCESS',
                        icon: '/Permissions',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor]
                    },
                    {
                        name: strings.NAVIGATION.ORGANIZATION.BRANDING,
                        url: '/organization/branding',
                        key: 'NAVIGATION.ORGANIZATION.BRANDING',
                        icon: '/Color',
                        roles: [UserRoles.Organization, UserRoles.Branding, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor]
                    }
                ],
                isExpanded: false,
            },
            {
                name: strings.NAVIGATION.SERVICES.TITLE,
                url: '',
                key: 'NAVIGATION.SERVICES',
                icon: 'images/navigation/icons/workflow.svg',
                links: [
                    {
                        name: strings.NAVIGATION.SERVICES.CONFIGURATION,
                        url: '/services/configuration',
                        key: 'NAVIGATION.SERVICES.CONFIGURATION',
                        icon: '/ServerProcesses',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor]
                    },
                    {
                        name: strings.NAVIGATION.SERVICES.PRINT,
                        url: '/services/print',
                        key: 'NAVIGATION.SERVICES.PRINT',
                        icon: '/DocLibrary',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor]
                    },
                    {
                        name: strings.NAVIGATION.SERVICES.CATALOGS,
                        url: '/services/catalogs',
                        key: 'NAVIGATION.SERVICES.CATALOGS',
                        icon: '/ProductCatalog',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor],
                    },
                    {
                        name: strings.NAVIGATION.SERVICES.PROMOTIONS,
                        url: '/services/promotions',
                        key: 'NAVIGATION.SERVICES.PROMOTIONS',
                        icon: '/Shop',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor],
                    },
                    {
                        name: strings.NAVIGATION.SERVICES.SELFSERVICEPROMOTIONS,
                        url: '/services/selfservicepromotions',
                        key: 'NAVIGATION.SERVICES.SELFSERVICEPROMOTIONS',
                        icon: '/Tag',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor]
                    }
                ],
                isExpanded: false,
            },
            {
                name: strings.NAVIGATION.FINANCE.TITLE,
                url: '',
                key: 'NAVIGATION.FINANCE',
                icon: 'images/navigation/icons/money.svg',
                links: [
                    {
                        name: strings.NAVIGATION.FINANCE.REPORTS,
                        url: '/finance/reports',
                        key: 'NAVIGATION.FINANCE.REPORTS',
                        icon: '/Chart',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor],
                    }
                ],
                isExpanded: false,
            },
            {
                name: strings.NAVIGATION.LOGISTICS.TITLE,
                url: '',
                key: 'NAVIGATION.LOGISTICS',
                icon: 'images/navigation/icons/warehouse.svg',
                links: [
                    {
                        name: strings.NAVIGATION.LOGISTICS.WAREHOUSE,
                        url: '/logistics/warehouse',
                        key: 'NAVIGATION.LOGISTICS.WAREHOUSE',
                        icon: '/Packages',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor]
                    },
                    {
                        name: strings.NAVIGATION.LOGISTICS.DELIVERY,
                        url: '/logistics/delivery',
                        key: 'NAVIGATION.LOGISTICS.DELIVERY',
                        icon: '/DeliveryTruck',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor]
                    }
                ],
                isExpanded: false,
            },
            /*{
                name: strings.NAVIGATION.TRADING.TITLE,
                url: '',
                key: 'NAVIGATION.TRADING',
                icon: 'images/navigation/icons/trading.svg',
                links: [
                    {
                        name: strings.NAVIGATION.TRADING.MARKETPLACE,
                        url: '/trading/marketplace',
                        key: 'NAVIGATION.TRADING.MARKETPLACE',
                        icon: '/Flow',
                        roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor]
                    }
                ],
                isExpanded: false,
            },*/
            {
                name: strings.NAVIGATION.ADMINISTRATION.TITLE,
                url: '',
                key: 'NAVIGATION.ADMINISTRATION',
                icon: 'images/navigation/icons/book.svg',
                links: [
                    {
                        name: strings.NAVIGATION.ADMINISTRATION.SUPERVISORS,
                        url: '/administration/supervisors',
                        key: 'NAVIGATION.ADMINISTRATION.SUPERVISORS',
                        icon: '/Devices2',
                        roles: [UserRoles.Administrator]
                    },
                    {
                        name: strings.SETTINGS.VALIDATION_API.VALIDATION_API_TITTLE,
                        url: '/administration/validation',
                        key: 'NAVIGATION.ADMINISTRATION.VALIDATION',
                        icon: '/Permissions',
                        roles: [UserRoles.Administrator]
                    },
                ],
                isExpanded: false,
            },
            {
                name: strings.NAVIGATION.DOCUMENTATION.TITLE,
                url: '',
                key: 'NAVIGATION.DOCUMENTATION',
                icon: 'images/navigation/icons/docs.svg',
                links: [
                    {
                        name: strings.NAVIGATION.DOCUMENTATION.TITLE,
                        url: '/documentation',
                        key: 'NAVIGATION.DOCUMENTATION',
                        icon: '/BookAnswers',
                        roles: [UserRoles.All, AccountRoles.Manager, AccountRoles.Master, AccountRoles.Operator, AccountRoles.Photoreport, AccountRoles.Service, AccountRoles.Statistic]
                    },
                ],
                isExpanded: false,
            },
            // {
            //     name: strings.NAVIGATION.TRADING.TITLE,
            //     url: '',
            //     key: 'NAVIGATION.TRADING',
            //     icon: 'images/navigation/icons/trading.svg?3',
            //     links: [
            //         {
            //             name: strings.NAVIGATION.TRADING.MARKETPLACE,
            //             url: '/trading/marketplace',
            //             key: 'NAVIGATION.TRADING.MARKETPLACE',
            //             icon: '/Flow',
            //             roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Statistic]
            //         }
            //     ],
            //     isExpanded: false,
            // },
            // {
            //     name: "Справочники",
            //     url: '',
            //     key: 'NAVIGATION.REFERANCES',
            //     icon: 'images/navigation/icons/book.svg?3',
            //     links: [
            //         {
            //             name: "Устройства",
            //             url: '/finance/reports',
            //             key: 'NAVIGATION.REFERANCES.DEVICES',
            //             icon: '/Devices2',
            //             roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Statistic]
            //         },
            //         {
            //             name: "Производители",
            //             url: '/finance/reports',
            //             key: 'NAVIGATION.REFERANCES.MANUFACTURERS',
            //             icon: '/Manufacturing',
            //             roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Statistic]
            //         },
            //     ],
            //     isExpanded: false,
            // }
        ]
    },
];

const settingsLink = {
    name: strings.NAVIGATION.LOGISTICS.TITLE,
    url: '/settings',
    key: 'NAVIGATION.SETTINGS',
    icon: '/Settings',
    roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Supervisor],
    isExpanded: false,
}

export const Navigation: React.FC<{ logo?: string, organization?: string }> = (props) => {

    const fullNav = getFullNav()
    const prepareNavLinkGroups = (roles: Array<AllRoles>): INavLinkGroup[] => {
        const result = fullNav.map(i => {
            let links = i.links.filter(il => {
                let result = false
                const groupLinks = il.links.filter(ill => {
                    if (ill.roles.includes(UserRoles.All)) {
                        return true;
                    }
                    roles.map(r => {
                        if (ill.roles?.includes(r)) {
                            result = true;
                        }
                    })
                })
                if (groupLinks.length > 0) {
                    result = true;
                }
                return result;
            })
            links = links.map(l => {
                const filteredLinks = l.links.filter(ill => {
                    let result = false
                    if (ill.roles.includes(UserRoles.All)) {
                        return true;
                    }
                    roles.map(r => {
                        if (ill.roles?.includes(r)) {
                            result = true;
                        }
                    })
                    return result;
                })
                return { ...l, url: filteredLinks[0].url, links: filteredLinks }
            })
            return { ...i, links: links }
        })
        return result;
    }

    const getKeyByPath = () => {
        const pathname = document.location.pathname
        navLinkGroups[0].links.find(i => {
            if ((i.url === pathname) || pathname.startsWith(i.url)) {
                setSelectedKey(i.key);
            }
            if (i.links) {
                i.links.map(l => {
                    if ((l.url === pathname) || pathname.startsWith(l.url)) {
                        setSelectedKey(l.key);
                    }
                })
            }
        })
        if (pathname === '/settings') {
            setSelectedKey('settings')
        }
    }

    const navigation = useSelector((s: IStore) => s.environment.navigation) ?? [];
    const roles = useSelector((s: IStore) => s.environment.roles) ?? [];
    const [navLinkGroups, setNavLinkGroups] = React.useState(prepareNavLinkGroups(roles))
    const [selectedKey, setSelectedKey] = React.useState<string>()
    const [isNavCollapsed, { toggle: toggleNavCollapseState }] = useBoolean(false)

    const history = useHistory();

    const canCreateOrganization = roles?.find(i => [UserRoles.Administrator, UserRoles.Supervisor].includes(i as UserRoles));

    React.useEffect(() => {
        getKeyByPath()
    }, [])

    React.useEffect(() => {
        updateExpandedGroup()
    }, [selectedKey])

    const updateExpandedGroup = () => {
        const newNavLinkGroups = [{
            links: [...navLinkGroups[0].links.map(i => {
                if (i.key === selectedKey?.substr(0, i.key?.length)) {
                    return { ...i, isExpanded: true }
                }
                return { ...i, isExpanded: false }
            })]
        }] as INavLinkGroup[]
        setNavLinkGroups(newNavLinkGroups)
    }

    const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.preventDefault()
        if (item) {
            if (item?.url === '/documentation')
            {
                window.open("/docs/Content/PiceaOnlineConfig/ConfigHelp/Release-notes/Release-notes.htm", "_blank");
            } else if (item?.url === '/swagger')
            {
                window.open("/swagger", "_blank");

            } else
            {
            history.push(item?.url)
            getKeyByPath()
            }
        }
    }

    const navStyles: Partial<INavStyles> = {
        root: {
            width: isNavCollapsed ? 48 : 260,
            transition: '0.2s',
            boxSizing: 'border-box'
        },
        chevronButton: {
            display: 'none'
        },
        //  UIKit CSS applies border-box to everything, which squeezes the images to 0x0 size.
        navItems: {
            "img": {
                boxSizing: 'content-box',
            }
        },
        // navItem: {
        // },
        // link: {
        //     height: 48,
        //     paddingLeft: 0,
        //     paddingTop: 0,
        //     paddingBottom: 0,
        //     selectors: {
        //         '&:hover': { background: 'rgba(0,0,0,.09)' ?? theme.palette.neutralLight },
        //         // '&:focus': { background: 'rgba(0,0,0,.08)' ?? theme.palette.neutralLight },
        //     },
        // },
        // compositeLink: {
        //     height: 48,
        //     paddingTop: 0,
        //     paddingBottom: 0
        // },
    };

    const settingsButtonStyles: IButtonStyles = {
        root: {
            borderLeft: `3px solid ${selectedKey === 'settings' ? theme.palette.themePrimary : 'transparent'}`,
            backgroundColor: selectedKey === 'settings' ? 'rgba(0,0,0,.06)' : 'transparent',
            ':hover': {
                backgroundColor: selectedKey === 'settings' ? undefined : 'rgba(0,0,0,.06)'
            }
        },
    };

    const linkAs: IComponentAs<INavButtonProps> = (props) => {
        const keyParts = selectedKey?.split('.');
        const isGroup = (props?.link?.links && props?.link?.links?.length > 0) ?? false
        let isSelectedKey = false;
        let isSelectedGroupChild = false
        let isSelectedGroupKey = false
        if (keyParts && keyParts?.length > 1) {
            isSelectedGroupChild = props?.link?.key?.split('.')[1] === keyParts[1]
            isSelectedGroupKey = props?.link?.key === [keyParts[0], keyParts[1]].join('.');
        }
        if (window.location.pathname === props?.link?.url) {
            isSelectedKey = true;
        }

        return (
            <Stack key={props.link?.key}
                tokens={{ childrenGap: 16 }}
                styles={getNavItemStyles(props.link?.key === selectedKey, isSelectedGroupChild, isGroup, isSelectedGroupKey)}
                horizontal verticalAlign='center'
                grow onClick={props?.onClick}
            >
                {props?.link?.icon && (
                    <Stack verticalFill verticalAlign='center' styles={{ root: { paddingLeft: !isGroup ? 0 : 0, marginLeft: -3 } }}>
                        {isGroup && (
                            <img src={props?.link?.icon} width={24} height={24} style={{ padding: 12 }} />
                        ) || (
                                <Icon iconName={props?.link?.icon?.substr(1)} style={{ fontSize: 16, padding: 12, marginLeft: 4 }} />
                            )}
                    </Stack>
                )}
                <Text block nowrap style={{ marginLeft: isGroup ? 0 : 14, fontWeight: isSelectedKey ? 600 : 500, color: "rgba(0,0,0,0.9)" }}>{props?.link?.name}</Text>
            </Stack>
        )
    }

    return (
        <Stack grow verticalFill style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            <Stack grow verticalFill style={{
                borderRight: `1px solid ${theme.palette.neutralTertiaryAlt}`,
                backgroundColor: 'rgba(0,0,0,.08)'
            }}>
                <Stack grow horizontalAlign="end" horizontal={isNavCollapsed ? false : true} verticalAlign='center' style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                    <Stack grow verticalFill horizontal verticalAlign='center' style={{ overflow: 'hidden', width: '100%', height: 48 }} >
                        <Stack verticalAlign='center' style={{ padding: 12 }}>
                            <Stack verticalAlign="center"
                                horizontalAlign="center"
                                style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: theme.effects.roundedCorner4,
                                    backgroundColor: !props.logo ? (props.organization ? getPersonaColorsPallete[props.organization[0].toLowerCase()] : undefined) : undefined
                                }}>
                                {(props.logo) && (
                                    <Stack horizontalAlign='center'>
                                        <Image src={props.logo} width={24} height={24} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                    </Stack>
                                ) || (
                                        <Text variant="small" style={{ color: "#fff" }}>
                                            {getIconSymbolsFromString(props.organization)}
                                        </Text>
                                    )}
                            </Stack>
                        </Stack>
                        {!isNavCollapsed && (
                            <Text block nowrap variant="mediumPlus" style={{ color: theme.palette.black, fontWeight: "bold", maxWidth: 160 }}>{props.organization}</Text>
                        )}
                        {canCreateOrganization && !isNavCollapsed && (
                            <Stack.Item grow>
                                <Stack grow horizontalAlign='end' verticalFill verticalAlign='center'>
                                    <AddOrganization />
                                </Stack>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack>
                {canCreateOrganization && isNavCollapsed && (
                    <Stack.Item grow>
                        <Stack verticalFill style={{ height: 40 }} verticalAlign='center' grow horizontalAlign='center'>
                            <AddOrganization />
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <div style={{ borderTop: `1px solid ${theme.palette.neutralQuaternary}` }} />
                </Stack.Item>
                <Stack.Item verticalFill>
                    <Nav
                        // onRenderLink={onRenderLink}
                        onLinkClick={onLinkClick}
                        selectedKey={selectedKey}
                        styles={navStyles}
                        groups={navLinkGroups}
                        linkAs={linkAs}
                    />
                </Stack.Item>
                <Stack.Item style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <div style={{ borderTop: `1px solid ${theme.palette.neutralQuaternary}` }} />
                </Stack.Item>
                <Stack grow horizontalAlign="end" horizontal={isNavCollapsed ? false : true} verticalAlign='center' style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                    {navigation.includes('/settings') && settingsLink.roles.find(i => roles.find(r => r === i)) && (
                        <Stack verticalFill horizontal verticalAlign='center' style={{ overflow: 'hidden', width: '100%', cursor: 'pointer' }} grow onClick={() => {
                            history.push('/settings')
                            getKeyByPath()
                        }}
                            tokens={{ padding: 12, childrenGap: 16 }}
                            styles={settingsButtonStyles}
                        >
                            <Icon styles={{ root: { fontSize: 16 } }} iconName={"Settings"} />
                            {!isNavCollapsed && (<Text block nowrap variant="medium">{strings.NAVIGATION.SETTINGS}</Text>)}
                        </Stack>
                    )}
                    <Stack.Item>
                        <Stack verticalFill horizontalAlign='center' verticalAlign='center' onClick={toggleNavCollapseState} style={{ width: 48 }}>
                            <TooltipHost content={isNavCollapsed ? strings.NAVIGATION.COLLAPSE_ON_INFO : strings.NAVIGATION.COLLAPSE_OFF_INFO}
                                tooltipProps={{
                                    calloutProps: {
                                        isBeakVisible: false,
                                        directionalHint: DirectionalHint.topAutoEdge,
                                    }
                                }}
                            >
                                <Icon styles={{ root: { padding: 12, fontSize: 16, cursor: 'pointer', ':hover': { color: '0 0 4px rgb(0 120 212 / 50%)', textShadow: '0 0 4px rgb(0 120 212 / 50%)' } } }} iconName={isNavCollapsed ? 'DoubleChevronRight' : 'DoubleChevronLeft'} />
                            </TooltipHost>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();

const getNavItemStyles = (isSelectedItem: boolean, isSelectedGroupChild: boolean, isGroup: boolean, isSelectedGroupKey: boolean): Partial<IStackStyles> => {
    return {
        root: {
            borderLeft: isSelectedItem ? `3px solid ${theme.palette.themePrimary}` : (isSelectedGroupChild ? `3px solid rgba(0,0,0,.2)` : `3px solid transparent`),
            background: isSelectedItem ? 'rgba(0,0,0,.10)' : (isSelectedGroupChild ? 'rgba(0,0,0,.06)' : 'transparent'),
            selectors: {
                '&:hover': { background: (!isSelectedGroupKey && isGroup) ? 'rgba(0,0,0,.10)' : (isSelectedGroupChild ? `rgba(0,0,0,.10)` : 'transparent') },
            },
            cursor: 'pointer',
            overflow: 'hidden'
        }
    }
}
