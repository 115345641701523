import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { IResultConfig } from "@piceasoft/core";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";

type TProps = {
    config: IResultConfig
    onChangeDispatch: Dispatch<any>
}

export const ResultCommonUIChildren: React.FC<TProps> = ({ onChangeDispatch, config }) => {
    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.UI.WARNING_TITLE}
                useToggle
                noSeparator
                toggleEnabled={true}
                toggleChecked={config.ui?.warning !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.result.onConfigCommit({
                        ...config,
                        ui: {
                            ...config.ui,
                            warning: config.ui?.warning !== undefined ? undefined : ""
                        }
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.UI.WARNING_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.warning !== undefined ? true : false}
                textFieldValue={config.ui?.warning}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.result.onConfigCommit({
                    ...config,
                    ui: {
                        ...config.ui,
                        warning: val
                    }
                }
                ))
                }
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.UI.SECONDARY_INFORMATION_TITLE}
                useToggle
                noSeparator
                toggleEnabled={true}
                toggleChecked={config.ui?.secondaryInformation !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.result.onConfigCommit({
                        ...config,
                        ui: {
                            ...config.ui,
                            secondaryInformation: config.ui?.secondaryInformation !== undefined ? undefined : ""
                        }
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.UI.SECONDARY_INFORMATION_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.secondaryInformation !== undefined ? true : false}
                textFieldValue={config.ui?.secondaryInformation}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.result.onConfigCommit({
                    ...config,
                    ui: {
                        ...config.ui,
                        secondaryInformation: val
                    }
                }
                ))
                }
            />
        </Stack>
    )
}