import { DetailsRow, getTheme, IDetailsRowProps, IDetailsRowStyles, IStyle } from "@fluentui/react"
import { useFormContext } from "react-hook-form";
import { IPromotionItem } from "../../../../core/store/typings/IPromotionItem";
import { strings } from "../../../../localization/strings";

type TProps = {
    rProps: IDetailsRowProps | undefined
}
export const PromotionsItemConditionsOnRenderRow: React.FC<TProps> = props => {
    const { formState } = useFormContext<IPromotionItem>();
    const customStyles: Partial<IDetailsRowStyles> = {};
        if (props.rProps) {
            if (formState.errors.conditions && formState.errors.conditions[props.rProps.itemIndex] && formState.errors.conditions[props.rProps.itemIndex]?.grade?.message === strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING) {
                customStyles.root = {
                    backgroundColor: "rgba(253, 231, 233,0.6)",
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: "rgb(253, 231, 233)",
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.hidden-button': {
                            color: theme.palette.black
                        },
                        '.up-icon': {
                            fontSize: 16,
                            color: theme.palette.themePrimary
                        }
                    }
                };
                customStyles.cellUnpadded = { paddingLeft: 10 }
            } else {
                customStyles.root = customRowRootStyle;
            }
            customStyles.cellUnpadded = { paddingLeft: 10 }
            return <DetailsRow {...props.rProps} styles={customStyles} />
        }
        return null;
    
}

const theme = getTheme();

const customRowRootStyle: IStyle = {
    cursor: 'default',
    ':hover': {
        '.disabled-icon': {
            color: theme.palette.black
        },
        '.hidden-button': {
            color: theme.palette.black
        },
        '.up-icon': {
            fontSize: 16,
            color: theme.palette.themePrimary
        }
    }
};