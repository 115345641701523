import * as React from 'react'
import { getTheme, Pivot, PivotItem, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { strings } from '../../../localization/strings';
import { ICatalog } from '../../../core/store';
import { FinanceCatalogPivotBasedState } from './FinanceCatalogPivotBasedState';
import { ICatalogVersionsState } from './FinanceCatalog';
import { CatalogTypes } from '@piceasoft/core';

type TProps = {
    data: ICatalog
    onDelete: (catalog: ICatalog) => void
    retryInfo: () => void
    versionsState: ICatalogVersionsState
    onImport: () => void
    setSelectedVersionId: (id?: string) => void
}

export type TFinanceCatalogPivotState = "info" | "prices" | "protocols"

const getTabId = (itemKey: string) => {
    return `ServicePivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "prices": return strings.ORGANIZATION.CATALOGS.PIVOTS.REPAIR.PRICETITLE
        case "info": return strings.ORGANIZATION.CATALOGS.PIVOTS.INFO.TITLE
        case "protocols": return strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.TITLE
        default: return m;
    }
}

export const FinanceCatalogDetails: React.FC<TProps> = (props) => {

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TFinanceCatalogPivotState>("prices");

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TFinanceCatalogPivotState);
    };

    return (
        <Stack verticalFill>
            <Stack.Item>
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                    <Stack tokens={{ padding: "0px 32px 0px 32px" }}>
                        <Stack.Item>
                            <Pivot selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="menu">
                                <PivotItem itemKey={"info"} headerText={getPivotHeaderText("info")} />
                                <PivotItem itemKey={"prices"} headerText={getPivotHeaderText("prices")} />
                                <PivotItem itemKey={"protocols"} headerText={getPivotHeaderText("protocols")} />
                            </Pivot>
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </Stack.Item>
            <Stack verticalFill>
                <FinanceCatalogPivotBasedState
                    state={selectedPivotKey}
                    data={props.data}
                    onDelete={props.onDelete}
                    versionsState={props.versionsState}
                    onImport={props.onImport}
                    setSelectedVersionId={props.setSelectedVersionId}
                />
            </Stack>
        </Stack>
    )
}
