import { IContractField, IContractCustomField } from "@piceasoft/core"
import { strings } from "../../../../../../../../../localization/strings"

export function getCommonFieldRules(field?: IContractField) {
    return {
        ...getRequiredRule(),
        ...getLengthRules(field),
    }
}

export function getMaskedFieldRules(value?: string) {
    return {
        ...getRequiredRule(),
        ...getValidateMaskRule(value),
    }
}

export function getCustomFieldRules(value?: string, field?: IContractCustomField) {
    return {
        validate: () => {
            if (field?.required && (value === "" || value === undefined)) {
                return strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.VALIDATION.FIELD_REQUIRED
            }

            if (value !== undefined && field?.minLength !== undefined && value.length < field?.minLength) {
                return `${strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.VALIDATION.MIN_LENGTH} ${field?.minLength}!`
            }

            if (value !== undefined && field?.maxLength !== undefined && value.length > field?.maxLength) {
                return `${strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.VALIDATION.MAX_LENGTH} ${field?.maxLength}!`
            }

            return validateMask()
        }
    }
}

function getRequiredRule(isRequired: boolean = true) {
    return {
        required: {
            value: isRequired,
            message: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.VALIDATION.FIELD_REQUIRED
        },
    }
}

function getLengthRules(field?: IContractField | IContractCustomField) {
    return {
        minLength: {
            value: field?.minLength ?? Number.MIN_VALUE,
            message: `${strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.VALIDATION.MIN_LENGTH} ${field?.minLength}!`
        },
        maxLength: {
            value: field?.maxLength ?? Number.MAX_VALUE,
            message: `${strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.VALIDATION.MAX_LENGTH} ${field?.maxLength}!`
        },
    }
}

function getValidateMaskRule(value?: string) {
    return {
        validate: () => validateMask(value)
    }
}

function validateMask(value?: string) {
    return !value?.includes('_') || strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.VALIDATION.MASK_REQUIRED
}
export function validatePassword(repeatPassword?: string, isManager?: boolean) {
    return {
        ...getValidatePasswordRules(repeatPassword, isManager),
    }
}
function validateSymbolsOfPassword(value: string,) {
    const symbols = value?.split('')
    for (let i = 0; i < symbols.length; i++) {
        if (symbols[i] === symbols[i + 1] && symbols[i + 1] === symbols[i + 2] && symbols[i + 2] === symbols[i + 3]) {
            return false
        }
    }
    return true
}
function getValidatePasswordRules(repeatPassword?: string, isManager?: boolean) {
    return {
        required : strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_NOT_MEET_SECURE_POLICY,
        validate: (value?: string) => {
            if (repeatPassword) {
                return validateSymbolsOfPassword(value ?? '') ? (repeatPassword === value) ? true : strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_NOT_MEET_SECURE_POLICY : strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_NOT_MEET_SECURE_POLICY
            } else {
                return validateSymbolsOfPassword(value ?? '') || strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_NOT_MEET_SECURE_POLICY
            }
        },
        pattern: {
            value: /(?=.*[\=!@#$%^&*_-])/,
            message: strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_NOT_MEET_SECURE_POLICY
        },
        minLength: {
            value: isManager ? 15 : 8,
            message: strings.ORGANIZATION.STRUCTURE.ITEM.PASSWORD_NOT_MEET_SECURE_POLICY
        }
    }
}

