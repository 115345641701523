import { IContextualMenuItem, SharedColors } from "@fluentui/react"
import { IService, TransactionPlaceholders } from "../../../../core/store"
import { getLocalizedInspectionName } from "../../../../localization/helpers/transaction"
import { strings } from "../../../../localization/strings"
import { IContractCustomField, Inspections, ProcessStages, ServiceTypes } from "@piceasoft/core";
import { IDataCollectionConfig } from "@piceasoft/core";
import { IInspectionModuleConfig } from "@piceasoft/core";
export const getPlaceholdersSubMenuItems = (pastePlaceholder: (placeholder: TransactionPlaceholders | string) => void, services?: IService[]):IContextualMenuItem[] => {

    const haveContentTransfer: boolean = services ? services.some( (s:IService) => ServiceTypes.ContentTransfer === s.workflow.serviceType) : false;

    return (
        [
            {
                key: 'transactionData',
                text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.TITLE,
                subMenuProps: {
                    items: [
                        {
                            key: 'NUMBER',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.NUMBER,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.NUMBER)
                        },
                        {
                            key: 'DATE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.DATE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DATE)
                        },
                        {
                            key: 'TIME',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.TIME,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TIME)
                        },
                        {
                            key: 'DATE_CLIENT',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.DATE_CLIENT,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DATE_CLIENT)
                        },
                        {
                            key: 'TIME_CLIENT',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.TIME_CLIENT,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TIME_CLIENT)
                        },
                        {
                            key: 'BARCODE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.BARCODE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.BARCODE)
                        },
                        {
                            key: 'BARCODE_IMEI',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.BARCODE_IMEI,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.BARCODE_IMEI)
                        },
                        {
                            key: 'BARCODE_IMEI2',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.BARCODE_IMEI2,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.BARCODE_IMEI2)
                        },
                        {
                            key: 'BARCODE_SN',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.BARCODE_SN,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.BARCODE_SN)
                        },
                        {
                            key: 'BARCODE_ARTIKUL',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.BARCODE_SKU,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.BARCODE_ARTIKUL)
                        },
                        {
                            key: 'SERVICE_CHANNEL',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SERVICE_CHANNEL,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.SERVICE_CHANNEL)
                        },
                        {
                            key: 'SERVICE_TYPE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SERVICE_TYPE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.SERVICE_TYPE)
                        },
                        {
                            key: 'SELF_SERVICE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.TITLE,
                            subMenuProps: {
                                items: [
                                    {
                                        key: 'SELF_SERVICE_TROUBLESHOOT',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.TROUBLESHOOT,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.SELF_SERVICE_TROUBLESHOOT)
                                    },
                                    {
                                        key: 'SELF_SERVICE_DIAGNOSTICS',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.DIAGNOSTICS,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.SELF_SERVICE_DIAGNOSTICS)
                                    },
                                    {
                                        key: 'SELF_SERVICE_AI_SOLUTION',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.AI,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.SELF_SERVICE_AI_SOLUTION)
                                    },
                                    {
                                        key: 'SELF_SERVICE_REPAIR_OFFER',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.REPAIR_OFFER.TITLE,
                                        subMenuProps: {
                                            items: [
                                                {
                                                    key: 'SELF_SERVICE_REPAIR_OFFER_ACCEPTED',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.REPAIR_OFFER.ACCEPTED,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.SELF_SERVICE_REPAIR_OFFER_ACCEPTED)
                                                },
                                                {
                                                    key: 'SELF_SERVICE_REPAIR_OFFER_ALL',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.REPAIR_OFFER.ALL,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.SELF_SERVICE_REAPIR_OFFER_ALL)
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        key: 'SELF_SERVICE_FOLLOW_UP',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.FOLLOW_UP_QUESTIONS.TITLE,
                                        subMenuProps: {
                                            items: [
                                                {
                                                    key: 'SELF_SERVICE_QUESTIONS_PHASE_ONE',                                           
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.FOLLOW_UP_QUESTIONS.ONE,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.SELF_SERVICE_QUESTIONS_PHASE_ONE),
                                                    ...followUpQuestionsIconAndStyleProps
                                                },
                                                {
                                                    key: 'SELF_SERVICE_QUESTIONS_PHASE_TWO',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.FOLLOW_UP_QUESTIONS.TWO,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.SELF_SERVICE_QUESTIONS_PHASE_TWO),
                                                    ...followUpQuestionsIconAndStyleProps
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        key: 'SELF_SERVICE_PROMOTION',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.PROMOTION,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.SELF_SERVICE_PROMOTION)
                                    },
                                    {
                                        key: 'SELF_SERVICE_RAN_FORM_DATA',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.TITLE,
                                        subMenuProps: {
                                            items: [
                                                {
                                                    key: 'RAN_FORM_NAME',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.NAME,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_NAME)
                                                },
                                                {
                                                    key: 'RAN_FORM_ID',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.ID,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_ID)
                                                },
                                                {
                                                    key: 'RAN_FORM_PHONENUMBER',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.PHONENUMBER,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_PHONENUMBER)
                                                },
                                                {
                                                    key: 'RAN_FORM_EMAIL',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.EMAIL,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_EMAIL)
                                                },
                                                {
                                                    key: 'RAN_FORM_ADDRESS',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.ADRESS,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_ADDRESS)
                                                },
                                                {
                                                    key: 'RAN_FORM_ZIP',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.ZIP,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_ZIP)
                                                },
                                                {
                                                    key: 'RAN_FORM_CITY',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.CITY,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_CITY)
                                                },
                                                {
                                                    key: 'RAN_FORM_SCHEDULE_FROM',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.SCHEDULE_FROM,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_SCHEDULE_FROM)
                                                },
                                                {
                                                    key: 'RAN_FORM_SCHEDULE_TO',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.SCHEDULE_TO,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_SCHEDULE_TO)
                                                },
                                                {
                                                    key: 'RAN_FORM_COMMENTS',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.COMMENTS,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_COMMENTS)
                                                },
                                                {
                                                    key: 'RAN_FORM_DEVICE_IMEI',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.IMEI,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_DEVICE_IMEI)
                                                },
                                                {
                                                    key: 'RAN_FORM_DEVICE_BRAND',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.BRAND,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_DEVICE_BRAND)
                                                },
                                                {
                                                    key: 'RAN_FORM_DEVICE_MODEL',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.MODEL,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_DEVICE_MODEL)
                                                },
                                                {
                                                    key: 'RAN_FORM_DEVICE_COLOR',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.COLOR,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_DEVICE_COLOR)
                                                },
                                                {
                                                    key: 'RAN_FORM_INVOICE_DATE',
                                                    text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.RAN_FORM.INVOICE,
                                                    onClick: () => pastePlaceholder(TransactionPlaceholders.RAN_FORM_INVOICE_DATE)
                                                },
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            key: 'CONTENTTRANSFER',
                            disabled: !haveContentTransfer,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CONTENTTRANSFER,
                            subMenuProps: {
                                items: [
                                    {
                                        key: 'CONTENTTRANSFER_DURATION',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CONTENTTRANSFER_DURATION,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CONTENTTRANSFER_DURATION)
                                    },
                                    {
                                        key: 'CONTENTTRANSFER_TABLE',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CONTENTTRANSFER_TABLE,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CONTENTTRANSFER_TABLE_RESULT)
                                    },
                                ]
                            }
                        },
                        {
                            key: 'inspections',
                            disabled: !services || services.length === 0,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.TITLE,
                            subMenuProps: {
                                items: services && services.length > 0 ? getServicesInspectionModulesItems(pastePlaceholder, services ?? []) : []
                            }
                        },
                        {
                            key: 'postoffer',
                            disabled: !services || services.length === 0,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.POST_OFFER.TITLE,
                            subMenuProps: {
                                items: services && services.length > 0 ? getServicesPostOfferInspectionModulesItems(pastePlaceholder, services ?? []) : []
                            }
                        },                        
                        {
                            key: 'promotion',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.TITLE,
                            subMenuProps: {
                                items: [
                                    {
                                        key: 'CAMPAIGN_NAME',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.NAME,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_NAME)
                                    },
                                    {
                                        key: 'CAMPAIGN_DESCRIPTION',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.DESCRIPTION,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_DESCRIPTION)
                                    },
                                    {
                                        key: 'CAMPAIGN_DEVICE_MANUFACTURER',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.DEVICE_MANUFACTURER,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_DEVICE_MANUFACTURER)
                                    },
                                    {
                                        key: 'CAMPAIGN_DEVICE_NAME',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.DEVICE_NAME,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_DEVICE_NAME)
                                    },
                                    {
                                        key: 'CAMPAIGN_PRICE',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.PRICE,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_PRICE)
                                    },
                                    {
                                        key: 'CAMPAIGN_PRICE_TYPE',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.PRICE_TYPE,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_PRICE_TYPE)
                                    },
                                    {
                                        key: 'CAMPAIGN_PROVIDER_CODE',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.PROVIDER_CODE,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_PROVIDER_CODE)
                                    },
                                    {
                                        key: 'CAMPAIGN_PROVIDER_NAME',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.PROVIDER_NAME,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_PROVIDER_NAME)
                                    },
                                    {
                                        key: 'CAMPAIGN_PROVIDER_DESCRIPTION',
                                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.CAMPAIGN.PROVIDER_DESCRIPTION,
                                        onClick: () => pastePlaceholder(TransactionPlaceholders.CAMPAIGN_PROVIDER_DESCRIPTION)
                                    },
                                ]
                            }
                        },

                    ]
                }
            },
            {
                key: 'deviceData',
                text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.TITLE,
                subMenuProps: {
                    items: [
                        {
                            key: 'DEVICE_VENDOR',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.VENDOR,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_VENDOR)
                        },
                        {
                            key: 'DEVICE_NAME',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.NAME,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_NAME)
                        },
                        {
                            key: 'DEVICE_MODEL',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.MODEL,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_MODEL)
                        },
                        {
                            key: 'DEVICE_STORAGE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.STORAGE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_STORAGE)
                        },
                        {
                            key: 'DEVICE_CONFIGURATION',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.CONFIGURATION,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_CONFIGURATION)
                        },
                        {
                            key: 'COLOR',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.COLOR,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.COLOR)
                        },
                        {
                            key: 'IMEI',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.IMEI,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.IMEI)
                        },
                        {
                            key: 'IMEI2',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.IMEI2,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.IMEI2)
                        },
                        {
                            key: 'MEID',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.MEID,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_MEID)
                        },
                        {
                            key: 'ICCID',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.ICCID,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_ICCID)
                        },
                        {
                            key: 'IMSI',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.IMSI,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_IMSI)
                        },
                        {
                            key: 'OS',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.OS,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_OS)
                        },
                        {
                            key: 'NETWORK_OPERATOR',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.NETWORK_OPERATOR,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_NETWORK_OPERATOR)
                        },
                        {
                            key: 'REGION',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.REGION,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_REGION)
                        },
                        {
                            key: 'REGION_CODE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.REGION_CODE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_REGION_CODE)
                        },
                        {
                            key: 'TAC',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.TAC,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TAC)
                        },
                        {
                            key: 'SN',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.SN,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.SN)
                        },
                        {
                            key: 'ARTIKUL',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.SKU,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.ARTIKUL)
                        },
                        {
                            key: 'PRICE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.PRICE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PRICE)
                        },
                        {
                            key: 'PRICE_WITH_CAMPAIGN',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.PRICE_WITH_CAMAPIGN,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PRICE_WITH_PROMO)
                        },
                        {
                            key: 'GRADE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.GRADE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.GRADE)
                        },
                        {
                            key: 'GRADE_CODE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.GRADE_ID,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.GRADE_CODE)
                        },
                        {
                            key: 'DEVICE_TABLE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.TABLE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.DEVICE_TABLE)
                        },
                    ]
                }
            },
            {
                key: 'targetDeviceData',
                disabled: !haveContentTransfer,
                text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TARGET_DEVICE.TITLE,
                subMenuProps: {
                    items: [
                        {
                            key: 'TARGET_DEVICE_VENDOR',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.VENDOR,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_VENDOR)
                        },
                        {
                            key: 'TARGET_DEVICE_NAME',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.NAME,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_NAME)
                        },
                        {
                            key: 'TARGET_DEVICE_MODEL',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.MODEL,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_MODEL)
                        },
                        {
                            key: 'TARGET_DEVICE_STORAGE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.STORAGE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_STORAGE)
                        },
                        {
                            key: 'TARGET_DEVICE_CONFIGURATION',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.CONFIGURATION,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_CONFIGURATION)
                        },
                        {
                            key: 'TARGET_COLOR',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.COLOR,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_COLOR)
                        },
                        {
                            key: 'TARGET_IMEI',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.IMEI,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_IMEI)
                        },
                        {
                            key: 'TARGET_IMEI2',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.IMEI2,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_IMEI2)
                        },
                        {
                            key: 'TARGET_MEID',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.MEID,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_MEID)
                        },
                        {
                            key: 'TARGET_ICCID',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.ICCID,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_ICCID)
                        },
                        {
                            key: 'TARGET_IMSI',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.IMSI,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_IMSI)
                        },
                        {
                            key: 'TARGET_OS',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.OS,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_OS)
                        },
                        {
                            key: 'TARGET_NETWORK_OPERATOR',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.NETWORK_OPERATOR,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_NETWORK_OPERATOR)
                        },
                        {
                            key: 'TARGET_REGION',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.REGION,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_REGION)
                        },
                        {
                            key: 'TARGET_REGION_CODE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.REGION_CODE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_REGION_CODE)
                        },
                        {
                            key: 'TARGET_TAC',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.TAC,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_TAC)
                        },
                        {
                            key: 'TARGET_SN',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.SN,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_SN)
                        },
                        //  No source for target SKU.
                        // {
                        //     key: 'TARGET_ARTIKUL',
                        //     text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.SKU,
                        //     onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_ARTIKUL)
                        // },
                        {
                            key: 'TARGET_DEVICE_TABLE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.DEVICE.TABLE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.TARGET_DEVICE_TABLE)
                        },
                    ]
                }
            },
            {
                key: 'pointData',
                text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.TITLE,
                subMenuProps: {
                    items: [
                        {
                            key: 'COMPANY_NAME',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.COMPANY_NAME,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.COMPANY_NAME)
                        },
                        {
                            key: 'COMPANY_INN',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.COMPANY_IEC,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.COMPANY_INN)
                        },
                        {
                            key: 'OFFICE_NAME',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.OFFICE_NAME,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.OFFICE_NAME)
                        },
                        {
                            key: 'OFFICE_PN',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.OFFICE_PN,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.OFFICE_PN)
                        },
                        {
                            key: 'OFFICE_CITY',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.OFFICE_CITY,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.OFFICE_CITY)
                        },
                        {
                            key: 'OFFICE_ADDRESS',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.OFFICE_ADDRESS,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.OFFICE_ADDRESS)
                        },
                        {
                            key: 'MANAGER_FIO',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.MANAGER_FULLNAME,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.MANAGER_FIO)
                        },
                        {
                            key: 'OPERATOR_SIGNATURE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.OPERATOR_SIGNATURE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.OPERATOR_SIGNATURE)
                        },
                        {
                            key: 'SUPERVISOR_SIGNATURE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.POINT.SUPERVISOR_SIGNATURE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.SUPERVISOR_SIGNATURE)
                        },
                    ]
                }
            },
            {
                key: 'clientData',
                text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.TITLE,
                subMenuProps: {
                    items: [
                        {
                            key: 'PASSPORT_FIRSTNAME',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.FULLNAME,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_FIRSTNAME)
                        },
                        {
                            key: 'CUSTOMER_PHONE_NUMBER',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.CUSTOMER_PHONE_NUMBER,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.CUSTOMER_PHONE_NUMBER)
                        },
                        {
                            key: 'CUSTOMER_EMAIL',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.EMAIL,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.CUSTOMER_EMAIL)
                        },
                        {
                            key: 'PASSPORT_DATEOFBIRTH',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.BIRTHDATE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_DATEOFBIRTH)
                        },
                        {
                            key: 'PASSPORT_PLACEOFBIRTH',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.BIRTHPLACE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_PLACEOFBIRTH)
                        },
                        {
                            key: 'PASSPORT_ADDRESS',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.ADDRESS,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_ADDRESS)
                        },
                        {
                            key: 'PASSPORT_SERIES',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.SERIE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_SERIES)
                        },
                        {
                            key: 'PASSPORT_NUMBER',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.NUMBER,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_NUMBER)
                        },
                        {
                            key: 'PASSPORT_ISSUEDBY',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.ISSUED_BY,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_ISSUEDBY)
                        },
                        {
                            key: 'PASSPORT_SUBDIVISIONCODE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.SUBDIVISION_CODE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_SUBDIVISIONCODE)
                        },
                        {
                            key: 'PASSPORT_DATEOFISSUE',
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.PASSPORT.ISSUE_DATE,
                            onClick: () => pastePlaceholder(TransactionPlaceholders.PASSPORT_DATEOFISSUE)
                        },
                        {
                            key: 'CUSTOM_FROM_SERVICES',
                            disabled: !services || services.length === 0,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.CUSTOM.TITLE,
                            subMenuProps: {
                                items: services && services.length > 0 ? getServicesContractCustomFieldsItems(pastePlaceholder, services) : []
                            }
                        },
                        {
                            key: 'ATTACHMENT',
                            disabled: !services || services.length === 0,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.CLIENT.ATTACHMENT.TITLE,
                            subMenuProps: {
                                items: services && services.length > 0 ? getServicesContractAttachmentsItems(pastePlaceholder, services) : []
                            }
                        },
                    ]
                }
            },
        ]
    )
}

const getServicesInspectionModulesItems = (pastePlaceholder: (placeholder: string) => void, services: IService[]):IContextualMenuItem[] => {
    return services.map(s => ({
        key: s.id?.toString(),
        text: s.name,
        disabled: !s.workflow.assessment?.modules || s.workflow.assessment.modules.length === 0,
        subMenuProps: {
            items: s.workflow.assessment?.modules?.map(m => ({
                key: m.index.toString(),
                text: m.config.ui?.title ?? `${getLocalizedInspectionName(m.type)} (${m.index})`,
                subMenuProps: {
                    items: [
                        {
                            //Temporary disable for AI Grading because functionality doesn't exist
                            //TODO: Enable menu item when Table Result functionality will be implemented
                            disabled: m.type === Inspections.AIGrading,
                            key: `MODULE.TABLE_RESULT.${m.index}`,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.TABLE_RESULT,
                            onClick: () => pastePlaceholder(`{MODULE.TABLE_RESULT.${m.index}}`)
                        },
                        {
                            key: `MODULE.GRADE.CODE.${m.index}`,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.GRADE_CODE,
                            onClick: () => pastePlaceholder(`{MODULE.GRADE.CODE.${m.index}}`)
                        },
                        {
                            key: `MODULE.GRADE.${m.index}`,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.GRADE_NAME,
                            onClick: () => pastePlaceholder(`{MODULE.GRADE.${m.index}}`)
                        },
                        {
                            key: `MODULE.STATUS.${m.index}`,
                            text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.STATUS,
                            onClick: () => pastePlaceholder(`{MODULE.STATUS.${m.index}}`)
                        },
                    ]
                }
            }
            )) as IContextualMenuItem[]
        }
    })) as IContextualMenuItem[]
}

const getServicesContractCustomFieldsItems = (pastePlaceholder: (placeholder: string) => void, services: IService[]):IContextualMenuItem[] => {
    return services.map(s => { 
        const allFields = s.workflow.contract?.fields?.custom?.length ? s.workflow.contract?.fields?.custom.map( (f) => ({...f})) : [] as IContractCustomField[]
        let contractFromFields = s.workflow.selfService?.contactForms?.contactForm?.custom?.length ? s.workflow.selfService?.contactForms?.contactForm?.custom.map( (f) => ({...f})) : [] as IContractCustomField[]
        allFields.push(...contractFromFields)
        const dataCollection = s.workflow.assessment?.modules.find( (m) => m.type === Inspections.DataCollection) as IInspectionModuleConfig<IDataCollectionConfig> | undefined
        if( dataCollection && dataCollection.config?.config?.fields?.custom?.length) {
            dataCollection.config?.config?.fields?.custom.forEach(element => {
                const field = allFields.find( (f) => f.key === element.key);
                if( !field) {
                    allFields.push( element)
                } 
                else if( field.label !== element.label) {
                    //  There is only one value stored per key; don't allow multiple names for same key.
                    //  Multiple names for same key would only confuse, when value is same.
                    field.label = field.label + "/" + element.label;
                }
            });
        }
        return ({
            key: s.id?.toString(),
            text: s.name,
            disabled: !allFields || allFields.length === 0,
            subMenuProps: {
                items: allFields?.map(c => ({
                    key: c.key.toString(),
                    text: c.label,
                    onClick: () => pastePlaceholder(`{CUSTOM_${c.key}}`)
                    }
                )) as IContextualMenuItem[]
            }
        }) as IContextualMenuItem
    })
}

const getServicesPostOfferInspectionModulesItems = (pastePlaceholder: (placeholder: string) => void, services: IService[]):IContextualMenuItem[] => {
    return services.map(s => {
        const providerFields:IContextualMenuItem[] = []
        s.workflow.commonOffer?.providers?.forEach( (provider) => {
            const mapping = provider.customFieldsMap?.map( (field) => ({
                key: field.key.toString(),
                text: field.label,
                onClick: () => pastePlaceholder(`{CUSTOM_${field.key}}`)
            }) as IContextualMenuItem)

            if( mapping && mapping.length > 0) {
                providerFields.push( {
                    key: provider.code,
                    text: provider.name,
                    items: mapping
                })
            }
        })

        const moduleFields = s.workflow.postOffer?.modules?.map(m => ({
            key: m.index.toString(),
            text: m.config.ui?.title ?? `${getLocalizedInspectionName(m.type, ProcessStages.PostOffer)} (${m.index})`,
            subMenuProps: {
                items: [
                    {
                        key: `POST_OFFER.MODULE.TABLE_RESULT.${m.index}`,
                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.TABLE_RESULT,
                        onClick: () => pastePlaceholder(`{POST_OFFER.MODULE.TABLE_RESULT.${m.index}}`)
                    },
                    {
                        key: `POST_OFFER.MODULE.GRADE.CODE.${m.index}`,
                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.GRADE_CODE,
                        onClick: () => pastePlaceholder(`{POST_OFFER.MODULE.GRADE.CODE.${m.index}}`)
                    },
                    {
                        key: `POST_OFFER.MODULE.GRADE.${m.index}`,
                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.GRADE_NAME,
                        onClick: () => pastePlaceholder(`{POST_OFFER.MODULE.GRADE.${m.index}}`)
                    },
                    {
                        key: `POST_OFFER.MODULE.STATUS.${m.index}`,
                        text: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.INSPECTIONS.STATUS,
                        onClick: () => pastePlaceholder(`{POST_OFFER.MODULE.STATUS.${m.index}}`)
                    }
                ]
            }
        }
        )) as IContextualMenuItem[]

        const totalFields = providerFields && moduleFields ? providerFields.concat( moduleFields) : (moduleFields ? moduleFields : (providerFields ? providerFields : []));

        return ({
            key: s.id?.toString(),
            text: s.name,
            disabled: totalFields.length === 0,
            subMenuProps: {
                items: totalFields
            }
        })
    }) as IContextualMenuItem[]
}

const followUpQuestionsIconAndStyleProps = {
    itemProps: {
        styles:{
            linkContent:{
                display: 'flex',
                flexDirection: 'row-reverse',
            }
        }                                                        
    },
    iconProps: {
        title: strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PLACEHOLDERS.TRANSACTION.SELF_SERVICE.FOLLOW_UP_QUESTIONS.HOVER,
        iconName: 'info',
        styles: {
            root:{
                color: SharedColors.gray30
            }
        }
    }
}

const getServicesContractAttachmentsItems = (pastePlaceholder: (placeholder: string) => void, services: IService[]):IContextualMenuItem[] => {
    return services.map(s => ({
        key: s.id?.toString(),
        text: s.name,
        disabled: !s.workflow.contract?.fields?.attachment || s.workflow.contract?.fields?.attachment.length === 0,
        subMenuProps: {
            items: s.workflow.contract?.fields?.attachment?.map(c => ({
                key: c.key.toString(),
                text: c.label === '' ? c.key.toString() : c.label ?? c.key.toString(),
                onClick: () => pastePlaceholder(`{ATTACHMENT_${c.key}}`)
                }
            )) as IContextualMenuItem[]
        }
    })) as IContextualMenuItem[]
}