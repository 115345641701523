import { Stack, getTheme, FontWeights, mergeStyleSets } from "@fluentui/react"
import React from "react";
import { IStageConfig, IStageConfigUI } from "@piceasoft/core";
import * as UIKit from "@piceasoft/picea-react-ui-kit"

type TProps = {
    config: IStageConfig<IStageConfigUI>
    defaultConfirmText: string
    defaultCancelText: string
    defaultheader: string
}

export const StageConfirmationPreviewUIKit: React.FC<TProps> = ({ config, ...props }) => {

    return (
        <Stack horizontalAlign="center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} >
            <Stack.Item tokens={{ padding: 24 }}>
                <UIKit.DialogS 
                    title={config.ui?.confirmation?.header ?? props.defaultheader}
                    primaryButtonContent={(config.ui?.confirmation?.buttons?.confirm?.text && config.ui?.confirmation?.buttons?.confirm?.text.trim().length > 0)
                                        ? config.ui?.confirmation?.buttons?.confirm?.text : props.defaultConfirmText}
                    secondaryButtonContent={(config.ui?.confirmation?.buttons?.cancel?.text && config.ui?.confirmation?.buttons?.cancel?.text.trim().length > 0)
                                        ? config.ui?.confirmation?.buttons?.cancel?.text : props.defaultCancelText}

                    onPrimaryButtonClick={ () => {}}
                    onSecondaryButtonClick={ () => {}}
                >
                    {props.children}
                </UIKit.DialogS>
            </Stack.Item>
        </Stack>
    )
}