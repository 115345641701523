import {
    DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint,
    FontWeights, getTheme, IButtonStyles, IColumn, IconButton,
    IContextualMenuProps, IDetailsHeaderProps, IDetailsListProps,
    IDetailsRowStyles, IRenderFunction, mergeStyleSets, MessageBar,
    MessageBarType, Modal, PrimaryButton, ScrollablePane, SelectionMode,
    SharedColors, Spinner, Stack, Text, TextField
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { MyPersona } from '../../components/shared/decorations/MyPersona';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { PersonForm } from '../../components/shared/forms/PersonForm';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { portalApi } from '../../core/api/api';
import { IPerson, IStore } from '../../core/store';
import { strings } from '../../localization/strings';
import { useSelector } from 'react-redux';
import { Badge } from '../../components/shared/decorations/Badge';
import { SimpleCustomFilter } from '../../components/shared/filter/SimpleCustomFilter';
import { useHistory } from 'react-router';
import { Section } from '../../components/shared/decorations/Section';
import { onRenderColumnHeaderCommon } from '../../components/shared/detailsList/commonRenders';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { getFormatedPhoneNumber } from '../../core/helpers/localization';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { SecondaryButton } from '../../components/shared/buttons/SecondaryButton';
import { getAccessToResetPassword } from '../../core/store/typings/UserRoles';
import { ResetPasswordForm } from '../../components/shared/forms/ResetPasswordForm';

export const OrganizationAccess: React.FC = () => {

    const [error, setError] = React.useState<string>()
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const [accountsState, setAccountsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IPerson> }>({ fetched: false })
    const [items, setItems] = React.useState<IPerson[]>([])

    const [searchString, setSearchString] = React.useState<string>()

    const [account, setAccount] = React.useState<{ data?: IPerson, requested: boolean }>({ requested: false })
    const [deleteAccountState, setDeleteAccountState] = React.useState<IPerson>()
    const [resetAccountPasswordState, setResetAccountPasswordState] = React.useState<{ fetched: boolean, error?: string, data?: IPerson }>({ fetched: false })
    const organization = useSelector((s: IStore) => s.workplace.organization)

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const roles = useSelector((s: IStore) => s.environment.roles);
    const [newPasswordState, setNewPasswordState] = React.useState<string>()

    const history = useHistory();
    React.useEffect(() => {
        if (organization) {
            requestAccounts()
        }
    }, [organization])

    React.useEffect(() => {
        if (accountsState.data && accountsState.data !== items) {
            setItems(accountsState.data)
        }
    }, [accountsState])

    React.useEffect(() => {
        console.log(account)
        if (!account?.requested) {
            requestAccountData()
        }
    }, [account])

    React.useEffect(() => {
        setItems(() => {
            var newItems = accountsState.data ? [...accountsState.data] : []
            if (searchString) {
                newItems = [
                    ...((newItems as IPerson[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.name,
                            i.email,
                            `${i.phone?.prefix ?? ''}${i.phone?.number ?? ''}`,
                            i.display,
                            i.officeName,
                            i.companyName
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as IPerson[]
        })
    }, [searchString])

    const requestAccounts = async () => {
        setError(undefined)
        setAccountsState({ fetched: false })
        console.log(organization)
        const result = await portalApi.organization.accounts.getAccounts(organization?.id)
        console.log(result)
        if (result.successed) {
            setAccountsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
        }
        if (result.errors && result.errors.length > 0) {
            setError(result.errors[0].description)
        }
    }

    const requestAccountData = async () => {
        console.log('requestAccountData')
        if (account?.data?.id) {
            const result = await portalApi.organization.accounts.getAccount(account?.data?.id);
            console.log(result)
            if (result.successed) {
                setAccount({
                    data: {
                        ...account.data,
                        password: result.data?.password,
                        companyId: result.data?.companyId ?? account.data?.companyId,
                        pointId: result.data?.pointId,
                        organizationId: result.data?.organizationId ?? account.data?.organizationId,
                        roles: result.data?.roles ?? account.data?.roles ?? []
                    }, requested: true
                })
            }
        }
    }

    const getItemMenuProps = (item: IPerson): IContextualMenuProps => {
        const access = getAccessToResetPassword(roles, item.roles)

        const resetPasswordItem = {
            key: `reset_password-${item.id}`,
            iconProps: { iconName: 'PasswordField', style: { color: theme.palette.black, fontSize: 14 } },
            onClick: () => setResetAccountPasswordState({ data: item, fetched: false }),
            text: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.RESET_PASSWORD_TITLE,
        }

        const result = {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? onEditAccount(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? setDeleteAccountState(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }

        access && result.items.splice(1, 0, resetPasswordItem)
        return result
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 700,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPerson) => <MyPersona onClick={() => onEditAccount(item)} text={item.display ?? ''} secondaryText={item.name ?? ''} />,
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.TABLE_COLUMNS.CONTACTS,
            fieldName: 'contacts',
            minWidth: 200,
            maxWidth: 350,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPerson) => <Stack verticalFill verticalAlign='center'>
                {item.email && <Text>{item.email}</Text>}
                {item.phone && <Text>{getFormatedPhoneNumber(item.phone)}</Text>}
            </Stack>,
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.TABLE_COLUMNS.POINT,
            fieldName: 'point',
            minWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPerson) => <Stack tokens={{ childrenGap: 8 }}>
                {item.companyName && <Stack verticalFill verticalAlign='center'><Text variant="small">{item.companyName}</Text></Stack>}
                {((item.officeCode || item.officeName) || (item.officeCode || item.officeName)) && (
                    <Stack verticalAlign='center' verticalFill horizontal tokens={{ childrenGap: 8 }}>
                        {item.officeCode && <Badge title={item.officeCode} />}
                        {item.officeName && <Text block nowrap style={{ maxWidth: item.officeCode ? 195 : 245 }}>{item.officeName}</Text>}
                    </Stack>
                )}
            </Stack>,
            isPadded: true,
        },
        {
            key: 'column5',
            name: '',
            fieldName: 'commandBar',
            minWidth: 40,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPerson) => (
                <Stack horizontal verticalFill verticalAlign='center'>
                    <Stack.Item>
                        <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item)} />
                    </Stack.Item>
                </Stack>
            )
        }
    ]

    const onSubmitHandler = (data: IPerson) => {
        setFormError(undefined)
        account?.data?.id ? updateAccount(data) : addAccount(data)
    }

    const onCancel = () => {
        hidePanel()
    }

    const onAddAccount = () => {
        stopFetch()
        setFormError(undefined)
        setAccount(account.data?.id ? { requested: true } : { ...account, requested: true })
        showPanel()
    }

    const addAccount = async (item: IPerson) => {
        startFetch()
        const result = await portalApi.organization.accounts.addAccount(item)
        console.log(result)
        if (result.successed && result.data) {
            setAccountsState({ ...accountsState, data: accountsState.data ? [...accountsState.data, result.data] : [result.data] })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onEditAccount = (item: IPerson) => {
        stopFetch()
        setFormError(undefined)
        setAccount({ data: item, requested: false })
        showPanel()
    }

    const updateAccount = async (item: IPerson) => {
        startFetch()
        const result = await portalApi.organization.accounts.updateAccount(item)
        if (result.successed && result.data) {
            console.log(result)
            setAccountsState({
                ...accountsState, data: accountsState.data && [...accountsState.data.map(p => {
                    if (p.id && p.id === result.data?.id) {
                        return result.data
                    }
                    return p
                })]
            })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onDeleteAccount = () => {
        if (deleteAccountState?.id) {
            deleteAccount(deleteAccountState.id)
            setDeleteAccountState(undefined)
        }
    }

    const deleteAccount = async (accountId: string) => {
        console.log('deletePerson ' + accountId)
        setAccountsState({ ...accountsState, error: undefined })
        const result = await portalApi.organization.accounts.deleteAccount(accountId)
        if (result.successed) {
            if (accountsState.data) {
                setAccountsState({
                    ...accountsState,
                    data: accountsState.data.filter(c => c.id !== accountId)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setAccountsState({ ...accountsState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setAccountsState({ ...accountsState, error: "There is a problem! Account wasn't deleted! Server response false without an error" });
        }
    }

    const resetPassword = async (newPassword: string) => {
        setResetAccountPasswordState({ ...resetAccountPasswordState, fetched: true })
        if (resetAccountPasswordState.data?.id) {
            const result = await portalApi.organization.accounts.resetPasswordAccount(resetAccountPasswordState.data?.id, newPassword)
            if (result.successed) {
                setResetAccountPasswordState({ data: undefined, fetched: false })
            } else {
                setResetAccountPasswordState({ ...resetAccountPasswordState, error: result.errors ? result.errors[0].description : '', fetched: false })
            }
        }
    }

    const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = {
                cursor: 'pointer',
                background: props.item['enabled'] ? undefined : 'rgb(253,231,233, 0.6)',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => history.push(`/organization/access/${props.item['id']}`)
            }><DetailsRow {...props} styles={customStyles} /></Stack>;
        }
        return null;
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }

        return (
            <Stack>
                <Stack.Item>
                    <Stack tokens={{ padding: "16px 32px 4px 20px", childrenGap: 4 }} horizontal>
                        <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                        <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                            <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{items.length}</Text>
                        </Stack>
                    </Stack>
                </Stack.Item>
                {defaultRender!({ ...hProps })}
            </Stack>
        );
    }

    return error && (
        <Stack verticalFill>
            {error.length > 0 && (
                <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
            )}
            <Stack.Item verticalFill>
                <SomethingWentWrong action={requestAccounts} />
            </Stack.Item>
        </Stack>
    ) || (
            !accountsState.fetched && (
                <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                    <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                </Stack>
            ) || (
                <>
                    {accountsState.data && accountsState.data.length > 0 && (
                        <Stack verticalFill>
                            <Stack verticalFill>
                                <ScreenHeader title={strings.NAVIGATION.ORGANIZATION.ACCESS}>
                                    <PrimaryButton onClick={onAddAccount} text={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ADD_PERSON} />
                                </ScreenHeader>
                                <Stack horizontalAlign='end' tokens={{ padding: '0px 32px 24px 0px' }}>
                                    <SimpleCustomFilter onChange={(ev, value) => setSearchString(value)} />
                                </Stack>
                                <Stack verticalFill style={{ position: 'relative' }}>
                                    <ScrollablePane>
                                        <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                            <Section max>
                                                <DetailsList
                                                    styles={{ root: { width: '100%' } }}
                                                    items={items}
                                                    columns={columns}
                                                    setKey="none"
                                                    selectionMode={SelectionMode.none}
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                                    isHeaderVisible={true}
                                                    onRenderRow={onRenderRow}
                                                />
                                            </Section>
                                        </Stack.Item>
                                    </ScrollablePane>
                                </Stack>
                            </Stack>
                        </Stack>
                    ) || (
                            <ItemsNotFound
                                info={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.NOT_FOUND_CASE.INFO}
                                suggestion={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.NOT_FOUND_CASE.SUGGESTION}
                                buttonText={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ADD_PERSON}
                                onClick={onAddAccount}
                                imgSrc="images/navigation/images/not_found.png"
                            />
                        )
                    }
                    <CustomPanel
                        isOpen={isPanelOpen}
                        onCancel={onCancel}
                        noCancelOnDissmiss={true}
                        title={account?.data?.id ? strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.EDIT_PERSON : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.ADD_PERSON}
                        description={account.data?.id ? account.data?.name : undefined}
                    >
                        <Stack verticalFill>
                            {formError && (
                                <MessageBar messageBarType={MessageBarType.error}>
                                    {formError}
                                </MessageBar>
                            )}
                            {(isPanelOpen && !fetching && account.requested) && (
                                <PersonForm
                                    onSubmit={onSubmitHandler}
                                    onCancel={onCancel}
                                    data={account.data}
                                    organizationDomain={organization?.domain}
                                    organizationCountry={organization?.region}
                                />
                            ) || (
                                    <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                        <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                                    </Stack>
                                )}
                        </Stack>
                    </CustomPanel>
                    {deleteAccountState && (
                        <Modal isOpen={deleteAccountState ? true : false} onDismiss={() => setDeleteAccountState(undefined)} containerClassName={contentStyles.container}>
                            <div className={contentStyles.header}>
                                <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TITLE}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Close"
                                    onClick={() => setDeleteAccountState(undefined)}
                                />
                            </div>
                            <div className={contentStyles.body}>
                                <ConfirmationForm
                                    onConfirm={onDeleteAccount}
                                    onCancel={() => setDeleteAccountState(undefined)}
                                    requiredMessageString={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_AREA.USERNAMES_DO_NOT_MATCH}
                                    confirmationCheckString={deleteAccountState?.name ?? ''}
                                    placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_PLACEHOLDER}
                                >
                                    <Stack tokens={{ childrenGap: 12 }}>
                                        <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TEXT1} <b>"{deleteAccountState.name}"</b>.</Text>
                                        <Text>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TEXT2}</Text>
                                        <Text unselectable="on">{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{deleteAccountState.name}"</b></Text>
                                    </Stack>
                                </ConfirmationForm>
                            </div>
                        </Modal>
                    )}
                    <Modal isOpen={resetAccountPasswordState.data ? true : false} onDismiss={() => setResetAccountPasswordState({ data: undefined, fetched: false })} containerClassName={contentStyles.container}>
                        <div className={contentStyles.header}>
                            <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.RESET_PASSWORD_TITLE}</span>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel="Close window"
                                onClick={() => setResetAccountPasswordState({ data: undefined, fetched: false })}
                            />
                        </div>
                        <div className={contentStyles.body}>
                            <Stack verticalFill tokens={{ childrenGap: 12 }}>
                                {resetAccountPasswordState.error && (
                                    <MessageBar messageBarType={MessageBarType.error}>{resetAccountPasswordState.error}</MessageBar>
                                )}
                                <ResetPasswordForm fetched={resetAccountPasswordState.fetched} onCancel={() => setResetAccountPasswordState({ data: undefined, fetched: false })} onSubmit={resetPassword} roles={account.data?.roles} />
                            </Stack>
                        </div>
                    </Modal>
                </>
            )
        )
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});