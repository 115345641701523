import { getTheme, Icon, SharedColors, Stack, Text } from "@fluentui/react";

type TServiceProps = {
    name: string
    description?: string
}

export const PrintTemplate: React.FC<TServiceProps> = (props) => {
    return (
        <Stack.Item>
            <Stack horizontal>
                <Stack horizontal verticalAlign='center' verticalFill style={{ width: 600 }} tokens={{ childrenGap: 12 }} >
                    <Stack.Item>
                        <Icon iconName="InsertSignatureLine" style={{ fontSize: 32, color: SharedColors.cyanBlue20 }} />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack>
                            <Stack.Item>
                                <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{props.name}</Text>
                            </Stack.Item>
                            {props.description && (
                                <Stack.Item>
                                    <Text variant="small" style={{ color: SharedColors.gray30 }}>{props.description}</Text>
                                </Stack.Item>
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack.Item>
    )
}

const theme = getTheme();