import { Stack, ScrollablePane, IDropdownOption, IChoiceGroupOption, ChoiceGroup } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { Section } from "../../../../../decorations/Section";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationScannerConfig } from "@piceasoft/core";
import { ScannerIdentificationTypes, ScannerUploadMethods, IIdentificationScannerConfigUI } from '@piceasoft/core';
import { Experience } from "../../../../helpers/evisibility";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const scannerUploadMethodsDescriber = (method: ScannerUploadMethods): string => {
    switch (method) {
        case ScannerUploadMethods.Remote: return strings.IDENTIFICATION.SCANNER.METHODS.REMOTE.PIVOT
        case ScannerUploadMethods.Camera: return strings.IDENTIFICATION.SCANNER.METHODS.CAMERA.PIVOT
        case ScannerUploadMethods.Upload: return strings.IDENTIFICATION.SCANNER.METHODS.UPLOAD.PIVOT
    }
}

export const ScannerCommonSettingsPivot: React.FC<TProps> = (props) => {

    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationScannerConfig)
    const channel = useSelector((s: IStore) => s.configurator.channel)

    const uploadMethodsOptions: IDropdownOption[] = [
        {
            key: ScannerUploadMethods.Remote,
            text: scannerUploadMethodsDescriber(ScannerUploadMethods.Remote)
        },
        {
            key: ScannerUploadMethods.Camera,
            text: scannerUploadMethodsDescriber(ScannerUploadMethods.Camera),
            disabled: channel === Experience.Mobile && config.type === ScannerIdentificationTypes.Imei
        },
        {
            key: ScannerUploadMethods.Upload,
            text: scannerUploadMethodsDescriber(ScannerUploadMethods.Upload)
        }
    ]

    const typesOptions: IChoiceGroupOption[] = [
        {
            key: ScannerIdentificationTypes.Label,
            text:  strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.TYPES.LABEL
        },
        {
            key: ScannerIdentificationTypes.Imei,
            text: strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.TYPES.IMEI
        }
    ]

    const getAppleWatchParameters = () => {
        if (config.type != ScannerIdentificationTypes.Label) {
            return null;
        }

        return (
            <>
                <ParameterItem
                    title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.APPLE_WATCH_CONFIRM_COLOR_TITLE}
                    useToggle
                    toggleEnabled={true}
                    toggleChecked={config.appleWatchConfirmColor === true}
                    toggleOnChange={() => props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        appleWatchConfirmColor: config.appleWatchConfirmColor ? false : true,
                    } as IIdentificationScannerConfig
                    ))}
                    description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.APPLE_WATCH_CONFIRM_COLOR_DESCRIPTION}
                />
                <ParameterItem
                    title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.APPLE_WATCH_CONFIRM_MATERIAL_TITLE}
                    useToggle
                    toggleEnabled={true}
                    toggleChecked={config.appleWatchConfirmMaterial === true}
                    toggleOnChange={() => props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        appleWatchConfirmMaterial: config.appleWatchConfirmMaterial ? false : true,
                    } as IIdentificationScannerConfig
                    ))}
                    description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.APPLE_WATCH_CONFIRM_MATERIAL_DESCRIPTION}
                />
            </>
        );
    }

    React.useEffect(() => {
        if (config.type != ScannerIdentificationTypes.Label) {
            props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    appleWatchConfirmColor: undefined,
                    appleWatchConfirmMaterial: undefined
                } as IIdentificationScannerConfig
            ));

            return;
        }

        props.onChangeDispatch(actionCreators.identification.editModule({
            ...config,
            appleWatchConfirmColor: config.appleWatchConfirmColor === undefined ? true : config.appleWatchConfirmColor,
            appleWatchConfirmMaterial: config.appleWatchConfirmMaterial === undefined ? true : config.appleWatchConfirmMaterial
        } as IIdentificationScannerConfig
    ));

    }, [config.type]);

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.COMMON.HEADER} max flat>
                    <ParameterItem
                        title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.TYPE_TITLE}
                        useToggle={false}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.TYPE_DESCRIPTION}
                    >
                        <ChoiceGroup
                            options={typesOptions}
                            selectedKey={config.type}
                            onChange={(ev, opt) => {
                                const newType = opt?.key;
                                let updatedMethods = config.uploadMethods;
                                if (channel === Experience.Mobile && newType === ScannerIdentificationTypes.Imei) {
                                    updatedMethods = updatedMethods.filter((method) => method !== ScannerUploadMethods.Camera);
                                }
                                if (updatedMethods.length === 0) {
                                    updatedMethods = [ScannerUploadMethods.Remote];
                                }
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    type: newType,
                                    uploadMethods: updatedMethods
                                } as IIdentificationScannerConfig));
                            }}
                        />
                    </ParameterItem>

                    <ParameterItem
                        title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.METHODS_TITLE}
                        useToggle={false}
                        useDropdown
                        dropdownEnabled
                        dropdownMultiselect
                        dropdownSelectedKeys={config.uploadMethods.filter((key) => key !== ScannerUploadMethods.Camera || (channel !== Experience.Mobile || config.type !== ScannerIdentificationTypes.Imei))}
                        dropdownOptions={uploadMethodsOptions}
                        dropdownOnChange={(opt) => opt && !opt.disabled && props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            uploadMethods: opt?.selected && !opt.disabled ? (
                                (config.uploadMethods.length > 0 ? [...config.uploadMethods, opt.key] : [opt.key])
                            ) : (config.uploadMethods.length > 1 ? config.uploadMethods?.filter(i => i !== opt?.key) : config.uploadMethods),
                            ui: {
                                ...config.ui,
                                uploadMethods: {
                                    remote: (opt.key === ScannerUploadMethods.Remote && !opt.selected) ? undefined : config.ui?.uploadMethods.remote,
                                    camera: (opt.key === ScannerUploadMethods.Camera && !opt.selected) ? undefined : config.ui?.uploadMethods.camera,
                                    upload: (opt.key === ScannerUploadMethods.Upload && !opt.selected) ? undefined : config.ui?.uploadMethods.upload
                                }
                            } as IIdentificationScannerConfigUI
                        } as IIdentificationScannerConfig
                        ))}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.METHODS_DESCRIPTION}
                    />

                    <ParameterItem
                        title={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.DEFAULT_METHOD_TITLE}
                        useToggle
                        toggleEnabled={config.uploadMethods.length > 0}
                        toggleChecked={config.defaultUploadMethod !== undefined}
                        toggleOnChange={() => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            defaultUploadMethod: config.defaultUploadMethod !== undefined ? undefined : config.uploadMethods[0],
                        } as IIdentificationScannerConfig
                        ))}
                        useDropdown
                        dropdownEnabled={config.defaultUploadMethod !== undefined}
                        dropdownSelectedKey={config.defaultUploadMethod ?? ""}
                        dropdownOptions={config.uploadMethods.map(i => ({ key: i, text: scannerUploadMethodsDescriber(i) })) ?? []}
                        dropdownOnChange={(opt) => opt && props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            defaultUploadMethod: opt?.key,
                        } as IIdentificationScannerConfig
                        ))}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SCANNER.PIVOTS.COMMON.DEFAULT_METHOD_DESCRIPTION}
                    />
                    {getAppleWatchParameters()}
                </Section>
            </ScrollablePane>
        </Stack.Item >
    )
}