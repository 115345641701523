import { Stack, TextField, DefaultButton, PrimaryButton, ScrollablePane } from '@fluentui/react';
import { getTheme } from '@fluentui/style-utilities';
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ICompany } from '../../../core/store'
import { strings } from '../../../localization/strings';
import { SecondaryButton } from '../buttons/SecondaryButton'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { CSHHelpLink } from '../help/CSHHelp';

type TProps = {
    onSubmit: (company: ICompany) => void
    onCancel: () => void
    data?: ICompany
}

export const CompanyForm: React.FC<TProps> = (props) => {
    const { control, handleSubmit, formState: { errors } } = useForm<ICompany>({ defaultValues: { ...props.data } });
    const onSubmit: SubmitHandler<ICompany> = data => {
        props.onSubmit(data)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ 
                                    required: strings.ORGANIZATION.COMPANIES.COMPANY.NAME_REQUIRED_MESSAGE,
                                    validate: (value) => value?.trim().length > 0 ? true : strings.ORGANIZATION.COMPANIES.COMPANY.NAME_REQUIRED_MESSAGE,
                                }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.COMPANIES.COMPANY.NAME}
                                        title={strings.ORGANIZATION.COMPANIES.COMPANY.NAME}
                                        placeholder={strings.ORGANIZATION.COMPANIES.COMPANY.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                name="itn"
                                control={control}
                                rules={{ 
                                    required: strings.ORGANIZATION.COMPANIES.COMPANY.ITN_REQUIRED_MESSAGE,
                                    validate: (value) => value?.trim().length > 0 ? true : strings.ORGANIZATION.COMPANIES.COMPANY.ITN_REQUIRED_MESSAGE,
                                }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.COMPANIES.COMPANY.ITN}
                                        title={strings.ORGANIZATION.COMPANIES.COMPANY.ITN}
                                        placeholder={strings.ORGANIZATION.COMPANIES.COMPANY.ITN_PLACEHOLDER}
                                        errorMessage={errors.itn?.message}
                                    />
                                }
                            />
                            <Controller
                                name="psrn"
                                control={control}
                                rules={{ 
                                    required: strings.ORGANIZATION.COMPANIES.COMPANY.PSRN_REQUIRED_MESSAGE,
                                    validate: (value) => value?.trim().length > 0 ? true : strings.ORGANIZATION.COMPANIES.COMPANY.PSRN_REQUIRED_MESSAGE,
                                 }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.COMPANIES.COMPANY.PSRN}
                                        title={strings.ORGANIZATION.COMPANIES.COMPANY.PSRN}
                                        placeholder={strings.ORGANIZATION.COMPANIES.COMPANY.PSRN_PLACEHOLDER}
                                        errorMessage={errors.psrn?.message}
                                    />
                                }
                            />
                            {/* <Controller
                                name="iec"
                                control={control}
                                // rules={{ required: strings.ORGANIZATION.COMPANIES.COMPANY.IEC_REQUIRED_MESSAGE }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.COMPANIES.COMPANY.IEC}
                                        title={strings.ORGANIZATION.COMPANIES.COMPANY.IEC}
                                        placeholder={strings.ORGANIZATION.COMPANIES.COMPANY.IEC_PLACEHOLDER}
                                        errorMessage={errors.iec?.message}
                                    />
                                }
                            /> */}
                            <Controller
                                name="manager"
                                control={control}
                                rules={{ 
                                    required: strings.ORGANIZATION.COMPANIES.COMPANY.MANAGER_REQUIRED_MESSAGE,
                                    validate: (value) => value?.trim().length > 0 ? true : strings.ORGANIZATION.COMPANIES.COMPANY.MANAGER_REQUIRED_MESSAGE,
                                }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.COMPANIES.COMPANY.MANAGER}
                                        title={strings.ORGANIZATION.COMPANIES.COMPANY.MANAGER}
                                        placeholder={strings.ORGANIZATION.COMPANIES.COMPANY.MANAGER_PLACEHOLDER}
                                        errorMessage={errors.manager?.message}
                                    />
                                }
                            />
                            <Controller
                                name="address"
                                control={control}
                                rules={{ 
                                    required: strings.ORGANIZATION.COMPANIES.COMPANY.ADDRESS_REQUIRED_MESSAGE,
                                    validate: (value) => value?.trim().length > 0 ? true : strings.ORGANIZATION.COMPANIES.COMPANY.ADDRESS_REQUIRED_MESSAGE,
                                }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        required    
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.COMPANIES.COMPANY.ADDRESS}
                                        title={strings.ORGANIZATION.COMPANIES.COMPANY.ADDRESS}
                                        placeholder={strings.ORGANIZATION.COMPANIES.COMPANY.ADDRESS_PLACEHOLDER}
                                        errorMessage={errors.address?.message}
                                    />
                                }
                            />
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink alignment='left' />
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();
