import { getTheme, Separator, Stack, Text } from "@fluentui/react"
import React from "react"
import { ICatalog, IStore } from "../../../../core/store"
import { strings } from "../../../../localization/strings"
import { Section } from "../../../../components/shared/decorations/Section"
import { RemoveArea } from "../../../../components/shared/decorations/RemoveArea"
import { CompanyService } from "../../../../components/shared/companyService/CompanyService"
import { PropertyValue } from "../../../../components/shared/decorations/PropertyValue"
import { useSelector } from "react-redux"

type TFinanceCatalogInfoPivotProps = {
    data: ICatalog
    onDelete: (catalog: ICatalog) => void
}

export const FinanceCatalogInfoPivot: React.FC<TFinanceCatalogInfoPivotProps> = (props) => {

    const workflowIconGeneration = useSelector((s: IStore) => s.workplace.organization?.workflowIconGeneration)

    return (
        <Stack tokens={{ padding: "24px 32px 24px 32px", childrenGap: 16 }}>
            <Section title={strings.ORGANIZATION.CATALOGS.CATALOG.CATALOG_DATA}>
                <Stack tokens={{ childrenGap: 12 }}>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.NAME} value={props.data.name} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.SUMMARY} value={props.data.summary} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.CATALOGS.VERSIONS.TITLE} value={"–"} />
                        <PropertyValue label={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.SUMMARY} value={"–"} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.FREE} value={props.data.isFree ? strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.FREE_ON : strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.FREE_OFF} />
                    </Stack>
                </Stack>
            </Section>
            {props.data.services && props.data.services.length > 0 && (
                <>
                    <Stack tokens={{ padding: '12px 0px 0px 0px', childrenGap: 0 }}>
                        <Text variant="large" style={{ color: theme.palette.black, fontWeight: 600, letterSpacing: "-.02em", lineHeight: "24px" }}>{strings.ORGANIZATION.CATALOGS.CATALOG.USE_BY_SERVICES}</Text>
                        <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 16 }}>
                        {props.data.services?.map(s => <CompanyService imageSrc={s.imageSrc} withoutToggle name={s.name} description={s.description} onToggleServiceState={() => {}} generateIcon={workflowIconGeneration}  />)}
                    </Stack>
                </>
            )}
            <Stack.Item>
                <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
            </Stack.Item>
            <Stack.Item >
                <Stack tokens={{ childrenGap: 8 }}>
                    <RemoveArea
                        href="1112"
                        hrefText={strings.ORGANIZATION.CATALOGS.REMOVE.INFO_MORE_HELP}
                        onDelete={() => props.onDelete(props.data)}
                        text={strings.ORGANIZATION.CATALOGS.REMOVE.INFO_TEXT}
                        title={strings.ORGANIZATION.CATALOGS.REMOVE.TITLE}
                    />
                </Stack>
            </Stack.Item>
        </Stack >
    )
}

const theme = getTheme();
