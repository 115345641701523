import { SharedColors, Stack, Text } from "@fluentui/react"
import { PropertyValue } from "../../../../../../components/shared/decorations/PropertyValue"
import { Section } from "../../../../../../components/shared/decorations/Section"
import { IContainer } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"

type TInfoPivotProps = {
    item: IContainer
}

export const ContainerInfoPivot: React.FC<TInfoPivotProps> = (props) => {
    return (
        <Section title={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.PIVOTS.INFORMATION.TITLE}>
            <Stack tokens={{ childrenGap: 12 }}>
                <Stack tokens={{ childrenGap: 6 }}>
                    <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.CODE} value={props.item.code} />
                    <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.NAME} value={props.item.name} />
                </Stack>
                <Stack tokens={{ childrenGap: 6 }}>
                    <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.DESCRIPTION} value={props.item.description} />
                </Stack>
            </Stack>
        </Section>
    )
}
