import { Stack, Separator, Text, getTheme, SharedColors, Link } from "@fluentui/react"
import { CustomSeparator } from "../../../../../components/shared/decorations/CustomSeparator";
import { Section } from "../../../../../components/shared/decorations/Section";
import { strings } from "../../../../../localization/strings";

export type TCompanyIntegrationPivotProps = {
}

export const CompanyIntegrationPivot: React.FC<TCompanyIntegrationPivotProps> = (props) => {
    return (
        <Stack tokens={{ padding: "24px 32px 0px 32px", childrenGap: 16 }}>
            <Section title={strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INTEGRATION_CONTENT.HEADER}>
                {/* Настраиваем статус данной организации и описываем его */}
                <Text>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INTEGRATION_CONTENT.INFORMATION}</Text>
                <br />
                <Stack horizontal tokens={{childrenGap: 4}}>
                <Text>
                    {strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INTEGRATION_CONTENT.OPTIONS.DEAL_DONE}
                </Text>
                <Text>
                    {strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INTEGRATION_CONTENT.OPTIONS.DEAL_CANCEL}
                </Text>
                </Stack>
                



            </Section>
            <CustomSeparator />
            <Stack tokens={{ childrenGap: 8 }}>
                <Stack.Item>
                    <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INTEGRATION_CONTENT.INFO.HEADER}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Text style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INTEGRATION_CONTENT.INFO.DESCRIPTION}</Text>
                    <br />
                    <Link href={"#"}>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.INTEGRATION_CONTENT.INFO.LINK}</Link>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();
