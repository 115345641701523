import { Stack, SharedColors, mergeStyleSets, FontIcon, Text, TooltipHost, TooltipOverflowMode, ITooltipHostStyles, css } from "@fluentui/react"
import { IManualGradingDefaultConfig } from "@piceasoft/core"

type TStateProps = {
    config: IManualGradingDefaultConfig
    description: string
    icon?: string
}

export const ManualGradesState: React.FC<TStateProps> = (props) => {
    return <None description={props.config.ui?.description} defaultDescription={props.description} icon={props.icon} />
}


type TNoneStateProps = {
    description?: string
    defaultDescription: string
    icon?: string
}

const None: React.FC<TNoneStateProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 24 }}>
            <Stack.Item style={{ color: SharedColors.gray40 }}>
                <TooltipHost
                    overflowMode={TooltipOverflowMode.Self}
                    content={props.description ?? props.defaultDescription}
                    styles={tooltipStyles}
                    hostClassName={css(styles.title)}
                >
                    {props.description ?? props.defaultDescription}
                </TooltipHost>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontalAlign="center">
                    <FontIcon iconName={props.icon ?? "Trackers"} style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray20 }} />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const styles = mergeStyleSets({
    title: [
        {
            overflow: 'hidden',
            overflowWrap: "break-word",
            maxHeight: 60,
        }
    ]
}) 

const tooltipStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'block' }
}