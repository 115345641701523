import React, { Dispatch } from "react"
import { TStageSettingsPivotStates } from "../../../Configurator"
import { StageCommonButtonsSettingsPivot } from "../../settings/StageCommonButtonsSettingsPivot"
import { PreOfferStageAdditionalSettingsPivot } from "./PreOfferStageAdditionalSettingsPivot"
import { PreOfferStageCommonUIPivot } from "./PreOfferStageCommonUIPivot"
import { IStageConfig, ProcessStages, IPreOfferConfig, IStageConfigUI } from "@piceasoft/core"

type TProps = {
    state: TStageSettingsPivotStates
    onChangeDispatch: Dispatch<any>
    config?: IPreOfferConfig
    stage: ProcessStages
    pivots?: { name: string, component: React.ReactNode }[]
    secondPivot?: string
    channel: number
}

export const PreOfferStageSettingsPivotBasedState: React.FC<TProps> = (props) => {
    switch (props.state) {
        case "common_buttons": return (
            <StageCommonButtonsSettingsPivot channel={props.channel } config={props.config as IStageConfig<IStageConfigUI>} onChangeDispatch={props.onChangeDispatch} stage={props.stage} />
        )
        case "additional": return <PreOfferStageAdditionalSettingsPivot channel={props.channel} onChangeDispatch={props.onChangeDispatch} config={props.config} />
        case "common_ui": return <PreOfferStageCommonUIPivot channel={props.channel}  onChangeDispatch={props.onChangeDispatch} config={props.config} selectedPivot={props.secondPivot} />
        default:
            return <>{props.pivots?.find(i => i.name === props.state)?.component}</>
    }
}
