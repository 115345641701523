import { Stack, ScrollablePane, getTheme, Checkbox, FontIcon, SharedColors, TooltipHost } from "@fluentui/react"
import React, { Dispatch, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { actionCreators, getStageInspectionModules } from "../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../core/store";
import { getLocalizedInspectionName } from "../../../../../../localization/helpers/transaction";
import { strings } from "../../../../../../localization/strings";
import { Section } from "../../../../decorations/Section";
import { ParameterItem } from "../../../components/ParameterItem";
import { ProcessStages } from "@piceasoft/core";

type TProps = {
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
}


export const DependencyPivot: React.FC<TProps> = (props) => {

    const [disabledButton, setDisabledButton] = useState<boolean[]>();
    const modules = useSelector((s: IStore) => getStageInspectionModules(props.stage, s))
    const config = modules?.find(i => i.index === props.index)?.config
    if (!config) return null


    const hasCycle = (graph: { [key: number]: number[] }): boolean => {
    const visited: { [key: number]: boolean } = {};
    const recStack: { [key: number]: boolean } = {};

    const isCyclic = (node: number): boolean => {
        if (!visited[node]) {
            visited[node] = true;
            recStack[node] = true;

            for (const neighbor of graph[node]) {
                if (!visited[neighbor] && isCyclic(neighbor)) {
                    return true;
                } else if (recStack[neighbor]) {
                    return true;
                }
            }
        }
        recStack[node] = false;
        return false;
    };
    for (const node in graph) {
        if (!visited[node] && isCyclic(Number(node))) {
            return true;
        }
    }
    return false;
    };


    const validateInspections = (modulePosition: number): boolean[] => {
    const moduleDependencies: (number[] | undefined)[] = [];
    modules.forEach((module) => {
        moduleDependencies.push(module.config.dependencyInspections);
    });

    const graph: { [key: number]: number[] } = {};
    moduleDependencies.forEach((dependencies, moduleIndex) => {
        graph[moduleIndex + 1] = dependencies || [];
    });

    const hasCircularDependency = hasCycle(graph);

    const filteredDependencies = moduleDependencies.filter((_, index) => index !== modulePosition - 1);

    const moduleRules: boolean[] = filteredDependencies.map((dependencies, moduleIndex) => {
        if (hasCircularDependency) {
            return true; 
        }
        const updatedGraph = { ...graph };
        updatedGraph[modulePosition] = dependencies || [];
        if (hasCycle(updatedGraph)) {
            return true;
        }
        return false;
    });

    return moduleRules;
    };
      
    
    useMemo(() => {
        setDisabledButton(validateInspections(props.index));
    }, [props.index])
        
        return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.DEPENDENCY_INSPECTIONS.HEADER} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.DEPENDENCY_INSPECTIONS.TITLE}
                        useToggle
                        toggleEnabled={true}
                        toggleChecked={config.dependencyInspections ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage, props.index, {
                                ...config,
                                dependencyInspections: config.dependencyInspections ? undefined : []
                            }
                            ))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.DEPENDENCY_INSPECTIONS.DESCRIPTION}
                    >
                        {config.dependencyInspections && modules?.filter(i => i.index !== props.index).map((m, index) => {
                            return (
                                <div key={index}>
                                    <Checkbox key={m.index} 
                                    disabled={disabledButton  && config.dependencyInspections?.includes(m.index) ? false : validateInspections(props.index)[index]} 
                                    checkmarkIconProps={ disabledButton && disabledButton[index] ? undefined : warningIconStyle }
                                    onRenderLabel={() => disabledButton && disabledButton[index] ? ( 
                                    <div style={{display: 'flex'}}>
                                        <div style={{paddingTop: '3px'}}>
                                        {m.index} {m.config.ui?.title ?? getLocalizedInspectionName(m.type)}
                                        </div>
                                        <TooltipHost content={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.DEPENDENCY_INSPECTIONS.WARNING}>
                                            <FontIcon iconName="Warning" style={warningIconStyle}/>
                                        </TooltipHost>
                                    </div> ) : 
                                    <div>
                                     {m.index} {m.config.ui?.title ?? getLocalizedInspectionName(m.type)}   
                                    </div>}
                                    checked={config.dependencyInspections?.includes(m.index)}
                                    onChange={(ev, checked) =>
                                        props.onChangeDispatch(actionCreators.inspections.editInspection(props.stage,props.index, {
                                            ...config,
                                            dependencyInspections: checked ? [...(config.dependencyInspections?.filter(i => i !== m.index) ?? []), m.index] : config.dependencyInspections?.filter(i => i !== m.index)
                                        }))
                                    } 
                                    />
                                </div>
                                
                            )
                        })}
                    </ParameterItem>
                </Section>
            </ScrollablePane>
        </Stack.Item>
    )
}

const warningIconStyle = {
    fontSize: '20px',
    height:  '20px',
    width: '20px',
    color: SharedColors.yellow10,
    marginRight: '5px',
    marginLeft: '5px',
    paddingBottom: '5px'
};