import {
    CommandBarButton, getTheme, IContextualMenuItemProps, IContextualMenuItemRenderFunctions,
    IContextualMenuProps, MessageBar, MessageBarType, SharedColors, Stack, Text, TooltipHost
} from "@fluentui/react"
import { Dispatch } from "react";
import { actionCreators } from "../../../../core/actions/configurator-actions";
import { IControlConfig, SoftwareModes, Inspections, ProcessStages } from '@piceasoft/core';
import { DataCollectionModes } from "@piceasoft/core";
import { EraseModes } from "@piceasoft/core";
import { getLocalizedDataCollectionModeName, getLocalizedInspectionName, getLocalizedSoftwareModeName } from "../../../../localization/helpers/transaction";
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";
import { InspectionItem } from "./common/InspectionItem";

type TProps = {
    config: IControlConfig
    setInspectionParams: (index: number, pivot: string) => void
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
}

export const getInspectionIcon = (method: Inspections): string => {
    switch (method) {
        case Inspections.Software: return "BlockedSite"
        case Inspections.DataCollection: return "PageEdit"
        case Inspections.Erase: return "EraseTool"
        default: return ""
    }
}

export const Control: React.FC<TProps> = (props) => {

    const newInspectionMenuProps: IContextualMenuProps = {
        items: [
            {
                key: Inspections.Software.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.Software), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.Software),
                subMenuProps: {
                    items: [
                        {
                            key: SoftwareModes.Piceasoft.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.software.addSoftware(ProcessStages.Control, SoftwareModes.Piceasoft)) },
                            text: getLocalizedSoftwareModeName(SoftwareModes.Piceasoft),
                        },
                    ]
                }
            },
            {
                key: Inspections.DataCollection.toString(),
                iconProps: { iconName: getInspectionIcon(Inspections.DataCollection), style: { color: theme.palette.black, fontSize: 14 } },
                text: getLocalizedInspectionName(Inspections.DataCollection),
                subMenuProps: {
                    items: [
                        {
                            key: DataCollectionModes.Standard.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.dataCollection.addDataCollection(ProcessStages.Control, DataCollectionModes.Standard)) },
                            text: getLocalizedDataCollectionModeName(DataCollectionModes.Standard),
                            onRenderContent: (props: IContextualMenuItemProps, defaultRenders: IContextualMenuItemRenderFunctions) => (
                                <TooltipHost content={strings.CONSTRUCTOR.COMMON.IS_EXPERIMENTAL}>
                                    <Text style={{ color: SharedColors.red10, paddingLeft: 4 }}>{props.item.text + " " + "*"}</Text>
                                </TooltipHost>
                            )
                        },
                    ]
                }
            },
            {
                key: strings.TRANSACTION.INSPECTIONS.TOOLS.TITLE,
                iconProps: { iconName: getInspectionIcon(Inspections.Erase), style: { color: theme.palette.black, fontSize: 14 } },
                text: strings.TRANSACTION.INSPECTIONS.TOOLS.TITLE,
                subMenuProps: {
                    items: [
                        {
                            key: EraseModes.Default.toString(),
                            onClick: () => { props.onChangeDispatch(actionCreators.inspections.erase.addErase(ProcessStages.Control, EraseModes.Default)) },
                            text: getLocalizedInspectionName(Inspections.Erase),
                            onRenderContent: (props: IContextualMenuItemProps, defaultRenders: IContextualMenuItemRenderFunctions) => (
                                <TooltipHost content={strings.CONSTRUCTOR.COMMON.IS_EXPERIMENTAL}>
                                    <Text style={{ color: SharedColors.red10, paddingLeft: 4 }}>{props.item.text + " " + "*"}</Text>
                                </TooltipHost>
                            )
                        },
                    ]
                }
            }
        ]
    }

    const commands = () => (
        <>
            <CommandBarButton iconProps={{ iconName: "Add" }} menuProps={newInspectionMenuProps} text={strings.BUTTONS.TEXT.ADD} disabled={false} />
        </>
    )

    return (
        <StageBox title={strings.CONSTRUCTOR.STAGES.CONTROL.TITLE} onCommandRender={commands} settingsButtonProps={{ onClick: props.showSettings }} width={480}>
            <Stack tokens={{ childrenGap: 0 }}>
                {props.config?.modules?.sort((a, b) => a.index - b.index).map((i, localIndex) => {
                    if (i) {
                        return (
                            <InspectionItem
                            key={`${localIndex}-${i.index}-${i.type}`}
                            stage={ProcessStages.Control}
                            onChangeDispatch={props.onChangeDispatch}
                            inspection={i}
                            icon={i.config.ui?.icon ?? getInspectionIcon(i.type)}
                            setInspectionParams={props.setInspectionParams}
                            isExperimental={[Inspections.Erase, Inspections.DataCollection, Inspections.AIGrading].includes(i.type)}
                            />
                        )
                    }
                })}
            </Stack>
            {props.config?.modules?.length === 0 && (
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <MessageBar isMultiline messageBarType={MessageBarType.severeWarning}>
                        <b>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.WARNING_EMPTY_CONTROL}</b>
                    </MessageBar>
                </Stack>
            )}
        </StageBox>
    )
}

const theme = getTheme();
