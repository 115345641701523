import { DirectionalHint, getTheme, Icon, IPivotItemProps, IPivotStyleProps, IPivotStyles, IRenderFunction, IStyleFunctionOrObject, Pivot, PivotItem, SharedColors, Stack, TooltipHost, Text } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import React from "react"
import { strings } from "../../../../../localization/strings"

export const StagePreviewWrapper: React.FC<{}> = (props) => {

    const [isPreviewStageExpanded, { toggle: toggleIsPreviewStageExpanded }] = useBoolean(false)
    const expandItem = React.createRef<HTMLDivElement>();

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 14px 12px 12px" }}>
                        <Stack.Item>
                            <Icon iconName={iProps?.itemIcon} style={{ fontSize: 10, display: "flex", color: theme.palette.white }} />
                        </Stack.Item>
                        <Stack.Item>
                            <Text style={{ color: theme.palette.white }}>{strings.CONSTRUCTOR.COMMON.PREVIEW}</Text>
                        </Stack.Item>
                    </Stack>
                </TooltipHost>
            </span>
        )
    }

    React.useEffect(() => {
        if (expandItem.current) {
            if (isPreviewStageExpanded) {
                expandItem.current.style.height = `${expandItem.current.scrollHeight}px`
            } else {
                expandItem.current.style.height = `${expandItem.current.scrollHeight}px`;
                window.getComputedStyle(expandItem.current, null).getPropertyValue("height");
                expandItem.current.style.height = "0";
            }
        }
    }, [isPreviewStageExpanded])

    return (
        <Stack>
            <Stack verticalAlign="center" horizontalAlign="end">
                <Pivot onLinkClick={toggleIsPreviewStageExpanded} styles={pivotStyles}>
                    <PivotItem itemIcon={isPreviewStageExpanded ? 'ChevronDown' : 'ChevronUp'} onRenderItemLink={onRenderItemLink} />
                </Pivot>
            </Stack>
            <div ref={expandItem} style={{ height: "0px", transition: 'height 600ms ease', overflow: 'hidden', padding: 0 }}>
                {props.children}
            </div>
        </Stack>
    )
}

const theme = getTheme()

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: 'rgb(0, 120, 212)',
        selectors: {
            '&:hover': {
                backgroundColor: 'rgb(16, 110, 190)',
            },
            '&:active': {
                backgroundColor: 'rgb(0, 90, 158)',
            }
        },
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 24,
        padding: 0,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}