import { strings } from '../../localization/strings';
import {
    getTheme, IconButton, IStackStyles,
    Modal, Spinner, Stack, Text, Image, SearchBox, FontWeights,
    mergeStyleSets, MessageBar, MessageBarType, ScrollablePane, DefaultButton
} from '@fluentui/react';
import * as React from 'react';
import { IStore } from '../../core/store';
import { useSelector } from 'react-redux';
import { useBoolean } from '@uifabric/react-hooks';
import { portalApi } from '../../core/api/api';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { IPromotion } from '../../core/store/typings/IPromotion';
import { FinancePromotion } from './Promotions/FinancePromotion';
import { ItemsList } from '../../components/shared/lists/ItemsList';
import { PromotionForm } from '../../components/shared/forms/PromotionForm';

export const FinancePromotions: React.FC = () => {

    const organization = useSelector((s: IStore) => s.workplace.organization)

    const [promotions, setPromotions] = React.useState<{ fetched: boolean, data?: IPromotion[] }>({ fetched: false })
    const [error, setError] = React.useState<string>()
    const [panelError, setPanelError] = React.useState<string>()

    const [selectedPromotionId, setSelectedPromotionId] = React.useState<string>()

    const [promotion, setPromotion] = React.useState<{ fetched: boolean, data?: IPromotion }>({ fetched: false })

    const [panelPromotion, setPanelPromotion] = React.useState<{ fetched: boolean, data?: IPromotion }>({ fetched: false })
    const [deletePromotionState, setDeletePromotionState] = React.useState<IPromotion>()

    const [isPromotionPanelVisible, { setTrue: showPromotionPanel, setFalse: hidePromotionPanel }] = useBoolean(false)
    const [isDeleteConfirmBoxVisible, { setTrue: showDeleteConfirmBox, setFalse: hideDeleteConfirmBox }] = useBoolean(false)

    const [filteredPromotions, setFilteredPromotions] = React.useState<IPromotion[]>()
    const [filterString, setFilterString] = React.useState<string>()

    React.useEffect(() => {
        getPromotions()
    }, [])

    React.useEffect(() => {
        console.log(promotions)
        if (promotions.fetched && promotions.data && promotions.data.length > 0) {
            setSelectedPromotionId(promotions.data[0].id)
        }
    }, [promotions])

    React.useEffect(() => {
        getPromotion()
    }, [selectedPromotionId])

    React.useEffect(() => {
        setFilteredPromotions(() => {
            var newItems = promotions.data ? [...promotions.data] : []
            if (filterString) {
                newItems = [
                    ...((newItems as IPromotion[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.summary,
                            i.name,
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${filterString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as IPromotion[]
        })
    }, [filterString, promotions.data])

    const getPromotions = async () => {
        setError(undefined)
        const result = await portalApi.organization.promotions.getPromotions()
        console.log(result)
        if (result.successed) {
            setPromotions({ fetched: true, data: result.data?.filter(i => !i.removed) })
        }
        if (result.errors) {
            setError(result.errors[0].description ?? "Promotions fetch error")
        }
    }

    const getPromotion = async () => {
        setError(undefined)
        if (selectedPromotionId) {
            setPromotion({ fetched: false })
            const result = await portalApi.organization.promotions.getPromotion(selectedPromotionId)
            if (result.successed) {
                setPromotion({ fetched: true, data: result.data })
            }
            if (result.errors) {
                setError(result.errors[0].description ?? "Promotion fetch error")
            }
        }
    }

    const onAddPromotion = () => {
        setPanelPromotion({ fetched: true })
        showPromotionPanel()
    }

    const addPromotion = async (item: IPromotion) => {
        const result = await portalApi.organization.promotions.addPromotion(item)
        if (result.successed && result.data) {
            setPromotion({ fetched: true, data: result.data })
            setPromotions({ ...promotions, data: promotions.data ? [result.data, ...promotions.data] : [result.data] })
            hidePromotionPanel()
        }
        if (result.errors) {
            setError(result.errors[0].description ?? "Promotion fetch error")
        }
    }

    const onEditPromotion = (item: IPromotion) => {
        setSelectedPromotionId(item.id)
        setPanelPromotion({ fetched: true, data: item })
        showPromotionPanel()
    }

    const editPromotion = async (item: IPromotion) => {
        const result = await portalApi.organization.promotions.updatePromotion(item)
        if (result.successed && result.data) {
            setPromotion({ fetched: true, data: result.data })
            setPromotions({
                ...promotions, data: promotions.data ? [...promotions.data.map(i => {
                    if (i.id === result.data?.id) {
                        return result.data
                    }
                    return i
                })] : [result.data]
            })
            hidePromotionPanel()
        }
        if (result.errors) {
            setError(result.errors[0].description ?? "Promotion fetch error")
        }
    }


    const deletePromotion = async () => {
        if (deletePromotionState?.id) {
            const result = await portalApi.organization.promotions.deletePromotion(deletePromotionState?.id)
            if (result.successed) {
                setPromotion({ fetched: false })
                setPromotions({ ...promotions, data: promotions.data?.filter(i => i.id !== deletePromotionState.id) })
                setDeletePromotionState(undefined)
            }
            if (result.errors) {
                setError(result.errors[0].description ?? "Promotion delete error")
            }
        }
    }

    return (
        <Stack verticalFill>
            {!error && (promotions.fetched && organization?.name && (
                <Stack.Item verticalFill>
                    {(promotions.data && promotions.data.length > 0) && (
                        <Stack verticalFill>
                            <Stack horizontal grow verticalFill verticalAlign="start" >
                                <Stack.Item verticalFill style={{ minWidth: 300 }}>
                                    <Stack verticalFill>
                                        <Stack.Item>
                                            <Stack horizontal grow verticalAlign='center' tokens={{ padding: '8px 8px 0px 20px' }}>
                                                <Stack.Item grow>
                                                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                        <Image height={16} src="images/navigation/icons/workflow.svg" />
                                                        <Text variant='large' style={{ fontWeight: 500 }}>{organization?.name}</Text>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <IconButton menuIconProps={{ style: { fontSize: 16, color: theme.palette.black }, iconName: "Add" }} onClick={onAddPromotion} />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack tokens={{ padding: 8 }}>
                                                <SearchBox onChange={(ev, value) => setFilterString(value)} />
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item verticalFill style={{ position: 'relative' }}>
                                            <ScrollablePane>
                                                <ItemsList
                                                    items={filteredPromotions?.map(i => { return { isSelected: i.id === selectedPromotionId, data: i } }) ?? promotions.data.map(i => { return { isSelected: i.id === selectedPromotionId, data: i } }) ?? []}
                                                    selectedKey={selectedPromotionId ?? ''}
                                                    setSelectedKey={setSelectedPromotionId}
                                                    onDelete={setDeletePromotionState}
                                                    onEdit={onEditPromotion}
                                                    getItemId={(promotion: IPromotion) => promotion.id} 
                                                    />
                                            </ScrollablePane>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <Stack verticalFill style={{ width: 1, borderRight: `1px solid ${theme.palette.neutralQuaternary}` }} />
                                <Stack verticalFill grow>
                                    {!promotion?.fetched && (
                                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                                            {error && <DefaultButton text={strings.BUTTONS.TEXT.TRY_AGAIN} onClick={getPromotion} />}
                                        </Stack>
                                    ) || (promotion?.fetched && (promotion?.data && (
                                        <FinancePromotion data={promotion.data} retryInfo={getPromotion} onEdit={onEditPromotion} onDelete={setDeletePromotionState} />
                                    ))
                                        )}
                                </Stack>
                            </Stack>
                        </Stack>
                    ) || (
                            <ItemsNotFound
                                info={strings.ORGANIZATION.PROMOTIONS.NOT_FOUND_CASE.INFO}
                                onClick={onAddPromotion}
                                buttonText={strings.ORGANIZATION.PROMOTIONS.NOT_FOUND_CASE.ADD_BUTTON}
                                suggestion={strings.ORGANIZATION.PROMOTIONS.NOT_FOUND_CASE.SUGGESTION}
                                imgSrc={"images/navigation/images/startup.png"}
                            />
                        )}
                    <CustomPanel
                        isOpen={isPromotionPanelVisible}
                        onCancel={hidePromotionPanel}
                        title={panelPromotion.data?.id ? strings.ORGANIZATION.PROMOTIONS.MENU.EDIT : strings.ORGANIZATION.PROMOTIONS.MENU.ADD}
                    >
                        {panelError && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                {panelError}
                            </MessageBar>
                        )}
                        {panelPromotion.fetched && organization?.id && (
                            <PromotionForm
                                data={panelPromotion.data}
                                onSubmit={(data: IPromotion) => data.id ? editPromotion(data) : addPromotion(data)}
                                onCancel={hidePromotionPanel}
                                organizationId={organization?.id}
                            />
                        ) || (
                                <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                    <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                                </Stack>
                            )}
                    </CustomPanel>
                    {deletePromotionState && (
                        <Modal isOpen={deletePromotionState ? true : false} onDismiss={() => setDeletePromotionState(undefined)} containerClassName={contentStyles.container}>
                            <div className={contentStyles.header}>
                                <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.PROMOTIONS.REMOVE.CONFIRMATION_TITLE}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: "Cancel" }}
                                    ariaLabel="Close"
                                    onClick={() => setDeletePromotionState(undefined)}
                                />
                            </div>
                            <div className={contentStyles.body}>
                                <ConfirmationForm
                                    onConfirm={deletePromotion}
                                    onCancel={() => setDeletePromotionState(undefined)}
                                    requiredMessageString={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.NAME_REQUIRED}
                                    confirmationCheckString={deletePromotionState?.name}
                                    placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.NAME_PLACEHOLDER}
                                    confirmText={strings.BUTTONS.TEXT.DELETE}
                                >
                                    <Stack tokens={{ childrenGap: 12 }}>
                                        <Text>{strings.ORGANIZATION.PROMOTIONS.REMOVE.CONFIRMATION_TEXT1}</Text>
                                        <Text>{strings.ORGANIZATION.PROMOTIONS.REMOVE.CONFIRMATION_TEXT2}</Text>
                                        <Text>{strings.ORGANIZATION.PROMOTIONS.REMOVE.CONFIRMATION_REQUIREMENTS} <b>"{deletePromotionState.name}"</b></Text>
                                    </Stack>
                                </ConfirmationForm>
                            </div>
                        </Modal>
                    )}
                </Stack.Item>
            )
                || (
                    <Stack.Item verticalFill>
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    </Stack.Item>
                ))}
            {error && (
                <Stack verticalFill>
                    {error.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                    )}
                    <SomethingWentWrong action={getPromotions} />
                </Stack>
            )}
        </Stack>
    )
}

const theme = getTheme();
const containerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 } };

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};