import { getTheme, Link, MessageBar, MessageBarType, Separator, SharedColors, Stack, Text } from "@fluentui/react"
import * as React from "react"
import { CustomSeparator } from "../../../../../components/shared/decorations/CustomSeparator"
import { portalApi } from "../../../../../core/api/api"
import { ICompany } from "../../../../../core/store"
import { IApplicationOptions } from "@piceasoft/core";
import { strings } from "../../../../../localization/strings"
import { StructureElementSettings } from "../../../OrganizationStructure/Elements/common/StructureElementSettings"
import { CSHHelpLink } from "../../../../../components/shared/help/CSHHelp"

type TProps = {
    item: ICompany
    updateState: (item: ICompany) => void
}

export const CompanySettingsPivot: React.FC<TProps> = ({ item, updateState }) => {

    const [error, setError] = React.useState<string>()
    const [isFetching, setIsFetching] = React.useState(false)

    const onUpdateOptions = async (options: IApplicationOptions) => {
        if (item.id) {
            setIsFetching(true)
            const result = await portalApi.organization.companies.updateOptions(item.id, options)
            console.log(result)
            if (result.successed) {
                updateState({ ...item, options: options })
            }
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            setIsFetching(false)
        }
    }

    return (
        <Stack tokens={{ childrenGap: 16, padding: "24px 32px 24px 32px" }}>
            <Stack tokens={{ childrenGap: 16 }}>
                {item.options && (
                    <StructureElementSettings disabled={false} settingsOptions={item.options} updateOptions={onUpdateOptions} />
                )}
            </Stack>
            <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
            <Stack.Item style={{ paddingBottom: 24 }}>
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.OPTIONS.INFORMATION_TITLE}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.OPTIONS.INFORMATION}</Text>
                        <br />
                        <CSHHelpLink  articleid="1097" alignment='left' label={strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.OPTIONS.LINK}/>
                    </Stack.Item>
                </Stack>
                <Text style={{ color: SharedColors.gray20 }}></Text>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
