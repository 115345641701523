/* eslint-disable max-depth */
import { getTheme, IButtonStyles, Icon, IconButton, IContextualMenuItem, IContextualMenuProps, IIconProps, IIconStyleProps, IIconStyles, IStyle, IStyleFunctionOrObject, mergeStyleSets, SharedColors, Stack, Text } from '@fluentui/react';
import { notDeepStrictEqual } from 'assert';
import React, { CSSProperties, FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { StructureNodeTypes } from '../../../screens/organization/OrganizationStructure/Elements/StructureNodeTypes';
import {
    FixedSizeNodeData,
    FixedSizeNodePublicState,
    FixedSizeTree,
    TreeWalker,
    TreeWalkerValue,
} from './vtree/'
import { NodeComponentProps } from './vtree/Tree';

export type ITreeNode = {
    id: string
    name: string
    iconProps?: IIconProps
    iconAlt?: string
    itemId: string
    itemCode?: string
    companyId?: string
    parentId?: string
    onClick: () => void
    children: ITreeNode[]
    actions?: ITreeNodeAction[]
    isExpanded?: boolean,
    disabled?: boolean,
    styles?: CSSProperties | undefined;
};

export interface ITreeNodeAction {
    id: string
    parentId?: string
    name: string
    itemCode?: string
    iconProps?: IIconProps
    onClick?: () => void
    childs?: ITreeNodeAction[]
}

type TreeData = FixedSizeNodeData & ITreeNode &
    Readonly<{
        isLeaf: boolean;
        name: string;
        nestingLevel: number;
    }>;

type NodeMeta = Readonly<{
    nestingLevel: number;
    node: ITreeNode;
}>;

type TreePresenterProps = Readonly<{
    itemSize: number;
    tree: ITreeNode;
    selectedKey: string
    getMenuProps: (actions: ITreeNodeAction[]) => IContextualMenuProps
    setDetailsItem: (type: StructureNodeTypes, id: string) => Promise<void> | undefined
    setSelectedKey: (key: string) => void
    expandedKeys?: string[]
    collapseNode?: (key: string) => void
    expandNode?: (key: string) => void
}>;

const getIsSelectedChildren = (key: string, trees: ITreeNode[]): boolean => {
    let result = false
    trees.forEach(child => {
        if (child.id === key) {
            result = true;
            return;
        }
        if (child.children.length > 0) {
            result = getIsSelectedChildren(key, child.children)
        }
    })
    return result
}

export const TreePresenter: FC<TreePresenterProps> = ({ tree, itemSize = 32, selectedKey, getMenuProps, setSelectedKey, expandedKeys, expandNode, collapseNode }) => {
    const getNodeData = (
        node: ITreeNode,
        nestingLevel: number,
    ): TreeWalkerValue<TreeData, NodeMeta> => {
        return ({
            data: {
                id: node.id,
                isLeaf: node.children.length === 0,
                isOpenByDefault: expandedKeys?.includes(node.id) ?? getIsSelectedChildren(selectedKey, node.children),
                name: node.name,
                nestingLevel,
                itemId: node.itemId,
                itemCode: node.itemCode,
                companyId: node.companyId,
                onClick: node.onClick,
                actions: node.actions,
                children: node.children,
                iconAlt: node.iconAlt,
                iconProps: node.iconProps,
                isExpanded: node.isExpanded,
                parentId: node.parentId,
                disabled: node.disabled,
                styles: node?.styles
            },
            nestingLevel,
            node,
        });
    }

    function* treeWalker(): ReturnType<TreeWalker<TreeData, NodeMeta>> {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < tree.children.length; i++) {
            yield getNodeData(tree.children[i], 0);
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        while (true) {
            const parentMeta = yield;

            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < parentMeta.node.children.length; i++) {
                yield getNodeData(
                    parentMeta.node.children[i],
                    parentMeta.nestingLevel + 1,
                );
            }
        }
    }

    const Node: FC<NodeComponentProps<
        TreeData,
        FixedSizeNodePublicState<TreeData>
    >> = ({ data, isOpen, style, setOpen }) => {
        return (
            <div style={{ ...style, display: 'flex', alignItems: 'center' }} key={'tree-element-' + data.id}>
                <Stack verticalFill grow >
                    <Stack.Item verticalFill className={data.id === selectedKey ? (data.disabled ? classNames.activeDisabledTreeItem : classNames.activeTreeItem) : classNames.treeItem}>
                        <Stack verticalFill grow
                            horizontal
                            horizontalAlign='space-between'
                            verticalAlign='center'
                            tokens={{ childrenGap: 4 }}
                            style={{
                                paddingLeft: data.parentId ?
                                    (!data.isLeaf ?
                                        16 * data.nestingLevel + 6 : 16 * data.nestingLevel + 30) :
                                    (!data.isLeaf ? 6 : 30), paddingRight: 6
                            }}
                        >
                            <Stack.Item>
                                {data.children && data.children.length > 0 && (
                                    <IconButton style={{ height: 24, width: 24 }} iconProps={{ iconName: !isOpen ? 'ChevronRight' : 'ChevronDown', style: { fontSize: 12, color: theme.palette.black } }}
                                        // onClick={() => toggleExpandedForestItem([data.id])} 
                                        onClick={() => {
                                            setOpen(!isOpen)
                                            if (!isOpen) {
                                                expandNode && expandNode(data.id)
                                            } else {
                                                collapseNode && collapseNode(data.id)
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                            {data.itemCode && (
                                <Stack verticalFill grow onClick={() => {
                                    setSelectedKey(data.id)
                                    data.onClick()
                                }} horizontal verticalAlign='center' tokens={{ childrenGap: 6 }}>
                                    <Icon style={{ ...data.iconProps?.style, color: data.id !== selectedKey && data.disabled && SharedColors.red10 || data.iconProps?.style?.color }} iconName={data.iconProps?.iconName} />
                                    {/* <Stack grow horizontal verticalFill verticalAlign="center" tokens={{ childrenGap: 16 }}> */}
                                    <Stack verticalFill verticalAlign="center" styles={{ root: { maxWidth: 50 } }} >
                                        <Text style={{ color: theme.palette.black, fontWeight: 400, backgroundColor: data.id !== selectedKey && data.disabled && 'rgb(253,231,233, 0.6)' || 'rgba(0, 0, 0, .08)', borderRadius: 10, padding: "3px 8px 3px 8px", fontSize: 11 }} block nowrap>{data.itemCode}</Text>
                                    </Stack>
                                    <Stack.Item grow styles={{ root: { maxWidth: data.isLeaf ? 195 : 205 } }}>
                                        <Text block style={{ color: data.id !== selectedKey && data.disabled && SharedColors.red10 || 'black' }} nowrap>{data.name}</Text>
                                    </Stack.Item>
                                    {/* </Stack> */}
                                </Stack>
                            ) || (
                                    <Stack verticalFill grow onClick={() => {
                                        setSelectedKey(data.id)
                                        data.onClick()
                                    }} horizontal verticalAlign='center' tokens={{ childrenGap: 6 }}>
                                        <Icon style={{ ...data.iconProps?.style, color: data.id !== selectedKey && data.disabled && SharedColors.red10 || data.iconProps?.style?.color }} iconName={data.iconProps?.iconName} />
                                        <Stack.Item styles={{ root: { maxWidth: data.isLeaf ? 245 : 255 } }}>
                                            <Text block style={{ ...data?.styles , color: data.id !== selectedKey && data.disabled && SharedColors.red10 || 'black'}} nowrap>{data.name}</Text>
                                        </Stack.Item>
                                    </Stack>
                                )}
                            {data.actions && (
                                <Stack.Item>
                                    <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={data.actions ? getMenuProps(data.actions) : undefined} />
                                </Stack.Item>
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div>
        )
    };

    return (
        <AutoSizer disableWidth>
            {({ height }) => (
                <FixedSizeTree
                    treeWalker={treeWalker}
                    itemSize={itemSize}
                    height={height - 88}
                    width={380}
                >
                    {Node}
                </FixedSizeTree>
            )}
        </AutoSizer>
    );
};

const theme = getTheme();

const classNames = mergeStyleSets({
    treeItem: [
        {
            padding: 3,
            borderLeft: '2px solid transparent',
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        },
    ],
    activeTreeItem: [
        {
            padding: 3,
            background: 'rgba(222,236,249,1)',
            borderLeft: `2px solid ${theme.palette.themePrimary}`,
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        }
    ],
    activeDisabledTreeItem: [
        {
            padding: 3,
            background: 'rgb(253,231,233, 0.6)',
            borderLeft: `2px solid ${theme.palette.red}`,
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        }
    ],
});

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}