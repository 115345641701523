declare global {
    interface Window {
        PICEA: IPicea
    }
}

export enum PiceaConnectionTypes {
    OTF = 1,
    USB = 2
}

export enum PiceaApplicationStates {
    /** Работа приложения приостановлена (оно не активно). */
    Suspended = 0,
    /** Работа приложения восстановлена (оно активно). */
    Activated = 1
}

export interface IPicea {
    onConnected: (handler: (args: PiceaConnectionEventArgs) => void) => void
    onDisconnected: (handler: (args: PiceaConnectionEventArgs) => void) => void
    onDeviceAdded: (handler: (args: PiceaDeviceEventArgs) => void) => void
    onDeviceUpdated: (handler: (args: PiceaDeviceEventArgs) => void) => void
    onDeviceRemoved: (handler: (args: PiceaDeviceEventArgs) => void) => void
    onApplicationStateChanged: (handler: (args: PiceaApplicationStateEventArgs) => void) => void
}

export type PiceaConnectionEventArgs = {
    error: PiceaError
    connectionType: PiceaConnectionTypes
    data: any
}

export type PiceaDeviceEventArgs = {
    error: PiceaError
    connectionType: PiceaConnectionTypes
    device: PiceaDevice
}

export type PiceaApplicationStateEventArgs = {
    error: PiceaError
    type: any
    data: PiceaApplicationState
}


export type PiceaError = {
    status: number
    message: string
    details?: PiceaErrorDetails
}

export type PiceaErrorDetails = {
    error: number
    message: string
}

export type PiceaApplicationState = {
    state: PiceaApplicationStates
    device_id: string
}

export type PiceaDevice = {
    /** Device ID. */
    device_id: string
    /** Device type. */
    tid: string
    /** Type string. */
    tid_string: string
    /** Device format. */
    fid: string
    /** Format string. */
    fid_string: string
    /** Operating system. */
    os: string
    /** Device manufacturer. */
    manufacturer: string
    /** Device brand name. */
    brand_name: string
    /** Device model. */
    model_name: string
    /** Device model code/number. */
    model_code: string
    /** Device primary IMEI. */
    imei: string
    /** Device secondary IMEI. */
    imei2: string
    /** Device MEID value. */
    meid: string
    /** Device serial number. */
    serial: string
    /** Device USB serial number (available only when connected with USB cable). */
    usb_serial: string
    /** Device unique ID. */
    unique_id: string
    /** Device software version. */
    sw_version: string
    /** Device OS version. */
    os_version: string
    /** Device storage. */
    storage: string
    /** Device internal memory. */
    ram: string
    /** Device color. */
    color: string
    /** Device supports GSM. */
    has_gsm: string
    /** Device supports CDMA. */
    has_cdma: string
    /** HTTP URL to device image. */
    device_img: string

    platform: string
}

export type PiceaDevices = {
    status: number
    devices: PiceaDevice[]
}

export enum DiagnosticsSets {
    TS_ACCESSORIES = 176,
    TS_AUDIO = 16,
    TS_BATTERY = 144,
    TS_CAMERA = 32,
    TS_CHIPSETS = 208,
    TS_CONDITION = 160,
    TS_CONNECTIVITY = 80,
    TS_CONNECTORS = 112,
    TS_DISPLAY = 48,
    TS_GENERAL = 0,
    TS_HW_BUTTONS = 64,
    TS_PERFORMANCE = 192,
    TS_ROBOTIC = 224,
    TS_SENSORS = 96,
    TS_SOFTWARE = 128
}

export enum DiagnosticStatuses {
    TCR_CANCELED = 4,
    TCR_FAILURE = 2,
    TCR_NOT_IMPLEMENTED = 7,
    TCR_NOT_SUPPORTED_BY_THE_DEVICE = 8,
    TCR_PASSED = 1,
    TCR_SKIPPED = 3,
    TCR_SKIPPED_BY_USER = 9,
    TCR_UNDEFINED = 0,
    TCR_UNEXECUTED = 5,
    TCR_WARNING = 6
}

/** OperationStatus */
export enum OperationStatus {
    OperationStatus_Unknown = 0,  //<! Unknown
    OperationStatus_Succeeded = 1,  //<! Succeeded
    OperationStatus_Failed = 2,  //<! Failed
    OperationStatus_EmptyRun = 3,  //<! Empty run
    OperationStatus_Skipped = 4,  //<! Skipped operation
    OperationStatus_Cancelled = 5,  //<! Cancelled
}

export type SwitchContentTypeReport = {
    content_type: number
    source_fid: string // guid
    target_fid: string // guid
    duration: number // seconds
    status: OperationStatus
    error_code: number // see EDP errorcodes
    error_msg?: number
    item_count: number
    item_size: null | number
    item_size_failed: null | number

    succeeded: number
    updated: number
    failed: number
    duplicate: number
    not_supported: number
    failure_ratio: number
    errors: Object[] // error_code, item_count
}

export type SwitchDetailsReport = {
    switch_type: number
    failure_ratio: number // 0...1
    target_device: Object // device object

    backup_info?: Object // backupRecord if backup/restore. Not applicable for us.

    // ios restore
    full_restore?: {
        result: number
        size: number
        }

    accounts?: Object

    content_types?: SwitchContentTypeReport[]
}

export type SwitchOperationReportData = {
    duration: number // seconds
    status: OperationStatus
    error_code: number
    failure_rate: number // 0...100
    switch_details: SwitchDetailsReport
}

export type SwitchTransferOperationCompletedData = {
    device_id: string // source device id
    operation_status: SwitchOperationReportData
    result: number
}

export enum ContentType {
    ContentType_Unknown = 0,  //<! Unknown
    ContentType_Contacts = 1,  //<! Contacts
    ContentType_Calendar = 2,  //<! Calendar
    ContentType_Messages = 3,  //<! Messages
    ContentType_Bookmarks = 4,  //<! Bookmarks
    ContentType_Music = 5,  //<! Music
    ContentType_Photos = 6,  //<! Photos
    ContentType_Videos = 7,  //<! Videos
    ContentType_Documents = 8,  //<! Documents
    ContentType_Apps = 9,  //<! Applications
    ContentType_CallLog = 10,  //<! Call log
    ContentType_iOSRestore = 20,  //<! iOS restore
    ContentType_OptionalContentApps = 11,
    ContentType_PreloadContentApps = 12,
    ContentType_WhatsApp = 13,
}

export enum ContentTransferTypes {
    Transfer = 1,
    Cloning = 5,
}