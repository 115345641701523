import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/help-actions";
import { IHelp } from "../store/typings/IHelp";

export const reducer: Reducer<IHelp> = (state: IHelp | undefined, incomingAction: Action): IHelp => {
    if (state === undefined) {
        return {
         helpArticleId: "-100" //documentation start page
        };
    }
    
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'HELP_SET_ARTICLE_ID':
            return { ...state, helpArticleId: action.helpArticleId };
        default: return state;
    }
};
