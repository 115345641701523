import { getTinyMCELang } from "../helpers/localization"
import { IStore } from "./typings/IStore"

export const selector = {
    workplace: {
        getCurrentLanguage: (state: IStore): string => {
            let lang = state.workplace.organization?.lang || 'en-US'

            if (lang.substring(0, 5).toLowerCase() === "pt-br") {
                return "pt-br"
            }

            return lang.substring(0, 2).toLowerCase()
        },

        getTinyMCELanguage: (state: IStore): string => {
            return getTinyMCELang(state.workplace.organization?.lang || 'en-US')
        }
    }
}