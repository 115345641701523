import React from "react";
import { strings } from "../../../../../localization/strings";
import { Section } from "../../../decorations/Section";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { ProcessStages, IResultConfig } from "@piceasoft/core";

type TProps = {
    config: IResultConfig
    onConfigCommit: (config: IResultConfig) => void
    channel: number
}

export const ResultSourceAndTargetBarcodesPivot: React.FC<TProps> = (props) => {
    return (
        <Section max flat>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SOURCE_DEVICE.TITLE}
                noSeparator
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Show the device barcode')}
                toggleChecked={props.config.barcodes?.device !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        barcodes: {
                            ...props.config.barcodes,
                            device: props.config.barcodes?.device !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.DEVICE.DESCRIPTION}
            />
            {props.config.barcodes?.device && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.DEVICE.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Device barcode title')}
                    toggleChecked={props.config.ui?.barcodes?.device !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                barcodes: {
                                    ...props.config.ui?.barcodes,
                                    device: props.config.ui?.barcodes?.device !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.DEVICE.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.barcodes?.device !== undefined ? true : false}
                    textFieldValue={props.config.ui?.barcodes?.device?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            barcodes: {
                                ...props.config.ui?.barcodes,
                                device: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SOURCE_DEVICE.IMEI.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Show IMEI barcode')}
                toggleChecked={props.config.barcodes?.imei !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        barcodes: {
                            ...props.config.barcodes,
                            imei: props.config.barcodes?.imei !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI.DESCRIPTION}
            />
            {props.config.barcodes?.imei && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'IMEI barcode title')}
                    toggleChecked={props.config.ui?.barcodes?.imei !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                barcodes: {
                                    ...props.config.ui?.barcodes,
                                    imei: props.config.ui?.barcodes?.imei !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.barcodes?.imei !== undefined ? true : false}
                    textFieldValue={props.config.ui?.barcodes?.imei?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            barcodes: {
                                ...props.config.ui?.barcodes,
                                imei: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SOURCE_DEVICE.IMEI2.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'IMEI barcode title')}
                toggleChecked={props.config.barcodes?.imei2 !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        barcodes: {
                            ...props.config.barcodes,
                            imei2: props.config.barcodes?.imei2 !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI2.DESCRIPTION}
            />
            {props.config.barcodes?.imei2 && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI2.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Show IMEI2 barcode')}
                    toggleChecked={props.config.ui?.barcodes?.imei2 !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                barcodes: {
                                    ...props.config.ui?.barcodes,
                                    imei2: props.config.ui?.barcodes?.imei2 !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI2.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.barcodes?.imei2 !== undefined ? true : false}
                    textFieldValue={props.config.ui?.barcodes?.imei2?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            barcodes: {
                                ...props.config.ui?.barcodes,
                                imei2: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SOURCE_DEVICE.SKU.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Show SKU barcode')}
                toggleChecked={props.config.barcodes?.sku !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        barcodes: {
                            ...props.config.barcodes,
                            sku: props.config.barcodes?.sku !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SKU.DESCRIPTION}
            />
            {props.config.barcodes?.sku && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SKU.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'SKU barcode title')}
                    toggleChecked={props.config.ui?.barcodes?.sku !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                barcodes: {
                                    ...props.config.ui?.barcodes,
                                    sku: props.config.ui?.barcodes?.sku !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SKU.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.barcodes?.sku !== undefined ? true : false}
                    textFieldValue={props.config.ui?.barcodes?.sku?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            barcodes: {
                                ...props.config.ui?.barcodes,
                                sku: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SOURCE_DEVICE.SN.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes','Show the serial number barcode')}
                toggleChecked={props.config.barcodes?.sn !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        barcodes: {
                            ...props.config.barcodes,
                            sn: props.config.barcodes?.sn !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SN.DESCRIPTION}
            />
            {props.config.barcodes?.sn && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SN.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes','Serial number barcode title')}
                    toggleChecked={props.config.ui?.barcodes?.sn !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                barcodes: {
                                    ...props.config.ui?.barcodes,
                                    sn: props.config.ui?.barcodes?.sn !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SN.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.barcodes?.sn !== undefined ? true : false}
                    textFieldValue={props.config.ui?.barcodes?.sn?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            barcodes: {
                                ...props.config.ui?.barcodes,
                                sn: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TARGET_DEVICE.TITLE}
                noSeparator
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Show the device barcode')}
                toggleChecked={props.config.targetDeviceBarcodes?.device !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        targetDeviceBarcodes: {
                            ...props.config.targetDeviceBarcodes,
                            device: props.config.targetDeviceBarcodes?.device !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.DEVICE.DESCRIPTION}
            />
            {props.config.targetDeviceBarcodes?.device && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.DEVICE.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Device barcode title')}
                    toggleChecked={props.config.ui?.targetDeviceBarcodes?.device !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                targetDeviceBarcodes: {
                                    ...props.config.ui?.targetDeviceBarcodes,
                                    device: props.config.ui?.targetDeviceBarcodes?.device !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.DEVICE.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.targetDeviceBarcodes?.device !== undefined ? true : false}
                    textFieldValue={props.config.ui?.targetDeviceBarcodes?.device?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            targetDeviceBarcodes: {
                                ...props.config.ui?.targetDeviceBarcodes,
                                device: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TARGET_DEVICE.IMEI.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Show IMEI barcode')}
                toggleChecked={props.config.targetDeviceBarcodes?.imei !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        targetDeviceBarcodes: {
                            ...props.config.targetDeviceBarcodes,
                            imei: props.config.targetDeviceBarcodes?.imei !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI.DESCRIPTION}
            />
            {props.config.targetDeviceBarcodes?.imei && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'IMEI barcode title')}
                    toggleChecked={props.config.ui?.targetDeviceBarcodes?.imei !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                targetDeviceBarcodes: {
                                    ...props.config.ui?.targetDeviceBarcodes,
                                    imei: props.config.ui?.targetDeviceBarcodes?.imei !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.targetDeviceBarcodes?.imei !== undefined ? true : false}
                    textFieldValue={props.config.ui?.targetDeviceBarcodes?.imei?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            targetDeviceBarcodes: {
                                ...props.config.ui?.targetDeviceBarcodes,
                                imei: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TARGET_DEVICE.IMEI2.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'IMEI barcode title')}
                toggleChecked={props.config.targetDeviceBarcodes?.imei2 !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        targetDeviceBarcodes: {
                            ...props.config.targetDeviceBarcodes,
                            imei2: props.config.targetDeviceBarcodes?.imei2 !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI2.DESCRIPTION}
            />
            {props.config.targetDeviceBarcodes?.imei2 && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI2.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Show IMEI2 barcode')}
                    toggleChecked={props.config.ui?.targetDeviceBarcodes?.imei2 !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                targetDeviceBarcodes: {
                                    ...props.config.ui?.targetDeviceBarcodes,
                                    imei2: props.config.ui?.targetDeviceBarcodes?.imei2 !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.IMEI2.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.targetDeviceBarcodes?.imei2 !== undefined ? true : false}
                    textFieldValue={props.config.ui?.targetDeviceBarcodes?.imei2?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            targetDeviceBarcodes: {
                                ...props.config.ui?.targetDeviceBarcodes,
                                imei2: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            {/* <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TARGET_DEVICE.SKU.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'Show SKU barcode')}
                toggleChecked={props.config.targetDeviceBarcodes?.sku !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        targetDeviceBarcodes: {
                            ...props.config.targetDeviceBarcodes,
                            sku: props.config.targetDeviceBarcodes?.sku !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SKU.DESCRIPTION}
            />
            {props.config.targetDeviceBarcodes?.sku && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SKU.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes', 'SKU barcode title')}
                    toggleChecked={props.config.ui?.targetDeviceBarcodes?.sku !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                targetDeviceBarcodes: {
                                    ...props.config.ui?.targetDeviceBarcodes,
                                    sku: props.config.ui?.targetDeviceBarcodes?.sku !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SKU.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.targetDeviceBarcodes?.sku !== undefined ? true : false}
                    textFieldValue={props.config.ui?.targetDeviceBarcodes?.sku?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            targetDeviceBarcodes: {
                                ...props.config.ui?.targetDeviceBarcodes,
                                sku: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )} */}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TARGET_DEVICE.SN.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes','Show the serial number barcode')}
                toggleChecked={props.config.targetDeviceBarcodes?.sn !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        targetDeviceBarcodes: {
                            ...props.config.targetDeviceBarcodes,
                            sn: props.config.targetDeviceBarcodes?.sn !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SN.DESCRIPTION}
            />
            {props.config.targetDeviceBarcodes?.sn && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SN.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes','Serial number barcode title')}
                    toggleChecked={props.config.ui?.targetDeviceBarcodes?.sn !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                targetDeviceBarcodes: {
                                    ...props.config.ui?.targetDeviceBarcodes,
                                    sn: props.config.ui?.targetDeviceBarcodes?.sn !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.SN.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.targetDeviceBarcodes?.sn !== undefined ? true : false}
                    textFieldValue={props.config.ui?.targetDeviceBarcodes?.sn?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            targetDeviceBarcodes: {
                                ...props.config.ui?.targetDeviceBarcodes,
                                sn: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TRANSACTION.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes','Show the transaction barcode')}
                toggleChecked={props.config.barcodes?.transaction !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        barcodes: {
                            ...props.config.barcodes,
                            transaction: props.config.barcodes?.transaction !== undefined ? undefined : true
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TRANSACTION.DESCRIPTION}
            />
            {props.config.barcodes?.transaction && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TRANSACTION.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel,ProcessStages.Result,'Barcodes','Transaction barcode title')}
                    toggleChecked={props.config.ui?.barcodes?.transaction !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onConfigCommit({
                            ...props.config,
                            ui: {
                                ...props.config.ui,
                                barcodes: {
                                    ...props.config.ui?.barcodes,
                                    transaction: props.config.ui?.barcodes?.transaction !== undefined ? undefined : {}
                                }
                            }
                        })
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BARCODES.TRANSACTION.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={props.config.ui?.barcodes?.transaction !== undefined ? true : false}
                    textFieldValue={props.config.ui?.barcodes?.transaction?.title}
                    textFieldOnChange={(val) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            barcodes: {
                                ...props.config.ui?.barcodes,
                                transaction: {
                                    title: val
                                }
                            }
                        }
                    })}
                />
            )}
        </Section>
    )
}