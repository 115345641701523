import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/workflow-actions";
import { IPortalWorkflow } from "../store/typings/IPortalWorkflow";

export const reducer: Reducer<IPortalWorkflow> = (state: IPortalWorkflow | undefined, incomingAction: Action): IPortalWorkflow => {
    if (state === undefined) {
        return {
            structure: {
                data: [],
                isReady: false
            }
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'WORKFLOW_STRUCTURE_REQUEST':
            return state
        case 'WORKFLOW_STRUCTURE_RECEIVE':
            return { structure: { ...state.structure, isReady: true, data: action.data } };
        case 'WORKFLOW_STRUCTURE_NODE_ADD':
            return {
                structure: {
                    ...state.structure,
                    isReady: true,
                    data: [
                        ...state.structure.data,
                        action.data
                    ]
                }
            };
        case 'WORKFLOW_STRUCTURE_NODE_EDIT':
            return {
                structure: {
                    ...state.structure,
                    isReady: true,
                    data: [
                        ...state.structure.data.map(i => {
                            if (i.id === action.data.id) {
                                return action.data
                            }
                            return i
                        }),
                    ]
                }
            };
        case 'WORKFLOW_STRUCTURE_NODE_DELETE':
            return {
                structure: {
                    ...state.structure,
                    isReady: true,
                    data: [
                        ...state.structure.data.filter(i => i.id !== action.id),
                    ]
                }
            };
        default: return state;
    }
};
