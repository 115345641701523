import { Stack, Text, IconButton, getTheme, ScrollablePane } from '@fluentui/react';
import * as React from 'react'
import { IInterviewConfig, InterviewModes, IGrade, IQuestion } from "@piceasoft/core";
import { strings } from '../../../../../../../../../localization/strings';
import { QuestionsList } from '../../QuestionsList';
import { ListQuestion } from './question/ListQuestion';
import { SequenceQuestion } from './question/SequenceQuestion';

export type TQuestionsConfiguratorQuestionsPivotProps = {
    interview: IInterviewConfig
    onDissmiss: () => void
    selectedQuestionOrder: number
    onAdd: () => void
    onConfirm: (question: IQuestion) => void
    onDelete: (index: number) => void
    onMoveUp: (index: number) => void
    setSelectedQuestionOrder: (order: number) => void
    questions: IQuestion[]
    grades: IGrade[]
}

export const QuestionsConfiguratorQuestionsPivot: React.FC<TQuestionsConfiguratorQuestionsPivotProps> = ({
    interview,
    questions,
    onAdd,
    onConfirm,
    onDelete,
    onMoveUp,
    selectedQuestionOrder,
    setSelectedQuestionOrder,
    grades
}) => {
    return (
        <Stack horizontal grow verticalFill>
            <Stack.Item verticalFill style={{ minWidth: 350, maxWidth: 450, borderRight: `1px solid ${theme.semanticColors.bodyDivider}`, backgroundColor: theme.palette.neutralLighterAlt }}>
                <Stack tokens={{ childrenGap: 8 }} verticalFill>
                    <Stack.Item>
                        <Stack horizontal grow verticalAlign='center' tokens={{ padding: '8px 8px 0px 20px' }}>
                            <Stack.Item grow>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                    {/* <Icon iconName="StatusCircleQuestionMark" /> */}
                                    <Text variant='large' style={{ fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.QUESTIONS}</Text>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <IconButton menuIconProps={{ style: { fontSize: 16, color: theme.palette.black }, iconName: "Add" }} onClick={onAdd} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                        <ScrollablePane>
                            {questions && questions.length > 0 && (
                                <QuestionsList
                                    questions={questions.map((q, order) => { return { isSelected: order === selectedQuestionOrder, data: q } }) ?? []}
                                    setOrder={setSelectedQuestionOrder}
                                    index={questions[selectedQuestionOrder]?.index ?? -1}
                                    onDelete={onDelete}
                                    onUp={onMoveUp}
                                />
                            )}
                        </ScrollablePane>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item grow style={{ position: 'relative' }}>
                <ScrollablePane>
                    {interview.mode === InterviewModes.Sequence && questions[selectedQuestionOrder] && (
                        <SequenceQuestion question={questions[selectedQuestionOrder]} questions={questions} grades={grades} onConfirm={onConfirm} />
                    )}
                    {interview.mode === InterviewModes.List && questions[selectedQuestionOrder] && (
                        <ListQuestion question={questions[selectedQuestionOrder]} onConfirm={onConfirm} grades={grades} questions={questions} />
                    )}
                </ScrollablePane>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
