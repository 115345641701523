import {
    IRenderFunction, IDetailsHeaderProps, Sticky, IDropdownOption, IDetailsListProps,
    IColumn, TextField, Dropdown, Stack, IconButton,
    ScrollablePane, DetailsList, SelectionMode, DetailsListLayoutMode, getTheme,
    IButtonStyles, IStyle, PrimaryButton, IDetailsColumnStyles, TooltipHost, ITooltipProps, Spinner, SharedColors, Icon
} from "@fluentui/react"
import React from "react"
import { Control, useFieldArray, useFormContext, Controller, FieldArrayWithId } from "react-hook-form"
import { onRenderColumnHeaderCommon, onRenderColumnHeaderCommonRequired } from "../detailsList/commonRenders"
import { ItemsNotFound } from "../notFound/ItemsNotFound"
import { IPromotionItem } from "../../../core/store/typings/IPromotionItem"
import { strings } from "../../../localization/strings"
import { IPromotionItemCondition } from "../../../core/store/typings/IPromotionItemCondition"
import { SecondaryButton } from "../buttons/SecondaryButton"
import { PromotionItemConditionManufacturer } from "./PromotionItemCondotionFormArray/PromotionItemConditionManufacturer"
import { IKnownDevice } from "@piceasoft/core"
import { PromotionItemConditionModel } from "./PromotionItemCondotionFormArray/PromotionItemConditionModel"
import { PromotionItemConditionConfiguration } from "./PromotionItemCondotionFormArray/PromotionItemConditionConfiguration"
import { Tooltip } from "../help/Tooltip"
import { PromotionsItemConditionsOnRenderRow } from "./PromotionItemCondotionFormArray/PromotionItemConditionsOnRenderRow"
import { PromotionPriceTypes } from "@piceasoft/core"
type TProps = {
    onSubmit: () => void
    onReset: () => void
    onDismiss: () => void
    devices: IKnownDevice[]
    control: Control<{ conditions: TPromotionItemCondition[] }, object>
    isFetching: boolean
}

type TPromotionItemCondition = IPromotionItemCondition & { itemId?: string }

export const PromotionItemConditionsFormArray: React.FC<TProps> = props => {

    const { fields, append, remove, swap } = useFieldArray({
        control: props.control,
        name: "conditions"
    });

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) return null;

        return (
            <Sticky>
                {defaultRender!({ ...hProps })}
            </Sticky>
        );
    }

    const priceTypeOptions: IDropdownOption[] = [
        { key: PromotionPriceTypes.Benefit, text: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.PRICE_TYPE.TYPES.BENEFIT },
        { key: PromotionPriceTypes.FixPrice, text: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.PRICE_TYPE.TYPES.FIX_PRICE }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        return <PromotionsItemConditionsOnRenderRow rProps={rProps} />
    }

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: '',
            fieldName: 'up',
            minWidth: 32,
            maxWidth: 32,
            isResizable: false,
            styles: columnStyles,
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                if (!index || index === 0) return null;
                return (
                    <Stack.Item tokens={{ padding: "0px 0px 0px 5px" }}>
                        <IconButton iconProps={{ iconName: "Up", className: 'hidden-button' }} styles={onHoverIconStyles} onClick={() => swap(index - 1, index)} />
                    </Stack.Item>
                )
            }
        },
        {
            key: 'column1',
            name: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.MANUFACTURER.TITLE,
            fieldName: 'manufacturer',
            minWidth: 200,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRenderHeader: onRenderColumnHeaderCommonRequired,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                if (index === undefined) return null
                const { formState, control, watch, trigger } = useFormContext<IPromotionItem>();
                const errors = formState.errors?.conditions ? formState.errors?.conditions[index] : undefined
                return (
                    <Controller
                        control={control}
                        name={`conditions.${index}.manufacturer`}
                        rules={{
                            required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.MANUFACTURER.REQUIRED,
                            validate: (value) => {
                                let elem = watch().conditions[index]
                                return watch().conditions.find((el, cIndex) => cIndex !== index && (el.configuration === elem?.configuration && el.manufacturer === value && el.grade === elem.grade && el.device === elem?.device)) ?
                                    strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING : true
                            }
                        }}
                        render={({ field, fieldState, formState }) =>
                            <TooltipHost content={errors?.manufacturer?.message !== strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING ? errors?.manufacturer?.message : undefined} tooltipProps={tooltipProps}>
                                <PromotionItemConditionManufacturer
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    styles={fieldStyles}
                                    placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.MANUFACTURER.PLACEHOLDER}
                                    errorMessage={errors?.manufacturer?.message !== strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING ? errors?.manufacturer?.message : undefined}
                                    devices={props.devices}
                                    trigger={trigger}
                                />
                            </TooltipHost>
                        }
                    />
                )
            },
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.DEVICE.TITLE,
            fieldName: 'device',
            minWidth: 200,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRenderHeader: onRenderColumnHeaderCommonRequired,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                if (index === undefined) return null
                const { formState, control, watch, trigger } = useFormContext<IPromotionItem>();
                const conditionState = watch().conditions[index];
                const errors = formState.errors?.conditions ? formState.errors?.conditions[index] : undefined
                return (
                    <Controller
                        control={control}
                        name={`conditions.${index}.device`}
                        rules={{
                            required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.DEVICE.REQUIRED,
                            validate: (value) => {
                                let elem = watch().conditions[index]
                                return watch().conditions.find((el, cIndex) => cIndex !== index && (el.configuration === elem?.configuration && el.manufacturer === elem?.manufacturer && el.grade === elem.grade && el.device === value)) ?
                                    strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING : true
                            }
                        }}
                        render={({ field, fieldState, formState }) =>
                            <TooltipHost content={errors?.device?.message !== strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING ? errors?.device?.message : undefined} tooltipProps={tooltipProps}>
                                <PromotionItemConditionModel
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    styles={fieldStyles}
                                    placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.DEVICE.PLACEHOLDER}
                                    errorMessage={errors?.device?.message !== strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING ? errors?.device?.message : undefined}
                                    devices={props.devices}
                                    manufacturer={conditionState.manufacturer}
                                    trigger={trigger}
                                />
                            </TooltipHost>
                        }
                    />
                )
            },
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.CONFIGURATION.TITLE,
            fieldName: 'configuration',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRenderHeader: onRenderColumnHeaderCommonRequired,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                if (index === undefined) return null
                const { formState, control, watch, trigger } = useFormContext<IPromotionItem>();
                const conditionState = watch().conditions[index];
                const errors = formState.errors?.conditions ? formState.errors?.conditions[index] : undefined
                return (
                    <Controller
                        control={control}
                        name={`conditions.${index}.configuration`}
                        rules={{
                            required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.CONFIGURATION.REQUIRED,
                            validate: (value) => {
                                let elem = watch().conditions[index]
                                return watch().conditions.find((el, cIndex) => cIndex !== index && (el.configuration === value && el.manufacturer === elem?.manufacturer && el.grade === elem.grade && el.device === elem?.device)) ?
                                    strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING : true
                            }
                        }}
                        render={({ field, fieldState, formState }) =>
                            <TooltipHost content={errors?.configuration?.message !== strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING ? errors?.configuration?.message : undefined} tooltipProps={tooltipProps}>
                                <PromotionItemConditionConfiguration
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    styles={fieldStyles}
                                    placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.CONFIGURATION.PLACEHOLDER}
                                    errorMessage={errors?.configuration?.message !== strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING ? errors?.configuration?.message : undefined}
                                    devices={props.devices}
                                    manufacturer={conditionState.manufacturer}
                                    model={conditionState.device}
                                    trigger={trigger}
                                />
                            </TooltipHost>
                        }
                    />
                )
            },
        },
        {
            key: 'column4',
            name: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.GRADE.TITLE,
            fieldName: 'grade',
            minWidth: 160,
            maxWidth: 160,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRenderHeader: onRenderColumnHeaderCommonRequired,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                if (index === undefined) return null
                const { formState, control, watch, trigger } = useFormContext<IPromotionItem>();
                const errors = formState.errors?.conditions ? formState.errors?.conditions[index] : undefined
                return (
                    <Controller
                        control={control}
                        name={`conditions.${index}.grade`}
                        rules={{
                            required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.GRADE.REQUIRED,
                            validate: (value) => {
                                let elem = watch().conditions[index]
                                return watch().conditions.find((el, cIndex) => cIndex !== index && (el.configuration === elem?.configuration && el.manufacturer === elem?.manufacturer && el.grade === value && el.device === elem?.device)) ?
                                    strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING : true
                            }
                        }}
                        render={({ field, fieldState, formState }) =>
                            <TooltipHost content={errors?.grade?.message !== strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING ? errors?.grade?.message : undefined} tooltipProps={tooltipProps}>
                                <TextField
                                    value={field.value}
                                    onChange={(ev, val) => {
                                        trigger("conditions")
                                        field.onChange(val)
                                    }}
                                    onBlur={field.onBlur}
                                    styles={fieldStyles}
                                    placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.GRADE.PLACEHOLDER}
                                    errorMessage={errors?.grade?.message !== strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING ? errors?.grade?.message : undefined}
                                />
                            </TooltipHost>
                        }
                    />
                )
            },
        },
        {
            key: 'column5',
            name: '',
            fieldName: 'warning',
            minWidth: 32,
            maxWidth: 32,
            isResizable: false,
            styles: columnStyles,
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                const { formState } = useFormContext<IPromotionItem>();
                if (index === undefined) return null
                const error = strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.VALIDATE_WARNING
                if (formState.errors.conditions && formState.errors.conditions[index] && formState.errors.conditions[index]?.grade?.message === error) {
                    return (
                        <Stack verticalAlign="center" tokens={{ padding: "0px 0px 0px 10px" }} verticalFill>
                            <Tooltip content={error}>
                                <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                            </Tooltip>
                        </Stack>
                    )
                }
                return null
            }
        },
        {
            key: 'column6',
            name: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.PRICE.TITLE,
            fieldName: 'price',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRenderHeader: onRenderColumnHeaderCommonRequired,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                if (index === undefined) return null
                const { formState, control } = useFormContext<IPromotionItem>();
                const errors = formState.errors?.conditions ? formState.errors?.conditions[index] : undefined
                return (
                    <Controller
                        control={control}
                        name={`conditions.${index}.price`}
                        rules={{
                            required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.PRICE.REQUIRED,
                        }}
                        render={({ field, fieldState, formState }) =>
                            <TooltipHost content={errors?.price?.message} tooltipProps={tooltipProps}>
                                <TextField
                                    value={field.value === undefined || field.value === null ? '' : String(field.value).toLocaleString()}
                                    onChange={(ev, val) => val ? (/^\d*\.?\d{0,2}$/.test(val) && field.onChange(val)) : field.onChange(null)}
                                    onBlur={field.onBlur}
                                    styles={fieldStyles}
                                    placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.PRICE.PLACEHOLDER}
                                    errorMessage={errors?.price?.message}
                                />
                            </TooltipHost>
                        }
                    />
                )
            },
        },
        {
            key: 'column7',
            name: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.PRICE_TYPE.TITLE,
            fieldName: 'priceType',
            minWidth: 160,
            maxWidth: 160,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRenderHeader: onRenderColumnHeaderCommonRequired,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                if (index === undefined) return null
                const { formState, control } = useFormContext<IPromotionItem>();
                const errors = formState.errors?.conditions ? formState.errors?.conditions[index] : undefined
                return (
                    <Controller
                        control={control}
                        name={`conditions.${index}.priceType`}
                        rules={{ required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.PRICE_TYPE.REQUIRED }}
                        render={({ field, fieldState, formState }) =>
                            <TooltipHost content={errors?.priceType?.message} tooltipProps={tooltipProps}>
                                <Dropdown
                                    selectedKey={field.value}
                                    onChange={(ev, opt) => field.onChange(opt?.key)}
                                    onBlur={field.onBlur}
                                    styles={fieldStyles}
                                    placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.COLUMNS.PRICE_TYPE.PLACEHOLDER}
                                    options={priceTypeOptions}
                                    errorMessage={errors?.priceType?.message}
                                />
                            </TooltipHost>
                        }
                    />
                )
            },
        },
        {
            key: 'column8',
            name: '',
            fieldName: 'commandBar',
            minWidth: 32,
            isResizable: false,
            data: 'string',
            styles: { cellTitle: { justifyContent: 'flex-end' }, ...columnStyles },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IPromotionItemCondition, index?: number) => {
                if (index === undefined) return null
                const { watch, trigger } = useFormContext<IPromotionItem>();
                const conditionState = watch().conditions[index];
                return (
                    <Stack tokens={{ childrenGap: 8 }} grow horizontal horizontalAlign="end" verticalFill verticalAlign='center'>
                        <Stack.Item>
                            <IconButton iconProps={{ iconName: "Copy", className: 'hidden-button' }} styles={onHoverIconStyles}
                                onClick={() => {
                                    append({ ...conditionState, itemId: undefined, id: generateId() })
                                }} />
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton iconProps={{ iconName: "Delete", className: 'hidden-button' }} styles={onHoverIconStyles}
                                onClick={() => {
                                    remove(index)
                                }} />
                        </Stack.Item>
                    </Stack>
                )
            }
        }
    ]

    return (
        <Stack.Item grow verticalFill>
            <Stack grow verticalFill tokens={{ childrenGap: 16 }}>
                {fields.length > 0 && (
                    <Stack.Item grow verticalFill style={{ position: 'relative' }}>
                        <ScrollablePane>
                            <DetailsList
                                items={fields ?? []}
                                columns={columns}
                                setKey="none"
                                getKey={(item: FieldArrayWithId<{
                                    conditions: TPromotionItemCondition[];
                                }, "conditions", "id">) => item.id}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.justified}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                isHeaderVisible={true}
                                onRenderRow={onRenderRow}
                            />
                        </ScrollablePane>
                    </Stack.Item>
                ) || (
                        <ItemsNotFound
                            info={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.NOT_FOUND_CASE.INFO}
                            onClick={() => append({ configuration: '', device: '', grade: '', manufacturer: '', itemId: undefined, id: generateId() })}
                            buttonText={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.NOT_FOUND_CASE.ADD_BUTTON}
                            suggestion={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.NOT_FOUND_CASE.SUGGESTION}
                            imgSrc={"images/navigation/images/startup.png"}
                        />
                    )}
                <Stack horizontal grow>
                    {fields.length > 0 && (
                        <Stack.Item tokens={{ padding: 16 }}>
                            <PrimaryButton onClick={() => append({ manufacturer: '', device: '', grade: '', configuration: '', itemId: undefined, id: generateId() })}>{strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.CONDITIONS.NOT_FOUND_CASE.ADD_BUTTON}</PrimaryButton>
                        </Stack.Item>
                    )}
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 16 }}>
                        {props.isFetching && (
                            <Spinner />
                        )}
                        <PrimaryButton disabled={props.isFetching} onClick={props.onSubmit} text={strings.BUTTONS.TEXT.CONFIRM} />
                        <SecondaryButton disabled={props.isFetching} onClick={props.onReset} text={strings.BUTTONS.TEXT.CANCEL} />
                        <SecondaryButton disabled={props.isFetching} onClick={props.onDismiss} text={strings.BUTTONS.TEXT.CLOSE} />
                    </Stack>
                </Stack>
            </Stack>
        </Stack.Item>
    )
}

const theme = getTheme();

const onHoverIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const customRowRootStyle: IStyle = {
    cursor: 'default',
    ':hover': {
        '.disabled-icon': {
            color: theme.palette.black
        },
        '.hidden-button': {
            color: theme.palette.black
        },
        '.up-icon': {
            fontSize: 16,
            color: theme.palette.themePrimary
        }
    }
};

const fieldStyles: Partial<{ errorMessage: IStyle }> = {
    errorMessage: {
        display: 'none',
    },

}

const columnStyles: Partial<IDetailsColumnStyles> = { root: { ':hover': { backgroundColor: 'transparent' }, ':active': { backgroundColor: 'transparent' } } }

const tooltipProps: Partial<ITooltipProps> = {
    calloutProps: {
        styles: {
            beak: { background: theme.palette.black },
            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
        }
    }
}

const generateId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substring(2);
    return dateString + randomness;
};