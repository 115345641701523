import { ICatalog } from "../../../core/store"
import { ICatalogVersionsState } from "./FinanceCatalog"
import { TFinanceCatalogPivotState } from "./FinanceCatalogDetails"
import { FinanceCatalogInfoPivot } from "./pivots/FinanceCatalogInfoPivot"
import { FinanceCatalogPricesPivot } from "./pivots/FinanceCatalogPricesPivot"
import { FinanceCatalogProtocolsPivot } from "./pivots/FinanceCatalogProtocolsPivot"

export type TFinanceCatalogPivotBasedStateProps = {
    state: TFinanceCatalogPivotState
    data: ICatalog
    onDelete: (catalog: ICatalog) => void
    versionsState: ICatalogVersionsState
    onImport: () => void
    setSelectedVersionId: (id?: string) => void
}

export const FinanceCatalogPivotBasedState: React.FC<TFinanceCatalogPivotBasedStateProps> = (props) => {
    switch (props.state) {
        case "prices": return (
            <FinanceCatalogPricesPivot data={props.data} onImport={props.onImport} versionsState={props.versionsState}
            setSelectedVersionId={props.setSelectedVersionId}
            />
        )
        case "info": return (
            <FinanceCatalogInfoPivot
                onDelete={props.onDelete} data={props.data} />
        )
        case "protocols": return (
            <FinanceCatalogProtocolsPivot
                data={props.data} />
        )
    }
}
