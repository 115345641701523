import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { IContractConfig, IStore } from "../../../../../core/store";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { useSelector } from "react-redux";
import { ProcessStages, getOnlineLanguage } from "@piceasoft/core";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: IContractConfig
    channel: number
}

export const ContractStageAdditionalSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, channel }) => {
    if (!config) return null
    const language = useSelector((s : IStore) => getOnlineLanguage(s.environment.lang));
    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.USE_SUBSCRIPTION_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Contract, 'Additional', 'Enable marketing consent request')}
                toggleChecked={config.useSubscription}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Contract, {
                        ...config,
                        useSubscription: !config.useSubscription,
                        continueWithSubscribtion: !config.useSubscription && false,
                        subscribeText: config.useSubscription ? undefined : config.subscribeText
                    } as IContractConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.USE_SUBSCRIPTION_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.CONTINUE_WITH_SUBSCRIPTION_TITLE}
                useToggle
                noSeparator
                toggleEnabled={config.useSubscription}
                toggleChecked={config.continueWithSubscribtion}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Contract, {
                        ...config,
                        continueWithSubscribtion: !config.continueWithSubscribtion
                    } as IContractConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.CONTINUE_WITH_SUBSCRIPTION_DESCRIPTION}
            />
            <ParameterItem
                title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.SUBSCRIBE_TEXT_TITLE}
                noSeparator
                useToggle
                toggleEnabled={config.useSubscription}
                toggleChecked={(config.subscribeText !== undefined && config.subscribeText !== null) ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Contract, {
                        ...config,
                        subscribeText: (config.subscribeText !== undefined && config.subscribeText !== null) ? undefined : ""
                    } as IContractConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.SUBSCRIBE_TEXT_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={(config.subscribeText !== undefined && config.subscribeText !== null) ? true : false}
                textFieldValue={config.subscribeText}
                textFieldOnChange={(val) => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Contract, {
                        ...config,
                        subscribeText: val
                    } as IContractConfig
                    ))
                }}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.SEND_TO_EMAIL_TITLE}
                useToggle
                noSeparator
                toggleEnabled={true}
                toggleChecked={config.sendToEmail}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Contract, {
                        ...config,
                        sendToEmail: !config.sendToEmail
                    } as IContractConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.SEND_TO_EMAIL_DESCRIPTION}
            />
            {language === "ru" && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.USE_DADATA_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Contract, 'Additional', 'Use DaData') && language === "ru"}
                toggleChecked={config.useDaData}
                
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Contract, {
                        ...config,
                        useDaData: !config.useDaData
                    } as IContractConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.USE_DADATA_DESCRIPTION}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.DO_NOT_SHOW_PRINT_FORM_TITLE}
                useToggle
                noSeparator
                toggleEnabled
                toggleChecked={config.doNotGenerate}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Contract, {
                        ...config,
                        doNotGenerate: config.doNotGenerate ? undefined : true
                    } as IContractConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.DO_NOT_SHOW_PRINT_FORM_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.SHOW_LINK_TITLE}
                useToggle
                noSeparator
                toggleEnabled
                toggleChecked={config.showDownloadLink}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(ProcessStages.Contract, {
                        ...config,
                        showDownloadLink: config.showDownloadLink ? undefined : true
                    } as IContractConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.SHOW_LINK_DESCRIPTION}
            />
        </Stack>
    )
}