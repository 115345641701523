import { endpoints } from "../../../../core/api/endpoints";
import { IOffer } from "../../../../core/store";
import { ITransactionDevice, IDiscount, IGrade } from "@piceasoft/core"
import { TPromoProviderItemState } from "../stages/commonOffer/preview/promotions/PromotionsPanel";
import { IAssessment } from "@piceasoft/core";

export const sampleIdentity: ITransactionDevice = {
    isAuthenticated: true,
    device: {
        name: "iPhone 11",
        manufacturer: "Apple",
        attributes: {
            "imei": "1234567890098765",
            "image": endpoints.v1.piceaDeviceImage("Apple", "iPhone 11")
        },
        configuration: "256"
    },
    method: 1
};

export const sampleGrade: IGrade = {
    name: "Good condition",
    code: "B",
    description: "Normal use condition",
    explanation: "Scratches due normal use",
    index: 1
};
export const sampleDiscount: IDiscount = {
    amount: 300,
    code: "DSC01",
    device: "IPhone 11 64",
    expiringDate: '11.01.29',
    id: 'dsc01',
    manufacturer: 'Apple',
    image: endpoints.v1.piceaDeviceImage("Apple", "iPhone 11")
};

export const sampleOffer: IOffer = {
    grade: "A",
    price: 500,
    source: "from configurator preview"
};

export const sampleAssessment: IAssessment = {
    grade: "A",
    price: 500,
    inspections: {

    },
    modules: [],
    monitoring: {}
};

export const processIdSample = 950007;

export const samplePromotions: TPromoProviderItemState[] = [
    {
        "id": "48f47926-83ec-4eca-a2c5-0cf659cb74c8",
        "from": new Date(),
        "to": new Date(24 * 3600 * 1000),
        "name": "Apple iPhone 13",
        "description": "Apple promo",
        "manufacturer": "Apple",
        "device": "iPhone 13",
        "imgSrc": endpoints.v1.piceaDeviceImage("Apple", "iPhone 13"),
        "conditions": [{
            "id": "48f47926-83ec-4eca-a2c5-0cf659cb74c8",
            "price": 80,
            "priceType": 1,
            "currency": "EUR",
            "manufacturer": "Xiaomi",
            "device": "Redmi Note 8T",
            "configuration": "128",
            "grade": "B"
        }],
        "provider_code": "apple_promo",
        "provider_name": "Apple promotions",
        "provider_description": "Apple phones promotion",
        "provider_img": "https://one2phone.blob.core.windows.net:443/offer-provider-icon/36e1bd71-2d33-4b8e-9dc1-d7e57355bb16",
        "key": "48f47926-83ec-4eca-a2c5-0cf659cb74c8-apple_promo"
    },
    {
        "id": "06790d0f-55fc-4704-bfbd-385a4a6ce79c",
        "from": new Date(),
        "to": new Date(24 * 3600 * 1000),
        "name": "Promo one",
        "description": "Promo one",
        "manufacturer": "Samsung",
        "device": "Galaxy S20",
        "imgSrc": endpoints.v1.piceaDeviceImage("Samsung", "Galaxy S20"),
        "conditions": [{
            "id": "06790d0f-55fc-4704-bfbd-385a4a6ce79c",
            "price": 600,
            "priceType": 0,
            "currency": "EUR",
            "manufacturer": "Xiaomi",
            "device": "Redmi Note 8T",
            "configuration": "128",
            "grade": "B"
        }],
        "provider_code": "promo_one",
        "provider_name": "Promo one",
        "provider_description": "",
        "provider_img": "https://one2phone.blob.core.windows.net:443/offer-provider-icon/36e1bd71-2d33-4b8e-9dc1-d7e57355bb16",
        "key": "06790d0f-55fc-4704-bfbd-385a4a6ce79c-promo_one"
    },
    {
        "id": "3a520307-fd4f-4306-81f6-4a82e336655b",
        "from": new Date(),
        "to": new Date(24 * 3600 * 1000),
        "name": "The wanted",
        "description": "Futher the best Huawei smartphone",
        "manufacturer": "Huawei",
        "device": "P50 Pro",
        "imgSrc": endpoints.v1.piceaDeviceImage("Huawei", "P50 Pro"),
        "conditions": [{
            "id": "3a520307-fd4f-4306-81f6-4a82e336655b",
            "price": 550,
            "priceType": 0,
            "currency": "EUR",
            "manufacturer": "Xiaomi",
            "device": "Redmi Note 8T",
            "configuration": "128",
            "grade": "C"
        }],
        "provider_code": "best_promos",
        "provider_name": "Best promo",
        "provider_description": "Best description",
        "provider_img": "https://one2phone.blob.core.windows.net:443/offer-provider-icon/36e1bd71-2d33-4b8e-9dc1-d7e57355bb16",
        "key": "3a520307-fd4f-4306-81f6-4a82e336655b-best_promos"
    }
];