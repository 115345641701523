import {
    FontIcon,
    getTheme,
    IPanelProps,
    IPanelStyles,
    IPivotItemProps,
    IRenderFunction,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    ScrollablePane,
    SharedColors,
    Stack,
    Text
} from "@fluentui/react";
import React, { Dispatch } from "react";
import { IAssessmentConfig, IIdentificationConfig, IResultConfig, ProcessStages } from "@piceasoft/core";
import { actionCreators } from "../../../core/actions/configurator-actions";
import { IWorkflow } from "../../../core/store";
import { strings } from "../../../localization/strings";
import { ParameterItem } from "./components/ParameterItem";
import { sampleIdentity } from "./mock/samples";
import { Assessment } from "./stages/Assessment";
import { StageAdditionalSettingsPivot } from "./stages/common/pivots/StageAdditionalSettingsPivot";
import { AssessmentRequirementsPivot } from "./stages/assessment/pivots/AssessmentRequirementsPivot";
import { AssessmentConfirmationPreview } from "./stages/assessment/preview/AssessmentConfirmationPreview";
import { AssessmentPreview } from "./stages/assessment/preview/AssessmentPreview";
import { StageRequirementsPreview } from "./stages/preview/StageRequirementsPreview";
import { Contract } from "./stages/Contract";
import { Control } from "./stages/Control";
import { Identification } from "./stages/Identification";
import {
    IdentificationStageAdditionalSettingsPivot
} from "./stages/identification/IdentificationStageAdditionalSettingsPivot";
import { Resources } from "./stages/Resources";
import { ResourcesConfigurator } from "./stages/resources/ResourcesConfigurator";
import { Result } from "./stages/Result";
import { ResultPreview } from "./stages/result/preview/ResultPreview";
import { ResultBarcodesPivot } from "./stages/result/ResultBarcodesPivot";
import { ResultCommonButtonsChildren } from "./stages/result/ResultCommonButtonsChildren";
import { ResultCommonUIChildren } from "./stages/result/ResultCommonUIChildren";
import { ResultSettingsPivotBasedState } from "./stages/result/ResultSettingsPivotBasedState";
import { StageSettingsPivotBasedState } from "./stages/settings/StageSettingsPivotBasedState";
import { ConfirmationPreview } from "./stages/common/preview/ConfirmationPreview";
import { IdentificationPreview } from "./stages/identification/preview/IdentificationPreview";
import {
    IdentificationStageDeviceGroupsSettingsPivot
} from "./stages/identification/IdentificationStageDeviceGroupsSettingsPivot";
import { ContractPreview } from "./stages/contract/preview/ContractPreview";
import { ContractCommonUIChildren } from "./stages/contract/ContractCommonUIChildren";
import { ContractStageAdditionalSettingsPivot } from "./stages/contract/ContractStageAdditionalSettingsPivot";
import {
    CommonOfferStageAdditionalSettingsPivot
} from "./stages/commonOffer/pivots/CommonOfferStageAdditionalSettingsPivot";
import { CommonOfferStagePromotionsPivot } from "./stages/commonOffer/pivots/CommonOfferStagePromotionsPivot";
import { CommonOfferStageOfferProvidersPivot } from "./stages/commonOffer/pivots/CommonOfferStageOfferProvidersPivot";
import { PostOfferStageSettingsPivotBasedState } from "./stages/postOffer/pivots/PostOfferStageSettingsPivotBasedState";
import { PreOfferPreview } from "./stages/preOffer/preview/PreOfferPreview";
import { PostOfferPreview } from "./stages/postOffer/preview/PostOfferPreview";
import { PreOffer } from "./stages/PreOffer";
import { PreOfferStageSettingsPivotBasedState } from "./stages/preOffer/pivots/PreOfferStageSettingsPivotBasedState";
import { PostOffer } from "./stages/PostOffer";
import { StagePreviewWrapper } from "./stages/preview/StagePreviewWrapper";
import { ControlPreview } from "./stages/control/preview/ControlPreview";
import { IdentificationRequirementsPivot } from "./stages/identification/pivots/IdentificationRequirementsPivot";
import { ResultMapPivot } from "./stages/result/ResultMapPivot";
import { ContentTransfer } from "./stages/ContentTransfer";
import { IdentificationContentTransfer } from "./stages/IdentificationContentTransfer";
import {
    ContentTransferStateSettingsPivotBasedState
} from "./stages/contentTransfer/pivots/ContentTransferStageSettingsPivotBasedState";
import { ContentTransferStagePreview } from "./stages/contentTransfer/preview/ContentTransferStagePreview";
import {
    ContentTransferIdentificationPreview
} from "./stages/identification/preview/ContentTransferIdentificationPreview";
import { ResultPreviewUIKit } from "./stages/result/preview/ResultPreviewUIKit";
import { ResultSourceAndTargetBarcodesPivot } from "./stages/result/ResultSourceAndTargetBarcodesPivot";
import { ContractPreviewUIKit } from "./stages/contract/preview/ContractPreviewUIKit";
import { ConfirmationPreviewUIKit } from "./stages/common/preview/ConfirmationPreviewUIKit";
import { CSHHelpLink, getHelpArticleIdForStage } from "../help/CSHHelp";
import { Landing } from "./stages/Landing";
import { ResultUserSatisfactionSurveyChildren } from "./stages/result/ResultUserSatisfactionSurveyChildren";
import { Experience } from "./helpers/evisibility";
import SelfService from "./stages/SelfService";
import SelfServiceSettingsPivotBasedState from "./stages/selfService/pivots/SelfServiceSettingsPivotBasedState";

export type IConfiguratorProps = {
    config: IWorkflow
    setIdentificationModuleParams: (index: number, pivot: string) => void
    setStageModuleParams: (stage: ProcessStages, index: number, pivot: string) => void
    setSelfServiceModule: () => void
    setLandingModuleParams: () => void
    onChangeDispatch: Dispatch<any>
    channel: number,
    deviceCheckSolution: number
}

export type TStageSettingsPivotStates = "common_ui" | "common_buttons" |"user_satisfaction_survey"| "cancel_reasons" | "confirmation" | "additional" | "result_barcodes" | "result_map" | "select_device" | "self_service" | string

export const Configurator: React.FC<IConfiguratorProps> = ({ config, ...props }) => {

    const [settingsState, setSettingsState] = React.useState<{ stage: ProcessStages, pivot: TStageSettingsPivotStates, secondPivot?: string, offerProviderCode?: string }>()
    const promoErrors = config.commonOffer?.promoProviders?.find((promo) => promo.errors && promo.errors.length > 0)
    const providersErrors = config.commonOffer?.providers?.find((provider) => provider.errors  && provider.errors.length > 0)
    const selfServiceRepairProvidersErrors = config.selfService?.repairOffers?.providers.find(p => p.errors)

    const isContentTransfer = config.contentTransfer ? true : false;
    const ctIdentification = settingsState?.stage === ProcessStages.Identification && isContentTransfer;
    const handlePivotLinkClick = (item?: PivotItem) => {
        if (settingsState) {
            setSettingsState({
                stage: settingsState.stage,
                pivot: item?.props.itemKey! as TStageSettingsPivotStates,
                secondPivot: undefined
            });
        }
    };

    const handleSecondPivotLinkClick = (item?: PivotItem) => {
        if (settingsState) {
            setSettingsState({
                stage: settingsState.stage,
                pivot: settingsState.pivot,
                secondPivot: item?.props.itemKey,
            });
        }
    };

    React.useEffect(() => {
        console.log(settingsState)
    }, [settingsState])

    const getPivotHeaderText = (m: string): string => {
        switch (m) {
            case "common_ui": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.COMMON_UI.TITLE
            case "common_buttons": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.COMMON_BUTTONS.TITLE
            case "user_satisfaction_survey": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.RESULT_USERSATISFACTION_SURVEY.TITLE
            case "cancel_reasons": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CANCEL_REASONS.TITLE
            case "confirmation": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CONFIRMATION.TITLE
            case "additional": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.TITLE
            case "assessment_requirements": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.TITLE
            case "result_barcodes": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.RESULT_BARCODES.TITLE
            case "offer_common_additional": return strings.CONSTRUCTOR.STAGES.COMMON_OFFER.ADDITIONAL_PIVOT.TITLE
            case "offer_common_custom": return strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.TITLE
            case "offer_common_promotions": return strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.TITLE
            case "identification_requirements": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.TITLE
            case "identification_groups": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.DEVICE_GROUPS.TITLE
            case "result_map": return strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.MAP.TITLE
            case "select_device": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.DEVICE_SELECTION.TITLE
            case "self_service": return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.LOCALIZE_TEXT
            case "follow_up_questions": return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP_QUESTIONS.TAB_TITLE
            case "promotions": return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.PROMOTIONS.TAB_TITLE
            case "offer_providers": return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.OFFER_PROVIDERS.TAB_TITLE
            case "recommendations": return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RECOMMENDATIONS.TAB_TITLE
            case "disclaimers": return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.DISCLAIMERS.TAB_TITLE
            case "contact_forms": return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.TAB_TITLE
            default: return m;
        }
    }

    const getPanelStagePivots = (stage: ProcessStages, channel: number): JSX.Element[] => {
        switch (stage) {
            case ProcessStages.Assessment:
                return [
                    <PivotItem headerText={getPivotHeaderText("assessment_requirements")} itemKey="assessment_requirements" />
                ]
            case ProcessStages.Identification:
                return [
                    <PivotItem headerText={getPivotHeaderText("identification_requirements")} itemKey="identification_requirements" />,
                    <PivotItem headerText={getPivotHeaderText("identification_groups")} itemKey="identification_groups" />
                ]
            case ProcessStages.Result:
                const headers =[];
                if(isContentTransfer) {
                    headers.push(<PivotItem headerText={getPivotHeaderText("result_barcodes")} itemKey="result_barcodes" />);
                    return headers;
                }
                if(channel == Experience.ECom || channel == Experience.Mobile){
                    headers.push(<PivotItem headerText={getPivotHeaderText("user_satisfaction_survey")} itemKey="user_satisfaction_survey" />);
                }
                headers.push(<PivotItem headerText={getPivotHeaderText("result_barcodes")} itemKey="result_barcodes" />);
                headers.push(<PivotItem headerText={getPivotHeaderText("result_map")} itemKey="result_map" />);
                return headers;
            case ProcessStages.PreOffer:
                return [
                    <PivotItem headerText={getPivotHeaderText("offer_common_promotions")} itemKey="offer_common_promotions" style={promoErrors && { color: SharedColors.red10 }} onRenderItemLink={onRenderItemLink} />,
                    <PivotItem headerText={getPivotHeaderText("offer_common_custom")} itemKey="offer_common_custom" style={providersErrors && { color: SharedColors.red10 }} onRenderItemLink={onRenderItemLink} />,
                    <PivotItem headerText={getPivotHeaderText("offer_common_additional")} itemKey="offer_common_additional" />
                ]
            case ProcessStages.PostOffer:
                return [
                    <PivotItem headerText={getPivotHeaderText("offer_common_promotions")} itemKey="offer_common_promotions" style={promoErrors && { color: SharedColors.red10 }} onRenderItemLink={onRenderItemLink} />,
                    <PivotItem headerText={getPivotHeaderText("offer_common_custom")} itemKey="offer_common_custom" style={providersErrors && { color: SharedColors.red10 }} onRenderItemLink={onRenderItemLink} />,
                    <PivotItem headerText={getPivotHeaderText("offer_common_additional")} itemKey="offer_common_additional" />
                ]
            case ProcessStages.SelfService:
                return [
                    <PivotItem headerText={getPivotHeaderText("follow_up_questions")} itemKey="follow_up_questions" />,
                    <PivotItem headerText={getPivotHeaderText("promotions")} itemKey="promotions" />,
                    <PivotItem headerText={getPivotHeaderText("contact_forms")} itemKey="contact_forms" />,
                    <PivotItem headerText={getPivotHeaderText("offer_providers")} itemKey="offer_providers" style={selfServiceRepairProvidersErrors && { color: SharedColors.red10 }} onRenderItemLink={onRenderItemLink} />,
                    <PivotItem headerText={getPivotHeaderText("recommendations")} itemKey="recommendations" />,
                    <PivotItem headerText={getPivotHeaderText("disclaimers")} itemKey="disclaimers" />,
                ]
            default: return []
        }
    }
    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }} >
                {iProps?.style?.color === SharedColors.red10 && (
                    <FontIcon iconName={'Warning'} style={{ fontSize: 14, color: SharedColors.red10 }} />
                )}
                <Text style={{ color: iProps?.style?.color }}>{iProps?.headerText}</Text>
            </Stack>
        )

    }

    const onRenderPanelHeader = (stage: ProcessStages, hProps?: IPanelProps, render?: IRenderFunction<IPanelProps>) => {
        switch (stage) {
            case ProcessStages.Resources:
            return <>
            <Stack grow style={{ overflow: "hidden" }}>
                        <Stack grow horizontal>
                            <Stack grow horizontal>
                                {render!(hProps)}
                            </Stack>
                            <Stack horizontal style={{ alignItems: "center" }}>
                                    <CSHHelpLink articleid={getHelpArticleIdForStage(stage)} />
                            </Stack>
                        </Stack>
            </Stack>
            </>
            default:
                return (
                    <Stack grow style={{ overflow: "hidden" }}>
                        <Stack grow horizontal>
                            <Stack grow horizontal>
                                {render!(hProps)}
                            </Stack>
                            <Stack horizontal style={{ alignItems: "center" }}>
                                    <CSHHelpLink articleid={getHelpArticleIdForStage(stage)} />
                            </Stack>
                        </Stack>
                        <Stack tokens={{ padding: "16px 16px 16px 16px", childrenGap: 8 }}>
                        { ctIdentification &&
                            (<Pivot selectedKey={settingsState?.pivot} onLinkClick={handlePivotLinkClick} headersOnly={true} overflowBehavior="menu">
                                <PivotItem headerText={getPivotHeaderText("common_ui")} itemKey="common_ui" />
                                <PivotItem headerText={getPivotHeaderText("common_buttons")} itemKey="common_buttons" />
                                <PivotItem headerText={getPivotHeaderText("select_device")} itemKey="select_device" />
                            </Pivot>) ||
                            (<Pivot selectedKey={settingsState?.pivot} onLinkClick={handlePivotLinkClick} headersOnly={true} overflowBehavior="menu">
                                {settingsState?.stage && ![ProcessStages.ContentTransfer, ProcessStages.SelfService].includes(settingsState?.stage) && <PivotItem headerText={getPivotHeaderText("common_ui")} itemKey="common_ui" />}
                                <PivotItem headerText={getPivotHeaderText("common_buttons")} itemKey="common_buttons" />
                                {settingsState?.stage && ![ProcessStages.Result, ProcessStages.PreOffer, ProcessStages.ContentTransfer, ProcessStages.SelfService].includes(settingsState?.stage) && <PivotItem headerText={getPivotHeaderText("confirmation")} itemKey="confirmation" />}
                                {settingsState?.stage && ![ProcessStages.Result, ProcessStages.PreOffer, ProcessStages.Identification, ProcessStages.ContentTransfer, ProcessStages.SelfService].includes(settingsState?.stage) && <PivotItem headerText={getPivotHeaderText("cancel_reasons")} itemKey="cancel_reasons" />}
                                {getPanelStagePivots(stage, props.channel)}
                                {[ProcessStages.Identification, ProcessStages.PreOffer, ProcessStages.Assessment, ProcessStages.PostOffer, ProcessStages.Contract, ProcessStages.Control].includes(stage) && <PivotItem headerText={getPivotHeaderText("additional")} itemKey="additional" />}
                            </Pivot>)
                        }
                            {settingsState?.stage === ProcessStages.PreOffer && settingsState.pivot === "common_ui" && (
                                <Pivot selectedKey={settingsState?.secondPivot} onLinkClick={handleSecondPivotLinkClick} headersOnly={true} overflowBehavior="menu">
                                    <PivotItem headerText={strings.CONSTRUCTOR.STAGES.PRE_OFFER.UI_PIVOTS.HAS_OFFER} itemKey="hasOffer" />
                                    <PivotItem headerText={strings.CONSTRUCTOR.STAGES.PRE_OFFER.UI_PIVOTS.NO_OFFER} itemKey="noOffer" />
                                    <PivotItem headerText={strings.CONSTRUCTOR.STAGES.PRE_OFFER.UI_PIVOTS.NOT_ACCEPTABLE} itemKey="notAcceptable" />
                                </Pivot>
                            )}
                            {settingsState?.pivot === "offer_common_promotions" && (
                                <Pivot selectedKey={settingsState?.secondPivot} onLinkClick={handleSecondPivotLinkClick} headersOnly={true} overflowBehavior="menu">
                                    <PivotItem headerText={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.PROVIDERS_PIVOT} itemKey="promotions_providers" style={promoErrors && { color: SharedColors.red10 }} onRenderItemLink={onRenderItemLink} />
                                    <PivotItem headerText={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.UI_PIVOT} itemKey="promotions_ui" />
                                </Pivot>
                            )}
                        </Stack>
                    </Stack>
                )
        }
    }

    const onRenderPanelFooterContentTransfer: IRenderFunction<IPanelProps> = (props) => {
        return (
            <>
                {settingsState?.stage === ProcessStages.Identification && (
                    <StagePreviewWrapper>
                        <ContentTransferIdentificationPreview currentPivot={settingsState?.pivot}/>
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.ContentTransfer && (
                    <StagePreviewWrapper>
                        <ContentTransferStagePreview />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Contract && settingsState?.pivot.startsWith("common") && (
                    <StagePreviewWrapper>
                        <ContractPreviewUIKit />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Contract && config.contract?.requireConfirmation && settingsState?.pivot.startsWith("confirmation") && (
                    <StagePreviewWrapper>
                        <ConfirmationPreviewUIKit config={config.contract} />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Result && settingsState?.pivot.startsWith("common") && (
                    <StagePreviewWrapper>
                        <ResultPreviewUIKit />
                    </StagePreviewWrapper>
                )}
            </>
        )
    }

    const onRenderPanelFooter: IRenderFunction<IPanelProps> = (props) => {
        return (
            <>
                {settingsState?.stage === ProcessStages.Identification && settingsState?.pivot.startsWith("common") && (
                    <StagePreviewWrapper>
                        <IdentificationPreview />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Identification && config.identification.requireConfirmation && settingsState?.pivot.startsWith("confirmation") && (
                    <StagePreviewWrapper>
                        <ConfirmationPreview config={config.identification} />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Identification && config.identification?.requirements && settingsState?.pivot.startsWith("identification_requirements") && (
                    <StagePreviewWrapper>
                        <StageRequirementsPreview config={config.identification} />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.PreOffer && config.preOffer && (
                    settingsState?.pivot.startsWith("common")
                    || settingsState?.pivot === "additional"
                    || (settingsState?.pivot === "offer_common_promotions" && settingsState?.secondPivot === "promotions_ui")) && (
                        <StagePreviewWrapper>
                            <PreOfferPreview config={config.preOffer} commonConfig={config.commonOffer ?? {}} device={sampleIdentity.device} />
                        </StagePreviewWrapper>
                    )}
                {/* {settingsState?.stage === ProcessStages.PreOffer && config.preOffer?.requireConfirmation && settingsState?.pivot.startsWith("confirmation") && (
                    <StagePreviewWrapper>
                        <ConfirmationPreview config={config.preOffer} />
                    </StagePreviewWrapper>
                )} */}
                {settingsState?.stage === ProcessStages.Assessment && settingsState?.pivot.startsWith("common") && (
                    <StagePreviewWrapper>
                        <AssessmentPreview />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Assessment && config.assessment?.requireConfirmation && settingsState?.pivot.startsWith("confirmation") && (
                    <StagePreviewWrapper>
                        <AssessmentConfirmationPreview config={config.assessment} device={sampleIdentity.device} />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.PostOffer && config.postOffer && (
                    settingsState?.pivot.startsWith("common")
                    || settingsState?.pivot === "additional"
                    || (settingsState?.pivot === "offer_common_promotions" && settingsState?.secondPivot === "promotions_ui")) && (
                        <StagePreviewWrapper>
                            <PostOfferPreview config={config.postOffer} commonConfig={config.commonOffer ?? {}} device={sampleIdentity.device} />
                        </StagePreviewWrapper>
                    )}
                {settingsState?.stage === ProcessStages.PostOffer && config.postOffer?.requireConfirmation && settingsState?.pivot.startsWith("confirmation") && (
                    <StagePreviewWrapper>
                        <ConfirmationPreview config={config.postOffer} />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Assessment && config.assessment?.requirements && settingsState?.pivot.startsWith("assessment_requirements") && (
                    <StagePreviewWrapper>
                        <StageRequirementsPreview config={config.assessment} />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Contract && settingsState?.pivot.startsWith("common") && (
                    <StagePreviewWrapper>
                        <ContractPreview />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Contract && config.contract?.requireConfirmation && settingsState?.pivot.startsWith("confirmation") && (
                    <StagePreviewWrapper>
                        <ConfirmationPreview config={config.contract} />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Control && settingsState?.pivot.startsWith("common") && (
                    <StagePreviewWrapper>
                        <ControlPreview />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Control && config.control?.requireConfirmation && settingsState?.pivot.startsWith("confirmation") && (
                    <StagePreviewWrapper>
                        <ConfirmationPreview config={config.control} />
                    </StagePreviewWrapper>
                )}
                {settingsState?.stage === ProcessStages.Result && settingsState?.pivot.startsWith("common") && (
                    <StagePreviewWrapper>
                        <ResultPreview />
                    </StagePreviewWrapper>
                )}
            </>
        )
    }

    const getPanelHeaderText = (stage?: ProcessStages): string => {
        switch (stage) {
            case ProcessStages.Identification: return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.IDENTIFICATION
            case ProcessStages.Assessment: return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.ASSESSMENT
            case ProcessStages.Resources: return strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.RESOURCES
            case ProcessStages.Contract: return strings.CONSTRUCTOR.STAGES.CONTRACT.TITLE
            case ProcessStages.Control: return strings.CONSTRUCTOR.STAGES.CONTROL.TITLE
            case ProcessStages.Result: return strings.CONSTRUCTOR.STAGES.RESULT.TITLE
            case ProcessStages.PreOffer: return strings.CONSTRUCTOR.STAGES.PRE_OFFER.TITLE
            case ProcessStages.PostOffer: return strings.CONSTRUCTOR.STAGES.POST_OFFER.TITLE
            case ProcessStages.ContentTransfer: return strings.ORGANIZATION.SERVICES.SERVICE_TYPE.CONTENT_TRANSFER
            case ProcessStages.Landing: return strings.CONSTRUCTOR.INSPECTIONS.LANDING.LANDING_PAGE
            case ProcessStages.SelfService: return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.HEADER
            default: return ""
        }
    }

    const getAssessmentConfirmationAddon = (): React.ReactNode => {
        return (
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.SHOW_DEVICE_IMEI_ON_CONFIRMATION_TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={config.assessment?.confirmation?.showDeviceIMEI}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                        ...config.assessment,
                        confirmation: {
                            ...config.assessment?.confirmation,
                            showDeviceIMEI: !config.assessment?.confirmation?.showDeviceIMEI
                        }
                    } as IAssessmentConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.SHOW_DEVICE_IMEI_ON_CONFIRMATION_DESCRIPTION}
            />
        )
    }

    const getCommonOfferPivots = (stage: ProcessStages): { name: string, component: React.ReactNode }[] => {


        return [
            {
                name: "offer_common_promotions",
                component: <CommonOfferStagePromotionsPivot stage={stage} channel={props.channel} config={config.commonOffer} onChangeDispatch={props.onChangeDispatch} selectedPivot={settingsState?.secondPivot as "promotions_ui" | "promotions_providers" | undefined} />
            },
            {
                name: "offer_common_custom",
                component: <CommonOfferStageOfferProvidersPivot config={config.commonOffer} onChangeDispatch={props.onChangeDispatch} grades={config.grades} offerProviderCode={settingsState?.offerProviderCode} />
            },
            {
                name: "offer_common_additional",
                component: <CommonOfferStageAdditionalSettingsPivot stage={stage} channel={props.channel} config={config.commonOffer} onChangeDispatch={props.onChangeDispatch} />
            }
        ]
    }

    const getAssessmentPivots = (): { name: string, component: React.ReactNode }[] => {
        return [
            {
                name: "assessment_requirements",
                component: <AssessmentRequirementsPivot channel={props.channel} config={config} onChangeDispatch={props.onChangeDispatch} />
            }
        ]
    }

    const getResultPivots = (config: IResultConfig, channel: number): { name: string, component: React.ReactNode }[] => {
        const components = [];
        if( isContentTransfer) {
            components.push({
                name: "result_barcodes",
                component: <ResultSourceAndTargetBarcodesPivot channel={channel} config={config} onConfigCommit={(config) => props.onChangeDispatch(actionCreators.result.onConfigCommit(config))} />
            });
            return components;
        }
        if(channel == Experience.ECom || channel == Experience.Mobile){
            components.push({
                name: "user_satisfaction_survey",
                component: <ResultUserSatisfactionSurveyChildren channel={props.channel} config={config} onChangeDispatch={(config) => props.onChangeDispatch(actionCreators.result.onConfigCommit(config))} />
            });
        }
        components.push(
            {
                name: "result_barcodes",
                component: <ResultBarcodesPivot channel={channel} config={config} onConfigCommit={(config) => props.onChangeDispatch(actionCreators.result.onConfigCommit(config))} />
            });
        components.push( {
                name: "result_map",
                component: <ResultMapPivot channel={channel} config={config} onConfigCommit={(config) => props.onChangeDispatch(actionCreators.result.onConfigCommit(config))} />
            });
        return components;
    }

    const getIdentificationPivots = (config: IIdentificationConfig): { name: string, component: React.ReactNode }[] => {
        return [
            {
                name: "identification_requirements",
                component: <IdentificationRequirementsPivot channel={props.channel} config={config} onChangeDispatch={props.onChangeDispatch} />
            },
            {
                name: "identification_groups",
                component: <IdentificationStageDeviceGroupsSettingsPivot channel={props.channel} config={config} onConfigCommit={(config) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Identification, config))} />
            }
        ]
    }

    return (
        <Stack verticalFill style={{ backgroundColor: theme.palette.white, position: 'relative' }}>
            <ScrollablePane>
                <Stack verticalFill style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    minHeight: 0,
                    minWidth: 0
                }}>
                    <Stack.Item style={{ padding: "0px 20px 40px 20px" }}>
                        <Stack verticalFill horizontal tokens={{ childrenGap: 32 }}>
                            {config?.landing && !config?.landing.disabled && (
                                <Stack.Item>
                                    <Landing config={config.landing}
                                        setModuleParams={props.setLandingModuleParams}
                                        onChangeDispatch={props.onChangeDispatch}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.Landing, pivot: "common_ui" })} />
                                </Stack.Item>
                            )}
                            {config?.resources && !config?.resources.disabled && (
                                <Stack.Item>
                                    <Resources config={config.resources}
                                        onChangeDispatch={props.onChangeDispatch}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.Resources, pivot: "common_ui" })} />
                                </Stack.Item>
                            )}
                            {config?.identification && !( config && config.contentTransfer && !config.contentTransfer.disabled) && (
                                <Stack.Item>
                                    <Identification 
                                        config={config.identification} 
                                        channel={props.channel} 
                                        deviceCheckSolution={props.deviceCheckSolution}
                                        service={config.serviceType}
                                        setModuleParams={props.setIdentificationModuleParams}
                                        onChangeDispatch={props.onChangeDispatch}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.Identification, pivot: "common_ui" })} />
                                </Stack.Item>
                            )}
                            {config?.contentTransfer && !config.contentTransfer.disabled && (
                                <Stack.Item>
                                    <IdentificationContentTransfer config={config.identification}
                                        setModuleParams={props.setIdentificationModuleParams}
                                        onChangeDispatch={props.onChangeDispatch}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.Identification, pivot: "common_ui" })} />
                                </Stack.Item>
                            )}
                            {config?.contentTransfer && !config.contentTransfer.disabled && (
                                <Stack.Item>
                                    <ContentTransfer config={config.contentTransfer}
                                    showSettings={() => setSettingsState({ stage: ProcessStages.ContentTransfer, pivot: "common_buttons" })}
                                    />
                                </Stack.Item>
                            )}
                            {config?.preOffer && !config?.preOffer.disabled && (
                                <Stack.Item>
                                    <PreOffer config={config.preOffer}
                                        commonConfig={config.commonOffer ?? {}}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.PreOffer, pivot: "common_ui" })}
                                        onChangeDispatch={props.onChangeDispatch}
                                        showCustomSettings={(offerProviderCode?: string) => setSettingsState({ stage: ProcessStages.PreOffer, pivot: "offer_common_custom", offerProviderCode: offerProviderCode })}
                                        showDiscountsSettings={() => setSettingsState({ stage: ProcessStages.PreOffer, pivot: "offer_common_promotions" })}
                                    />
                                </Stack.Item>
                            )}
                            {config?.selfService && (
                                <Stack.Item>
                                    <SelfService
                                        config={config.selfService}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.SelfService, pivot: "common_buttons" })}
                                        showConfigSettings={() => props.setSelfServiceModule()}
                                        showOfferSettings = {(offerProviderCode?: string) => setSettingsState({ stage: ProcessStages.SelfService, pivot: "offer_providers", offerProviderCode: offerProviderCode  })}
                                        // onChangeDispatch={props.onChangeDispatch}
                                    />
                                </Stack.Item>
                            )}
                            {config?.assessment && !config?.assessment?.disabled && (
                                <Stack.Item>
                                    <Assessment config={config.assessment}
                                        channel={props.channel}
                                        deviceCheckSolution={props.deviceCheckSolution}
                                        onChangeDispatch={props.onChangeDispatch}
                                        setInspectionParams={(index, pivot) => props.setStageModuleParams(ProcessStages.Assessment, index, pivot)}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.Assessment, pivot: "common_ui" })} />
                                </Stack.Item>
                            )}
                            {config?.postOffer && !config?.postOffer.disabled && (
                                <Stack.Item>
                                    <PostOffer config={config.postOffer}
                                        commonConfig={config.commonOffer ?? {}}
                                        channel={props.channel}
                                        deviceCheckSolution={props.deviceCheckSolution}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.PostOffer, pivot: "common_ui" })}
                                        onChangeDispatch={props.onChangeDispatch}
                                        showCustomSettings={(offerProviderCode?: string) => setSettingsState({ stage: ProcessStages.PostOffer, pivot: "offer_common_custom", offerProviderCode: offerProviderCode })}
                                        showDiscountsSettings={() => setSettingsState({ stage: ProcessStages.PostOffer, pivot: "offer_common_promotions" })}
                                        setInspectionParams={(index, pivot) => props.setStageModuleParams(ProcessStages.PostOffer, index, pivot)}
                                    />
                                </Stack.Item>
                            )}
                            {config?.contract && !config.contract.disabled && (
                                <Stack.Item>
                                    <Contract config={config.contract}
                                        onChangeDispatch={props.onChangeDispatch}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.Contract, pivot: "common_ui" })} 
                                        channel={props.channel}
                                        stage={ProcessStages.Contract}/>
                                </Stack.Item>
                            )}
                            {config?.control && !config.control.disabled && (
                                <Stack.Item>
                                    <Control config={config.control}
                                        onChangeDispatch={props.onChangeDispatch}
                                        setInspectionParams={(index, pivot) => props.setStageModuleParams(ProcessStages.Control, index, pivot)}
                                        showSettings={() => setSettingsState({ stage: ProcessStages.Control, pivot: "common_ui" })} />
                                </Stack.Item>
                            )}
                            {config?.result && !config.result.disabled && (
                                <Stack.Item>
                                    <Result config={config.result} showSettings={() => setSettingsState({ stage: ProcessStages.Result, pivot: "common_ui" })}
                                        showBarcodes={() => setSettingsState({ stage: ProcessStages.Result, pivot: "result_barcodes" })}
                                        targetBarcodes={isContentTransfer}/>
                                </Stack.Item>
                            )}
                        </Stack>
                        <Panel styles={settingsState?.stage === ProcessStages.Resources ? resourcesPanelStyles : stagesPanelStyles}
                            headerText={getPanelHeaderText(settingsState?.stage)} type={PanelType.custom}
                            customWidth={'1000px'}
                            isOpen={settingsState?.stage !== undefined ? true : false} onDismiss={() => setSettingsState(undefined)}
                            onRenderHeader={settingsState?.stage !== undefined ? (hProps, render) => onRenderPanelHeader(settingsState.stage, hProps, render) : undefined}
                            onRenderFooter={ isContentTransfer ? onRenderPanelFooterContentTransfer : onRenderPanelFooter} isFooterAtBottom
                            headerTextProps={{style: {display: 'inline-block'}}}
                            >
                            <Stack tokens={{ childrenGap: 16 }} verticalFill grow>
                                {settingsState?.stage === ProcessStages.Resources && (
                                    <ResourcesConfigurator channel={props.channel}
                                        config={config.resources}
                                        onChangeDispatch={props.onChangeDispatch}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.Identification && (
                                    <StageSettingsPivotBasedState
                                        channel={props.channel}
                                        state={settingsState?.pivot}
                                        config={config.identification}
                                        onChangeDispatch={props.onChangeDispatch}
                                        stage={settingsState?.stage}
                                        withBack={ctIdentification}
                                        withoutTitle={ctIdentification}
                                        withoutInformation={ctIdentification}
                                        pivots={getIdentificationPivots(config.identification)}
                                        additionalPivot={<IdentificationStageAdditionalSettingsPivot channel={props.channel} config={config.identification} onChangeDispatch={props.onChangeDispatch} />}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.ContentTransfer && (
                                    <ContentTransferStateSettingsPivotBasedState
                                        channel={props.channel}
                                        state={settingsState?.pivot}
                                        config={config.contentTransfer}
                                        onChangeDispatch={props.onChangeDispatch}
                                        stage={settingsState?.stage}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.PreOffer && (
                                    <PreOfferStageSettingsPivotBasedState
                                        channel={props.channel}
                                        state={settingsState?.pivot}
                                        config={config.preOffer}
                                        stage={settingsState?.stage}
                                        onChangeDispatch={props.onChangeDispatch}
                                        pivots={getCommonOfferPivots(ProcessStages.PreOffer)}
                                        secondPivot={settingsState?.secondPivot}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.Assessment && (
                                    <StageSettingsPivotBasedState
                                        channel={props.channel}
                                        state={settingsState?.pivot}
                                        config={config.assessment}
                                        onChangeDispatch={props.onChangeDispatch}
                                        stage={settingsState?.stage}
                                        withoutTitle
                                        pivots={getAssessmentPivots()}
                                        confirmationChildren={getAssessmentConfirmationAddon()}
                                        additionalPivot={<StageAdditionalSettingsPivot channel={props.channel} stage={settingsState.stage} config={config.assessment} onChangeDispatch={props.onChangeDispatch} />}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.PostOffer && (
                                    <PostOfferStageSettingsPivotBasedState
                                        channel={props.channel}
                                        state={settingsState?.pivot}
                                        config={config.postOffer}
                                        stage={settingsState?.stage}
                                        onChangeDispatch={props.onChangeDispatch}
                                        pivots={getCommonOfferPivots(ProcessStages.PostOffer)}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.Contract && (
                                    <StageSettingsPivotBasedState
                                        channel={props.channel}
                                        state={settingsState?.pivot}
                                        config={config.contract}
                                        onChangeDispatch={props.onChangeDispatch}
                                        stage={settingsState?.stage}
                                        withoutTitle={isContentTransfer}
                                        commonUIChildren={config.contract ? <ContractCommonUIChildren config={config.contract} onChangeDispatch={props.onChangeDispatch} /> : undefined}
                                        additionalPivot={<ContractStageAdditionalSettingsPivot channel={props.channel} config={config.contract} onChangeDispatch={props.onChangeDispatch} />}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.Control && (
                                    <StageSettingsPivotBasedState
                                        channel={props.channel}
                                        state={settingsState?.pivot}
                                        config={config.control}
                                        onChangeDispatch={props.onChangeDispatch}
                                        stage={settingsState?.stage}
                                        additionalPivot={<StageAdditionalSettingsPivot channel={props.channel} stage={settingsState.stage} config={config.control} onChangeDispatch={props.onChangeDispatch} />}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.Result && (
                                    <ResultSettingsPivotBasedState
                                        serviceType={config.serviceType}
                                        channel={props.channel}
                                        state={settingsState?.pivot}
                                        config={config.result}
                                        onChangeDispatch={props.onChangeDispatch}
                                        stage={settingsState?.stage}
                                        pivots={config.result ? getResultPivots(config.result, props.channel) : undefined}
                                        commonButtonsChildren={config.result ? <ResultCommonButtonsChildren channel={props.channel} config={config.result} serviceType={config.serviceType} onChangeDispatch={props.onChangeDispatch} /> : undefined}
                                        commonUIChildren={config.result ? <ResultCommonUIChildren config={config.result} onChangeDispatch={props.onChangeDispatch} /> : undefined}
                                        withoutNextTeaching
                                        withoutTitle={isContentTransfer}
                                    />
                                )}
                                {settingsState?.stage === ProcessStages.SelfService && (
                                    <SelfServiceSettingsPivotBasedState
                                        state={settingsState?.pivot}
                                        config={config.selfService}
                                        stage={settingsState?.stage}
                                        onChangeDispatch={props.onChangeDispatch}
                                        channel={props.channel}
                                        offerProviderCode={settingsState?.offerProviderCode}
                                  />
                                )}
                            </Stack>
                        </Panel>
                    </Stack.Item>
                </Stack>
            </ScrollablePane>
        </Stack>
    )
}

const theme = getTheme()

const resourcesPanelStyles: Partial<IPanelStyles> = {
    scrollableContent: {
        height: '100%',
        overflow: 'hidden',
        display: "flex",
        flexGrow: 1
    },
    content: {
        heigth: "100%",
        display: "flex",
        flexGrow: 1,
        paddingLeft: 0,
        paddingRight: 0
    }
}

const stagesPanelStyles: Partial<IPanelStyles> = {
    scrollableContent: {
        height: '100%',
        overflow: 'hidden',
    },
    content: {
        overflowY: 'auto'
    },
    footer: {
        padding: 0
    },
    header: {
        display: 'inline-block'
    }
}
