import { FollowUpQuestionIds, FollowUpQuestionType, FollowUpResponseIds, IDisclaimer, IFollowUpQuestion, IFollowUpResponse, ISelfServiceWorkflowConfig, SelfServiceRule } from "@piceasoft/core"
import { TStageSettingsPivotStates } from "../../../Configurator"
import { Dispatch, useState } from "react"
import { ComboBox, FontIcon, IComboBoxOption, IOnRenderComboBoxLabelProps, IconButton, PrimaryButton, ScrollablePane, Stack, Text, TextField, Toggle, getTheme } from "@fluentui/react"
import { strings } from "../../../../../../localization/strings"
import { CustomPanel } from "../../../../panel/CustomPanel"
import { useBoolean } from "@uifabric/react-hooks"
import { SecondaryButton } from "../../../../buttons/SecondaryButton"
import { Controller, useForm } from "react-hook-form"
import { onRenderLabel } from "../../../../../renders/onRenderLabel"

interface TProps {
    state: TStageSettingsPivotStates
    config?: ISelfServiceWorkflowConfig
    pivots?: { name: string, component: React.ReactNode }[]
    onChangeDispatch: Dispatch<any>
}

type TDisclaimerData = {
    name: string,
    id: number
}

type TRuleData = {
    name: string,
    id: string
}

type TResponseEditData = {
    data: IFollowUpResponse
    disclaimers: TDisclaimerData[]
    rules: TRuleData[]
    showDisclaimers?: boolean
    nextQuestion?: string
}

const getResponseText = ( id: string): string => {
    if( FollowUpResponseIds.ProblemSolved_Yes === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_YES;
    if( FollowUpResponseIds.ProblemSolved_No === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_NO;
    if( FollowUpResponseIds.ProblemSolved_Partial === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_PARTLY;
    if( FollowUpResponseIds.ProblemSolved_NotSure === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_NOT_SURE;

    if( FollowUpResponseIds.UnderWarranty_Yes === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_YES;
    if( FollowUpResponseIds.UnderWarranty_No === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_NO;
    if( FollowUpResponseIds.UnderWarranty_NotSure === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_NOT_SURE;

    if( FollowUpResponseIds.RepairDelivery_BringToStore === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_BRING_TO_STORE;
    if( FollowUpResponseIds.RepairDelivery_Pickup === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_PICK_UP_SERVICE

    return ''
}

const getRuleName = (id: SelfServiceRule): string => {

    if( id === SelfServiceRule.ShowRecommendations_warrantyActive) {
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_RULE.RECOMMENDATIONS
    }
    if( id === SelfServiceRule.ShowRecommendations_warrantyNotActive) {
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_RULE.RECOMMENDATIONS
    }
    if( id === SelfServiceRule.ShowRecommendations_problemsSolved) {
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_RULE.RECOMMENDATIONS
    }
    if( id === SelfServiceRule.MoreQuestions) {
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_RULE.QUESTION
    }
    if( id === SelfServiceRule.ShowRANForm) {
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_RULE.RAN_FORM
    }
    if( id === SelfServiceRule.ShowContactForm) {
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_RULE.CONTACT_FORM
    }
    if( id === SelfServiceRule.ExcludeOffers) {
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_RULE.EXCLUDE_OFFERS
    }
    return ''
}

const getShowDisclaimers = ( id: string): boolean => {
    if( FollowUpResponseIds.RepairDelivery_BringToStore === id)
        return true
    if( FollowUpResponseIds.RepairDelivery_Pickup === id)
        return true
    if( FollowUpResponseIds.UnderWarranty_Yes === id)
        return true
    if( FollowUpResponseIds.UnderWarranty_No === id)
        return true
    if( FollowUpResponseIds.UnderWarranty_NotSure === id)
        return true

    return false
}

const getNextQuestion = (id: string): string | undefined => {
    if( FollowUpResponseIds.ProblemSolved_No === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.WARRANTY.QUESTION
    if( FollowUpResponseIds.ProblemSolved_Partial === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.WARRANTY.QUESTION
    if( FollowUpResponseIds.ProblemSolved_NotSure === id)
        return strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.WARRANTY.QUESTION

    return undefined
}

const getRules = (id: string): TRuleData[] => {
    if( FollowUpResponseIds.UnderWarranty_No === id || FollowUpResponseIds.UnderWarranty_NotSure === id) {
        return [
        {
            id: SelfServiceRule.ShowRecommendations_warrantyNotActive,
            name: getRuleName( SelfServiceRule.ShowRecommendations_warrantyNotActive)
        },
        // We add an option to allow the user to show repair offers even on the passed diagnostics results
        {
            id: SelfServiceRule.ExcludeOffers,
            name: getRuleName( SelfServiceRule.ExcludeOffers)
        }]
    }
    if( FollowUpResponseIds.UnderWarranty_Yes === id) {
        return [
        {
            id: SelfServiceRule.ShowRecommendations_warrantyActive,
            name: getRuleName( SelfServiceRule.ShowRecommendations_warrantyActive)
        },
        // We add an option to allow the user to show repair offers even on the passed diagnostics results
        {
            id: SelfServiceRule.ExcludeOffers,
            name: getRuleName( SelfServiceRule.ExcludeOffers)
        }]
    }

    if( FollowUpResponseIds.RepairDelivery_BringToStore === id) {
        return [{
            id: SelfServiceRule.ShowContactForm,
            name: getRuleName( SelfServiceRule.ShowContactForm)
        }]
    }
    if( FollowUpResponseIds.RepairDelivery_Pickup === id) {
        return [{
            id: SelfServiceRule.ShowRANForm,
            name: getRuleName( SelfServiceRule.ShowRANForm)
        }]
    }

    if( FollowUpResponseIds.ProblemSolved_Yes === id) {
        return [{
            id: SelfServiceRule.ShowRecommendations_problemsSolved,
            name: getRuleName( SelfServiceRule.ShowRecommendations_problemsSolved)
        }]
    }
    if( FollowUpResponseIds.ProblemSolved_No === id || FollowUpResponseIds.ProblemSolved_Partial === id || FollowUpResponseIds.ProblemSolved_NotSure === id) {
        return [{
            id: FollowUpQuestionIds.UnderWarrantyQuestion,
            name: getRuleName( SelfServiceRule.MoreQuestions)
        }]
    }

    return []
}

const SelfServiceFollowUpQuestions: React.FC<TProps> = (props: TProps) => {
    const [isEditOpen, {setTrue: showEdit, setFalse: hideEdit}] = useBoolean(false)
    const [responseToEdit, setResponseToEdit] = useState<TResponseEditData | undefined>(undefined)
    const availableDisclaimers = props.config?.disclaimers.map( ( d:IDisclaimer) => {
        return {
            id: d.id,
            name: d.name
        } as TDisclaimerData
    })
    const deliveryEnabled = !!props.config?.followUpQuestions.find( (q:IFollowUpQuestion) => FollowUpQuestionIds.RepairDeliveryQuestion === q.id) 

    const getDisclaimers = (id: string): TDisclaimerData[] => {
        if( id === FollowUpResponseIds.UnderWarranty_Yes || 
            id === FollowUpResponseIds.UnderWarranty_No || 
            id === FollowUpResponseIds.UnderWarranty_NotSure || 
            id === FollowUpResponseIds.RepairDelivery_Pickup || 
            id === FollowUpResponseIds.RepairDelivery_BringToStore) 
        {
            return availableDisclaimers ?? [];
        }
        return []
    }

    const findResponse = (id:string): IFollowUpResponse => {
        let item = undefined;
        props.config?.followUpQuestions.forEach( ( q:IFollowUpQuestion) => {
            const r = q.responses.find( (r:IFollowUpResponse) => r.id === id)
            if( r) {
                item = r;
            }
        })
        if( item) {
            return item;
        }

        return {
            id: id,
            text: getResponseText( id),
            ruleId: '',
            disclaimerId: 0
        } as IFollowUpResponse
    }

    const configureAnswer = (id:string) => {
        const respProps = {
            data: findResponse( id),
            disclaimers: getDisclaimers( id),
            rules: getRules( id),
            showDisclaimers: getShowDisclaimers( id),
            nextQuestion: getNextQuestion( id)
        } as TResponseEditData

        //  Select 1st rule by default
        if( respProps.data.ruleId === '' && respProps.rules.length > 0 ) {
            respProps.data.ruleId = respProps.rules[0].id;
        }

        setResponseToEdit( respProps);
        showEdit()
    }

    const onCancelEdit = () => {
        hideEdit();
        setResponseToEdit( undefined);
    }

    const commitResponse = (response: IFollowUpResponse) => {
        const selfService = JSON.parse( JSON.stringify( props.config))

        selfService.followUpQuestions?.forEach( (q:IFollowUpQuestion) => {
            q.responses = q.responses.map( ( r:IFollowUpResponse) => {
                if( r.id === response.id) {
                    return response
                }
                return r;
            })
        })
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: selfService})
        onCancelEdit();
    }

    const toggleDelivery = () => {
        const selfService = {...props.config}
        if( deliveryEnabled) {
            selfService.followUpQuestions = selfService.followUpQuestions?.filter( (q:IFollowUpQuestion) => FollowUpQuestionIds.RepairDeliveryQuestion != q.id)
        }
        else {
            selfService.followUpQuestions?.push( {
                id: FollowUpQuestionIds.RepairDeliveryQuestion,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DELIVERY.QUESTION,
                type: FollowUpQuestionType.AcceptOffer,
                responseId: '',
                responses: [{
                    id: FollowUpResponseIds.RepairDelivery_BringToStore,
                    text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_BRING_TO_STORE,
                    ruleId: SelfServiceRule.ShowContactForm,
                    disclaimerId: 0
                },
                {
                    id: FollowUpResponseIds.RepairDelivery_Pickup,
                    text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_PICK_UP_SERVICE,
                    ruleId: SelfServiceRule.ShowRANForm,
                    disclaimerId: 0
                }]
            })
        }
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: selfService})
    }

    const getResponseItemText = ( id: string) => {
        const item = findResponse( id);
        return item.text;
    }
    
    return (<>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 4}}>
        <Text variant="large" style={{ paddingRight: 32, paddingBottom: 8, fontWeight: 600 }}>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.SOLUTION_TITLE}</Text>
        <QuestionBox text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.PROBLEM_SOLVED.QUESTION} iconName="Unknown" minHeight={64}/>
        <QuestionBox id={FollowUpResponseIds.ProblemSolved_Yes} text={getResponseItemText( FollowUpResponseIds.ProblemSolved_Yes)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>
        <QuestionBox id={FollowUpResponseIds.ProblemSolved_No} text={getResponseItemText( FollowUpResponseIds.ProblemSolved_No)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>
        <QuestionBox id={FollowUpResponseIds.ProblemSolved_Partial} text={getResponseItemText( FollowUpResponseIds.ProblemSolved_Partial)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>
        <QuestionBox id={FollowUpResponseIds.ProblemSolved_NotSure} text={getResponseItemText( FollowUpResponseIds.ProblemSolved_NotSure)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>

        <QuestionBox text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.WARRANTY.QUESTION} iconName="Unknown" minHeight={64}/>
        <QuestionBox id={FollowUpResponseIds.UnderWarranty_Yes} text={getResponseItemText( FollowUpResponseIds.UnderWarranty_Yes)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>
        <QuestionBox id={FollowUpResponseIds.UnderWarranty_No} text={getResponseItemText( FollowUpResponseIds.UnderWarranty_No)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>
        <QuestionBox id={FollowUpResponseIds.UnderWarranty_NotSure} text={getResponseItemText( FollowUpResponseIds.UnderWarranty_NotSure)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>

        <Text variant="large" style={{ paddingRight: 32, paddingTop: 8, paddingBottom: 8, fontWeight: 600 }}>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.REPAIR_TITLE}</Text>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 8}}>
            <div style={{marginTop: 22}}>
                <Toggle
                    onText={strings.BUTTONS.TOGGLE_ON} 
                    offText={strings.BUTTONS.TOGGLE_OFF}
                    checked={deliveryEnabled}
                    onChange={toggleDelivery}
                />
            </div>
            { deliveryEnabled && (<div style={{ flexGrow: 99, display: 'flex', flexDirection: 'column', gap: 4}}>
                <QuestionBox text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DELIVERY.QUESTION} iconName="Unknown" minHeight={64} />
                <QuestionBox id={FollowUpResponseIds.RepairDelivery_BringToStore}  text={getResponseItemText( FollowUpResponseIds.RepairDelivery_BringToStore)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>
                <QuestionBox id={FollowUpResponseIds.RepairDelivery_Pickup} text={getResponseItemText( FollowUpResponseIds.RepairDelivery_Pickup)} iconName="BulletedList2" minHeight={45} onConfigure={configureAnswer}/>
            </div>)}
        </div>
    </div>

    <CustomPanel
        isOpen={isEditOpen}
        onCancel={onCancelEdit}
        noCancelOnDissmiss={true}
        title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_PRESETS}
        >
            { responseToEdit && (<ResponseEditor responseData={responseToEdit} commitChanges={commitResponse} onCancel={onCancelEdit}/>)}
    </CustomPanel>
    </>)
}

export default SelfServiceFollowUpQuestions;

type TQuestionBoxProps = {
    text: string
    iconName: string
    minHeight: number
    id?: string
    onConfigure?: (id:string) => void
}

const QuestionBox: React.FC<TQuestionBoxProps> = (props:TQuestionBoxProps) => {

    const configure = () => {
        if( props.onConfigure && props.id) {
            props.onConfigure( props.id)
        }
    }

    return (<div 
        style={{ 
            width: '100%', 
            minHeight: props.minHeight+'px', 
            display: 'flex', 
            flexDirection: 'row', 
            borderColor: '#EDEBE9', 
            borderWidth: 1, 
            borderStyle: 'solid',
            gap: '14px',
            alignItems: 'center',
            paddingLeft: '20px',
            paddingRight: '13px',
            boxShadow: "0px 1.6px 3.6px 0px #00000021"
        }}
        >
        <FontIcon style={{fontSize: '24px', fontWeight: 400}} iconName={props.iconName}/>
        <Text variant="medium" style={{ fontWeight: 600, flexGrow: 99 }}>{props.text}</Text>
        {props.onConfigure && (<IconButton 
            iconProps={{ iconName: "Settings" }} 
            onClick={configure} /> )}
    </div>)
}

type TResponseProps = {
    responseData: TResponseEditData
    onCancel: () => void
    commitChanges: ( r: IFollowUpResponse) => void
}

const theme = getTheme()

const ResponseEditor: React.FC<TResponseProps> = (props:TResponseProps) => {

    const data = props.responseData.data
    const [showDisclaimer, setShowDisclaimer] = useState<boolean>( props.responseData.disclaimers.find( (d) => d.id === props.responseData.data.disclaimerId) ? true:false)
    const { control, handleSubmit, getValues, setValue, formState: { errors, isValid }, clearErrors, trigger } = useForm<IFollowUpResponse>({ defaultValues: { ...props.responseData.data } });

    const disclaimers = props.responseData.disclaimers.map( ( d:TDisclaimerData) => {
        return {
            key: d.id,
            text: d.name
        } as IComboBoxOption
    })

    const rules = props.responseData.rules.map( (r:TRuleData) => {
        return {
            key: r.id,
            text: r.name
        } as IComboBoxOption
    })

    const onSubmit = (data: IFollowUpResponse) => {
        if( !showDisclaimer) {
            data.disclaimerId = 0;
        }
        props.commitChanges(data)
    }

    const onRenderDisclaimersLabel = (props?: IOnRenderComboBoxLabelProps): JSX.Element => {
        return (
          <Stack horizontal verticalAlign="center">
            <Text variant="medium" style={{ fontWeight: 600 }}>{props?.props.label}</Text>
            {props?.props.required && <Text variant="medium" style={{ color: theme.palette.red, paddingLeft: 4}}>*</Text>}
            <IconButton
              iconProps={{ iconName: 'Info' }}
              title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DISCLAIMER.TOOLTIP}
              ariaLabel={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DISCLAIMER.TOOLTIP}
              styles={{ root: { marginBottom: -3 } }}
            />
          </Stack>
        );
      };

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                <ScrollablePane>
                <Stack verticalFill tokens={{ padding: "0px 20px 20px 20px", childrenGap: 16 }}>
                <Controller
                    control={control}
                    name={"text"}
                    rules={{
                        required: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE.REQUIRED,
                        validate: (value) => {
                            if (data && data.text === value) {
                                return true;
                            }
                            return "" !== value
                        }
                    }}
                    render={({ field }) =>
                        <TextField
                            required
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            style={{ width: 350 }}
                            onRenderLabel={onRenderLabel}
                            label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE.LABEL}
                            placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE.PLACEHOLDER}
                            errorMessage={errors.text?.message}
                        />
                    }
                />
                {rules && rules.length === 1 && (
                    <TextField
                        readOnly
                        value={rules[0].text}
                        onRenderLabel={onRenderLabel}
                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RULE.LABEL}
                    />
                )}
                { rules && rules.length > 1 && (<Controller
                    control={control}
                    name={"ruleId"}
                    rules={{
                        required: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RULE.REQUIRED,
                        validate: (value) => {
                            if (data && data.ruleId === value) {
                                return true;
                            }
                            return "" !== value
                        }
                    }}
                    render={({field}) =>
                        <ComboBox {...field}
                            className="single-select"
                            selectedKey={field.value}
                            disabled={rules.length < 2}
                            onChange={(ev, opt) => field.onChange(opt?.key)}
                            options={rules}
                            label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RULE.LABEL}
                            placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RULE.PLACEHOLDER}
                            required
                            errorMessage={errors.ruleId?.message}
                        />
                    }
                />)}
                {props.responseData.nextQuestion && (
                    <TextField
                        readOnly
                        value={props.responseData.nextQuestion}
                        onRenderLabel={onRenderLabel}
                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RESPONSE_RULE.QUESTION}
                    />
                )}
                { props.responseData.showDisclaimers && (<Toggle
                    checked={showDisclaimer}
                    onChange={(ev, checked) => setShowDisclaimer(checked ?? false)}
                    label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DISCLAIMER.TOGGLE_TITLE}
                    onText={strings.BUTTONS.TOGGLE_ON} 
                    offText={strings.BUTTONS.TOGGLE_OFF}
                />)}
                { showDisclaimer && (<Controller 
                    control={control}
                    name='disclaimerId'
                    rules={{
                        required: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DISCLAIMER.REQUIRED,
                        validate: (value) => {
                            if (data && data.disclaimerId === value) {
                                return true;
                            }
                            return value > 0
                        }
                    }}
                    render={({field}) =>
                        <ComboBox {...field}
                            className="single-select"
                            selectedKey={field.value}
                            disabled={disclaimers.length < 1}
                            onChange={(ev, opt) => field.onChange(opt?.key)}
                            options={disclaimers}
                            onRenderLabel={onRenderDisclaimersLabel}
                            label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DISCLAIMER.LABEL}
                            placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DISCLAIMER.PLACEHOLDER}
                            required
                            errorMessage={errors.disclaimerId?.message}
                        />
                    }
                />)}

                </Stack>
                </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <PrimaryButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}
