import { Stack, TextField, PrimaryButton, ScrollablePane, Toggle, Dropdown } from '@fluentui/react';
import { getTheme } from '@fluentui/style-utilities';
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { prepareCurrencyOptionsRequired } from '../../../core/helpers/currency';
import { ICatalog, IStore } from '../../../core/store';
import { strings } from '../../../localization/strings';
import { onRenderLabel } from '../../renders/onRenderLabel'
import { SecondaryButton } from '../buttons/SecondaryButton';
import { useSelector } from 'react-redux';
import { CSHHelpLink } from '../help/CSHHelp';

type TProps = {
    onSubmit: (catalog: ICatalog) => void
    onCancel: () => void
    data?: ICatalog
    organizationId: string
    catalogBusy: boolean
}

export const CatalogForm: React.FC<TProps> = (props) => {
    const organizationCurrency = useSelector((s: IStore) => s.workplace.organization?.currency);
    const { control, handleSubmit, formState: { errors } } = useForm<ICatalog>({ defaultValues: { organizationId: props.organizationId, ...props.data, currency: props.data?.currency ?? organizationCurrency } });
    const onSubmit: SubmitHandler<ICatalog> = data => {
        console.log(data)
        props.onSubmit(data)
    };

    const currency = prepareCurrencyOptionsRequired();
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ 
                                    validate: (value)=> value && value.trim().length > 0 ? true : strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.NAME_REQUIRED,
                                    required: strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.NAME_REQUIRED
                                 }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.NAME}
                                        title={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.NAME}
                                        placeholder={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                name="summary"
                                control={control}
                                // rules={{ required: strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.SUMMARY_REQUIRED }}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        multiline
                                        resizable={false}
                                        style={{ height: 100 }}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.SUMMARY}
                                        title={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.SUMMARY}
                                        placeholder={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.SUMMARY_PLACEHOLDER}
                                        errorMessage={errors.summary?.message}
                                    />
                                }
                            />
                            <Controller
                                name="currency"
                                control={control}
                                rules={{ required: strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.CURRENCY_REQUIRED }}
                                render={({ field }) =>
                                    <Dropdown
                                        required
                                        selectedKey={field.value}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.CURRENCY}
                                        placeholder={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.CURRENCY_PLACEHOLDER}
                                        options={currency}
                                        errorMessage={errors.currency?.message}
                                    />}
                            />
                            <Controller
                                name="isFree"
                                control={control}
                                render={({ field }) =>
                                    <Toggle
                                        checked={field.value}
                                        onChange={(ev, checked) => field.onChange(checked)}
                                        onBlur={field.onBlur}
                                        label={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.FREE}
                                        offText={strings.BUTTONS.TOGGLE_NO}
                                        onText={strings.BUTTONS.TOGGLE_YES}
                                    />
                                }
                            />
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink articleid='1112' alignment='left' />
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton disabled={props.catalogBusy} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();

