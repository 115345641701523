import { getTheme, mergeStyleSets, SharedColors, Stack, Text } from "@fluentui/react"

type TProps = {
    name: string
    value: string
    maxWidth?: number | string
    onValueRender?: (value: string) => React.ReactNode
}

export const TransactionTabEntry: React.FC<TProps> = (props) => {
    return (
        <Stack horizontal>
            <Stack.Item grow>
                <Text block className={styles.subtext}>{props.name}</Text>
            </Stack.Item>
            <Stack>
                {<Text block className={styles.value}>{props.onValueRender ? props.onValueRender(props.value) : props.value}</Text>}
            </Stack>
        </Stack>
    )
}

const theme = getTheme()

const styles = mergeStyleSets({
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            color: SharedColors.gray20,
            minWidth: 80
        },
    ],
    value: [
        theme.fonts.medium,
        {
            margin: 0,
            color: SharedColors.cyanBlue10,
            textAlign: "right"
        },
    ]
})