import type { FieldSettingsData, FieldType } from "../../../dataCollectionTypes"
import { strings } from "../../../../../../../../../localization/strings"
import { getTheme, IColumn, Stack, Text, IconButton, IContextualMenuProps, DirectionalHint, IButtonStyles } from "@fluentui/react"
import { onRenderColumnHeaderCommon } from "../../../../../../../detailsList/commonRenders"
import { IContractFieldWithMask } from "@piceasoft/core"
import { IContractField, IDataCollectionStandardConfig, IContractFieldCommon } from "@piceasoft/core"
export function getFields(config: IDataCollectionStandardConfig) {
    const fields: FieldSettingsData[] = []

    if (config.fields?.name) {
        fields.push({
            id: 'name',
            type: 'standard',
            label: config.fields?.name.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.NAME.TITLE,
            minLength: config.fields?.name.minLength,
            maxLength: config.fields?.name.maxLength,
            placeholder: config.fields?.name.placeholder
        })
    }

    if (config.fields?.phone) {
        fields.push({
            id: 'phone',
            type: 'masked',
            mask: config.fields?.phone?.mask,
            label: config.fields?.phone?.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.PHONE.TITLE,
            minLength: config.fields?.phone.minLength,
            maxLength: config.fields?.phone.maxLength,
            placeholder: config.fields?.phone.placeholder,
            disableSlider: true
        })
    }
    if (config.fields?.email) {
        fields.push({
            id: 'email',
            type: 'common',
            label: config.fields?.email?.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.EMAIL.TITLE,
            placeholder: config.fields?.email.placeholder
        })
    }

    if (config.fields?.passport?.number) {
        fields.push({
            id: 'passport.number',
            type: 'masked',
            mask: config.fields?.passport?.number.mask,
            label: config.fields?.passport?.number.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.PASSPORT.NUMBER.TITLE,
            minLength: config.fields?.passport?.number.minLength,
            maxLength: config.fields?.passport?.number.maxLength,
            placeholder: config.fields?.passport?.number.placeholder
        })
    }

    if (config.fields?.passport?.issuedBy) {
        fields.push({
            id: 'passport.issuedBy',
            type: 'standard',
            label: config.fields?.passport?.issuedBy.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.PASSPORT.ISSUED_BY.TITLE,
            minLength: config.fields?.passport?.issuedBy.minLength,
            maxLength: config.fields?.passport?.issuedBy.maxLength,
            placeholder: config.fields?.passport?.issuedBy.placeholder
        })
    }

    if (config.fields?.passport?.dateOfIssue) {
        fields.push({
            id: 'passport.dateOfIssue',
            type: 'common',
            label: config.fields?.passport?.dateOfIssue.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.PASSPORT.DATE_OF_ISSUE.TITLE,
            placeholder: config.fields?.passport?.dateOfIssue.placeholder
        })
    }

    if (config.fields?.passport?.subdivisionCode) {
        fields.push({
            id: 'passport.subdivisionCode',
            type: 'masked',
            mask: config.fields?.passport?.subdivisionCode.mask ?? "999-999",
            label: config.fields?.passport?.subdivisionCode.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.PASSPORT.SUBDIVISION_CODE.TITLE,
            minLength: config.fields?.passport?.subdivisionCode.minLength,
            maxLength: config.fields?.passport?.subdivisionCode.maxLength,
            placeholder: config.fields?.passport?.subdivisionCode.placeholder,
            hideSlider: true
        })
    }

    if (config.fields?.passport?.dateOfBirth) {
        fields.push({
            id: 'passport.dateOfBirth',
            type: 'common',
            label: config.fields?.passport?.dateOfBirth.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.PASSPORT.DATE_OF_BIRTH.TITLE,
            placeholder: config.fields?.passport?.dateOfBirth.placeholder
        })
    }

    if (config.fields?.passport?.placeOfBirth) {
        fields.push({
            id: 'passport.placeOfBirth',
            type: 'standard',
            label: config.fields?.passport?.placeOfBirth.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.PASSPORT.PLACE_OF_BIRTH.TITLE,
            minLength: config.fields?.passport?.placeOfBirth.minLength,
            maxLength: config.fields?.passport?.placeOfBirth.maxLength,
            placeholder: config.fields?.passport?.placeOfBirth.placeholder
        })
    }
    if (config.fields?.passport?.address) {
        fields.push({
            id: 'passport.address',
            type: 'standard',
            label: config.fields?.passport?.address.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.PASSPORT.ADDRESS.TITLE,
            minLength: config.fields?.passport?.address.minLength,
            maxLength: config.fields?.passport?.address.maxLength,
            placeholder: config.fields?.passport?.address.placeholder
        })
    }

    if (config.fields?.operator) {
        fields.push({
            id: 'operator',
            type: 'standard',
            label: config.fields?.operator.label,
            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FIELDS.OPERATOR.TITLE,
            minLength: config.fields?.operator.minLength,
            maxLength: config.fields?.operator.maxLength,
            placeholder: config.fields?.operator.placeholder
        })
    }

    config.fields?.custom?.map(cf => {
        fields.push({
            id: cf.key,
            type: 'custom',
            mask: cf.mask,
            title: cf.key,
            label: cf.label,
            required: cf.required,
            minLength: cf.minLength,
            maxLength: cf.maxLength,
            placeholder: cf.placeholder,
        })
    })

    return fields
}

let theme = getTheme()

export function getColumns(
    onDelete: (fieldId: string, fieldType: FieldType) => void,
    onEdit: (item: FieldSettingsData) => void
): IColumn[] {
    return [
        {
            key: 'column_title',
            fieldName: 'title',
            name: "Field name",
            minWidth: 100,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: FieldSettingsData) => (
                <Stack verticalFill horizontal verticalAlign="center">
                    <Text style={{ color: theme.palette.black }}>
                        {item.title}
                    </Text>
                    {item.required && <span style={{ color: theme.palette.red, marginLeft: '5px' }}>*</span>}
                </Stack>
            ),
            isPadded: true,
        },
        {
            key: 'column_label',
            fieldName: 'label',
            name: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.LABEL_TITLE,
            minWidth: 100,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: FieldSettingsData) => (
                <Stack verticalFill verticalAlign="center">
                    <Text style={{ color: theme.palette.black }}>{item.label}</Text>
                </Stack>
            ),
            isPadded: true,
        },
        {
            key: 'column_placeholder',
            fieldName: 'placeholder',
            name: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.PLACEHOLDER_TITLE,
            minWidth: 50,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: FieldSettingsData) => (
                <Stack verticalFill verticalAlign="center" >
                    <Text style={{ color: theme.palette.black }}>{item.placeholder}</Text>
                </Stack>
            ),
            isPadded: true,
        },
        {
            key: 'column_minLength',
            fieldName: 'minLength',
            name: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.MIN_LENGTH,
            minWidth: 50,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: FieldSettingsData) => (
                <Stack verticalFill verticalAlign="center">
                    <Text style={{ color: theme.palette.black }}>
                        {item.type === "common"
                            ? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.AUTO
                            : item.minLength}
                    </Text>
                </Stack>
            ),
            isPadded: true,
        },
        {
            key: 'column_maxLength',
            fieldName: 'maxLength',
            name: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.MAX_LENGTH,
            minWidth: 50,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: FieldSettingsData) => (
                <Stack verticalFill verticalAlign="center">
                    <Text style={{ color: theme.palette.black }}>
                        {item.type === "common"
                            ? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.AUTO
                            : item.maxLength}
                    </Text>
                </Stack>
            ),
            isPadded: true,
        },
        {
            key: 'column_mask',
            fieldName: 'mask',
            name: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.MASK_TITLE,
            minWidth: 50,
            maxWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: FieldSettingsData) => (
                <Stack verticalFill verticalAlign="center">
                    <Text style={{ color: theme.palette.black }}>
                        {item.type === "common" || item.type === "standard"
                            ? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.AUTO
                            : item.mask}
                    </Text>
                </Stack>
            ),
            isPadded: true,
        },
        {
            key: 'commandBar',
            name: '',
            fieldName: 'commandBar',
            minWidth: 70,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: FieldSettingsData) => {
                return (
                    <Stack horizontal verticalFill horizontalAlign='end' verticalAlign='center' onClick={(ev) => ev.stopPropagation()}>
                        <Stack.Item>
                            <IconButton
                                styles={menuIconStyles}
                                menuProps={getItemMenuProps(item, onEdit, onDelete)}
                                menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }}
                            />
                        </Stack.Item>
                    </Stack>
                )
            }
        }
    ]
}

const getItemMenuProps = (
    item: FieldSettingsData,
    onEdit: (item: FieldSettingsData) => void,
    onDelete: (fieldId: string, fieldType: FieldType) => void
): IContextualMenuProps => {
    return {
        items: [
            {
                key: `edit-${item.id}`,
                iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { onEdit(item) },
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
            },
            {
                key: `delete-${item.id}`,
                iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { onDelete(item.id, item.type) },
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
            }
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    }
}

export function getAddFieldMenuItems(
    config: IDataCollectionStandardConfig,
    createCustomField: () => void,
    setField: (fieldName: string, field: IContractField | IContractFieldWithMask | IContractFieldCommon | undefined) => void,
    setPassportField: (fieldName: string, field: IContractField | IContractFieldWithMask | undefined) => void
): IContextualMenuProps {
    return {
        items: [
            {
                key: "name",
                onClick: () => setField('name', config.fields?.name),
                disabled: config.fields?.name ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.NAME.TITLE,
            },
            {
                key: "phone",
                onClick: () => setField('phone', config.fields?.phone),
                disabled: config.fields?.phone ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PHONE.TITLE,
            },
            {
                key: "email",
                onClick: () => setField('email', config.fields?.email),
                disabled: config.fields?.email ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.EMAIL.TITLE,
            },
            {
                key: "passport",
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.TITLE,
                subMenuProps: {
                    items: [
                        {
                            key: "number",
                            onClick: () => setPassportField('number', config.fields?.passport?.number),
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.NUMBER.TITLE,
                            disabled: config.fields?.passport?.number ? true : false,
                        },
                        {
                            key: "dateOfIssue",
                            onClick: () => setPassportField('dateOfIssue', config.fields?.passport?.dateOfIssue),
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_ISSUE.TITLE,
                            disabled: config.fields?.passport?.dateOfIssue ? true : false,
                        },
                        {
                            key: "issuedBy",
                            onClick: () => setPassportField('issuedBy', config.fields?.passport?.issuedBy),
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ISSUED_BY.TITLE,
                            disabled: config.fields?.passport?.issuedBy ? true : false,
                        },
                        {
                            key: "subdivisionCode",
                            onClick: () => setPassportField('subdivisionCode', config.fields?.passport?.subdivisionCode),
                            disabled: config.fields?.passport?.subdivisionCode ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.SUBDIVISION_CODE.TITLE,
                        },
                        {
                            key: "dateOfBirth",
                            onClick: () => setPassportField('dateOfBirth', config.fields?.passport?.dateOfBirth),
                            disabled: config.fields?.passport?.dateOfBirth ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_BIRTH.TITLE,
                        },
                        {
                            key: "placeOfBirth",
                            onClick: () => setPassportField('placeOfBirth', config.fields?.passport?.placeOfBirth),
                            disabled: config.fields?.passport?.placeOfBirth ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.PLACE_OF_BIRTH.TITLE,
                        },
                        {
                            key: "address",
                            onClick: () => setPassportField('address', config.fields?.passport?.address),
                            disabled: config.fields?.passport?.address ? true : false,
                            text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ADDRESS.TITLE,
                        },
                    ]
                }
            },
            {
                key: "operator",
                onClick: () => setField('operator', config.fields?.operator),
                disabled: config.fields?.operator ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.OPERATOR.TITLE,
            },
            {
                key: "custom",
                onClick: () => createCustomField(),
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.CUSTOM.TITLE,
            },
        ]
    }
}

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}