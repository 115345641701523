import { getTheme, SharedColors, Stack, Text } from "@fluentui/react"
import { strings } from "../../../localization/strings"

export const PropertyValue: React.FC<{ label: string, value?: string }> = (props) => {
    return (
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item styles={{ root: { minWidth: 200, maxWidth: 200 } }}>
                <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 500 }}>{props.label}</Text>
            </Stack.Item>
            <Stack.Item styles={{ root: { maxWidth: 480, maxHeight: 480, overflow: 'hidden', overflowWrap: 'break-word', wordWrap: 'break-word' } }}>
                <Text variant="medium" style={{ color: SharedColors.gray30, }}>{props.value && props.value.length > 0 ? props.value : "–"}</Text>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
