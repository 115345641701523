import {
    DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint,
    FontWeights, getTheme, IButtonStyles, IColumn, IconButton, IContextualMenuProps,
    IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListProps,
    IDetailsRowStyles, IIconProps, IRenderFunction, mergeStyleSets, MessageBar,
    MessageBarType, Modal, PrimaryButton, ScrollablePane, SelectionMode,
    SharedColors, Spinner, Stack, Text, TooltipHost, Icon
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useHistory } from 'react-router';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { Section } from '../../components/shared/decorations/Section';
import { onRenderColumnHeaderCommon } from '../../components/shared/detailsList/commonRenders';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { portalApi } from '../../core/api/api';
import { IService, IStore } from '../../core/store';
import { strings } from '../../localization/strings';
import { SelfServicePromotion } from './SelfServicePromotion';
import { SelfServicePromotionForm } from '../../components/shared/forms/SelfServicePromotionForm';
import { CurrencyCodes, CurrencySigns, ISelfServicePromotion } from '@piceasoft/core';
import { getLocalizedCurrencyName } from '../../core/helpers/currency';
import { compareCurrentUTCDateTime } from '../../core/helpers/date-time'
import unknown from "../../assets/emptyimage.svg"


export const SelfServicePromotions: React.FC = () => {

    const [promotionsState, setPromotionsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<ISelfServicePromotion> }>({ fetched: false })

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [promotion, setPromotion] = React.useState<ISelfServicePromotion>();
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const [deletePromotionState, setDeletePromotionState] = React.useState<IService>()

    const history = useHistory();

    React.useEffect(() => {
        getPromotions()
    }, [])

    const getPromotions = async () => {
        setPromotionsState({ fetched: false })
        const result = await portalApi.organization.selfServicePromotion.getPromotions()
        console.log(result)
        setPromotionsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0].description : undefined, fetched: true, data: result.data })
    }

    const onPromotionDelete = () => {
        if (deletePromotionState?.id) {
            deletePromotion(deletePromotionState?.id)
            setDeletePromotionState(undefined)
        }
    }

    const deletePromotion = async (id: number) => {
        setPromotionsState({ ...promotionsState, error: undefined })
        const result = await portalApi.organization.selfServicePromotion.deletePromotion(id)
        if (result.successed) {
            if (promotionsState.data) {
                setPromotionsState({
                    ...promotionsState,
                    data: promotionsState.data.filter(c => c.id !== id)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setPromotionsState({ ...promotionsState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setPromotionsState({ ...promotionsState, error: strings.ORGANIZATION.PROMOTIONS.WARNINGS.PROMOTION_DELETE_FAILED });
        }
    }

    const onAddPromotion = () => {
        setFormError(undefined)
        stopFetch()
        showPanel()
    }

    const addPromotion = async (item: ISelfServicePromotion) => {
        startFetch()
        console.log(item)
        const result = await portalApi.organization.selfServicePromotion.addPromotion(item)
        if (result.successed && result.data) {
            console.log(result)
            stopFetch()
            hidePanel()
            getPromotions();
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const updatePromotion = async (item: ISelfServicePromotion) => {
        startFetch()
        console.log(item)
        const result = await portalApi.organization.selfServicePromotion.updatePromotion(item)
        if (result.successed && result.data) {
            console.log(result)
            stopFetch()
            hidePanel()
            setPromotion(undefined)
            getPromotions();
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
            setPromotion(undefined)
        }
    }

    const getPromotion = async (item: ISelfServicePromotion) => {
        startFetch()
        setFormError(undefined);
        console.log(item)
        const result = await portalApi.organization.selfServicePromotion.getPromotion(item.id)
        if (result.successed && result.data) {
            console.log(result)
            setPromotion(result.data)
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onCancel = () => {
        hidePanel()
        setPromotion(undefined);
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.SERVICES.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 600,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ISelfServicePromotion) => <SelfServicePromotion title={item.title} imageSrc={item.imageSrc || unknown} />,
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.SERVICES.TABLE_COLUMNS.CREATION_DATE,
            fieldName: 'code',
            minWidth: 100,
            maxWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ISelfServicePromotion) => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        {item.creationDate && (
                            <Text variant='small'>{new Date(item.creationDate).toLocaleString()}</Text>
                        ) || (
                                <Text variant='small' style={{ color: SharedColors.red10 }}>{item.creationDate !== undefined ? new Date(item.creationDate).toLocaleString() : ""}</Text>
                            )}
                    </Stack>);
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.SERVICES.TABLE_COLUMNS.CURRENCY,
            fieldName: 'serviceType',
            minWidth: 90,
            isResizable: false,
            data: 'string',
            maxWidth: 90,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ISelfServicePromotion) => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        {item.currency && (
                            <Text variant='small'>{`${getLocalizedCurrencyName(item.currency as CurrencyCodes)} - ${CurrencySigns[item.currency as CurrencyCodes]}`}</Text>
                        ) || (
                                <Text variant='small' style={{ color: SharedColors.red10 }}>{`${getLocalizedCurrencyName(item.currency as CurrencyCodes)} - ${CurrencySigns[item.currency as CurrencyCodes]}`}</Text>
                            )}
                    </Stack>);
            },
            isPadded: true,
        },
        {
            key: 'column4',
            name: '',
            fieldName: 'enabled',
            minWidth: 40,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ISelfServicePromotion) => (
                <Stack horizontal verticalFill horizontalAlign='end' verticalAlign='center'>
                    <Stack.Item>
                        {
                            item.expirationDate && compareCurrentUTCDateTime(item.expirationDate, true) && (
                                <TooltipHost content={strings.ORGANIZATION.PROMOTIONS.WARNINGS.PROMOTION_EXPIRED}>
                                    <Icon iconName={'Warning'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                                </TooltipHost>
                            )}
                        {/* {!item.removed &&  */}
                        <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item, deletePromotion)} />
                        {/* } */}
                    </Stack.Item>
                </Stack>
            )
        }
    ]

    const editPromotion = (item?: ISelfServicePromotion) => {
        showPanel();
        if (item)
            getPromotion(item)
    }
    const getItemMenuProps = (item: ISelfServicePromotion, onDelete: (id: number) => void): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? editPromotion(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? onDelete(item.id) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        const errorServices = promotionsState.data?.map((service) => {
            if (!service) {
                return service
            }
        })
        if (props) {
            if (props.item) {
                customStyles.root = {
                    cursor: 'pointer',
                    ':hover': {
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.menu-icon': {
                            color: theme.palette.black
                        },
                    }
                };
                props.item.expirationDate && compareCurrentUTCDateTime(props.item.expirationDate, true) && (customStyles.root = {
                    backgroundColor: "rgba(253, 231, 233,0.6)",
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: "rgb(253, 231, 233)",
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.menu-icon': {
                            color: theme.palette.black
                        },
                    }
                });
            } else {
                customStyles.root = {
                    backgroundColor: "rgba(253, 231, 233,0.6)",
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: "rgb(253, 231, 233)",
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.menu-icon': {
                            color: theme.palette.black
                        },
                    }
                };
            }

            return <Stack><DetailsRow {...props} styles={customStyles} /></Stack>;
        }
        return null;
    };

    return (
        <Stack verticalFill>
            <Stack.Item verticalFill>
                <Stack verticalFill>
                    <ScreenHeader title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTION_HEADER}>
                        <PrimaryButton onClick={onAddPromotion} text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CREATE_A_PROMOTION} />
                    </ScreenHeader>
                    <Stack horizontal tokens={{ childrenGap: 20 }} style={{ padding: "0px 32px 24px 32px" }}>
                        <Text variant="medium" nowrap >{strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTIONS_APPLICABLE_HEADER}</Text>
                        <Text variant="medium" nowrap ><Icon iconName="Info" style={{color : SharedColors.cyanBlue10}}/> {strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTIONS_REMAINDER}</Text>
                    </Stack>                    
                    {!promotionsState.error && (promotionsState.fetched && (!promotionsState.error && (
                        promotionsState.data && promotionsState.data.length > 0 && (
                            <>
                                <Stack verticalFill style={{ position: 'relative' }}>
                                    <ScrollablePane>
                                        <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                            <Section max style={{ padding: 0 }}>
                                                <DetailsList
                                                    styles={{ root: { width: '100%' } }}
                                                    items={promotionsState.data}
                                                    columns={columns}
                                                    setKey="none"
                                                    selectionMode={SelectionMode.none}
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                                    isHeaderVisible={true}
                                                    onRenderRow={onRenderRow}
                                                />
                                            </Section>
                                        </Stack.Item>
                                    </ScrollablePane>
                                </Stack>
                            </>
                        ) || (
                            <ItemsNotFound
                                onClick={onAddPromotion}
                                info={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CREATE_YOUR_FIRST_PROMOTION}
                                suggestion={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CREATE_PROMOTION_SUGGESTION}
                                buttonText={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CREATE_A_PROMOTION}
                                imgSrc={"images/navigation/images/startup.png"} />
                        )) || (
                            <SomethingWentWrong action={getPromotions} />
                        )) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        ))
                    }
                    {promotionsState.error && (
                        <Stack verticalFill>
                            {promotionsState.error.length > 0 && (
                                <MessageBar messageBarType={MessageBarType.error}>{promotionsState.error}</MessageBar>
                            )}
                            <Stack.Item verticalFill>
                                <SomethingWentWrong action={getPromotions} />
                            </Stack.Item>
                        </Stack>
                    )}
                </Stack>
            </Stack.Item>
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CREATE_A_PROMOTION}
            >
                {formError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {formError}
                    </MessageBar>
                )}
                {(isPanelOpen && !fetching) && (
                    promotion
                        ?
                        <SelfServicePromotionForm
                            onSubmit={(data: ISelfServicePromotion) => updatePromotion(data)}
                            onCancel={onCancel} codes={[]} data={promotion} />
                        :
                        <SelfServicePromotionForm
                            onSubmit={(data: ISelfServicePromotion) => addPromotion(data)}
                            onCancel={onCancel} codes={[]} />
                ) || (!promotion &&
                    <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                        <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                    </Stack>
                    )}
            </CustomPanel>
            {deletePromotionState && (
                <Modal isOpen={deletePromotionState ? true : false} onDismiss={() => setDeletePromotionState(undefined)} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close"
                            onClick={() => setDeletePromotionState(undefined)}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={onPromotionDelete}
                            onCancel={() => setDeletePromotionState(undefined)}
                            requiredMessageString={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_REQUIRED}
                            confirmationCheckString={deletePromotionState?.name}
                            placeholder={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_PLACEHOLDER}
                            confirmText={strings.BUTTONS.TEXT.DELETE}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_TEXT1}</Text>
                                <Text>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_TEXT2}</Text>
                                <Text>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{deletePromotionState.name}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>
            )}
        </Stack>
    )
}

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );

    return (
        defaultRender!({
            ...props,
            onRenderColumnHeaderTooltip,
        })
    );
};

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};