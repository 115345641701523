import { getTheme, Link, Separator, SharedColors, Stack, Text } from "@fluentui/react"
import React from "react";
import { strings } from "../../../../../localization/strings";
import { MdHelp } from "../../../../../components/shared/mdHelp/MdHelp";

export type TCompanyHelpPivotProps = {
    information?: string
    setInformation: (information: string) => void
    disabled?: boolean
    isFetching: boolean
    resetInfoWasSaved: () => void
    infoWasSaved: boolean
}

export const CompanyHelpPivot: React.FC<TCompanyHelpPivotProps> = (props) => {

    const [mdState, setMdState] = React.useState(props.information ?? "");

    const getHelp = async () => {
        setMdState(props.information ?? "")
    }

    const setHelp = async () => {
        props.setInformation(mdState)
    }

    return (
        <Stack  verticalFill tokens={{ padding: "24px 0px 0px 0px", childrenGap: 8 }}>
            <Stack verticalFill>
                <MdHelp
                    disabled={props.disabled} 
                    value={mdState} getHelp={getHelp}
                    setHelp={setHelp} setValue={(value) => setMdState(value ?? "")}
                    headerText={strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.HELP.HEADER}
                    isFetching={props.isFetching}
                    noChanges={(props.information === mdState)}
                    wasSaved={props.infoWasSaved}
                    resetWasSaved={props.resetInfoWasSaved}
                />
            </Stack>
        </Stack>
    )
}

const theme = getTheme();
