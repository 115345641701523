import { Stack, SharedColors, ImageFit, Icon, Text, Image } from "@fluentui/react"
import { IStore } from "../../../../../../core/store"
import { IAssessmentConfig, IDevice } from '@piceasoft/core';
import { strings } from "../../../../../../localization/strings"
import { StageConfirmationPreview } from "../../preview/StageConfirmationPreview"
import unknown from '../../../../../../assets/unknown.png'
import { sampleAssessment, sampleDiscount, sampleGrade } from "../../../mock/samples"
import { useSelector } from "react-redux"

type TProps = {
    config: IAssessmentConfig
    device: IDevice
}


export const AssessmentConfirmationPreview: React.FC<TProps> = ({ config, ...props }) => {

    const workflow = useSelector((s: IStore) => s.configurator);
    const offerConfig = workflow.offer;
    const assessment = sampleAssessment;
    const discount = sampleDiscount;
    const grade = sampleGrade;

    return (
        <StageConfirmationPreview config={config}
            defaultConfirmText={strings.BUTTONS.TEXT.CONFIRM}
            defaultCancelText={strings.BUTTONS.TEXT.CANCEL}
            defaultheader={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONFIRMATION}
        >
            <Stack tokens={{ childrenGap: 32 }}>
                <Stack.Item>
                    <div dangerouslySetInnerHTML={{ __html: config?.ui?.confirmation?.information ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONFIRM_STAGE_FINISH }}></div>
                </Stack.Item>
                <Stack horizontal verticalFill verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 16 }} styles={{ root: { paddingTop: 0, paddingBottom: 24 } }}>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: 16 }} style={{ maxWidth: 260, minWidth: 260, width: 260 }}>
                            <Stack.Item>
                                <Stack tokens={{ childrenGap: 8 }} horizontalAlign="center">
                                    <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{offerConfig?.ui?.tradeInDevice ?? strings.CONSTRUCTOR.STAGES.PRE_OFFER.TRADEIN_DEVICE}</Text>
                                    {config?.confirmation?.showDeviceIMEI && (
                                        <Stack>
                                            <Text variant="small" style={{ color: SharedColors.gray40, textAlign: "center" }}>{strings.ATTRIBUTES.DEVICE.IMEI}</Text>
                                            <Text variant="mediumPlus" style={{ color: SharedColors.gray40, textAlign: "center" }}>{props.device?.attributes["imei"]}</Text>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontalAlign="center">
                                    <Image width={128} height={128} imageFit={ImageFit.contain} maximizeFrame={true}
                                        src={props.device?.attributes.image ?? unknown}
                                    />
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontalAlign="center">
                                    <Text variant="mediumPlus" style={{ color: SharedColors.cyanBlue10 }}>{props.device?.manufacturer}</Text>
                                    <Text variant="large" style={{ color: SharedColors.cyanBlue10, fontWeight: "bold", textAlign: "center" }}>{props.device?.name} {props.device?.attributes.color}</Text>
                                    <Text variant="medium" style={{ fontWeight: "bold", color: SharedColors.gray30 }}>{grade?.name}</Text>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    {discount && (
                        <Stack.Item>
                            <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                                <Icon styles={{ root: { width: 32, height: 32, fontSize: 32, color: SharedColors.gray10 } }} iconName="Sync" />
                            </Stack>
                        </Stack.Item>
                    )}
                    {discount && (
                        <Stack.Item>
                            <Stack tokens={{ childrenGap: config?.confirmation?.showDeviceIMEI ? 61 : 16 }} style={{ maxWidth: 260, minWidth: 260, width: 260 }}>
                                <Stack.Item>
                                    <Stack horizontalAlign="center">
                                        <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{offerConfig?.ui?.tradeUpDevice ?? strings.CONSTRUCTOR.STAGES.PRE_OFFER.PURCHASED_DEVICE}</Text>
                                    </Stack>
                                </Stack.Item>
                                <Stack tokens={{ childrenGap: 16 }}>
                                    <Stack.Item>
                                        <Stack horizontalAlign="center">
                                            <Image width={128} height={128} imageFit={ImageFit.contain} maximizeFrame={true}
                                                src={discount.image ?? unknown}
                                            />
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontalAlign="center">
                                            <Text variant="mediumPlus" style={{ color: SharedColors.cyanBlue10 }}>{discount.manufacturer}</Text>
                                            <Text variant="large" style={{ color: SharedColors.cyanBlue10, fontWeight: "bold", textAlign: "center" }}>{discount.device}</Text>
                                            <Text variant="medium" style={{ color: SharedColors.gray30 }}><small>{strings.CONSTRUCTOR.STAGES.BENEFIT}</small> <b>{discount.amount.toLocaleString()}</b> <Text variant="smallPlus">{strings.CONSTRUCTOR.STAGES.CURRENCY}</Text></Text>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    )}
                </Stack>
                {!assessment?.price && (
                    <Stack style={{ paddingBottom: 20 }} tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            {discount && (
                                <Stack horizontalAlign="center">
                                    <Text variant="xxLarge" style={{ color: SharedColors.orangeYellow20 }}>{(assessment?.price ?? 0)} <small>{strings.CONSTRUCTOR.STAGES.CURRENCY}</small></Text>
                                    <Text variant="large" style={{ color: SharedColors.green10 }}>+ {(discount?.amount ?? 0).toLocaleString()} <small>{strings.CONSTRUCTOR.STAGES.CURRENCY}</small></Text>
                                </Stack>
                            ) || (
                                    <Stack horizontalAlign="center">
                                        <Text variant="xxLarge" style={{ color: SharedColors.orangeYellow20 }}>{assessment?.price?.toLocaleString()} <small>{strings.CONSTRUCTOR.STAGES.CURRENCY}</small></Text>
                                    </Stack>
                                )}
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
        </StageConfirmationPreview>
    )
}