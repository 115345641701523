import { Stack, Icon, Text, SharedColors, Spinner, Image, ImageFit, Link, PrimaryButton } from "@fluentui/react"
import { getTheme } from "@fluentui/style-utilities"
import { useId } from "@uifabric/react-hooks"
import React from "react"
import { SecondaryButton } from "../../../../../components/shared/buttons/SecondaryButton"
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../../../core/scripts/style"
import { strings } from "../../../../../localization/strings"
import defaultServiceIcon from '../../../../../assets/service.svg'

type TProps = {
    imgSrc?: string
    iconColor?: string
    serviceName?: string
    type: "icon" | "image"
    acceptTypeMask?: string
    lable: string
    description?: string
    allowedFileTypes: string[]
    isFetching: boolean
    onConfirm: (file?: File) => Promise<void>
    generateIcon?: boolean
    changed: boolean
    resetChanged: () => void
}

export const ServiceConfigurationSettingsWorkflowIcon: React.FC<TProps> = (props) => {
    const [file, setFile] = React.useState<File>()
    const [imageSrc, setImageSrc] = React.useState(props.imgSrc)
    const inputFileButtonId = useId('input-file-button-id')

    React.useEffect(() => {
        setImageSrc(props.imgSrc)
        setFile(undefined)
    }, [props.imgSrc])

    const addFile = (upFiles: FileList) => {
        console.log(upFiles)
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => props.allowedFileTypes.includes(upFile.type))]
        console.log(checkedUpFiles)
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            props.resetChanged()
            if (props.type === "image") {
                setImageSrc(URL.createObjectURL(checkedUpFiles[0]))
            }
            setFile(checkedUpFiles[0])
        }
    }

    const resetImageSrc = () => {
        if (imageSrc) {
            URL.revokeObjectURL(imageSrc)
            setImageSrc(props.imgSrc)
            setFile(undefined)
        }
    }

    const deleteImageSrc = () => {
        if (imageSrc) {
            setImageSrc(undefined)
            props.resetChanged()
        }
    }

    return (
        <Stack styles={{ root: { width: 640 } }} tokens={{ childrenGap: 16 }}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack tokens={{ padding: '24px 0px 24px 0px', childrenGap: 24 }} horizontal verticalAlign='center'>
                    <Stack verticalAlign="center" horizontalAlign='center' tokens={{ childrenGap: 8 }} >
                        <Stack verticalAlign="center"
                            horizontalAlign="center"
                            style={{
                                width: 72,
                                height: 72,
                                borderRadius: theme.effects.roundedCorner6,
                                backgroundColor: !imageSrc && props.generateIcon ?
                                    (props.serviceName ?
                                        getPersonaColorsPallete[props.serviceName[0].toLowerCase()] :
                                        undefined) :
                                    undefined
                            }}>
                            {imageSrc && (
                                <Stack horizontalAlign='center'>
                                    <Image src={imageSrc} width={72} height={72} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                </Stack>
                            ) || (props.generateIcon && (
                                <Text style={{ color: "#fff", fontSize: 28, fontWeight: 400, paddingBottom: 2 }}>
                                    {getIconSymbolsFromString(props.serviceName)}
                                </Text>
                            ) || (
                                    <Stack horizontalAlign='center'>
                                        <Image src={defaultServiceIcon} width={72} height={72} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                    </Stack>
                                )
                                )}
                        </Stack>
                    </Stack>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Stack.Item>
                                <Text>{props.lable}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                    <SecondaryButton onClick={() => {
                                        const el = document.getElementById(inputFileButtonId)
                                        if (el) {
                                            (el as HTMLInputElement).value = ''
                                            el.click()
                                        }
                                    }}>{!file ? strings.BUTTONS.TEXT.CHOOSE_FILE : strings.BUTTONS.TEXT.CHOOSE_ANOTHER_FILE}</SecondaryButton>
                                    {imageSrc !== undefined && imageSrc !== props.imgSrc && props.changed && <Link onClick={resetImageSrc}>{strings.BUTTONS.TEXT.RESET}</Link>}
                                    {props.imgSrc && imageSrc === props.imgSrc && <Link onClick={deleteImageSrc}>{strings.BUTTONS.TEXT.DELETE}</Link>}
                                </Stack>
                                <input accept={props.acceptTypeMask} id={inputFileButtonId} hidden type="file" onChange={(ev) => ev.target.files && addFile(ev.target.files)} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
                {props.description && (
                    <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
                        <Stack.Item>
                            <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20 }} title="Info" ariaLabel="Info" styles={iconButtonStyles} />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Text style={{ fontWeight: 500, color: SharedColors.gray20 }}>{props.description}</Text>
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <PrimaryButton disabled={(props.isFetching || !props.changed || props.imgSrc === imageSrc)} onClick={() => props.onConfirm(file)} >{strings.BUTTONS.TEXT.APPLY}</PrimaryButton>
                </Stack.Item>
                <Stack verticalAlign='center'>
                    {props.isFetching && <Spinner />}
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};