import React from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { onRenderLabel } from "../../renders/onRenderLabel"
import { SecondaryButton } from "../buttons/SecondaryButton"
import { IPromotionItem } from "../../../core/store/typings/IPromotionItem"
import { IResponseError } from "@piceasoft/core"
import { strings } from "../../../localization/strings"
import { ChooseIcon } from "../chooseIcon/ChooseIcon"
import { portalApi } from "../../../core/api/api"
import { IRequestResult } from "../../../core/store/typings/IResponseResult"
import unknown from "../../../assets/unknown.png"
import {
    Stack, TextField, DatePicker, PrimaryButton, TimePicker, Text,
    MessageBar, MessageBarType, ScrollablePane, Sticky, IComboBoxOption, ComboBox, Spinner
} from "@fluentui/react"
import { IKnownDevice } from "@piceasoft/core"
import { endpoints } from "../../../core/api/endpoints"
import { updateUTCDateTime, formatTime } from "../../../core/helpers/date-time"
import { CSHHelpLink } from "../help/CSHHelp"

type TProps = {
    itemState: { id?: string, fetched: boolean, data?: IPromotionItem, errors?: IResponseError[] }
    promotionId: string
    onDelete: (itemId: string) => void
    onCommit: (item: IPromotionItem) => void
    onDismiss: () => void
    devices: IKnownDevice[]
    isFetching: boolean
}

export const PromotionItemForm: React.FC<TProps> = (props) => {

    const { control, handleSubmit, watch, formState: { errors } } = useForm<IPromotionItem>({
        defaultValues: {
            ...props.itemState.data,
            conditions: props.itemState.data?.conditions ?? [],
            from: props.itemState.data?.from ? new Date(props.itemState.data?.from) : undefined,
            to: props.itemState.data?.to ? new Date(props.itemState.data?.to) : undefined
        }
    });


    const [manufacturer, setManufacturer] = React.useState(props.itemState.data?.manufacturer)
    const [model, setModel] = React.useState(props.itemState.data?.device)

    const getImageSrc = endpoints.v1.piceaDeviceImage(manufacturer ?? "", model ?? "")

    const onSubmit: SubmitHandler<IPromotionItem> = async data => {
        console.log(data)

        let image = props.itemState.data?.imgSrc
        let errors: IResponseError[] = []

        if (image !== fileSrc) {
            if (file) {
                setIsImageFetching(true)
                setImageErrors([])
                let uploadResult: IRequestResult<string> = { successed: false }
                if (file) {
                    uploadResult = await portalApi.settings.organization.uploadFile('promotion-item-image', file, undefined, true);
                    if (uploadResult.errors && uploadResult.errors.length > 0) {
                        errors = uploadResult.errors;
                    }
                } else {
                    uploadResult.successed = true;
                }

                if (uploadResult.successed) {
                    image = uploadResult.data
                    setIsImageChanged(false)
                }
                setIsImageFetching(false)
            }
            else {
                image = fileSrc
            }
        }

        if (errors.length === 0) {
            console.log(image)
            props.onCommit({ ...data, imgSrc: image ?? "" })
        } else {
            setImageErrors(errors)
        }

    };

    const [manufacturerOptions, setManufacturerOptions] = React.useState<IComboBoxOption[]>([])
    const [modelOptions, setModelOptions] = React.useState<IComboBoxOption[]>([])

    const allowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif"]

    const [file, setFile] = React.useState<File>()
    const [isImageFetching, setIsImageFetching] = React.useState(false)
    const [isImageChanged, setIsImageChanged] = React.useState(false)
    const [imageErrors, setImageErrors] = React.useState<IResponseError[]>([])
    const [fileSrc, setFileSrc] = React.useState(props.itemState.data?.imgSrc)

    const addFile = (upFiles: FileList) => {
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => allowedFileTypes.includes(upFile.type))]
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            setIsImageChanged(true)
            setFileSrc(URL.createObjectURL(checkedUpFiles[0]))
            setFile(checkedUpFiles[0])
        }
    }

    const resetImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFileSrc(props.itemState.data?.imgSrc)
            setFile(undefined)
            setIsImageChanged(false)
        }
    }

    const deleteImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFile(undefined)
            setFileSrc(undefined)
            setIsImageChanged(true)
        }
    }

    const onGetImageSrc = () => {
        if (manufacturer && model) {
            if (fileSrc) {
                URL.revokeObjectURL(fileSrc)
            }
            if (manufacturer && model) {
                setFileSrc(getImageSrc)
                setIsImageChanged(true)
            }
        }
    }

    React.useEffect(() => {
        prepareManufacturerOptions()
    }, [])

    React.useEffect(() => {
        if (manufacturer && manufacturerOptions?.find(i => i.text === manufacturer)) {
            prepareModelOptions(manufacturer)
        } else {
            setModelOptions([])
        }
    }, [manufacturerOptions, manufacturer])

    const prepareManufacturerOptions = () => {
        const manufacturersArray = props.devices.sort((a, b) => (a.manufacturer > b.manufacturer) ? 1 : ((b.manufacturer > a.manufacturer) ? -1 : 0)).map(i => i.manufacturer)
        const manufacturersSet = new Set(manufacturersArray)
        setManufacturerOptions(Array.from(manufacturersSet).map(i => ({ key: i, text: i })))
    }

    const prepareModelOptions = async (manufacturer: string) => {
        const modelsArray = props.devices.filter(i => i.manufacturer === manufacturer).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(i => i.name)
        const modelsSet = new Set(modelsArray)
        setModelOptions(Array.from(modelsSet).map(i => ({ key: i, text: i })))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        {imageErrors.length > 0 && (
                            <Sticky>
                                <MessageBar messageBarType={MessageBarType.error}>
                                    <Stack tokens={{ childrenGap: 2 }}>
                                        {imageErrors.map(i => <Text>{i.description}</Text>)}
                                    </Stack>
                                </MessageBar>
                            </Sticky>
                        )}
                        <Stack verticalFill tokens={{ padding: "0px 20px 20px 20px", childrenGap: 16 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.NAME_REQUIRED_MESSAGE }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 400 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.NAME_LABEL}
                                        title={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.NAME_TITLE}
                                        placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="description"
                                rules={{ required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.DESCRIPTION_REQUIRED_MESSAGE }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 400 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.DESCRIPTION_LABEL}
                                        title={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.DESCRIPTION_TITLE}
                                        placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.DESCRIPTION_PLACEHOLDER}
                                        errorMessage={errors.description?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="from"
                                rules={{ required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.FROM_REQUIRED_MESSAGE }}
                                render={({ field }) =>
                                    <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 16 }}>
                                        <Stack.Item grow>
                                            {onRenderLabel({
                                                title: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.FROM_TITLE,
                                                label: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.FROM_LABEL,
                                                required: true
                                            })}
                                            <DatePicker
                                                value={field.value}
                                                onSelectDate={(date) => field.onChange(updateUTCDateTime(field.value, date))
                                                }
                                                formatDate={(date?: Date) => date ? date.toLocaleDateString() : ''}
                                                style={{ width: 294 }}
                                                onBlur={field.onBlur}
                                                placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.FROM_PLACEHOLDER}
                                                strings={strings.DAY_PICKER}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TimePicker
                                                defaultValue={field.value ?? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0 + (new Date().getTimezoneOffset() / 60), 0))}
                                                onFormatDate={formatTime}
                                                onChange={(ev, time) => field.onChange(updateUTCDateTime(field.value, time, true))
                                                }
                                                disabled={!field.value}
                                                useComboBoxAsMenuWidth
                                                styles={{ optionsContainer: { maxHeight: 290 } }}
                                                style={{ width: 130, paddingBottom: 5 }}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                }
                            />
                            <Controller
                                control={control}
                                name="to"
                                rules={{ required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.TO_REQUIRED_MESSAGE }}
                                render={({ field }) =>
                                    <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 16 }}>
                                        <Stack.Item grow>
                                            {onRenderLabel({
                                                title: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.TO_TITLE,
                                                label: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.TO_LABEL,
                                                required: true
                                            })}
                                            <DatePicker
                                                value={field.value}
                                                onSelectDate={(date) => field.onChange(updateUTCDateTime(field.value, date))
                                                }
                                                formatDate={(date?: Date) => date ? date.toLocaleDateString() : ''}
                                                style={{ width: 294 }}
                                                onBlur={field.onBlur}
                                                placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.TO_PLACEHOLDER}
                                                strings={strings.DAY_PICKER}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TimePicker
                                                defaultValue={field.value ?? (new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0 + (new Date().getTimezoneOffset() / 60), 0)))}
                                                onFormatDate={formatTime}
                                                onChange={(ev, time) => field.onChange(updateUTCDateTime(field.value, time, true))
                                                }
                                                disabled={!field.value}
                                                useComboBoxAsMenuWidth
                                                styles={{ optionsContainer: { maxHeight: 290 } }}
                                                style={{ width: 130, paddingBottom: 5 }}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                }
                            />
                            <Controller
                                control={control}
                                name="manufacturer"
                                rules={{ required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.MANUFACTURER_REQUIRED_MESSAGE }}
                                render={({ field }) =>
                                    <Stack.Item>
                                        {onRenderLabel({
                                            title: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.MANUFACTURER_TITLE,
                                            label: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.MANUFACTURER_LABEL,
                                            required: true
                                        })}
                                        <ComboBox
                                            allowFreeform
                                            autoComplete="on"
                                            useComboBoxAsMenuWidth
                                            text={field.value}
                                            options={manufacturerOptions}
                                            styles={{ optionsContainer: { maxHeight: 290 } }}
                                            placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.MANUFACTURER_PLACEHOLDER}
                                            onChange={(ev, opt, index, val) => {
                                                setManufacturer(opt?.text ?? val)
                                                field.onChange(opt?.text ?? val)
                                            }}
                                            onInputValueChange={(val) => {
                                                setManufacturer(val)
                                                field.onChange(val)
                                            }}
                                            onItemClick={(ev, opt) => {
                                                if (opt) {
                                                    setManufacturer(opt.text)
                                                    field.onChange(opt.text)
                                                }
                                            }}
                                            errorMessage={errors.manufacturer?.message}
                                        />
                                    </Stack.Item>
                                }
                            />
                            <Controller
                                control={control}
                                name="device"
                                rules={{ required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.DEVICE_REQUIRED_MESSAGE }}
                                render={({ field }) =>
                                    <Stack.Item>
                                        {onRenderLabel({
                                            title: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.DEVICE_TITLE,
                                            label: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.DEVICE_LABEL,
                                            required: true
                                        })}
                                        <ComboBox
                                            allowFreeform
                                            autoComplete="on"
                                            useComboBoxAsMenuWidth
                                            text={field.value}
                                            options={modelOptions}
                                            styles={{ optionsContainer: { maxHeight: 290 } }}
                                            placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.DEVICE_PLACEHOLDER}
                                            onChange={(ev, opt, index, val) => {
                                                setModel(opt?.text ?? val)
                                                field.onChange(opt?.text ?? val)
                                            }}
                                            onInputValueChange={(val) => {
                                                setModel(val)
                                                field.onChange(val)
                                            }}
                                            onItemClick={(ev, opt) => {
                                                if (opt) {
                                                    setModel(opt?.text)
                                                    field.onChange(opt?.text)
                                                }
                                            }}
                                            errorMessage={errors.device?.message}
                                        />
                                    </Stack.Item>
                                }
                            />
                            {/* <Separator /> */}
                            {onRenderLabel({
                                title: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.IMAGE_TITLE,
                                label: strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.IMAGE_LABEL
                            })}
                            <ChooseIcon
                                acceptTypeMask={"image/*"}
                                allowedFileTypes={allowedFileTypes}
                                type="image"
                                changed={isImageChanged}
                                resetChanged={() => setIsImageChanged(true)}
                                imgSrc={watch().imgSrc}
                                fileSrc={fileSrc}
                                itemName={watch().name}
                                lable={strings.ORGANIZATION.PROMOTIONS.PROMOTION.ITEMS.ITEM.IMAGE_LOAD_LABEL}
                                description={true}
                                defaultIcon={unknown}
                                addFile={addFile}
                                deleteImageSrc={deleteImageSrc}
                                resetImageSrc={resetImageSrc}
                                onGetImageSrc={(manufacturer && model) ? onGetImageSrc : undefined}
                                getImageSrc={getImageSrc}
                            />
                            {/* <Separator /> */}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        {(props.isFetching || isImageFetching) && (
                            <Spinner />
                        )}
                        <CSHHelpLink articleid='1157' alignment='left' />
                        <PrimaryButton disabled={props.isFetching || isImageFetching} onClick={handleSubmit(onSubmit)} text={strings.BUTTONS.TEXT.CONFIRM} />
                        <SecondaryButton disabled={props.isFetching || isImageFetching} onClick={props.onDismiss} text={strings.BUTTONS.TEXT.CANCEL} />
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}