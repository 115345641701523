import { FontWeights, getTheme, Icon, Link, mergeStyleSets, Separator, SharedColors, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { PropertyGrid } from '../../../../../components/shared/decorations/PropertyGrid';
import { IContractConfig } from '../../../../../core/store';
import { IDataCollectionStandardConfig } from '@piceasoft/core';
import { ITransactionDataItem } from '../../../../../core/store/typings/ITransaction';
import { strings } from '../../../../../localization/strings';
import { TransactionTabEntry } from './TransactionTabEntry';
import { TransactionKeys } from '@piceasoft/core';
import { endpoints } from '../../../../../core/api/endpoints';

type TProps = {
    data: ITransactionDataItem[]
    config: IContractConfig | IDataCollectionStandardConfig
}

export const ContractPivot: React.FC<TProps> = ({ data, config }) => {

    return (
        <Stack verticalFill style={{ padding: "24px 24px" }}>
            <Stack horizontal verticalFill tokens={{ childrenGap: 24 }}>
                <Stack tokens={{ childrenGap: 16 }} style={{ width: "50%" }}>
                    <PropertyGrid name={strings.TRANSACTION.CONTRACT.CUSTOMER} icon="Contact">
                        {config.fields?.name && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.FULL_NAME}
                                value={data.find(i => i.key === TransactionKeys.PASSPORT_NAME)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.phone && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.PHONE}
                                value={data.find(i => i.key === TransactionKeys.CONTRACT_PHONE_NUMBER)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.email && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.EMAIL}
                                value={data.find(i => i.key === TransactionKeys.CONTRACT_EMAIL)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                    </PropertyGrid>
                    <PropertyGrid name={strings.TRANSACTION.CONTRACT.CUSTOMER_CUSTOM} icon="TextDocument">
                        {config.fields?.custom && config.fields.custom.map(i => 
                            <TransactionTabEntry
                                key={i.key}
                                name={i.label}
                                value={data.find(j => j.key === "CUSTOM_" + i.key)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                    </PropertyGrid>
                    <PropertyGrid name={strings.TRANSACTION.CONTRACT.ATTACHMENT_TITLE} icon="Attach">
                        {(config as IContractConfig)?.fields?.attachment && (config as IContractConfig).fields?.attachment?.map(i => 
                            <TransactionTabEntry
                                name={i.label}
                                key={i.key}
                                value={data.find(j => j.key === "ATTACHMENT_" + i.key)?.value ?? ""}
                                onValueRender={(v) => {
                                    if (v) {
                                        const fileName = data.find(j => j.key === "ATTACHMENT_NAME_" + i.key)?.value ?? "";
                                        const donwnloadUrl = endpoints.v1.downloadPhoto(v, fileName)
                                        return ( 
                                            <Stack horizontal>
                                                <Stack.Item grow>
                                                    <Link download={fileName} href={donwnloadUrl} >
                                                        <Text className={styles.downloadLink} block nowrap>{fileName}</Text>
                                                    </Link>
                                                </Stack.Item>
                                                <Link className={styles.value} download={fileName} href={donwnloadUrl}>
                                                    <Icon iconName={"Download"} />
                                                </Link>
                                            </Stack>)
                                    } else {
                                            return strings.TRANSACTION.RESULT.NO_DATA
                                    }
                                }}
                            />
                        )}
                        </PropertyGrid>  
                </Stack>
                <Separator vertical />
                <Stack.Item style={{ width: "50%" }}>
                    <PropertyGrid name={strings.TRANSACTION.CONTRACT.DOCUMENT} icon="ContactCard">
                        {config.fields?.passport?.number && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.PASSPORT_SN}
                                value={data.find(i => i.key === TransactionKeys.PASSPORT_SN)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.passport?.issuedBy && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.PASSPORT_ISSUED_BY}
                                value={data.find(i => i.key === TransactionKeys.PASSPORT_ISSUED_BY)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.passport?.dateOfIssue && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.PASSPORT_DATE_OF_ISSUE}
                                value={data.find(i =>
                                    i.key === TransactionKeys.PASSPORT_DATE_OF_ISSUE.toString())?.value ?
                                    new Date((data.find(i => i.key === TransactionKeys.PASSPORT_DATE_OF_ISSUE) as ITransactionDataItem).value).toLocaleDateString() :
                                    strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.passport?.subdivisionCode && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.PASSPORT_SUBDIVISION_CODE}
                                value={data.find(i => i.key === TransactionKeys.PASSPORT_SUBDIVISION_CODE)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.passport?.dateOfBirth && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.PASSPORT_DATE_OF_BIRTH}
                                value={data.find(i =>
                                    i.key === TransactionKeys.PASSPORT_DATE_OF_BIRTH.toString())?.value ?
                                    new Date((data.find(i => i.key === TransactionKeys.PASSPORT_DATE_OF_BIRTH) as ITransactionDataItem).value).toLocaleDateString() :
                                    strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.passport?.placeOfBirth && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.PASSPORT_PLACE_OF_BIRTH}
                                value={data.find(i => i.key === TransactionKeys.PASSPORT_PLACE_OF_BIRTH)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.passport?.address && (
                            <TransactionTabEntry
                                name={strings.TRANSACTION.CONTRACT.PASSPORT_ADDRESS}
                                value={data.find(i => i.key === TransactionKeys.PASSPORT_ADDRESS)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                        {config.fields?.operator && (
                            <TransactionTabEntry
                            name={strings.TRANSACTION.CONTRACT.OPERATOR}
                            value={data.find(i => i.key === TransactionKeys.OPERATOR)?.value ?? strings.TRANSACTION.RESULT.NO_DATA}
                            />
                        )}
                    </PropertyGrid>
                </Stack.Item>
            </Stack >
        </Stack >
    )
}

const theme = getTheme();

const styles = mergeStyleSets({
    buttonArea: {
    },
    callout: {
        maxWidth: 400,
        minWidth: 400,
    },
    header: {
        padding: '18px 24px 12px',
    },
    title: [
        theme.fonts.xLarge,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0px 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.medium,
        {
            margin: 0,
            color: SharedColors.gray10
        },
    ],
    grade: [
        theme.fonts.medium,
        {
            margin: 0,
            fontWeight: FontWeights.regular,
            color: SharedColors.cyanBlue10
        },
    ],
    value: [
        theme.fonts.medium,
        {
            margin: 0,
            color: SharedColors.cyanBlue10,
        },
    ],
    price: [
        theme.fonts.large,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
            color: SharedColors.orangeYellow10
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
    downloadLink: [
        theme.fonts.small,
        {
            margin: 0,
            color: SharedColors.cyanBlue10,
            marginRight:16
        },
    ]    

});
