import {
    getTheme, Stack, IconButton, DirectionalHint, IContextualMenuProps,
    Text, PrimaryButton
} from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React, { Dispatch, useState } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";
import { ItemsNotFound } from "../../../../notFound/ItemsNotFound";
import { CustomPanel } from "../../../../panel/CustomPanel";
import { ISelfServiceWorkflowConfig, IGrade, IDisclaimer } from "@piceasoft/core";
import { DisclaimerFrom } from "../../../../forms/DisclaimerFrom";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: ISelfServiceWorkflowConfig
}

export const DisclaimerList: React.FC<TProps> = ({ config, onChangeDispatch}) => {

    if (!config) return null

    const [panelState, setPanelState] = React.useState<{ id: number, item: IDisclaimer }>()
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)

    React.useEffect(() => {
        if (panelState && !isPanelOpen) {
            showPanel()
        }
    }, [panelState])

    const onCancel = () => {
        hidePanel();
        setTimeout(() => setPanelState(undefined), 100);
    }

    const onEdit = (item: IDisclaimer, id: number) => {
        setPanelState({ id, item });
    }

    const onAdd = () => {
        showPanel()
    }

    const onDelete = async (id: number) => {
        let newProviders = config?.disclaimers.filter(x => x.id != id) ?? [];
        onChangeDispatch(actionCreators.selfService.upsertDisclaimersConfigs(newProviders));
        onCancel();
    };

    const onCommit = async (item: IDisclaimer) => {
        let newProviders: IDisclaimer[] = []
        if (panelState?.id !== undefined && panelState?.id !== null) {
            // update existing
            newProviders = config?.disclaimers?.map((op) => {
                if (panelState.id === op.id) {
                    return item;
                }
                return op;
            }) ?? [];
        } else {
            item.id = config.disclaimers && config?.disclaimers?.length > 0 ? config.disclaimers.reduce((max, obj) => (obj.id > max ? obj.id : max), 0)+1 : 1;
            newProviders = [...(config?.disclaimers ?? []), item];
        }
        onChangeDispatch(actionCreators.selfService.upsertDisclaimersConfigs(newProviders));
        onCancel();
    };
    return (
        <>
            <div style={{ marginTop: 10, marginBottom: -7 }} />
            {(config.disclaimers && config.disclaimers.length > 0) && (
                <>
                    <DisclaimersList
                        items={config.disclaimers}
                        onDelete={(id) => onDelete(id)}
                        onEdit={(item: IDisclaimer, index) => onEdit(item, index)}
                    />
                    <Stack tokens={{ padding: "12px 0px", childrenGap: 16 }}>
                        <Stack.Item>
                            <PrimaryButton onClick={onAdd}>{strings.BUTTONS.TEXT.ADD}</PrimaryButton>
                        </Stack.Item>
                    </Stack>
                </>
            ) || (
                    <ItemsNotFound
                        info={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.NOT_FOUND.INFO}
                        suggestion={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.NOT_FOUND.INFO_SUGGESTION}
                        buttonText={strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.NOT_FOUND.INFO_BUTTON_TEXT}
                        imgSrc={"images/navigation/images/not_found.png"}
                        onClick={showPanel}
                    />
                )}
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                noCancelOnDissmiss={true}
                title={panelState ?
                    strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.EDIT_DISCLAIMER :
                    strings.CONSTRUCTOR.STAGES.COMMON_DISCLAIMERS.ADD_DISCLAIMER
                }
            >
                <DisclaimerFrom
                    onFormSubmit={onCommit}
                    onCancel={onCancel}
                    data={panelState?.item}
                />
            </CustomPanel>
        </>
    )
}


type TDisclaimersListProps = {
    items?: IDisclaimer[];
    onEdit: (item: IDisclaimer, id: number) => void;
    onDelete: (id: number) => void;
}
const DisclaimersList: React.FC<TDisclaimersListProps> = ({ items, onEdit, onDelete }) => {
    return <div style={providersListStyle}>
        {items?.map((item, index) => <DisclaimerRow key={index} arrIndex={index} item={item} onEdit={onEdit} onDelete={onDelete} />)}
    </div>

}

type TDisclaimerRowProps = {
    arrIndex: number
    item: IDisclaimer;
    onEdit: (item: IDisclaimer, index: number) => void;
    onDelete: (arrIndex: number) => void;
}
const DisclaimerRow: React.FC<TDisclaimerRowProps> = ({ item, onEdit, onDelete, arrIndex }) => {
    const [isHovered, setIsHovered] = useState(false);
    const getItemMenuProps = (item: IDisclaimer, arrIndex: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => onEdit(item, Number(item.id)),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => onDelete(Number(item.id)),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }
    const getCustomStyles = (isHovered: boolean) => {
        const baseStyles: React.CSSProperties = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
            boxShadow: '0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13), 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.10)',
            backgroundColor: isHovered ? '#f3f2f1' : "",
        };

        return {
            ...baseStyles,
        };
    }

    return (
        <div key={`row-${item.id}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={getCustomStyles(isHovered)}>
            {/** name /*/}
            <div style={{ ...cellStyle, minWidth: 150, maxWidth: 400, flex: 3, flexDirection: 'column', paddingLeft:20 }}>
                <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
            </div>

            {/** commandbar /*/}
            <div style={{ ...cellStyle, width: 70, marginLeft: 'auto' }} onClick={(ev) => ev.stopPropagation()}>
                <Stack.Item>
                    <IconButton size={16} menuIconProps={{ iconName: "MoreVertical", className: 'provider-menu-icon' }} className="provider-menu-icon" style={{ color: isHovered ? "black" : "transparent" }} menuProps={getItemMenuProps(item, Number(item.id))} />
                </Stack.Item>
            </div>
        </div>)
}


const theme = getTheme();

const providersListStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
}

const cellStyle: React.CSSProperties = {
    display: 'flex',
    padding: '5px'
}

