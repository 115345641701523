import { AppThunkAction } from "..";
import { endpoints } from "../api/endpoints";
import { consoleStyles } from "../scripts/console";
import { IStructureNode } from "../store/typings/IStructureNode";

export interface RequestStructureAction { type: 'WORKFLOW_STRUCTURE_REQUEST' }
export interface ReceiveStructureAction { type: 'WORKFLOW_STRUCTURE_RECEIVE', data: IStructureNode[] }
export interface AddStructureAction { type: 'WORKFLOW_STRUCTURE_NODE_ADD', data: IStructureNode }
export interface UpdateStructureAction { type: 'WORKFLOW_STRUCTURE_NODE_EDIT', data: IStructureNode }
export interface DeleteStructureAction { type: 'WORKFLOW_STRUCTURE_NODE_DELETE', id: string, withChilds: boolean }

export type KnownAction = RequestStructureAction | ReceiveStructureAction | 
                        AddStructureAction | UpdateStructureAction | DeleteStructureAction

export const actionCreators = {
    /** Запрос на получение данных авторизованного рабочего места. */
    requestStructure: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestStructure()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            dispatch({ type: 'WORKFLOW_STRUCTURE_REQUEST' });
            fetch(endpoints.v1.requestStructure)
                .then(response => response.json() as Promise<IStructureNode[]>)
                .then(data => {
                    // console.log(data)
                    dispatch({ type: 'WORKFLOW_STRUCTURE_RECEIVE', data: data });
                })
                .catch(response => {
                    console.log(response);
                });
        }
    },
    addStructureNode: (node: IStructureNode): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC addStructureNode()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            dispatch({ type: 'WORKFLOW_STRUCTURE_NODE_ADD', data: node });
        }
    },
    updateStructureNode: (node: IStructureNode): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateStructureNode()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            dispatch({ type: 'WORKFLOW_STRUCTURE_NODE_EDIT', data: node });
        }
    },
    deleteStructureNode: (nodeId: string, withChilds?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC deleteStructureNode()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            dispatch({ type: 'WORKFLOW_STRUCTURE_NODE_DELETE', id: nodeId, withChilds: withChilds ?? false });
        }
    },
};
