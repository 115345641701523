import { getTheme, Separator, Stack } from "@fluentui/react"
import React from "react"
import { strings } from "../../../../localization/strings"
import { Section } from "../../../../components/shared/decorations/Section"
import { RemoveArea } from "../../../../components/shared/decorations/RemoveArea"
import { PropertyValue } from "../../../../components/shared/decorations/PropertyValue"
import { IPromotion } from "../../../../core/store/typings/IPromotion"
import { CurrencyCodes } from "@piceasoft/core"
import { CurrencySigns } from "@piceasoft/core"

type TFinancePromotionInfoPivotProps = {
    data: IPromotion
    onDelete: (item: IPromotion) => void
}

export const FinancePromotionInfoPivot: React.FC<TFinancePromotionInfoPivotProps> = (props) => {
    return (
        <Stack tokens={{ padding: "24px 32px 24px 32px", childrenGap: 16 }}>
            <Section title={strings.ORGANIZATION.PROMOTIONS.PROMOTION.PROMOTION_DATA}>
                <Stack tokens={{ childrenGap: 12 }}>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.NAME} value={props.data.name} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.SUMMARY} value={props.data.summary} />
                    </Stack>
                    {props.data.currency && (
                        <Stack tokens={{ childrenGap: 6 }}>
                            <PropertyValue label={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.CURRENCY} value={`${props.data.currency} - ${CurrencySigns[props.data.currency as CurrencyCodes]}`} />
                        </Stack>
                    )}
                </Stack>
            </Section>
            <Stack.Item>
                <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
            </Stack.Item>
            <Stack.Item >
                <Stack tokens={{ childrenGap: 8 }}>
                    <RemoveArea
                        href="1110"
                        hrefText={strings.ORGANIZATION.PROMOTIONS.REMOVE.HREF_TEXT}
                        onDelete={() => props.onDelete(props.data)}
                        text={strings.ORGANIZATION.PROMOTIONS.REMOVE.TEXT}
                        title={strings.ORGANIZATION.PROMOTIONS.REMOVE.TITLE}
                    />
                </Stack>
            </Stack.Item>
        </Stack >
    )
}

const theme = getTheme();
