import { DefaultButton, getTheme, IButtonProps, IButtonStyles } from "@fluentui/react";

export const DangerButton: React.FC<IButtonProps> = (props) => {
    return <DefaultButton styles={dangerButtonStyles} {...props} />
}

const theme = getTheme();

const dangerButtonStyles:IButtonStyles = {
    root: {
        backgroundColor: theme.palette.red,
        color: theme.palette.white,
        borderColor: theme.palette.red
    },
    rootPressed: {
        backgroundColor: theme.palette.redDark,
        color: theme.palette.white,
        borderColor: theme.palette.redDark
    },
    rootHovered: {
        backgroundColor: '#c20000',
        color: theme.palette.white,
        borderColor: '#c20000'
    }
}
