import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";
import { ParameterItem } from "../../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { ProcessStages, ICommonOfferConfig } from "@piceasoft/core";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: ICommonOfferConfig
    channel: number
    stage: ProcessStages
}

export const CommonOfferStagePromotionsPivotUI: React.FC<TProps> = ({ onChangeDispatch, config,channel,stage }) => {
    if (!config) return null

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.ALLOW_DISCOUNTS_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Campaigns/UI settings', 'Allow campaigns')}
                toggleChecked={config.allowDiscount}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        allowDiscount: config.allowDiscount ? undefined : true
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.ALLOW_DISCOUNTS_DESCRIPTION}
            />
            {config.allowDiscount && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.TRADE_UP_DEVICE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Campaigns/UI settings', 'Custom trade-up device title')}
                    toggleChecked={config.discount?.ui?.tradeUpDevice !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                            ...config,
                            discount: {
                                ...config.discount,
                                ui: {
                                    ...config.ui,
                                    tradeUpDevice: config.discount?.ui?.tradeUpDevice !== undefined ? undefined : ""
                                }
                            }
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.TRADE_UP_DEVICE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.discount?.ui?.tradeUpDevice !== undefined ? true : false}
                    textFieldValue={config.discount?.ui?.tradeUpDevice}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        discount: {
                            ...config.discount,
                            ui: {
                                ...config.ui,
                                tradeUpDevice: val
                            }
                        }
                    }))}
                />
            )}
            {config.allowDiscount && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_BUTTON_TEXT_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Campaigns/UI settings', 'Custom Campaign button caption')}
                    toggleChecked={config.discount?.ui?.button !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                            ...config,
                            discount: {
                                ...config.discount,
                                ui: {
                                    ...config.discount?.ui,
                                    button: config.discount?.ui?.button !== undefined ? undefined : ""
                                }
                            }
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_BUTTON_TEXT_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.discount?.ui?.button !== undefined ? true : false}
                    textFieldValue={config.discount?.ui?.button}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        discount: {
                            ...config.discount,
                            ui: {
                                ...config.discount?.ui,
                                button: val
                            }
                        }
                    }))}
                />
            )}
            {config.allowDiscount && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_BUTTON_TEACHING_HEADER_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Campaigns/UI settings', 'Custom Campaign button hint header text')}
                    toggleChecked={config.discount?.ui?.header !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                            ...config,
                            discount: {
                                ...config.discount,
                                ui: {
                                    ...config.discount?.ui,
                                    header: config.discount?.ui?.header !== undefined ? undefined : ""
                                }
                            }
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_BUTTON_TEACHING_HEADER_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.discount?.ui?.header !== undefined ? true : false}
                    textFieldValue={config.discount?.ui?.header}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        discount: {
                            ...config.discount,
                            ui: {
                                ...config.discount?.ui,
                                header: val
                            }
                        }
                    }))}
                />
            )}
            {config.allowDiscount && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_PANEL_INFORMATION_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Campaigns/UI settings', 'Custom Campaign panel information')}
                    toggleChecked={config.discount?.ui?.information !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                            ...config,
                            discount: {
                                ...config.discount,
                                ui: {
                                    ...config.discount?.ui,
                                    information: config.discount?.ui?.information !== undefined ? undefined : ""
                                }
                            }
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_PANEL_INFORMATION_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.discount?.ui?.information !== undefined ? true : false}
                    textFieldValue={config.discount?.ui?.information}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        discount: {
                            ...config.discount,
                            ui: {
                                ...config.discount?.ui,
                                information: val
                            }
                        }
                    }))}
                />
            )}
            {config.allowDiscount && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_PANEL_PLACEHOLDER_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Campaigns/UI settings', 'Custom Campaign panel search input placeholder')}
                    toggleChecked={config.discount?.ui?.placeholder !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                            ...config,
                            discount: {
                                ...config.discount,
                                ui: {
                                    ...config.discount?.ui,
                                    placeholder: config.discount?.ui?.placeholder !== undefined ? undefined : ""
                                }
                            }
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_PANEL_PLACEHOLDER_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.discount?.ui?.placeholder !== undefined ? true : false}
                    textFieldValue={config.discount?.ui?.placeholder}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        discount: {
                            ...config.discount,
                            ui: {
                                ...config.discount?.ui,
                                placeholder: val
                            }
                        }
                    }))}
                />
            )}
            {config.allowDiscount && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_BUTTON_TEACHING_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Campaigns/UI settings', 'Custom Campaign button hint text')}
                    toggleChecked={config.discount?.ui?.teaching !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                            ...config,
                            discount: {
                                ...config.discount,
                                ui: {
                                    ...config.discount?.ui,
                                    teaching: config.discount?.ui?.teaching !== undefined ? undefined : ""
                                }
                            }
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS_PIVOT.DISCOUNTS_BUTTON_TEACHING_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.discount?.ui?.teaching !== undefined ? true : false}
                    textFieldValue={config.discount?.ui?.teaching}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        discount: {
                            ...config.discount,
                            ui: {
                                ...config.discount?.ui,
                                teaching: val
                            }
                        }
                    }))}
                />
            )}
        </Stack>
    )
}