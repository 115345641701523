import { getTheme, IContextualMenuProps, IStackStyles, Spinner, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { ITreeNode, ITreeNodeAction, TreePresenter } from '../../../assets/ts/tree/VTree';
import { StructureNodeTypes } from './Elements/StructureNodeTypes';

type TProps = {
    trees: ITreeNode[]
    actions: ITreeNodeAction[]
    organizationName: string
    setDetailsItem: (type: StructureNodeTypes, id: string) => Promise<void> | undefined
    selectedKey: string
    getMenuProps: (actions: ITreeNodeAction[]) => IContextualMenuProps
    setSelectedKey: (key: string) => void
    expandedKeys?: string[]
    collapseNode?: (key: string) => void
    expandNode?: (key: string) => void
}

export const OrganizationStructureTree: React.FC<TProps> = (props) => {

    return (
        <>
            {props.trees && (
                props.trees.length > 0 && (
                    <TreePresenter 
                    itemSize={38} 
                    selectedKey={props.selectedKey} 
                    tree={{ id: '', children: props.trees, itemId: '', name: '', onClick: () => { } }} 
                    setDetailsItem={props.setDetailsItem}
                    setSelectedKey={props.setSelectedKey}
                    getMenuProps={props.getMenuProps} 
                    expandedKeys={props.expandedKeys}
                    collapseNode={props.collapseNode}
                    expandNode={props.expandNode}
                    />
                ) || (
                    <Stack verticalFill verticalAlign="center">
                        <Stack horizontalAlign='center'>
                            <Stack.Item styles={containerStyles} tokens={{ padding: 18 }}>
                                <Text>{"Структура организации пуста. Начните ее создание"}</Text>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                )
            ) ||
                (
                    <Spinner />
                )}
        </>
    )
}

const theme = getTheme();
const containerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 } };