import { IOrganization, IResponseError } from "@piceasoft/core";
import { IOrganizationSettings } from "../store/typings/IOrganizationSettings";
import { IOrganizationSettingsAdditional } from "../store/typings/IOrganizationSettingsAdditional";
import { IOrganizationSettingsLocale } from "../store/typings/IOrganizationSettingsLocale";
import { IOrganizationSettingsOverview } from "../store/typings/IOrganizationSettingsOverview";
import { IPiceaCredentials } from "../store/typings/IPiceaCredentials";
import { IRequestResult } from "../store/typings/IResponseResult";
import { endpoints } from "./endpoints";

export const settingsApi = {
    getOrganizations: async (): Promise<IRequestResult<IOrganization[]>> => {
        let result: IRequestResult<IOrganization[]> = { successed: false };
        await fetch(endpoints.v1.organizations)
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IOrganization[]>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    changeOrganization: async (organicationId: string): Promise<IRequestResult<undefined>> => {
        let result: IRequestResult<undefined> = { successed: false };
        await fetch(endpoints.v1.changeOrganization(organicationId), {
            method: 'PATCH'
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<undefined>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    organization: {
        getSettings: async (): Promise<IRequestResult<IOrganizationSettings>> => {
            let result: IRequestResult<IOrganizationSettings> = { successed: false };
            await fetch(endpoints.v1.organization.settings.getSettings)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IOrganizationSettings>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateSettingsOverview: async (data: IOrganizationSettingsOverview): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.settings.settingsOverview, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateSettingsAdditional: async (data: IOrganizationSettingsAdditional): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.settings.settingsAdditional, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateSettingsLocale: async (data: IOrganizationSettingsLocale): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.settings.settingsLocale, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateLogo: async (logoSrc: string) => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.settings.settingsLogo(logoSrc), {
                method: 'PATCH'
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteLogo: async () => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.settings.settingsLogo(), {
                method: 'DELETE'
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteFile: async (container: string, fileKey: string) => {
            let result: IRequestResult<undefined> = { successed: false};
            await fetch(endpoints.v1.deleteFile(container, fileKey), {
                method: 'DELETE'
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        uploadFile: async (container: string, file: File, setProgress?: (progress: number) => void, noPreview?: boolean): Promise<IRequestResult<string>> => {
            return new Promise((resolve, reject) => {
                let result: IRequestResult<string> = { successed: false };
                let body = new FormData()
                const error: IResponseError = { description: 'Bad response from server' }
                result.errors = [error]

                body.append('uploadFile', file)

                let xhr = new XMLHttpRequest();
                xhr.upload.onprogress = function (event) {
                    setProgress && setProgress(event.loaded / event.total)
                }
                xhr.open("POST", endpoints.v1.uploadFile(container, noPreview))
                xhr.responseType = 'json';
                xhr.onload = function () {
                    if (xhr.status == 200) {
                        resolve(xhr.response);
                    } else {
                        resolve(result);
                    }
                };
                xhr.onerror = function () {
                    resolve(result);
                };
                xhr.send(body);
            });
        },
        updatePrivateKey: async (): Promise<IRequestResult<string>> => {
            let result: IRequestResult<string> = { successed: false };
            await fetch(endpoints.v1.organization.settings.organizationSecurityKey, {
                method: 'POST'
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<string>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deletePrivateKey: async (): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.settings.organizationSecurityKey, {
                method: 'DELETE'
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getPiceasoftCredentials: async (): Promise<IRequestResult<IPiceaCredentials>> =>{
            let result: IRequestResult<IPiceaCredentials> = { successed: false };
            await fetch(endpoints.v1.organization.settings.organizationSecurityPiceasoft)
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IPiceaCredentials>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })  .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        generateNewPiceasoftCredentials: async (): Promise<IRequestResult<IPiceaCredentials>> =>{
            let result: IRequestResult<IPiceaCredentials> = { successed: false };
            await fetch(endpoints.v1.organization.settings.organizationSecurityPiceasoft,{
                method:'PATCH'
            })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IPiceaCredentials>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })  .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updatePiceasoftCredentials: async (data: IPiceaCredentials): Promise<IRequestResult<IPiceaCredentials>> =>{
            let result: IRequestResult<IPiceaCredentials> = { successed: false };
            await fetch(endpoints.v1.organization.settings.organizationSecurityPiceasoft, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IPiceaCredentials>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })  .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deletePiceasoftCredentials: async (): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.settings.organizationSecurityPiceasoft, {
                method: 'DELETE'
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    }
}