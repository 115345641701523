import { DetailsList, Text, IColumn, Icon, IconButton, SelectionMode, SharedColors, Stack, getTheme, IContextualMenuProps, ContextualMenuItemType, IContextualMenuItem, PrimaryButton, DirectionalHint, IButtonStyles, mergeStyleSets, DetailsRow, IDetailsListProps, IDetailsRowStyles, IStyle, Link, FontWeights, TooltipHost, TooltipDelay, IContextualMenuItemProps } from '@fluentui/react'
import * as React from 'react'
import { verifyDescriber } from '../../../../../../../../core/scripts/picea.describer'
import { ISoftwarePiceaConfig } from '../../../../../../../../core/store'
import { ISoftwareCheck } from '../../../../../../../../core/store/typings/ISoftwarePiceaConfig'
import { strings } from '../../../../../../../../localization/strings'
import { Section } from '../../../../../../decorations/Section'
import { onRenderColumnHeaderCommon } from '../../../../../../detailsList/commonRenders'
import { IGradesCategory, SoftwareModes } from '@piceasoft/core'
import { ErrorsRenderVerify } from '../../../../../../../../screens/services/Service/ServiceConfigurationDetails/ValidationInspectionsModules'
import { definitions, IVerifyCheck, SupportedVerifyChecks, VerifyRunTypes, IGrade } from '@piceasoft/core'
import { Badge } from '../../../../../../decorations/Badge'

export type TPiceaChecksConfiguratorModePivotProps = {
    config: ISoftwarePiceaConfig
    mode: SoftwareModes
    grades: IGrade[]
    gradesCategories?: IGradesCategory[]
    useGradesCategories?: boolean
    onModeConfirm: (config: ISoftwarePiceaConfig) => void
    withoutGrades?: boolean
}

const UNDEFINED_ICON = "ConstructionCone"
const DOWNGRADE_ICON = "MarketDown"
const DECLINE_ICON = "HandsFree"

/** Verify checks currently supported for PiceaMobile
 * 
 */
 enum PiceaMobileVerifyChecks {
    /** Verifies Apple's Find My service is not enabled in device */
    VerifyCheck_FindMy = 5,
    /** Verifies the GSMA Device Check™ status of the device */
    VerifyCheck_GSMADeviceCheck = 6,
    /** Verifies the device has no Google account */
    VerifyCheck_GoogleAccount = 17,
}

export const PiceaChecksConfiguratorModePivot: React.FC<TPiceaChecksConfiguratorModePivotProps> = (props) => {

    const getItemMenuProps = (checkId: number, checkGrade?: string) => {
        let result: IContextualMenuProps = {
            items: []
        }
        result.items.push(getGradeAssignmentMenuItem(checkId, checkGrade))
        let actionsItem: IContextualMenuItem = {
            key: `check-${checkId}-actions`,
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                items: getCheckActionsMenuProps(checkId).items,
                topDivider: true,
                title: strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.CONTEXTUAL_MENU.ACTIONS,
            }
        }
        result.items.push(actionsItem)
        return result
    }

    const onConfirmCheck = (check: ISoftwareCheck) => {
        props.onModeConfirm({
            ...props.config,
            checks: props.config.checks.map((i) => {
                if (i.id === check.id) {
                    return check
                }
                return i
            })
        })
    }

    const getChecksMenuProps = (): IContextualMenuProps => {
        const supportedChecks = SoftwareModes.Piceasoft ===  props.mode ? Object.keys( SupportedVerifyChecks) : Object.keys( PiceaMobileVerifyChecks);
        let items = supportedChecks
            .filter(k => !isNaN(Number(k)))
            .filter(k => k !== '0')
            .map(i => Number(i))
            .filter(i => !props.config.checks?.find(c => c.id === i))?.map(check => ({
                key: `add-check-${check}`,
                onClick: () => onAddCheck(check),
                text: verifyDescriber(check),
                onRenderContent: (props: IContextualMenuItemProps) => {
                    let checkInfo = definitions.verify.getCheck(check);
                    return (
                        <Stack grow>
                            <TooltipHost calloutProps={{ gapSpace: 10 }} content={onRenderCheckInfo(checkInfo)} directionalHint={DirectionalHint.rightCenter} >
                                <Text>{props.item.text}</Text>
                            </TooltipHost>
                        </Stack>
                    )
                }
            }));

        return {
            items: items,
            directionalHintFixed: true
        }
    }

    const getCheckActionsMenuProps = (checkId: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `delete-set-${checkId}`,
                    onClick: () => onDeleteCheck(checkId),
                    text: strings.BUTTONS.TEXT.DELETE,
                    iconProps: { iconName: "Delete", style: { color: theme.palette.black } }
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const onAddCheck = (checkId: number) => {
        props.onModeConfirm({
            ...props.config,
            checks: props.config.checks ? [...props.config.checks.filter((check) => check.id !== checkId), { id: checkId }] : [{ id: checkId }]
        })
    }

    const onDeleteCheck = (checkId: number) => {
        props.onModeConfirm({
            ...props.config,
            checks: props.config.checks.filter((check) => check.id !== checkId)
        })
    }

    const getGradeAssignmentText = (grade?: string, category?: string, isCategoryHidden?: boolean) => {
        switch (grade) {
            case undefined:
                return strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.NO_CHANGE_GRADE;
            case "": return strings.CONSTRUCTOR.GRADES.DECLINE;
            case '!WASRESET':
                return strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_WAS_RESET;
            default: return getGradeText(grade, category, isCategoryHidden)
        }
    }

    const getGradeAssignmentTextColor = (grade?: string) => {
        switch (grade) {
            case undefined: return SharedColors.gray20
            case "": return SharedColors.red10
            case "!WASRESET": return SharedColors.red10
            default: return SharedColors.cyanBlue10
        }
    }

    const getGradeText = (gradeCode?: string, categoryCode?: string, isCategoryHidden?: boolean): string => {
        let gradeText = strings.CONSTRUCTOR.GRADES.GRADE_NOT_FOUND
        if (categoryCode) {
            const category = props.gradesCategories?.find(i => i.code === categoryCode)
            const grade = category?.grades.find(i => i.code === gradeCode)
            if (grade) {
                gradeText = isCategoryHidden ? grade.name : `${category?.name}: ${grade.name}`
            }
        } else {
            const grade = props.grades.find(i => i.code === gradeCode)
            if (grade) {
                gradeText = grade.name
            }
        }
        return gradeText;
    }

    const getGradeAssignmentIcon = (grade?: string) => {
        switch (grade) {
            case undefined: return UNDEFINED_ICON
            case "": return DECLINE_ICON
            // case "!WASRESET": return ERROR_ICON
            default: return DOWNGRADE_ICON
        }
    }

    const getGradeAssignmentMenuItem = (checkId: number, checkGrade?: string) => {
        let result: IContextualMenuItem = {
            key: `check-${checkId}-assingment`,
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                items: [],
                topDivider: true,
                bottomDivider: true,
                title: strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.CONTEXTUAL_MENU.ASSIGNMENT,
            }
        }
        result.sectionProps?.items.push(getGradeMenuItem(`check-${checkId}-no-grade`, checkId, UNDEFINED_ICON, checkGrade, undefined, undefined))
        let gradeMenu: IContextualMenuItem = {
            iconProps: { iconName: DOWNGRADE_ICON, style: { color: theme.palette.black } },
            key: `check-${checkId}-grades`,
            subMenuProps: {
                items: []
            },
            text: strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.CONTEXTUAL_MENU.USE_DOWN_GRADE
        }
        if (props.useGradesCategories) {
            props.gradesCategories?.forEach(gc => {
                let gradeCategoryMenuItem: IContextualMenuItem = {
                    key: `check-${checkId}-gradeCategory-${gc.code}}`,
                    subMenuProps: {
                        items: []
                    },
                    text: gc.name
                }

                gc.grades.forEach(g => {
                    gradeCategoryMenuItem.subMenuProps?.items.push(getGradeMenuItem(`check-${checkId}-grade-${gc.code}-${g.code}`, checkId, undefined, checkGrade, g.code, gc.code))
                })

                gradeMenu.subMenuProps?.items.push(gradeCategoryMenuItem)
            })
        } else {
            props.grades?.forEach(g => {
                gradeMenu.subMenuProps?.items.push(getGradeMenuItem(`check-${checkId}-grade-${g.code}`, checkId, undefined, checkGrade, g.code))
            })


        }
        result.sectionProps?.items.push(gradeMenu)
        result.sectionProps?.items.push(getGradeMenuItem(`check-${checkId}-decline`, checkId, DECLINE_ICON, checkGrade, "", undefined))
        return result;
    }

    const getGradeMenuItem = (key: string, checkId: number, icon?: string, checkGrade?: string, code?: string, category?: string): IContextualMenuItem => {
        const itemCategory = checkGrade?.indexOf("-") === -1 ? undefined : checkGrade?.split("-")[0]
        return {
            iconProps: icon ? { iconName: icon, style: { color: theme.palette.black } } : undefined,
            key: key,
            onClick: () =>
                onConfirmCheck({ ...props.config.checks?.find(i => i.id === checkId) as ISoftwareCheck, grade: category ? `${category}-${code}` : code }),
            text: getGradeAssignmentText(code, category, true),
            disabled: itemCategory ? `${category}-${code}` === checkGrade : code === checkGrade
        }
    }

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            var checkInfo = definitions.verify.getCheck(rProps.item.id);
            if (!checkInfo?.supported) {
                customStyles.root = {
                    backgroundColor: "rgba(253, 231, 233,0.6)",
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: "rgb(253, 231, 233)",
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.hidden-button': {
                            color: theme.palette.black
                        },
                        '.up-icon': {
                            fontSize: 16,
                            color: theme.palette.themePrimary
                        }
                    }
                };
                customStyles.cellUnpadded = { paddingLeft: 6 }
            } else {
                customStyles.root = customRowRootStyle;
            }
            customStyles.cellUnpadded = { paddingLeft: 6 }
            return <DetailsRow {...rProps} styles={customStyles} />
        }
        return null;
    }

    const styles = mergeStyleSets({
        button: {
            width: 130,
        },
        callout: {
            width: 320
        },
        title: {
            marginBottom: 12,
            fontWeight: FontWeights.semilight,
        },
        description: {
            marginBottom: 12,
        },
        runType: {
            color: SharedColors.gray10,
        },
        supported: {
            marginBottom: 6,
            fontWeight: FontWeights.semibold,
        },
        platform: {
            color: SharedColors.cyanBlue10,
            fontWeight: FontWeights.semibold,
        },
        link: {
            display: 'block',
            marginTop: 20,
        },
    });

    const onRenderCheckInfo = (item?: IVerifyCheck): JSX.Element | undefined => {
        if (item) {
            const imageString = definitions.verify.getImage(item.id)
            let base64icon = ''
            if (imageString) {
                base64icon = btoa(imageString)
            }
            return (
                <Stack key={item.id} tokens={{ padding: "10px 14px" }} className={styles.callout}>
                    <Stack.Item>
                        <Stack horizontal grow tokens={{ childrenGap: 16 }}>
                            <Stack.Item grow>
                                <Text block variant="xLarge" className={styles.title}>
                                    {item.getName()}
                                </Text>
                            </Stack.Item>
                            <Stack.Item>
                                {imageString && (
                                    <img style={{ height: 24, width: 24 }} src={`data:image/svg+xml;base64,${base64icon}`} />
                                ) || (
                                        <Icon style={{ fontSize: 16 }} iconName='BlockedSite' />
                                    )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Text block variant="small" className={styles.description}>
                            {item.getDescription()}
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text block variant="small" className={styles.supported}>
                            {strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.CONTEXTUAL_MENU.TOOLTIP_MENU.SUPPORTING}
                        </Text>
                        <Stack tokens={{ childrenGap: 4 }}>
                            {item.platforms.map((p, index) => (
                                <Stack.Item key={index}>
                                    <Stack horizontal grow>
                                        <Stack horizontal grow tokens={{ childrenGap: 8 }}>
                                            <Text variant="small" className={styles.platform}>{p.name}</Text>
                                            <Text variant="xSmall" className={styles.runType}>{definitions.verify.runTypeDescriber(p.runType as VerifyRunTypes)}</Text>
                                        </Stack>
                                        <Stack.Item>
                                            <Stack horizontal tokens={{ childrenGap: 4 }}>
                                                {p.connectionTypes.map((ct, index) => (
                                                    <Badge key={index} title={ct} />
                                                ))}
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            ))}
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Link href="#" target="_blank" className={styles.link}>
                            {strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.CONTEXTUAL_MENU.TOOLTIP_MENU.LINK}
                        </Link>
                    </Stack.Item>
                </Stack>
            )
        }
    };

    let columns: IColumn[] = [
        {
            key: 'column1',
            name: "",
            fieldName: 'checkName',
            minWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: () => {
                return (
                    <Stack tokens={{ padding: "0px 32px 0px 6px" }} horizontal>
                        <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.CHECK_TITLE}</Text>
                    </Stack>
                )
            },
            onRender: (item: ISoftwareCheck) => {
                var checkInfo = definitions.verify.getCheck(item.id);
                if (!checkInfo) return null;
                const imageString = definitions.verify.getImage(item.id)
                let base64icon = ''
                if (imageString) {
                    base64icon = btoa(imageString)
                }
                return (
                    <Stack horizontal verticalFill verticalAlign="center" tokens={{ childrenGap: 12, padding: '0 0 0 6px' }}>
                        {imageString && (
                            <img style={{ height: 24, width: 24 }} src={`data:image/svg+xml;base64,${base64icon}`} />
                        ) || (
                                <Icon style={{ fontSize: 16 }} iconName='BlockedSite' />
                            )}
                        <Text style={{ color: SharedColors.gray40 }}>{checkInfo.getName()}</Text>
                        <TooltipHost content={checkInfo.supported? onRenderCheckInfo(checkInfo) : strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.CONTEXTUAL_MENU.TOOLTIP_MENU.UNSUPPORTED_CHECK} delay={TooltipDelay.zero}
                            directionalHint={DirectionalHint.bottomCenter}
                            styles={{ root: { display: 'flex' } }}
                        >
                            <Icon iconName="Info" style={{ color: !checkInfo.supported ? SharedColors.red10 : undefined }} />
                        </TooltipHost>
                    </Stack>
                )
            }
        }
    ]

    if (!props.withoutGrades) {
        columns.push(
            {
                key: 'column3',
                name: strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.CONTEXTUAL_MENU.ASSIGNMENT,
                fieldName: 'grade',
                minWidth: 250,
                isResizable: false,
                data: 'string',
                styles: { cellTitle: { justifyContent: 'flex-end', paddingRight: 22 }, root: { ':hover': { backgroundColor: 'transparent' } } },
                onRenderHeader: onRenderColumnHeaderCommon,
                onRender: (item: ISoftwareCheck) => {
                    const checkCategory = item.grade?.indexOf("-") === -1 ? undefined : item.grade?.split("-")[0]
                    const checkGrade = item.grade?.indexOf("-") === -1 ? item.grade : item.grade?.split("-")[1]
                    return (
                        <Stack grow horizontalAlign='end' horizontal verticalAlign='center' className={classNames.listItem}>
                            {item.errors && item.errors.length > 0 && (
                                <ErrorsRenderVerify check={item} />
                            )}
                            {(item && (!item.errors || item.errors.length === 0)) && (
                                <Stack grow horizontalAlign='end' horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                                    <Icon style={{ fontSize: 14, color: checkGrade && checkGrade === "!WASRESET" ? SharedColors.red10 : undefined }} iconName={getGradeAssignmentIcon(checkGrade)} />
                                    <Text variant='small' style={{ color: getGradeAssignmentTextColor(checkGrade) }}>{getGradeAssignmentText(checkGrade, checkCategory)}</Text>
                                </Stack>
                            )}{
                                <Stack horizontal verticalFill verticalAlign='center'>
                                    <Stack.Item>
                                        <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }}
                                            styles={onHoverIconStyles}
                                            menuProps={getItemMenuProps(item?.id, checkGrade)} />
                                    </Stack.Item>
                                </Stack>
                            }
                        </Stack>
                    )
                }
            }
        )
    } else {
        columns.push(
            {
                key: 'column3',
                name: "",
                // name: strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.CONTEXTUAL_MENU.ACTIONS,
                fieldName: 'grade',
                minWidth: 50,
                isResizable: false,
                data: 'string',
                styles: { cellTitle: { justifyContent: 'flex-end', paddingRight: 22 }, root: { ':hover': { backgroundColor: 'transparent' } } },
                onRenderHeader: onRenderColumnHeaderCommon,
                onRender: (item: ISoftwareCheck) => {
                    return (
                        <Stack grow horizontal verticalAlign='center' className={classNames.listItem}>
                            <Stack horizontal verticalFill verticalAlign='center'>
                                <Stack.Item>
                                    <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }}
                                        styles={onHoverIconStyles}
                                        menuProps={getCheckActionsMenuProps(item?.id)} />
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    )
                }
            }
        )
    }

    return (
        <Section flat max>
            <Stack tokens={{ padding: "16px 16px" }} horizontal>
                <PrimaryButton disabled={getChecksMenuProps().items.length === 0} menuProps={getChecksMenuProps().items.length > 0 ? getChecksMenuProps() : undefined}>{strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.ADD_CHECKS}</PrimaryButton>
            </Stack>
            <DetailsList items={props.config?.checks ?? []}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderRow={onRenderRow}
            />
        </Section>
    )
}
const theme = getTheme();
const onHoverIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}
const classNames = mergeStyleSets({
    listItem: [
        {
            borderLeft: '2px solid transparent',
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                },
                '.up-icon': {
                    fontSize: 16,
                    color: theme.palette.themePrimary
                }
            }
        },
    ]
});

const customRowRootStyle: IStyle = {
    cursor: 'default',
    ':hover': {
        '.disabled-icon': {
            color: theme.palette.black
        },
        '.menu-icon': {
            color: theme.palette.black
        },
        '.up-icon': {
            fontSize: 16,
            color: theme.palette.themePrimary
        }
    }
};
