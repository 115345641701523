/**Service types for workflow creation.*/
export enum ServiceTypes {
    Generic = 2,
    TradeIn = 11,
    Insurance = 12,
    Finance = 13,
    Repair = 14,
    Warranty = 15,
    SelfService = 16,
    CustomerSupport = 17,
    Recycling = 18,
    OutOfBoxWarranty = 19,
    ContentTransfer = 10
}

export const CommonServiceTypes: ServiceTypes[] = [
    ServiceTypes.TradeIn,
    ServiceTypes.Insurance,
    ServiceTypes.Finance,
    ServiceTypes.Repair,
    ServiceTypes.Warranty,
    ServiceTypes.SelfService,
    ServiceTypes.CustomerSupport,
    ServiceTypes.Recycling,
    ServiceTypes.OutOfBoxWarranty
]

export const AllServiceTypes: ServiceTypes[] = [
    ...CommonServiceTypes,
    ServiceTypes.ContentTransfer
]