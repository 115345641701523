import * as React from 'react';
import {
    DocumentCard,
    DocumentCardActions,
    DocumentCardTitle,
    IDocumentCardStyles,
    mergeStyles,
    mergeStyleSets,
    Stack,
    FontIcon,
    FontSizes,
    Image,
    ImageFit
} from '@fluentui/react';

type StatePropsType = {
    title: string
    status: string
    statusIcon: string
    iconName: string
    isSending?: true
    persentSended?: number
    imgUrl: string
}

type DispatchPropsType = {
    deleteFile: () => void
}

type PropsType = StatePropsType & DispatchPropsType

export const FileCard: React.FunctionComponent<PropsType> = (props) => {

    const documentCardActions = [
        {
            iconProps: { iconName: 'Delete' },
            onClick: props.deleteFile,
            ariaLabel: 'remove file',
        },
    ];

    const cardStyles: IDocumentCardStyles = {
        root: {
            display: "flex",
            flexFlow: "column",
            minWidth: 160,
            width: 160,
        },
    };

    const iconClass = mergeStyles({
        // fontSize: "4em",
        // margin: '0.4em 0'
    });
    const classNames = mergeStyleSets({
        deepSkyBlue: [{ color: 'gray' }, iconClass],
        greenYellow: [{ color: 'greenyellow' }, iconClass],
        salmon: [{ color: 'salmon' }, iconClass],
    });

    return (
        <DocumentCard styles={cardStyles}>
            <Stack style={{ width: 160, height: 120, textAlign: "center", justifyContent: "center", border: 1, background: "black" }}>
                {!props.imgUrl 
                ? <FontIcon iconName={props.iconName} className={classNames.deepSkyBlue}/>
                : <Image src={props.imgUrl} width={160} height={120} imageFit={ImageFit.contain} />}
            </Stack>
            <Stack horizontal horizontalAlign="space-between">
                <DocumentCardTitle styles={{ root: { fontSize: FontSizes.small, height: "32" } }} title={props.title} shouldTruncate />
                <DocumentCardActions actions={documentCardActions} />
            </Stack>
        </DocumentCard>
    );
};
