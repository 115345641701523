import { Text, IconButton, Stack, getTheme, ScrollablePane } from '@fluentui/react'
import { useBoolean } from '@uifabric/react-hooks'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../../../../../../../../core/store'
import { ConnectionTypes, DiagnosticsModes, IDiagnosticsPiceaConfig, IDiagnosticsPiceaTestConfig, IDiagnosticsWebBasedConfig, IGrade } from "@piceasoft/core"
import { strings } from '../../../../../../../../localization/strings'
import { ItemsNotFound } from '../../../../../../notFound/ItemsNotFound'
import { CustomPanel } from '../../../../../../panel/CustomPanel'
import { DiagnosticsTestConfigurator } from './DiagnosticsTestConfigurator'
import { DiagnosticsTestForm } from './DiagnosticsTestForm'
import { TestsList } from './TestsList'

export type TPiceaChecksConfiguratorModePivotProps = {
    config: IDiagnosticsPiceaConfig | IDiagnosticsWebBasedConfig
    gradesCategory?: string
    mode: DiagnosticsModes
    onModeConfirm: (config: IDiagnosticsPiceaConfig | IDiagnosticsWebBasedConfig) => void
}

export const PiceaTestsConfiguratorModePivot: React.FC<TPiceaChecksConfiguratorModePivotProps> = (props) => {
    const [testPanelState, setTestPanelState] = React.useState<{ index: number, item: IDiagnosticsPiceaTestConfig }>()
    const [isTestPanelOpen, { setTrue: showTestPanel, setFalse: hideTestPanel }] = useBoolean(false)

    const [selectedTestOrder, setSelectedTestOrder] = React.useState(-1)

    const grades = (props.gradesCategory
        ? useSelector((s: IStore) => s.configurator.gradesCategories?.find(i => i.code === props.gradesCategory))?.grades
        : useSelector((s: IStore) => s.configurator.grades)) as IGrade[]
    const useGradesCategories = useSelector((s: IStore) => s.configurator.useGradesCategories)
    const gradesCategories = useSelector((s: IStore) => s.configurator.gradesCategories) ?? []
    
    React.useEffect(() => {
        if (selectedTestOrder < 0 && props.config.tests && props.config.tests.length > 0) {
            setSelectedTestOrder(0)
        }
        if (!props.config.tests || props.config.tests.length === 0) {
            setSelectedTestOrder(-1)
        }
        if (props.config.tests && selectedTestOrder >= 0 && !props.config.tests[selectedTestOrder]) {
            setSelectedTestOrder(props.config.tests.length - 1)
        }
    }, [props.config.tests])

    React.useEffect(() => {
        if (testPanelState) {
            showTestPanel()
        } else {
            hideTestPanel()
        }
    }, [testPanelState])

    const onAddTest = () => {
        const nextIndex = (props.config.tests && props.config.tests.length > 0) ? Math.max(...props.config.tests.map(i => i.index)) + 1 : 1
        // console.log("nextIndex: " + nextIndex)
        setTestPanelState({ index: -1, item: { name: "", description: "", sets: [], index: nextIndex } })
    }

    const onConfirmTest = (item: IDiagnosticsPiceaTestConfig) => {
        // console.log(item.sets)
        if (testPanelState && testPanelState?.index >= 0) {
            props.onModeConfirm({
                ...props.config,
                tests: (props.config.tests ?? []).map((t, tIndex) => {
                    if (testPanelState?.index === tIndex) {
                        return item;
                    }
                    return t;
                })
            });
        } else {
            props.onModeConfirm({
                ...props.config,
                tests: props.config.tests ? [...props.config.tests, item] : [item]
            });
        }
        onCancelTest();
    }

    const onUpdateTest = (item: IDiagnosticsPiceaTestConfig) => {
        // console.log(item.sets)
        props.onModeConfirm({
            ...props.config,
            tests: (props.config.tests ?? []).map((t) => {
                if (item.index === t.index) {
                    return item;
                }
                return t;
            })
        });
    }

    const onEditTest = (index: number) => {
        // console.log(index)
        let testOrder = -1;
        props.config.tests.forEach((t, order) => {
            if (t.index === index) {
                testOrder = order;
            }
        })
        const test = props.config.tests.find(i => i.index === index)
        if (test) {
            setTestPanelState({ index: testOrder, item: test })
        }
    }

    const onMoveUpTest = (testIndex: number) => {
        let testOrder = -1;
        props.config.tests.forEach((t, order) => {
            if (t.index === testIndex) {
                testOrder = order;
            }
        })
        if (testOrder > 0) {
            const newTests = props.config.tests.map((t, order) => {
                if (order === testOrder - 1) {
                    return props.config.tests[testOrder];
                }
                if (order === testOrder) {
                    return props.config.tests[testOrder - 1];
                }
                return t;
            });
            props.onModeConfirm({
                ...props.config,
                tests: newTests
            });
        }
    }

    const onDeleteTest = (lIndex: number) => {
        props.onModeConfirm({
            ...props.config,
            tests: props.config.tests.filter(t => t.index !== lIndex)
        })
    }

    const onCancelTest = () => {
        hideTestPanel();
        setTimeout(() => setTestPanelState(undefined), 1000);
    }

    return (
        <Stack horizontal grow verticalFill>
            <Stack.Item verticalFill style={{ minWidth: 350, maxWidth: 450, borderRight: `1px solid ${theme.semanticColors.bodyDivider}`, backgroundColor: theme.palette.neutralLighterAlt }}>
                <Stack tokens={{ childrenGap: 8 }} verticalFill>
                    <Stack.Item>
                        <Stack horizontal grow verticalAlign='center' tokens={{ padding: '8px 8px 0px 20px' }}>
                            <Stack.Item grow>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                    {/* <Icon iconName="StatusCircleQuestionMark" /> */}
                                    <Text variant='large' style={{ fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TESTS}</Text>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <IconButton menuIconProps={{ style: { fontSize: 16, color: theme.palette.black }, iconName: "Add" }} onClick={onAddTest} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                        <ScrollablePane>
                            {props.config.tests && props.config.tests.length > 0 && (
                                <TestsList
                                    tests={props.config.tests.map((q, order) => { return { isSelected: order === selectedTestOrder, data: q } }) ?? []}
                                    setOrder={setSelectedTestOrder}
                                    index={props.config.tests[selectedTestOrder]?.index ?? -1}
                                    onDelete={onDeleteTest}
                                    onUp={onMoveUpTest}
                                    onEdit={(item) => onEditTest(item.index)}
                                />
                            )}
                        </ScrollablePane>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack verticalFill grow tokens={{ padding: "20px 0px 0px 0px", childrenGap: 16 }}>
                {(!props.config.tests || props.config.tests.length === 0) && (
                    <ItemsNotFound
                        onClick={onAddTest}
                        buttonText={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.NOT_FOUND_CASE.BUTTON_TEXT}
                        info={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.NOT_FOUND_CASE.INFO}
                        suggestion={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.NOT_FOUND_CASE.SUGGESTION}
                        imgSrc={"images/navigation/images/not_found.png"} />
                )}
                {props.config.tests[selectedTestOrder] && (
                    <DiagnosticsTestConfigurator
                        onUpdateTest={onUpdateTest}
                        grades={grades}
                        gradesCategories={gradesCategories}
                        useGradesCategories={useGradesCategories}
                        item={props.config.tests[selectedTestOrder]}
                        onEditTest={onEditTest}
                        helpTopic={ DiagnosticsModes.Piceasoft === props.mode ? '1136' : ''}
                        supportedConnections={ DiagnosticsModes.Piceasoft === props.mode ? [ConnectionTypes.USB, ConnectionTypes.WIRELESS] : [ConnectionTypes.WEB]}
                    />
                )}
            </Stack>
            <Stack.Item>
                <CustomPanel isOpen={isTestPanelOpen} onCancel={onCancelTest} title={(testPanelState && testPanelState.index < 0) ? strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.ADD_TEST : strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.EDIT_TEST}>
                    <DiagnosticsTestForm
                        test={testPanelState?.item}
                        grades={grades}
                        onConfirm={onConfirmTest} />
                </CustomPanel>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();