import {
    ActionButton, CompoundButton, DefaultButton, FontIcon,
    FontWeights, getTheme, IButtonStyles, IconButton, IIconProps,
    Image, ImageFit, mergeStyleSets, MessageBar, MessageBarType,
    PrimaryButton, Separator, Stack, Text,
    TooltipHost
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { IInterviewSequenceConfig } from '@piceasoft/core';
import { IQuestion, IGrade } from "@piceasoft/core"
import { strings } from '../../../../../../../localization/strings';
import { IAnswerOption, TAnswerButtonType } from '@piceasoft/core';
type TProps = {
    question: IQuestion
    config: IInterviewSequenceConfig
    grade?: IGrade
    isFirstQuestion?: boolean
    isFinished?: boolean
    onReset: () => void
    onFinish: () => void
    onRollback: () => void
    onAction: (item: IAnswerOption) => void
    headerText: string
}

export const SequenceQuestionPreview: React.FC<TProps> = (props) => {

    const getButtonStyles = (props: TAnswerButtonType): IButtonStyles => {
        return ({
            root: {
                minWidth: 144,
                color: props.textColor,
                borderColor: props.color,
                backgroundColor: props.color
            },
            rootHovered: {
                color: props.textColor,
                backgroundColor: props.colorHover,
                borderColor: props.color
            },
            rootPressed: {
                color: props.textColor,
                backgroundColor: props.colorActive,
                borderColor: props.color
            },
            description: { color: props.textColor },
            descriptionHovered: { color: props.textColor },
            descriptionPressed: { color: props.textColor }
        })
    }

    const answerOptions = props.question.answers.map((item, index) => {
        const isPrimary = props.config.allowPrimary ? item.next ? false : true : false
        if (props.config.allowSecondaryText) {
            return (
                <CompoundButton
                    key={index}
                    text={item.text}
                    secondaryText={item.secondaryText}
                    onClick={() => props.onAction(item)}
                    primary={isPrimary}
                    styles={item.button ? getButtonStyles(item.button) : { root: { minWidth: 144 } }}
                />
            )
        } else {
            return (
                <DefaultButton
                    key={index}
                    text={item.text}
                    onClick={() => props.onAction(item)}
                    allowDisabledFocus
                    primary={isPrimary}
                    styles={item.button ? getButtonStyles(item.button) : { root: { minWidth: 144 } }}
                />
            )
        }
    })

    const onRenderBodyContent = () => {
        return (
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <Text style={{ color: theme.palette.blue }} variant="xLarge">{props.question.title}</Text>
                </Stack.Item>
                {props.question.images && (
                    <Stack.Item>
                        <Image height={240} src={props.question.images[0]} imageFit={ImageFit.centerContain} />
                    </Stack.Item>
                )}
                <Stack.Item style={{ color: SharedColors.gray40 }}>
                    <div dangerouslySetInnerHTML={{ __html: props.question.text }}></div>
                </Stack.Item>
                {props.question.warning && (
                    <Stack.Item>
                        <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={true}>
                            {props.question.warning}
                        </MessageBar>
                    </Stack.Item>
                )}
            </Stack>
        )
    }

    const onRenderFooterContent = () => {
        return (
            <Stack horizontal verticalAlign="center">
                <Stack.Item grow>
                    {props.config.allowRollback && (
                        <TooltipHost content={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.SEQUENCE_TO_PREVIOUS_QUIESTION} styles={{ root: { display: 'inline-block' } }}>
                            <ActionButton iconProps={{ iconName: "Back" }} onClick={() => props.onRollback()} disabled={props.isFirstQuestion} />
                        </TooltipHost>
                    )}
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 4 }}>
                        {answerOptions}
                    </Stack>
                </Stack.Item>
            </Stack>
        )
    }

    const onRenderPanelBodyFinished = () => {
        return (
            <Stack horizontalAlign="center" verticalFill verticalAlign="center" tokens={{ childrenGap: 16, padding: 16 }}>
                <Stack verticalAlign="center">
                    <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                        {props.grade && (
                            <FontIcon iconName="CompletedSolid" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.green10 }} />
                        ) || (
                                <FontIcon iconName="HandsFree" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.red10 }} />
                            )}
                    </Stack>
                </Stack>
                <Stack.Item>
                    {props.grade && (
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 16 }}>
                            <Text style={{ color: SharedColors.gray40 }}>{strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.DEVICE_GRADE}</Text>
                            <Stack horizontalAlign="center">
                                <Text variant="large" style={{ color: SharedColors.cyanBlue10 }}>{props.grade?.name}</Text>
                                <Text variant="medium" style={{ color: SharedColors.gray40 }}>{props.grade?.explanation}</Text>
                            </Stack>
                        </Stack>
                    ) || (
                            <Text style={{ color: SharedColors.gray40 }}>
                                {props.config.ui?.reject ?? strings.CONSTRUCTOR.INSPECTIONS.INSPECTION_DECLINE}
                            </Text>
                        )}
                </Stack.Item>
            </Stack>
        )
    }

    const onRenderFooterFinished = () => {
        return (
            <Stack horizontal verticalAlign="center">
                <Stack.Item grow>
                    {props.config.allowRollback && (
                        <TooltipHost content={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.SEQUENCE_TO_PREVIOUS_QUIESTION} styles={{ root: { display: 'inline-block' } }}>
                            <ActionButton iconProps={{ iconName: "Back" }} onClick={() => props.onRollback()} />
                        </TooltipHost>
                    )}
                </Stack.Item>
                <Stack.Item>
                    {props.config.allowSecondaryText && (
                        <Stack horizontal tokens={{ childrenGap: 4 }}>
                            <CompoundButton text={strings.BUTTONS.TEXT.CONFIRM} secondaryText={strings.BUTTONS.SECONDARY_TEXT.CONFIRM_RESULT} onClick={props.onReset} primary={true} style={{ width: 144 }} />
                            <CompoundButton text={strings.BUTTONS.TEXT.CANCEL} secondaryText={strings.BUTTONS.SECONDARY_TEXT.CANCEL_RESULT} onClick={props.onReset} primary={false} style={{ width: 144 }} />
                        </Stack>
                    ) || (
                            <Stack horizontal tokens={{ childrenGap: 4 }}>
                                <PrimaryButton style={{ width: 144 }} text={strings.BUTTONS.TEXT.CONFIRM} onClick={props.onReset} />
                                <DefaultButton style={{ width: 144 }} text={strings.BUTTONS.TEXT.CANCEL} onClick={props.onReset} />
                            </Stack>
                        )}
                </Stack.Item>
            </Stack>
        )
    }

    return (
        <>
            {props.config.useFullscreen && (
                <Stack verticalFill grow className={contentStyles.panel} tokens={{ padding: "64px 12px 32px 48px" }}>
                    <Stack grow tokens={{ padding: "16px 0px 0px 0px" }} >
                        <Stack horizontal grow tokens={{ childrenGap: 16 }}>
                            <Stack.Item grow tokens={{ padding: "0px 16px" }}>
                                <Text variant="xLarge">{props.headerText}</Text>
                            </Stack.Item>
                            <Stack.Item tokens={{ padding: "0px 16px" }}>
                                <IconButton disabled={true} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                            </Stack.Item>
                        </Stack>
                        <Separator />
                    </Stack>
                    <Stack verticalFill tokens={{ childrenGap: 16, padding: "32px" }}>
                        {!props.isFinished ? onRenderBodyContent() : onRenderPanelBodyFinished()}
                    </Stack>
                    <Stack.Item>
                        <Stack horizontalAlign='end' tokens={{ childrenGap: 16, padding: "32px" }}>
                            {!props.isFinished ? onRenderFooterContent() : onRenderFooterFinished()}
                        </Stack>
                    </Stack.Item>
                </Stack>) || (
                    <Stack verticalFill verticalAlign='center' tokens={{ padding: '64px 32px 32px 32px' }}>
                        <Stack className={contentStyles.container}>
                            <Stack grow horizontal tokens={{ padding: '12px 12px 14px 24px', childrenGap: 24 }}
                                style={{
                                    borderTop: `4px solid ${theme.palette.themePrimary}`,
                                    color: theme.palette.neutralPrimary,
                                }}
                            >
                                <Stack.Item grow>
                                    <Text variant='xLargePlus' style={{ fontWeight: FontWeights.semibold }}>{props.headerText}</Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <IconButton disabled={true} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                                </Stack.Item>
                            </Stack>
                            <div className={contentStyles.body}>
                                <Stack tokens={{ childrenGap: 16 }}>
                                    {!props.isFinished ? onRenderBodyContent() : onRenderPanelBodyFinished()}
                                    {!props.isFinished ? onRenderFooterContent() : onRenderFooterFinished()}
                                </Stack>
                            </div>
                        </Stack>
                    </Stack>
                )}
        </>
    )
}

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const contentStyles = mergeStyleSets({
    panel: {
        width: '640px',
        maxWidth: '640px',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        // boxShadow: theme.effects.elevation64,
        backgroundColor: theme.palette.white,
        // padding:'64px 32px 32px 32px'
    },
    container: {
        width: '640px',
        maxWidth: '640px',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        // boxShadow: theme.effects.elevation64,
        backgroundColor: theme.palette.white
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        heigth: '100%',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};