import { Stack, ScrollablePane } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators, getStageInspectionModules } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { IGradesCategory } from "@piceasoft/core";
import { ValidationErrors } from "../../../../../../../core/store/typings/ValidationErrors";
import { strings } from "../../../../../../../localization/strings";
import { Section } from "../../../../../decorations/Section";
import { ParameterItem } from "../../../../components/ParameterItem";
import { ProcessStages } from "@piceasoft/core";
import { IInspectionConfig, IInspectionConfigUI } from "@piceasoft/core";
import { CheckProcessStageSectionOptionAccess } from "../../../../helpers/evisibility";

type TProps = {
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
    withoutGrade?: boolean
}

export const InspectionModeCommonSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, index, stage, withoutGrade, children }) => {
    const config = useSelector((s: IStore) => getStageInspectionModules(stage, s)?.find(i => i.index === index)?.config as IInspectionConfig<IInspectionConfigUI>)
    const useGradesCategories = useSelector((s: IStore) => s.configurator.useGradesCategories)
    const gradesCategories = useSelector((s: IStore) => s.configurator.gradesCategories)
    const channel = useSelector((s: IStore) => s.configurator.channel) ?? 0;
    const skipAllowed = CheckProcessStageSectionOptionAccess( channel, stage, 'Common settings', 'Skip summary');

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.HEADER} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.AUTORUN_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.autoRun}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                                ...config,
                                autoRun: config.autoRun ? undefined : true
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.AUTORUN_DESCRIPTION}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.ALLOW_RESTART_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.allowRestart}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                                ...config,
                                allowRestart: config.allowRestart ? undefined : true
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.ALLOW_RESTART_DESCRIPTION}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.ALLOW_SKIP_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.allowSkip}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                                ...config,
                                allowSkip: config.allowSkip ? undefined : true
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.ALLOW_SKIP_DESCRIPTION}
                    />
                    {config.allowSkip && (
                        <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.GOODBYE_ON_SKIP_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.goodbyeOnSkip}
                            toggleOnChange={() => {
                                onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                                    ...config,
                                    goodbyeOnSkip: config.goodbyeOnSkip ? undefined : true
                                }))
                            }}
                            description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.GOODBYE_ON_SKIP_DESCRIPTION}
                        />
                    )}
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.RESET_STATE_AT_SKIP_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.resetStateAtSkip}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                                ...config,
                                resetStateAtSkip: config.resetStateAtSkip ? undefined : true
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.RESET_STATE_AT_SKIP_DESCRIPTION}
                    />
                    {!withoutGrade && useGradesCategories && (
                        <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.GRADES_CATEGORY_TITLE}
                            description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.GRADES_CATEGORY_DESCRIPTION}
                            useToggle={false}
                            useDropdown
                            dropdownEnabled={gradesCategories && gradesCategories.length > 0 ? true : false}
                            dropdownSelectedKey={config.gradesCategory}
                            dropdownOnChange={(opt) => onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                                ...config,
                                gradesCategory: opt?.key as string ?? undefined
                            }))
                            }
                            dropdownPlaceholder={gradesCategories && gradesCategories.length > 0
                                ? strings.CONSTRUCTOR.GRADES_CATEGORIES.PLACEHOLDER_GRADES_CATEGORIES
                                : strings.CONSTRUCTOR.GRADES_CATEGORIES.PLACEHOLDER_NO_GRADES_CATEGORIES
                            }
                            dropdownOptions={gradesCategories?.map(gc => ({ key: gc.code, text: `${((gc as IGradesCategory).name && (gc as IGradesCategory).name.length > 0) ? gc.name : strings.CONSTRUCTOR.GRADES_CATEGORIES.NEW_CATEGORY_TAB_HEADER} - ${gc.code}` }))}
                            dropdownErrorMessage={config.errors?.find((error) => error.code === ValidationErrors.CategoryNotSelected) ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.CATEGORY_GRADE_NOT_SELECTED : undefined}
                        />
                    )}
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.SKIP_CONFIRMATION_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.skipResultConfirmation}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                                ...config,
                                skipResultConfirmation: config.skipResultConfirmation ? undefined : true
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.SKIP_CONFIRMATION_DESCRIPTION}
                    />
                    { skipAllowed && (<ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.SKIP_SUMMARY_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.skipSummary}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.inspections.editInspection(stage, index, {
                                ...config,
                                skipSummary: config.skipSummary ? undefined : true
                            }))
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.SKIP_SUMMARY_DESCRIPTION}
                    />)}
                    {children}
                </Section>
            </ScrollablePane>
        </Stack.Item>

    )
}
