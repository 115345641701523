import React, {Dispatch, FC, useCallback, useEffect, useMemo, useState} from "react";
import {
    ConnectionTypes,
    DiagnosticCases,
    DiagnosticsModes,
    IDiagnosticsManualConfig,
    IDiagnosticsPiceaConfig,
    IDiagnosticsPiceaMobileCaseConfig,
    IDiagnosticsPiceaMobileSetConfig,
    IDiagnosticsWebBasedConfig,
    IInspectionConfig,
    IInspectionConfigUI,
    ISelfServiceConfigAnswer,
    ISelfServiceConfigItem,
    ISelfServiceDiagnosticCase,
    ISelfServiceDiagnosticCaseOptions,
    ISelfServiceWorkflowConfig,
    SelfServiceConfigType,
    definitions
} from "@piceasoft/core"
import {getTheme, IconButton, ScrollablePane, Stack, Text} from "@fluentui/react";
import AnswersItemsList from "./AnswersItemList";
import CasesItemList from "./CasesItemList";
import {aiApi} from "../../../../../../core/api/ai.api";
import {DiagnosticCasesMap} from "../../../../../../core/store/typings/DiagnosticCasesMap";
import {RepairComponentsMap} from "../../../../../../core/store/typings/RepairComponentsMap";
import RepairOffersList from "./RepairOffersList";
import {paddingLeftDefault, styles} from "./styles";
import EmptyListPlaceholder from "./EmptyListPlaceholder";
import RenameModal from "./RenameModal";
import {actionCreators} from "../../../../../../core/actions/configurator-actions";
import {useSelector} from "react-redux";
import {selector} from "../../../../../../core/store/selector";
import DiagnosticsConfiguration from "./DiagnosticsConfiguration";
import {useBoolean} from "@uifabric/react-hooks";
import {strings} from "../../../../../../localization/strings";
import { CustomPanel } from "../../../../panel/CustomPanel";
import { DiagnosticCaseOptionsForm } from "../../assessment/diagnostics/modeConfigurator/pivots/diagnosticCase/DiagnosticCaseOptionsForm";
import { getPiceaSetIdByCaseId, testDescriber } from "../../../../../../core/scripts/picea.describer";
import { DeviceCheckSolutions } from "../../../../../../core/store/typings/DeviceCheckSolutions";
import { SelfServiceDiagnosticCaseOptionsForm } from "./SelfServiceDiagnosticCaseOptionsForm";
import { connectionTypeToMode } from "../../../../../../core/helpers/self-service";

const theme = getTheme();

interface SelfServiceConfigView {
    categories: ISelfServiceConfigItem[]
    level1: ISelfServiceConfigItem[]
    level2: ISelfServiceConfigItem[]
    diagnostics: ISelfServiceConfigItem[]
    repairOffers: ISelfServiceConfigItem[]
    questions: ISelfServiceConfigItem[]
    solutions: ISelfServiceConfigItem[]
}

const defaultSelfServiceConfigView: () => SelfServiceConfigView = () => ({
    categories: [],
    level1: [],
    level2: [],
    diagnostics: [],
    repairOffers: [],
    questions: [],
    solutions: []
})

const DividerVertical = () => (<Stack verticalFill
                                      style={{width: 1, borderRight: `1px solid ${theme.palette.neutralQuaternary}`}}/>)

interface IProps {
    config?: ISelfServiceWorkflowConfig
    onChangeDispatch: Dispatch<any>
}

const TitleStack: FC<{ paddingLeft?: number, isSpaceBetween?: boolean }> = ({
                                                                                children,
                                                                                paddingLeft = paddingLeftDefault,
                                                                                isSpaceBetween
                                                                            }) => (
    <Stack.Item grow className={styles.sectionTitleWr}
                style={{
                    paddingLeft,
                    justifyContent: isSpaceBetween ? 'space-between' : undefined,
                    display: 'flex'
                }}>
        {children}
    </Stack.Item>
);

const updateConfigItem = (item: ISelfServiceConfigItem): ISelfServiceConfigItem => {
    if (item.type === SelfServiceConfigType.FreeText) {
        return {
            ...item,
            properties: {
                answers: [
                    {
                        id: `custom_answer-${item.id}`,
                        text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CUSTOM_ANSWER.TEXT,
                        disable: false
                    }
                ]
            }
        }
    }
    return item
}

const connectionTypes = [ConnectionTypes.NG_WIRELESS, ConnectionTypes.WEB]

// Helper function to check if a config item of a given type is present
const isConfigItemPresent = (configItems: ISelfServiceConfigItem[], configType: SelfServiceConfigType, parentId: string) =>
    configItems.some((ci: ISelfServiceConfigItem) => ci.type === configType && ci.parent === parentId);

const SelfServiceConfiguratorPivot: FC<IProps> = ({config, onChangeDispatch}) => {
    const [answersSelected, setAnswersSelected] = useState<Map<number, string>>(new Map())
    const [casesMap, setCasesMap] = useState<DiagnosticCasesMap | undefined>(undefined)
    const [componentsMap, setComponentsMap] = useState<RepairComponentsMap | undefined>(undefined)
    const [answerToEdit, setAnswerToEdit] = useState<ISelfServiceConfigAnswer | undefined>(undefined);
    const currentlang = useSelector(selector.workplace.getCurrentLanguage);
    const [isShowConfiguration, {setTrue: showConfiguration, setFalse: hideConfiguration}] = useBoolean(false)
    const [currentCaseIndex, setCurrenCaseIndex] = React.useState<number>()
    
    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            const cases = await aiApi.getDiagnosticCases(currentlang)
            const components = await aiApi.getComponents(currentlang)

            if (isSubscribed && cases.data?.message && components.data?.message) {
                setCasesMap(cases.data.message)
                setComponentsMap(components.data.message)
            }
        }

        fetchData()
            .catch(error => {
                if (isSubscribed) {
                    console.log(error)
                }
            });

        return () => {
            isSubscribed = false;
        }
    }, [])

    useEffect(() => {
        if(casesMap && config?.config.diagnostics.cases) {
            if(Object.keys(casesMap).length > config.config.diagnostics.cases.length) {
                const diagnosticsCasesIdSet = new Set(config.config.diagnostics.cases.map(c => c.caseId));
                const filteredDiagnosticCases = Object.keys(casesMap)
                    .filter(index => !diagnosticsCasesIdSet.has(Number(index)))
                    .map(m => {
                        if(DiagnosticCases[Number(m)]) {
                            const diagnosticCase = definitions.diagnostic.getCase(Number(m) as DiagnosticCases);
                            let getConnectionTypes: ConnectionTypes[] = []
                            diagnosticCase?.platforms.forEach(p => {
                                p.connectionTypes
                                    .filter(f => connectionTypes.includes(f))
                                    .forEach(c => {
                                        if(!getConnectionTypes.includes(c)) getConnectionTypes.push(c)
                                    })
                            })

                            if(getConnectionTypes.length > 0) {
                                const mode = connectionTypeToMode(getConnectionTypes[0]) ?? DiagnosticsModes.PiceaMobile;
                                return { caseId: Number(m), mode: mode } as ISelfServiceDiagnosticCase
                            } 
                        }
                    })

                if(filteredDiagnosticCases.length > 0) {
                    onChangeDispatch(actionCreators.selfService.updateSelfServiceConfigDiagnosticsCases(filteredDiagnosticCases));
                }

                const fetchData = async () => {
                    const configurations = await aiApi.getConfigurations(currentlang);

                    if(configurations.data && configurations.data.length > config.config.items.length) {
                        onChangeDispatch(actionCreators.selfService.updateSelfServiceConfigItems(configurations.data));
                    }
                }

                fetchData()
                .catch(error => {
                    console.log("AiAPI Configurations fetch error: ", error)
                });
            }
        }
    }, [casesMap])

    const {
        categories,
        level1,
        level2,
        diagnostics,
        repairOffers,
        questions,
        solutions
    }: SelfServiceConfigView = useMemo(() => {
        if (!config) {
            return defaultSelfServiceConfigView()
        }
        return config.config.items
            .reduce((acc: SelfServiceConfigView, value: ISelfServiceConfigItem) => {
                const val = updateConfigItem(value);
                switch (val.type) {
                    case SelfServiceConfigType.Question:
                    case SelfServiceConfigType.FreeText:
                        acc.questions.push(val)
                        switch (val.level) {
                            case 0:
                                acc.categories.push(val)
                                break
                            case 1:
                                acc.level1.push(val)
                                break
                            case 2:
                                acc.level2.push(val)
                                break
                        }
                        break
                    case SelfServiceConfigType.Diagnostic:
                        acc.diagnostics.push(val)
                        break
                    case SelfServiceConfigType.Catalog:
                        acc.repairOffers.push(val)
                        break
                    case SelfServiceConfigType.Solution:
                        acc.solutions.push(val)
                        break
                }
                return acc
            }, defaultSelfServiceConfigView())
    }, [config])

    useEffect(() => {
        if (!answersSelected.get(0) && categories.length) {
            const defaultCategoryAnswers = categories[0].properties?.answers
            if (defaultCategoryAnswers && defaultCategoryAnswers.length) {
                const defaultCategory = defaultCategoryAnswers[0]
                if (defaultCategory) {
                    updateSelected(0, defaultCategory.id)
                }
            }
        }
    }, [categories]);

    const updateSelected = (level: number, value: string) => {
        setAnswersSelected((prevMap) => {
            const clearedSelection = Array.from(prevMap)
                .filter(([l]) => l <= level)
            const temp = new Map(clearedSelection)
            temp.set(level, value)
            return temp
        })
    }

    const diagnosticsVisible = useMemo(() => {
        const valuesSelected = Array.from(answersSelected.values())
        if (valuesSelected.length) {
            const lastSelected = valuesSelected[valuesSelected.length - 1];
            const findAnswersRelated = (answerId: string) => {
                const result: ISelfServiceConfigAnswer[] = []
                const answers = questions
                    .filter(q => q.parent === answerId)
                    .reduce((acc, val) => {
                        acc.push(...(val.properties?.answers ?? []))
                        return acc
                    }, [] as ISelfServiceConfigAnswer[])
                answers.forEach((a) => result.push(a, ...findAnswersRelated(a.id)))
                return result;
            }

            return diagnostics.filter((d) => !d.parent
                || valuesSelected.includes(d.parent)
                || findAnswersRelated(lastSelected).map(({id}) => id).includes(d.parent)
            );
        }
        return [];
    }, [answersSelected, diagnostics, questions])

    const solutionsVisible = useMemo(() => {
        const valuesSelected = Array.from(answersSelected.values())
        if (valuesSelected.length) {
            const lastSelected = valuesSelected[valuesSelected.length - 1];
            const findAnswersRelated = (answerId: string) => {
                const result: ISelfServiceConfigAnswer[] = []
                const answers = questions
                    .filter(q => q.parent === answerId)
                    .reduce((acc, val) => {
                        acc.push(...(val.properties?.answers ?? []))
                        return acc
                    }, [] as ISelfServiceConfigAnswer[])
                answers.forEach((a) => result.push(a, ...findAnswersRelated(a.id)))
                return result;
            }

            // if no parent selected (might be as general)
            return solutions.filter((s) => !s.parent
                || valuesSelected.includes(s.parent)
                // if diagnostic has related solution
                || diagnosticsVisible.map(({id}) => id).includes(s.parent)
                // if answer has related solution
                || findAnswersRelated(lastSelected).map(({id}) => id).includes(s.parent)
                // if question has straight solution (currently FreeText only)
                || questions.map(({id}) => id).includes(s.parent)
            );
        }
        return [];
    }, [answersSelected, diagnostics, solutions, diagnosticsVisible])

    const diagnosticCases: number[] = diagnosticsVisible
        .reduce((acc: number[], val) => {
            acc.push(...(val?.properties?.cases ?? []))
            return [...new Set(acc)]
        }, [])

    const componentsVisible = useMemo(() => {
        const diagnosticsIds = diagnosticsVisible.map(({id}) => id)
        const solutionsIds = solutionsVisible.map(({id}) => id)
        return !repairOffers.length
            ? []
            : repairOffers.filter((d) => !d.parent || diagnosticsIds.includes(d.parent) || solutionsIds.includes(d.parent));
    }, [answersSelected, repairOffers, diagnosticsVisible])

    const components: string[] = componentsVisible
        .reduce((acc: string[], val) => {
            acc.push(...(val?.properties?.components ?? []))
            return [...new Set(acc)]
        }, [])

    const getTypeIconName = useCallback((answerId: string) => {
        if (!config?.config) {
            return undefined;
        }
        const {items} = config.config;
        if (isConfigItemPresent(items, SelfServiceConfigType.Question, answerId)) {
            return 'Questionnaire';
        }
        if (isConfigItemPresent(items, SelfServiceConfigType.Diagnostic, answerId)) {
            return 'Diagnostic';
        }
        if (isConfigItemPresent(items, SelfServiceConfigType.FreeText, answerId)) {
            return 'Unknown';
        }
        return 'Lightbulb';
    }, [config]);

    const hasDiffInDiagnosticModes = useMemo(() => {
        const cases =  config?.config.diagnostics.cases
            .filter((c) => {
                return diagnosticCases.includes(c.caseId)
            })
            .map(({mode}) => Number(mode)) || []
        const hasDifference = (arr: number[]) => {
            for (let i = 1; i < arr.length; i++) {
                if (arr[i] - arr[i - 1] !== 0) {
                    return true;
                }
            }
            return false;
        };
        return hasDifference(cases)
    }, [diagnosticsVisible, config])
    const onConfigConfirmHandler = (itemId : number, options?: ISelfServiceDiagnosticCaseOptions)=>{
        onChangeDispatch(actionCreators.selfService.updateDiagnosticCaseOptions(itemId, options))
        showConfiguration()
    }
    const onOptionsClick =(itemId: number)=>{
        hideConfiguration()
        setCurrenCaseIndex(itemId);
    }
    return (
        <>
        <Stack.Item verticalFill style={{minHeight: 300}} grow>
            <Stack horizontal grow verticalFill verticalAlign="start">
                <Stack.Item verticalFill
                            style={{minWidth: 350, maxWidth: 450, backgroundColor: theme.palette.neutralLighterAlt}}>
                    <Stack verticalFill>
                        <TitleStack paddingLeft={28}>
                            <Text variant='large'
                                  className={styles.sectionTitle}>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.TROUBLESHOOTING_CATEGORIES}</Text>
                        </TitleStack>
                        <Stack.Item verticalFill style={{position: 'relative'}}>
                            <ScrollablePane>
                                {categories.map((item, index) => (
                                    <AnswersItemsList
                                        key={`${item.id}-level-list-${index}`}
                                        items={(item?.properties?.answers ?? []).map(i => ({
                                            isSelected: answersSelected.has(0) && answersSelected.get(0) === item.id,
                                            data: i,
                                            isBold: true
                                        }))}
                                        selectedKey={answersSelected.get(0) ?? ""}
                                        setSelectedKey={(key) => {
                                            updateSelected(0, key)
                                        }}
                                        onChangeDispatch={onChangeDispatch}
                                        allowControls={true}
                                        paddingLeft={28}
                                        onEdit={(answerToEdit) => {
                                            setAnswerToEdit(answerToEdit)
                                        }}
                                    />
                                ))}
                            </ScrollablePane>
                            <RenameModal isOpen={!!answerToEdit}
                                         initialValue={answerToEdit?.text}
                                         setValue={(text?: string) => {
                                             if (answerToEdit && text) {
                                                 onChangeDispatch(actionCreators.selfService.renameSelfServiceConfigItem(answerToEdit.id, text))
                                                 setAnswerToEdit(undefined)
                                             }
                                         }}
                                         hideModal={() => {
                                             setAnswerToEdit(undefined)
                                         }}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <DividerVertical/>
                <Stack.Item verticalFill grow>
                    <Stack verticalFill>
                        <TitleStack>
                            <Text variant='large'
                                  className={styles.sectionTitle}>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.TROUBLESHOOTING_QUESTIONS}</Text>
                        </TitleStack>
                        <Stack verticalFill style={{position: 'relative', minWidth: 600}} grow>
                            <ScrollablePane>
                                {answersSelected.get(0) && level1.filter(i => i.parent === answersSelected.get(0))
                                    .map((item, index) => (
                                        <AnswersItemsList
                                            title={`Level 1 - ${item.label}`}
                                            key={`${item.id}-level-list-${index}`}
                                            items={(item?.properties?.answers ?? []).map(i => ({
                                                isSelected: answersSelected.has(1) && answersSelected.get(1) === item.id,
                                                data: i,
                                                type: getTypeIconName(i.id)
                                            }))}
                                            selectedKey={answersSelected.get(1) ?? ""}
                                            setSelectedKey={(key) => {
                                                updateSelected(1, key)
                                            }}
                                            onChangeDispatch={onChangeDispatch}
                                        />
                                    ))}
                                {answersSelected.get(1) && level2.filter(i => i.parent === answersSelected.get(1))
                                    .map((item, index) => (
                                        <AnswersItemsList
                                            title={`Level 2 - ${item.label}`}
                                            key={`${item.id}-level-list-${index}`}
                                            items={(item?.properties?.answers ?? []).map(i => ({
                                                isSelected: answersSelected.has(2) && answersSelected.get(2) === item.id,
                                                data: i,
                                                type: getTypeIconName(i.id)
                                            }))}
                                            selectedKey={answersSelected.get(2) ?? ""}
                                            setSelectedKey={(key) => {
                                                updateSelected(2, key)
                                            }}
                                            onChangeDispatch={onChangeDispatch}
                                        />
                                    ))}
                            </ScrollablePane>
                        </Stack>
                    </Stack>
                </Stack.Item>
                <DividerVertical/>
                <Stack.Item verticalFill>
                    <Stack verticalFill>
                        <TitleStack isSpaceBetween>
                            <Text variant='large'
                                  className={styles.sectionTitle}>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.DIAGNOSTICS}</Text>
                            <IconButton onClick={showConfiguration}
                                        iconProps={{iconName: 'Settings'}}
                                        style={{height: 24, width: 24, padding: 0}}/>
                        </TitleStack>
                        <Stack.Item verticalFill style={{position: 'relative', minWidth: 400}}>
                            {diagnosticsVisible.length
                                ? <ScrollablePane>
                                    <CasesItemList
                                        items={diagnosticCases}
                                        setSelectedCase={(key) => {
                                            // no action planned yet
                                        }}
                                        casesMap={casesMap}
                                    />
                                    {hasDiffInDiagnosticModes && <Stack tokens={{padding: '16px 8px'}}><EmptyListPlaceholder
                                        text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.DIAGNOSTIC_SOLUTIONS_DIFFERS}/></Stack>}
                                </ScrollablePane>
                                : <Stack tokens={{padding: '16px 8px'}}><EmptyListPlaceholder
                                    text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.NO_DIAGNOSTICS_AVAILABLE_FOR_CATEGORY}/></Stack>}
                            <DiagnosticsConfiguration
                                onChangeDispatch={onChangeDispatch}
                                isOpen={isShowConfiguration}
                                hideConfiguration={hideConfiguration}
                                casesMap={casesMap}
                                cases={config?.config.diagnostics.cases}
                                onOptionsClick ={onOptionsClick}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <DividerVertical/>
                <Stack.Item verticalFill>
                    <Stack verticalFill>
                        <TitleStack>
                            <Text variant='large'
                                  className={styles.sectionTitle}>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.REPAIR_OFFERS}</Text>
                        </TitleStack>
                        <Stack.Item verticalFill style={{position: 'relative', minWidth: 400}}>
                            {componentsVisible.length
                                ? <ScrollablePane><RepairOffersList
                                    items={components}
                                    componentsMap={componentsMap}/>
                                </ScrollablePane>
                                : <Stack tokens={{padding: '16px 8px'}}><EmptyListPlaceholder
                                    text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.NO_REPAIR_OFFERS_AVAILABLE_FOR_CATEGORY}/></Stack>}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            </Stack.Item>
            <CustomPanel title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.TEST_RUN_OPTIONS_TITLE}
                isOpen={currentCaseIndex !== undefined}
                description={testDescriber(currentCaseIndex)}
                onCancel={() => setCurrenCaseIndex(undefined)}
            >
                <SelfServiceDiagnosticCaseOptionsForm onConfirmCase={onConfigConfirmHandler} caseElement={config?.config.diagnostics.cases?.find((el) => el.caseId === currentCaseIndex)} options={config?.config.diagnostics.cases?.find((el) => el.caseId === currentCaseIndex)?.options} handleCancelClick={() => {setCurrenCaseIndex(undefined); showConfiguration()}} diagnosticType={DeviceCheckSolutions.PiceaMobile} />
            </CustomPanel>
        </>
    )
}

export default SelfServiceConfiguratorPivot
