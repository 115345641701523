import {
    ChoiceGroup, classNamesFunction, IChoiceGroupOption, IChoiceGroupOptionProps,
    IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles, Image, ImageFit, Stack, Text, TooltipHost
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { TPromoProviderItemState } from './PromotionsPanel';
import { strings } from '../../../../../../../localization/strings';
import { escapeRegExp } from '../../../../../../../assets/ts/utils/regex';
import { IPromoProviderItemState, CurrencySigns } from '@piceasoft/core';
import { CurrencyCodes, IDiscountsConfig } from '@piceasoft/core';
import { getPromoPriceTypeDescription } from '../../../../../../../localization/helpers/transaction';

type TProps = {
    promotions: TPromoProviderItemState[]
    promotion?: IPromoProviderItemState
    config?: IDiscountsConfig
    selectedItem?: TPromoProviderItemState
    filteredOptions?: IChoiceGroupOption[]
    confirmHandler: () => void
    setSelectedItem: (dicount?: TPromoProviderItemState) => void
    setFilteredOptions: (options?: IChoiceGroupOption[]) => void
    searchString?: string
    setSearchString: (text?: string) => void
}

const getClassNames = classNamesFunction<IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles>();

const discountsOnRenderLabel = (props: IChoiceGroupOptionProps): JSX.Element => {
    return (
        <span id={props.labelId} className="ms-ChoiceFieldLabel">
            {props.text}
        </span>
    );
};

const getTooltipHostContent = (provider_name?: string, provider_description?: string): JSX.Element => {
    return (
        <Stack tokens={{ childrenGap: 8 }}>
            <Text>{provider_name}</Text>
            <Text>{provider_description}</Text>
        </Stack>
    )
}

const promotionsOnRenderField = (props: IChoiceGroupOption & IChoiceGroupOptionProps, item: TPromoProviderItemState): JSX.Element => {

    const LARGE_IMAGE_SIZE = 71;

    const {
        ariaLabel,
        focused,
        required,
        theme,
        iconProps,
        imageSrc,
        imageSize,
        disabled,
        checked,
        id,
        styles,
        name,
        ...rest
    } = props;

    const classNames = getClassNames(styles!, {
        theme: theme!,
        hasIcon: !!iconProps,
        hasImage: !!imageSrc,
        checked,
        disabled,
        imageIsLarge: !!imageSrc && (imageSize!.width > LARGE_IMAGE_SIZE || imageSize!.height > LARGE_IMAGE_SIZE),
        imageSize,
        focused,
    });

    const onRenderLabel = props.onRenderLabel
        ? props.onRenderLabel
        : discountsOnRenderLabel;

    const label = onRenderLabel(props);

    const isSingleCondition = item.conditions?.length === 1
    const maxConditionPrice = Math.max(...(item.conditions?.map(i => i.price) ?? [0]))
    const condition = isSingleCondition ? item.conditions?.find(i => i.price) : item.conditions?.find(i => i.price === maxConditionPrice)

    return (
        <label htmlFor={props.id} className={classNames.field}>
            <Stack verticalFill grow horizontalAlign="center" tokens={{ childrenGap: 8 }} style={{ width: '100%' }} >
                {props.imageSrc || props.iconProps ? <div className={classNames.labelWrapper}>{label}</div> : label}
                {props.imageSrc && (
                    <div className={classNames.innerField} style={{ display: 'flex', flexGrow: 'initial', flexDirection: 'column' }}>
                        <Stack.Item verticalFill>
                            <Stack>
                                <div className={classNames.imageWrapper}>
                                    <Image src={props.imageSrc} alt={props.imageAlt} {...props.imageSize} imageFit={ImageFit.contain} />
                                </div>
                                <div className={classNames.selectedImageWrapper}>
                                    <div>
                                        <Image src={props.selectedImageSrc} alt={props.imageAlt} {...props.imageSize} imageFit={ImageFit.contain} />
                                    </div>
                                </div>
                            </Stack>
                            <Stack.Item>
                                {condition && (
                                    <Text variant="large" style={{ color: SharedColors.orangeYellow20 }}>
                                        <Text variant="small" style={{ color: SharedColors.orangeYellow20 }}>
                                            {getPromoPriceTypeDescription(condition.priceType)} {!isSingleCondition ? strings.CONSTRUCTOR.STAGES.UP_TO : ""}
                                        </Text>{condition.price.toLocaleString()} <Text variant="medium" style={{ color: SharedColors.orangeYellow20 }}>{condition.currency ? CurrencySigns[condition.currency as CurrencyCodes] : undefined}</Text>
                                    </Text>
                                )}
                            </Stack.Item>
                            <Stack.Item>
                                {item.to && (
                                    <Text variant="xSmall" style={{ color: SharedColors.gray10 }}>
                                        {strings.CONSTRUCTOR.STAGES.UP_TO} {new Date(item.to).toLocaleDateString()}
                                    </Text>
                                )}
                            </Stack.Item>
                        </Stack.Item>
                    </div>
                )}
                <Stack.Item style={{ position: 'absolute', top: 0, left: 8 }}>
                    <TooltipHost content={getTooltipHostContent(item.provider_name, item.provider_description)}
                        tooltipProps={{
                            calloutProps: { gapSpace: 6 },
                        }}
                    >
                        <Stack.Item style={{ boxShadow: theme?.effects.elevation16, borderRadius: 16, overflow: 'hidden' }}>
                            <Stack.Item>
                                <Image src={item.provider_img} imageFit={ImageFit.contain} height={32} width={32} />
                            </Stack.Item>
                        </Stack.Item>
                    </TooltipHost>
                </Stack.Item>
            </Stack>
        </label>
    );
};

export const Promotions: React.FunctionComponent<TProps> = (props) => {

    let options: IChoiceGroupOption[] = props.promotions.map((item) => ({
        key: item.key,
        imageSrc: item.imgSrc,
        selectedImageSrc: item.imgSrc,
        disabled: item.notValid,
        imageSize: {
            width: 128,
            height: 128
        },
        text: item.manufacturer + " " + item.device,
        styles: {
            labelWrapper: {
                height: 40,
                margin: 0,
                padding: 0

            },
            innerField: {
                minHeight: 180,
                marginBottom: 8,
                paddingRight: 0,
                paddingLeft: 0
            },
            field: {
                width: 220,
            },
        },
        onRenderLabel: () => {
            return (
                <Stack tokens={{ childrenGap: 4 }}>
                    <Stack.Item align="center" style={{ width: 160 }}><Text variant="medium"><b>{item.manufacturer}</b></Text></Stack.Item>
                    <Stack.Item align="center" style={{ width: 160 }}><Text variant="medium" block nowrap >{item.device}</Text></Stack.Item>
                </Stack>
            )
        },
        onRenderField: (lProps: IChoiceGroupOption & IChoiceGroupOptionProps) => promotionsOnRenderField(lProps, item)
    })) as IChoiceGroupOption[]

    React.useEffect(() => {
        var newOptions: IChoiceGroupOption[] = [...options]
        if (props.searchString) {
            newOptions = [
                ...((newOptions as IChoiceGroupOption[]).filter(i => escapeRegExp(i.text.toLowerCase()).match(new RegExp(`\w*${escapeRegExp(props.searchString?.toLowerCase() ?? "")}\w*`)) != null))
            ]
        }
        props.setFilteredOptions(newOptions)
    }, [props.searchString])

    React.useEffect(() => {
        if (!props.filteredOptions) {
            props.setFilteredOptions(options)
        }
        if (!props.filteredOptions?.find(o => o.key === props.selectedItem?.id)) {
            props.setSelectedItem(undefined)
        }
    }, [props.filteredOptions])

    return (
        <Stack verticalFill grow tokens={{ childrenGap: 16 }}>
            <Stack verticalFill grow tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <ChoiceGroup options={props.filteredOptions} selectedKey={props.selectedItem?.key ?? props.promotion?.id + '-' + props.promotion?.provider_code} onChange={(ev, option) => props.setSelectedItem(props.promotions.find(i => i.key === option?.key))} />
                </Stack.Item>
            </Stack>
        </Stack>
    )
}
