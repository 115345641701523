import { getTheme, SharedColors, Stack, Sticky, Text } from "@fluentui/react"

export type TScreenHeaderProps = {
    title: string
    description?: string
}

export const ScreenHeader: React.FC<TScreenHeaderProps> = (props) => {
    return (
        <Sticky>
            <Stack horizontal grow style={{ padding: "24px 32px 24px 32px" }}>
                <Stack.Item grow>
                    <Stack tokens={{childrenGap: 16}}>
                        <Text style={{ color: theme.palette.black, fontWeight: 600, fontSize: "1.3125rem", letterSpacing: "-.02em", lineHeight: "24px" }}>{props.title}</Text>
                        {props.description && (
                            <Text variant="mediumPlus" style={{ fontWeight: 400, color: SharedColors.gray30 }}>{props.description}</Text>
                        )}
                    </Stack>
                </Stack.Item>
                {props.children && (
                    <Stack.Item>
                        {props.children}
                    </Stack.Item>
                )}
            </Stack>
        </Sticky>
    )
}

const theme = getTheme();
