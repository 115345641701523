import { DefaultButton, Dropdown, getFocusStyle, getTheme, IComboBoxOption, Icon, IDropdownOption, mergeStyleSets, PrimaryButton, Text, ScrollablePane, SharedColors, Stack, TextField, IDropdownStyles, Link } from '@fluentui/react'
import { useBoolean, useId } from '@uifabric/react-hooks'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { escapeRegExp } from '../../../assets/ts/utils/regex'
import { portalApi } from '../../../core/api/api'
import { ICompany, IPoint } from '../../../core/store'
import { IStructureNode } from '../../../core/store/typings/IStructureNode'
import { prepareTimezonesOptions } from '../../../core/helpers/timezone'
import { strings } from '../../../localization/strings'
import { onRenderLabel } from '../../renders/onRenderLabel'
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from '../search/SuggestedSearch'
import { SecondaryButton } from '../buttons/SecondaryButton'

import { useSelector, useDispatch } from 'react-redux';
import GoogleMaps from '../googleMaps/GoogleMaps';
import { IStore } from '../../../core/store';
import { actionCreators } from '../../../core/actions/googlemaps-actions';
import { GoogleMapsApi } from '../../../core/api/googlemaps.api'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { TooltipHost, DirectionalHint} from "@fluentui/react";
import { CSHHelpLink } from '../help/CSHHelp'
import { ParameterText } from '../configurator/components/ParameterText'

type TProps = {
    onSubmit: (point: IPoint) => void
    onCancel: () => void
    data?: IPoint
}

interface IPlaceID {
    place_id: string;
}
interface IAutocomplete {
    value: IPlaceID
}

interface ICoordinates {
    lat: number,
    lng: number,
    address: string
}


export const PointForm: React.FC<TProps> = (props) => {

    const googleMapsIsReady = useSelector((s: IStore) => s.googlemaps.isReady)
    const [autocomplete, setAutocompleteh] = React.useState<IAutocomplete>();
    const [coordinates, SetCoordinates] = React.useState<ICoordinates>({ lat: 0, lng: 0, address: '' });


    React.useEffect(() => {
        reset({ ...formState, lat: coordinates.lat.toString(), lng: coordinates.lng.toString(), address: coordinates.address })
    }, [coordinates])


    const dispatch = useDispatch();

    React.useEffect(() => {
        getApiKey()
    }, [])

    const getApiKey = async () => {
        const result = await GoogleMapsApi.getApiKey();
        console.log(result);
        if (!googleMapsIsReady && result && result.apiKey != "") {
            dispatch(actionCreators.requestGoogleMaps(result.apiKey))
        }
    }




    const [isContactsShow, { setTrue: showContacts, setFalse: hideContacts }] = useBoolean(false)
    const { reset, watch, control, handleSubmit, formState: { errors } } = useForm<IPoint>({ defaultValues: { ...props.data, containerId: undefined, companyId: undefined }, });
    const onSubmit: SubmitHandler<IPoint> = data => {
        props.onSubmit({ ...data, containerId: selectedContainer?.key.toString() ?? props.data?.containerId, companyId: selectedCompany?.key.toString() ?? props.data?.companyId })
    };

    const formState = watch();

    const timezones = prepareTimezonesOptions()

    const [companiesState, setCompaniesState] = React.useState<{ fetched: boolean, error?: string, data?: Array<ICompany> }>({ fetched: false })
    const [companies, setCompanies] = React.useState<IDropdownOption[]>()

    const [selectedCompany, setSelectedCompany] = React.useState<IComboBoxOption>()
    const companyHostId = useId("company-host-id")
    const companyTextId = useId("company-text-id")
    const companyCalloutListId = "company-callout-id"

    const [containersState, setContainersState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IStructureNode> }>({ fetched: false })
    const [containers, setContainers] = React.useState<IComboBoxOption[]>()

    const [selectedContainer, setSelectedContainer] = React.useState<IComboBoxOption>()
    const containerHostId = useId("container-host-id")
    const containerTextId = useId("container-text-id")
    const containerCalloutListId = "container-callout-id"

    const latId = useId("lat-id")
    const lngId = useId("lng-id")

    let default_address = '';

    if (props.data && props.data.address) {
        default_address = props.data.address;
    }


    React.useEffect(() => {
        getCompanies()
        getContainers()
    }, [])

    React.useEffect(() => {
        reset({ ...formState, containerId: selectedContainer?.text })
    }, [selectedContainer])

    React.useEffect(() => {
        reset({ ...formState, companyId: selectedCompany?.text })
    }, [selectedCompany])

    React.useEffect(() => {
        if (companiesState.fetched && companiesState.data) {
            setCompanies(getCompaniesOptions(companiesState.data))
            reset({ ...formState, companyId: companiesState.data.find(i => i.id === props.data?.companyId)?.name })
        }
    }, [companiesState])

    React.useEffect(() => {
        if (containersState.fetched && containersState.data) {
            setContainers(getContainersOptions(containersState.data))
            reset({ ...formState, containerId: containersState.data.find(i => i.id === props.data?.containerId)?.itemName })
        }
    }, [containersState])

    const getCompanies = async () => {
        setCompaniesState({ fetched: false })
        const result = await portalApi.organization.companies.getCompanies()
        setCompaniesState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(c => !c.removed) })
        const dCompany = result.data?.find(i => i.id === props.data?.companyId)
        if (dCompany?.id) {
            setSelectedCompany({ key: dCompany.id, text: dCompany.name })
        }
    }

    const getContainers = async () => {
        setContainersState({ fetched: false })
        const result = await portalApi.organization.containers.getContainers()
        setContainersState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
        const dContainer = result.data?.find(i => i.id === props.data?.containerId)
        if (dContainer?.id) {
            setSelectedContainer({ key: dContainer.id, text: dContainer.itemName })
        }
    }

    const getContainersOptions = (containerNodes: IStructureNode[]): IDropdownOption[] => {
        return containerNodes.map(cn => ({ key: cn.itemId, text: cn.itemName } as IDropdownOption))
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderCompanySuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${companyCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, companies?.length ?? 0, companyTextId, companyCalloutListId, () => companySelectHandler(item), index, item?.text, companyHostId)}
                tabIndex={-1}
                onClick={() => {
                    companySelectHandler(item)
                    focusElement(`${companyCalloutListId}-${index as number}`, companyHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    const companySelectHandler = (item?: IComboBoxOption) => {
        // if (item?.key !== selectedCompany?.key) {
        setSelectedCompany(item)
        // }
    }

    const onRenderContainerSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${containerCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, containers?.length ?? 0, containerTextId, containerCalloutListId, () => containerSelectHandler(item), index, item?.text, containerHostId)}
                tabIndex={-1}
                onClick={() => {
                    containerSelectHandler(item)
                    focusElement(`${containerCalloutListId}-${index as number}`, containerHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const containerSelectHandler = (item?: IComboBoxOption) => {
        // if (item?.key !== selectedContainer?.key) {
        setSelectedContainer(item)
        // }
    }

    const getCompaniesOptions = (items: ICompany[]): IDropdownOption[] => {
        return items.map(c => ({ key: c.id, text: c.name } as IDropdownOption))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }} >
                            <Controller
                                name="name"
                                control={control}
                                rules={{ 
                                    validate: (value) => value?.trim().length > 0  ? true : strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_REQUIRED, 
                                    required: strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_REQUIRED, minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` } }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_TOOLTIP}
                                        placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />}
                            />
                            <Controller
                                name="code"
                                control={control}
                                rules={{ 
                                    validate: (value) => value && value?.trim().length > 0 ? true : strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CODE_REQUIRED, 
                                    required: strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CODE_REQUIRED, minLength: { value: 2, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${2}` } 
                                }}
                                render={({ field, fieldState, formState }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        required
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CODE}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CODE_TOOLTIP}
                                        placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CODE_PLACEHOLDER}
                                        errorMessage={errors.code?.message}
                                    />}
                            />
                            <Controller
                                name="companyId"
                                control={control}
                                rules={{ 
                                    validate: (value) => value && value?.trim().length > 0 ? true : strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY_REQUIRED , 
                                    required: strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY_REQUIRED 
                                }}
                                render={({ field }) =>
                                    <SuggestedSearch
                                        type="dropdown-search"
                                        inputBoxId={companyTextId}
                                        hostId={companyHostId}
                                        required={true}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY_TOOLTIP}
                                        // description={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY}
                                        inputBoxPlaceholder={!companies ? strings.SPINNERS.DATA_IS_GETTING : strings.ORGANIZATION.STRUCTURE.ITEM.POINT.COMPANY_PLACEHOLDER}
                                        onClickSuggestionCell={companySelectHandler}
                                        // setSearchRequest={(opt: IComboBoxOption) => field.onChange(opt?.text)}
                                        searchRequest={field.value}
                                        suggestionsListWidth={440}
                                        disabled={!companies || companies.length === 0}
                                        suggestions={companies ?? []}
                                        suggestionsListId={companyCalloutListId}
                                        isSearchEqualTheOneSuggestion={false}
                                        onRenderSuggestionCell={onRenderCompanySuggestionsCell}
                                        prepareFilteredItems={prepareFilteredOptions}
                                        selectedKey={selectedCompany?.key.toString()}
                                        errorMessage={errors.companyId?.message}
                                    />}
                            />
                            <Controller
                                control={control}
                                name="containerId"
                                render={({ field }) =>
                                    <SuggestedSearch
                                        type="dropdown-search"
                                        inputBoxId={containerTextId}
                                        hostId={containerHostId}
                                        // required={true}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.PARENT}
                                        title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.PARENT_TOOLTIP}
                                        inputBoxPlaceholder={!containers ? strings.SPINNERS.DATA_IS_GETTING : containers.length > 0 ? (strings.ORGANIZATION.STRUCTURE.ITEM.POINT.PARENT_PLACEHOLDER) : strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.PARENT_NO_CONTAINERS}
                                        onClickSuggestionCell={containerSelectHandler}
                                        // setSearchRequest={(opt: IComboBoxOption) => field.onChange(opt?.text)}
                                        searchRequest={field.value}
                                        suggestionsListWidth={440}
                                        disabled={!containers || containers.length === 0}
                                        suggestions={containers ?? []}
                                        suggestionsListId={containerCalloutListId}
                                        isSearchEqualTheOneSuggestion={false}
                                        onRenderSuggestionCell={onRenderContainerSuggestionsCell}
                                        prepareFilteredItems={prepareFilteredOptions}
                                        selectedKey={selectedContainer?.key.toString()}
                                        errorMessage={errors.containerId?.message}
                                    />
                                }
                            />
                            <Stack horizontalAlign="end">
                                <SecondaryButton onClick={isContactsShow ? hideContacts : showContacts}>
                                    <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                        <Icon iconName={isContactsShow ? "ChevronUp" : "ChevronDown"} style={{ fontWeight: 500 }} />
                                        <Text style={{ fontWeight: 500 }}>{strings.BUTTONS.TEXT.ADVANCED}</Text>
                                    </Stack>
                                </SecondaryButton>
                            </Stack>
                            {isContactsShow && (
                                <>
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field, fieldState, formState }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CITY}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CITY}
                                                description={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CITY}
                                                placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CITY_PLACEHOLDER}
                                                errorMessage={errors.city?.message}
                                            />}
                                    />

                                    <div style={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}>
                                        <Stack
                                            verticalAlign='center' tokens={{ childrenGap: 6, padding: "0 0 4px 0" }} horizontal={true}
                                        >
                                            <Text style={{ color: "#000000", fontWeight: 500 }}>
                                                Address
                                            </Text>
                                            <TooltipHost content={'Address'}
                                                directionalHint={DirectionalHint.bottomCenter}
                                                styles={{ root: { display: "flex", cursor: "default" } }} >
                                                <Icon iconName="Info" styles={{ root: { display: "flex" } }} />
                                            </TooltipHost>
                                        </Stack>

                                        <GooglePlacesAutocomplete
                                        selectProps={{
                                            autocomplete,
                                            onChange: setAutocompleteh,
                                            defaultInputValue: default_address
                                        }}
                                    />
    
                                    </div>
                      
                                    <div style={{ display: "none" }}>
                                        <Controller
                                            name="address"
                                            control={control}
                                            render={({ field, fieldState, formState }) =>
                                                <TextField
                                                    value={coordinates.address != "" ? coordinates.address : field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDRESS}
                                                    title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDRESS}
                                                    description={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDRESS}
                                                    placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDRESS_PLACEHOLDER}
                                                    errorMessage={errors.address?.message}
                                                />}
                                        />

                                        <Controller
                                            name="lat"
                                            control={control}
                                            rules={{ 
                                                min: { value: -90, message: `Min value is: ${-90}` }, max: { value: 90, message: `Max value is: ${90}` } 
                                            }}
                                            render={({ field, fieldState, formState }) =>
                                                <TextField
                                                    type="number"
                                                    value={coordinates.lat != 0 ? coordinates.lat.toString() : field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    label={'Latitude'}
                                                    title={'Latitude'}
                                                    description={'Latitude'}
                                                    placeholder={'0.0'}
                                                    errorMessage={errors.lat?.message}
                                                />}
                                        />

                                        <Controller
                                            name="lng"
                                            control={control}
                                            rules={{ 
                                                min: { value: -180, message: `Min value is: ${-180}` }, max: { value: 180, message: `Max value is: ${180}` } }}
                                            render={({ field, fieldState, formState }) =>
                                                <TextField
                                                    type="number"
                                                    value={coordinates.lng != 0 ? coordinates.lng.toString() : field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    label={'Longitude'}
                                                    title={'Longitude'}
                                                    description={'Longitude'}
                                                    placeholder={'0.0'}
                                                    errorMessage={errors.lng?.message}
                                                />}
                                        />
                                    </div>
                                    <Controller
                                        name="timezone"
                                        control={control}
                                        render={({ field, fieldState, formState }) =>
                                            <Stack>
                                                <Dropdown
                                                    selectedKey={field.value}
                                                    onChange={(ev, opt) => field.onChange(opt?.key)}
                                                    onBlur={field.onBlur}
                                                    onRenderLabel={onRenderLabel}
                                                    label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.TIMEZONE}
                                                    title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.TIMEZONE}
                                                    placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.TIMEZONE_PLACEHOLDER}
                                                    options={timezones}
                                                    // key={timezones.find(t => t.key === props.data?.timezone)?.key}
                                                    styles={timezonesDropdownStyles}
                                                    errorMessage={errors.timezone?.message}
                                                />
                                                <Stack.Item>
                                                    <Text style={{ color: "rgb(96, 94, 92)" }} variant="xSmall">{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.TIMEZONE}</Text>
                                                </Stack.Item>
                                            </Stack>
                                        }
                                    />
                                    <Controller
                                        name="additionalInformation"
                                        control={control}
                                        render={({ field, fieldState, formState }) => 
                                            <ParameterText
                                                multiline
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDITIONAL_INFORMATION}
                                                title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDITIONAL_INFORMATION}
                                                placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDITIONAL_INFORMATION_PLACEHOLDER}
                                                errorMessage={errors.additionalInformation?.message}
                                                maxLength={500}
                                            />}
                                    />
                                    {googleMapsIsReady && (
                                        <GoogleMaps
                                            formdata={props.data}
                                            SetCoordinates={SetCoordinates}
                                            autocomplete={autocomplete}
                                            // setAutocomplete={setAutocompleteh}
                                            mapType={google.maps.MapTypeId.ROADMAP}
                                            mapTypeControl={true}>
                                        </GoogleMaps>)}

                                </>
                            )}

                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink alignment='left' articleid='1107' />
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}

const theme = getTheme();

const cancelButtonStyle: React.CSSProperties = {
    border: 'none',
    background: theme.palette.neutralLight
}

const panelFooterStyle: React.CSSProperties = {
    paddingTop: 16,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 16
}

const timezonesDropdownStyles: Partial<IDropdownStyles> = {
    dropdownItemsWrapper: { height: 300 },
};