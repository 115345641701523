import { List, SearchBox, Stack, Text, Callout, TextField, ISearchBoxStyles, IDropdownOption, Icon, IDropdownProps, IRenderFunction, ITextFieldProps, getTheme, Dropdown, IComboBoxOption, Spinner } from '@fluentui/react';
import * as React from 'react';
import s from '../../../assets/css/scrollHide.module.css';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { ReactNode } from 'react';
import { strings } from '../../../localization/strings';
import { escapeRegExp } from '../../../assets/ts/utils/regex';
import { debounce } from "lodash";

type TProps = {
    hostId?: string
    onRenderSuggestionCell: (item?: any, index?: number) => ReactNode
    onRenderSuggestionsHeader?: () => ReactNode
    onRenderPlaceholder?: () => JSX.Element
    onInputEnterDefaultAction?: () => void
    onRenderLabel?: (props: ITextFieldProps | IDropdownProps | undefined) => JSX.Element
    isSearchEqualTheOneSuggestion: boolean
    searchRequest?: string
    suggestions?: any[]
    inputBoxId: string
    inputBoxWidth?: number | string
    suggestionsListId: string
    inputBoxPlaceholder?: string
    label?: string
    title?: string
    description?: string
    type?: "search" | "text" | "dropdown-search" | "combobox-search"
    selectedKey?: string
    textDeferredValidationTime?: number
    textValidateOnFocusOut?: boolean
    textMinLength?: number
    textMaxLength?: number
    suggestionsListWidth?: number | string
    required?: boolean
    textOnBlur?: () => void
    textOnGetErrorMessage?: (value: string) => string | JSX.Element | PromiseLike<string | JSX.Element> | undefined
    notFoundBlockRender?: () => ReactNode
    setSearchRequest?: (item?: any) => void // Необходим для "search" | "text" | "combobox-search"
    onClickSuggestionCell: (item?: any) => void
    searchBoxStyles?: ISearchBoxStyles
    prepareFilteredItems?: (items: any[], filterValue?: string, selectedKey?: string) => any[]
    onRenderFilterEmptyResult?: () => ReactNode
    listWithTextfieldItem?: boolean
    setFilteredItemsLenght?: (count?: number) => void
    disabled?: boolean,
    errorMessage?: string
    isFetching?: boolean
    fetchingLabel?: string
    maxHeigth?: number | string
}

export const focusElement = (prevId: string, nextId: string) => {
    var nextEl = document.getElementById(nextId)
    var prevEl = document.getElementById(prevId)
    // console.log(prevId, " => ", nextId)
    prevEl?.setAttribute("tabIndex", "-1")
    nextEl?.focus({ preventScroll: true })
    nextEl?.scrollIntoView({ block: "center", inline: "nearest" })
    nextEl?.setAttribute("tabIndex", "0")
}

export const handleTextToListKeyPress = (ev: React.KeyboardEvent<HTMLElement>, suggestionsLenght: number, inputId: string, listId: string, onEnter?: (item?: any) => void, index?: number, item?: any, hostId?: string) => {
    // console.log("key: " + ev.key)
    // console.log("suggestionsLenght: ", +suggestionsLenght)
    // console.log("inputId: ", inputId)
    // console.log("listId: ", listId)
    // console.log("index: ", index)
    // console.log("item: ", item)
    // console.log("hostId: ", hostId)
    if (suggestionsLenght > 0) {
        switch (ev.key) {
            case 'ArrowDown':
                ev.preventDefault()
                if (index === undefined) {
                    focusElement(inputId, `${listId}-0`)
                    break
                }
                if (index < suggestionsLenght - 1) {
                    focusElement(`${listId}-${index}`, `${listId}-${index + 1}`)
                    break
                }
                focusElement(`${listId}-${index}`, inputId)
                break
            case 'ArrowUp':
                ev.preventDefault()
                if (index !== undefined && index > 0) {
                    focusElement(`${listId}-${index}`, `${listId}-${index - 1}`)
                    break
                }
                focusElement(`${listId}-${index}`, inputId)
                break
            case 'Enter':
                if (item) {
                    onEnter && onEnter(item)
                    // console.log(hostId)
                    if (hostId) {
                        if (index) {
                            focusElement(`${listId}-${index}`, hostId)
                        } else {
                            focusElement(inputId, hostId)
                        }
                    } else {
                        focusElement(`${listId}-${index}`, 'undefined')
                    }
                }
                break
            case 'Tab':
                if (hostId) {
                    if (!index) {
                        focusElement(inputId, hostId)
                        break;
                    }
                    if (index && index < suggestionsLenght) {
                        focusElement(`${listId}-${index}`, hostId)
                        break;
                    }
                    focusElement(inputId, hostId)
                } else {
                    if (!index) {
                        focusElement(inputId, inputId)
                        break;
                    }
                    if (index && index < suggestionsLenght) {
                        focusElement(`${listId}-${index}`, inputId)
                        break;
                    }
                    focusElement(inputId, inputId)
                }
                break;
            case 'Escape':
                if (hostId) {
                    if (!index) {
                        focusElement(inputId, hostId)
                        break;
                    }
                    if (index && index < suggestionsLenght) {
                        focusElement(`${listId}-${index}`, hostId)
                        break;
                    }
                    focusElement(inputId, hostId)
                } else {
                    if (!index) {
                        focusElement(inputId, inputId)
                        break;
                    }
                    if (index) {
                        focusElement(`${listId}-${index}`, inputId)
                        break;
                    }
                    focusElement(inputId, inputId)
                }
                break;
            default:
                focusElement(`${listId}-${index}`, inputId)
                break
        }
    }
}

export const preparePhoneCountryFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
    return items.filter(i =>
        escapeRegExp(`${(i.text ?? "").toLowerCase()}${(i.title ?? "")}`)
            .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
        .map((i, index) => {
            return ({ ...i, selected: i.key.toString() === selectedKey } as IComboBoxOption)
        })
}

const SuggestedSearch: React.FC<TProps> = (props) => {

    const [showCallout, { setTrue: setCalloutOn, setFalse: setCalloutOff }] = useBoolean(false)
    const inputItemClassName = useId('input-item-class-name')

    const [filterValue, setFilterValue] = React.useState<string>()
    const [filteredItems, setFilteredItems] = React.useState<IDropdownOption[]>()

    React.useEffect(() => {
        // console.log('showCallout: ', showCallout)
        if (props.type === "dropdown-search" ||
            props.listWithTextfieldItem &&
            props.type === "combobox-search") {
            if (showCallout) {
                focusElement("", props.inputBoxId)
            } else {
                setFilterValue(undefined)
            }
        }
        // console.log(document.activeElement?.id)
        // if (props.type && ["dropdown-search","combobox-search"].includes(props.type) && document.activeElement?.id.startsWith(props.suggestionsListId) && props.hostId && !showCallout) {
        //     focusElement('', props.hostId)
        // }
    }, [showCallout])

    React.useEffect(() => {
        props.setFilteredItemsLenght && props.setFilteredItemsLenght(filteredItems?.length)
    }, [filteredItems])

    React.useEffect(() => {
        if (props.type === "dropdown-search" && props.suggestions && props.prepareFilteredItems) {
            setFilteredItems(
                props.prepareFilteredItems(props.suggestions, filterValue, props.selectedKey).map(i => {
                    return ({ ...i, selected: (i.key.toString() === props.selectedKey || i.key === props.selectedKey) ?? i.text === props.searchRequest } as IComboBoxOption)
                })
            )

        }
        if (props.type === "combobox-search" && props.suggestions && props.prepareFilteredItems) {
            const filtredSuggestions: IComboBoxOption[] = props.prepareFilteredItems(props.suggestions, filterValue, props.selectedKey)
            let preparedItems = []
            if (filterValue && props.searchRequest !== filterValue && !filteredItems?.find(i => i.text.toLowerCase() === filterValue.toLowerCase())) {
                preparedItems.push({ key: filterValue, text: filterValue } as IComboBoxOption)
            }
            if (props.searchRequest && !preparedItems.find(i => i.text === props.searchRequest) && !filtredSuggestions.find(i => i.text.toLowerCase() === props.searchRequest?.toLowerCase())) {
                if ((filterValue?.length === 0) || props.searchRequest.substr(0, filterValue?.length) === (filterValue ? filterValue : props.searchRequest)) {
                    preparedItems.push({ key: props.searchRequest, text: props.searchRequest } as IComboBoxOption)
                }
            }
            preparedItems.push(...filtredSuggestions)
            preparedItems = preparedItems.map(i => {
                return ({ ...i, selected: i.key === props.selectedKey ?? i.text === props.searchRequest } as IComboBoxOption)
            })
            setFilteredItems(preparedItems as IComboBoxOption[])
        }
    }, [
        // showCallout, 
        props.suggestions,
        filterValue,
        props.searchRequest
    ])

    React.useEffect(() => {
        if (props.type && ["dropdown-search", "combobox-search"].includes(props.type)) {
            setCalloutOff()
        }
    }, [props.searchRequest])

    React.useEffect(() => {
        return () => {
            //Cancel the invocation of the debounced function when component is unmounting
            debounceSearchChangeHandler.cancel();
        }
    }, []);
    
    const handleListVisibility = () => {
        if (props.disabled) return
        // console.log('handleListVisibility')
        if (!showCallout) {
            setCalloutOn()
        }
    }

    const handleDropdownClick = () => {
        if (props.disabled) return
        //console.log('handleDropdownClick')
        if (!showCallout) {
            setCalloutOn()
        } else {
            setCalloutOff()
        }
    }

    const searchChangeHandler = (
        ev?: (React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>  | undefined),
        value?: (string | undefined)) : void => {
        props.setSearchRequest && props.setSearchRequest(value ?? "")
    }

    const debounceSearchChangeHandler = React.useMemo(
        () => debounce(searchChangeHandler, 1500),
        [props.type, props.searchRequest]
    )
    
    const onRenderDropdownPlaceholder = () => (
        <Text style={{ color: props.disabled ? theme.palette.neutralTertiary : props.searchRequest ? theme.palette.neutralDark : theme.palette.neutralSecondary }}>{props.disabled ? props.inputBoxPlaceholder : props.searchRequest ?? props.inputBoxPlaceholder}</Text>
    );

    const onRenderDropdownFilterInputPrefix: IRenderFunction<ITextFieldProps> = (orProps) => {
        return (
            <div onClick={() => focusElement("", props.inputBoxId)}>
                <Icon style={{ WebkitTouchCallout: "none", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", cursor: "default" }} iconName="Search" />
            </div>
        );
    }

    const onRenderDropdownFilterInputSuffix: IRenderFunction<ITextFieldProps> = (orProps) => {
        return (
            <div>
                {filterValue && filterValue?.length > 0 &&
                    <Icon
                        style={{ WebkitTouchCallout: "none", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", cursor: "default" }}
                        iconName="Cancel"
                        ariaLabel="Clear filter"
                        onClick={() => setFilterValue("")}
                    />
                }
            </div>
        );
    }

    return (
        <>
            {(!props.type || props.type === "search") && (
                <Stack>
                    <Stack.Item>
                        <SearchBox
                            value={props.searchRequest}
                            onSearch={() => undefined}
                            onClick={handleListVisibility}
                            onChange={debounceSearchChangeHandler}
                            onKeyDown={(ev) => {
                                if (ev.key !== 'Tab') {
                                    handleListVisibility()
                                    handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined, props.hostId)
                                }
                            }}
                            id={props.inputBoxId}
                            placeholder={props.inputBoxPlaceholder ?? strings.SHARED.SEARCH_PLACEHOLDER}
                            onFocus={setCalloutOn}
                            autoComplete="off"
                            className={inputItemClassName}
                            styles={props.searchBoxStyles}
                            disabled={props.disabled}
                        />
                    </Stack.Item>
                    {props.description && (
                        <Stack.Item>
                            <Text variant="xSmall" style={{ color: "rgb(96, 94, 92)" }}>{props.description}</Text>
                        </Stack.Item>
                    )}
                </Stack>
            )}
            {props.type === "text" && (
                <TextField
                    value={props.searchRequest}
                    onChange={ debounceSearchChangeHandler }
                    onKeyDown={(ev) => {
                        if (ev.key !== 'Tab') {
                            handleListVisibility()
                            handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined)
                        }
                    }}
                    onRenderLabel={props.onRenderLabel}
                    id={props.inputBoxId}
                    label={props.label}
                    title={props.title}
                    onBlur={props.textOnBlur}
                    description={props.description}
                    onClick={handleListVisibility}
                    placeholder={props.inputBoxPlaceholder ?? strings.SHARED.SEARCH_PLACEHOLDER}
                    required={props.required ?? false}
                    onFocus={setCalloutOn}
                    minLength={props.textMinLength}
                    maxLength={props.textMaxLength}
                    validateOnFocusOut={props.textValidateOnFocusOut ?? false}
                    deferredValidationTime={props.textDeferredValidationTime}
                    // onGetErrorMessage={props.textOnGetErrorMessage}
                    autoComplete="off"
                    className={inputItemClassName}
                    disabled={props.disabled}
                    errorMessage={props.errorMessage}
                />
            )}
            {props.type === "dropdown-search" && (
                <Stack>
                    <Stack.Item>
                        <div onClick={handleDropdownClick}>
                            <Dropdown
                                options={[]}
                                label={props.label}
                                title={props.title}
                                // description={props.description}
                                onRenderLabel={props.onRenderLabel}
                                onRenderPlaceholder={(p) =>  props.onRenderPlaceholder !== undefined ? props.onRenderPlaceholder() : onRenderDropdownPlaceholder() }
                                placeholder={props.disabled ? props.inputBoxPlaceholder : undefined}
                                className={inputItemClassName}
                                // onFocus={setCalloutOff}
                                onKeyDown={(ev) => {
                                    if (!props.disabled) {
                                        if (ev.key !== 'Tab') {
                                            handleListVisibility()
                                            handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined, props.hostId)
                                        }
                                    }
                                }}
                                id={props.hostId}
                                onClick={handleListVisibility}
                                styles={{ dropdown: { pointerEvents: "none", width: props.inputBoxWidth ?? undefined } }}
                                required={props.required ?? false}
                                errorMessage={props.errorMessage}
                            />
                        </div>
                    </Stack.Item>
                    {props.description && (
                        <Stack.Item>
                            <Text variant="xSmall" style={{ color: "rgb(96, 94, 92)" }}>{props.description}</Text>
                        </Stack.Item>
                    )}
                </Stack>
            )
            }
            {props.type === "combobox-search" && (
                props.listWithTextfieldItem && (
                    <div onClick={handleDropdownClick}>
                        <Dropdown
                            options={[]}
                            label={props.label}
                            title={props.title}
                            onRenderLabel={props.onRenderLabel}
                            onRenderPlaceholder={(p) => onRenderDropdownPlaceholder()}
                            className={inputItemClassName}
                            // onFocus={setCalloutOff}
                            onKeyDown={(ev) => {
                                if (ev.key !== 'Tab') {
                                    handleListVisibility()
                                    handleTextToListKeyPress(ev, filteredItems?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined, props.hostId)
                                }
                            }}
                            id={props.hostId}
                            onClick={handleListVisibility}
                            disabled={props.disabled}
                            styles={{ dropdown: { pointerEvents: "none", width: props.inputBoxWidth ?? undefined } }}
                            required={props.required ?? false}
                            errorMessage={props.errorMessage}
                        />
                    </div>
                ) || (
                    <TextField
                        value={props.searchRequest}
                        onChange={ debounceSearchChangeHandler }
                        onKeyDown={(ev) => {
                            handleListVisibility()
                            handleTextToListKeyPress(ev,
                                (props.suggestions && (props.suggestions?.length > 1 || showCallout) ? props.suggestions?.length : 0),
                                props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined)
                        }}
                        id={props.inputBoxId}
                        label={props.label}
                        title={props.title}
                        onClick={handleListVisibility}
                        onRenderLabel={props.onRenderLabel}
                        placeholder={props.inputBoxPlaceholder ?? strings.SHARED.SEARCH_PLACEHOLDER}
                        required={props.required ?? false}
                        // onFocus={setCalloutOn}
                        minLength={props.textMinLength}
                        maxLength={props.textMaxLength}
                        validateOnFocusOut={props.textValidateOnFocusOut ?? false}
                        deferredValidationTime={props.textDeferredValidationTime}
                        onGetErrorMessage={props.textOnGetErrorMessage}
                        autoComplete="off"
                        className={inputItemClassName}
                    />
                )
            )}
            <Callout target={`.${inputItemClassName}`}
                isBeakVisible={false}
                gapSpace={5}
                onDismiss={setCalloutOff}
                alignTargetEdge>
                {!props.isFetching && props.searchRequest && props.searchRequest.length > 0 && ((!props.type || props.type && ["search", "text"].includes(props.type)) && showCallout && props.suggestions && !props.isSearchEqualTheOneSuggestion && ((
                    props.suggestions.length === 0 && ((
                        props.notFoundBlockRender && props.notFoundBlockRender()
                    ) || (
                            <Stack style={{ width: '23em' }}>
                                <Text style={{ paddingLeft: '0.5em', padding: '0.5em' }}>{strings.SHARED.SEARCH_NO_RESULTS}: <b>"{props.searchRequest}"</b></Text>
                            </Stack>)
                    )
                ) || !props.isSearchEqualTheOneSuggestion && (
                    <>
                        {props.onRenderSuggestionsHeader && props.onRenderSuggestionsHeader()}
                        <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: props.maxHeigth ?? 300 }}
                            className={s.scrollHide}
                            id={props.suggestionsListId}
                            items={props.suggestions}
                            onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                        />
                    </>
                ))
                    // ) || (props.onRenderFilterEmptyResult && (
                    //     <div style={{ WebkitTouchCallout: "none", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", cursor: "default" }} onClick={() => focusElement("", props.inputBoxId)}>
                    //         {props.onRenderFilterEmptyResult() || (
                    //             <Stack verticalAlign="center" horizontalAlign="center" style={{ width: props.suggestionsListWidth ?? 320, height: 50 }}>
                    //                 <Text onFocusCapture={() => undefined} style={{ color: theme.palette.neutralSecondaryAlt }}>{strings.SHARED.SEARCH_FILTER_NO_RESULT}</Text>
                    //             </Stack>
                    //         )}
                    //     </div>
                    // ))
                )}
                {/* {!props.isFetching && props.searchRequest && props.searchRequest.length > 0 && (props.type === "dropdown-search" && showCallout && ( */}
                {!props.isFetching && (props.type === "dropdown-search" && showCallout && (
                    <>
                        <Stack onClick={() => focusElement("", props.inputBoxId)} grow tokens={{ padding: 6 }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                            <TextField
                                onRenderSuffix={onRenderDropdownFilterInputSuffix}
                                onRenderPrefix={onRenderDropdownFilterInputPrefix}
                                value={filterValue}
                                onChange={(e, v) => setFilterValue(v)}
                                onKeyDown={(ev) => {
                                    handleListVisibility()
                                    handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, undefined, props.hostId)
                                }}
                                autoFocus={true}
                                disabled={props.disabled}
                                id={props.inputBoxId}
                                placeholder={props.inputBoxPlaceholder}
                                autoComplete="off"
                                width={props.suggestionsListWidth ?? 320}
                                styles={{ fieldGroup: { backgroundColor: theme.palette.neutralLighter } }}
                            />
                        </Stack>
                        {filteredItems && filteredItems.length > 0 && (
                            <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300, paddingLeft: 1, paddingRight: 0.5, paddingTop: 1 }}
                                className={s.scrollHide}
                                id={props.suggestionsListId}
                                items={filteredItems}
                                onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                            />
                        ) || (
                                <div style={{ WebkitTouchCallout: "none", userSelect: "none", msUserSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", cursor: "default" }} onClick={() => focusElement("", props.inputBoxId)}>
                                    {props.onRenderFilterEmptyResult && props.onRenderFilterEmptyResult() || (
                                        <Stack verticalAlign="center" horizontalAlign="center" style={{ width: props.suggestionsListWidth ?? 320, height: 50 }}>
                                            <Text onFocusCapture={() => undefined} style={{ color: theme.palette.neutralSecondaryAlt }}>{strings.SHARED.SEARCH_FILTER_NO_RESULT}</Text>
                                        </Stack>
                                    )}
                                </div>
                            )
                        }
                    </>
                )
                )}
                {/* {!props.isFetching && props.searchRequest && props.searchRequest.length > 0 && (props.type === "combobox-search" && showCallout && ( */}
                {!props.isFetching && (props.type === "combobox-search" && showCallout && (
                    props.listWithTextfieldItem && (
                        <>
                            <Stack onClick={() => focusElement("", props.inputBoxId)} grow tokens={{ padding: 6 }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                                <TextField
                                    onRenderSuffix={onRenderDropdownFilterInputSuffix}
                                    onRenderPrefix={onRenderDropdownFilterInputPrefix}
                                    value={filterValue}
                                    onChange={(e, v) => setFilterValue(v)}
                                    onKeyDown={(ev) => {
                                        handleListVisibility()
                                        console.log(filteredItems?.find(i => i.text.toUpperCase() === filterValue?.toUpperCase())?.text)
                                        handleTextToListKeyPress(ev, props.suggestions?.length ?? 0, props.inputBoxId, props.suggestionsListId, props.onClickSuggestionCell, undefined, filteredItems?.find(i => i.text.toUpperCase() === filterValue?.toUpperCase())?.text ?? filterValue, props.hostId)
                                    }}
                                    autoFocus={true}
                                    id={props.inputBoxId}
                                    placeholder={props.inputBoxPlaceholder}
                                    autoComplete="off"
                                    // width={props.suggestionsListWidth ?? 320}
                                    styles={{ fieldGroup: { backgroundColor: theme.palette.neutralLighter } }}
                                />
                            </Stack>
                            {filteredItems && (
                                (
                                    filteredItems.length === 0 && (
                                        (
                                            props.notFoundBlockRender && props.notFoundBlockRender()
                                        ) || (
                                            props.onRenderFilterEmptyResult && props.onRenderFilterEmptyResult()
                                            || (
                                                <>
                                                </>
                                                // <Stack style={{ width: '23em' }}>
                                                //     <Text style={{ paddingLeft: '0.5em', padding: '0.5em' }}>{strings.SHARED.SEARCH_NO_RESULTS}: <b>"{props.searchRequest}"</b></Text>
                                                // </Stack>
                                            )
                                        )
                                    )
                                ) || (filteredItems.length > 2 && (
                                    <>
                                        {props.onRenderSuggestionsHeader && props.onRenderSuggestionsHeader()}
                                        <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                                            className={s.scrollHide}
                                            id={props.suggestionsListId}
                                            items={props.listWithTextfieldItem ? filteredItems : props.suggestions}
                                            onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                                        />
                                    </>
                                ) || (
                                        (
                                            <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                                                className={s.scrollHide}
                                                id={props.suggestionsListId}
                                                items={props.listWithTextfieldItem ? filteredItems : props.suggestions}
                                                onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                                            />
                                        )
                                    )
                                )
                            )
                            }
                        </>
                    ) || (
                        props.suggestions && (
                            (
                                props.suggestions.length === 0 && (
                                    (
                                        props.notFoundBlockRender && props.notFoundBlockRender()
                                    ) || (
                                        props.onRenderFilterEmptyResult && props.onRenderFilterEmptyResult()
                                        || (
                                            <>
                                            </>
                                            // <Stack style={{ width: '23em' }}>
                                            //     <Text style={{ paddingLeft: '0.5em', padding: '0.5em' }}>{strings.SHARED.SEARCH_NO_RESULTS}: <b>"{props.searchRequest}"</b></Text>
                                            // </Stack>
                                        )
                                    )
                                )
                            ) || (props.suggestions.length > 1 && (
                                <>
                                    {props.onRenderSuggestionsHeader && props.onRenderSuggestionsHeader()}
                                    <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                                        className={s.scrollHide}
                                        id={props.suggestionsListId}
                                        items={props.listWithTextfieldItem ? filteredItems : props.suggestions}
                                        onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                                    />
                                </>
                            ) || (
                                    (
                                        props.suggestions[0] as IComboBoxOption).text.substr(0, props.searchRequest?.length) !== props.searchRequest && (
                                        props.onRenderFilterEmptyResult && props.onRenderFilterEmptyResult()
                                    ) || (
                                        (props.suggestions[0] as IComboBoxOption).text !== props.searchRequest) && (
                                        <List style={{ width: props.suggestionsListWidth ?? 320, maxHeight: 300 }}
                                            className={s.scrollHide}
                                            id={props.suggestionsListId}
                                            items={props.listWithTextfieldItem ? filteredItems : props.suggestions}
                                            onRenderCell={(item, index) => props.onRenderSuggestionCell(item, index)}
                                        />
                                    )
                                )
                            )
                        )
                    )
                )
                )}
                {props.searchRequest && props.searchRequest.length > 0 && props.isFetching && (
                    <Stack style={{ width: props.suggestionsListWidth ?? 320, height: 60 }} verticalAlign="center" horizontalAlign="center" verticalFill grow>
                        <Spinner label={props.fetchingLabel ?? strings.SPINNERS.DATA_IS_LOADING} />
                    </Stack>
                )}
            </Callout>
        </>
    );
}

export default SuggestedSearch

const theme = getTheme();