import { getTheme, MessageBar, MessageBarType, Separator, Spinner, Stack, Text, Toggle } from "@fluentui/react"
import * as React from "react"
import { useSelector } from "react-redux"
import { CompanyService } from "../../../../../../components/shared/companyService/CompanyService"
import { SomethingWentWrong } from "../../../../../../components/shared/somethingWentWrong/SomethingWentWrong"
import { portalApi } from "../../../../../../core/api/api"
import { IContainer, IService, IStore } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"

type TContainerServicesPivotProps = {
    item: IContainer
    toggleUseServices: () => void
}

export const ContainerServicesPivot: React.FC<TContainerServicesPivotProps> = (props) => {

    const [servicesState, setServicesState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IService> }>({ fetched: false })
    const [toggledService, setToggledService] = React.useState<IService>()
    const workflowIconGeneration = useSelector((s: IStore) => s.workplace.organization?.workflowIconGeneration)

    React.useEffect(() => {
        props.item.id && getContainerServices(props.item.id)
    }, [])

    React.useEffect(() => {
        console.log(props.item.id)
        setServicesState({ fetched: false })
        props.item.id && getContainerServices(props.item.id)
    }, [props.item.id])

    React.useEffect(() => {
        setServicesState({
            ...servicesState, data: servicesState.data ? [...servicesState.data?.map(s => {
                if (s.id && s.id === toggledService?.id) {
                    return toggledService
                }
                return s
            })] : []
        })
    }, [toggledService])

    const getContainerServices = async (id: string) => {
        setServicesState({ fetched: false })
        const result = await portalApi.organization.services.getContainerServices(id)
        console.log(result)
        setServicesState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
    }

    const toggleContainerServiceState = async (containerId: string, service: IService) => {
        const apiResult = await portalApi.organization.services.toggleContainerService(containerId, service.id as number)
        if (apiResult.successed) {
            setToggledService({ ...service, enabled: !service.enabled })
        }
    }

    return (
        <>
        {!servicesState.error && (servicesState.fetched && servicesState.data && (
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item style={{ padding: "0 32px 0 32px" }}>
                    <Stack>
                        <Stack horizontal verticalAlign='center'>
                            <Text style={{ color: theme.palette.black, fontWeight: 600, fontSize: "1.3125rem", letterSpacing: "-.02em", lineHeight: "24px", width: 600 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.PIVOTS.SERVICES.SERVICES_PERMISSION}</Text>
                            <Toggle checked={props.item.turnOnServices} onText={strings.BUTTONS.TOGGLE_ENABLE} offText={strings.BUTTONS.TOGGLE_DISABLE} onChange={props.toggleUseServices} />
                        </Stack>
                    </Stack>
                    <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                </Stack.Item>
                {servicesState.data && servicesState.data.length > 0 && (
                    <Stack tokens={{ childrenGap: 16 }} style={{ padding: "0 32px 0 32px" }}>
                        {servicesState.data.map(s => <CompanyService
                            key={s.id}
                            enabled={s.enabled}
                            name={s.name}
                            imageSrc={s.imageSrc}
                            description={s.description}
                            onToggleServiceState={() => props.item.id && toggleContainerServiceState(props.item.id, s)}
                            isToggleDisabled={!props.item.turnOnServices}
                            generateIcon={workflowIconGeneration}
                        />)}
                    </Stack>
                ) || (
                        <Stack style={{ padding: "0 32px 0 32px" }}>
                            <MessageBar messageBarType={MessageBarType.warning}>
                                <div dangerouslySetInnerHTML={{__html: strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.PIVOTS.SERVICES.NO_SERVICES}}></div>
                            </MessageBar>
                        </Stack>
                    )}
                <Stack tokens={{ childrenGap: 8 }} style={{ padding: "0 32px 24px 32px" }}>
                    <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                    <Text style={{ color: theme.palette.neutralSecondaryAlt }} >{strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.PIVOTS.SERVICES.INFORMATION}</Text>
                </Stack>
            </Stack>
        ) || (
            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
            </Stack>
        ))}
        {servicesState.error && (
            <Stack verticalFill>
                {servicesState.error.length > 0 && (
                    <MessageBar messageBarType={MessageBarType.error}>{servicesState.error}</MessageBar>
                )}
                <Stack.Item verticalFill>
                    <SomethingWentWrong action={() => props.item.id && getContainerServices(props.item.id)} />
                </Stack.Item>
            </Stack>
        )}
        </>
    )
}

const theme = getTheme();
