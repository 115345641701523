import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";
import { ParameterItem } from "../../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess, Experience } from "../../../helpers/evisibility";
import { ProcessStages, IPreOfferConfig } from "@piceasoft/core";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: IPreOfferConfig
    channel: number
}

export const PreOfferStageAdditionalSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, channel }) => {
    if (!config) return null

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.USE_ANALOGS_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, 'Additional', 'Show device analogs')}
                toggleChecked={config.useAnalogs}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                        ...config,
                        useAnalogs: !config.useAnalogs ? true : undefined
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.USE_ANALOGS_DESCRIPTION}
            />
            {config.useAnalogs && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.USE_ANALOGS_CHOICE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, 'Additional', 'Allow analog selection')}
                    toggleChecked={config.useAnalogsChoice}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                            ...config,
                            useAnalogsChoice: !config.useAnalogsChoice ? true : undefined
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.USE_ANALOGS_CHOICE_DESCRIPTION}
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.SHOW_PRICES_BEFORE_ASSESSMENT_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, 'Additional', 'Show offer prices')}
                toggleChecked={config.showPricesBeforeAssessment || (channel === Experience.Mobile || channel === Experience.ECom)}
                toggleOnChange={() => {
                    const showPrices = !config.showPricesBeforeAssessment ? true : undefined;
                    onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                        ...config,
                        showPricesBeforeAssessment: showPrices,
                        showBestPriceOnly: showPrices
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.SHOW_PRICES_BEFORE_ASSESSMENT_DESCRIPTION}
            />
            {config.showPricesBeforeAssessment && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.SHOW_BEST_PRICE_ONLY_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, 'Additional', 'Show the best price only')}
                    toggleChecked={config.showBestPriceOnly || (channel === Experience.Mobile || channel === Experience.ECom)}
                    toggleOnChange={(channel === Experience.Mobile || channel === Experience.ECom) ? () => { return } : () => {
                        onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                            ...config,
                            showBestPriceOnly: !config.showBestPriceOnly ? true : undefined
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.ADDITIONAL_PIVOT.SHOW_BEST_PRICE_ONLY_DESCRIPTION}
                />
            )}
        </Stack>
    )
}