import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationSerialConfig } from "@piceasoft/core";
import { IIdentificationSerialConfigUI } from "@piceasoft/core";
import { IdentificationModuleUISettingsPivot } from "../IdentificationModuleUISettingsPivot";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const SerialUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationSerialConfig)

    return (
        <IdentificationModuleUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch}>
            {/* <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.SERIAL_LABEL_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={config.ui?.serial?.label !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            serial: {
                                ...config.ui?.serial,
                                label: config.ui?.serial?.label !== undefined ? undefined : ""
                            }
                        } as IIdentificationSerialConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.SERIAL_LABEL_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.serial?.label !== undefined ? true : false}
                textFieldValue={config.ui?.serial?.label}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        serial: {
                            ...config.ui?.serial,
                            label: val
                        }
                    } as IIdentificationSerialConfigUI
                }))}
            /> */}
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.SERIAL_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={config.ui?.serial?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            serial: {
                                ...config.ui?.serial,
                                placeholder: config.ui?.serial?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationSerialConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.SERIAL_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.serial?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.serial?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        serial: {
                            ...config.ui?.serial,
                            placeholder: val
                        }
                    } as IIdentificationSerialConfigUI
                }))}
            />
            {/* <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.CONFIGURATION_LABEL_TITLE}
                useToggle
                toggleEnabled={config.useConfiguration}
                toggleChecked={config.ui?.configuration?.label !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            configuration: {
                                ...config.ui?.configuration,
                                label: config.ui?.configuration?.label !== undefined ? undefined : ""
                            }
                        } as IIdentificationSerialConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.CONFIGURATION_LABEL_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.configuration?.label !== undefined ? true : false}
                textFieldValue={config.ui?.configuration?.label}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        configuration: {
                            ...config.ui?.configuration,
                            label: val
                        }
                    } as IIdentificationSerialConfigUI
                }))}
            /> */}
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.CONFIGURATION_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled={config.useConfiguration}
                toggleChecked={config.ui?.configuration?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            configuration: {
                                ...config.ui?.configuration,
                                placeholder: config.ui?.configuration?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationSerialConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.UI.CONFIGURATION_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.configuration?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.configuration?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        configuration: {
                            ...config.ui?.configuration,
                            placeholder: val
                        }
                    } as IIdentificationSerialConfigUI
                }))}
            />
        </IdentificationModuleUISettingsPivot>

    )
}