import { Checkbox, PrimaryButton, Slider, Stack, TextField } from "@fluentui/react"
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { IContractAttachmentField } from "@piceasoft/core"
import { strings } from "../../../../../localization/strings";
import { onRenderLabel } from "../../../../renders/onRenderLabel";
import { CSHHelpLink } from "../../../help/CSHHelp";
import { ParameterText } from '../../components/ParameterText'
import { SecondaryButton } from "../../../buttons/SecondaryButton";

type TProps = {
    data?: IContractAttachmentField
    keys: string[]
    onCommit: (data: IContractAttachmentField) => void
    onCloseModal: () => void

}

export const AttachmentFieldForm: React.FC<TProps> = ({ data, onCommit, keys,onCloseModal }) => {

    const { control, handleSubmit, getValues, formState: { errors } } = useForm({ defaultValues: data });

    const onSubmit = (data: IContractAttachmentField) => {
        onCommit(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Stack verticalFill tokens={{ childrenGap: 16 }}>
                <Stack verticalFill tokens={{ padding: "0px 20px", childrenGap: 16 }}>
                    <Controller
                        control={control}
                        name={"key"}
                        rules={{
                            required: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_REQUIRED,
                            validate: (value) => {
                                if(value.trim().length === 0) {
                                    return strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_REQUIRED
                                }
                                if (data && data.key === value) {
                                    return true;
                                }
                                return keys.find(k => k === value) ? 
                                strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.ATTACHMENT_VALIDATE  :
                                    true
                            }
                        }}
                        render={({ field }) =>
                            <ParameterText
                                required
                                value={field.value === undefined ? '' : field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                style={{ width: 350 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_LABEL}
                                title={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.ATTACHMENT_KEY_TITLE}
                                placeholder={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_PLACEHOLDER}
                                errorMessage={errors.key?.message}
                                maxLength={36}
                            />
                        }
                    />
                    <Controller
                        control={control}
                        name={"label"}
                        rules={{
                            required: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.VALUE_REQUIRED,
                            validate: (value) => {
                                if(value.trim().length === 0) {
                                    return strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.VALUE_REQUIRED
                                }
                                if (data && data.key === value) {
                                    return true;
                                }
                                return keys.find(k => k === value) ?
                                    strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.REQUIRED_LABEL :
                                    true
                            }
                        }}
                        render={({ field }) =>
                            <ParameterText
                                required
                                value={field.value === undefined ? '' : field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                style={{ width: 350 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_LABEL}
                                placeholder={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.ATTACHMENT_PLACEHOLDER}
                                errorMessage={errors.label?.message}
                                maxLength={36}
                            />
                        }
                    />
                    <Controller
                        control={control}
                        name={"required"}
                        render={({ field }) =>
                            <Stack.Item>
                                {onRenderLabel({
                                    title: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.ATTACHMENT_REQUIRED_TITLE,
                                    label: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.REQUIRED_LABEL
                                })}
                                <Checkbox
                                    checked={field.value}
                                    onChange={field.onChange}
                                />
                            </Stack.Item>
                        }
                    />
                    <Controller
                        control={control}
                        name={"includeToEmail"}
                        render={({ field }) =>
                            <Stack.Item>
                                {onRenderLabel({
                                    title: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.INCLUDE_ATTACHMENT_TITLE,
                                    label: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.INCLUDE_ATTACHMENT_LABEL
                                })}
                                <Checkbox
                                    checked={field.value}
                                    onChange={field.onChange}
                                />
                            </Stack.Item>
                        }
                    />
                </Stack>
                <Stack.Item tokens={{ padding: "16px 20px 24px 20px" }}>
                    <Stack horizontal grow>
                        <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                            <CSHHelpLink alignment="left" articleid="1160" />
                            <SecondaryButton onClick={() => onCloseModal()}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                            <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}