import {
    Stack, Separator, SharedColors, AnimationStyles, FontIcon,
} from "@fluentui/react"

import { useSelector } from "react-redux"
import { IStore } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"
import { BarCode } from "../../../../barCode/BarCode";
import { StagePreviewUIKit } from "../../preview/StagePreviewUIKit"
import * as UIKit from "@piceasoft/picea-react-ui-kit"
import { ProcessStages } from "@piceasoft/core";

export const ResultPreviewUIKit: React.FC = () => {

    const config = useSelector((s: IStore) => s.configurator.result)
    const panelButtons = (<>
        <UIKit.OnlineButton color="primary" fixedSize="small" onClickHandler={ () => {}}>{strings.BUTTONS.TEXT.CLOSE}</UIKit.OnlineButton>
    </>)

    if (!config) return null

    return (
        <StagePreviewUIKit
            config={config}
            stage={ProcessStages.Result}
            defaultNextText={strings.BUTTONS.TEXT.CLOSE}
            previewStateSelected={ () => {}}
            noTitle
            noTransaction
            noDevice
            noNext
            >
            {config.ui?.warning && (
                <UIKit.AlertBanner type="warning">
                    {config.ui?.warning}
                </UIKit.AlertBanner>
            )}

            <Stack styles={{ root: { ...AnimationStyles.slideUpIn10 } }} >
                <UIKit.OnlineClosableFlatPanel 
                    title={config?.ui?.header ?? strings.CONSTRUCTOR.STAGES.RESULT.HEADER_TRANSACTION_COMPLETED}
                    buttons={panelButtons}
                >
                    <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Stack.Item style={{ color: SharedColors.gray40 }}>
                                    <div dangerouslySetInnerHTML={{ __html: config?.ui?.information ?? strings.PROCESS.RESULT.INFORMATION_TRANSACTION_COMPLETED }}></div>
                                </Stack.Item>
                                {config?.contractButton && (
                                <Stack.Item>
                                    <a href="javascript:void()" title={config.contractButton.tooltip}>
                                        {config.contractButton.text ?? strings.PROCESS.RESULT.DOWNLOAD_PRINT_FORM}
                                        {config.contractButton.icon && (
                                            <FontIcon iconName={config.contractButton.icon} style={ { position: 'relative', left: '6px', top: '3px'}}/>
                                        )}
                                    </a>
                                </Stack.Item>
                                )}
                                {config?.ui?.secondaryInformation && (
                                <Stack.Item>
                                    <UIKit.Text variant="medium">{config.ui.secondaryInformation}</UIKit.Text>
                                </Stack.Item>
                                )}
                            </Stack>

                            <Separator />
                            <Stack horizontalAlign="center">
                                <Stack.Item style={{ width: 200, paddingLeft: 18, }}>
                                    <BarCode title="" label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.BARCODES_TITLE} />
                                </Stack.Item>
                            </Stack>    
                        </Stack>
                    </Stack>
                </UIKit.OnlineClosableFlatPanel>
            </Stack>
        </StagePreviewUIKit>

    )
}