import { Stack } from "@fluentui/react";
import { noop } from "../../../../../../core/helpers/common";
import { IAIGradingConfig, Inspections } from "@piceasoft/core";
import { strings } from "../../../../../../localization/strings";
import Inspection from "../Inspection";
import { AIGradingState } from "./preview/AIGradingState";

type TProps = {
    config?: IAIGradingConfig
}

export const AIGradingStatePreview: React.FC<TProps> = (props) => {
    if (props.config === undefined) return null;

    return (
        <Stack grow tokens={{ padding: "32px 32px 32px 32px" }}>
            <Inspection
                inspection={Inspections.AIGrading}
                icon={props.config.ui?.icon ?? "Robot"}
                title={props.config.ui?.title ?? strings.CONSTRUCTOR.INSPECTIONS.AIGRADING.TITLE}
                allowRestart={props.config.allowRestart}
                onStart={noop}
                onRestart={noop}
                dependency={props.config.dependency}
                skips={props.config.skip}
                autoRun={props.config.autoRun}
                executeButtonText={props.config.ui?.executeButtonText}
                repeatButtonText={props.config.ui?.restartButtonText}
            >
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    <AIGradingState config={props.config as IAIGradingConfig} />
                </Stack>
            </Inspection>
        </Stack>
    );
}