import { Stack, Text, getTheme, IDropdownOption, TextField } from "@fluentui/react"
import React from "react"
import { useSelector } from "react-redux";
import { getLocalizedCurrencyName } from "../../../core/helpers/currency";
import { CountryCodes, getLocalizedRegion, preparedLanguageOptions } from "../../../core/helpers/localization";
import { getLocalizedTimezone } from "../../../core/helpers/timezone";
import { IStore } from "../../../core/store";
import { CurrencyCodes } from "@piceasoft/core";
import { strings } from "../../../localization/strings";

export const SettingsUserLocaleTab: React.FC<{}> = () => {

    const organization = useSelector((s: IStore) => s.workplace.organization);

    return (
        <Stack style={{ width: 640 }} tokens={{ padding: 24, childrenGap: 16 }}>
            <Stack horizontal horizontalAlign="space-between">
                <Stack style={{ width: 360 }} verticalFill tokens={{ childrenGap: 16 }} >
                    <Stack tokens={{ childrenGap: 16 }} >
                        <Stack.Item>
                            <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.USER_SETTINGS.LOCALE_TITLE}</Text>
                        </Stack.Item>
                        <TextField
                            disabled
                            value={getLocalizedTimezone(organization?.timezone)}
                            label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.TIMEZONE}
                        />
                        <TextField
                            disabled
                            value={preparedLanguageOptions.find((elem) => elem.key === organization?.lang)?.text}
                            label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.LANGUAGE}
                        />
                        <TextField
                            disabled
                            value={getLocalizedRegion(organization?.region as CountryCodes)}
                            label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.REGION}
                        />
                        <TextField
                            disabled
                            value={getLocalizedCurrencyName(organization?.currency as CurrencyCodes)}
                            label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.CURRENCY}/>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();

