import { ResponsibleAreas } from "../store/typings/ResponsibleAreas";

export const endpoints = {

    v1: {
        search: (searchRequest: string) => { return "v1/logs/search/" + searchRequest },
        organizations: "v1/environment/organizations",
        changeOrganization: (id: string) => { return "v1/account/change_organization/" + id },
        requestSignIn: "v1/account/sign-in",
        requestSignOut: "v1/account/sign-out",
        requestWorkplace: "v1/workplace",
        piceaDeviceImage: (manufacturer: string, model: string): string => `https://api.piceasoft.com/deviceinfo/v1/get_image?manufacturer=${manufacturer}&model_name=${model}`,
        getEnvironmentConfiguration: (lang?: string): string => {
            let params = new URLSearchParams();
            if (lang && lang.trim().length > 0) {
                params.append('lang', lang)
            }
            return "/v1/environment/configuration?" + params.toString();
        },
        requestStructure: "v1/structure",
        exportStructure: "v1/structure/export",
        importStructure: "v1/structure/import",
        structureProtocols: (organizationId: string) => "v1/structure/protocol/" + organizationId,
        downloadPhoto: (url: string, name?: string): string => {
            let params = new URLSearchParams();
            params.append('url', url)
            if (name && name.trim().length > 0) {
                params.append('name', name)
            }
            console.log("/v1/files/download?" + params.toString());
            return "/v1/files/download?" + params.toString();
        },
        getContract: (uid: string, templateId?: number) => {
            const params = new URLSearchParams();
            params.append('uid', uid)
            if (templateId) {
                params.append("documentId", templateId.toString())
            }
            return "/v1/contract/?" + params.toString()
        },
        organization: {

            organization: (): string => {
                return "v1/account/organization";
            },
            customers: (): string => {
                return "v1/account/customers";
            },
            products: (): string => {
                return "v1/account/products";
            },
            searchCustomerId: (): string => {
                return "v1/account/searchCustomerId";
            },
            searchCustomerVat: (): string => {
                return "v1/account/searchCustomerVat";
            },
            catalogs: "v1/finance/catalog",
            catalogsByType: (catalogType: number): string => {
                return "v1/finance/catalog/getCatalogsByCatalogType/" + catalogType;
            },
            catalog: (catalogId: string): string => {
                return "v1/finance/catalog/" + catalogId;
            },
            getComponentsByCatalogId: (catalogId: string): string => {
                return "v1/finance/catalog/components/" + catalogId;
            },
            catalogVersion: "v1/finance/catalog/version",
            catalogVersions: (catalogId: string): string => {
                return "v1/finance/catalog/" + catalogId + "/version"
            },
            catalogImport: "v1/finance/catalog/import",
            catalogVersionItems: (catalogId: string, catalogVersionId: string): string => {
                return "v1/finance/catalog/" + catalogId + "/version/" + catalogVersionId;
            },
            catalogItems: (catalogId: string): string => {
                return "v1/finance/catalog/" + catalogId + "/items/";
            },
            catalogExportedVersionItems: (catalogId: string, catalogVersionId: string): string => {
                return "v1/finance/catalog/" + catalogId + "/export/" + catalogVersionId;
            },
            catalogProtocols: (catalogId: string) => "v1/finance/catalog/protocol/" + catalogId,
            catalogProtocol: "v1/finance/catalog/protocol",
            promotions: "v1/finance/promotion",
            promotion: (promotionId: string): string => {
                return "v1/finance/promotion/" + promotionId;
            },
            promotionItem: (promotionId: string, promotionItemId?: string) => {
                const result = "v1/finance/promotion/" + promotionId + "/item/" + (promotionItemId ?? "");
                console.log(result)
                return result;
            },
            companies: "v1/companies",
            company: (companyId: string): string => {
                return "v1/companies/" + companyId;
            },
            companyInformation: (companyId: string): string => {
                return "v1/companies/information/" + companyId;
            },
            companyUpdateOptions: (companyId: string): string => {
                return "v1/companies/options/" + companyId
            },
            brandings: "v1/branding",
            branding: (brandingId: number): string => {
                return "v1/branding/" + brandingId
            },
            services: (serviceId?: number): string => {
                let params = new URLSearchParams();
                if (serviceId) {
                    params.append('serviceId', serviceId.toString())
                }
                return "v1/services" + "?" + params.toString();
            },
            selfServicePromotion:"v1/selfservicepromotion",
            promotionById:(id: number): string => {
                return "v1/selfservicepromotion/" + id;
            },
            companyServices: (companyId: string): string => {
                return "v1/services/company/" + companyId;
            },
            service: (serviceId: number): string => {
                return "v1/services/" + serviceId;
            },
            serviceConfiguration: (serviceId: number): string => {
                return "v1/services/configuration/" + serviceId;
            },
            serviceConfig: (serviceId: number, isValid?: boolean): string => {
                let params = new URLSearchParams();
                if (isValid) {
                    params.append('isValid', isValid.toString())
                }
                return "v1/services/config/" + serviceId + "?" + params.toString();
            },
            serviceInformation: (serviceId: number): string => {
                return "v1/services/information/" + serviceId;
            },
            toggleCompanyService: (companyId: string, serviceId: number): string => {
                return "v1/services/company/" + companyId + "/" + serviceId;
            },
            containerServices: (containerId: string): string => {
                return "v1/services/container/" + containerId;
            },
            containerService: (containerId: string, serviceId: number): string => {
                return "v1/services/container/" + containerId + "/" + serviceId;
            },
            pointServices: (pointId: string): string => {
                return "v1/services/point/" + pointId;
            },
            pointService: (pointId: string, serviceId: number): string => {
                return "v1/services/point/" + pointId + "/" + serviceId;
            },
            documents: "v1/services/document",
            document: (documentId: number): string => {
                return "v1/services/document/" + documentId;
            },
            documentVersions: (documentId?: number): string => {
                if (!documentId) return "v1/services/document/document_version";
                return "v1/services/document/" + documentId + "/document_version";
            },
            documentVersion: (documentId: number, versionId: number): string => {
                return "v1/services/document/" + documentId + "/document_version/" + versionId;
            },
            points: (companyId?: string, pointId?: string): string => {
                let params = new URLSearchParams();
                if (companyId) {
                    params.append('companyId', companyId)
                }
                if (pointId) {
                    params.append('pointId', pointId)
                }
                return "v1/points" + "?" + params.toString();
            },
            pointToggleEnabled: (pointId: string): string => {
                return "v1/points/toggle_enabled/" + pointId
            },
            pointsToggleOptions: (pointId: string): string => {
                return "v1/points/toggle_options/" + pointId
            },
            pointsUpdateOptions: (pointId: string): string => {
                return "v1/points/options/" + pointId
            },
            responsiblePersons: (responsibleArea?: ResponsibleAreas, responsiblePersonId?: string): string => {
                let params = new URLSearchParams();
                if (responsibleArea) {
                    params.append('responsibleArea', responsibleArea.toString())
                }
                if (responsiblePersonId) {
                    params.append('responsiblePersonId', responsiblePersonId)
                }
                return "v1/responsible_persons" + "?" + params.toString();
            },
            sendCredentials: (accountId: string): string => {
                return "v1/messages/credentials/" + accountId
            },
            accounts: (organizationId?: string, accountId?: string): string => {
                let params = new URLSearchParams();
                if (organizationId) {
                    params.append('organizationId', organizationId)
                }
                if (accountId) {
                    params.append('accountId', accountId)
                }
                return "v1/account" + "?" + params.toString();
            },
            resetPassword: "v1/account/reset_password",
            accountToggleEnabled: (accountId: string): string => {
                return "v1/account/toggle_enabled/" + accountId
            },
            profile: (): string => {
                return "v1/account/profile"
            },
            profilePassword: (): string => {
                return "v1/account/profile/password"
            },
            devices: {
                devices: "v1/devices",
                manufacturers: "v1/devices/manufacturers",
                models: (manufacturer: string): string => {
                    return "v1/devices/models/" + manufacturer
                },
                configurations: (manufacturer: string, model: string): string => {
                    return "v1/devices/models/" + manufacturer + "/" + model
                }
            },
            containers: "v1/containers",
            container: (containerId: string, removeChilds?: boolean): string => {
                let params = new URLSearchParams();
                if (removeChilds) {
                    params.append('removeChilds', removeChilds ? "true" : " false")
                }
                return "v1/containers/" + containerId + "?" + params.toString();
            },
            containerInformation: (containerId: string): string => {
                return "v1/containers/information/" + containerId;
            },
            containersToggleOptions: (containerId: string): string => {
                return "v1/containers/toggle_options/" + containerId
            },
            containersUpdateOptions: (containerId: string): string => {
                return "v1/containers/options/" + containerId
            },
            supervisors: "v1/supervisors",
            supervisor: (supervisorId: string): string => {
                return "v1/supervisors/" + supervisorId;
            },
            supervisorOrganizations: (supervisorId: string): string => {
                return "v1/supervisors/" + supervisorId + "/organizations";
            },
            supervisorOrganization: (supervisorId: string, organizationId: string): string => {
                return "v1/supervisors/" + supervisorId + "/organizations/" + organizationId;
            },
            help: "v1/help",
            settings: {
                getSettings: "v1/settings",
                settingsOverview: "v1/settings/overview",
                settingsAdditional: "v1/settings/additional",
                settingsLocale: "v1/settings/locale",
                settingsLogo: (logoSrc?: string): string => {
                    const params = new URLSearchParams();
                    if (logoSrc) {
                        params.append("imgSrc", logoSrc)
                    }
                    return "v1/settings/logo" + "?" + params.toString();
                },
                organizationSecurityKey: "v1/settings/security/private_key",
                organizationSecurityPiceasoft: "v1/settings/security/piceasoft"
            }
        },
        logs: (from?: string, to?: string, search?: string): string => {
            const params = new URLSearchParams();
            if (from && to) {
                params.append("from", from)
                params.append("to", to)
            }
            if (search) {
                params.append("search", search)
            }
            return "/v1/logs" + "?" + params.toString();
        },
        exportlogs: (from: string, to: string): string => {
            return "/v1/logs/export/" + from + "/" + to;
        },
        postSticker: "http://localhost:5145/print",
        moderations: "v1/logs/moderations",
        moderation: (transactionId: string) => "v1/logs/moderations/" + transactionId,
        externalModeration: (transactionId: string, moderatorHash: string) => "v1/transaction/moderation/" + transactionId + "/" + moderatorHash,
        externalModerationRequest: (transactionId: string, moderatorHash: string) => "v1/transaction/moderation_request/" + transactionId + "/" + moderatorHash,
        files: (path?: string): string => {
            const params = new URLSearchParams();
            if (path) {
                params.append("path", path)
            }
            return "/v1/files" + "?" + params.toString();
        },
        uploadFile: (container: string, noPreview?: boolean): string => {
            const params = new URLSearchParams();
            if (noPreview) {
                params.append("noPreview", "true")
            }
            return "/v1/files/upload/" + container + "?" + params.toString();
        },
        downloadQrCodes: (qrCodeData: string, formats: number) =>{
            const params = new URLSearchParams();
            params.append("data", qrCodeData)
            params.append("formats", formats.toString())
            return `/v1/files/downloadqrcodes?${params.toString()}`
        },
        deleteFile: (container: string, fileKey: string): string => {
            return "v1/files/" + container + "/" + fileKey
        },
        apiFile: (file_id: string) => {
            return "v1/files/api_download?fileId=" + file_id
        },
        environment: {
            timezones: "/v1/environment/timezones"
        },
        overview: {
            transaction: (uid: string): string => {
                return "/v1/transaction/" + uid
            },
            transactionTechLog: (transactionId: string): string => {
                return "/v1/transaction/tech_log/" + transactionId
            },
            skipInspectionModule: "/v1/transaction/skip/notify",
            moderation: {
                start: "/v1/transaction/moderation_start/",
                request: "/v1/transaction/moderation_request/",
                result: "/v1/transaction/moderation_result/",
                notifyModerationState: "/v1/transaction/moderation/notify",
                notifyModerationStateExternal: (moderatorHash: string) => "/v1/transaction/moderation/notify/" + moderatorHash
            },
        },
        googleMaps: {
            getApiKey: "/v1/googlemaps/getApiKey"
        },
        picea: {
            validationToken: "/v1/picea/validation/tokens",
            validationEnableToken: "/v1/picea/validation/enable_tokens",
            validationApis: "/v1/picea/validation/apis",
            ai: {
                configurations: () => "/v1/picea/ai/configurations",
                components: () => "/v1/picea/ai/components",
                diagnostic: () => "/v1/picea/ai/diagnostics"
            }
        },
    },
    external: {
        applicationWidget: (url: string, serviceId: number): string => {
            const serviceHash = btoa(serviceId.toString())
            return `${url}/widget?workflow=${serviceHash}`
        }
    }
}
