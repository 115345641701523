import { 
    Callout, getFocusStyle, getTheme, 
    IconButton, IStackStyles, List, 
    Image, mergeStyleSets, MessageBar, 
    MessageBarType, PrimaryButton,
    Separator, Spinner, SpinnerSize, 
    Stack, Text, TextField 
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../core/actions/environment-actions';
import { IOrganization, OnlineLanguage, getOnlineLanguage } from "@piceasoft/core";

import { strings } from "../../localization/strings";
import { strings as coreStrings } from '@piceasoft/core'

type TProps = {
    domain?: string,
}

type TLangItem = { key: OnlineLanguage, imgSrc: string }
export const langItems: Array<TLangItem> = [
    { key: "de", imgSrc: "/images/lang/germany.png" }, 
    { key: "en", imgSrc: "/images/lang/usa.png" }, 
    { key: "es", imgSrc: "/images/lang/spain.png" }, 
    { key: "fr", imgSrc: "/images/lang/france.png" }, 
    { key: "it", imgSrc: "/images/lang/italy.png" }, 
    { key: "ru", imgSrc: "/images/lang/russia.png" },
    { key: "pt", imgSrc: "/images/lang/icons8-portugal-48.png" },
    { key: "pt_br", imgSrc: "/images/lang/icons8-brazil-48.png" },
    { key: "tr", imgSrc: "/images/lang/icons8-turkey-48.png" },
    { key: "el", imgSrc: "/images/lang/greece.png" },
    { key: "nl", imgSrc: "/images/lang/netherlands.png" },
    { key: "bg", imgSrc: "/images/lang/bulgaria.png"},
    ]

const Login: React.FC<TProps> = (props) => {
    const dispatch = useDispatch();

    const [login, setLogin] = React.useState<string>();
    const [password, setPassword] = React.useState<string>();
    const [organizationData, setOrganizationData] = React.useState<IOrganization>()
    const [message, setMessage] = React.useState<string>()
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false)
    const [showLanguagesCallout, { toggle: toggleLangCallout }] = useBoolean(false)
    const langButtonClassName = useId('lang-button-class-name')

    const onRenderLangListCell = (item: TLangItem) => {
        return (
            <Stack onClick={() => {
                toggleLangCallout()
                strings.setLanguage(item.key);
                coreStrings.setLanguage(item.key);
                dispatch(actionCreators.languageReceive(item.key))
            }} className={classNames.itemCell} horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                <Image src={item.imgSrc} height={24} width={24}></Image>
                <Text>{item.key}</Text>
            </Stack>
        )
    }

    const loginTextFieldId = useId('login-text-field-id')
    const passwordTextFieldId = useId('password-text-field-id')

    React.useEffect(() => {
        if (props.domain) {
            fetch("/v1/environment/organization?domain=" + props.domain)
                .then(response => response.json() as Promise<IOrganization>)
                .then(data => {
                    setOrganizationData(data)
                    const onlineLang = getOnlineLanguage(data.lang)
                    strings.setLanguage(onlineLang);
                    coreStrings.setLanguage(onlineLang);

                    dispatch(actionCreators.languageReceive(onlineLang))
                });
        }
    }, [])

    const submitCallback = (message: string) => {
        setMessage(message)
        setIsButtonDisabled(false)
    }

    function submitForm() {
        if (login && password) {
            setMessage(undefined)
            setIsButtonDisabled(true)
            dispatch(actionCreators.requestSignIn({
                name: login,
                password: password
            }, submitCallback))
        }
    }

    const focusElement = (prevId: string, nextId: string) => {
        var nextEl = document.getElementById(nextId)
        var prevEl = document.getElementById(prevId)
        prevEl?.setAttribute("tabIndex", "-1")
        nextEl?.focus()
        nextEl?.setAttribute("tabIndex", "0")
    }

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLElement> | KeyboardEvent, fieldId?: string) => {
        switch (ev.key) {
            case 'Enter':
                if (password && login) {
                    submitForm()
                    break
                }
                if (login && !password) {
                    focusElement(`${loginTextFieldId}`, `${passwordTextFieldId}`)
                }
                if (password && !login) {
                    focusElement(`${passwordTextFieldId}`, `${loginTextFieldId}`)
                }
        }
    }

    return (
        props.domain && !organizationData && (
            <Stack verticalFill verticalAlign="center" horizontalAlign="center" grow>
                <Spinner label={strings.SPINNERS.PLEASE_WAIT} />
            </Stack>
        ) || (
            <Stack grow horizontalAlign="center" verticalAlign="center" styles={mainStyles}>
                <Stack tokens={{ padding: 24, childrenGap: 10 }} styles={containerStyles}>
                    <Stack.Item>
                        <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                            {organizationData?.name && organizationData.logo && (
                                <img alt='logo' height={48} src={organizationData.logo} />
                            ) || (
                                <img alt='logo' height={64} src={"/logo-v.svg"} />
                            )}
                        </Stack>
                    </Stack.Item>
                    <Separator><Text variant="xSmall" style={{ fontWeight: "bold" }}>{strings.LOGIN.PORTAL_TITLE}</Text></Separator>
                    <Stack.Item>
                        <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                            <Text variant="large" style={{ color: SharedColors.cyanBlue10 }}><b>{strings.LOGIN.AUTHORIZATION}</b></Text>
                            <Text variant="small" style={{ color: SharedColors.gray40 }}>{strings.LOGIN.AUTHORIZATION_INFORMATION}</Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { marginTop: 32 } }}>
                            <Stack tokens={{ childrenGap: 8 }}>
                                <TextField id={loginTextFieldId} placeholder={strings.LOGIN.LOGIN} underlined value={login} tabIndex={1} onKeyDown={(ev) => handleKeyDown(ev, loginTextFieldId)} onChange={(_, val) => setLogin(val)} />
                                <TextField id={passwordTextFieldId} placeholder={strings.LOGIN.PASSWORD} underlined type="password" value={password} tabIndex={2} onKeyDown={(ev) => handleKeyDown(ev, passwordTextFieldId)} onChange={(_, val) => setPassword(val)} canRevealPassword></TextField>
                            </Stack>
                            <Stack.Item>
                                {message && <MessageBar messageBarType={MessageBarType.error}>{message}</MessageBar>}
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                    <Stack.Item grow>
                                        <IconButton
                                            styles={iconButtonStyles}
                                            iconProps={{ iconName: "Globe" ?? strings.getLanguage(), style: { color: theme.palette.themePrimary } }}
                                            ariaLabel="Закрыть окно"
                                            onClick={toggleLangCallout}
                                            className={langButtonClassName}
                                        >
                                            {showLanguagesCallout && (
                                                <Callout target={`.${langButtonClassName}`}
                                                    isBeakVisible={false}
                                                    gapSpace={5}
                                                    onDismiss={toggleLangCallout}>
                                                    <List
                                                        items={langItems}
                                                        onRenderCell={(item) => item && onRenderLangListCell(item)}
                                                    />
                                                </Callout>
                                            )}
                                        </IconButton>
                                    </Stack.Item>
                                    <Stack.Item>
                                        {isButtonDisabled && (
                                            <Stack verticalAlign="center" horizontalAlign="center" grow={1}>
                                                <Spinner size={SpinnerSize.small} />
                                            </Stack>
                                        )}
                                    </Stack.Item>
                                    <Stack.Item>
                                        <PrimaryButton tabIndex={3} disabled={isButtonDisabled} text={strings.LOGIN.SIGN_IN} onClick={submitForm} allowDisabledFocus />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        )
    )
}

export default Login

const theme = getTheme();
const mainStyles: IStackStyles = { root: { position: "relative", backgroundColor: theme.palette.neutralLighterAlt } };
const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation16,
        maxWidth: 420,
        width: 420
    }
};

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.neutralLight, borderColor: theme.palette.neutralLight }),
        {
            padding: 6,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: theme.palette.neutralLight },
                '&:focus': { background: theme.palette.neutralLight },
            },
        },
    ],
});
