import { Stack, Spinner, Text, getTheme, MessageBar, MessageBarType, Modal, IconButton, FontWeights, mergeStyleSets, DefaultButton, Sticky, ScrollablePane } from '@fluentui/react'
import { useBoolean } from '@uifabric/react-hooks'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CompanyForm } from '../../../components/shared/forms/CompanyForm'
import { ConfirmationForm } from '../../../components/shared/forms/ConfirmationForm'
import { CustomPanel } from '../../../components/shared/panel/CustomPanel'
import { SomethingWentWrong } from '../../../components/shared/somethingWentWrong/SomethingWentWrong'
import { portalApi } from '../../../core/api/api'
import { ICompany, IStore } from '../../../core/store'
import { strings } from '../../../localization/strings'
import { CompanyDetails } from './CompanyDetails'
import { OrganizationCompanyHeader } from './OrganizationCompanyHeader'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../../../core/actions/workflow-actions'

type TProps = {
}

export const OrganizationCompany: React.FC<TProps> = (props) => {

    const [companyState, setCompanyState] = React.useState<{ fetched: boolean, data?: ICompany }>({ fetched: false })
    const [error, setError] = React.useState<string>()
    const [confirmation, { setTrue: showConfirmation, setFalse: hideConfirmation }] = useBoolean(false)
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const { companyId } = useParams<{ companyId: string }>();
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const [infoFetching, { setTrue: startFetchInfo, setFalse: stopFetchInfo }] = useBoolean(false)
    const [infoWasSaved, setInfoWasSaved] = React.useState(false)
    const structure = useSelector((s: IStore) => s.workflow.structure);

    const history = useHistory();
    const dispatch = useDispatch();

    React.useEffect(() => {
        getCompany(companyId)
    }, [])

    const getCompany = async (id: string) => {
        setError(undefined)
        const result = await portalApi.organization.companies.getCompany(id)
        console.log(result)
        if (result.successed && result.data) {
            setCompanyState({ fetched: true, data: result.data })
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation()
            setError(result.errors[0].description)
        }
    }

    const updateCompany = async (item: ICompany) => {
        setFormError(undefined)
        startFetch()
        const result = await portalApi.organization.companies.updateCompany(item)
        if (result.successed && result.data) {
            setCompanyState({ fetched: true, data: result.data })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setError(result.errors[0].description)
        }
    }

    const deleteCompany = async (id: string) => {
        startFetch();
        setError(undefined);
        if(structure.data.filter(s => s.companyId === id).length > 0) {
            hideConfirmation();
            setError(`${strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY.ERROR_TEXT}`);
            return;
        }
        const result = await portalApi.organization.companies.deleteCompany(id)
        if (result.successed) {
            history.push("/organization/companies");
        }
        if (result.errors && result.errors.length > 0) {
            hideConfirmation();
            setError(result.errors[0].description);
            return;
        }
        if (!result.successed) {
            setError("There is a problem! Company wasn't deleted! Server response false without an error")
        }
        stopFetch()
        hideConfirmation()
    }

    const updateCompanyInformation = async (information: string) => {
        if (companyState.data?.id) {
            startFetchInfo()
            setInfoWasSaved(false)
            const result = await portalApi.organization.companies.updateCompanyInformation(companyState.data?.id, information)
            if (result.successed) {
                setCompanyState({ ...companyState, data: { ...companyState.data, information: information } })
                setInfoWasSaved(true)
            }
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
            stopFetchInfo()
        }
    }

    const onFormSubmit = (data: ICompany) => {
        companyState.data?.id && updateCompany(data)
    }

    return (
        <>
            <Stack verticalFill style={{ position: 'relative' }}>
                {!error && (companyState.fetched && companyState.data && (
                    <ScrollablePane>
                        <Stack verticalFill>
                            <OrganizationCompanyHeader onEdit={showPanel} company={companyState.data} />
                            <Stack.Item verticalFill>
                                <CompanyDetails retryInfo={() => getCompany(companyId)}
                                    data={companyState.data}
                                    onDelete={showConfirmation}
                                    setInformation={updateCompanyInformation}
                                    isFetching={infoFetching}
                                    infoWasSaved={infoWasSaved}
                                    resetInfoWasSaved={() => setInfoWasSaved(false)}
                                    updateState={(data) => setCompanyState({ ...companyState, data: data })}
                                />
                            </Stack.Item>
                        </Stack>
                    </ScrollablePane>
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    ))}
                {error && (
                    <Stack verticalFill>
                        {error.length > 0 && (
                            <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                        )}
                        <Stack.Item verticalFill>
                            <SomethingWentWrong action={() => getCompany(companyId)} />
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={hidePanel}
                title={strings.ORGANIZATION.COMPANIES.PANEL_TITLE}
            >
                <Stack verticalFill>
                    {formError && (
                        <MessageBar messageBarType={MessageBarType.error}>
                            {formError}
                        </MessageBar>
                    )}
                    {!formError && !fetching && (
                        <CompanyForm
                            onSubmit={(data: ICompany) => onFormSubmit(data)}
                            onCancel={hidePanel}
                            data={companyState.data as ICompany} />
                    ) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        )}
                </Stack>
            </CustomPanel >
            {companyState.data?.id && (
                <Modal isOpen={confirmation} onDismiss={hideConfirmation} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={hideConfirmation}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={() => companyState.data?.id && deleteCompany(companyState.data?.id)}
                            onCancel={hideConfirmation}
                            requiredMessageString={strings.ORGANIZATION.COMPANIES.COMPANY.ITN_VALID_REQUIRED_MESSAGE}
                            confirmationCheckString={companyState.data?.itn}
                            placeholder={strings.ORGANIZATION.COMPANIES.COMPANY.ITN_PLACEHOLDER}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_TEXT1} <b>"{companyState.data?.name}"</b></Text>
                                <Text>{strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_TEXT2}</Text>
                                <Text>{strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_REQUIREMENTS} <b>"{companyState.data?.itn}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>)}
        </>
    )
}


const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};