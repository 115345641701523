import * as environment from '../reducers/environment-reducer';
import * as workplace from '../reducers/workplace-reducer';
import * as teaching from '../reducers/teaching-reducer';
import * as workflow from '../reducers/workflow-reducer';
import * as configurator from '../reducers/configurator-reducer';
import * as settings from '../reducers/settings-reducer';
import * as googlemaps from '../reducers/googlemaps-reducers';
import * as validationapi from '../reducers/validationapi-reducer';
import * as help from '../reducers/help-reducer';

export const reducers = {
    environment: environment.reducer,
    workplace: workplace.reducer,
    teaching: teaching.reducer,
    workflow: workflow.reducer,
    configurator: configurator.reducer,
    settings: settings.reducer,
    googlemaps: googlemaps.reducer,
    validationapi: validationapi.reducer,
    help: help.reducer
};


export type { AllRoles } from './typings/UserRoles'
export type { ICatalog } from './typings/ICatalog'
export type { ICatalogItem } from './typings/ICatalogItem'
export type { ICatalogItemPrice } from './typings/ICatalogItemPrice'
export type { ICatalogVersion } from './typings/ICatalogVersion'
export type { ICompany } from './typings/ICompany'
export type { IContainer } from './typings/IContainer'
export type { IContractConfig } from './typings/IContractConfig'
export type { IDiagnosticsConfig } from './typings/IDiagnosticsConfig'
export type { IDocument } from './typings/IDocument'
export type { IDocumentVersion } from './typings/IDocumentVersion'
export type { IOffer } from './typings/IOffer'
export type { IService } from './typings/IService'
export type { IServiceDocument } from './typings/IServiceDocument'
export type { ITransactionTechLog } from './typings/ITransactionTechLog'
export type { IEnvironment } from './typings/IEnvironment'
export type { IModerationResult } from './typings/IModerationResult'
export type { IModerationResultLog } from './typings/IModerationResultLog'
export type { IModerationMessage } from './typings/IModerationMessage'
export type { IPhotographic } from './typings/IPhotographic'
export type { IPoint } from './typings/IPoint'
export type { ISettings } from './typings/ISettings'
export type { ISoftwareConfig } from './typings/ISoftwareConfig'
export type { ISoftwarePiceaConfig } from './typings/ISoftwarePiceaConfig'
export type { IStore } from './typings/IStore'
export type { IUser } from './typings/IUser'
export type { IPerson } from './typings/IPerson'
export type { IResponsiblePerson } from './typings/IResponsiblePerson'
export type { IWorkplace } from './typings/IWorkplace'
export type { IWorkflow } from './typings/IWorkflow'
export type { IWorkflowType } from './typings/IWorkflowType'
export { DocumentTypes } from './typings/DocumentTypes'
export { CatalogVersionStates } from './typings/CatalogVersionStates'
export { TransactionPlaceholders } from './typings/TransactionPlaceholders'
export type { IHelp } from './typings/IHelp'
