import { getTheme, IconButton, Stack, Text, Icon, Label } from "@fluentui/react"
import { Dispatch } from "react";
import { actionCreators } from "../../../../core/actions/configurator-actions";
import { ILandingConfig } from "@piceasoft/core";
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";

type TProps = {
    config: ILandingConfig
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
    noCommonSettings?: boolean
    setModuleParams: () => void
}

export const Landing: React.FC<TProps> = (props) => {

    

    return (     
               
        <StageBox title={strings.CONSTRUCTOR.INSPECTIONS.LANDING.LANDING_PAGE} settingsButtonProps={{ onClick: props.showSettings, hideButton: true }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
         
            <Stack.Item grow styles={{ root: { paddingRight: 18 } }}>
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                        borderRadius: 10
                    }
                }}>
                    <Stack onClick={() => props.setModuleParams()} styles={{
                        root: {
                            padding: 16,
                            color: theme.palette.black,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: theme.palette.neutralQuaternaryAlt,
                            backgroundColor: theme.palette.white,
                            position: "relative",
                            borderRadius: 10,
                            ":hover": {
                                cursor: "pointer",
                                borderColor: theme.palette.themePrimary,
                                backgroundColor: theme.palette.themeLighterAlt
                            }
                        }
                    }}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Icon iconName={"Globe"} styles={{ root: { color: theme.palette.black } }} />
                                <Text nowrap block style={{ maxWidth: 200 }} styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{strings.CONSTRUCTOR.INSPECTIONS.LANDING.LANDING_PAGE}</Text>
                            </Stack>
                        </Stack.Item>                                             
                    </Stack>
                    <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0 } }}>
                 
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
        </StageBox>       
    )
}



const theme = getTheme();
