import { strings } from "../strings"

export const getStructureProtocolStringByCode = (code: string):string => {
    switch (code) {
        case CatalogProtocolCodes.INVALID_FILE_TYPE : return strings.MESSAGES.PROTOCOL.COMMON.INVALID_FILE_TYPE
        case CatalogProtocolCodes.INVALID_TABLE_FORMAT : return strings.MESSAGES.PROTOCOL.COMMON.INVALID_TABLE_FORMAT
        case CatalogProtocolCodes.GOOD_DATA : return strings.MESSAGES.PROTOCOL.COMMON.GOOD_DATA
        default: return code
    }
}

export enum StructureProtocolCodes {
    INVALID_FILE_TYPE = "INVALID_FILE_TYPE",
    INVALID_TABLE_FORMAT = "INVALID_TABLE_FORMAT",
    GOOD_DATA = "GOOD_DATA"
}

export const getCatalogProtocolStringByCode = (code: string):string => {
    switch (code) {
        case CatalogProtocolCodes.INVALID_FROMDATE : return strings.MESSAGES.PROTOCOL.CATALOG.INVALID_FROMDATE
        case CatalogProtocolCodes.INVALID_FILE_TYPE : return strings.MESSAGES.PROTOCOL.COMMON.INVALID_FILE_TYPE
        case CatalogProtocolCodes.INVALID_TABLE_FORMAT : return strings.MESSAGES.PROTOCOL.COMMON.INVALID_TABLE_FORMAT
        case CatalogProtocolCodes.GOOD_DATA : return strings.MESSAGES.PROTOCOL.COMMON.GOOD_DATA
        default: return code
    }
}

export enum CatalogProtocolCodes {
    INVALID_FROMDATE = "INVALID_FROMDATE",
    INVALID_FILE_TYPE = "INVALID_FILE_TYPE",
    INVALID_TABLE_FORMAT = "INVALID_TABLE_FORMAT",
    GOOD_DATA = "GOOD_DATA"
}