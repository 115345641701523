import { ILogEntry } from "@piceasoft/core";
import { IRequestResult } from "../store/typings/IResponseResult";
import { getApiResponse, IApiResponse } from "./api";
import { endpoints } from "./endpoints";

export const logApi = {
    async getLogs(from?: string, to?: string, search?: string): Promise<IApiResponse<ILogEntry[]>> {
        return await getApiResponse(endpoints.v1.logs(from, to, search));
    },
    search: async (searchRequest: string): Promise<IRequestResult<ILogEntry[]>> => {
        let result: IRequestResult<ILogEntry[]> = { successed: false };
        await fetch(endpoints.v1.search(searchRequest))
            .then(async response => {
                await (response.json() as Promise<IRequestResult<ILogEntry[]>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    }
}