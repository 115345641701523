import { Checkbox, DefaultButton, IButtonStyles, IModalStyleProps, IModalStyles, IStyleFunctionOrObject, IconButton, Modal, Panel, PrimaryButton, Stack, Text, TextField } from '@fluentui/react'
import { useEffect, useState } from 'react'
import { IValidationToken } from '../../core/store/typings/IValidationToken'
import { IValidationApiDataApis } from '../../core/store/typings/IValidationTokenResponse'
import { IValidationApis } from '../../core/store/typings/IValidationApis'
import { IValidationTokenApis } from '../../core/store/typings/IValidationTokenApis'
import { actionCreators } from '../../core/actions/validation-actions'
import { useDispatch } from 'react-redux'
import { strings } from '../../localization/strings';
import { getTheme } from '@fluentui/style-utilities';

type TProps = {
    token: IValidationToken,
    tokenApis: IValidationTokenApis[],
    apisData: IValidationApiDataApis,
    isOpenEditModal: boolean
    closeEditModal: () => void
}

export const EditValidationToken: React.FC<TProps> = (props) => {

    const { token, tokenApis, apisData, isOpenEditModal, closeEditModal } = props;
    const [maxExpiryTime, setMaxExpiryTime] = useState<number>(0);
    const [createdTime, setCreatedTime] = useState<number>(0);
    const [creator, setCreator] = useState<string>('');
    const [tokenName, setTokenName] = useState<string>('');
    const [selectedAPIs, setSelectedAPIs] = useState<IValidationApis[]>([]);
    const dispatch =  useDispatch();

    useEffect(() => {
        tokenApis.map((api) => {
            setMaxExpiryTime(api.max_expiry_time);
            setCreatedTime(api.created);
            setCreator(api.creator);
        })

        setSelectedAPIs(tokenApis.map((api) => ({
            name: api.name,
            id: api.api_id
        })));

        setTokenName(token.name);

    }, [tokenApis])

    const formatExpireDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000); 
      
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}:${month}:${year}`;
    };
    
    const formattedDate = formatExpireDate(maxExpiryTime);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const updatedToken: IValidationToken = {
            ...token,
            name: tokenName,
            apis: selectedAPIs.map((api: IValidationApis) => {
                return {
                    ...api,
                    name: api.name,
                    api_id: api.id,
                    creator: creator,
                    max_expiry_time: maxExpiryTime,
                    params: {},
                    created: createdTime
                };
            })
          };
        await dispatch(actionCreators.updateValidationToken(updatedToken));
        closeEditModal();
    }

  return (
    <Modal 
        isOpen={isOpenEditModal} 
        styles={modalStyles}
    >
        <Stack verticalFill grow>
            <Stack style={panelHeaderStyle} grow tokens={{ childrenGap: 16, padding: 20 }}>

            <Stack style={{display: 'flex', flexDirection: 'row'}}>
                    <Text variant='xLarge'>{strings.SETTINGS.VALIDATION_API.OPERATION.EDIT_VALIDATION_TOKEN}</Text>
                    <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel', style: { fontSize: 20 } }}
                                onClick={() => closeEditModal()}
                            />
                </Stack>

                <Stack style={{margin:'10px 0 20px 0'}}>
                        <Text>{strings.SETTINGS.VALIDATION_API.TOKEN_NAME}</Text>
                        <TextField 
                            type="text"
                            maxLength={20}
                            onChange={(e: any) => setTokenName(e.target.value)}
                            value={tokenName}
                            required
                        />
                </Stack>

                <Stack grow>
                    <Stack.Item style={{display: 'flex', marginBottom: '10px'}}>
                        <Text variant='medium' style={{fontSize: '18px', marginRight: '15px'}}>{strings.SETTINGS.VALIDATION_API.SELECT_APIS}</Text>
                        <Text variant='medium' style={{fontSize: '18px'}}>{formattedDate}</Text>
                    </Stack.Item>
                    <Stack.Item style={{paddingLeft: '15px'}}>
                        { apisData.data ?
                        apisData.data.apis.map((api: IValidationApis) => {
                            const isChecked = selectedAPIs.some((selectedAPI: IValidationApis) => selectedAPI.id === api.id);
                            return (
                            <Stack key={api.id} verticalAlign="center">
                                <Stack.Item style={{margin: '7px'}}>
                                    <Checkbox
                                        label={api.name}
                                        name={api.name}
                                        id={api.id}
                                        checked={isChecked}
                                        onChange={() => {
                                            if (isChecked) {
                                            setSelectedAPIs(selectedAPIs.filter((selectedAPI: IValidationApis) => selectedAPI.id !== api.id));
                                            } else {
                                            setSelectedAPIs([...selectedAPIs, api]);
                                            }
                                        }}
                                        required
                                    />
                                </Stack.Item>
                            </Stack>
                    )}) : (
                            <Text>{strings.SETTINGS.VALIDATION_API.NOT_FOUND}</Text>
                        )}
                    </Stack.Item>
                </Stack>

                <Stack>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <DefaultButton onClick={() => closeEditModal()}>Cancel</DefaultButton>
                        <PrimaryButton onClick={(handleSubmit)} style={{marginLeft: '5px'}}>Save</PrimaryButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </Modal>
  )
}

const theme = getTheme();

const iconButtonStyles: IButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    }
};

const modalStyles: IStyleFunctionOrObject<IModalStyleProps, IModalStyles> = {
    main: {
        display: 'flex',
        borderRadius: theme.effects.roundedCorner6,
        boxShadow: theme.effects.elevation64,
        flexFlow: 'column nowrap',
        position: 'absolute',
        overflow: 'hidden',
        width: 480,
        top: 16,
        bottom: 16,
        right: 16
    },
}

const panelHeaderStyle: React.CSSProperties = {
    paddingTop: 16,
    paddingLeft: 20,
    paddingRight: 16,
    paddingBottom: 16
}