import React, { Dispatch } from "react"
import {  } from "../../../../../core/store"
import { TStageSettingsPivotStates } from "../../Configurator"
import { StageCancelReasonsPivot } from "./StageCancelReasonsPivot"
import { StageCommonButtonsSettingsPivot } from "./StageCommonButtonsSettingsPivot"
import { StageCommonUISettingsPivot } from "./StageCommonUISettingsPivot"
import { StageConfirmationSettingsPivot } from "./StageConfirmationSettingsPivot"
import { IdentificationContentTransfer } from "../IdentificationContentTransfer"
import { IdentificationStageSelectDevicesSettingsPivot } from "../identification/IdentificationStageSelectDevicesSettingsPivot"
import { IStageConfig, ProcessStages, IStageConfigUI } from "@piceasoft/core"

type TProps = {
    state: TStageSettingsPivotStates
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    additionalPivot?: React.ReactNode
    config?: IStageConfig<IStageConfigUI>
    withoutTitle?: boolean
    withBack?: boolean
    withoutInformation?: boolean
    confirmationChildren?: React.ReactNode
    commonUIChildren?: React.ReactNode
    commonButtonsChildren?: React.ReactNode
    pivots?: { name: string, component: React.ReactNode }[]
    channel: number
}

export const StageSettingsPivotBasedState: React.FC<TProps> = ({ config, ...props }) => {

    if (!config) return null

    switch (props.state) {
        case "common_buttons": return (
            <StageCommonButtonsSettingsPivot config={config} channel={ props.channel} onChangeDispatch={props.onChangeDispatch} stage={props.stage} withBack={props.withBack}>
                {props.commonButtonsChildren}
            </StageCommonButtonsSettingsPivot>
        )
        case "common_ui": return (
            <StageCommonUISettingsPivot config={config} channel={ props.channel}  onChangeDispatch={props.onChangeDispatch} stage={props.stage} withoutTitle={props.withoutTitle} withoutInformation={props.withoutInformation}>
                {props.commonUIChildren}
            </StageCommonUISettingsPivot>
        )
        case "select_device": return (
            <IdentificationStageSelectDevicesSettingsPivot
                config={config} onChangeDispatch={props.onChangeDispatch} stage={props.stage}
            />
        )
        case "confirmation": return (
            <StageConfirmationSettingsPivot config={config} channel={ props.channel} onChangeDispatch={props.onChangeDispatch} stage={props.stage}>{props.confirmationChildren}</StageConfirmationSettingsPivot>
        )
        case "cancel_reasons": return (
            <StageCancelReasonsPivot config={config} channel={ props.channel}  onChangeDispatch={props.onChangeDispatch} stage={props.stage} />
        )
        case "additional": return (
            <>{props.additionalPivot}</>
        )
        default:
            return <>{props.pivots?.find(i => i.name === props.state)?.component}</>
    }
}
