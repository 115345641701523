import { Dropdown, getFocusStyle, getTheme, IComboBoxOption, Icon, IDropdownOption, MaskedTextField, mergeStyleSets, PrimaryButton, ScrollablePane, SharedColors, Stack, Text, TextField } from '@fluentui/react'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { IResponsiblePerson } from '../../../core/store/typings/IResponsiblePerson'
import { strings } from '../../../localization/strings'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { useBoolean, useId } from '@uifabric/react-hooks'
import SuggestedSearch, { focusElement, handleTextToListKeyPress, preparePhoneCountryFilteredOptions } from '../search/SuggestedSearch'
import { CountryPhonePrefixCodes, getLocalizedPhoneMask, preparePhonePrefixOptions } from '../../../core/helpers/localization'
import { CSHHelpLink } from '../help/CSHHelp'

type TProps = {
    onSubmit: (person: IResponsiblePerson) => void
    onCancel: () => void
    data?: IResponsiblePerson
    organizationCountry?: string
}

export const ResponsiblePersonForm: React.FC<TProps> = (props) => {

    const [isContactsShow, { setTrue: showContacts, setFalse: hideContacts }] = useBoolean(false)
    const { control, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm<IResponsiblePerson>({
        defaultValues: {
            ...props.data,
            phone: {
                ...props.data?.phone,
                country: !props.data?.phone?.country ? props.organizationCountry : props.data.phone.country,

            }
        }
    });

    const onSubmit: SubmitHandler<IResponsiblePerson> = data => {
        console.log(data)

        let preparedPhoneNumber = data.phone?.number?.replace(/\D/g, '')
        if (preparedPhoneNumber && preparedPhoneNumber.length > 10) {
            preparedPhoneNumber = preparedPhoneNumber.substring(0, 10)
        }

        props.onSubmit(
            {
                ...data,
                phone: undefined,
                phoneNumber: !preparedPhoneNumber ? undefined : ((data.phone?.country ?? '') + (data.phone?.country ? CountryPhonePrefixCodes[data.phone?.country as unknown as CountryPhonePrefixCodes].toString() : "") + (preparedPhoneNumber ?? ""))
            }
        );
    }

    const formState = watch();

    const areaOptions: IDropdownOption[] = [
        { key: 0, text: strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.MODERATION.TITLE },
        { key: 1, text: strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.PRICES.TITLE }
    ]

    const phoneCountryHostId = useId("phone-country-host-id")
    const phoneCountryTextId = useId("phone-country-text-id")
    const phoneCountryCalloutListId = "phone-country-callout-id"

    const phoneCountryOptions = preparePhonePrefixOptions();

    const onRenderPhoneCountrySuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${phoneCountryCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, phoneCountryOptions?.length ?? 0, phoneCountryTextId, phoneCountryCalloutListId, () => setValue("phone.country", item?.key.toString()), index, item?.text, phoneCountryHostId)}
                tabIndex={-1}
                onClick={() => {
                    setValue("phone.country", item?.key.toString())
                    focusElement(`${phoneCountryCalloutListId}-${index as number}`, phoneCountryHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                            {item?.title && <Text>{`(+${item?.title})`}</Text>}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const onRenderPlaceholder = (value?: string) => {
        const item = phoneCountryOptions.find(i => i.key === value)
        return item && (
            <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                {item?.title && <Text>{`(+${item?.title})`}</Text>}
            </Stack>
        ) || (
                <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_PLACEHOLDER}</Text>
            )
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.NAME_REQUIRED_MESSAGE, minLength: { value: 3, message: `${strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.NAME_MIN_LENGHT_MESSAGE} ${3}` } }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.NAME}
                                        title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.NAME}
                                        placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                name="email"
                                control={control}
                                rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.EMAIL_REQUIRED_MESSAGE }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.EMAIL}
                                        title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.EMAIL}
                                        placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.EMAIL_PLACEHOLDER}
                                        errorMessage={errors.email?.message}
                                    />
                                }
                            />
                            <Controller
                                name="responsibleArea"
                                control={control}
                                rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA_REQUIRED }}
                                render={({ field, fieldState, formState }) =>
                                    <Dropdown
                                        required
                                        selectedKey={field.value}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA}
                                        title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA}
                                        placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.AREA_PLACEHOLDER}
                                        options={areaOptions}
                                        key={areaOptions.find(t => t.key === props.data?.responsibleArea)?.key}
                                        errorMessage={errors.responsibleArea?.message}
                                    />}
                            />
                            <Controller
                                name="description"
                                control={control}
                                // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.DESCRIPTION_REQUIRED_MESSAGE }}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        multiline resizable={false}
                                        label={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.DESCRIPTION}
                                        title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.DESCRIPTION}
                                        placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.DESCRIPTION_PLACEHOLDER}
                                        errorMessage={errors.description?.message}
                                    />
                                }
                            />
                            <Stack horizontalAlign="end">
                                <SecondaryButton onClick={isContactsShow ? hideContacts : showContacts}>
                                    <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                        <Icon iconName={isContactsShow ? "ChevronUp" : "ChevronDown"} style={{ fontWeight: 500 }} />
                                        <Text style={{ fontWeight: 500 }}>{strings.BUTTONS.TEXT.ADVANCED}</Text>
                                    </Stack>
                                </SecondaryButton>
                            </Stack>
                            {isContactsShow && (
                                <>
                                    <Stack>
                                        <Controller
                                            control={control}
                                            name="phone.country"
                                            // rules={{ required: errors.phone?.number ? strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_REQUIRED : undefined }}
                                            rules={{
                                                validate: (value) => {
                                                    let number = getValues().phone?.number
                                                    if (!number) return

                                                    number = number.replace(/\D/g, '');
                                                    if (number.length > 10) {
                                                        number = number.substring(0, 10)
                                                    }

                                                    if (number.length === 0) return;
                                                    if (!value || value.length === 0) return strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_REQUIRED;
                                                }
                                            }}
                                            render={({ field }) =>
                                                <SuggestedSearch
                                                    type="dropdown-search"
                                                    inputBoxId={phoneCountryTextId}
                                                    hostId={phoneCountryHostId}
                                                    onRenderLabel={onRenderLabel}
                                                    label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_LABLE}
                                                    title={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_COUNTRY_TITLE}
                                                    onClickSuggestionCell={(item) => field.onChange((item as IComboBoxOption).key)}
                                                    // setSearchRequest={(opt: IComboBoxOption) => field.onChange(opt?.text)}
                                                    searchRequest={phoneCountryOptions.find(i => i.key === field.value)?.text.toString()}
                                                    suggestionsListWidth={440}
                                                    onRenderPlaceholder={() => onRenderPlaceholder(field.value)}
                                                    suggestions={phoneCountryOptions}
                                                    suggestionsListId={phoneCountryCalloutListId}
                                                    isSearchEqualTheOneSuggestion={false}
                                                    onRenderSuggestionCell={onRenderPhoneCountrySuggestionsCell}
                                                    prepareFilteredItems={preparePhoneCountryFilteredOptions}
                                                    selectedKey={field.value}
                                                    errorMessage={errors.phone?.country?.message}
                                                />
                                            }
                                        />
                                    </Stack>
                                    <Controller
                                        name="phone.number"
                                        control={control}
                                        rules={{
                                            validate: (value) => {
                                                if (!value) return;

                                                value = value.replace(/\D/g, '');
                                                if (value.length > 10) {
                                                    value = value.substring(0, 10)
                                                }
                                                
                                                if (value.length === 10) return;
                                                if (value.length === 0) return;
                                                return strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PHONE_FILL;
                                            }
                                        }}
                                        render={({ field }) =>
                                            <MaskedTextField
                                                value={field.value ?? undefined}
                                                onChange={(ev, value) => field.onChange(value ? value.substring(0, getLocalizedPhoneMask(getValues().phone).length) : undefined)}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                mask={getLocalizedPhoneMask(getValues().phone)}
                                                label={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.PHONE_NUMBER}
                                                title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.PHONE_NUMBER}
                                                placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.PHONE_NUMBER_PLACEHOLDER}
                                                errorMessage={errors.phone?.number?.message}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="telegramUser"
                                        control={control}
                                        // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.TELEGRAM_ACCOUNT_REQUIRED_MESSAGE }}
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.TELEGRAM_ACCOUNT}
                                                title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.TELEGRAM_ACCOUNT}
                                                placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.TELEGRAM_ACCOUNT_PLACEHOLDER}
                                                errorMessage={errors.telegramUser?.message}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="telegramChannel"
                                        control={control}
                                        // rules={{ required: strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.TELEGRAM_CHANNEL_REQUIRED_MESSAGE }}
                                        render={({ field }) =>
                                            <TextField
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                onRenderLabel={onRenderLabel}
                                                label={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.TELEGRAM_CHANNEL}
                                                title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.TELEGRAM_CHANNEL}
                                                placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.TELEGRAM_CHANNEL_PLACEHOLDER}
                                                errorMessage={errors.telegramChannel?.message}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink alignment='left' articleid='1103' />
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();
