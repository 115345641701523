import { Slider } from '@fluentui/react'
import * as React from 'react'
import { IPhotographicFreeConfig } from '@piceasoft/core'
import { strings } from '../../../../../../../../localization/strings'
import { Section } from '../../../../../../decorations/Section'
import { ParameterItem } from '../../../../../components/ParameterItem'
import { getSliderLabel } from '../../../../common/helpers/fuctions'

export type TFreeModeConfiguratorModePivotProps = {
    config: IPhotographicFreeConfig
    onModeConfirm: (config: IPhotographicFreeConfig) => void
}

export const FreeModeConfiguratorModePivot: React.FC<TFreeModeConfiguratorModePivotProps> = ({ config, onModeConfirm }) => {
    return (
        <Section flat max>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FREE.COUNT_TITLE}
                noSeparator
                useToggle={false}
                description={strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FREE.COUNT_DESCRIPTION}
            >
                <Slider showValue={false} ranged lowerValue={config.minCount}
                    label={getSliderLabel(
                        config.minCount,
                        config.maxCount,
                        strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FILE_LOWER,
                        strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.FILES_LOWER,
                        strings.CONSTRUCTOR.INSPECTIONS.PHOTOGRAPHIC.NO_FILES_LIMIT
                    )}
                    value={config.maxCount} onChange={(val, range) => {
                        onModeConfirm({
                            ...config,
                            minCount: range && range[0] !== 0 && range[0] || 0,
                            maxCount: range && range[1] !== 0 && range[1] || 0
                        }
                        )
                    }} min={0} max={10} />
            </ParameterItem>
            {/* <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.INFORMATION_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={props.config.information !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        information: props.config.information !== undefined ? undefined : ""
                    }
                    )
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.INFORMATION_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={props.config.information !== undefined ? true : false}
                textFieldValue={props.config.information}
                textFieldOnChange={(val) => props.onModeConfirm({
                    ...props.config,
                    information: val
                })
                }
            /> */}
        </Section>
    )
}
