import React, {FC, Fragment, useCallback, useMemo} from "react";
import {definitions, DiagnosticCases} from "@piceasoft/core";
import {DirectionalHint, Icon, List, SharedColors, Stack, Text, TooltipHost} from "@fluentui/react";
import {DiagnosticCasesMap} from "../../../../../../core/store/typings/DiagnosticCasesMap";
import {itemPadding, paddingLeftDefault, styles} from "./styles";
import AvailablePlatforms from "./AvailablePlatforms";
import {onRenderCaseInfo} from "./onRenderCaseInfo";
import { testDescriber } from "../../../../../../core/scripts/picea.describer";

const CasesItemList: FC<{
    items: number[]
    setSelectedCase: (key: string | number) => void
    casesMap?: DiagnosticCasesMap
    title?: string
}> = ({items, setSelectedCase, casesMap}) => {
    const sets = useMemo(() =>
        definitions.diagnostic.db.filter((ds) => ds.cases.some(i => items.includes(i.id))
        ), [casesMap, items])

    const onRenderCell = useCallback((item?: { isSelected: boolean, data: DiagnosticCases }, index?: number) => {
        if (item) {
            const itemId = item?.data
            const text = testDescriber(itemId);
            const imageString = definitions.diagnostic.getImage(itemId)
            let base64icon = ''
            if (imageString) {
                base64icon = btoa(imageString)
            }
            return (
                <Stack horizontal
                       verticalAlign="center"
                       grow tokens={{childrenGap: 8}}
                       key={`${item?.data}-${index}` ?? ""}
                       onClick={() => setSelectedCase(itemId)}
                       className={styles.itemCell}>
                    <Stack.Item>
                        {imageString && (
                            <img alt='image' style={{height: 16, width: 16}}
                                 src={`data:image/svg+xml;base64,${base64icon}`}/>
                        ) || (
                            <Icon style={{fontSize: 16}} iconName='TestBeakerSolid'/>
                        )}
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack horizontal verticalAlign="center" tokens={{childrenGap: 8}}>
                            <Text block nowrap variant="medium"
                                  style={{
                                      maxWidth: 500,
                                      color: SharedColors.gray40
                                  }}>{text}</Text>
                            <TooltipHost calloutProps={{gapSpace: 10}}
                                         content={onRenderCaseInfo(definitions.diagnostic.getCase(itemId))}
                                         directionalHint={DirectionalHint.rightCenter}>
                                <Icon iconName="Info" style={{cursor: 'pointer'}}/>
                            </TooltipHost>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <AvailablePlatforms caseId={item.data}/>
                    </Stack.Item>
                </Stack>
            )
        }
        return <></>
    }, [casesMap])

    if (!casesMap) {
        return <></>
    }

    return (<>
        {sets.map(set => (
            <Fragment key={`cases-item-set-${set.id}`}>
                <Stack horizontal grow verticalAlign='center' className={styles.cellBgGrey}
                       style={{padding: itemPadding, paddingLeft: paddingLeftDefault}}>
                    <Text variant="medium" style={{fontWeight: 600}}>{set.getName()}</Text>
                </Stack>
                <List
                    items={
                        set.cases
                            .filter((c) => items.includes(c.id))
                            .map((i) => ({data: i.id, isSelected: false}))
                    }
                    onRenderCell={onRenderCell}
                    getKey={(item) => String(item?.data)}
                />
            </Fragment>
        ))}
    </>)
}

export default CasesItemList;
