import {
    DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint,
    FontWeights, getTheme, IButtonStyles, IColumn, IconButton, IContextualMenuProps,
    IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListProps,
    IDetailsRowStyles, IIconProps, IRenderFunction, mergeStyleSets, MessageBar,
    MessageBarType, Modal, PrimaryButton, ScrollablePane, SelectionMode,
    SharedColors, Spinner, Stack, Text, TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useHistory } from 'react-router';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { Section } from '../../components/shared/decorations/Section';
import { onRenderColumnHeaderCommon } from '../../components/shared/detailsList/commonRenders';
import { CompanyForm } from '../../components/shared/forms/CompanyForm';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { portalApi } from '../../core/api/api';
import { getIconSymbolsFromString, getPersonaColorsPallete } from '../../core/scripts/style';
import { ICompany } from '../../core/store';
import { strings } from '../../localization/strings';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../core/store';
import { actionCreators } from '../../core/actions/workflow-actions';
export const OrganizationCompanies: React.FC = () => {

    const [companiesState, setCompaniesState] = React.useState<{ fetched: boolean, error?: string, data?: Array<ICompany> }>({ fetched: false })

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const structure = useSelector((s: IStore) => s.workflow.structure);
    const [deleteCompanyState, setDeleteCompanyState] = React.useState<ICompany>()
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        getCompanies()
    }, [])

    React.useEffect(() => {
        dispatch(actionCreators.requestStructure())
    }, [])

    React.useEffect(() => {
        console.log(deleteCompanyState)
    }, [deleteCompanyState])

    const getCompanies = async () => {
        setCompaniesState({ fetched: false })
        const result = await portalApi.organization.companies.getCompanies()
        setCompaniesState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(c => !c.removed) })
    }

    const updateCompany = async (item: ICompany) => {
        setFormError(undefined)
        const result = await portalApi.organization.companies.updateCompany(item)
        if (result.successed && result.data) {
            const updatedData = companiesState.data?.map(i => {
                if (i.id === item.id) {
                    return item
                }
                return i
            })
            setCompaniesState({ ...companiesState, data: updatedData })
        }
        if (result.errors && result.errors.length > 0) {
            setCompaniesState({ ...companiesState, error: result.errors[0].description })
        }
    }

    const onCompanyDelete = () => {
        if (deleteCompanyState?.id) {
            deleteCompany(deleteCompanyState?.id)
            setDeleteCompanyState(undefined)
        }
    }

    const deleteCompany = async (id: string) => {
        if(structure.data.filter(s => s.companyId === id).length > 0) {
            setCompaniesState({...companiesState, error: `${strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY.ERROR_TEXT}`})
            return;
        }
        setCompaniesState({ ...companiesState, error: undefined })
        const result = await portalApi.organization.companies.deleteCompany(id)
        if (result.successed) {
            if (companiesState.data) {
                setCompaniesState({
                    ...companiesState,
                    data: companiesState.data.filter(c => c.id !== id)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setCompaniesState({ ...companiesState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setCompaniesState({ ...companiesState, error: "There is a problem! Company wasn't deleted! Server response false without an error" });
        }
    }

    const companyIsActivatedToggle = (id: string) => {
        const item = companiesState.data?.find(i => i.id === id)
        if (item) {
            updateCompany({ ...item, enabled: !item?.enabled })
        }
    }

    const onAddCompany = () => {
        setFormError(undefined)
        stopFetch()
        showPanel()
    }

    const addCompany = async (item: ICompany) => {
        startFetch()
        const result = await portalApi.organization.companies.addCompany(item)
        if (result.successed && result.data) {
            console.log(result)
            setCompaniesState({ ...companiesState, data: companiesState.data ? [...companiesState.data, result.data] : [result.data] })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onCancel = () => {
        hidePanel()
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.COMPANIES.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ICompany) => onRenderNameCompanyCell(item),
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.COMPANIES.TABLE_COLUMNS.ITN,
            fieldName: 'itn',
            minWidth: 150,
            maxWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ICompany) => {
                return <Stack verticalFill verticalAlign="center"><Text style={{ color: theme.palette.black }}>{item.itn}</Text></Stack>;
            },
            isPadded: true,
        },
        // {
        //     key: 'column3',
        //     name: strings.ORGANIZATION.COMPANIES.TABLE_COLUMNS.MANAGER,
        //     fieldName: 'manager',
        //     minWidth: 300,
        //     maxWidth: 500,
        //     isResizable: false,
        //     data: 'string',
        //     onRender: (item: ICompany) => {
        //         return <Stack verticalFill verticalAlign="center"><Text style={{ color: theme.palette.black }}>{item.manager}</Text></Stack>;
        //     },
        //     isPadded: true,
        // },
        {
            key: 'column4',
            name: '',
            fieldName: 'enabled',
            minWidth: 70,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ICompany) => (
                <Stack horizontal verticalFill verticalAlign='center'>
                    <Stack.Item>
                        <TooltipHost directionalHint={DirectionalHint.leftBottomEdge} key={item.id} content={item.enabled ? strings.ORGANIZATION.COMPANIES.COMPANY.DISABLE_TOOLTIP : strings.ORGANIZATION.COMPANIES.COMPANY.ENABLE_TOOLTIP}>
                            <IconButton iconProps={item.enabled ? activeEnabledIconProps : enabledIconProps} onClick={(e) => {
                                e.stopPropagation();
                                item.id && companyIsActivatedToggle(item.id)
                            }} />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        {!item.removed && <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item, setDeleteCompanyState)} />}
                    </Stack.Item>
                </Stack>
            )
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => history.push(`/organization/companies/${props.item['id']}`)}><DetailsRow {...props} styles={customStyles} /></Stack>;
        }
        return null;
    };

    return companiesState.error && (
        <Stack verticalFill>
            {companiesState.error.length > 0 && (
                <MessageBar messageBarType={MessageBarType.error}>{companiesState.error}</MessageBar>
            )}
            <Stack.Item verticalFill>
                <SomethingWentWrong action={getCompanies} />
            </Stack.Item>
        </Stack>
    ) || (
            !companiesState.fetched && (
                <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                    <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                </Stack>
            ) || (
                <>
                    {companiesState.data && companiesState.data.length > 0 && (
                        <Stack verticalFill>
                            <Stack.Item>
                                <ScreenHeader title={strings.NAVIGATION.ORGANIZATION.COMPANIES}>
                                    <PrimaryButton onClick={onAddCompany} text={strings.ORGANIZATION.COMPANIES.BUTTONS.TEXT.ADD_COMPANY} />
                                </ScreenHeader>
                            </Stack.Item>
                            <Stack.Item verticalFill>
                                <Stack verticalFill>
                                    <Stack verticalFill style={{ position: 'relative' }}>
                                        <ScrollablePane>
                                            <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                                <Section max>
                                                    <DetailsList
                                                        styles={{ root: { width: '100%' } }}
                                                        items={companiesState.data}
                                                        columns={columns}
                                                        setKey="none"
                                                        selectionMode={SelectionMode.none}
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        onRenderDetailsHeader={onRenderDetailsHeader}
                                                        isHeaderVisible={true}
                                                        onRenderRow={onRenderRow}
                                                    />
                                                </Section>
                                            </Stack.Item>
                                        </ScrollablePane>
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                        </Stack>) || (
                            <ItemsNotFound
                                info={strings.ORGANIZATION.COMPANIES.NOT_FOUND_CASE.INFO}
                                suggestion={strings.ORGANIZATION.COMPANIES.NOT_FOUND_CASE.SUGGESTION}
                                buttonText={strings.ORGANIZATION.COMPANIES.ADD_COMPANY}
                                onClick={onAddCompany}
                                imgSrc="images/navigation/images/organization.png"
                            />
                        )}
                    <CustomPanel
                        isOpen={isPanelOpen}
                        onCancel={onCancel}
                        title={strings.ORGANIZATION.COMPANIES.ADD_COMPANY}
                    >
                        <Stack verticalFill>
                            {formError && (
                                <MessageBar messageBarType={MessageBarType.error}>
                                    {formError}
                                </MessageBar>
                            )}
                            {(isPanelOpen && !fetching) && (
                                <CompanyForm
                                    onSubmit={(data: ICompany) => addCompany(data)}
                                    onCancel={onCancel}
                                />
                            ) || (
                                    <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                        <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                                    </Stack>
                                )}
                        </Stack>
                    </CustomPanel>
                    {deleteCompanyState && (
                        <Modal isOpen={deleteCompanyState ? true : false} onDismiss={() => setDeleteCompanyState(undefined)} containerClassName={contentStyles.container}>
                            <div className={contentStyles.header}>
                                <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_TITLE}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close"
                                    onClick={() => setDeleteCompanyState(undefined)}
                                />
                            </div>
                            <div className={contentStyles.body}>
                                <ConfirmationForm
                                    onConfirm={onCompanyDelete}
                                    onCancel={() => setDeleteCompanyState(undefined)}
                                    requiredMessageString={strings.ORGANIZATION.COMPANIES.COMPANY.ITN_VALID_REQUIRED_MESSAGE}
                                    confirmationCheckString={deleteCompanyState?.itn}
                                    placeholder={strings.ORGANIZATION.COMPANIES.COMPANY.ITN_PLACEHOLDER}
                                >
                                    <Stack tokens={{ childrenGap: 12 }}>
                                        <Text>{strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_TEXT1} <b>"{deleteCompanyState.name}"</b>.</Text>
                                        <Text>{strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_TEXT2}</Text>
                                        <Text>{strings.ORGANIZATION.COMPANIES.REMOVE_COMPANY_CONFIRMATION_REQUIREMENTS} <b>"{deleteCompanyState.itn}"</b></Text>
                                    </Stack>
                                </ConfirmationForm>
                            </div>
                        </Modal>
                    )}
                </>
            )
        )
}

const getItemMenuProps = (item: ICompany, onDelete: (item: ICompany) => void): IContextualMenuProps => {
    return {
        items: [
            {
                key: `delete-${item.id}`,
                iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => item.id ? onDelete(item) : undefined,
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
            }
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    }
}

const onRenderNameCompanyCell = (item: ICompany) => {
    return (
        <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
            <Stack.Item>
                <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: getPersonaColorsPallete[item.name[0].toLowerCase()] }}>
                    <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                        {getIconSymbolsFromString(item.name)}
                    </Text>
                </Stack>
            </Stack.Item>
            <Stack.Item grow>
                <Stack>
                    <Stack.Item>
                        <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="small" style={{ color: SharedColors.gray30 }}>{item.address}</Text>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );

    return (
        // <Sticky stickyClassName={classNames.header} stickyPosition={StickyPositionType.Header} isScrollSynced>
        defaultRender!({
            ...props,
            onRenderColumnHeaderTooltip,
        })
        // </Sticky>
    );
};

const theme = getTheme();

const activeEnabledIconProps: IIconProps = { iconName: 'FavoriteStarFill', styles: { root: { color: SharedColors.yellow10 } }, className: 'enabled-icon' }
const enabledIconProps: IIconProps = { iconName: 'FavoriteStar', styles: { root: { color: 'transparent' } }, className: 'disabled-icon' }
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};