import { Stack, Separator, Text, getTheme, SharedColors, Spinner, MessageBar, MessageBarType, Link, Sticky, mergeStyleSets } from "@fluentui/react"
import React from "react"
import { useSelector } from "react-redux"
import { CompanyService } from "../../../../../components/shared/companyService/CompanyService"
import { SomethingWentWrong } from "../../../../../components/shared/somethingWentWrong/SomethingWentWrong"
import { portalApi } from "../../../../../core/api/api"
import { IService, IStore } from "../../../../../core/store"
import { strings } from "../../../../../localization/strings"
import { CSHHelpLink } from "../../../../../components/shared/help/CSHHelp"

export type TCompanyServicesPivotProps = {
    id: string
}

export const CompanyServicesPivot: React.FC<TCompanyServicesPivotProps> = (props) => {

    const [servicesState, setServicesState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IService> }>({ fetched: false })
    const [toggledService, setToggledService] = React.useState<IService>()
    const workflowIconGeneration = useSelector((s:IStore) => s.workplace.organization?.workflowIconGeneration)

    React.useEffect(() => {
        getServices()
    }, [])

    React.useEffect(() => {
        setServicesState({
            ...servicesState, data: servicesState.data ? [...servicesState.data?.map(s => {
                if (s.id && s.id === toggledService?.id) {
                    return toggledService
                }
                return s
            })] : []
        })
    }, [toggledService])

    const getServices = async () => {
        setServicesState({ fetched: false })
        const result = await portalApi.organization.services.getCompanyServices(props.id)
        setServicesState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(i => !i.removed) })
    }

    const toggleServiceState = async (companyID: string, service: IService) => {
        const apiResult = await portalApi.organization.services.toggleCompanyService(companyID, service.id as number)
        if (apiResult.successed) {
            setToggledService({ ...service, enabled: !service.enabled })
        }
    }

    return (
        <>
            {!servicesState.error && (servicesState.fetched && servicesState.data && (
                <Stack tokens={{ childrenGap: 16, padding: "0px 32px" }}>
                    <Sticky stickyClassName={classNames.sticky}>
                        <Stack tokens={{ padding: '24px 0px 0px 0px', childrenGap: 16 }}>
                            <Text style={{ color: theme.palette.black, fontWeight: 600, fontSize: "1.3125rem", letterSpacing: "-.02em", lineHeight: "24px" }}>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.SERVICES.SERVICES_PERMISSION}</Text>
                            <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                        </Stack>
                    </Sticky>
                    <Stack tokens={{ childrenGap: 16 }}>
                        {servicesState.data && servicesState.data.length > 0 && (
                            <>
                                {servicesState.data.map(s => <CompanyService key={s.id} enabled={s.enabled} name={s.name} imageSrc={s.imageSrc} description={s.description} onToggleServiceState={() => toggleServiceState(props.id, s)} generateIcon={workflowIconGeneration} />)}
                            </>
                        ) || (
                                <Stack>
                                    <MessageBar messageBarType={MessageBarType.warning}>
                                        <div dangerouslySetInnerHTML={{ __html: strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.SERVICES.NO_SERVICES }}></div>
                                    </MessageBar>
                                </Stack>
                            )}
                    </Stack>
                    <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                    <Stack.Item style={{ paddingBottom: 24 }}>
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Stack.Item>
                                <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.SERVICES.INFORMATION_TITLE}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.SERVICES.INFORMATION}</Text>
                                <br />
                                <CSHHelpLink  articleid="1098" alignment='left' label={strings.ORGANIZATION.COMPANIES.COMPANY_DETAILS.PIVOTS.SERVICES.LINK}/>
                            </Stack.Item>
                        </Stack>
                        <Text style={{ color: SharedColors.gray20 }}></Text>
                    </Stack.Item>
                </Stack>
            ) || (
                    <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                        <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                    </Stack>
                ))}
            {servicesState.error && (
                <Stack verticalFill>
                    {servicesState.error.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error}>{servicesState.error}</MessageBar>
                    )}
                    <Stack.Item verticalFill>
                        <SomethingWentWrong action={() => getServices()} />
                    </Stack.Item>
                </Stack>
            )}
        </>
    )
}

const theme = getTheme();

const classNames = mergeStyleSets({
    sticky: {
        padding: "0px 32px"
    },
});