import { Stack, getTheme, IStackStyles, SharedColors } from "@fluentui/react"
import * as React from 'react';
import { useSelector } from "react-redux";
import { IStore } from "../../../../../../core/store";
import { IDevice } from '@piceasoft/core';
import { strings } from "../../../../../../localization/strings";
import { IIdentificationPiceaOneConfig } from "@piceasoft/core";
import { IPicea } from "../typings/IPicea";
import { PiceaStatuses, PiceaConnectionStatuses } from '@piceasoft/core';
import PiceaOne from "./PiceaOne";
import { PreviewStatesToggler, TPreviewState } from "./ui/PreviewStatesToggler";

type TProps = {
    index: number
}

enum IdentificationPreviewModuleStates {
    PhoneNotConnected,
    ConnectingToOtf,
    Problem,
    PhoneConnected,
    OtfDisconnected,
}

export const PiceaOnePreview: React.FC<TProps> = ({ index }) => {

    const getRandomDevice = (): IDevice => {
        return (
            {
                manufacturer: "Xiaomi",
                name: "Redmi Note 8T",
                configuration: "128",
                attributes: {
                    capacity: 128,
                    imei: "",
                    image: "https://api.piceasoft.com/deviceinfo/v1/get_image?manufacturer=Xiaomi&model_name=Redmi%20Note%208T",
                    model: "REDMI NOTE 8T",
                    os: "android",
                    os_version: "10"
                }
            }
        )
    }

    const module = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === index) as IIdentificationPiceaOneConfig)
    const [device, setDevice] = React.useState<IDevice>();
    const [picea, setPicea] = React.useState<IPicea>();

    const [state, setState] = React.useState<IdentificationPreviewModuleStates>()

    React.useEffect(() => {
        setState(IdentificationPreviewModuleStates.PhoneNotConnected)
    }, [])

    React.useEffect(() => {
        setDevice(undefined)
        switch (state) {
            case IdentificationPreviewModuleStates.PhoneNotConnected:
                setPicea({
                    connections: {
                        otf: { status: PiceaConnectionStatuses.Connected },
                        usb: { status: PiceaConnectionStatuses.Disconnected }
                    },
                    status: PiceaStatuses.Available,
                    script: ""
                })
                break;
            case IdentificationPreviewModuleStates.Problem:
                setPicea({
                    connections: {
                        otf: { status: PiceaConnectionStatuses.Failed },
                        usb: { status: PiceaConnectionStatuses.Disconnected }
                    },
                    status: PiceaStatuses.Available,
                    script: ""
                })
                break;
            case IdentificationPreviewModuleStates.PhoneConnected:
                setDevice(getRandomDevice())
                setPicea({
                    connections: {
                        otf: { status: PiceaConnectionStatuses.Connected },
                        usb: { status: PiceaConnectionStatuses.Disconnected }
                    },
                    status: PiceaStatuses.Available,
                    script: ""
                })
                break;
            case IdentificationPreviewModuleStates.OtfDisconnected:
                setPicea({
                    connections: {
                        otf: { status: PiceaConnectionStatuses.Disconnected },
                        usb: { status: PiceaConnectionStatuses.Disconnected }
                    },
                    status: PiceaStatuses.Available,
                    script: ""
                })
                break;
            case IdentificationPreviewModuleStates.ConnectingToOtf:
                setPicea({
                    connections: {
                        otf: { status: PiceaConnectionStatuses.Connecting },
                        usb: { status: PiceaConnectionStatuses.Disconnected }
                    },
                    status: PiceaStatuses.Available,
                    script: ""
                })
                break;
            default:
        }
    }, [state])

    if (!module) return null

    const states: TPreviewState[] = [
        {
            index: IdentificationPreviewModuleStates.PhoneNotConnected,
            iconProps: { style: { color: SharedColors.gray20 } },
            title: strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.STATES.DEVICE_NOT_CONNECTED
        },
        {
            index: IdentificationPreviewModuleStates.ConnectingToOtf,
            iconProps: { style: { color: SharedColors.orangeYellow10 } },
            title: strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.STATES.CONNECT_TO_SERVICES
        },
        {
            index: IdentificationPreviewModuleStates.Problem,
            iconProps: { style: { color: SharedColors.red10 } },
            title: strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.STATES.ERROR
        },
        {
            index: IdentificationPreviewModuleStates.PhoneConnected,
            iconProps: { style: { color: SharedColors.green10 } },
            title: strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.STATES.DEVICE_CONNECTED
        },
        {
            index: IdentificationPreviewModuleStates.OtfDisconnected,
            iconProps: { style: { color: SharedColors.cyanBlue10 } },
            title: strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.STATES.ERROR_CONNECT_TO_SERVICES
        }
    ]

    return (
        <Stack grow tokens={{ padding: "32px 32px 8px 32px" }}>
            <Stack tokens={{ childrenGap: 8 }}>
                <Stack grow styles={containerStyles}>
                    {picea && <PiceaOne config={module as IIdentificationPiceaOneConfig} picea={picea} device={device} />}
                </Stack>
                <Stack horizontalAlign="center" tokens={{ padding: "8px 0 0 0" }}>
                    <PreviewStatesToggler currentState={state} states={states} onSelectState={setState} />
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();
const containerStyles: IStackStyles = { root: { minWidth: 320, maxWidth: 320, minHeight: 412, padding: 16, backgroundColor: theme.palette.white, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 } };