import { ISelfServiceWorkflowConfig, ProcessStages } from "@piceasoft/core";
import { Dispatch } from "react";
import { Stack } from "@fluentui/react";
import { ParameterItem } from "../../../components/ParameterItem";
import { strings } from "../../../../../../localization/strings";
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";

interface TProps {
    config: ISelfServiceWorkflowConfig
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    channel: number
}

export const SelfServiceCommonButtonsSettingsPivot: React.FC<TProps> = ({ ...props }) => {

    const maxButtonCharacters = 25;
    
    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.UI.BUTTONS.CONTINUE.TEXT_TITLE}
                noSeparator
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, props.stage, 'Buttons', 'Custom Continue button caption')}
                toggleChecked={props.config?.ui?.buttons?.continue?.text !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.selfService.editStepButtons({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            buttons: {
                                ...props.config.ui?.buttons,
                                continue: {
                                    ...props.config.ui?.buttons?.continue,
                                    text: props.config.ui?.buttons.continue?.text !== undefined ? undefined : ""
                                }
                            }
                        }
                    } as ISelfServiceWorkflowConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.UI.BUTTONS.CONTINUE.TEXT_DESCRIPTION}
                useTextField
                textFieldEnabled={props.config.ui?.buttons?.continue?.text !== undefined ? true : false}
                textFieldValue={props.config.ui?.buttons?.continue?.text}
                textFieldMaxLength={maxButtonCharacters}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.selfService.editStepButtons({
                    ...props.config,
                    ui: {
                        ...props.config.ui,
                        buttons: {
                            ...props.config.ui?.buttons,
                            continue: {
                                ...props.config.ui?.buttons?.continue,
                                text: val
                            }
                        }
                    }
                } as ISelfServiceWorkflowConfig
                ))}
            />

            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.CANCEL.TEXT_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, props.stage, 'Buttons', 'Custom Cancel button caption')}
                toggleChecked={props.config?.ui?.buttons?.cancel?.text !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.selfService.editStepButtons({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            buttons: {
                                ...props.config.ui?.buttons,
                                cancel: {
                                    ...props.config.ui?.buttons?.cancel,
                                    text: props.config.ui?.buttons?.cancel?.text !== undefined ? undefined : ""
                                }
                            }
                        }
                    } as ISelfServiceWorkflowConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.CANCEL.TEXT_DESCRIPTION}
                useTextField
                textFieldEnabled={props.config.ui?.buttons?.cancel?.text !== undefined ? true : false}
                textFieldValue={props.config.ui?.buttons?.cancel?.text}
                textFieldMaxLength={maxButtonCharacters}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.selfService.editStepButtons({
                    ...props.config,
                    ui: {
                        ...props.config.ui,
                        buttons: {
                            ...props.config.ui?.buttons,
                            cancel: {
                                ...props.config.ui?.buttons?.cancel,
                                text: val
                            }
                        }
                    }
                } as ISelfServiceWorkflowConfig
                ))}
            />
        </Stack>
    )
}