import { Image, ImageFit, Stack, Separator, Text, Icon, getTheme } from '@fluentui/react';
import * as React from 'react';
import unknown from '../../../../../assets/unknown.png';
import { getPromoPriceTypeDescription } from '../../../../../localization/helpers/transaction';
import { TransactionTabEntry } from './TransactionTabEntry';
import { strings } from '../../../../../localization/strings';
import { PropertyGrid } from '../../../../../components/shared/decorations/PropertyGrid';
import { CurrencyCodes, IPromoProviderConfig, IPromoProviderItemState } from '@piceasoft/core';
import { CurrencySigns } from '@piceasoft/core';
import { getIconSymbolsFromString, getPersonaColorsPallete } from '../../../../../core/scripts/style';
import { getPromoProviderIcon } from '../../../../../core/helpers/providers';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../core/store';

type TProps = {
    data: IPromoProviderItemState
    grade?: string
    providerConfig?: IPromoProviderConfig
}

export const PromotionPivot: React.FC<TProps> = ({ data, providerConfig, grade }) => {
    const condition = data.conditions?.find(i => i.grade === grade || i.grade === "*")
    const generateIcons = useSelector((s: IStore) => s.workplace.organization?.workflowIconGeneration)

    return (
        <Stack verticalFill style={{ padding: 24 }}>
            <Stack horizontal verticalFill tokens={{ childrenGap: 24 }}>
                <Stack horizontalAlign="center" tokens={{ childrenGap: 24 }} style={{ paddingTop: 2 }}>
                    <Stack.Item style={{ borderBottom: "1px solid", borderBottomColor: theme.palette.neutralLight, paddingBottom: 24 }}>
                        <Image height={160} width={160} imageFit={ImageFit.contain} maximizeFrame={true} src={data.imgSrc ?? unknown} />
                    </Stack.Item>
                    <Stack horizontal horizontalAlign='center' verticalAlign="center" tokens={{ childrenGap: 32 }}>
                        {providerConfig && (providerConfig?.imageSrc && (
                            <Stack horizontalAlign='center'>
                                <Image src={providerConfig?.imageSrc} width={48} height={48} imageFit={ImageFit.cover} style={{ borderRadius: 24 }} />
                            </Stack>
                        ) || (
                                generateIcons && (
                                    <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 48, height: 48, borderRadius: 24, backgroundColor: getPersonaColorsPallete[providerConfig.name[0].toLowerCase()] }}>
                                        <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                                            {getIconSymbolsFromString(providerConfig?.name)}
                                        </Text>
                                    </Stack>

                                ) || (
                                    <Icon style={{ fontSize: 48 }} iconName={getPromoProviderIcon(providerConfig.type)} />
                                )
                            )
                        )}
                    </Stack>
                </Stack>
                <Separator vertical />
                <Stack grow horizontal tokens={{ childrenGap: 32 }}>
                    <Stack tokens={{ childrenGap: 16 }} grow>
                        <PropertyGrid name={strings.TRANSACTION.PROMOTION.PROMO_TITLE} icon="Package">
                            <TransactionTabEntry name={strings.TRANSACTION.PROMOTION.NAME} value={data.name ?? ""} />
                            <TransactionTabEntry name={strings.TRANSACTION.PROMOTION.DESCRIPTION} value={data.description ?? ""} />
                            <TransactionTabEntry name={strings.TRANSACTION.PROMOTION.MANUFACTURER} value={data.manufacturer ?? ""} />
                            <TransactionTabEntry name={strings.TRANSACTION.PROMOTION.DEVICE} value={data.device ?? ""} />
                            {condition && (
                                <TransactionTabEntry
                                    name={strings.TRANSACTION.PROMOTION.PRICE}
                                    value={getPromoPriceTypeDescription(condition.priceType)}
                                    onValueRender={(value: string) => <><small>{value}</small> {condition.price.toLocaleString()} <small>{condition.currency ? CurrencySigns[condition.currency as CurrencyCodes] : undefined}</small></>}
                                />
                            )}
                        </PropertyGrid>
                        <PropertyGrid name={strings.TRANSACTION.PROMOTION.FROM_TO} icon="Clock">
                            <TransactionTabEntry
                                name={strings.TRANSACTION.PROMOTION.FROM}
                                value={new Date(data.from).toLocaleString()}
                                onValueRender={(value: string) => <small>{value}</small>}
                            />
                            <TransactionTabEntry
                                name={strings.TRANSACTION.PROMOTION.TO}
                                value={new Date(data.to).toLocaleString()}
                                onValueRender={(value: string) => <small>{value}</small>}
                            />
                        </PropertyGrid>
                    </Stack>
                </Stack>
                <Separator vertical />
                {providerConfig && (
                    <Stack style={{ width: 250 }} tokens={{ childrenGap: 16 }}>
                        <PropertyGrid name={strings.TRANSACTION.PROMOTION.PROVIDER_TITLE} icon="UserSync">
                            <TransactionTabEntry name={strings.TRANSACTION.PROMOTION.PROVIDER_NAME} value={providerConfig?.name ?? ""} />
                            <TransactionTabEntry name={strings.TRANSACTION.PROMOTION.PROVIDER_DESCRIPTION} value={providerConfig?.description ?? ""} />
                        </PropertyGrid>
                    </Stack>
                )}

            </Stack >
        </Stack >
    )
}

const theme = getTheme();
