import { ScrollablePane, Stack } from "@fluentui/react"
import React from "react"
import { IPromotion } from "../../../core/store/typings/IPromotion"
import { FinancePromotionDetails } from "./FinancePromotionDetails"
import { FinancePromotionHeader } from "./FinancePromotionHeader"

type TProps = {
    data: IPromotion
    onDelete: (item: IPromotion) => void
    onEdit: (item: IPromotion) => void
    retryInfo: () => void
}

export const FinancePromotion: React.FC<TProps> = (props) => {
    return (
        <Stack verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Stack verticalFill>
                    <FinancePromotionHeader onEdit={props.onEdit} item={props.data} onDelete={props.onDelete} />
                    <Stack.Item verticalFill>
                        <FinancePromotionDetails retryInfo={props.retryInfo} data={props.data} onDelete={props.onDelete} />
                    </Stack.Item>
                </Stack>
            </ScrollablePane>
        </Stack>
    )
}
