import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationPiceaOneConfig } from "@piceasoft/core";
import { IdentificationModuleUISettingsPivot } from "../IdentificationModuleUISettingsPivot";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const PiceaOneUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationPiceaOneConfig)

    return (
        <IdentificationModuleUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch}>
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.SHORT_URL_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={config.useShortUrl}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        useShortUrl: config.useShortUrl !== undefined ? undefined : true
                    } as IIdentificationPiceaOneConfig))
                }}
            />
        </IdentificationModuleUISettingsPivot>

    )
}