import { FontWeights, getTheme, mergeStyleSets, Separator, Stack, Text } from "@fluentui/react"
import React from "react"
import { IDocument } from "../../../../core/store"
import { strings } from "../../../../localization/strings"
import { Section } from "../../../../components/shared/decorations/Section"
import { RemoveArea } from "../../../../components/shared/decorations/RemoveArea"
import { CompanyService } from "../../../../components/shared/companyService/CompanyService"
import { PropertyValue } from "../../../../components/shared/decorations/PropertyValue"

type TServicesPrintTemplateInfoPivotProps = {
    data: IDocument
    onDelete: (document: IDocument) => void
}

export const ServicesPrintTemplateInfoPivot: React.FC<TServicesPrintTemplateInfoPivotProps> = (props) => {

    return (
        <Stack tokens={{ padding: "24px 32px 24px 32px", childrenGap: 16 }}>
            <Section title={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PIVOTS.INFORMATION.TEMPLATE_DATA}>
                <Stack tokens={{ childrenGap: 12 }}>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.NAME} value={props.data.name} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.FIELDS.SUMMARY} value={props.data.summary} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 6 }}>
                        <PropertyValue label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.INFORMATIONS.CURRENT_VERSION} value={props.data.currentVersionDate ? new Date(props.data.currentVersionDate).toLocaleString() : undefined} />
                        <PropertyValue label={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.INFORMATIONS.CURRENT_VERSION_SUMMARY} value={props.data.currentVersionSummary} />
                    </Stack>
                </Stack>
            </Section>
            {props.data.services && props.data.services.length > 0 && (
                <>
                    <Stack tokens={{ padding: '12px 0px 0px 0px', childrenGap: 0 }}>
                        <Text variant="large" style={{ color: theme.palette.black, fontWeight: 600, letterSpacing: "-.02em", lineHeight: "24px" }}>
                            {strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PIVOTS.INFORMATION.USE_BY_SERVICES}
                        </Text>
                        <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                    </Stack>
                    <Stack tokens={{ childrenGap: 16 }}>
                        {props.data.services?.map((s, i) => <CompanyService key={i} withoutToggle name={s.name} description={s.description} onToggleServiceState={() => { }} />)}
                    </Stack>
                </>
            )}
            <Stack.Item>
                <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
            </Stack.Item>
            <Stack.Item >
                <Stack tokens={{ childrenGap: 8 }}>
                    <RemoveArea
                        href="1114"
                        hrefText={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_INFO_MORE_HELP}
                        onDelete={() => props.onDelete(props.data)}
                        text={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_INFO_TEXT}
                        title={strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.REMOVE_TITLE}
                    />
                </Stack>
            </Stack.Item>
        </Stack >
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const cancelButtonStyle: React.CSSProperties = {
    border: 'none',
    background: theme.palette.neutralLight
}