import React, {Dispatch, FC} from 'react';
import {getTheme, IconButton, ScrollablePane, Stack, Text} from "@fluentui/react";
import {ISelfServiceWorkflowConfig} from "@piceasoft/core";
import {CSHHelpLink} from "../../../help/CSHHelp";
import SelfServiceConfiguratorPivot from "./pivots/SelfServiceConfiguratorPivot";
import {strings} from '../../../../../localization/strings';


interface IProps {
    config?: ISelfServiceWorkflowConfig
    onDismiss: () => void
    onChangeDispatch: Dispatch<any>
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const SelfServiceConfigurator: FC<IProps> = ({config, onDismiss, onChangeDispatch}) => {

    return (
        <Stack verticalFill grow>
            <Stack horizontal grow style={{padding: 8}}>
                <Stack style={{padding: 8}} grow tokens={{childrenGap: 4}}>
                    <Text variant="xLarge" style={{fontWeight: 600}}>
                        {strings.CONSTRUCTOR.STAGES.SELF_SERVICE.HEADER}
                    </Text>
                    <Text variant="small">
                        {strings.CONSTRUCTOR.STAGES.SELF_SERVICE.MOBILE_SUPPORT_HUB}
                    </Text>
                </Stack>
                <Stack horizontal style={{alignItems: "center"}}>
                    <CSHHelpLink articleid="1016"/>
                    <IconButton onClick={onDismiss} styles={iconButtonStyles}
                                iconProps={{iconName: 'Cancel'}} ariaLabel="Close"/>
                </Stack>
            </Stack>
            <Stack.Item verticalFill style={{position: 'relative'}}>
                <ScrollablePane>
                    <SelfServiceConfiguratorPivot config={config} onChangeDispatch={onChangeDispatch}/>
                </ScrollablePane>
            </Stack.Item>
        </Stack>
    );
};

export default SelfServiceConfigurator;
