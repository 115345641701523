import { Stack, AnimationStyles, SharedColors } from "@fluentui/react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"
import { StagePreviewUIKit } from "../../preview/StagePreviewUIKit"
import * as UIKit from "@piceasoft/picea-react-ui-kit"
import { TPreviewState } from "./ui/PreviewStatesToggler"
import * as React from 'react'
import { ProcessStages } from "@piceasoft/core"
type TProps = {
    currentPivot: string | undefined
}

enum SelectionPreviewModuleStates {
    SelectSource,
    SelectTarget
}

export const ContentTransferIdentificationPreview: React.FC<TProps> = (props:TProps ) => {

    const [titleText, setTitleText] = React.useState<string>();
    const [previewState, setPreviewState] = React.useState<number>( SelectionPreviewModuleStates.SelectSource);
    const config = useSelector((s: IStore) => s.configurator.identification)

    React.useEffect(() => {
        if( SelectionPreviewModuleStates.SelectSource === previewState) {
            setTitleText( config.ui?.selectDevices?.selectSource ?? strings.CONTENT_TRANSFER.DEVICE_SELECTION.SELECT_SOURCE_DEVICE);
        }
        else if( SelectionPreviewModuleStates.SelectTarget === previewState) {
            setTitleText( config.ui?.selectDevices?.selectTarget ?? strings.CONTENT_TRANSFER.DEVICE_SELECTION.SELECT_TARGET_DEVICE);
        }
    }, [previewState])

    const states: TPreviewState[] = ( props.currentPivot && props.currentPivot.startsWith( "common") ) ? [] : [
        {
            index: SelectionPreviewModuleStates.SelectSource,
            iconProps: { style: { color: SharedColors.gray20 } },
            title: strings.CONTENT_TRANSFER.DEVICE_SELECTION.SELECT_SOURCE_DEVICE
        },
        {
            index: SelectionPreviewModuleStates.SelectTarget,
            iconProps: { style: { color: SharedColors.orangeYellow10 } },
            title: strings.CONTENT_TRANSFER.DEVICE_SELECTION.SELECT_TARGET_DEVICE
        }]

    return (
        <StagePreviewUIKit config={config}
            stage={ProcessStages.Identification}
            defaultNextText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONTINUE}
            defaultCancelText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CANCEL}
            defaultBackText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.BACK}
            defaultStageTitle={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.IDENTIFICATION}
            previewStateSelected={setPreviewState}
            previewStates={states}
        >
            { props.currentPivot && props.currentPivot.startsWith( "common") && (
            <Stack tokens={{ childrenGap: 32, padding: 32 }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }} >
                <Stack.Item>
                    <Stack horizontalAlign="center" >
                        <UIKit.Text variant="h2" >{config.ui?.header ?? strings.CONTENT_TRANSFER.IDENTIFICATION.CONNECT_DEVICE_VIA_USB}</UIKit.Text>
                        <Stack tokens={{ maxWidth: 800 }}>
                            <div style={{ textAlign: "center" }}>
                                <UIKit.Text variant="small">
                                    <div dangerouslySetInnerHTML={{ __html: config.ui?.information ?? "" }}></div>
                                </UIKit.Text>
                            </div>
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack> 
            )}
            { props.currentPivot && props.currentPivot == "select_device" && (
                <Stack tokens={{ childrenGap: 32, padding: 32 }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }} >
                <Stack.Item>
                    <Stack horizontalAlign="center" >
                        <UIKit.Text variant="h2" >{titleText}</UIKit.Text>
                        <Stack tokens={{ maxWidth: 800 }}>
                            <div style={{ textAlign: "center" }}>
                                <UIKit.Text variant="small">
                                    <div dangerouslySetInnerHTML={{ __html: config.ui?.selectDevices?.information ?? strings.CONTENT_TRANSFER.DEVICE_SELECTION.SUBTITLE }}></div>
                                </UIKit.Text>
                            </div>
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack> 
            )}
        </StagePreviewUIKit>
    )
}