import { getTheme, IconButton, Separator, Stack, Text, TooltipHost } from "@fluentui/react"
import { useId } from "@uifabric/react-hooks";
import { strings } from "../../../../localization/strings";
import { TStageSettingsButtonProps } from "./StageBox";

export type IStageHeaderProps = {
    title: string
    onCommandRender?: () => React.ReactNode
    settingsButtonProps?: TStageSettingsButtonProps
    width?: number
}

export const StageHeader: React.FC<IStageHeaderProps> = (props) => {

    const tooltipId = useId('tooltip');

    return (
        <Stack horizontal>
            <Stack.Item styles={{ root: { overflow: "hidden" } }}>
                <Text block nowrap style={{ minWidth: 0, fontSize: 16, fontWeight: 600, color: theme.palette.black, marginTop: 4 }}>{props.title}</Text>
            </Stack.Item>
            {props.onCommandRender && (
                <Stack.Item styles={{ root: { overflow: "hidden", paddingLeft: 8 } }}>
                    <Separator vertical />
                </Stack.Item>
            )}
            <Stack.Item grow disableShrink>
                <Stack horizontal horizontalAlign="space-between" styles={{ root: { overflow: "hidden" } }}>
                    <Stack horizontal styles={{ root: { height: 32 } }}>
                        {props.onCommandRender && props.onCommandRender()}
                    </Stack>
                    <Stack.Item>
                        {/* <TooltipHost content="Локализация раздела" id={tooltipId} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                            <IconButton iconProps={{ iconName: "LocaleLanguage" }} ariaLabel="Emoji" />
                        </TooltipHost> */}
                        <TooltipHost content={props.settingsButtonProps?.tooltipContent ?? strings.CONSTRUCTOR.BUTTONS.STAGE_SETTINGS} id={tooltipId} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                            {!props.settingsButtonProps?.hideButton && (<IconButton iconProps={{ iconName: props.settingsButtonProps?.iconName ?? "Settings" }} ariaLabel="Settings" onClick={props.settingsButtonProps?.onClick} />)}
                        </TooltipHost>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
