import { IRequestResult } from "../store/typings/IResponseResult";
import { ITimeZoneInfoShort } from "../store/typings/ITimeZoneInfoShort";
import { endpoints } from "./endpoints";

export const environmentApi = {
    getTimezones: async (): Promise<IRequestResult<ITimeZoneInfoShort[]>> => {
        let result: IRequestResult<ITimeZoneInfoShort[]> = { successed: false };
        await fetch(endpoints.v1.environment.timezones)
            .then(async response =>  {
                await (response.json() as Promise<IRequestResult<ITimeZoneInfoShort[]>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [error])
            })
            .catch(error => result.errors = [error])
        return result;
    },
}