import { Stack, ScrollablePane } from '@fluentui/react';
import * as React from 'react'
import { IInterviewModeConfig, IInterviewModeConfigUI } from "@piceasoft/core";
import { strings } from '../../../../../../../localization/strings';
import { Section } from '../../../../../decorations/Section';
import { ParameterItem } from '../../../../components/ParameterItem';

type TProps = {
    config: IInterviewModeConfig<IInterviewModeConfigUI>
    onConfirm: (config: IInterviewModeConfig<IInterviewModeConfigUI>) => void
}

export const InterviewModeSettings: React.FC<TProps> = ({ config, onConfirm, children }) => {
    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.PIVOTS.SETTINGS} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.USE_FULLSCREEN_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useFullscreen ? true : false}
                        toggleOnChange={() => {
                            onConfirm({
                                ...config,
                                useFullscreen: !config.useFullscreen ? true : undefined
                            })
                        }}
                        description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.PIVOTS.SETTINGS.USE_FULLSCREEN_DESCRIPTION}
                    />
                    {children}
                </Section>
            </ScrollablePane>
        </Stack.Item>
    )
}
