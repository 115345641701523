import { getEnumName, getEnumNumber } from "../../actions/teaching-actions";
import { AccountRoles, getAccountRoleTitle } from "./AccountRoles";
import { strings } from '../../../localization/strings';

export enum UserRoles {
    Organization = 'Organization',
    Administrator = 'Administrator',
    Trader = 'Trader',
    Supervisor = 'Supervisor',
    All = 'All',
    Branding = 'Branding'
}

export type AllRoles = UserRoles | AccountRoles

export const getAccessToResetPassword = (currentRoles?: AllRoles[], resetRoles?: AllRoles[]): boolean => {
    enum roles {
        Operator = 0,
        Photoreport = 0,
        Branding = 0,
        Trader = 0,
        Master = 0,
        Service = 0,
        Statistic = 0,
        All = 0,
        Manager = 1,
        Organization = 2,
        Supervisor = 3,
        Administrator = 4
    }
    let manageUserRole = 0
    let resetUserRole = 0

    if (currentRoles) {
        currentRoles.map((role) => {
            let currentRole = getEnumName(UserRoles, role) !== '' ? getEnumNumber(roles, role) : getEnumName(AccountRoles, role) != '' ? getEnumNumber(roles, role) : 0
            manageUserRole = currentRole > manageUserRole ? currentRole : manageUserRole
        }
        )
    }
    if (resetRoles) {
        resetRoles.map((role) => {
            let currentRole = getEnumName(UserRoles, role) !== '' ? getEnumNumber(roles, role) : getEnumName(AccountRoles, role) != '' ? getEnumNumber(roles, role) : 0
            resetUserRole = currentRole > resetUserRole ? currentRole : resetUserRole
        })
    }
    return manageUserRole > resetUserRole ? true : false
}

export const checkIsManagerUser = (roles?: string[]) => {
    return (roles?.find(i => [
        UserRoles.Administrator, 
        UserRoles.Supervisor, 
        UserRoles.Organization, 
        AccountRoles.Manager, 
        AccountRoles.Master, 
        AccountRoles.Photoreport, 
        AccountRoles.Service, 
        AccountRoles.Statistic,
        UserRoles.Trader,
        UserRoles.Branding 
    ].includes(i as AllRoles)) ? true : false)
}

export const getLocalizedUserRole = (role: UserRoles) => {
    switch (role) {
        case UserRoles.Organization: return strings.USER_ROLES.ORGANIZATION
        case UserRoles.Administrator: return strings.USER_ROLES.ADMINISTRATOR
        case UserRoles.Trader: return strings.USER_ROLES.TRADER
        case UserRoles.Supervisor: return strings.USER_ROLES.SUPERVISOR
        case UserRoles.All: return strings.USER_ROLES.ALL
        case UserRoles.Branding: return strings.USER_ROLES.BRANDING
        default: return role
    }
}



export const getLocalizedRole = (role: AllRoles) => {

    const userRoles = Object.values(UserRoles)
    const accountRoles = Object.values(AccountRoles)
    
    const isUserRole = (role: any): role is UserRoles => userRoles.includes(role);
    const isAccountRole = (role: any): role is AccountRoles => accountRoles.includes(role);

    if( isUserRole(role) && !isAccountRole(role) ) {
        return getLocalizedUserRole(role)
    } else {
        // account role or unknown role
        return getAccountRoleTitle(role)
    }
}