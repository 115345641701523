import {
    Breadcrumb, FontSizes, IBreadcrumbItem, Icon,
    IDividerAsProps, IRenderFunction, SharedColors,
    TooltipHost, Text, Stack
} from '@fluentui/react';
import * as React from 'react'
import { useHistory, useLocation } from 'react-router';
import { getFullNav } from '../navigation/Navigation';

type TCrumbsItem = { href: string, key: string, text: string }

function _getCustomDivider(dividerProps: IDividerAsProps): JSX.Element {
    return (
        <TooltipHost calloutProps={{ gapSpace: 0 }}>
            <span aria-hidden="true" style={{ cursor: "default", margin: "0 4px", color: SharedColors.gray30 }}>/</span>
        </TooltipHost>
    );
}

function _getCustomOverflowIcon(): JSX.Element {
    return <Icon iconName={'MoreVertical'} />
}

const _returnUndefined = () => undefined;

const prepareCrumbs = (location: string): Array<TCrumbsItem> => {
    const fullNav = getFullNav();
    const pathParts = location.split("/").filter(i => i !== "").slice(0, 2)
    let crumbs: TCrumbsItem[] = []
    fullNav[0].links.map(l => {
        // console.log(l.key.split("."))
        if (l.key.split(".")[1].toLowerCase() === pathParts[0]) {
            crumbs.push({ text: l.name, href: l.url, key: l.key })
            l.links && l.links.map(ll => {
                if (ll.key.split(".").length > 2 && ll.key.split(".")[2].toLowerCase() === pathParts[1]) {
                    crumbs.push({ text: ll.name, href: ll.url, key: ll.key })
                }
            })
        }
        return;
    })
    return crumbs;
}

type TProps = {
}

export const PortalBreadcrumb: React.FC<TProps> = (props) => {

    const location = useLocation().pathname
    const history = useHistory()

    const onRenderItem: IRenderFunction<IBreadcrumbItem> = (props) => {
        return (
            <Stack.Item verticalFill>
                <Text style={{ padding: '0px 2px 0px 2px', fontWeight: props?.href && location.startsWith(props?.href) ? 600 : 'normal', cursor: props?.href && props.href.length > 0 ? 'pointer' : 'default' }} variant="medium" onClick={() => props?.href && history.push(props?.href)}>{props?.text}</Text>
            </Stack.Item>
        )
    }

    return (
        <Breadcrumb styles={scopedSettings}
            items={prepareCrumbs(location)}
            dividerAs={_getCustomDivider}
            onReduceData={_returnUndefined}
            onRenderItem={onRenderItem}
            onRenderOverflowIcon={_getCustomOverflowIcon}
        />
    )
}

const scopedSettings = {
    chevron: {
        fontSize: "8px",
    },
    item: {
        color: SharedColors.gray30,
        fontSize: FontSizes.medium
    },
    itemLink: {
        color: SharedColors.gray30,
        fontSize: FontSizes.medium
    },
    root: {
        margin: "auto"
    }
}
