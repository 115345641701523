import React, { Dispatch } from "react"
import { useBoolean } from "@uifabric/react-hooks"
import { Stack } from "@fluentui/react"
import { ParameterItem } from "../../../../../components/ParameterItem"
import { DocumentsSuggestedSearch } from "./DocumentsSuggestedSearch"
import { actionCreators } from "../../../../../../../../core/actions/configurator-actions"
import { strings } from "../../../../../../../../localization/strings"
import { IDataCollectionConfig, ProcessStages } from "@piceasoft/core"

export type TModeConfiguratorCommonPivotProps = {
    index: number
    stage: ProcessStages
    dataCollection: IDataCollectionConfig
    onChangeDispatch: Dispatch<any>
}

export const ModeConfiguratorCommonPivot: React.FC<TModeConfiguratorCommonPivotProps> = (props) => {
    const { dataCollection, index, onChangeDispatch } = props
    
    const [isDocumentsSelectVisible, { setTrue: showDocumentsSelect, setFalse: hideDocumentsSelect }] = useBoolean(dataCollection.documentId !== undefined)
    let removeDocumentTemplate = () => {
        let config = {
            ...dataCollection,
            documentId: undefined
        }
        onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
    }
    console.log(dataCollection.useSubscription)
    if(dataCollection.useSubscription){
        console.log(true)
    }else{
        console.log(false)
    }
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.PRINT_FORM_TITLE}
                useToggle
                noSeparator
                toggleEnabled
                toggleChecked={isDocumentsSelectVisible}
                toggleOnChange={() => {
                    if (isDocumentsSelectVisible) {
                        hideDocumentsSelect()
                        removeDocumentTemplate()
                        return
                    }
                    showDocumentsSelect()
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.PRINT_FORM_DESCRIPTION}
            >
                {isDocumentsSelectVisible && (
                    <DocumentsSuggestedSearch
                        index={index}
                        stage={props.stage}
                        dataCollection={dataCollection}
                        onChangeDispatch={onChangeDispatch}
                    />
                )}
            </ParameterItem>
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.USE_SUBSCRIPTION_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={dataCollection.useSubscription}
                toggleOnChange={() => {
                    let config = {
                        ...dataCollection,
                        useSubscription: !dataCollection.useSubscription,
                        continueWithSubscribtion: !dataCollection.useSubscription && false,
                        subscribeText: dataCollection.useSubscription ? undefined : dataCollection.subscribeText 
                    }
                    onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.USE_SUBSCRIPTION_DESCRIPTION}
            />
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.CONTINUE_WITH_SUBSCRIPTION_TITLE}
                useToggle
                toggleEnabled={dataCollection.useSubscription}
                toggleChecked={dataCollection.continueWithSubscribtion }
                toggleOnChange={() => {
                    let config = {
                        ...dataCollection,
                        continueWithSubscribtion: !dataCollection.continueWithSubscribtion
                    }
                    onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.CONTINUE_WITH_SUBSCRIPTION_DESCRIPTION}
            />
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.SUBSCRIBE_TEXT_TITLE}
                useToggle
                toggleEnabled={dataCollection.useSubscription}
                toggleChecked={dataCollection.subscribeText !== undefined ? true : false}
                toggleOnChange={() => {
                    let config = {
                        ...dataCollection,
                        subscribeText: dataCollection.subscribeText !== undefined ? undefined : ""
                    }
                    onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.SUBSCRIBE_TEXT_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={dataCollection.subscribeText !== undefined ? true : false}
                textFieldValue={dataCollection.subscribeText}
                textFieldOnChange={(val) => {
                    let config = {
                        ...dataCollection,
                        subscribeText: val
                    }

                    onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
                }}
            />
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.DADATA_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={dataCollection.useDaData}
                toggleOnChange={() => {
                    let config = {
                        ...dataCollection,
                        useDaData: !dataCollection.useDaData,
                        daDataKey: undefined
                    }
                    onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.DADATA_DESCRIPTION}
                useTextField
                textFieldMultiLimitSymbolsCount={40}
                textFieldEnabled={dataCollection.useDaData}
                textFieldValue={dataCollection.daDataKey}
                textFieldOnChange={(val) => {
                    let config = {
                        ...dataCollection,
                        daDataKey: val
                    }

                    onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
                }}
            />
            <ParameterItem
                title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.SEND_TO_EMAIL_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={dataCollection.sendToEmail}
                toggleOnChange={() => {
                    let config = {
                        ...dataCollection,
                        sendToEmail: !dataCollection.sendToEmail
                    }
                    onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.SEND_TO_EMAIL_DESCRIPTION}
            />
        </Stack>
    )
}
