import { getTheme, IStackProps, IStackSlots, IStackStyles, IStackTokens, Stack, Text } from "@fluentui/react"

export type ISectionProps = {
    title?: string
    description?: string
    flat?: boolean
    max?: boolean
    headerOnly?: boolean
    backgroundBlue?:boolean
    onRenderHeader?: () => React.ReactNode
    onRenderCommand?: () => React.ReactNode
    onRenderFooter?: () => React.ReactNode
}

export const Section: React.FC<ISectionProps & IStackProps> = (props) => {
    const getStyles=(props:ISectionProps)=>{
        if(props.backgroundBlue){
            return flatContainerBlueBackgroundStyles;
        } else{
            return props.flat ? flatContainerStyles : containerStyles;
        }
    }
    return (
        <Stack styles={getStyles(props)} tokens={{ childrenGap: 10 }} {...props} title={undefined}>
            {(props.onRenderHeader && props.onRenderHeader()) || props.title && (
                <Stack.Item styles={{ root: { padding: "16px 20px 0px 20px" } }}>
                    <Stack horizontal>
                        <Stack.Item grow>
                            <Stack tokens={{ childrenGap: 4 }}>
                                <Text style={{ fontSize: "1.0625rem", fontWeight: 600, color: theme.palette.black }}>{props.title}</Text>
                                {props.description && (
                                    <Text style={{ fontWeight: 500, color: theme.palette.neutralSecondaryAlt }}>{props.description}</Text>
                                )}
                            </Stack>
                        </Stack.Item>
                        {props.onRenderCommand && (
                            <Stack.Item>
                                {props.onRenderCommand()}
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
            )}
                {!props.headerOnly && (
                    <> 
                    <Stack.Item verticalFill style={{ padding: props.max ? 0 : "0px 20px 20px 20px" }}>
                        {props.children}
                    </Stack.Item>
                    {props.onRenderFooter && props.onRenderFooter()}
                     </>
             )} 
        </Stack>
    )
}

const theme = getTheme();

const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4
    }
};

const flatContainerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        // boxShadow: theme.effects.elevation8,
    }
};
const flatContainerBlueBackgroundStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.themeLighter,//'#DEECF9'
        marginTop:'10px'
    }
};
