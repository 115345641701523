import { Dropdown, IComboBoxOption, IDropdownOption, Icon, SharedColors, Stack, Text, getFocusStyle, getTheme, mergeStyleSets } from "@fluentui/react"
import { strings } from "../../../../../../localization/strings"
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from "../../../../search/SuggestedSearch"
import { useId } from "@uifabric/react-hooks"
import { escapeRegExp } from "../../../../../../assets/ts/utils/regex"
import React from "react"

type TProps = {
    label?: string
    placeholder?: string
    isFetching?: boolean
    selectionOptions?: IDropdownOption[]
    selectedId?: number
    onSelected: (item?: IComboBoxOption) => void
}

const DocumentTemplateSelect: React.FC<TProps> = (props: TProps) => {

    const documentsOptions = props.selectionOptions
    const documentsHostId = useId("documents-host-id")
    const documentsTextId = useId("documents-text-id")
    const documentsCalloutListId = useId("documents-callout-id")
    const [documentsFilterValue, setDocumentsFilterValue] = React.useState<string>()

    const controlWidth = 432

    const documentOptionSelectHandler = (item?: IComboBoxOption) => {
        props.onSelected( item)
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderDocumentsSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div style={{ cursor: 'pointer' }} className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${documentsCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, documentsOptions?.length ?? 0, documentsTextId, documentsCalloutListId, () => documentOptionSelectHandler(item), index, item?.text, documentsHostId)}
                tabIndex={-1}
                onClick={() => {
                    documentOptionSelectHandler(item)
                    focusElement(`${documentsCalloutListId}-${index as number}`, documentsHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    return (
    <Stack.Item>
    {(documentsOptions?.length ?? 0) <= 10 && (
        <Dropdown
            label={props.label ?? strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.DOCUMENT}
            options={documentsOptions ?? []}
            onChange={(ev, opt) => documentOptionSelectHandler(opt)}
            disabled={!documentsOptions || documentsOptions.length === 0 || props.isFetching}
            styles={{ dropdown: { width: controlWidth } }}
            selectedKey={props.selectedId ?? 'null'}
            placeholder={(props.isFetching) ?
                strings.SPINNERS.DATA_IS_GETTING :
                documentsOptions?.find(i => i.key === props.selectedId)?.text ?? (documentsOptions && documentsOptions.length > 0 ?
                    (props.placeholder ?? strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.SELECT_PLACEHOLDER) :
                    strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NO_TEMPLATES)} />
    ) || (
            <SuggestedSearch
                type="dropdown-search"
                inputBoxId={documentsTextId}
                hostId={documentsHostId}
                label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.DOCUMENT}
                inputBoxPlaceholder={(props.isFetching) ?
                    strings.SPINNERS.DATA_IS_GETTING :
                    documentsOptions?.find(i => i.key === props.selectedId)?.text ?? (documentsOptions && documentsOptions.length > 0 ?
                        (props.placeholder ?? strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.SELECT_PLACEHOLDER) :
                        strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NO_TEMPLATES)}
                onClickSuggestionCell={documentOptionSelectHandler}
                // setSearchRequest={(opt: IComboBoxOption) => setDocumentsFilterValue(opt?.text)}
                searchRequest={documentsFilterValue}
                suggestionsListWidth={controlWidth}
                inputBoxWidth={controlWidth}
                disabled={!documentsOptions || documentsOptions.length === 0 || props.isFetching}
                suggestions={documentsOptions ?? []}
                suggestionsListId={documentsCalloutListId}
                isSearchEqualTheOneSuggestion={false}
                onRenderSuggestionCell={onRenderDocumentsSuggestionsCell}
                prepareFilteredItems={prepareFilteredOptions}
                selectedKey={props.selectedId?.toString() ?? 'null'}
            />
        )}
    </Stack.Item>)
}

export default DocumentTemplateSelect

const theme = getTheme();