import { Stack, TextField, PrimaryButton, ScrollablePane, Dropdown } from '@fluentui/react';
import { getTheme } from '@fluentui/style-utilities';
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { prepareCurrencyOptionsRequired } from '../../../core/helpers/currency';
import { IPromotion } from '../../../core/store/typings/IPromotion';
import { strings } from '../../../localization/strings';
import { onRenderLabel } from '../../renders/onRenderLabel'
import { SecondaryButton } from '../buttons/SecondaryButton';
import { CSHHelpLink } from '../help/CSHHelp';

type TProps = {
    onSubmit: (item: IPromotion) => void
    onCancel: () => void
    data?: IPromotion
    organizationId: string
}

export const PromotionForm: React.FC<TProps> = (props) => {
    const { control, handleSubmit, formState: { errors } } = useForm<IPromotion>({ defaultValues: { organizationId: props.organizationId, ...props.data, currency: props.data?.currency } });
    const onSubmit: SubmitHandler<IPromotion> = data => {
        console.log(data)
        props.onSubmit(data)
    };

    const currency = prepareCurrencyOptionsRequired();

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: "relative" }}>
                    <ScrollablePane>
                        <Stack tokens={{ childrenGap: 16, padding: 20 }}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ 
                                    validate: (value)=> value && value.trim().length > 0 ? true : strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.NAME_REQUIRED,
                                    required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.NAME_REQUIRED
                                 }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.NAME}
                                        title={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.NAME}
                                        placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                name="summary"
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        multiline
                                        resizable={false}
                                        style={{ height: 100 }}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.SUMMARY}
                                        title={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.SUMMARY}
                                        placeholder={strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.SUMMARY_PLACEHOLDER}
                                        errorMessage={errors.summary?.message}
                                    />
                                }
                            />
                            <Controller
                                name="currency"
                                control={control}
                                rules={{ required: strings.ORGANIZATION.PROMOTIONS.PROMOTION.FIELDS.CURRENCY_REQUIRED }}
                                render={({ field }) =>
                                    <Dropdown
                                        selectedKey={field.value}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        label={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.CURRENCY}
                                        placeholder={strings.ORGANIZATION.SETTINGS.GENERAL.LOCALE.CURRENCY_PLACEHOLDER}
                                        options={currency}
                                        errorMessage={errors.currency?.message}
                                    />}
                            />
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <CSHHelpLink alignment='left' articleid='1110'></CSHHelpLink>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}
