import {
    DirectionalHint, FontWeights, getTheme, ICalloutContentStyles,
    Icon, IconButton, Link, mergeStyleSets, MessageBar, MessageBarType,
    Modal, SharedColors, Spinner, Stack, Text
} from "@fluentui/react"
import { SecondaryButton } from "../../../../../components/shared/buttons/SecondaryButton";
import { ResetPasswordForm } from "../../../../../components/shared/forms/ResetPasswordForm";
import { portalApi } from "../../../../../core/api/api";
import { getPersonaColorsPallete } from "../../../../../core/scripts/style";
import { IPerson, IStore } from "../../../../../core/store";
import { AccountRoles } from "../../../../../core/store/typings/AccountRoles";
import { getAccessToResetPassword } from "../../../../../core/store/typings/UserRoles";
import { strings } from "../../../../../localization/strings";
import { CopyToClipBoardButton } from "../../../../../components/shared/buttons/CopyToClipboardButton";
import React from "react";
import { useSelector } from "react-redux";

export type TProps = {
    person?: IPerson
    onEdit: () => void
}

export const PersonHeader: React.FC<TProps> = ({ person, onEdit }) => {

    const isOperator = person?.roles.includes(AccountRoles.Operator)

    const [resetPasswordAccountState, setResetPasswordAccountState] = React.useState<{ fetched: boolean, error?: string, data?: IPerson, currentPassword?: string }>({ fetched: false, currentPassword: person?.password })
    const roles = useSelector((s: IStore) => s.environment.roles);
    const access = getAccessToResetPassword(roles, person?.roles)

    const resetPassword = async (newPassword: string) => {
        setResetPasswordAccountState({ ...resetPasswordAccountState, fetched: true, })
        if (resetPasswordAccountState.data?.id) {
            const result = await portalApi.organization.accounts.resetPasswordAccount(resetPasswordAccountState.data?.id, newPassword)
            if (result.successed) {
                setResetPasswordAccountState({ data: undefined, fetched: false, currentPassword: newPassword })
            } else {
                setResetPasswordAccountState({ ...resetPasswordAccountState, error: result.errors ? result.errors[0].description : '', fetched: false })
            }
        }
    }
    return (
        <Stack horizontal grow style={{ padding: "24px 32px 12px 32px" }}>
            <Modal isOpen={resetPasswordAccountState.data ? true : false} onDismiss={() => setResetPasswordAccountState({ data: undefined, fetched: false })} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.RESET_PASSWORD_TITLE}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close window"
                        onClick={() => setResetPasswordAccountState({ data: undefined, fetched: false })}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Stack verticalFill tokens={{ childrenGap: 12 }}>
                        {resetPasswordAccountState.error && (
                            <MessageBar messageBarType={MessageBarType.error}>{resetPasswordAccountState.error}</MessageBar>
                        )}
                        <ResetPasswordForm fetched={resetPasswordAccountState.fetched} onCancel={() => setResetPasswordAccountState({ data: undefined, fetched: false })} onSubmit={resetPassword} roles={person?.roles} />
                    </Stack>
                </div>
            </Modal>
            {person && (
                <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                    <Stack.Item>
                        <Stack verticalAlign="center" horizontalAlign="center" style={{
                            width: 72, height: 72, borderRadius: 36,
                            backgroundColor: person.name && getPersonaColorsPallete[person.name[0].toLowerCase()]
                        }}>
                            <Icon iconName={"Contact"} style={{ color: "#fff", fontSize: 28, fontWeight: 400 }} />
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack>
                            <Stack.Item>
                                <Text style={{ fontSize: "1.3125rem", letterSpacing: "-.02em", fontWeight: 600, color: theme.palette.black }}>{person.display}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text style={{ color: SharedColors.gray30 }}>{person.roles.join(", ")}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>
                                    {isOperator && person.password && (
                                        <>
                                            <CopyToClipBoardButton
                                                content={resetPasswordAccountState.currentPassword ?? person.password}
                                                copyText={""}
                                                copiedText={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_COPIED}
                                                tooltipCalloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                                            >
                                                <Link>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.COPY_PASSWORD}</Link>
                                            </CopyToClipBoardButton> {access && <> |</>}
                                        </>
                                    )} {access && <Link onClick={() => setResetPasswordAccountState({ data: person, fetched: false })}>{strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.RESET_PASSWORD_TITLE}</Link>}
                                </Text>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            ) || (
                    <Stack verticalFill verticalAlign='center'>
                        <Spinner label={strings.SPINNERS.DATA_IS_LOADING} />
                    </Stack>
                )}
            <Stack.Item>
                <SecondaryButton text={strings.BUTTONS.TEXT.EDIT_DATA} onClick={onEdit} />
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const tooltipCalloutStyles: Partial<ICalloutContentStyles> = {
    beakCurtain: { background: theme.palette.neutralLight },
    beak: { background: theme.palette.neutralLight },
    calloutMain: { background: theme.palette.neutralLight },
    root: { color: theme.palette.white },
    container: { color: 'white' }
}

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});