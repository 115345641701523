import {
    Stack, getTheme, Separator, Text, PrimaryButton, SharedColors,
    FontIcon
} from "@fluentui/react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"
import * as UIKit from "@piceasoft/picea-react-ui-kit"


export const ContentTransferStagePreview: React.FC = () => {

    const config = useSelector((s: IStore) => s.configurator.contentTransfer)
    const defaultCancelText=strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CANCEL
    const defaultStageTitle = ""

    return (
        <Stack style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} >
            <Stack.Item tokens={{ padding: 24 }}>
                <Stack style={{ backgroundColor: theme.palette.neutralLighter, boxShadow: theme.effects.elevation64 }} >
                    <Stack.Item>
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }} styles={{ root: { padding: "4px 16px 4px 24px", backgroundColor: theme.palette.white } }}>
                            <Stack.Item grow>
                                <Stack horizontal verticalAlign="center" styles={{ root: { height: 56 } }}>
                                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                        <FontIcon iconName="Sprint" style={{ fontSize: 24, height: 24, width: 24 }} />
                                        <Separator vertical />
                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                            {(config?.ui?.stageTitle || defaultStageTitle) && (
                                                <Text variant="mediumPlus" style={{ color: SharedColors.gray30 }}>{config?.ui?.stageTitle ?? defaultStageTitle}</Text>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <UIKit.OnlineButton color="secondary" onClickHandler={ () => {}}>{config?.ui?.buttons?.cancel?.text ?? defaultCancelText}</UIKit.OnlineButton>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
