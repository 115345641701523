import { strings } from '../../localization/strings';
import {
    getTheme, IconButton, IStackStyles,
    Modal, Spinner, Stack, Text, Image, SearchBox, FontWeights,
    mergeStyleSets, MessageBar, MessageBarType, ScrollablePane, DefaultButton, IContextualMenuProps, IContextualMenuItem, PrimaryButton, DirectionalHint, ContextualMenuItemType,
    IStyle
} from '@fluentui/react';
import {CatalogTypes} from "@piceasoft/core";
import * as React from 'react';
import { ICatalog, IStore } from '../../core/store';
import { useSelector } from 'react-redux';
import { useBoolean } from '@uifabric/react-hooks';
import { portalApi } from '../../core/api/api';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { CatalogForm } from '../../components/shared/forms/CatalogForm';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { FinanceCatalog } from './Catalogs/FinanceCatalog';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { StructureNodeTypes } from '../organization/OrganizationStructure/Elements/StructureNodeTypes';
import { ITreeNode, ITreeNodeAction } from '../../assets/ts/tree/VTree';
import { IForestAction, IForestTree } from '../../components/shared/forest/Forest';
import { StructureNodeActionTypes } from '../organization/OrganizationStructure/Elements/StructureNodeActionTypes';
import { FinanceCatalogStructure } from './Catalogs/FinanceCatalogStructure';

export const FinanceCatalogs: React.FC = () => {

    const organization = useSelector((s: IStore) => s.workplace.organization)
    const [filteredTrees, setFilteredTree] = React.useState<ITreeNode[]>()
    const [selectedTreeKey, setSelectedTreeKey] = React.useState<string>('')
    const [expandedNodeList, setExpandedNodeList] = React.useState<string[]>()
    const [preparedTrees, setPreparedTree] = React.useState<ITreeNode[]>()
    const [catalogType,setCatalogType] = React.useState(0)
    const [catalogs, setCatalogs] = React.useState<{ fetched: boolean, data?: ICatalog[] }>({ fetched: false })
    const [error, setError] = React.useState<string>()
    const [panelError, setPanelError] = React.useState<string>()

    const [selectedCatalogId, setSelectedCatalogId] = React.useState<string>()

    const [catalog, setCatalog] = React.useState<{ fetched: boolean, data?: ICatalog }>({ fetched: false })

    const [panelCatalog, setPanelCatalog] = React.useState<{ fetched: boolean, data?: ICatalog }>({ fetched: false })
    const [deleteCatalogState, setDeleteCatalogState] = React.useState<ICatalog>()

    const [ catalogUpdating, setCatalogUpdating] = React.useState<boolean>( false);
    const [isCatalogPanelVisible, { setTrue: showCatalogPanel, setFalse: hideCatalogPanel }] = useBoolean(false)
    const [filterString, setFilterString] = React.useState<string>()

    React.useEffect(() => {
        getCatalogs()        
    }, [])

    React.useEffect(() => {
        console.log(catalogs)
        if (catalogs!==undefined && catalogs.fetched) {
            prepareTrees(catalogs?.data ?? [])
        }
    }, [catalogs])

    React.useEffect(() => {
        if(selectedCatalogId !== undefined && selectedCatalogId !==''){
        getCatalog()
    }
    }, [selectedCatalogId])

    React.useEffect(() => {
        setFilteredTree(undefined)
        if (filterString) {
            let newTrees: ITreeNode[] = getTreesByItemNameOrCode(filterString, preparedTrees)
            if (newTrees.length > 0) {
                setFilteredTree(newTrees)
            }
        }
    }, [filterString, catalogs.data])

    const getCatalogs = async () => {
        setError(undefined)
        const result = await portalApi.organization.catalogs.getCatalogs()
        console.log(result)
        if (result.successed) {
            setCatalogs({ fetched: true, data: result.data?.filter(i => !i.removed) })
        }
        if (result.errors) {
            setError(result.errors[0].description ?? "Catalogs fetch error")
        }
    }

    const getCatalog = async () => {
        setError(undefined)
        if (selectedCatalogId) {
            setCatalog({ fetched: false })
            const result = await portalApi.organization.catalogs.getCatalog(selectedCatalogId)
            if (result.successed) {
                setCatalog({ fetched: true, data: result.data })
            }
            if (result.errors) {
                setError(result.errors[0].description ?? "Catalog fetch error")
            }
        }
    }

    const onAddCatalog = (id?: number) => {
        setPanelCatalog({ fetched: true })
        showCatalogPanel()
        if(id !== 0 && id !== undefined)
        {
            setCatalogType(id);
        };
    }

    const addCatalog = async (item: ICatalog) => {
        setCatalogUpdating( true);
        item = {...item,catalogType:catalogType };
        const result = await portalApi.organization.catalogs.addCatalog(item)
        if (result.successed && result.data) {
            setCatalog({ fetched: true, data: result.data })
            setCatalogs({ ...catalogs, data: catalogs.data ? [result.data, ...catalogs.data] : [result.data] })
            hideCatalogPanel()
            setSelectedCatalogId(result.data.id)
        }
        if (result.errors) {
            setError(result.errors[0].description ?? "Catalogs fetch error")
        }
        setCatalogUpdating( false);
    }

    const onEditCatalog = (item: ICatalog) => {
        setSelectedCatalogId(item.id)
        setPanelCatalog({ fetched: true, data: item })
        showCatalogPanel()
        setCatalogType(item.catalogType);
    }

    const editCatalog = async (item: ICatalog) => {
        setCatalogUpdating( true);
        const result = await portalApi.organization.catalogs.updateCatalog(item)
        if (result.successed && result.data) {
            setCatalog({ fetched: true, data: result.data })
            setCatalogs({
                ...catalogs, data: catalogs.data ? [...catalogs.data.map(i => {
                    if (i.id === result.data?.id) {
                        return result.data
                    }
                    return i
                })] : [result.data]
            })
            hideCatalogPanel()
        }
        if (result.errors) {
            setError(result.errors[0].description ?? "Catalogs fetch error")
        }
        setCatalogUpdating( false);
    }


    const deleteCatalog = async () => {
        if (deleteCatalogState?.id) {
            const result = await portalApi.organization.catalogs.deleteCatalog(deleteCatalogState?.id)
            if (result.successed) {
                setCatalogs({ ...catalogs, data: catalogs.data?.filter(i => i.id !== deleteCatalogState.id) })
                //setCatalog({ fetched: false })
                setDeleteCatalogState(undefined)
            }
            if (result.errors) {
                setError(result.errors[0].description ?? "Catalog delete error")
            }
        }
    }
    const setDetailsItemHandler = async (type: StructureNodeTypes, itemId: string, parentId?: string): Promise<void> => {
        setSelectedCatalogId(itemId)
    }

    const getTreesByItemNameOrCode = (pattern: string, inputTrees?: ITreeNode[]): ITreeNode[] => {
        if (!inputTrees) return [];
        let trees: ITreeNode[] = []
        inputTrees?.filter(ct => {
            let isFounded = false
            
            const originValues = [
                ct.name,
                ct.itemCode,
                ct.parentId
            ]
            originValues.map(ov => {

                if (ov && !isFounded) {
                    isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${pattern?.toLowerCase()}\w*`)) != null
                }
            })
            if (isFounded && ct.parentId !== 'null') {
                trees.push(ct);
                return;
            }
            if (ct.children) {
                trees.push(...getTreesByItemNameOrCode(pattern, ct.children))
            }
        })
        return trees
    }
    const getSetsMenuProps=(): IContextualMenuProps => {
        let catalogs :ICatalog[]= [];
        catalogs.push({
            catalogType:CatalogTypes.Tradein,
            currency:'',
            id:String(CatalogTypes.Tradein),
            isFree:true,
            name:strings.ORGANIZATION.CATALOGS.TRADEINCATALOG,
            organizationId:String(0),
            removed:false,
            summary: '',
            currentVersionSummary:'',
            services:[]
        },{
            catalogType:CatalogTypes.Repair,
            currency:'',
            id:String(CatalogTypes.Repair),
            isFree:true,
            name:strings.ORGANIZATION.CATALOGS.REPAIRCATALOG,
            organizationId:String(0),
            removed:false,
            summary: '',
            currentVersionSummary:'',
            services:[]
        },{
            catalogType:CatalogTypes.GradeDiscount,
            currency:'',
            id:String(CatalogTypes.GradeDiscount),
            isFree:true,
            name: strings.ORGANIZATION.CATALOGS.GRADE_DISCOUNT_CATALOG,
            organizationId:String(0),
            removed:false,
            summary: '',
            currentVersionSummary:'',
            services:[]
        })
        let items = catalogs.map(i => ({
            key: `add-set-${i}`,
            onClick: () => onAddCatalog(i?.catalogType),
            text: i.name
        }) as IContextualMenuItem)
        return {
            items,
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }
    const prepareTrees = (data: ICatalog[]): IForestTree[] => {
        const parentStyles : React.CSSProperties = {
            color: theme.palette.black, 
            fontWeight: 700,
        };
        let items: ITreeNode[] = []
        let hasTradeInCatalogs = data.some(item => item.catalogType === CatalogTypes.Tradein);
        if (hasTradeInCatalogs) {
            items.push({
                name: strings.ORGANIZATION.CATALOGS.TRADE.IN.CATALOGS,
                id: String(CatalogTypes.Tradein),
                itemId: String(CatalogTypes.Tradein),
                parentId: 'null',
                onClick: () => { },
                children: [],
                isExpanded: false,
                disabled: false,
                styles: parentStyles
            })
        }

        let hasRetailCatalogs = data.some(item => item.catalogType == CatalogTypes.Repair);
        if (hasRetailCatalogs) {
            items.push({
                name: strings.ORGANIZATION.CATALOGS.REPAIR.CATALOGS,
                id: String(CatalogTypes.Repair),
                itemId: String(CatalogTypes.Repair),
                parentId: 'null',
                onClick: () => { },
                children: [],
                isExpanded: false,
                disabled: false,
                styles: parentStyles
            })
        }

        const hasGradeDiscountCatalogs = data.some(item => item.catalogType == CatalogTypes.GradeDiscount);
        if (hasGradeDiscountCatalogs) {
            items.push({
                name: strings.ORGANIZATION.CATALOGS.GRADE_DISCOUNT_CATALOGS,
                id: String(CatalogTypes.GradeDiscount),
                itemId: String(CatalogTypes.GradeDiscount),
                parentId: 'null',
                onClick: () => { },
                children: [],
                isExpanded: false,
                disabled: false,
                styles: parentStyles
            })
        }

        data.forEach(d => items.push({
            name: d.name,
            id: d.id,
            itemId: d.id,
            parentId: String(d.catalogType),
            onClick: () => setDetailsItemHandler(2,d.id, String(d.catalogType)),
            children: [],
            isExpanded: false,
            actions: getActions(d),
            disabled: false
        }))

        const hashTable = Object.create(null);

        items.forEach(item => hashTable[item.id as string] = { ...item });

        const trees: ITreeNode[] = [];

        if (catalogs.fetched && catalogs.data && catalogs.data.length > 0) {
            setSelectedCatalogId(catalogs.data[0].id)
        }
        
        items.forEach(item => {
            if (item.parentId && Object.keys(hashTable).includes(item.parentId)) hashTable[item.parentId].children.push(hashTable[item.id as string])
            else trees.push(hashTable[item.id as string])
        });

        
        setPreparedTree(trees)
        if(catalogs?.data)
        expandNode(String(catalogs?.data[0]?.catalogType))
        return trees ?? []
    }

    const getActions = (item?: ICatalog): IForestAction[] => {
        let itemActions: IForestAction[] = []
        if(item!==undefined)
        {
            const onNodeAction = (actionType: StructureNodeActionTypes, item: ICatalog) => {
                if (error) {
                    setError(undefined)
                }
                switch (actionType) {
                    case StructureNodeActionTypes.Update:
                        if (item.id) {
                            onEditCatalog(item)
                        }
                        break;
                    case StructureNodeActionTypes.Remove:
                        if (item.id) {
                            setDeleteCatalogState(item);
                        } else {
                            console.debug('Portal Organization Structure Logic => Item id not found');
                        }
                        break;
                    default:
                        console.log('Portal Organization Structure Logic => Action not found');
                }
            }

            const editAction = {
                id: 'editAction',
                name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                iconProps: { iconName: 'Edit', style: { fontSize: 16, color: 'black' } },
                onClick: () => item && onNodeAction(StructureNodeActionTypes.Update, item)
            }

            const removeAction = {
                id: 'removeAction',
                name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                iconProps: { iconName: 'Delete', style: { fontSize: 16, color: 'black' } },
                onClick: () => item && onNodeAction(StructureNodeActionTypes.Remove, item)
            }

            itemActions.push(editAction, removeAction);
        }
        return itemActions
    }

    const getMenuProps = (actions: ITreeNodeAction[]): IContextualMenuProps => {
        return (
            {
                shouldFocusOnMount: true,
                items: actions?.map(action => getMenuItemFromAction(action))
            }
        )
    }
    const getMenuItemFromAction = (action: ITreeNodeAction): IContextualMenuItem => {
        const menuItem: IContextualMenuItem = {
            key: action.id,
            iconProps: action.iconProps,
            onClick: action.onClick,
            text: action.name,
            subMenuProps: !action.childs ? undefined : {
                items: action.childs?.map(a => getMenuItemFromAction(a))
            }
        }

        return menuItem
    }
    const collapseNode = (key: string) => {
        if (expandedNodeList) {
            setExpandedNodeList([...expandedNodeList.filter(en => en !== key)])
        }
    }
    const expandNode = (key: string) => {
        setExpandedNodeList(expandedNodeList ? [...expandedNodeList, key] : [key])
    }

    const getPanelHeader = (id?: string) =>  {
        if (id === undefined) {
            switch (catalogType) {
                case CatalogTypes.Tradein: return strings.ORGANIZATION.CATALOGS.HEADER.ADD.TRADEINCATALOG;
                case CatalogTypes.Repair: return strings.ORGANIZATION.CATALOGS.HEADER.ADD.REPAIRCATALOG;
                case CatalogTypes.GradeDiscount: return strings.ORGANIZATION.CATALOGS.HEADER.ADD.GRADE_DISCOUNT_CATALOG;
            }
        }
        else {
            switch (catalogType) {
                case CatalogTypes.Tradein: return strings.ORGANIZATION.CATALOGS.HEADER.EDIT.TRADEINCATALOG;
                case CatalogTypes.Repair: return strings.ORGANIZATION.CATALOGS.HEADER.EDIT.REPAIRCATALOG;
                case CatalogTypes.GradeDiscount: return strings.ORGANIZATION.CATALOGS.HEADER.EDIT.GRADE_DISCOUNT_CATALOG;
            }
        }
        return "unknown catalogType";
    }
    return (
        <Stack verticalFill>
            {!error && (catalogs.fetched && organization?.name && (
                <Stack.Item verticalFill>
                    {(catalogs.data && catalogs.data.length > 0) && (
                        <Stack verticalFill>
                            <Stack horizontal grow verticalFill verticalAlign="start" >
                                <Stack.Item verticalFill style={{ minWidth: 300 }}>
                                    <Stack verticalFill>
                                        <Stack.Item>
                                            <Stack horizontal grow verticalAlign='center' tokens={{ padding: '8px 8px 0px 20px' }}>
                                                <Stack.Item grow>
                                                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                                        <Image height={16} src="images/navigation/icons/workflow.svg" />
                                                        <Text variant='large' style={{ fontWeight: 500 }}>{organization?.name}</Text>
                                                    </Stack>
                                                </Stack.Item>
                                                <Stack.Item>
                                                <PrimaryButton iconProps={{ iconName: "Add" }} disabled={false} menuProps={getSetsMenuProps()} >{strings.ORGANIZATION.CATALOGS.ADD}</PrimaryButton>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack tokens={{ padding: 8 }}>
                                                <SearchBox onChange={(ev, value) => setFilterString(value)} />
                                            </Stack>
                                        </Stack.Item>
                                {(!filterString || filteredTrees) && (
                                    <FinanceCatalogStructure organizationName={organization?.name}
                                        trees={(filteredTrees) ?? (preparedTrees?? [])}
                                        actions={getActions()}
                                        setDetailsItem={setDetailsItemHandler}
                                        selectedKey={selectedTreeKey}
                                        getMenuProps={getMenuProps}
                                        setSelectedKey={setSelectedTreeKey}
                                        expandedKeys={expandedNodeList}
                                        collapseNode={collapseNode}
                                        expandNode={expandNode}
                                    />
                                ) || (
                                        <Stack verticalFill verticalAlign="center">
                                            <Stack horizontalAlign='center'>
                                                <Text>{strings.ORGANIZATION.STRUCTURE.TREE.EMPTY_FILTER}</Text>
                                            </Stack>
                                        </Stack>
                                    )}
                                    </Stack>
                                </Stack.Item>
                                <Stack verticalFill style={{ width: 1, borderRight: `1px solid ${theme.palette.neutralQuaternary}` }} />
                                <Stack verticalFill grow>
                                    {!catalog?.fetched && (
                                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                                            {error && <DefaultButton text={strings.BUTTONS.TEXT.TRY_AGAIN} onClick={getCatalog} />}
                                        </Stack>
                                    ) || (catalog?.fetched && (catalog?.data && (
                                        <FinanceCatalog data={catalog.data} retryInfo={getCatalog} onEdit={onEditCatalog} onDelete={setDeleteCatalogState} />
                                    ))
                                        )}
                                </Stack>
                            </Stack>
                        </Stack>
                    )
                    || (
                        <ItemsNotFound
                            info={strings.ORGANIZATION.CATALOGS.NOT_FOUND_CASE.INFO}
                            buttonText={strings.ORGANIZATION.CATALOGS.NOT_FOUND_CASE.ADD_BUTTON}
                            suggestion={strings.ORGANIZATION.CATALOGS.NOT_FOUND_CASE.SUGGESTION}
                            imgSrc={"images/navigation/images/startup.png"}
                            menuProps={getSetsMenuProps}
                        />
                    ) 
                    }
                    <CustomPanel
                        isOpen={isCatalogPanelVisible}
                        onCancel={hideCatalogPanel}
                        title={panelCatalog.data?.id ? getPanelHeader(panelCatalog.data?.id)  : getPanelHeader()}
                    >
                        {panelError && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                {panelError}
                            </MessageBar>
                        )}
                        {panelCatalog.fetched && organization?.id && (
                            <CatalogForm
                                data={panelCatalog.data}
                                onSubmit={(data: ICatalog) => data.id ? editCatalog(data) : addCatalog(data)}
                                onCancel={hideCatalogPanel}
                                organizationId={organization?.id}
                                catalogBusy={catalogUpdating}
                            />
                        ) || (
                                <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                    <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                                </Stack>
                            )}
                    </CustomPanel>
                    {deleteCatalogState && (
                        <Modal isOpen={deleteCatalogState ? true : false} onDismiss={() => setDeleteCatalogState(undefined)} containerClassName={contentStyles.container}>
                            <div className={contentStyles.header}>
                                <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.CATALOGS.REMOVE.CONFIRMATION_TITLE}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: "Cancel" }}
                                    ariaLabel="Close"
                                    onClick={() => setDeleteCatalogState(undefined)}
                                />
                            </div>
                            <div className={contentStyles.body}>
                                <ConfirmationForm
                                    onConfirm={deleteCatalog}
                                    onCancel={() => setDeleteCatalogState(undefined)}
                                    requiredMessageString={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.NAME_REQUIRED}
                                    confirmationCheckString={deleteCatalogState?.name}
                                    placeholder={strings.ORGANIZATION.CATALOGS.CATALOG.FIELDS.NAME_PLACEHOLDER}
                                    confirmText={strings.BUTTONS.TEXT.DELETE}
                                >
                                    <Stack tokens={{ childrenGap: 12 }}>
                                        <Text>{strings.ORGANIZATION.CATALOGS.REMOVE.CONFIRMATION_TEXT1}</Text>
                                        <Text>{strings.ORGANIZATION.CATALOGS.REMOVE.CONFIRMATION_TEXT2}</Text>
                                        <Text>{strings.ORGANIZATION.CATALOGS.REMOVE.CONFIRMATION_REQUIREMENTS} <b>"{deleteCatalogState.name}"</b></Text>
                                    </Stack>
                                </ConfirmationForm>
                            </div>
                        </Modal>
                    )}
                </Stack.Item>
            )
                || (
                    <Stack.Item verticalFill>
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    </Stack.Item>
                ))}
            {error && (
                <Stack verticalFill>
                    {error.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                    )}
                    <SomethingWentWrong action={getCatalogs} />
                </Stack>
            )}
        </Stack>
    )
}

const theme = getTheme();
const containerStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 } };

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};