import { Control, Controller } from "react-hook-form"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import { getCommonFieldRules } from "../../../helpers/validationRules"
import DateOfBirth from "./DateOfBirth"

import type { TContractData } from "../../../../../dataCollectionTypes"

type TProps = {
    config?: IDataCollectionStandardConfig
    errorMessage: string
    phoneCountry?: string
    control: Control<TContractData, object>,
}

const DateOfBirthControlleer: React.FunctionComponent<TProps> = (props: TProps) => {
    const {
        config,
        control,
        errorMessage,
    } = props

    return (
        <Controller
            name="passport.dateOfBirth"
            control={control}
            rules={getCommonFieldRules(config?.fields?.passport?.dateOfBirth)}
            render={({ field }) =>
                <DateOfBirth
                    config={config}
                    value={field.value}
                    errorMessage={errorMessage}
                    handleOnBlur={field.onBlur}
                    handleOnChange={(value: string) => {
                        field.onChange(value)
                    }}
                />
            }
        />
    )
}

export default DateOfBirthControlleer