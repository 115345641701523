import { Stack, ScrollablePane, IDropdownOption, PrimaryButton, IconButton, TextField } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { onRenderLabel } from "../../../../../../renders/onRenderLabel";
import { Section } from "../../../../../decorations/Section";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationSerialConfig } from "@piceasoft/core";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const SerialCommonSettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationSerialConfig)

    const groupsOptions: IDropdownOption[] = [
        { key: "IPHONE", text: "IPHONE" },
        { key: "WATCH", text: "WATCH" },
        { key: "AIRPODS", text: "AIRPODS" },
        { key: "IPAD", text: "IPAD" },
        { key: "IMAC", text: "IMAC" },
        { key: "MACBOOK", text: "MACBOOK" },
    ]

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.HEADER} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.COMMON.USE_CONFIGURATIONS_TITLE}
                        // childrenAlignStart
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useConfiguration !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useConfiguration: config.useConfiguration !== undefined ? undefined : true,
                            } as IIdentificationSerialConfig))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.COMMON.USE_CONFIGURATIONS_DESCRIPTION}
                    >
                        {config.useConfiguration && (
                            <Stack tokens={{ childrenGap: 4 }}>
                                {config.configurations?.map((i, index) => <Stack.Item key={index} grow>
                                    <Stack verticalFill grow horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                                        <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                            <IconButton disabled={index === 0}
                                                iconProps={{ iconName: 'Up' }}
                                                onClick={() => index > 0 && props.onChangeDispatch(actionCreators.identification.editModule({
                                                    ...config,
                                                    configurations: config.configurations?.map((i, localIndex) => {
                                                        if (localIndex === index && config.configurations && config.configurations[localIndex - 1]) {
                                                            return config.configurations[localIndex - 1] as { value: string, text: string }
                                                        }
                                                        if (localIndex === index - 1 && config.configurations && config.configurations[localIndex + 1]) {
                                                            return config.configurations[localIndex + 1] as { value: string, text: string }
                                                        }
                                                        return i
                                                    }),
                                                } as IIdentificationSerialConfig))}
                                            />
                                        </Stack>
                                        <Stack grow style={{ paddingTop: 2 }} horizontal verticalAlign='center' tokens={{ childrenGap: 24 }}>
                                            <Stack.Item>
                                                <TextField onRenderLabel={onRenderLabel}
                                                    // title={index === 0 ? strings.CONSTRUCTOR.GRADES.TITLES.NAME : undefined}
                                                    label={index === 0 ? strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.COMMON.GROUP_TEXT : undefined} value={i.text}
                                                    onChange={(ev, val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                                        ...config,
                                                        configurations: config.configurations?.map((i, localIndex) => {
                                                            if (index === localIndex) {
                                                                return {
                                                                    text: val,
                                                                    value: i.value
                                                                }
                                                            }
                                                            return i
                                                        }),
                                                    } as IIdentificationSerialConfig))}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField onRenderLabel={onRenderLabel}
                                                    // title={index === 0 ? strings.CONSTRUCTOR.GRADES.TITLES.EXPLANATION : undefined}
                                                    label={index === 0 ? strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.COMMON.GROUP_VALUE : undefined} value={i.value}
                                                    onChange={(ev, val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                                        ...config,
                                                        configurations: config.configurations?.map((i, localIndex) => {
                                                            if (index === localIndex) {
                                                                return {
                                                                    text: i.text,
                                                                    value: val
                                                                }
                                                            }
                                                            return i
                                                        }),
                                                    } as IIdentificationSerialConfig))}
                                                />
                                            </Stack.Item>
                                        </Stack>
                                        <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                            <IconButton
                                                iconProps={{ iconName: 'Delete' }}
                                                onClick={() => props.onChangeDispatch(actionCreators.identification.editModule({
                                                    ...config,
                                                    configurations: config.configurations?.filter((i, localIndex) => localIndex !== index),
                                                } as IIdentificationSerialConfig))}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack.Item>)}
                                <Stack.Item style={{ padding: "16px 0px 0px 0px" }}>
                                    <PrimaryButton onClick={() => props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        configurations: config.configurations ? [...config.configurations, {}] : [{}],
                                    } as IIdentificationSerialConfig))} >{strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.COMMON.ADD_CONFIGURATION_BUTTON}</PrimaryButton>
                                </Stack.Item>
                            </Stack>
                        )}
                    </ParameterItem>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.COMMON.GROUPS_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.groups !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                groups: config.groups !== undefined ? undefined : [],
                            } as IIdentificationSerialConfig))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.COMMON.GROUPS_DESCRIPTION}
                        useDropdown
                        dropdownMultiselect
                        dropdownEnabled={config.groups !== undefined ? true : false}
                        dropdownSelectedKeys={config.groups ?? []}
                        dropdownOnChange={(opt) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            groups: opt?.selected ? (config.groups ? [...config.groups, opt.key] : [opt.key]) : config.groups?.filter(i => i !== opt?.key)
                        } as IIdentificationSerialConfig))}
                        dropdownPlaceholder={strings.CONSTRUCTOR.IDENTIFICATION.SERIAL.PIVOTS.COMMON.GROUPS_PLACEHOLDER}
                        dropdownOptions={groupsOptions}
                    >
                    </ParameterItem>
                </Section>
            </ScrollablePane>
        </Stack.Item >
    )
}