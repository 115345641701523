import { Stack, TextField, PrimaryButton } from '@fluentui/react';
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IGrade, IDiagnosticsPiceaTestConfig } from "@piceasoft/core"
import { strings } from '../../../../../../../../localization/strings';
import { onRenderLabel } from '../../../../../../../renders/onRenderLabel';
import { SecondaryButton } from '../../../../../../buttons/SecondaryButton';

type TProps = {
    grades?: IGrade[]
    onConfirm: (test: IDiagnosticsPiceaTestConfig) => void
    test?: IDiagnosticsPiceaTestConfig
}

export const DiagnosticsTestForm: React.FC<TProps> = ({ test, onConfirm }) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<IDiagnosticsPiceaTestConfig>({ defaultValues: { ...test } });

    const onSubmit: SubmitHandler<IDiagnosticsPiceaTestConfig> = data => {
        console.log(data)
        onConfirm({ ...data, sets: test?.sets ?? [] })
    };

    React.useEffect(() => {
        reset(test, { keepValues: false })
    }, [test])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack tokens={{ childrenGap: 16, padding: "0px 20px 20px 20px" }}>
                <Stack.Item>
                    <Stack horizontal grow>
                        <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                            <SecondaryButton iconProps={{ iconName: "Undo" }} onClick={() => reset(test)} />
                            <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack.Item>
                <Stack grow tokens={{ childrenGap: 16 }}>
                    <Controller
                        control={control}
                        name="name"
                        rules={{ required: strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.NAME_REQUIRED }}
                        render={({ field }) =>
                            <TextField {...field}
                                required
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.NAME}
                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.NAME_TITLE}
                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.NAME_PLACEHOLDER}
                                errorMessage={errors.name?.message}
                            />
                        }
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) =>
                            <TextField {...field}
                                multiline
                                resizable={false}
                                style={{ height: 100 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.DESCRIPTION}
                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.DESCRIPTION_TITLE}
                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.DESCRIPTION_PLACEHOLDER}
                                errorMessage={errors.description?.message}
                            />
                        }
                    />
                </Stack>
            </Stack>
        </form>
    )
}
