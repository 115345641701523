import { Dispatch } from "react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../../core/store"
import { ShowConfigurationPivot } from "../../../common/pivots/ShowConfigurationPivot"
import { TIdentificationModuleSettingsPivotStates } from "../../IdentificationModule"
import { ScannerCommonSettingsPivot } from "./ScannerCommonSettingsPivot"
import { ScannerComponentUISettingsPivot } from "./ScannerComponentUISettingsPivot"
import { ScannerModalUISettingsPivot } from "./ScannerModalUISettingsPivot"
import { ScannerUISettingsPivot } from "./ScannerUISettingsPivot"

type TProps = {
    state: TIdentificationModuleSettingsPivotStates
    index: number
    onChangeDispatch: Dispatch<any>
}

export const ScannerSettingsPivotBasedState: React.FC<TProps> = (props) => {
    switch (props.state) {
        case "ui": return (
            <ScannerUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "modal_ui": return (
            <ScannerModalUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "component_ui": return (
            <ScannerComponentUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "common": return (
            <ScannerCommonSettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "config": return (
            <ShowConfigurationPivot json={useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index)) ?? {}} />
        )
        default:
            return (
                <></>
            )
    }
}
