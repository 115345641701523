import { Stack } from "@fluentui/react"
import { PropertyValue } from "../../../../components/shared/decorations/PropertyValue"
import { Section } from "../../../../components/shared/decorations/Section"
import { ISupervisor } from "../../../../core/store/typings/ISupervisor"
import { strings } from "../../../../localization/strings"

type TInfoPivotProps = {
    item: ISupervisor
}

export const SupervisorInfoPivot: React.FC<TInfoPivotProps> = (props) => {
    return (
        <Section title={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.PIVOTS.INFORMATION}>
            <Stack tokens={{ childrenGap: 12 }}>
                <Stack tokens={{ childrenGap: 6 }}>
                    <PropertyValue label={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.FIELDS.DISPLAY} value={props.item.display ?? strings.COMMON.EMPTY} />
                    <PropertyValue label={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.FIELDS.NAME} value={props.item.name} />
                    <PropertyValue label={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.FIELDS.EMAIL} value={props.item.email ?? strings.COMMON.EMPTY} />
                </Stack>
            </Stack>
        </Section>
    )
}
