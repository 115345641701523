import { getTheme, Text } from "@fluentui/react"

type TProps = {
    title: string
}

export const Badge: React.FC<TProps> = (props) => {
    return <Text style={{ color: theme.palette.black, fontWeight: 400, backgroundColor: "rgba(0, 0, 0, .08)", borderRadius: 10, padding: "1px 8px 2px 8px", fontSize: 10 }} block nowrap>{props.title}</Text>
}

const theme = getTheme();