import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, DetailsRow, IColumn, IDetailsListProps, IDetailsRowStyles, SelectionMode, SharedColors, Stack, Text } from '@fluentui/react';
import { IWorkflow } from '../../../../../core/store';
import { IInspectionConfig, IInspectionConfigUI, IInspectionState, Inspections, InspectionStatuses } from "@piceasoft/core";
import { MyPersona } from '../../../../../components/shared/decorations/MyPersona';
import { Badge2 } from '../../../../../components/shared/decorations/Badge2';
import { getLocalizedInspectionName, getLocalizedInspectionStatus } from '../../../../../localization/helpers/transaction';
import { strings } from '../../../../../localization/strings';
import { getInspectionStatusBadgeTheme } from '../../../../../components/helpers/transaction';
import { IInspectionModuleState, IAssessment } from '@piceasoft/core';

type TInspectionItem = {
    name: string
    grade?: string
    status: InspectionStatuses
    tab?: Inspections | string
    forceSkip?: boolean
    index?: number
    gradeCategory?: string
}

type TProps = {
    assessment: IAssessment
    workflow: IWorkflow
    setInspectionTab: (tab: Inspections) => void
}

export const AssessmentPivot: React.FC<TProps> = ({ assessment, workflow, setInspectionTab }) => {

    let inspectionsList: string[] = []
    let inspectionsState: TInspectionItem[] = []
    let inspectionIndexesList: number[] = []

    if (!workflow?.version && workflow?.assessment) {
        inspectionsList = Object.keys(workflow.assessment.inspections)
    }

    if (workflow?.version && workflow?.assessment) {
        inspectionIndexesList = workflow.assessment.modules.map(i => i.index).sort((a, b) => a - b)
    }

    if (!workflow?.version && inspectionsList.length > 0) {
        inspectionsList.forEach(inspection => {
            const inspections = assessment.inspections as unknown as { [key: string]: IInspectionState }
            if (inspections[inspection]?.status) {
                inspectionsState.push({
                    name: inspection,
                    grade: inspections[inspection]?.grade,
                    status: inspections[inspection]?.status,
                    tab: (Object.values(Inspections).find(i => i === inspection) && ![
                        InspectionStatuses.SkippedByUser,
                        InspectionStatuses.SkippedByCondition,
                        InspectionStatuses.Lock,
                        InspectionStatuses.New
                    ].includes(inspections[inspection as Inspections]?.status)) ? inspection as Inspections : undefined
                })
            }
        });
    }

    if (workflow?.version && inspectionIndexesList.length > 0) {
        inspectionIndexesList.forEach(inspectionIndex => {
            const inspectionResult = (assessment.modules.find(r => r.index === inspectionIndex) as IInspectionModuleState)?.state
            if (inspectionResult?.status) {
                inspectionsState.push({
                    name: workflow.assessment?.modules.find(i => i.index === inspectionIndex)?.type as Inspections,
                    grade: inspectionResult.grade,
                    forceSkip: inspectionResult.warning?.code === 200,
                    status: inspectionResult.status,
                    gradeCategory: workflow.assessment?.modules.find(i => i.index === inspectionIndex)?.config.gradesCategory,
                    index: inspectionIndex,
                    tab: !(inspectionResult.warning?.code === 200) && ![
                        InspectionStatuses.SkippedByUser,
                        InspectionStatuses.SkippedByCondition,
                        InspectionStatuses.Lock,
                        InspectionStatuses.New
                    ].includes(inspectionResult.status) ? `inspection-${inspectionIndex}` : undefined
                })
            }
        });
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            fieldName: 'inspection',
            minWidth: 250,
            isResizable: false,
            data: 'string',
            onRender: (item: TInspectionItem) => {
                let inspections
                let inspectionTitle
                if (workflow.version) {
                    inspections = workflow.assessment?.modules
                    inspectionTitle = inspections?.find(i => i.index === item.index)?.config.ui?.title
                }
                if (!workflow.version) {
                    inspections = workflow.assessment?.inspections as { [key: string]: IInspectionConfig<IInspectionConfigUI> }
                    inspectionTitle = inspections[item.name].ui?.title
                }
                return (
                    <MyPersona onClick={() => { }} text={inspectionTitle ?? getLocalizedInspectionName(item.name as Inspections)} secondaryText="" />)
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: '',
            fieldName: 'status',
            minWidth: 100,
            isResizable: false,
            flexGrow: 1,
            onRender: (item: TInspectionItem) => (
                <Stack verticalFill tokens={{ padding: 2 }} verticalAlign="center">
                    <Badge2  {...getInspectionStatusBadgeTheme(item.status)} title={getLocalizedInspectionStatus(item.status)} />
                </Stack>
            ),
        },
        {
            key: 'column2',
            name: '',
            fieldName: 'grade',
            minWidth: 160,
            isResizable: false,
            data: 'string',
            onRender: (item: TInspectionItem) => {
                return (
                    <Stack verticalFill verticalAlign="center" horizontalAlign='end'>

                        {item.forceSkip && (
                            <Text style={{ color: SharedColors.cyanBlue10 }}>{strings.TRANSACTION.INSPECTION.FORCED_SKIP}</Text>
                        ) || (
                                item.grade && (
                                    <Text style={{ color: SharedColors.cyanBlue10 }}>
                                        {workflow.useGradesCategories
                                            ? workflow.gradesCategories?.find(gc => gc.code === item.gradeCategory)?.grades.find(i => i.code === item.grade)?.name
                                            : workflow.grades.find(i => i.code === item.grade)?.name
                                        }
                                    </Text>
                                ) || (
                                    <Text style={{ color: SharedColors.cyanBlue10 }}>–</Text>
                                ))}
                    </Stack>
                );
            },
            isPadded: true,
        },
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: (rProps?.item as TInspectionItem).tab ? 'pointer' : undefined,
                ':hover': {
                    backgroundColor: !(rProps?.item as TInspectionItem).tab ? 'transparent' : undefined
                }
            };
            return (
                <Stack onClick={() => rProps.item.tab && setInspectionTab(rProps.item.tab)}>
                    <DetailsRow {...rProps} styles={customStyles} />
                </Stack>
            );
        }
        return null;
    }

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item>
                <DetailsList
                    items={inspectionsState}
                    columns={columns}
                    isHeaderVisible={false}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderRow={onRenderRow}
                />
            </Stack.Item>
            <Stack.Item styles={{ root: { paddingRight: 30 } }}>
                <Stack horizontal horizontalAlign="end" verticalAlign="end" tokens={{ childrenGap: 20 }}>
                    <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.TRANSACTION.OVERVIEW.RESULT_GRADE}</Text>
                    <Text style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{workflow?.grades?.find(i => i.code === assessment.grade)?.name ?? assessment.grade ?? strings.TRANSACTION.RESULT.GRADE_NOT_DEFINED_UPPERCASE}</Text>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}
