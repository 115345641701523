import { Stack, SharedColors, DefaultButton, Text } from "@fluentui/react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"
import { StagePreviewUIKit } from "../../preview/StagePreviewUIKit"
import * as UIKit from "@piceasoft/picea-react-ui-kit"
import { ProcessStages } from "@piceasoft/core"

export const ContractPreviewUIKit: React.FC = () => {

    const config = useSelector((s: IStore) => s.configurator.contract)

    if (!config) return null

    return (
        <StagePreviewUIKit config={config}
        stage={ProcessStages.Contract}
        defaultNextText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONTINUE}
        defaultCancelText={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CANCEL}
        defaultStageTitle=""
        previewStateSelected={ () => {}}
        noTitle
        noTransaction
        noDevice
    >
        <Stack verticalFill tokens={{ childrenGap: 24, padding: 32 }}>
            <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                <UIKit.Text variant="h2" style={{ fontWeight: "bold" }}>{config?.ui?.header ?? strings.CONSTRUCTOR.STAGES.CONTRACT.TITLE}</UIKit.Text>
                <Stack horizontalAlign="center" tokens={{ maxWidth: 800, childrenGap: 24 }}>
                    <UIKit.Text variant="small" style={{ color: SharedColors.gray40 }}>
                        <div dangerouslySetInnerHTML={{ __html: config?.ui?.information ?? "" }}></div>
                    </UIKit.Text>
                    <Stack.Item>
                        <UIKit.OnlineButton color="primary" onClickHandler={ () => {}}>{config?.ui?.edit ?? strings.BUTTONS.TEXT.EDIT_DATA}</UIKit.OnlineButton>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
    </StagePreviewUIKit>
    )
}
