import { Stack } from '@fluentui/react';
import * as React from 'react';
import { ComingSoon } from '../../components/shared/comingSoon/ComingSoon';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { strings } from '../../localization/strings';

export const FinanceReports: React.FC = () => {
    return (
        <Stack verticalFill>
            <ScreenHeader title={strings.NAVIGATION.FINANCE.REPORTS}></ScreenHeader>
            <ComingSoon />
        </Stack>
    )
}