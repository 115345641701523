import { Stack, ScrollablePane, IDropdownOption } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { DeviceTypes } from "@piceasoft/core";
import { strings } from "../../../../../../../localization/strings";
import { Section } from "../../../../../decorations/Section";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationGlobalConfig } from "@piceasoft/core";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
    okButtonDisabled(arg: boolean): void
}

export const GlobalCommonSettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationGlobalConfig)

    const typesOptions: IDropdownOption[] = [
        {
            key: DeviceTypes.DEVICE_TYPE_MOBILE,
            text: strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES.MOBILE
        },        
        {
            key: DeviceTypes.DEVICE_TYPE_WATCH,
            text: strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES.WATCH
        },
    ]

    React.useEffect(() => {
        if(config.types !== undefined) {
            config.types.length === 0 ? props.okButtonDisabled(true) : props.okButtonDisabled(false)
        } else {
            props.okButtonDisabled(false)
        }
    }, [config.types])

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.HEADER} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.types !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                types: config.types !== undefined ? undefined : []
                            } as IIdentificationGlobalConfig))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES_DESCRIPTION}
                        useDropdown
                        dropdownMultiselect
                        dropdownEnabled={config.types !== undefined ? true : false}
                        dropdownSelectedKeys={config.types}
                        dropdownOnChange={(opt) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            types: opt?.selected ? (config.types ? [...config.types, opt.key] : [opt.key]) : config.types?.filter(i => i !== opt?.key)
                        } as IIdentificationGlobalConfig))}
                        dropdownPlaceholder={strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES_PLACEHOLDER}
                        dropdownOptions={typesOptions}
                        dropdownErrorMessage={config.types?.length === 0 ? strings.CONSTRUCTOR.WARNINGS.SELECT_DEVICE_CATEGORY : undefined}
                    />
                </Section>
            </ScrollablePane>
        </Stack.Item>
    )
}