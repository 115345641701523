import { Icon, SharedColors, Stack, StackItem, Text, getTheme, Image, ImageFit, Separator, mergeStyleSets } from "@fluentui/react"
import { IGrade } from "@piceasoft/core"
import { strings } from '../../../../../localization/strings';

type TProps = {
    result: string | undefined
    grades: IGrade[]
}

export const ManualGradingPivot: React.FC<TProps> = (props) => {
    const currentGrade = props.grades.find(i => i.code === props.result)

    return (
        <>
            {props.result && currentGrade && (
                <ManualGradingResult result={props.result} gradeName={currentGrade.name} gradeExample={currentGrade.explanation} 
                    imageUrl={currentGrade.image_url} imageName={currentGrade.image_name} grades={props.grades} />
            )}
        </>
    )
}

type TManualGradingResultProps = {
    result: string | undefined
    gradeName: string
    gradeExample: string
    imageUrl: string | undefined
    imageName: string | undefined
    grades: IGrade[]
}

const ManualGradingResult: React.FC<TManualGradingResultProps> = (props) => {
    return (
        <Stack>
            <Stack horizontal grow verticalAlign="center" tokens={{ padding: "16px 16px 12px 24px", childrenGap: 4  }}>
                <Stack.Item grow>
                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                        <Icon iconName="AssessmentGroup" style={{ paddingTop: "3px"}}/>
                        <Text block nowrap style={{ maxWidth: 160, color: theme.palette.black }}>{strings.TRANSACTION.RESULT.MANUAL_GRADING.SELECTED_GRADE}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text block nowrap variant="mediumPlus" style={{ color: SharedColors.cyanBlue10, fontWeight: "510" }}>{props.gradeName}</Text>
                </Stack.Item>
            </Stack>
            <Stack.Item style={{ paddingLeft: 12, paddingRight: 12 }}>
                <div style={{ borderTop: `0.5px solid ${theme.palette.neutralQuaternary}` }} />
            </Stack.Item>
            <Stack horizontal grow>
                <Stack tokens={{ padding: "16px 16px 16px 24px", childrenGap: 4 }}>
                    <StackItem grow>
                        <Text className={styles.label}>{`${strings.CONSTRUCTOR.GRADES.IMAGE}:`} </Text>
                        <Text className={styles.value}>{props.imageName}</Text>
                    </StackItem>
                    {props.imageUrl && 
                        <Stack grow style={{ paddingTop: 15 }}>
                            <Image src={props.imageUrl} alt="" imageFit={ImageFit.contain} width={300} />
                        </Stack>
                    }
                </Stack>
                <Separator vertical />
                <Stack grow tokens={{ childrenGap: 6 }} style={{ paddingTop: 16, paddingLeft: 16}}>
                    <Stack horizontal>
                        <Stack.Item styles={{ root: { minWidth: 100, maxWidth: 100 } }}>
                            <Text className={styles.label}>{`${strings.CONSTRUCTOR.GRADES.CODE}:`}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { maxWidth: 480 } }}>
                            <Text className={styles.value}>{props.result}</Text>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal style={{ paddingTop: 12 }}>
                        <Stack.Item styles={{ root: { minWidth: 100, maxWidth: 100 } }}>
                            <Text className={styles.label}>{`${strings.CONSTRUCTOR.GRADES.NAME}:`}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { maxWidth: 480 } }}>
                            <Text className={styles.value}>{props.gradeName}</Text>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal style={{ paddingTop: 12 }}>
                        <Stack.Item styles={{ root: { minWidth: 100, maxWidth: 100 } }}>
                            <Text className={styles.label}>{`${strings.CONSTRUCTOR.GRADES.EXPLANATION}:`}</Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { maxWidth: 480 } }}>
                            <Text className={styles.value}>{props.gradeExample}</Text>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();

const styles = mergeStyleSets({
    label: [
        theme.fonts.smallPlus,
        {
            color: theme.palette.black,
            fontWeight: 500
        }
    ],
    value: [
        theme.fonts.smallPlus,
        {
            color: SharedColors.gray30
        }
    ]
})
