import React, {FC, useEffect} from 'react';
import {FontWeights, getTheme, IconButton, mergeStyleSets, Modal, PrimaryButton, Stack, Text} from "@fluentui/react";
import {SecondaryButton} from "../../../../buttons/SecondaryButton";
import {strings} from "../../../../../../localization/strings";
import {Controller, useForm} from "react-hook-form";
import {onRenderLabel} from "../../../../../renders/onRenderLabel";
import {ParameterText} from "../../../components/ParameterText";

const theme = getTheme();

const modalStyles = mergeStyleSets({
    containerModule: {
        borderRadius: 4
    },
    container: {
        flex: '1 1 auto',
        alignItems: 'stretch',
        borderRadius: 6
    },
    header: [
        theme.fonts.xLarge,
        {
            color: theme.palette.neutralPrimary,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 0 0',
        },
    ],
    body: {
        fontSize: 10,
        position: "relative",
        overflowY: 'hidden'
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

interface IProps {
    isOpen: boolean,
    hideModal: () => void,
    setValue: (text?: string) => void
    initialValue?: string
    title?: string
}

const TITLE_MAX_LENGTH = 25

const RenameModal: FC<IProps> = ({isOpen, hideModal, setValue, initialValue = '', title = strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RENAME_MODAL.TITLE}) => {

    const {reset, control, getValues, formState: {errors, isDirty, isValid}} = useForm({defaultValues: {label: ''}});
    useEffect(() => {
        reset({label: initialValue})
    }, [initialValue])

    return (
        <Modal isOpen={isOpen} onDismiss={hideModal}>
            <Stack verticalFill tokens={{ padding: "0px 24px 0", childrenGap: 16 }}>
                <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
                    <Stack verticalAlign="center">
                        <Text variant="xLarge" style={{fontWeight: 600}}>{title}</Text>
                    </Stack>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{iconName: 'Cancel'}}
                        ariaLabel="Close"
                        onClick={hideModal}
                    />
                </Stack>
                <Stack.Item>
                    <Text style={{
                        color: theme.palette.neutralPrimary
                    }}>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RENAME_MODAL.CUSTOMIZE_MESSAGE}</Text>
                </Stack.Item>
                <Stack.Item verticalFill>
                    <Controller
                        control={control}
                        name={"label"}
                        rules={{
                            required: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.REQUIRED_LABEL,
                        }}
                        render={({field}) =>
                            <ParameterText
                                required
                                value={field.value}
                                onChange={field.onChange}
                                label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RENAME_MODAL.TEXT_FIELD.TITLE}
                                placeholder={field.value}
                                errorMessage={errors.label?.message}
                                style={{width: 500}}
                                maxLength={TITLE_MAX_LENGTH}
                                onRenderLabel={onRenderLabel}
                            />
                        }
                    />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{childrenGap: 8, padding: 20}}>
                        <SecondaryButton onClick={hideModal}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                        <PrimaryButton
                            disabled={!isDirty || !isValid}
                            onClick={() => {
                                setValue(getValues().label)
                            }
                            }>{strings.BUTTONS.TEXT.SAVE}</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Modal>
    );
};

export default RenameModal;
