import { Stack, Icon, SharedColors, getTheme, Text } from "@fluentui/react"

type TBarcodeProps = {
    title?: string
    label: string
    showBarcodes?: () => void
}

export const BarCode: React.FC<TBarcodeProps> = (props) => {
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item grow styles={{ root: { paddingRight: 18 } }}>
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <Stack onClick={props.showBarcodes} styles={{
                        root: {
                            padding: 16,
                            color: theme.palette.black,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: theme.palette.neutralQuaternaryAlt,
                            backgroundColor: theme.palette.white,
                            position: "relative",
                            ":hover": props.showBarcodes ? {
                                cursor: "pointer",
                                borderColor: theme.palette.themePrimary,
                                backgroundColor: theme.palette.themeLighterAlt
                            } : undefined
                        }
                    }}>
                        <Stack horizontal verticalAlign="center" >
                            <Stack grow>
                                <Stack.Item>
                                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                                        <Text styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{props.title ? props.title : props.label}</Text>
                                    </Stack>
                                </Stack.Item>
                                {props.title && (
                                    <Stack.Item>
                                        <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>{props.label}</Text>
                                    </Stack.Item>
                                )}
                            </Stack>
                            <Icon style={{fontSize: 24, color: SharedColors.gray30}} iconName="GenericScan" />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
