import {
    Stack, getTheme, Separator, Text,
    PrimaryButton, SharedColors, AnimationStyles,
    FontIcon, DirectionalHint, IconButton,
    TooltipDelay, TooltipHost, ICalloutContentStyles,
    MessageBar, MessageBarType, DefaultButton
} from "@fluentui/react"
import { useId } from "@uifabric/react-hooks";
import React from "react";
import { IResultConfig } from "@piceasoft/core";
import { strings } from "../../../../../../localization/strings";
import { BarCode } from "../../../../barCode/BarCode";
import Device from "../../preview/Device";

type TProps = {
    config: IResultConfig
    defaultNextText: string
    transactionNumber?: string
    transaction: string
    device: string
    sku: string
    sn: string
    imei: string
}

export const ResultStagePreview: React.FC<TProps> = ({ config, ...props }) => {

    const tooltipId = useId('tooltip');

    return (
        <Stack style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} >
            <Stack.Item tokens={{ padding: 24 }}>
                <Stack style={{ backgroundColor: theme.palette.neutralLighter, boxShadow: theme.effects.elevation64 }} >
                    <Stack.Item>
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }} styles={{ root: { padding: "4px 16px 4px 24px", backgroundColor: theme.palette.white } }}>
                            <Stack.Item grow>
                                <Stack horizontal verticalAlign="center" styles={{ root: { height: 56 } }}>
                                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                        <FontIcon iconName="Sprint" style={{ fontSize: 24, height: 24, width: 24 }} />
                                        <Separator vertical />
                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                            <Text variant="mediumPlus" style={{ color: SharedColors.gray30 }}>{props.transactionNumber}</Text>
                                            <TooltipHost
                                                calloutProps={{ styles: tooltipCalloutStyles }}
                                                content={strings.BUTTONS.TEXT.COPY}
                                                delay={TooltipDelay.medium}
                                                id={tooltipId}
                                                directionalHint={DirectionalHint.bottomCenter}
                                            >
                                                <IconButton iconProps={{ iconName: 'Copy' }} ariaLabel="Copy" />
                                            </TooltipHost>
                                        </Stack>
                                        <Separator vertical />
                                        <Device />
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal verticalFill verticalAlign='center' tokens={{ childrenGap: 16 }}>
                                    <Stack.Item>
                                        <PrimaryButton>{config.ui?.buttons?.next?.text ?? props.defaultNextText}</PrimaryButton>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                        {config.ui?.warning && (
                            <MessageBar messageBarType={MessageBarType.warning}>
                                {config.ui?.warning}
                            </MessageBar>
                        )}
                        <Stack tokens={{ childrenGap: 32, padding: 32 }} styles={{ root: { ...AnimationStyles.slideUpIn10 } }} >
                            <Stack tokens={{ childrenGap: 16 }}>
                                <Stack horizontalAlign="center">
                                    <Text variant="xLarge">{config?.ui?.header ?? strings.CONSTRUCTOR.STAGES.RESULT.HEADER_TRANSACTION_COMPLETED}</Text>
                                </Stack>
                                <Stack horizontalAlign="center" tokens={{ childrenGap: 16 }}>
                                    <Stack.Item style={{ color: SharedColors.gray40 }}>
                                        <div dangerouslySetInnerHTML={{ __html: config?.ui?.information ?? strings.CONSTRUCTOR.STAGES.RESULT.INFORMATION_TRANSACTION_COMPLETED }}></div>
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontalAlign="center" horizontal tokens={{ childrenGap: 16 }}>
                                    <Stack.Item style={{ width: 200 }}>
                                        <BarCode title="" label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.BARCODES_TITLE} />
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontalAlign="center" tokens={{ childrenGap: 24 }}>
                                    {config?.ui?.secondaryInformation && (
                                        <Stack.Item>
                                            <Text variant="small">{config.ui.secondaryInformation}</Text>
                                        </Stack.Item>
                                    )}
                                    {config?.contractButton && (
                                        <Stack.Item>
                                            <DefaultButton iconProps={{ iconName: config.contractButton.icon ?? "Print" }}>
                                                {config.contractButton.text ?? strings.CONSTRUCTOR.STAGES.RESULT.DOWNLOAD_PRINT_FORM}
                                            </DefaultButton>
                                        </Stack.Item>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const tooltipCalloutStyles: Partial<ICalloutContentStyles> = {
    beakCurtain: { background: theme.palette.neutralLight },
    beak: { background: theme.palette.neutralLight },
    calloutMain: { background: theme.palette.neutralLight },
    root: { color: theme.palette.white },
    container: { color: 'white' }
}