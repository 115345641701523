import { getTheme, SharedColors, Stack, Text, Image, ImageFit, StackItem, Icon } from "@fluentui/react";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../core/scripts/style";
import defaultServiceIcon from '../../assets/service.svg'
import { Tooltip } from "../../components/shared/help/Tooltip";
import { strings } from "../../localization/strings";

type TServiceProps = {
    name: string
    code?: string
    generateIcon?: boolean
    imageSrc?: string
    isValid?: boolean
}

export const Service: React.FC<TServiceProps> = (props) => {
    return (
        <Stack.Item>
            <Stack horizontal>
                <Stack horizontal verticalAlign='center' verticalFill style={{ width: 600 }} tokens={{ childrenGap: 12 }} >
                    <Stack.Item>
                        <Stack verticalAlign="center"
                            horizontalAlign="center"
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: theme.effects.roundedCorner6,
                                backgroundColor: !props.imageSrc && props.generateIcon ?
                                    (props.name ?
                                        getPersonaColorsPallete[props.name[0].toLowerCase()] :
                                        undefined) :
                                    undefined
                            }}>
                            {props.imageSrc && (
                                <Stack horizontalAlign='center'>
                                    <Image src={props.imageSrc} width={40} height={40} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                </Stack>
                            ) || (props.generateIcon && (
                                <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                                    {getIconSymbolsFromString(props.name)}
                                </Text>
                            ) || (
                                    <Stack horizontalAlign='center'>
                                        <Image src={defaultServiceIcon} width={40} height={40} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                    </Stack>
                                )
                                )}
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow style = {{overflowX:'auto'}}>
                        <Stack horizontal tokens={{ childrenGap: 12 }}>
                            <Stack>
                                <Stack.Item>
                                    <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{props.name}</Text>
                                </Stack.Item>
                                {props.code && (
                                    <Stack.Item>
                                        <Text variant="small" style={{ color: SharedColors.gray30 }}>{props.code}</Text>
                                    </Stack.Item>
                                )}
                            </Stack>
                            {!props.isValid && (
                                <Stack verticalAlign="center">
                                    <Tooltip content={strings.SERVICES.WARNING_SERVICES}>
                                        <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                                    </Tooltip>
                                </Stack>
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack.Item>
    )
}

const theme = getTheme();