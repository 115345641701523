import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import Layout from '../components/layout/Layout'
import { AllRoles, IStore } from '../core/store'
import { FinanceReports } from '../screens/finance/FinanceReports'
import Login from '../screens/login'
import { OverviewModerations } from '../screens/main/OverviewModerations'
import { OverviewTransactions } from '../screens/main/OverviewTransactions'
import { OrganizationAccess } from '../screens/organization/OrganizationAccess'
import { OrganizationCompanies } from '../screens/organization/OrganizationCompanies'
import { OrganizationCompany } from '../screens/organization/OrganizationCompany/OrganizationCompany'
import { OrganizationStructure } from '../screens/organization/OrganizationStructure/OrganizationStructure'
import { ServicesConfiguration } from '../screens/services/ServicesConfiguration'
import { ServicesPrintTemplates } from '../screens/services/ServicesPrintTemplates'
import { FinanceCatalogs } from '../screens/finance/FinanceCatalogs'
import { Settings } from '../screens/settings/Settings'
import { FinancePromotions } from '../screens/finance/FinancePromotions'
import { ResponsiblePersons } from '../screens/organization/ResponsiblePersons'
import { OrganizationAccount } from '../screens/organization/OrganizationAccount'
import { ServiceConfiguration } from '../screens/services/Service/ServiceConfiguration'
import { ServicesPrintTemplate } from '../screens/services/Template/ServicesPrintTemplate'
import { CompanyPoint } from '../screens/organization/OrganizationCompany/CompanyDetails/pivots/CompanyPoint'
import { LogisticsWarehouse } from '../screens/logistics/LogisticsWarehouse'
import { LogisticsDelivery } from '../screens/logistics/LogisticsDelivery'
import { AccountRoles } from '../core/store/typings/AccountRoles'
import { UserRoles } from '../core/store/typings/UserRoles'
import { AdministrationSupervisors } from '../screens/administration/Supervisors/AdministrationSupervisors'
import { Supervisor } from '../screens/administration/Supervisors/Supervisor'
import { ExternalModeration } from '../components/shared/moderation/ExternalModeration'
import { UserSettings } from '../screens/userSettings/UserSettings'
import { OrganizationBranding } from '../screens/organization/branding/OrganizationBranding'
import { ValidationApi } from '../screens/validationapi/ValidationApi'
import { SelfServicePromotions } from '../screens/SelefServicePromotions/SelfServicePromotions'

type TProps = {
    isAuthenticated: boolean
}

const MainRouter: React.FC<TProps> = (props) => {

    const roles = useSelector((s: IStore) => s.environment.roles) ?? []
    const allowedRoutes = getAllowedNavigation(roles);
    const allowedPaths = [...allowedRoutes.map(ar => ar.path)]

    return (
        <>
            <Switch>
                <Route exact path={[...allowedPaths]}>
                    {props.isAuthenticated ? <Layout>
                        {allowedRoutes.map(r => {
                            // if (r.path === '/organization/companies/:companyId?') {
                            //     return <Route path={r.path} component={r.component} />
                            // }
                            return <Route key={r.path} exact path={r.path} component={r.component} />
                        })}
                    </Layout>
                        : <Route render={(props) => {
                            const search = props.location.search
                            const params = new URLSearchParams(search)
                            const domain = params.get('domain')
                            return (
                                <Login domain={domain ?? undefined} />
                            )
                        }} />}
                </Route>
                <Route exact path={['/moderation/:transactionUid/:moderatorHash']}>
                    <Route exact path='/moderation/:transactionUid/:moderatorHash' render={(props) => {
                        const search = props.location.search
                        const params = new URLSearchParams(search)
                        const isRequest = params.get('isRequest')
                        const isDecline = params.get('isDecline')
                        const lang = params.get('lang')
                        return <ExternalModeration
                            transactionUid={props.match.params.transactionUid}
                            moderatorHash={props.match.params.moderatorHash}
                            lang={String(lang) ?? undefined} isRequest={Boolean(isRequest) ?? undefined}
                            isDecline={Boolean(isDecline) ?? undefined}
                        />
                    }} />
                </Route>
                <Redirect from="/" to={allowedPaths[0]} />
            </Switch>
        </>
    )
}

export default MainRouter

const portalPaths = [
    // { path: '/', component: OverviewTransactions, roles: [UserRoles.All] },
    { path: '/overview/transactions', component: OverviewTransactions, roles: [UserRoles.All] },
    { path: '/overview/transactions/:transactionUid', component: OverviewTransactions, roles: [UserRoles.All] },
    { path: '/overview/moderations', component: OverviewModerations, roles: [UserRoles.All] },
    // { path: '/overview/store', component: OverviewStore, roles: [UserRoles.All] },
    { path: '/organization/structure', component: OrganizationStructure, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.All] },
    { path: '/organization/companies', component: OrganizationCompanies, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor] },
    { path: '/organization/companies/:companyId', component: OrganizationCompany, roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor, AccountRoles.Master, AccountRoles.Manager] },
    { path: '/organization/companies/:companyId/points/:pointId', component: CompanyPoint, roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor, AccountRoles.Master, AccountRoles.Manager] },
    { path: '/organization/responsible', component: ResponsiblePersons, roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor, AccountRoles.Master, AccountRoles.Manager] },
    // { path: '/organization/responsible/:responsiblePersonId', component: ResponsiblePerson, roles: [UserRoles.Organization, UserRoles.Administrator, UserRoles.Trader] },
    { path: '/organization/access', component: OrganizationAccess, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/organization/branding', component: OrganizationBranding, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    // { path: '/organization/branding/:brandingId', component: BrandingDetails , roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/organization/access/:accountId', component: OrganizationAccount, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.Supervisor] },
    { path: '/services/configuration', component: ServicesConfiguration, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/services/configuration/:serviceId', component: ServiceConfiguration, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/services/print', component: ServicesPrintTemplates, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/services/print/:templateId', component: ServicesPrintTemplate, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/services/catalogs', component: FinanceCatalogs, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/services/promotions', component: FinancePromotions, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/services/selfservicepromotions', component: SelfServicePromotions, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Manager, UserRoles.Supervisor] },
    { path: '/finance/reports', component: FinanceReports, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Statistic, UserRoles.Supervisor] },
    { path: '/logistics/delivery', component: LogisticsDelivery, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Statistic, UserRoles.Supervisor] },
    { path: '/logistics/warehouse', component: LogisticsWarehouse, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, AccountRoles.Statistic, UserRoles.Supervisor] },
    { path: '/settings', component: Settings, roles: [UserRoles.Organization, UserRoles.Administrator, AccountRoles.Master, UserRoles.Trader, UserRoles.Supervisor] },
    { path: '/userSettings', component: UserSettings, roles: [UserRoles.All] },
    { path: '/administration/supervisors', component: AdministrationSupervisors, roles: [UserRoles.Administrator] },
    { path: '/administration/supervisors/:supervisorId', component: Supervisor, roles: [UserRoles.Administrator] },
    { path: '/administration/validation', component: ValidationApi, roles: [UserRoles.Administrator] },
    // { path: '/documentation', component: Documentation, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.All] },
   // { path: '/swagger', component: Documentation, roles: [UserRoles.Organization, AccountRoles.Master, UserRoles.Administrator, UserRoles.Trader, UserRoles.All] },

]

export const getAllowedNavigation = (roles: Array<AllRoles>): Array<{ path: string, component: React.FC }> => {
    return portalPaths.filter(p => {
        let result = false
        if (p.roles.includes(UserRoles.All)) {
            return true;
        }
        roles.map(r => {
            if (p.roles.includes(r)) {
                result = true;
            }
        })
        return result;
    }).map(pr => { return { path: pr.path, component: pr.component } })
}

