import { Stack, Text, Separator, SharedColors, PrimaryButton, TextField } from "@fluentui/react"
import { strings } from "../../../../../../localization/strings"
import { IIdentificationImeiConfig } from "@piceasoft/core"
import IdentificationHeader from "./ui/IdentificationHeader"
import { TooltipHost, css, mergeStyleSets, TooltipOverflowMode} from "@fluentui/react"
import { identificationContainerStyle, hasBlockLevelElements, blockOffset } from "../helpers/identificationContainer"

type TProps = {
    config: IIdentificationImeiConfig
}


export const Imei: React.FC<TProps> = (props) => {

    return (
        <Stack horizontalAlign="stretch" verticalFill>
            <Stack.Item>
                <IdentificationHeader
                    icon={props.config?.ui?.icon ?? "NumberField"}
                    title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.IMEI_TITLE}
                />
            </Stack.Item>
                <Separator />
            <Stack verticalFill tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                <TooltipHost
                    overflowMode={TooltipOverflowMode.Self}
                    content={props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION}
                    hostClassName={css(identificationContainerStyle().body)}
                    styles={{ root: {display: 'block'}}}>
                    <Text style={{ color: SharedColors.gray40 }}>
                        <div 
                            style={hasBlockLevelElements(props.config?.ui?.information) ? {marginTop: `${blockOffset}`} : {marginTop: '0px'}}
                            dangerouslySetInnerHTML={{ __html: props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.IMEI_INFORMATION }}>
                        </div>
                    </Text>
                </TooltipHost>
                </Stack.Item>
                <Stack.Item grow={1} verticalFill>
                    <Stack horizontalAlign="stretch">
                        <Stack.Item>
                            <TextField
                                placeholder={props.config?.ui?.imei?.placeholder ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PLACEHOLDER_IMEI}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item tokens={{ padding: "16px 0px 0px 0px" }} grow>
                    <Stack horizontalAlign="stretch">
                        <PrimaryButton text={strings.BUTTONS.TEXT.CONFIRM}></PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}
