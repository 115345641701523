import { ScrollablePane, SharedColors, Stack } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import React from "react"
import { portalApi } from "../../../core/api/api"
import { endpoints } from "../../../core/api/endpoints"
import { ICatalog, ICatalogVersion } from "../../../core/store"
import { strings } from "../../../localization/strings"
import { FinanceCatalogDetails } from "./FinanceCatalogDetails"
import { FinanceCatalogHeader } from "./FinanceCatalogHeader"
import { ImportModal } from "./ImportModal"

type TProps = {
    data: ICatalog
    onDelete: (catalog: ICatalog) => void
    onEdit: (catalog: ICatalog) => void
    retryInfo: () => void
}

export type ICatalogVersionsState = { fetched: boolean, data?: ICatalogVersion[], error?: string }

export const FinanceCatalog: React.FC<TProps> = (props) => {

    const [versionsState, setVersionsState] = React.useState<ICatalogVersionsState>({ fetched: false })

    const [selectedVersionId, setSelectedVersionId] = React.useState<string>()

    const [fromDate, setNewCatalogVersionFromDate] = React.useState<Date>();
    const [fromTimeHour, setNewCatalogVersionFromTimeHour] = React.useState(0);
    const [fromTimeMin, setNewCatalogVersionFromTimeMin] = React.useState(0);
    const [fromDateTime, setFromDateTime] = React.useState<Date>();

    const [summary, setNewCatalogVersionSummary] = React.useState<string>();

    const [isImportModalShowed, { setTrue: showImportModal, setFalse: hideImportModal }] = useBoolean(false)

    React.useEffect(() => {
        getCatalogVersions()
        console.log(props.data)
    }, [])

    React.useEffect(() => {
        if (!isImportModalShowed) {
            setNewCatalogVersionFromDate(undefined)
            setNewCatalogVersionFromTimeHour(0)
            setNewCatalogVersionFromTimeMin(0)
            setFromDateTime(undefined)
            setNewCatalogVersionSummary(undefined)
        }
    }, [isImportModalShowed])

    React.useEffect(() => {
        const currentDate = new Date();
        if (fromDate?.getFullYear() === currentDate.getFullYear() &&
            fromDate.getMonth() === currentDate.getMonth() &&
            fromDate.getDate() === currentDate.getDate()
        ) {
            if (fromTimeHour < currentDate.getHours()) {
                setNewCatalogVersionFromTimeHour(currentDate.getHours())
            }
            if (fromTimeHour === currentDate.getHours() && fromTimeMin < currentDate.getMinutes()) {
                setNewCatalogVersionFromTimeMin(currentDate.getMinutes() + 1)
            }
        }
        if (fromDate) {
            setFromDateTime(
                new Date(
                    Date.UTC(
                        (fromDate)?.getFullYear(),
                        (fromDate)?.getMonth(),
                        (fromDate)?.getDate(),
                        fromTimeHour + new Date().getTimezoneOffset() / 60,
                        fromTimeMin
                    )
                )
            )
        }
    }, [fromDate, fromTimeHour, fromTimeMin])

    const getCatalogNewVersion = (): ICatalogVersion => {
        return { catalogId: props.data.id, fromDate: fromDateTime ?? null, summary: summary ?? '', removed: false, catalogType: props.data.catalogType }
    }

    const getCatalogVersions = async () => {
        setVersionsState({ fetched: false })
        if (props.data.id) {
            const result = await portalApi.organization.catalogs.getCatalogVersions(props.data.id)
            console.log(result)
            setVersionsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
        }
    }

    const onExportHandler = async () => {
        if (props.data.id && selectedVersionId) {
            window.open(endpoints.v1.organization.catalogExportedVersionItems(props.data.id, selectedVersionId));
        }
    }

    return (
        <Stack verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Stack verticalFill>
                    <FinanceCatalogHeader onEdit={props.onEdit} item={props.data} onDelete={props.onDelete} onImport={showImportModal} onExport={onExportHandler} />
                    <Stack.Item verticalFill>
                        <FinanceCatalogDetails retryInfo={props.retryInfo} data={props.data} onDelete={props.onDelete} versionsState={versionsState} onImport={showImportModal} setSelectedVersionId={setSelectedVersionId}
                        />
                    </Stack.Item>
                </Stack>
            </ScrollablePane>
            {isImportModalShowed && (
                <ImportModal
                    type="icon"
                    src='ExcelDocument'
                    show={isImportModalShowed}
                    onHide={hideImportModal}
                    acceptTypeMask={".xlsx"}
                    isValid={fromDateTime ? true : false}
                    onSuccessed={() => props.retryInfo()}
                    iconColor={SharedColors.green10}
                    title={strings.ORGANIZATION.CATALOGS.IMPORT_FROM_EXCEL.TITLE}
                    sendMethod={(file, setResponse, setUploadProgress) => portalApi.organization.catalogs.importCatalogVersion(getCatalogNewVersion(), file, setResponse, setUploadProgress)}
                    fromDate={fromDate}
                    fromTimeHour={fromTimeHour}
                    fromTimeMin={fromTimeMin}
                    summary={summary}
                    handleDateSelect={setNewCatalogVersionFromDate}
                    handleTimeHourSelect={setNewCatalogVersionFromTimeHour}
                    handleTimeMinSelect={setNewCatalogVersionFromTimeMin}
                    onChangeSummary={setNewCatalogVersionSummary}
                />
            )}
        </Stack>
    )
}
