import { SupportedDiagnosticCases, definitions, PiceaChecks, DiagnosticSets, VerifyStatuses  } from "@piceasoft/core";
import { strings } from "../../localization/strings";
import { getEnumValidNumberKeys, range } from "../helpers/common";

export const setsDescriber = (id?: number) : string => {

    if (id == undefined) return ""

    return definitions.diagnostic.setDescriber(id, "name") ?? id?.toString() ?? ""
}

export const testDescriber = (id?: number) => {

    if (id == undefined) return ""

    return definitions.diagnostic.caseDescriber(id, "name") ?? id?.toString() ?? ""
}

export function verifyDescriber(check: number): string {
    switch (check) {
        case PiceaChecks.Accounts: return strings.PICEA.VERIFY.CHECKS.ACCOUNTS as string;
        case PiceaChecks.Google: return strings.PICEA.VERIFY.CHECKS.GOOGLE_SECURITY as string;
        case PiceaChecks.DEP: return strings.PICEA.VERIFY.CHECKS.DEVICE_ENROLLMENT as string;
        case PiceaChecks.FindMy: return strings.PICEA.VERIFY.CHECKS.FIND_IPHONE as string;
        case PiceaChecks.GSMA: return strings.PICEA.VERIFY.CHECKS.GSMA_DEVICE_CKECK as string;
        case PiceaChecks.Genuineness: return strings.PICEA.VERIFY.CHECKS.AUTHENTICATION as string;
        case PiceaChecks.GoogleAccount: return strings.PICEA.VERIFY.CHECKS.GOOGLE_ACCOUNT as string;
        case PiceaChecks.Jailbreack: return strings.PICEA.VERIFY.CHECKS.JAILBRAKE as string;
        case PiceaChecks.MemoryCard: return strings.PICEA.VERIFY.CHECKS.SD_CARD as string;
        case PiceaChecks.Rooted: return strings.PICEA.VERIFY.CHECKS.ROOT as string;
        case PiceaChecks.SecurityLock: return strings.PICEA.VERIFY.CHECKS.SECURITY_LOCK as string;
        case PiceaChecks.SimCard: return strings.PICEA.VERIFY.CHECKS.SIM_CARD as string;
        case PiceaChecks.XiaomiAccount: return strings.PICEA.VERIFY.CHECKS.XIAOMI_ACCOUNT as string;
        case PiceaChecks.eSIM: return strings.PICEA.VERIFY.CHECKS.ESIM as string;
        case PiceaChecks.CarrirerLocked: return strings.PICEA.VERIFY.CHECKS.OPERATOR_LOCK as string;
        case PiceaChecks.Battery: return strings.PICEA.VERIFY.CHECKS.BATTERY_GENUINE as string;
        case PiceaChecks.Warranty: return strings.PICEA.VERIFY.CHECKS.WARRANTY_STATUS as string;
        case PiceaChecks.SamsungAccount: return strings.PICEA.VERIFY.CHECKS.SAMSUNG_ACCOUNT as string;
        case PiceaChecks.SamsungKnox: return strings.PICEA.VERIFY.CHECKS.SAMSUNG_KNOX as string;
        case PiceaChecks.HuaweiAccount: return strings.PICEA.VERIFY.CHECKS.HUAWEI_ACCOUNT as string;
        default: return strings.PICEA.VERIFY.CHECKS.UNKNOWN_CHECK as string;
    }
}

export const verifyStatusDescriber = (id: number) => {
    switch (id) {
        case VerifyStatuses.VerifyStatus_Unknown: return strings.PICEA.VERIFY.STATUSES.UNKNOWN_STATUS as string;
        case VerifyStatuses.VerifyStatus_Passed: return strings.PICEA.VERIFY.STATUSES.SUCCESS as string;
        case VerifyStatuses.VerifyStatus_Unexecuted: return strings.PICEA.VERIFY.STATUSES.NOT_PERFORMED as string;
        case VerifyStatuses.VerifyStatus_eSIMSupported: return strings.PICEA.VERIFY.STATUSES.E_SIM_UNKNOWN_STATUS as string;
        case VerifyStatuses.VerifyStatus_NotSupported: return strings.PICEA.VERIFY.STATUSES.NOT_SUPPORTED as string;
        case VerifyStatuses. VerifyStatus_SecurityLockEnabled: return strings.PICEA.VERIFY.STATUSES.LOCK_DETECTED as string;
        case VerifyStatuses.VerifyStatus_MemoryCardInserted: return strings.PICEA.VERIFY.STATUSES.MEMORY_CARD_FOUND as string;
        case VerifyStatuses.VerifyStatus_SIMCardInserted: return strings.PICEA.VERIFY.STATUSES.SIM_CARD_FOUND as string;
        case VerifyStatuses.VerifyStatus_GoogleProtectionEnabled: return strings.PICEA.VERIFY.STATUSES.GOOGLE_SECURE_DETECTED as string;
        case VerifyStatuses.VerifyStatus_FindMyEnabled: return strings.PICEA.VERIFY.STATUSES.FIND_MY_IPHONE_ACTIVATED as string;
        case VerifyStatuses.VerifyStatus_DeviceStolen: return strings.PICEA.VERIFY.STATUSES.STOLEN_DEVICE as string;
        case VerifyStatuses.VerifyStatus_AccountsFound: return strings.PICEA.VERIFY.STATUSES.ACOUNTS_DETECTED as string;
        case VerifyStatuses.VerifyStatus_DeviceStolenWarning: return strings.PICEA.VERIFY.STATUSES.SUSPECT_DEVICE as string;
        case VerifyStatuses.VerifyStatus_DeviceRooted: return strings.PICEA.VERIFY.STATUSES.ROOTED_DEVICE as string;
        case VerifyStatuses.VerifyStatus_XiaomiMiAccountEnabled: return strings.PICEA.VERIFY.STATUSES.MI_ACCOUNT_DETECTED as string;
        case VerifyStatuses.VerifyStatus_SamsungAccountEnabled: return strings.PICEA.VERIFY.STATUSES.SAMSUNG_ACCOUNT_DETECTED as string;
        case VerifyStatuses.VerifyStatus_DeviceCarrierLocked: return strings.PICEA.VERIFY.STATUSES.DEVICE_ASSIGNED_TO_OPERATOR as string;
        case VerifyStatuses.VerifyStatus_DeviceIsNotGenuine: return strings.PICEA.VERIFY.STATUSES.DEVICE_GENUINE_PROBLEM as string;
        case VerifyStatuses.VerifyStatus_DeviceEsimActivated: return strings.PICEA.VERIFY.STATUSES.E_SIM_ACTIVATION_DETECTED as string;
        case VerifyStatuses.VerifyStatus_DeviceBatteryNotGenuine: return strings.PICEA.VERIFY.STATUSES.DEVICE_BATTERY_GENUINE_PROBLEM as string;
        case VerifyStatuses.VerifyStatus_StolenCheckFailure: return definitions.verify.statusDescriber(id, "name") ?? ""; //strings.PICEA.VERIFY.STATUSES.STOLEN_CHECK_FAILURE as string;
        case VerifyStatuses.VerifyStatus_MDMProfileInstallNeeded: return strings.PICEA.VERIFY.STATUSES.MDM_NOT_INSTALLED as string;
        case VerifyStatuses.VerifyStatus_WarrantyPeriodOver: return strings.PICEA.VERIFY.STATUSES.WARRANY_EXPIRED as string;
        case VerifyStatuses.VerifyStatus_DeviceDepEnabled: return strings.PICEA.VERIFY.STATUSES.DEP_DETECTED as string;
        case VerifyStatuses.VerifyStatus_DeviceImeiNeeded: return strings.PICEA.VERIFY.STATUSES.NO_DEVICE_IMEI as string;
        case VerifyStatuses.VerifyStatus_DeviceJailbroken: return strings.PICEA.VERIFY.STATUSES.JAILBRAKE_DETECTED as string;
        case VerifyStatuses.VerifyStatus_GoogleAccountFound: return strings.PICEA.VERIFY.STATUSES.GOOGLE_ACCOUNT_DETECTED as string;
        case VerifyStatuses.VerifyStatus_HuaweiAccountEnabled: return strings.PICEA.VERIFY.STATUSES.HUAWEI_ACCOUNT_DETECTED as string;
        case VerifyStatuses.VerifyStatus_FailedMask: return strings.PICEA.VERIFY.STATUSES.MASK_ERROR as string;
        case VerifyStatuses.VerifyStatus_KnoxFrameworkFound: return strings.PICEA.VERIFY.STATUSES.KNOX_ENABLED as string;
        case VerifyStatuses.VerifyStatus_ZeroTouchDetected: return strings.PICEA.VERIFY.STATUSES.ZERO_TOUCH_ENABLED as string;
        case VerifyStatuses.VerifyStatus_StolenCheckLegal: return strings.PICEA.VERIFY.STATUSES.STOLEN_CHECK_LEGAL as string;

        default: return id.toString();
    }
}

export const getPiceaSetCasesIndexRange = (set: DiagnosticSets):number[] => {
    return getEnumValidNumberKeys(SupportedDiagnosticCases, range(16, set))
}

export const getPiceaSetIdByCaseId = (caseId: number): number | undefined => {
    const setsTo = getEnumValidNumberKeys(DiagnosticSets, range(caseId + 1, 0))

    if (setsTo.length > 0) {
        return Math.max(...setsTo)
    }
}
