import { Control, Controller } from "react-hook-form"
import { getCommonFieldRules } from "../../../helpers/validationRules"
import Operator from "./Operator"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import { TContractData } from "../../../../../dataCollectionTypes"

type TProps = {
    config?: IDataCollectionStandardConfig
    errorMessage: string
    control: Control<TContractData, object>,
}

const OperatorController: React.FunctionComponent<TProps> = (props: TProps) => {
    let {config, control, errorMessage} = props

    return (
        <Controller
            name="operator"
            control={control}
            rules={getCommonFieldRules(config?.fields?.operator)}
            render={({ field }) =>
                <Operator
                    config={config}
                    value={field.value}
                    errorMessage={errorMessage}
                    handleOnBlur={field.onBlur}
                    handleOnChange={(value?: string) => field.onChange(value)}
                />
            }
        />
    )
}

export default OperatorController