import {
    Stack, Toggle, TextField, Text, getTheme, Link,
    Dropdown, IDropdownOption, IDropdownProps, ITextFieldProps, SharedColors, TooltipHost,
    DirectionalHint, Icon, DefaultButton, Image, ImageFit, TooltipOverflowMode, mergeStyleSets, css
} from "@fluentui/react";
import React from "react";
import { strings } from "../../../../localization/strings";
import processingSvg from "../../../../assets/progressing.svg"
import { onRenderLabel } from "../../../renders/onRenderLabel";

type TProps = {
    useToggle?: boolean
    toggleOnText?: string
    toggleOffText?: string
    toggleEnabled?: boolean
    toggleChecked?: boolean
    toggleOnChange?: () => void
    useTextField?: boolean
    textFieldMultiLimitSymbolsCount?: number
    textFieldMultiline?: boolean
    textFieldLabel?: string
    textFieldPlaceholder?: string
    textFieldEnabled?: boolean
    textFieldValue?: string
    textFieldMaxLength?: number
    textFieldOnRenderLabel?: (props: ITextFieldProps | IDropdownProps | undefined) => JSX.Element
    textFieldOnChange?: (value: string) => void
    title: string
    description?: string
    useDescriptionLink?: boolean
    descriptionlinkHref?: string
    descriptionlinkText?: string
    useDropdown?: boolean
    dropdownEnabled?: boolean
    dropdownMultiselect?: boolean
    dropdownSelectedKeys?: number[] | string[]
    dropdownSelectedKey?: number | string
    dropdownLabel?: string
    dropdownPlaceholder?: string
    dropdownOptions?: IDropdownOption[]
    dropdownOnChange?: (option?: IDropdownOption) => void
    dropdownErrorMessage?: string
    errorMessage?: string
    childrenAlignStart?: boolean
    noSeparator?: boolean
    isExperimental?: boolean
    useImage?: boolean
    image_url?: string
    inputFileButtonId?: string
    addFile?: (value: FileList) => void
    onDeleteImage?: () => void
    descriptionBotom?: string
    imageEnabled?: boolean
    isImageUploading?: boolean
    imageName?: string
    renderLabel?: boolean
    isRequired?: boolean
    dropdownTooltipTitle?: string
}

export const ParameterItem: React.FC<TProps> = (props) => {
    return (
        <Stack.Item styles={{ root: { borderTop: !props.noSeparator ? `1px solid ${theme.palette.neutralLight}` : undefined } }}>
            <Stack horizontal style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }}>
                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                    {props.useToggle && (
                        <Toggle onText={props.toggleOnText ?? strings.BUTTONS.TOGGLE_ON} offText={props.toggleOffText ?? strings.BUTTONS.TOGGLE_OFF}
                            disabled={!props.toggleEnabled}
                            checked={props.toggleChecked}
                            onChange={() => props.toggleOnChange && props.toggleOnChange()}
                        />
                    )}
                </Stack.Item>
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack tokens={{ childrenGap: !props.useImage ? 16 : 0 }}>
                        <Stack styles={{ root: { maxWidth: 400 } }}>
                            {!props.useImage && (<Text style={{ fontWeight: 600, color: theme.palette.black }}>{props.title}</Text>)}
                            {props.description && !props.useImage && (
                                <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                    {props.description}
                                    {props.useDescriptionLink && (
                                        <>
                                            {" "}
                                            <Link href={props.descriptionlinkHref} target='_blank'>{props.descriptionlinkText}</Link>
                                        </>
                                    )}
                                </Text>
                            )}
                        </Stack>

                        {props.useTextField && (
                            <Stack>
                                <Stack.Item styles={{ root: { width: 320 } }}>
                                    <TextField
                                        disabled={!props.textFieldEnabled}
                                        placeholder={props.textFieldPlaceholder}
                                        onRenderLabel={props.textFieldOnRenderLabel}
                                        maxLength={props.textFieldMaxLength}
                                        label={props.textFieldLabel}
                                        multiline={props.textFieldMultiline ?? ((props.textFieldMultiLimitSymbolsCount && props.textFieldValue) ? props.textFieldValue.length > props.textFieldMultiLimitSymbolsCount : false)}
                                        value={props.textFieldValue}
                                        onChange={(ev, value) => props.textFieldOnChange && props.textFieldOnChange(value ?? "")}
                                    />

                                </Stack.Item>
                                {props.textFieldMaxLength && (
                                    <Text variant="small" style={{ color: SharedColors.gray10 }}>
                                        {strings.TRANSACTION.RESULT.INPUT_LIMIT.replace('{0}', (props.textFieldMaxLength ?? 0).toString())
                                            .replace('{0}', (props.textFieldMaxLength ?? 0).toString()).replace('{1}', (props.textFieldValue ?? '').length.toString())}
                                    </Text>)}
                                <Stack.Item>
                                    {props.descriptionBotom && (<Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                        {props.descriptionBotom}
                                    </Text>)}
                                </Stack.Item>
                            </Stack>
                        )}
                        {props.useDropdown && (
                            <Stack.Item styles={{ root: { width: 320 } }}>
                                <Dropdown
                                    onChange={(ev, opt) => props.dropdownOnChange && props.dropdownOnChange(opt)}
                                    label={props.dropdownLabel}
                                    placeholder={props.dropdownPlaceholder}
                                    options={props.dropdownOptions ?? []}
                                    multiSelect={props.dropdownMultiselect}
                                    selectedKey={props.dropdownSelectedKey}
                                    selectedKeys={props.dropdownSelectedKeys}
                                    disabled={!props.dropdownEnabled}
                                    errorMessage={props.dropdownErrorMessage ?? undefined}
                                    onRenderLabel={props.renderLabel ? onRenderLabel : undefined}
                                    title={props.dropdownTooltipTitle}
                                    required={props.isRequired}
                                />
                            </Stack.Item>
                        )}
                        {props.useImage && (
                            <Stack>
                                <Stack horizontal styles={{ root: { width: 320 } }}>
                                    <Stack.Item>
                                        {props.image_url ? <Image src={props.image_url} width={32} height={32} imageFit={ImageFit.contain} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                            : <Icon style={{ fontSize: 32 }} iconName="Photo2" />}
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontal verticalAlign="center" style={{ paddingLeft: 6 }}>
                                            {!props.isImageUploading && !props.imageName && (<DefaultButton style={{ height: 32, paddingLeft: 10 }} disabled={!props.imageEnabled} onClick={() => {
                                                const el = document.getElementById(props.inputFileButtonId ?? '')
                                                if (el) {
                                                    (el as HTMLInputElement).value = ''
                                                    el.click()
                                                }
                                            }}>{strings.ORGANIZATION.BRANDING.UPLOAD_LOGO}</DefaultButton>)}
                                            {!props.isImageUploading && props.imageName && (<TooltipHost
                                                content={props.imageName}
                                                overflowMode={TooltipOverflowMode.Self}
                                                styles={{ root: { display: 'block', paddingLeft: 10 } }}
                                                hostClassName={css(styles.title)}
                                            >
                                                {props.imageName}
                                            </TooltipHost>)}
                                            {props.isImageUploading && (
                                                <Image height={32} style={{ paddingLeft: 10 }} src={processingSvg} />
                                            )}
                                            {props.image_url &&
                                                <TooltipHost content={strings.ORGANIZATION.BRANDING.REMOVE_LOGO}
                                                    directionalHint={DirectionalHint.bottomCenter}
                                                    styles={{ root: { display: "flex", cursor: "default" } }}
                                                    tooltipProps={{
                                                        calloutProps: {
                                                            styles: {
                                                                beak: { background: theme.palette.black },
                                                                beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Link onClick={() => { if (props.onDeleteImage) { props.onDeleteImage() } }}><Icon iconName='Cancel' style={{ fontSize: 15, paddingTop: 6, paddingLeft: 10 }} /></Link>
                                                </TooltipHost>
                                            }
                                        </Stack>
                                        <input id={props.inputFileButtonId} hidden type="file" onChange={(e) => e.target.files && props.addFile && props.addFile(e.target.files)} />
                                    </Stack.Item>
                                </Stack>
                                <Stack>
                                    <Stack.Item>
                                        <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                            {props.description}
                                        </Text>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        )}
                        {!props.useDropdown && props.errorMessage && (
                            <Stack>
                                <Text style={{ fontSize: 14, color: SharedColors.red10 }}>{props.errorMessage}</Text>
                            </Stack>
                        )}
                        {!props.childrenAlignStart && props.children}
                    </Stack>
                    {props.isExperimental && (
                        <Stack.Item>
                            <TooltipHost content={strings.CONSTRUCTOR.COMMON.IS_EXPERIMENTAL_CONTENT}
                                directionalHint={DirectionalHint.topCenter}
                                styles={{ root: { cursor: "default" } }}
                                tooltipProps={{
                                    calloutProps: {
                                        styles: {
                                            beak: { background: theme.palette.black },
                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                        },
                                    },
                                }}
                            >
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }}>
                                    <Text variant="small" style={{ color: SharedColors.red10, fontWeight: 600 }}>{strings.CONSTRUCTOR.COMMON.IS_EXPERIMENTAL}</Text>
                                    <Icon style={{ color: SharedColors.red10, fontSize: 16 }} iconName="InfoSolid" />
                                </Stack>
                            </TooltipHost>
                        </Stack.Item>
                    )}
                </Stack>
            </Stack>
            {props.childrenAlignStart && props.children}
        </Stack.Item>
    )
}

const theme = getTheme();
const styles = mergeStyleSets({
    title: [
        theme.fonts.medium,
        {
            color: SharedColors.cyanBlue10,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 150,
        }
    ]
});