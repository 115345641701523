import { CommandBarButton, getTheme, Icon, IconButton, IContextualMenuProps, MessageBar, MessageBarType, SharedColors, Stack, Text, TooltipHost } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../core/actions/configurator-actions";
import { IIdentificationConfig, ServiceTypes } from "@piceasoft/core";
import { IdentificationMethods } from '@piceasoft/core';
import { IIdentificationModuleConfig } from "@piceasoft/core";
import { IIdentificationModuleConfigUI } from "@piceasoft/core";
import { getLocalizedIdentificationMethod, getLocalizedIdentificationSubtitle } from "../../../../localization/helpers/transaction";
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";
import { CheckIdentificationModuleAccess, Experience } from "../helpers/evisibility";
import { DeviceCheckSolutions } from "../../../../core/store/typings/DeviceCheckSolutions";


type TProps = {
    config: IIdentificationConfig,
    setModuleParams: (index: number, pivot: string) => void
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
    channel: Experience,
    deviceCheckSolution: DeviceCheckSolutions,
    service?: ServiceTypes
}

export const getIdentificationModuleIcon = (method: IdentificationMethods): string | undefined => {
    switch (method) {
        case IdentificationMethods.FreeForm: return "Edit"
        case IdentificationMethods.Global: return "Globe"
        case IdentificationMethods.PiceaOne: return "InternetSharing"
        case IdentificationMethods.PiceaUsb: return "Plug"
        case IdentificationMethods.SearchForm: return "Search"
        case IdentificationMethods.Serial: return "GenericScan"
        case IdentificationMethods.Universal: return "Edit"
        case IdentificationMethods.Transaction: return "EntitlementRedemption"
        case IdentificationMethods.Scanner: return "DelveAnalyticsLogo"
        case IdentificationMethods.Imei: return "NumberField"
    }
}

export const Identification: React.FC<TProps> = (props) => {

    let newModuleMenuProps: IContextualMenuProps = {
        items: [
            {
                key: IdentificationMethods.Global.toString(),
                iconProps: { iconName: getIdentificationModuleIcon(IdentificationMethods.Global), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { props.onChangeDispatch(actionCreators.identification.addModule(IdentificationMethods.Global)) },
                text: getLocalizedIdentificationMethod(IdentificationMethods.Global),
            },
            {
                key: IdentificationMethods.PiceaOne.toString(),
                iconProps: { iconName: getIdentificationModuleIcon(IdentificationMethods.PiceaOne), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { props.onChangeDispatch(actionCreators.identification.addModule(IdentificationMethods.PiceaOne)) },
                text: getLocalizedIdentificationMethod(IdentificationMethods.PiceaOne),
            },
            {
                key: IdentificationMethods.PiceaUsb.toString(),
                iconProps: { iconName: getIdentificationModuleIcon(IdentificationMethods.PiceaUsb), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { props.onChangeDispatch(actionCreators.identification.addModule(IdentificationMethods.PiceaUsb)) },
                text: getLocalizedIdentificationMethod(IdentificationMethods.PiceaUsb),
            },
            {
                key: IdentificationMethods.SearchForm.toString(),
                iconProps: { iconName: getIdentificationModuleIcon(IdentificationMethods.SearchForm), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { props.onChangeDispatch(actionCreators.identification.addModule(IdentificationMethods.SearchForm)) },
                text: getLocalizedIdentificationMethod(IdentificationMethods.SearchForm),
            },
            {
                key: IdentificationMethods.Universal.toString(),
                iconProps: { iconName: getIdentificationModuleIcon(IdentificationMethods.Universal), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { props.onChangeDispatch(actionCreators.identification.addModule(IdentificationMethods.Universal)) },
                text: getLocalizedIdentificationMethod(IdentificationMethods.Universal),
            },
            {
                key: IdentificationMethods.Transaction.toString(),
                iconProps: { iconName: getIdentificationModuleIcon(IdentificationMethods.Transaction), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { props.onChangeDispatch(actionCreators.identification.addModule(IdentificationMethods.Transaction)) },
                text: getLocalizedIdentificationMethod(IdentificationMethods.Transaction),
            },
            {
                key: IdentificationMethods.Scanner.toString(),
                iconProps: { iconName: getIdentificationModuleIcon(IdentificationMethods.Scanner), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { props.onChangeDispatch(actionCreators.identification.addModule(IdentificationMethods.Scanner)) },
                text: getLocalizedIdentificationMethod(IdentificationMethods.Scanner),
            },
            {
                key: IdentificationMethods.Imei.toString(),
                iconProps: { iconName: getIdentificationModuleIcon(IdentificationMethods.Imei), style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => { props.onChangeDispatch(actionCreators.identification.addModule(IdentificationMethods.Imei)) },
                text: getLocalizedIdentificationMethod(IdentificationMethods.Imei),
            },
        ]
    }


    newModuleMenuProps.items = newModuleMenuProps.items.map(function (value) {
        let disableTitle = "";

        if (props.config?.items.find(m => m.method?.toString() == value.key)) {
            disableTitle = 'Item is already selected.';
        }

        if (!CheckIdentificationModuleAccess(props.channel, parseInt(value.key)) && value.iconProps !== undefined 
            || value.key === IdentificationMethods.SearchForm.toString() && props.service === ServiceTypes.SelfService) {
            if (disableTitle.length > 0) {
                disableTitle += '\n';
            }

            disableTitle += strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL_FEATURE_IS_NOT_SUPPORTED;
        }
        if((value.key === IdentificationMethods.PiceaOne.toString() || value.key === IdentificationMethods.PiceaUsb.toString())
            && (props.deviceCheckSolution === DeviceCheckSolutions.PiceaMobile || props.deviceCheckSolution === DeviceCheckSolutions.WebBased)) {
            disableTitle = strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.DEVICE_CHECK_SOLUTION_FEATURE_IS_NOT_SUPPORTED;
        }
        if (disableTitle.length > 0) {
            value.disabled = true;
            value.title = disableTitle;
            if (value.iconProps && value.iconProps.style ){
                value.iconProps.style.color = 'rgb(174 172 170)';
            }
        }
        return value;
    });




    const commands = () => (
        <CommandBarButton iconProps={{ iconName: "Add" }} menuProps={newModuleMenuProps} text={strings.BUTTONS.TEXT.ADD} disabled={false} />
    )

    return (
        <StageBox
            title={strings.CONSTRUCTOR.IDENTIFICATION.TITLE}
            onCommandRender={commands}
            settingsButtonProps={{onClick: props.showSettings}}
        >
            <Stack tokens={{ childrenGap: 16 }}>
                {props.config.items?.sort((a, b) => a.index - b.index).map((m, order) => {
                    if (m) {
                        return (
                            <IdentificationModuleItem
                                key={`${order}-${m.index}-${m.method}`}
                                channel={props.channel}
                                module={m}
                                onChangeDispatch={props.onChangeDispatch}
                                noCommonSettings={[IdentificationMethods.PiceaUsb, IdentificationMethods.Transaction, IdentificationMethods.Imei].includes(m.method)}
                                icon={m.ui?.icon ?? getIdentificationModuleIcon(m.method)}
                                setModuleParams={props.setModuleParams}/>
                        )
                    }
                })}
            </Stack>
            {props.config.items && props.config.items.length === 0 && (
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <MessageBar isMultiline messageBarType={MessageBarType.severeWarning}>
                        <b>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.WARNING_EMPTY_ASSESSMENT}</b>
                    </MessageBar>
                </Stack>
            )}
        </StageBox>
    )
}

type TItemProps = {
    channel: Experience;
    module: IIdentificationModuleConfig<IIdentificationModuleConfigUI>
    setModuleParams: (index: number, pivot: string) => void
    icon?: string
    onChangeDispatch: Dispatch<any>
    noCommonSettings?: boolean
    isExperimental?: boolean
}

interface IdentificationModuleConfig {
    isStatic: boolean;
}
const configDefault: IdentificationModuleConfig = {
    isStatic: false
}
const getIdentificationModuleSettings = (method: IdentificationMethods, channel: Experience): IdentificationModuleConfig => {
    switch (method) {
        case IdentificationMethods.SDK:
            return channel === Experience.MobileSDK ? {
                isStatic: true
            } : configDefault;
        default:
            return configDefault;
    }
}

export const IdentificationModuleItem: React.FC<TItemProps> = (iProps) => {
    const {isStatic} = getIdentificationModuleSettings(iProps.module.method, iProps.channel)
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            {!isStatic && (
                <Stack.Item>
                    <Stack verticalFill verticalAlign="center">
                        <IconButton iconProps={{ iconName: "Up" }} onClick={() => iProps.onChangeDispatch(actionCreators.identification.moveUpModule(iProps.module.index))} styles={{ icon: { fontSize: 12 } }} />
                        <IconButton iconProps={{ iconName: "Delete" }} onClick={() => iProps.onChangeDispatch(actionCreators.identification.removeModule(iProps.module.index))} styles={{ icon: { fontSize: 12 } }} />
                    </Stack>
                </Stack.Item>
            )}
            <Stack.Item grow styles={{ root: { paddingRight: 18 } }}>
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                        borderRadius: 10
                    }
                }}>
                    <Stack
                        onClick={() => {
                            if (!isStatic) {
                                iProps.setModuleParams(iProps.module.index, "ui")
                            }
                        }}
                        styles={{
                            root: {
                                padding: 16,
                                color: theme.palette.black,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: theme.palette.neutralQuaternaryAlt,
                                backgroundColor: theme.palette.white,
                                position: "relative",
                                borderRadius: 10,
                                ":hover": {
                                    cursor: "pointer",
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            }
                        }}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Icon iconName={iProps.icon ?? "InternetSharing"} styles={{ root: { color: theme.palette.black } }} />
                                <Text nowrap block style={{ maxWidth: 200 }} styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{iProps.module.ui?.title ?? getLocalizedIdentificationMethod(iProps.module.method)}</Text>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>
                                {getLocalizedIdentificationSubtitle(iProps.module.method)}
                            </Text>
                        </Stack.Item>
                        {iProps.isExperimental && (
                            <Stack.Item>
                                <TooltipHost content={strings.CONSTRUCTOR.COMMON.IS_EXPERIMENTAL_CONTENT}>
                                    <Text variant="small" styles={{ root: { color: SharedColors.red10, fontWeight: 400 } }}>{strings.CONSTRUCTOR.COMMON.IS_EXPERIMENTAL + " " + "*"}</Text>
                                </TooltipHost>
                            </Stack.Item>
                        )}
                    </Stack>
                    <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0 } }}>
                        {!(iProps.noCommonSettings || isStatic) && (
                            <IconButton onClick={() => iProps.setModuleParams(iProps.module.index, "common")} iconProps={{ iconName: "Repair" }} styles={{
                                root: {
                                    width: 36,
                                    height: 36,
                                    backgroundColor: theme.palette.white,
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: theme.palette.neutralQuaternaryAlt,
                                    borderRadius: "50%",
                                    color: theme.palette.black,
                                    boxShadow: theme.effects.elevation8,
                                    ":hover": {
                                        borderColor: theme.palette.themePrimary,
                                        backgroundColor: theme.palette.themeLighterAlt
                                    }
                                },
                                flexContainer: {
                                    borderRadius: "50%",
                                    ":hover": {
                                        borderColor: theme.palette.themePrimary,
                                        backgroundColor: theme.palette.themeLighterAlt
                                    }
                                }
                            }} />
                        )}
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
