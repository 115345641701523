import { ComboBox, IComboBoxOption, IStyle } from "@fluentui/react"
import React from "react"
import { UseFormTrigger } from "react-hook-form"
import { IKnownDevice } from "@piceasoft/core"
import { IPromotionItem } from "../../../../core/store/typings/IPromotionItem"

type TProps = {
    value: string
    onChange: (...event: any[]) => void
    onBlur: () => void
    styles: Partial<{ errorMessage: IStyle }>
    placeholder: string
    errorMessage?: string
    devices: IKnownDevice[]
    trigger: UseFormTrigger<IPromotionItem>
}

export const PromotionItemConditionManufacturer: React.FC<TProps> = props => {

    const [manufacturerOptions, setManufacturerOptions] = React.useState<IComboBoxOption[]>([])

    React.useEffect(() => {
        prepareManufacturerOptions()
    }, [])

    const prepareManufacturerOptions = async () => {
        const manufacturersArray = props.devices.sort((a, b) => (a.manufacturer > b.manufacturer) ? 1 : ((b.manufacturer > a.manufacturer) ? -1 : 0)).map(i => i.manufacturer)
        const manufacturersSet = new Set(manufacturersArray)
        setManufacturerOptions(Array.from(manufacturersSet).map(i => ({ key: i, text: i })))
    }

    return (
        <ComboBox
            allowFreeform
            autoComplete="on"
            text={props.value}
            options={manufacturerOptions}
            placeholder={props.placeholder}
            onChange={(ev, opt, index, val) => {
                console.log(opt, val)
                props.trigger("conditions")
                props.onChange(opt?.text ?? val)
            }}
            onInputValueChange={(val) => {
                props.trigger("conditions")
                props.onChange(val)
            }}
            onItemClick={(ev, opt) => {
                if (opt) {
                    props.trigger("conditions")
                    props.onChange(opt.text)
                }
            }}
            styles={{ ...props.styles, optionsContainer: { maxHeight: 300 } }}
            useComboBoxAsMenuWidth
            errorMessage={props.errorMessage}
        />
    )
}
