import { Stack } from '@fluentui/react';
import * as React from 'react';
import Inspection from '../Inspection';
import { IInterviewConfig, IInterviewWithQuestionsConfig, InterviewModes, Inspections } from "@piceasoft/core";
import { noop } from '../../../../../../core/helpers/common';
import { strings } from '../../../../../../localization/strings';
import { InterviewState } from './preview/InterviewState';

type TProps = {
    config?: IInterviewConfig
}

export const InterviewStatePreview: React.FC<TProps> = (props) => {
    if (props.config === undefined) return null;

    return (
        <Stack grow tokens={{ padding: "32px 32px 32px 32px" }}>
            <Inspection inspection={Inspections.Interview}
                icon={props.config.ui?.icon ?? "RedEye"}
                title={props.config.ui?.title ?? strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.TITLE}
                allowRestart={props.config.allowRestart}
                onStart={noop}
                onRestart={noop}
                dependency={props.config.dependency}
                skips={props.config.skip}
                autoRun={props.config.autoRun}
                executeButtonText={props.config.ui?.executeButtonText}
                repeatButtonText={props.config.ui?.restartButtonText}
            >
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    {props.config.mode === InterviewModes.List && (
                        <InterviewState config={props.config.config as IInterviewWithQuestionsConfig}
                            description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.LIST_NONE_INFORMATION}
                            icon={props.config.ui?.icon} />
                    )}
                    {props.config.mode === InterviewModes.Sequence && (
                        <InterviewState config={props.config.config as IInterviewWithQuestionsConfig}
                            description={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.SEQUENCE_NONE_INFORMATION}
                            icon={props.config.ui?.icon} />
                    )}
                </Stack>
            </Inspection>
        </Stack>
    );
}