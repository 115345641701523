import {
    DefaultButton, DetailsList, DetailsListLayoutMode,
    DetailsRow, DirectionalHint, FontIcon, FontWeights, getTheme,
    IButtonStyles, IColumn, IconButton, IContextualMenuProps,
    IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles,
    IRenderFunction, mergeStyleSets, MessageBar, MessageBarType, Modal, PrimaryButton, ScrollablePane, SelectionMode,
    Separator,
    SharedColors, Spinner, Stack, Sticky, Text
} from "@fluentui/react"
import React from "react"
import { ICatalog } from "../../../../core/store"
import { strings } from "../../../../localization/strings"
import { Section } from "../../../../components/shared/decorations/Section"
import { portalApi } from "../../../../core/api/api"
import { ItemsNotFound } from "../../../../components/shared/notFound/ItemsNotFound"
import { ICatalogProtocol } from "../../../../core/store/typings/ICatalogProtocol"
import { onRenderColumnHeaderCommon } from "../../../../components/shared/detailsList/commonRenders"
import { MyPersona } from "../../../../components/shared/decorations/MyPersona"
import { useBoolean } from "@uifabric/react-hooks"
import { PropertyValue } from "../../../../components/shared/decorations/PropertyValue"
import { getCatalogProtocolStringByCode } from "../../../../localization/helpers/protocols"
import { SomethingWentWrong } from "../../../../components/shared/somethingWentWrong/SomethingWentWrong"

type TFinanceCatalogProtocolsPivotProps = {
    data: ICatalog
}

export const FinanceCatalogProtocolsPivot: React.FC<TFinanceCatalogProtocolsPivotProps> = (props) => {

    const [protocolsState, setProtocolsState] = React.useState<{ error?: string, data?: ICatalogProtocol[], fetched: boolean }>({ fetched: false })
    const [selectedProtocol, setSelectedProtocol] = React.useState<ICatalogProtocol>()
    const [isModalShow, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)

    React.useEffect(() => {
        getProtocols()
    }, [])

    React.useEffect(() => {
        getProtocols()
    }, [props.data])

    React.useEffect(() => {
        if (selectedProtocol) {
            showModal()
        }
    }, [selectedProtocol])

    const getProtocols = async () => {
        if (props.data.id) {
            const result = await portalApi.organization.catalogs.getCatalogProtocols(props.data.id)
            console.log(result)
            setProtocolsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
        }
    }

    const onHideModal = () => {
        hideModal()
        setTimeout(() => {
            setSelectedProtocol(undefined)
        }, 500)
    }

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            maxWidth: 640,
            width: 640
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${selectedProtocol?.successed ? SharedColors.greenCyan10 : SharedColors.red10}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const getItemMenuProps = (item: ICatalogProtocol): IContextualMenuProps => {
        let menuProps: IContextualMenuProps = {
            items: [],
            directionalHint: DirectionalHint.bottomRightEdge
        }

        if (item.originalFile.length > 0) menuProps.items.push({
            key: `download-origin-${item.id}`,
            iconProps: { iconName: 'DownloadDocument', style: { color: theme.palette.black, fontSize: 14 } },
            onClick: () => { location.href = item.originalFile },
            text: strings.ORGANIZATION.CATALOGS.CATALOG.MENU.ORIGINAL_FILE,
        })

        if (item.protocolFile.length > 0) menuProps.items.push({
            key: `download-protocol-${item.id}`,
            iconProps: { iconName: 'EntitlementPolicy', style: { color: theme.palette.black, fontSize: 14 } },
            onClick: () => { location.href = item.protocolFile },
            text: strings.ORGANIZATION.CATALOGS.CATALOG.MENU.PROTOCOL_FILE,
        })

        return menuProps
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.CATALOGS.CATALOG.PROTOCOLS.TABLE_COLUMNS.CREATION_DATE,
            fieldName: 'creationDate',
            minWidth: 150,
            maxWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ICatalogProtocol) => <Stack style={{ paddingLeft: 12 }} horizontal horizontalAlign='center' verticalFill verticalAlign="center" tokens={{ childrenGap: 12 }}>
                <FontIcon style={{ fontSize: 20, color: item.successed ? SharedColors.greenCyan10 : SharedColors.red10 }} iconName={item.successed ? "CompletedSolid" : "AlertSolid"} />
                <Text>{new Date(item.creationDate).toLocaleString()}</Text>
            </Stack>,
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.CATALOGS.CATALOG.PROTOCOLS.TABLE_COLUMNS.PERSON,
            fieldName: 'person',
            minWidth: 250,
            maxWidth: 250,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ICatalogProtocol) => item.login && <Stack verticalFill verticalAlign="center"><MyPersona text={item.login} secondaryText={item.name} /></Stack>,
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.CATALOGS.CATALOG.PROTOCOLS.TABLE_COLUMNS.MESSAGE,
            fieldName: 'message',
            minWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ICatalogProtocol) => <Stack verticalFill verticalAlign="center"><Text>{getCatalogProtocolStringByCode(item.message)}</Text></Stack>,
            isPadded: true,
        },
        {
            key: 'column4',
            name: '',
            fieldName: 'commandBar',
            minWidth: 40,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ICatalogProtocol) => {
                const menuProps = getItemMenuProps(item)

                if (menuProps.items.length > 0) {
                    return (
                        <Stack horizontal verticalFill verticalAlign='center'>
                            <Stack.Item>
                                <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item)} />
                            </Stack.Item>
                        </Stack>
                    )
                }
                return null
            }
        }
    ]

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }

        return (
            <Stack>
                <Stack.Item>
                    <Stack tokens={{ padding: "16px 32px 4px 20px", childrenGap: 4 }} horizontal>
                        <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                        <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                            <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{protocolsState.data?.length}</Text>
                        </Stack>
                    </Stack>
                </Stack.Item>
                {defaultRender!({ ...hProps })}
            </Stack>
        );
    }

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                paddingBottom: 0,
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => setSelectedProtocol(rProps.item)}>
                <DetailsRow {...rProps} styles={customStyles} />
            </Stack>
        }
        return null;
    }

    return (
        <Stack verticalFill tokens={{ padding: "24px 0 0 0" }}>
            <Section flat max verticalFill>
                {!protocolsState.error && protocolsState.fetched && ((protocolsState.data && protocolsState.data.length > 0 && (
                    <Stack verticalFill>
                        <Stack verticalFill style={{ position: 'relative' }}>
                            <ScrollablePane>
                                <DetailsList
                                    styles={{ root: { width: '100%' } }}
                                    items={protocolsState.data}
                                    columns={columns}
                                    setKey="none"
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                    onRenderRow={onRenderRow}
                                    isHeaderVisible={true}
                                />
                            </ScrollablePane>
                        </Stack>
                    </Stack >
                ) || (
                        <ItemsNotFound doNotUseButton
                            info={strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.NOT_FOUND_CASE.INFO}
                            suggestion={strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.NOT_FOUND_CASE.SUGGESTION}
                            imgSrc={"images/navigation/images/not_found.png"}
                        />
                    )
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    ))}
                {protocolsState.error && (
                    <Stack verticalFill>
                        {protocolsState.error.length > 0 && (
                            <MessageBar messageBarType={MessageBarType.error}>{protocolsState.error}</MessageBar>
                        )}
                        <SomethingWentWrong action={getProtocols} />
                    </Stack>
                )}
                <Modal isOpen={isModalShow} onDismiss={onHideModal} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                <FontIcon style={{ fontSize: 20, color: selectedProtocol?.successed ? SharedColors.greenCyan10 : SharedColors.red10 }} iconName={selectedProtocol?.successed ? "CompletedSolid" : "AlertSolid"} />
                                <Text variant="xLargePlus" styles={{ root: { fontWeight: FontWeights.semibold } }}>{strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.PROTOCOL.TITLE}</Text>
                            </Stack>
                        </span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close window"
                            onClick={onHideModal}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <Stack tokens={{ childrenGap: 24 }}>
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Stack tokens={{ childrenGap: 6 }}>
                                    <PropertyValue label={strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.PROTOCOL.CREACTION_DATE} value={new Date(selectedProtocol?.creationDate as Date).toLocaleString()} />
                                    <PropertyValue label={strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.PROTOCOL.MESSAGE} value={selectedProtocol?.message && getCatalogProtocolStringByCode(selectedProtocol.message)} />
                                </Stack>
                                <Stack tokens={{ childrenGap: 6 }}>
                                    <PropertyValue label={strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.PROTOCOL.USER_NAME} value={selectedProtocol?.name} />
                                    <PropertyValue label={strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.PROTOCOL.USER_LOGIN} value={selectedProtocol?.login} />
                                    <PropertyValue label={strings.ORGANIZATION.CATALOGS.PIVOTS.PROTOCOLS.PROTOCOL.IP_ADDRESS} value={selectedProtocol?.remoteIpAddress} />
                                </Stack>
                            </Stack>
                            <Stack.Item>
                                <Stack verticalAlign="center">
                                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                                        <Stack.Item>
                                        </Stack.Item>
                                        <Stack.Item grow>
                                            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                                <DefaultButton disabled={!selectedProtocol?.originalFile && true}
                                                    onClick={() => {
                                                        if (selectedProtocol?.originalFile) {
                                                            location.href = selectedProtocol?.originalFile
                                                        }
                                                    }}>
                                                    {strings.ORGANIZATION.CATALOGS.CATALOG.MENU.ORIGINAL_FILE}
                                                </DefaultButton>
                                                <DefaultButton disabled={!selectedProtocol?.protocolFile && true}
                                                    onClick={() => {
                                                        if (selectedProtocol?.protocolFile) {
                                                            location.href = selectedProtocol?.protocolFile
                                                        }
                                                    }}>
                                                    {strings.ORGANIZATION.CATALOGS.CATALOG.MENU.PROTOCOL_FILE}
                                                </DefaultButton>
                                                <PrimaryButton onClick={onHideModal}>OK</PrimaryButton>
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </div>
                </Modal>
            </Section>
        </Stack>
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}