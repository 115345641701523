import { CommissionType, TransactionKeys } from '@piceasoft/core';

import { strings } from '../../localization/strings';
import { ITransaction } from '../store/typings/ITransaction';

export const noop = () => { }

export const range = (size: number, startAt: number = 0): number[] => {
    return [...Array(size).keys()].map(i => i + startAt);
}

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

export function getEnumName<E extends Enum<E>>(enumeration: E, text: string): string {
    if (Number.parseInt(text)) {
        return enumeration[Number.parseInt(text)]
    }
    if (Object.keys(enumeration).includes(text)) {
        return text
    }
    return ""
}

export function getEnumValidNumberKeys<E extends Enum<E>>(enumeration: E, range: number[]): number[] {
    return Object.keys(enumeration).filter(k => !isNaN(Number(k)) && range.includes(Number(k))).map(i => Number(i))
}

export function getEnumNumberKeys<E extends Enum<E>>(enumeration: E): number[] {
    return Object.keys(enumeration).filter(k => !isNaN(Number(k))).map(i => Number(i))
}

/** Rounds to nearest number with decimals, if necessary.  */ 
export function round(num: number, decimals?: number) {
    const d = Math.pow(10, decimals ?? 0);
    return Math.round((num + Number.EPSILON) * d) / d;
}

/** Converts bytes to human readable format, like "20 GB", "5.2 MB". */
export const sizeToText = (bytes: number) => {
    // determine units. if overflows then unit will be 'undefined'.
    const sizes = [
        strings.CONTENT_TRANSFER.UNIT_B,
        strings.CONTENT_TRANSFER.UNIT_KB,
        strings.CONTENT_TRANSFER.UNIT_MB,
        strings.CONTENT_TRANSFER.UNIT_GB,
        strings.CONTENT_TRANSFER.UNIT_TB
    ];

    if (bytes === 0) return `0 ${strings.CONTENT_TRANSFER.UNIT_B}`;
    // get unit index
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    // get the size in units
    let number = bytes / Math.pow(1024, i);

    // how many digits there are?
    var length = Math.log(number) * Math.LOG10E + 1 | 0;

    // use 1 decimal if short number like 5.456 => 5.4
    // no decimals for long numbers like 45.678 => 46

    const size = round(number, length > 1 ? 0: 1);
    return `${size} ${sizes[i]}`;
}

/** Converts a JavaScript Object Notation (JSON) string into an object.
 *  In case of any error, null is returned.
 * */ 
export const parsedJSON = (text?: string) => {
    if (!text) return null;
    try {
        return JSON.parse(text);
    }
    catch (e) {
        return null;
    }
}

export const getTextOrDefault = (text?: string, defaultText?: string) : string => {
    if (text && text.length > 0)
        return text

    return defaultText ?? "";
}
