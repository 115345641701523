import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";
import { ParameterItem } from "../../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { IAssessmentConfig, IControlConfig, ProcessStages } from "@piceasoft/core";

type TProps = {
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    config?: IAssessmentConfig | IControlConfig
    channel: number
}

export const StageAdditionalSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, stage, channel }) => {
    if (!config) return null

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.HIDE_DEPENDENCY_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Additional', 'Hide dependent module')}
                toggleChecked={config.hideDependency}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        hideDependency: !config.hideDependency
                    } as IAssessmentConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.HIDE_DEPENDENCY_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.HIDE_SKIPED_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Additional', 'Hide skipped modules')}
                toggleChecked={config.hideSkiped}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        hideSkiped: !config.hideSkiped
                    } as IAssessmentConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.HIDE_SKIPED_DESCRIPTION}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.COLLAPSE_DONE_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Additional', 'Collapse completed modules')}
                toggleChecked={config.collapseDone}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        collapseDone: !config.collapseDone
                    } as IAssessmentConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ADDITIONAL.COLLAPSE_DONE_DESCRIPTION}
            />
        </Stack>
    )
}