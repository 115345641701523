import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, SharedColors, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { IInterviewConfig, IInterviewWithQuestionsConfig, IInterview, InterviewModes, IGrade } from "@piceasoft/core";
import { strings } from '../../../../../localization/strings';

type TProps = {
    result: IInterview
    config: IInterviewConfig
    grades: IGrade[]
}

export const InterviewPivot: React.FC<TProps> = (props) => {
    return (
        <>
            {props.config.config && [InterviewModes.List, InterviewModes.Sequence].includes(props.config.mode) && (
                <QuestionsResult result={props.result} config={props.config.config as IInterviewWithQuestionsConfig} grades={props.grades} />
            )}
        </>
    )
}

type TQuestionsResultProps = {
    config: IInterviewWithQuestionsConfig
    result: IInterview
    grades: IGrade[]
}

const QuestionsResult: React.FC<TQuestionsResultProps> = (props) => {

    type TDescriptedAnsweredQuestion = {
        question: string
        answer: string | boolean
    }

    const items: TDescriptedAnsweredQuestion[] = props.result.answers?.map(a => {
        return {
            question: props.config.questions.find(q => a.questionIndex === q.index)?.title,
            answer: props.config.questions.find(q => a.questionIndex === q.index)?.answers.find(qa => qa.index === a.answerIndex)?.text
        } as TDescriptedAnsweredQuestion
    }) ?? []

    const onRenderCell = (item: TDescriptedAnsweredQuestion): React.ReactNode => {

        return (
            <Stack grow horizontal verticalAlign="center">
                <Stack.Item grow>
                    <Text style={{ color: SharedColors.gray40, fontWeight: 600 }}>{item?.question}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Text style={{ color: SharedColors.cyanBlue10 }}>{item?.answer}</Text>
                </Stack.Item>
            </Stack>
        )
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.TRANSACTION.RESULT.INTERVIEW.QUESTION,
            fieldName: 'item',
            minWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } }, cellTitle: { display: 'flex' } },
            onRender: onRenderCell,
            isPadded: true,
        },
    ]

    return (
        <Stack>
            {items.length > 0 && (
                <Stack>
                    <Stack horizontal grow verticalAlign="center" tokens={{ padding: "12px 24px 12px 12px" }}>
                        <Stack.Item grow>
                            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.INTERVIEW.QUESTION}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.INTERVIEW.ANSWER}</Text>
                        </Stack.Item>
                    </Stack>
                    <DetailsList
                        items={items}
                        columns={columns}
                        isHeaderVisible={false}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                    />
                </Stack>
            )}
            {props.result.comment && (
                <Stack.Item>
                    <Stack grow verticalAlign="center" tokens={{ childrenGap: 8, padding: "12px 32px 12px 12px" }}>
                        <Stack.Item grow>
                            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{"Комментарий оператора"}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            {props.result.comment}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    )
}
