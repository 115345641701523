import {
    MessageBar, MessageBarType, Stack, IconButton, SharedColors,
    Icon, TooltipHost, DirectionalHint, FontIcon, getTheme, Text
} from "@fluentui/react"
import { Dispatch } from "react"
import { useSelector } from "react-redux"
import { getStageInspectionModules } from "../../../../../core/actions/configurator-actions"
import { actionCreators } from "../../../../../core/actions/configurator-actions"
import { IStore,  } from "../../../../../core/store"
import { IInspectionConfig, IInspectionConfigUI, IGradesCategory, Inspections } from "@piceasoft/core"
import { getLocalizedInspectionName, getLocalizedInspectionModeName } from "../../../../../localization/helpers/transaction"
import { strings } from "../../../../../localization/strings"
import { getInspectionIcon } from "../Assessment"
import { ProcessStages, IInspectionModuleConfig } from "@piceasoft/core"

export type TInspectionItemProps = {
    stage: ProcessStages
    inspection: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>
    useGradeCategories?: boolean
    gradesCategories?: IGradesCategory[]
    setInspectionParams: (index: number, pivot: string) => void
    icon?: string
    onChangeDispatch: Dispatch<any>
    isExperimental?: boolean
    withoutGrades?: boolean
}

export const InspectionItem: React.FC<TInspectionItemProps> = (iProps) => {
    const modules = useSelector((s: IStore) => getStageInspectionModules(iProps.stage, s))
    const existingSettingErrors = (iProps.inspection.config.errors && iProps.inspection.config.errors.length > 0 && iProps && iProps.inspection.config.errors.find((error) => (error.code !== 11 && error.code !== 20 && error.code !== 3))) ? true : false
    let errorListRender: JSX.Element = (
        <div style={{ display: "inline" }}>
            <ul style={{ paddingLeft: 16 }}>
                {iProps.inspection.config.errors?.map((error, index) => (
                    <li key={index}>{error.text}</li>
                ))}
            </ul>
        </div>
    )

    const renderErrors = (
        <>
            {iProps.inspection.config.errors && iProps.inspection.config.errors.length > 0 && (
                <MessageBar isMultiline={true} overflowButtonAriaLabel="See more" messageBarType={MessageBarType.severeWarning}>
                    <b style={{ width: "100%" }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.NOTES_ON_SETTINGS_MODULE}</b> {errorListRender}
                </MessageBar>
            )}
        </>
    )

    return (
        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item>
                <Stack verticalFill verticalAlign="center">
                    <IconButton iconProps={{ iconName: "Up" }} onClick={() => iProps.onChangeDispatch(actionCreators.inspections.moveUpInspection(iProps.stage, iProps.inspection.index))} styles={{ icon: { fontSize: 12 } }} />
                    <IconButton iconProps={{ iconName: "Delete" }} onClick={() => iProps.onChangeDispatch(actionCreators.inspections.removeInspection(iProps.stage, iProps.inspection.index))} styles={{ icon: { fontSize: 12 } }} />
                </Stack>
            </Stack.Item>
            <Stack.Item grow styles={{ root: { padding: "0px 18px 24px 18px", position: "relative", width: "100%", overflow: "hidden" } }}>
                <Stack styles={{
                    root: {
                        boxShadow: theme.effects.elevation16
                    }
                }}>
                    <Stack.Item styles={{
                        root: {
                            color: theme.palette.black,
                            position: "relative",
                            width: "100%"
                        }
                    }}>
                        <Stack onClick={() => iProps.setInspectionParams(iProps.inspection.index, "ui")} styles={{
                            root: {
                                padding: "16px 32px",
                                color: theme.palette.black,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: (iProps.inspection.config.errors && iProps.inspection.config.errors.length > 0) ? '#ee7676' : theme.palette.neutralQuaternaryAlt,
                                borderTopWidth: 8,
                                backgroundColor: theme.palette.white,
                                position: "relative",
                                ":hover": {
                                    cursor: "pointer",
                                    borderColor: (iProps.inspection.config.errors && iProps.inspection.config.errors.length > 0) ? SharedColors.redOrange10 : theme.palette.themePrimary,
                                    backgroundColor: iProps.inspection.config.errors ? theme.palette.neutralLighter : theme.palette.themeLighterAlt
                                }
                            }
                        }}>
                            <Stack.Item>
                                <Stack horizontal grow>
                                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                        <Icon iconName={iProps.icon ?? "InternetSharing"} styles={{ root: { color: theme.palette.black } }} />
                                        <Text nowrap block style={{ maxWidth: 200 }} styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>{iProps.inspection.config.ui?.title ?? getLocalizedInspectionName(iProps.inspection.type)}</Text>
                                    </Stack>
                                    {iProps.inspection.config.skip && iProps.inspection.config.skip.length > 0 && (
                                        <Stack grow verticalAlign="center" horizontalAlign="end">
                                            <TooltipHost content={getInspectionSkipList(iProps.inspection)}
                                                directionalHint={DirectionalHint.bottomCenter}
                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                tooltipProps={{
                                                    calloutProps: {
                                                        styles: {
                                                            beak: { background: theme.palette.black },
                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                        },
                                                    },
                                                }}
                                            >
                                                <FontIcon iconName={"Unpin"} style={{ color: SharedColors.red10 }} />
                                            </TooltipHost>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>{getLocalizedInspectionModeName(iProps.inspection.type, iProps.inspection.config)}</Text>
                            </Stack.Item>
                            {iProps.isExperimental && (
                                <Stack.Item>
                                    <TooltipHost content={strings.CONSTRUCTOR.COMMON.IS_EXPERIMENTAL_CONTENT}>
                                        <Text variant="small" styles={{ root: { color: SharedColors.red10, fontWeight: 400 } }}>{strings.CONSTRUCTOR.COMMON.IS_EXPERIMENTAL + " " + "*"}</Text>
                                    </TooltipHost>
                                </Stack.Item>
                            )}
                            {!iProps.withoutGrades && iProps.useGradeCategories && (
                                <Stack.Item>
                                    <Text variant="small" styles={{ root: { color: iProps.inspection.config.gradesCategory ? SharedColors.orange20 : SharedColors.gray10 } }}>
                                        {(iProps.gradesCategories?.find((category) => category.code === iProps.inspection.config.gradesCategory)) ? iProps.inspection.config.gradesCategory : (iProps.inspection.config.gradesCategory === '!WASRESET') ? strings.CONSTRUCTOR.INSPECTIONS.COMMON.GRADE_CATEGORY_WAS_RESET : strings.CONSTRUCTOR.GRADES_CATEGORIES.CATEGORY_NOT_ASSIGNED}
                                    </Text>
                                </Stack.Item>
                            )}
                            {((iProps.inspection.config.dependencyInspections && iProps.inspection.config.dependencyInspections.length > 0 && modules)
                                || iProps.inspection.config.allowRestart
                                || iProps.inspection.config.resetStateAtSkip
                            ) && (
                                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ padding: "8px 0 0 0" }}>
                                        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
                                            {iProps.inspection.config.allowRestart && (
                                                <TooltipHost content={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.ALLOW_RESTART_TITLE}
                                                    directionalHint={DirectionalHint.bottomCenter}
                                                    styles={{ root: { display: "flex", cursor: "default" } }}
                                                    tooltipProps={{
                                                        calloutProps: {
                                                            styles: {
                                                                beak: { background: theme.palette.black },
                                                                beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <FontIcon iconName={"Rerun"} style={{ color: SharedColors.gray10 }} />
                                                </TooltipHost>
                                            )}
                                            {iProps.inspection.config.resetStateAtSkip && (
                                                <TooltipHost content={strings.CONSTRUCTOR.INSPECTIONS.COMMON.PIVOTS.COMMON.RESET_STATE_AT_SKIP_TITLE}
                                                    directionalHint={DirectionalHint.bottomCenter}
                                                    styles={{ root: { display: "flex", cursor: "default" } }}
                                                    tooltipProps={{
                                                        calloutProps: {
                                                            styles: {
                                                                beak: { background: theme.palette.black },
                                                                beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <FontIcon iconName={"ClearFormatting"} style={{ color: SharedColors.gray10 }} />
                                                </TooltipHost>
                                            )}
                                        </Stack>
                                        {iProps.inspection.config.dependencyInspections && iProps.inspection.config.dependencyInspections.length > 0 && modules && (
                                            <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 8 }} grow>
                                                {iProps.inspection.config.dependencyInspections?.map((d, index) => {
                                                    const module = modules?.find(m => m.index === d)
                                                    if (module) {
                                                        const icon = module?.config.ui?.icon ?? getInspectionIcon(module?.type)
                                                        return (
                                                            <TooltipHost key={index} content={module.config.ui?.title ?? getLocalizedInspectionName(module.type)}
                                                                directionalHint={DirectionalHint.bottomCenter}
                                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                                tooltipProps={{
                                                                    calloutProps: {
                                                                        styles: {
                                                                            beak: { background: theme.palette.black },
                                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <FontIcon iconName={icon} style={{ color: SharedColors.yellowGreen10 }} />
                                                            </TooltipHost>
                                                        )
                                                    }
                                                    return null
                                                })}
                                            </Stack>
                                        )}
                                    </Stack>
                                )}
                        </Stack>
                        <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, left: -18, bottom: 0 } }}>
                            <IconButton iconProps={{ iconName: "LightningBolt" }}
                                onClick={() => iProps.onChangeDispatch(actionCreators.inspections.editInspection(iProps.stage, iProps.inspection.index, { ...iProps.inspection.config, autoRun: iProps.inspection.config.autoRun !== undefined ? undefined : true }))} styles={{
                                    root: {
                                        width: 36,
                                        height: 36,
                                        backgroundColor: !iProps.inspection.config.autoRun ? theme.palette.white : theme.palette.themeLighterAlt,
                                        borderWidth: 1,
                                        borderStyle: "solid",
                                        borderColor: !iProps.inspection.config.autoRun ? theme.palette.neutralQuaternaryAlt : theme.palette.themePrimary,
                                        borderRadius: "50%",
                                        color: !iProps.inspection.config.autoRun ? theme.palette.black : theme.palette.themePrimary,
                                        boxShadow: theme.effects.elevation8,
                                        ":hover": {
                                            borderColor: !iProps.inspection.config.autoRun ? theme.palette.themePrimary : theme.palette.neutralQuaternaryAlt,
                                            backgroundColor: !iProps.inspection.config.autoRun ? theme.palette.themeLighterAlt : theme.palette.white
                                        }
                                    },
                                    flexContainer: {
                                        borderRadius: "50%",
                                        ":hover": {
                                            borderColor: theme.palette.themePrimary,
                                            backgroundColor: theme.palette.themeLighterAlt
                                        }
                                    }
                                }} />
                        </Stack>
                        <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0 } }}>
                            <IconButton onClick={() => iProps.setInspectionParams(iProps.inspection.index, "questions")} iconProps={{ iconName: "Repair" }} styles={{
                                root: {
                                    width: 36,
                                    height: 36,
                                    backgroundColor: theme.palette.white,
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: existingSettingErrors ? '#ee7676' : theme.palette.neutralQuaternaryAlt,
                                    borderRadius: "50%",
                                    color: existingSettingErrors ? SharedColors.redOrange10 : theme.palette.black,
                                    boxShadow: theme.effects.elevation8,
                                    ":hover": {
                                        borderColor: existingSettingErrors ? SharedColors.redOrange10 : theme.palette.themePrimary,
                                        backgroundColor: existingSettingErrors ? theme.palette.neutralLighter : theme.palette.themeLighterAlt
                                    }
                                },
                                flexContainer: {
                                    borderRadius: "50%",
                                    ":hover": {
                                        borderColor: theme.palette.themePrimary,
                                        backgroundColor: theme.palette.themeLighterAlt
                                    }
                                },
                                iconHovered: {
                                    color: existingSettingErrors ? SharedColors.redOrange10 : theme.palette.black
                                }
                            }} 
                            disabled={iProps.inspection.type === Inspections.ManualGrading}/>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item styles={{ root: {} }}>
                        {renderErrors}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const getInspectionSkipList = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>): JSX.Element => {
    return (
        <Stack tokens={{ childrenGap: 2 }}>
            {module.config.skip?.map(s => (
                <Stack.Item>
                    <Text variant="small" style={{ color: theme.palette.white }}>{s.name}</Text>
                </Stack.Item>
            ))}
        </Stack>
    )
}

const theme = getTheme();