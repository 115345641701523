import { IContextualMenuProps, Image, Link, PrimaryButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { strings } from '../../../localization/strings';

type TProps = {
    onClick?: () => void
    imgSrc?: string
    info: string
    suggestion?: string
    buttonText?: string
    doNotUseButton?: boolean
    imgHeight?: number
    menuProps?: () => IContextualMenuProps
    linkText?: string
}

export const ItemsNotFound: React.FC<TProps> = (props) => {
    return (
        <Stack verticalFill verticalAlign="center" horizontalAlign="center">
            <Stack horizontalAlign="center" tokens={{ childrenGap: 16 }} style={{ maxWidth: 520 }}>
                {props.imgSrc && (
                    <Stack.Item>
                        <Image height={props.imgHeight ?? 160} src={props.imgSrc} />
                    </Stack.Item>
                )}
                <Stack.Item style={{ fontSize: "1.75rem", fontWeight: "bold", letterSpacing: "-.04em", color: "black", textAlign: "center" }}>
                    {props.info}
                </Stack.Item>
                {props.suggestion && (
                    <Stack.Item style={{ color: "black", textAlign: "center" }}>
                        {props.suggestion}
                        {props.linkText && (<Link href="#" target='_blank'>
                        {' '}{props.linkText}
                        </Link>)}
                    </Stack.Item>
                )}
                {props.menuProps && (
                    <Stack.Item style={{ paddingTop: 16 }}>
                        <PrimaryButton iconProps={{ iconName: "Add" }} disabled={false} menuProps={props.menuProps()} >{strings.ORGANIZATION.CATALOGS.ADD}</PrimaryButton>
                    </Stack.Item>
                ) || (
                        !props.doNotUseButton && (
                            <Stack.Item style={{ paddingTop: 16 }}>
                                <PrimaryButton onClick={props.onClick} text={props.buttonText ?? strings.BUTTONS.TEXT.ADD} />
                            </Stack.Item>
                        )
                    )}
            </Stack>
        </Stack>
    )
}