import { DefaultButton, Panel, PanelType, Text, PrimaryButton, SearchBox, Separator, Stack, IChoiceGroupOption } from '@fluentui/react';
import * as React from 'react';
import { IDiscountsConfig, IPromoProviderItem, IPromoProviderItemState } from '@piceasoft/core';
import { strings } from '../../../../../../../localization/strings';
import { samplePromotions } from '../../../../mock/samples';
import { Promotions } from './Promotions';

type TProps = {
    isOpen: boolean
    dismissPanel: () => void
    config?: IDiscountsConfig
    promotion?: IPromoProviderItemState
    setPromotion: (item?: IPromoProviderItemState) => void
}

export type TPromoProviderItemState = IPromoProviderItemState & {
    provider_name?: string
    provider_description?: string
    provider_img?: string
    notValid?: boolean
    key: string
}

export const PromotionsPanel: React.FC<TProps> = (props) => {

    const [selectedProviderPromotion, setSelectedProviderPromotion] = React.useState<TPromoProviderItemState>();
    const receiveProviderPromotion = (promotion: IPromoProviderItemState) => props.setPromotion(promotion)
    const removeProviderPromotion = () => props.setPromotion(undefined)
    const [filteredOptions, setFilteredOptions] = React.useState<IChoiceGroupOption[]>()
    const [searchString, setSearchString] = React.useState<string>()

    const onRenderPanelFooter = () => {
        return (
            <Stack verticalAlign="end" horizontal horizontalAlign="space-between" tokens={{ childrenGap: 8, padding: 16 }}>
                <PrimaryButton onClick={removeProviderPromotionHandler} disabled={props.promotion ? false : true}>{strings.BUTTONS.TEXT.DISCOUNT_REMOVE}</PrimaryButton>
                <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }} >
                    <PrimaryButton onClick={confirmProviderPromotionHandler} disabled={selectedProviderPromotion ? false : true}>
                        {props.promotion ? strings.BUTTONS.TEXT.DISCOUNT_CHANGE : strings.BUTTONS.TEXT.DISCOUNT_SELECT}
                    </PrimaryButton>
                    <DefaultButton onClick={onDismissPanel}>{strings.BUTTONS.TEXT.DISCOUNT_CANCEL}</DefaultButton>
                </Stack>
            </Stack>
        )
    }

    const onDismissPanel = () => {
        props.dismissPanel()
        setTimeout(() => {
            setSearchString(undefined)
        }, 200)
    }

    const onRenderPanelBody = () => {
        return (
            <Stack verticalFill grow tokens={{ padding: 16 }}>
                    <Promotions promotion={props.promotion}
                        promotions={samplePromotions ?? []}
                        config={props.config}
                        confirmHandler={confirmProviderPromotionHandler}
                        setSelectedItem={setSelectedProviderPromotion}
                        selectedItem={selectedProviderPromotion}
                        setFilteredOptions={setFilteredOptions}
                        filteredOptions={filteredOptions}
                        searchString={searchString}
                        setSearchString={setSearchString}
                    />
            </Stack>
        )
    }

    const onRenderPanelHeader = () => {
        return (
            <Stack verticalFill style={{ paddingTop: 0, paddingLeft: 16, paddingRight: 0 }} grow tokens={{ childrenGap: 8 }}>
                <Stack tokens={{ childrenGap: 0 }}>
                    <Text variant="xLarge">{props.config?.ui?.header ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS.VENDORS_OFFERS}</Text>
                    <Separator />
                    <Text>{props.config?.ui?.information ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS.INFORMATION_TEXT}</Text>
                </Stack>
                <Stack.Item>
                    <SearchBox placeholder={props.config?.ui?.placeholder ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS.PLACEHOLDER} onChange={(ev, value) => setSearchString(value)} />
                </Stack.Item>
                <Separator />
            </Stack>
        )
    }

    const removeProviderPromotionHandler = () => {
        removeProviderPromotion()
        setSelectedProviderPromotion(undefined)
        onDismissPanel()
    }

    const confirmProviderPromotionHandler = () => {
        if (selectedProviderPromotion) {
            receiveProviderPromotion(selectedProviderPromotion)
            onDismissPanel()
        }
    }

    return (
        <Panel headerText={props.config?.ui?.header ?? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.DISCOUNTS.VENDORS_OFFERS}
            isOpen={props.isOpen}
            onDismiss={onDismissPanel}
            type={PanelType.large}
            closeButtonAriaLabel={strings.BUTTONS.TEXT.CLOSE}
            onRenderHeader={onRenderPanelHeader}
            onRenderFooter={onRenderPanelFooter}
            onRenderBody={onRenderPanelBody}
            isFooterAtBottom={true}
        />
    )
}
