import { css, FontIcon, getTheme, Image, ImageFit, mergeStyleSets, MessageBar, MessageBarButton, MessageBarType, ProgressIndicator, Separator, Spinner, Stack, Text, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import QRCode from 'react-qr-code';
import { DeviceAttributes } from '@piceasoft/core';
import { IDevice, PiceaConnectionStatuses } from '@piceasoft/core';
import { strings } from '../../../../../../localization/strings';
import unknown from '../../../../../../assets/unknown.png';
import { IIdentificationPiceaOneConfig } from '@piceasoft/core';
import { IPicea } from '../typings/IPicea';
import IdentificationHeader from './ui/IdentificationHeader';
import { defaultPiceaOneRowSize, identificationContainerStyle, hasBlockLevelElements, blockOffset } from '../helpers/identificationContainer';

type TProps = {
    config?: IIdentificationPiceaOneConfig
    picea: IPicea,
    device?: IDevice
    warning?: string
}

const PiceaOne: React.FC<TProps> = ({ picea, device, warning, ...props }) => {
    return (
        <Stack style={{maxHeight: 380}} verticalFill horizontalAlign="stretch">
            <Stack.Item>
                <IdentificationHeader
                    icon={props.config?.ui?.icon ?? "InternetSharing"}
                    title={props.config?.ui?.title ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_VIA_APP}
                />
            </Stack.Item>
            <Separator />
            <Stack.Item verticalFill>
                {picea === undefined && <Unavailable />}
                {picea && picea.status === "available" && picea.connections.otf.status === PiceaConnectionStatuses.Connected && <Information device={device} warning={warning} config={props.config} />}
                {picea && picea.status === "available" && picea.connections.otf.status === PiceaConnectionStatuses.Connecting && <Connecting />}
                {picea && picea.status === "available" && picea.connections.otf.status === PiceaConnectionStatuses.Disconnected && <Disconnected />}
                {picea && picea.status === "available" && picea.connections.otf.status === PiceaConnectionStatuses.Failed && <Fail message={picea.connections.otf.error?.message} />}
                {picea && picea.status === "fail" && <Fail message={picea.error?.message} />}
                {picea && picea.status === "loading" && <Loading />}
            </Stack.Item>
        </Stack>
    );
}

export default PiceaOne;

const childrenGap = 16;

const Information: React.FC<{ device?: IDevice, warning?: string, link?: string, qr?: string, config?: IIdentificationPiceaOneConfig }> = (props) => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item>
                <TooltipHost
                overflowMode={TooltipOverflowMode.Self}
                content={props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION}
                hostClassName={css(identificationContainerStyle(defaultPiceaOneRowSize).body)}
                styles={{ root: {display: 'block'}}}>
                <Text style={{ color: SharedColors.gray40 }}>
                    <div 
                        style={hasBlockLevelElements(props.config?.ui?.information) ? {marginTop: `${blockOffset}`} : {marginTop: '0px'}}
                        dangerouslySetInnerHTML={{ __html: props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION }}>
                    </div>
                </Text>
                </TooltipHost>
            </Stack.Item>
            <Stack.Item verticalFill>
                {props.device && (
                    <Stack verticalFill>
                        <Stack.Item>
                            <Stack verticalFill horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: childrenGap }}>
                                <Image width={96} height={96} imageFit={ImageFit.contain} maximizeFrame={true}
                                    src={props.device.attributes[DeviceAttributes.Image] ?? unknown} alt="Device Image" />
                                <Text variant="mediumPlus" style={{ color: SharedColors.cyanBlue10 }}>{props.device.manufacturer} {props.device.name} {props.device.configuration}</Text>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <ProgressIndicator />
                        </Stack.Item>
                    </Stack>
                ) || (
                        <Stack verticalFill horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                            <div style={{ boxShadow: theme.effects.elevation8, padding: 12, overflow: "hidden" }}>
                                <QRCode value={"one2phone"} size={96} fgColor={SharedColors.gray40} />
                            </div>
                            {props.config?.useShortUrl &&
                                <>
                                    <Text variant="xSmall" style={{ color: SharedColors.gray20 }}>{strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_OR_OPEN_PAGE_ON_PHONE}</Text>
                                    <Text style={{ color: SharedColors.gray40, fontWeight: "bold" }}>
                                        {window.location.hostname}{window.location.hostname === "localhost" ? ":5001" : ""}/app/{"{processId}"}
                                    </Text>
                                </>
                            }
                        </Stack>
                    )}
            </Stack.Item>
            {props.warning && (
                <Stack.Item>
                    <MessageBar messageBarType={MessageBarType.warning}>
                        {props.warning}
                    </MessageBar>
                </Stack.Item>
            ) || (
                    <Stack.Item>
                        <MessageBar>
                            {strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_MESSAGE_BAR}
                        </MessageBar>
                    </Stack.Item>
                )}
        </Stack>
    )
}

const Unavailable: React.FC = () => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <FontIcon iconName="AlertSettings" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray10 }} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <MessageBar messageBarType={MessageBarType.warning}>
                    {strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_NO_ACCESS_TO_SERVICE} <b>Picea® Online Services</b>.
                </MessageBar>
            </Stack.Item>
        </Stack>
    )
}

const Connecting: React.FC = () => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <FontIcon iconName="Processing" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray10 }} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <ProgressIndicator label="Picea® Online Services" description={strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CONNECTION_TO_SERVICES} />
            </Stack.Item>
        </Stack>
    )
}

const Disconnected: React.FC = () => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <FontIcon iconName="AlertSettings" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.gray10 }} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <MessageBar messageBarType={MessageBarType.warning} actions={<MessageBarButton>{strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.BUTTON_RETRY}</MessageBarButton>}>
                    {strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.TEXT_CANT_CONNECT} <b>Picea® Online Services</b>.
                </MessageBar>
            </Stack.Item>
        </Stack>
    )
}

const Fail: React.FC<{ message?: string }> = (props) => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <FontIcon iconName="AlertSettings" style={{ fontSize: 128, height: 128, width: 128, color: SharedColors.red10 }} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <MessageBar messageBarType={MessageBarType.error} actions={<MessageBarButton>{strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.BUTTON_RETRY}</MessageBarButton>}>
                    {props.message ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.MESSAGE_UNKNOWN_ERROR}
                </MessageBar>
            </Stack.Item>
        </Stack>
    )
}

const Loading: React.FC = () => {
    return (
        <Stack verticalFill tokens={{ childrenGap: childrenGap }}>
            <Stack.Item verticalFill>
                <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <Spinner label={strings.SPINNERS.MODULE_LOADING} />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme()
