import { IContextualMenuProps, IDropdownOption, PrimaryButton, Stack, Text } from "@fluentui/react"
import { ISelfServiceWorkflowConfig } from "@piceasoft/core"
import React, { Dispatch } from "react"
import { strings } from "../../../../../../localization/strings"
import { InputField } from "../../Contract"
import ContactFormFieldEditor, { RanFieldNames, EditableContactField } from "./ContactFormFieldEditor"
import DocumentTemplateSelect from "./DocumentTemplateSelect"


interface TProps {
    config?: ISelfServiceWorkflowConfig
    pivots?: { name: string, component: React.ReactNode }[]
    isFetchingTemplates?: boolean
    documentTemplates?: IDropdownOption[]
    onChangeDispatch: Dispatch<any>
}

const RanFormSettings: React.FC<TProps> = (props: TProps) => {

    const[isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const[fieldToEdit, setFieldtoEdit] = React.useState<EditableContactField>('')
    const[dataToEdit, setDataToEdit] = React.useState<object|undefined>(undefined)
    const fields = props.config?.contactForms?.ranForm

    const updateFieldConfiguration = ( field: EditableContactField, value?: object) => {
        const ranForm = props.config?.contactForms?.ranForm ? {...props.config?.contactForms?.ranForm} : {enabled: true}

        if( "name" === field) {
            ranForm.name = value;
        }
        else if( "idNumber" === field) {
            ranForm.idNumber = value;
        }
        else if( "phone" === field) {
            ranForm.phone = value;
        }
        else if( "email" === field) {
            ranForm.email = value;
        }
        else if( "address" === field) {
            ranForm.address = value;
        }
        else if( "zipCode" === field) {
            ranForm.zipCode = value;
        }
        else if( "city" === field) {
            ranForm.city = value;
        }
        else if( "scheduleStart" === field) {
            ranForm.scheduleStart = value;
        }
        else if( "scheduleEnd" === field) {
            ranForm.scheduleEnd = value;
        }
        else if( "comments" === field) {
            ranForm.comments = value;
        }
        else if( "deviceIMEI" === field) {
            ranForm.deviceIMEI = value;
        }
        else if( "deviceBrand" === field) {
            ranForm.deviceBrand = value;
        }
        else if( "deviceModel" === field) {
            ranForm.deviceModel = value;
        }
        else if( "deviceColor" === field) {
            ranForm.deviceColor = value;
        }
        else if( "deviceInvoiceDate" === field) {
            ranForm.deviceInvoiceDate = value;
        }

        const settings = {...props.config}
        if( !settings.contactForms) {
            settings.contactForms = {}
        }
        settings.contactForms.ranForm = ranForm;
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: settings })
    }

    const setField = ( field: RanFieldNames, enable: boolean) => {
        const value = enable ? {} : undefined
        updateFieldConfiguration( field, value)
    }

    const editField = ( field: RanFieldNames) => {
        const data = props.config?.contactForms?.ranForm ? props.config?.contactForms?.ranForm[field] : undefined
        setDataToEdit( data)
        setFieldtoEdit( field)
        setIsModalOpen( true)
    }

    const onTemplateSelected = (item?: IDropdownOption) => {
        const settings = {...props.config}
        if( !settings.contactForms) {
            settings.contactForms = { ranForm: {}}
        }
        if( !settings.contactForms.ranForm) {
            settings.contactForms.ranForm = {}
        }
        settings.contactForms.ranForm.documentId = item?.key.toString() !== 'null' ? item?.key as number : undefined;
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: settings })
    }

    const addFieldMenuProps: IContextualMenuProps = {
        items: [
            {
                key: "name",
                onClick: () => setField( 'name', true),
                disabled: fields?.name ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.NAME.TITLE
            },
            {
                key: "idNumber",
                onClick: () => setField( 'idNumber', true),
                disabled: fields?.idNumber ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ID_NUMBER.TITLE
            },
            {
                key: "phone",
                onClick: () => setField( 'phone', true),
                disabled: fields?.phone ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PHONE.TITLE
            },
            {
                key: "email",
                onClick: () => setField( 'email', true),
                disabled: fields?.email ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.EMAIL.TITLE
            },
            {
                key: "address",
                onClick: () => setField( 'address', true),
                disabled: fields?.address ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ADDRESS.TITLE
            },
            {
                key: "zipCode",
                onClick: () => setField( 'zipCode', true),
                disabled: fields?.zipCode ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ZIP_CODE.TITLE
            },
            {
                key: "city",
                onClick: () => setField( 'city', true),
                disabled: fields?.city ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.CITY.TITLE
            },
            {
                key: "scheduleStart",
                onClick: () => setField( 'scheduleStart', true),
                disabled: fields?.scheduleStart ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.SCHEDULE_START.TITLE
            },
            {
                key: "scheduleEnd",
                onClick: () => setField( 'scheduleEnd', true),
                disabled: fields?.scheduleEnd ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.SCHEDULE_END.TITLE
            },
            {
                key: "comments",
                onClick: () => setField( 'comments', true),
                disabled: fields?.comments ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.COMMENTS.TITLE
            },
            {
                key: "deviceIMEI",
                onClick: () => setField( 'deviceIMEI', true),
                disabled: fields?.deviceIMEI ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_IMEI.TITLE
            },
            {
                key: "deviceBrand",
                onClick: () => setField( 'deviceBrand', true),
                disabled: fields?.deviceBrand ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_BRAND.TITLE
            },
            {
                key: "deviceModel",
                onClick: () => setField( 'deviceModel', true),
                disabled: fields?.deviceModel ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_MODEL.TITLE
            },
            {
                key: "deviceColor",
                onClick: () => setField( 'deviceColor', true),
                disabled: fields?.deviceColor ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_COLOR.TITLE
            },
            {
                key: "deviceInvoiceDate",
                onClick: () => setField( 'deviceInvoiceDate', true),
                disabled: fields?.deviceInvoiceDate ? true : false,
                text: strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_INVOICE_DATE.TITLE
            },
        ]
    }

    const allFieldsAdded = !!fields?.address && !!fields.city && !!fields.comments && !!fields.deviceBrand && !!fields.deviceColor
        && !!fields.deviceIMEI && !!fields.deviceInvoiceDate && !!fields.deviceModel && !!fields.email && !!fields.idNumber 
        && !!fields.name && !!fields.phone && !!fields.scheduleEnd && !!fields.scheduleStart && !!fields.zipCode

    return (<Stack tokens={{ childrenGap: 24 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <DocumentTemplateSelect
                label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.SELECT_TEMPLATE}
                placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.RAN_TEMPLATE_PLACEHOLDER}
                isFetching={props.isFetchingTemplates}
                selectionOptions={props.documentTemplates}
                selectedId={fields?.documentId}
                onSelected={onTemplateSelected}
             />
        </Stack>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Text><b>{strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.RAN_FORM}</b></Text>
            <PrimaryButton iconProps={{iconName: 'add'}} disabled={allFieldsAdded} menuProps={addFieldMenuProps} text={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.ADD_FIELDS}/>
        </Stack>
        <Stack tokens={{ childrenGap: 16 }}>
            { fields?.name && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.NAME.TITLE} 
                onConfig={ () => editField( "name")} 
                onDelete={ () => setField( 'name', false)}/>}
            { fields?.idNumber && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ID_NUMBER.TITLE}
                onConfig={ () => editField( "idNumber")} 
                onDelete={ () => setField( 'idNumber', false)}/>}
            { fields?.phone && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PHONE.TITLE} 
                onConfig={ () => editField( "phone")} 
                onDelete={ () => setField( 'phone', false)}/>}
            { fields?.email && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.EMAIL.TITLE} 
                onConfig={ () => editField( "email")} 
                onDelete={ () => setField( 'email', false)}/>}
            { fields?.address && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ADDRESS.TITLE} 
                onConfig={ () => editField( "address")} 
                onDelete={ () => setField( 'address', false)}/>}
            { fields?.zipCode && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ZIP_CODE.TITLE} 
                onConfig={ () => editField( "zipCode")} 
                onDelete={ () => setField( 'zipCode', false)}/>}
            { fields?.city && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.CITY.TITLE} 
                onConfig={ () => editField( "city")} 
                onDelete={ () => setField( 'city', false)}/>}
            { fields?.scheduleStart && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.SCHEDULE_START.TITLE} 
                onConfig={ () => editField( "scheduleStart")} 
                onDelete={ () => setField( 'scheduleStart', false)}/>}
            { fields?.scheduleEnd && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.SCHEDULE_END.TITLE} 
                onConfig={ () => editField( "scheduleEnd")} 
                onDelete={ () => setField( 'scheduleEnd', false)}/>}
            { fields?.comments && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.COMMENTS.TITLE} 
                onConfig={ () => editField( "comments")} 
                onDelete={ () => setField( 'comments', false)}/>}
            { fields?.deviceIMEI && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_IMEI.TITLE} 
                onConfig={ () => editField( "deviceIMEI")} 
                onDelete={ () => setField( 'deviceIMEI', false)}/>}
            { fields?.deviceBrand && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_BRAND.TITLE} 
                onConfig={ () => editField( "deviceBrand")} 
                onDelete={ () => setField( 'deviceBrand', false)}/>}
            { fields?.deviceModel && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_MODEL.TITLE} 
                onConfig={ () => editField( "deviceModel")} 
                onDelete={ () => setField( 'deviceModel', false)}/>}
            { fields?.deviceColor && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_COLOR.TITLE} 
                onConfig={ () => editField( "deviceColor")} 
                onDelete={ () => setField( 'deviceColor', false)}/>}
            { fields?.deviceInvoiceDate && <InputField name={strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_INVOICE_DATE.TITLE} 
                onConfig={ () => editField( "deviceInvoiceDate")} 
                onDelete={ () => setField( 'deviceInvoiceDate', false)}/>}
        </Stack>
        <ContactFormFieldEditor 
            isModalOpen={isModalOpen} 
            fieldId={fieldToEdit}
            fieldData={dataToEdit}
            customFields={[]}
            onCloseModal={ () => setIsModalOpen(false)} 
            onUpdateField={updateFieldConfiguration} 
        />
    </Stack> )
}
export default RanFormSettings;