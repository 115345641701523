import React, { Dispatch } from "react"
import { Stack } from "@fluentui/react"
import { ParameterItem } from "../../../../../components/ParameterItem"
import { strings } from "../../../../../../../../localization/strings"
import { actionCreators } from "../../../../../../../../core/actions/configurator-actions"
import { IEraseConfig, ProcessStages } from "@piceasoft/core"

export type TModeConfiguratorCommonPivotProps = {
    index: number
    erase: IEraseConfig
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
}

export const ModeConfiguratorCommonPivot: React.FC<TModeConfiguratorCommonPivotProps> = (props) => {
    const {erase, index, onChangeDispatch, stage} = props

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack>
                <ParameterItem
                    title={strings.CONSTRUCTOR.INSPECTIONS.ERASE.MANUAL_RESET_TO_FACTORY}
                    useToggle
                    noSeparator
                    toggleEnabled={false}
                    toggleChecked={true}
                    toggleOnChange={() => {
                        let config = {
                            allowManualFactoryReset: erase.config.allowManualFactoryReset ? undefined : true
                        }
                        onChangeDispatch(actionCreators.inspections.erase.editModeConfig(stage, index, config))
                    }}
                    description={strings.CONSTRUCTOR.INSPECTIONS.ERASE.MANUAL_RESET_TO_FACTORY_DESCRIPTION}
                />
            </Stack>
        </Stack>
    )
}
