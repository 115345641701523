import { Dropdown, Text, Stack, IComboBoxOption, Icon, SharedColors, getFocusStyle, mergeStyleSets, getTheme, MessageBar, MessageBarType } from "@fluentui/react"
import { useId } from "@uifabric/react-hooks"
import React, { Dispatch } from "react"
import { escapeRegExp } from "../../../../../../../../assets/ts/utils/regex"
import { actionCreators } from "../../../../../../../../core/actions/configurator-actions"
import { portalApi } from "../../../../../../../../core/api/api"
import { IDocument } from "../../../../../../../../core/store/typings/IDocument"
import { strings } from "../../../../../../../../localization/strings"
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from "../../../../../../search/SuggestedSearch"
import { IDataCollectionConfig, ProcessStages, IResponseError } from "@piceasoft/core"

type TProps = {
    index: number
    stage: ProcessStages
    dataCollection: IDataCollectionConfig
    onChangeDispatch: Dispatch<any>
}

export const DocumentsSuggestedSearch = (props: TProps) => {
    let {dataCollection, index, onChangeDispatch} = props

    const documentsHostId = useId("documents-host-id")
    const documentsTextId = useId("documents-text-id")
    const documentsCalloutListId = useId("documents-callout-id")
    const [documentsOptions, setDocumentsOptions] = React.useState<IComboBoxOption[]>()
    const [documentsFilterValue, setDocumentsFilterValue] = React.useState<string>()

    const [documentsState, setDocumentsState] =
        React.useState<{ fetched: boolean, data?: IDocument[], errors?: IResponseError[] }>({ fetched: false })
    
    React.useEffect(() => {
        getDocuments()
    }, [])

    const getDocuments = async () => {
        setDocumentsState({ fetched: false })
        const result = await portalApi.organization.documents.getDocuments()
        console.log(result)
        setDocumentsState({
                fetched: true,
                data: result.data?.filter(i => !i.removed),
                errors: (result.errors && result.errors.length > 0) ? result.errors : undefined
            })
    }

    React.useEffect(() => {
        prepareDocumentOptions()
    }, [documentsState])

    const documentOptionSelectHandler = (item?: IComboBoxOption) => {
        let documentId = item?.key.toString() === 'null' ? undefined : item?.key as number
        let config = {
            ...dataCollection,
            documentId
        }

        onChangeDispatch(actionCreators.inspections.dataCollection.editDataCollection(props.stage, index, config))
    }

    const onRenderDocumentsSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div style={{ cursor: 'pointer' }} className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${documentsCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, documentsOptions?.length ?? 0, documentsTextId, documentsCalloutListId, () => documentOptionSelectHandler(item), index, item?.text, documentsHostId)}
                tabIndex={-1}
                onClick={() => {
                    documentOptionSelectHandler(item)
                    focusElement(`${documentsCalloutListId}-${index as number}`, documentsHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    const prepareDocumentOptions = () => {
        setDocumentsOptions(undefined)
        if (documentsState.data) {
            setDocumentsOptions([{ key: 'null', text: strings.COMMON.NOT_CHOSED }, ...documentsState.data.map(i => {
                return { key: i.id, text: i.name } as IComboBoxOption
            })])
        }
    }

    const theme = getTheme()

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    return (
        <>
            {(documentsOptions?.length ?? 0) <= 10 && (
                <Dropdown
                    label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.DOCUMENT}
                    options={documentsOptions ?? []}
                    onChange={(ev, opt) => documentOptionSelectHandler(opt)}
                    disabled={!documentsOptions || documentsOptions.length === 0 || !documentsState.fetched}
                    styles={{ dropdown: { width: 320 } }}
                    selectedKey={dataCollection.documentId ?? 'null'}
                    placeholder={(!documentsState.fetched) ?
                        strings.SPINNERS.DATA_IS_GETTING :
                        documentsOptions?.find(i => i.key === dataCollection.documentId)?.text ?? (documentsOptions && documentsOptions.length > 0 ?
                            strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.SELECT_PLACEHOLDER :
                            strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NO_TEMPLATES)}
                />
            ) || (
                <SuggestedSearch
                    type="dropdown-search"
                    inputBoxId={documentsTextId}
                    hostId={documentsHostId}
                    label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.DOCUMENT}
                    inputBoxPlaceholder={(!documentsState.fetched) ?
                        strings.SPINNERS.DATA_IS_GETTING :
                        documentsOptions?.find(i => i.key === dataCollection.documentId)?.text ?? (documentsOptions && documentsOptions.length > 0 ?
                            strings.ORGANIZATION.CATALOGS.VERSIONS.VERSIONS_PLACEHOLDER :
                            strings.ORGANIZATION.CATALOGS.VERSIONS.NO_VERSIONS)}
                    onClickSuggestionCell={documentOptionSelectHandler}
                    // setSearchRequest={(opt: IComboBoxOption) => setDocumentsFilterValue(opt?.text)}
                    searchRequest={documentsFilterValue}
                    suggestionsListWidth={320}
                    inputBoxWidth={320}
                    disabled={!documentsOptions || documentsOptions.length === 0 || !documentsState.fetched}
                    suggestions={documentsOptions ?? []}
                    suggestionsListId={documentsCalloutListId}
                    isSearchEqualTheOneSuggestion={false}
                    onRenderSuggestionCell={onRenderDocumentsSuggestionsCell}
                    prepareFilteredItems={prepareFilteredOptions}
                    selectedKey={dataCollection.documentId?.toString() ?? 'null'}
                />
            )}
            {documentsState.errors && (
                documentsState.errors.map(e => <MessageBar messageBarType={MessageBarType.error} >{e.description}</MessageBar>)
            )}
        </>
    )
}