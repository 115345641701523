import { Text, Stack, SharedColors, Icon, getTheme, Spinner } from '@fluentui/react';
import * as React from 'react';
import { SecondaryButton } from '../../../components/shared/buttons/SecondaryButton';
import { ISupervisor } from '../../../core/store/typings/ISupervisor';
import { strings } from '../../../localization/strings';

export type TProps = {
    item: ISupervisor
    onEdit: () => void
}

export const SupervisorHeader: React.FC<TProps> = (props) => {
    return (
        <Stack horizontal grow style={{ padding: "24px 32px 8px 32px" }} >
            {props.item && (
                <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                    <Stack.Item>
                        <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 72, height: 72 }}>
                            <Icon iconName="Telemarketer" style={{ fontSize: 48 }} />
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack>
                            <Stack.Item>
                                <Text style={{ fontSize: "1.3125rem", letterSpacing: "-.02em", fontWeight: 600, color: theme.palette.black }}>{props.item.display}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text style={{ color: SharedColors.gray30 }}>{props.item.name}</Text>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            ) || (
                    <Stack verticalFill verticalAlign='center'>
                        <Spinner label={strings.SPINNERS.DATA_IS_LOADING} />
                    </Stack>
                )}
            <Stack.Item>
                <SecondaryButton text={strings.BUTTONS.TEXT.EDIT_DATA} onClick={props.onEdit} />
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
