import LocalizedStrings from "react-localization";

export const strings_login_en = {
    AUTHORIZATION: "Authorization",
    AUTHORIZATION_INFORMATION: "Please enter your login and password",
    LOGIN: "Login:",
    LOGIN_FAILED: "Login failed. Login or password is incorrect!",
    PASSWORD: "Password:",
    SIGN_IN: "Sign in"
}

export const strings_login_ru = {
    AUTHORIZATION: "Авторизация",
    AUTHORIZATION_INFORMATION: "Выполните вход под своим логином и паролем.",
    LOGIN_FAILED: "Не удалось войти в систему. Логин или пароль неверны!",
    LOGIN: "Имя пользователя:",
    PASSWORD: "Пароль:",
    SIGN_IN: "Войти"
}

export const stringsLogin = new LocalizedStrings({
    en: {
        PORTAL: "PORTAL",
        PLEASE_WAIT: "Loading...",
        AUTHORIZATION: "Authorization",
        AUTHORIZATION_INFORMATION: "Please enter your login and password",
        LOGIN: "Login:",
        PASSWORD: "Password:",
        SIGN_IN: "Sign in",
        LOGIN_FAILED: "Login failed. Login or password is incorrect!",
    },
    ru: {
        PORTAL: "ПОРТАЛ",
        PLEASE_WAIT: "Пожалуйста, подождите...",
        AUTHORIZATION: "Авторизация",
        AUTHORIZATION_INFORMATION: "Выполните вход под своим логином и паролем.",
        LOGIN: "Имя пользователя:",
        PASSWORD: "Пароль:",
        SIGN_IN: "Войти",
        LOGIN_FAILED: "Не удалось войти в систему. Логин или пароль неверны!",
    }
});