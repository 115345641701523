import {FontWeights, getFocusStyle, getTheme, mergeStyleSets, SharedColors} from "@fluentui/react";

const theme = getTheme();
export const elementHeight = 36;
export const paddingLeftDefault = 16;
export const itemPadding = `8px 8px 8px ${paddingLeftDefault}px`;

export const styles = mergeStyleSets({
    button: {
        width: 130,
    },
    callout: {
        width: 320
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    description: {
        marginBottom: 12,
    },
    runType: {
        color: SharedColors.gray10,
    },
    supported: {
        marginBottom: 6,
        fontWeight: FontWeights.semibold,
    },
    platform: {
        color: SharedColors.cyanBlue10,
        fontWeight: FontWeights.semibold,
    },
    link: {
        display: 'block',
        marginTop: 20,
    },
    listItem: [
        {
            minHeight: elementHeight,
            padding: itemPadding,
            borderLeft: '2px solid transparent',
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        },
    ],
    activeListItem: [
        {
            padding: itemPadding,
            background: 'rgba(222,236,249,1)',
            borderLeft: `2px solid ${theme.palette.themePrimary}`,
            ':hover': {
                cursor: 'pointer',
                '.menu-icon': {
                    fontSize: 16,
                    color: theme.palette.black
                }
            }
        }
    ],
    itemCell: [
        getFocusStyle(theme, {
            inset: -1,
            outlineColor: theme.palette.themePrimary,
            borderColor: theme.palette.themePrimary,
        }),
        {
            minHeight: elementHeight,
            padding: itemPadding,
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': {background: theme.semanticColors.bodyDivider}
            },
        },
    ],
    cellBgGrey: {
        minHeight: elementHeight,
        backgroundColor: theme.palette.neutralLighterAlt,
        borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`
    },
    sectionTitle: {
        fontWeight: 600,
    },
    sectionTitleWr: {
        padding: itemPadding,
        borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`
    }
});
