export const updateUTCDateTime = (prevDatetime: Date, newDatetimePart: Date | null | undefined, timeUpdate?: boolean) => {
	return new Date(
			Date.UTC(
					((timeUpdate ? prevDatetime : newDatetimePart) as Date)?.getFullYear(),
					((timeUpdate ? prevDatetime : newDatetimePart) as Date)?.getMonth(),
					((timeUpdate ? prevDatetime : newDatetimePart) as Date)?.getDate(),
					((timeUpdate ? newDatetimePart : prevDatetime) as Date)?.getHours() ?? ((timeUpdate ? prevDatetime : newDatetimePart) as Date)?.getHours(),
					((timeUpdate ? newDatetimePart : prevDatetime) as Date)?.getMinutes() ?? ((timeUpdate ? prevDatetime : newDatetimePart) as Date)?.getMinutes()
			)
	)
}

export const formatTime = (date: Date) => {
let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
return  `${hours}:${minutes}`
}

export const getUtcDate=(date : Date)=>{
	date = new Date(date);
	let utcTime = Date.UTC(
		date.getFullYear(),
		date.getMonth(),
		date.getDate());
	return new Date(utcTime);
}

export const getUTCStartDate = (validFrom: Date) => {
	let utcDate = getUtcDate(validFrom);
	// Set the time to start of the UTC day
	utcDate.setHours(0, 0, 0, 0);
	return utcDate;
}
export const getUTCEndDate = (expireDate: Date) => {
	let utcDate = getUtcDate(expireDate);
	// Set the time to the end of the day in UTC
	utcDate.setHours(23, 59, 59, 997);
	return utcDate;
}

export const compareCurrentUTCDateTime = (expireDate: Date, checkGreater : boolean) => {
	let currentUTCDate = new Date(new Date().toISOString());
	let expiredDate = new Date(expireDate);
     return checkGreater ? currentUTCDate > expiredDate : currentUTCDate < expiredDate;
}
