import {
    getTheme, Stack, IconButton, DirectionalHint, IContextualMenuProps,
    Text, SharedColors, PrimaryButton, Image, ImageFit,
    Icon
} from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React, { Dispatch, useState } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../../../../core/scripts/style";
import { strings } from "../../../../../../localization/strings";
import { ItemsNotFound } from "../../../../notFound/ItemsNotFound";
import { CustomPanel } from "../../../../panel/CustomPanel";
import { ISelfServiceWorkflowConfig, IGrade, IRepairOfferProviderConfig } from "@piceasoft/core";
import { onRenderLabel } from "../../../../../renders/onRenderLabel";
import { RepairOfferProviderForm } from "../../../../forms/RepairOfferProviderForm";
import { Tooltip } from "../../../../help/Tooltip";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: ISelfServiceWorkflowConfig
    grades?: IGrade[]
    offerProviderCode?: string
}

export const RepairOfferProviderList: React.FC<TProps> = ({ config, onChangeDispatch, grades, offerProviderCode }) => {

    const [panelState, setPanelState] = React.useState<{ index: number, item: IRepairOfferProviderConfig }>()
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)

  React.useEffect(() => {
        if (offerProviderCode) {
            let index = 0
            const providerItem = config?.repairOffers?.providers.find((i, localIndex) => {
                index = localIndex;
                return i.code === offerProviderCode ? true : false;
            })
            if (providerItem) {
                setPanelState({ index: index, item: providerItem })
            }
        }
    }, [])
    React.useEffect(() => {
        if (panelState && !isPanelOpen) {
            showPanel()
        }
    }, [panelState])

    if (!config) return null
    
    const onCancel = () => {
        hidePanel();
        setTimeout(() => setPanelState(undefined), 100);
    }

    const onEdit = (item: IRepairOfferProviderConfig, index: number) => {
        setPanelState({ index, item });
    }

    const onAdd = () => {
        showPanel()
    }

    const onDelete = async (index: number) => {

        let newProviders = config?.repairOffers?.providers.filter((op, opIndex) => opIndex !== index)
            ?.map(item => {
                // adjust index on rest items in array
                if (item.index > index && item.index > 0)
                    item.index--;
                return item;
            }) ?? [];

        populateProvidersIndex(newProviders);
        onChangeDispatch(actionCreators.selfService.setRepairOfferProviders(newProviders));
        onCancel();
    };

    const onCommit = async (item: IRepairOfferProviderConfig) => {
        console.log("onCommit: %O", item)
        let newProviders: IRepairOfferProviderConfig[] = []
        if (panelState?.index !== undefined) {
            // update existing
            newProviders = config?.repairOffers?.providers?.map((op, aIndex) => {
                if (panelState.index === aIndex) {
                    return item;
                }
                return op;
            }) ?? [];
        } else {
            // new item, update index to max.
            item.index = config?.repairOffers?.providers?.length ?? 0;
            newProviders = [...(config?.repairOffers.providers ?? []), item];
        }

        populateProvidersIndex(newProviders);
        onChangeDispatch(actionCreators.selfService.setRepairOfferProviders(newProviders));
        onCancel();
    };

    /** 
     * Adds index values to array objects, based on current array order.
     * Index should be already there, but this more like safeguard for old configs to make sure index is really uptodate for all items
     */
    const populateProvidersIndex = (items: IRepairOfferProviderConfig[]) => {
        items.forEach((item, index) => {
            item.index = index;
        });
    }
    return (
        <>
            <div style={{ marginTop: 10, marginBottom: -7 }} />
            {(config.repairOffers?.providers && config.repairOffers.providers.length > 0) && (
                <>
                    <ProvidersList
                        items={config.repairOffers.providers}
                        onDelete={(index) => onDelete(index)}
                        onEdit={(item: IRepairOfferProviderConfig, index) => onEdit(item, index)}
                    />
                    <Stack tokens={{ padding: "12px 0px", childrenGap: 16 }}>
                        <Stack.Item>
                            <PrimaryButton onClick={onAdd}>{strings.BUTTONS.TEXT.ADD}</PrimaryButton>
                        </Stack.Item>
                    </Stack>
                </>
            ) || (
                    <ItemsNotFound
                        info={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_REPAIR.FORM.NOT_FOUND.INFO}
                        suggestion={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_REPAIR.FORM.NOT_FOUND.SUGGESTION}
                        buttonText={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_REPAIR.FORM.NOT_FOUND.BUTTON_TEXT}
                        imgSrc={"images/navigation/images/not_found.png"}
                        onClick={showPanel}
                    />
                )}
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                noCancelOnDissmiss={true}
                title={panelState ?
                    strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER.EDIT :
                    strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER.NEW
                }
            >
                <RepairOfferProviderForm
                    onFormSubmit={onCommit}
                    onCancel={onCancel}
                    data={panelState?.item}
                    codes={config.repairOffers?.providers?.filter(i => i.code !== panelState?.item.code).map(i => i.code) ?? []}
                />
            </CustomPanel>
        </>
    )
}


type TProvidersListProps = {
    items?: IRepairOfferProviderConfig[];
    onEdit: (item: IRepairOfferProviderConfig, arrIndex: number) => void;
    onDelete: (arrIndex: number) => void;
}
const ProvidersList: React.FC<TProvidersListProps> = ({ items, onEdit, onDelete }) => {
    return <div style={providersListStyle}>
        {items?.map((item, index) => <ProviderRow key={index} arrIndex={index} item={item} onEdit={onEdit} onDelete={onDelete} />)}
    </div>

}

type TProviderRowProps = {
    arrIndex: number
    item: IRepairOfferProviderConfig;
    onEdit: (item: IRepairOfferProviderConfig, index: number) => void;
    onDelete: (arrIndex: number) => void;
}
const ProviderRow: React.FC<TProviderRowProps> = ({ item, onEdit, onDelete, arrIndex }) => {
    const [isHovered, setIsHovered] = useState(false);
    const getItemMenuProps = (item: IRepairOfferProviderConfig, arrIndex: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.code}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => onEdit(item, arrIndex),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.code}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => onDelete(arrIndex),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }
    const getCustomStyles = (isHovered: boolean) => {
        const baseStyles: React.CSSProperties = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
            boxShadow: '0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13), 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.10)',
            backgroundColor: isHovered ? '#f3f2f1' : "",
        };

        return {
            ...baseStyles,
        };
    }

    return (
        <div key={`row-${item.index}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
            // style={providerRowStyle}
            style={getCustomStyles(isHovered)}
        >

        <div className={`provider-list-item ${item.errors && item.errors.length > 0 ? 'error-row' : ''}`} style={{border: `1px solid ${theme.palette.neutralLight}`}} onClick={() => onEdit(item, arrIndex)} >
            {/** type /*/}
            <div style={{ ...cellStyle, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 120 }}>
                {item.imageSrc && (
                    <Stack horizontalAlign='center'>
                        <Image src={item.imageSrc} width={40} height={40} imageFit={ImageFit.cover} style={{ borderRadius: 20 }} />
                    </Stack>
                ) || (
                        <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: getPersonaColorsPallete[item.name[0].toLowerCase()] }}>
                            <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                                {getIconSymbolsFromString(item.name)}
                            </Text>
                        </Stack>
                    )}
                    <Icon style={{ fontSize: 32 }} iconName='ProductCatalog'/>
            </div>

            {/** description /*/}
            <div style={{ ...cellStyle, minWidth: 150, maxWidth: 400, flex: 3, flexDirection: 'column' }}>
                <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
                <Text variant="small" style={{ color: SharedColors.gray30, maxHeight: 50, overflowY: 'hidden' }}>{item.description}</Text>
            </div>

            {/** code /*/}
            <div style={{ ...cellStyle, maxWidth: 270, flex: 1 }}>

                <Text variant="medium" nowrap style={{ color: SharedColors.gray30, fontWeight: 500 }}>{item.code}</Text>
                <Text variant="medium" nowrap style={{ color: SharedColors.gray30 }}>{item.endpoint}</Text>
            </div>

            {/** commandbar /*/}
            <div style={{ ...cellStyle, width: 70, marginLeft: 'auto' }} onClick={(ev) => ev.stopPropagation()}>
                {item.errors && item.errors.length > 0 && (
                        <Stack.Item>
                            <Stack verticalAlign="center" verticalFill>
                                <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DELETED_CATALOG}>
                                    <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                                </Tooltip>
                            </Stack>
                        </Stack.Item>
                    )}                
                <Stack.Item>
                    <IconButton size={16} menuIconProps={{ iconName: "MoreVertical", className: 'provider-menu-icon' }} className="provider-menu-icon" style={{ color: isHovered ? "black" : "transparent" }} menuProps={getItemMenuProps(item, arrIndex)} />
                </Stack.Item>
            </div>
        </div>
        </div>)
}


const theme = getTheme();

const providersListStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
}

const providerRowStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};

const cellStyle: React.CSSProperties = {
    display: 'flex',
    padding: '5px'
}

