import * as React from 'react'
import {
    FontWeights, getTheme, IconButton, mergeStyleSets, MessageBar,
    MessageBarType, Modal, Pivot, PivotItem, PrimaryButton,
    Spinner, Stack, Sticky, StickyPositionType, Text
} from "@fluentui/react"
import { ResponsiblePersonsPivotBasedState } from './ResponsiblePersons/ResponsiblePresonsPivotBasedState';
import { strings } from '../../localization/strings';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { useBoolean } from '@uifabric/react-hooks';
import { IResponsiblePerson, IStore } from '../../core/store';
import { portalApi } from '../../core/api/api';
import { ResponsibleAreas } from '../../core/store/typings/ResponsibleAreas';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { ResponsiblePersonForm } from '../../components/shared/forms/ResponsiblePersonForm';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { useSelector } from 'react-redux';

export type TResponsiblePersonsPivotState = "moderation" | "prices"

export type TResponsiblePersonState = { fetched: boolean, error?: string, data?: Array<IResponsiblePerson> }

const getTabId = (itemKey: string) => {
    return `ResponsiblePersonsPivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "moderation": return strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.MODERATION.TITLE
        case "prices": return strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.PRICES.TITLE
        default: return m;
    }
}

export const ResponsiblePersons: React.FC<{}> = (props) => {

    const organization = useSelector((s: IStore) => s.workplace.organization)

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TResponsiblePersonsPivotState>("moderation");

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const [searchString, setSearchString] = React.useState<string>()

    const [selectedArea, setSelectedArea] = React.useState<ResponsibleAreas>(ResponsibleAreas.Moderation)
    const [personsState, setPersonsState] = React.useState<TResponsiblePersonState>({ fetched: false })
    const [items, setItems] = React.useState<IResponsiblePerson[]>([])

    const [person, setPerson] = React.useState<IResponsiblePerson>()
    const [deletePersonState, setDeletePersonState] = React.useState<IResponsiblePerson>()

    React.useEffect(() => {
        requestResponsiblePersons()
    }, [])

    React.useEffect(() => {
        switch (selectedPivotKey) {
            case "prices":
                setSelectedArea(ResponsibleAreas.Prices)
                break;
            default:
                setSelectedArea(ResponsibleAreas.Moderation)
        }
    }, [selectedPivotKey])

    React.useEffect(() => {
        setPersonsState({ fetched: false })
        requestResponsiblePersons()
    }, [selectedArea])

    React.useEffect(() => {
        if (personsState.data && personsState.data !== items) {
            setItems(personsState.data)
        }
    }, [personsState])

    React.useEffect(() => {
        setItems(() => {
            var newItems = personsState.data ? [...personsState.data] : []
            if (searchString) {
                newItems = [
                    ...((newItems as IResponsiblePerson[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.description,
                            i.email,
                            i.name,
                            `${i.phone?.prefix ?? ''}${i.phone?.number ?? ''}`,
                            i.telegramChannel,
                            i.telegramUser
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as IResponsiblePerson[]
        })
    }, [searchString])

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TResponsiblePersonsPivotState);
    };

    const requestResponsiblePersons = async () => {
        setPersonsState({ fetched: false })
        const result = await portalApi.organization.responsiblePersons.getResponsiblePersons(selectedArea)
        setPersonsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
    }

    const onCancel = () => {
        hidePanel()
        setTimeout(() => setPerson(undefined), 1000)
    }

    const onAddPerson = () => {
        stopFetch()
        setFormError(undefined)
        setPerson({ responsibleArea: selectedArea, })
        showPanel()
    }

    const addPerson = async (item: IResponsiblePerson) => {
        startFetch()
        const result = await portalApi.organization.responsiblePersons.addResponsiblePerson(item)
        if (result.successed && result.data) {
            console.log(result)
            setPersonsState({ ...personsState, data: personsState.data ? [...personsState.data, result.data] : [result.data] })
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onEditPerson = (item: IResponsiblePerson) => {
        stopFetch()
        setFormError(undefined)
        setPerson({ ...item, responsibleArea: selectedArea })
        showPanel()
    }

    const updatePerson = async (item: IResponsiblePerson) => {
        startFetch()
        const result = await portalApi.organization.responsiblePersons.updateResponsiblePerson(item)
        if (result.successed && result.data) {
            console.log(result)
            if (item.responsibleArea !== selectedArea) {
                setPersonsState({
                    ...personsState,
                    data: personsState.data?.filter(c => c.id !== result.data?.id)
                })
            } else {
                setPersonsState({
                    ...personsState, data: personsState.data && [...personsState.data.map(p => {
                        if (result.data && p.id === result.data?.id) {
                            return result.data
                        }
                        return p
                    })]
                })
            }
            hidePanel()
            stopFetch()
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onDeletePerson = () => {
        if (deletePersonState?.id) {
            deletePerson(deletePersonState.id, deletePersonState.responsibleArea)
            setDeletePersonState(undefined)
        }
    }

    const deletePerson = async (responsiblePersonId: string, area: ResponsibleAreas) => {
        console.log('deletePerson ' + responsiblePersonId)
        setPersonsState({ ...personsState, error: undefined })
        const result = await portalApi.organization.responsiblePersons.deleteResponsiblePerson(responsiblePersonId, area)
        if (result.successed) {
            if (personsState.data) {
                setPersonsState({
                    ...personsState,
                    data: personsState.data.filter(c => c.id !== responsiblePersonId)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setPersonsState({ ...personsState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setPersonsState({ ...personsState, error: "There is a problem! Responsible person wasn't deleted! Server response false without an error" });
        }
    }

    const onSubmitHandler = (data: IResponsiblePerson) => {
        setFormError(undefined)
        person?.id ? updatePerson(data) : addPerson(data)
    }

    return (
        <>
            <Stack verticalFill>
                <ScreenHeader title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.TITLE}>
                    <Stack.Item style={{minHeight: 32}}>
                    {personsState.fetched && personsState.data && personsState.data.length > 0 && (
                        <PrimaryButton onClick={onAddPerson} text={strings.ORGANIZATION.RESPONSIBLE_PERSONS.BUTTONS.ADD_RESPONSIBLE_PERSON} />
                    )}
                    </Stack.Item>
                </ScreenHeader>
                <Stack.Item>
                    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                        <Stack tokens={{ padding: "0px 32px 0px 32px" }}>
                            <Stack.Item>
                                <Pivot selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="menu">
                                    <PivotItem itemKey={"moderation"} headerText={getPivotHeaderText("moderation")} />
                                    <PivotItem itemKey={"prices"} headerText={getPivotHeaderText("prices")} />
                                </Pivot>
                            </Stack.Item>
                        </Stack>
                    </Sticky>
                </Stack.Item>
                <Stack.Item verticalFill>
                    <Stack verticalFill tokens={{ childrenGap: 16 }} style={{ position: 'relative' }}>
                        <ResponsiblePersonsPivotBasedState
                            area={selectedArea}
                            items={items}
                            onAddPerson={onAddPerson}
                            onEditPerson={onEditPerson}
                            personsState={personsState}
                            requestResponsiblePersons={requestResponsiblePersons}
                            setDeletePersonState={setDeletePersonState}
                            setSearchString={setSearchString}
                            state={selectedPivotKey}
                        />
                    </Stack>
                </Stack.Item>
            </Stack>
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                noCancelOnDissmiss={true}
                title={person?.id ? strings.ORGANIZATION.RESPONSIBLE_PERSONS.EDIT_RESPONSIBLE_PERSON : strings.ORGANIZATION.RESPONSIBLE_PERSONS.ADD_RESPONSIBLE_PERSON}
            >
                {formError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {formError}
                    </MessageBar>
                )}
                {(isPanelOpen && !fetching) && (
                    <ResponsiblePersonForm
                        onSubmit={onSubmitHandler}
                        onCancel={onCancel}
                        data={person}
                        organizationCountry={organization?.region}
                    />
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
            </CustomPanel>
            {deletePersonState && (
                <Modal isOpen={deletePersonState ? true : false} onDismiss={() => setDeletePersonState(undefined)} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.RESPONSIBLE_PERSONS.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close"
                            onClick={() => setDeletePersonState(undefined)}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={onDeletePerson}
                            onCancel={() => setDeletePersonState(undefined)}
                            requiredMessageString={strings.ORGANIZATION.RESPONSIBLE_PERSONS.EMAIL_VALID_REQUIRED_MESSAGE}
                            confirmationCheckString={deletePersonState?.email ?? ''}
                            placeholder={strings.ORGANIZATION.RESPONSIBLE_PERSONS.FIELDS.EMAIL_PLACEHOLDER}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.RESPONSIBLE_PERSONS.REMOVE_CONFIRMATION_TEXT1} <b>"{deletePersonState.name}"</b>.</Text>
                                <Text>{strings.ORGANIZATION.RESPONSIBLE_PERSONS.REMOVE_CONFIRMATION_TEXT2}</Text>
                                <Text unselectable="on">{strings.ORGANIZATION.RESPONSIBLE_PERSONS.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{deletePersonState.email}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>
            )}
        </>
    )
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
