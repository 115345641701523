import React, {Dispatch, FC, Fragment, useCallback, useMemo} from 'react';
import {
    DirectionalHint,
    Icon,
    IconButton,
    IContextualMenuProps,
    List,
    Panel,
    PanelType,
    SharedColors,
    Stack,
    Text,
    TooltipHost
} from "@fluentui/react";
import {DiagnosticCasesMap} from "../../../../../../core/store/typings/DiagnosticCasesMap";
import {ConnectionTypes, definitions, DiagnosticCases, DiagnosticsModes} from "@piceasoft/core";
import {itemPadding, paddingLeftDefault, styles} from "./styles";
import {onRenderCaseInfo} from "./onRenderCaseInfo";
import {Badge} from "../../../../decorations/Badge";
import {actionCreators} from "../../../../../../core/actions/configurator-actions";
import {ISelfServiceDiagnosticCase} from "@piceasoft/core/dist/interfaces/ISelfServiceDiagnosticCase";
import {strings} from "../../../../../../localization/strings";
import {casesFilterFunc, connectionTypeToMode, modeToConnectionType} from "../../../../../../core/helpers/self-service";

interface IProps {
    onChangeDispatch: Dispatch<any>
    isOpen: boolean
    hideConfiguration: () => void
    cases?: ISelfServiceDiagnosticCase[]
    casesMap?: DiagnosticCasesMap
    onOptionsClick: (caseId: number)=> void
}

export const PANEL_WIDTH = 524;
const connectionTypes = [ConnectionTypes.NG_WIRELESS, ConnectionTypes.WEB]
const casesSupportingOptions = [DiagnosticCases.TC_RAM, DiagnosticCases.TC_STORAGE, DiagnosticCases.TC_UPTIME, DiagnosticCases.TC_SLEEPTIME];

const connectionTypeToString = (ct: ConnectionTypes) => {
    switch (ct) {
        case ConnectionTypes.WEB:
            return strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.WEB
        case ConnectionTypes.NG_WIRELESS:
            return strings.TRANSACTION.INSPECTIONS.DIAGNOSTICS.MOBILE
        default:
            return 'UNKNOWN'
    }
}

const DiagnosticsConfiguration: FC<IProps> = ({onChangeDispatch, isOpen, hideConfiguration, cases, casesMap, onOptionsClick}) => {
    const sets = useMemo(() => definitions.diagnostic.db, [])

    const onRenderCell = useCallback((item?: { isSelected: boolean, data: DiagnosticCases }, index?: number) => {
        if (item) {
            const itemId = item?.data
            const text = casesMap ? casesMap[item?.data] : item?.data ?? '';
            const imageString = definitions.diagnostic.getImage(itemId)
            const diagnosticCase = definitions.diagnostic.getCase(itemId)
            let base64icon = ''
            if (imageString) {
                base64icon = btoa(imageString)
            }
            const menuProps: IContextualMenuProps = {
                items: []
            }
            diagnosticCase?.platforms.forEach((p) => {
                const cts = Array.from(new Set(p.connectionTypes.filter(ct => connectionTypes.includes(ct))))
                menuProps.items = cts.map((ct) => ({
                    key: `${p.id}-ct-${ct}`,
                    data: ct,
                    text: connectionTypeToString(ct),
                    onClick: () => {
                        let mode: DiagnosticsModes | undefined = undefined
                        switch (ct) {
                            case ConnectionTypes.WEB:
                                mode = DiagnosticsModes.WebBased
                                break
                            case ConnectionTypes.NG_WIRELESS:
                                mode = DiagnosticsModes.PiceaMobile
                                break
                        }
                        if (mode) {
                            onChangeDispatch(actionCreators.selfService.updateDiagnosticCase(itemId, mode))
                        }
                    }
                }))
                if(casesSupportingOptions.includes(item.data)){
                    menuProps.items.push({
                    key: `${p.id}-ct-options`,
                    data:  strings.BUTTONS.TEXT.OPTIONS,
                    text: strings.BUTTONS.TEXT.OPTIONS,
                    onClick: () => {
                        onOptionsClick(item.data)
                        //If the ai api is updated with new diagnostics cases, old self-service workflows
                        //doesn't know about the updates, and need to update the diagnostic cases manually. 
                        const itemExistsInCases = cases?.some(c => c.caseId === itemId);
                        if(!itemExistsInCases) {
                            const mode = connectionTypeToMode(menuProps.items[0].data) ?? DiagnosticsModes.PiceaMobile;
                            onChangeDispatch(actionCreators.selfService.updateDiagnosticCase(itemId, mode))
                        }
                    }
                })
                }
            })
            const connectionTypeSelected = modeToConnectionType(cases?.find(c => c.caseId === itemId)?.mode) ?? menuProps.items[0].data

            return (
                <Stack horizontal
                       verticalAlign="center"
                       grow tokens={{childrenGap: 8}}
                       key={`${item?.data}-${index}` ?? ""}
                       className={styles.itemCell}>
                    <Stack.Item>
                        {imageString && (
                            <img alt='image' style={{height: 16, width: 16}}
                                 src={`data:image/svg+xml;base64,${base64icon}`}/>
                        ) || (
                            <Icon style={{fontSize: 16}} iconName='TestBeakerSolid'/>
                        )}
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack horizontal verticalAlign="center" tokens={{childrenGap: 8}}>
                            <Text block nowrap variant="medium"
                                  style={{
                                      maxWidth: 500,
                                      color: SharedColors.gray40
                                  }}>{diagnosticCase?.getName()}</Text>
                            <TooltipHost calloutProps={{gapSpace: 10}}
                                         content={onRenderCaseInfo(diagnosticCase)}
                                         directionalHint={DirectionalHint.rightCenter}>
                                <Icon iconName="Info"  style={{cursor: 'pointer'}}/>
                            </TooltipHost>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Badge title={connectionTypeToString(connectionTypeSelected)}/>
                    </Stack.Item>
                    <Stack.Item style={{width: 32}}>
                        {menuProps.items.length > 1 && (
                            <IconButton menuIconProps={{iconName: "MoreVertical"}} menuProps={menuProps}/>
                        )}
                    </Stack.Item>
                </Stack>
            )
        }
        return <></>
    }, [casesMap, cases])

    return (
        <Panel type={PanelType.custom}
               customWidth={`${PANEL_WIDTH}px`}
               isOpen={isOpen}
               onDismiss={hideConfiguration}
               headerText={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.DIAGNOSTIC_SETTINGS_TITLE}>
            {sets
                .filter(s => s.cases.some(casesFilterFunc))
                .map(set => (
                    <Fragment key={`cases-config-set-${set.id}`}>
                        <Stack horizontal grow verticalAlign='center' className={styles.cellBgGrey}
                               style={{padding: itemPadding, paddingLeft: paddingLeftDefault}}>
                            <Text variant="medium" style={{fontWeight: 600}}>{set.getName()}</Text>
                        </Stack>
                        <List
                            items={
                                set.cases
                                    .filter(casesFilterFunc)
                                    .map((c) => ({data: c.id, isSelected: false}))
                            }
                            onRenderCell={onRenderCell}
                            getKey={(item) => String(item?.data)}
                        />
                    </Fragment>
                ))}
        </Panel>
    );
};

export default DiagnosticsConfiguration;
