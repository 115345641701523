import { FontIcon, Icon, IPivotItemProps, IRenderFunction, Pivot, PivotItem, SharedColors, Stack, StackItem, Text } from "@fluentui/react"
import React from "react"
import { IGrade, IQuestion } from "@piceasoft/core"
import { strings } from "../../../../../../../../../../localization/strings"
import { SequenceQuestionAnswers } from "./SequenceQuestionAnswers"
import { SequenceQuestionForm } from "./SequenceQuestionForm"
import { SequenceQuestionImagesForm } from "./SequenceQuestionImagesForm"
import { IAnswerOption } from "@piceasoft/core"
import { ValidationErrors } from "../../../../../../../../../../core/store/typings/ValidationErrors"
type TSequenceQuestionPivotStateProps = {
    question: IQuestion
    onConfirm: (data: IQuestion) => void
    grades: IGrade[]
    questions: IQuestion[]
}

export type TSequenceQuestionPivotState = "question" | "answers" | "images"

export const SequenceQuestion: React.FC<TSequenceQuestionPivotStateProps> = ({ question, onConfirm, grades, questions }) => {

    const [state, setPivotState] = React.useState<TSequenceQuestionPivotState>("question")
    const questionError = question.errors?.find((error) => error.code === ValidationErrors.QuestionAnswersEmpty);
    const titleQuestionError = question.errors?.find((error) => error.code === ValidationErrors.QuestionsTitleEmpty);
    
    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(item?.props.itemKey! as TSequenceQuestionPivotState);
    };

    const getTabId = (itemKey: string) => {
        return `QuestionPivot_${itemKey}`;
    };

    const getPivotHeaderText = (m: string): string => {
        switch (m) {
            case "question": return strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.PIVOTS.QUESTION
            case "answers": return strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.PIVOTS.ANSWERS
            case "images": return strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.PIVOTS.IMAGES
            default: return m;
        }
    }
    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }} >
                {iProps?.style?.color === SharedColors.red10 && (
                    <FontIcon iconName={'Warning'} style={{ fontSize: 14, color: SharedColors.red10 }} />
                )}
                <Text style={{ color: iProps?.style?.color, fontWeight: (state === iProps?.itemKey) ? 600 : undefined }}>{iProps?.headerText}</Text>
            </Stack>
        )

    }
    return (
        <Stack verticalFill>
            <Stack.Item tokens={{ padding: "16px 16px 0px 16px" }}>
                <Pivot selectedKey={state} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                    <PivotItem headerText={getPivotHeaderText("question")} itemKey="question" style={titleQuestionError && {color: SharedColors.red10}} onRenderItemLink={onRenderItemLink}/>
                    <PivotItem headerText={getPivotHeaderText("answers")} itemKey="answers" style={questionError && {color:SharedColors.red10}} onRenderItemLink={onRenderItemLink} />
                    <PivotItem headerText={getPivotHeaderText("images")} itemKey="images" onRenderItemLink={onRenderItemLink}/>
                </Pivot>
            </Stack.Item>
            <Stack.Item verticalFill>
                <PivotBasedState {...{ question, onConfirm, state, grades, questions }} />
            </Stack.Item>
        </Stack>
    )

}

type TStateProps = {
    state: TSequenceQuestionPivotState
    question: IQuestion
    onConfirm: (question: IQuestion) => void
    grades: IGrade[]
    questions: IQuestion[]
}

const PivotBasedState: React.FC<TStateProps> = ({ state, question, onConfirm, grades, questions }) => {
    const onAnswersConfirm = (answers: IAnswerOption[]) => {
        console.log(answers)
        onConfirm({
            ...question,
            answers: answers
        })
    }

    const onImagesConfirm = (images?: string[]) => {
        onConfirm({
            ...question,
            images: images
        })
    }

    switch (state) {
        case "question": return <SequenceQuestionForm question={question} onConfirm={onConfirm} />
        case "answers": return <SequenceQuestionAnswers answers={question.answers} onConfirm={onAnswersConfirm} grades={grades} questions={questions} />
        case "images": return <SequenceQuestionImagesForm images={question.images} onConfirm={onImagesConfirm} />
    }
}
