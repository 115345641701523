import React from "react";
import { Stack, Pivot, PivotItem, DirectionalHint, Icon, IPivotItemProps, IRenderFunction, TooltipHost, getTheme, IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject, SharedColors } from "@fluentui/react"
import { strings } from "../../../../../../localization/strings"
import { useBoolean } from "@uifabric/react-hooks";

type TProps = {
    color?: string
}


export const ModulePreviewWrapper: React.FC<TProps> = (props) => {
    const [isPreviewStageExpanded, { toggle: toggleIsPreviewStageExpanded }] = useBoolean(false)
    const expandItem = React.createRef<HTMLDivElement>();
    const pivotPreviewClick = () => {
        console.log('sdsd')
        toggleIsPreviewStageExpanded()
    }



    React.useEffect(() => {
        if (expandItem.current) {
            if (isPreviewStageExpanded) {
                expandItem.current.style.width = `${expandItem.current.scrollWidth}px`
            } else {
                expandItem.current.style.width = `${expandItem.current.scrollWidth}px`;
                window.getComputedStyle(expandItem.current, null).getPropertyValue("width");
                expandItem.current.style.width = "0";
            }
        }
    }, [isPreviewStageExpanded])

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Stack.Item>
                        <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", color: theme.palette.white }}/>
                    </Stack.Item>
                </TooltipHost>
            </span>
        )
    }
    return (
        <Stack verticalFill  style={{backgroundColor: props.color? props.color : undefined, transition:'background 600ms ease', borderRight: `1px solid ${theme.semanticColors.bodyDivider}`}}>
            <Stack.Item verticalFill>
                <Stack horizontal verticalFill verticalAlign="center">
                    <Stack.Item>
                        <div ref={expandItem} style={{ width: "0px", transition: 'width 600ms ease', overflow: 'hidden', padding: 0 }}>
                            {props.children}
                        </div>
                    </Stack.Item>
                    <Stack verticalFill  verticalAlign="end" horizontalAlign="end" >
                        <Pivot styles={pivotStyles} onLinkClick={() => pivotPreviewClick()} >
                            <PivotItem itemIcon={isPreviewStageExpanded ? 'DoubleChevronLeft' : 'DoubleChevronRight'} onRenderItemLink={onRenderItemLink} headerText={strings.CONSTRUCTOR.COMMON.PREVIEW}/>
                        </Pivot>
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}
const theme = getTheme()

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: 'rgb(0, 120, 212)',
        selectors: {
            '&:hover': {
                backgroundColor: 'rgb(16, 110, 190)',
            },
            '&:active': {
                backgroundColor: 'rgb(0, 90, 158)',
            }
        },
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        display: "flex"
    },
    link: {
        marginRight: 0,
        marginBottom: 4,
        marginLeft: 4,
        height: '40px',
        lineHeight: '40px',
        padding:'0 12px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20,
    },
    root: {
        display: "flex",
        margin: 0,
        padding: 0,
    },
}
const buttonStyles = {
    root: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: 'rgb(0, 120, 212)',
        selectors: {
            '&:hover': {
                backgroundColor: 'rgb(16, 110, 190)',
            },
            '&:active': {
                backgroundColor: 'rgb(0, 90, 158)',
            }
        },
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    }
}