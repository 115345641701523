import React, { Dispatch } from "react"
import { CommonOfferStagePromotionsPivotProviders } from "./CommonOfferStagePromotionsPivotProviders"
import { CommonOfferStagePromotionsPivotUI } from "./CommonOfferStagePromotionsPivotUI"
import { ProcessStages, ICommonOfferConfig } from "@piceasoft/core"

type TProps = {
    selectedPivot?: "promotions_ui" | "promotions_providers" 
    onChangeDispatch: Dispatch<any>
    config?: ICommonOfferConfig
    channel: number
    stage: ProcessStages
}

export const CommonOfferStagePromotionsPivot: React.FC<TProps> = (props) => {
    switch (props.selectedPivot) {
        case "promotions_ui": return (
            <CommonOfferStagePromotionsPivotUI stage={ props.stage} config={props.config} channel={props.channel} onChangeDispatch={props.onChangeDispatch} />
        )
        default: return (
            <CommonOfferStagePromotionsPivotProviders config={props.config} onChangeDispatch={props.onChangeDispatch} />
        )
    }
}
