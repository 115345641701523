import { Checkbox, IconButton, Modal, Stack, Text, TextField, Spinner, PrimaryButton, Panel, DefaultButton, IStyleFunctionOrObject, IModalStyles, IModalStyleProps, IButtonStyles } from '@fluentui/react'
import { useState } from 'react'
import { IValidationApiDataApis } from '../../core/store/typings/IValidationTokenResponse'
import { IValidationApis } from '../../core/store/typings/IValidationApis'
import { IValidationToken } from '../../core/store/typings/IValidationToken'
import { actionCreators } from '../../core/actions/validation-actions'
import { useDispatch, useSelector } from 'react-redux'
import { IStore } from '../../core/store'
import { strings } from '../../localization/strings';
import { getTheme } from '@fluentui/style-utilities';

type TProps = {
    apisData: IValidationApiDataApis,
    isOpenCreateModal: boolean
    closeCreateModal: () => void
    token: IValidationToken
}

export const CreateValidationToken: React.FC<TProps> = (props) => {

    const { token, apisData, isOpenCreateModal, closeCreateModal} = props;
    const [tokenName, setTokenName] = useState<string>('');
    const [selectedAPIs, setSelectedAPIs] = useState<IValidationApis[]>([]);
    
    const user = useSelector((s: IStore) => s.workplace.user);
    const dispatch =  useDispatch();
    
    const currentTime = Math.floor(Date.now() / 1000);
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    const maxExpiryTime = Math.floor(oneYearFromNow.getTime() / 1000);
    
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if(!user)
        {
            return null; 
        }

        const newToken: IValidationToken = {
            name: tokenName,
            customer_id: user.id.toLowerCase(),
            creator: user.name,
            is_valid: true,
            restrictions: [],
            apis: selectedAPIs.map((api: IValidationApis) => ({
                id: api.id,
                name: api.name,
                api_id: api.id,
                creator: user.name,
                max_expiry_time: maxExpiryTime,
                params: {},
                created: currentTime
            }))
        }

        await dispatch(actionCreators.createValidationToken(newToken));
        closeCreateModal();
    }

  return (
    <Modal 
        isOpen={isOpenCreateModal} 
        styles={modalStyles}
    >
        <Stack verticalFill grow>
            <Stack style={panelHeaderStyle} grow tokens={{ childrenGap: 16, padding: 20 }}>

                <Stack style={{display: 'flex', flexDirection: 'row'}}>
                    <Text variant='xLarge'>{strings.SETTINGS.VALIDATION_API.OPERATION.CREATE_VALIDATION_TOKEN}</Text>
                    <IconButton
                                styles={iconButtonStyles}
                                iconProps={{ iconName: 'Cancel', style: { fontSize: 20 } }}
                                onClick={() => closeCreateModal()}
                            />
                </Stack>

                <Stack style={{margin:'10px 0 20px 0'}}>
                        <Text variant='medium' style={{ marginBottom: '10px', fontSize: '18px'}}>{strings.SETTINGS.VALIDATION_API.TOKEN_NAME}</Text>
                        <TextField 
                            type="text"
                            maxLength={20}
                            onChange={(e: any) => setTokenName(e.target.value)}
                        />
                </Stack>

                <Stack grow>
                    <Stack.Item style={{display: 'flex', marginBottom: '10px'}}>
                        <Text variant='medium' style={{fontSize: '18px'}}>{strings.SETTINGS.VALIDATION_API.SELECT_APIS}</Text>
                    </Stack.Item>
                    <Stack.Item style={{paddingLeft: '15px'}}>
                    {apisData.data ? (
                        apisData.data.apis.map((api: IValidationApis, index: any) => {
                            const isChecked = selectedAPIs.some((selectedAPI: IValidationApis) => selectedAPI.id === api.id);
                            return (
                            <Stack key={'checkbox ' + index} verticalAlign="center">
                                <Stack style={{ margin: '7px' }}>
                                    <Checkbox
                                    label={api.name}
                                    name={api.name}
                                    id={api.id}
                                    checked={isChecked}
                                    onChange={() => {
                                        if (isChecked) {
                                          setSelectedAPIs(selectedAPIs.filter((selectedAPI: IValidationApis) => selectedAPI.id !== api.id));
                                        } else {
                                          setSelectedAPIs([...selectedAPIs, api]);
                                        }
                                      }}
                                      required
                                    />
                                </Stack>
                            </Stack>
                        )})
                    ) : (
                        <Spinner label="WAIT" />
                    )}
                    </Stack.Item>
                </Stack>
                
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        <DefaultButton onClick={() => closeCreateModal() }>Cancel</DefaultButton>
                        <PrimaryButton onClick={(handleSubmit)} style={{marginLeft: '5px'}}>Save</PrimaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Stack>
    </Modal>
  )
}

const theme = getTheme();

const iconButtonStyles: IButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    }
};

const modalStyles: IStyleFunctionOrObject<IModalStyleProps, IModalStyles> = {
    main: {
        display: 'flex',
        borderRadius: theme.effects.roundedCorner6,
        boxShadow: theme.effects.elevation64,
        flexFlow: 'column nowrap',
        position: 'absolute',
        overflow: 'hidden',
        width: 480,
        top: 16,
        bottom: 16,
        right: 16
    },
   // root: {
        //background: 'rgba(0, 0, 0, .01)'
    //}
}

const panelHeaderStyle: React.CSSProperties = {
    paddingTop: 16,
    paddingLeft: 20,
    paddingRight: 16,
    paddingBottom: 16
}