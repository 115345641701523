import { getTheme, SharedColors, Spinner, Stack, Text } from "@fluentui/react"
import { SecondaryButton } from "../../../../../components/shared/buttons/SecondaryButton";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../../../core/scripts/style";
import { IPoint } from "../../../../../core/store";
import { getLocalizedTimezone } from "../../../../../core/helpers/timezone";
import { strings } from "../../../../../localization/strings";

export type TProps = {
    point?: IPoint
    onEdit: () => void
}

export const PointHeader: React.FC<TProps> = ({ point, onEdit }) => {

    return (
        <Stack horizontal grow style={{ padding: "24px 32px 12px 32px" }}>
            {point && (
                <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                    <Stack.Item>
                        <Stack verticalAlign="center" horizontalAlign="center" style={{
                            width: 72, height: 72, borderRadius: 36,
                            backgroundColor: getPersonaColorsPallete[point.name[0].toLowerCase()]
                        }}>
                            <Text style={{ color: "#fff", fontSize: 28, fontWeight: 400, paddingBottom: 2 }}>
                                {getIconSymbolsFromString(point.name)}
                            </Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack>
                            <Stack.Item>
                                <Text style={{ fontSize: "1.3125rem", letterSpacing: "-.02em", fontWeight: 600, color: theme.palette.black }}>{point.name}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text style={{ color: SharedColors.gray30 }}>{point.address}</Text>
                            </Stack.Item>
                            {point.timezone !== undefined && <Stack.Item>
                                <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>
                                    {getLocalizedTimezone(point.timezone)}
                                </Text>
                            </Stack.Item>}
                        </Stack>
                    </Stack.Item>
                </Stack>
            ) || (
                    <Stack verticalFill verticalAlign='center'>
                        <Spinner label={strings.SPINNERS.DATA_IS_LOADING} />
                    </Stack>
                )}
            <Stack.Item>
                <SecondaryButton text={strings.BUTTONS.TEXT.EDIT_DATA} onClick={onEdit} />
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
