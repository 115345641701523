import { getTheme, Icon, SharedColors, Stack, Text } from "@fluentui/react"
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { IDocument } from "../../../core/store";
import { strings } from "../../../localization/strings";

export type TProps = {
    item: IDocument
    onEdit: () => void
}

export const ServicesPrintTemplateHeader: React.FC<TProps> = ({ item, onEdit }) => {
    return (
        <Stack horizontal grow style={{ padding: "24px 32px 12px 32px" }}>
            <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                <Stack.Item>
                    <Icon iconName={"InsertSignatureLine"} style={{ fontSize: 42, color: SharedColors.cyanBlue20 }} />
                </Stack.Item>
                <Stack.Item grow>
                    <Stack>
                        <Stack.Item>
                            <Text style={{ fontSize: "1.3125rem", letterSpacing: "-.02em", fontWeight: 600, color: theme.palette.black }}>{item.name}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            {item.currentVersionDate && (
                                <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{new Date(item.currentVersionDate).toLocaleString()}</Text>
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            {!item.removed && (
                <Stack.Item>
                    <SecondaryButton text={strings.BUTTONS.TEXT.EDIT_DATA} onClick={onEdit} />
                </Stack.Item>
            )}
        </Stack>
    )
}

const theme = getTheme();