import { Stack, TextField, Text, Image, getTheme, ImageFit, Spinner } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { CustomSeparator } from "../../../components/shared/decorations/CustomSeparator";
import { MyPersona } from "../../../components/shared/decorations/MyPersona";
import { RemoveArea } from "../../../components/shared/decorations/RemoveArea";
import { ImportFromFile } from "../../../components/shared/importFromFile/ImportFromFile";
import { actionCreators } from "../../../core/actions/settings-actions";
import { portalApi } from "../../../core/api/api";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../core/scripts/style";
import { IOrganizationSettings } from "../../../core/store/typings/IOrganizationSettings";
import { IOrganizationSettingsOverview } from "../../../core/store/typings/IOrganizationSettingsOverview";
import { strings } from "../../../localization/strings";
import { Updatelogo } from "./Updatelogo";

export type TSettingsOverviewTabProps = {
    data?: IOrganizationSettings
    isUpdating: boolean
}

export const SettingsOverviewTab: React.FC<TSettingsOverviewTabProps> = props => {

    const dispatch = useDispatch();

    const { control, handleSubmit, formState: { errors } } = useForm<IOrganizationSettingsOverview>({ defaultValues: props.data });
    const onSubmit: SubmitHandler<IOrganizationSettingsOverview> = data => {
        dispatch(actionCreators.updateOrganizationSettingsOverview(data));
    };

    const [logoLoadState, { setTrue: showLogoLoad, setFalse: hideLogoLoad }] = useBoolean(false)
    const AllowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif"]

    return (
        <Stack style={{ width: 640 }} tokens={{ padding: 24, childrenGap: 16 }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack style={{ width: 360 }} verticalFill tokens={{ childrenGap: 16 }} >
                        <Stack tokens={{ childrenGap: 16 }} >
                            <Stack.Item>
                                <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.GENERAL.OVERVIEW.TITLE}</Text>
                            </Stack.Item>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.NAME_REQUIRED, minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` } }}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        label={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.NAME}
                                        placeholder={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name="description"
                                rules={{ required: strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.DESCRIPTION_REQUIRED, minLength: { value: 3, message: `${strings.ORGANIZATION.STRUCTURE.ITEM.MIN_LENGHT} ${3}` } }}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        multiline
                                        resizable={false}
                                        style={{ height: 100 }}
                                        label={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.DESCRIPTION}
                                        placeholder={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.DESCRIPTION_PLACEHOLDER}
                                        errorMessage={errors.description?.message}
                                    />
                                }
                            />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <SecondaryButton disabled={props.isUpdating} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</SecondaryButton>
                            {props.isUpdating && (
                                <Spinner />
                            )}
                        </Stack>
                    </Stack>
                    <Stack.Item tokens={{ padding: 16 }}>
                        <Stack onClick={showLogoLoad} verticalAlign="center"
                            horizontalAlign="center"
                            style={{
                                width: 100,
                                height: 100,
                                borderRadius: theme.effects.roundedCorner4,
                                backgroundColor: !props.data?.logo ?
                                    (props.data?.name ?
                                        getPersonaColorsPallete[props.data.name[0].toLowerCase()] :
                                        undefined) :
                                    undefined,
                                cursor: 'pointer'
                            }}>
                            {(props.data?.logo) && (
                                <Stack horizontalAlign='center'>
                                    <Image src={props.data?.logo} width={100} height={100} imageFit={ImageFit.cover} style={{ borderRadius: theme.effects.roundedCorner4 }} />
                                </Stack>
                            ) || (
                                    <Text variant="xxLargePlus" style={{ color: "#fff" }}>
                                        {getIconSymbolsFromString(props.data?.name)}
                                    </Text>
                                )}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </form>
            {props.data && (
                <>
                    <CustomSeparator />
                    <Stack tokens={{ childrenGap: 8 }}>
                        <Text style={{ fontWeight: 600 }} variant="xLarge">{strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.OWNER_TITLE}</Text>
                        <MyPersona onClick={() => { }} text={props.data?.ownerName} secondaryText={props.data?.ownerEmail ?? props.data?.ownerName} />
                        <Text></Text>
                        {/* <Stack.Item>
                    <SecondaryButton text={strings.BUTTONS.TEXT.CHANGE_OWNER} />
                </Stack.Item> */}
                    </Stack>
                </>
            )}
            <CustomSeparator />
            <RemoveArea
                href="1105"
                hrefText={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.REMOVE_AREA.HREF_TEXT}
                onDelete={() => { }}
                text={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.REMOVE_AREA.TEXT}
                title={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.REMOVE_AREA.TITLE}
            />
            {logoLoadState && (
                <Updatelogo
                    show={logoLoadState}
                    onHide={hideLogoLoad}
                    acceptTypeMask={"image/*"}
                    allowedFileTypes={AllowedFileTypes}
                    onRenderImgPlaceHolder={() => (
                        <Stack verticalAlign="center"
                            horizontalAlign="center"
                            style={{
                                width: 72,
                                height: 72,
                                borderRadius: theme.effects.roundedCorner4,
                                backgroundColor: !props.data?.logo ?
                                    (props.data?.name ?
                                        getPersonaColorsPallete[props.data.name[0].toLowerCase()] :
                                        undefined) :
                                    undefined,
                                cursor: 'pointer'
                            }}>
                            <Text variant="xxLargePlus" style={{ color: "#fff" }} onClick={showLogoLoad}>
                                {getIconSymbolsFromString(props.data?.name)}
                            </Text>
                        </Stack>
                    )}
                    type="image"
                    src={props.data?.logo}
                    lable={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.LOGO_LOAD_LABEL}
                    title={strings.ORGANIZATION.SETTINGS.GENERAL.OVERVIEW.LOGO_LOAD_TITLE}
                    description={strings.CONSTRUCTOR.COMMON.IMAGE_DESCRIPTION}
                    sendMethod={(file, setResponse, setProgress) => dispatch(actionCreators.updateOrganizationSettingsLogo(file, setResponse, setProgress))}
                    sendDelete={(setResponse) => dispatch(actionCreators.deleteOrganizationSettingsLogo(setResponse))}
                />
            )}
        </Stack>
    )
}

const theme = getTheme();

