import { Stack, Text } from "@fluentui/react"
import { IResponsiblePerson } from "../../../core/store"
import { ResponsibleAreas } from "../../../core/store/typings/ResponsibleAreas"
import { strings } from "../../../localization/strings"
import { TResponsiblePersonsPivotState, TResponsiblePersonState } from "../ResponsiblePersons"
import { ResponsiblePersonsPivot } from "./pivots/ResponsiblePersonsPivot"

export type TResponsiblePersonsPivotBasedStateProps = {
    state: TResponsiblePersonsPivotState
    area: ResponsibleAreas
    items: IResponsiblePerson[]
    onEditPerson: (item:IResponsiblePerson) => void
    onAddPerson:() => void
    setDeletePersonState: (item:IResponsiblePerson) => void
    requestResponsiblePersons: () => void
    setSearchString: (text?: string) => void
    personsState: TResponsiblePersonState
}

export const ResponsiblePersonsPivotBasedState: React.FC<TResponsiblePersonsPivotBasedStateProps> = (props) => {
    switch (props.state) {
        case "moderation": return (
            <Stack grow verticalFill>
                <Stack grow verticalFill verticalAlign='space-between'>
                    <ResponsiblePersonsPivot
                        items={props.items}
                        onEditPerson={props.onEditPerson}
                        onAddPerson={props.onAddPerson}
                        setDeletePersonState={props.setDeletePersonState}
                        requestResponsiblePersons={props.requestResponsiblePersons}
                        setSearchString={props.setSearchString}
                        personsState={props.personsState}
                        area={props.area}
                        title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.MODERATION.TITLE}
                        information={strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.MODERATION.INFORMATION}
                        href={undefined}
                        hrefInformation={strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.MODERATION.INFORMATION_LINK_TEXT}
                    />
                </Stack>
            </Stack>
        )
        case "prices": return (
            <Stack grow verticalFill>
                <Stack grow verticalFill verticalAlign='space-between'>
                    <ResponsiblePersonsPivot
                        items={props.items}
                        onEditPerson={props.onEditPerson}
                        onAddPerson={props.onAddPerson}
                        setDeletePersonState={props.setDeletePersonState}
                        requestResponsiblePersons={props.requestResponsiblePersons}
                        setSearchString={props.setSearchString}
                        personsState={props.personsState}
                        area={props.area}
                        title={strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.PRICES.TITLE}
                        information={strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.PRICES.INFORMATION}
                        href={undefined}
                        hrefInformation={strings.ORGANIZATION.RESPONSIBLE_PERSONS.PIVOTS.PRICES.INFORMATION_LINK_TEXT}
                    />
                </Stack>
            </Stack>
        )
    }
}
