import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationTransactionConfig } from "@piceasoft/core";
import { IIdentificationTransactionConfigUI } from "@piceasoft/core";
import { IdentificationModuleUISettingsPivot } from "../IdentificationModuleUISettingsPivot";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const TransactionUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationTransactionConfig)

    return (
        <IdentificationModuleUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch}>
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.TRANSACTION.PIVOTS.UI.PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled
                toggleChecked={config.ui?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            placeholder: config.ui?.placeholder !== undefined ? undefined : ""
                        } as IIdentificationTransactionConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.TRANSACTION.PIVOTS.UI.PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                       placeholder: val
                    } as IIdentificationTransactionConfigUI
                }))}
            />
        </IdentificationModuleUISettingsPivot>

    )
}