import { Stack } from "@fluentui/react"
import { IResultConfig } from "@piceasoft/core";
import { strings } from "../../../../localization/strings";
import { BarCode } from "../../barCode/BarCode";
import { StageBox } from "../components/StageBox";

type TProps = {
    config: IResultConfig
    showSettings: () => void
    showBarcodes: () => void
    targetBarcodes?: boolean
}

export const Result: React.FC<TProps> = (props) => {

    if( props.targetBarcodes) {
        return (
            <StageBox title={strings.CONSTRUCTOR.STAGES.RESULT.TITLE} settingsButtonProps={{ onClick: props.showSettings }}>
                <Stack tokens={{ childrenGap: 16 }}>
                {props.config.barcodes?.transaction && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.TRANSACTION.TITLE} title={props.config.ui?.barcodes?.transaction?.title} />}
                    {props.config.barcodes?.device && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.SOURCE_DEVICE.TITLE} title={props.config.ui?.barcodes?.device?.title} />}
                    {props.config.barcodes?.imei && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.SOURCE_IMEI.TITLE} title={props.config.ui?.barcodes?.imei?.title} />}
                    {props.config.barcodes?.imei2 && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.SOURCE_IMEI2.TITLE} title={props.config.ui?.barcodes?.imei2?.title} />}
                    {props.config.barcodes?.sku && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.SOURCE_SKU.TITLE} title={props.config.ui?.barcodes?.sku?.title} />}
                    {props.config.barcodes?.sn && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.SOURCE_SN.TITLE} title={props.config.ui?.barcodes?.sn?.title} />}
                    {props.config.targetDeviceBarcodes?.device && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.TARGET_DEVICE.TITLE} title={props.config.ui?.targetDeviceBarcodes?.device?.title} />}
                    {props.config.targetDeviceBarcodes?.imei && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.TARGET_IMEI.TITLE} title={props.config.ui?.targetDeviceBarcodes?.imei?.title} />}
                    {props.config.targetDeviceBarcodes?.imei2 && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.TARGET_IMEI2.TITLE} title={props.config.ui?.targetDeviceBarcodes?.imei2?.title} />}
                    {props.config.targetDeviceBarcodes?.sku && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.TARGET_SKU.TITLE} title={props.config.ui?.targetDeviceBarcodes?.sku?.title} />}
                    {props.config.targetDeviceBarcodes?.sn && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.TARGET_SN.TITLE} title={props.config.ui?.targetDeviceBarcodes?.sn?.title} />}
                </Stack>
            </StageBox>
        )
    }

    return (
        <StageBox title={strings.CONSTRUCTOR.STAGES.RESULT.TITLE} settingsButtonProps={{ onClick: props.showSettings }}>
            <Stack tokens={{ childrenGap: 16 }}>
                {props.config.barcodes?.device && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.DEVICE.TITLE} title={props.config.ui?.barcodes?.device?.title} />}
                {props.config.barcodes?.imei && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.IMEI.TITLE} title={props.config.ui?.barcodes?.imei?.title} />}
                {props.config.barcodes?.imei2 && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.IMEI2.TITLE} title={props.config.ui?.barcodes?.imei2?.title} />}
                {props.config.barcodes?.sku && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.SKU.TITLE} title={props.config.ui?.barcodes?.sku?.title} />}
                {props.config.barcodes?.sn && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.SN.TITLE} title={props.config.ui?.barcodes?.sn?.title} />}
                {props.config.barcodes?.transaction && <BarCode showBarcodes={props.showBarcodes} label={strings.CONSTRUCTOR.STAGES.RESULT.BARCODES.TRANSACTION.TITLE} title={props.config.ui?.barcodes?.transaction?.title} />}
            </Stack>
        </StageBox>
    )
}
