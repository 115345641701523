import { getTheme, IconButton, IIconProps, Modal, Spinner, Stack, Text, Image, IContextualMenuItem, IContextualMenuProps, SearchBox, FontWeights, mergeStyleSets, MessageBar, MessageBarType, SharedColors } from '@fluentui/react';
import * as React from 'react';
import { StructureNodeTypes } from './Elements/StructureNodeTypes';
import { OrganizationStructureTree } from './OrganizationStructureTree';
import { StructureElementDetails } from './StructureElementDetails';
import { IPerson } from '../../../core/store/typings/IPerson';
import { IContainer, IPoint, IStore } from '../../../core/store';
import { useBoolean } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../core/actions/workflow-actions';
import { strings } from '../../../localization/strings';
import { IStructureNode } from '../../../core/store/typings/IStructureNode';
import { IForestAction, IForestTree } from '../../../components/shared/forest/Forest';
import { StructureNodeActionTypes } from './Elements/StructureNodeActionTypes';
import { portalApi } from '../../../core/api/api';
import { IRequestResult } from '../../../core/store/typings/IResponseResult';
import { ITreeNode, ITreeNodeAction } from '../../../assets/ts/tree/VTree';
import { ConfirmationForm } from '../../../components/shared/forms/ConfirmationForm';
import { StructureItemPanel } from './Elements/StructureItemPanel';
import { endpoints } from '../../../core/api/endpoints';
import { SomethingWentWrong } from '../../../components/shared/somethingWentWrong/SomethingWentWrong';
import { Section } from '../../../components/shared/decorations/Section';
import { ItemsNotFound } from '../../../components/shared/notFound/ItemsNotFound';
import { UserRoles } from '../../../core/store/typings/UserRoles';
import { ImportStructureModal } from './ImportStructureModal';
import { StructureProtocols } from './StructureProtocols';

export type TStructureItemData = {
    type: StructureNodeTypes,
    id?: string
    itemId?: string
    itemCode?: string
    parentId?: string
    data?: IContainer | IPoint | IPerson
    fetched: boolean
    error?: string
    actionType?: StructureNodeActionTypes
}

export const OrganizationStructure: React.FC = (props) => {

    const [itemState, setItemState] = React.useState<TStructureItemData>()
    const [panelItemState, setPanelItemState] = React.useState<TStructureItemData>()
    const [error, setError] = React.useState<string>()

    const [editPanelState, { setTrue: showEditPanel, setFalse: hideEditPanel }] = useBoolean(false)
    const [confirmBoxState, { setTrue: showConfirmBox, setFalse: hideConfirmBox }] = useBoolean(false)

    const [isImportModalShowed, { setTrue: showImportModal, setFalse: hideImportModal }] = useBoolean(false)

    const [expandedNodeList, setExpandedNodeList] = React.useState<string[]>()

    const [isAdminPerson, setIsAdminPerson] = React.useState(false)

    const [lastDate, setLastDate] = React.useState<Date>(new Date())

    const [isProtocolsShow, { setTrue: showProtocols, setFalse: hideProtocols }] = useBoolean(false)

    const [confirmFormData, setConfirmFormData] = React.useState<{
        title: string
        requiredTextString: string
        placeholder: string
        text1: string,
        text2: string,
        requirementText: string
    }>()

    const organization = useSelector((s: IStore) => s.workplace.organization)
    const structure = useSelector((s: IStore) => s.workflow.structure)

    const [preparedTrees, setPreparedTree] = React.useState<ITreeNode[]>()
    const [filteredTrees, setFilteredTree] = React.useState<ITreeNode[]>()
    const [filterString, setFilterString] = React.useState<string>()
    const [selectedTreeKey, setSelectedTreeKey] = React.useState<string>('')
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(actionCreators.requestStructure())
    }, [])

    React.useEffect(() => {
        if (structure.isReady) {
            prepareTrees(structure.data)
        }
    }, [structure])

    React.useEffect(() => {
        hideProtocols()
        if (panelItemState?.actionType === StructureNodeActionTypes.Remove && panelItemState.data) {
            setConfirmFormData(getConfirmFormData(panelItemState.type))
        }
        if (panelItemState?.data && panelItemState?.type === StructureNodeTypes.Person &&
            (panelItemState.data as IPerson).roles.find(i =>
                [UserRoles.Administrator, UserRoles.Organization, UserRoles.Trader].includes(i as UserRoles))) {
            setIsAdminPerson(true)
        } else if (isAdminPerson) {
            setIsAdminPerson(false)
        }
    }, [panelItemState])

    React.useEffect(() => {
        console.log(itemState)
        hideProtocols()
    }, [itemState])

    React.useEffect(() => {
        if (confirmFormData) {
            showConfirmBox()
        }

    }, [confirmFormData])

    React.useEffect(() => {
        setFilteredTree(undefined)
        if (filterString) {
            let newTrees: ITreeNode[] = getTreesByItemNameOrCode(filterString, preparedTrees)
            if (newTrees.length > 0) {
                setFilteredTree(newTrees)
            }
        }
    }, [filterString])

    const expandNode = (key: string) => {
        setExpandedNodeList(expandedNodeList ? [...expandedNodeList, key] : [key])
    }

    const collapseNode = (key: string) => {
        if (expandedNodeList) {
            setExpandedNodeList([...expandedNodeList.filter(en => en !== key)])
        }
    }

    const getTreesByItemNameOrCode = (pattern: string, inputTrees?: ITreeNode[]): ITreeNode[] => {
        if (!inputTrees) return [];
        let trees: ITreeNode[] = []
        inputTrees?.filter(ct => {
            let isFounded = false
            const originValues = [
                ct.name,
                ct.itemCode,
            ]
            originValues.map(ov => {

                if (ov && !isFounded) {
                    isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${pattern?.toLowerCase()}\w*`)) != null
                }
            })
            if (isFounded) {
                trees.push(ct);
                return;
            }
            if (ct.children) {
                trees.push(...getTreesByItemNameOrCode(pattern, ct.children))
            }
        })
        return trees
    }

    const prepareTrees = (data: IStructureNode[]): IForestTree[] => {

        let items: ITreeNode[] = []
        data.forEach(d => items.push({
            name: d.itemName,
            iconProps: getNodeIconProps(d.type),
            id: d.id,
            itemId: d.itemId,
            parentId: d.parentId,
            itemCode: d.itemCode,
            companyId: d.companyId,
            onClick: () => setDetailsItemHandler(d.type, d.itemId, d.parentId),
            children: [],
            isExpanded: false,
            actions: getActions(d),
            disabled: d.disabled
        }))

        const hashTable = Object.create(null);

        items.forEach(item => hashTable[item.id as string] = { ...item });

        const trees: ITreeNode[] = [];

        items.forEach(item => {
            if (item.parentId && Object.keys(hashTable).includes(item.parentId)) hashTable[item.parentId].children.push(hashTable[item.id as string])
            else trees.push(hashTable[item.id as string])
        });
        setPreparedTree(trees)

        return trees
    }

    const addTreeChild = (tree: ITreeNode, node: ITreeNode) => {
        if (tree.itemId === node.parentId) {
            tree.children = tree.children ? [...tree.children, node] : [node]
            return;
        }
        if (tree.children && tree.children.length > 0) {
            tree.children.map(child => addTreeChild(child, node))
        }
    }

    const addForestNode = (node: IStructureNode) => {
        dispatch(actionCreators.addStructureNode(node))
        if (node.parentId) {
            expandNode(node.parentId)
        }
        setSelectedTreeKey(node.id)
    }

    // const editTree = (tree: ITreeNode, node: IStructureNode) => {
    //     if (tree.itemId === node.itemId) {
    //         tree.name = node.itemName
    //         return tree;
    //     }
    //     if (tree.children && tree.children.length > 0) {
    //         tree.children.map(child => editTree(child, node))
    //     }
    // }

    const editForestNode = (node: IStructureNode) => {
        dispatch(actionCreators.updateStructureNode(node))
        if (node.parentId) {
            expandNode(node.parentId)
        }
        setSelectedTreeKey(node.id)
    }

    const deleteTree = (tree: ITreeNode, node: IStructureNode) => {
        tree.children = tree.children?.filter(branch => branch.id !== node.id)
        if (tree.children && tree.children.length > 0) {
            tree.children.map(child => deleteTree(child, node))
        }
    }

    const deleteForestNode = (node: IStructureNode) => {
        dispatch(actionCreators.deleteStructureNode(node.id))
    }

    const onUpdateStateItem = () => {
        setPanelItemState(itemState)
        showEditPanel()
    }

    const getItemData = async (type: StructureNodeTypes, itemId: string): Promise<IRequestResult<IContainer | IPoint | IPerson | undefined>> => {
        let result: IRequestResult<IContainer | IPoint | IPerson | undefined>
        switch (type) {
            case StructureNodeTypes.Container:
                result = await portalApi.organization.containers.getContainer(itemId);
                break;
            case StructureNodeTypes.Point:
                result = await portalApi.organization.points.getPoint(itemId);
                break;
            case StructureNodeTypes.Person:
                result = await portalApi.organization.accounts.getAccount(itemId);
                break;
            default:
                result = { successed: false, errors: [{ description: 'Undefined item type' }] }
        }
        return result;
    }

    const setDetailsItemHandler = async (type: StructureNodeTypes, itemId: string, parentId?: string): Promise<void> => {
        setItemState({ fetched: false, id: itemId, parentId: parentId, type: type })
        let result = await getItemData(type, itemId)

        setItemState({ ...itemState, type: type, data: result.data, fetched: true, parentId: parentId })
    }

    const onCreateChildNode = (type: StructureNodeTypes, item?: IStructureNode) => {
        switch (type) {
            case StructureNodeTypes.Person:
                setPanelItemState({
                    type: type, parentId: item?.itemId, fetched: true, data: {
                        companyId: item?.companyId,
                        pointId: item?.type === StructureNodeTypes.Point ? item?.itemId : undefined,
                        containerId: item?.type === StructureNodeTypes.Container ? item?.itemId : undefined,
                        roles: []
                    } as IPerson
                })
                break;
            case StructureNodeTypes.Container:
                setPanelItemState({
                    type: type, parentId: item?.itemId, fetched: true, data: {
                        parentId: item?.itemId,
                        name: '',
                        code: '',
                        turnOnOptions: false
                    } as IContainer
                })
                break;
            case StructureNodeTypes.Point:
                setPanelItemState({
                    type: type, parentId: item?.itemId, fetched: true, data: {
                        parentId: item?.itemId,
                        containerId: item?.itemId,
                        name: ''
                    } as IPoint
                })
                break;
            // default: setPanelItemState({ type: type, parentId: item?.itemId, fetched: true })
        }
        showEditPanel()
    }

    const onUpdateNode = async (item: IStructureNode): Promise<void> => {
        setPanelItemState({ type: item.type, id: item.id, itemId: item.itemId, parentId: item.parentId, fetched: false })
        showEditPanel()
        const result = await getItemData(item.type, item.itemId)

        if (result.successed) {
            setPanelItemState({ type: item.type, id: item.id, itemId: item.itemId, fetched: true, parentId: item.parentId, data: result.data })
        }
        if (result.errors && result.errors.length > 0) {
            setPanelItemState({ type: item.type, data: result.data, fetched: true, error: result.errors[0].description })
        }
    }

    // const onToggleEnabledCurrentNode = async (): Promise<void> => {

    // }

    const onRemoveNode = async (type: StructureNodeTypes, itemId: string, callback?: (successed: boolean) => void) => {
        let result = await getItemData(type, itemId)
        if (result.successed) {
            setPanelItemState({ ...itemState, type: type, data: result.data, fetched: true, actionType: StructureNodeActionTypes.Remove })
        }
        if (result.errors && result.errors?.length > 0) {
            setError(result.errors[0].description)
        }
    }

    // const onToggleEnabledNode = async (type: StructureNodeTypes, itemId: string, callback?: (successed: boolean) => void) => {
    //     // let result = await getItemData(type, itemId)
    //     // if (result.successed) {
    //     //     setPanelItemState({ ...itemState, type: type, data: result.data, fetched: true, actionType: StructureNodeActionTypes.Remove })
    //     // }
    //     // if (result.errors && result.errors?.length > 0) {
    //     //     setError(result.errors[0].description)
    //     // }
    // }

    // const onMoveNode = async (type: StructureNodeTypes, node: IStructureNode, isAlone?: boolean, callback?: (successed: boolean) => void) => {


    // }

    const onNodeAction = (actionType: StructureNodeActionTypes, type: StructureNodeTypes, item?: IStructureNode, callback?: (successed: boolean) => void) => {
        if (error) {
            setError(undefined)
        }
        switch (actionType) {
            case StructureNodeActionTypes.Add:
                onCreateChildNode(type, item)
                break;
            case StructureNodeActionTypes.Update:
                if (item?.itemId) {
                    onUpdateNode(item)
                }
                break;
            case StructureNodeActionTypes.Remove:
                if (item?.itemId) {
                    onRemoveNode(type, item.itemId)
                } else {
                    console.debug('Portal Organization Structure Logic => Item id not found');
                }
                break;
            case StructureNodeActionTypes.RemoveFromItemState:
                if (itemState?.data && itemState.data.id) {
                    onRemoveNode(type, itemState.data.id)
                }
                break;
            case StructureNodeActionTypes.ToggleEnabled:
                if (itemState?.data) {
                    // onToggleEnabledNode(type, item.itemId)
                }
                break;
            // case StructureNodeActionTypes.MoveTogether:
            //     if (itemState?.data) {
            //         onMoveNode(type, itemState.)
            //     }
            //     break;
            // case StructureNodeActionTypes.MoveAlone:
            //     if (itemState?.data) {
            //         onMoveNode(type, itemState.data.id, itemState.parentId, true)
            //     }
            // break;
            default:
                console.log('Portal Organization Structure Logic => Action not found');
        }
    }

    const onHideEditPanelHandler = () => {
        hideEditPanel()
        setTimeout(() => {
            setPanelItemState(undefined)
        }, 500)
    }

    const onConfirmHandler = async (state: TStructureItemData) => {
        setPanelItemState({ type: panelItemState?.type ?? state.type, fetched: false })
        // setPanelItemState({ ...panelItemState, fetched: false, type:  })
        let result: IRequestResult<IContainer | IPoint | IPerson | undefined>
        switch (state.type) {
            case StructureNodeTypes.Container:
                if (state.data?.id) {
                    result = await portalApi.organization.containers.updateContainer(state.data as IContainer)
                } else {
                    result = await portalApi.organization.containers.addContainer(state.data as IContainer)
                }
                break;
            case StructureNodeTypes.Point:
                if (state.data?.id) {
                    result = await portalApi.organization.points.updatePoint(state.data as IPoint)
                } else {
                    result = await portalApi.organization.points.addPoint(state.data as IPoint, state.parentId)
                }
                break;
            case StructureNodeTypes.Person:
                if (state.data?.id) {
                    result = await portalApi.organization.accounts.updateAccount(state.data as IPerson)
                } else {
                    result = await portalApi.organization.accounts.addAccount(state.data as IPerson)
                }
                break;
            default:
                result = { successed: false, errors: [{ description: 'Undefined item type' }] }
        }

        if (result.successed && result.data) {
            hideEditPanel()

            let containerParentId: string | undefined = undefined
            if (state.type === StructureNodeTypes.Container && state.parentId && state.parentId !== (result.data as IContainer).parentId) {
                containerParentId = (result.data as IContainer).parentId
            }

            let node: IStructureNode = {
                id: result.data?.id ?? '',
                itemId: result.data.id ?? '',
                itemName: result.data?.name ?? '',
                parentId: containerParentId ? (containerParentId.length > 0 ? containerParentId : state.parentId) : '',
                type: state.type,
                disabled: state.type === StructureNodeTypes.Point && !(result.data as IPoint).enabled,
                itemCode: state.type === StructureNodeTypes.Point ? (state.data as IPoint).code : undefined
            }

            switch (state.type) {
                case StructureNodeTypes.Container:
                    node.itemCode = (state.data as IContainer)?.code
                    node.parentId = (state.data as IContainer)?.parentId
                    break;
                case StructureNodeTypes.Point:
                    node.itemCode = (result.data as IPoint)?.code
                    node.parentId = (result.data as IPoint)?.containerId
                    node.companyId = (result.data as IPoint)?.companyId
                    break;
                case StructureNodeTypes.Person:
                    node.parentId = (result.data as IPerson)?.pointId ?? (result.data as IPerson)?.containerId
                    node.companyId = (result.data as IPerson)?.companyId
                    break;
                default:
            }

            if (!state.data?.id && result.data?.id) {
                addForestNode(node)
            }

            if (state.data?.id) {
                editForestNode(node)
            }

            setItemState({ ...state, data: result.data, error: undefined })
        }

        if (result.errors && result.errors.length > 0) {
            setPanelItemState({ type: state.type, data: state.data, fetched: true, error: result.errors[0].description })
        }
    }

    const onDeleteHandler = async (state: TStructureItemData) => {
        let result: IRequestResult<IContainer | IPoint | IPerson | undefined> = { successed: false }
        switch (state.type) {
            case StructureNodeTypes.Container:
                if (state.data?.id) {
                    result = await portalApi.organization.containers.deleteContainer(state.data.id)
                }
                break;
            case StructureNodeTypes.Point:
                if (state.data?.id) {
                    result = await portalApi.organization.points.deletePoint(state.data.id)
                }
                break;
            case StructureNodeTypes.Person:
                if (state.data?.id) {
                    result = await portalApi.organization.accounts.deleteAccount(state.data.id)
                }
                break;
            default:
                result = { successed: false, errors: [{ description: 'Undefined item type' }] }
        }
        // console.log(result)
        if (result.successed && state.data?.id) {
            hideConfirmBox()
            setPanelItemState(undefined)
            setItemState(undefined)
            setConfirmFormData(undefined)
            deleteForestNode(
                {
                    id: state.data.id,
                    itemId: state.itemId ?? '',
                    itemName: state.data?.name ?? '',
                    parentId: state.parentId ?? '',
                    type: state.type
                }
            )
        }
    }

    const onUpdateItemState = (data: IContainer | IPoint | IPerson) => {
        let node: IStructureNode = {
            id: data?.id ?? '',
            itemId: data.id ?? '',
            itemName: data?.name ?? '',
            type: itemState?.type as StructureNodeTypes
        }
        switch (itemState?.type as StructureNodeTypes) {
            case StructureNodeTypes.Container:
                break;
            case StructureNodeTypes.Point:
                data = data as IPoint
                
                node.disabled = !data.enabled,
                node.itemCode = data.code,
                node.parentId = data.containerId
                
                setItemState({ ...itemState, data: data, error: undefined, type: StructureNodeTypes.Point, fetched: true })
                
                if (data?.id) {
                    editForestNode(node)
                }
                break;
            case StructureNodeTypes.Person:
                data = data as IPerson
                
                node.companyId = data.companyId,
                node.parentId = data.pointId ?? data.containerId,
                node.disabled = !(data as IPerson).enabled,
                
                setItemState({ ...itemState, data: data, error: undefined, type: StructureNodeTypes.Person, fetched: true })
                
                if (data?.id) {
                    editForestNode(node)
                }
                break;
        }
    }

    const onExportToExcelAction = () => {
        location.href = endpoints.v1.exportStructure
    }

    const getActions = (item?: IStructureNode): IForestAction[] => {
        let itemActions: IForestAction[] = []
        let addActions: IForestAction = {
            id: 'addActions',
            name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.ADD,
            iconProps: { iconName: 'Add', style: { fontSize: 16, color: 'black' } },
            childs: []
        }

        const addContainerAction = {
            id: 'addContainerAction',
            iconProps: { iconName: "FabricFolderFill", style: { fontSize: 16, color: '#dcb67a' } },
            name: strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.TITLE,
            onClick: () =>
                onNodeAction(
                    StructureNodeActionTypes.Add,
                    StructureNodeTypes.Container,
                    item
                )
        }

        const addPointAction = {
            id: 'addPointAction',
            iconProps: { iconName: 'Home', style: { fontSize: 16, color: 'black' } },
            name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.ADD_POINT,
            onClick: () =>
                onNodeAction(
                    StructureNodeActionTypes.Add,
                    StructureNodeTypes.Point,
                    item
                )
        }

        const addPersonAction = {
            id: 'addPersonAction',
            iconProps: { iconName: 'Contact', style: { fontSize: 16, color: 'black' } },
            name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.ADD_PERSON,
            onClick: () =>
                onNodeAction(
                    StructureNodeActionTypes.Add,
                    StructureNodeTypes.Person,
                    item
                )
        }

        const editAction = {
            id: 'editAction',
            name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
            iconProps: { iconName: 'Edit', style: { fontSize: 16, color: 'black' } },
            onClick: () => item && onNodeAction(StructureNodeActionTypes.Update, item.type, item)
        }

        const removeAction = {
            id: 'removeAction',
            name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
            iconProps: { iconName: 'Delete', style: { fontSize: 16, color: 'black' } },
            onClick: () => item && onNodeAction(StructureNodeActionTypes.Remove, item.type, item)
        }

        const importFromExcelAction = {
            id: 'importFromExcelAction',
            iconProps: { iconName: "ExcelDocument", style: { fontSize: 16, color: SharedColors.green10 } },
            name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.IMPORT_FROM_EXCEL,
            onClick: showImportModal
        }

        const exportToExcelAction = {
            id: 'exportToExcelAction',
            iconProps: { iconName: "ExcelDocument", style: { fontSize: 16, color: SharedColors.green10 } },
            name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EXPORT_TO_EXCEL,
            onClick: onExportToExcelAction
        }

        const showStrucutreProtocolsAction = {
            id: 'showStrucutreProtocolsAction',
            iconProps: { iconName: "EntitlementPolicy", style: { fontSize: 16, color: SharedColors.gray30 } },
            name: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.PROTOCOLS,
            onClick: showProtocols
        }

        switch (item?.type) {
            case StructureNodeTypes.Container:
                addActions.childs?.push(addContainerAction, addPointAction, addPersonAction);
                itemActions.push(addActions, editAction, removeAction);
                break;
            case StructureNodeTypes.Point:
                addActions.childs?.push(addPersonAction);
                itemActions.push(addActions, editAction, removeAction);
                break;
            case StructureNodeTypes.Person:
                itemActions.push(editAction, removeAction);
                break;
            default:
                addActions.childs?.push(addContainerAction, addPointAction, addPersonAction);
                itemActions.push(addActions, importFromExcelAction, exportToExcelAction, showStrucutreProtocolsAction);
        }

        return itemActions
    }

    const getNodeIconProps = (type: StructureNodeTypes): IIconProps => {
        switch (type) {
            case StructureNodeTypes.Container: return { iconName: "FabricFolderFill", style: { fontSize: 16, color: '#dcb67a' } }
            case StructureNodeTypes.Point: return { iconName: "Home", style: { fontSize: 16 } }
            case StructureNodeTypes.Person: return { iconName: "Contact", style: { fontSize: 16 } }
            default: return { iconName: "Document", style: { fontSize: 16 } }
        }
    }

    const getMenuItemFromAction = (action: ITreeNodeAction): IContextualMenuItem => {
        const menuItem: IContextualMenuItem = {
            key: action.id,
            iconProps: action.iconProps,
            onClick: action.onClick,
            text: action.name,
            subMenuProps: !action.childs ? undefined : {
                items: action.childs?.map(a => getMenuItemFromAction(a))
            }
        }

        return menuItem
    }

    const getMenuProps = (actions: ITreeNodeAction[]): IContextualMenuProps => {
        return (
            {
                shouldFocusOnMount: true,
                items: actions?.map(action => getMenuItemFromAction(action))
            }
        )
    }

    return (
        <Stack verticalFill>
            {error && (
                <MessageBar messageBarType={MessageBarType.error}>
                    {error}
                </MessageBar>
            )}
            {(!structure.isReady || !preparedTrees) && (
                <Stack verticalFill verticalAlign="center">
                    <Stack horizontalAlign='center'>
                        <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                    </Stack>
                </Stack>
            )}
            {/* {preparedTrees && structure.isReady && structure.data.length === 0 && (
                <ItemsNotFound doNotUseButton
                    imgSrc={"images/navigation/images/organization.png"}
                    info={strings.ORGANIZATION.STRUCTURE.MANAGEMENT_TITLE}
                    suggestion={strings.ORGANIZATION.STRUCTURE.MANAGEMENT_INFORMATION} />
            )} */}
            {preparedTrees && structure.isReady && structure.data.length > 0 && (
                <Stack horizontal grow verticalFill verticalAlign="start">
                    <Stack.Item verticalFill style={{ width: '380px' }}>
                        {preparedTrees && organization?.name && (
                            <>
                                <Stack horizontal grow verticalAlign='center' tokens={{ padding: '8px 8px 0px 20px' }}>
                                    <Stack.Item grow>
                                        <Stack>
                                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
                                                <Image height={16} src="images/navigation/icons/work.svg" />
                                                <Stack>
                                                    <Text variant='large' style={{ fontWeight: 600 }}>{organization?.name}</Text>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <IconButton menuIconProps={{ style: { fontSize: 16, color: theme.palette.black }, iconName: "MoreVertical" }} menuProps={getMenuProps(getActions())} onClick={() => { }} />
                                    </Stack.Item>
                                </Stack>
                                {organization?.container && (
                                    <Stack horizontal grow verticalAlign='center' tokens={{ padding: '0px 8px 8px 20px' }}>
                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
                                            <Image height={16} src="images/navigation/icons/organization.svg" />
                                            <Stack>
                                                <Text variant="small" style={{ color: SharedColors.gray40 }}>{organization?.container}</Text>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                )}
                                <Stack tokens={{ padding: 8 }}>
                                    <SearchBox onChange={(ev, value) => setFilterString(value)} />
                                </Stack>
                                {(!filterString || filteredTrees) && (
                                    <OrganizationStructureTree organizationName={organization?.name}
                                        trees={filteredTrees ?? preparedTrees}
                                        actions={getActions()}
                                        setDetailsItem={setDetailsItemHandler}
                                        selectedKey={selectedTreeKey}
                                        getMenuProps={getMenuProps}
                                        setSelectedKey={setSelectedTreeKey}
                                        expandedKeys={expandedNodeList}
                                        collapseNode={collapseNode}
                                        expandNode={expandNode}
                                    />
                                ) || (
                                        <Stack verticalFill verticalAlign="center">
                                            <Stack horizontalAlign='center'>
                                                <Text>{strings.ORGANIZATION.STRUCTURE.TREE.EMPTY_FILTER}</Text>
                                            </Stack>
                                        </Stack>
                                    )}
                            </>
                        )}
                    </Stack.Item>
                    <Stack verticalFill style={{ width: 1, borderRight: `1px solid ${theme.palette.neutralQuaternary}` }}>
                    </Stack>
                    <Stack verticalFill grow>
                        {!isProtocolsShow && (!itemState && (
                            <ItemsNotFound doNotUseButton
                                imgSrc={"images/navigation/images/organization.png"}
                                info={strings.ORGANIZATION.STRUCTURE.MANAGEMENT_TITLE}
                                suggestion={strings.ORGANIZATION.STRUCTURE.MANAGEMENT_INFORMATION} />
                        ) || (
                                <>
                                    {itemState?.error && (
                                        <Stack verticalFill>
                                            <MessageBar messageBarType={MessageBarType.error}>
                                                {itemState.error}
                                            </MessageBar>
                                            <Section flat verticalFill>
                                                <SomethingWentWrong />
                                            </Section>
                                        </Stack>
                                    )}
                                    {!itemState?.fetched && (
                                        <Stack verticalFill verticalAlign='center'>
                                            <Spinner label={strings.SPINNERS.DATA_IS_LOADING} />
                                        </Stack>
                                    ) || (itemState?.data && (
                                        <StructureElementDetails updateTreeItemData={() => { }} item={itemState} updateState={onUpdateItemState} onEdit={onUpdateStateItem} onDelete={() => itemState && onNodeAction(StructureNodeActionTypes.RemoveFromItemState, itemState.type)} />
                                    ))}
                                </>
                            ))}
                        {isProtocolsShow && (organization?.id && (
                            <StructureProtocols lastDate={lastDate} organizationId={organization?.id} />
                        ) || (
                                <SomethingWentWrong />
                            ))}
                    </Stack>
                    {panelItemState && (
                        <StructureItemPanel
                            isOpen={editPanelState}
                            item={panelItemState}
                            onConfirm={onConfirmHandler}
                            onCancel={onHideEditPanelHandler}
                            denied={isAdminPerson}
                        />
                    )}
                    {panelItemState?.data && confirmBoxState && confirmFormData && (
                        <Modal isOpen={confirmBoxState ? true : false} onDismiss={hideConfirmBox} containerClassName={contentStyles.container}>
                            <div className={contentStyles.header}>
                                <span style={{ paddingRight: 32 }}>{confirmFormData.title}</span>
                                <IconButton
                                    styles={iconButtonStyles}
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Close"
                                    onClick={hideConfirmBox}
                                />
                            </div>
                            <div className={contentStyles.body}>
                                {isAdminPerson && (
                                    <ItemsNotFound doNotUseButton
                                        imgSrc={"images/navigation/images/security_crop.png"}
                                        info={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DENIED_INFO}
                                        suggestion={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.DENIED_DELETE_SUGGESTION} />
                                ) || (
                                        <ConfirmationForm
                                            onConfirm={() => onDeleteHandler(panelItemState)}
                                            onCancel={hideConfirmBox}
                                            requiredMessageString={confirmFormData?.requiredTextString}
                                            confirmationCheckString={panelItemState?.data?.name ?? ''}
                                            placeholder={confirmFormData?.placeholder}
                                            confirmText={strings.BUTTONS.TEXT.DELETE}
                                        >
                                            <Stack tokens={{ childrenGap: 12 }}>
                                                <Text>{confirmFormData.text1}</Text>
                                                <Text>{confirmFormData.text2}</Text>
                                                <Text>{confirmFormData.requirementText} <b>"{panelItemState.data.name}"</b></Text>
                                            </Stack>
                                        </ConfirmationForm>
                                    )}
                            </div>
                        </Modal>
                    )}
                    {isImportModalShowed && (
                        <ImportStructureModal
                            type="icon"
                            src='ExcelDocument'
                            show={isImportModalShowed}
                            onHide={hideImportModal}
                            acceptTypeMask={".xlsx"}
                            allowedFileTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                            lable={strings.ORGANIZATION.STRUCTURE.TREE.IMPORT_FROM_EXCEL.LABEL}
                            description={strings.ORGANIZATION.STRUCTURE.TREE.IMPORT_FROM_EXCEL.DESCRIPTION}
                            iconColor={SharedColors.green10}
                            onSuccessed={() => dispatch(actionCreators.requestStructure())}
                            title={strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.IMPORT_FROM_EXCEL}
                            sendMethod={portalApi.organization.structure.import}
                            onResult={() => setLastDate(new Date())}
                        />
                    )}
                </Stack>
            )}
        </Stack>
    )
}

const getConfirmFormData = (type: StructureNodeTypes) => {
    let title = ''
    let placeholder = ''
    let requiredTextString = ''
    let requirementText = ''
    let text1 = ''
    let text2 = ''
    switch (type) {
        case StructureNodeTypes.Container:
            title = strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.REMOVE_CONFIRMATION_TITLE
            placeholder = strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.NAME_PLACEHOLDER
            requiredTextString = strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.FIELDS.NAME_REQUIRED
            requirementText = strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.REMOVE_CONFIRMATION_REQUIREMENTS
            text1 = strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.REMOVE_CONFIRMATION_TEXT1
            text2 = strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.REMOVE_CONFIRMATION_TEXT2
            break;
        case StructureNodeTypes.Point:
            title = strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TITLE
            placeholder = strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_PLACEHOLDER
            requirementText = strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_REQUIREMENTS
            requiredTextString = strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME_REQUIRED
            text1 = strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TEXT1
            text2 = strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_CONFIRMATION_TEXT2
            break;
        case StructureNodeTypes.Person:
            title = strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TITLE
            placeholder = strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.USERNAME_PLACEHOLDER
            requirementText = strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_REQUIREMENTS
            requiredTextString = strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_AREA.USERNAMES_DO_NOT_MATCH
            text1 = strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TEXT1
            text2 = strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.REMOVE_CONFIRMATION_TEXT2
            break;
        default:
    }
    return {
        title: title,
        placeholder: placeholder,
        requiredTextString: requiredTextString,
        requirementText: requirementText,
        text1: text1,
        text2: text2,
    }
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};