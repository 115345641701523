import { AppThunkAction } from "..";
import { endpoints } from "../api/endpoints";
import { consoleStyles } from "../scripts/console";
import { IValidationToken } from "../store/typings/IValidationToken";
import { IValidationApis } from "../store/typings/IValidationApis";

export interface RequestValidationApiTokenAction { type: 'VALIDATION_API_TOKEN_REQUEST' }
export interface ReceiveValidationApiTokenAction { type: 'VALIDATION_API_TOKEN_RECEIVE', data: IValidationToken[] }
export interface CreateValidationApiTokenAction { type: 'VALIDATION_API_TOKEN_CREATE', data: IValidationToken }
export interface UpdateValidationApiTokenAction { type: 'VALIDATION_API_TOKEN_EDIT', data: IValidationToken }
export interface EnableValidationApiTokenAction { type: 'VALIDATION_API_TOKEN_ENABLE', data: IValidationToken }
export interface RequestValidationApiApisAction { type: 'VALIDATION_API_APIS_REQUEST' }
export interface ReceiveValidationApiApisAction { type: 'VALIDATION_API_APIS_RECEIVE', data: IValidationApis[] }

export type KnownAction = RequestValidationApiTokenAction | ReceiveValidationApiTokenAction | 
                    CreateValidationApiTokenAction | UpdateValidationApiTokenAction | EnableValidationApiTokenAction |
                    RequestValidationApiApisAction | ReceiveValidationApiApisAction;

export const actionCreators = {
    requestValidation: (): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        console.debug("%c" + "LOGIC requestValidation()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true){
            console.debug("Client is Authenticated!");
            dispatch({type: "VALIDATION_API_TOKEN_REQUEST"});
            fetch(endpoints.v1.picea.validationToken)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json() as Promise<IValidationToken[]>;
                })
                .then(data => {
                    dispatch({ type: 'VALIDATION_API_TOKEN_RECEIVE', data: data });
                })
                .catch(response => {
                    console.log(response);
                });
        }    
    },
    requestValidationApis: (): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        console.debug("%c" + "LOGIC requestValidationApiApis()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true){
            console.debug("Client is Authenticated");
            dispatch({type: "VALIDATION_API_APIS_REQUEST"});
            fetch(endpoints.v1.picea.validationApis)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json() as Promise<IValidationApis[]>
                })
                .then(data => {
                    dispatch({ type: 'VALIDATION_API_APIS_RECEIVE', data: data });
                })
                .catch(response => {
                    console.log(response);
                });
        }    
    },
    createValidationToken: (token: IValidationToken): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC createValidationTokenApi()", consoleStyles.logic);
        const appState = getState();

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(token) 
          };

        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            console.debug("Client is Authenticated!");
            fetch(endpoints.v1.picea.validationToken, requestOptions)
                .then(response => {
                    return response.json() as Promise<IValidationToken>
                })
                .then((validation_token) => {
                    const newToken = {
                        ...token,
                        token: validation_token.data.token
                    }
                    dispatch({ type: 'VALIDATION_API_TOKEN_CREATE', data: newToken });
                })
                .catch(response => {
                    console.log(response);
                });
        }
    },
    updateValidationToken: (token: IValidationToken): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateValidationTokenApi()", consoleStyles.logic);
        const appState = getState();

        const requestOptions: RequestInit = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(token) 
          };

        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            console.debug("Client is Authenticated!");
            fetch(endpoints.v1.picea.validationToken, requestOptions)
                .then(response => {
                    return response.json() as Promise<IValidationToken>
                })
                .then(() => {
                    dispatch({ type: 'VALIDATION_API_TOKEN_EDIT', data: token });
                })
                .catch(response => {
                    console.log(response);
                });
        }
    },
    enableValidationToken: (token: IValidationToken): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC updateValidationTokenApi()", consoleStyles.logic);
        const appState = getState();

        const requestOptions: RequestInit = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(token) 
          };

        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            console.debug("Client is Authenticated!");
            fetch(endpoints.v1.picea.validationEnableToken, requestOptions)
                .then(response => {
                    return response.json() as Promise<IValidationToken>
                })
                .then((validation_token) => {
                    const enabledToken = {
                        ...token,
                        is_valid: validation_token.data.is_valid
                    }
                    dispatch({ type: 'VALIDATION_API_TOKEN_ENABLE', data: enabledToken });
                })
                .catch(response => {
                    console.log(response);
                });
        }
    },
    // deleteValidationToken: (token: IValidationToken): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //     console.debug("%c" + "LOGIC deleteValidationTokenApi()", consoleStyles.logic);
    //     const appState = getState();
    //     if (appState && appState.environment && appState.environment.isAuthenticated === true) {
    //         console.debug("Client is Authenticated!");

    //         const requestOptions: RequestInit = {
    //             method: 'DELETE',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify(token)
    //         };
                
    //         fetch(endpoints.v1.picea.validationToken, requestOptions)
    //             .then(response => response.json() as Promise<string>)
    //             .then(() => {
    //                 dispatch({ type: 'VALIDATION_API_TOKEN_DELETE', data: token });
    //             })
    //             .catch(response => {
    //                 console.log(response);
    //             });
    //     }
    // }
}