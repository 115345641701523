import { Stack, SharedColors, Text } from "@fluentui/react"
import { PropertyValue } from "../../../../../../components/shared/decorations/PropertyValue"
import { getLocalizedTimezone } from "../../../../../../core/helpers/timezone"
import { IPoint } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"

export type TPointInformationPivotProps = {
    item: IPoint
}

export const PointInformationPivot: React.FC<TPointInformationPivotProps> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 12 }}>
            <Stack tokens={{ childrenGap: 6 }}>
                <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CODE} value={props.item.code}/>
                <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.NAME} value={props.item.name}/>
            </Stack>
            <Stack tokens={{ childrenGap: 6 }}>
                <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.CITY} value={props.item.city}/>
                <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDRESS} value={props.item.address}/>
            </Stack>
            <Stack tokens={{ childrenGap: 6 }}>
                <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.TIMEZONE} value={props.item.timezone ? getLocalizedTimezone(props.item.timezone) : undefined}/>
            </Stack>
            <Stack tokens={{ childrenGap: 6}}>
                <PropertyValue label={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.ADDITIONAL_INFORMATION} value={props.item.additionalInformation}/>
            </Stack>
        </Stack>
    )
}
