import { Control, Controller } from "react-hook-form"
import PassportNumber from "./PassportNumber"
import { getMaskedFieldRules } from "../../../helpers/validationRules"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import type { TContractData } from "../../../../../dataCollectionTypes"

type TProps = {
    config?: IDataCollectionStandardConfig
    errorMessage: string
    fieldValue?: string
    control: Control<TContractData, object>,
}

const PassportNumberController: React.FunctionComponent<TProps> = (props: TProps) => {
    let {config, control, fieldValue, errorMessage} = props
    return (
        <Controller
            name="passport.number"
            control={control}
            rules={getMaskedFieldRules(fieldValue)}
            render={({ field }) =>
                <PassportNumber
                    config={config}
                    value={field.value}
                    errorMessage={errorMessage}
                    handleOnBlur={field.onBlur}
                    handleOnChange={(value: string) => {
                        field.onChange(value)
                    }}
                />
            }
        />
    )
}

export default PassportNumberController