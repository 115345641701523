import {
    getTheme, Stack, Text, Separator, PrimaryButton, ScrollablePane, Sticky, MessageBar, MessageBarType,
    Dropdown, Toggle, Spinner, DatePicker, Check, TextField
} from '@fluentui/react'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { strings } from '../../../localization/strings'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { ChooseIcon } from '../chooseIcon/ChooseIcon'
import { portalApi } from '../../../core/api/api'
import { IRequestResult } from '../../../core/store/typings/IResponseResult'
import { IStore } from '../../../core/store'
import { prepareCurrencyOptionsRequired } from '../../../core/helpers/currency'
import { useSelector } from 'react-redux'
import { IResponseError, ISelfServicePromotion, OpeningPreferences } from '@piceasoft/core';
import unknown from "../../../assets/emptyimage.svg"
import {inputStyles, ParameterText} from '../configurator/components/ParameterText'
import { getUTCEndDate, getUTCStartDate } from '../../../core/helpers/date-time'

type TProps = {
    onSubmit: (item: ISelfServicePromotion) => void
    onCancel: () => void
    codes: string[]
    data?: ISelfServicePromotion
}

export const SelfServicePromotionForm: React.FC<TProps> = (props) => {

    console.log(props.data)
    const defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear());
    const [checked, setChecked] = React.useState(props.data?.customPrimaryButtonLabel !== null && props.data?.customPrimaryButtonLabel !== undefined ? true : false);
    const organizationCurrency = useSelector((s: IStore) => s.workplace.organization?.currency);
    const currency = prepareCurrencyOptionsRequired();
    const openPreferences = [{ key: OpeningPreferences.NewTab, text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.NEW_BROWSER_TAB }, { key: OpeningPreferences.SameBrowser, text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CURRENT_BROWSER_TAB }]
    const { control, handleSubmit, reset, watch, getValues, setValue, formState: { errors } } = useForm<ISelfServicePromotion>({ defaultValues: { ...props.data, currency: props.data?.currency ?? organizationCurrency, validFrom: props.data?.validFrom ? props.data?.validFrom : defaultDate } });
    const onSubmit: SubmitHandler<ISelfServicePromotion> = async data => {

        let icon = props.data?.imageSrc
        let errors: IResponseError[] = []
        if (icon !== fileSrc) {
            setIsFetchingIcon(true)
            setIconErrors([])
            let uploadResult: IRequestResult<string> = { successed: false }
            if (file) {
                uploadResult = await portalApi.settings.organization.uploadFile('offer-provider-icon', file, undefined, true);
                if (uploadResult.errors && uploadResult.errors.length > 0) {
                    errors = uploadResult.errors;
                }
            } else {
                uploadResult.successed = true;
            }

            if (uploadResult.successed) {
                icon = uploadResult.data
                setIsIconChanged(false)
            }
            setIsFetchingIcon(false)
        }

        if (errors.length === 0) {
            if (!checked) {
                props.onSubmit({ ...data, customPrimaryButtonLabel: undefined, imageSrc: icon ?? "", validFrom: getUTCStartDate(data.validFrom ?? new Date()), expirationDate: data.expirationDate == null || data.expirationDate === undefined ? undefined : getUTCEndDate(data.expirationDate) })
            } else {
                props.onSubmit({ ...data, imageSrc: icon ?? "", customPrimaryButtonLabel: data.customPrimaryButtonLabel === null || data.customPrimaryButtonLabel?.trim() === '' ? strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CUSTOM_PRIMARY_BUTTON_LABEL_PROCEED : data.customPrimaryButtonLabel, validFrom: getUTCStartDate(data.validFrom ?? new Date()), expirationDate: data.expirationDate == null || data.expirationDate === undefined ? undefined : getUTCEndDate(data.expirationDate) })
            }

        } else {
            setIconErrors(errors)
        }
    };

    const state = watch();

    const [file, setFile] = React.useState<File>()
    const [isFetchingIcon, setIsFetchingIcon] = React.useState(false)
    const [isIconChanged, setIsIconChanged] = React.useState(false)
    const [iconErrors, setIconErrors] = React.useState<IResponseError[]>([])
    const [fileSrc, setFileSrc] = React.useState(props.data?.imageSrc)

    React.useEffect(() => {
        setFileSrc(props.data?.imageSrc)
        setFile(undefined)
    }, [props.data?.imageSrc])


    React.useEffect(() => {
        if (iconErrors.length > 0) {
            setIconErrors([])
        }
    }, [fileSrc])

    const allowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif", "image/svg+xml"]

    const addFile = (upFiles: FileList) => {
        console.log(upFiles)
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => allowedFileTypes.includes(upFile.type))]
        console.log(checkedUpFiles)
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            setIsIconChanged(true)
            setFileSrc(URL.createObjectURL(checkedUpFiles[0]))
            setFile(checkedUpFiles[0])
        }
    }

    const resetImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFileSrc(props.data?.imageSrc)
            setFile(undefined)
            setIsIconChanged(false)
        }
    }

    const deleteImageSrc = () => {
        if (fileSrc) {
            setFileSrc(undefined)
            setIsIconChanged(true)
        }
    }

    const formatDate = (date?: Date): string => {
        return date?.toLocaleDateString() ?? "";
    }

    const parseDateFromString = (value: string): Date | null => {
        let data = value.split(".");
        if (data.length === 3) {
            return new Date(Number(data[2]), Number(data[1]) - 1, Number(data[0]));
        } else {
            return null;
        }
    }

    const onCheckedChange = (checked: boolean | undefined) => {
        setChecked(checked ?? false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        {iconErrors.length > 0 && (
                            <Sticky>
                                <MessageBar messageBarType={MessageBarType.error}>
                                    <Stack tokens={{ childrenGap: 2 }}>
                                        {iconErrors.map(i => <Text>{i.description}</Text>)}
                                    </Stack>
                                </MessageBar>
                            </Sticky>
                        )}
                        <Stack verticalFill tokens={{ padding: "0px 20px 20px 20px", childrenGap: 16 }}>
                            <Controller
                                control={control}
                                name={"title"}
                                rules={{
                                    validate: (value) => value && value.trim().length > 0 ? true : strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.TITLE_REQUIRED,
                                    required: strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.TITLE_REQUIRED
                                }}
                                render={({ field }) =>
                                    <ParameterText
                                        required={true}
                                        value={field.value}
                                        onChange={field.onChange}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        maxLength={50}
                                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.TITLE_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.TITLE_PLACEHOLDER}
                                        errorMessage={errors.title?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"tagline"}
                                render={({ field }) =>
                                    <ParameterText
                                        value={field.value}
                                        onChange={field.onChange}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        maxLength={200}
                                        draggable={true}
                                        multiline={true}
                                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTION_TAGLINE_LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTION_TAGLINE_TITLE}
                                        placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTION_TAGLINE_PLACE_HOLDER}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"description"}
                                render={({ field }) =>
                                    <ParameterText
                                        value={field.value !== null ? field.value : ""}
                                        onChange={field.onChange}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        maxLength={500}
                                        draggable={true}
                                        multiline={true}
                                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTION_DESCRIPTION.LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTION_DESCRIPTION_TITLE}
                                        placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PROMOTION_DESCRIPTION_PLACE_HOLDER}
                                    />
                                }
                            />
                            <Controller
                                name="currency"
                                control={control}
                                rules={{
                                    required: strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CURRENCY_REQUIRED,
                                }}
                                render={({ field }) =>
                                    <Dropdown
                                        selectedKey={field.value}
                                        required
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CURRENCY_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CURRENCY_PLACE_HOLDER}
                                        options={currency}
                                        errorMessage={errors.currency?.message}
                                    />}
                            />
                            <Controller
                                control={control}
                                name={"price"}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value === undefined || field.value === null ? '' : String(field.value).toLocaleString()}
                                        onChange={(ev, val) => val ? (/^(?:\d{1,15}(?:\.\d{0,4})?|(?=.{16})\d{15}\.\d{0,4})$/.test(val) && field.onChange(val)) : field.onChange(null)}
                                        style={{ width: 350 }}
                                        styles={{ field: inputStyles.field }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PRICE_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PRICE_PLACE_HOLDER}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"priceHeadline"}
                                render={({ field }) =>
                                    <ParameterText
                                        value={field.value === undefined ? "" : field.value}
                                        onChange={field.onChange}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        maxLength={40}
                                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PRICE_HEAD_LINE_LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PRICE_HEAD_LINE_TITLE}
                                        placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.PRICE_HEAD_LINE_PLACE_HOLDER}
                                    />
                                }
                            />
                            <Stack>
                                <Separator />
                                {onRenderLabel({
                                    label: strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.IMAGE_LABEL
                                })}
                                <ChooseIcon
                                    acceptTypeMask={"image/*"}
                                    allowedFileTypes={allowedFileTypes}
                                    type="image"
                                    changed={isIconChanged}
                                    resetChanged={() => setIsIconChanged(true)}
                                    imgSrc={getValues().imageSrc}
                                    fileSrc={fileSrc}
                                    itemName={getValues().imageSrc}//* name
                                    lable={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ICON_LOAD_LABEL}
                                    defaultIcon={unknown}
                                    description={true}
                                    //generateIcon
                                    addFile={addFile}
                                    deleteImageSrc={deleteImageSrc}
                                    resetImageSrc={resetImageSrc}
                                />
                                <Separator />
                            </Stack>
                            <Controller
                                control={control}
                                name="validFrom"
                                render={({ field }) =>
                                    <Stack.Item>
                                        {onRenderLabel({
                                            label: strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.VALID_FROM_LABEL
                                        })}
                                        <DatePicker
                                            value={field.value ? new Date(field.value) : defaultDate}
                                            onSelectDate={(date) => {
                                                field.onChange(date);
                                            }}
                                            defaultValue={defaultDate.toString()}
                                            formatDate={(date?: Date) => formatDate(date)}
                                            parseDateFromString={parseDateFromString}
                                            onBlur={field.onBlur}
                                            placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.VALID_FROM_PLACEHOLDER}
                                            strings={strings.DAY_PICKER}
                                        />
                                    </Stack.Item>
                                }
                            />
                            <Controller
                                control={control}
                                name="expirationDate"
                                render={({ field }) =>
                                    <Stack.Item>
                                        {onRenderLabel({
                                            label: strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.EXPIRATION_DATE_LABEL
                                        })}
                                        <DatePicker
                                            value={field.value ? new Date(field.value) : undefined}
                                            onSelectDate={(date) => {
                                                field.onChange(date);
                                            }}
                                            formatDate={(date?: Date) => formatDate(date)}
                                            parseDateFromString={parseDateFromString}
                                            onBlur={field.onBlur}
                                            placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.EXPIRATION_DATE_PLACE_HOLDER}
                                            strings={strings.DAY_PICKER}
                                        />
                                    </Stack.Item>
                                }
                            />
                            <Controller
                                control={control}
                                name={"destinationUrl"}
                                render={({ field }) =>
                                    <ParameterText
                                        value={field.value ?? ""}
                                        onChange={field.onChange}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.DESTINATION_URL_LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.DESTINATION_URL_TITLE}
                                        placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.DESTINATION_URL_PLACE_HOLDER}
                                    />
                                }
                            />
                            <Controller
                                name="openPreferences"
                                control={control}
                                render={({ field }) =>
                                    <Dropdown
                                        selectedKey={field.value}
                                        onChange={(ev, opt) => field.onChange(opt?.key)}
                                        onBlur={field.onBlur}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.LINK_OPENING_PREFERENCES_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.LINK_OPENING_PREFERENCES_PLACE_HOLDER}
                                        options={openPreferences}
                                    />}
                            />
                            <Controller
                                name="customPrimaryButtonLabel"
                                control={control}
                                render={({ field }) =>
                                    <Stack>
                                        <Toggle
                                            checked={checked}
                                            onChange={(ev, checked) => onCheckedChange(checked)}
                                            onBlur={field.onBlur}
                                            label={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CUSTOM_PRIMARY_BUTTON_LABEL_LABEL}
                                            offText={strings.BUTTONS.TOGGLE_OFF}
                                            onText={strings.BUTTONS.TOGGLE_ON}
                                        />
                                        <ParameterText
                                            disabled={!checked}
                                            value={field.value ?? strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CUSTOM_PRIMARY_BUTTON_LABEL_PROCEED}
                                            onChange={field.onChange}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            maxLength={20}
                                            placeholder={strings.CONSTRUCTOR.STAGES.SELF_SERVICE_PROMOTION.CUSTOM_PRIMARY_BUTTON_LABEL}
                                        />
                                    </Stack>
                                }
                            />
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        {isFetchingIcon && (
                            <Spinner />
                        )}
                        <PrimaryButton disabled={isFetchingIcon} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}
const theme = getTheme();
