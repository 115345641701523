import axios, { AxiosRequestConfig } from "axios";
import { consoleStyles } from "../scripts/console";
import { environmentApi } from "./environment.api";
import { logApi } from "./log";
import { organizationApi } from "./organization.api";
import { overviewApi } from "./overview.api";
import { settingsApi } from "./settings.api";

export const portalApi = {
    organization: organizationApi,
    log: logApi,
    environment: environmentApi,
    overview: overviewApi,
    settings: settingsApi
}

export interface IApiResponse<T> {
    successed?: boolean
    error?: any
    data?: T
}

export async function getApiResponse<T>(url: string, config?: AxiosRequestConfig): Promise<IApiResponse<T>> {
    let result: IApiResponse<T> = {};
    await axios(url, config as AxiosRequestConfig).then(async response => {
        const contentType = response.headers["content-type"];
        return (contentType && contentType.indexOf("application/json") !== -1) ? await response.data as T : undefined;
    }).then(data => {
        result = { successed: true };
        result.data = data;
    }).catch(error => {
        console.debug("%c" + "FETCH " + url, consoleStyles.apiCollback);
        console.info(error);
        result.error = error.message;
    });
    return result;
}
