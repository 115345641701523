import { Modal, IconButton, Dropdown, IComboBoxOption, Icon, IDropdownOption, SharedColors, Stack, Text, Image, PrimaryButton, Spinner, FontIcon, IDropdownProps, IRenderFunction, ISelectableOption, ProgressIndicator } from "@fluentui/react";
import { getTheme, mergeStyleSets, FontWeights, getFocusStyle, IconFontSizes } from "@fluentui/style-utilities";
import { useBoolean, useId } from "@uifabric/react-hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { escapeRegExp } from "../../assets/ts/utils/regex";
import { actionCreators } from "../../core/actions/environment-actions";
import { portalApi } from "../../core/api/api";
import { IStore } from "../../core/store";
import { IOrganization } from "@piceasoft/core";
import { strings } from "../../localization/strings";
import { SecondaryButton } from "./buttons/SecondaryButton";
import SuggestedSearch, { handleTextToListKeyPress, focusElement } from "./search/SuggestedSearch";

type TProps = {
    isOpen: boolean
    onDismiss: () => void
}

export const ChangeOrganization: React.FC<TProps> = (props) => {

    const organizationId = useSelector((s: IStore) => s.workplace.organization?.id)

    const [organizations, setOrganizations] = React.useState<{ fetched: boolean, data?: IOrganization[], error?: string }>({ fetched: false })

    const [organizationsOptions, setOrganizationsOptions] = React.useState<IDropdownOption[]>()
    const [isFetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const [success, setSuccess] = React.useState(false)

    const organizationsHostId = useId("organizations-host-id")
    const organizationsTextId = useId("organizations-text-id")
    const organizationsCalloutListId = useId("organizations-callout-id")

    const [selectedOrganizationOption, setSelectedOrganizationOption] = React.useState<IComboBoxOption>()

    const dispatch = useDispatch();

    React.useEffect(() => {
        getOrganizations()
    }, [])

    React.useEffect(() => {
        setOrganizationsOptions(organizations?.data?.map(i => {
            return { key: i.id, text: i.name, data: i } as IDropdownOption
        }) ?? [])
    }, [organizations.data])

    React.useEffect(() => {
        const currentOrganizationOption = organizationsOptions?.find(i => i.key === organizationId)
        if (currentOrganizationOption) {
            setSelectedOrganizationOption(currentOrganizationOption)
        }
    }, [organizationsOptions])

    const getOrganizations = async () => {
        const result = await portalApi.settings.getOrganizations()
        setOrganizations({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
    }

    const changeOrganization = async () => {
        if (selectedOrganizationOption?.key) {
            startFetch()
            const result = await portalApi.settings.changeOrganization(selectedOrganizationOption.key.toString())
            console.log(result)
            if (result.successed) {
                setSuccess(true)
                location.href = '/';
            }
            if (result.errors) {
                setOrganizations({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
            }
            stopFetch()
        }
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.neutralQuaternaryAlt : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    // padding: 2,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.neutralQuaternaryAlt,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    cursor: 'pointer',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderDropDownOption: IRenderFunction<ISelectableOption<IOrganization>> = (item) => {
        return (
            <Stack 
            // onClick={() => setSelectedOrganizationOption(item)} 
            verticalAlign='center'
                horizontal horizontalAlign="space-between" grow tokens={{ padding: 4, childrenGap: 18 }}>
                <Stack tokens={{ padding: 2 }}>
                    <Text block nowrap variant="medium" style={{ color: SharedColors.gray20 }}>{item?.text}</Text>
                    <Text block nowrap variant="medium" style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{item?.data?.domain}</Text>
                </Stack>
                <Stack verticalAlign='center' style={{ height: 48 }}>
                    <Image height={24} src={item?.data?.logo ?? "/favicon.png"} />
                </Stack>
            </Stack>
        );
    }

    const onRenderSuggestionsCell = (item?: IDropdownOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${organizationsCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, organizationsOptions?.length ?? 0, organizationsTextId, organizationsCalloutListId, () => setSelectedOrganizationOption(item), index, item?.text, organizationsHostId)}
                tabIndex={-1}
                onClick={() => {
                    setSelectedOrganizationOption(item)
                    focusElement(`${organizationsCalloutListId}-${index as number}`, organizationsHostId)
                }}
            >
                <Stack verticalAlign='center' horizontal horizontalAlign="space-between" grow tokens={{ padding: 4, childrenGap: 18 }}>
                    <Stack tokens={{ padding: 2 }}>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray20 }}>{item?.text}</Text>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{(item?.data as IOrganization)?.domain}</Text>
                    </Stack>
                    <Stack verticalAlign='center' style={{ height: 48 }}>
                        <Image height={24} src={(item?.data as IOrganization)?.logo ?? "/favicon.png"} />
                    </Stack>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i => [(i.data as IOrganization).domain, (i.data as IOrganization).name].find(i => i && escapeRegExp(i.toLowerCase())
            .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null))
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey, data: i.data } as IDropdownOption)
            })
    }

    const handleLogout = () => {
        dispatch(actionCreators.requestSignOut());
    }

    return (
        <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span style={{ paddingRight: 32 }}>{strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.TITLE}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close"
                    onClick={props.onDismiss}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack tokens={{ childrenGap: 16 }}>
                    {(organizationsOptions?.length ?? 0) <= 5 && (
                        <Dropdown
                            // label={strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.CHOOSE_ORGANIZATION_LABEL}
                            options={organizationsOptions ?? []}
                            onChange={(ev, opt) => setSelectedOrganizationOption(opt)}
                            disabled={!organizationsOptions || organizationsOptions.length === 0}
                            styles={{
                                dropdown: { width: '100%' },
                                dropdownItem: { height: 64, borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }, dropdownItemSelected: { height: 64, borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}` }
                            }}
                            selectedKey={selectedOrganizationOption?.key.toString()}
                            onRenderOption={onRenderDropDownOption}
                            errorMessage={organizations.error}
                            placeholder={(!organizationsOptions) ?
                                strings.SPINNERS.DATA_IS_GETTING :
                                organizationsOptions?.find(i => i.key === selectedOrganizationOption?.key)?.text ?? (
                                    organizationsOptions.length > 0 ?
                                        strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.CHOOSE_ORGANIZATION_PLACEHOLDER :
                                        strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.NO_ORGANIZATIONS
                                )
                            } />
                    ) || (
                            <SuggestedSearch
                                type="dropdown-search"
                                inputBoxId={organizationsTextId}
                                hostId={organizationsHostId}
                                inputBoxPlaceholder={!organizationsOptions ?
                                    strings.SPINNERS.DATA_IS_GETTING :
                                    // organizationsOptions?.find(i => i.key === selectedOrganizationOption?.key)?.text ?? (organizationsOptions &&
                                    organizationsOptions.length > 0 ?
                                        strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.CHOOSE_ORGANIZATION_PLACEHOLDER :
                                        strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.NO_ORGANIZATIONS
                                    // )
                                }
                                onClickSuggestionCell={setSelectedOrganizationOption}
                                searchRequest={organizationsOptions?.find(i => i.key === selectedOrganizationOption?.key)?.text}
                                suggestionsListWidth={430}
                                inputBoxWidth={'100%'}
                                maxHeigth={320}
                                errorMessage={organizations.error}
                                disabled={!organizationsOptions || organizationsOptions.length === 0}
                                suggestions={organizationsOptions ?? []}
                                suggestionsListId={organizationsCalloutListId}
                                isSearchEqualTheOneSuggestion={false}
                                onRenderSuggestionCell={onRenderSuggestionsCell}
                                prepareFilteredItems={prepareFilteredOptions}
                                selectedKey={selectedOrganizationOption?.key.toString()}
                            />
                        )}
                    <Stack verticalAlign='center' tokens={{ childrenGap: 8 }} horizontal horizontalAlign='end'>
                        {!organizationId && (<PrimaryButton onClick={handleLogout} text={strings.BUTTONS.TEXT.LOGOUT} />)}
                        <PrimaryButton disabled={!selectedOrganizationOption?.key || selectedOrganizationOption.key === organizationId} onClick={changeOrganization} text={strings.BUTTONS.TEXT.CONFIRM} />
                        <SecondaryButton onClick={props.onDismiss} text={strings.BUTTONS.TEXT.CANCEL} />
                    </Stack>
                    {(isFetching || success) && (
                        <ProgressIndicator label={!success ?
                            strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.ORGANIZATION_CHANGING :
                            strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.REDIRECTING} />
                    )}
                </Stack>
            </div>
        </Modal>
    )
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

