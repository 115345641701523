import React, {Dispatch, FC, useCallback} from "react";
import {ISelfServiceConfigAnswer} from "@piceasoft/core";
import {
    DirectionalHint,
    getFocusStyle,
    getTheme,
    IButtonStyles,
    Icon,
    IconButton,
    IContextualMenuProps,
    List,
    mergeStyleSets,
    SharedColors,
    Stack,
    Text
} from "@fluentui/react";
import {actionCreators} from "../../../../../../core/actions/configurator-actions";
import {elementHeight, itemPadding, paddingLeftDefault, styles} from "./styles";
import {strings} from "../../../../../../localization/strings";

const theme = getTheme();
const menuIconStyles: IButtonStyles = {
    root: {
        padding: 0
    },
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    }
}

interface IProps {
    items: { isSelected: boolean, data: ISelfServiceConfigAnswer, type?: string | undefined, isBold?: boolean | undefined }[]
    setSelectedKey: (key: string) => void
    selectedKey: string
    onChangeDispatch: Dispatch<any>
    title?: string
    allowControls?: boolean,
    paddingLeft?: number,
    onEdit?: (answerId: ISelfServiceConfigAnswer) => void
}

const AnswersItemsList: FC<IProps> = ({
                                          items,
                                          setSelectedKey,
                                          selectedKey,
                                          title,
                                          allowControls,
                                          onChangeDispatch,
                                          paddingLeft = paddingLeftDefault,
                                          onEdit
                                      }) => {
    const getItemMenuProps = (item: ISelfServiceConfigAnswer, onDisable: (item: ISelfServiceConfigAnswer) => void, onEdit: (item: ISelfServiceConfigAnswer) => void): IContextualMenuProps => ({
        items: [
            {
                key: `edit-${item.id}`,
                onClick: () => onEdit(item),
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.RENAME_OPTION,
            },
            {
                key: `delete-${item.id}`,
                onClick: () => onDisable(item),
                text: item.disable ? strings.CONSTRUCTOR.STAGES.SELF_SERVICE.ENABLE_OPTION : strings.CONSTRUCTOR.STAGES.SELF_SERVICE.DISABLE_OPTION,
            }
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    })
    const prepareCellStyle = (item?: any) => {
        const currentColor = item.id === selectedKey ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        return mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, {
                    inset: -1,
                    outlineColor: theme.palette.themePrimary,
                    borderColor: theme.palette.themePrimary,
                }),
                {
                    padding: 0,
                    minHeight: elementHeight,
                    background: item.id === selectedKey && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': {background: currentColor}
                    },
                },
            ]
        })
    }

    const onRenderCell = useCallback((item?: {
        isSelected: boolean,
        data: ISelfServiceConfigAnswer,
        type?: string | undefined
        isBold?: boolean | undefined
    }, index?: number) => {
        const itemId = item?.data.id ?? ""
        return (
            <div key={`${item?.data.id}-${index}` ?? ""}
                 onClick={() => setSelectedKey(itemId)}
                 className={prepareCellStyle(item?.data).itemCell}>
                <Stack horizontal
                       grow
                       verticalAlign="center"
                       className={item?.data.id === selectedKey ? styles.activeListItem : styles.listItem}
                       style={{paddingLeft}}
                       tokens={{childrenGap: 8}}>
                    <Stack.Item grow>
                        <Text block variant="medium"
                              style={{
                                  color: SharedColors.gray40,
                                  textDecoration: item?.data.disable ? 'line-through' : undefined,
                                  fontWeight: item?.isBold ? 600 : undefined,
                              }}>{item?.data.text}</Text>
                    </Stack.Item>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{width: 14}}>
                        {item?.type && <Icon style={{fontWeight: 600, color: SharedColors.gray40}}
                                             iconName={item.type}/>}
                    </Stack>
                    {allowControls && <Stack.Item>
                        {item && <IconButton menuIconProps={{iconName: "MoreVertical", className: 'menu-icon'}}
                                             style={{height: 16, width: 16}}
                                             styles={menuIconStyles}
                                             menuProps={
                                                 getItemMenuProps(
                                                     item.data,
                                                     (item) => {
                                                         onChangeDispatch(actionCreators.selfService.toggleSelfServiceConfigItem(item.id))
                                                     },
                                                     (item) => {
                                                         if (onEdit) {
                                                             onEdit(item)
                                                         }
                                                     })
                                             }
                        />}
                    </Stack.Item>}
                </Stack>
            </div>
        )
    }, [selectedKey, onEdit])

    return (<>
        {title && (
            <Stack horizontal grow verticalAlign='center' style={{padding: itemPadding, paddingLeft}}
                   className={styles.cellBgGrey}>
                <Text variant="medium" style={{fontWeight: 600}}>{title}</Text>
            </Stack>
        )}
        <List
            items={items}
            onRenderCell={onRenderCell}
            getKey={(item) => item?.data.id}
        />
    </>)
}

export default AnswersItemsList;
