import React from "react";
import { Control, Controller } from "react-hook-form"
import PhoneSearch from "./PhoneCountryCodeSearch"
import { IDataCollectionStandardConfig } from "@piceasoft/core";
import type { TContractData } from "../../../../../dataCollectionTypes"


type TProps = {
    config?: IDataCollectionStandardConfig
    errorMessage: string
    organizationCountry?: string
    control: Control<TContractData, object>,
}

const PhoneCountryController: React.FunctionComponent<TProps> = (props) => {
    const {
        config,
        control,
        errorMessage,
    } = props

    return (
        <Controller
            name="phoneCountry"
            control={control}
            render={({ field }) =>
                <PhoneSearch 
                    config={config}
                    searchRequest={field.value}
                    errorMessage={errorMessage}
                    setSearchRequest={(value: string) => {
                        field.onChange(value)
                    }}
                />
            }
        />
    )
}

export default PhoneCountryController