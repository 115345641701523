import { Layer, Stack } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { INotificationMessage } from "@piceasoft/core"
import { AlertMessage } from "./AllertMessage"

export const Notifications: React.FC = () => {

    const [notifications, setNotifications] = useState<Array<{ uid: string, content: INotificationMessage }>>([])
    const [incomingNotification, setIncomingNotification] = useState<{ uid: string, content: INotificationMessage }>()

    const addNotification = (message: { uid: string, content: INotificationMessage }) => {
        let result = [...notifications]
        result.push(message)
        setNotifications(result)
    }

    const removeNotification = (uid: string) => {
        setNotifications([...(notifications?.filter(n => n.uid !== uid) as Array<{ uid: string, content: INotificationMessage }>)])
    }

    useEffect(() => {
        if (incomingNotification) {
            addNotification(incomingNotification)
        }
        setIncomingNotification(undefined)
    }, [incomingNotification])

    useEffect(() => {
        const removeEvent = window.NOTIFICATIONS_HANDLERS.onNotification((message: INotificationMessage) => {
            setIncomingNotification({ uid: (new Date().getTime() / 1000 * Math.random()).toString(), content: message })
        });
        return removeEvent;
    }, [])

    return (
        notifications && (
            <Layer>
                {notifications &&
                    <Stack style={{ position: 'fixed', bottom: 28, right: 0 }} tokens={{ padding: 16, childrenGap: 16 }}>
                        {notifications.map(n => <AlertMessage onClose={removeNotification} text={n.content.message} uid={n.uid} type={n.content.type} title={n.content.title} />)}
                    </Stack>}
            </Layer>
        )
    )
}
