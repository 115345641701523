import {
    DetailsList, Text, DetailsRow, getTheme, IDetailsListProps,
    IDetailsRowStyles, Stack, SelectionMode, DetailsListLayoutMode,
    IconButton, ScrollablePane, PrimaryButton, Modal,
    FontWeights, mergeStyleSets, IIconProps
} from "@fluentui/react"
import { useBoolean, useId } from "@uifabric/react-hooks"
import { Dispatch, useState } from "react"
import { actionCreators } from "../../../../../../../../core/actions/configurator-actions"
import { strings } from "../../../../../../../../localization/strings"
import { CustomPanel } from "../../../../../../panel/CustomPanel"
import { FieldSettingsForm } from "./FieldSettingsForm"
import { getAddFieldMenuItems, getColumns, getFields } from "./helpers/standardConfiguratorHelper"
import { SecondaryButton } from "../../../../../../buttons/SecondaryButton"
import { PreviewForm } from "./preview/PreviewForm"
import type { FieldSettingsData, FieldType } from "../../dataCollectionTypes"
import { IContractFieldWithMask, IDataCollectionConfig, IContractField, ProcessStages, IContractCustomField } from "@piceasoft/core"

export type TStandardModeConfiguratorModePivotProps = {
    index: number
    stage: ProcessStages
    dataCollection: IDataCollectionConfig
    onChangeDispatch: Dispatch<any>
}

export const StandardModeConfiguratorModePivot: React.FC<TStandardModeConfiguratorModePivotProps> = (props) => {
    let { dataCollection, index, onChangeDispatch, stage } = props
    let [selectedItem, setItem] = useState<FieldSettingsData>()
    const [isFieldSettingsPanelOpen, { setTrue: showFieldSettingsPanel, setFalse: hideFieldSettingsPanel }] = useBoolean(false)
    const [isPreviewPanelOpen, { setTrue: showPreviewPanel, setFalse: hidePreviewPanel }] = useBoolean(false)

    let theme = getTheme()
    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                    '.up-icon': {
                        fontSize: 16,
                        color: theme.palette.black
                    }
                }
            };
            return (
                <Stack onClick={() => {
                    setItem(rProps.item)
                    showFieldSettingsPanel()
                }}>
                    <DetailsRow {...rProps} styles={customStyles} />
                </Stack>
            )
        }
        return null;
    }

    let setField = (fieldName: string, field: IContractField | IContractFieldWithMask | undefined) => {
        onChangeDispatch(actionCreators.inspections.dataCollection.editModeConfig(
            stage,
            index,
            {
                fields: {
                    ...dataCollection.config.fields,
                    [fieldName]: field !== undefined ? field : {}
                }
            }
        ))
    }

    let deleteField = (fieldName: string) => {
        onChangeDispatch(actionCreators.inspections.dataCollection.editModeConfig(
            stage,
            index,
            {
                fields: {
                    ...dataCollection.config.fields,
                    [fieldName]: undefined
                }
            }
        ))
    }

    let deleteCustomField = (fieldId: string) => {
        let customFields = dataCollection.config.fields?.custom ?? []
        let existingField = customFields.find(cf => cf.key === fieldId)
        if (existingField !== undefined) {
            let fieldIdx = customFields.indexOf(existingField)
            customFields.splice(fieldIdx, 1)
        }

        onChangeDispatch(actionCreators.inspections.dataCollection.editModeConfig(
            stage,
            index,
            {
                fields: {
                    ...dataCollection.config.fields,
                    custom: [...customFields]
                }
            }
        ))
    }

    let setPassportField = (fieldName: string, field: IContractField | IContractFieldWithMask | undefined) => {
        onChangeDispatch(actionCreators.inspections.dataCollection.editModeConfig(
            stage,
            index,
            {
                fields: {
                    ...dataCollection.config.fields,
                    passport: {
                        ...dataCollection.config.fields?.passport,
                        [fieldName]: field !== undefined ? { ...field } : {}
                    }
                }
            }
        ))
    }

    let setCustomField = (field: IContractCustomField | undefined, isNew: boolean) => {
        let customFields = dataCollection.config.fields?.custom ?? []
        if (field === undefined) {
            return
        }

        if (isNew) {
            customFields.push(field ?? {})
        } else {
            let existingField = customFields.find(cf => cf.key === field?.key)
            if (existingField !== undefined) {
                let fieldIdx = customFields.indexOf(existingField)
                customFields.splice(fieldIdx, 1, field)
            }
        }

        onChangeDispatch(actionCreators.inspections.dataCollection.editModeConfig(
            stage,
            index,
            {
                fields: {
                    ...dataCollection.config.fields,
                    custom: [...customFields]
                }
            }
        ))
    }

    let createCustomField = () => {
        setItem({
            id: '',
            title: '',
            type: 'custom',
            isNewCustomField: true,
        })
        showFieldSettingsPanel()
    }

    let deletePassportField = (fieldId: string) => {
        let fieldName = fieldId.split('.')[1]
        onChangeDispatch(actionCreators.inspections.dataCollection.editModeConfig(
            stage,
            index,
            {
                fields: {
                    ...dataCollection.config.fields,
                    passport: {
                        ...dataCollection.config.fields?.passport,
                        [fieldName]: undefined
                    }
                }
            }
        ))
    }

    let onConfirm = (fieldData: FieldSettingsData) => {
        if (fieldData.type === 'custom') {
            const newCustomField = createField(fieldData) as IContractCustomField
            setCustomField(newCustomField, fieldData.isNewCustomField ?? false)
            hideFieldSettingsPanel()
            return
        }

        if (fieldData.type === 'standard' || fieldData.type === 'common' || fieldData.type === 'masked') {
            const newField = createField(fieldData)
            if (fieldData.id.includes('passport')) {
                let fieldId = fieldData.id.split('.')[1]
                setPassportField(fieldId, newField)
            } else {
                setField(fieldData.id, newField)
            }
        }

        hideFieldSettingsPanel()
    }

    let createField = (fieldData: FieldSettingsData) => {
        switch (fieldData.type) {
            case 'common':
                return {
                    label: fieldData.label,
                    placeholder: fieldData.placeholder,
                }
            case 'standard':
                return {
                    label: fieldData.label,
                    placeholder: fieldData.placeholder,
                    minLength: fieldData.minLength,
                    maxLength: fieldData.maxLength,
                }
            case 'masked': {
                return {
                    mask: fieldData.mask,
                    label: fieldData.label,
                    placeholder: fieldData.placeholder,
                    minLength: fieldData.minLength,
                    maxLength: fieldData.maxLength,
                }
            }
            case 'custom': {
                return {
                    key: fieldData.id,
                    label: fieldData.label ?? '',
                    placeholder: fieldData.placeholder,
                    minLength: fieldData.minLength,
                    maxLength: fieldData.maxLength,
                    required: fieldData.required,
                }
            }
            default:
                console.error(`${fieldData.type} does not exist`)
        }

    }

    let onDelete = (fieldId: string, fieldType: FieldType) => {
        if (fieldType === 'custom') {
            deleteCustomField(fieldId)
        }

        if (fieldType === 'standard' || fieldType === 'common' || fieldType === 'masked') {
            if (fieldId.includes('passport')) {
                deletePassportField(fieldId)
            } else {
                deleteField(fieldId)
            }
        }

        hideFieldSettingsPanel()
    }

    let onEdit = (field: FieldSettingsData) => {
        setItem(field)
        showFieldSettingsPanel()
    }

    let addFieldMenuProps = getAddFieldMenuItems(
        dataCollection.config,
        createCustomField,
        setField,
        setPassportField,
    )

    const titleId = useId('preview_modal')
    const fields = getFields(dataCollection.config)
    const cancelIcon: IIconProps = { iconName: 'Cancel' }
    let title = dataCollection.ui?.title

    return (
        <Stack verticalFill grow>
            <Stack tokens={{ childrenGap: 4, padding: "20px 20px 0px 20px" }} horizontal>
                <Stack.Item>
                    <PrimaryButton menuProps={addFieldMenuProps}>{strings.BUTTONS.TEXT.ADD}</PrimaryButton>
                </Stack.Item>
                <Stack.Item>
                    <SecondaryButton text={strings.CONSTRUCTOR.COMMON.PREVIEW} onClick={() => showPreviewPanel()} />
                </Stack.Item>
            </Stack>
            <Stack.Item verticalFill style={{ position: 'relative' }}>
                <ScrollablePane>
                    <DetailsList
                        styles={{ root: { width: '100%' } }}
                        items={fields}
                        columns={getColumns(onDelete, onEdit)}
                        setKey="none"
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onRenderRow={onRenderRow}
                    />
                </ScrollablePane>
            </Stack.Item>
            <Stack.Item>
                <CustomPanel
                    isOpen={isFieldSettingsPanelOpen}
                    onCancel={hideFieldSettingsPanel}
                    noCancelOnDissmiss={true}
                    title={`${strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.FORM_TITLE} ${selectedItem?.title}`}
                >
                    <FieldSettingsForm
                        onConfirm={onConfirm}
                        fieldIds={fields.map(f => f.id)}
                        field={selectedItem}
                    />
                </CustomPanel>
            </Stack.Item>
            <Stack.Item style={{ width: "600px" }}>
                <Modal isOpen={isPreviewPanelOpen} onDismiss={hidePreviewPanel} isBlocking={true} containerClassName={contentStyles.container}>
                    <Stack horizontal grow tokens={{ padding: '12px 12px 14px 24px', childrenGap: 24 }}
                        style={{
                            borderTop: `4px solid ${theme.palette.themePrimary}`,
                            color: theme.palette.neutralPrimary,
                        }}
                    >
                        <Stack.Item grow>
                            <Text variant='xLargePlus' style={{ fontWeight: FontWeights.semibold }} id={titleId}>{title ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.TITLE}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Закрыть" onClick={() => { hidePreviewPanel(); }} />
                        </Stack.Item>
                    </Stack>
                    <div className={contentStyles.body}>
                        <PreviewForm
                            dataCollection={dataCollection}
                            hidePreviewModal={hidePreviewPanel}
                        />
                    </div>
                </Modal>
            </Stack.Item>
        </Stack>
    )
}

let theme = getTheme()

const contentStyles = mergeStyleSets({
    container: {
        width: 640,
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
})

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};