import { IModerationResult, IModerationResultLog, ITransactionTechLog } from "../store";
import { IRequestResult } from "../store/typings/IResponseResult";
import { IStickerData, ISkipInspectionMessage } from "@piceasoft/core";
import { ITransaction } from "../store/typings/ITransaction";
import { endpoints } from "./endpoints";

export const overviewApi = {
    transactions: {
        getTransaction: async (uid: string): Promise<IRequestResult<ITransaction>> => {
            let result: IRequestResult<ITransaction> = { successed: false };
            await fetch(endpoints.v1.overview.transaction(uid))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ITransaction>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        skipInspectionModule: async (message: ISkipInspectionMessage): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.overview.skipInspectionModule, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(message)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getTransactionTechLog: async (transactionId: string): Promise<IRequestResult<ITransactionTechLog[]>> => {
            let result: IRequestResult<ITransactionTechLog[]> = { successed: false };
            await fetch(endpoints.v1.overview.transactionTechLog(transactionId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ITransactionTechLog[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        async postSticker(data: IStickerData): Promise<IRequestResult<undefined>> {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.postSticker, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { "Content-Type": "application/json" }
            })
                .then(async response => {
                    if (response.status === 200) {
                        result = { successed: true }
                    }
                    else {
                        result = { successed: false, errors: [{ code: response.status.toString(), description: response.statusText }] }
                    }
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    moderation: {
        start: async (transactionId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.overview.moderation.start, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    uid: transactionId
                })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        request: async (transactionId: string, message?: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.overview.moderation.request, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    uid: transactionId,
                    message: message
                })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        result: async (transactionId: string, message?: string, grade?: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.overview.moderation.result, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    uid: transactionId,
                    message: message,
                    grade: grade
                })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        notify: async (transactionId: string) => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.overview.moderation.notifyModerationState, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    uid: transactionId
                })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        notifyExternal: async (transactionId: string, moderatorHash: string) => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.overview.moderation.notifyModerationStateExternal(moderatorHash), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    uid: transactionId
                })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    getModerations: async (): Promise<IRequestResult<IModerationResultLog[]>> => {
        let result: IRequestResult<IModerationResultLog[]> = { successed: false };
        await fetch(endpoints.v1.moderations)
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IModerationResultLog[]>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    getModeration: async (transactionId: string): Promise<IRequestResult<IModerationResult>> => {
        let result: IRequestResult<IModerationResult> = { successed: false };
        await fetch(endpoints.v1.moderation(transactionId))
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IModerationResult>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    getExternalModeration: async (transactionId: string, moderatorHash: string): Promise<IRequestResult<IModerationResult>> => {
        let result: IRequestResult<IModerationResult> = { successed: false };
        await fetch(endpoints.v1.externalModeration(transactionId, moderatorHash))
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IModerationResult>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    setExternalModeration: async (transactionId: string, moderatorHash: string, message?: string, grade?: string): Promise<IRequestResult<undefined>> => {
        let result: IRequestResult<undefined> = { successed: false };
        await fetch(endpoints.v1.externalModeration(transactionId, moderatorHash), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                uid: transactionId,
                message: message,
                grade: grade
            })
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<undefined>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    setExternalModerationRequest: async (transactionId: string, moderatorHash: string, message?: string): Promise<IRequestResult<undefined>> => {
        let result: IRequestResult<undefined> = { successed: false };
        await fetch(endpoints.v1.externalModerationRequest(transactionId, moderatorHash), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                uid: transactionId,
                message: message
            })
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<undefined>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
}