import {
    Stack, getTheme, Separator, Text, PrimaryButton, SharedColors,
    FontIcon, DirectionalHint, IconButton, TooltipDelay,
    TooltipHost, ICalloutContentStyles
} from "@fluentui/react"
import { useId } from "@uifabric/react-hooks";
import React from "react";
import { strings } from "../../../../../localization/strings";
import Device from "./Device";
import { IStageConfig, ProcessStages, IStageConfigUI } from "@piceasoft/core";

type TProps = {
    config: IStageConfig<IStageConfigUI>
    stage: ProcessStages
    defaultNextText: string
    defaultCancelText: string
    defaultStageTitle?: string
}

export const StagePreview: React.FC<TProps> = ({ config, ...props }) => {

    const tooltipId = useId('tooltip');

    return (
        <Stack style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} >
            <Stack.Item tokens={{ padding: 24 }}>
                <Stack style={{ backgroundColor: theme.palette.neutralLighter, boxShadow: theme.effects.elevation64 }} >
                    <Stack.Item>
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }} styles={{ root: { padding: "4px 16px 4px 24px", backgroundColor: theme.palette.white } }}>
                            <Stack.Item grow>
                                <Stack horizontal verticalAlign="center" styles={{ root: { height: 56 } }}>
                                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                        <FontIcon iconName="Sprint" style={{ fontSize: 24, height: 24, width: 24 }} />
                                        <Separator vertical />
                                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                            {(config?.ui?.stageTitle || props.defaultStageTitle) && (
                                                <Text variant="mediumPlus" style={{ color: SharedColors.gray30 }}>{config?.ui?.stageTitle ?? props.defaultStageTitle}</Text>
                                            )}
                                            { ![ProcessStages.Identification, ProcessStages.PreOffer].includes(props.stage) &&
                                                <TooltipHost
                                                    calloutProps={{ styles: tooltipCalloutStyles }}
                                                    content={strings.BUTTONS.TEXT.COPY}
                                                    delay={TooltipDelay.medium}
                                                    id={tooltipId}
                                                    directionalHint={DirectionalHint.bottomCenter}
                                                >
                                                    <IconButton iconProps={{ iconName: 'Copy' }} ariaLabel="Copy" />
                                                </TooltipHost>}
                                        </Stack>
                                        {![ProcessStages.Identification, ProcessStages.PreOffer].includes(props.stage) && (
                                            <>
                                                <Separator vertical />
                                                <Device />
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <PrimaryButton>{config.ui?.buttons?.next?.text ?? props.defaultNextText}</PrimaryButton>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal verticalFill verticalAlign='center' tokens={{ childrenGap: 16 }}>
                                    <Stack.Item>
                                        <PrimaryButton>{config.ui?.buttons?.cancel?.text ?? props.defaultCancelText}</PrimaryButton>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                        {props.children}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const tooltipCalloutStyles: Partial<ICalloutContentStyles> = {
    beakCurtain: { background: theme.palette.neutralLight },
    beak: { background: theme.palette.neutralLight },
    calloutMain: { background: theme.palette.neutralLight },
    root: { color: theme.palette.white },
    container: { color: 'white' }
}