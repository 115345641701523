import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { ICommonOfferConfig } from "@piceasoft/core";
import { strings } from "../../../../../../localization/strings";
import { ParameterItem } from "../../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { ProcessStages } from "@piceasoft/core";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: ICommonOfferConfig
    channel: number
    stage: ProcessStages
}

export const CommonOfferStageAdditionalSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, channel, stage }) => {
    if (!config) return null

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.TRADE_IN_DEVICE_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Common offer settings', 'Custom trade-in device title')}
                toggleChecked={config.ui?.tradeInDevice !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        ui: {
                            ...config.ui,
                            tradeInDevice: config.ui?.tradeInDevice !== undefined ? undefined : ""
                        }
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.TRADE_IN_DEVICE_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.tradeInDevice !== undefined ? true : false}
                textFieldValue={config.ui?.tradeInDevice}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                    ...config,
                    ui: {
                        ...config.ui,
                        tradeInDevice: val
                    }
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.ADDITIONAL_PIVOT.WITHOUT_PRICES_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Common offer settings', 'Do not stop workflow if no offer found')}
                toggleChecked={config.withoutPrices}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.commonOffer.onConfigCommit({
                        ...config,
                        withoutPrices: !config.withoutPrices ? true : undefined
                    }))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.ADDITIONAL_PIVOT.WITHOUT_PRICES_DESCRIPTION}
            />
        </Stack>
    )
}