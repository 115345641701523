import { Stack, TextField, PrimaryButton, Spinner, FontIcon } from "@fluentui/react"
import React from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { checkIsManagerUser } from "../../../core/store/typings/UserRoles"
import { strings } from "../../../localization/strings"
import { createPassword } from "../../helpers/passwordGenerator"
import { SecondaryButton } from "../buttons/SecondaryButton"
import { validatePassword } from "../configurator/stages/assessment/dataCollection/modeConfigurator/pivots/helpers/validationRules"
import { PasswordPolicy } from "../PasswordPolicy"

type TProps = {
    fetched: boolean
    onSubmit: (data: string) => void
    onCancel: () => void
    roles?: string[]
}
type TFormValues = {
    newPassword: string
    repeatNewPassword: string
}
export const ResetPasswordForm: React.FC<TProps> = (props) => {
    const isManager = checkIsManagerUser(props.roles);
    const [randomPassword, setRandomPassword] = React.useState<string>();

    React.useEffect(() => {
        setRandomPassword(createPassword(!isManager ? 8 : 15))
    }, [])

    const { control, handleSubmit, watch, reset, trigger, formState: { errors, isValid } } = useForm<TFormValues>({
        mode: "all",
        defaultValues: {
            newPassword: randomPassword,
            repeatNewPassword: randomPassword
        }
    })

    React.useEffect(() => {
        if (randomPassword) {
            reset({ newPassword: randomPassword, repeatNewPassword: randomPassword })
        }
    }, [randomPassword])

    const onSubmit: SubmitHandler<TFormValues> = data => {
        if (data.newPassword === data.repeatNewPassword) {
            props.onSubmit(data.newPassword)
        }
    }

    return (
        <Stack>
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
                <PasswordPolicy isManager={isManager} />
                <Stack tokens={{ childrenGap: 12 }}>
                    <Controller
                        control={control}
                        name="newPassword"
                        rules={validatePassword(undefined, isManager)}
                        render={({ field }) =>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Stack.Item grow>
                                    <TextField
                                        styles={{
                                            fieldGroup: {
                                                borderColor: field.value && !errors.newPassword?.message ? "#5DCA42" : undefined, ':after': {
                                                    borderColor: field.value && !errors.newPassword?.message ? "#5DCA42" : undefined
                                                }
                                            }
                                        }}
                                        label={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.NEW_PASSWORD_LABEL}
                                        // placeholder={strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.NEW_PASSWORD_PLACEHOLDER}
                                        value={field.value}
                                        onChange={(ev, val) => { field.onChange(val); trigger("repeatNewPassword") }}
                                        required
                                        type="password"
                                        canRevealPassword
                                        errorMessage={errors.newPassword?.message} />
                                </Stack.Item>
                                <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>
                                    <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().newPassword && !errors.newPassword) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                </Stack.Item>
                            </Stack>
                        } />
                    <Controller
                        control={control}
                        name="repeatNewPassword"
                        rules={{
                            // required: strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED,
                            validate: (value) => {
                                return (watch().newPassword === value) ? true : strings.ORGANIZATION.STRUCTURE.ITEM.PERSON.PASSWORD_MATCH_REQUIRED
                            }
                        }}
                        render={({ field }) =>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                                <Stack.Item grow>
                                    <TextField
                                        styles={{
                                            fieldGroup: {
                                                borderColor: field.value && !errors.repeatNewPassword?.message ? "#5DCA42" : undefined, ':after': {
                                                    borderColor: field.value && !errors.newPassword?.message ? "#5DCA42" : undefined
                                                }
                                            }
                                        }}
                                        value={field.value}
                                        type="password"
                                        required
                                        canRevealPassword
                                        onChange={field.onChange}
                                        label={strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.REPEAT_NEW_PASSWORD}
                                        // placeholder={strings.SETTINGS.USER_SETTINGS.PASSWORD_SETTINGS.REPEAT_NEW_PASSWORD_PLACEHOLDER}
                                        errorMessage={errors.repeatNewPassword?.message}
                                    />
                                </Stack.Item>
                                <Stack.Item verticalFill align="end" tokens={{ padding: "0 0 6px 0" }}>
                                    <FontIcon iconName={"CompletedSolid"} style={{ color: (watch().repeatNewPassword && !errors.repeatNewPassword) ? "#5DCA42" : 'transparent', fontSize: 14 }} />
                                </Stack.Item>
                            </Stack>
                        }
                    />
                    <Stack.Item>
                        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 12 }}>
                            {props.fetched && <Spinner />}
                            <SecondaryButton text={strings.BUTTONS.TEXT.CANCEL} onClick={props.onCancel} />
                            <PrimaryButton disabled={!isValid} text={strings.BUTTONS.TEXT.SAVE} onClick={handleSubmit(onSubmit)} />
                        </Stack>
                    </Stack.Item>
                </Stack>
            </form>
        </Stack>
    )
}