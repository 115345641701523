import {
    Modal, IconButton, Stack,
    MessageBar, MessageBarType, ProgressIndicator,
    Icon, Text, SharedColors, Spinner, SpinnerSize,
    FontIcon, Image, ImageFit, Link, DatePicker,
    TextField, IComboBoxOption, ComboBox, MessageBarButton, Separator, PrimaryButton
} from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { getTheme, mergeStyleSets, FontWeights } from "@fluentui/style-utilities"
import { useId } from "@uifabric/react-hooks"
import React from "react"
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton"
import { IResponseError } from "@piceasoft/core"
import { IRequestResult } from "../../../core/store/typings/IResponseResult"
import { getCatalogProtocolStringByCode } from "../../../localization/helpers/protocols"
import { strings } from "../../../localization/strings"
import { CSHHelpLink } from "../../../components/shared/help/CSHHelp"

type TProps = {
    show: boolean
    onHide: () => void
    src?: string
    iconColor?: string
    type: "icon" | "image"
    acceptTypeMask?: string
    isValid: boolean
    title: string
    onSuccessed: () => void
    sendMethod: (file: File, setResponse: (response: IRequestResult<undefined>) => void, setUploadProgress: (value: number) => void) => Promise<void>
    fromDate?: Date
    fromTimeHour: number,
    fromTimeMin: number,
    summary?: string
    onChangeSummary: (summary?: string) => void
    handleDateSelect: (date: Date) => void
    handleTimeHourSelect: (hour: number) => void
    handleTimeMinSelect: (min: number) => void
}

export const ImportModal: React.FC<TProps> = (props) => {

    const [currentTime, setCurrentTime] = React.useState(new Date())


    const prepareHourOptions = (): IComboBoxOption[] => {
        return [...Array(24).keys()].map(i => {
            return {
                key: i,
                text: i < 10 ? "0" + i.toString() : i.toString(),
                disabled: (props.fromDate && props.fromDate <= currentTime) && currentTime.getHours() > i
            }
        })
    }
    const prepareMinOptions = (): IComboBoxOption[] => {
        return [...Array(60).keys()].map(i => {
            return {
                key: i,
                text: i < 10 ? "0" + i.toString() : i.toString(),
                disabled: (props.fromDate && props.fromDate <= currentTime && currentTime.getHours() === props.fromTimeHour) && currentTime.getMinutes() > i - 1
            }
        })
    }

    const [uploadProgress, setUploadProgress] = React.useState(0)
    const [file, setFile] = React.useState<File>()
    const [imageSrc, setImageSrc] = React.useState<string>()
    const [isFetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const [isDateTimePickerShowed, { setTrue: showDateTimePicker, setFalse: hideDateTimePicker }] = useBoolean(false)
    const [response, setResponse] = React.useState<IRequestResult<undefined>>()
    const [errors, setErrors] = React.useState<IResponseError[]>([])
    const inputFileButtonId = useId('input-file-button-id')
    const [hourOptions, setHourOptions] = React.useState(prepareHourOptions())
    const [minOptions, setMinOptions] = React.useState(prepareMinOptions())

    React.useEffect(() => {
        if (response) {
            if (response.successed) {
                props.onHide()
                props.onSuccessed();
            }
            if (response.errors) {
                setErrors(response.errors)
            }
            stopFetch()
        }
    }, [response])

    React.useEffect(() => {
        setCurrentTime(new Date())
        setHourOptions(prepareHourOptions())
        setMinOptions(prepareMinOptions())
    }, [props.fromDate, props.fromTimeHour, props.fromTimeMin])

    const addFile = (upFiles: FileList) => {
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => upFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")]
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            if (props.type === "image") {
                setImageSrc(URL.createObjectURL(checkedUpFiles[0]))
            }
            setFile(checkedUpFiles[0])
        }
    }

   const downloadCatalogSample = () => {
        fetch('samples/Trade-in_catalog_sample.xlsx')
            .then(resp => resp.blob())
            .then(blob => {
                let URL = window.URL || window.webkitURL,
                    downloadUrl = URL.createObjectURL(blob),
                    a = document.createElement('a');
                a.href = downloadUrl;
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                a.parentNode?.removeChild(a);
                setTimeout(() => { URL.revokeObjectURL(downloadUrl) }, 100);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const resetImageSrc = () => {
        if (imageSrc) {
            URL.revokeObjectURL(imageSrc)
            setImageSrc(undefined)
            setFile(undefined)
        }
    }

    const confirmHandler = async () => {
        if (file) {
            setErrors([])
            startFetch()
            props.sendMethod(file, setResponse, setUploadProgress)
        }
    }

    return (
        <Modal isOpen={props.show ? true : false} onDismiss={props.onHide} containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span style={{ paddingRight: 32 }}>{props.title}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close"
                    onClick={props.onHide}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack tokens={{ childrenGap: 16 }}>
                    <Stack tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <Stack grow tokens={{ padding: '8px 0px 8px 0px', childrenGap: 24 }} horizontal verticalAlign='center'>
                                <Stack horizontalAlign='center' verticalAlign='center' tokens={{ childrenGap: 8 }} style={{ minHeight: 100 }}>
                                    {props.type === "icon" && <Icon iconName={props.src} style={{ fontSize: 56, color: props.iconColor }} />}
                                    {props.type === "image" && <Image src={imageSrc ?? props.src} style={{ color: props.iconColor }} width={72} height={72} imageFit={ImageFit.cover} />}
                                    {imageSrc && <Link onClick={resetImageSrc}>{strings.BUTTONS.TEXT.RESET}</Link>}
                                </Stack>
                                <Stack.Item>
                                    <Stack tokens={{ childrenGap: 8 }}>
                                        <Stack.Item>
                                            <Text>{strings.ORGANIZATION.CATALOGS.IMPORT_FROM_EXCEL.DESCRIPTION}</Text>
                                        </Stack.Item>
                                        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 16 }}>
                                            <SecondaryButton disabled={isFetching} onClick={() => {
                                                const el = document.getElementById(inputFileButtonId)
                                                if (el) {
                                                    (el as HTMLInputElement).value = ''
                                                    el.click()
                                                }
                                            }}>{strings.BUTTONS.TEXT.CHOOSE_FILE}</SecondaryButton>
                                            {file && (
                                                <Text style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{file?.name}</Text>
                                            )}
                                            <input accept={props.acceptTypeMask} id={inputFileButtonId} hidden type="file" onChange={(ev) => ev.target.files && addFile(ev.target.files)} />
                                        </Stack>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
                            <Stack.Item>
                                <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20 }} title="Info" ariaLabel="Info" styles={iconButtonStyles} />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Text style={{ fontWeight: 500, color: SharedColors.gray20 }}>{strings.ORGANIZATION.CATALOGS.IMPORT_FROM_EXCEL.LABLE_TEXT_1}</Text> <Link href="/docs/default.htm#cshid=1112" target="_blank" >{strings.ORGANIZATION.CATALOGS.IMPORT_FROM_EXCEL.LABLE_LINK_TEXT}</Link>.
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack tokens={{ childrenGap: 16 }}>
                        {!isDateTimePickerShowed && (
                            <Text>{strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.VALID_FROM} <Link onClick={showDateTimePicker}>
                                {strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.LOAD_MOMENT}
                            </Link>.</Text>
                        )}
                        {isDateTimePickerShowed && (
                            <Stack horizontal verticalAlign='end' horizontalAlign='space-between'>
                                <DatePicker disabled={isFetching} style={{ width: 220 }} value={props.fromDate ? new Date(props.fromDate) : undefined} allowTextInput
                                    formatDate={(date?: Date) => formatDate(date)} parseDateFromString={parseDateFromString}
                                    label={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.VALID_FROM}
                                    placeholder={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.FROM_DATE_PLACEHOLDER}
                                    minDate={new Date()}
                                    onSelectDate={(date) => date && props.handleDateSelect(date)}
                                    strings={strings.DAY_PICKER}
                                />
                                <ComboBox disabled={isFetching} label={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.HOURS}
                                    style={{ width: 90, paddingBottom: 5 }} options={hourOptions}
                                    selectedKey={props.fromTimeHour}
                                    autoComplete="on"
                                    dropdownWidth={52}
                                    onChange={(ev, opt, val) => opt && props.handleTimeHourSelect(opt.key as number)}
                                />
                                <ComboBox disabled={isFetching} label={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.MINUTES}
                                    style={{ width: 90, paddingBottom: 5 }} options={minOptions}
                                    selectedKey={props.fromTimeMin}
                                    autoComplete="on"
                                    dropdownWidth={52}
                                    onChange={(ev, opt, val) => opt && props.handleTimeMinSelect(opt.key as number)}
                                />
                            </Stack>
                        )}
                        <TextField disabled={isFetching} value={props.summary}
                            onChange={(e, value) => { props.onChangeSummary(value) }}
                            label={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.SUMMARY}
                            placeholder={strings.ORGANIZATION.CATALOGS.VERSIONS.VERSION.IMPORT.SUMMARY_PLACEHOLDER}
                        />
                        {errors && errors.map((e, i) => <MessageBar key={e.description + i} isMultiline={false} messageBarType={MessageBarType.error}
                            actions={e.code === "fail" && (
                                <div>
                                    <MessageBarButton href={e.description}>{strings.BUTTONS.TEXT.GET_PROTOCOL}</MessageBarButton>
                                </div>
                            ) || undefined
                            }>{e.code === "fail" ? strings.ORGANIZATION.CATALOGS.IMPORT_FROM_EXCEL.FAIL : (e.code ? getCatalogProtocolStringByCode(e.code) : e.description)}</MessageBar>)}
                        {isFetching && (
                            <ProgressIndicator description={strings.SPINNERS.WAITING_PROCESSING_DESCRIPTION} label={strings.SPINNERS.WAITING_PROCESSING_LABEL} percentComplete={uploadProgress} />
                        )}
                        <Stack horizontal horizontalAlign={"end"} tokens={{ childrenGap: 8 }}>
                            {isFetching && (
                                <Stack verticalAlign="center" horizontalAlign="center">
                                    <Spinner size={SpinnerSize.small} />
                                </Stack>
                            )}
                            <CSHHelpLink articleid='1112' alignment='left' />
                            <PrimaryButton onClick={confirmHandler} disabled={!file || (isDateTimePickerShowed && !props.isValid) || isFetching}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                            {props.onHide !== undefined && <SecondaryButton onClick={props.onHide}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>}
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        </Modal>
    )
}

function formatDate(date?: Date): string {
    return date?.toLocaleDateString() ?? "";
}

function parseDateFromString(value: string): Date | null {
    let data = value.split(".");
    if (data.length === 3) {
        return new Date(Number(data[2]), Number(data[1]) - 1, Number(data[0]));
    } else {
        return null;
    }
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 540,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};