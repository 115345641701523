import * as React from 'react';
import Inspection from '../Inspection';
import { Stack } from '@fluentui/react';
import { noop } from '../../../../../../core/helpers/common';
import { strings } from '../../../../../../localization/strings';
import { DataCollectionState } from './preview/DataCollectionState';
import { Inspections, IInspectionModeConfig, IDataCollectionConfig, IInspectionModeConfigUI } from '@piceasoft/core';

export type uploadFileType = { imgUrl: string, loadProgress: number, data: File, label?: string }

type TProps = {
    config?: IDataCollectionConfig
}

export const DataCollectionStatePreview: React.FC<TProps> = (props) => {
    if (props.config === undefined) return null;

    return (
        <Stack grow tokens={{ padding: "32px 32px 32px 32px" }}>
            <Inspection inspection={Inspections.DataCollection}
                icon={props.config.ui?.icon ?? "PageEdit"}
                title={props.config.ui?.title ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.TITLE}
                allowRestart={props.config.allowRestart}
                onStart={noop}
                onRestart={noop}
                dependency={props.config.dependency}
                skips={props.config.skip}
                autoRun={props.config.autoRun}
                executeButtonText={props.config.ui?.executeButtonText}
                repeatButtonText={props.config.ui?.restartButtonText}
            >
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    <DataCollectionState config={props.config.config as IInspectionModeConfig<IInspectionModeConfigUI>}
                    icon={props.config.ui?.icon} />
                </Stack>
            </Inspection>
        </Stack>
    );
}