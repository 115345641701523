import { getTheme, SharedColors, Spinner, Stack, Text } from "@fluentui/react"
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../core/scripts/style";
import { ICompany } from "../../../core/store";
import { strings } from "../../../localization/strings";

export type TProps = {
    company?: ICompany
    onEdit: () => void
}

export const OrganizationCompanyHeader: React.FC<TProps> = ({ company, onEdit }) => {
    return (
        <Stack horizontal grow style={{ padding: "24px 32px 12px 32px" }}>
            {company && (
                <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                    <Stack.Item>
                        <Stack verticalAlign="center" horizontalAlign="center" style={{
                            width: 72, height: 72, borderRadius: 36,
                            backgroundColor: getPersonaColorsPallete[company.name[0].toLowerCase()]
                        }}>
                            <Text style={{ color: "#fff", fontSize: 28, fontWeight: 400, paddingBottom: 2 }}>
                                {getIconSymbolsFromString(company.name)}
                            </Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack>
                            <Stack.Item>
                                <Text style={{ fontSize: "1.3125rem", letterSpacing: "-.02em", fontWeight: 600, color: theme.palette.black }}>{company.name}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>
                                    {strings.ORGANIZATION.COMPANIES.COMPANY.ITN}: {company.itn} / {" "}
                                    {strings.ORGANIZATION.COMPANIES.COMPANY.IEC}: {company.iec} / {" "}
                                    {strings.ORGANIZATION.COMPANIES.COMPANY.PSRN}: {company.psrn}
                                </Text>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            ) || (
                    <Stack verticalFill verticalAlign='center'>
                        <Spinner label={strings.SPINNERS.DATA_IS_LOADING} />
                    </Stack>
                )}
            <Stack.Item>
                <SecondaryButton text={strings.BUTTONS.TEXT.EDIT_DATA} onClick={onEdit} />
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
