import { Stack } from "@fluentui/react"
import React from "react"
import { Dispatch } from "react"
import { actionCreators } from "../../../../../../../../core/actions/configurator-actions"
import { IAIGradingConfig, ProcessStages, IAIGradingDefaultConfigUI, IScannerComponentTextConfig } from "@piceasoft/core"
import { strings } from "../../../../../../../../localization/strings"
import { ParameterItem } from "../../../../../components/ParameterItem"

export type TModeConfiguratorCommonPivotProps = {
    index: number
    stage: ProcessStages
    aigrading: IAIGradingConfig
    onChangeDispatch: Dispatch<any>
}

export const DefaultModeComponentUIPivot: React.FC<TModeConfiguratorCommonPivotProps> = ({ aigrading, stage, index, onChangeDispatch }) => {

    const config = aigrading.config.ui?.componentText

    const onChangeConfig = (config: IScannerComponentTextConfig) => {
        onChangeDispatch(actionCreators.inspections.aigrading.editAIGrading(stage, index, {
            ...aigrading,
            config: {
                ...aigrading.config,
                ui: {
                    ...aigrading.config.ui,
                    componentText: config
                } as IAIGradingDefaultConfigUI
            }
        }))
    }

    return (
        <Stack>
            <>
                {/* <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.HEADER_TITLE}
                noSeparator
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.header !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                header: config?.grading?.header !== undefined ? undefined : ""
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.HEADER_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.header !== undefined ? true : false}
                    textFieldValue={config?.grading?.header}
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                header: val
                            }
                        }
                        )
                    }}
                />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.DESCRIPTION_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.description !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                description: config?.grading?.description !== undefined ? undefined : ""
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.DESCRIPTION_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.description !== undefined ? true : false}
                    textFieldValue={config?.grading?.description}
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                description: val
                            }
                        }
                        )
                    }}
                />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.INFORMATION_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.information !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                information: config?.grading?.information !== undefined ? undefined : ""
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.INFORMATION_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.information !== undefined ? true : false}
                    textFieldValue={config?.grading?.information}
                    textFieldMultiline
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                information: val
                            }
                        }
                        )
                    }}
                /> */}
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_1.TITLE} useToggle={false} />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_1.TITLE_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.step1?.title !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step1: {
                                    ...(config?.grading?.step1 ?? {}),
                                    title: config?.grading?.step1?.title !== undefined ? undefined : ""
                                }
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_1.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.step1?.title !== undefined ? true : false}
                    textFieldValue={config?.grading?.step1?.title}
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step1: {
                                    ...(config?.grading?.step1 ?? {}),
                                    title: val
                                }
                            }
                        }
                        )
                    }}
                />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_1.INFORMATION_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.step1?.information !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step1: {
                                    ...(config?.grading?.step1 ?? {}),
                                    information: config?.grading?.step1?.information !== undefined ? undefined : ""
                                }
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_1.INFORMATION_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.step1?.information !== undefined ? true : false}
                    textFieldValue={config?.grading?.step1?.information}
                    textFieldMultiline
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step1: {
                                    ...(config?.grading?.step1 ?? {}),
                                    information: val
                                }
                            }
                        }
                        )
                    }}
                />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_1.CAMERA_HINT_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.step1?.cameraHint !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step1: {
                                    ...(config?.grading?.step1 ?? {}),
                                    cameraHint: config?.grading?.step1?.cameraHint !== undefined ? undefined : ""
                                }
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_1.CAMERA_HINT_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.step1?.cameraHint !== undefined ? true : false}
                    textFieldValue={config?.grading?.step1?.cameraHint}
                    textFieldMultiline
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step1: {
                                    ...(config?.grading?.step1 ?? {}),
                                    cameraHint: val
                                }
                            }
                        }
                        )
                    }}
                />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_2.TITLE} useToggle={false} />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_2.TITLE_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.step2?.title !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step2: {
                                    ...(config?.grading?.step2 ?? {}),
                                    title: config?.grading?.step2?.title !== undefined ? undefined : ""
                                }
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_2.TITLE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.step2?.title !== undefined ? true : false}
                    textFieldValue={config?.grading?.step2?.title}
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step2: {
                                    ...(config?.grading?.step2 ?? {}),
                                    title: val
                                }
                            }
                        }
                        )
                    }}
                />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_2.INFORMATION_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.step2?.information !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step2: {
                                    ...(config?.grading?.step2 ?? {}),
                                    information: config?.grading?.step2?.information !== undefined ? undefined : ""
                                }
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_2.INFORMATION_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.step2?.information !== undefined ? true : false}
                    textFieldValue={config?.grading?.step2?.information}
                    textFieldMultiline
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step2: {
                                    ...(config?.grading?.step2 ?? {}),
                                    information: val
                                }
                            }
                        }
                        )
                    }}
                />
                <ParameterItem title={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_2.CAMERA_HINT_TITLE}
                    useToggle
                    toggleEnabled
                    toggleChecked={config?.grading?.step2?.cameraHint !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step2: {
                                    ...(config?.grading?.step2 ?? {}),
                                    cameraHint: config?.grading?.step2?.cameraHint !== undefined ? undefined : ""
                                }
                            }
                        }
                        )
                    }}
                    description={strings.IDENTIFICATION.SCANNER.COMPONENT.GRADING.STEP_2.CAMERA_HINT_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config?.grading?.step2?.cameraHint !== undefined ? true : false}
                    textFieldValue={config?.grading?.step2?.cameraHint}
                    textFieldMultiline
                    textFieldOnChange={(val) => {
                        onChangeConfig({
                            ...config,
                            grading: {
                                ...(config?.grading ?? {}),
                                step2: {
                                    ...(config?.grading?.step2 ?? {}),
                                    cameraHint: val
                                }
                            }
                        }
                        )
                    }}
                />
            </>
        </Stack>
    )
}
