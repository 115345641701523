import {
    Stack, Text, IconButton, getTheme, DetailsList, DetailsListLayoutMode,
    SelectionMode, DetailsRow, DirectionalHint, IColumn, IContextualMenuProps,
    IDetailsListProps, IDetailsRowStyles, SharedColors, IButtonStyles
} from '@fluentui/react';
import * as React from 'react'
import { IGrade, IQuestion } from "@piceasoft/core"
import { strings } from '../../../../../../../../../../localization/strings';
import { ResultAnswer } from '../../../../../../../../../../screens/services/Service/ServiceConfigurationDetails/ValidationInspectionsModules';
import { onRenderColumnHeaderCommon } from '../../../../../../../../detailsList/commonRenders';
import { CustomPanel } from '../../../../../../../../panel/CustomPanel';
import { ListQuestionAnswerForm } from './ListQuestionAnswerForm';
import { IAnswerOption } from '@piceasoft/core';
type TProps = {
    answers: IAnswerOption[]
    onConfirm: (answers: IAnswerOption[]) => void
    questions: IQuestion[]
    grades: IGrade[]
}

export const ListQuestionAnswers: React.FC<TProps> = ({ answers, onConfirm, grades, questions }) => {


    const [panelState, setPanelState] = React.useState<{ index: number, item: IAnswerOption }>()

    const onCancel = () => {
        setPanelState(undefined)
    }

    const onEdit = (item: IAnswerOption, index: number) => {
        setPanelState({ index, item });
    }

    const onCommit = (item: IAnswerOption) => {
        if (answers.find(i => i.index === panelState?.index)) {
            onConfirm(answers.map((a, aIndex) => {
                if (panelState?.index === aIndex) {
                    return item;
                }
                return a;
            }));
        } else {
            if (panelState?.index !== undefined) {
                onConfirm([...answers, { ...item, index: panelState?.index }]);
            }
        }
        onCancel();
    };

    const getItemMenuProps = (item: IAnswerOption, order: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.index}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => onEdit(item, order),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.TITLE,
            fieldName: 'title',
            minWidth: 200,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IAnswerOption) => <Stack verticalFill verticalAlign="center">
                <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.text}</Text>
                <Text variant="small" style={{ color: SharedColors.gray30 }}>{item.secondaryText}</Text>
            </Stack>,
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.TITLE,
            fieldName: 'result',
            minWidth: 100,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IAnswerOption) => {
                return (
                    <Stack verticalFill verticalAlign='center'>
                        <ResultAnswer answer={item} questions={questions} />
                    </Stack>
                )
            }
            ,
            isPadded: true,
        },
        {
            key: 'column3',
            name: '',
            fieldName: 'commandBar',
            minWidth: 70,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IAnswerOption, order?: number) => {
                console.log(order)
                console.log(item)
                return (
                    <Stack horizontal verticalFill horizontalAlign='end' verticalAlign='center' onClick={(ev) => ev.stopPropagation()}>
                        <Stack.Item>
                            <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={order !== undefined ? getItemMenuProps(item, order) : undefined} />
                        </Stack.Item>
                    </Stack>
                )
            }
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                    '.up-icon': {
                        fontSize: 16,
                        color: theme.palette.black
                    }
                }
            };
            return <Stack onClick={() => { onEdit(rProps.item, rProps.itemIndex) }
            }><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    }

    return (
        <Stack verticalFill tokens={{ childrenGap: 16 }}>
            <Stack.Item verticalFill>
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    <DetailsList
                        styles={{ root: { width: '100%' } }}
                        items={answers}
                        columns={columns}
                        setKey="none"
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={false}
                        onRenderRow={onRenderRow}
                    />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <CustomPanel
                    isOpen={panelState ? true : false}
                    onCancel={onCancel}
                    noCancelOnDissmiss={true}
                    title={strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.EDIT}
                >
                    <ListQuestionAnswerForm
                        onSubmit={onCommit}
                        onCancel={onCancel}
                        data={panelState?.item}
                        grades={grades}
                        questions={questions}
                    />
                </CustomPanel>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    icon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16,
        padding: 0
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}
