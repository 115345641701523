import { Stack, Text, IconButton, getTheme, IIconProps, Pivot, SharedColors, IStyleFunctionOrObject, IPivotStyleProps, IPivotStyles, PivotItem, IRenderFunction, IPivotItemProps, DirectionalHint, TooltipHost, Icon, ScrollablePane, FontWeights, mergeStyleSets, CommandBarButton, IContextualMenuProps, Label, IColumn, DetailsListLayoutMode, SelectionMode, DetailsRow, IDetailsRowStyles, IDetailsListProps, DetailsList, Button, DefaultButton } from '@fluentui/react';
import { Dispatch, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStageInspectionModules } from '../../../../../../../core/actions/configurator-actions';
import { IStore } from '../../../../../../../core/store';
import { getLocalizedInspectionModeName, getLocalizedInspectionName } from '../../../../../../../localization/helpers/transaction';
import { strings } from '../../../../../../../localization/strings';
import { ModeConfiguratorCommonPivot } from './pivots/ModeConfiguratorCommonPivot';
import { StandardModeConfiguratorModePivot } from './pivots/StandardModeConfiguratorPivot';
import { IDataCollectionConfig, Inspections, ProcessStages } from "@piceasoft/core";
import { CSHHelpLink } from '../../../../../help/CSHHelp';
type TProps = {
    index: number
    stage: ProcessStages
    onDissmiss: () => void
    onChangeDispatch: Dispatch<any>
}

type TDataCollectionModeConfiguratorPivotState = "mode" | "common"

export const StandardModeConfigurator: React.FC<TProps> = ({ stage, index, onDissmiss, onChangeDispatch }) => {
    const dataCollection = useSelector((s: IStore) => getStageInspectionModules(stage, s)?.find(i => i.index === index)?.config as IDataCollectionConfig)
    const [pivotState, setPivotState] = useState<TDataCollectionModeConfiguratorPivotState>("mode")

    const getTabId = (itemKey: string) => {
        return `StandardModeConfiguratorPivot_${itemKey}`;
    };

    const handlePivotLinkClick = (item?: PivotItem) => {
        setPivotState(item?.props.itemKey! as TDataCollectionModeConfiguratorPivotState);
    };

    const getPivotHeaderText = (m: string): string => {
        switch (m) {
            case "mode": return strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.MODE_CONFIGURATOR.PIVOTS.MODE
            case "common": return strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.MODE_CONFIGURATOR.PIVOTS.COMMON
            default: return m;
        }
    };

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <span style={{ ...iProps?.style }}>
                <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                    <Icon
                        iconName={iProps?.itemIcon}
                        style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
                </TooltipHost>
            </span>
        )
    };

    return (
        <Stack verticalFill grow>
            <Stack horizontal grow style={{ padding: 8 }}>
                <Stack style={{ padding: 8 }} grow tokens={{ childrenGap: 4 }}>
                    <Text variant="xLarge">
                        {dataCollection?.ui?.title ?? getLocalizedInspectionName(Inspections.DataCollection)}
                    </Text>
                    <Text variant="small">
                        {getLocalizedInspectionModeName(Inspections.DataCollection, dataCollection as IDataCollectionConfig)}
                    </Text>
                </Stack>
                <Stack horizontal style={{ alignItems: "center" }}>
                        <CSHHelpLink articleid="1133"/>
                        <IconButton onClick={onDissmiss} styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close" />
                    </Stack>
            </Stack>
            <Stack.Item tokens={{ padding: "0px 16px 0px 16px" }} style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                <Pivot styles={pivotStyles} selectedKey={pivotState} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                    <PivotItem
                        itemIcon={'List'}
                        headerText={getPivotHeaderText("mode")}
                        onRenderItemLink={onRenderItemLink}
                        itemKey="mode"
                    />
                    <PivotItem
                        itemIcon={'Settings'}
                        headerText={getPivotHeaderText("common")}
                        onRenderItemLink={onRenderItemLink}
                        itemKey="common"
                    />
                </Pivot>
            </Stack.Item>
            <Stack.Item verticalFill style={{ position: 'relative' }}>
                <PivotStateBase
                    index={index}
                    stage={stage}
                    pivotState={pivotState}
                    dataCollection={dataCollection}
                    onChangeDispatch={onChangeDispatch}
                />
            </Stack.Item>
        </Stack>
    )
}

type TPivotStateBaseProps = {
    index: number,
    stage: ProcessStages,
    dataCollection: IDataCollectionConfig,
    pivotState: TDataCollectionModeConfiguratorPivotState,
    onChangeDispatch: Dispatch<any>
}

const PivotStateBase = (props: TPivotStateBaseProps) => {
    let {stage, index, dataCollection, pivotState, onChangeDispatch} = props
    switch (pivotState) {
        case "common": 
            return (
                <ModeConfiguratorCommonPivot
                    index={index}
                    stage={stage}
                    dataCollection={dataCollection}
                    onChangeDispatch={onChangeDispatch}
                />
            )
        case "mode":
            return (
                <StandardModeConfiguratorModePivot
                    index={index}
                    stage={stage}
                    dataCollection={dataCollection}
                    onChangeDispatch={onChangeDispatch}
                />
            )
        default: return <></>
    }
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

let theme = getTheme()

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}
