import { Stack, ScrollablePane, IDropdownOption, PrimaryButton, IconButton, TextField, Toggle, Slider } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { portalApi } from "../../../../../../../core/api/api";
import { IStore } from "../../../../../../../core/store";
import { DeviceTypes } from "@piceasoft/core";
import { strings } from "../../../../../../../localization/strings";
import { onRenderLabel } from "../../../../../../renders/onRenderLabel";
import { Section } from "../../../../../decorations/Section";
import { SearchResultsWithTagPicker } from "../../../../../search/SearchResultsWithTagPicker";
import { ParameterItem } from "../../../../components/ParameterItem";
import { getSliderLabel } from "../../../common/helpers/fuctions";
import { IDeviceConfiguration, IDeviceGroup } from '@piceasoft/core';
import { IIdentificationUniversalConfig } from "@piceasoft/core";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const UniversalCommonSettingsPivot: React.FC<TProps> = (props) => {

    const [error, setError] = React.useState<string>()
    const [manufacturers, setManufacturers] = React.useState<string[]>()

    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationUniversalConfig)

    const groupsOptions: IDropdownOption[] = [
        {
            key: DeviceTypes.DEVICE_TYPE_MOBILE,
            text: strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES.MOBILE
        },        
        {
            key: DeviceTypes.DEVICE_TYPE_WATCH,
            text: strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES.WATCH
        },
    ]

    React.useEffect(() => {
        if (config.useManufacturer) {
            getManufacturers()
        }
    }, [config.useManufacturer])

    const getManufacturers = async () => {
        setError(undefined)
        const result = await portalApi.organization.devices.getManufacturers()
        console.log(result)
        if (result.successed) {
            setTimeout(() => {
                setManufacturers(result.data)
            }, 500)
        }
        if (result.errors && result.errors.length > 0) {
            setError(result.errors[0].description)
        }
    }

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.HEADER} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useGroup !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useGroup: config.useGroup !== undefined ? undefined : {}
                            } as IIdentificationUniversalConfig))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES_DESCRIPTION}
                    // useDropdown
                    // dropdownMultiselect
                    // dropdownEnabled={config.useGroup !== undefined ? true : false}
                    // dropdownSelectedKeys={(config.useGroup?.items && config.useGroup?.items.length > 0 && config.useGroup?.items?.map(i => i.code)) || []}
                    // dropdownOnChange={(opt) => props.onChangeDispatch(actionCreators.identification.editModule({
                    //     ...config,
                    //     useGroup: {
                    //         ...config.useGroup,
                    //         items: opt?.selected ? (config.useGroup?.items ? [...config.useGroup?.items, { code: opt.key, value: opt.text } as IDeviceGroup] : [{ code: opt.key, value: opt.text } as IDeviceGroup]) : config.useGroup?.items?.filter(i => i.code !== opt?.key)
                    //     }
                    // } as IIdentificationUniversalConfig))}
                    // dropdownPlaceholder={strings.CONSTRUCTOR.IDENTIFICATION.GLOBAL.PIVOTS.COMMON.TYPES_PLACEHOLDER}
                    // dropdownOptions={groupsOptions}
                    >
                        {config.useGroup && (
                            <Stack tokens={{ childrenGap: 4 }}>
                                {config.useGroup.items?.map((i, index) => <Stack.Item key={index} grow>
                                    <Stack verticalFill grow horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                                        <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                            <IconButton disabled={index === 0}
                                                iconProps={{ iconName: 'Up' }}
                                                onClick={() => index > 0 && props.onChangeDispatch(actionCreators.identification.editModule({
                                                    ...config,
                                                    useGroup: {
                                                        ...config.useGroup,
                                                        items: config.useGroup?.items?.map((i, localIndex) => {
                                                            if (localIndex === index && config.useGroup?.items && config.useGroup?.items[localIndex - 1]) {
                                                                return config.useGroup?.items[localIndex - 1] as IDeviceGroup
                                                            }
                                                            if (localIndex === index - 1 && config.useGroup?.items && config.useGroup?.items[localIndex + 1]) {
                                                                return config.useGroup?.items[localIndex + 1] as IDeviceGroup
                                                            }
                                                            return i
                                                        })
                                                    }
                                                } as IIdentificationUniversalConfig))}
                                            />
                                        </Stack>
                                        <Stack grow style={{ paddingTop: 2 }} horizontal verticalAlign='center' tokens={{ childrenGap: 24 }}>
                                            <Stack.Item>
                                                <TextField onRenderLabel={onRenderLabel}
                                                    label={index === 0 ? strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPE_NAME : undefined} value={i.value}
                                                    onChange={(ev, val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                                        ...config,
                                                        useGroup: {
                                                            ...config.useGroup,
                                                            items: config.useGroup?.items?.map((i, localIndex) => {
                                                                if (index === localIndex) {
                                                                    return {
                                                                        value: val,
                                                                        code: i.code
                                                                    } as IDeviceGroup
                                                                }
                                                                return i
                                                            })
                                                        }
                                                    } as IIdentificationUniversalConfig))}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField onRenderLabel={onRenderLabel}
                                                    label={index === 0 ? strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPE_CODE : undefined} value={i.code}
                                                    onChange={(ev, val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                                        ...config,
                                                        useGroup: {
                                                            ...config.useGroup,
                                                            items: config.useGroup?.items?.map((i, localIndex) => {
                                                                if (index === localIndex) {
                                                                    return {
                                                                        value: i.value,
                                                                        code: val
                                                                    } as IDeviceGroup
                                                                }
                                                                return i
                                                            })
                                                        }
                                                    } as IIdentificationUniversalConfig))}
                                                />
                                            </Stack.Item>
                                        </Stack>
                                        <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                            <IconButton
                                                iconProps={{ iconName: 'Delete' }}
                                                onClick={() => props.onChangeDispatch(actionCreators.identification.editModule({
                                                    ...config,
                                                    useGroup: {
                                                        ...config.useGroup,
                                                        items: config.useGroup?.items?.filter((i, localIndex) => localIndex !== index)
                                                    }
                                                } as IIdentificationUniversalConfig))}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack.Item>)}
                                <Stack.Item style={{ padding: "16px 0px 0px 0px" }}>
                                    <PrimaryButton onClick={() => props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        useGroup: {
                                            ...config.useGroup,
                                            items: config.useGroup?.items ? [...config.useGroup.items, { value: "", code: "" } as IDeviceGroup] : [{ value: "", code: "" } as IDeviceGroup]
                                        }
                                    } as IIdentificationUniversalConfig))} >{strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.ADD_TYPE_BUTTON}</PrimaryButton>
                                </Stack.Item>
                            </Stack>
                        )}
                    </ParameterItem>
                    {config.useGroup && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES_DEFAULT_TITLE}
                            useToggle={false}
                            useDropdown
                            dropdownEnabled={(config.useGroup.items && config.useGroup.items?.length > 0) ? true : false}
                            dropdownSelectedKey={config.useGroup.default}
                            dropdownPlaceholder={
                                !(config.useGroup.items && config.useGroup.items?.length > 0) ?
                                    strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES_NO_TYPES :
                                    strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES_DEFAULT_PLACEHOLDER
                            }
                            dropdownOptions={config.useGroup.items?.map(m => ({ key: m.code, text: m.value }))}
                            dropdownOnChange={(opt) => opt && props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useGroup: {
                                    ...config.useGroup,
                                    default: opt.key
                                }
                            } as IIdentificationUniversalConfig
                            ))}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES_DEFAULT_DESCRIPTION}
                        />
                    )}
                    {config.useGroup && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES_USE_MANUAL_INPUT_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useGroup?.useManualInput !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useGroup: {
                                        ...config.useGroup,
                                        useManualInput: config.useGroup?.useManualInput !== undefined ? undefined : true
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.TYPES_USE_MANUAL_INPUT_DESCRIPTION}
                        />
                    )}
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useManufacturer !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useManufacturer: config.useManufacturer !== undefined ? undefined : {}
                            } as IIdentificationUniversalConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_DESCRIPTION}
                    />
                    {config.useManufacturer && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURERS_SET_TITLE}
                            useToggle={false}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURERS_SET_DESCRIPTION}
                        >
                            {manufacturers && (
                                <SearchResultsWithTagPicker
                                    items={manufacturers?.map(i => { return { key: i, name: i } }) ?? []}
                                    inputProps={{ 'aria-label': 'Manufacturers Picker', placeholder: strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURERS_SET_PLACEHOLDER }}
                                    selectionAriaLabel={"Selected manufacturers"}
                                    onItemSelected={i => i && props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        useManufacturer: {
                                            ...config.useManufacturer,
                                            items: config.useManufacturer?.items ? [...config.useManufacturer?.items, i.name] : [i.name]
                                        }
                                    } as IIdentificationUniversalConfig
                                    )) || null}
                                    onRemoveSelectedItem={i => props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        useManufacturer: {
                                            ...config.useManufacturer,
                                            items: (config.useManufacturer?.items && config.useManufacturer?.items?.filter(m => i.name !== m).length > 0) ?
                                                config.useManufacturer?.items?.filter(m => i.name !== m) :
                                                undefined
                                        }
                                    } as IIdentificationUniversalConfig
                                    ))}
                                    selectedItems={config.useManufacturer.items?.map(m => ({ key: m, name: m })) ?? []}
                                    pickerSuggestionsProps={{
                                        suggestionsHeaderText: strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.PICKER_SUGGESTIONS_HEADER,
                                        noResultsFoundText: strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.PICKER_NO_RESULTS_TEXT,
                                    }}
                                />
                            ) || (
                                    <TextField placeholder={strings.SPINNERS.DATA_IS_LOADING} disabled={true} />
                                )}
                        </ParameterItem>
                    )}
                    {config.useManufacturer && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_DEFAULT_TITLE}
                            useToggle={false}
                            useDropdown
                            dropdownEnabled={(config.useManufacturer.items && config.useManufacturer.items?.length > 0) ? true : false}
                            dropdownSelectedKey={config.useManufacturer.default}
                            dropdownPlaceholder={
                                !(config.useManufacturer.items && config.useManufacturer.items?.length > 0) ?
                                    strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_NO_MANUFACTURERS :
                                    strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_DEFAULT_PLACEHOLDER
                            }
                            dropdownOptions={config.useManufacturer.items?.map(m => ({ key: m, text: m }))}
                            dropdownOnChange={(opt) => opt && props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useManufacturer: {
                                    ...config.useManufacturer,
                                    default: opt.text
                                }
                            } as IIdentificationUniversalConfig
                            ))}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_DEFAULT_DESCRIPTION}
                        />
                    )}
                    {config.useManufacturer && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_USE_MANUAL_INPUT_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useManufacturer?.useManualInput !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useManufacturer: {
                                        ...config.useManufacturer,
                                        useManualInput: config.useManufacturer?.useManualInput !== undefined ? undefined : true
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_USE_MANUAL_INPUT_DESCRIPTION}
                        />
                    )}
                    {config.useManufacturer && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_PRICE_SOURCE_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useManufacturer?.usePriceSource !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useManufacturer: {
                                        ...config.useManufacturer,
                                        usePriceSource: config.useManufacturer?.usePriceSource !== undefined ? undefined : true
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MANUFACTURER_PRICE_SOURCE_DESCRIPTION}
                        />
                    )}
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MODEL_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useModel !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useModel: config.useModel !== undefined ? undefined : {}
                            } as IIdentificationUniversalConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MODEL_DESCRIPTION}
                    />
                    {config.useModel && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MODEL_DEFAULT_TITLE}
                            useToggle={false}
                            // toggleEnabled
                            // toggleChecked={config.useModel?.default !== undefined ? true : false}
                            // toggleOnChange={() => {
                            //     props.onChangeDispatch(actionCreators.identification.editModule({
                            //         ...config,
                            //         useModel: {
                            //             ...config.useModel,
                            //             default: config.useModel?.default !== undefined ? undefined : ""
                            //         }
                            //     } as IIdentificationUniversalConfig))
                            // }}
                            useTextField
                            // textFieldEnabled={config.useModel.default !== undefined ? true : false}
                            textFieldEnabled={true}
                            // textFieldLabel={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MODEL_DEFAULT_LABEL}
                            textFieldValue={config.useModel?.default}
                            textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useModel: {
                                    ...config.useModel,
                                    default: val
                                }
                            } as IIdentificationUniversalConfig
                            ))}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MODEL_DEFAULT_DESCRIPTION}
                        />
                    )}
                    {config.useModel && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MODEL_LENGTH_TITLE}
                            useToggle={false}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_MODEL_LENGTH_DESCRIPTION}
                        >
                            <Stack style={{ maxWidth: 320 }}>
                                <Slider label={getSliderLabel(config.useModel.minLength, config.useModel.maxLength)} showValue={false} ranged lowerValue={config.useModel.minLength} value={config.useModel.maxLength} onChange={(val, range) => {
                                    props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        useModel: {
                                            ...config.useModel,
                                            minLength: range && range[0] !== 0 && range[0] || undefined,
                                            maxLength: range && range[1] !== 0 && range[1] || undefined
                                        }
                                    } as IIdentificationUniversalConfig))
                                }} min={0} max={50} />
                            </Stack>
                        </ParameterItem>
                    )}
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useSerial !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useSerial: config.useSerial !== undefined ? undefined : {}
                            } as IIdentificationUniversalConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_DESCRIPTION}
                    />
                    {config.useSerial && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_MASK_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useSerial?.mask !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useSerial: {
                                        ...config.useSerial,
                                        mask: config.useSerial?.mask !== undefined ? undefined : ""
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            useTextField
                            textFieldEnabled={config.useSerial.mask !== undefined ? true : false}
                            // textFieldLabel={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_MASK_LABEL}
                            textFieldValue={config.useSerial?.mask}
                            textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useSerial: {
                                    ...config.useSerial,
                                    mask: val
                                }
                            } as IIdentificationUniversalConfig
                            ))}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_MASK_DESCRIPTION}
                        />
                    )}
                    {config.useSerial && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_AUTOGENERATE_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useSerial?.autoGenerate !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useSerial: {
                                        ...config.useSerial,
                                        autoGenerate: config.useSerial?.autoGenerate !== undefined ? undefined : true
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_AUTOGENERATE_DESCRIPTION}
                        />
                    )}
                    {config.useSerial && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_HIDE_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useSerial?.hide !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useSerial: {
                                        ...config.useSerial,
                                        hide: config.useSerial?.hide !== undefined ? undefined : true
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_HIDE_DESCRIPTION}
                        />
                    )}
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_IMEI_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useImei !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useImei: config.useImei !== undefined ? undefined : {}
                            } as IIdentificationUniversalConfig
                            ))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_IMEI_DESCRIPTION}
                    />
                    {config.useImei && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_IMEI_MASK_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useImei?.mask !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useImei: {
                                        ...config.useImei,
                                        mask: config.useImei?.mask !== undefined ? undefined : ""
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            useTextField
                            textFieldEnabled={config.useImei.mask !== undefined ? true : false}
                            // textFieldLabel={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_SERIAL_MASK_LABEL}
                            textFieldValue={config.useImei?.mask}
                            textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useImei: {
                                    ...config.useImei,
                                    mask: val
                                }
                            } as IIdentificationUniversalConfig
                            ))}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_IMEI_MASK_DESCRIPTION}
                        />
                    )}
                    {config.useImei && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_IMEI_AUTOGENERATE_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useImei?.autoGenerate !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useImei: {
                                        ...config.useImei,
                                        autoGenerate: config.useImei?.autoGenerate !== undefined ? undefined : true
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_IMEI_AUTOGENERATE_DESCRIPTION}
                        />
                    )}
                    {config.useImei && (
                        <ParameterItem noSeparator
                            title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_IMEI_HIDE_TITLE}
                            useToggle
                            toggleEnabled
                            toggleChecked={config.useImei?.hide !== undefined ? true : false}
                            toggleOnChange={() => {
                                props.onChangeDispatch(actionCreators.identification.editModule({
                                    ...config,
                                    useImei: {
                                        ...config.useImei,
                                        hide: config.useImei?.hide !== undefined ? undefined : true
                                    }
                                } as IIdentificationUniversalConfig))
                            }}
                            description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_IMEI_HIDE_DESCRIPTION}
                        />
                    )}
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_CONFIGURATIONS_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useConfiguration !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useConfiguration: config.useConfiguration !== undefined ? undefined : true,
                            } as IIdentificationUniversalConfig))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.USE_CONFIGURATIONS_DESCRIPTION}
                    >
                        {config.useConfiguration && (
                            <Stack tokens={{ childrenGap: 4 }}>
                                {config.useConfiguration.items?.map((i, index) => <Stack.Item key={index} grow>
                                    <Stack verticalFill grow horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                                        <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                            <IconButton disabled={index === 0}
                                                iconProps={{ iconName: 'Up' }}
                                                onClick={() => index > 0 && props.onChangeDispatch(actionCreators.identification.editModule({
                                                    ...config,
                                                    useConfiguration: {
                                                        ...config.useConfiguration,
                                                        items: config.useConfiguration?.items?.map((i, localIndex) => {
                                                            if (localIndex === index && config.useConfiguration?.items && config.useConfiguration?.items[localIndex - 1]) {
                                                                return config.useConfiguration?.items[localIndex - 1] as IDeviceConfiguration
                                                            }
                                                            if (localIndex === index - 1 && config.useConfiguration?.items && config.useConfiguration?.items[localIndex + 1]) {
                                                                return config.useConfiguration?.items[localIndex + 1] as IDeviceConfiguration
                                                            }
                                                            return i
                                                        })
                                                    }
                                                } as IIdentificationUniversalConfig))}
                                            />
                                        </Stack>
                                        <Stack grow style={{ paddingTop: 2 }} horizontal verticalAlign='center' tokens={{ childrenGap: 24 }}>
                                            <Stack.Item>
                                                <TextField onRenderLabel={onRenderLabel}
                                                    label={index === 0 ? strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.CONFIGURATION_NAME : undefined} value={i.name}
                                                    onChange={(ev, val) => {
                                                        const re = /^[0-9\b]+$/;
                                                        if (val === '' || re.test(val ?? '')) {
                                                            props.onChangeDispatch(actionCreators.identification.editModule({
                                                                ...config,
                                                                useConfiguration: {
                                                                    ...config.useConfiguration,
                                                                    items: config.useConfiguration?.items?.map((i, localIndex) => {
                                                                        if (index === localIndex) {
                                                                            return {
                                                                                name: val,
                                                                                code: i.code
                                                                            }
                                                                        }
                                                                        return i
                                                                    })
                                                                }
                                                            } as IIdentificationUniversalConfig))
                                                        }
                                                    }}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TextField onRenderLabel={onRenderLabel}
                                                    label={index === 0 ? strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.CONFIGURATION_CODE : undefined} value={i.code}
                                                    onGetErrorMessage={(val) => {
                                                        const sameCodes = config.useConfiguration?.items?.filter(it => it.code == val);
                                                        if (val === '' || (sameCodes && sameCodes.length > 1)) {
                                                            return strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.PROVIDER_CODE_VALIDATION;
                                                        }
                                                        return undefined;
                                                    }}
                                                    onChange={(ev, val) => {
                                                        props.onChangeDispatch(actionCreators.identification.editModule({
                                                            ...config,
                                                            useConfiguration: {
                                                                ...config.useConfiguration,
                                                                items: config.useConfiguration?.items?.map((i, localIndex) => {
                                                                    if (index === localIndex) {
                                                                        return {
                                                                            name: i.name,
                                                                            code: val
                                                                        }
                                                                    }
                                                                    return i
                                                                })
                                                            }
                                                        } as IIdentificationUniversalConfig))
                                                    }
                                                    }
                                                />
                                            </Stack.Item>
                                        </Stack>
                                        <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                            <IconButton
                                                iconProps={{ iconName: 'Delete' }}
                                                onClick={() => props.onChangeDispatch(actionCreators.identification.editModule({
                                                    ...config,
                                                    useConfiguration: {
                                                        ...config.useConfiguration,
                                                        items: config.useConfiguration?.items?.filter((i, localIndex) => localIndex !== index)
                                                    }
                                                } as IIdentificationUniversalConfig))}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack.Item>)}
                                <Stack.Item style={{ padding: "16px 0px 0px 0px" }}>
                                    <PrimaryButton onClick={() => props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        useConfiguration: {
                                            ...config.useConfiguration,
                                            items: config.useConfiguration?.items ? [...config.useConfiguration.items, {}] : [{}]
                                        }
                                    } as IIdentificationUniversalConfig))} >{strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.COMMON.ADD_CONFIGURATION_BUTTON}</PrimaryButton>
                                </Stack.Item>
                            </Stack>
                        )}
                    </ParameterItem>
                </Section>
            </ScrollablePane>
        </Stack.Item >
    )
}