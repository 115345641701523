import { getTheme, Stack, Text } from "@fluentui/react"
import { SharedColors } from "@fluentui/theme"
import { getPersonaColorsPallete, getIconSymbolsFromString } from "../../../core/scripts/style"

type TMyPersonaProps = {
    text: string
    secondaryText?: string
    onClick?: () => void
    onlyPersona?: boolean
}
export const MyPersona: React.FC<TMyPersonaProps> = (props) => {
    return (
        <Stack grow={props.onlyPersona ? undefined : 1} horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} onClick={props.onClick ? props.onClick : undefined} >
            <Stack.Item>
                <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: getPersonaColorsPallete[(props.text?.length > 0 ? props.text[0] : 'a').toLowerCase()] }}>
                    <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                        {getIconSymbolsFromString(props.text)}
                    </Text>
                </Stack>
            </Stack.Item>
            {!props.onlyPersona && (
                <Stack.Item grow>
                    <Stack>
                        <Stack.Item>
                            <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{props.text}</Text>
                        </Stack.Item>
                        {props.secondaryText && (
                            <Stack.Item>
                                <Text variant="small" style={{ color: SharedColors.gray30 }}>{props.secondaryText}</Text>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    )
}

const theme = getTheme();