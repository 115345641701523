import {
    DirectionalHint, getTheme, Icon, IconButton,
    PrimaryButton, ScrollablePane, SharedColors,
    Stack, TextField, TooltipHost
} from "@fluentui/react"
import React from "react";
import { Control, Controller, FieldErrorsImpl, useFieldArray, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { IResourcesConfig } from "@piceasoft/core";
import { strings } from "../../../../../localization/strings";
import { onRenderLabel } from "../../../../renders/onRenderLabel";

type TProps = {
    control: Control<IResourcesConfig, object>
    setValue: UseFormSetValue<IResourcesConfig>
    getValues: UseFormGetValues<IResourcesConfig>
    errors: Partial<FieldErrorsImpl<{
        items: {
            name: string;
            source: string;
            error: string;
        }[];
    }>>
}

export const ResourcesArray: React.FC<TProps> = ({ control, setValue, getValues, errors }) => {

    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: "items"
    });

    return (
        <Stack grow style={{ padding: "0px 0px" }} tokens={{ childrenGap: 16 }}>
            <Stack.Item grow style={{ position: 'relative' }}>
                <ScrollablePane>
                    <Stack tokens={{ padding: "0px 16px", childrenGap: 16 }}>
                        {fields.map((field, index) => <Stack.Item key={field.id} grow>
                            <Stack verticalFill grow horizontal verticalAlign='end' style={{ minWidth: 600 }} tokens={{ childrenGap: 16 }}>
                                <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }} style={{ minWidth: 32 }}>
                                    {index > 0 && <IconButton
                                        iconProps={{ iconName: 'Up' }}
                                        onClick={() => swap(index - 1, index)}
                                    />}
                                </Stack>
                                <Stack grow style={{ paddingTop: 2 }} horizontal verticalAlign='start' tokens={{ childrenGap: 24 }}>
                                    <Stack tokens={{ childrenGap: 8 }}>
                                        <Controller
                                            control={control}
                                            name={`items.${index}.name`}
                                            rules={{ required: strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.NAME_REQUIRED }}
                                            render={({ field }) =>
                                                <TextField
                                                    required={index === 0 ? true : false}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    style={{ width: 350 }}
                                                    styles={{ fieldGroup: { borderColor: (errors.items && errors.items[index] && errors?.items[index]?.name?.message) ? SharedColors.red10 : undefined } }}
                                                    onRenderLabel={index === 0 ? onRenderLabel : undefined}
                                                    label={index === 0 ? strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.NAME_LABEL : undefined}
                                                    title={index === 0 ? strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.NAME_TITLE : undefined}
                                                    placeholder={strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.NAME_PLACEHOLDER}
                                                />
                                            }
                                        />
                                    </Stack>
                                    <Controller
                                        control={control}
                                        name={`items.${index}.source`}
                                        rules={{
                                            required: strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.SOURCE_REQUIRED,
                                            validate: (value) => {
                                                return (value.startsWith("https://") || value.startsWith("http://")) ? true : strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.SOURCE_INVALID
                                            }
                                        }}
                                        render={({ field }) =>
                                            <TextField
                                                required={index === 0 ? true : false}
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={field.onBlur}
                                                style={{ width: 450 }}
                                                styles={{ fieldGroup: { borderColor: (errors.items && errors.items[index] && errors?.items[index]?.source?.message) ? SharedColors.red10 : undefined } }}
                                                onRenderLabel={index === 0 ? onRenderLabel : undefined}
                                                label={index === 0 ? strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.SOURCE_LABEL : undefined}
                                                title={index === 0 ? strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.SOURCE_TITLE : undefined}
                                                placeholder={strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.SOURCE_PLACEHOLDER}
                                            />
                                        }
                                    />
                                </Stack>
                                <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                    <Stack.Item>
                                        {(errors.items && errors.items[index]) && (
                                            <TooltipHost content={errors.items[index]?.source?.message}
                                                directionalHint={DirectionalHint.bottomCenter}
                                                styles={{ root: { display: "flex", cursor: "default" } }}
                                                tooltipProps={{
                                                    calloutProps: {
                                                        styles: {
                                                            beak: { background: theme.palette.black },
                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                        },
                                                    },
                                                }}
                                            >
                                                <Icon iconName='AlertSolid' style={{ color: SharedColors.red10, fontSize: 16 }}
                                                />
                                            </TooltipHost>
                                        )}
                                    </Stack.Item>
                                    <IconButton
                                        iconProps={{ iconName: 'Delete' }}
                                        onClick={() => remove(index)}
                                    />
                                </Stack>
                            </Stack>
                        </Stack.Item>)}
                        <Stack tokens={{ padding: "12px 48px", childrenGap: 16 }}>
                            <Stack.Item>
                                <PrimaryButton onClick={() => append({ name: '', source: '' })}>{strings.BUTTONS.TEXT.ADD}</PrimaryButton>
                            </Stack.Item>
                            <Controller
                                control={control}
                                name={"ui.error"}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ height: 40 }}
                                        multiline
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.ERROR_LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.ERROR_TITLE}
                                        placeholder={strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.FORM.ERROR_PLACEHOLDER}
                                    />
                                }
                            />
                        </Stack>
                    </Stack>
                </ScrollablePane>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();