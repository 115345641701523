import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { ProcessStages, IResultConfig, ServiceTypes } from "@piceasoft/core";

type TProps = {
    config: IResultConfig
    onChangeDispatch: Dispatch<any>
    channel: number
    serviceType?: number
}

export const ResultCommonButtonsChildren: React.FC<TProps> = ({ onChangeDispatch, config, channel, serviceType }) => {
    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BUTTONS.CONTRACT_BUTTON_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Result, 'Buttons', 'Show contract button', serviceType)}
                toggleChecked={config.contractButton !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.result.onConfigCommit({
                        ...config,
                        contractButton: config.contractButton ? undefined : {}
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BUTTONS.CONTRACT_BUTTON_DESCRIPTION}
            />
            {config.contractButton && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BUTTONS.ICON_TITLE}
                    useToggle
                    noSeparator
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Result, 'Buttons', 'Custom Print button icon')}
                    toggleChecked={config.contractButton?.icon !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.result.onConfigCommit({
                            ...config,
                            contractButton: {
                                ...config.contractButton,
                                icon: config.contractButton?.icon !== undefined ? undefined : ""
                            }
                        }
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BUTTONS.ICON_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.contractButton?.icon !== undefined ? true : false}
                    textFieldValue={config.contractButton?.icon}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.result.onConfigCommit({
                        ...config,
                        contractButton: {
                            ...config.contractButton,
                            icon: val
                        }
                    }
                    ))
                    }
                />
            )}
            {config.contractButton && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BUTTONS.TEXT_TITLE}
                    useToggle
                    noSeparator
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Result, 'Buttons', 'Custom Print button caption')}
                    toggleChecked={config.contractButton?.text !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.result.onConfigCommit({
                            ...config,
                            contractButton: {
                                ...config.contractButton,
                                text: config.contractButton?.text !== undefined ? undefined : ""
                            }
                        }
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BUTTONS.TEXT_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.contractButton?.text !== undefined ? true : false}
                    textFieldValue={config.contractButton?.text}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.result.onConfigCommit({
                        ...config,
                        contractButton: {
                            ...config.contractButton,
                            text: val
                        }
                    }
                    ))
                    }
                />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BUTTONS.TOOLTIP_TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Result, 'Buttons', 'Custom Print button tooltip text')}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.result.onConfigCommit({
                        ...config,
                        contractButton: {
                            ...config.contractButton,
                            tooltip: config.contractButton?.tooltip !== undefined ? undefined : ""
                        }
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.BUTTONS.TOOLTIP_DESCRIPTION}
                useTextField
                textFieldEnabled={config.contractButton?.tooltip !== undefined ? true : false}
                textFieldValue={config.contractButton?.tooltip}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.result.onConfigCommit({
                    ...config,
                    contractButton: {
                        ...config.contractButton,
                        tooltip: val
                    }
                }
                ))
                }
            />
        </Stack>
    )
}