import React from "react"
import { Dispatch } from "react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../../core/store"
import { DependencyPivot } from "../../../common/pivots/DependencyPivot"
import { ShowConfigurationPivot } from "../../../common/pivots/ShowConfigurationPivot"
import { SkipPivot } from "../../../common/pivots/SkipPivot"
import { TInspectionSettingsPivotStates } from "../../InspectionModule"
import { InspectionModeCommonSettingsPivot } from "../../settings/mode/InspectionModeCommonSettingsPivot"
import { AIGradingUISettingsPivot } from "./AIGradingUISettingsPivot"
import { ProcessStages } from "@piceasoft/core"
type TProps = {
    state: TInspectionSettingsPivotStates
    index: number
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
    onClose: () => void
    withoutGrade?: boolean
}

export const AIGradingSettingsPivotBasedState: React.FC<TProps> = (props) => {
    
    switch (props.state) {
        case "ui":
            console.log(props.state) 
        return (
            <AIGradingUISettingsPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "common": return (
            <InspectionModeCommonSettingsPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch} withoutGrade={props.withoutGrade} />
        )
        case "dependency_inspections": return (
            <DependencyPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "skip": return (
            <SkipPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch} onClose={props.onClose} />
        )
        case "config": return (
            <ShowConfigurationPivot json={useSelector((s: IStore) => s.configurator.assessment?.modules.find(i => i.index === props.index)) ?? {}} />
        )
        default:
            return (
                <></>
            )
    }
}