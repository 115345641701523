import { Text, DefaultButton, PrimaryButton, Stack, SharedColors } from "@fluentui/react"
import React from "react"
import { FieldError, useForm } from "react-hook-form"
import { strings } from "../../../../../../../../../localization/strings"
import { TContractData } from "../../../dataCollectionTypes"
import AddressController from "./controllers/address/AddressController"
import ClientNameController from "./controllers/clientName/ClientNameController"
import CustomFieldController from "./controllers/customField/CustomFieldController"
import DateOfBirthControlleer from "./controllers/dateOfBirth/DateOfBirthController"
import DateOfIssueControlleer from "./controllers/dateOfIssue/DateOfIssueController"
import IssuedByController from "./controllers/issuedBy/IssuedByController"
import OperatorController from "./controllers/operator/OperatorController"
import PassportNumberController from "./controllers/passportNumber/PassportNumberController"
import PhoneCountryController from "./controllers/phoneCountryCode/PhoneCountryCodeController"
import PhoneNumberController from "./controllers/phoneNumber/PhoneNumberController"
import PlaceOfBirthControlleer from "./controllers/placeOfBirth/PlaceOfBirthController"
import SubdivisonCodeController from "./controllers/subdivisionCode/SubdivisionCodeController"
import { IDataCollectionConfig } from "@piceasoft/core"

type TProps = {
    hidePreviewModal: () => void,
    dataCollection?: IDataCollectionConfig
}

export const PreviewForm: React.FC<TProps> = (props: TProps) => {
    let {dataCollection, hidePreviewModal} = props

    const { control, formState : {errors}, getValues, handleSubmit, watch} = useForm<TContractData>({
        defaultValues: {},
        mode: 'onBlur'
    });

    const watchState = watch()
    
    const onRenderSuggestionsHeader = (): JSX.Element => {
        return (
            <Stack tokens={{ padding: 8, childrenGap: 4 }}>
                <Text style={{ color: SharedColors.gray10 }}>{strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.PREVIEW_FORM.CHOOSE_AN_OPTION}</Text>
            </Stack>
        );
    }

    const getCustomFieldError = (index: number) => {
        if (errors.customFields !== undefined && errors.customFields[index] !== undefined) {
            const error = errors.customFields[index] as FieldError
            return error.message ?? ""
        }
        return ""
    }

    const onSubmit = () => {
        hidePreviewModal()
    }

    return (
        <Stack>
            <Stack.Item>
                <Text>{dataCollection?.config?.ui?.description ?? strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.FILL_FORM_DATA}</Text>
                </Stack.Item>
            <Stack.Item>
                <form>
                    <Stack tokens={{ childrenGap: 8 }} style={{ paddingTop: 8 }}>
                        <Stack.Item grow>
                            {dataCollection?.config?.fields?.name &&
                                <ClientNameController
                                    control={control}
                                    config={dataCollection}
                                    onRenderSuggestionsHeader={onRenderSuggestionsHeader}
                                    errorMessage={errors.name?.message ?? ''}
                                />
                            }
                        </Stack.Item>
                        {dataCollection?.config?.fields?.phone && (
                            <Stack horizontal grow tokens={{ childrenGap: 16 }}>
                                <Stack.Item grow>
                                    <PhoneCountryController
                                        config={dataCollection?.config}
                                        control={control}
                                        errorMessage={errors.phoneCountry?.message ?? ''}
                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <PhoneNumberController
                                        config={dataCollection?.config}
                                        control={control}
                                        fieldValue={getValues('phone')}
                                        phoneCountry={getValues('phoneCountry')}
                                        errorMessage={errors.phone?.message ?? ''}
                                    />
                                </Stack.Item>
                            </Stack>
                        )}
                        {(dataCollection?.config.fields?.passport?.dateOfBirth || dataCollection?.config.fields?.passport?.placeOfBirth) && (
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 16 }} >
                                <Stack.Item>
                                    {dataCollection?.config.fields?.passport?.dateOfBirth && (
                                        <DateOfBirthControlleer
                                            config={dataCollection?.config}
                                            control={control}
                                            errorMessage={errors.passport?.dateOfBirth?.message ?? ''}
                                        />
                                    )}
                                </Stack.Item>
                                <Stack.Item grow>
                                    {dataCollection?.config.fields?.passport?.placeOfBirth && (
                                        <PlaceOfBirthControlleer
                                            config={dataCollection?.config}
                                            control={control}
                                            errorMessage={errors.passport?.placeOfBirth?.message ?? ''}
                                        />
                                    )}
                                </Stack.Item>
                            </Stack>
                        )}
                        {(dataCollection?.config?.fields?.passport?.number || dataCollection?.config?.fields?.passport?.dateOfIssue) && (
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 16 }}>
                                <Stack.Item grow>
                                    {dataCollection?.config?.fields?.passport?.number && (
                                        <PassportNumberController
                                            config={dataCollection?.config}
                                            control={control}
                                            fieldValue={getValues('passport.number')}
                                            errorMessage={errors.passport?.number?.message ?? ''}
                                        />
                                    )}
                                </Stack.Item>
                                <Stack.Item grow>
                                    {dataCollection?.config?.fields?.passport?.dateOfIssue && (
                                        <DateOfIssueControlleer
                                            config={dataCollection?.config}
                                            control={control}
                                            errorMessage={errors.passport?.dateOfIssue?.message ?? ''}
                                        />
                                    )}
                                </Stack.Item>
                            </Stack>
                        )}
                        {dataCollection?.config?.fields?.passport?.subdivisionCode && (
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 16 }}>
                                <SubdivisonCodeController
                                    config={dataCollection?.config}
                                    control={control}
                                    fieldValue={getValues('passport.subdivisionCode')}
                                    errorMessage={errors.passport?.subdivisionCode?.message ?? ''}
                                />
                            </Stack>
                        )}
                        {dataCollection?.config?.fields?.passport?.issuedBy && (
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 16 }}>
                                <Stack.Item grow>
                                    <IssuedByController
                                        config={dataCollection}
                                        control={control}
                                        subdivisionCode={watchState.passport?.subdivisionCode}
                                        errorMessage={errors.passport?.issuedBy?.message ?? ''}
                                    />
                                </Stack.Item>
                            </Stack>            
                        )}
                        {dataCollection?.config?.fields?.passport?.address && (
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 16 }}>
                                <Stack.Item grow>
                                    <AddressController
                                        control={control}
                                        config={dataCollection}
                                        onRenderSuggestionsHeader={onRenderSuggestionsHeader}
                                        errorMessage={errors.passport?.address?.message ?? ''}
                                    />
                                </Stack.Item>
                            </Stack>
                        )}
                        {dataCollection?.config?.fields?.operator && (
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 16 }}>
                                <OperatorController
                                    config={dataCollection?.config}
                                    control={control}
                                    errorMessage={errors.operator?.message ?? ''}
                                />
                            </Stack>
                        )}
                        {dataCollection?.config?.fields?.custom?.map((item, index) => {
                            return (dataCollection?.config?.fields?.custom !== undefined &&
                                <Stack key={item.key} horizontal horizontalAlign="space-between" tokens={{ childrenGap: 16 }}>
                                    <Stack.Item grow>
                                        <CustomFieldController
                                            control={control}
                                            number={index}
                                            field={getValues(`customFields.${index}`)}
                                            customFieldData={dataCollection?.config?.fields?.custom[index]}
                                            errorMessage={getCustomFieldError(index)}
                                        />
                                    </Stack.Item>
                                </Stack>
                            ) 
                        })}
                    </Stack>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: "20px 0 0 0" }}>
                        <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        <DefaultButton onClick={hidePreviewModal}>{strings.BUTTONS.TEXT.CANCEL}</DefaultButton>
                    </Stack>
                </form>
            </Stack.Item>
        </Stack>
    )
}