import { Dispatch } from "react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../../core/store"
import { ShowConfigurationPivot } from "../../../common/pivots/ShowConfigurationPivot"
import { TIdentificationModuleSettingsPivotStates } from "../../IdentificationModule"
import { TransactionUISettingsPivot } from "./TransactionUISettingsPivot"

type TProps = {
    state: TIdentificationModuleSettingsPivotStates
    index: number
    onChangeDispatch: Dispatch<any>
}

export const TransactionSettingsPivotBasedState: React.FC<TProps> = (props) => {
    switch (props.state) {
        case "ui": return (
            <TransactionUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "config": return (
            <ShowConfigurationPivot json={useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index)) ?? {}} />
        )
        default:
            return (
                <></>
            )
    }
}
