import { IComboBoxOption } from "@fluentui/react"
import { strings } from "../../localization/strings"

export const getLocalizedTimezone = (offset?: number):string => {
    switch (offset) {
        case 0: return strings.SHARED.TIMEZONES.UTC_0
        case 1: return strings.SHARED.TIMEZONES.UTC_1_POSITIVE
        case -1: return strings.SHARED.TIMEZONES.UTC_1_NEGATIVE
        case 2: return strings.SHARED.TIMEZONES.UTC_2_POSITIVE
        case -2: return strings.SHARED.TIMEZONES.UTC_2_NEGATIVE
        case 3: return strings.SHARED.TIMEZONES.UTC_3_POSITIVE
        case -3: return strings.SHARED.TIMEZONES.UTC_3_NEGATIVE
        case 4: return strings.SHARED.TIMEZONES.UTC_4_POSITIVE
        case -4: return strings.SHARED.TIMEZONES.UTC_4_NEGATIVE
        case 5: return strings.SHARED.TIMEZONES.UTC_5_POSITIVE
        case -5: return strings.SHARED.TIMEZONES.UTC_5_NEGATIVE
        case 6: return strings.SHARED.TIMEZONES.UTC_6_POSITIVE
        case -6: return strings.SHARED.TIMEZONES.UTC_6_NEGATIVE
        case 7: return strings.SHARED.TIMEZONES.UTC_7_POSITIVE
        case -7: return strings.SHARED.TIMEZONES.UTC_7_NEGATIVE
        case 8: return strings.SHARED.TIMEZONES.UTC_8_POSITIVE
        case -8: return strings.SHARED.TIMEZONES.UTC_8_NEGATIVE
        case 9: return strings.SHARED.TIMEZONES.UTC_9_POSITIVE
        case -9: return strings.SHARED.TIMEZONES.UTC_9_NEGATIVE
        case 10: return strings.SHARED.TIMEZONES.UTC_10_POSITIVE
        case -10: return strings.SHARED.TIMEZONES.UTC_10_NEGATIVE
        case 11: return strings.SHARED.TIMEZONES.UTC_11_POSITIVE
        case -11: return strings.SHARED.TIMEZONES.UTC_11_NEGATIVE
        case 12: return strings.SHARED.TIMEZONES.UTC_12_POSITIVE
        case -12: return strings.SHARED.TIMEZONES.UTC_12_NEGATIVE
        default: return offset?.toString() ?? ""
    }
}

export const prepareTimezonesOptions = (): IComboBoxOption[] => {
    return [...Array(25).keys()].map(i => {
        return {
            key: i - 12,
            text: getLocalizedTimezone(i - 12),
        }
    })
}