import { Control, Controller } from "react-hook-form"
import { IDataCollectionStandardConfig } from "@piceasoft/core"
import { getCommonFieldRules } from "../../../helpers/validationRules"
import PlaceOfBirth from "./PlaceOfBirth"

import type { TContractData } from "../../../../../dataCollectionTypes"

type TProps = {
    config?: IDataCollectionStandardConfig
    errorMessage: string
    phoneCountry?: string
    control: Control<TContractData, object>,
}

const PlaceOfBirthControlleer: React.FunctionComponent<TProps> = (props: TProps) => {
    let {config, control, errorMessage} = props
    return (
        <Controller
            name="passport.placeOfBirth"
            control={control}
            rules={getCommonFieldRules(config?.fields?.passport?.placeOfBirth)}
            render={({ field }) =>
                <PlaceOfBirth
                    config={config}
                    value={field.value}
                    errorMessage={errorMessage}
                    handleOnBlur={field.onBlur}
                    handleOnChange={(value?: string) => {
                        field.onChange(value)
                    }}
                />
            }
        />
    )
}

export default PlaceOfBirthControlleer