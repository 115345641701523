import React, { Dispatch } from "react";
import { IContactForm, IRANForm, IResponseError, ISelfServiceWorkflowConfig } from "@piceasoft/core"
import { TStageSettingsPivotStates } from "../../../Configurator";
import { IComboBoxOption, IDropdownOption, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { ParameterItem } from "../../../components/ParameterItem";
import { strings } from "../../../../../../localization/strings";
import { portalApi } from "../../../../../../core/api/api";
import RanFormSettings from "../contactForm/RanFormSettings";
import ContactFormSettings from "../contactForm/ContactFormSettings";

const contactEnabled = {
    enabled: true,
    name: {},
    phone: {},
    email: {},
    address: {},
    passportNumber: {},
    passportDateOfIssue: {},
    passportIssuedBy: {},
    passportSubdivisionCode: {},
    dateOfBirth: {},
    placeOfBirth: {},
    operator: {}
} as IContactForm

const ranEnabled = {
    enabled: true,
    idNumber: {},
    name: {},
    phone: {},
    email: {},
    address: {},
    zipCode: {},
    city: {},
    comments: {},
    scheduleStart: {},
    scheduleEnd: {},
    deviceIMEI: {},
    deviceModel: {},
    deviceBrand: {},
    deviceColor: {},
    deviceInvoiceDate: {}
} as IRANForm


interface TProps {
    state: TStageSettingsPivotStates
    config?: ISelfServiceWorkflowConfig
    pivots?: { name: string, component: React.ReactNode }[]
    onChangeDispatch: Dispatch<any>
}

const ContactFormsPivot: React.FC<TProps> = (props: TProps) => {

    const contactSettingsEnabled = props.config?.contactForms?.contactForm?.enabled ? true:false;
    const ranSettingsEnabled = props.config?.contactForms?.ranForm?.enabled ? true:false
    const [documentsState, setDocumentsState] = React.useState<{ fetched: boolean, errors?: IResponseError[] }>({ fetched: false })
    const [ranTemplates, setRanTemplates] = React.useState<IDropdownOption[] | undefined>()
    const [contactTemplates, setContactTemplates] = React.useState<IDropdownOption[] | undefined>()
    //  TODO: help for self service 
    //  Done. K. Lebedev
    const ranHelpId = '/docs/default.htm#cshid=1017'
    const contactHelpId = '/docs/default.htm#cshid=1017'

    const getDocuments = async () => {
        setDocumentsState({ fetched: false })
        const result = await portalApi.organization.documents.getDocuments()
        
        const filtered = result.data?.filter(i => !i.removed)
        if( filtered && filtered.length > 0) {
            const ranOptions = [{ key: 'null', text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.RAN_TEMPLATE_PLACEHOLDER }, ...filtered.map(i => {
                return { key: i.id, text: i.name } as IComboBoxOption
            })]
            const contactOptions = [{ key: 'null', text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.CONTACT_TEMPLATE_PLACEHOLDER }, ...filtered.map(i => {
                return { key: i.id, text: i.name } as IComboBoxOption
            })]
            setRanTemplates( ranOptions)
            setContactTemplates( contactOptions)
        }
        else {
            setRanTemplates( undefined)
            setContactTemplates( undefined)
        }
        setDocumentsState({ errors: (result.errors && result.errors.length > 0) ? result.errors : undefined, fetched: true })
    }

    const toggleContactForm = () => {
        const tmp = {...props.config}
        if( !tmp.contactForms) {
            tmp.contactForms = {contactForm: contactEnabled}
        }
        else {
            tmp.contactForms = {...tmp.contactForms}
            if( !tmp.contactForms.contactForm) {
                tmp.contactForms.contactForm = contactEnabled
            }
            else {
                tmp.contactForms.contactForm = {...tmp.contactForms.contactForm, enabled: !tmp.contactForms.contactForm.enabled}
            }
        }
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: tmp })
    }

    const toggleRanForm = () => {
        const tmp = {...props.config}
        if( !tmp.contactForms) {
            tmp.contactForms = {ranForm: ranEnabled}
        }
        else {
            tmp.contactForms = {...tmp.contactForms}
            if( !tmp.contactForms.ranForm) {
                tmp.contactForms.ranForm = ranEnabled
            }
            else {
                tmp.contactForms.ranForm = {...tmp.contactForms.ranForm, enabled: !tmp.contactForms.ranForm.enabled}
            }
        }
        props.onChangeDispatch( { type: 'CONFIGURATOR_SELF_SERVICE_UPDATE', selfService: tmp })
    }

    React.useEffect( () => {
        if( !documentsState.fetched) {
            getDocuments();
        }
    }, [])

    return (<Stack.Item>
        <ParameterItem 
        title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.CONTACT_FORM_TITLE}
        description={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.CONTACT_FORM_DESCRIPTION}
        useToggle
        toggleEnabled
        toggleChecked={contactSettingsEnabled}
        toggleOnChange={toggleContactForm}
        useDescriptionLink
        descriptionlinkText={strings.ORGANIZATION.OPTIONS.LEARN_MORE}
        descriptionlinkHref={contactHelpId}
        >
            {documentsState.errors && (
                documentsState.errors.map(e => <MessageBar messageBarType={MessageBarType.error} >{e.description}</MessageBar>)
            )}
            {contactSettingsEnabled && (<ContactFormSettings 
                config={props.config} 
                onChangeDispatch={props.onChangeDispatch}
                isFetchingTemplates={!documentsState.fetched}
                documentTemplates={contactTemplates}
            />)}
        </ParameterItem>

        <ParameterItem 
        title={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.RAN_FORM_TITLE}
        description={strings.CONSTRUCTOR.STAGES.SELF_SERVICE.CONTACT_FORMS.RAN_FORM_DESCRIPTION}
        useToggle
        toggleEnabled
        toggleChecked={ranSettingsEnabled}
        toggleOnChange={toggleRanForm}
        useDescriptionLink
        descriptionlinkText={strings.ORGANIZATION.OPTIONS.LEARN_MORE}
        descriptionlinkHref={ranHelpId}
        >
            {documentsState.errors && (
                documentsState.errors.map(e => <MessageBar messageBarType={MessageBarType.error} >{e.description}</MessageBar>)
            )}
            {ranSettingsEnabled && (<RanFormSettings 
                config={props.config} 
                onChangeDispatch={props.onChangeDispatch}
                isFetchingTemplates={!documentsState.fetched}
                documentTemplates={ranTemplates}
            />)}
        </ParameterItem>

    </Stack.Item>)
}

export default ContactFormsPivot;