import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { IContractConfig } from "../../../../../core/store";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";

type TProps = {
    config: IContractConfig
    onChangeDispatch: Dispatch<any>
}

export const ContractCommonUIChildren: React.FC<TProps> = ({ onChangeDispatch, config }) => {
    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.CONTRACT.PIVOTS.UI.EDIT_TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={config.ui?.edit !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.contract.onConfigCommit({
                        ...config,
                        ui: {
                            ...config.ui,
                            edit: config.ui?.edit !== undefined ? undefined : ""
                        }
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.CONTRACT.PIVOTS.UI.EDIT_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.edit !== undefined ? true : false}
                textFieldValue={config.ui?.edit}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.contract.onConfigCommit({
                    ...config,
                    ui: {
                        ...config.ui,
                        edit: val
                    }
                }
                ))
                }
            />
        </Stack>
    )
}