import { AppThunkAction } from "..";
import { strings } from "../../localization/strings";
import { OnlineLanguage, getOnlineLanguage, strings as coreStrings } from '@piceasoft/core'
import { stringsLogin } from "../../screens/login/index.strings";
import { endpoints } from "../api/endpoints";
import { consoleStyles } from "../scripts/console";
import { IEnvironment } from "../store";
import { ILogin } from "@piceasoft/core";

export interface InitializationRequestAction { type: 'ENVIRONMENT_INITIALIZATION_REQUEST' }
export interface InitializationIsReadyAction { type: 'ENVIRONMENT_INITIALIZATION_IS_READY', configuration: IEnvironment }

export type KnownAction =
    InitializationRequestAction |
    InitializationIsReadyAction 

export const actionCreators = {
    requestSignIn: (login: ILogin, callback: (message: string) => void): AppThunkAction<KnownAction | AppThunkAction<KnownAction>> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestSignIn()", consoleStyles.logic);
        fetch(endpoints.v1.requestSignIn, { method: "POST", body: JSON.stringify(login), headers: { "Content-Type": "application/json" } })
            .then(response => response.json() as Promise<{ authorize: boolean }>)
            .then(data => {
                if (data.authorize === true) {
                    const lang = getOnlineLanguage(getState().environment.lang)
                    let params = new URLSearchParams();
                    if (lang && lang.trim().length > 0) {
                        params.append('lang', lang)
                    }
                    window.location.href = lang ?  "/?" + params.toString() : "/";
                } else {
                    callback(stringsLogin.LOGIN_FAILED);
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    requestSignOut: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestSignOut()", consoleStyles.logic);
        fetch(endpoints.v1.requestSignOut, { method: "POST" })
            .then(response => {
                console.log(response);
                window.location.href = "/";
            })
            .catch(error => {
                console.log(error);
            });
    },

    initializationRequest: (lang?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC initializationRequest()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isReady === false) {
            dispatch({ type: 'ENVIRONMENT_INITIALIZATION_REQUEST' });
            fetch(endpoints.v1.getEnvironmentConfiguration(lang))
                .then(response => response.json() as Promise<IEnvironment>)
                .then(data => {
                    const onlineLang = getOnlineLanguage(data.lang)
                    strings.setLanguage(onlineLang);
                    coreStrings.setLanguage(onlineLang);
                    dispatch({ type: 'ENVIRONMENT_INITIALIZATION_IS_READY', configuration: data });
                })
                .catch(response => {
                    console.log(response);
                });
        }
    },

    languageReceive: (language: OnlineLanguage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC languageReceive()", consoleStyles.logic);
        const appState = getState();
        if (appState.environment) {
            console.log(language)
            dispatch({ type: 'ENVIRONMENT_INITIALIZATION_IS_READY', configuration: { ...appState.environment, lang: language } });
        }
    }
};
