import { Dispatch } from "react"
import { useSelector } from "react-redux"
import { IStore } from "../../../../../../../core/store"
import { SkipPivot } from "../../../common/pivots/SkipPivot"
import { TInspectionSettingsPivotStates } from "../../InspectionModule"
import { DependencyPivot } from "../../../common/pivots/DependencyPivot"
import { DataCollectiontUISettingsPivot } from "./DataCollectionUISettingsPivot"
import { ShowConfigurationPivot } from "../../../common/pivots/ShowConfigurationPivot"
import { InspectionModeCommonSettingsPivot } from "../../settings/mode/InspectionModeCommonSettingsPivot"
import { getStageInspectionModules } from "../../../../../../../core/actions/configurator-actions"
import { ProcessStages } from "@piceasoft/core"
type TProps = {
    state: TInspectionSettingsPivotStates
    stage: ProcessStages
    index: number
    onChangeDispatch: Dispatch<any>
    onClose: () => void
    commonSettingsPivotChildren?: React.ReactNode
}

export const DataCollectionSettingsPivotBasedState: React.FC<TProps> = (props) => {
    switch (props.state) {
        case "ui": return (
            <DataCollectiontUISettingsPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "common": return (
            <InspectionModeCommonSettingsPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch} withoutGrade >
                {props.commonSettingsPivotChildren}
            </InspectionModeCommonSettingsPivot>
        )
        case "dependency_inspections": return (
            <DependencyPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch} />
        )
        case "skip": return (
            <SkipPivot stage={props.stage} index={props.index} onChangeDispatch={props.onChangeDispatch} onClose={props.onClose} />
        )
        case "config": return (
            <ShowConfigurationPivot json={useSelector((s: IStore) => getStageInspectionModules(props.stage, s)?.find(i => i.index === props.index)) ?? {}} />
        )
        default:
            return (
                <></>
            )
    }
}
