import { Stack, Pivot, PivotItem, Sticky } from '@fluentui/react';
import * as React from 'react';
import { portalApi } from '../../../../../core/api/api';
import { IPoint } from '../../../../../core/store';
import { strings } from '../../../../../localization/strings';
import { PointHeader } from './PointHeader';
import { PointPivotBasedState } from './PointPivotBasedState';

export type TPointPivotState = "information" | "services" | "access" | "settings"

const getTabId = (itemKey: string) => {
    // console.log(itemKey)
    return `ShapeColorPivot_${itemKey}`;
};

export type TProps = {
    point: IPoint
    onEdit: () => void
    onDelete: () => void
    updateState: (item: IPoint) => void
}

export const StructurePoint: React.FC<TProps> = ({ point, onDelete, onEdit, updateState }) => {

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TPointPivotState>("information");
    const [item, setItem] = React.useState(point);

    React.useEffect(() => {
        setItem(point)
    }, [point])

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TPointPivotState);
    };

    const toggleUsePointServiceState = async (pointId: string) => {
        const apiResult = await portalApi.organization.services.toggleUsePointServices(pointId)
        if (apiResult.successed) {
            setItem({ ...item, turnOnServices: !item.turnOnServices })
        }
    }

    const toggleEnabled = async () => {
        if (point.id) {
            const apiResult = await portalApi.organization.points.toggleEnabledPoint(point.id)
            if (apiResult.successed && apiResult.data) {
                setItem(apiResult.data)
                updateState(apiResult.data)
            }
        }
    }

    return (
        <Stack verticalFill>
            <PointHeader point={point} onEdit={onEdit} />
            <Stack verticalFill>
                <Sticky>
                    <Pivot style={{ padding: "0px 32px" }} aria-label="Point details" selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} >
                        <PivotItem itemKey='information' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.POINT_CARD} />
                        {/* <PivotItem itemKey='access' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.ACCESS} /> */}
                        <PivotItem itemKey='settings' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.SETTINGS} />
                        <PivotItem itemKey='services' headerText={strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.SERVICES} />
                    </Pivot>
                </Sticky>
                <Stack.Item grow verticalFill>
                    <PointPivotBasedState
                        state={selectedPivotKey}
                        point={item}
                        onDelete={onDelete}
                        updateState={updateState}
                        toggleEnabled={toggleEnabled}
                        toggleUseServices={() => point.id && toggleUsePointServiceState(point.id)}
                    />
                </Stack.Item>
            </Stack>

        </Stack>
    )
}
