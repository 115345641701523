import { AnimationStyles, DefaultButton, DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint, getTheme, IButtonStyles, IColumn, Icon, IconButton, IContextualMenuProps, IDetailsColumnProps, IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles, IRenderFunction, IStackStyles, Link, MessageBar, MessageBarType, ScrollablePane, SelectionMode, SharedColors, Spinner, Stack, Sticky, StickyPositionType, Text } from "@fluentui/react"
import React from "react"
import { ScreenHeader } from "../../../../components/shared/decorations/ScreenHeader"
import { Section } from "../../../../components/shared/decorations/Section"
import { onRenderColumnHeaderCommon } from "../../../../components/shared/detailsList/commonRenders"
import { SimpleCustomFilter } from "../../../../components/shared/filter/SimpleCustomFilter"
import { ItemsNotFound } from "../../../../components/shared/notFound/ItemsNotFound"
import { SomethingWentWrong } from "../../../../components/shared/somethingWentWrong/SomethingWentWrong"
import { getFormatedPhoneNumber } from "../../../../core/helpers/localization"
import { IResponsiblePerson } from "../../../../core/store/typings/IResponsiblePerson"
import { ResponsibleAreas } from "../../../../core/store/typings/ResponsibleAreas"
import { strings } from "../../../../localization/strings"
import { TResponsiblePersonState } from "../../ResponsiblePersons"

export type TResponsiblePersonsPivotProps = {
    area: ResponsibleAreas
    title: string
    items: IResponsiblePerson[]
    onEditPerson: (item: IResponsiblePerson) => void
    onAddPerson: () => void
    setDeletePersonState: (item: IResponsiblePerson) => void
    requestResponsiblePersons: () => void
    setSearchString: (text?: string) => void
    personsState: TResponsiblePersonState
    information?: string
    href?: string
    hrefInformation?: string
}

export const ResponsiblePersonsPivot: React.FC<TResponsiblePersonsPivotProps> = (props) => {

    const getItemMenuProps = (item: IResponsiblePerson): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? props.onEditPerson(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? props.setDeletePersonState(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }

        return (
            <Stack>
                <Stack.Item>
                    <Stack tokens={{ padding: "16px 32px 4px 20px", childrenGap: 4 }} horizontal>
                        <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                        <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                            <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{props.items.length}</Text>
                        </Stack>
                    </Stack>
                </Stack.Item>
                {defaultRender!({ ...hProps })}
            </Stack>
        );
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.RESPONSIBLE_PERSONS.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IResponsiblePerson) => onRenderNamePersonCell(item),
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.RESPONSIBLE_PERSONS.TABLE_COLUMNS.PHONE_NUMBER,
            fieldName: 'phone',
            minWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IResponsiblePerson) => <Stack verticalFill verticalAlign="center"><Text>{getFormatedPhoneNumber(item.phone)}</Text></Stack>,
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.RESPONSIBLE_PERSONS.TABLE_COLUMNS.TELEGRAM_CHANNEL,
            fieldName: 'telegramChannel',
            minWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IResponsiblePerson) => <Stack verticalFill verticalAlign="center"><Text>{item.telegramChannel}</Text></Stack>,
            isPadded: true,
        },
        {
            key: 'column4',
            name: strings.ORGANIZATION.RESPONSIBLE_PERSONS.TABLE_COLUMNS.TELEGRAM_USER_ID,
            fieldName: 'telegramUserId',
            minWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IResponsiblePerson) => <Stack verticalFill verticalAlign="center"><Text>{item.telegramUser}</Text></Stack>,
            isPadded: true,
        },
        {
            key: 'column5',
            name: '',
            fieldName: 'commandBar',
            minWidth: 40,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IResponsiblePerson) => (
                <Stack horizontal verticalFill verticalAlign='center'>
                    <Stack.Item>
                        <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item)} />
                    </Stack.Item>
                </Stack>
            )
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => { props.onEditPerson(rProps.item) }
                // history.push(`/organization/company/person/${props.item['id']}`)
            }><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    }

    const onRenderNamePersonCell = (item: IResponsiblePerson) => {
        return (
            <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }}>
                <Stack.Item>
                    <Icon iconName={"Telemarketer"} style={{ fontSize: 32 }} />
                </Stack.Item>
                <Stack.Item grow>
                    <Stack>
                        <Stack.Item>
                            <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="small" style={{ color: SharedColors.gray30 }}>{item.email}</Text>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        )
    }

    return (
        <Stack verticalFill>
            <Stack.Item verticalFill>
                <Stack verticalFill>
                    {!props.personsState.error && (props.personsState.fetched && props.personsState.data && (
                        props.personsState.data.length > 0 && (
                            <>
                                <ScreenHeader title={props.title}>
                                    <SimpleCustomFilter onChange={(ev, value) => props.setSearchString(value)} />
                                </ScreenHeader>
                                <Stack verticalFill style={{ position: 'relative' }}>
                                    <ScrollablePane>
                                        <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                            <Section max>
                                                <DetailsList
                                                    styles={{ root: { width: '100%' } }}
                                                    items={props.items}
                                                    columns={columns}
                                                    setKey="none"
                                                    selectionMode={SelectionMode.none}
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                                    isHeaderVisible={true}
                                                    onRenderRow={onRenderRow}
                                                />
                                            </Section>
                                        </Stack.Item>
                                        <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                            <Text style={{ color: SharedColors.gray20 }}>{props.information}</Text>
                                            <br />
                                            <Link href={props.href}>{props.hrefInformation}</Link>
                                        </Stack.Item>
                                    </ScrollablePane>
                                </Stack>
                            </>
                        ) || (
                            <ItemsNotFound
                                onClick={props.onAddPerson}
                                info={strings.ORGANIZATION.RESPONSIBLE_PERSONS.NOT_FOUND_CASE.INFO}
                                suggestion={strings.ORGANIZATION.RESPONSIBLE_PERSONS.NOT_FOUND_CASE.SUGGESTION}
                                buttonText={strings.ORGANIZATION.RESPONSIBLE_PERSONS.NOT_FOUND_CASE.ADD_BUTTON}
                                imgSrc={"images/navigation/images/not_found.png"} />
                        )) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        ))}
                    {props.personsState.error && (
                        <Stack tokens={{padding: "24px 0 0 0"}} verticalFill>
                            {props.personsState.error.length > 0 && (
                                <MessageBar messageBarType={MessageBarType.error}>{props.personsState.error}</MessageBar>
                            )}
                            <Stack.Item verticalFill>
                                <SomethingWentWrong action={props.requestResponsiblePersons} />
                            </Stack.Item>
                        </Stack>
                    )}
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4,
        ...AnimationStyles.slideUpIn10,
        overflow: 'hidden',
        position: "relative"
    }
};

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}



