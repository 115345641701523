import { getTheme, IconButton, IStackStyles, Stack, Text } from "@fluentui/react"
import { SharedColors } from '@fluentui/theme'

type TProps = {
    uid: string
    type: string
    title?: string
    text: string
    onClose: (uid: string) => void
}

export const AlertMessage: React.FC<TProps> = (props) => {
    let messageColor
    switch (props.type) {
        case "warning":
            messageColor = SharedColors.yellow10
            break
        case "error":
            messageColor = SharedColors.red10
            break
        case "critical":
            messageColor = SharedColors.magentaPink20
            break
        case "information":
            messageColor = SharedColors.cyanBlue10
            break
        default:
            messageColor = SharedColors.greenCyan10
    }

    const containerStyle: IStackStyles = {
        root: {
            marginLeft: 2,
            marginBottom: 2,
            marginTop: 2,
            borderLeftStyle: "solid",
            borderLeftWidth: 8,
            borderLeftColor: messageColor,
            backgroundColor: theme.palette.neutralLighter,
            width: 350,
            borderTopRightRadius: theme.effects.roundedCorner4,
            borderBottomRightRadius: theme.effects.roundedCorner4,
            boxShadow: theme.effects.elevation8,
            selectors: {
                '&:hover': {
                    boxShadow: theme.effects.elevation64
                }
            },
            transition: '0.5s'
        }
    }
    const iconButtonStyles = {
        root: {
            display: 'flex',
            flexFlow: 'column nowrap',
            width: 'auto',
            height: '100%',
            boxSizing: 'border-box',
            color: theme.palette.neutralPrimary,
            paddingTop: 6,
            paddingBottom: 6,
            transition: '0.5s'
        },
        rootHovered: {
            color: theme.palette.neutralDark,
            backgroundColor: theme.palette.neutralQuaternary,
        },
    };

    return (
        <Stack verticalFill styles={containerStyle} >
            <Stack grow horizontal tokens={{ padding: 4, childrenGap: 4 }} style={{ borderBottom: `1px solid ${theme.palette.neutralQuaternary}` }}>
                <Stack verticalAlign="center" grow>
                    {props.title && <Text nowrap style={{ paddingLeft: 4, maxWidth: 250 }} block variant="medium"><b><u>{props.title}</u></b></Text>}
                </Stack>
                <Stack.Item verticalFill>
                    <IconButton styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={() => props.onClose(props.uid)} />
                </Stack.Item>
            </Stack>
            <Stack.Item style={{ backgroundColor: theme.palette.neutralLight }} tokens={{ padding: 8 }} grow verticalFill>
                <Text style={{ maxWidth: 270 }} variant="medium">{props.text}</Text>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
