import { Stack } from "@fluentui/react"
import { IStore } from "../../../../../../core/store"
import { IStageConfig, IStageConfigUI } from "@piceasoft/core";
import { strings } from "../../../../../../localization/strings"
import { StageConfirmationPreview } from "../../preview/StageConfirmationPreview"

type TProps = {
    config: IStageConfig<IStageConfigUI>
}


export const ConfirmationPreview: React.FC<TProps> = ({ config }) => {

    return (
        <StageConfirmationPreview config={config}
            defaultConfirmText={strings.BUTTONS.TEXT.CONFIRM}
            defaultCancelText={strings.BUTTONS.TEXT.CANCEL}
            defaultheader={strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONFIRMATION}
        >
            <Stack tokens={{ childrenGap: 32 }}>
                <Stack.Item>
                    <div dangerouslySetInnerHTML={{ __html: config.ui?.confirmation?.information ?? strings.CONSTRUCTOR.STAGES.COMMON.TRANSACTION.CONFIRM_STAGE_FINISH }}></div>
                </Stack.Item>
            </Stack>
        </StageConfirmationPreview>
    )
}