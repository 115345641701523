import { PrimaryButton, Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { useForm } from "react-hook-form";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../localization/strings";
import { SecondaryButton } from "../../../buttons/SecondaryButton";
import { ItemsNotFound } from "../../../notFound/ItemsNotFound";
import { ResourcesArray } from "./ResourcesArray";
import { CheckProcessStageAccess } from "../../helpers/evisibility";
import { ProcessStages, IResourcesConfig } from "@piceasoft/core";

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: IResourcesConfig
    channel: number
}

export const ResourcesConfigurator: React.FC<TProps> = ({ onChangeDispatch, config, channel }) => {

    const { control, handleSubmit, getValues, watch, formState: { errors }, reset, setValue } = useForm({ defaultValues: config });

    const onSubmit = (data: IResourcesConfig) => {
        onChangeDispatch(actionCreators.resources.onConfigCommit(data))
    }
    let disabled = false;
    if (!CheckProcessStageAccess(channel,ProcessStages.Resources)) {
        disabled = true;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            {getValues().items.length > 0 && (
                <Stack verticalFill tokens={{ childrenGap: 16, padding: "16px 0px 0px 0px" }}>
                    <Stack.Item tokens={{ padding: "0px 24px" }}>
                        <Stack horizontal grow>
                            <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                                <SecondaryButton iconProps={{ iconName: "Undo" }} onClick={() => reset(config)} />
                                <PrimaryButton disabled={disabled} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                    <Stack verticalFill>
                        <ResourcesArray {...{ control, defaultValues: config, getValues, setValue, errors }} />
                    </Stack>
                </Stack>
            ) || (
                    <ItemsNotFound
                        onClick={() => reset({
                            ...config,
                            items: [{}]
                        })}
                        buttonText={strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.NOT_FOUND_CASE.BUTTON_TEXT}
                        info={strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.NOT_FOUND_CASE.INFO}
                        suggestion={strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.NOT_FOUND_CASE.SUGGESTION}
                        imgSrc={"images/navigation/images/not_found.png"} />
                )}
        </form>
    )
}