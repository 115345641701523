import { getTheme, IconButton, Stack, Text } from "@fluentui/react"
import { Dispatch } from "react";
import { actionCreators } from "../../../../core/actions/configurator-actions";
import { IResourcesConfig } from "@piceasoft/core";
import { strings } from "../../../../localization/strings";
import { StageBox } from "../components/StageBox";

type TProps = {
    config: IResourcesConfig
    onChangeDispatch: Dispatch<any>
    showSettings: () => void
}

export const Resources: React.FC<TProps> = (props) => {

    const onResourceDelete = (resourceIndex: number) => {
        props.onChangeDispatch(actionCreators.resources.removeResource(resourceIndex))
    }

    return (
        <StageBox title={props.config.ui?.stageTitle ?? strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.HEADER} settingsButtonProps={{ onClick: props.showSettings }}>
            <Stack tokens={{ childrenGap: 16 }}>
                {props.config.items.length > 0 && (
                    props.config.items.map((r, index) => <Resource key={index} name={r.name} source={r.source} onDelete={() => onResourceDelete(index)} />)
                ) || (
                        <Stack grow horizontalAlign="center">
                            <Text variant="small">{strings.CONSTRUCTOR.STAGES.RESOURCES_CHECK.NO_RESOURCES}</Text>
                        </Stack>
                    )}
            </Stack>
        </StageBox>
    )
}

type TResourceProps = {
    name: string
    source: string
    onDelete: () => void
}

export const Resource: React.FC<TResourceProps> = (props) => {
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item>
                <Stack verticalFill verticalAlign="center">
                    <IconButton iconProps={{ iconName: "Delete" }} onClick={props.onDelete} styles={{ icon: { fontSize: 12 } }} />
                </Stack>
            </Stack.Item>
            <Stack.Item grow styles={{ root: { paddingRight: 18 } }}>
                <Stack styles={{
                    root: {
                        color: theme.palette.black,
                        boxShadow: theme.effects.elevation16,
                        position: "relative",
                    }
                }}>
                    <Stack styles={{
                        root: {
                            padding: "12px 16px",
                            color: theme.palette.black,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: theme.palette.neutralQuaternaryAlt,
                            backgroundColor: theme.palette.white,
                            position: "relative",
                            ":hover": {
                                cursor: "pointer",
                                borderColor: theme.palette.themePrimary,
                                backgroundColor: theme.palette.themeLighterAlt
                            }
                        }
                    }}>
                        <Stack.Item>
                            <Stack verticalAlign="center" tokens={{ childrenGap: 4 }}>
                                <Text variant={"small"} styles={{ root: { color: theme.palette.black, fontWeight: 400 } }}>{props.name.substring(0, 40)}{props.name.length > 40 ? "..." : ""}</Text>
                                <Text variant={"small"} styles={{ root: { color: theme.palette.themePrimary, fontWeight: 400 } }}>{props.source.substring(0, 40)}{props.source.length > 40 ? "..." : ""}</Text>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    {/* <Stack verticalFill verticalAlign="center" styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0, zIndex: 100 } }}>
                        <IconButton iconProps={{ iconName: "Repair" }} styles={{
                            root: {
                                width: 36,
                                height: 36,
                                backgroundColor: theme.palette.white,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: theme.palette.neutralQuaternaryAlt,
                                borderRadius: "50%",
                                color: theme.palette.black,
                                boxShadow: theme.effects.elevation8,
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            },
                            flexContainer: {
                                borderRadius: "50%",
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            }
                        }} />
                    </Stack> */}
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
