import { Stack, IStackStyles, Text, getTheme } from "@fluentui/react"
import { IDocument } from "../../../core/store"
import { ServicesPrintTemplateConfigurationPivot } from "./pivots/ServicesPrintTemplateConfigurationPivot"
import { ServicesPrintTemplateInfoPivot } from "./pivots/ServicesPrintTemplateInfoPivot"
import { TServicePrintTemplatePivotState } from "./ServicesPrintTemplateDetails"

export type TServicesPrintTemplatePivotBasedStateProps = {
    state: TServicePrintTemplatePivotState
    data: IDocument
    onDelete: () => void
    onRetry: () => void
}

export const ServicesPrintTemplatePivotBasedState: React.FC<TServicesPrintTemplatePivotBasedStateProps> = (props) => {
    switch (props.state) {
        case "configuration": return (
            <ServicesPrintTemplateConfigurationPivot data={props.data} onRetry={props.onRetry} />
        )
        case "information": return (
            <ServicesPrintTemplateInfoPivot data={props.data} onDelete={props.onDelete} />
        )
    }
}

const theme = getTheme();

const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4
    }
};