import { getTheme, Link, MessageBar, MessageBarType, Separator, SharedColors, Stack, Text, Toggle } from "@fluentui/react"
import * as React from "react"
import { CustomSeparator } from "../../../../../../components/shared/decorations/CustomSeparator"
import { portalApi } from "../../../../../../core/api/api"
import { IContainer } from "../../../../../../core/store"
import { IApplicationOptions } from "@piceasoft/core";
import { strings } from "../../../../../../localization/strings"
import { StructureElementSettings } from "../../common/StructureElementSettings"
import { CSHHelpLink } from "../../../../../../components/shared/help/CSHHelp"

type TProps = {
    item: IContainer
    updateState: (item: IContainer) => void
}

export const ContainerSettingsPivot: React.FC<TProps> = ({ item, updateState }) => {

    const [error, setError] = React.useState<string>()
    const [isFetching, setIsFetching] = React.useState(false)

    console.log(item.options)
    const onToggleTurnOnOptions = async () => {
        if (item.id) {
            setIsFetching(true)
            const result = await portalApi.organization.containers.toggleTurnOnOptions(item.id)
            console.log(result)
            setTimeout(() => {
            if (result.successed) {
                updateState({ ...item, turnOnOptions: !item.turnOnOptions })
            }
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
                setIsFetching(false)
            },500)
        }
    }

    const onUpdateOptions = async (options: IApplicationOptions) => {
        if (item.id) {
            setIsFetching(true)
            const result = await portalApi.organization.containers.updateOptions(item.id, options)
            console.log(result)
            setTimeout(() => {
            if (result.successed) {
                updateState({ ...item, options: options })
            }
            if (result.errors && result.errors.length > 0) {
                setError(result.errors[0].description)
            }
                setIsFetching(false)
            },500)
        }
    }

    return (
        <Stack tokens={{ padding: "24px 32px 24px 32px", childrenGap: 16 }}>
            {error &&
                <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
            }
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    {item.turnOnOptions !== undefined && (
                        <Stack>
                            <Stack horizontal verticalAlign='center'>
                                <Text style={{ color: theme.palette.black, fontWeight: 600, fontSize: "1.3125rem", letterSpacing: "-.02em", lineHeight: "24px", width: 600 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.SETTINGS_PIVOT.TITLE}</Text>
                                <Toggle checked={item.turnOnOptions} onText={strings.BUTTONS.TOGGLE_ENABLE} offText={strings.BUTTONS.TOGGLE_DISABLE} onChange={onToggleTurnOnOptions} />
                            </Stack>
                            <Text></Text>
                        </Stack>
                    )}
                    <CustomSeparator />
                </Stack.Item>
                {item.options && (
                    <StructureElementSettings isFetching={isFetching} disabled={!item.turnOnOptions || isFetching} settingsOptions={item.options} updateOptions={onUpdateOptions} />
                )}
            </Stack>
            <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
            <Stack.Item style={{ paddingBottom: 24 }}>
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.SETTINGS}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.PIVOTS.SETTINGS.INFORMATION}</Text>
                        <br />
                        <CSHHelpLink  articleid="1097" alignment='left' label={strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.PIVOTS.SETTINGS.LINK}/>
                    </Stack.Item>
                </Stack>
                <Text style={{ color: SharedColors.gray20 }}></Text>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
