import { Separator, Stack, Text, TextField, PrimaryButton, ComboBox, IComboBoxOption, TooltipHost, TooltipOverflowMode, css, mergeStyleSets } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import IdentificationHeader from './ui/IdentificationHeader';
import { strings } from '../../../../../../localization/strings';
import { IIdentificationScannerConfig } from '@piceasoft/core';
import { identificationContainerStyle, hasBlockLevelElements, blockOffset } from '../helpers/identificationContainer';

type TProps = {
    config?: IIdentificationScannerConfig
}

export const Scanner: React.FC<TProps> = (props) => {
    const isLabelType = props.config?.type === 'label';
    return (
        <Stack horizontalAlign="stretch" verticalFill>
            <Stack.Item>
                <IdentificationHeader
                    icon={props.config?.ui?.icon ?? "DelveAnalyticsLogo"}
                    title={props.config?.ui?.title ?? strings.IDENTIFICATION.TEXT_SCANNER}
                />
            </Stack.Item>
            <Separator />
            <Stack verticalFill tokens={{ childrenGap: 16 }}>
                <Stack.Item verticalFill>
                    <TooltipHost
                    overflowMode={TooltipOverflowMode.Self}
                    content={props.config?.ui?.information ?? strings.CONSTRUCTOR.IDENTIFICATION.PREVIEW.PICEAONE_DEVICE_CONNECT_INFORMATION}
                    hostClassName={css(identificationContainerStyle().body)}
                    styles={{ root: {display: 'block'}}}>
                        <Text style={{ color: SharedColors.gray40 }}>
                            <div 
                                style={hasBlockLevelElements(props.config?.ui?.information) ? {marginTop: `${blockOffset}`} : {marginTop: '0px'}}
                                dangerouslySetInnerHTML={{ __html: props.config?.ui?.information ?? (isLabelType ? strings.IDENTIFICATION.SCANNER_INFORMATION_LABEL : strings.IDENTIFICATION.SCANNER_INFORMATION)}}>
                            </div>
                        </Text>
                    </TooltipHost>
                </Stack.Item>
                <Stack.Item grow>
                    <Stack horizontalAlign="stretch">
                        <PrimaryButton style={{ overflow:"hidden", whiteSpace: "nowrap" }} text={props.config?.ui?.buttonText ?? strings.BUTTONS.TEXT.EXECUTE} />
                    </Stack>
                </Stack.Item>
            </Stack>
        </Stack>
    );
}
