import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../../../core/store";
import { strings } from "../../../../../../../localization/strings";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationUniversalConfig } from "@piceasoft/core";
import { IIdentificationUniversalConfigUI } from "@piceasoft/core";
import { IdentificationModuleUISettingsPivot } from "../IdentificationModuleUISettingsPivot";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const UniversalUISettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationUniversalConfig)

    return (
        <IdentificationModuleUISettingsPivot index={props.index} onChangeDispatch={props.onChangeDispatch}>
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.GROUP_LABEL_TITLE}
                useToggle
                toggleEnabled={config.useGroup ? true : false}
                toggleChecked={config.ui?.group?.label !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            group : {
                                ...config.ui?.group,
                                label: config.ui?.group?.label !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.GROUP_LABEL_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.group?.label !== undefined ? true : false}
                textFieldValue={config.ui?.group?.label}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        group: {
                            ...config.ui?.group,
                            label: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.GROUP_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled={config.useGroup ? true : false}
                toggleChecked={config.ui?.group?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            group : {
                                ...config.ui?.group,
                                placeholder: config.ui?.group?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.GROUP_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.group?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.group?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        group: {
                            ...config.ui?.group,
                            placeholder: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.MANUFACTURER_LABEL_TITLE}
                useToggle
                toggleEnabled={config.useManufacturer ? true : false}
                toggleChecked={config.ui?.manufacturer?.label !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            manufacturer: {
                                ...config.ui?.manufacturer,
                                label: config.ui?.manufacturer?.label !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.MANUFACTURER_LABEL_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.manufacturer?.label !== undefined ? true : false}
                textFieldValue={config.ui?.manufacturer?.label}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        manufacturer: {
                            ...config.ui?.manufacturer,
                            label: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.MANUFACTURER_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled={config.useManufacturer ? true : false}
                toggleChecked={config.ui?.manufacturer?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            manufacturer: {
                                ...config.ui?.manufacturer,
                                placeholder: config.ui?.manufacturer?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.MANUFACTURER_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.manufacturer?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.manufacturer?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        manufacturer: {
                            ...config.ui?.manufacturer,
                            placeholder: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.MODEL_LABEL_TITLE}
                useToggle
                toggleEnabled={config.useModel ? true : false}
                toggleChecked={config.ui?.name?.label !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            name: {
                                ...config.ui?.name,
                                label: config.ui?.name?.label !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.MODEL_LABEL_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.name?.label !== undefined ? true : false}
                textFieldValue={config.ui?.name?.label}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        name: {
                            ...config.ui?.name,
                            label: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.MODEL_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled={config.useModel ? true : false}
                toggleChecked={config.ui?.name?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            name: {
                                ...config.ui?.name,
                                placeholder: config.ui?.name?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.MODEL_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.name?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.name?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        name: {
                            ...config.ui?.name,
                            placeholder: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.SERIAL_LABEL_TITLE}
                useToggle
                toggleEnabled={config.useSerial ? true : false}
                toggleChecked={config.ui?.serial?.label !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            serial: {
                                ...config.ui?.serial,
                                label: config.ui?.serial?.label !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.SERIAL_LABEL_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.serial?.label !== undefined ? true : false}
                textFieldValue={config.ui?.serial?.label}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        serial: {
                            ...config.ui?.serial,
                            label: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.SERIAL_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled={config.useSerial ? true : false}
                toggleChecked={config.ui?.serial?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            serial: {
                                ...config.ui?.serial,
                                placeholder: config.ui?.serial?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.SERIAL_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.serial?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.serial?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        serial: {
                            ...config.ui?.serial,
                            placeholder: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.IMEI_LABEL_TITLE}
                useToggle
                toggleEnabled={config.useImei ? true : false}
                toggleChecked={config.ui?.imei?.label !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            imei: {
                                ...config.ui?.imei,
                                label: config.ui?.imei?.label !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.IMEI_LABEL_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.imei?.label !== undefined ? true : false}
                textFieldValue={config.ui?.imei?.label}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        imei: {
                            ...config.ui?.imei,
                            label: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.IMEI_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled={config.useImei ? true : false}
                toggleChecked={config.ui?.imei?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            imei: {
                                ...config.ui?.imei,
                                placeholder: config.ui?.imei?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.IMEI_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.imei?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.imei?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        imei: {
                            ...config.ui?.imei,
                            placeholder: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.CONFIGURATION_LABEL_TITLE}
                useToggle
                toggleEnabled={config.useConfiguration ? true : false}
                toggleChecked={config.ui?.configuration?.label !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            configuration: {
                                ...config.ui?.configuration,
                                label: config.ui?.configuration?.label !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.CONFIGURATION_LABEL_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.configuration?.label !== undefined ? true : false}
                textFieldValue={config.ui?.configuration?.label}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        configuration: {
                            ...config.ui?.configuration,
                            label: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.CONFIGURATION_PLACEHOLDER_TITLE}
                useToggle
                toggleEnabled={config.useConfiguration ? true : false}
                toggleChecked={config.ui?.configuration?.placeholder !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.identification.editModule({
                        ...config,
                        ui: {
                            ...config.ui,
                            configuration: {
                                ...config.ui?.configuration,
                                placeholder: config.ui?.configuration?.placeholder !== undefined ? undefined : ""
                            }
                        } as IIdentificationUniversalConfigUI
                    }))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.UNIVERSAL.PIVOTS.UI.CONFIGURATION_PLACEHOLDER_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.configuration?.placeholder !== undefined ? true : false}
                textFieldValue={config.ui?.configuration?.placeholder}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.identification.editModule({
                    ...config,
                    ui: {
                        ...config.ui,
                        configuration: {
                            ...config.ui?.configuration,
                            placeholder: val
                        }
                    } as IIdentificationUniversalConfigUI
                }))}
            />
        </IdentificationModuleUISettingsPivot>

    )
}