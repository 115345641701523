import { Stack, Slider, TextField } from "@fluentui/react"
import { Dispatch } from "react"
import { actionCreators } from "../../../../../../core/actions/configurator-actions"
import { IWorkflow } from "../../../../../../core/store"
import { strings } from "../../../../../../localization/strings"
import { onRenderLabel } from "../../../../../renders/onRenderLabel"
import { ParameterItem } from "../../../components/ParameterItem"
import { getSliderLabel } from "../../common/helpers/fuctions"
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { IAssessmentConfig, ProcessStages } from "@piceasoft/core"

type TProps = {
    config: IWorkflow
    onChangeDispatch: Dispatch<any>
    channel: number
}

export const AssessmentRequirementsPivot: React.FC<TProps> = ({ config, ...props }) => {
    return (
        <Stack.Item>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.USE_REQUIREMENTS_TITLE}
                useToggle
                noSeparator
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, ProcessStages.Assessment, 'Device Identifiers', 'Require device identifiers')}
                toggleChecked={config.assessment?.requirements ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                        ...config.assessment,
                        requirements: config.assessment?.requirements ? undefined : {}
                    } as IAssessmentConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.USE_REQUIREMENTS_DESCRIPTION}
            />
            {config.assessment?.requirements && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.TITLE_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, ProcessStages.Assessment, 'Device Identifiers', 'Use a custom title')}
                    toggleChecked={config.assessment?.ui?.requirements?.title !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                            ...config.assessment,
                            ui: {
                                ...config.assessment?.ui,
                                requirements: {
                                    ...config.assessment?.ui?.requirements,
                                    title: config.assessment?.ui?.requirements?.title !== undefined ? undefined : ""
                                }
                            }
                        } as IAssessmentConfig
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.TITLE_DESCRIPTION}
                    useTextField
                    textFieldValue={config.assessment.ui?.requirements?.title}
                    textFieldEnabled={config.assessment.ui?.requirements?.title !== undefined ? true : false}
                    textFieldOnChange={(val) => {
                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                            ...config.assessment,
                            ui: {
                                ...config.assessment?.ui,
                                requirements: {
                                    ...config.assessment?.ui?.requirements,
                                    title: val
                                }
                            }
                        } as IAssessmentConfig
                        ))
                    }}
                />
            )}
            {config.assessment?.requirements && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.INFORMATION_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, ProcessStages.Assessment, 'Device Identifiers', 'Use custom information')}
                    toggleChecked={config.assessment?.ui?.requirements?.information !== undefined ? true : false}
                    toggleOnChange={() => {
                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                            ...config.assessment,
                            ui: {
                                ...config.assessment?.ui,
                                requirements: {
                                    ...config.assessment?.ui?.requirements,
                                    information: config.assessment?.ui?.requirements?.information !== undefined ? undefined : ""
                                }
                            }
                        } as IAssessmentConfig
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.INFORMATION_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.assessment.ui?.requirements?.information !== undefined ? true : false}
                    textFieldValue={config.assessment.ui?.requirements?.information}
                    textFieldOnChange={(val) => {
                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                            ...config.assessment,
                            ui: {
                                ...config.assessment?.ui,
                                requirements: {
                                    ...config.assessment?.ui?.requirements,
                                    information: val
                                }
                            }
                        } as IAssessmentConfig
                        ))
                    }}
                />
            )}
            {config.assessment?.requirements && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.IMEI.TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, ProcessStages.Assessment, 'Device Identifiers', 'Requires IMEI')}
                    toggleChecked={config.assessment?.requirements?.imei ? true : false}
                    toggleOnChange={() => {
                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                            ...config.assessment,
                            requirements: {
                                ...config.assessment?.requirements,
                                imei: config.assessment?.requirements?.imei ? undefined : {}
                            }
                        } as IAssessmentConfig
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.IMEI.DESCRIPTION}
                >
                    {config.assessment?.requirements?.imei && (
                        <Stack tokens={{ childrenGap: 16 }} style={{ width: 320 }}>
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LABEL_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LABEL} value={config.assessment?.requirements.imei.label ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        imei: {
                                            ...config.assessment?.requirements?.imei,
                                            label: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.PLACEHOLDER_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.PLACEHOLDER} value={config.assessment?.requirements.imei.placeholder ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        imei: {
                                            ...config.assessment?.requirements?.imei,
                                            placeholder: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.MASK_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.MASK} value={config.assessment?.requirements.imei.mask ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        imei: {
                                            ...config.assessment?.requirements?.imei,
                                            mask: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <Stack.Item>
                                {onRenderLabel({
                                    label: getSliderLabel(config.assessment.requirements.imei.minLength, config.assessment.requirements.imei.maxLength),
                                    title: strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LENGHT_TITLE
                                })}
                                <Slider showValue={false} ranged lowerValue={config.assessment.requirements.imei.minLength}
                                    value={config.assessment.requirements.imei.maxLength} onChange={(val, range) => {
                                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                            ...config.assessment,
                                            requirements: {
                                                ...config.assessment?.requirements,
                                                imei: {
                                                    ...config.assessment?.requirements?.imei,
                                                    minLength: range && range[0] !== 0 && range[0] || undefined,
                                                    maxLength: range && range[1] !== 0 && range[1] || undefined
                                                }
                                            }
                                        } as IAssessmentConfig
                                        ))
                                    }} min={0} max={50} />
                            </Stack.Item>
                        </Stack>
                    )}
                </ParameterItem>
            )}
            {config.assessment?.requirements && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.IMEI2.TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, ProcessStages.Assessment, 'Device Identifiers', 'Requires IMEI2')}
                    toggleChecked={config.assessment?.requirements?.imei2 ? true : false}
                    toggleOnChange={() => {
                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                            ...config.assessment,
                            requirements: {
                                ...config.assessment?.requirements,
                                imei2: config.assessment?.requirements?.imei2 ? undefined : {}
                            }
                        } as IAssessmentConfig
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.IMEI2.DESCRIPTION} >
                    {config.assessment?.requirements?.imei2 && (
                        <Stack tokens={{ childrenGap: 16 }} style={{ width: 320 }}>
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LABEL_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LABEL} value={config.assessment?.requirements.imei2.label ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        imei2: {
                                            ...config.assessment?.requirements?.imei2,
                                            label: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.PLACEHOLDER_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.PLACEHOLDER} value={config.assessment?.requirements.imei2.placeholder ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        imei2: {
                                            ...config.assessment?.requirements?.imei2,
                                            placeholder: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.MASK_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.MASK} value={config.assessment?.requirements.imei2.mask ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        imei2: {
                                            ...config.assessment?.requirements?.imei2,
                                            mask: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <Stack.Item>
                                {onRenderLabel({
                                    label: getSliderLabel(config.assessment.requirements.imei2.minLength, config.assessment.requirements.imei2.maxLength),
                                    title: strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LENGHT_TITLE
                                })}
                                <Slider showValue={false} ranged lowerValue={config.assessment.requirements.imei2.minLength}
                                    value={config.assessment.requirements.imei2.maxLength} onChange={(val, range) => {
                                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                            ...config.assessment,
                                            requirements: {
                                                ...config.assessment?.requirements,
                                                imei2: {
                                                    ...config.assessment?.requirements?.imei2,
                                                    minLength: range && range[0] !== 0 && range[0] || undefined,
                                                    maxLength: range && range[1] !== 0 && range[1] || undefined
                                                }
                                            }
                                        } as IAssessmentConfig
                                        ))
                                    }} min={0} max={50} />
                            </Stack.Item>
                        </Stack>
                    )}
                </ParameterItem>
            )}
            {config.assessment?.requirements && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.SN.TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, ProcessStages.Assessment, 'Device Identifiers', 'Requires serial number')}
                    toggleChecked={config.assessment?.requirements?.sn ? true : false}
                    toggleOnChange={() => {
                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                            ...config.assessment,
                            requirements: {
                                ...config.assessment?.requirements,
                                sn: config.assessment?.requirements?.sn ? undefined : {}
                            }
                        } as IAssessmentConfig
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.SN.DESCRIPTION}
                >
                    {config.assessment?.requirements?.sn && (
                        <Stack tokens={{ childrenGap: 16 }} style={{ width: 320 }}>
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LABEL_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LABEL} value={config.assessment?.requirements.sn.label ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        sn: {
                                            ...config.assessment?.requirements?.sn,
                                            label: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.PLACEHOLDER_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.PLACEHOLDER} value={config.assessment?.requirements.sn.placeholder ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        sn: {
                                            ...config.assessment?.requirements?.sn,
                                            placeholder: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <TextField onRenderLabel={onRenderLabel} title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.MASK_TITLE}
                                label={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.MASK} value={config.assessment?.requirements.sn.mask ?? ""}
                                onChange={(ev, val) => props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                    ...config.assessment,
                                    requirements: {
                                        ...config.assessment?.requirements,
                                        sn: {
                                            ...config.assessment?.requirements?.sn,
                                            mask: (val && val.trim().length > 0) ? val : undefined
                                        }
                                    }
                                } as IAssessmentConfig
                                ))} />
                            <Stack.Item>
                                {onRenderLabel({
                                    label: getSliderLabel(config.assessment.requirements.sn.minLength, config.assessment.requirements.sn.maxLength),
                                    title: strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.ASSESSMENT_REQUIREMENTS.LENGHT_TITLE
                                })}
                                <Slider
                                    showValue={false} ranged lowerValue={config.assessment.requirements.sn.minLength}
                                    value={config.assessment.requirements.sn.maxLength} onChange={(val, range) => {
                                        props.onChangeDispatch(actionCreators.editStage(ProcessStages.Assessment, {
                                            ...config.assessment,
                                            requirements: {
                                                ...config.assessment?.requirements,
                                                sn: {
                                                    ...config.assessment?.requirements?.sn,
                                                    minLength: range && range[0] !== 0 && range[0] || undefined,
                                                    maxLength: range && range[1] !== 0 && range[1] || undefined
                                                }
                                            }
                                        } as IAssessmentConfig
                                        ))
                                    }} min={0} max={50} />
                            </Stack.Item>
                        </Stack>
                    )}
                </ParameterItem>
            )}
        </Stack.Item>
    )
}