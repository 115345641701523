import { DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint, getTheme, IButtonStyles, IColumn, Icon, IconButton, IContextualMenuProps, IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles, IRenderFunction, Link, MessageBar, MessageBarType, ScrollablePane, SelectionMode, SharedColors, Spinner, Stack, Text } from "@fluentui/react"
import React from "react"
import { useHistory } from "react-router"
import { ScreenHeader } from "../../../components/shared/decorations/ScreenHeader"
import { Section } from "../../../components/shared/decorations/Section"
import { onRenderColumnHeaderCommon } from "../../../components/shared/detailsList/commonRenders"
import { SimpleCustomFilter } from "../../../components/shared/filter/SimpleCustomFilter"
import { ItemsNotFound } from "../../../components/shared/notFound/ItemsNotFound"
import { SomethingWentWrong } from "../../../components/shared/somethingWentWrong/SomethingWentWrong"
import { ISupervisor } from "../../../core/store/typings/ISupervisor"
import { strings } from "../../../localization/strings"
import { TSupervisorsState } from "./AdministrationSupervisors"

type TProps = {
    items: ISupervisor[]
    onEdit: (item: ISupervisor) => void
    onAdd: () => void
    setDeleteSupervisorState: (item: ISupervisor) => void
    requestSupervisors: () => void
    setSearchString: (text?: string) => void
    supervisorsState: TSupervisorsState
    information?: string
    href?: string
    hrefInformation?: string
}

export const AdministrationSupervisorsList: React.FC<TProps> = (props) => {

    const history = useHistory();

    const getItemMenuProps = (item: ISupervisor): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.id}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? props.onEdit(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.id}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => item.id ? props.setDeleteSupervisorState(item) : undefined,
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (hProps, defaultRender) => {
        if (!hProps) {
            return null;
        }

        return (
            <Stack>
                <Stack.Item>
                    <Stack tokens={{ padding: "16px 32px 4px 20px", childrenGap: 4 }} horizontal>
                        <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                        <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                            <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{props.items.length}</Text>
                        </Stack>
                    </Stack>
                </Stack.Item>
                {defaultRender!({ ...hProps })}
            </Stack>
        );
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.SUPERVISORS.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 400,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ISupervisor) => onRenderNamePersonCell(item),
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.SUPERVISORS.TABLE_COLUMNS.EMAIL,
            fieldName: 'email',
            minWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ISupervisor) => <Stack verticalFill verticalAlign="center"><Text>{item.email}</Text></Stack>,
            isPadded: true,
        },
        {
            key: 'column3',
            name: '',
            fieldName: 'commandBar',
            minWidth: 40,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: ISupervisor) => (
                <Stack horizontal verticalFill verticalAlign='center'>
                    <Stack.Item>
                        <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item)} />
                    </Stack.Item>
                </Stack>
            )
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack onClick={() => history.push(`/administration/supervisors/${rProps.item['id']}`)
            }><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    }

    const onRenderNamePersonCell = (item: ISupervisor) => {
        return (
            <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }}>
                <Stack.Item>
                    <Icon iconName={"Telemarketer"} style={{ fontSize: 32 }} />
                </Stack.Item>
                <Stack.Item grow>
                    <Stack>
                        <Stack.Item>
                            <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.display}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="small" style={{ color: SharedColors.gray30 }}>{item.name}</Text>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        )
    }

    return (
        <Stack verticalFill>
            <Stack.Item verticalFill>
                <Stack verticalFill>
                    {!props.supervisorsState.error && (props.supervisorsState.fetched && props.supervisorsState.data && (
                        props.supervisorsState.data.length > 0 && (
                            <>
                                <ScreenHeader title={""}>
                                    <SimpleCustomFilter onChange={(ev, value) => props.setSearchString(value)} />
                                </ScreenHeader>
                                <Stack verticalFill style={{ position: 'relative' }}>
                                    <ScrollablePane>
                                        <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                            <Section max>
                                                <DetailsList
                                                    styles={{ root: { width: '100%' } }}
                                                    items={props.items}
                                                    columns={columns}
                                                    setKey="none"
                                                    selectionMode={SelectionMode.none}
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                                    isHeaderVisible={true}
                                                    onRenderRow={onRenderRow}
                                                />
                                            </Section>
                                        </Stack.Item>
                                        <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                            <Text style={{ color: SharedColors.gray20 }}>{props.information}</Text>
                                            <br />
                                            <Link href={props.href}>{props.hrefInformation}</Link>
                                        </Stack.Item>
                                    </ScrollablePane>
                                </Stack>
                            </>
                        ) || (
                            <ItemsNotFound
                                onClick={props.onAdd}
                                info={strings.ORGANIZATION.SUPERVISORS.NOT_FOUND_CASE.INFO}
                                suggestion={strings.ORGANIZATION.SUPERVISORS.NOT_FOUND_CASE.SUGGESTION}
                                buttonText={strings.ORGANIZATION.SUPERVISORS.NOT_FOUND_CASE.ADD_BUTTON}
                                imgSrc={"images/navigation/images/not_found.png"} />
                        )) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        ))}
                    {props.supervisorsState.error && (
                        <Stack tokens={{ padding: "24px 0 0 0" }} verticalFill>
                            {props.supervisorsState.error.length > 0 && (
                                <MessageBar messageBarType={MessageBarType.error}>{props.supervisorsState.error}</MessageBar>
                            )}
                            <Stack.Item verticalFill>
                                <SomethingWentWrong action={props.requestSupervisors} />
                            </Stack.Item>
                        </Stack>
                    )}
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}



