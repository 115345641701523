import { Action, Reducer } from "redux";
import { KnownAction } from "../actions/teaching-actions";
import { IStartTeaching, IStartTeachingStateItem } from "@piceasoft/core";

export const reducer: Reducer<IStartTeaching> = (state: IStartTeaching | undefined, incomingAction: Action): IStartTeaching => {
    const commonItems = ["tutorial", "support", "transaction_search"]

    if (state === undefined) {
        const localState = JSON.parse(localStorage.getItem('START_TEACHING') ?? "[]") as IStartTeachingStateItem[]
        const teachingState = commonItems.map(i => { return { name: i, passed: localState.find(k => k.name === i)?.passed } as IStartTeachingStateItem })
        const currentItem = teachingState.find(i => !i.passed && !i.hidden && i.id && (document.getElementById(i.id)?.id === i.id))?.name
        return {
            teachingState: teachingState,
            currentItem: currentItem,
            items: [...commonItems]
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'TEACHING_UPDATE_ITEM':
            let updateResult = [] as IStartTeachingStateItem[]

            if (state.teachingState && state.items) {
                state.items.map(k => {
                    const findedItem = state.teachingState && [...state.teachingState.filter(i => i.name !== action.item.name), action.item].find(i => i.name === k)
                    if (findedItem) updateResult.push(findedItem)
                })
            } else {
                updateResult.push(action.item)
            }
            return { ...state, teachingState: updateResult, currentItem: updateResult.find(i => !i?.passed && !i?.hidden && i.id && (document.getElementById(i.id)?.id === i.id))?.name };
        case 'TEACHING_CLEAR_STATE':
            const clearResult = [...state.teachingState?.map(i => { return { name: i.name, id: i.id } }) ?? []]
            return { ...state, teachingState: clearResult,currentItem: clearResult.find(i => i.id && (document.getElementById(i.id)?.id === i.id))?.name, items: (state.items && [...state.items]) ?? [] };
        case 'TEACHING_REBUILD_ITEMS':
            const localState = JSON.parse(localStorage.getItem('START_TEACHING') ?? "[]") as IStartTeachingStateItem[]
            const workflowItemsState = action.items.map(i => { return { name: i, passed: localState.find(k => k.name === i)?.passed } as IStartTeachingStateItem })
            const newTeachingState = state.teachingState ? [...state.teachingState, ...workflowItemsState] : workflowItemsState
            const currentItem = newTeachingState.find(i => !i.passed && !i.hidden && i.id && (document.getElementById(i.id)?.id === i.id))?.name
            return { ...state, teachingState: newTeachingState, currentItem: currentItem, items: [...commonItems, ...action.items] };
        default: return state;
    }
};
