import { Modal, Stack, Text, IconButton, SharedColors, FontWeights, getTheme, mergeStyleSets } from "@fluentui/react"
import { strings } from "../../../../../../localization/strings"
import { FieldForm } from "../../contract/FieldForm"
import { FieldFormCommon } from "../../contract/FieldFormCommon"
import { FieldFormWithMask } from "../../contract/FieldFormWithMask"
import { CustomFieldForm } from "../../contract/CustomFieldForm"
import React from "react"
import { IContractCustomField } from "@piceasoft/core"

export type CommonFieldNames = 'name' | 'phone' | 'email' | 'address'
export type ContactFieldNames = CommonFieldNames | 'passportNumber' | 'passportDateOfIssue' | 'passportIssuedBy' | 'passportSubdivisionCode'
                                | 'dateOfBirth' | 'placeOfBirth' | 'operator'

export type RanFieldNames =  CommonFieldNames | 'zipCode' | 'city' | 'idNumber' | 'scheduleStart' | 'scheduleEnd' | 'comments' 
                            | 'deviceIMEI' | 'deviceBrand' | 'deviceModel' | 'deviceColor' | 'deviceInvoiceDate'

export type EditableContactField = RanFieldNames | ContactFieldNames | string

interface TProps {
    isModalOpen?: boolean
    fieldId: EditableContactField
    fieldData?: object
    customFieldData?:  IContractCustomField
    customFields: string[]
    onCloseModal: () => void
    onUpdateField: ( field: EditableContactField, value: object) => void
}

const ContactFormFieldEditor: React.FC<TProps> = (props: TProps) => {

    const useCommonEdit = [ 'email', 'scheduleStart', 'scheduleEnd', 'deviceInvoiceDate', 'dateOfBirth', 'passportDateOfIssue'].includes( props.fieldId)
    const useFormEdit = [ 'name', 'address', 'zipCode', 'city', 'idNumber', 'comments', 'deviceIMEI', 'deviceBrand', 'deviceModel', 
                        'deviceColor', 'passportIssuedBy', 'placeOfBirth', 'operator'].includes( props.fieldId)
    const useMaskedEdit = [ 'phone', 'passportNumber', 'passportSubdivisionCode'].includes( props.fieldId)
    const useCustomEdit = !useCommonEdit && !useFormEdit && !useMaskedEdit
    const fieldTitle = getContactFieldTitle( props.fieldId)

    const onCloseModal = () => {
        props.onCloseModal();
    }

    const onUpdateField = (field: EditableContactField, value: object) => {
        props.onUpdateField( field, value);
        props.onCloseModal();
    }

    return (
    <Modal isOpen={props.isModalOpen} onDismiss={onCloseModal} containerClassName={modalStyles.container} styles={{ main: { display: "flex", maxHeight: "640px", maxWidth: "500px" } }}>
    <Stack verticalFill>
        <Stack horizontal horizontalAlign="space-between" className={modalStyles.header}>
            <Stack verticalAlign="center" tokens={{ childrenGap: 2 }}>
                <Text variant="xLarge" style={{ paddingRight: 32, fontWeight: 600 }}>{strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.FIELD_CONFIGURATION.TITLE}</Text>
                <Text variant="smallPlus" style={{ paddingRight: 32, color: SharedColors.gray40, fontWeight: 600 }}>{fieldTitle}</Text>
            </Stack>
            <IconButton
                styles={iconButtonStyles}
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close"
                onClick={onCloseModal}
            />
        </Stack>
        <Stack.Item verticalFill>
            {useFormEdit && (
                <FieldForm data={props.fieldData} onCommit={(data) => {
                    onUpdateField( props.fieldId, data)
                }
                } />
            )}
            {useCommonEdit && (
                <FieldFormCommon data={props.fieldData} onCommit={(data) => {
                    onUpdateField( props.fieldId, data)
                }
                } />
            )}
            {useMaskedEdit && (
                <FieldFormWithMask data={props.fieldData} onCommit={(data) => {
                    onUpdateField( props.fieldId, data)
                }
                } />
            )}
            {useCustomEdit && (<CustomFieldForm data={props.customFieldData} keys={props.customFields} onCommit={(data) => onUpdateField( props.fieldId, data)}
            />)}
        </Stack.Item>
    </Stack>
    </Modal>)
}

export default ContactFormFieldEditor;

const theme = getTheme();

const modalStyles = mergeStyleSets({
    containerModule: {
        borderRadius: 4
    },
    container: {
        flex: '1 1 auto',
        alignItems: 'stretch',
        borderRadius: 6
    },
    header: [
        theme.fonts.xLarge,
        {
            color: theme.palette.neutralPrimary,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        fontSize: 10,
        position: "relative",
        overflowY: 'hidden'
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export const getContactFieldTitle = ( name: EditableContactField): string => {

    if( "name" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.NAME.TITLE
    }
    if( "idNumber" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ID_NUMBER.TITLE
    }
    if( "phone" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PHONE.TITLE
    }
    if( "email" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.EMAIL.TITLE
    }
    if( 'passportNumber' === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.NUMBER.TITLE
    }
    if( 'passportDateOfIssue' === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_ISSUE.TITLE
    }
    if( 'passportIssuedBy' === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ISSUED_BY.TITLE
    }
    if( 'passportSubdivisionCode' === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.SUBDIVISION_CODE.TITLE
    }
    if( 'dateOfBirth' === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.DATE_OF_BIRTH.TITLE
    }
    if( 'placeOfBirth' === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.PLACE_OF_BIRTH.TITLE
    }
    if( 'operator' === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.OPERATOR.TITLE
    }
    if( "address" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.PASSPORT.ADDRESS.TITLE
    }
    if( "zipCode" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.ZIP_CODE.TITLE
    }
    if( "country" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.COUNTRY.TITLE
    }
    if( 'city' == name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.CITY.TITLE
    }
    if( "scheduleStart" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.SCHEDULE_START.TITLE
    }
    if( "scheduleEnd" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.SCHEDULE_END.TITLE
    }
    if( "comments" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.COMMENTS.TITLE
    }
    if( "deviceIMEI" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_IMEI.TITLE
    }
    if( "deviceBrand" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_BRAND.TITLE
    }
    if( "deviceModel" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_MODEL.TITLE
    }
    if( "deviceColor" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_COLOR.TITLE
    }
    if( "deviceInvoiceDate" === name) {
        return strings.CONSTRUCTOR.STAGES.CONTRACT.FIELDS.DEVICE_INVOICE_DATE.TITLE
    }
    return ''
}