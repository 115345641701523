import { ScrollablePane, Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";
import { ParameterItem } from "../../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { ProcessStages, IPreOfferConfig } from "@piceasoft/core";

type TProps = {
    config?: IPreOfferConfig
    selectedPivot?: string
    onChangeDispatch: Dispatch<any>
    channel: number
}

type TPivotStates = "hasOffer" | "noOffer" | "notAcceptable"

export const PreOfferStageCommonUIPivot: React.FC<TProps> = ({ onChangeDispatch, config, selectedPivot, channel }) => {
    if (!config) return null;

    return (
        <Stack>
            {(!selectedPivot || selectedPivot === "hasOffer") && (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.PRICES_HEADER_TITLE}
                        useToggle
                        noSeparator
                        toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, 'hasOffer', 'Custom message text')}
                        toggleChecked={config.prices?.ui?.header !== undefined ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                                ...config,
                                prices: {
                                    ...config.prices,
                                    ui: {
                                        ...config.prices?.ui,
                                        header: config.prices?.ui?.header !== undefined ? undefined : ""
                                    }
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.PRICES_HEADER_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.prices?.ui?.header !== undefined ? true : false}
                        textFieldValue={config.prices?.ui?.header}
                        textFieldOnChange={(val) => onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                            ...config,
                            prices: {
                                ...config.prices,
                                ui: {
                                    ...config.prices?.ui,
                                    header: val
                                }
                            }
                        }))}
                    />

                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.PRICES_INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, 'hasOffer', 'Custom information text')}
                        toggleChecked={config.prices?.ui?.information !== undefined ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                                ...config,
                                prices: {
                                    ...config.prices,
                                    ui: {
                                        ...config.prices?.ui,
                                        information: config.prices?.ui?.information !== undefined ? undefined : ""
                                    }
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.PRICES_INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.prices?.ui?.information !== undefined ? true : false}
                        textFieldValue={config.prices?.ui?.information}
                        textFieldOnChange={(val) => onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                            ...config,
                            prices: {
                                ...config.prices,
                                ui: {
                                    ...config.prices?.ui,
                                    information: val
                                }
                            }
                        }))}
                    />
                </>)}
            {selectedPivot === "notAcceptable" && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.NOT_ACCEPTED_MESSAGE_TITLE}
                    useToggle
                    noSeparator
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, selectedPivot, 'Custom message text')}
                    toggleChecked={config.prices?.ui?.notAcceptedMessage !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                            ...config,
                            prices: {
                                ...config.prices,
                                ui: {
                                    ...config.prices?.ui,
                                    notAcceptedMessage: config.prices?.ui?.notAcceptedMessage !== undefined ? undefined : ""
                                }
                            }
                        }))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.NOT_ACCEPTED_MESSAGE_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.prices?.ui?.notAcceptedMessage !== undefined ? true : false}
                    textFieldValue={config.prices?.ui?.notAcceptedMessage}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                        ...config,
                        prices: {
                            ...config.prices,
                            ui: {
                                ...config.prices?.ui,
                                notAcceptedMessage: val
                            }
                        }
                    }))}
                />
            )
            }
            {selectedPivot === "noOffer" && (
                <>
                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.NO_PRICES_CAPTION_TITLE}
                        useToggle
                        noSeparator
                        toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, selectedPivot, 'Custom message text')}
                        toggleChecked={config.prices?.ui?.priceNotFoundCaption !== undefined ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                                ...config,
                                prices: {
                                    ...config.prices,
                                    ui: {
                                        ...config.prices?.ui,
                                        priceNotFoundCaption: config.prices?.ui?.priceNotFoundCaption !== undefined ? undefined : ""
                                    }
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.NO_PRICES_CAPTION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.prices?.ui?.priceNotFoundCaption !== undefined ? true : false}
                        textFieldValue={config.prices?.ui?.priceNotFoundCaption}
                        textFieldOnChange={(val) => onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                            ...config,
                            prices: {
                                ...config.prices,
                                ui: {
                                    ...config.prices?.ui,
                                    priceNotFoundCaption: val
                                }
                            }
                        }))}
                    />
                    <ParameterItem title={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.NO_PRICES_INFORMATION_TITLE}
                        useToggle
                        toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.PreOffer, selectedPivot, 'Custom information text')}
                        toggleChecked={config.prices?.ui?.priceNotFoundInformation !== undefined ? true : false}
                        toggleOnChange={() => {
                            onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                                ...config,
                                prices: {
                                    ...config.prices,
                                    ui: {
                                        ...config.prices?.ui,
                                        priceNotFoundInformation: config.prices?.ui?.priceNotFoundInformation !== undefined ? undefined : ""
                                    }
                                }
                            }))
                        }}
                        description={strings.CONSTRUCTOR.STAGES.PRE_OFFER.COMMON_UI.NO_PRICES_INFORMATION_DESCRIPTION}
                        useTextField
                        textFieldEnabled={config.prices?.ui?.priceNotFoundInformation !== undefined ? true : false}
                        textFieldValue={config.prices?.ui?.priceNotFoundInformation}
                        textFieldOnChange={(val) => onChangeDispatch(actionCreators.preOffer.onConfigCommit({
                            ...config,
                            prices: {
                                ...config.prices,
                                ui: {
                                    ...config.prices?.ui,
                                    priceNotFoundInformation: val
                                }
                            }
                        }))}
                    />
                </>
            )}
        </Stack>
    )
}