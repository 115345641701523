import {
    getTheme, Stack, TextField, Text, Separator, PrimaryButton,
    ScrollablePane, Sticky, MessageBar,
    MessageBarType, getFocusStyle, IComboBoxOption, Icon, IDropdownOption,
    mergeStyleSets, SharedColors, Toggle, Spinner, StackItem
} from '@fluentui/react'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { strings } from '../../../localization/strings'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { CatalogTypes, IRepairOfferProviderConfig } from '@piceasoft/core'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { ChooseIcon } from '../chooseIcon/ChooseIcon'
import { portalApi } from '../../../core/api/api'
import { IRequestResult } from '../../../core/store/typings/IResponseResult'
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from '../search/SuggestedSearch'
import { useId } from '@uifabric/react-hooks'
import { escapeRegExp } from '../../../assets/ts/utils/regex'
import { ICatalog, ICatalogItemPrice } from '../../../core/store'
import { IResponseError } from '@piceasoft/core'
import { CSHHelpLink } from '../help/CSHHelp'
import { aiApi } from '../../../core/api/ai.api'
import { RepairComponentsMap } from '../../../core/store/typings/RepairComponentsMap'
import { IComponentMap } from '@piceasoft/core/dist/interfaces/IRepairOfferProviderConfig'
import { RepairOfferProviderFormGradesMapArray } from './RepairOfferProviderFormGradesMapArray'
import unknown from "../../../assets/emptyimage.svg"

type TProps = {
    onFormSubmit: (item: IRepairOfferProviderConfig) => void
    onCancel: () => void
    codes: string[]
    data?: IRepairOfferProviderConfig
}

export const RepairOfferProviderForm: React.FC<TProps> = ({ data, onCancel, codes, onFormSubmit }) => {

    const [file, setFile] = React.useState<File>()
    const [isFetchingIcon, setIsFetchingIcon] = React.useState(false)
    const [isIconChanged, setIsIconChanged] = React.useState(false)
    const [iconErrors, setIconErrors] = React.useState<IResponseError[]>([])
    const [fileSrc, setFileSrc] = React.useState(data?.imageSrc)
    const [templateComponents, setTemplateComponents] = React.useState<RepairComponentsMap | undefined>(undefined)
    const [catalogId, setCatalogId] = React.useState<string>(data?.catalogId ?? '')
    const [catalogComponents, setCatalogComponents] = React.useState<ICatalogItemPrice[]>();
    const [catalogsState, setCatalogsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<ICatalog> }>({ fetched: false })

    const [componentsMap, setComponentsMap] = React.useState<IComponentMap[]>(data?.componentsMap ?? [])

    const [catalogs, setCatalogs] = React.useState<IComboBoxOption[]>()
    const catalogHostId = useId("catalog-host-id")
    const catalogTextId = useId("catalog-text-id")
    const catalogCalloutListId = "catalog-callout-id"

    const { control, handleSubmit, getValues, setValue, resetField, formState: { errors } } = 
        useForm<IRepairOfferProviderConfig>({ defaultValues: { ...data } });

    const onSubmit: SubmitHandler<IRepairOfferProviderConfig> = async data => {
        let icon = data?.imageSrc ?? ''
        let errors: IResponseError[] = []
        let currency = data?.currency ?? ''
        if (icon !== fileSrc) {
            setIsFetchingIcon(true)
            setIconErrors([])
            let uploadResult: IRequestResult<string> = { successed: false }
            if (file) {
                uploadResult = await portalApi.settings.organization.uploadFile('offer-provider-icon', file, undefined, true);
                if (uploadResult.errors && uploadResult.errors.length > 0) {
                    errors = uploadResult.errors;
                }
            } else {
                uploadResult.successed = true;
            }

            if (uploadResult.successed) {
                icon = uploadResult?.data ?? ''
                setIsIconChanged(false)
            }
            setIsFetchingIcon(false)
        }
        if (catalogId)
            currency = catalogsState.data?.find(i => i.id === catalogId)?.currency!;
        if (errors.length === 0) {
            onFormSubmit({ ...data, imageSrc: icon ?? "", currency: currency })
        } else {
            setIconErrors(errors)
        }

    };

    React.useEffect(() => {
        if (catalogsState.fetched && catalogsState.data) {
            setCatalogs(getCatalogsOptions(catalogsState.data))
            // Validate if current catalog exists.
            // If catalog doesn't exist then reset catalog field and block saving form without selecting new catalog.
            const catalogId = getValues().catalogId;
            const catalogIndex = catalogsState.data.findIndex(c => c.id === catalogId)
            if (catalogIndex < 0){
                resetField("catalogId", {defaultValue: null})
            }            
        }
    }, [catalogsState])

    React.useEffect(() => {
        let isSubscribed = true;
        setCatalogsState({ fetched: false })
        const fetchData = async () => {
            const catalogs = await portalApi.organization.catalogs.getCatalogsByCatalogType(CatalogTypes.Repair)
            if (isSubscribed && catalogs?.data) {
                setCatalogsState({ error: (catalogs.errors && catalogs.errors.length > 0) ? catalogs.errors[0]?.description : undefined, fetched: true, data: catalogs.data?.filter(c => !c.removed) })
            }
        }

        fetchData()
            .catch(error => {
                if (isSubscribed) {
                    console.log(error)
                }
            });

        return () => {
            isSubscribed = false;
        }
    }, [])

    React.useEffect(() => {
        if (catalogId) {
            let isSubscribed = true;
            const fetchData = async () => {
                const aicomponents = await aiApi.getComponents()
                const components = await portalApi.organization.catalogs.getComponentsByCatalogId(catalogId)
                if (isSubscribed && aicomponents.data?.message && components) {
                    setTemplateComponents(aicomponents.data.message)
                    setCatalogComponents(components.data)
                    setComponentsMap(getMappings(components.data, aicomponents.data.message))
                }
            }

            fetchData()
                .catch(error => {
                    if (isSubscribed) {
                        console.log(error)
                    }
                });

            return () => {
                isSubscribed = false;
            }
        }
    }, [catalogId])

    React.useEffect(() => {
        setFileSrc(data?.imageSrc)
        setFile(undefined)
    }, [data?.imageSrc])

    React.useEffect(() => {
        if (iconErrors.length > 0) {
            setIconErrors([])
        }
    }, [fileSrc])

    const allowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif", "image/svg+xml"]

    const addFile = (upFiles: FileList) => {
        const checkedUpFiles = [...Array.from(upFiles).filter(upFile => allowedFileTypes.includes(upFile.type))]
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            setIsIconChanged(true)
            setFileSrc(URL.createObjectURL(checkedUpFiles[0]))
            setFile(checkedUpFiles[0])
        }
    }

    const resetImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFileSrc(data?.imageSrc)
            setFile(undefined)
            setIsIconChanged(false)
        }
    }

    const deleteImageSrc = () => {
        if (fileSrc) {
            setFileSrc(undefined)
            setIsIconChanged(true)
        }
    }

    const getMappings = (catalogComponents: ICatalogItemPrice[] = [], aiTemplateComponents: RepairComponentsMap = {}) => {
        const tempCatalogComponents: IComponentMap[] = [];
        const catalogComponentOptions: IDropdownOption[] = getDropDownOptions(catalogComponents)
    
        for (const key in aiTemplateComponents) {
            if (Object.prototype.hasOwnProperty.call(aiTemplateComponents, key)) {
                const value = aiTemplateComponents[key];
                const existingValue = componentsMap.find(x => x.workflowComponentId?.toString().toLowerCase() === key.toLowerCase())?.providerComponent ?? '';
                const componentExistIndex = catalogComponentOptions.findIndex(x => x.key?.toString().toLowerCase() === value.toLowerCase());
                const componentExist = componentExistIndex !== -1;
                const itemMap = {
                    workflowComponentId: key?.toString(),
                    providerComponent: (existingValue === '' && componentExist && data?.index == undefined)
                        ? catalogComponentOptions[componentExistIndex].text || ''
                        : existingValue
                };
    
                tempCatalogComponents.push(itemMap);
            }
        }
    
        return aiTemplateComponents ? tempCatalogComponents : componentsMap;
    }

    const getCatalogsOptions = (catalogs: ICatalog[]): IDropdownOption[] => catalogs.map(c => ({ key: c.id, text: c.name, disabled: c.removed } as IDropdownOption))

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderCatalogSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${catalogCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, catalogs?.length ?? 0, catalogTextId, catalogCalloutListId, () => setValue("catalogId", item?.key as string), index, item?.text, catalogHostId)}
                tabIndex={-1}
                onClick={() => {
                    setValue("catalogId", item?.key as string)
                    setCatalogId(item?.key as string)
                    focusElement(`${catalogCalloutListId}-${index as number}`, catalogHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    const getDropDownOptions = (catalogComponents? : ICatalogItemPrice[])=>{
        let options : IDropdownOption [] = [];
        options.push({key:'', text:strings.CONSTRUCTOR.SELFSERVICE.TITLE_PLACEHOLDER}) 
        let mapOptions = catalogComponents?.map(c => ({ key: c.grade, text: c.grade } as IDropdownOption) as IDropdownOption)
        if(mapOptions){
            options = options.concat(mapOptions)
        }
        return options
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        {iconErrors.length > 0 && (
                            <Sticky>
                                <MessageBar messageBarType={MessageBarType.error}>
                                    <Stack tokens={{ childrenGap: 2 }}>
                                        {iconErrors.map(i => <Text>{i.description}</Text>)}
                                    </Stack>
                                </MessageBar>
                            </Sticky>
                        )}
                        <Stack verticalFill tokens={{ padding: "0px 20px 20px 20px", childrenGap: 16 }}>
                            <Controller
                                control={control}
                                name={"code"}
                                rules={{
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_FORM.CODE_REQUIRED,
                                    validate: (value) => {
                                        if (data && data.code === value) {
                                            return true;
                                        }
                                        return codes.find(k => k === value) ?
                                            strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_FORM.PROVIDER_CODE_VALIDATION :
                                            true
                                    }
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_FORM.LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_FORM.PLACEHOLDER}
                                        errorMessage={errors.code?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"name"}
                                rules={{
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_NAME.REQUIRED,
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_NAME.LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_NAME.PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"description"}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value ?? ""}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_DESCRIPTION.LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_DESCRIPTION.PLACEHOLDER}
                                        errorMessage={errors.description?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"catalogId"}
                                rules={{
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_CATALOG.REQUIRED,
                                }}
                                render={({ field }) =>
                                    <SuggestedSearch
                                        type="dropdown-search"
                                        inputBoxId={catalogTextId}
                                        hostId={catalogHostId}
                                        required
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_CATALOG.LABEL}
                                        title={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_CATALOG.LABEL}
                                        inputBoxPlaceholder={!catalogs ? strings.SPINNERS.DATA_IS_GETTING : catalogs.length > 0 ? (strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_CATALOG.PLACEHOLDER) : strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_REPAIR.NO_CATALOGS_PLACEHOLDER}
                                        onClickSuggestionCell={(item) => field.onChange(item ? item.key as string : undefined)}
                                        searchRequest={catalogs ? catalogs.find(i => i.key === field.value)?.text : strings.SPINNERS.DATA_IS_GETTING}
                                        suggestionsListWidth={440}
                                        disabled={!catalogs || catalogs.length === 0}
                                        suggestions={catalogs ?? []}
                                        suggestionsListId={catalogCalloutListId}
                                        isSearchEqualTheOneSuggestion={false}
                                        onRenderSuggestionCell={onRenderCatalogSuggestionsCell}
                                        prepareFilteredItems={prepareFilteredOptions}
                                        selectedKey={getValues().catalogId}
                                        errorMessage={errors.catalogId?.message}
                                    />
                                }
                            />
                            <Controller
                                name="supportWarrantyRepair"
                                control={control}
                                render={({ field }) =>
                                    <Toggle
                                        checked={field.value}
                                        onChange={(ev, checked) => field.onChange(checked)}
                                        onBlur={field.onBlur}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_SUPPORT_WARRANTY.LABEL}
                                        offText={strings.BUTTONS.TOGGLE_NO}
                                        onText={strings.BUTTONS.TOGGLE_YES}
                                    />
                                }
                            />
                            <Stack>
                                <Separator />
                                {onRenderLabel({
                                    title: strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_ICON.TITLE,
                                    label: strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_ICON.LABEL
                                })}
                                <ChooseIcon
                                    acceptTypeMask={"image/*"}
                                    allowedFileTypes={allowedFileTypes}
                                    type="image"
                                    changed={isIconChanged}
                                    resetChanged={() => setIsIconChanged(true)}
                                    imgSrc={getValues().imageSrc}
                                    fileSrc={fileSrc}
                                    itemName={getValues().name}
                                    lable={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_REPAIR.FORM.ICON_LOAD_LABEL}
                                    description={true}
                                    defaultIcon={unknown}
                                    addFile={addFile}
                                    deleteImageSrc={deleteImageSrc}
                                    resetImageSrc={resetImageSrc}
                                />
                                <Separator />
                            </Stack>
                            <Stack>
                                <StackItem>
                                    <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600 }}>{strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_MAP.TITLES.LABEL}</Text>
                                </StackItem>
                                <StackItem>
                                    <div style={{ display: 'flex', minWidth: 10, maxWidth: 300, flex: 2, flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                                        <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600 }}>{strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_MAP.TEMPLATE.LABEL}</Text>
                                        <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600 }}>{strings.CONSTRUCTOR.STAGES.COMMON_REPAIR.OFFER.PROVIDER_MAP.CATALOG.LABEL}</Text>
                                    </div>
                                </StackItem>
                            </Stack>
                            {componentsMap && componentsMap.length > 0
                                &&
                                <RepairOfferProviderFormGradesMapArray {...{
                                    control, componentsMap, setValue,
                                    templateComponents,
                                    options: getDropDownOptions(catalogComponents)
                                }} />}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        {isFetchingIcon && (
                            <Spinner />
                        )}
                        <CSHHelpLink alignment='left' articleid='1017'></CSHHelpLink>
                        <PrimaryButton disabled={isFetchingIcon} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        <SecondaryButton onClick={onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}

const theme = getTheme();
