import { getTheme, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { getInspectionStatusBadgeTheme } from '../../../../../components/helpers/transaction';
import { Badge2 } from '../../../../../components/shared/decorations/Badge2';
import { IInspectionState, EraseModes, IEraseConfig, IEraseDefaultConfig } from '@piceasoft/core';
import { getLocalizedInspectionStatus } from '../../../../../localization/helpers/transaction';
import { strings } from '../../../../../localization/strings';

type TProps = {
    result: IInspectionState
    config: IEraseConfig
}

export const ErasePivot: React.FC<TProps> = (props) => {
    console.log(props.result)
    return (
        <>
            {props.config.config && props.config.mode === EraseModes.Default && (
                <EraseDefaultResult result={props.result} config={props.config.config as IEraseDefaultConfig} />
            )}
        </>
    )
}

type TEraseDefaultResultProps = {
    config: IEraseDefaultConfig
    result: IInspectionState
}

const EraseDefaultResult: React.FC<TEraseDefaultResultProps> = (props) => {

    console.log(props.result)

    return (
        <Stack horizontal tokens={{ padding: 24 }}>
            <Stack.Item grow>
                <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{strings.TRANSACTION.RESULT.STATUS}</Text>
            </Stack.Item>
            <Stack verticalFill tokens={{ padding: 2 }} verticalAlign="center">
                <Badge2  {...getInspectionStatusBadgeTheme(props.result.status)} title={getLocalizedInspectionStatus(props.result.status)} />
            </Stack>
        </Stack>
    )
}

const theme = getTheme();
