import { DirectionalHint, FontWeights, getTheme, IconButton, mergeStyleSets, Modal, PrimaryButton, ScrollablePane, Separator, Spinner, Stack, Text, Tooltip, TooltipHost } from "@fluentui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { ScreenHeader } from "../../../components/shared/decorations/ScreenHeader";
import { IBranding } from "../../../core/store/typings/IBranding";
import { BrandingOnlineButtonColorScheme } from "./sections/BrandingOnlineButtonColorScheme";
import { BrandingColorPallete } from "./sections/BrandingColorPallete";
import { BrandingLogo } from "./sections/BrandingLogo";
import { BrandingSetup } from "./sections/BrandingSetup";
import { BrandingTypeografy } from "./sections/BrandingTypeografy";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { IRequestResult } from "../../../core/store/typings/IResponseResult";
import { portalApi } from "../../../core/api/api";
import { strings } from "../../../localization/strings";
import { useBoolean, useId } from "@uifabric/react-hooks";
import { BrandingMobileTopBar } from "./sections/BrandingMobileTopBar";
import { IBrandingConfig, IResponseError } from "@piceasoft/core";
import { ShowBrandingConfiguration } from "./ShowBrandingCofiguration";
import { useSelector } from "react-redux";
import { IStore } from "../../../core/store";
import { UserRoles } from "../../../core/store/typings/UserRoles";

type TProps = {
    branding?: IBranding
    onClickSubmit: (data: IBranding) => void
    hidePanel: () => void
    defaultLogo?: string
}

export type TMainColor = {
    key: string
    value: string
}
export const getNameOfColorByKey = (key: string, fullName?: boolean) => {
    switch (key) {
        case 'primary': return fullName ? strings.ORGANIZATION.BRANDING.COLORS.PRIMARY : strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_PLACEHOLDER
        case 'secondary': return fullName ? strings.ORGANIZATION.BRANDING.COLORS.SECONDARY : strings.ORGANIZATION.BRANDING.COLORS.SECONDARY_PLACEHOLDER
        case 'tertiary': return fullName ? strings.ORGANIZATION.BRANDING.COLORS.TERTIARY : strings.ORGANIZATION.BRANDING.COLORS.TERTIARY_PLACEHOLDER
        default: return undefined
    }
}
export const BrandingDetails: React.FC<TProps> = (props) => {
    const defaultConfig: IBrandingConfig = {
        showLogoInMobile: false,
        accent: '#33C5FF',
        primary: '#0080B4',
        secondary: '#505050',
        tertiary: '#727272',
        logo: '',
        fontColorBody: '#727272',
        fontColorHeadings: '#505050',
        fontFamily: 'Manrope',
        mobileTopBar: { background: '#F5F5F5', color: '#0080B4' },
        onlineButton: { primary: '#0080B4', primaryFontColor: '#ffffff', secondary: '#ffffff', secondaryFontColor: '#0080B4', secondaryOutline: '#0080B4' }
    }
    const defaultDarkConfig: IBrandingConfig = {
        showLogoInMobile: false,
        accent: '#33C5FF',
        primary: '#0080B4',
        secondary: '#505050',
        tertiary: '#727272',
        logo: '',
        fontColorBody: '#ffffff',
        fontColorHeadings: '#ffffff',
        fontFamily: 'Manrope',
        mobileTopBar: { background: '#000000', color: props.branding?.config?.primary ?? '#0080B4' },
        onlineButton: { primary: props.branding?.config?.primary ?? '#0080B4', primaryFontColor: '#000', secondary: '#00ffffff', secondaryFontColor: props.branding?.config?.primary ?? '#0080B4', secondaryOutline: props.branding?.config?.primary ?? '#0080B4' }
    }
    const { control, getValues, setValue, handleSubmit, formState: { errors } } = useForm<IBranding>({ defaultValues: { ...props.branding, config: props.branding?.config ?? defaultConfig, darkConfig: props.branding?.darkConfig ?? defaultDarkConfig } });

    const [isFetching, { setTrue: startFetching, setFalse: stopFetching }] = useBoolean(false)
    const [paletteColorState, setPalletteColorState] = React.useState<TMainColor[]>(
        [
            { key: 'primary', value: props.branding?.config?.primary ?? defaultConfig.primary },
            { key: 'secondary', value: props.branding?.config?.secondary ?? defaultConfig.secondary },
            { key: 'tertiary', value: props.branding?.config?.tertiary ?? defaultConfig.tertiary }
        ]
    )

    const onChangePaletteColor = (key: string, value: string, previousValue: string) => {    
        
        const currentMainColors = paletteColorState.map((color) => {
            if (color.key === key) {
                return { key: key, value: value }
            }
            return color
        })
        setPalletteColorState([...currentMainColors])
        onChangePreviousValuePalette(value, previousValue)
    }
    const onChangePreviousValuePalette = (currentValue: string, prevValue?: string,) => {
        let config = getValues().config as IBrandingConfig
        let darkConfig = getValues().darkConfig as IBrandingConfig
        if (config.accent === prevValue) {
            setValue("config.accent", currentValue)
        }
        if (config.fontColorHeadings === prevValue) {
            setValue("config.fontColorHeadings", currentValue)
        }
        if (config.fontColorBody === prevValue) {
            setValue("config.fontColorBody", currentValue)
        }
        if (config.onlineButton.primary === prevValue) {
            setValue("config.onlineButton.primary", currentValue)
        }
        if (config.onlineButton.primaryFontColor === prevValue) {
            setValue("config.onlineButton.primaryFontColor", currentValue)
        }
        if (config.onlineButton.primaryOutline === prevValue) {
            setValue("config.onlineButton.primaryOutline", currentValue)
        }
        if (config.onlineButton.secondary === prevValue) {
            setValue("config.onlineButton.secondary", currentValue)
        }
        if (config.onlineButton.secondaryFontColor === prevValue) {
            setValue("config.onlineButton.secondaryFontColor", currentValue)
        }
        if (config.onlineButton.secondaryOutline === prevValue) {
            setValue("config.onlineButton.secondaryOutline", currentValue)
        }
        if (config.mobileTopBar.background === prevValue) {
            setValue("config.mobileTopBar.background", currentValue)
        }
        if (config.mobileTopBar.color === prevValue) {
            setValue("config.mobileTopBar.color", currentValue)
        }
        //Unfortunetly have to use copy\past setValue is very unflaxible 
        if (darkConfig.accent === prevValue) {
            setValue("darkConfig.accent", currentValue)
        }
        if (darkConfig.fontColorHeadings === prevValue) {
            setValue("darkConfig.fontColorHeadings", currentValue)
        }
        if (darkConfig.fontColorBody === prevValue) {
            setValue("darkConfig.fontColorBody", currentValue)
        }
        if (darkConfig.onlineButton.primary === prevValue) {
            setValue("darkConfig.onlineButton.primary", currentValue)
        }
        if (darkConfig.onlineButton.primaryFontColor === prevValue) {
            setValue("darkConfig.onlineButton.primaryFontColor", currentValue)
        }
        if (darkConfig.onlineButton.primaryOutline === prevValue) {
            setValue("darkConfig.onlineButton.primaryOutline", currentValue)
        }
        if (darkConfig.onlineButton.secondary === prevValue) {
            setValue("darkConfig.onlineButton.secondary", currentValue)
        }
        if (darkConfig.onlineButton.secondaryFontColor === prevValue) {
            setValue("darkConfig.onlineButton.secondaryFontColor", currentValue)
        }
        if (darkConfig.onlineButton.secondaryOutline === prevValue) {
            setValue("darkConfig.onlineButton.secondaryOutline", currentValue)
        }
        if (darkConfig.mobileTopBar.background === prevValue) {
            setValue("darkConfig.mobileTopBar.background", currentValue)
        }
        if (darkConfig.mobileTopBar.color === prevValue) {
            setValue("darkConfig.mobileTopBar.color", currentValue)
        }
    }   


    const onSubmit = async (data: IBranding) => {
        startFetching()
        let image = props.branding?.config?.logo
        let errors: IResponseError[] = []

        if (image !== fileSrc) {
            if (file) {
                setIsImageFetching(true)
                setImageErrors([])
                let uploadResult: IRequestResult<string> = { successed: false }
                if (file) {
                    uploadResult = await portalApi.settings.organization.uploadFile('branding-image', file, undefined, true);
                    if (uploadResult.errors && uploadResult.errors.length > 0) {
                        errors = uploadResult.errors;
                    }
                } else {
                    uploadResult.successed = true;
                }

                if (uploadResult.successed) {
                    image = uploadResult.data
                    setIsImageChanged(false)
                }
                setIsImageFetching(false)
            }
            else {
                image = fileSrc
            }
        }
        let imageDark = props.branding?.darkConfig?.logo
        if (imageDark !== fileDarkSrc) {
            if (fileDark) {
                setIsImageDarkFetching(true)
                setImageErrors([])
                let uploadResult: IRequestResult<string> = { successed: false }
                if (fileDark) {
                    uploadResult = await portalApi.settings.organization.uploadFile('branding-dark-image', fileDark, undefined, true);
                    if (uploadResult.errors && uploadResult.errors.length > 0) {
                        errors = uploadResult.errors;
                    }
                } else {
                    uploadResult.successed = true;
                }

                if (uploadResult.successed) {
                    imageDark = uploadResult.data
                    setIsImageDarkChanged(false)
                }
                setIsImageDarkFetching(false)
            }
            else {
                imageDark = fileDarkSrc
            }
        }
        if(data.darkConfig && data.config){
            data.darkConfig.primary = data.config.primary;
            data.darkConfig.secondary = data.config.secondary;
            data.darkConfig.tertiary = data.config.tertiary;
            data.darkConfig.accent = data.config.accent;       
            data.darkConfig.showLogoInMobile = isShowLogoInMobile;
            data.config.showLogoInMobile = isShowLogoInMobile;
        }        

        if (errors.length > 0) {
            setImageErrors(errors)
        } else {
            data = { ...data, imageSrc: image ?? '', config: { ...data.config, logo: image ?? '' } as IBrandingConfig 
            , darkImageSrc: imageDark ?? '', darkConfig: { ...data.darkConfig, logo: imageDark ?? '' } as IBrandingConfig}
        }
        props.onClickSubmit(data)
        startFetching()
    }

    const allowedFileTypes = ["image/png"]

    const [file, setFile] = React.useState<File>()
    const [isImageFetching, setIsImageFetching] = React.useState(false)
    const [isImageChanged, setIsImageChanged] = React.useState(false)
    const [imageErrors, setImageErrors] = React.useState<IResponseError[]>([])
    const [fileSrc, setFileSrc] = React.useState(props.branding?.config?.logo)
    //Dark
    const [fileDark, setFileDark] = React.useState<File>()
    const [isImageDarkFetching, setIsImageDarkFetching] = React.useState(false)
    const [isImageDarkChanged, setIsImageDarkChanged] = React.useState(false)  
    const [isShowLogoInMobile, setShowLogoInMobile] = React.useState(props.branding?.config?.showLogoInMobile == true)  
      
    const [fileDarkSrc, setFileDarkSrc] = React.useState(props.branding?.darkConfig?.logo)

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)
    const roles = useSelector((s: IStore) => s.environment.roles)
    const tooltipId = useId('tooltip');

    const addFile = (upFiles: FileList) => {
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => allowedFileTypes.includes(upFile.type))]
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            setIsImageChanged(true)
            setFileSrc(URL.createObjectURL(checkedUpFiles[0]))
            setFile(checkedUpFiles[0])
        }
    }

    const resetImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFileSrc(props.branding?.config?.logo)
            setFile(undefined)
            setIsImageChanged(false)
        }
    }

    const deleteImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFile(undefined)
            setFileSrc(undefined)
            setIsImageChanged(true)
        }
    }

    const addDarkFile = (upFiles: FileList) => {
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => allowedFileTypes.includes(upFile.type))]
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            setIsImageDarkChanged(true)
            setFileDarkSrc(URL.createObjectURL(checkedUpFiles[0]))
            setFileDark(checkedUpFiles[0])
        }
    }

    const resetDarkImageSrc = () => {
        if (fileDarkSrc) {
            URL.revokeObjectURL(fileDarkSrc)
            setFileDarkSrc(props.branding?.darkConfig?.logo)
            setFileDark(undefined)
            setIsImageDarkChanged(false)
        }
    }

    const deleteDarkImageSrc = () => {
        if (fileDarkSrc) {
            URL.revokeObjectURL(fileDarkSrc)
            setFileDark(undefined)
            setFileDarkSrc(undefined)
            setIsImageDarkChanged(true)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <ScrollablePane>
                    <Stack verticalFill style={{ position: 'relative' }}>
                        <Stack.Item>
                            <ScreenHeader title={strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_SET_TITLE}>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <SecondaryButton iconProps={{ iconName: 'Undo' }} onClick={props.hidePanel} />
                                    <PrimaryButton text={strings.BUTTONS.TEXT.SAVE} onClick={handleSubmit(onSubmit)} disabled={isFetching} />
                                    {(roles?.includes(UserRoles.Administrator) || roles?.includes(UserRoles.Supervisor)) && (
                                        <TooltipHost content={strings.CONSTRUCTOR.COMMAND_BAR.SHOW_CONFIG} directionalHint={DirectionalHint.bottomCenter} id={tooltipId}>
                                            <IconButton iconProps={{ iconName: 'View' }} onClick={showModal} />
                                        </TooltipHost>
                                    )}
                                </Stack>
                            </ScreenHeader>
                        </Stack.Item>
                        {isFetching && (
                            <Stack verticalFill grow verticalAlign="center" horizontalAlign="center">
                                <Spinner />
                            </Stack>
                        ) || (
                                <Stack tokens={{ padding: '0 0 24px 0' }}>
                                    <Stack.Item style={{ padding: "0px 32px 12px 32px" }}>
                                        <BrandingSetup control={control} errors={errors} />
                                    </Stack.Item>
                                    <Stack.Item style={{ padding: "0px 32px 12px 32px" }}>
                                        <BrandingColorPallete control={control} brandingItem={props.branding} mainColors={paletteColorState} onChangeMainColors={onChangePaletteColor} />
                                    </Stack.Item>
                                    <Stack.Item style={{ padding: "0px 32px 12px 32px" }}>
                                        <BrandingLogo control={control} getValues={getValues}
                                            addFile={addFile}
                                            addFileDark={addDarkFile}
                                            allowedFileTypes={allowedFileTypes}
                                            deleteImageSrc={deleteImageSrc}
                                            deleteImageDarkSrc={deleteDarkImageSrc}
                                            isImageChanged={isImageChanged}
                                            isImageDarkChanged={isImageDarkChanged}
                                            resetImageSrc={resetImageSrc}
                                            resetImageDarkSrc={resetDarkImageSrc}
                                            setIsImageChanged={setIsImageChanged}
                                            setIsImageDarkChanged={setIsImageDarkChanged}
                                            fileSrc={fileSrc}
                                            fileDarkSrc={fileDarkSrc}
                                            setShowLogoInMobile={setShowLogoInMobile}
                                            isShowLogoInMobile={isShowLogoInMobile}
                                            defaultLogo={"/noLogo.svg"} />
                                    </Stack.Item>
                                    <Stack.Item style={{ padding: "0px 32px 12px 32px" }}>
                                        <BrandingMobileTopBar control={control} brandingItem={props.branding} mainColors={paletteColorState} defaultDarkConfig={defaultDarkConfig} setValue={setValue} getValues={getValues}/>
                                    </Stack.Item>
                                    <Stack.Item style={{ padding: "0px 32px 12px 32px" }}>
                                        <BrandingTypeografy control={control} mainColors={paletteColorState} defaultDarkConfig={defaultDarkConfig} setValue={setValue} getValues={getValues}/>
                                    </Stack.Item>
                                    <Stack.Item style={{ padding: "0px 32px 12px 32px" }}>
                                        <BrandingOnlineButtonColorScheme control={control} brandingItem={props.branding} mainColors={paletteColorState} 
                                        defaultDarkConfig={defaultDarkConfig} setValue={setValue} getValues={getValues}/>
                                    </Stack.Item>
                                </Stack>
                            )}
                    </Stack>
                </ScrollablePane>
                <Modal isOpen={isModalOpen} onDismiss={hideModal} containerClassName={contentStyles.container} styles={{ main: { display: "flex", overflow: 'hidden' } }}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>
                            {strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.CONFIGURATION}
                        </span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close window"
                            onClick={hideModal}
                        />
                    </div>
                    <ShowBrandingConfiguration json={props.branding?.id ? props.branding : getValues()} />
                </Modal>
            </Stack>
        </form>
    )
}
const theme = getTheme()
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        maxWidth: 1000,
        width: 800,
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
