import { Stack, Icon, Text, SharedColors, Image, ImageFit, Link, DefaultButton, MessageBar, MessageBarType } from "@fluentui/react"
import { getTheme } from "@fluentui/style-utilities"
import { useId } from "@uifabric/react-hooks"
import React, { ChangeEvent, EventHandler } from "react"
import { SecondaryButton } from "../buttons/SecondaryButton"
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../core/scripts/style"
import { strings } from "../../../localization/strings"
import defaultIcon from '../../../assets/service.svg'
import unknown from "../../../assets/emptyimage.svg"

type TProps = {
    imgSrc?: string
    file?: File
    fileSrc?: string
    addFile: (files: FileList) => void
    iconColor?: string
    itemName?: string
    type: "icon" | "image"
    acceptTypeMask?: string
    brandingProps?: boolean
    lable: string
    description?: boolean
    allowedFileTypes: string[]
    allowedFileTypesText?: string
    generateIcon?: boolean
    size?: number
    defaultIcon?: string
    borderRadius?: number
    changed: boolean
    noHideRemoveButton?: boolean
    removeImageText?: string
    uploadImageText?: string
    withoutButtonLabel?: boolean
    resetChanged: () => void
    resetImageSrc: () => void
    deleteImageSrc: () => void
    width?: number
    getImageSrc?: string
    onGetImageSrc?: () => void
    imageFit?: ImageFit
    buttonDisabled?: boolean
}

export const ChooseIcon: React.FC<TProps> = (props) => {

    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const inputFileButtonId = useId('input-file-button-id');
    const allowTypes = props.allowedFileTypes.map((type) => ' ' + type.split('/')[1].toUpperCase());

    const handleFileInput = (event: ChangeEvent <HTMLInputElement>, props: TProps): void => {
        setErrorMessage("");
        const file = event.target.files;
        console.log(file);
        const maximumFileSize = props.brandingProps ? 1000 * 256 : 1000 * 512;
        const validImageExtensions = props.brandingProps ? ["png"] : ["png", "jpeg", "svg"];

        if(!file){
            return;
        }

        const fileTypeExtension = [...Array.from(file).map(file => file.type.split('/')[1])];

        const hasValidExtensions = fileTypeExtension.every(extensions => validImageExtensions.includes(extensions));
        const isFileTooBig = [...Array.from(file).map(file => file.size > maximumFileSize)];

        if(!hasValidExtensions){
            setErrorMessage(strings.CONSTRUCTOR.COMMON.IMAGE.ERRORS.FILE_FORMAT);   
            return;
        }
        if(isFileTooBig.includes(true)){
            setErrorMessage(strings.CONSTRUCTOR.COMMON.IMAGE.ERRORS.FILE_LIMIT);
            return;
        }

        props.addFile(file);
    }

    React.useEffect(() => {
        setErrorMessage("");
    },[props.changed])

    return (
        <Stack styles={{ root: { width: props.width ?? 400 } }} tokens={{ childrenGap: 16 }}>
            <Stack tokens={{ childrenGap: 16 }}>
                {props.allowedFileTypesText && (
                    <Stack>
                        <Text style={{ color: SharedColors.gray30, fontSize: 16, width: 400 }}>{props.allowedFileTypesText + allowTypes}</Text>
                    </Stack>
                )}
                <Stack tokens={{ padding: '8px 0px 0px 0px', childrenGap: 24 }} horizontal verticalAlign='center'>
                    <Stack verticalAlign="center" horizontalAlign='center' tokens={{ childrenGap: 8 }} >
                        <Stack verticalAlign="center"
                            horizontalAlign="center"
                            style={{
                                width: props.size ?? 72,
                                height: props.size ?? 72,
                                borderRadius: props.borderRadius ?? theme.effects.roundedCorner6,
                                backgroundColor: !props.fileSrc && props.generateIcon ?
                                    (props.itemName ?
                                        getPersonaColorsPallete[props.itemName[0].toLowerCase()] :
                                        undefined) :
                                    undefined
                            }}>
                            {props.fileSrc && (
                                <Stack horizontalAlign='center'>
                                    <Image src={props.fileSrc} width={props.size ?? 72} height={props.size ?? 72} imageFit={props.imageFit ?? ImageFit.contain} style={{ borderRadius: props.borderRadius ?? theme.effects.roundedCorner4 }} />
                                </Stack>
                            ) || (props.generateIcon && (
                                <Text style={{ color: "#fff", fontSize: 28, fontWeight: 400, paddingBottom: 2 }}>
                                    {getIconSymbolsFromString(props.itemName)}
                                </Text>
                            ) || (
                                    <Stack horizontalAlign='center'>
                                        <Image src={props.defaultIcon || defaultIcon} width={props.size ?? 72} height={props.size ?? 72} imageFit={props.imageFit ?? ImageFit.contain} style={{ borderRadius: props.borderRadius ?? theme.effects.roundedCorner4 }} />
                                    </Stack>
                                )
                                )}
                        </Stack>
                    </Stack>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Stack.Item>
                                {!props.withoutButtonLabel && (
                                    <Text>{props.lable}</Text>
                                )}
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                    <DefaultButton disabled={props.buttonDisabled} style={{ height: 36 }} onClick={() => {
                                        const el = document.getElementById(inputFileButtonId)
                                        if (el) {
                                            (el as HTMLInputElement).value = ''
                                            el.click()
                                        }
                                    }}>{!props.file ? props.uploadImageText ? props.uploadImageText : strings.BUTTONS.TEXT.CHOOSE_FILE : strings.BUTTONS.TEXT.CHOOSE_ANOTHER_FILE}</DefaultButton>
                                    {props.removeImageText && props.fileSrc && props.changed && (
                                        <DefaultButton style={{ height: 36 }} onClick={props.resetImageSrc}>{props.removeImageText}</DefaultButton>
                                    ) || props.fileSrc && props.changed && (
                                        <Link onClick={props.resetImageSrc}>{strings.BUTTONS.TEXT.RESET}</Link>
                                    )}
                                    {props.removeImageText && props.imgSrc && !props.changed && (
                                        <DefaultButton onClick={props.deleteImageSrc} style={{ height: 36 }}>{props.removeImageText}</DefaultButton>
                                    ) || props.imgSrc && !props.changed && (
                                        <Link onClick={props.deleteImageSrc}>{strings.BUTTONS.TEXT.DELETE}</Link>
                                    )}
                                    {props.removeImageText && props.noHideRemoveButton && !props.fileSrc && (
                                        <DefaultButton disabled onClick={props.deleteImageSrc} style={{ height: 36 }}>{props.removeImageText}</DefaultButton>
                                    )}
                                    {props.onGetImageSrc && props.getImageSrc !== props.fileSrc && <Link onClick={props.onGetImageSrc}>{strings.BUTTONS.TEXT.AUTODETECT}</Link>}
                                </Stack>
                                <input accept={props.acceptTypeMask} id={inputFileButtonId} hidden type="file" onChange={(ev) => handleFileInput(ev, props)} onClick={() =>setErrorMessage("")} />
                            </Stack.Item>

                        </Stack>
                    </Stack.Item>
                </Stack>
                {errorMessage !== "" && (
                    <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
                        <MessageBar messageBarType={MessageBarType.error}>
                            {errorMessage}
                        </MessageBar>                        
                    </Stack>
                )}
                {props.description && (
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <Stack.Item>
                            <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20 }} styles={iconButtonStyles} />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Text style={{ fontWeight: 500, color: SharedColors.gray20 }}>{strings.CONSTRUCTOR.COMMON.IMAGE_DESCRIPTION}</Text>
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};