import { Stack, IconButton, PrimaryButton, TextField } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../localization/strings";
import { onRenderLabel } from "../../../../renders/onRenderLabel";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { IIdentificationConfig, ProcessStages } from "@piceasoft/core";

type TProps = {
    config: IIdentificationConfig
    onConfigCommit: (data: IIdentificationConfig) => void
    channel: number
}

export const IdentificationStageDeviceGroupsSettingsPivot: React.FC<TProps> = ({ config, onConfigCommit, channel }) => {

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.DEVICE_GROUPS.USE_DEVICE_GROUPS_TITLE}
                noSeparator
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, ProcessStages.Identification, 'Device groups', 'Allowed device groups (from the workflow catalog)')}
                toggleChecked={config.deviceGroups ? true : false}
                toggleOnChange={() => {
                    onConfigCommit({
                        ...config,
                        deviceGroups: config.deviceGroups ? undefined : []
                    })
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.DEVICE_GROUPS.USE_DEVICE_GROUPS_DESCRIPTION}
            >
                {config?.deviceGroups && (
                    <Stack tokens={{ childrenGap: 4 }}>
                        {config.deviceGroups?.map((i, index) => <Stack.Item key={index} grow>
                            <Stack verticalFill grow horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                                <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                    <IconButton disabled={index === 0}
                                        iconProps={{ iconName: 'Up' }}
                                        onClick={() => index > 0 && onConfigCommit({
                                            ...config,
                                            deviceGroups: config.deviceGroups?.map((i, localIndex) => {
                                                if (localIndex === index && config.deviceGroups && config.deviceGroups[localIndex - 1]) {
                                                    return config.deviceGroups[localIndex - 1]
                                                }
                                                if (localIndex === index - 1 && config.deviceGroups && config.deviceGroups[localIndex + 1]) {
                                                    return config.deviceGroups[localIndex + 1]
                                                }
                                                return i
                                            })
                                        }
                                        )}
                                    />
                                </Stack>
                                <Stack grow style={{ paddingTop: 2 }} horizontal verticalAlign='center' tokens={{ childrenGap: 24 }}>
                                    <Stack.Item>
                                        <TextField styles={{ field: { width: 400 } }} onRenderLabel={onRenderLabel}
                                            // label={index === 0 ? strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CANCEL_REASONS.FIELD_NAME : undefined} 
                                            value={i}
                                            onChange={(ev, val) => onConfigCommit({
                                                ...config,
                                                deviceGroups: config.deviceGroups?.map((i, localIndex) => {
                                                    if (index === localIndex) {
                                                        return val ?? ""
                                                    }
                                                    return i
                                                })
                                            }
                                            )}
                                        />
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                    <IconButton
                                        iconProps={{ iconName: 'Delete' }}
                                        onClick={() => onConfigCommit({
                                            ...config,
                                            deviceGroups: config.deviceGroups?.filter((i, localIndex) => localIndex !== index)
                                        }
                                        )}
                                    />
                                </Stack>
                            </Stack>
                        </Stack.Item>)}
                        <Stack horizontal tokens={{ childrenGap: 16 }} style={{ padding: "16px 0px 0px 48px" }}>
                            <PrimaryButton iconProps={{ iconName: "Add" }} onClick={() => onConfigCommit({
                                ...config,
                                deviceGroups: config.deviceGroups ? [...config.deviceGroups, ""] : [""]
                            })}>
                                {strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.DEVICE_GROUPS.ADD_DEVICE_GROUP}
                            </PrimaryButton>
                        </Stack>
                    </Stack>
                )}
            </ParameterItem>
        </Stack>
    )
}