import { Modal, IconButton, Stack, MessageBar, MessageBarType, ProgressIndicator, Icon, Text, SharedColors, Spinner, SpinnerSize, FontIcon, Image, ImageFit, Link } from "@fluentui/react"
import { useBoolean } from "@fluentui/react-hooks"
import { getTheme, mergeStyleSets, FontWeights } from "@fluentui/style-utilities"
import { useId } from "@uifabric/react-hooks"
import React from "react"
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton"
import { IRequestResult } from "../../../core/store/typings/IResponseResult"
import { strings } from "../../../localization/strings"


type TProps = {
    show: boolean
    onHide: () => void
    src?: string
    iconColor?: string
    type: "icon" | "image"
    acceptTypeMask?: string
    lable: string
    description?: string
    allowedFileTypes: string[]
    title: string
    onSuccessed?: () => void
    sendParams?: { [key: string]: number | string }
    sendMethod: (file: File, setResponse: (response: IRequestResult<any>) => void, setUploadProgress: (value: number) => void) => void
    sendDelete: (setResponse: (response: IRequestResult<any>) => void) => void
    onRenderImgPlaceHolder?: () => JSX.Element
}

export const Updatelogo: React.FC<TProps> = (props) => {
    const [uploadProgress, setUploadProgress] = React.useState(0)
    const [file, setFile] = React.useState<File>()
    const [fileName, setFileName] = React.useState<string>()
    const [imageSrc, setImageSrc] = React.useState(props.src)
    const [isFetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const [deleteLogo, { setTrue: setDelete }] = useBoolean(false)
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [response, setResponse] = React.useState<IRequestResult<undefined>>()
    // const [errors, setErrors] = React.useState<string[]>([])
    const inputFileButtonId = useId('input-file-button-id')

    React.useEffect(() => {
        if (response) {
            // if (response.successed) {
            // setTimeout(() => {
            props.onHide()
            // props.onSuccessed && props.onSuccessed()
            // }, 1500);
            // }
            // if (response.errors) {
            // setErrors(response.errors.map(e => e.description))
            // }
            stopFetch()
        }
    }, [response])

    const addFile = (upFiles: FileList) => {
        setErrorMessage("");
        const maximumFileSize = 512 * 1000;
        var checkedUpFiles = [...Array.from(upFiles).filter(upFile => props.allowedFileTypes.includes(upFile.type))]
        const fileSizeLimitReached = [...Array.from(checkedUpFiles).map(checkedUpFiles => checkedUpFiles.size > maximumFileSize)];

        if(checkedUpFiles.length < 1){
            setErrorMessage(strings.CONSTRUCTOR.COMMON.IMAGE.ERRORS.FILE_FORMAT);
            return;
        }
        if(fileSizeLimitReached.includes(true)){
            setErrorMessage(strings.CONSTRUCTOR.COMMON.IMAGE.ERRORS.FILE_LIMIT);
            return;
        }
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            if (props.type === "image") {
                setImageSrc(URL.createObjectURL(checkedUpFiles[0]))
            }
            setFile(checkedUpFiles[0])

            const nameParts = checkedUpFiles[0].name
            setFileName(nameParts)
        }
    }

    const resetImageSrc = () => {
        if (imageSrc) {
            URL.revokeObjectURL(imageSrc)
            setImageSrc(props.src)
            setFile(undefined)
            setFileName(undefined)
            setErrorMessage("");
        }
    }

    const onDelete = () => {
        if (imageSrc) {
            setImageSrc(undefined)
            setDelete()
            setErrorMessage("");
        }
    }

    const confirmHandler = async () => {
        if (file) {
            // setErrors([])
            startFetch()
            props.sendMethod(file, setResponse, setUploadProgress)
        }
    }

    const deleteHandler = async () => {
        // setErrors([])
        startFetch()
        props.sendDelete(setResponse)
    }

    return (
        <Modal isOpen={props.show ? true : false} onDismiss={props.onHide} containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span style={{ paddingRight: 32 }}>{props.title}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close"
                    onClick={props.onHide}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack tokens={{ childrenGap: 16 }}>
                    {!isFetching && !response?.successed && (
                        <Stack tokens={{ childrenGap: 16 }}>
                            <Stack.Item>
                                <Stack grow tokens={{ padding: '24px 0px 24px 0px', childrenGap: 24 }} horizontal verticalFill verticalAlign='center'>
                                    <Stack verticalFill verticalAlign="center" horizontalAlign='center' tokens={{ childrenGap: 8 }} style={{ minHeight: 100 }}>
                                        {props.type === "icon" && <Icon iconName={props.src} style={{ fontSize: 56, color: props.iconColor }} />}
                                        {props.type === "image" && imageSrc && <Image src={imageSrc} style={{ color: props.iconColor }} width={72} height={72} imageFit={ImageFit.cover} />}
                                        {imageSrc && imageSrc !== props.src && <Link onClick={resetImageSrc}>{strings.BUTTONS.TEXT.RESET}</Link>}
                                        {imageSrc && imageSrc === props.src && <Link onClick={onDelete}>{strings.BUTTONS.TEXT.DELETE}</Link>}
                                        {!imageSrc && props.onRenderImgPlaceHolder && props.onRenderImgPlaceHolder()}
                                    </Stack>
                                    <Stack.Item>
                                        <Stack tokens={{ childrenGap: 8 }}>
                                            <Stack.Item>
                                                <Text>{props.lable}</Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                                    <SecondaryButton onClick={() => {
                                                        const el = document.getElementById(inputFileButtonId)
                                                        if (el) {
                                                            (el as HTMLInputElement).value = ''
                                                            el.click()
                                                        }
                                                    }}>{!file ? strings.BUTTONS.TEXT.CHOOSE_FILE : strings.BUTTONS.TEXT.CHOOSE_ANOTHER_FILE}</SecondaryButton>
                                                    {fileName && (
                                                        <Text style={{ color: SharedColors.cyanBlue10, fontWeight: 600 }}>{fileName}</Text>
                                                    )}
                                                </Stack>
                                                <input onClick={() => setErrorMessage("")} accept={props.acceptTypeMask} id={inputFileButtonId} hidden type="file" onChange={(ev) => ev.target.files && addFile(ev.target.files)} />
                                            </Stack.Item>

                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            {errorMessage !== "" && (
                            <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
                                <MessageBar messageBarType={MessageBarType.error}>
                                    {errorMessage}
                                </MessageBar>                        
                            </Stack>
                            )}
                            {props.description && (
                                <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
                                    <Stack.Item>
                                        <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20 }} title="Info" ariaLabel="Info" styles={iconButtonStyles} />
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Text style={{ fontWeight: 500, color: SharedColors.gray20 }}>{props.description}</Text>
                                    </Stack.Item>
                                </Stack>
                            )}
                        </Stack>
                    )}
                    {/* {errors && errors.map((e, i) => <MessageBar key={e + i} messageBarType={MessageBarType.error}>{e}</MessageBar>)} */}
                    {isFetching && !response && (
                        <ProgressIndicator description={strings.SPINNERS.WAITING_PROCESSING_DESCRIPTION} label={strings.SPINNERS.WAITING_PROCESSING_LABEL} percentComplete={uploadProgress} />
                    )}
                    {/* {response?.successed && (
                        <Stack tokens={{ childrenGap: 8 }} grow horizontalAlign='center'>
                            <FontIcon iconName={"CompletedSolid"} style={{ fontSize: 48, height: 48, width: 48, color: SharedColors.green10 }} />
                            <Stack.Item grow={1}>
                                <Text variant="medium">{strings.ORGANIZATION.STRUCTURE.TREE.IMPORT_FROM_EXCEL.SUCCESS}</Text>
                            </Stack.Item>
                        </Stack>
                    )} */}
                    {/* {!response?.successed && ( */}
                    <Stack horizontal horizontalAlign={"end"} tokens={{ childrenGap: 8 }}>
                        {isFetching && (
                            <Stack verticalAlign="center" horizontalAlign="center">
                                <Spinner size={SpinnerSize.small} />
                            </Stack>
                        )}
                        {!deleteLogo && <SecondaryButton onClick={confirmHandler} disabled={!file || isFetching}>{strings.BUTTONS.TEXT.CONFIRM}</SecondaryButton>}
                        {deleteLogo && <SecondaryButton onClick={deleteHandler}>{strings.BUTTONS.TEXT.CONFIRM}</SecondaryButton>}
                        {props.onHide !== undefined && <SecondaryButton onClick={props.onHide}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>}
                    </Stack>
                    {/* )} */}
                </Stack>
            </div>
        </Modal>
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 540,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};