import { Stack, IconButton, PrimaryButton, TextField } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../localization/strings";
import { onRenderLabel } from "../../../../renders/onRenderLabel";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess, Experience } from "../../helpers/evisibility";
import { IStageConfig, ProcessStages, IStageConfigUI } from "@piceasoft/core";

type TProps = {
    config: IStageConfig<IStageConfigUI>
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    channel: number
}

export const StageCancelReasonsPivot: React.FC<TProps> = ({ config, onChangeDispatch, stage, channel }) => {

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CANCEL_REASONS.USE_CANCEL_REASONS_TITLE}
                noSeparator
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Cancel reasons', 'Use step cancel reasons')}
                toggleChecked={config?.cancelOptions ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        cancelOptions: config?.cancelOptions ? undefined : []
                    }
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CANCEL_REASONS.USE_CANCEL_REASONS_DESCRIPTION}
            >
                {config?.cancelOptions && (
                    <Stack tokens={{ childrenGap: 4 }}>
                        {config.cancelOptions?.map((i, index) => <Stack.Item key={index} grow>
                            <Stack verticalFill grow horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                                <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                    <IconButton disabled={index === 0}
                                        iconProps={{ iconName: 'Up' }}
                                        onClick={() => index > 0 && onChangeDispatch(actionCreators.editStage(stage, {
                                            ...config,
                                            cancelOptions: config.cancelOptions?.map((i, localIndex) => {
                                                if (localIndex === index && config.cancelOptions && config.cancelOptions[localIndex - 1]) {
                                                    return config.cancelOptions[localIndex - 1]
                                                }
                                                if (localIndex === index - 1 && config.cancelOptions && config.cancelOptions[localIndex + 1]) {
                                                    return config.cancelOptions[localIndex + 1]
                                                }
                                                return i
                                            })
                                        }
                                        ))}
                                    />
                                </Stack>
                                <Stack grow style={{ paddingTop: 2 }} horizontal verticalAlign='center' tokens={{ childrenGap: 24 }}>
                                    <Stack.Item>
                                        <TextField styles={{ field: { width: 400 } }} onRenderLabel={onRenderLabel}
                                            // label={index === 0 ? strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CANCEL_REASONS.FIELD_NAME : undefined} 
                                            value={i}
                                            onChange={(ev, val) => onChangeDispatch(actionCreators.editStage(stage, {
                                                ...config,
                                                cancelOptions: config.cancelOptions?.map((i, localIndex) => {
                                                    if (index === localIndex) {
                                                        return val ?? ""
                                                    }
                                                    return i
                                                })
                                            }
                                            ))}
                                        />
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                    <IconButton
                                        iconProps={{ iconName: 'Delete' }}
                                        onClick={() => onChangeDispatch(actionCreators.editStage(stage, {
                                            ...config,
                                            cancelOptions: config.cancelOptions?.filter((i, localIndex) => localIndex !== index)
                                        }
                                        ))}
                                    />
                                </Stack>
                            </Stack>
                        </Stack.Item>)}
                        <Stack horizontal tokens={{ childrenGap: 16 }} style={{ padding: "16px 0px 0px 48px" }}>
                            {/* <IconButton iconProps={{ iconName: "Add" }} onClick={() => onChangeDispatch(actionCreators.editStageCancelOptions(stage, cancelOptions ? [...cancelOptions, ""] : [""]))} /> */}
                            <PrimaryButton iconProps={{ iconName: "Add" }} onClick={() => onChangeDispatch(actionCreators.editStage(stage, {
                                ...config,
                                cancelOptions: config.cancelOptions ? [...config.cancelOptions, ""] : [""]
                            }))}>
                                {strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CANCEL_REASONS.ADD_CANCEL_OPTION}
                            </PrimaryButton>
                        </Stack>
                    </Stack>
                )}
            </ParameterItem>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CANCEL_REASONS.GOODBYE_ON_CANCEL_TITLE}
                            useToggle                            
                            toggleEnabled={channel != Experience.Retail}
                            toggleChecked={config.goodbyeOnCancel}
                            toggleOnChange={() => {
                                onChangeDispatch(actionCreators.editStage(stage, {
                                    ...config,
                                    goodbyeOnCancel: config.goodbyeOnCancel ? undefined : true
                                }))
                            }}
                            description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.CANCEL_REASONS.GOODBYE_ON_CANCEL_DESCRIPTION}
                        />
        </Stack>
    )
}