import { Stack } from '@fluentui/react';
import * as React from 'react';
import Inspection from '../Inspection';
import { Inspections, IManualGradingConfig, IManualGradingDefaultConfig } from "@piceasoft/core";
import { noop } from '../../../../../../core/helpers/common';
import { ManualGradesState } from './preview/ManualGradesState';
import { strings } from '../../../../../../localization/strings';

type TProps = {
    config?: IManualGradingConfig
}

export const ManualGradesStatePreview: React.FC<TProps> = (props) => {
    if (props.config === undefined) return null;

    return (
        <Stack grow tokens={{ padding: "32px 32px 32px 32px" }}>
            <Inspection inspection={Inspections.ManualGrading}
                icon={props.config.ui?.icon ?? "ViewList"}
                title={props.config.ui?.title ?? strings.TRANSACTION.GRADING.MAIN_TITLE}
                allowRestart={props.config.allowRestart}
                onStart={noop}
                onRestart={noop}
                dependency={props.config.dependency}
                skips={props.config.skip}
                autoRun={props.config.autoRun}
                executeButtonText={props.config.ui?.executeButtonText}
                repeatButtonText={props.config.ui?.restartButtonText}
            >
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    <ManualGradesState config={props.config.config as IManualGradingDefaultConfig}
                        description={strings.CONSTRUCTOR.GRADES.GRADE_PLACEHOLDER}
                        icon={props.config.ui?.icon} />
                </Stack>
            </Inspection>
        </Stack>
    );
}