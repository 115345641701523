import { DetailsList, DetailsListLayoutMode, DirectionalHint, FontIcon, getTheme, IColumn, MessageBar, MessageBarType, SelectionMode, SharedColors, Stack, StackItem, Text, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import { verifyDescriber, verifyStatusDescriber } from '../../../../../core/scripts/picea.describer';
import { ISoftwareConfig, ISoftwarePiceaConfig } from '../../../../../core/store';
import { SoftwareModes, InspectionStatuses } from '@piceasoft/core';
import { IPiceaVerify, ISoftware, PiceaChecks } from '@piceasoft/core';
import { strings } from '../../../../../localization/strings';
import { VerifyStatuses } from '@piceasoft/core';
import { errorDescriber } from '../../../../../core/scripts/picea.errors';

type TProps = {
    result: ISoftware
    config: ISoftwareConfig
}

export const SoftwarePivot: React.FC<TProps> = (props) => {
    return (
        <>
            {props.config.config && (props.config.mode === SoftwareModes.Piceasoft || props.config.mode === SoftwareModes.PiceaMobile) && (
                <PiceasoftResult result={props.result} config={props.config.config as ISoftwarePiceaConfig} />
            )}
        </>
    )
}

type TPiceasoftResultProps = {
    config: ISoftwarePiceaConfig
    result: ISoftware
}

const PiceasoftResult: React.FC<TPiceasoftResultProps> = (props) => {

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            fieldName: 'item',
            minWidth: 250,
            isResizable: false,
            data: 'string',
            onRender: (item: IPiceaVerify) => onRenderCell(item),
            isPadded: true,
        }
    ]

    const onRenderCell = (item: IPiceaVerify | undefined, index?: number | undefined, isScrolling?: boolean | undefined): React.ReactNode => {
        let _color: string;
        let _icon: string;
        let _colorIMEI: string;
        let _iconIMEI: string;
        let result;
        const imeiString = strings.ATTRIBUTES.DEVICE.IMEI;
        const imeiString2 = strings.ATTRIBUTES.DEVICE.IMEI2;
        const statusString = strings.TRANSACTIONS.STATUS; 
        
        if (!item) return null;
        
        switch (item?.status) {
            case 1: _color = SharedColors.green10; _icon = "Accept"; break;
            case 2: _color = SharedColors.red10; _icon = "Warning"; break;
            default: _color = SharedColors.redOrange10; _icon = "Error"; break;
        }
        
        if(item.data && item.data[0] && item.data[0].value && item.data[0].value[0]){
            const validIMEI = item?.data[0].value[0];
            result  = item.data[0].value.map((e: any, index: number) =>{
                switch (e.status) {
                    case VerifyStatuses.VerifyStatus_DeviceStolenWarning: _colorIMEI = SharedColors.red10; _iconIMEI = "Warning"; break;
                    case VerifyStatuses.VerifyStatus_StolenCheckLegal: _colorIMEI = SharedColors.green10; _iconIMEI = "Accept"; break;
                    default: _colorIMEI = SharedColors.redOrange10; _iconIMEI = "Error"; break;
                }

                return (
                    <Stack style={{ marginTop: '5px', paddingLeft: "15px" }} horizontal key={index}>
                        <Stack style={{ width: "200px" }}>
                            <Text variant="medium" style={{ fontWeight: 600 }}>{ e === validIMEI ? imeiString : imeiString2}: {e.imei}</Text>
                            <Text variant="medium" style={{ color: _colorIMEI, fontWeight: 600 }}>{statusString}: {verifyStatusDescriber(e.status)}</Text>
                        </Stack>
                        <TooltipHost styles={{ root: { display: "flex" } }} directionalHint={DirectionalHint.rightCenter} content={`${verifyStatusDescriber(e.status)}`}>
                            <FontIcon style={{ color: _colorIMEI}} iconName={_iconIMEI} />
                        </TooltipHost>
                    </Stack>
                )
            });
        }

        if(item?.check === PiceaChecks.GSMA){
                return (
                    <Stack horizontal verticalAlign="center" style={{ padding: "0px 0" }} key={index}>
                        <Stack.Item grow>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                                <Text variant="medium" style={{ color: SharedColors.gray40, fontWeight: 600 }}>{verifyDescriber(item.check)}</Text>
                                <TooltipHost styles={{ root: { display: "flex" } }} directionalHint={DirectionalHint.rightCenter} content={`${strings.TRANSACTION.INSPECTIONS.SOFTWARE.CODE_STATUS} ${item.status}`}>
                                    <FontIcon style={{ color: _color }} iconName={_icon} />
                                </TooltipHost>
                            </Stack>
                            {result}
                        </Stack.Item>
                        <Stack.Item style={{ display: 'flex', flexDirection: 'column'}}>
                            <Text variant="medium" style={{ color: SharedColors.cyanBlue10, display: 'flex', justifyContent: 'flex-end' }}>{verifyStatusDescriber(item.status)}</Text>
                            { item?.error_code !== undefined && item?.error_code !== 0 && (
                                <Text variant="xSmall" style={{ color: SharedColors.red10 }}>{errorDescriber(item?.error_code)}</Text>
                            )}
                        </Stack.Item>
                    </Stack>
                )
        }
        return (
            <Stack horizontal verticalAlign="center" style={{ padding: "0px 0" }}>
                <Stack.Item grow>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                        <Text variant="medium" style={{ color: SharedColors.gray40, fontWeight: 600 }}>{verifyDescriber(item.check)}</Text>
                        <TooltipHost styles={{ root: { display: "flex" } }} directionalHint={DirectionalHint.rightCenter} content={`${strings.TRANSACTION.INSPECTIONS.SOFTWARE.CODE_STATUS} ${item.status}`}>
                            <FontIcon style={{ color: _color }} iconName={_icon} />
                        </TooltipHost>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    {item.status === VerifyStatuses.VerifyStatus_Unknown &&
                        <Text variant="medium" style={{ color: SharedColors.cyanBlue10 }}>{errorDescriber(item.error_code as number)}</Text>
                        || <Text variant="medium" style={{ color: SharedColors.cyanBlue10 }}>{verifyStatusDescriber(item.status)}</Text>
                    }
                    
                </Stack.Item>
            </Stack>
        )
    }

    return (
        <Stack>
            {props.result.status === InspectionStatuses.Run && (
                <Stack tokens={{ padding: 24 }}>
                    <MessageBar messageBarType={MessageBarType.warning} styles={{ root: { boxShadow: theme.effects.elevation8 } }}>
                        {strings.TRANSACTION.INSPECTIONS.SOFTWARE.RESULT_STATUS_RUN}
                    </MessageBar>
                </Stack>
            )}
            <Stack.Item>
                {props.result.checks && props.result.checks.length > 0 && (
                    <DetailsList
                        items={props.result.checks?.filter(i => i.status !== 8)}
                        columns={columns}
                        isHeaderVisible={false}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                    />
                )}
                { (!props.result.checks || props.result.checks.length === 0) && (<Stack tokens={{ padding: "12px"}}>
                    <Text variant="medium" style={{ color: SharedColors.cyanBlue10 }}>{strings.INSPECTIONS.SOFTWARE.NO_SUPPORTED_CHECKS}</Text>
                </Stack>)}
            </Stack.Item>
        </Stack>
    )
}
const theme = getTheme();
