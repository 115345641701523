import { getTheme, INavLink, INavLinkGroup, MessageBar, MessageBarType, ScrollablePane, SharedColors, Spinner, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../core/actions/settings-actions';
import { IStore } from '../../core/store';
import { IOrganizationSettings } from '../../core/store/typings/IOrganizationSettings';
import { strings } from '../../localization/strings';
import { SettingsTabs } from './SettingsTabs';
import { TabBasedState } from './TabBasedState';
import { UserRoles } from '../../core/store/typings/UserRoles';

export type TTabState = 'GENERAL.OVERVIEW' | 'GENERAL.LOCALE' | 'GENERAL.BILLING' | 'GENERAL.NOTIFICATIONS' | 'GENERAL.ADDITIONAL' |
    'AUTHORIZATION.BASIC' | 'AUTHORIZATION.CERTIFICATES' | 'SECURITY.PRIVATE_KEY' | 'SECURITY.PICEASOFT' |
    'INTEGRATION.HTTP' | 'INTEGRATION.FTP'

export const Settings: React.FC = () => {

    const [selectedTabKey, setSelectedTabKey] = React.useState<TTabState>('GENERAL.OVERVIEW');

    const handleTabLinkClick = (item?: INavLink) => {
        setSelectedTabKey(item?.key as TTabState);
    };

    const settings = useSelector((s: IStore) => s.settings)

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!settings.isReady) {
            dispatch(actionCreators.requestOrganizationSettings())
        }
    }, [])

    const roles = useSelector((s: IStore) => s.environment.roles) ?? [];
    const canAccessPiceasoftOption = roles.find(i => [UserRoles.Organization, UserRoles.Administrator, UserRoles.Supervisor].includes(i as UserRoles))

    const navGroups: INavLinkGroup[] = [
        {
            name: strings.SETTINGS.NAVIGATION.GENERAL.TITLE,
            links: [
                {
                    name: strings.SETTINGS.NAVIGATION.GENERAL.OVERVIEW.TITLE,
                    url: '',
                    icon: '/OEM',
                    iconProps: {},
                    key: 'GENERAL.OVERVIEW'
                },
                {
                    name: strings.SETTINGS.NAVIGATION.GENERAL.LOCALE.TITLE,
                    url: '',
                    icon: '/WorldClock',
                    iconProps: {},
                    key: 'GENERAL.LOCALE'
                },
                {
                    name: strings.SETTINGS.NAVIGATION.GENERAL.BILLING.TITLE,
                    url: '',
                    icon: '/BIDashboard',
                    iconProps: {},
                    key: 'GENERAL.BILLING'
                },
                {
                    name: strings.SETTINGS.NAVIGATION.GENERAL.NOTIFICATIONS.TITLE,
                    url: '',
                    icon: '/CannedChat',
                    iconProps: {},
                    key: 'GENERAL.NOTIFICATIONS'
                },
                {
                    name: strings.SETTINGS.NAVIGATION.GENERAL.ADDITIONAL.TITLE,
                    url: '',
                    icon: '/Settings',
                    iconProps: {},
                    key: 'GENERAL.ADDITIONAL'
                },
            ]
        },
        {
            name: strings.SETTINGS.NAVIGATION.AUTHORIZATION.TITLE,
            links: [
                {
                    name: strings.SETTINGS.NAVIGATION.AUTHORIZATION.BASIC.TITLE,
                    url: '',
                    icon: '/OfflineStorage',
                    iconProps: {},
                    key: 'AUTHORIZATION.BASIC'
                },
                {
                    name: strings.SETTINGS.NAVIGATION.AUTHORIZATION.CERTIFICATES.TITLE,
                    url: '',
                    icon: '/Certificate',
                    iconProps: {},
                    key: 'AUTHORIZATION.CERTIFICATES'
                },
            ]
        },
        (canAccessPiceasoftOption ? 
            {
                name: strings.SETTINGS.NAVIGATION.SECURITY.TITLE,
                links: [
                    {
                        name: strings.SETTINGS.NAVIGATION.SECURITY.PRIVATE_KEY.TITLE,
                        url: '',
                        icon: '/Lock',
                        iconProps: {},
                        key: 'SECURITY.PRIVATE_KEY'
                    },
                    {
                        name: strings.SETTINGS.SECURITY.PICEASOFT.PICESOFT_TITLE,
                        url: '',
                        icon: '/Lock',
                        iconProps: {},
                        key: 'SECURITY.PICEASOFT'
                    }
                ]
            } : {
                name: strings.SETTINGS.NAVIGATION.SECURITY.TITLE,
                links: [
                    {
                        name: strings.SETTINGS.NAVIGATION.SECURITY.PRIVATE_KEY.TITLE,
                        url: '',
                        icon: '/Lock',
                        iconProps: {},
                        key: 'SECURITY.PRIVATE_KEY'
                    }
                ]
            }
        ),
        
               
            
        
        // {
        //     name: strings.SETTINGS.NAVIGATION.INTEGRATION.TITLE,
        //     links: [
        //         {
        //             name: strings.SETTINGS.NAVIGATION.INTEGRATION.HTTP.TITLE,
        //             url: '',
        //             icon: '/Dataflows',
        //             iconProps: {},
        //             key: 'INTEGRATION.HTTP'
        //         },
        //         {
        //             name: strings.SETTINGS.NAVIGATION.INTEGRATION.FTP.TITLE,
        //             url: '',
        //             icon: '/DataflowsLink',
        //             iconProps: {},
        //             key: 'INTEGRATION.FTP'
        //         },
        //     ]
        // },

    ]

    return (
        <Stack horizontal grow verticalFill>
            {/* <Stack.Item>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 12 }}>
                    <FontIcon iconName="WebAppBuilderFragment" style={{ fontSize: 24, height: 24, width: 24 }} />
                    <Text>{strings.NAVIGATION.SETTINGS}</Text>
                </Stack>
            </Stack.Item> */}
            <Stack.Item verticalFill style={{ width: 250, borderRight: `1px solid ${theme.palette.neutralTertiaryAlt}` }}>
                <Stack verticalFill>
                    <Stack.Item>
                        <Stack tokens={{ padding: 20 }}>
                            <Text block nowrap style={{ color: theme.palette.black, fontSize: "1.3125rem", fontWeight: 600, letterSpacing: "-.02em" }}>{strings.SETTINGS.SETTINGS_TITLE}</Text>
                            <Text style={{ color: SharedColors.gray30, fontWeight: 600 }}>{settings.organization?.domain}</Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item verticalFill styles={{ root: { position: 'relative' } }}>
                        <ScrollablePane>
                            <SettingsTabs groups={navGroups} handleTabLinkClick={handleTabLinkClick} selectedTabKey={selectedTabKey} />
                        </ScrollablePane>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item verticalFill grow>
                <Stack verticalFill grow>
                    {settings.errors && (
                        < Stack.Item >
                            {settings.errors.map(e => <MessageBar messageBarType={MessageBarType.error}>
                                {e}
                            </MessageBar>)}
                        </Stack.Item>
                    )}
                    <Stack.Item grow verticalFill style={{ position: 'relative' }}>
                        <ScrollablePane>
                            {settings.isReady && (
                                <TabBasedState state={selectedTabKey} settings={settings.organization as IOrganizationSettings} isUpdating={settings.isUpdating} />
                            ) || (
                                    <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                        <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                                    </Stack>
                                )}
                        </ScrollablePane>
                    </Stack.Item>
                </Stack >
            </Stack.Item >
        </Stack >
    )
}

const theme = getTheme();