import { FontIcon, getTheme, IconButton, mergeStyleSets, Stack, TextField } from '@fluentui/react';
import * as React from 'react';
import { strings } from '../../../localization/strings';
import { FilterOption, TFilterOptionValue } from './FilterOption';

export type TFilterOptions = { [key: string]: { values: Array<TFilterOptionValue>, title?: string, hasValuesFilter?: boolean } }

type TProps = {
    options?: TFilterOptions,
    searchString?: string,
    selectedFilterOptions: TFilterOptions,
    setSelectedFilterOption: (name?: string, values?: TFilterOptionValue[], hasValuesFilter?: boolean) => void,
    setSearchString: (text?: string) => void,
    placeholder?: string
}

export const Filter: React.FC<TProps> = (props) => {

    function onChangeSearchText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string): void {
        props.setSearchString(text)
    }

    const clearAllFilter = () => {
        props.setSelectedFilterOption()
        props.setSearchString()
    }

    return (
        <Stack style={filterRootStyle} horizontal verticalAlign="center" tokens={{ childrenGap: 16, padding: 8 }}>
            <Stack horizontal grow verticalAlign="center" verticalFill style={{ paddingLeft: 8 }}>
                <FontIcon style={{ fontSize: 18 }} iconName="Filter" />
                <Stack.Item grow>
                    <TextField style={{ backgroundColor: theme.palette.neutralLight }} borderless value={props.searchString ?? ""} placeholder={props.placeholder ?? strings.FILTER.PLACEHOLDER} onChange={onChangeSearchText} />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 16 }} horizontal>
                {props.options && Object.keys(props.options).map(k => {
                    if (props.options && props.options[k].values && props.options[k].values.length > 1) {
                        return (
                            <FilterOption key={k}
                                name={k}
                                hasValuesFilter={props.options && props.options[k].hasValuesFilter}
                                title={props.options && props.options[k].title}
                                values={props.options && props.options[k].values && [...props.options[k].values]}
                                selectedOptions={props.selectedFilterOptions[k]?.values ?? []}
                                setSelectedFilterOption={props.setSelectedFilterOption} />
                        )
                    }
                    return null
                })}
            </Stack>
            <Stack.Item>
                <IconButton
                    disabled={Object.values(props.selectedFilterOptions).find(i => (i.values && i.values.length > 0)) === undefined ? true : false}
                    className={classNames.iconButton}
                    iconProps={{ iconName: "ClearFilter", style: { fontSize: 18 } }}
                    ariaLabel={strings.FILTER.CLEAR_BUTTON_ARIALABLE}
                    onClick={clearAllFilter}
                />
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
const filterRootStyle = {
    backgroundColor: theme.palette.neutralLight,
    borderRadius: 6
}
const classNames = mergeStyleSets({
    iconButton: [
        // getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.neutralLight, borderColor: theme.palette.neutralLight }),
        {
            height: 30,
            color: theme.palette.neutralDark,
            selectors: {
                '&:hover': {
                    color: theme.palette.red,
                    backgroundColor: theme.palette.neutralQuaternary
                }
            },
        },
    ]
});