import { PrimaryButton, Slider, Stack } from "@fluentui/react"
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { IContractField } from "@piceasoft/core";
import { strings } from "../../../../../localization/strings";
import { onRenderLabel } from "../../../../renders/onRenderLabel";
import { SecondaryButton } from "../../../buttons/SecondaryButton";
import { getSliderLabel } from "../common/helpers/fuctions";
import { CSHHelpLink } from "../../../help/CSHHelp";
import { ParameterText } from '../../../configurator/components/ParameterText'


type TProps = {
    data?: IContractField
    onCommit: (data: IContractField) => void
}

export const FieldForm: React.FC<TProps> = ({ data, onCommit }) => {

    const { control, handleSubmit, getValues, formState: { errors }, reset } = useForm({ defaultValues: data });

    const onSubmit = (data: IContractField) => {
        onCommit(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Stack verticalFill tokens={{ childrenGap: 16 }}>
                <Stack verticalFill tokens={{ padding: "0px 20px", childrenGap: 16 }}>
                    <Controller
                        control={control}
                        name={"label"}
                        render={({ field }) =>
                            <ParameterText
                                value={field.value === undefined ? '' : field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                style={{ width: 350 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_LABEL}
                                title={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_TITLE}
                                placeholder={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_PLACEHOLDER}
                                errorMessage={errors.label?.message}
                                maxLength={36}
                            />
                        }
                    />
                    <Controller
                        control={control}
                        name={"placeholder"}
                        render={({ field }) =>
                            <ParameterText
                                value={field.value === undefined ? '' : field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                style={{ width: 350 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.PLACEHOLDER_LABEL}
                                title={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.PLACEHOLDER_TITLE}
                                placeholder={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.PLACEHOLDER_PLACEHOLDER}
                                errorMessage={errors.placeholder?.message}
                                maxLength={36}
                            />
                        }
                    />
                    <Stack style={{ maxWidth: 350 }}>
                        <Slider label={getSliderLabel(getValues("minLength"), getValues("maxLength"))}
                            showValue={false} ranged lowerValue={getValues("minLength")}
                            value={getValues("maxLength") ?? 0}
                            onChange={(val, range) => {
                                reset({
                                    ...getValues(),
                                    minLength: range ? range[0] : undefined,
                                    maxLength: range ? range[1] : undefined
                                })
                            }} min={0} max={50} />
                    </Stack>
                </Stack>
                <Stack.Item tokens={{ padding: "16px 20px 24px 20px" }}>
                    <Stack horizontal grow>
                        <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                            <CSHHelpLink alignment="left" articleid="1160" />
                            <SecondaryButton iconProps={{ iconName: "Undo" }} onClick={() => reset(data)} />
                            <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}