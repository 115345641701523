import {
    DetailsList, DetailsListLayoutMode, DetailsRow, DirectionalHint,
    FontWeights, getTheme, IButtonStyles, IColumn, IconButton, IContextualMenuProps,
    IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListProps,
    IDetailsRowStyles, IIconProps, IRenderFunction, mergeStyleSets, MessageBar,
    MessageBarType, Modal, PrimaryButton, ScrollablePane, SelectionMode,
    SharedColors, Spinner, Stack, Text, TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ScreenHeader } from '../../components/shared/decorations/ScreenHeader';
import { Section } from '../../components/shared/decorations/Section';
import { onRenderColumnHeaderCommon } from '../../components/shared/detailsList/commonRenders';
import { ConfirmationForm } from '../../components/shared/forms/ConfirmationForm';
import { ServiceForm } from '../../components/shared/forms/ServiceForm';
import { ItemsNotFound } from '../../components/shared/notFound/ItemsNotFound';
import { CustomPanel } from '../../components/shared/panel/CustomPanel';
import { SomethingWentWrong } from '../../components/shared/somethingWentWrong/SomethingWentWrong';
import { portalApi } from '../../core/api/api';
import { IService, IStore } from '../../core/store';
import { strings } from '../../localization/strings';
import { Service } from './Service';
import { getLocalizedChannel, getLocalizedServiceType, getLocalizedDeviceCheckSolution } from "../../localization/helpers/channel";

export const ServicesConfiguration: React.FC = () => {

    const [servicesState, setServicesState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IService> }>({ fetched: false })
    const workflowIconGeneration = useSelector((s: IStore) => s.workplace.organization?.workflowIconGeneration)

    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [formError, setFormError] = React.useState<string>()
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)

    const [deleteServiceState, setDeleteServiceState] = React.useState<IService>()

    const history = useHistory();

    React.useEffect(() => {
        getServices()
    }, [])

    const getServices = async () => {
        setServicesState({ fetched: false })
        const result = await portalApi.organization.services.getServices()
        console.log(result)
        setServicesState({ error: (result.errors && result.errors.length > 0) ? result.errors[0].description : undefined, fetched: true, data: result.data?.filter(i => !i.removed) })
    }

    const onServiceDelete = () => {
        if (deleteServiceState?.id) {
            deleteService(deleteServiceState?.id)
            setDeleteServiceState(undefined)
        }
    }

    const deleteService = async (id: number) => {
        setServicesState({ ...servicesState, error: undefined })
        const result = await portalApi.organization.services.deleteService(id)
        if (result.successed) {
            if (servicesState.data) {
                setServicesState({
                    ...servicesState,
                    data: servicesState.data.filter(c => c.id !== id)
                })
            }
        }
        if (result.errors && result.errors.length > 0) {
            setServicesState({ ...servicesState, error: result.errors[0].description });
            return;
        }
        if (!result.successed) {
            setServicesState({ ...servicesState, error: "There is a problem! Service wasn't deleted! Server response false without an error" });
        }
    }

    const onAddService = () => {
        setFormError(undefined)
        stopFetch()
        showPanel()
    }

    const addService = async (item: IService) => {
        startFetch()
        console.log(item)
        const result = await portalApi.organization.services.addService(item)
        if (result.successed && result.data) {
            console.log(result)
            history.push('/services/configuration/' + result.data.id?.toString())
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].description)
            stopFetch()
        }
    }

    const onCancel = () => {
        hidePanel()
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.SERVICES.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 600,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IService) => <Service name={item.name} code={item.code} generateIcon={workflowIconGeneration} imageSrc={item.imageSrc} isValid={item.isValid} />,
            isPadded: true,
        },
        {
            key: 'column5',
            name: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_CHANNEL,
            fieldName: 'channel',
            minWidth: 100,
            isResizable: false,
            data: 'string',
            maxWidth: 100,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IService)  => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        {item.channel && (
                            <Text variant='small'>{getLocalizedChannel(item.channel)}</Text>
                        ) || (
                                <Text variant='small' style={{ color: SharedColors.red10 }}>{getLocalizedChannel(item.channel)}</Text>
                            )}
                    </Stack>);
            },
            isPadded: true,
        },
        {
            key: 'column6',
            name: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.SERVICE_TYPE,
            fieldName: 'serviceType',
            minWidth: 90,
            isResizable: false,
            data: 'string',
            maxWidth: 90,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IService)  => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        {item.serviceType && (
                            <Text variant='small'>{getLocalizedServiceType( item.serviceType)}</Text>
                        ) || (
                                <Text variant='small' style={{ color: SharedColors.red10 }}>{getLocalizedServiceType( item.serviceType)}</Text>
                            )}
                    </Stack>);
            },
            isPadded: true,
        },
        {
            key: 'column7',
            name: strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.DEVICE_CHECK_SOLUTION,
            fieldName: 'deviceCheckSolution',
            minWidth: 100,
            isResizable: false,
            data: 'string',
            maxWidth: 100,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IService)  => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        {item.serviceType && (
                            <Text variant='small'>{getLocalizedDeviceCheckSolution( item.deviceCheckSolution)}</Text>
                        ) || (
                                <Text variant='small' style={{ color: SharedColors.red10 }}>{getLocalizedDeviceCheckSolution( item.deviceCheckSolution)}</Text>
                            )}
                    </Stack>);
            },
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.ORGANIZATION.SERVICES.TABLE_COLUMNS.CATALOG,
            fieldName: 'id',
            minWidth: 200,
            maxWidth: 300,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IService) => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        {item.catalogName && (
                            <Text variant='small' style={{ color: SharedColors.orangeYellow20, overflowX: 'auto' }}>{item.catalogName}</Text>
                        ) || (
                                <Text variant='small' style={{ color: SharedColors.gray10 }}>{strings.ORGANIZATION.SERVICES.SERVICE.CATALOG_NOT_SPECIFIED}</Text>
                            )}
                    </Stack>);
            },
            isPadded: true,
        },
        {
            key: 'column3',
            name: strings.ORGANIZATION.SERVICES.TABLE_COLUMNS.DOCUMENT,
            fieldName: 'code',
            minWidth: 200,
            // maxWidth: 400,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IService) => {
                return (
                    <Stack verticalFill verticalAlign="center">
                        {item.documentName && (
                            <Text variant='small' style={{ color: SharedColors.cyanBlue10, overflowX: 'auto'}}>{item.documentName}</Text>
                        ) || (
                                <Text variant='small' style={{ color: SharedColors.gray10 }}>{strings.ORGANIZATION.SERVICES.SERVICE.DOCUMENT_NOT_SPECIFIED}</Text>
                            )}
                    </Stack>
                );
            },
            isPadded: true,
        },
        {
            key: 'column4',
            name: '',
            fieldName: 'enabled',
            minWidth: 40,
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: IService) => (
                <Stack horizontal verticalFill verticalAlign='center'>
                    <Stack.Item>
                        <IconButton menuIconProps={{ iconName: "MoreVertical", className: 'menu-icon' }} styles={menuIconStyles} menuProps={getItemMenuProps(item, setDeleteServiceState)} />
                    </Stack.Item>
                </Stack>
            )
        }
    ]

    const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        const errorServices = servicesState.data?.map((service)=>{
            if(!service.isValid){
                return service
            }
        })

        if (props) {
            if(props.item.isValid){
                customStyles.root = {
                    cursor: 'pointer',
                    ':hover': {
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.menu-icon': {
                            color: theme.palette.black
                        },
                    }
                };
            }else{
                customStyles.root = {
                    backgroundColor:"rgba(253, 231, 233,0.6)",
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: "rgb(253, 231, 233)",
                        '.disabled-icon': {
                            color: theme.palette.black
                        },
                        '.menu-icon': {
                            color: theme.palette.black
                        },
                    }
                };
            }

            return <Stack onClick={() => history.push(`/services/configuration/${props.item['id']}`)}><DetailsRow {...props} styles={customStyles} /></Stack>;
        }
        return null;
    };

    return (
        <Stack verticalFill>
            <Stack.Item verticalFill>
                <Stack verticalFill>
                    {!servicesState.error && (servicesState.fetched && (!servicesState.error && (
                        servicesState.data && servicesState.data.length > 0 && (
                            <>
                                <ScreenHeader title={strings.NAVIGATION.SERVICES.CONFIGURATION}>
                                    <PrimaryButton onClick={onAddService} text={strings.ORGANIZATION.SERVICES.SERVICE.CREATE_WORKFLOW} />
                                    {/* <SimpleCustomFilter onChange={(ev, value) => props.setSearchString(value)} /> */}
                                </ScreenHeader>
                                <Stack style={{ padding: "0px 32px 24px 32px" }}>
                                    {strings.ORGANIZATION.SERVICES.SERVICE.MANAGE_WORKFLOWS}
                                </Stack>
                                <Stack verticalFill style={{ position: 'relative' }}>
                                    <ScrollablePane>
                                        <Stack.Item style={{ padding: "0px 32px 24px 32px" }}>
                                            <Section max style={{ padding: 0 }}>
                                                <DetailsList
                                                    styles={{ root: { width: '100%' } }}
                                                    items={servicesState.data}
                                                    columns={columns}
                                                    setKey="none"
                                                    selectionMode={SelectionMode.none}
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                                    isHeaderVisible={true}
                                                    onRenderRow={onRenderRow}
                                                />
                                            </Section>
                                        </Stack.Item>
                                    </ScrollablePane>
                                </Stack>
                            </>
                        ) || (
                            <ItemsNotFound
                                onClick={onAddService}
                                info={strings.ORGANIZATION.SERVICES.NOT_FOUND_CASE.INFO}
                                suggestion={strings.ORGANIZATION.SERVICES.NOT_FOUND_CASE.SUGGESTION}
                                buttonText={strings.ORGANIZATION.SERVICES.NOT_FOUND_CASE.ADD_BUTTON}
                                imgSrc={"images/navigation/images/workflow.png"} />
                        )) || (
                            <SomethingWentWrong action={getServices} />
                        )) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        ))
                    }
                    {servicesState.error && (
                        <Stack verticalFill>
                            {servicesState.error.length > 0 && (
                                <MessageBar messageBarType={MessageBarType.error}>{servicesState.error}</MessageBar>
                            )}
                            <Stack.Item verticalFill>
                                <SomethingWentWrong action={getServices} />
                            </Stack.Item>
                        </Stack>
                    )}
                </Stack>
            </Stack.Item>
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                title={strings.ORGANIZATION.SERVICES.SERVICE.CREATE_WORKFLOW}
            >
                {formError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {formError}
                    </MessageBar>
                )}
                {(isPanelOpen && !fetching) && (
                    <ServiceForm
                        onSubmit={(data: IService) => addService(data)}
                        onCancel={onCancel}
                    />
                ) || (
                        <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                            <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                        </Stack>
                    )}
            </CustomPanel>
            {deleteServiceState && (
                <Modal isOpen={deleteServiceState ? true : false} onDismiss={() => setDeleteServiceState(undefined)} containerClassName={contentStyles.container}>
                    <div className={contentStyles.header}>
                        <span style={{ paddingRight: 32 }}>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_TITLE}</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close"
                            onClick={() => setDeleteServiceState(undefined)}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        <ConfirmationForm
                            onConfirm={onServiceDelete}
                            onCancel={() => setDeleteServiceState(undefined)}
                            requiredMessageString={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_REQUIRED}
                            confirmationCheckString={deleteServiceState?.name}
                            placeholder={strings.ORGANIZATION.SERVICES.SERVICE.FIELDS.NAME_PLACEHOLDER}
                            confirmText={strings.BUTTONS.TEXT.DELETE}
                        >
                            <Stack tokens={{ childrenGap: 12 }}>
                                <Text>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_TEXT1}</Text>
                                <Text>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_TEXT2}</Text>
                                <Text>{strings.ORGANIZATION.SERVICES.SERVICE.REMOVE_CONFIRMATION_REQUIREMENTS} <b>"{deleteServiceState.name}"</b></Text>
                            </Stack>
                        </ConfirmationForm>
                    </div>
                </Modal>
            )}
        </Stack>
    )
}

const getItemMenuProps = (item: IService, onDelete: (item: IService) => void): IContextualMenuProps => {
    return {
        items: [
            {
                key: `delete-${item.id}`,
                iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => item.id ? onDelete(item) : undefined,
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
            }
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    }
}

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );

    return (
        defaultRender!({
            ...props,
            onRenderColumnHeaderTooltip,
        })
    );
};

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const menuIconStyles: IButtonStyles = {
    menuIcon: {
        fontSize: 16,
        color: 'transparent',
        minWidth: 16
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: 480,
        borderRadius: 6
    },
    header: [
        theme.fonts.large,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        position: "relative",
        flex: '4 4 auto',
        padding: '0px 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};