import { Stack, ScrollablePane, SharedColors, getTheme } from "@fluentui/react"
import React from "react";

type TProps = {
    json: object
    style?: React.CSSProperties
}

export const ShowConfigurationPivot: React.FC<TProps> = ({json, style}) => {
    return (
        <Stack.Item verticalFill style={{ position: 'relative', backgroundColor: SharedColors.gray40 }}>
            <ScrollablePane>
                <pre style={{ overflow: "hidden", margin: 0, padding: 8, fontSize: 12, color: theme.palette.neutralLight, ...style }}>{JSON.stringify(json, null, 2)}</pre>
            </ScrollablePane>
        </Stack.Item>
    )
}

const theme = getTheme();