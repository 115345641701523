import { FontIcon, getTheme, PrimaryButton, Stack, Text } from "@fluentui/react";
import { SharedColors } from '@fluentui/theme';
import { useId } from "@uifabric/react-hooks";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { strings } from "../../../localization/strings";

type TProps = {
    handleDrop: (e: FileList, c?: string) => void
    filesLenght?: number
    isMultiple?: boolean
    label?: string
    description?: string
    minHeight: number
    qrCode?: string
    acceptTypeMask?: string
}

const Dropzone: React.FC<TProps> = props => {

    const dropRef = useRef<any>(null);
    const [dragging, setDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);

    useEffect(() => {
        const handleDrag = (ev: React.DragEvent) => {
            ev.preventDefault();
            ev.stopPropagation();
        };

        const handleDragIn = (ev: React.DragEvent) => {
            ev.preventDefault();
            ev.stopPropagation();
            setDragCounter((prev: number) => prev + 1);
            if (ev.dataTransfer.files) {
                setDragging(true);
            }
        };

        const handleDragOut = (ev: React.DragEvent) => {
            ev.preventDefault();
            ev.stopPropagation();
            setDragCounter((prev: number) => prev - 1);
            if (dragCounter === 0) {
                setDragging(false);
            }
        };

        const handleDrop = (ev: React.DragEvent) => {
            ev.preventDefault();
            ev.stopPropagation();
            setDragging(false);
            const files = ev.dataTransfer.files;
            if (files.length) {
                props.handleDrop(ev.dataTransfer.files, props.label);
                ev.dataTransfer.clearData();
                setDragCounter(0);
            }
        };
        const enableDragEventListeners = () => {
            const div = dropRef && dropRef.current;
            if (div) {
                div.addEventListener('dragenter', handleDragIn);
                div.addEventListener('dragleave', handleDragOut);
                div.addEventListener('dragover', handleDrag);
                div.addEventListener('drop', handleDrop);
            }
        };
        const disableDragEventListeners = () => {
            const div = dropRef && dropRef.current;
            if (div) {
                div.removeEventListener('dragenter', handleDragIn);
                div.removeEventListener('dragleave', handleDragOut);
                div.removeEventListener('dragover', handleDrag);
                div.removeEventListener('drop', handleDrop);
            }
        };
        setDragCounter(0);
        enableDragEventListeners();
        return () => {
            disableDragEventListeners();
        };
    }, [dragCounter, props]);

    switch (props.isMultiple) {
        case false:
            return (
                <SingleDropzone
                    dragging={dragging}
                    dropRef={dropRef}
                    handleDrop={props.handleDrop}
                    filesLenght={props.filesLenght}
                    label={props.label}
                    description={props.description}
                    minHeight={props.minHeight}
                    files={props.children}
                    acceptTypeMask={props.acceptTypeMask}
                />)
        default:
            return (
                <MultiDropzone
                    dragging={dragging}
                    dropRef={dropRef}
                    handleDrop={props.handleDrop}
                    filesLenght={props.filesLenght}
                    label={props.label}
                    minHeight={props.minHeight}
                    files={props.children}
                    qrCode={props.qrCode}
                />)
    }
};

type TInputFileProps = {
    handleDrop: (e: FileList, c?: string) => void
    isMultiple?: boolean
    filesLenght?: number
    label?: string
    qrCode?: string
    acceptTypeMask?: string
}

const InputFile: React.FC<TInputFileProps> = (props) => {
    const raisedButtonFileId = useId()
    return (
        <>
            <input
                accept={props.acceptTypeMask ?? "image/*"}
                style={{ display: 'none' }}
                id={raisedButtonFileId}
                multiple={props.isMultiple}
                type="file"
                onChange={(e: any) => {
                    e.target.files.length > 0 && props.handleDrop(e.target.files, props.label)
                }} />
            <PrimaryButton style={{ width: 160 }} onClick={() => document.getElementById(raisedButtonFileId)?.click()}>
                {!props.filesLenght ? !props.isMultiple ? strings.SHARED.DROPZONE.CHOOSE_FILE : strings.SHARED.DROPZONE.CHOOSE_FILES : strings.SHARED.DROPZONE.ADD_FILES}
            </PrimaryButton>
        </>
    )
}

type TZoneProps = {
    handleDrop: (e: FileList, c?: string) => void
    filesLenght?: number
    isMultiple?: boolean
    label?: string
    description?: string
    minHeight: number
    isButtonCentered?: boolean
    dropRef: any
    dragging: boolean
    files: React.ReactNode
    qrCode?: string
    acceptTypeMask?: string
}

const MultiDropzone: React.FC<TZoneProps> = (props) => {
    return (
        <div style={zoneStyles} ref={props.dropRef}>
            {(props.filesLenght && (
                <Stack>
                    <Stack.Item style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <InputFile isMultiple={true} handleDrop={props.handleDrop} filesLenght={props.filesLenght} />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack wrap style={{ minHeight: props.minHeight }}>
                            {props.files}
                        </Stack>
                    </Stack.Item>
                </Stack>
            )) || (
                    <Stack verticalFill verticalAlign="center" horizontalAlign="center" style={{ minHeight: props.minHeight }}>
                        {props.label &&
                            <Stack.Item>
                                <Text>{props.label}</Text>
                            </Stack.Item>}
                        <Stack.Item>
                            {props.qrCode
                                ? <Stack horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                                    <Stack.Item>
                                        <div style={{ overflow: "hidden" }}>
                                            <a href={props.qrCode} target="_blank">
                                                <QRCode value={props.qrCode} size={112} fgColor={SharedColors.gray20} />
                                            </a>
                                        </div>
                                    </Stack.Item>
                                    <Text style={{ color: SharedColors.gray10 }}>{strings.SHARED.DROPZONE.QR_LOAD_INFORMATION}</Text>
                                </Stack>
                                : <FontIcon iconName="Attach" style={{ fontSize: 64, height: 64, width: 64, color: SharedColors.gray30 }} />}
                        </Stack.Item>
                        <Stack.Item tokens={{ padding: 16 }} styles={{ root: { textAlign: "center" } }}>
                            <Text variant="large" style={{ color: SharedColors.gray30 }}>{props.dragging ? strings.SHARED.DROPZONE.DROP_FOR_FILES_PASTE : strings.SHARED.DROPZONE.CHOOSE_OR_DRAG_FILES_HERE}</Text>
                        </Stack.Item>
                        <Stack.Item tokens={{ padding: 8 }}>
                            <InputFile isMultiple={true} handleDrop={props.handleDrop} filesLenght={props.filesLenght} />
                        </Stack.Item>
                    </Stack>
                )}
        </div>
    )
}

const SingleDropzone: React.FC<TZoneProps> = (props) => {
    return (
        <div style={zoneStyles} ref={props.dropRef}>
            {(props.filesLenght && (
                <Stack verticalFill verticalAlign="center" horizontalAlign="center" tokens={{ padding: 8, childrenGap: 8 }}>
                    {props.label && <Stack.Item>
                        <Text><b>{props.label}</b></Text>
                    </Stack.Item>}
                    <Stack.Item>
                        {props.files}
                    </Stack.Item>
                    <Stack.Item>
                        <InputFile label={props.label} isMultiple={false} handleDrop={props.handleDrop} acceptTypeMask={props.acceptTypeMask} />
                    </Stack.Item>
                </Stack>
            )) || (
                    <Stack verticalFill verticalAlign="center" horizontalAlign="center" tokens={{ padding: 8, childrenGap: 8 }}>
                        {props.label && <Stack.Item>
                            <Text><b>{props.label}</b></Text>
                        </Stack.Item>}

                        <Stack verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 8 }} styles={{ root: { height: 120, maxHeight: 120, textAlign: "center" } }}>
                            {props.description && <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>{props.description}</Text>}
                            <FontIcon iconName="Attach" style={{ fontSize: 32, height: 32, width: 32, color: SharedColors.gray30 }} />
                            {!props.description && <Text variant="medium" style={{ color: SharedColors.gray30 }}>{props.dragging ? strings.SHARED.DROPZONE.DROP_FOR_FILE_PASTE : strings.SHARED.DROPZONE.CHOOSE_OR_DRAG_FILE_HERE}</Text>}
                        </Stack>

                        <Stack.Item>
                            <InputFile label={props.label} isMultiple={false} handleDrop={props.handleDrop} acceptTypeMask={props.acceptTypeMask} />
                        </Stack.Item>
                    </Stack>
                )}
        </div>
    )
}

export default Dropzone;

const theme = getTheme();

const zoneStyles: React.CSSProperties = {
    display: "flex",
    flexGrow: 1,
    flexFlow: "column",
    borderStyle: "dashed",
    borderWidth: 2,
    borderColor: SharedColors.gray10
}
