import { Stack, MessageBar, MessageBarType, Spinner, Text, Icon, TooltipHost, DirectionalHint } from "@fluentui/react";
import { useBoolean } from "@uifabric/react-hooks";
import React from "react";
import { portalApi } from "../core/api/api";
import { IOrganization } from "@piceasoft/core";
import { INewOrganization } from "../core/store/typings/INewOrganization";
import { getLozalizedBusinessError } from "../localization/helpers/common";
import { strings } from '../localization/strings';
import { OrganizationForm } from "./shared/forms/OrganizationForm";
import { ItemsNotFound } from "./shared/notFound/ItemsNotFound";
import { WidePanel } from "./shared/panel/WidePanel";
import { IPiceaCustomer } from "../core/store/typings/IPiceaCustomer";
import { IRequestResult } from "../core/store/typings/IResponseResult";
import { IPiceaCountry } from "../core/store/typings/IPiceaCountry";
import { IPiceaProduct } from "../core/store/typings/IPiceaProduct";
import moment from "moment";
import { useSelector } from "react-redux";
import { IStore } from "../core/store";
import { UserRoles } from "../core/store/typings/UserRoles";

type TProps = {
}

export const AddOrganization: React.FC<TProps> = (props) => {

    const user = useSelector( (s:IStore) => s.workplace.user);
    const roles = useSelector( (s:IStore) => s.environment.roles);
    const [formError, setFormError] = React.useState<string>()
    const [formData, setFormData] = React.useState<INewOrganization>()
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [fetching, { setTrue: startFetch, setFalse: stopFetch }] = useBoolean(false)
    const [organization, setOrganization] = React.useState<IOrganization>()
    const [isRedirecting, setIsRedirecting] = React.useState(false)
    const [customers, setCustomers] = React.useState<IPiceaCustomer[]>();
    const [countries, setCountries] = React.useState<IPiceaCountry[]>();
    const [existingOrganizationId, setExistingOrganizationId] = React.useState<string>("")
    const [existingVatOrganizationId, setExistingVatOrganizationId] = React.useState<string>("")

    const [customerProducts, setCustomerProducts] = React.useState<IPiceaProduct[]>();


    // Get Customers
    React.useEffect(() => {
        if (isPanelOpen) {
            const fetchCustomersData = async () => {
                startFetch()

                let result: IRequestResult<IPiceaCustomer[]> = { successed: false };
                await fetch('v1/account/customers', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }
                })
                    .then(async response => {
                        await (response.json() as Promise<IRequestResult<IPiceaCustomer[]>>)
                            .then(response => result = response)
                            .catch(error => result.errors = [{ description: error.message }])
                    })
                    .catch(error => result.errors = [{ description: error.message }])

                stopFetch()

                return result;
            }

            const fetchCountriesData = async () => {
                startFetch()

                let result: IRequestResult<IPiceaCountry[]> = { successed: false };
                await fetch('v1/account/countries', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }
                })
                    .then(async response => {
                        await (response.json() as Promise<IRequestResult<IPiceaCountry[]>>)
                            .then(response => result = response)
                            .catch(error => result.errors = [{ description: error.message }])
                    })
                    .catch(error => result.errors = [{ description: error.message }])
                stopFetch()

                return result;
            }

            fetchCustomersData()
                .then(data => {
                    setCustomers(data.data);
                })
                .catch(console.error);

            fetchCountriesData()
                .then(data => {
                    setCountries(data.data);
                })
                .catch(console.error);

        }
    }, [isPanelOpen])


    const onGetCustomerProducts = async () => {
        const result = await portalApi.organization.getCustomers();
    }

    const canAccessOrganization = async (organizationId: string) => {
        if( !roles)
            return false;
        if (roles.includes(UserRoles.Administrator)) 
            return true

        if (!roles.includes(UserRoles.Supervisor)) 
            return false

        const otherOrgs = await portalApi.settings.getOrganizations();
        if( otherOrgs.successed && otherOrgs.data && otherOrgs.data.length > 0) {
            return otherOrgs.data.some( o => o.id === organizationId)
        }

        return false
    }

    const changeToExistingOrganization = async (organizationId: string) => {
        startFetch()

        const can = await canAccessOrganization( organizationId)
        if( can ) {
            const result = await portalApi.settings.changeOrganization(organizationId)
            setTimeout(() => {
                if (result.successed) {
                    setIsRedirecting(true)
                    location.href = '/';
                }
                if (result.errors && result.errors.length > 0) {
                    setFormError(result.errors[0].description)
                }
            }, 500)
        }
        else {
            //  This user has no access to the requested organization.
            let msg = strings.ORGANIZATION.NEW_ORGANIZATION.NO_PERMISSION + '. ' + strings.ORGANIZATION.NEW_ORGANIZATION.CONTACT_ADMIN;
            setFormError(msg);
            setOrganization(undefined)
            setFormData(undefined)
            setExistingOrganizationId("");
            setCustomerProducts(undefined);

        }
        stopFetch()

    }


    const searchCustomerProducts = async (customer_id: string) => {
        const result = await portalApi.organization.getCustomerProducts(customer_id);
        if (result.data && result.data) {
            // Show only products that are not expired and belong to online solution
            alert(JSON.stringify(result.data));
            let filtered = result.data.filter((product) => {
                return moment(product.expires) > moment() && product.solution.solution_id === 'a5f53ab3-d011-411c-9db5-a2bf42ab390d'
            });
            setCustomerProducts(filtered);
        }
        else {
            setCustomerProducts([]);
        }
    }

    const searchPiceaCustomerId = async (customer_id: string) => {
        if (customer_id !== "") {
            const result = await portalApi.organization.searchCustomerId(customer_id);
            if (result.data && result.data.customerId && result.data.organizationId) {
                setExistingOrganizationId(result.data.organizationId);
            }
            else {
                setExistingOrganizationId("");
            }
        }

    }

    const searchPiceaCustomerVat = async (vat_number: string) => {
        const result = await portalApi.organization.searchCustomerVat(vat_number);
        if (result.data && result.data.customerId && result.data.organizationId) {
            setExistingVatOrganizationId(result.data.organizationId);
        }
        else {
            setExistingVatOrganizationId("");
        }
    }

    const resetOrganizationId = async (vat: boolean = false) => {
        if (vat) {
            setExistingVatOrganizationId("");
        }
        else {
            setExistingOrganizationId("");
        }
    }



    const onSubmitHandler = async (data: INewOrganization) => {
        setFormError(undefined)
        setFormData(data)
        startFetch()

        if (data.picea_product_expiry_date) {

            data.picea_product_expiry_date  = moment(data.picea_product_expiry_date ).hours(23).minutes(59).seconds(0)
                    .millisecond(0)
                    .utc().format('YYYY-MM-DDTHH:mm:ss[Z]').toString();
        } 
     


        const result = await portalApi.organization.create(data)

        if (result.successed && result.data) {
            setOrganization(result.data)
        }
        if (result.errors && result.errors.length > 0) {
            setFormError(result.errors[0].statusCode ? getLozalizedBusinessError(result.errors[0].statusCode) : result.errors[0].description)
        }
        stopFetch()
    }

    const onCancel = () => {
        hidePanel()
        setTimeout(() => {
            setFormError(undefined)
            setOrganization(undefined)
            setFormData(undefined)
            setCustomerProducts(undefined);
            setExistingVatOrganizationId("");
            setExistingOrganizationId("");


        }, 500)
    }

    const changeOrganization = async () => {
        if (organization?.id) {
            startFetch()
            const result = await portalApi.settings.changeOrganization(organization.id)
            console.log(result)
            setTimeout(() => {
                if (result.successed) {
                    setIsRedirecting(true)
                    location.href = '/';
                }
                if (result.errors && result.errors.length > 0) {
                    setFormError(result.errors[0].description)
                }
                stopFetch()
            }, 500)
        }
    }

    return (
        <Stack.Item>
            <Stack verticalFill horizontalAlign='center' verticalAlign='center' onClick={showPanel} style={{ width: 48 }}>
                <TooltipHost content={strings.ORGANIZATION.NEW_ORGANIZATION.ADD_ORGANIZATION}
                    tooltipProps={{
                        calloutProps: {
                            isBeakVisible: false,
                            directionalHint: DirectionalHint.topAutoEdge,
                        }
                    }}
                >
                    <Icon styles={{ root: { padding: 12, fontSize: 16, cursor: 'pointer', ':hover': { color: '0 0 4px rgb(0 120 212 / 50%)', textShadow: '0 0 4px rgb(0 120 212 / 50%)' } } }} iconName={'Add'} />
                    <WidePanel
                        isOpen={isPanelOpen}
                        onCancel={onCancel}
                        noCancelOnDissmiss={true}
                        title={strings.ORGANIZATION.NEW_ORGANIZATION.ADD_ORGANIZATION}
                    >
                        <Stack verticalFill>
                            {formError && (
                                <MessageBar messageBarType={MessageBarType.error}>
                                    {formError}
                                </MessageBar>
                            )}
                            {(isPanelOpen && !fetching && customers && countries) && ((
                                !organization && (
                                    <OrganizationForm
                                        onSubmit={onSubmitHandler}
                                        onCancel={onCancel}
                                        data={formData}
                                        customers={customers}
                                        countries={countries}
                                        searchCustomerProducts={searchCustomerProducts}
                                        customerProducts={customerProducts}
                                        searchPiceaCustomerId={searchPiceaCustomerId}
                                        searchPiceaCustomerVat={searchPiceaCustomerVat}
                                        resetOrganizationId={resetOrganizationId}
                                        existingOrganizationId={existingOrganizationId}
                                        existingVatOrganizationId={existingVatOrganizationId}
                                        changeToExistingOrganization={changeToExistingOrganization}
                                    />
                                ) || (
                                    <Stack verticalAlign="center" verticalFill>
                                        {isRedirecting && (
                                            <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                                <Spinner label={strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.REDIRECTING} />
                                            </Stack>
                                        ) || (
                                                <ItemsNotFound
                                                    info={strings.ORGANIZATION.NEW_ORGANIZATION.SUCCESS.INFO}
                                                    suggestion={strings.ORGANIZATION.NEW_ORGANIZATION.SUCCESS.SUGGESTION}
                                                    buttonText={strings.ORGANIZATION.NEW_ORGANIZATION.SUCCESS.BUTTON_TEXT}
                                                    onClick={changeOrganization}
                                                    imgSrc="images/navigation/images/organization.png"
                                                />
                                            )}

                                    </Stack>

                                ))
                            ) || (
                                    <Stack verticalAlign='center' verticalFill horizontalAlign='center'>
                                        <Spinner label={organization?.id ? strings.SETTINGS.WORKPLACE.CHANGE_ORGANIZATION.ORGANIZATION_CHANGING : strings.SPINNERS.DATA_IS_GETTING} />
                                    </Stack>
                                )}
                        </Stack>


                    </WidePanel>
                </TooltipHost>
            </Stack>
        </Stack.Item>
    )
}