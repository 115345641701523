import { Stack, Text, getTheme, Spinner, Toggle, SharedColors } from "@fluentui/react"
import React from "react"
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { actionCreators } from "../../../core/actions/settings-actions";
import { IOrganizationSettings } from "../../../core/store/typings/IOrganizationSettings";
import { IOrganizationSettingsAdditional } from "../../../core/store/typings/IOrganizationSettingsAdditional";
import { strings } from "../../../localization/strings";

export type TSettingsAdditionalTabProps = {
    data?: IOrganizationSettings
    isUpdating: boolean
}

export const SettingsAdditionalTab: React.FC<TSettingsAdditionalTabProps> = props => {

    const dispatch = useDispatch();

    const { control, handleSubmit } = useForm<IOrganizationSettingsAdditional>({ defaultValues: props.data });
    const onSubmit: SubmitHandler<IOrganizationSettingsAdditional> = data => {
        dispatch(actionCreators.updateOrganizationSettingsAdditional(data));
    };

    return (
        <Stack style={{ width: 640 }} tokens={{ padding: 24, childrenGap: 16 }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack style={{ width: 360 }} verticalFill tokens={{ childrenGap: 16 }} >
                        <Stack tokens={{ childrenGap: 16 }} >
                            <Stack.Item>
                                <Text style={{ color: theme.palette.black, fontSize: 28, fontWeight: "bold", letterSpacing: "-.04em" }}>{strings.SETTINGS.GENERAL.ADDITIONAL.TITLE}</Text>
                            </Stack.Item>
                            <Controller
                                name="workflowIconGeneration"
                                control={control}
                                render={({ field }) =>
                                    <Stack>
                                        <Toggle
                                            checked={field.value}
                                            onChange={(ev, checked) => field.onChange(checked)}
                                            onBlur={field.onBlur}
                                            inlineLabel
                                            label={strings.ORGANIZATION.SETTINGS.GENERAL.ADDITIONAL.GENERATE_WORKFLOW_ICONS}
                                        />
                                        <Text style={{ color: SharedColors.gray30 }}>{strings.ORGANIZATION.SETTINGS.NEED_RESTART}</Text>
                                    </Stack>
                                }
                            />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <SecondaryButton disabled={props.isUpdating} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.SAVE}</SecondaryButton>
                            {props.isUpdating && (
                                <Spinner />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    )
}

const theme = getTheme();

