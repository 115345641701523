import { getTheme, IStackStyles, Stack, Text } from "@fluentui/react"
import { SharedColors } from '@fluentui/theme'
import { strings } from "../../../localization/strings"
import { MyPersona } from "../decorations/MyPersona"

type TProps = {
    dateTime: string
    offset?: number
    sender: string
    myMessage?: boolean
    text: string
}

export const MyMessage: React.FC<TProps> = (props) => {
    const containerStyle: IStackStyles = {
        root: {
            padding: "4px 8px",
            borderLeftStyle: "solid",
            borderLeftWidth: 4,
            borderLeftColor: props.myMessage ? SharedColors.cyanBlue10 : SharedColors.green10,
            backgroundColor: props.myMessage ? theme.palette.themeLighter : theme.palette.neutralLighterAlt,
            minWidth: 100,
            maxWidth: 300,
            borderTopLeftRadius: theme.effects.roundedCorner4,
            borderBottomLeftRadius: theme.effects.roundedCorner4,
            boxShadow: theme.effects.elevation8,
        }
    }

    const getTime = (dateTime: string, offset?: number) => {
        const d = new Date(dateTime);
        if (offset) {
            d.setHours(d.getHours() + offset)
        }
        return d.toLocaleTimeString();
    }

    return (
        <Stack horizontalAlign={props.myMessage ? "end" : "start"} horizontal tokens={{ childrenGap: 8 }}>
            {!props.myMessage && <MyPersona text={strings.TRANSACTIONS.MODERATIONS.MODERATOR} onlyPersona />}
            <Stack styles={containerStyle} tokens={{ childrenGap: 2 }} >
                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 24 }}>
                    <Text style={{ color: SharedColors.gray20 }} variant="small">{props.sender}</Text>
                    <Text style={{ color: SharedColors.gray20 }} variant="small">{Date.parse(props.dateTime) ? getTime(props.dateTime, props.offset) : props.dateTime}</Text>
                </Stack>
                <Stack.Item>
                    <Text variant="medium" style={{ color: SharedColors.gray40, wordBreak: "break-word", msWordBreak: "break-word" }}>{props.text}</Text>
                </Stack.Item>
            </Stack>
            {props.myMessage && <MyPersona text={strings.ACCOUNT_ROLES.OPERATOR} onlyPersona />}
        </Stack>
    )
}

const theme = getTheme();
