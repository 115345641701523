import { Stack, Text, IconButton, Separator, Dropdown, IDropdownOption, Toggle, Icon, SharedColors, getTheme } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import React from "react"
import { Control, Controller } from "react-hook-form"
import { onRenderLabel } from "../../../../components/renders/onRenderLabel"
import { Section } from "../../../../components/shared/decorations/Section"
import { IBranding } from "../../../../core/store/typings/IBranding"
import { strings } from "../../../../localization/strings"
import { TMainColor } from "../BrandingDetails"
import { BrandingSelectColorComponent } from "../BrandingSelectColorComponent"
import { IBrandingConfig } from "@piceasoft/core";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

type Tprops = {
    mainColors: TMainColor[]
    control: Control<IBranding, object> 
    defaultDarkConfig: IBrandingConfig,
    getValues: UseFormGetValues<IBranding>,
    setValue: UseFormSetValue<IBranding>,
}
export const BrandingTypeografy: React.FC<Tprops> = (props) => {
    const [isSectionCollapsed, { toggle: toggleSectionCollapse }] = useBoolean(false)
    const expandItem = React.createRef<HTMLDivElement>();
    const isDefault = (): boolean =>{
        var branding = props.getValues();
        return branding?.darkConfig?.fontColorBody == props.defaultDarkConfig.fontColorBody
        && branding?.darkConfig?.fontColorHeadings == props.defaultDarkConfig.fontColorHeadings;
    }
    const [isCustomeDark, { toggle: changeCustomeDark }] = useBoolean(!isDefault())
    const toggleCustomeDark = () =>{
        changeCustomeDark();
        if(isCustomeDark){
            props.setValue('darkConfig.fontColorBody', props.defaultDarkConfig.fontColorBody);
            props.setValue('darkConfig.fontColorHeadings', props.defaultDarkConfig.fontColorHeadings);
        }
    }

    React.useEffect(() => {
        if (expandItem.current) {
            if (isSectionCollapsed) {
                expandItem.current.style.height = `${expandItem.current.scrollWidth}px`
            } else {
                expandItem.current.style.height = `${expandItem.current.scrollWidth}px`;
                window.getComputedStyle(expandItem.current, null).getPropertyValue("height");
                expandItem.current.style.height = "0";
            }
        }
    }, [isSectionCollapsed])
    const onRenderHeader = (): React.ReactNode => {
        return (
            <Stack grow>
                <Stack
                    onClick={toggleSectionCollapse} horizontal
                    verticalAlign="center" grow horizontalAlign="space-between"
                    tokens={{ padding: "10px 20px 0px 20px" }}
                    style={{ paddingBottom: isSectionCollapsed ? "10px" : "0", cursor: 'pointer' }}
                >
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_TYPEOGRAFY.BRANDING_TYPEOGRAFY_TITLE}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton iconProps={{ iconName: isSectionCollapsed ? "ChevronDown" : "ChevronUp" }} />
                    </Stack.Item>
                </Stack>
                {!isSectionCollapsed && (
                    <Separator />
                )}
            </Stack>
        )
    }
    const optionsFontFamily: IDropdownOption[] = [
        { key: 'Manrope', text: "Manrope" },
    ]
    return (
        <Stack>
            <Section onRenderHeader={onRenderHeader} headerOnly={isSectionCollapsed}>
                <Stack>
                    {!isSectionCollapsed && (
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap>
                            <Stack.Item style={{paddingTop: 47}}>
                                <Controller
                                    name="config.fontFamily"
                                    control={props.control}
                                    render={({ field }) =>
                                        <Dropdown
                                            {...field}
                                            options={optionsFontFamily}
                                            selectedKey={field.value}
                                            onChange={(ev, opt) => field.onChange(opt?.key)}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_TYPEOGRAFY.FONT_FAMILY}
                                            placeholder={strings.ORGANIZATION.BRANDING.BRANDING_PLACEHOLDER.SELECT_FONT}
                                            style={{ width: 200 }} />
                                    } />
                            </Stack.Item>
                            <Stack.Item>
                            <Stack.Item>
                             <Text style={{ fontSize: 16,  fontWeight: 600 }}>{strings.BRANDING.DARK.LIGHT_MODE}</Text>
                                </Stack.Item>
                                <Separator/>
                                <Stack.Item style={{display: "inline-block"}}>
                                <Controller
                                    name="config.fontColorHeadings"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_TYPEOGRAFY.FONT_HEADINGS_COLOR}
                                            description={strings.ORGANIZATION.BRANDING.BRANDING_TYPEOGRAFY.FONT_HEADINGS_COLOR_INFO}
                                            onChangeField={field.onChange}
                                            mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item style={{display: "inline-block", paddingLeft:10}}>
                                <Controller
                                    name="config.fontColorBody"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_TYPEOGRAFY.FONT_BODY_COLOR}
                                            description={strings.ORGANIZATION.BRANDING.BRANDING_TYPEOGRAFY.FONT_BODY_COLOR_INFO}
                                            onChangeField={field.onChange}
                                            mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item style={{paddingTop: 13}}>
                            <Stack.Item style={{display: "inline-block"}} >
                            <Toggle                                  
                                        checked={isCustomeDark}
                                        onChange={toggleCustomeDark }
                                    />
                                </Stack.Item>  
                                <Stack.Item  style={{display: "inline-block"}}      >
                                <Text style={{ fontSize: 16,  fontWeight: 600, paddingLeft: 5 }}>{strings.BRANDING.DARK.CUSTOM_DARK_MODE}</Text>
                                <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20, paddingLeft: 10, paddingRight: 10 }} title={strings.BRANDING.DARK.INFO} ariaLabel="Info" styles={iconButtonStyles} />
                                </Stack.Item>
                                </Stack.Item>
                                <Separator/>
                                <Stack.Item style={{display: "inline-block"}}>
                                <Controller
                                    name="darkConfig.fontColorHeadings"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_TYPEOGRAFY.FONT_HEADINGS_COLOR}
                                            description={strings.ORGANIZATION.BRANDING.BRANDING_TYPEOGRAFY.FONT_HEADINGS_COLOR_INFO}
                                            onChangeField={field.onChange}
                                            disabled={!isCustomeDark} 
                                            mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item style={{display: "inline-block", paddingLeft:10}}>
                                <Controller
                                    name="darkConfig.fontColorBody"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            label={strings.ORGANIZATION.BRANDING.BRANDING_TYPEOGRAFY.FONT_BODY_COLOR}
                                            description={strings.ORGANIZATION.BRANDING.BRANDING_TYPEOGRAFY.FONT_BODY_COLOR_INFO}
                                            onChangeField={field.onChange}
                                            disabled={!isCustomeDark} 
                                            mainColors={props.mainColors} />} />
                            </Stack.Item>
                            </Stack.Item>
                            
                        </Stack>
                    )}
                </Stack>
            </Section>
        </Stack>
    )
}
const theme = getTheme()
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};