import { Stack, getTheme, IStackStyles } from "@fluentui/react"
import * as React from 'react';
import { useSelector } from "react-redux";
import { IStore } from "../../../../../../core/store";
import { IIdentificationUniversalConfig } from "@piceasoft/core";
import Universal from "./Universal";

type TProps = {
    index: number
}

export const UniversalPreview: React.FC<TProps> = ({ index }) => {

    const module = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === index) as IIdentificationUniversalConfig)
    
    if (!module) return null

    return (
        <Stack grow tokens={{ padding: "32px 32px 32px 32px" }}>
            <Stack tokens={{ childrenGap: 8 }}>
                <Stack grow styles={containerStyles}>
                    <Universal config={module as IIdentificationUniversalConfig} />
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();
const containerStyles: IStackStyles = { root: { minWidth: 320, maxWidth: 320, minHeight: 412, padding: 16, backgroundColor: theme.palette.white, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 } };