import React, {FC, useCallback} from "react";
import {Icon, List, SharedColors, Stack, Text} from "@fluentui/react";
import {RepairComponentsMap} from "../../../../../../core/store/typings/RepairComponentsMap";
import {styles} from "./styles";

interface IProps {
    items: string[]
    componentsMap?: RepairComponentsMap
}

const RepairOffersList: FC<IProps> = ({items, componentsMap}) => {
    const onRenderCell = useCallback((item?: { isSelected: boolean, data: string }, index?: number) => {
        if (item) {
            const text = componentsMap ? componentsMap[item?.data] : item?.data ?? '';
            return (
                <Stack horizontal
                       key={`${item?.data}-${index}` ?? ""}
                       verticalAlign="center"
                       grow tokens={{childrenGap: 8}}
                       className={styles.itemCell}>
                    <Stack.Item>
                        <Icon iconName="DeveloperTools" style={{fontSize: 16}}/>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Text block nowrap variant="medium"
                              style={{
                                  width: 236,
                                  color: SharedColors.gray40
                              }}>{text}</Text>
                    </Stack.Item>
                </Stack>
            )
        }
        return <></>
    }, [componentsMap])

    const itemsLocal = items.map((i) => ({data: i, isSelected: false}))

    if(!componentsMap) {
        return <></>
    }

    return <List
        items={itemsLocal}
        onRenderCell={onRenderCell}
        getKey={(item) => item.data}
    />
}

export default RepairOffersList;
