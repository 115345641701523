import React, { Dispatch } from "react";
import { IResultConfig } from "@piceasoft/core";
import { strings } from "../../../../../localization/strings";
import { Section } from "../../../decorations/Section";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { actionCreators } from "../../../../../core/actions/configurator-actions"
import { IDropdownOption } from '@fluentui/react'

type TProps = {
    config: IResultConfig
    onConfigCommit: (config: IResultConfig) => void
    channel: number
}

const zoomLevelOptions: IDropdownOption[] = [
    // { key: 0, text: "0" },
    { key: 1, text: "1" },
    { key: 2, text: "2" },
    { key: 3, text: "3" },
    { key: 4, text: "4" },
    { key: 5, text: "5" },
    { key: 6, text: "6" },
    { key: 7, text: "7" },
    { key: 8, text: "8" },
    { key: 9, text: "9" },
    { key: 10, text: "10" },
    { key: 11, text: "11" },
    { key: 12, text: "12" },
    { key: 13, text: "13" },
    { key: 14, text: "14" },
    { key: 15, text: "15" },
    { key: 16, text: "16" },
    { key: 17, text: "17" },
    { key: 18, text: "18" }
]


export const ResultMapPivot: React.FC<TProps> = (props) => {
    return (
        <Section max flat>
         <ParameterItem title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.MAP.TITLE_TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={props.config.ui?.map?.enabled === undefined  ||  props.config.ui?.map?.enabled === false ? false : true}
                toggleOnChange={() => {
                    props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            map: {
                                ...props.config.ui?.map,
                                enabled: props.config.ui?.map?.enabled === undefined  ||  props.config.ui?.map?.enabled === false ? true : false
                            }
                        }
                    })
                }}
                description={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.MAP.TITLE_DESCRIPTION}
                // useDropdown
                // dropdownEnabled={props.config.ui?.map?.enabled === true}
                // dropdownSelectedKey={props.config.ui?.map?.zoomLevel || 10}
                // dropdownOptions={zoomLevelOptions}
                // dropdownOnChange={(opt) => props.onConfigCommit({
                //     ...props.config,
                //     ui: {
                //         ...props.config.ui,
                //         map: {
                //             ...props.config.ui?.map,
                //             zoomLevel: opt?.key as number
                //         }
                //     }
                // })
                // }
            />
        <ParameterItem 
            title={strings.CONSTRUCTOR.STAGES.RESULT.PIVOTS.MAP.BUTTON_DESCRIPTION}
            useToggle
            toggleEnabled={props.config.ui?.map?.enabled ? true : false}
            toggleChecked={props.config.ui?.map?.button !== undefined ? true : false}
            toggleOnChange={() => {
                props.onConfigCommit({
                    ...props.config,
                    ui:{
                        ...props.config.ui,
                        map: {
                            ...props.config.ui?.map,
                            button: props.config.ui?.map?.button !== undefined ? undefined : ""
                        }
                    }
                })
            }}
            useTextField
            textFieldEnabled={props.config.ui?.map?.button !== undefined ? true : false}
            textFieldValue={props.config.ui?.map?.button}
            textFieldOnChange={(val) => props.onConfigCommit({
                ...props.config,
                ui:{
                    ...props.config.ui,
                    map:{
                        ...props.config.ui?.map,
                        button: val
                    }
                }
            })}
            description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.NEXT.TEXT_DESCRIPTION}
        />

            {/* {props.config.ui?.map?.enabled === true && (
                <ParameterItem
                    title={'Use custom zoom level'}
                    useToggle
                    toggleEnabled={true}
                    toggleChecked={true}
                    useDropdown={true}
                    dropdownEnabled={props.config.ui?.map?.enabled === true}
                    dropdownSelectedKey={props.config.ui?.map?.zoomLevel || 10}
                    dropdownOptions={zoomLevelOptions}
                    dropdownOnChange={(opt) => props.onConfigCommit({
                        ...props.config,
                        ui: {
                            ...props.config.ui,
                            map: {
                                ...props.config.ui?.map,
                                zoomLevel: opt?.key as number
                            }
                        }
                    })
                    }
                    description={'Use custom zoom level'}
                    // errorMessage={'plaplapla'}
                />)} */}
        </Section>
    )
}