import { Stack, Text, Spinner, ScrollablePane, List, MessageBar, MessageBarType, IconButton, SharedColors, getTheme, Sticky, StickyPositionType, ISearchBoxStyleProps, ISearchBoxStyles, IStyleFunctionOrObject, DirectionalHint, Checkbox } from '@fluentui/react';
import * as React from 'react';
import { CopyToClipBoardButton } from '../../../../../components/shared/buttons/CopyToClipboardButton';
import { SimpleCustomFilter } from '../../../../../components/shared/filter/SimpleCustomFilter';
import { ItemsNotFound } from '../../../../../components/shared/notFound/ItemsNotFound';
import { SomethingWentWrong } from '../../../../../components/shared/somethingWentWrong/SomethingWentWrong';
import { portalApi } from '../../../../../core/api/api';
import { ITransactionTechLog } from '../../../../../core/store';
import { IResponseError } from '@piceasoft/core';
import { strings } from '../../../../../localization/strings';
import { parsedJSON } from '../../../../../core/helpers/common';

type TProps = {
    transactionId: string
    processId: number
}

type TTransactionTechLog = {
    id: number
    creationDate: Date
    code: string
    description: string
    data: string
    expanded: boolean
    hasError: boolean
}

export const TechLogPivot: React.FC<TProps> = (props) => {

    const prepareItems = (): TTransactionTechLog[] => {
        return techLog.data?.map((i, index) => {
            const dataObject = parsedJSON(i.data)

            return {
                id: index,
                creationDate: new Date(i.creationDate),
                code: i.code,
                description: i.description,
                data: i.data,
                hasError: dataObject && dataObject.error && dataObject.error !== null,
                expanded: false
            }
        }).filter(i => (!i.hasError && onlyErrors) ? false : true) ?? []
    }


    const [techLog, setTechLog] = React.useState<{ fetched: boolean, data?: ITransactionTechLog[], errors?: IResponseError[], hasErrors?: boolean }>({ fetched: false })
    const [items, setItems] = React.useState<TTransactionTechLog[]>()
    const [searchString, setSearchString] = React.useState<string>()
    const [onlyErrors, setOnlyErrors] = React.useState(false)

    const toggleItemState = (id: number) => {
        setItems(items?.map(i => {
            if (i.id === id) {
                return { ...i, expanded: !i.expanded }
            }
            return i
        }))
    }

    React.useEffect(() => {
        setItems(prepareItems())
    }, [techLog.data, onlyErrors])

    React.useEffect(() => {
        setItems(() => {
            var newItems = prepareItems()
            if (searchString) {
                newItems = [
                    ...((newItems as TTransactionTechLog[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.code,
                            i.description,
                            i.data
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as TTransactionTechLog[]
        })
    }, [searchString])

    React.useEffect(() => {
        getTechLog(props.transactionId)
    }, [])

    React.useEffect(() => {

        setItems(techLog.data?.map((i, index) => {
            const dataObject = parsedJSON(i.data)

            return {
                id: index,
                creationDate: new Date(i.creationDate),
                code: i.code,
                description: i.description,
                data: i.data,
                hasError: dataObject && dataObject.error && dataObject.error !== null,
                expanded: false
            }
        }) ?? [])
    }, [techLog.data])

    const getTechLog = async (id: string) => {
        setTechLog({ fetched: false })
        const result = await portalApi.overview.transactions.getTransactionTechLog(id)
        console.log(result)
        if (result.successed) {
            const filtered = result.data?.filter(i => {
                const object = parsedJSON(i.data)
                if (object && object.error && object.error !== null) {
                    return true
                }
                return false
            })
            setTechLog({
                fetched: true, data: result.data, hasErrors: (filtered && filtered.length > 0) ? true : false
            })
        }
        if (result.errors) {
            setTechLog({ fetched: true, errors: result.errors })
        }
    }

    const onRenderCell = (item?: TTransactionTechLog): React.ReactNode | undefined => {
        const json = item?.data ? JSON.parse(item?.data) : null

        if (!item) return null

        const backgroundColor = item?.hasError ? 'rgb(253,231,233, 0.6)' : undefined
        const hoverBackgroundColor = item?.hasError ? 'rgb(253,231,233)' : theme.palette.neutralLighter

        return (
            <Stack>
                <Stack horizontal
                    tokens={{ childrenGap: 16 }}
                    verticalAlign='center'
                    styles={{
                        root: {
                            padding: 4,
                            borderBottom: `1px solid rgb(243, 242, 241)`,
                            cursor: 'pointer',
                            backgroundColor: backgroundColor,
                            ':hover': {
                                backgroundColor: hoverBackgroundColor
                            }
                        }
                    }}
                    onClick={() => toggleItemState(item.id)}
                >
                    <Stack.Item>
                        <IconButton disabled={!json} onClick={() => toggleItemState(item.id)} style={{ color: `rgb(96, 94, 92)` }} iconProps={{ iconName: item.expanded ? 'ChevronDown' : 'ChevronRight' }} />
                    </Stack.Item>
                    <Stack.Item style={{ paddingRight: 16 }}>
                        <Text variant='small' style={{ color: SharedColors.gray20 }}>{item.creationDate.toLocaleTimeString()}</Text>
                    </Stack.Item>
                    <Stack verticalAlign='center'>
                        <Stack.Item>
                            <Text variant='small' style={{ color: SharedColors.cyanBlue10 }}>{item.code}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant='small' style={{ color: SharedColors.gray20 }}>{item.description}</Text>
                        </Stack.Item>
                    </Stack>
                </Stack>
                {item.expanded && json && (
                    <Stack verticalFill style={{ padding: '0px 16px 0px 16px', borderBottom: `1px solid rgb(243, 242, 241)`, color: theme.palette.neutralLight, backgroundColor: SharedColors.gray40 }}>
                        <pre style={{ whiteSpace: 'pre-wrap', fontSize: 10 }}>{JSON.stringify(json, null, 2)}</pre>
                    </Stack>
                )}
            </Stack>
        )
    }

    return (
        <Stack verticalFill>
            {!techLog.errors && (techLog.fetched && (techLog.data && (
                <Stack verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                            <Stack horizontal verticalAlign="center" style={{ padding: "16px 16px 16px 16px", borderBottom: "1px solid", borderBottomColor: theme.palette.neutralQuaternaryAlt }}>
                                <Stack.Item grow>
                                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                                        <Stack verticalFill verticalAlign='center' horizontal tokens={{ childrenGap: 8 }}>
                                            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                                                <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.ATTRIBUTES.PROCESS.TRANSACTION_ID}</Text>
                                                <Text variant="small" style={{ color: theme.palette.black }}>{props.transactionId}</Text>
                                            </Stack>
                                            <CopyToClipBoardButton
                                                content={props.transactionId}
                                                copyText={strings.TRANSACTION.RESULT.TECH_LOG.TRANSACTION_ID_COPY}
                                                copiedText={strings.TRANSACTION.RESULT.TECH_LOG.TRANSACTION_ID_COPIED}
                                                tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }} />
                                        </Stack>
                                        <Stack verticalFill verticalAlign='center' horizontal tokens={{ childrenGap: 8 }}>
                                            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 8 }}>
                                                <Text variant="small" style={{ color: SharedColors.gray20 }}>{strings.ATTRIBUTES.PROCESS.PROCESS_ID}</Text>
                                                <Text variant="small" style={{ color: theme.palette.black }}>{props.processId}</Text>
                                            </Stack>
                                            <CopyToClipBoardButton
                                                content={props.processId.toString()}
                                                copyText={strings.TRANSACTION.RESULT.TECH_LOG.PROCESS_ID_COPY}
                                                copiedText={strings.TRANSACTION.RESULT.TECH_LOG.PROCESS_ID_COPIED}
                                                tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }} />
                                        </Stack>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal verticalAlign="center" grow horizontalAlign="end" tokens={{ childrenGap: 16 }}>
                                        <Checkbox label={strings.TRANSACTION.RESULT.TECH_LOG.ERROR_CHECKBOX_LABEL}
                                            checked={onlyErrors}
                                            disabled={techLog.hasErrors ? false : true}
                                            onRenderLabel={(props) => <Text style={{ maxWidth: 300, fontSize: 12, paddingLeft: 8, color: props?.disabled ? SharedColors.gray10 : SharedColors.gray40 }} block nowrap>{props?.label}</Text>}
                                            onChange={(ev, opt) => setOnlyErrors(opt ?? false)}
                                        />
                                        <SimpleCustomFilter styles={filterStyles} onChange={(ev, value) => setSearchString(value)} />
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Sticky>
                        <List
                            items={items}
                            onRenderCell={onRenderCell}
                        />
                    </ScrollablePane>
                </Stack>
            ) || (
                    <ItemsNotFound doNotUseButton
                        info={strings.TRANSACTION.RESULT.TECH_LOG.NOT_FOUND_CASE.INFO}
                        suggestion={strings.TRANSACTION.RESULT.TECH_LOG.NOT_FOUND_CASE.SUGGESTION}
                        imgSrc={"images/navigation/images/not_found.png"} />
                )
            ) || (
                    <Stack grow tokens={{ childrenGap: 16 }} horizontalAlign="center" verticalFill verticalAlign="center">
                        <Spinner label={strings.SPINNERS.DATA_IS_LOADING} />
                    </Stack>
                ))}
            {techLog.errors && (
                <Stack verticalFill>
                    {techLog.errors && (
                        <MessageBar messageBarType={MessageBarType.error}>{techLog.errors.map(i => <span>{i.description}</span>)}</MessageBar>
                    )}
                    <Stack.Item verticalFill>
                        <SomethingWentWrong action={() => getTechLog(props.transactionId)} />
                    </Stack.Item>
                </Stack>
            )}
        </Stack>
    )
}

const theme = getTheme();

const filterStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = {
    icon: { color: theme.palette.neutralTertiaryAlt },
    iconContainer: {},
    clearButton: {},
    root: {
        backgroundColor: theme.palette.white,
        borderColor: theme.palette.neutralTertiaryAlt,
        width: 250
    }
}