import { Stack, Text, IconButton, Separator, SharedColors, Icon, getTheme } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import React from "react"
import { Control, Controller } from "react-hook-form"
import { Section } from "../../../../components/shared/decorations/Section"
import { IBranding } from "../../../../core/store/typings/IBranding"
import { strings } from "../../../../localization/strings"
import { TMainColor } from "../BrandingDetails"
import { BrandingSelectColorComponent } from "../BrandingSelectColorComponent"

type Tprops = {
    mainColors: TMainColor[]
    onChangeMainColors: (key: string, value: string, prevValue: string) => void
    brandingItem?: IBranding
    control: Control<IBranding, object>
}
export const BrandingColorPallete: React.FC<Tprops> = (props) => {
    const [isSectionCollapsed, { toggle: toggleSectionCollapse }] = useBoolean(false)




    const onRenderHeader = (): React.ReactNode => {
        return (
            <Stack grow>
                <Stack
                    onClick={toggleSectionCollapse}
                    horizontal grow verticalAlign="center"
                    horizontalAlign="space-between"
                    tokens={{ padding: "10px 20px 0px 20px" }}
                    style={{ paddingBottom: isSectionCollapsed ? "10px" : "0", cursor: 'pointer' }}
                >
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_COLOR_PALLETE}</Text>
                        <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20, paddingLeft: 10, paddingRight: 10 }} title={strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_COLOR_PALLETE_INOF} ariaLabel="Info" styles={iconButtonStyles} />
                        <Text style={{ color: SharedColors.gray30, fontSize: 16, width: 400 }}>{strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_COLOR_PALLETE_INOF}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton iconProps={{ iconName: isSectionCollapsed ? "ChevronDown" : "ChevronUp" }} />
                    </Stack.Item>
                </Stack>
                {!isSectionCollapsed && (
                    <Separator style={{ padding: 0 }} />
                )}
            </Stack>
        )
    }

    return (
        <Stack>
            <Section onRenderHeader={onRenderHeader} headerOnly={isSectionCollapsed}>
                <Stack>
                    {!isSectionCollapsed && (
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap >
                            <Stack.Item >
                                <Controller
                                    name="config.primary"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            onChangeField={(value) => {
                                                field.onChange(value)
                                                // onChangePaletteColor('primary', value as string)
                                                // props.onChangeMainColors('primary', value as string)
                                            }}
                                            onChangePalleteColors={props.onChangeMainColors}
                                            colorKey='primary'
                                            mainColors={props.mainColors}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY}
                                        />
                                    } />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.secondary"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            onChangeField={(value) => {
                                                field.onChange(value)
                                                // onChangePaletteColor('secondary', value as string)
                                                // props.onChangeMainColors('secondary', value as string)
                                            }}
                                            onChangePalleteColors={props.onChangeMainColors}
                                            colorKey='secondary'
                                            mainColors={props.mainColors}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.SECONDARY} />
                                    } />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.tertiary"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            onChangeField={(value) => {
                                                field.onChange(value)
                                                // props.onChangeMainColors('tertiary', value as string)
                                                // onChangePaletteColor('tertiary', value as string)
                                            }}
                                            colorKey='tertiary'
                                            onChangePalleteColors={props.onChangeMainColors}
                                            mainColors={props.mainColors}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.TERTIARY} />
                                    } />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.accent"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value}
                                            onChangeField={field.onChange}
                                            mainColors={props.mainColors}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.ACCENT}
                                            description={strings.ORGANIZATION.BRANDING.COLORS.ACCENT_INFO} />
                                    } />
                            </Stack.Item>
                        </Stack>
                    )}
                </Stack>
            </Section>
        </Stack>
    )
}
const theme = getTheme()
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};