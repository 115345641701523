import { Stack, getTheme, IStackStyles, Text, Icon, TooltipHost, IPivotItemProps, IRenderFunction,
    DirectionalHint, SharedColors, IStyleFunctionOrObject, IPivotStyleProps, IPivotStyles, PivotItem, 
    Pivot } from "@fluentui/react"
import * as React from 'react';
import { useSelector } from "react-redux";
import { IStore } from "../../../../../../core/store";
import { Dispatch } from "react";
import { strings } from "../../../../../../localization/strings";
import { ILandingConfig } from "@piceasoft/core";
import { LandingPreview } from "./LandingPreview";
import { LandingSetting } from "./LandingSetting";
import { ShowConfigurationPivot } from "../../../../../shared/configurator/stages/common/pivots/ShowConfigurationPivot"

type TProps = {
    config?: ILandingConfig,
    onChangeDispatch: Dispatch<any>
    serviceName?: string
}

export type TIdentificationModuleSettingsPivotStates = "ui" | "config" 

const getTabId = (itemKey: string) => {
    return `LandingPivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {       
        case "ui": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.TITLE        
        case "config": return strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.CONFIG.TITLE
        default: return m;
    }
}

const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
    return (
        <span style={{ ...iProps?.style }}>
            <TooltipHost directionalHint={DirectionalHint.bottomCenter} content={iProps?.headerText}>
                <Icon iconName={iProps?.itemIcon} style={{ fontSize: 16, display: "flex", padding: '0px 12px 2px 12px' }} />
            </TooltipHost>
        </span>
    )
}

export const LandingPivot: React.FC<TProps> = (props) => {

    const module = useSelector((s: IStore) => s.configurator.landing as ILandingConfig)    
    if (!module) return null    

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TIdentificationModuleSettingsPivotStates>("ui");

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TIdentificationModuleSettingsPivotStates);
    };

    return (
        <>
            <Stack horizontalAlign="end" styles={headerStylesMain}>
                <Stack.Item>
                    <Pivot styles={pivotStyles} selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="none">
                        <PivotItem itemIcon={'View'} headerText={getPivotHeaderText("ui")} onRenderItemLink={onRenderItemLink} itemKey="ui" />                        
                        <PivotItem itemIcon={'Code'} headerText={getPivotHeaderText("config")} onRenderItemLink={onRenderItemLink} itemKey="config" />
                    </Pivot>
                </Stack.Item>
            </Stack>
            {props.config && (<Stack styles={contentStyles} horizontal verticalFill>
                <Stack verticalAlign="center" horizontalAlign="center" style={{ background: theme.palette.neutralLighter }}>                                      
                        <LandingPreview config={ props.config} serviceName={props.serviceName}/>                                     
                </Stack>
                <Stack.Item grow style={{ zIndex: 10, minHeight: 476 }}>   
                {selectedPivotKey == "ui" && ( <LandingSetting config={ props.config} onChangeDispatch={props.onChangeDispatch}/>)}        
                {selectedPivotKey == "config" && (<ShowConfigurationPivot json={props.config ?? {}} />)}                                 
                </Stack.Item>
            </Stack>)}           
        </>
    )
}

const theme = getTheme();

const headerStylesMain: IStackStyles = { root: { position: "relative", zIndex: 14 } };
const contentStyles: IStackStyles = { root: { backgroundColor: theme.palette.white, boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0", zIndex: 10 } };

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderLeft: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}