import { Stack, ScrollablePane } from "@fluentui/react"
import { Dispatch } from "react"
import { useSelector } from "react-redux"
import { actionCreators } from "../../../../../../../core/actions/configurator-actions"
import { IStore } from "../../../../../../../core/store"
import { strings } from "../../../../../../../localization/strings"
import { Section } from "../../../../../decorations/Section"
import { ParameterItem } from "../../../../components/ParameterItem"
import { IIdentificationPiceaOneConfig } from "@piceasoft/core"

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}
export const PiceaOneCommonSettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationPiceaOneConfig)

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.COMMON.HEADER} max flat>
                <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.PICEA_ONE.PIVOTS.COMMON.USE_MDM_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.useMdm}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                useMdm: config.useMdm !== undefined ? undefined : true,
                            } as IIdentificationPiceaOneConfig))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.PICEA_ONE.PIVOTS.COMMON.USE_MDM_DESCRIPTION}
                    >
                        </ParameterItem>
                </Section>
            </ScrollablePane>
        </Stack.Item>
    )
}