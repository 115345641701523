import {
    Dropdown, getFocusStyle, getTheme, IComboBoxOption,
    Icon, IconButton, Link, mergeStyleSets, MessageBar,
    MessageBarType, PrimaryButton, SharedColors, Spinner, Stack, Text
} from "@fluentui/react"
import { useId } from "@uifabric/react-hooks"
import * as React from "react"
import { escapeRegExp } from "../../../../../assets/ts/utils/regex"
import { SecondaryButton } from "../../../../../components/shared/buttons/SecondaryButton"
import { Section } from "../../../../../components/shared/decorations/Section"
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from "../../../../../components/shared/search/SuggestedSearch"
import { portalApi } from "../../../../../core/api/api"
import { ICatalog, ICompany, IDocument, IService } from "../../../../../core/store"
import { CatalogTypes, IResponseError } from "@piceasoft/core"
import { IRequestResult } from "../../../../../core/store/typings/IResponseResult"
import { strings } from "../../../../../localization/strings"
import { TGrade } from "../ServiceConfigurationPivotBasedState"
import { ServiceConfigurationSettingsWorkflowIcon } from "./ServiceConfigurationSettingsWorkflowIcon"
import { ValidationErrors, getValidtionErrorTitle } from "../../../../../core/store/typings/ValidationErrors"
import { CSHHelpLink } from "../../../../../components/shared/help/CSHHelp"
import {Experience} from "../../../../../components/shared/configurator/helpers/evisibility";
import {ServiceTypes} from "../../../../../core/store/typings/ServiceTypes"


type TServiceSettingsPivotProps = {
    data: IService
    grades?: TGrade[]
    updateService: (data: IService) => Promise<IRequestResult<IService>>
    isFetching: boolean
    generateIcon?: boolean
}

export const ServiceConfigurationSettingsPivot: React.FC<TServiceSettingsPivotProps> = (props) => {

    const [service, setService] = React.useState<IService>(props.data)

    const [catalogsState, setCatalogsState] = React.useState<{ fetched: boolean, data?: ICatalog[], errors?: IResponseError[] }>({ fetched: false })
    const [documentsState, setDocumentsState] = React.useState<{ fetched: boolean, data?: IDocument[], errors?: IResponseError[] }>({ fetched: false })
    const [catalogsOptions, setCatalogsOptions] = React.useState<IComboBoxOption[]>()
    const [documentsOptions, setDocumentsOptions] = React.useState<IComboBoxOption[]>()
    const [isFetchingCommonSettings, setIsFetchingCommonSettings] = React.useState(false)
    const [isFetchingWorkflowIcon, setIsFetchingWorkflowIcon] = React.useState(false)
    const [isFetchingDocuments, setIsFetchingDocuments] = React.useState(false)
    const [isCommonSettingsChanged, setIsCommonSettingsChanged] = React.useState(false)
    const [isWorkflowIconChanged, setIsWorkflowIconChanged] = React.useState(false)
    const [isDocumentsValid, setIsDocumentsValid] = React.useState(true)
    const [isDocumentsChanged, setIsDocumentsChanged] = React.useState(false)

    const [commonSettingsError, setCommonSettingsError] = React.useState<string>()
    const [workflowIconError, setWorkflowIconError] = React.useState<string>()
    const [commonSettingsSaved, setCommonSettingsSaved] = React.useState(false)
    const [documentsError, setDocumentsError] = React.useState<string>()
    const [documentsWasSaved, setDocumentsWasSaved] = React.useState(false)

    const AllowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif", "image/svg+xml"]

    const [companiesState, setCompaniesState] = React.useState<{ fetched: boolean, data?: ICompany[], errors?: IResponseError[] }>({ fetched: false })
    const [companiesOptions, setCompaniesOptions] = React.useState<IComboBoxOption[]>()

    const catalogsHostId = useId("catalogs-host-id")
    const catalogsTextId = useId("catalogs-text-id")
    const catalogsCalloutListId = useId("catalogs-callout-id")
    const [catalogsFilterValue, setCatalogsFilterValue] = React.useState<string>()

    const documentsHostId = useId("documents-host-id")
    const documentsTextId = useId("documents-text-id")
    const documentsCalloutListId = useId("documents-callout-id")
    const [documentsFilterValue, setDocumentsFilterValue] = React.useState<string>()

    React.useEffect(() => {
        getCatalogs()
        getDocuments()
        getCompanies()
    }, [])

    React.useEffect(() => {
        console.log(props.data)
        setService(props.data)
    }, [props.data])

    React.useEffect(() => {
        prepareCatalogOptions()
    }, [catalogsState])

    React.useEffect(() => {
        if (!props.isFetching) {
            if (isFetchingCommonSettings) {
                setIsFetchingCommonSettings(false)
            }
            if (isFetchingDocuments) {
                setIsFetchingDocuments(false)
            }
        }
    }, [props.isFetching])

    React.useEffect(() => {
        console.log(service.documents)
        prepareCompaniesOptions()
        if (service.documents) {
            setIsDocumentsValid((service.documents.find(i => i.companyId === undefined) || service.documents.find(i => i.documentId === undefined)) ? false : true)
        }
    }, [companiesState, service.documents])

    React.useEffect(() => {
        prepareDocumentOptions()
    }, [documentsState])

    const getCatalogs = async () => {
        setDocumentsState({ fetched: false })
        const result = await portalApi.organization.catalogs.getCatalogsByCatalogType(CatalogTypes.Tradein)
        console.log(result)
        setCatalogsState({ errors: (result.errors && result.errors.length > 0) ? result.errors : undefined, fetched: true, data: result.data?.filter(i => !i.removed) })
    }

    const getDocuments = async () => {
        setDocumentsState({ fetched: false })
        const result = await portalApi.organization.documents.getDocuments()
        console.log(result)
        setDocumentsState({ errors: (result.errors && result.errors.length > 0) ? result.errors : undefined, fetched: true, data: result.data?.filter(i => !i.removed) })
    }

    const getCompanies = async () => {
        setDocumentsState({ fetched: false })
        const result = await portalApi.organization.companies.getCompanies()
        console.log(result)
        setCompaniesState({ errors: (result.errors && result.errors.length > 0) ? result.errors : undefined, fetched: true, data: result.data?.filter(i => !i.removed) })
    }

    const prepareCatalogOptions = () => {
        setCatalogsOptions(undefined)
        if (catalogsState.data) {
            setCatalogsOptions([{ key: 'null', text: strings.COMMON.NOT_CHOSED }, ...catalogsState.data.map(i => {
                return { key: i.id, text: i.name } as IComboBoxOption
            })])
        }
    }

    const updateCommonSettings = async () => {
        setIsFetchingCommonSettings(true)
        setCommonSettingsError(undefined)
        const result = await props.updateService({ ...props.data, catalogId: service.catalogId, documentId: service.documentId })
        if (result.successed) {
            setIsCommonSettingsChanged(false)
        }
        if (result.errors && result.errors.length > 0) {
            setCommonSettingsError(result.errors[0].description)
        }
        setIsFetchingCommonSettings(false)
    }

    const updateWorkflowIcon = async (file?: File) => {
        setIsFetchingWorkflowIcon(true)
        setWorkflowIconError(undefined)
        let uploadResult: IRequestResult<string> = { successed: false }
        if (file) {
            uploadResult = await portalApi.settings.organization.uploadFile('workflow-icon', file, undefined, true);
            if (uploadResult.errors && uploadResult.errors.length > 0) {
                setWorkflowIconError(uploadResult.errors[0].description)
            }
        } else {
            uploadResult.successed = true;
        }

        if (uploadResult.successed) {
            const result = await props.updateService({ ...props.data, imageSrc: uploadResult.data })
            if (result.successed) {
                setIsWorkflowIconChanged(false)
            }
            if (result.errors && result.errors.length > 0) {
                setWorkflowIconError(result.errors[0].description)
            }
        }
        setIsFetchingWorkflowIcon(false)
    }

    const updateDocuments = async () => {
        setIsFetchingDocuments(true)
        setDocumentsError(undefined)
        const result = await props.updateService({ ...props.data, documents: service.documents })
        if (result.successed) {
            setIsDocumentsChanged(false)
            setService({ ...service, documents: result.data?.documents ?? [] })
        }
        if (result.errors && result.errors.length > 0) {
            setDocumentsError(result.errors[0].description)
        }
        setIsFetchingDocuments(false)
    }

    const prepareDocumentOptions = () => {
        setDocumentsOptions(undefined)
        if (documentsState.data) {
            setDocumentsOptions([{ key: 'null', text: strings.COMMON.NOT_CHOSED }, ...documentsState.data.map(i => {
                return { key: i.id, text: i.name } as IComboBoxOption
            })])
        }
    }

    const prepareCompaniesOptions = () => {
        setCompaniesOptions(undefined)
        if (companiesState.data) {
            setCompaniesOptions([{ key: 'null', text: strings.COMMON.NOT_CHOSED }, ...companiesState.data.map(i => {
                return { key: i.id, text: i.name, disabled: (service.documents && service.documents.find(sd => sd.companyId === i.id)) ? true : false } as IComboBoxOption
            })])
        }
    }

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderCatalogsSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div style={{ cursor: 'pointer' }} className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${catalogsCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, catalogsOptions?.length ?? 0, catalogsTextId, catalogsCalloutListId, () => catalogOptionSelectHandler(item), index, item?.text, catalogsHostId)}
                tabIndex={-1}
                onClick={() => {
                    catalogOptionSelectHandler(item)
                    focusElement(`${catalogsCalloutListId}-${index as number}`, catalogsHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const onRenderDocumentsSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div style={{ cursor: 'pointer' }} className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${documentsCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, documentsOptions?.length ?? 0, documentsTextId, documentsCalloutListId, () => documentOptionSelectHandler(item), index, item?.text, documentsHostId)}
                tabIndex={-1}
                onClick={() => {
                    documentOptionSelectHandler(item)
                    focusElement(`${documentsCalloutListId}-${index as number}`, documentsHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    const catalogOptionSelectHandler = (item?: IComboBoxOption) => {
        if (!isCommonSettingsChanged) {
            setIsCommonSettingsChanged(true)
        }
        if (item?.key.toString() !== 'null') {
            setService({ ...service, catalogId: item?.key as string })
        } else {
            setService({ ...service, catalogId: undefined })
        }
    }

    const documentOptionSelectHandler = (item?: IComboBoxOption) => {
        if (!isCommonSettingsChanged) {
            setIsCommonSettingsChanged(true)
        }
        if (item?.key.toString() !== 'null') {
            setService({ ...service, documentId: item?.key as number })
        } else {
            setService({ ...service, documentId: undefined })
        }
    }

    const documentsCompanyOptionSelectHandler = (item: IComboBoxOption, index: number,) => {
        if (!isDocumentsChanged) {
            setIsDocumentsChanged(true)
        }
        if (item.key.toString() !== 'null') {
            setService({
                ...service, documents: service.documents?.map(
                    (sd, sdIndex) => {
                        if (sdIndex === index) {
                            return { ...sd, companyId: item?.key.toString() }
                        }
                        return sd
                    })
            })
        } else {
            setService({
                ...service, documents: service.documents?.map(
                    (sd, sdIndex) => {
                        if (sdIndex === index) {
                            return { ...sd, companyId: undefined }
                        }
                        return sd
                    })
            })
        }
    }

    const undoServiceCommonSettings = () => {
        if (isCommonSettingsChanged) {
            setIsCommonSettingsChanged(false)
        }
        setService({ ...service, documentId: props.data.documentId, catalogId: props.data.catalogId })
    }

    const undoServiceDocuments = () => {
        if (isDocumentsChanged) {
            setIsDocumentsChanged(false)
        }
        setService({ ...service, documents: props.data.documents })
    }

    const addServiceDocument = () => {
        if (!isDocumentsChanged) {
            setIsDocumentsChanged(true)
        }
        setService({ ...service, documents: service.documents ? [...service.documents, {}] : [{}] })
    }

    const deleteServiceDocument = (index: number) => {
        if (!isDocumentsChanged) {
            setIsDocumentsChanged(true)
        }
        setService({ ...service, documents: service.documents?.filter((sd, sdIndex) => sdIndex !== index) })
    }

    const documentsDocumentOptionSelectHandler = (item: IComboBoxOption, index: number,) => {
        if (!isDocumentsChanged) {
            setIsDocumentsChanged(true)
        }
        setService({
            ...service, documents: service.documents?.map(
                (sd, sdIndex) => {
                    if (sdIndex === index) {
                        return { ...sd, documentId: Number.parseInt(item.key.toString()) }
                    }
                    return sd
                })
        })
    }

    return (
        <Stack tokens={{ padding: "24px 32px", childrenGap: 20 }}>
            {props.data.workflow.errors && props.data.workflow.errors.length > 0 &&
                props.data.workflow.errors.filter((error) => error.code == ValidationErrors.NotSetCatalog).length > 0 &&
                (
                    <Stack style={{ width: "100%" }} horizontal verticalAlign="center" tokens={{ padding: "10px 0px 0px 0px" }}>
                        <Stack.Item style={{ width: "100%" }}>
                            <MessageBar style={{ width: "100%" }} isMultiline={true} messageBarType={MessageBarType.error}>
                                <b style={{ width: "100%" }}>{getValidtionErrorTitle(ValidationErrors.NotSetCatalog)}</b>
                            </MessageBar>
                        </Stack.Item>
                    </Stack>
                )}
            {!(props.data.channel == Experience.Mobile && props.data.serviceType == ServiceTypes.SelfService) && <Section title={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.COMMON}
                onRenderCommand={() => (
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <SecondaryButton disabled={props.isFetching || !isCommonSettingsChanged || !props.grades} onClick={undoServiceCommonSettings} iconProps={{ iconName: "Undo" }}></SecondaryButton>
                    </Stack>
                )}
            >
                <Stack tokens={{ childrenGap: 16 }}>
                    <Stack horizontal tokens={{ childrenGap: 24 }}>
                        <Stack.Item>
                            {(catalogsOptions?.length ?? 0) <= 10 && (
                                <Dropdown
                                    label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.CATALOG}
                                    options={catalogsOptions ?? []}
                                    styles={{ dropdown: { width: 320 } }}
                                    onChange={(ev, opt) => opt && catalogOptionSelectHandler(opt)}
                                    disabled={!catalogsOptions || catalogsOptions.length === 0 || props.isFetching}
                                    selectedKey={service.catalogId ?? 'null'}
                                    placeholder={(!catalogsState.fetched) ?
                                        strings.SPINNERS.DATA_IS_GETTING :
                                        catalogsOptions?.find(i => i.key === service.catalogId)?.text ?? (catalogsOptions && catalogsOptions.length > 0 ?
                                            strings.ORGANIZATION.CATALOGS.SELECT_PLACEHOLDER :
                                            strings.ORGANIZATION.CATALOGS.NO_CATALOGS)} />
                            ) || (
                                    <SuggestedSearch
                                        type="dropdown-search"
                                        inputBoxId={catalogsTextId}
                                        hostId={catalogsHostId}
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.CATALOG}
                                        inputBoxPlaceholder={(!catalogsState.fetched) ?
                                            strings.SPINNERS.DATA_IS_GETTING :
                                            catalogsOptions?.find(i => i.key === service.catalogId)?.text ?? (catalogsOptions && catalogsOptions.length > 0 ?
                                                strings.ORGANIZATION.CATALOGS.SELECT_PLACEHOLDER :
                                                strings.ORGANIZATION.CATALOGS.NO_CATALOGS)}
                                        onClickSuggestionCell={(opt) => opt && catalogOptionSelectHandler(opt)}
                                        // setSearchRequest={(opt: IComboBoxOption) => setCatalogsFilterValue(opt?.text)}
                                        searchRequest={catalogsFilterValue}
                                        suggestionsListWidth={320}
                                        inputBoxWidth={320}
                                        disabled={!catalogsOptions || catalogsOptions.length === 0 || props.isFetching}
                                        suggestions={catalogsOptions ?? []}
                                        suggestionsListId={catalogsCalloutListId}
                                        isSearchEqualTheOneSuggestion={false}
                                        onRenderSuggestionCell={onRenderCatalogsSuggestionsCell}
                                        prepareFilteredItems={prepareFilteredOptions}
                                        selectedKey={service.catalogId ?? 'null'}
                                    />
                                )}
                        </Stack.Item>
                        <Stack.Item>
                            {(documentsOptions?.length ?? 0) <= 10 && (
                                <Dropdown
                                    label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.DOCUMENT}
                                    options={documentsOptions ?? []}
                                    onChange={(ev, opt) => documentOptionSelectHandler(opt)}
                                    disabled={!documentsOptions || documentsOptions.length === 0 || props.isFetching}
                                    styles={{ dropdown: { width: 320 } }}
                                    selectedKey={service.documentId ?? 'null'}
                                    placeholder={(!documentsState.fetched) ?
                                        strings.SPINNERS.DATA_IS_GETTING :
                                        documentsOptions?.find(i => i.key === service.documentId)?.text ?? (documentsOptions && documentsOptions.length > 0 ?
                                            strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.SELECT_PLACEHOLDER :
                                            strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NO_TEMPLATES)} />
                            ) || (
                                    <SuggestedSearch
                                        type="dropdown-search"
                                        inputBoxId={documentsTextId}
                                        hostId={documentsHostId}
                                        label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.DOCUMENT}
                                        inputBoxPlaceholder={(!documentsState.fetched) ?
                                            strings.SPINNERS.DATA_IS_GETTING :
                                            documentsOptions?.find(i => i.key === service.documentId)?.text ?? (documentsOptions && documentsOptions.length > 0 ?
                                                strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.SELECT_PLACEHOLDER :
                                                strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NO_TEMPLATES)}
                                        onClickSuggestionCell={documentOptionSelectHandler}
                                        // setSearchRequest={(opt: IComboBoxOption) => setDocumentsFilterValue(opt?.text)}
                                        searchRequest={documentsFilterValue}
                                        suggestionsListWidth={320}
                                        inputBoxWidth={320}
                                        disabled={!documentsOptions || documentsOptions.length === 0 || props.isFetching}
                                        suggestions={documentsOptions ?? []}
                                        suggestionsListId={documentsCalloutListId}
                                        isSearchEqualTheOneSuggestion={false}
                                        onRenderSuggestionCell={onRenderDocumentsSuggestionsCell}
                                        prepareFilteredItems={prepareFilteredOptions}
                                        selectedKey={service.documentId?.toString() ?? 'null'}
                                    />
                                )}
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <PrimaryButton disabled={props.isFetching || !isCommonSettingsChanged || !props.grades} onClick={updateCommonSettings} >{strings.BUTTONS.TEXT.APPLY}</PrimaryButton>
                        </Stack.Item>
                        <Stack verticalAlign='center'>
                            {(isFetchingCommonSettings || !props.grades) && <Spinner />}
                        </Stack>
                    </Stack>
                    {!props.data.workflow?.useGradesCategories && props.grades?.find(i => !i.isInCatalog) && (
                        <MessageBar messageBarType={MessageBarType.warning} >{strings.CONSTRUCTOR.GRADES.NOT_ALL_CATALOG_GRADE}</MessageBar>
                    )}
                    {commonSettingsError && (
                        <MessageBar messageBarType={MessageBarType.error} >{commonSettingsError}</MessageBar>
                    )}
                    {documentsState.errors && (
                        documentsState.errors.map(e => <MessageBar messageBarType={MessageBarType.error} >{e.description}</MessageBar>)
                    )}
                </Stack>
            </Section>}
            {!(props.data.channel == Experience.Mobile && props.data.serviceType == ServiceTypes.SelfService) && <Section title={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.COMPANY_DOCUMENTS}
                description={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.COMPANY_DOCUMENTS_DESCRIPTION}
                onRenderCommand={() => (
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <SecondaryButton disabled={props.isFetching || !isDocumentsChanged} onClick={undoServiceDocuments} iconProps={{ iconName: "Undo" }}></SecondaryButton>
                        <SecondaryButton disabled={props.isFetching} onClick={addServiceDocument} iconProps={{ iconName: "Add" }}></SecondaryButton>
                    </Stack>
                )}
            >
                <Stack grow tokens={{ childrenGap: 16 }}>
                    {service.documents && service.documents.length > 0 && service.documents.map((i, index) => <Stack.Item key={index} grow>
                        <Stack verticalFill grow horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                            <Stack style={{ paddingTop: 2 }} horizontal verticalAlign='center' tokens={{ childrenGap: 24 }}>
                                <Dropdown
                                    label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.COMPANY}
                                    options={companiesOptions ?? []}
                                    onChange={(ev, opt) => opt && documentsCompanyOptionSelectHandler(opt, index)}
                                    disabled={!companiesOptions || companiesOptions.length === 0 || props.isFetching}
                                    styles={{ dropdown: { width: 320 } }}
                                    selectedKey={i?.companyId ?? 'null'}
                                    placeholder={companiesOptions?.find(co => co.key === i.companyId)?.text ?? (companiesOptions && companiesOptions.length > 0 ?
                                        strings.ORGANIZATION.COMPANIES.COMPANY_SELECT_PLACEHOLDER :
                                        strings.ORGANIZATION.COMPANIES.NO_COMPANIES)} />
                                <Dropdown
                                    label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.DOCUMENT}
                                    options={documentsOptions ?? []}
                                    onChange={(ev, opt) => opt && documentsDocumentOptionSelectHandler(opt, index)}
                                    disabled={!documentsOptions || documentsOptions.length === 0 || props.isFetching}
                                    styles={{ dropdown: { width: 320 } }}
                                    selectedKey={i?.documentId ?? 'null'}
                                    placeholder={documentsOptions?.find(dop => dop.key === i.documentId)?.text ?? (documentsOptions && documentsOptions.length > 0 ?
                                        strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.SELECT_PLACEHOLDER :
                                        strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.NO_TEMPLATES)} />
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                <IconButton
                                    disabled={props.isFetching}
                                    iconProps={{ iconName: 'Delete' }}
                                    onClick={() => deleteServiceDocument(index)}
                                />
                            </Stack>
                        </Stack>
                    </Stack.Item>)}
                    {((!service.documents || service.documents.length === 0) && (props.data.documents && props.data.documents.length > 0)) && (
                        <Stack.Item tokens={{ padding: "8px 0px" }}>
                            <Text variant='medium' style={{ color: SharedColors.gray10 }}>{strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.NO_SERVICE_DOCUMENTS}</Text>
                        </Stack.Item>
                    )}
                    {(!((!service.documents || service.documents.length === 0) && (!props.data.documents || props.data.documents.length === 0))) && (<Stack horizontal tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <PrimaryButton disabled={props.isFetching || !isDocumentsChanged || !isDocumentsValid} onClick={updateDocuments} >{strings.BUTTONS.TEXT.APPLY}</PrimaryButton>
                        </Stack.Item>
                        <Stack verticalAlign='center'>
                            {isFetchingDocuments && <Spinner />}
                        </Stack>
                    </Stack>)}
                    {((!service.documents || service.documents.length === 0) && (!props.data.documents || props.data.documents.length === 0)) && (
                        <Stack horizontalAlign="center" tokens={{ padding: 20, childrenGap: 20 }}>
                            <Stack verticalAlign="center" horizontalAlign="center" style={{
                                width: 72, height: 72, borderRadius: "50%",
                                backgroundColor: theme.palette.neutralLighter
                            }}>
                                <Icon iconName={"Dataflows"} style={{ color: theme.palette.black, fontSize: 28 }} />
                            </Stack>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 0 }}>
                                <Text style={{ fontSize: "1.0625rem", fontWeight: 500, color: theme.palette.black }}>{ }</Text>
                                <Stack horizontalAlign="center">
                                    <Text style={{ color: theme.palette.black }}>{strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.NO_SERVICE_DOCUMENTS_DESCRIPTION}</Text>
                                </Stack>
                                <CSHHelpLink articleid="1127" alignment='left' label={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.NO_SERVICE_DOCUMENTS_LINK_TEXT} />
                            </Stack>
                        </Stack>
                    )}
                    {documentsError && (
                        <MessageBar messageBarType={MessageBarType.error} >{documentsError}</MessageBar>
                    )}
                </Stack>
            </Section >
            }
            <Section title={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.ICON}>
                <Stack tokens={{ childrenGap: 16 }}>
                    <ServiceConfigurationSettingsWorkflowIcon
                        acceptTypeMask={"image/*"}
                        allowedFileTypes={AllowedFileTypes}
                        type="image"
                        changed={isWorkflowIconChanged}
                        resetChanged={() => setIsWorkflowIconChanged(true)}
                        imgSrc={props.data.imageSrc}
                        serviceName={props.data.name}
                        lable={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.ICON_LOAD_LABEL}
                        isFetching={isFetchingWorkflowIcon}
                        description={strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.ICON_LOAD_DESCRIPTION}
                        onConfirm={updateWorkflowIcon}
                        generateIcon={props.generateIcon}
                    />
                    {workflowIconError && (
                        <MessageBar messageBarType={MessageBarType.error} >{workflowIconError}</MessageBar>
                    )}
                </Stack>
            </Section>
        </Stack >
    )
}

const theme = getTheme();