import { AppThunkAction } from "..";
import { endpoints } from "../api/endpoints";
import { consoleStyles } from "../scripts/console";
import { initializeNotifications } from "../scripts/notifications";
import { IUser, IWorkplace } from "../store";
import { IPhoneNumber } from "@piceasoft/core"
import { IRequestResult } from "../store/typings/IResponseResult";

export interface RequestWorkplaceAction { type: 'WORKPLACE_REQUEST_DATA' }
export interface ReceiveWorkplaceAction { type: 'WORKPLACE_RECEIVE_DATA', data?: IWorkplace }


export type KnownAction = RequestWorkplaceAction | ReceiveWorkplaceAction

export const actionCreators = {
    /** Запрос на получение данных авторизованного рабочего места. */
    requestWorkplace: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestWorkplace()", consoleStyles.logic);
        const appState = getState();
        if (appState && appState.environment && appState.environment.isAuthenticated === true) {
            dispatch({ type: 'WORKPLACE_REQUEST_DATA' });
            fetch(endpoints.v1.requestWorkplace)
                .then(response => response.json() as Promise<IRequestResult<IWorkplace>>)
                .then(data => {
                    dispatch({ type: 'WORKPLACE_RECEIVE_DATA', data: data.data });
                    initializeNotifications();
                })
                .catch(response => {
                    console.log(response);
                });
        }
    },
    /** Refresh workplace user. */
    refreshUserData: (userData: {display?: string, imgSrc?: string, email?: string, phone?: IPhoneNumber }): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.debug("%c" + "LOGIC requestWorkplace()", consoleStyles.logic);
        const workplace = getState().workplace;
        dispatch({ type: 'WORKPLACE_RECEIVE_DATA', data: {...workplace, user: {
            ...workplace.user,
            display: userData.display ?? workplace.user?.display,
            email: userData.email ?? workplace.user?.email,
            imgSrc: userData.imgSrc ?? workplace.user?.imgSrc,
            phone: userData.phone ?? workplace.user?.phone
        } as IUser} });
    }

};
