import { Link, SharedColors, Stack, Text, Toggle } from "@fluentui/react"
import { CustomSeparator } from "../../../../../components/shared/decorations/CustomSeparator"
import { RemoveArea } from "../../../../../components/shared/decorations/RemoveArea"
import { Section } from "../../../../../components/shared/decorations/Section"
import { IPoint } from "../../../../../core/store"
import { strings } from "../../../../../localization/strings"
import { PointInformationPivot } from "./pivots/PointInformationPivot"
import { PointServicesPivot } from "./pivots/PointServicesPivot"
import { PointSettingsPivot } from "./pivots/PointSettingsPivot"
import { TPointPivotState } from "./StructurePoint"

export type TPointPivotBasedStateProps = {
    state: TPointPivotState
    point: IPoint
    onDelete: () => void
    toggleEnabled: () => void
    toggleUseServices: () => void
    updateState: (item: IPoint) => void
}

export const PointPivotBasedState: React.FC<TPointPivotBasedStateProps> = (props) => {

    switch (props.state) {
        case "services": return (
            <Stack verticalFill tokens={{ padding: "24px 0px 0px 0px", childrenGap: 16 }}>
                <PointServicesPivot item={props.point} toggleUseServices={props.toggleUseServices} />
            </Stack>
        )
        case "access": return (<></>)
        case "settings": return (
            <PointSettingsPivot item={props.point} updateState={props.updateState} />
        )
        case "information": return (
            <Stack verticalFill tokens={{ padding: "24px 32px", childrenGap: 16 }}>
                <Section title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.DESCRIPTION}>
                    <PointInformationPivot item={props.point} />
                </Section>
                <CustomSeparator />
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.LOCK_AREA.TITLE}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text style={{ color: SharedColors.gray20 }}>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.LOCK_AREA.TEXT}</Text>
                        <br />
                        <Link href={"#"}>{strings.ORGANIZATION.STRUCTURE.ITEM.POINT.LOCK_AREA.HREF_TEXT}</Link>
                    </Stack.Item>
                    <Stack.Item style={{ paddingTop: 8 }}>
                        <Toggle onClick={props.toggleEnabled} checked={!props.point.enabled} onText={strings.BUTTONS.TOGGLE_ENABLE} offText={strings.BUTTONS.TOGGLE_DISABLE} />
                    </Stack.Item>
                </Stack>
                <CustomSeparator />
                <RemoveArea
                    href="1107"
                    hrefText={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_AREA.HREF_TEXT}
                    onDelete={props.onDelete}
                    text={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_AREA.TEXT}
                    title={strings.ORGANIZATION.STRUCTURE.ITEM.POINT.REMOVE_AREA.TITLE} />
            </Stack>
        )
    }
}
