import { Checkbox, PrimaryButton, Slider, Stack, TextField } from "@fluentui/react"
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { IContractCustomField } from "@piceasoft/core"
import { strings } from "../../../../../localization/strings";
import { onRenderLabel } from "../../../../renders/onRenderLabel";
import { SecondaryButton } from "../../../buttons/SecondaryButton";
import { getSliderLabel } from "../common/helpers/fuctions";
import { CSHHelpLink } from "../../../help/CSHHelp";
import { ParameterText } from '../../../configurator/components/ParameterText'

type TProps = {
    data?: IContractCustomField
    keys: string[]
    onCommit: (data: IContractCustomField) => void
}

export const CustomFieldForm: React.FC<TProps> = ({ data, onCommit, keys }) => {

    const { control, handleSubmit, getValues, formState: { errors }, reset } = useForm({ defaultValues: data });

    const onSubmit = (data: IContractCustomField) => {
        onCommit(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Stack verticalFill tokens={{ childrenGap: 16 }}>
                <Stack verticalFill tokens={{ padding: "0px 20px", childrenGap: 16 }}>
                    <Controller
                        control={control}
                        name={"key"}
                        rules={{
                            required: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_REQUIRED,
                            validate: (value) => {
                                if(value.trim().length === 0) {
                                    return strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_REQUIRED
                                }
                                if (data && data.key === value) {
                                    return true;
                                }
                                return keys.find(k => k === value) ?
                                    strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_VALIDATE :
                                    true
                            }
                        }}
                        render={({ field }) =>
                            <TextField
                                required
                                value={field.value === undefined ? '' : field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                style={{ width: 350 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_LABEL}
                                title={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_TITLE}
                                placeholder={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.KEY_PLACEHOLDER}
                                errorMessage={errors.key?.message}
                            />
                        }
                    />
                    <Controller
                        control={control}
                        name={"label"}
                        rules={{
                            required: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_REQUIRED,
                            validate: (value) => value && value.trim().length > 0 ? true : strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_REQUIRED
                        }}
                        render={({ field }) =>
                            <ParameterText
                                required
                                value={field.value === undefined ? '' : field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                style={{ width: 350 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_LABEL}
                                title={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_TITLE}
                                placeholder={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.LABEL_PLACEHOLDER}
                                errorMessage={errors.label?.message}
                                maxLength={36}
                            />
                        }
                    />
                    <Controller
                        control={control}
                        name={"placeholder"}
                        render={({ field }) =>
                            <ParameterText
                                value={field.value === undefined ? '' : field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                style={{ width: 350 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.PLACEHOLDER_LABEL}
                                title={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.PLACEHOLDER_TITLE}
                                placeholder={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.PLACEHOLDER_PLACEHOLDER}
                                errorMessage={errors.placeholder?.message}
                                maxLength={36}
                            />
                        }
                    />
                    <Controller
                        control={control}
                        name={"mask"}
                        render={({ field }) =>
                            <TextField
                                value={field.value === undefined ? '' : field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                style={{ width: 350 }}
                                onRenderLabel={onRenderLabel}
                                label={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.MASK_LABEL}
                                title={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.MASK_TITLE}
                                placeholder={strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.MASK_PLACEHOLDER}
                                errorMessage={errors.mask?.message}
                            />
                        }
                    />
                    <Stack style={{ maxWidth: 305 }}>
                        <Slider label={getSliderLabel(getValues("minLength"), getValues("maxLength"))}
                            showValue={false} ranged lowerValue={getValues("minLength")}
                            value={getValues("maxLength") ?? 0}
                            onChange={(val, range) => {
                                reset({
                                    ...getValues(),
                                    minLength: range ? range[0] : undefined,
                                    maxLength: range ? range[1] : undefined
                                })
                            }} min={0} max={50} />
                    </Stack>
                    <Controller
                        control={control}
                        name={"required"}
                        render={({ field }) =>
                            <Stack.Item>
                                {onRenderLabel({
                                    title: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.REQUIRED_TITLE,
                                    label: strings.CONSTRUCTOR.STAGES.CONTRACT.FORM.REQUIRED_LABEL
                                })}
                                <Checkbox
                                    checked={field.value}
                                    onChange={field.onChange}
                                />
                            </Stack.Item>
                        }
                    />
                </Stack>
                <Stack.Item tokens={{ padding: "16px 20px 24px 20px" }}>
                    <Stack horizontal grow>
                        <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                            <CSHHelpLink alignment="left" articleid="1160" />
                            <SecondaryButton iconProps={{ iconName: "Undo" }} onClick={() => reset(data)} />
                            <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}