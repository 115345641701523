import React, { Dispatch, FC } from "react";
import { TStageSettingsPivotStates } from "../../../Configurator";
import { ISelfServiceWorkflowConfig, ProcessStages, IRepairOfferConfig } from "@piceasoft/core";
import PromotionList from "./PromotionList";
import ContactFormsPivot from "./ContactFormsPivot";
import { SelfServiceCommonButtonsSettingsPivot } from "./SelfServiceCommonButtonsSettingsPivot";

import { SelfServiceRecommendationsSettingsPivot } from "./SelfServiceRecommendationsSettingsPivot";

import { RepairOfferProviderList } from "./RepairOfferProviderList";
import SelfServiceFollowUpQuestions from "./SelfServiceFollowUpQuestions";

import { DisclaimerList } from "./DisclaimerList";

interface IProps {
    state: TStageSettingsPivotStates
    pivots?: { name: string, component: React.ReactNode }[]
    config?: ISelfServiceWorkflowConfig
    stage: ProcessStages
    onChangeDispatch: Dispatch<any>
    channel: number
    offerProviderCode?: string
}
const SelfServiceSettingsPivotBasedState: FC<IProps> = ({state, pivots, config, stage, onChangeDispatch, channel,offerProviderCode}) => {
    switch (state) {
        case "common_buttons":
            return (<SelfServiceCommonButtonsSettingsPivot config={config as ISelfServiceWorkflowConfig} onChangeDispatch={onChangeDispatch} stage={stage} channel={channel} />)
        case "follow_up_questions":
            return (<SelfServiceFollowUpQuestions state={state} config={config} pivots={pivots} onChangeDispatch={onChangeDispatch}/>)
        case "promotions":
            return (<PromotionList state={state} config={config} pivots={pivots} onChangeDispatch={onChangeDispatch}/>)
        case "contact_forms":
            return (<ContactFormsPivot state={state} config={config} pivots={pivots} onChangeDispatch={onChangeDispatch}/>)
        case "offer_providers":
            return (<RepairOfferProviderList config={config} onChangeDispatch={onChangeDispatch} offerProviderCode= {offerProviderCode}/>)
        case "recommendations":
            return (<SelfServiceRecommendationsSettingsPivot config={config} onChangeDispatch={onChangeDispatch} stage={stage} channel={channel} />)
        case "disclaimers":
            return (<DisclaimerList config={config} onChangeDispatch={onChangeDispatch}/>)
        default:
            return <>{pivots?.find(i => i.name === state)?.component}</>
    }
};

export default SelfServiceSettingsPivotBasedState;
