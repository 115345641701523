import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";
import { IStageConfig, IIdentificationConfigUI, IStageConfigUI } from "@piceasoft/core";
import { ProcessStages } from "@piceasoft/core";

type TProps = {
    config: IStageConfig<IIdentificationConfigUI>
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
}

export const IdentificationStageSelectDevicesSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, stage }) => {

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.SELECT_SOURCE.TITLE}
                noSeparator
                useToggle
                toggleEnabled={true}
                toggleChecked={config?.ui?.selectDevices?.selectSource !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            selectDevices: {
                                ...config?.ui?.selectDevices,
                                selectSource: config?.ui?.selectDevices?.selectSource !== undefined ? undefined : ""
                            }
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.SELECT_SOURCE.DESCRIPTION}
                useTextField
                textFieldEnabled={config?.ui?.selectDevices?.selectSource !== undefined ? true : false}
                textFieldValue={config?.ui?.selectDevices?.selectSource}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                    ...config,
                    ui: {
                        ...config.ui,
                            selectDevices: {
                                ...config?.ui?.selectDevices,
                                selectSource: val
                            }
                    }
                } as IStageConfig<IStageConfigUI>
                ))
                }
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.SELECT_TARGET.TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={config?.ui?.selectDevices?.selectTarget !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            selectDevices: {
                                ...config?.ui?.selectDevices,
                                selectTarget: config?.ui?.selectDevices?.selectTarget !== undefined ? undefined : ""
                            }
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.SELECT_TARGET.DESCRIPTION}
                useTextField
                textFieldEnabled={config?.ui?.selectDevices?.selectTarget !== undefined ? true : false}
                textFieldValue={config?.ui?.selectDevices?.selectTarget}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                    ...config,
                    ui: {
                        ...config.ui,
                            selectDevices: {
                                ...config?.ui?.selectDevices,
                                selectTarget: val
                            }
                    }
                } as IStageConfig<IStageConfigUI>
                ))
                }
            />
            <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.CONTENT_TRANSFER.GUIDE.TITLE}
                useToggle
                toggleEnabled={true}
                toggleChecked={config?.ui?.selectDevices?.information !== undefined ? true : false}
                toggleOnChange={() => {
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            selectDevices: {
                                ...config?.ui?.selectDevices,
                                information: config?.ui?.selectDevices?.information !== undefined ? undefined : ""
                            }
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                }}
                description={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.CONTENT_TRANSFER.GUIDE.DESCRIPTION}
                useTextField
                textFieldMultiline
                textFieldEnabled={config?.ui?.selectDevices?.information !== undefined ? true : false}
                textFieldValue={config?.ui?.selectDevices?.information}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                    ...config,
                    ui: {
                        ...config.ui,
                            selectDevices: {
                                ...config?.ui?.selectDevices,
                                information: val
                            }
                    }
                } as IStageConfig<IStageConfigUI>
                ))
                }
            />
        </Stack>
    )
}