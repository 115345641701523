import { FontIcon, getTheme, SharedColors, Stack, Text } from "@fluentui/react"
import { strings } from "../../../localization/strings"

export const PropertyGrid: React.FC<{ name: string, icon?: string }> = (props) => {
    return (
        <Stack tokens={{ childrenGap: 8 }}>
            <Stack.Item style={{ borderBottom: "1px solid", borderBottomColor: theme.palette.neutralLight, paddingBottom: 6 }}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                    {props.icon && (
                        <FontIcon iconName={props.icon} style={{ fontSize: 16, marginTop: 2 }} />
                    )}
                    <Text style={{ color: SharedColors.gray40, fontSize: 16, fontWeight: 600 }}>{props.name}</Text>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 4 }}>
                    {props.children}
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();
