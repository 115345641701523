import {
    IRenderFunction, IDetailsColumnProps, DetailsList, getTheme,
    IconButton, Text, IPivotStyleProps, IPivotStyles,
    IStyleFunctionOrObject, Pivot, PivotItem, SharedColors, Stack,
    TextField, Icon, Link, PrimaryButton, IColumn, SelectionMode,
    DefaultButton, TooltipHost, ScrollablePane, DirectionalHint,
    IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, Sticky,
    StickyPositionType, IDetailsColumnStyles, IPivotItemProps, FontIcon
} from '@fluentui/react';
import React from 'react';
import { Dispatch } from "react"
import {
    Control, useForm, Controller, UseFormSetValue, SubmitHandler,
    useFieldArray, UseFormGetValues, FieldErrorsImpl, UseFormSetError, UseFormClearErrors
} from 'react-hook-form';
import { onRenderLabel } from '../../renders/onRenderLabel';
import { actionCreators } from '../../../core/actions/configurator-actions';
import { IGrade } from "@piceasoft/core"
import { IGradesCategory } from '@piceasoft/core';
import { strings } from '../../../localization/strings';
import { useSelector } from 'react-redux';
import { IStore } from '../../../core/store';

type TProps = {
    onChangeDispatch: Dispatch<any>
    handleCancelClick: () => void
    categories?: IGradesCategory[]

}

type TPropsForm = {
    categories: IGradesCategory[]
}
export const GradesCategoriesForm: React.FC<TProps> = (props) => {

    const { control, handleSubmit, getValues, setValue, formState: { errors, isValid }, trigger, setError, clearErrors } = useForm<TPropsForm>({ defaultValues: { categories: props.categories ?? [] } })
    const [pivotState, setPivotState] = React.useState(getValues().categories?.[0]?.index ?? 0)

    const onSubmit: SubmitHandler<TPropsForm> = data => {
        props.onChangeDispatch(actionCreators.gradesCategories.save(getValues().categories))
        props.handleCancelClick()
    }

    const handlePivotLinkClick = (item?: PivotItem) => {
        if (item?.props.itemKey === "__ADD_CATEGORY__") {
            onAddCategory()
            setPivotState((getValues().categories?.length ?? 0) + 1)
            return;
        }
        setPivotState(Number.parseInt(item?.props.itemKey as string));
    };

    React.useEffect(() => {
        if (!getValues().categories || getValues().categories.length === 0) {
            setPivotState(0)
        }
        if (getValues().categories && getValues().categories?.length > 0 && (!pivotState || pivotState > getValues().categories.length)) {
            setPivotState(getValues().categories.length)
        }
    }, [getValues().categories])


    const onAddCategory = () => {
        setValue("categories", [...getValues().categories, { name: "", index: (getValues().categories?.length ?? 0) + 1, grades: [], code: '' }])
    }

    const onRenderItemLink: IRenderFunction<IPivotItemProps> = (iProps) => {
        return (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }} style={{ margin: 0, padding: '0px 12px 2px 12px', display: "flex" }}>
                <FontIcon iconName={'Warning'} style={{ fontSize: 12, color: SharedColors.red10 }} />
                <Text style={{ fontSize: 12, color: SharedColors.red10, }}>{iProps?.headerText}</Text>
            </Stack>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%" }}>
            <Stack verticalFill >
                {pivotState > 0 && (
                    <Stack.Item >
                        <Pivot styles={pivotStyles} defaultSelectedKey={pivotState?.toString()} selectedKey={pivotState?.toString()} onLinkClick={handlePivotLinkClick} headersOnly={true} overflowBehavior="menu">
                            <PivotItem itemKey="__ADD_CATEGORY__" itemIcon={"Add"} />
                            {/* TODO, if category tab contains validation issues show red exclamation sign and prevent saving */}
                            {getValues().categories?.sort((a, b) => a.index - b.index).map((c, index) =>{ 
                                if(errors.categories && errors.categories[index]?.type === c.index.toString()) {
                                    return <PivotItem headerText={c.name.length > 0 ? c.name : strings.CONSTRUCTOR.GRADES_CATEGORIES.NEW_CATEGORY_TAB_HEADER} key={`gradeCategoryTab_${c.index}`} itemKey={c.index.toString()} onRenderItemLink={onRenderItemLink} />
                                }
                                return <PivotItem headerText={c.name.length > 0 ? c.name : strings.CONSTRUCTOR.GRADES_CATEGORIES.NEW_CATEGORY_TAB_HEADER} key={`gradeCategoryTab_${c.index}`} itemKey={c.index.toString()} />
                            })}
                        </Pivot>
                    </Stack.Item>
                )}
                <Stack.Item verticalFill style={{ position: 'relative' }} >
                    <CategoriesFields {...{ control, setValue, getValues, trigger, isValid, setError, clearErrors }} categoryIndex={pivotState} setCategoryIndex={setPivotState} errors={errors} />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: '20px 20px' }}>
                        {(pivotState > 0 || (props.categories && props.categories?.length > 0)) && (
                            <PrimaryButton text={strings.BUTTONS.TEXT.CONFIRM} onClick={handleSubmit(onSubmit)} />
                        )}
                        <DefaultButton text={strings.BUTTONS.TEXT.CANCEL} onClick={() => props.handleCancelClick()} />
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

type TPropsFields = {
    setValue: UseFormSetValue<{
        categories: IGradesCategory[];
    }>
    getValues: UseFormGetValues<{
        categories: IGradesCategory[];
    }>
    setCategoryIndex: React.Dispatch<React.SetStateAction<number>>
    errors?: TErrors
    categoryIndex: number
    control: Control<{
        categories: IGradesCategory[];
    }, object>
    isValid: boolean
    setError: UseFormSetError<{
        categories: IGradesCategory[]
    }>
    clearErrors: UseFormClearErrors<{
        categories: IGradesCategory[]
    }>
}
const CategoriesFields: React.FC<TPropsFields> = ({ control, errors, setValue, getValues, categoryIndex, setCategoryIndex, isValid, setError, clearErrors }) => {

    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: "categories"
    });

    const onAddCategoryField = () => {
        setCategoryIndex(categoryIndex + 1)
        append({ name: "", index: (categoryIndex) + 1, grades: [], code: '' })
    }

    const onDeleteCategoryField = (index: number) => {
        remove(index)
        let categories = getValues().categories.sort((a, b) => a.index - b.index)
            .map((i, localIndex) => {
                setCategoryIndex(localIndex + 1)
                return ({ ...i, index: localIndex + 1 })
            })
        setValue("categories", categories)
        checkErrors();
    }

    const onMoveUpCategory = (index: number) => {
        if (index > 0) {
            swap(0, index - 1)
            let categories = getValues().categories.map(gс => {
                if (gс.index === index + 1) {
                    return { ...gс, index: gс.index - 1 }
                }
                if (gс.index === index) {
                    return { ...gс, index: gс.index + 1 }
                }
                return gс
            })
            setCategoryIndex(index)
            setValue("categories", categories)
            checkErrors();
        }
    }

    const onAddGradeToCategory = (categoryIndex: number) => {
        console.log(categoryIndex)
        const category = getValues().categories[categoryIndex - 1]
        const categoryGrades = [...category.grades, { code: "", name: "", index: category.grades.length + 1, description: "", explanation: "" }]
        category.grades = categoryGrades
        const newCategories: IGradesCategory[] = getValues().categories.map((el) => {
            el.index === categoryIndex ? el.grades = categoryGrades : el
            return el
        })
        setValue('categories', newCategories)
    }

    const onDeleteGradeFromCategory = (categoryIndex: number, gradeIndex: number) => {
        const category = getValues().categories[categoryIndex - 1]
        const categoryGrades = category.grades.
            filter(i => i.index !== gradeIndex)
            .sort((a, b) => a.index - b.index)
            .map((i, localIndex) => { return ({ ...i, index: localIndex + 1 }) })
        category.grades = categoryGrades
        const newCategories: IGradesCategory[] = getValues().categories.map((el) => {
            el.index === categoryIndex ? el.grades = categoryGrades : el
            return el
        })
        setValue('categories', newCategories)
    }

    const onMoveUpGradeInCategory = (categoryIndex: number, gradeIndex: number) => {
        if (gradeIndex > 1) {
            const category = getValues().categories[categoryIndex - 1]
            const categoryGrades = category.grades.map(cg => {
                if (cg.index === gradeIndex) {
                    return { ...cg, index: cg.index - 1 }
                }
                if (cg.index === gradeIndex - 1) {
                    return { ...cg, index: cg.index + 1 }
                }
                return cg
            }).sort((a, b) => a.index - b.index)
            category.grades = categoryGrades
            const newCategories: IGradesCategory[] = getValues().categories.map((el) => {
                el.index === categoryIndex ? el.grades = categoryGrades : el
                return el
            })
            setValue('categories', newCategories)
        }
    }

    React.useEffect(() => {
        if(!isValid) {
            fields.map((field, index) => {
                if((index === categoryIndex - 1)) {
                    setError(`categories.${index}`, {
                        type: `${categoryIndex}`,
                        message: `Empty fields detected at category index ${categoryIndex}`
                    })
                }
                
            })
        } else {
            fields.map((field, index) => {
                if((index === categoryIndex - 1)) {
                    clearErrors(`categories.${index}`)
                }
            })
        }
    }, [isValid])

    const checkErrors = () => {
        clearErrors();
        const checkCategories = getValues().categories;
        checkCategories.map(c => {
            let isErrors = false;
            if(c.code.trim().length === 0) {
                isErrors = true;
            }

            if(c.name.trim().length === 0) {
                isErrors = true;
            }

            c.grades.map(g => {
                if(g.code.trim().length === 0) {
                    isErrors = true;
                }

                if(g.name.trim().length === 0) {
                    isErrors = true;
                }
            })

            if(isErrors) {
                setError(`categories.${c.index - 1}`, {
                    type: `${c.index}`,
                    message: `Empty fields detected at category index ${c.index}`
                })
            }
        })
    }

    const gradesDelimiter = useSelector((s: IStore) => s.configurator.gradesDelimiter) ?? ':';
    const regex = new RegExp(`^[^${gradesDelimiter}]*$`);

    return (
        <Stack verticalFill >
            {categoryIndex === 0 && (
                <Stack verticalFill verticalAlign='center'>
                    <Stack.Item>
                        <Stack verticalFill verticalAlign="center" horizontalAlign="center" tokens={{ padding: 20, childrenGap: 20 }}>
                            <Stack verticalAlign="center" horizontalAlign="center" style={{
                                width: 72, height: 72, borderRadius: "50%",
                                backgroundColor: theme.palette.neutralLighter
                            }}>
                                <Icon iconName={"AssessmentGroup"} style={{ color: theme.palette.black, fontSize: 28 }} />
                            </Stack>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 0 }}>
                                <Text style={{ fontSize: "1.0625rem", fontWeight: 500, color: theme.palette.black }}>{ }</Text>
                                <Stack horizontalAlign="center">
                                    <Text style={{ color: theme.palette.black }}>{strings.CONSTRUCTOR.GRADES_CATEGORIES.MODAL_NO_CATEGORIES_DESCRIPTION}</Text>
                                </Stack>
                                <Link>{strings.CONSTRUCTOR.GRADES_CATEGORIES.MODAL_NO_CATEGORIES_LINK_TEXT}</Link>
                                <Stack.Item style={{ padding: "16px 0px 0px 0px" }}>
                                    <PrimaryButton onClick={() => onAddCategoryField()} >{strings.CONSTRUCTOR.BUTTONS.ADD_GRADE_CATEGORY}</PrimaryButton>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                </Stack>
            ) || (
                    <Stack.Item verticalFill>
                        {fields.map((field, index) => (index === categoryIndex - 1) ?
                            <Stack verticalFill key={field.id}
                                style={{ borderTop: `1px solid ${theme.palette.neutralLight}` }}>
                                <Stack style={{ borderBottom: `1px solid ${theme.palette.neutralLight}` }} tokens={{ padding: '8px 8px 8px 16px' }}>
                                    <Text variant="mediumPlus" style={{ fontWeight: 600 }}>{strings.CONSTRUCTOR.GRADES_CATEGORIES.CATEGORY_TITLE}</Text>
                                </Stack>
                                <Stack horizontal verticalAlign='end' style={{ minWidth: 600, }} tokens={{ childrenGap: 16, padding: "16px 16px" }}>
                                    <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                        <IconButton disabled={categoryIndex === 1}
                                            iconProps={{ iconName: 'Back' }}
                                            onClick={() => { onMoveUpCategory(index) }}
                                        />
                                        <Stack style={{ paddingLeft: 6, minWidth: 22 }} horizontalAlign="center" verticalAlign="center">
                                            <Text style={{ fontWeight: 500, color: SharedColors.cyanBlue10 }} >{categoryIndex}</Text>
                                        </Stack>
                                    </Stack>
                                    <Stack grow style={{ paddingTop: 2 }} horizontal verticalAlign='center' tokens={{ childrenGap: 24 }}>
                                        <Stack.Item grow={20}>
                                            <Controller control={control} name={`categories.${index}.code`}
                                            rules={{
                                                /* Rules for "Category code" */
                                                required: strings.CONSTRUCTOR.GRADES.REQUIRED_ERROR,
                                                // TODO: maybe need ban delimeter chars?
                                                pattern: {
                                                    value: regex,
                                                    message: strings.CONSTRUCTOR.GRADES_CATEGORIES.GRADES_DELIMITER_USED_ERROR + ` ( ${gradesDelimiter.toString()} )`,
                                                },
                                                validate: (value) => {
                                                    // Category code needs to be unique
                                                    return getValues().categories.find(el => el.code === value && el.index !== categoryIndex) ? strings.CONSTRUCTOR.GRADES_CATEGORIES.CODE_VALIDATION_ERR_UNIQUE : true
                                                }
                                            }}
                                            render={({ field }) =>
                                                <TooltipHost 
                                                directionalHint={DirectionalHint.rightCenter}
                                                tooltipProps={{
                                                    calloutProps: {
                                                        styles: {
                                                            beak: { background: theme.palette.black },
                                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                        },
                                                    },
                                                }} 
                                                content={errors?.categories?.[index]?.code ? errors?.categories?.[index]?.code?.message : undefined}
                                                >
                                                    <TextField onRenderLabel={onRenderLabel}
                                                        required={true}
                                                        styles={{ errorMessage: { display: 'none' } }} 
                                                        title={strings.CONSTRUCTOR.GRADES_CATEGORIES.CODE_TITLE}
                                                        label={strings.CONSTRUCTOR.GRADES_CATEGORIES.CODE_LABEL}
                                                        value={field.value}
                                                        placeholder={strings.CONSTRUCTOR.GRADES_CATEGORIES.CODE_PLACEHOLDER}
                                                        errorMessage={ 
                                                            errors?.categories?.[index]?.code ? errors?.categories?.[index]?.code?.message : undefined
                                                        }
                                                        onChange={field.onChange}
                                                    />
                                                </TooltipHost>
                                                } />
                                        </Stack.Item>
                                        <Stack.Item grow={20}>
                                            <Controller control={control} name={`categories.${index}.name`} 
                                            rules={{
                                                /* Rules for "Category name" */
                                                required: strings.CONSTRUCTOR.GRADES.REQUIRED_ERROR,
                                                validate: (value) => {
                                                    // Category name needs to be unique
                                                    return getValues().categories.find(el => el.name === value && el.index !== categoryIndex) ? strings.CONSTRUCTOR.GRADES_CATEGORIES.NAME_VALIDATION_ERR_UNIQUE : true
                                                }
                                            }}
                                            render={({ field }) =>
                                            <TooltipHost 
                                            directionalHint={DirectionalHint.rightCenter}
                                            tooltipProps={{
                                                calloutProps: {
                                                    styles: {
                                                        beak: { background: theme.palette.black },
                                                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                                    },
                                                },
                                            }} 
                                            content={errors?.categories?.[index]?.name ? errors?.categories?.[index]?.name?.message : undefined}
                                            >
                                                <TextField onRenderLabel={onRenderLabel}
                                                    required={true}
                                                    styles={{ errorMessage: { display: 'none' } }} 
                                                    title={strings.CONSTRUCTOR.GRADES_CATEGORIES.NAME_TITLE}
                                                    label={strings.CONSTRUCTOR.GRADES_CATEGORIES.NAME_LABEL}
                                                    placeholder={strings.CONSTRUCTOR.GRADES_CATEGORIES.NAME_PLACEHOLDER}
                                                    value={field.value}
                                                    errorMessage={ 
                                                        errors?.categories?.[index]?.name ? errors?.categories?.[index]?.name?.message : undefined
                                                    }
                                                    onChange={field.onChange}
                                                />
                                            </TooltipHost>
                                                } />
                                        </Stack.Item>
                                    </Stack>
                                    <Stack horizontal tokens={{ childrenGap: 8, padding: "12px 0px 0px 0px" }}>
                                        <IconButton
                                            iconProps={{ iconName: 'Delete' }}
                                            onClick={() => onDeleteCategoryField(index)}
                                        />
                                    </Stack>
                                </Stack>
                                {getValues().categories[categoryIndex - 1] && getValues().categories[categoryIndex - 1].grades.length === 0 && (
                                    <Stack.Item>
                                        <Stack verticalFill verticalAlign="center" horizontalAlign="center" tokens={{ padding: 20, childrenGap: 20 }}>
                                            <Stack verticalAlign="center" horizontalAlign="center" style={{
                                                width: 72, height: 72, borderRadius: "50%",
                                                backgroundColor: theme.palette.neutralLighter
                                            }}>
                                                <Icon iconName={"AssessmentGroup"} style={{ color: theme.palette.black, fontSize: 28 }} />
                                            </Stack>
                                            <Stack horizontalAlign="center" tokens={{ childrenGap: 0 }}>
                                                <Text style={{ fontSize: "1.0625rem", fontWeight: 500, color: theme.palette.black }}>{ }</Text>
                                                <Stack horizontalAlign="center">
                                                    <Text style={{ color: theme.palette.black }}>{strings.CONSTRUCTOR.GRADES_CATEGORIES.MODAL_NO_GRADES_DESCRIPTION}</Text>
                                                </Stack>
                                                <Link>{strings.CONSTRUCTOR.GRADES_CATEGORIES.MODAL_NO_GRADES_LINK_TEXT}</Link>
                                                <Stack.Item style={{ padding: "16px 0px 0px 0px" }}>
                                                    <PrimaryButton onClick={() => onAddGradeToCategory(categoryIndex)} >{strings.CONSTRUCTOR.BUTTONS.ADD_GRADE}</PrimaryButton>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack>
                                    </Stack.Item>
                                )}
                                {getValues().categories[categoryIndex - 1].grades.length > 0 && (
                                    <Stack.Item verticalFill>
                                        <Stack verticalFill>
                                            <Stack.Item style={{ borderBottom: `1px solid ${theme.palette.neutralLight}` }} tokens={{ padding: '8px 8px 8px 16px' }}>
                                                <Text variant="mediumPlus" style={{ fontWeight: 600 }}>{strings.CONSTRUCTOR.GRADES_CATEGORIES.GRADES_TITLE}</Text>
                                            </Stack.Item>
                                            <Stack.Item verticalFill>
                                                <CategoriesDetailsListComponent grades={getValues().categories[categoryIndex - 1].grades} {...{ control }} indexCategory={categoryIndex} onAddGrade={onAddGradeToCategory} onDeleteItem={onDeleteGradeFromCategory} onMoveUp={onMoveUpGradeInCategory} errors={errors} />
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                )}
                            </Stack> : <Stack key={field.id}></Stack>)}
                    </Stack.Item>
                )}

        </Stack>)
}

type TErrors = Partial<FieldErrorsImpl<{
    categories: {
        index: number;
        code: string;
        name: string;
        grades: {
            index: number;
            code: string;
            name: string;
            explanation: string;
            description: string;
        }[];
    }[];
}>>

type TPropsDetailsList = {
    control: Control<{
        categories: IGradesCategory[];
    }, object>
    onDeleteItem: (categoryIndex: number, gradeIndex: number) => void,
    onMoveUp: (categoryIndex: number, gradeIndex: number) => void,
    indexCategory: number
    onAddGrade: (categoryIndex: number) => void,
    errors?: TErrors
    grades: IGrade[]

}
export const CategoriesDetailsListComponent: React.FC<TPropsDetailsList> = ({ control, grades, onDeleteItem, onAddGrade, onMoveUp, errors, indexCategory }) => {

    const onRenderHeaderColumn: IRenderFunction<IDetailsColumnProps> = () => {
        return (
            <Stack horizontal tokens={{ padding: 6 }}>
                <IconButton iconProps={{ iconName: 'Add' }}
                    onClick={() => onAddGrade(indexCategory)} />
            </Stack>
        )
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            fieldName: 'testUp',
            onRenderHeader: onRenderHeaderColumn,
            minWidth: 38,
            maxWidth: 38,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Stack key={index} horizontal verticalAlign='center' tokens={{ childrenGap: 8, padding: "0 0 0 6px" }}>
                        <IconButton disabled={!index}
                            iconProps={{ iconName: 'Up' }}
                            onClick={() => { onMoveUp(indexCategory, item.index) }}
                        /></Stack>)
            },
        },

        {
            key: 'column2',
            name: '',
            fieldName: 'testIndex',
            minWidth: 5,
            maxWidth: 5,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Stack style={{ maxWidth: 10, padding: '6px 0 0 0' }} horizontalAlign="start" verticalAlign="baseline">
                        <Text style={{ fontWeight: 500, color: SharedColors.cyanBlue10 }} >{item.index}</Text>
                    </Stack>)
            },
            isPadded: false,
        },

        {
            key: 'column3',
            name: '',
            fieldName: 'testCode',
            onRenderHeader: () => onRenderLabel({
                title: strings.CONSTRUCTOR.GRADES.TITLES.CODE,
                label: strings.CONSTRUCTOR.GRADES.CODE,
                required: true
            }),
            minWidth: 150,
            maxWidth: 150,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`categories.${indexCategory - 1}.grades.${index}.code`}
                        rules={{
                            required: strings.CONSTRUCTOR.GRADES.REQUIRED_ERROR,
                            pattern: {
                                value: /[A-Z0-9_]/,
                                message: strings.CONSTRUCTOR.GRADES.CODE_VALIDATION_ERROR
                            },
                            validate: (value) => {
                                return grades.find(el => el.code === value && el.index !== item.index) ?
                                    strings.CONSTRUCTOR.GRADES.GRADES_CODE_VALIDATION : true
                            }
                        }}
                        render={({ field }) =>
                            <TooltipHost directionalHint={DirectionalHint.rightCenter}
                                styles={{ root: { display: "flex", cursor: "default" } }}
                                tooltipProps={{
                                    calloutProps: {
                                        styles: {
                                            beak: { background: theme.palette.black },
                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                        },
                                    },
                                }} content={(errors?.categories?.[indexCategory - 1]?.grades) ? errors?.categories?.[indexCategory - 1]?.grades?.[index]?.code?.message : undefined}>
                                <TextField styles={{ errorMessage: { display: 'none' } }} value={field.value}
                                    placeholder={strings.CONSTRUCTOR.GRADES.PLACEHOLDERS.CODE}
                                    errorMessage={errors?.categories?.[indexCategory - 1]?.grades ? errors?.categories?.[indexCategory - 1]?.grades?.[index]?.code?.message : undefined}
                                    onChange={(ev, value) => {
                                        if (ev.target) {
                                            (ev.target as HTMLInputElement).value = value ? value.toUpperCase() : ''
                                            field.onChange(ev)
                                        }
                                    }} />
                            </TooltipHost>
                        } />
                )
            },
            isPadded: false,
        },
        {
            key: 'column4',
            name: '',
            fieldName: 'testName',
            onRenderHeader: () => onRenderLabel({
                title: strings.CONSTRUCTOR.GRADES.TITLES.NAME,
                label: strings.CONSTRUCTOR.GRADES.NAME,
                required: true
            }),
            minWidth: 180,
            maxWidth: 180,
            isMultiline: true,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`categories.${indexCategory - 1}.grades.${index}.name`}
                        rules={{
                            required: strings.CONSTRUCTOR.GRADES.REQUIRED_ERROR,
                            validate: (value) => {
                                return grades.find(el => el.name === value && el.index !== item.index) ?
                                    strings.CONSTRUCTOR.GRADES.GRADES_NAME_VALIDATION : true
                            }
                        }}
                        render={({ field }) =>
                            <TooltipHost directionalHint={DirectionalHint.rightCenter}
                                styles={{ root: { display: "flex", cursor: "default" } }}
                                tooltipProps={{
                                    calloutProps: {
                                        styles: {
                                            beak: { background: theme.palette.black },
                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                        },
                                    },
                                }} content={errors?.categories?.[indexCategory - 1]?.grades ? errors?.categories?.[indexCategory - 1]?.grades?.[index]?.name?.message : undefined}>
                                <TextField styles={{ errorMessage: { display: 'none' } }} value={field.value}
                                    placeholder={strings.CONSTRUCTOR.GRADES.PLACEHOLDERS.NAME}
                                    errorMessage={errors?.categories?.[indexCategory - 1]?.grades ? errors?.categories?.[indexCategory - 1]?.grades?.[index]?.name?.message : undefined}
                                    onChange={(ev) => {
                                        field.onChange(ev)
                                    }} />
                            </TooltipHost>
                        } />
                )
            },
            isPadded: false,
        },

        {
            key: 'column5',
            name: '',
            fieldName: 'testExplanation',
            onRenderHeader: () => onRenderLabel({
                title: strings.CONSTRUCTOR.GRADES.TITLES.EXPLANATION,
                label: strings.CONSTRUCTOR.GRADES.EXPLANATION,
            }),
            minWidth: 200,
            maxWidth: 200,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`categories.${indexCategory - 1}.grades.${index}.explanation`}
                        render={({ field }) =>
                            <TextField value={field.value}
                                placeholder={strings.CONSTRUCTOR.GRADES.PLACEHOLDERS.EXPLANATION}
                                onChange={field.onChange} />} />
                )
            },
            isPadded: false,
        },

        {
            key: 'column6',
            name: '',
            fieldName: 'testDescription',
            onRenderHeader: () => onRenderLabel({
                title: strings.CONSTRUCTOR.GRADES.TITLES.DESCRIPTION,
                label: strings.CONSTRUCTOR.GRADES.DESCRIPTION,
            }),
            minWidth: 30,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`categories.${indexCategory - 1}.grades.${index}.description`}
                        render={({ field }) =>
                            <TextField value={field.value}
                                placeholder={strings.CONSTRUCTOR.GRADES.PLACEHOLDERS.DESCRIPTION}
                                onChange={field.onChange} />} />
                )
            },
            isPadded: false,
        },

        {
            key: 'column7',
            name: '',
            fieldName: 'testDelete',
            minWidth: 30,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Stack key={index}>
                        <IconButton
                            style={{ padding: '0 16px 0 0' }}
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => { onDeleteItem(indexCategory, item.index) }}
                        /></Stack>)
            },
            isPadded: false,
        }
    ]

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        props.styles = { root: { padding: 0 } }
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };
    return (

        <Stack verticalFill style={{ position: 'relative' }}>
            <ScrollablePane>
                <DetailsList items={grades} columns={columns} selectionMode={SelectionMode.none} onRenderDetailsHeader={onRenderDetailsHeader} />
            </ScrollablePane>
        </Stack>



    )

}

const theme = getTheme();

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
    linkIsSelected: {
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        backgroundColor: theme.palette.white,
        fontWeight: 400,
        color: SharedColors.cyanBlue10
    },
    linkContent: {
        fontSize: 12,
        margin: 0,
        padding: '0px 12px 2px 12px',
        display: "flex"
    },
    link: {
        borderTop: `1px solid ${theme.palette.neutralLight}`,
        borderRight: `1px solid ${theme.palette.neutralLight}`,
        padding: 0,
        margin: 0,
        height: '34px',
        lineHeight: '34px',
        '::before': {
            display: 'none'
        },
        '::after': {
            display: 'none'
        },
        color: SharedColors.gray20
    },
    root: {
        display: "flex",
        zIndex: 20,
        marginRight: 16,
        'button:last-child': {
            borderRight: `1px solid ${theme.palette.neutralLight}`
        }
    }
}
const columnStyles: Partial<IDetailsColumnStyles> = {
    root:
    {
        ':hover': { backgroundColor: 'transparent' },
        ':active': { backgroundColor: 'transparent' }
    }
}
