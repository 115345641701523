import { IPromotion } from "../../../core/store/typings/IPromotion"
import { TFinancePromotionPivotState } from "./FinancePromotionDetails"
import { FinancePromotionInfoPivot } from "./pivots/FinancePromotionInfoPivot"
import { FinancePromotionItemsConfiguratorPivot } from "./pivots/FinancePromotionItemsConfiguratorPivot"

export type TFinancePromotionPivotBasedStateProps = {
    state: TFinancePromotionPivotState
    data: IPromotion
    onDelete: (item: IPromotion) => void
}

export const FinancePromotionPivotBasedState: React.FC<TFinancePromotionPivotBasedStateProps> = (props) => {
    switch (props.state) {
        case "devices": return (
            <FinancePromotionItemsConfiguratorPivot data={props.data} />
        )
        case "info": return (
            <FinancePromotionInfoPivot
                onDelete={props.onDelete} data={props.data} />
        )
    }
}
