import { DirectionalHint, getTheme, IButtonStyles, Icon, IconButton, PrimaryButton, SharedColors, Stack, Text } from "@fluentui/react"
import { ICatalog } from "../../../core/store";
import { strings } from "../../../localization/strings";
import { SecondaryButton } from "../../../components/shared/buttons/SecondaryButton";
import { CatalogTypes } from "@piceasoft/core";

export type TProps = {
    item: ICatalog
    onEdit: (catalog: ICatalog) => void
    onDelete: (catalog: ICatalog) => void
    onImport: () => void
    onExport: () => void
}

export const FinanceCatalogHeader: React.FC<TProps> = (props) => {

    const menuItems = {
        items: [
            {
                key: `export-${props.item.id}`,
                iconProps: { iconName: 'ExcelDocument', style: { color: SharedColors.green10, fontSize: 14 } },
                onClick: () => props.onExport(),
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EXPORT_TO_EXCEL,
                disabled: !props.item.currentVersionDate
            },
            {
                key: `edit-${props.item.id}`,
                iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => props.item.id ? props.onEdit(props.item) : undefined,
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
            },
            {
                key: `delete-${props.item.id}`,
                iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                onClick: () => props.item.id ? props.onDelete(props.item) : undefined,
                text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
            },
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    }
 
    const onDownloadTemplate=()=>{
        let catalogUrl = "";
        switch (props.item.catalogType) {
            case CatalogTypes.Tradein: catalogUrl = 'samples/Trade-in_catalog_sample.xlsx'; break;
            case CatalogTypes.Repair: catalogUrl = 'samples/Repair_self_support_catalog_Sample.xlsx'; break;
            case CatalogTypes.GradeDiscount: catalogUrl = 'samples/Grade_discount_catalog_sample.xlsx'; break;
            default: return;
        }
        fetch(catalogUrl)
        .then(resp => resp.blob())
        .then(blob => {
            let URL = window.URL || window.webkitURL,
                downloadUrl = URL.createObjectURL(blob),
                a = document.createElement('a');
            a.href = downloadUrl;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            a.parentNode?.removeChild(a);
            setTimeout(() => { URL.revokeObjectURL(downloadUrl) }, 100);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return (
        <Stack horizontal grow style={{ padding: "24px 32px 12px 32px" }}>
            <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                <Stack.Item>
                    <Icon iconName={"ProductCatalog"} style={{ fontSize: 42, color: SharedColors.orangeYellow20 }} />
                </Stack.Item>
                <Stack.Item grow>
                    <Stack>
                        <Stack.Item>
                            <Text style={{ fontSize: "1.3125rem", letterSpacing: "-.02em", fontWeight: 600, color: theme.palette.black }}>{props.item.name}</Text>
                        </Stack.Item>
                        {props.item.currentVersionDate && (
                            <Stack.Item>
                                <Text variant="smallPlus" style={{ color: SharedColors.gray30 }}>
                                    {new Date(props.item.currentVersionDate).toLocaleString()}
                                    {props.item.currentVersionSummary && `/ ${props.item.currentVersionSummary}`}
                                </Text>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
            </Stack>
            {!props.item.removed && (
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <SecondaryButton  iconProps={{ iconName: "Download" }} text={strings.ORGANIZATION.CATALOGS.HEADER.DOWNLOAD.TEMPLATE} onClick={onDownloadTemplate} />
                    <PrimaryButton iconProps={{ iconName: "ExcelDocument" }} text={strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.IMPORT_FROM_EXCEL} onClick={props.onImport} />
                    <IconButton menuIconProps={{ iconName: "MoreVertical" }} styles={menuIconStyles} menuProps={menuItems} />
                </Stack>
            )}
        </Stack>
    )
}

const theme = getTheme();

const menuIconStyles: IButtonStyles = {
    root: {
        padding: 4
    },
    menuIcon: {
        fontSize: 16,
        color: theme.palette.black,
        minWidth: 16,
        padding: 4
    },
    menuIconExpanded: {
        color: theme.palette.black
    }
}