import { DefaultButton, Dialog, DialogFooter, DialogType, getTheme, List, MessageBar, MessageBarType, PrimaryButton, ScrollablePane, Separator, Spinner, Stack, Text } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import * as React from "react"
import { ItemsNotFound } from "../../../../components/shared/notFound/ItemsNotFound"
import { SomethingWentWrong } from "../../../../components/shared/somethingWentWrong/SomethingWentWrong"
import { portalApi } from "../../../../core/api/api"
import { IOrganization } from "@piceasoft/core";
import { ISupervisor } from "../../../../core/store/typings/ISupervisor"
import { strings } from "../../../../localization/strings"
import { SupervisorOrganizationItem } from "./SupervisorOrganizationItem"
import { ChooseOrganization } from "./ChooseOrganization"

type TContainerServicesPivotProps = {
    item: ISupervisor
}

export const SupervisorOrganizationsPivot: React.FC<TContainerServicesPivotProps> = (props) => {

    const [organizationsState, setOrganizationsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IOrganization> }>({ fetched: false })
    const [supervisorOrganizationsState, setSupervisorOrganizationsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<IOrganization> }>({ fetched: false })
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)
    const [deleteOrganizationId, setDeleteOrganizationId] = React.useState<string>()

    React.useEffect(() => {
        if (props.item.id) {
            getOrganizations()
            getSupervisorOrganizations(props.item.id)
        }
    }, [])

    const getOrganizations = async () => {
        setOrganizationsState({ fetched: false })
        const result = await portalApi.settings.getOrganizations()
        console.log(result)
        setOrganizationsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data })
    }

    const getSupervisorOrganizations = async (id: string) => {
        setSupervisorOrganizationsState({ fetched: false })
        const result = await portalApi.organization.supervisors.getSupervisorOrganizations(id)
        console.log(result)
        setSupervisorOrganizationsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data ?? [] })
    }

    const addSupervisorOrganizationHandler = (organizationId: string) => {
        hidePanel()
        addSupervisorOrganization(props.item.id as string, organizationId)
    }

    const addSupervisorOrganization = async (supervisorId: string, organizationId: string) => {
        setSupervisorOrganizationsState({ ...supervisorOrganizationsState, error: undefined, fetched: false })
        const result = await portalApi.organization.supervisors.addSupervisorOrganization(supervisorId, organizationId)
        console.log(result)
        setSupervisorOrganizationsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: [...(supervisorOrganizationsState.data ?? []), organizationsState.data?.find(i => i.id === organizationId) as IOrganization] })
    }

    const deleteSupervisorOrganizationHandler = () => {
        if (deleteOrganizationId) {
            deleteSupervisorOrganization(props.item.id as string, deleteOrganizationId)
            setDeleteOrganizationId(undefined)
        }
    }

    const deleteSupervisorOrganization = async (supervisorId: string, organizationId: string) => {
        setSupervisorOrganizationsState({ ...supervisorOrganizationsState, error: undefined, fetched: false })
        const result = await portalApi.organization.supervisors.deleteSupervisorOrganization(supervisorId, organizationId)
        console.log(result)
        if (result.successed) {
            setSupervisorOrganizationsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: supervisorOrganizationsState.data?.filter(i => i.id !== organizationId) })
        }
    }

    const onRenderSupervisorOrganization = (item?: IOrganization | undefined, index?: number | undefined):React.ReactNode => {
        if (!item) return null
        return (
            <SupervisorOrganizationItem item={item} onDelete={() => setDeleteOrganizationId(item.id)}  key={index}/>
        )
    }

    return (
        <>
            {!organizationsState.error && !supervisorOrganizationsState.error && (organizationsState.fetched && organizationsState.data && (
                <Stack grow tokens={{ childrenGap: 16 }}>
                    <Stack.Item style={{ padding: "0 32px 0 32px" }}>
                        <Stack>
                            <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
                                <Text style={{ color: theme.palette.black, fontWeight: 600, fontSize: "1.3125rem", letterSpacing: "-.02em", lineHeight: "24px", width: 600 }}>
                                    {strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.ORGANIZATIONS.TITLE}
                                </Text>
                                {supervisorOrganizationsState.data && supervisorOrganizationsState.data.length !== 0 && (
                                    <PrimaryButton onClick={showPanel} text={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.ORGANIZATIONS.NOT_FOUND_CASE.ADD_BUTTON} />
                                )}
                            </Stack>
                        </Stack>
                        <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                    </Stack.Item>
                    {!supervisorOrganizationsState.error && (supervisorOrganizationsState.fetched && supervisorOrganizationsState.data && (
                        supervisorOrganizationsState.data.length > 0 && (
                            <Stack.Item verticalFill grow style={{ position: 'relative' }}>
                                <ScrollablePane>
                                    <Stack grow verticalFill tokens={{ childrenGap: 16 }} style={{ padding: "0 32px 0 32px" }}>
                                        <List items={supervisorOrganizationsState.data} onRenderCell={onRenderSupervisorOrganization} />
                                    </Stack>
                                </ScrollablePane>
                            </Stack.Item>
                        ) || (
                            <ItemsNotFound
                                onClick={showPanel}
                                info={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.ORGANIZATIONS.NOT_FOUND_CASE.INFO}
                                suggestion={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.ORGANIZATIONS.NOT_FOUND_CASE.SUGGESTION}
                                buttonText={strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.ORGANIZATIONS.NOT_FOUND_CASE.ADD_BUTTON}
                                imgSrc={"images/navigation/images/not_found.png"} />
                        )) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        ))}
                    <Stack tokens={{ childrenGap: 8 }} style={{ padding: "0 32px 24px 32px" }}>
                        <Separator styles={{ root: { selectors: { '&::before': { backgroundColor: theme.palette.neutralQuaternary } } } }} />
                        <Text style={{ color: theme.palette.neutralSecondaryAlt }} >{strings.ORGANIZATION.STRUCTURE.ITEM.CONTAINER.PIVOTS.SERVICES.INFORMATION}</Text>
                    </Stack>
                    <ChooseOrganization
                        isOpen={isPanelOpen}
                        onDismiss={hidePanel}
                        items={organizationsState.data.filter(o => supervisorOrganizationsState.data?.find(so => so.id === o.id) ? false : true)}
                        onAddSupervisorOrganization={addSupervisorOrganizationHandler}
                    />
                </Stack>
            ) || (
                    <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                        <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                    </Stack>
                ))}
            {(organizationsState.error || supervisorOrganizationsState.error) && (
                <Stack verticalFill>
                    {organizationsState.error && organizationsState.error.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error}>{organizationsState.error}</MessageBar>
                    )}
                    {supervisorOrganizationsState.error && supervisorOrganizationsState.error.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error}>{organizationsState.error}</MessageBar>
                    )}
                    <Stack.Item verticalFill>
                        <SomethingWentWrong action={() => props.item.id && getSupervisorOrganizations(props.item.id)} />
                    </Stack.Item>
                </Stack>
            )}
            <Stack.Item>
                <Dialog hidden={deleteOrganizationId ? false : true} minWidth={320} maxWidth={360}
                    modalProps={{ isBlocking: false }}
                    onDismiss={() => setDeleteOrganizationId(undefined)}
                    dialogContentProps={{
                        title: strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.ORGANIZATIONS.DELETE_TITLE,
                        subText: strings.ORGANIZATION.SUPERVISORS.SUPERVISOR.ORGANIZATIONS.DELETE_TITLE_TEXT,
                        type: DialogType.largeHeader,
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton
                            onClick={deleteSupervisorOrganizationHandler}
                            text={strings.BUTTONS.TOGGLE_YES} />
                        <DefaultButton onClick={() => setDeleteOrganizationId(undefined)} text={strings.BUTTONS.TOGGLE_NO} />
                    </DialogFooter>
                </Dialog>
            </Stack.Item>
        </>
    )
}

const theme = getTheme();
