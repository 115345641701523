import { FC } from "react";
import { getTheme, IconButton, Stack, Text } from "@fluentui/react";
import { strings } from "../../../../../localization/strings";

interface IProps {
    showConfigSettings: () => void
}

const SelfServiceConfigItem: FC<IProps> = ({ showConfigSettings }) => {
    return (
        <Stack.Item styles={{
            root: {
                color: theme.palette.black,
                position: "relative",
                width: "100%",
            }
        }}>
            <Stack styles={{
                root: {
                    color: theme.palette.black,
                    boxShadow: theme.effects.elevation16,
                    position: "relative",
                }
            }}>
                <Stack onClick={showConfigSettings}
                    styles={{
                        root: {
                            padding: "16px 32px",
                            color: theme.palette.black,
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: theme.palette.neutralQuaternaryAlt,
                            borderTopWidth: 8,
                            backgroundColor: theme.palette.white,
                            position: "relative",
                            ":hover": {
                                cursor: "pointer",
                                borderColor: theme.palette.themePrimary,
                                backgroundColor: theme.palette.themeLighterAlt
                            }
                        }
                    }}>
                    <Stack.Item>
                        <Text nowrap block style={{ maxWidth: 200 }} styles={{ root: { color: theme.palette.black, fontWeight: 600 } }}>
                            {strings.CONSTRUCTOR.STAGES.SELF_SERVICE.HEADER}
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>
                            {strings.CONSTRUCTOR.STAGES.SELF_SERVICE.MOBILE_SUPPORT_HUB}
                        </Text>
                    </Stack.Item>
                </Stack>
                <Stack verticalFill
                    verticalAlign="center"
                    styles={{ root: { position: "absolute", top: 0, left: -18, bottom: 0 } }}>
                    <IconButton iconProps={{ iconName: "LightningBolt" }}
                        onClick={() => {/* noop */
                        }}
                        styles={{
                            root: {
                                width: 36,
                                height: 36,
                                backgroundColor: theme.palette.white,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: theme.palette.themePrimary,
                                borderRadius: "50%",
                                color: theme.palette.themePrimary,
                                boxShadow: theme.effects.elevation8,
                                ":hover": {
                                    borderColor: theme.palette.neutralQuaternaryAlt,
                                    backgroundColor: theme.palette.white
                                }
                            },
                            flexContainer: {
                                borderRadius: "50%",
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            }
                        }} />
                </Stack>
                <Stack verticalFill
                    verticalAlign="center"
                    styles={{ root: { position: "absolute", top: 0, right: -18, bottom: 0 } }}>
                    <IconButton onClick={showConfigSettings}
                        iconProps={{ iconName: "Repair" }}
                        styles={{
                            root: {
                                width: 36,
                                height: 36,
                                backgroundColor: theme.palette.white,
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: theme.palette.neutralQuaternaryAlt,
                                borderRadius: "50%",
                                color: theme.palette.black,
                                boxShadow: theme.effects.elevation8,
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            },
                            flexContainer: {
                                borderRadius: "50%",
                                ":hover": {
                                    borderColor: theme.palette.themePrimary,
                                    backgroundColor: theme.palette.themeLighterAlt
                                }
                            },
                            iconHovered: {
                                color: theme.palette.black
                            }
                        }}
                    />
                </Stack>
            </Stack>
        </Stack.Item>
    );
};

export default SelfServiceConfigItem;

const theme = getTheme();
