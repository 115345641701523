import { getTheme, Stack, Text, Dropdown, IDropdownOption, Icon, SharedColors } from "@fluentui/react"
import React, { useCallback, useEffect } from "react";
import { Control, Controller, useFieldArray, UseFormSetValue } from "react-hook-form";
import { IRepairOfferProviderConfig } from "@piceasoft/core";
import { strings } from "../../../localization/strings";
import { onRenderLabel } from "../../renders/onRenderLabel";
import { IComponentMap } from "@piceasoft/core/dist/interfaces/IRepairOfferProviderConfig";
import { RepairComponentsMap } from "../../../core/store/typings/RepairComponentsMap";

type TProps = {
    control: Control<IRepairOfferProviderConfig, object>
    setValue: UseFormSetValue<IRepairOfferProviderConfig>
    componentsMap: IComponentMap[]
    templateComponents?: RepairComponentsMap
    options: IDropdownOption[]
}

export const RepairOfferProviderFormGradesMapArray: React.FC<TProps> = ({ control, setValue, componentsMap, templateComponents, options }) => {
    const { fields } = useFieldArray({
        control,
        name: "componentsMap",
    });

    useEffect(()=> {
        setValue('componentsMap', componentsMap)
    }, [componentsMap]);

    const getTemplateComponentName = useCallback((id: string) => {
        let value = '';
        for (const key in templateComponents) {
            if (Object.prototype.hasOwnProperty.call(templateComponents, key)) {
                value = templateComponents[key];
                if (key == id) {
                    break;
                }
            }
        }
        return value
    }, [templateComponents])

    return (
        <Stack grow style={{ borderTop: `1px solid ${theme.semanticColors.bodyDivider}` }}>
            {fields.map((field, index) =>
                <>
                    <Stack.Item key={field.id} grow style={{ borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                        <Stack verticalFill grow horizontal verticalAlign='end'>
                            <Stack grow horizontal verticalAlign='center'>
                                <Stack>
                                    <Icon iconName={'DeveloperTools'} style={{ fontSize: 20, color: SharedColors.cyanBlue20, paddingRight: 10 }} />
                                </Stack>
                                <Stack tokens={{ childrenGap: 8 }}>
                                    <Controller
                                        control={control}
                                        name={`componentsMap.${index}.workflowComponentId`}
                                        render={({ field }) =>
                                            <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600, width: 150 }}>{getTemplateComponentName(field.value)}</Text>
                                        }
                                    />
                                </Stack>
                                <Stack tokens={{ childrenGap: 8 }}>
                                    <Controller
                                        control={control}
                                        name={`componentsMap.${index}.providerComponent`}
                                        render={({ field }) =>
                                            <Dropdown
                                                options={options}
                                                style={{ width: 200 }}
                                                selectedKey={field.value}
                                                placeholder={strings.CONSTRUCTOR.SELFSERVICE.TITLE_PLACEHOLDER}
                                                onChange={(ev, opt) => field.onChange(opt?.key)}
                                                onBlur={field.onBlur}
                                                onRenderLabel={index === 0 ? onRenderLabel : undefined}
                                            />
                                        }
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                </>
            )}
        </Stack>)
}

const theme = getTheme();