import React, {FC} from 'react';
import {getTheme, MessageBar, MessageBarType, Stack, Text} from "@fluentui/react";

const theme = getTheme()
const EmptyListPlaceholder: FC<{ text: string }> = ({text}) => (
    <Stack styles={{
        root: {
            color: theme.palette.black,
            position: "relative",
            background: 'rgba(222,236,249,1)'
        }
    }}>
        <MessageBar isMultiline messageBarType={MessageBarType.info}
                    styles={{root: {background: 'rgba(222,236,249,1)'}}}>
            <Stack horizontal verticalAlign="center" tokens={{childrenGap: 8}}>
                <Text>{text}</Text>
            </Stack>
        </MessageBar>
    </Stack>
);

export default EmptyListPlaceholder;
