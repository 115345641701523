import { IRenderFunction, Stack, Text, SharedColors, IDetailsColumnProps, getTheme } from "@fluentui/react";

export const onRenderColumnHeaderCommon: IRenderFunction<IDetailsColumnProps> = (chProps) => {
    if (!chProps) {
        return null;
    }

    return (
        <Stack tokens={{ padding: "0px 32px 0px 0px" }} horizontal>
            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{chProps.column.name}</Text>
        </Stack>
    );
}

export const onRenderColumnHeaderCommonRequired: IRenderFunction<IDetailsColumnProps> = (chProps) => {
    if (!chProps) {
        return null;
    }

    return (
        <Stack tokens={{ padding: "0px 32px 0px 0px", childrenGap: 4 }} horizontal>
            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{chProps.column.name}</Text>
            <Text style={{ color: SharedColors.red10 }}>*</Text>
        </Stack>
    );
}

const theme = getTheme()