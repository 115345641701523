import { Stack, Text, IconButton, Separator, Toggle, Icon, SharedColors, getTheme } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import { Control, Controller } from "react-hook-form"
import { Section } from "../../../../components/shared/decorations/Section"
import { IBranding } from "../../../../core/store/typings/IBranding"
import { BrandingSelectColorComponent } from "../BrandingSelectColorComponent"
import { strings } from "../../../../localization/strings"
import { TMainColor } from "../BrandingDetails"
import { IBrandingConfig } from "@piceasoft/core";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

type Tprops = {
    mainColors: TMainColor[]
    brandingItem?: IBranding
    control: Control<IBranding, object>   
    defaultDarkConfig: IBrandingConfig,
    getValues: UseFormGetValues<IBranding>,
    setValue: UseFormSetValue<IBranding>,
}



export const BrandingOnlineButtonColorScheme: React.FC<Tprops> = (props) => {
    const [isSectionCollapsed, { toggle: toggleSectionCollapse }] = useBoolean(false)
    
    const isDefault = (): boolean =>{
        var branding = props.getValues();
        return branding?.darkConfig?.onlineButton?.secondaryFontColor == props.defaultDarkConfig.onlineButton.secondaryFontColor
        && branding?.darkConfig?.onlineButton?.secondaryOutline == props.defaultDarkConfig.onlineButton.secondaryOutline
        && branding?.darkConfig?.onlineButton?.secondary == props.defaultDarkConfig.onlineButton.secondary
        && branding?.darkConfig?.onlineButton?.primaryFontColor == props.defaultDarkConfig.onlineButton.primaryFontColor
        && branding?.darkConfig?.onlineButton?.primaryOutline == props.defaultDarkConfig.onlineButton.primaryOutline
        && branding?.darkConfig?.onlineButton?.primary == props.defaultDarkConfig.onlineButton.primary;
    }
    const [isCustomeDark, { toggle: changeCustomeDark }] = useBoolean(!isDefault())
    const toggleCustomeDark = () =>{
        changeCustomeDark();
        if(isCustomeDark){
            props.setValue('darkConfig.onlineButton.secondaryFontColor', props.defaultDarkConfig.onlineButton.secondaryFontColor);
            props.setValue('darkConfig.onlineButton.secondaryOutline', props.defaultDarkConfig.onlineButton.secondaryOutline);
            props.setValue('darkConfig.onlineButton.secondary', props.defaultDarkConfig.onlineButton.secondary);
            props.setValue('darkConfig.onlineButton.primaryFontColor', props.defaultDarkConfig.onlineButton.primaryFontColor);
            props.setValue('darkConfig.onlineButton.primaryOutline', props.defaultDarkConfig.onlineButton.primaryOutline);
            props.setValue('darkConfig.onlineButton.primary', props.defaultDarkConfig.onlineButton.primary);
        }
    }


    const onRenderHeader = (): React.ReactNode => {
        return (
            <Stack grow>
                <Stack
                    onClick={toggleSectionCollapse} horizontal
                    verticalAlign="center" grow horizontalAlign="space-between"
                    tokens={{ padding: "10px 20px 0px 20px" }}
                    style={{ paddingBottom: isSectionCollapsed ? "10px" : "0", cursor: 'pointer' }}
                >
                    <Stack.Item>
                        <Text style={{ fontWeight: 600 }}>{strings.ORGANIZATION.BRANDING.BRANDING_SET.BRANDING_ONLINE_BUTTONS}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton iconProps={{ iconName: isSectionCollapsed ? "ChevronDown" : "ChevronUp" }} />
                    </Stack.Item>
                </Stack>
                {!isSectionCollapsed && (
                    <Separator />
                )}
            </Stack>
        )
    }

    return (
        <Stack>
            <Section onRenderHeader={onRenderHeader} headerOnly={isSectionCollapsed}>
                <Stack>
                    {!isSectionCollapsed && (
                        <>
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap>
                        <Stack.Item>
                             <Text style={{ fontSize: 16,  fontWeight: 600 }}>{strings.BRANDING.DARK.LIGHT_MODE}</Text>
                                </Stack.Item>
                        </Stack>
                        <Separator/>
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap>
                            <Stack.Item>
                                <Controller
                                    name="config.onlineButton.primary"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_BUTTON} mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.onlineButton.primaryOutline"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value ?? ''} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_BUTTON_OUTLINE} mainColors={props.mainColors}
                                            placeholder={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_BUTTON_OUTLINE_PlACEHOLDER} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.onlineButton.primaryFontColor"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_BUTTON_FONT_COLOR} mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.onlineButton.secondary"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.SECONDARY_BUTTON} mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.onlineButton.secondaryOutline"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.SECONDARY_BUTTON_OUTLINE} mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="config.onlineButton.secondaryFontColor"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.SECONDARY_BUTTON_FONT_COLOR} mainColors={props.mainColors} />} />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap>
                        <Stack.Item style={{paddingTop:13}}>
                        <Stack.Item style={{display: "inline-block"}} >
                            <Toggle                                  
                                        checked={isCustomeDark}
                                        onChange={toggleCustomeDark }
                                    />
                                </Stack.Item>  
                                <Stack.Item  style={{display: "inline-block"}}      >
                                <Text style={{ fontSize: 16,  fontWeight: 600, paddingLeft: 5 }}>{strings.BRANDING.DARK.CUSTOM_DARK_MODE}</Text>
                                <Icon iconName={'Info'} style={{ fontSize: 14, color: SharedColors.cyanBlue20, paddingLeft: 10, paddingRight: 10 }} title={strings.BRANDING.DARK.INFO} ariaLabel="Info" styles={iconButtonStyles} />
                                </Stack.Item>
                                </Stack.Item>
                        </Stack>
                        <Separator/>
                        <Stack horizontal tokens={{ childrenGap: '20 40' }} wrap>
                            <Stack.Item>
                                <Controller
                                    name="darkConfig.onlineButton.primary"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent disabled={!isCustomeDark}  fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_BUTTON} mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="darkConfig.onlineButton.primaryOutline"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent disabled={!isCustomeDark}  fieldValue={field.value ?? ''} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_BUTTON_OUTLINE} mainColors={props.mainColors}
                                            placeholder={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_BUTTON_OUTLINE_PlACEHOLDER} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="darkConfig.onlineButton.primaryFontColor"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent disabled={!isCustomeDark}  fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.PRIMARY_BUTTON_FONT_COLOR} mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="darkConfig.onlineButton.secondary"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent disabled={!isCustomeDark}  fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.SECONDARY_BUTTON} mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="darkConfig.onlineButton.secondaryOutline"
                                    control={props.control}
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent disabled={!isCustomeDark}  fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.SECONDARY_BUTTON_OUTLINE} mainColors={props.mainColors} />} />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    name="darkConfig.onlineButton.secondaryFontColor"
                                    control={props.control}                                    
                                    render={({ field }) =>
                                        <BrandingSelectColorComponent disabled={!isCustomeDark} fieldValue={field.value} onChangeField={field.onChange}
                                            label={strings.ORGANIZATION.BRANDING.COLORS.SECONDARY_BUTTON_FONT_COLOR} mainColors={props.mainColors} />} />
                            </Stack.Item>
                        </Stack>
                        </>
                    )}
                </Stack>
            </Section>
        </Stack>
    )
}
const theme = getTheme()
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};