import { getTheme, Icon, IIconProps, IIconStyles, Stack, Text } from "@fluentui/react"

export type TBadge2Props = {
    title: string
    iconName: string
    iconColor?: string
    iconBackgroundColor?: string
    isFilled?: boolean
}

export const Badge2: React.FC<TBadge2Props> = (props) => {
    return (
        <Stack horizontal verticalAlign='center' style={{ width: 96, borderRadius: 4, border: `1px solid ${theme.palette.neutralSecondaryAlt}`, padding: "3px 6px 3px 6px" }} tokens={{ childrenGap: 6 }}>
            {props.iconName && (
                <Icon iconName={props.iconName}
                    style={{
                        color: props.isFilled ? props.iconBackgroundColor : (props.iconColor ?? theme.palette.white),
                        height: 12,
                        width: 12,
                        borderRadius: props.isFilled ? 6 : undefined,
                        backgroundClip: !props.isFilled ? props.iconBackgroundColor : undefined
                    }}
                />
            )}
            <Stack grow horizontalAlign="center">
                <Text style={{ color: theme.palette.neutralSecondary, fontWeight: 400, fontSize: 11, width: 68 }} block nowrap>{props.title}</Text>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();