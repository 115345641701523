import * as React from 'react'
import { Pivot, PivotItem, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { strings } from '../../../localization/strings';
import { IPromotion } from '../../../core/store/typings/IPromotion';
import { FinancePromotionPivotBasedState } from './FinancePromotionPivotBasedState';

type TProps = {
    data: IPromotion
    onDelete: (item: IPromotion) => void
    retryInfo: () => void
}

export type TFinancePromotionPivotState = "info" | "devices"

const getTabId = (itemKey: string) => {
    return `ServicePivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "devices": return strings.ORGANIZATION.PROMOTIONS.PIVOTS.DEVICES.TITLE
        case "info": return strings.ORGANIZATION.PROMOTIONS.PIVOTS.INFO.TITLE
        default: return m;
    }
}

export const FinancePromotionDetails: React.FC<TProps> = (props) => {

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TFinancePromotionPivotState>("devices");

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TFinancePromotionPivotState);
    };

    return (
        <Stack verticalFill>
            <Stack.Item>
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                    <Stack tokens={{ padding: "0px 32px 0px 32px" }}>
                        <Stack.Item>
                            <Pivot selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="menu">
                                <PivotItem itemKey={"info"} headerText={getPivotHeaderText("info")} />
                                <PivotItem itemKey={"devices"} headerText={getPivotHeaderText("devices")} />
                            </Pivot>
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </Stack.Item>
            <Stack verticalFill>
                <FinancePromotionPivotBasedState
                    state={selectedPivotKey}
                    data={props.data}
                    onDelete={props.onDelete}
                />
            </Stack>
        </Stack>
    )
}
