import * as React from 'react'
import { Pivot, PivotItem, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { strings } from '../../../localization/strings';
import { IDocument } from '../../../core/store';
import { ServicesPrintTemplatePivotBasedState } from './ServicesPrintTemplatePivotBasedState';

type TProps = {
    data: IDocument
    onDelete: () => void
    onRetry: () => void
}

export type TServicePrintTemplatePivotState = "configuration" | "information"

const getTabId = (itemKey: string) => {
    return `ServicePivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "configuration": return strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PIVOTS.CONFIGURATION.TITLE
        case "information": return strings.ORGANIZATION.SERVICES_PRINT_TEMPLATES.TEMPLATE.PIVOTS.INFORMATION.TITLE
        default: return m;
    }
}

export const ServicesPrintTemplateDetails: React.FC<TProps> = (props) => {

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TServicePrintTemplatePivotState>("information");

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TServicePrintTemplatePivotState);
    };

    return (
        <Stack verticalFill>
            <Stack.Item >
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                    <Stack tokens={{ padding: "0px 32px 0px 32px" }}>
                        <Stack.Item>
                            <Pivot selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="menu">
                                <PivotItem itemKey={"information"} headerText={getPivotHeaderText("information")} />
                                <PivotItem itemKey={"configuration"} headerText={getPivotHeaderText("configuration")} />
                                {/* <PivotItem itemKey={"settings"} headerText={getPivotHeaderText("settings")} /> */}
                                {/* <PivotItem itemKey={"help"} headerText={getPivotHeaderText("help")} /> */}
                            </Pivot>
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </Stack.Item>
            <Stack.Item verticalFill>
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    <ServicesPrintTemplatePivotBasedState
                        state={selectedPivotKey}
                        data={props.data}
                        onDelete={props.onDelete}
                        onRetry={props.onRetry}
                    />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}
