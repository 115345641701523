import {
    ConnectionTypes,
    definitions,
    DiagnosticsModes,
    IDiagnosticCase,
    ISelfServiceConfigItem,
    ISelfServiceWorkflowConfig
} from "@piceasoft/core";
import {ISelfServiceDiagnosticCase} from "@piceasoft/core/dist/interfaces/ISelfServiceDiagnosticCase";

export const connectionTypes = [ConnectionTypes.WEB, ConnectionTypes.NG_WIRELESS]
export const casesFilterFunc = (c: IDiagnosticCase) => c.platforms.some(p => p.connectionTypes.some(ct => connectionTypes.includes(ct)))
export const connectionTypeToMode = (ct: ConnectionTypes) => {
    switch (ct) {
        case ConnectionTypes.WEB:
            return DiagnosticsModes.WebBased
        case ConnectionTypes.NG_WIRELESS:
            return DiagnosticsModes.PiceaMobile
        default:
            return undefined
    }
}
export const modeToConnectionType = (dm?: DiagnosticsModes) => {
    switch (dm) {
        case DiagnosticsModes.WebBased:
            return ConnectionTypes.WEB
        case DiagnosticsModes.PiceaMobile:
            return ConnectionTypes.NG_WIRELESS
        default:
            return undefined
    }
}

export const getDefaultSelfServiceConfig = (items: ISelfServiceConfigItem[] = []): ISelfServiceWorkflowConfig => ({
    ui: {
        buttons: {}
    },
    followUpQuestions: [],
    disclaimers: [],
    repairOffers: {
        ui: {
            recommendations: {}
        },
        providers: []
    },
    promotions: [],
    config: {
        items,
        diagnostics: {
            cases: definitions.diagnostic.db
                .filter(s => s.cases.some(casesFilterFunc))
                .reduce((acc: ISelfServiceDiagnosticCase[], set) => {
                    acc.push(...set.cases
                        .filter(casesFilterFunc)
                        .map((c) => {
                            const res: ISelfServiceDiagnosticCase = {caseId: c.id, mode: DiagnosticsModes.WebBased}
                            c.platforms.forEach((p) => {
                                const cts = Array.from(new Set(p.connectionTypes.filter(ct => connectionTypes.includes(ct))))
                                if (cts.length === 1) {
                                    res.mode = connectionTypeToMode(cts[0]) ?? DiagnosticsModes.WebBased
                                }
                            })
                            return res
                        })
                    )
                    return acc
                }, []),
        }
    }
});


