import { DirectionalHint, getTheme, Icon, IDropdownProps, ITextFieldProps, SharedColors, Stack, Text, TooltipHost } from "@fluentui/react"

export const onRenderLabel = (props: ITextFieldProps | IDropdownProps | undefined): JSX.Element => {
    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6, padding: "0 0 4px 0" }}>
            <Text style={{ color: theme.palette.black, fontWeight: props?.disabled ? 400 : 500 }}>
                {props?.label ?? ""}{" "}
                {props?.required && (
                    <Text style={{ color: SharedColors.red10 }}>*</Text>
                )}
            </Text>
            {props?.title && (
                <TooltipHost content={props?.title}
                    directionalHint={DirectionalHint.bottomCenter}
                    styles={{ root: { display: "flex", cursor: "default" } }}
                    tooltipProps={{
                        calloutProps: {
                        styles: {
                            beak: { background: theme.palette.black },
                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                        },
                        },
                    }}
                >
                    <Icon iconName="Info" styles={{ root: { display: "flex" } }} />
                </TooltipHost>
            )}
        </Stack>
    )
}

const theme = getTheme();
