import { mergeStyleSets } from '@fluentui/react';


const defaultFontSize = 14;

const defaultLineHeight = 1.4;

const defaultRowSize = 10;

export const defaultCustomSearchRowSize = 2;

export const defaultPiceaOneRowSize = 5;

export const defaultPiceaUsbRowSize = 5;

// 1.2 used due to vast amount browser defaulting to this lineheight 
export const blockOffset = `-${(defaultFontSize * 1.2) / defaultFontSize}em`

export const identificationContainerStyle = (customRowSize?: number) => {

    let rowSize = customRowSize || defaultRowSize;

    const newContainerHeight = rowSize * defaultLineHeight * defaultFontSize;

    const heightinEm = newContainerHeight / defaultFontSize;
    
    const style = mergeStyleSets({
        body:[
            {
                maxHeight:`${heightinEm}em`, 
                overflow: 'hidden',
                overflowWrap: 'break-word' as 'break-word',
                lineHeight: `${defaultLineHeight}`
            },
        ]
    })
    return style
};

// Identifies whether html has block-level elements
export const hasBlockLevelElements = (htmlContent?: string): boolean => {
    const blockLevelElements = ['address', 'article', 'aside', 'blockquote', 'details', 'dialog', 'dd', 'div', 'dl', 'dt', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hr', 'li', 'main', 'nav', 'ol', 'p', 'pre', 'section', 'table', 'ul'];

    const parser = new DOMParser();
    if(htmlContent){
        const doc = parser.parseFromString(htmlContent, 'text/html');

        for (const elementName of blockLevelElements) {
            if (doc.querySelector(elementName)) {
                return true;
            }
        }
        return false;
    }
    return false;
};