import {
    DetailsList, IColumn, Icon, PrimaryButton, Link,
    SelectionMode, Stack, Text, TextField, getTheme,
    IconButton, SharedColors, DefaultButton, ScrollablePane,
    DirectionalHint, IDetailsColumnStyles, TooltipHost, Image, ImageFit, TooltipOverflowMode, css, mergeStyleSets, MessageBar, MessageBarType, IIconProps
} from '@fluentui/react';
import { Controller, Control, SubmitHandler, useForm, FieldError, FieldErrorsImpl } from 'react-hook-form';
import React from 'react';
import { Dispatch } from "react"
import { actionCreators } from '../../../core/actions/configurator-actions';
import { IGrade, IResponseError } from "@piceasoft/core"
import { IGradesCategory } from '@piceasoft/core';
import { onRenderLabel } from '../../renders/onRenderLabel';
import { strings } from '../../../localization/strings';
import { Experience } from '../configurator/helpers/evisibility';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { IRequestResult } from '../../../core/store/typings/IResponseResult';
import { portalApi } from '../../../core/api/api';
import processingSvg from '../../../assets/progressing.svg'
import { CSHHelpLink } from '../help/CSHHelp';

type TProps = {
    onChangeDispatch: Dispatch<any>
    handleCancelClick: () => void
    grades: IGrade[],
    channel: number
}

type TPropsForm = {
    categories?: IGradesCategory[]
    grades: IGrade[]
}

type TPropsImage = {
    index: number,
    imageFile: File | null,
    errorMessage?: string
}

export const GradesForm: React.FC<TProps> = (props) => {

    const [newGrades, setNewGrades] = React.useState<IGrade[]>(props.grades)
    const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm<TPropsForm>({ defaultValues: { grades: newGrades } })

    const [imageArray, setImageArray] = React.useState<TPropsImage[]>()
    const [isImageUploading, { setTrue: startUploading, setFalse: stopUploading }] = useBoolean(false)
    const maxFileSize = 500;
    const [imagesToDelete, setImagesToDelete] = React.useState<string[]>()
    const azureContainer = "workflow-grades"
    
    React.useEffect(() => {
        return () => {
            setImageArray([])
            setImagesToDelete([])
        }
    }, [])

    const onSubmit: SubmitHandler<TPropsForm> = async data => {
        console.log(data.grades)
        let gradesWithImageUrl;
        if(props.channel !== Experience.Retail) {
            gradesWithImageUrl = await uploadImageToStorage();
            await deleteImageFromStorage();
        }
        props.onChangeDispatch(actionCreators.previousGrades.save(props.grades))
        gradesWithImageUrl ? props.onChangeDispatch(actionCreators.grades.save(gradesWithImageUrl))
                            : props.onChangeDispatch(actionCreators.grades.save(data.grades))
        
        props.handleCancelClick()
    }

    const onDeleteItem = (index: number) => {
        updateImagesToDeleteArray(getValues().grades.find(f => f.index === index))

        let grades = getValues().grades
            .filter(i => i.index !== index)
            .sort((a, b) => a.index - b.index)
            .map((i, localIndex) => { return ({ ...i, index: localIndex + 1 }) })
        setNewGrades(grades)
        setValue("grades", grades)

        if(imageArray) {
            let newImageArray = imageArray.filter(i => i.index !== index)
                .sort((a, b) => a.index - b.index)
                .map((image, localIndex) => { return ({ ...image, index: localIndex + 1 })})
            setImageArray(newImageArray)
        }
    }

    const onAddItem = () => {
        let grades = ([
            ...getValues().grades,
            ((props.channel !== Experience.Retail) 
                ? { code: "", name: "", index: newGrades.length + 1, description: "", explanation: "", image_url: "", image_name: "" }
                : { code: "", name: "", index: newGrades.length + 1, description: "", explanation: "" })])
        setNewGrades(grades)
        setValue("grades", grades)
    }

    const onMoveUp = (index: number) => {
        if (index > 1) {
            let grades = getValues().grades.map(grade => {
                if (grade.index === index) {
                    return { ...grade, index: grade.index - 1 }
                }
                if (grade.index === index - 1) {
                    return { ...grade, index: grade.index + 1 }
                }
                return grade
            }).sort((a, b) => a.index - b.index)
            setNewGrades(grades)
            setValue("grades", grades)

            if(imageArray) {
                let newImageArray = imageArray?.map((image) => {
                        if (image.index === index) {
                            return { ...image, index: image.index - 1}
                        }
                        if (image.index === index - 1) {
                            return { ...image, index: image.index + 1}
                        }
                        return image
                    }
                ).sort((a, b) => a.index - b.index)
                setImageArray(newImageArray)
            }
        }
    }

    const allowedFileTypes = ["image/jpeg", "image/png"]

    const addFile = (upFiles: FileList, index: number) => {
        var checkUpFilesSize = [...Array.from(upFiles).filter(upFile => (upFile.size / 1024) < maxFileSize )]

        if(!checkUpFilesSize.length) {
            if(imageArray) {
                if(imageArray.find(image => image.index === index)) {
                    let newImageArray = imageArray.map((image) => {
                        if(image.index === index) {
                            return { ...image, errorMessage: strings.CONSTRUCTOR.GRADES.TITLES.IMAGE.split(".")[1]}
                        }
                        return image
                    })
                    setImageArray(newImageArray)
                } else {
                    setImageArray([...imageArray, {index: index, imageFile: null, errorMessage: strings.CONSTRUCTOR.GRADES.TITLES.IMAGE.split(".")[1]}])
                }
            } else {
                setImageArray([{index: index, imageFile: null, errorMessage: strings.CONSTRUCTOR.GRADES.TITLES.IMAGE.split(".")[1]}])
            }
        } else {
            var checkUpFiles = [...Array.from(checkUpFilesSize).filter(upFile => allowedFileTypes.includes(upFile.type))]

            if(checkUpFiles.length) {
                if(imageArray) {
                    if(imageArray.find((image) => image.index === index)) {
                        let newImageArray = imageArray.map((image) => {
                            if(image.index === index) {
                                return { ...image, imageFile: checkUpFiles[0], errorMessage: ""}
                            }
                            return image
                        })
                        setImageArray(newImageArray)
                    } else {
                        setImageArray([...imageArray, {index: index, imageFile: checkUpFiles[0], errorMessage: ""}])
                    }

                    let grades = getValues().grades
                        .map((item) => {
                            if(item.index === index) { return { ...item, image_url: URL.createObjectURL(checkUpFiles[0]), image_name: checkUpFiles[0].name }}
                            return item
                        })
                    setNewGrades(grades)
                    setValue("grades", grades)
                } else {
                    setImageArray([{ index: index, imageFile: checkUpFiles[0] }])
                    let grades = getValues().grades
                        .map((item) => {
                            if(item.index === index) { return { ...item, image_url: URL.createObjectURL(checkUpFiles[0]), image_name: checkUpFiles[0].name }}
                            return item
                        })
                    setNewGrades(grades)
                    setValue("grades", grades)
                }
            } else {
                if(imageArray) {
                    if(imageArray.find(image => image.index === index)) {
                        let newImageArray = imageArray.map((image) => {
                            if(image.index === index) {
                                return { ...image, errorMessage: strings.CONSTRUCTOR.GRADES.TITLES.IMAGE.split(".")[0]}
                            }
                            return image
                        })
                        setImageArray(newImageArray)
                    } else {
                        setImageArray([...imageArray, {index: index, imageFile: null, errorMessage: strings.CONSTRUCTOR.GRADES.TITLES.IMAGE.split(".")[0]}])
                    }
                } else {
                    setImageArray([{index: index, imageFile: null, errorMessage: strings.CONSTRUCTOR.GRADES.TITLES.IMAGE.split(".")[0]}])
                }
            }
        }
    }

    const onDeleteImage = (index: number) => {
        let grades = getValues().grades
            .map((item) => {
                if(item.index === index) {
                    updateImagesToDeleteArray(item)
                    return { ...item, image_url: "", image_name: ""}
                }
                return item
            })
        setNewGrades(grades)
        setValue("grades", grades)

        if(imageArray){
            let newImageArray = imageArray
                .map((image) => { 
                    if(image.index === index) {
                        return ({ ...image, imageFile: null })
                    }
                    return image
                })
            setImageArray(newImageArray)
        }
    }

    const uploadImageToStorage = async () => {
        if(imageArray) {
            startUploading()

            let errors: IResponseError[] = []
            const resultImageArray: { index: number, imageUrl: string }[] = []
            for(const image of imageArray) {
                let uploadResult: IRequestResult<string> = { successed: false }
                if(image.imageFile) {
                    uploadResult = await portalApi.settings.organization.uploadFile(azureContainer, image.imageFile, undefined, true)
                    if(uploadResult.errors && uploadResult.errors.length > 0) {
                        errors = [...errors, ...uploadResult.errors]
                    }
                } else {
                    uploadResult.successed = true;
                }

                if(uploadResult.successed) {
                    if(uploadResult.data) {
                        const currentImage = {
                            index: image.index,
                            imageUrl: uploadResult.data
                        }
                        resultImageArray.push(currentImage)
                    }
                }
            }

            if(resultImageArray) {
                let grades = getValues().grades
                    .map((grade) => {
                        const findImage = resultImageArray.find((image) => image.index === grade.index)
                        if(findImage) {
                            return ({ ...grade, image_url: findImage.imageUrl })
                        }
                        return grade
                    })
                setNewGrades(grades)
                setValue("grades", grades)
            }

            if(errors.length > 0) {
                console.log(errors)
            }

            stopUploading()
            return getValues().grades
        }
    }

    const updateImagesToDeleteArray = (grade: IGrade | undefined) => {
        if(grade?.image_url?.split("//")[0] === "https:") {
            const splitUrl = grade.image_url.split("/")
            const imageKey = splitUrl[splitUrl.length - 1]

            imagesToDelete  ? setImagesToDelete([...imagesToDelete, imageKey])
                            : setImagesToDelete([imageKey])
        }
    }

    const deleteImageFromStorage = async () => {
        let errors: IResponseError[] = [] 
        if(imagesToDelete) {
            startUploading()
            for(const imageKey of imagesToDelete) {
                const result = await portalApi.settings.organization.deleteFile(azureContainer, imageKey) 
                if(result.errors && result.errors.length > 0) {
                    errors = [...errors, ...result.errors]
                }
            }
            stopUploading()
        }

        if(errors.length > 0) {
            console.log(errors)
        }
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            {isImageUploading && (
                <Stack verticalFill horizontalAlign='center' verticalAlign='center'>
                    <Image height={128} src={processingSvg} />
                </Stack>
            ) || (
            <Stack verticalFill>
                {newGrades && newGrades.length > 0 && (
                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                        
                        <ScrollablePane>
                            <GradesDetailsListComponent grades={getValues().grades} {...{ control, setValue }} onDeleteItem={onDeleteItem} onMoveUp={onMoveUp} errors={errors} channel={props.channel} addFile={addFile} onDeleteImage={onDeleteImage} imageArray={imageArray} />
                        </ScrollablePane>
                    </Stack.Item>
                )}
                {newGrades.length === 0 && (
                    <Stack.Item verticalFill style={{ position: 'relative' }}>
                        <Stack verticalFill verticalAlign="center" horizontalAlign="center" tokens={{ padding: 20, childrenGap: 20 }}>
                            <Stack verticalAlign="center" horizontalAlign="center" style={{
                                width: 72, height: 72, borderRadius: "50%",
                                backgroundColor: theme.palette.neutralLighter
                            }}>
                                <Icon iconName={"AssessmentGroup"} style={{ color: theme.palette.black, fontSize: 28 }} />
                            </Stack>
                            <Stack horizontalAlign="center" tokens={{ childrenGap: 0 }}>
                                <Text style={{ fontSize: "1.0625rem", fontWeight: 500, color: theme.palette.black }}>{ }</Text>
                                <Stack horizontalAlign="center">
                                    <Text style={{ color: theme.palette.black }}>{strings.CONSTRUCTOR.GRADES.MODAL_NO_GRADES_DESCRIPTION}</Text>
                                </Stack>
                                <CSHHelpLink  articleid="1122" alignment='left' label={strings.CONSTRUCTOR.GRADES.MODAL_NO_GRADES_LINK_TEXT}/>
                                <Stack.Item style={{ padding: "16px 0px 0px 0px" }}>
                                    <PrimaryButton onClick={() => onAddItem()} >{strings.CONSTRUCTOR.BUTTONS.ADD_GRADE}</PrimaryButton>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="space-between" tokens={{ childrenGap: 8, padding: '20px 20px' }}>
                        <Stack>
                            {newGrades && newGrades.length > 0 && (
                                <PrimaryButton onClick={() => onAddItem()} >{strings.CONSTRUCTOR.BUTTONS.ADD_GRADE}</PrimaryButton>
                            )}
                        </Stack>
                        <Stack tokens={{ childrenGap: 8 }} horizontal horizontalAlign='end'>
                            {(newGrades.length > 0 || props.grades.length > 0) && (
                                <PrimaryButton text={strings.BUTTONS.TEXT.CONFIRM} onClick={handleSubmit(onSubmit)} />
                            )}
                            <DefaultButton text={strings.BUTTONS.TEXT.CANCEL} onClick={() => props.handleCancelClick()} />
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack>
            )}

        </form>


    )
}
type Error = {
    grades?: {
        code?: FieldError | undefined;
        index?: FieldError | undefined;
        name?: FieldError | undefined;
        explanation?: FieldError | undefined;
        description?: FieldError | undefined;
        image_url?: FieldError | undefined;
    }[] | undefined;
}

type TPropsDetailsList = {
    control: Control<TPropsForm, object>,
    onDeleteItem: (index: number) => void,
    onDeleteImage: (index: number) => void,
    onMoveUp: (index: number) => void,
    errors: Partial<FieldErrorsImpl<{
        categories: {
            index: number;
            code: string;
            name: string;
            grades: {
                index: number;
                code: string;
                name: string;
                explanation: string;
                description: string;
                image_url?: string;
            }[];
        }[];
        grades: {
            index: number;
            code: string;
            name: string;
            explanation: string;
            description: string;
            image_url?: string;
        }[];
    }>>
    grades: IGrade[],
    channel: number,
    addFile?: (files: FileList, index: number) => void,
    imageArray?: TPropsImage[]
}

export const GradesDetailsListComponent: React.FC<TPropsDetailsList> = ({ control, grades, onDeleteItem, onMoveUp, errors, channel, addFile, onDeleteImage, imageArray }) => {

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            fieldName: 'testUp',
            minWidth: 38,
            maxWidth: 38,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Stack key={index} horizontal verticalAlign='center' tokens={{ childrenGap: 8, padding: "0 0 0 6px" }}>
                        <IconButton disabled={!index}
                            iconProps={{ iconName: 'Up' }}
                            onClick={() => { onMoveUp(item.index) }}
                        /></Stack>)
            },
        },

        {
            key: 'column2',
            name: '',
            fieldName: 'testIndex',
            minWidth: 15,
            maxWidth: 15,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Stack style={{ maxWidth: '100%', padding: '6px 0 0 0' }} horizontalAlign="start" verticalAlign="baseline">
                        <Text style={{ fontWeight: 500, color: SharedColors.cyanBlue10, width: '100%', textAlign: 'right' }} >{item.index}</Text>
                    </Stack>)
            },
            isPadded: false,
        },

        {
            key: 'column3',
            name: '',
            fieldName: 'testCode',
            onRenderHeader: () => onRenderLabel({
                title: strings.CONSTRUCTOR.GRADES.TITLES.CODE,
                label: strings.CONSTRUCTOR.GRADES.CODE,
                required: true
            }),
            minWidth: 150,
            maxWidth: 150,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`grades.${index}.code`}
                        rules={{
                            required: strings.CONSTRUCTOR.GRADES.REQUIRED_ERROR,
                            pattern: {
                                value: /[A-Z0-9_]/,
                                message: strings.CONSTRUCTOR.GRADES.CODE_VALIDATION_ERROR
                            },
                            validate: (value) => {
                                return grades.find(el => el.code === value && el.index !== item.index) ?
                                    strings.CONSTRUCTOR.GRADES.GRADES_CODE_VALIDATION : true
                            }
                        }}
                        render={({ field }) =>
                            <TooltipHost directionalHint={DirectionalHint.rightCenter}
                                styles={{ root: { display: "flex", cursor: "default" } }}
                                tooltipProps={{
                                    calloutProps: {
                                        styles: {
                                            beak: { background: theme.palette.black },
                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                        },
                                    },
                                }} content={errors?.grades ? errors?.grades?.[index]?.code?.message : undefined}>
                                <TextField styles={{ errorMessage: { display: 'none' } }} value={field.value}
                                    placeholder={strings.CONSTRUCTOR.GRADES.PLACEHOLDERS.CODE}
                                    errorMessage={errors?.grades ? errors?.grades?.[index]?.code?.message : undefined}
                                    onChange={(ev, value) => {
                                        if (ev.target) {
                                            (ev.target as HTMLInputElement).value = value ? value.toUpperCase() : ''
                                            field.onChange(ev)
                                        }
                                    }} />
                            </TooltipHost>
                        } />
                )
            },
            isPadded: false,
        },
        {
            key: 'column4',
            name: '',
            fieldName: 'testName',
            onRenderHeader: () => onRenderLabel({
                title: strings.CONSTRUCTOR.GRADES.TITLES.NAME,
                label: strings.CONSTRUCTOR.GRADES.NAME,
                required: true
            }),
            minWidth: 180,
            maxWidth: 180,
            isMultiline: true,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`grades.${index}.name`}
                        rules={{
                            required: strings.CONSTRUCTOR.GRADES.REQUIRED_ERROR,
                            validate: (value) => {
                                return grades.find(el => el.name === value && el.index !== item.index) ?
                                    strings.CONSTRUCTOR.GRADES.GRADES_NAME_VALIDATION : true
                            }
                        }}
                        render={({ field }) =>
                            <TooltipHost directionalHint={DirectionalHint.rightCenter}
                                styles={{ root: { display: "flex", cursor: "default" } }}
                                tooltipProps={{
                                    calloutProps: {
                                        styles: {
                                            beak: { background: theme.palette.black },
                                            beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                            calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                        },
                                    },
                                }} content={errors?.grades ? errors?.grades?.[index]?.name?.message : undefined}>
                                <TextField styles={{ errorMessage: { display: 'none' } }} value={field.value}
                                    placeholder={strings.CONSTRUCTOR.GRADES.PLACEHOLDERS.NAME}
                                    errorMessage={errors?.grades ? errors?.grades?.[index]?.name?.message : undefined}
                                    onChange={(ev) => {
                                        field.onChange(ev)
                                    }} />
                            </TooltipHost>
                        } />
                )
            },
            isPadded: false,
        },

        {
            key: 'column5',
            name: '',
            fieldName: 'testExplanation',
            onRenderHeader: () => onRenderLabel({
                title: (channel === Experience.Retail
                            ? strings.CONSTRUCTOR.GRADES.TITLES.EXPLANATION 
                            : strings.CONSTRUCTOR.MANUAL_GRADING.TITLES.EXPLANATION),
                label: strings.CONSTRUCTOR.GRADES.EXPLANATION,
            }),
            minWidth: 200,
            maxWidth: 200,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`grades.${index}.explanation`}
                        render={({ field }) =>
                            <TextField value={field.value}
                                placeholder={strings.CONSTRUCTOR.GRADES.PLACEHOLDERS.EXPLANATION}
                                onChange={field.onChange} />} />
                )
            },
            isPadded: false,
        },
        (channel === Experience.Retail ?
        {
            key: 'column6',
            name: '',
            fieldName: 'testDescription',
            onRenderHeader: () => onRenderLabel({
                title: strings.CONSTRUCTOR.GRADES.TITLES.DESCRIPTION,
                label: strings.CONSTRUCTOR.GRADES.DESCRIPTION,
            }),
            minWidth: 30,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`grades.${index}.description`}
                        render={({ field }) =>
                            <TextField value={field.value}
                                placeholder={strings.CONSTRUCTOR.GRADES.PLACEHOLDERS.DESCRIPTION}
                                onChange={field.onChange} />} />
                )
            },
            isPadded: false,
        } : {
            key: 'column8',
            name: '',
            fieldName: 'testImage',
            onRenderHeader: () => onRenderLabel({
                title: strings.CONSTRUCTOR.GRADES.TITLES.IMAGE,
                label: strings.CONSTRUCTOR.GRADES.IMAGE,
            }),
            minWidth: 30,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Controller control={control} name={`grades.${index}.image_url`}
                        render={({ field }) =>
                            <Stack verticalAlign="center">
                                <UploadGradingImages field={field} item={item} addFile={addFile} onDeleteImage={onDeleteImage} />
                                {imageArray && imageArray.map(image => {
                                    if(image.index === item.index && image.errorMessage) {
                                        return <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>{image.errorMessage}</MessageBar>
                                    }
                                })}
                            </Stack>
                        }
                    />
                )
            },
            isPadded: false,
        }),

        {
            key: 'column7',
            name: '',
            fieldName: 'testDelete',
            minWidth: 40,
            isResizable: false,
            data: 'string',
            styles: columnStyles,
            onRender: (item: IGrade, index?: number) => {
                if (index === undefined) return;
                return (
                    <Stack key={index}>
                        <TooltipHost content={strings.CONSTRUCTOR.GRADES.DELETE}
                            directionalHint={DirectionalHint.bottomCenter}
                            styles={{ root: { display: "flex", cursor: "default" } }}
                            tooltipProps={{
                                calloutProps: {
                                    styles: {
                                        beak: { background: theme.palette.black },
                                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                    },
                                },
                            }}
                        >
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={() => { onDeleteItem(item.index) }}
                            />
                        </TooltipHost>
                    </Stack>)
            },
            isPadded: false,
        }
    ]
    return (
        <DetailsList items={grades} columns={columns} selectionMode={SelectionMode.none} />
    )

}

type TUploadImageProps = {
    field: any,
    item: IGrade,
    addFile?: (files: FileList, index: number) => void,
    onDeleteImage: (index: number) => void
}

export const UploadGradingImages: React.FC<TUploadImageProps> = (props) => {
    const inputFileButtonId = useId('upload-grade-image-id')

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }} >
           <Stack>
                {props.item.image_url ? <Image src={props.item.image_url} width={32} height={32} imageFit={ImageFit.contain} style={{ borderRadius: theme.effects.roundedCorner4}} />
                : <Icon style={{ fontSize: 32 }} iconName="Photo2" />  }
           </Stack>
            <Stack.Item>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 16 }}>
                    {props.item.image_name ? 
                        <TooltipHost 
                            content={props.item.image_name}
                            overflowMode={TooltipOverflowMode.Self}
                            styles={{ root: { display: 'block' } }}
                            hostClassName={css(styles.title)}
                        >
                            {props.item.image_name}
                        </TooltipHost>
                        : <TooltipHost
                            content={strings.ORGANIZATION.BRANDING.UPLOAD_LOGO}
                            directionalHint={DirectionalHint.bottomCenter}
                            styles={{ root: { display: "flex", cursor: "default" } }}
                            tooltipProps={{
                                calloutProps: {
                                    styles: {
                                        beak: { background: theme.palette.black },
                                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                    },
                                },
                            }}
                        >
                            <IconButton 
                                style={{ height: 32, border: '1px solid #8A8886', color: 'black' }}
                                iconProps={uploadIcon}
                                onClick={() => {
                                    const el = document.getElementById(inputFileButtonId)
                                    if(el) {
                                        (el as HTMLInputElement).value=''
                                        el.click()
                                    }
                                }}
                            />
                        </TooltipHost>
                    }
                    {props.item.image_url && 
                        <TooltipHost content={strings.ORGANIZATION.BRANDING.REMOVE_LOGO}
                            directionalHint={DirectionalHint.bottomCenter}
                            styles={{ root: { display: "flex", cursor: "default" } }}
                            tooltipProps={{
                                calloutProps: {
                                    styles: {
                                        beak: { background: theme.palette.black },
                                        beakCurtain: { background: theme.palette.black, color: "red !important", fontWeight: 600 },
                                        calloutMain: { background: theme.palette.black, "p": { color: theme.palette.white } }
                                    },
                                },
                            }}
                        >
                            <Link onClick={() => {props.onDeleteImage(props.item.index)}}><Icon iconName='Cancel' style={{ fontSize: 15, paddingTop: 6 }} /></Link>
                        </TooltipHost>
                    }
                </Stack>
                <input id={inputFileButtonId} hidden type="file" onChange={(e) => e.target.files && props.addFile && props.addFile(e.target.files, props.item.index) } />
            </Stack.Item>
        </Stack>
    )
}

const theme = getTheme();

const columnStyles: Partial<IDetailsColumnStyles> = {
    root:
    {
        ':hover': { backgroundColor: 'transparent' },
        ':active': { backgroundColor: 'transparent' }
    }
}

const styles = mergeStyleSets({
    title: [
        theme.fonts.medium,
        {
            color: SharedColors.cyanBlue10,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 150,
        }
    ]
})

const uploadIcon: IIconProps = { iconName: 'Upload' }
