import { focusElement } from "../../../../../../../search/SuggestedSearch"

export async function fetchDaData(value: string, key: string, useDaData: boolean, endpoint: 'fio' | 'fms_unit' | 'address') {
    if (!useDaData) {
        return
    }
    
    let url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${endpoint}`
    const authHeader = `Token ${key}`

    let options: RequestInit = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": authHeader
        },
        body: JSON.stringify({ query: value })
    }

    try {
        let resp = await fetch(url, options)
        if (!resp.ok) {
            console.error(`Failed to fetch ${endpoint} DaData. Status code:`, resp.status)
            return null
        }
        return await resp.json()
    } catch (e) {
        console.error(`Failed to fetch ${endpoint} DaData`, e)
    }
}

export const handleTextToListKeyPressLocal = (
    ev: React.KeyboardEvent<HTMLElement>,
    hostId: string,
    listId: string,
    suggestionsLength: number,
    setSearchRequest: (item?: any) => void,
    index?: number,
    item?: string,
) => {
    if (suggestionsLength > 0) {
        switch (ev.key) {
            case 'ArrowDown':
                if (index === undefined) {
                    focusElement(hostId, `${listId}-0`)
                    break
                }
                if (index < suggestionsLength - 1) {
                    focusElement(`${listId}-${index}`, `${listId}-${index + 1}`)
                    break
                }
                focusElement(`${listId}-${index}`, hostId)
                break
            case 'ArrowUp':
                if (index !== undefined && index > 0) {
                    focusElement(`${listId}-${index}`, `${listId}-${index - 1}`)
                    break
                }
                focusElement(`${listId}-${index}`, hostId)
                break
            case 'Enter':
                setSearchRequest(item)
                // переделать на ref
                document.getElementById(hostId)?.focus()
                break
            default:
                focusElement(`${listId}-${index}`, hostId)
                break
        }
    }
}

export function parseDateFromString(value: string): Date | null {
    let data = value.split(".");
    if (data.length === 3) {
        return new Date(Number(data[2]), Number(data[1]) - 1, Number(data[0]));
    } else {
        return null;
    }
}

export function parseDateField(dateString: string | undefined) : Date | null {
    return dateString !== undefined ? parseDateFromString(dateString) : null
} 

export function formatDate(date?: Date | null): string {
    return date?.toLocaleDateString() ?? "";
}