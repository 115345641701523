import { getTheme, ScrollablePane, Stack } from '@fluentui/react';
import * as React from 'react';
import { PropertyGrid } from '../../../../../components/shared/decorations/PropertyGrid';
import { MyMessage } from '../../../../../components/shared/messages/MyMessage';
import { ItemsNotFound } from '../../../../../components/shared/notFound/ItemsNotFound';
import { SliderImage } from '../../../../../components/shared/SliderImage/SilderImage';
import { IPhotographic, IModerationMessage } from '../../../../../core/store';
import { IGrade, IPhotographicConfig } from "@piceasoft/core"
import { strings } from '../../../../../localization/strings';
import { TransactionTabEntry } from './TransactionTabEntry';

type TProps = {
    result: IPhotographic
    config: IPhotographicConfig
    grades: IGrade[]
    messages?: IModerationMessage[]
    moderatorName?: string
}

export const PhotographicPivot: React.FC<TProps> = (props) => {
    return (
        <>
            {props.config.config && (
                <PhotographicResult result={props.result} config={props.config} grades={props.grades} messages={props.messages} moderatorName={props.moderatorName} />
            )}
        </>
    )
}

type TPhotographicResultProps = {
    config: IPhotographicConfig
    result: IPhotographic
    grades: IGrade[]
    messages?: IModerationMessage[]
    moderatorName?: string
}

const PhotographicResult: React.FC<TPhotographicResultProps> = (props) => {
    return (
        <Stack grow verticalFill verticalAlign='center'>
            <Stack horizontal grow verticalFill>
                {(props.result.files && props.result.files.length > 0) && (
                    <Stack tokens={{ padding: "20px 0 0 20px" }}>
                        <SliderImage
                            data={props.result.files.map(i => ({ image: i.link, text: i.name, preview: i.link + '-preview' }))}
                            showDescription={true}
                            direction="right"
                        />
                    </Stack>
                )}
                {props.messages && props.messages.length > 0 && (
                    <Stack grow={5} style={{ background: theme.palette.neutralLighter }}>
                        <Stack grow verticalFill style={{ position: "relative", borderLeft: `1px solid ${theme.semanticColors.bodyDivider}` }}>
                            <ScrollablePane>
                                <Stack tokens={{ padding: 16, childrenGap: 16 }}>
                                    {props.messages.map(m => (
                                        <Stack.Item>
                                            <MyMessage dateTime={m.datetime} sender={m.sender} text={m.message} myMessage={["модератор", "moderator"].includes(m.sender.toLowerCase())} />
                                        </Stack.Item>
                                    ))}
                                </Stack>
                            </ScrollablePane>
                        </Stack>
                    </Stack>
                ) || (
                        <Stack verticalFill verticalAlign='center' grow horizontalAlign='center'>
                            <ItemsNotFound doNotUseButton imgSrc={"images/navigation/images/not_found.png"} info={strings.TRANSACTIONS.MODERATIONS.MODERATION.INFO} suggestion={strings.TRANSACTIONS.MODERATIONS.MODERATION.SUGGESTION} />
                        </Stack>
                    )}
                <Stack tokens={{ childrenGap: 16, padding: 16 }} grow={1}>
                    <PropertyGrid name={strings.TRANSACTION.RESULT.PHOTOGRAPHIC.MODERATION} icon="Telemarketer">
                        <TransactionTabEntry name={strings.TRANSACTION.RESULT.PHOTOGRAPHIC.MODERATOR} value={props.moderatorName ?? "–"} />
                        {/* <TransactionTabEntry name={strings.TRANSACTION.RESULT.PHOTOGRAPHIC.PROCESSING_TIME} value={"00:00"} /> */}
                        <TransactionTabEntry name={strings.TRANSACTION.RESULT.PHOTOGRAPHIC.MODERATOR_GRADE} value={props.grades.find(i => i.code === props.result.grade)?.name ?? "–"} />
                    </PropertyGrid>
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();
