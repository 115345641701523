import { getTheme, IStackStyles, Spinner, Stack } from '@fluentui/react';
import * as React from 'react';
import { Notifications } from '../shared/notifications/Notifications';
import { useSelector } from 'react-redux';
import { IStore } from '../../core/store';
import Footer from './footer/Footer';
import Header from './header/Header';
import Main from './main/Main';
import { Navigation } from './navigation/Navigation';
import { UserRoles } from '../../core/store/typings/UserRoles';
import { ChangeOrganization } from '../shared/ChangeOrganization';

const theme = getTheme();
const headerStyles: IStackStyles = { root: { zIndex: 10, position: "relative" } };
const mainStyles: IStackStyles = { root: { position: "relative", backgroundColor: theme.palette.neutralLighterAlt } };
const footerStyles: IStackStyles = { root: { zIndex: 10, position: "relative" } };

const Layout: React.FC = (props) => {
    const workplace = useSelector((state: IStore) => state.workplace);
    const roles = useSelector((s: IStore) => s.environment.roles);

    const [showChangeOrganization, setShowChangeOrganization] = React.useState(false)

    // console.log(roles?.find(i => [UserRoles.Administrator, UserRoles.Trader].includes(i as UserRoles)))

    return (
        workplace.isReady && (
            workplace.organization && (
                <Stack grow={1}>
                    <Stack.Item styles={headerStyles}>
                        <Header logo={workplace.organization?.logo} organization={workplace.organization?.name} showChangeOrganizatoinModal={() => setShowChangeOrganization(true)} />
                    </Stack.Item>
                    <Stack.Item verticalFill styles={mainStyles}>
                        <Stack horizontal verticalFill>
                            <Stack.Item>
                                <Navigation logo={workplace.organization?.logo} organization={workplace.organization?.name} />
                            </Stack.Item>
                            <Stack.Item verticalFill grow styles={mainStyles}>
                                <Main>{props.children}</Main>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item styles={footerStyles}>
                        <Footer />
                    </Stack.Item>
                    {roles?.find(i => [UserRoles.Administrator, UserRoles.Supervisor].includes(i as UserRoles)) && (
                        <ChangeOrganization isOpen={showChangeOrganization} onDismiss={() => setShowChangeOrganization(false)} />
                    )}
                    <Notifications />
                </Stack>
            ) || (
                <ChangeOrganization isOpen={true} onDismiss={() => setShowChangeOrganization(false)} />
            )
        ) || (
            <Stack verticalFill verticalAlign='center' horizontalAlign='center'>
                <Spinner />
            </Stack>
        )
    );
}

export default Layout;
